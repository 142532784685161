import { useLayoutEffect, useEffect, useCallback } from 'react';
import { isNumber } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import { useAccount } from 'wagmi';
import { SignWalletButton, Button } from '@/components/buttons';
import { UserBalance } from '@/components/common-elements';
import { UserMenuItems } from '@/components/menus/UserMenuItems';
import {
  useIsTablet,
  useShortenedAddress,
  useOnMenuItemClick,
  useFirebaseAuthState,
} from '@/hooks';
import { useModals, useUserBalance } from '@/providers';
import { useServerAuth } from '@/providers/ServerAuth';
import { MenuItemTypes } from '@/utils/user-menu';
import { Paper, List } from '@mui/material';
import { styled, css, Theme } from '@mui/material/styles';
import { Text } from '../texts';

const MobileMenuModal = () => {
  const { t, i18n } = useTranslation();
  const router = useRouter();
  const [user] = useFirebaseAuthState();
  const { isConnected, address } = useAccount();
  const { authorized, authOnServerWithWallet } = useServerAuth();
  const { dispatch } = useModals<'withdrawDeposit' | 'mobileMenu'>();
  const tablet = useIsTablet();
  const shortenedAddress = useShortenedAddress(address);

  const handleMenuItemClick = useOnMenuItemClick();

  const { balance, balancePending } = useUserBalance();

  const closeModal = useCallback(() => {
    dispatch({
      type: 'closeModal',
    });
  }, [dispatch]);

  const onMenuItemClick = useCallback(
    (type: MenuItemTypes) => {
      handleMenuItemClick(type);
    },
    [handleMenuItemClick]
  );

  const onLanguageClick = useCallback(
    (language: string) => {
      i18n.changeLanguage(language);
      closeModal();
    },
    [i18n, closeModal]
  );

  useLayoutEffect(() => {
    dispatch({
      type: 'setWrapperProps',
      payload: {
        withCloseButton: false,
        dialogContentStyles,
        sx: {
          '& .MuiDialog-container': {
            alignItems: 'flex-start',
          },
        },
        PaperProps: {
          sx: { mt: '64px', ml: '16px', mr: '16px', borderRadius: '16px' },
        },
      },
    });
  }, [dispatch, t]);

  useEffect(() => {
    if (!tablet) {
      closeModal();
    }
  }, [tablet, closeModal]);

  return (
    <>
      <UserBlock>
        <UserBlockName variant={'label-large'}>Username</UserBlockName>
        <UserBlockAddress variant={'label-large'}>
          {user?.email || shortenedAddress}
        </UserBlockAddress>
      </UserBlock>
      {!authorized && isConnected ? (
        <SignWalletButton
          fullWidth
          onClick={() => {
            authOnServerWithWallet();
          }}
        />
      ) : (
        <>
          {isNumber(balance) && (
            <UserBalance balance={balance} balancePending={balancePending} />
          )}
          <Buttons>
            <Button
              variant={'outlined'}
              onClick={() => {
                dispatch({
                  type: 'setModalContent',
                  payload: {
                    name: 'withdrawDeposit',
                  },
                });
              }}
            >
              {t('HEADER__depositWithdrawButton')}
            </Button>
            <Button
              variant={'outlined'}
              onClick={() => {
                dispatch({
                  type: 'closeModal',
                });
                router.push('/stakings');
              }}
            >
              {t('HEADER__stakingButton')}
            </Button>
          </Buttons>
          <MenuItems>
            <UserMenuItems
              onMenuItemClick={onMenuItemClick}
              onLanguageClick={onLanguageClick}
            />
          </MenuItems>
        </>
      )}
    </>
  );
};

const dialogContentStyles = (theme: Theme) => css`
  padding: 12px;
  background-color: ${theme.getColor('blueZodiac')};
`;

const UserBlock = styled(Paper)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  margin-bottom: 12px;
  background-color: ${({ theme }) => theme.getColor('deepCove')};
`;

const UserBlockName = styled(Text)``;
const UserBlockAddress = styled(Text)``;

const Buttons = styled('div')`
  margin-top: 36px;
  display: flex;
  justify-content: space-between;

  button {
    flex: 0 0 calc(50% - 8px);
  }
`;

const MenuItems = styled(List)`
  margin-top: 16px;
`;

export { MobileMenuModal };
