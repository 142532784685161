import { useEffect, useCallback, useMemo } from 'react';
import { isBoolean } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { useAsyncFn } from 'react-use';
import { useSnackbar } from 'notistack';
import { useSignMessage, useAccount } from 'wagmi';
import {
  useLinkErc20toFireBaseSignatureMutation,
  useLinkErc20toFireBaseAcceptMutation,
  GeneralStatus,
  UserErc20AddressDocument,
} from '@/apollo/operations';
import { sendSentryError } from '@/utils/sentry';

export const useConnectWalletToFirebaseUser = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { address } = useAccount();
  const { signMessageAsync } = useSignMessage();
  const [linkErc20toFireBaseSignature] =
    useLinkErc20toFireBaseSignatureMutation({
      fetchPolicy: 'no-cache',
    });
  const [linkErc20toFireBaseAccept] = useLinkErc20toFireBaseAcceptMutation({
    fetchPolicy: 'no-cache',
    awaitRefetchQueries: true,
    refetchQueries: [{ query: UserErc20AddressDocument }],
  });

  const [{ loading, error }, connectWallet] = useAsyncFn(async () => {
    if (!address) {
      throw new Error('There is no address to connect wallet to firebase user');
    }
    const nonceResponse = await linkErc20toFireBaseSignature({
      variables: {
        address,
      },
    });
    const nonce = nonceResponse.data?.linkERC20toFireBaseSignature?.nonce;
    if (!nonce) {
      throw new Error(
        'There is no nonce in linkErc20toFireBaseSignature response to connect wallet to firebase user'
      );
    }
    const signature = await signMessageAsync({
      message: nonce,
    });
    const acceptResponse = await linkErc20toFireBaseAccept({
      variables: {
        address,
        signature,
      },
    });
    if (
      acceptResponse.data?.linkERC20toFireBaseAccept?.status !==
      GeneralStatus.Ok
    ) {
      throw new Error(
        'Status response in connecting wallet to firebase user is not ok'
      );
    }
    return true;
  }, [address]);

  useEffect(() => {
    if (error) {
      sendSentryError(error);
    }
  }, [error]);

  const connectWalletToFirebaseUser = useCallback(async () => {
    const connected = await connectWallet();
    if (isBoolean(connected) && connected) {
      enqueueSnackbar(t('CONNECT_WALLET_TO_FIREBASE__success'), {
        variant: 'success',
      });
    } else {
      enqueueSnackbar(t('CONNECT_WALLET_TO_FIREBASE__error'), {
        variant: 'error',
      });
    }
  }, [t, enqueueSnackbar, connectWallet]);

  return useMemo(
    () =>
      [
        {
          loading,
          error,
        },
        connectWalletToFirebaseUser,
      ] as const,
    [loading, error, connectWalletToFirebaseUser]
  );
};
