import { useMemo, ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';
import {
  EmailAuthProvider,
  reauthenticateWithCredential,
  updatePassword,
} from 'firebase/auth';
import { Formik, Form as DefForm } from 'formik';
import { TFunction } from 'i18next';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';
import { Button } from '@/components/buttons';
import {
  HorizontalFormControl,
  HorizontalFormControlLabel,
} from '@/components/form-elements';
import { FormikTextField } from '@/components/formik-elements';
import { firebaseAuth } from '@/utils/firebase';
import { TranslationKeys } from '@/utils/i18n';
import { sendSentryError } from '@/utils/sentry';
import { styled } from '@mui/material/styles';

export type ChangePasswordFormProps = ComponentProps<typeof Form>;

const ChangePasswordForm = (props: ChangePasswordFormProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const schema = useMemo(() => getSchema({ t }), [t]);

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={async ({ currentPassword, newPassword }, { resetForm }) => {
        try {
          const user = firebaseAuth.currentUser;

          if (!user || !user.email) {
            throw new Error('No firebase user or his email to change password');
          }

          const tokenResult = await user.getIdTokenResult();

          if (tokenResult?.signInProvider !== 'password') {
            throw new Error('Firebase auth provider is not password');
          }

          const cred = EmailAuthProvider.credential(
            user.email,
            currentPassword
          );

          await reauthenticateWithCredential(user, cred);

          await updatePassword(user, newPassword);

          resetForm();

          enqueueSnackbar(t('CHANGE_PASSWORD_FORM__changeSuccess'), {
            variant: 'success',
          });
        } catch (e) {
          const error =
            e instanceof Error
              ? e
              : new Error(
                  typeof e === 'string' ? e : 'Error on changing password'
                );
          sendSentryError(error);
          enqueueSnackbar(t('CHANGE_PASSWORD_FORM__changeError'), {
            variant: 'error',
          });
        }
      }}
    >
      {({ isSubmitting }) => (
        <Form {...props}>
          {inputs.map(([key, label]) => (
            <HorizontalFormControl key={key}>
              <HorizontalFormControlLabel>
                {t(label)}
              </HorizontalFormControlLabel>
              <Input type={'password'} name={key} />
            </HorizontalFormControl>
          ))}
          <SubmitButton type={'submit'} loading={isSubmitting}>
            {t('CHANGE_PASSWORD_FORM__submitButton')}
          </SubmitButton>
        </Form>
      )}
    </Formik>
  );
};

const inputsMap: ReadonlyMap<keyof FormValues, TranslationKeys> = new Map([
  ['currentPassword', 'CHANGE_PASSWORD_FORM__currentPasswordInputLabel'],
  ['newPassword', 'CHANGE_PASSWORD_FORM__newPasswordInputLabel'],
  ['confirmNewPassword', 'CHANGE_PASSWORD_FORM__newPasswordConfirmInputLabel'],
]);

const inputs = Array.from(inputsMap);

type FormValues = {
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;
};

const initialValues: FormValues = {
  currentPassword: '',
  newPassword: '',
  confirmNewPassword: '',
};

const getSchema = ({ t }: { t: TFunction }): yup.ObjectSchema<FormValues> =>
  yup
    .object({
      currentPassword: yup.string().required(t('FORMS__requiredError')),
      newPassword: yup
        .string()
        .min(8, t('FORMS__minCharsError', { amount: 8 }))
        .required(t('FORMS__requiredError')),
      confirmNewPassword: yup
        .string()
        .oneOf([yup.ref('newPassword')], t('FORMS__passwordsMatchError'))
        .required(t('FORMS__requiredError')),
    })
    .defined();

const Form = styled(DefForm)`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Input = styled(FormikTextField)`
  flex: 1 1 auto;
`;

const SubmitButton = styled(Button)`
  margin-top: 32px;
`;

export { ChangePasswordForm };
