import { useMemo, createContext, useContext, PropsWithChildren } from 'react';
import { useUserBalanceQuery } from '@/apollo/operations';
import { useServerAuth } from '@/providers/ServerAuth';
import { devEnvironment } from '@/utils/consts';

const UserBalance = (props: PropsWithChildren) => {
  const { authorized } = useServerAuth();
  const { data } = useUserBalanceQuery({
    skip: !authorized,
    // Initially called in useInitialUserDataLoad hook
    // fetchPolicy: 'cache-and-network',
    pollInterval: (devEnvironment ? 60 : 10) * 1000,
  });

  const contextValue = useMemo(
    () => ({
      balance: data?.userBalances[0]?.balance ?? null,
      balancePending: data?.userBalances[1]?.balance ?? null,
    }),
    [data]
  );

  return <UserBalanceContext.Provider value={contextValue} {...props} />;
};

const UserBalanceContext = createContext<{
  balance: Nullish<number>;
  balancePending: Nullish<number>;
}>({
  balance: null,
  balancePending: null,
});

export const useUserBalance = () => useContext(UserBalanceContext);

export { UserBalance };
