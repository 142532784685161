import { useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useEditTournamentDataQuery } from '@/apollo/operations';
import { PageSpinner } from '@/components/common-elements';
import { UserVenueAddEditTournamentForm } from '@/components/forms';
import { useModals } from '@/providers';
import { Text } from '../texts';

const UserVenueAddEditTournamentModal = () => {
  const { t } = useTranslation();
  const { dispatch, data: modalData } =
    useModals<'userVenueAddEditTournament'>();

  const venueId = modalData?.venueId;

  const eventId =
    modalData && 'eventId' in modalData ? modalData.eventId : undefined;

  const tournamentId =
    modalData && 'tournamentId' in modalData
      ? modalData.tournamentId
      : undefined;

  const { loading, error, data } = useEditTournamentDataQuery({
    fetchPolicy: 'cache-and-network',
    skip: !tournamentId,
    variables: {
      id: tournamentId,
    },
  });

  useLayoutEffect(() => {
    dispatch({
      type: 'setWrapperProps',
      payload: {
        title: t('USER_VENUE_ADD_EDIT_TOURNAMENT_MODAL__title'),
      },
    });
  }, [t, dispatch]);

  if (loading && !data?.tournament) {
    return <PageSpinner />;
  }

  if (!venueId || error) {
    return (
      <Text align={'center'}>
        {t('USER_VENUE_ADD_EDIT_TOURNAMENT_MODAL__eventLoadError')}
      </Text>
    );
  }

  return (
    <UserVenueAddEditTournamentForm
      venueId={venueId}
      eventId={eventId}
      tournament={data?.tournament}
      onSuccessSubmit={() => {
        dispatch({
          type: 'closeModal',
        });
      }}
    />
  );
};

export { UserVenueAddEditTournamentModal };
