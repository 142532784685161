import { useLayoutEffect, useCallback, useEffect, ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import {
  GoogleIcon, // FacebookIcon,
  UserLoginIcon,
} from '@/components/icons';
import { useFirebaseLogin } from '@/hooks';
import { useModals } from '@/providers';
import { resetListStyles } from '@/styles';
import { TranslationKeys } from '@/utils/i18n';
import { styled } from '@mui/material/styles';
import { Button as DefButton, ConnectWalletButton } from '../buttons';

const LoginModal = () => {
  const { t } = useTranslation();
  const { dispatch } = useModals<'wallets' | 'emailLogin'>();

  const [signInWithGoogle, googleUserCredentials, googleSigningIn] =
    useFirebaseLogin('google');

  const [signInWithFacebook, facebookUserCredentials, facebookSigningIn] =
    useFirebaseLogin('facebook');

  // Close modal if auth was successful
  useEffect(() => {
    if (googleUserCredentials || facebookUserCredentials) {
      dispatch({
        type: 'closeModal',
      });
    }
  }, [googleUserCredentials, facebookUserCredentials, dispatch]);

  useLayoutEffect(() => {
    dispatch({
      type: 'setWrapperProps',
      payload: {
        title: t('LOGIN_MODAL__title'),
      },
    });
  }, [dispatch, t]);

  const onButtonClick = useCallback(
    (type: ButtonType) => {
      switch (type) {
        case ButtonTypes.GOOGLE:
          signInWithGoogle();
          break;
        case ButtonTypes.FACEBOOK:
          signInWithFacebook();
          break;
        case ButtonTypes.WALLET:
          dispatch({
            type: 'setModalContent',
            payload: {
              name: 'wallets',
              data: {
                withServerAuthRequest: true,
              },
            },
          });
          break;
        case ButtonTypes.EMAIL:
          dispatch({
            type: 'setModalContent',
            // @ts-ignore - wrong generic setup, should be refactored so data
            // to be taken from name
            payload: {
              name: 'emailLogin',
            },
          });
          break;
        default:
          throw new Error('No such login type');
      }
    },
    [signInWithGoogle, signInWithFacebook, dispatch]
  );

  return (
    <>
      <ButtonsList>
        {buttons.map(({ type, ...buttonData }) => {
          const { Component, props } =
            'Component' in buttonData
              ? {
                  Component: buttonData.Component,
                  props: {},
                }
              : {
                  Component: Button,
                  props: {
                    startIcon: <buttonData.Icon />,
                    children: t(buttonData.label),
                  },
                };

          return (
            <ButtonsListItem key={type}>
              <Component
                loading={googleSigningIn || facebookSigningIn}
                fullWidth
                onClick={() => {
                  onButtonClick(type);
                }}
                {...props}
              />
            </ButtonsListItem>
          );
        })}
      </ButtonsList>
    </>
  );
};

const ButtonTypes = {
  GOOGLE: 'google',
  FACEBOOK: 'facebook',
  WALLET: 'wallet',
  EMAIL: 'email',
} as const;

const buttons: ReadonlyArray<
  { type: ButtonType } & (
    | {
        Component: typeof ConnectWalletButton;
      }
    | {
        Icon: ComponentType;
        label: TranslationKeys;
      }
  )
> = [
  {
    type: ButtonTypes.GOOGLE,
    Icon: GoogleIcon,
    label: 'LOGIN_MODAL__googleButton',
  },
  // {
  //   type: ButtonTypes.FACEBOOK,
  //   Icon: FacebookIcon,
  //   label: 'LOGIN_MODAL__facebookButton',
  // },
  {
    type: ButtonTypes.WALLET,
    Component: ConnectWalletButton,
  },
  {
    type: ButtonTypes.EMAIL,
    Icon: UserLoginIcon,
    label: 'LOGIN_MODAL__emailButton',
  },
];

type ButtonType = ObjectValues<typeof ButtonTypes>;

const ButtonsList = styled('ul')`
  ${resetListStyles};
  max-width: 310px;
  align-self: center;
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding-bottom: 32px;
`;

const ButtonsListItem = styled('li')``;

const Button = styled(DefButton)``;

export { LoginModal };
