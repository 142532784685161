import { FormControl as DefFormControl } from '@mui/material';
import { styled } from '@mui/material/styles';

export const HorizontalFormControl = styled(DefFormControl)`
  flex-direction: row;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    flex-direction: column;
  }
  & + & {
    margin-top: 32px;
  }
`;
