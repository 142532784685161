import Image from 'next/image';
import { styled } from '@mui/material/styles';

const SearchResultListItemImage = styled(Image)`
  margin-right: 16px;
  border-radius: 8px;
`;

SearchResultListItemImage.defaultProps = {
  width: 30,
  height: 30,
};

export { SearchResultListItemImage };
