import { useState, useEffect, ComponentProps } from 'react';
import { isEmpty } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import {
  EventsByNameDocument,
  EventsByNameQuery,
  EventsByNameQueryVariables,
} from '@/apollo/operations';
import { SearchInput } from '@/components/form-elements';
import {
  SearchResultListItem,
  SearchResultListItemImage,
  SearchResultListItemName,
} from '@/components/styled/search';
import dayjs from '@/utils/dayjs';
import { sendSentryError } from '@/utils/sentry';
import { useApolloClient, ApolloError } from '@apollo/client';
import { styled } from '@mui/material/styles';
import { SearchResultList } from './SearchResultList';
import { SearchWrapper } from './SearchWrapper';

export type EventsSearchProps = ComponentProps<typeof Wrapper>;

const EventsSearch = (props: EventsSearchProps) => {
  const { t } = useTranslation();
  const client = useApolloClient();
  const [loading, setLoading] = useState(false);
  const [eventName, setEventName] = useState('');
  const [result, setResult] = useState<EventsByNameQuery['events']>([]);

  useEffect(() => {
    let mounted = true;
    const t = setTimeout(async () => {
      if (eventName.length < 2) return;
      try {
        setLoading(true);
        setResult([]);
        const { error, data } = await client.query<
          EventsByNameQuery,
          EventsByNameQueryVariables
        >({
          fetchPolicy: 'no-cache',
          query: EventsByNameDocument,
          variables: {
            name: `%${eventName}%`,
          },
        });
        if (!mounted) return;
        if (error) throw error;
        setResult(data.events);
      } catch (e) {
        setResult([]);
        if (e instanceof Error || e instanceof ApolloError) {
          sendSentryError(e);
          return;
        }
        console.log('Error trying find players', e);
      } finally {
        mounted && setLoading(false);
      }
    }, 400);
    return () => {
      clearTimeout(t);
      mounted = false;
    };
  }, [client, eventName]);

  return (
    <Wrapper {...props}>
      <SearchInput
        loading={loading}
        label={t('SEARCH_BAR__eventNameInputLabel')}
        value={eventName}
        onChange={(e) => {
          setEventName(e.target.value);
        }}
      />
      {!isEmpty(result) && (
        <SearchResultList itemsAmount={result.length} columns={2}>
          {result.map(({ id, image, name, dateFrom, dateTill, slug }) => (
            <li key={id}>
              <SearchResultListItem href={`/event/${slug}`}>
                {!!image && (
                  <SearchResultListItemImage src={image} alt={name} />
                )}
                <SearchResultListItemName>
                  {name}
                  {' ('}
                  {dayjs(dateFrom).format('D MMM')}
                  {' - '}
                  {dayjs(dateTill).format('D MMM')}
                  {')'}
                </SearchResultListItemName>
              </SearchResultListItem>
            </li>
          ))}
        </SearchResultList>
      )}
    </Wrapper>
  );
};

const Wrapper = styled(SearchWrapper)``;

export { EventsSearch };
