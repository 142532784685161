import { HTMLAttributes } from 'react';
import { isNumber } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { Text } from '@/components/texts';
import { getPrice } from '@/utils/helpers';

export type UserBalanceProps = {
  balance: number;
  balancePending: Nullish<number>;
} & HTMLAttributes<HTMLDivElement>;

const UserBalance = ({
  balance,
  balancePending,
  ...props
}: UserBalanceProps) => {
  const { t } = useTranslation();

  return (
    <div {...props}>
      <Text variant={'body-small'} color={'spunPearl'}>
        {t('USER_BALANCE__label')}
      </Text>
      <Text variant={'title-large'}>{getPrice(balance)}</Text>
      {isNumber(balancePending) && balancePending > 0 && (
        <Text variant={'label-small'}>
          ({t('USER_BALANCE__pendingLabel')} {getPrice(balancePending)})
        </Text>
      )}
    </div>
  );
};

export { UserBalance };
