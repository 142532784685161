import { ComponentProps, useCallback } from 'react';
import { isObject } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { useSnackbar } from 'notistack';
import {
  UserVenueTournamentDocument,
  StatusGamesEnum,
  PlayerShareAction,
  GeneralStatus,
  TournamentResultStatusesEnum,
  useModifyPlayerShareMutation,
  UserVenueTournamentQuery,
} from '@/apollo/operations';
import { Button as DefButton } from '@/components/buttons';
import { useModals, ModalsData } from '@/providers';
import { resetListStyles } from '@/styles';
import { getPercentages, getFormattedNumber } from '@/utils/helpers';
import { getRoundedNumber } from '@/utils/helpers';
import { TranslationKeys } from '@/utils/i18n';
import { sendSentryError } from '@/utils/sentry';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell as DefTableCell,
  Tooltip,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { ShareDilutionIcon as DefShareDilutionIcon } from '../icons';
import { Text as DefText } from '../texts';
import {TicketsStatusEnum} from "../../apollo/operations";

export type UserVenueTournamentPlayerSharesTableProps = {
  tournament: Tournament;
} & ComponentProps<typeof Wrapper>;

const UserVenueTournamentPlayerSharesTable = ({
  tournament,
  ...props
}: UserVenueTournamentPlayerSharesTableProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { dispatch } = useModals<'confirm' | 'setWinningPlayer'>();

  const showError = (e: Error, msg?: string) => {
    sendSentryError(e);
    enqueueSnackbar(msg || t('USER_VENUE_TOURNAMENT__tournamentStartedError'), {
      variant: 'error',
    });
  };

  const [modifyPlayerShare, { loading: modifyingPlayerShare }] =
    useModifyPlayerShareMutation({
      fetchPolicy: 'no-cache',
      refetchQueries: [
        {
          query: UserVenueTournamentDocument,
          variables: {
            slug: tournament.slug,
          },
        },
      ],
      awaitRefetchQueries: true,
      onCompleted: (data) => {
        if (data.playerShareResult?.status === GeneralStatus.Ok) {
          enqueueSnackbar(
            t(
              'USER_VENUE_TOURNAMENT_PLAYER_SHARES_TABLE__modifyPlayerShareMutationSuccess'
            ),
            {
              variant: 'success',
            }
          );
        } else {
          showError(
            new Error(
              `modifyPlayerShare mutation status not ok; msg: ${data.playerShareResult?.message}`
            ),
            data.playerShareResult?.message
          );
        }
      },
      onError: (e) => {
        showError(e);
      },
    });

  const onButtonPress = useCallback(
    (playerShareId: string, action: PlayerShareAction) => {
      const openModal = (
        data: Omit<ModalsData['confirm'], 'onConfirmPress'>
      ) => {
        dispatch({
          type: 'setModalContent',
          payload: {
            name: 'confirm',
            data: {
              ...data,
              onConfirmPress: () =>
                modifyPlayerShare({
                  variables: {
                    playerShareId,
                    action,
                  },
                }),
            },
          },
        });
      };

      // noinspection JSUnreachableSwitchBranches
      switch (action) {
        case PlayerShareAction.Paid:
          openModal({
            title:
              'USER_VENUE_TOURNAMENT_PLAYER_SHARES_TABLE__paidConfirmModalTitle',
          });
          break;
        case PlayerShareAction.Cancelled:
          openModal({
            title:
              'USER_VENUE_TOURNAMENT_PLAYER_SHARES_TABLE__canceledConfirmModalTitle',
          });
          break;
        case PlayerShareAction.PlayerOut:
          openModal({
            title:
              'USER_VENUE_TOURNAMENT_PLAYER_SHARES_TABLE__playerOutConfirmModalTitle',
          });
          break;
        case PlayerShareAction.BuyBullet:
          openModal({
            title:
              'USER_VENUE_TOURNAMENT_PLAYER_SHARES_TABLE__buyBulletConfirmModalTitle',
          });
          break;
        case PlayerShareAction.Won:
          dispatch({
            type: 'setModalContent',
            payload: {
              name: 'setWinningPlayer',
              data: {
                playerShareId,
              },
            },
          });
          break;
        default:
          throw new Error(`No such PlayerShareAction: ${action}`);
      }
    },
    [dispatch, modifyPlayerShare]
  );

  return (
    <Wrapper {...props}>
      <>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columnLabels.map(([column, label]) => (
                  <TableCell key={column}>{label ? t(label) : null}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {tournament.player_shares.map((playerShare) => {
                if (playerShare.status !== StatusGamesEnum.Cancelled || playerShare.tournament_result)
                return (
                  <TableRow
                    key={playerShare.id}
                    hover
                    role="checkbox"
                    tabIndex={-1}
                  >
                    {columnLabels.map(([column]) => (
                      <TableCell key={column}>
                        {getCellValue({
                          t,
                          loading: modifyingPlayerShare,
                          column,
                          buyIn: tournament.buyIn,
                          tournamentStatus: tournament.status,
                          tournamentCurrency: tournament.local_currency,
                          tournamentCurrencyRate: tournament.currencyRate,
                          playerShare,
                          onButtonPress,
                        })}
                      </TableCell>
                    ))}
                  </TableRow>
                )
              })}
              {tournament.tickets.map((ticket) => {
                if (ticket.status !== TicketsStatusEnum.Cancelled || ticket.tournament_result)
                return (
                  <TableRow
                    key={ticket.id}
                    hover
                    role="checkbox"
                    tabIndex={-1}
                  >
                    {columnLabels.map(([column]) => (
                      <TableCell key={column}>
                        {getCellValue({
                          t,
                          loading: modifyingPlayerShare,
                          column,
                          buyIn: tournament.buyIn,
                          tournamentStatus: tournament.status,
                          tournamentCurrency: tournament.local_currency,
                          tournamentCurrencyRate: tournament.currencyRate,
                          ticket,
                          onButtonPress,
                        })}
                      </TableCell>
                    ))}
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    </Wrapper>
  );
};

type Tournament = UserVenueTournamentQuery['tournaments'][number];
type PlayerShare = Tournament['player_shares'][number];
type Ticket = Tournament['tickets'][number];

const Wrapper = styled('div')``;

const TableCell = styled(DefTableCell)`
  white-space: pre;
`;

const BulletBlock = styled('div')`
  display: flex;
`;
const ShareDilutionIcon = styled(DefShareDilutionIcon)`
  width: 20px;
  height: 20px;
`;

const WinBlock = styled('div')`
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto;
  justify-content: space-between;
  grid-gap: 0 10px;
`;

const ButtonsList = styled('ul')`
  ${resetListStyles};
  display: flex;
  gap: 10px;
`;

const Button = styled(DefButton)`
  width: 112px;
`;

enum Columns {
  PLAYER_NAME,
  TICKET,
  STAKED_PERCENT,
  SOLD_BULLETS,
  // STAKED_USD,
  STAKED_VND,
  BULLET,
  PAYOUT,
  STATUS,
  CONTROLS,
}

const columnLabelsMap: ReadonlyMap<Columns, TranslationKeys | null> = new Map([
  [
    Columns.PLAYER_NAME,
    'USER_VENUE_TOURNAMENT_PLAYER_SHARES_TABLE__playerColumn',
  ],
  [
    Columns.TICKET,
    'USER_VENUE_TOURNAMENT_PLAYER_SHARES_TABLE__ticketColumn',
  ],
  [
    Columns.STAKED_PERCENT,
    'USER_VENUE_TOURNAMENT_PLAYER_SHARES_TABLE__stakedPercentColumn',
  ],
  [
    Columns.SOLD_BULLETS,
    'USER_VENUE_TOURNAMENT_PLAYER_SHARES_TABLE__soldBulletsColumn',
  ],
  // [
  //   Columns.STAKED_USD,
  //   'USER_VENUE_TOURNAMENT_PLAYER_SHARES_TABLE__stakedUSDColumn',
  // ],
  [
    Columns.STAKED_VND,
    'USER_VENUE_TOURNAMENT_PLAYER_SHARES_TABLE__stakedVNDColumn',
  ],
  [Columns.BULLET, 'USER_VENUE_TOURNAMENT_PLAYER_SHARES_TABLE__bulletColumn'],
  [Columns.PAYOUT, 'USER_VENUE_TOURNAMENT_PLAYER_SHARES_TABLE__payoutColumn'],
  [Columns.STATUS, 'USER_VENUE_TOURNAMENT_PLAYER_SHARES_TABLE__statusColumn'],
  [Columns.CONTROLS, null],
]);

const columnLabels = Array.from(columnLabelsMap);

type GetCellValueParams = {
  t: TFunction;
  column: Columns;
  loading: boolean;
  buyIn: number;
  tournamentStatus: StatusGamesEnum;
  tournamentCurrency: Tournament['local_currency'];
  tournamentCurrencyRate: Maybe<number>;
  playerShare?: PlayerShare;
  ticket?: Ticket;
  onButtonPress: (playerShareId: string, action: PlayerShareAction) => void;
};
const getCellValue = ({
  t,
  column,
  loading,
  buyIn,
  tournamentStatus,
  tournamentCurrency,
  tournamentCurrencyRate,
  playerShare,
  ticket,
  onButtonPress,
}: GetCellValueParams) => {
  const tournamentResult = (playerShare ? playerShare.tournament_result : (ticket ? ticket.tournament_result : null));
  if(playerShare && playerShare.tickets.length > 0)
    ticket = playerShare.tickets[0];
  switch (column) {
    case Columns.PLAYER_NAME:
      return  [(playerShare ? 'Share: ' + playerShare.player.name : false), (ticket ? 'Ticket: ' + ticket.playerName : false)].filter(Boolean).join("\n");
    case Columns.TICKET:
      return (ticket ? ticket.bullet : "No");
    case Columns.STAKED_PERCENT:
      if(playerShare) {
        return playerShare.player_shares_bought?.totalSharePercentage
          ? getPercentages(playerShare.player_shares_bought?.totalSharePercentage)
          : tournamentResult?.stakedPercentage
          ? getPercentages(tournamentResult?.stakedPercentage)
          : null;
      } else {
        return null;
      }
    case Columns.SOLD_BULLETS:
      if(playerShare) {
        return (
          <BulletBlock>
            {playerShare.bullets}{' '}
            {playerShare.shareDilution && (
              <Tooltip
                title={t(
                  'USER_VENUE_TOURNAMENT_PLAYER_SHARES_TABLE__shareDilutionLabel'
                )}
              >
                <div>
                  <ShareDilutionIcon/>
                </div>
              </Tooltip>
            )}
          </BulletBlock>
        );
      } else {
        return null;
      }
    // case Columns.STAKED_USD:
    //   return playerShare.player_shares_bought?.totalShareAmount
    //     ? getPrice(playerShare.player_shares_bought?.totalShareAmount)
    //     : null;
    case Columns.STAKED_VND:
      if(playerShare) {
        const totalAmount =
          playerShare.player_shares_bought?.totalShareAmount ??
          tournamentResult?.stakedAmount;
        return totalAmount && tournamentCurrencyRate
          ? `${getFormattedNumber(
            totalAmount * tournamentCurrencyRate,
            tournamentCurrency.decimals,
            tournamentCurrency.rounding
          )} ${tournamentCurrency.symbol}`
          : null;
      } else {
        return null;
      }
    case Columns.BULLET:
      if(playerShare) {
        return tournamentResult
          ? Array.from(Array(tournamentResult.bullets))
            .map((_, index) => index + 1)
            .join('\n') +
          (!tournamentResult?.status ||
          tournamentResult?.status ===
          TournamentResultStatusesEnum.Paid
            ? '\n' + ' '
            : '')
          : '';
      } else {
        return null;
      }
    case Columns.PAYOUT: {
      let toBePaid = '';
      if (tournamentResult) {
        try {
          if (!isObject(tournamentResult.toBePaid)) {
            throw new Error('toBePaid is not an object');
          }
          toBePaid = Object.values(tournamentResult.toBePaid)
            .map(
              (amount) =>
                `${getFormattedNumber(
                  amount,
                  tournamentCurrency.decimals,
                  tournamentCurrency.rounding
                )} ${tournamentCurrency.symbol}`
            )
            .join('\n');
          toBePaid = toBePaid + '\n';
        } catch (e) {
          sendSentryError(e);
          return null;
        }
      }
      if (
        (!tournamentResult?.status ||
          tournamentResult?.status ===
            TournamentResultStatusesEnum.Paid) &&
        tournamentCurrencyRate
      ) {
        const totalAmount =
          playerShare?.player_shares_bought?.totalShareAmount ?? 0;
        const usedBullets = tournamentResult?.bullets ?? 0;
        const priceUSD = (ticket && (ticket.bullet - usedBullets) > 0 ? 0 : (playerShare ?
          (playerShare.bullets - usedBullets > 0
            ? buyIn - totalAmount / playerShare.bullets
            : buyIn) : buyIn)); // TODO
        return (
          toBePaid +
          `(${getFormattedNumber(
            priceUSD * tournamentCurrencyRate,
            tournamentCurrency.decimals,
            tournamentCurrency.rounding
          )} ${tournamentCurrency.symbol})`
        );
      } else {
        return toBePaid;
      }
    }
    case Columns.STATUS:
      return tournamentResult?.status ?? 'Waiting for player';
    case Columns.CONTROLS: {
      const playerShareStatus = tournamentResult?.status;
      const tournamentHasntStarted =
        tournamentStatus === StatusGamesEnum.Active;
      const userPlayerShareWasCanceled =
        playerShareStatus === TournamentResultStatusesEnum.Cancelled;
      const playerIsOut =
        playerShareStatus === TournamentResultStatusesEnum.PlayerOut;
      const id = playerShare?.id ?? ticket?.id ?? 0;

      if (tournamentHasntStarted) {
        return null;
      }

      if (userPlayerShareWasCanceled && tournamentCurrencyRate) {
        const totalAmount = tournamentResult?.stakedAmount ?? 0;
        return (
          <WinBlock>
            <DefText>
              {t(
                'USER_VENUE_TOURNAMENT_PLAYER_SHARES_TABLE__payoutStake3Label'
              )}
              :{' '}
              <DefText component={'span'} color={'brightTurquoise'}>
                {getFormattedNumber(
                  totalAmount * tournamentCurrencyRate,
                  tournamentCurrency.decimals
                )}{' '}
                {tournamentCurrency.symbol}
              </DefText>
            </DefText>
          </WinBlock>
        );
      }
      if (playerIsOut && tournamentCurrencyRate) {
        if(!playerShare)
          return null;
        const usedBullets = tournamentResult?.bullets ?? 0;
        const totalAmount = tournamentResult?.stakedAmount ?? 0;
        const totalBullets = tournamentResult?.bullets ?? 0;
        if (playerShare.bullets <= usedBullets) return null;
        const returnAmount =
          (totalAmount / playerShare.bullets) *
          (playerShare.bullets - totalBullets);
        return (
          <WinBlock>
            <DefText>
              {t(
                'USER_VENUE_TOURNAMENT_PLAYER_SHARES_TABLE__payoutStake3Label'
              )}
              :{' '}
              <DefText component={'span'} color={'brightTurquoise'}>
                {getFormattedNumber(
                  returnAmount * tournamentCurrencyRate,
                  tournamentCurrency.decimals
                )}{' '}
                {tournamentCurrency.symbol}
              </DefText>
            </DefText>
          </WinBlock>
        );
      }

      if (playerShareStatus === TournamentResultStatusesEnum.Won) {
        if (
          tournamentCurrencyRate &&
          tournamentResult!.winning &&
          tournamentResult!.playerPayout
        ) {
          const winning = getRoundedNumber(
            tournamentResult!.winning * tournamentCurrencyRate,
            tournamentCurrency.rounding
          );
          // In case the user bought a ticket, all the winnings go to Stake3
          const playerOut = (ticket ? 0 : getRoundedNumber(
            tournamentResult!.playerPayout *
              tournamentCurrencyRate,
            tournamentCurrency.rounding
          ));
          const remainingBullets = (playerShare ? playerShare.bullets - tournamentResult!.bullets : 0);
          const remainingBulletAmount = (playerShare ? (
            playerShare.player_shares_bought?.totalShareAmount &&
            remainingBullets > 0
              ? getRoundedNumber(
                  (playerShare.player_shares_bought?.totalShareAmount /
                    playerShare.bullets) *
                    remainingBullets *
                    tournamentCurrencyRate,
                  tournamentCurrency.rounding
                )
              : 0)
            : 0);
          return (
            <WinBlock>
              <DefText>
                {t('USER_VENUE_TOURNAMENT_PLAYER_SHARES_TABLE__placeLabel')}:{' '}
                {tournamentResult!.place}
                {'\n'}
                {t(
                  'USER_VENUE_TOURNAMENT_PLAYER_SHARES_TABLE__priceLabel'
                )}:{' '}
                {getFormattedNumber(
                  winning,
                  tournamentCurrency.decimals,
                  tournamentCurrency.rounding
                )}{' '}
                {tournamentCurrency.symbol}
              </DefText>
              <DefText>
                {t('USER_VENUE_TOURNAMENT_PLAYER_SHARES_TABLE__payoutLabel')}:{' '}
                {getFormattedNumber(
                  playerOut,
                  tournamentCurrency.decimals,
                  tournamentCurrency.rounding
                )}{' '}
                {tournamentCurrency.symbol}
                {'\n'}
                {t(
                  'USER_VENUE_TOURNAMENT_PLAYER_SHARES_TABLE__payoutStake3Label'
                )}
                :{' '}
                {getFormattedNumber(
                  winning - playerOut,
                  tournamentCurrency.decimals
                )}{' '}
                {tournamentCurrency.symbol}
                <DefText component={'span'} color={'brightTurquoise'}>
                  {remainingBullets > 0
                    ? '\n + ' +
                      remainingBullets +
                      ' ' +
                      t(
                        'USER_VENUE_TOURNAMENT_PLAYER_SHARES_TABLE__bulletColumn'
                      ) +
                      ' = ' +
                      getFormattedNumber(
                        remainingBulletAmount,
                        tournamentCurrency.decimals
                      ) +
                      ' ' +
                      tournamentCurrency.symbol
                    : ''}
                </DefText>
              </DefText>
            </WinBlock>
          );
        }
      }

      if (!tournamentResult) {
        return (
          <ButtonsList>
            <li>
              <Button
                loading={loading}
                onClick={() =>
                  onButtonPress(id, PlayerShareAction.Paid)
                }
              >
                {((ticket)?t('USER_VENUE_TOURNAMENT_PLAYER_SHARES_TABLE__activateButton') :
                  t('USER_VENUE_TOURNAMENT_PLAYER_SHARES_TABLE__paidButton'))}
              </Button>
            </li>
            <li>
              <Button
                loading={loading}
                onClick={() =>
                  onButtonPress(id, PlayerShareAction.Cancelled)
                }
              >
                {t('USER_VENUE_TOURNAMENT_PLAYER_SHARES_TABLE__cancelButton')}
              </Button>
            </li>
          </ButtonsList>
        );
      }

      if (playerShareStatus === TournamentResultStatusesEnum.Paid) {
        return (
          <ButtonsList>
            <li>
              <Button
                loading={loading}
                onClick={() =>
                  onButtonPress(id, PlayerShareAction.PlayerOut)
                }
              >
                {t('USER_VENUE_TOURNAMENT_PLAYER_SHARES_TABLE__outButton')}
              </Button>
            </li>
            <li>
              <Button
                loading={loading}
                onClick={() =>
                  onButtonPress(id, PlayerShareAction.BuyBullet)
                }
              >
                {t(
                  'USER_VENUE_TOURNAMENT_PLAYER_SHARES_TABLE__buyBulletButton'
                )}
              </Button>
            </li>
            <li>
              <Button
                loading={loading}
                onClick={() =>
                  onButtonPress(id, PlayerShareAction.Won)
                }
              >
                {t('USER_VENUE_TOURNAMENT_PLAYER_SHARES_TABLE__earnButton')}
              </Button>
            </li>
          </ButtonsList>
        );
      }

      return null;
    }
    default:
      throw new Error('No such column');
  }
};

export { UserVenueTournamentPlayerSharesTable };
