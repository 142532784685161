// import followingImg from '@/assets/svgs/menu-following.svg';
import { isString } from 'lodash-es';
import { UserMenuDataQuery, PlayerStatus } from '@/apollo/operations';
import langImg from '@/assets/svgs/menu-globe.svg';
import logoutImg from '@/assets/svgs/menu-logout.svg';
import playerImg from '@/assets/svgs/menu-player.svg';
import settingsImg from '@/assets/svgs/menu-settings.svg';
import sharesImg from '@/assets/svgs/menu-shares.svg';
// import profileImg from '@/assets/svgs/menu-profile.svg';
import playerTicketsImg from '@/assets/svgs/menu-tickets.svg';
import { TranslationKeys } from '@/utils/i18n';

export enum MenuItemTypes {
  // PROFILE,
  BECOMING_PLAYER,
  PLAYER_PROFILE,
  PLAYER_SHARES,
  PLAYER_TICKETS,
  // FOLLOWING,
  SETTINGS,
  VENUE,
  LANGUAGE,
  LOGOUT,
}

export const isProperMenuItem = (
  menuItemName: unknown
): menuItemName is MenuItemTypes => {
  if (!isString(menuItemName)) return false;
  return Object.keys(MenuItemTypes).some(
    (name) => name.toLowerCase() === menuItemName.toLowerCase()
  );
};

export const menuItemsMap: ReadonlyMap<
  MenuItemTypes,
  {
    label: TranslationKeys;
    icon: string;
  }
> = new Map([
  /*[
    MenuItemTypes.PROFILE,
    { label: 'USER_MENU__profileMenuItem', icon: profileImg },
  ],*/
  [
    MenuItemTypes.BECOMING_PLAYER,
    {
      label: 'USER_MENU__becomePlayerMenuItem',
      icon: playerImg,
    },
  ],
  [
    MenuItemTypes.PLAYER_PROFILE,
    {
      label: 'USER_MENU__playerProfileMenuItem',
      icon: playerImg,
    },
  ],
  [
    MenuItemTypes.PLAYER_SHARES,
    {
      label: 'USER_MENU__sharesMenuItem',
      icon: sharesImg,
    },
  ],
  [
    MenuItemTypes.PLAYER_TICKETS,
    {
      label: 'USER_MENU__playerTicketsMenuItem',
      icon: playerTicketsImg,
    },
  ],
  // [
  //   MenuItemTypes.FOLLOWING,
  //   { label: 'USER_MENU__followingMenuItem', icon: followingImg },
  // ],
  [
    MenuItemTypes.SETTINGS,
    { label: 'USER_MENU__settingsMenuItem', icon: settingsImg },
  ],
  [MenuItemTypes.VENUE, { label: 'USER_MENU__venueMenuItem', icon: langImg }],
  [
    MenuItemTypes.LANGUAGE,
    { label: 'USER_MENU__languageMenuItem', icon: langImg },
  ],
  [
    MenuItemTypes.LOGOUT,
    { label: 'USER_MENU__logoutMenuItem', icon: logoutImg },
  ],
]);

export const userMenuItems = Array.from(menuItemsMap).filter(
  ([key]) =>
    ![
      MenuItemTypes.PLAYER_PROFILE,
      MenuItemTypes.PLAYER_SHARES,
      MenuItemTypes.PLAYER_TICKETS,
    ].some((st) => st === key)
);

export const playerMenuItems = Array.from(menuItemsMap).filter(
  ([key]) => ![MenuItemTypes.BECOMING_PLAYER].some((st) => st === key)
);

type User = UserMenuDataQuery['me'];

export const isUserAPlayer = (user: User) =>
  [PlayerStatus.Active, PlayerStatus.Pending].some(
    (s) => s === user?.playerStatus
  );

export const isPlayerProfileAvailable = (user: User) =>
  isUserAPlayer(user) && !!user?.playerSlug;

export const isVenueUser = (user: User) => !!user?.venueId;
