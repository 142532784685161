import {
  GraphQLResolveInfo,
  GraphQLScalarType,
  GraphQLScalarTypeConfig,
} from 'graphql';
import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
import {
  FieldPolicy,
  FieldReadFunction,
  TypePolicies,
  TypePolicy,
} from '@apollo/client/cache';

export type Maybe<T> = T | null | undefined;
export type InputMaybe<T> = T | null | undefined;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
export type RequireFields<T, K extends keyof T> = Omit<T, K> & {
  [P in K]-?: NonNullable<T[P]>;
};
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  bigint: { input: number; output: number };
  date: { input: any; output: any };
  jsonb: { input: any; output: any };
  numeric: { input: number; output: number };
  timestamp: { input: any; output: any };
  timestamptz: { input: any; output: any };
  uuid: { input: any; output: any };
};

/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type BigintComparisonExp = {
  _eq?: InputMaybe<Scalars['bigint']['input']>;
  _gt?: InputMaybe<Scalars['bigint']['input']>;
  _gte?: InputMaybe<Scalars['bigint']['input']>;
  _in?: InputMaybe<Array<Scalars['bigint']['input']>>;
  _isNull?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['bigint']['input']>;
  _lte?: InputMaybe<Scalars['bigint']['input']>;
  _neq?: InputMaybe<Scalars['bigint']['input']>;
  _nin?: InputMaybe<Array<Scalars['bigint']['input']>>;
};

/** columns and relationships of "blockchain_currencies" */
export type BlockchainCurrencies = {
  __typename?: 'BlockchainCurrencies';
  /** An object relationship */
  blockchain: Blockchains;
  blockchainId: Scalars['Int']['output'];
  contract?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['timestamptz']['output'];
  /** An object relationship */
  currency: Currencies;
  currencyId: Scalars['Int']['output'];
  decimals: Scalars['Int']['output'];
  deposit: Scalars['Boolean']['output'];
  icon: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  symbol: Scalars['String']['output'];
  units: Scalars['String']['output'];
  updatedAt: Scalars['timestamptz']['output'];
  value: Scalars['numeric']['output'];
  withdraw: Scalars['Boolean']['output'];
  withdrawFee: Scalars['numeric']['output'];
  withdrawMin?: Maybe<Scalars['numeric']['output']>;
};

/** aggregated selection of "blockchain_currencies" */
export type BlockchainCurrenciesAggregate = {
  __typename?: 'BlockchainCurrenciesAggregate';
  aggregate?: Maybe<BlockchainCurrenciesAggregateFields>;
  nodes: Array<BlockchainCurrencies>;
};

/** aggregate fields of "blockchain_currencies" */
export type BlockchainCurrenciesAggregateFields = {
  __typename?: 'BlockchainCurrenciesAggregateFields';
  avg?: Maybe<BlockchainCurrenciesAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<BlockchainCurrenciesMaxFields>;
  min?: Maybe<BlockchainCurrenciesMinFields>;
  stddev?: Maybe<BlockchainCurrenciesStddevFields>;
  stddevPop?: Maybe<BlockchainCurrenciesStddev_PopFields>;
  stddevSamp?: Maybe<BlockchainCurrenciesStddev_SampFields>;
  sum?: Maybe<BlockchainCurrenciesSumFields>;
  varPop?: Maybe<BlockchainCurrenciesVar_PopFields>;
  varSamp?: Maybe<BlockchainCurrenciesVar_SampFields>;
  variance?: Maybe<BlockchainCurrenciesVarianceFields>;
};

/** aggregate fields of "blockchain_currencies" */
export type BlockchainCurrenciesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<BlockchainCurrenciesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "blockchain_currencies" */
export type BlockchainCurrenciesAggregateOrderBy = {
  avg?: InputMaybe<Blockchain_Currencies_Avg_Order_By>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<Blockchain_Currencies_Max_Order_By>;
  min?: InputMaybe<Blockchain_Currencies_Min_Order_By>;
  stddev?: InputMaybe<Blockchain_Currencies_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Blockchain_Currencies_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Blockchain_Currencies_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Blockchain_Currencies_Sum_Order_By>;
  var_pop?: InputMaybe<Blockchain_Currencies_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Blockchain_Currencies_Var_Samp_Order_By>;
  variance?: InputMaybe<Blockchain_Currencies_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "blockchain_currencies" */
export type BlockchainCurrenciesArrRelInsertInput = {
  data: Array<BlockchainCurrenciesInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<BlockchainCurrenciesOnConflict>;
};

/** aggregate avg on columns */
export type BlockchainCurrenciesAvgFields = {
  __typename?: 'BlockchainCurrenciesAvgFields';
  blockchainId?: Maybe<Scalars['Float']['output']>;
  currencyId?: Maybe<Scalars['Float']['output']>;
  decimals?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
  withdrawFee?: Maybe<Scalars['Float']['output']>;
  withdrawMin?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "blockchain_currencies". All fields are combined with a logical 'AND'. */
export type BlockchainCurrenciesBoolExp = {
  _and?: InputMaybe<Array<BlockchainCurrenciesBoolExp>>;
  _not?: InputMaybe<BlockchainCurrenciesBoolExp>;
  _or?: InputMaybe<Array<BlockchainCurrenciesBoolExp>>;
  blockchain?: InputMaybe<BlockchainsBoolExp>;
  blockchainId?: InputMaybe<IntComparisonExp>;
  contract?: InputMaybe<StringComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  currency?: InputMaybe<CurrenciesBoolExp>;
  currencyId?: InputMaybe<IntComparisonExp>;
  decimals?: InputMaybe<IntComparisonExp>;
  deposit?: InputMaybe<BooleanComparisonExp>;
  icon?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  symbol?: InputMaybe<StringComparisonExp>;
  units?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  value?: InputMaybe<NumericComparisonExp>;
  withdraw?: InputMaybe<BooleanComparisonExp>;
  withdrawFee?: InputMaybe<NumericComparisonExp>;
  withdrawMin?: InputMaybe<NumericComparisonExp>;
};

/** unique or primary key constraints on table "blockchain_currencies" */
export enum BlockchainCurrenciesConstraint {
  /** unique or primary key constraint on columns "id" */
  BlockchainCurrenciesPkey = 'blockchain_currencies_pkey',
}

/** input type for incrementing numeric columns in table "blockchain_currencies" */
export type BlockchainCurrenciesIncInput = {
  blockchainId?: InputMaybe<Scalars['Int']['input']>;
  currencyId?: InputMaybe<Scalars['Int']['input']>;
  decimals?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['numeric']['input']>;
  withdrawFee?: InputMaybe<Scalars['numeric']['input']>;
  withdrawMin?: InputMaybe<Scalars['numeric']['input']>;
};

/** input type for inserting data into table "blockchain_currencies" */
export type BlockchainCurrenciesInsertInput = {
  blockchain?: InputMaybe<BlockchainsObjRelInsertInput>;
  blockchainId?: InputMaybe<Scalars['Int']['input']>;
  contract?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  currency?: InputMaybe<CurrenciesObjRelInsertInput>;
  currencyId?: InputMaybe<Scalars['Int']['input']>;
  decimals?: InputMaybe<Scalars['Int']['input']>;
  deposit?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  symbol?: InputMaybe<Scalars['String']['input']>;
  units?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  value?: InputMaybe<Scalars['numeric']['input']>;
  withdraw?: InputMaybe<Scalars['Boolean']['input']>;
  withdrawFee?: InputMaybe<Scalars['numeric']['input']>;
  withdrawMin?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate max on columns */
export type BlockchainCurrenciesMaxFields = {
  __typename?: 'BlockchainCurrenciesMaxFields';
  blockchainId?: Maybe<Scalars['Int']['output']>;
  contract?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  currencyId?: Maybe<Scalars['Int']['output']>;
  decimals?: Maybe<Scalars['Int']['output']>;
  icon?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  symbol?: Maybe<Scalars['String']['output']>;
  units?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  value?: Maybe<Scalars['numeric']['output']>;
  withdrawFee?: Maybe<Scalars['numeric']['output']>;
  withdrawMin?: Maybe<Scalars['numeric']['output']>;
};

/** aggregate min on columns */
export type BlockchainCurrenciesMinFields = {
  __typename?: 'BlockchainCurrenciesMinFields';
  blockchainId?: Maybe<Scalars['Int']['output']>;
  contract?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  currencyId?: Maybe<Scalars['Int']['output']>;
  decimals?: Maybe<Scalars['Int']['output']>;
  icon?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  symbol?: Maybe<Scalars['String']['output']>;
  units?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  value?: Maybe<Scalars['numeric']['output']>;
  withdrawFee?: Maybe<Scalars['numeric']['output']>;
  withdrawMin?: Maybe<Scalars['numeric']['output']>;
};

/** response of any mutation on the table "blockchain_currencies" */
export type BlockchainCurrenciesMutationResponse = {
  __typename?: 'BlockchainCurrenciesMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<BlockchainCurrencies>;
};

/** input type for inserting object relation for remote table "blockchain_currencies" */
export type BlockchainCurrenciesObjRelInsertInput = {
  data: BlockchainCurrenciesInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<BlockchainCurrenciesOnConflict>;
};

/** on_conflict condition type for table "blockchain_currencies" */
export type BlockchainCurrenciesOnConflict = {
  constraint: BlockchainCurrenciesConstraint;
  update_columns?: Array<BlockchainCurrenciesUpdateColumn>;
  where?: InputMaybe<BlockchainCurrenciesBoolExp>;
};

/** Ordering options when selecting data from "blockchain_currencies". */
export type BlockchainCurrenciesOrderBy = {
  blockchain?: InputMaybe<BlockchainsOrderBy>;
  blockchainId?: InputMaybe<OrderBy>;
  contract?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  currency?: InputMaybe<CurrenciesOrderBy>;
  currencyId?: InputMaybe<OrderBy>;
  decimals?: InputMaybe<OrderBy>;
  deposit?: InputMaybe<OrderBy>;
  icon?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  symbol?: InputMaybe<OrderBy>;
  units?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
  withdraw?: InputMaybe<OrderBy>;
  withdrawFee?: InputMaybe<OrderBy>;
  withdrawMin?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: blockchain_currencies */
export type BlockchainCurrenciesPkColumnsInput = {
  id: Scalars['Int']['input'];
};

/** select columns of table "blockchain_currencies" */
export enum BlockchainCurrenciesSelectColumn {
  /** column name */
  BlockchainId = 'blockchainId',
  /** column name */
  Contract = 'contract',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CurrencyId = 'currencyId',
  /** column name */
  Decimals = 'decimals',
  /** column name */
  Deposit = 'deposit',
  /** column name */
  Icon = 'icon',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Symbol = 'symbol',
  /** column name */
  Units = 'units',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Value = 'value',
  /** column name */
  Withdraw = 'withdraw',
  /** column name */
  WithdrawFee = 'withdrawFee',
  /** column name */
  WithdrawMin = 'withdrawMin',
}

/** input type for updating data in table "blockchain_currencies" */
export type BlockchainCurrenciesSetInput = {
  blockchainId?: InputMaybe<Scalars['Int']['input']>;
  contract?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  currencyId?: InputMaybe<Scalars['Int']['input']>;
  decimals?: InputMaybe<Scalars['Int']['input']>;
  deposit?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  symbol?: InputMaybe<Scalars['String']['input']>;
  units?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  value?: InputMaybe<Scalars['numeric']['input']>;
  withdraw?: InputMaybe<Scalars['Boolean']['input']>;
  withdrawFee?: InputMaybe<Scalars['numeric']['input']>;
  withdrawMin?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate stddev on columns */
export type BlockchainCurrenciesStddevFields = {
  __typename?: 'BlockchainCurrenciesStddevFields';
  blockchainId?: Maybe<Scalars['Float']['output']>;
  currencyId?: Maybe<Scalars['Float']['output']>;
  decimals?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
  withdrawFee?: Maybe<Scalars['Float']['output']>;
  withdrawMin?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type BlockchainCurrenciesStddev_PopFields = {
  __typename?: 'BlockchainCurrenciesStddev_popFields';
  blockchainId?: Maybe<Scalars['Float']['output']>;
  currencyId?: Maybe<Scalars['Float']['output']>;
  decimals?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
  withdrawFee?: Maybe<Scalars['Float']['output']>;
  withdrawMin?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type BlockchainCurrenciesStddev_SampFields = {
  __typename?: 'BlockchainCurrenciesStddev_sampFields';
  blockchainId?: Maybe<Scalars['Float']['output']>;
  currencyId?: Maybe<Scalars['Float']['output']>;
  decimals?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
  withdrawFee?: Maybe<Scalars['Float']['output']>;
  withdrawMin?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "blockchain_currencies" */
export type BlockchainCurrenciesStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: BlockchainCurrenciesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type BlockchainCurrenciesStreamCursorValueInput = {
  blockchainId?: InputMaybe<Scalars['Int']['input']>;
  contract?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  currencyId?: InputMaybe<Scalars['Int']['input']>;
  decimals?: InputMaybe<Scalars['Int']['input']>;
  deposit?: InputMaybe<Scalars['Boolean']['input']>;
  icon?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  symbol?: InputMaybe<Scalars['String']['input']>;
  units?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  value?: InputMaybe<Scalars['numeric']['input']>;
  withdraw?: InputMaybe<Scalars['Boolean']['input']>;
  withdrawFee?: InputMaybe<Scalars['numeric']['input']>;
  withdrawMin?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate sum on columns */
export type BlockchainCurrenciesSumFields = {
  __typename?: 'BlockchainCurrenciesSumFields';
  blockchainId?: Maybe<Scalars['Int']['output']>;
  currencyId?: Maybe<Scalars['Int']['output']>;
  decimals?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['numeric']['output']>;
  withdrawFee?: Maybe<Scalars['numeric']['output']>;
  withdrawMin?: Maybe<Scalars['numeric']['output']>;
};

/** update columns of table "blockchain_currencies" */
export enum BlockchainCurrenciesUpdateColumn {
  /** column name */
  BlockchainId = 'blockchainId',
  /** column name */
  Contract = 'contract',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CurrencyId = 'currencyId',
  /** column name */
  Decimals = 'decimals',
  /** column name */
  Deposit = 'deposit',
  /** column name */
  Icon = 'icon',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Symbol = 'symbol',
  /** column name */
  Units = 'units',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Value = 'value',
  /** column name */
  Withdraw = 'withdraw',
  /** column name */
  WithdrawFee = 'withdrawFee',
  /** column name */
  WithdrawMin = 'withdrawMin',
}

export type BlockchainCurrenciesUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<BlockchainCurrenciesIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<BlockchainCurrenciesSetInput>;
  /** filter the rows which have to be updated */
  where: BlockchainCurrenciesBoolExp;
};

/** aggregate var_pop on columns */
export type BlockchainCurrenciesVar_PopFields = {
  __typename?: 'BlockchainCurrenciesVar_popFields';
  blockchainId?: Maybe<Scalars['Float']['output']>;
  currencyId?: Maybe<Scalars['Float']['output']>;
  decimals?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
  withdrawFee?: Maybe<Scalars['Float']['output']>;
  withdrawMin?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type BlockchainCurrenciesVar_SampFields = {
  __typename?: 'BlockchainCurrenciesVar_sampFields';
  blockchainId?: Maybe<Scalars['Float']['output']>;
  currencyId?: Maybe<Scalars['Float']['output']>;
  decimals?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
  withdrawFee?: Maybe<Scalars['Float']['output']>;
  withdrawMin?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type BlockchainCurrenciesVarianceFields = {
  __typename?: 'BlockchainCurrenciesVarianceFields';
  blockchainId?: Maybe<Scalars['Float']['output']>;
  currencyId?: Maybe<Scalars['Float']['output']>;
  decimals?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
  withdrawFee?: Maybe<Scalars['Float']['output']>;
  withdrawMin?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "blockchains" */
export type Blockchains = {
  __typename?: 'Blockchains';
  active: Scalars['Boolean']['output'];
  alchemy?: Maybe<Scalars['String']['output']>;
  apiKey?: Maybe<Scalars['String']['output']>;
  apiUrl?: Maybe<Scalars['String']['output']>;
  apiUrlProxy?: Maybe<Scalars['String']['output']>;
  /** An aggregate relationship */
  blockchainCurrenciesAggregate: BlockchainCurrenciesAggregate;
  /** An array relationship */
  blockchain_currencies: Array<BlockchainCurrencies>;
  chainId: Scalars['Int']['output'];
  createdAt: Scalars['timestamptz']['output'];
  iconUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  nativeCurrency?: Maybe<Scalars['jsonb']['output']>;
  rpc: Scalars['jsonb']['output'];
  rpcPublic: Scalars['jsonb']['output'];
  scanUrl?: Maybe<Scalars['String']['output']>;
  symbol?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['timestamptz']['output'];
};

/** columns and relationships of "blockchains" */
export type BlockchainsBlockchainCurrenciesAggregateArgs = {
  distinctOn?: InputMaybe<Array<BlockchainCurrenciesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<BlockchainCurrenciesOrderBy>>;
  where?: InputMaybe<BlockchainCurrenciesBoolExp>;
};

/** columns and relationships of "blockchains" */
export type BlockchainsBlockchain_CurrenciesArgs = {
  distinctOn?: InputMaybe<Array<BlockchainCurrenciesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<BlockchainCurrenciesOrderBy>>;
  where?: InputMaybe<BlockchainCurrenciesBoolExp>;
};

/** columns and relationships of "blockchains" */
export type BlockchainsNativeCurrencyArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "blockchains" */
export type BlockchainsRpcArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "blockchains" */
export type BlockchainsRpcPublicArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "blockchains" */
export type BlockchainsAggregate = {
  __typename?: 'BlockchainsAggregate';
  aggregate?: Maybe<BlockchainsAggregateFields>;
  nodes: Array<Blockchains>;
};

/** aggregate fields of "blockchains" */
export type BlockchainsAggregateFields = {
  __typename?: 'BlockchainsAggregateFields';
  avg?: Maybe<BlockchainsAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<BlockchainsMaxFields>;
  min?: Maybe<BlockchainsMinFields>;
  stddev?: Maybe<BlockchainsStddevFields>;
  stddevPop?: Maybe<BlockchainsStddev_PopFields>;
  stddevSamp?: Maybe<BlockchainsStddev_SampFields>;
  sum?: Maybe<BlockchainsSumFields>;
  varPop?: Maybe<BlockchainsVar_PopFields>;
  varSamp?: Maybe<BlockchainsVar_SampFields>;
  variance?: Maybe<BlockchainsVarianceFields>;
};

/** aggregate fields of "blockchains" */
export type BlockchainsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<BlockchainsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type BlockchainsAppendInput = {
  nativeCurrency?: InputMaybe<Scalars['jsonb']['input']>;
  rpc?: InputMaybe<Scalars['jsonb']['input']>;
  rpcPublic?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate avg on columns */
export type BlockchainsAvgFields = {
  __typename?: 'BlockchainsAvgFields';
  chainId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "blockchains". All fields are combined with a logical 'AND'. */
export type BlockchainsBoolExp = {
  _and?: InputMaybe<Array<BlockchainsBoolExp>>;
  _not?: InputMaybe<BlockchainsBoolExp>;
  _or?: InputMaybe<Array<BlockchainsBoolExp>>;
  active?: InputMaybe<BooleanComparisonExp>;
  alchemy?: InputMaybe<StringComparisonExp>;
  apiKey?: InputMaybe<StringComparisonExp>;
  apiUrl?: InputMaybe<StringComparisonExp>;
  apiUrlProxy?: InputMaybe<StringComparisonExp>;
  blockchain_currencies?: InputMaybe<BlockchainCurrenciesBoolExp>;
  blockchain_currencies_aggregate?: InputMaybe<Blockchain_Currencies_Aggregate_Bool_Exp>;
  chainId?: InputMaybe<IntComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  iconUrl?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  nativeCurrency?: InputMaybe<JsonbComparisonExp>;
  rpc?: InputMaybe<JsonbComparisonExp>;
  rpcPublic?: InputMaybe<JsonbComparisonExp>;
  scanUrl?: InputMaybe<StringComparisonExp>;
  symbol?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "blockchains" */
export enum BlockchainsConstraint {
  /** unique or primary key constraint on columns "id" */
  BlockchainsPkey = 'blockchains_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type BlockchainsDeleteAtPathInput = {
  nativeCurrency?: InputMaybe<Array<Scalars['String']['input']>>;
  rpc?: InputMaybe<Array<Scalars['String']['input']>>;
  rpcPublic?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type BlockchainsDeleteElemInput = {
  nativeCurrency?: InputMaybe<Scalars['Int']['input']>;
  rpc?: InputMaybe<Scalars['Int']['input']>;
  rpcPublic?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type BlockchainsDeleteKeyInput = {
  nativeCurrency?: InputMaybe<Scalars['String']['input']>;
  rpc?: InputMaybe<Scalars['String']['input']>;
  rpcPublic?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "blockchains" */
export type BlockchainsIncInput = {
  chainId?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "blockchains" */
export type BlockchainsInsertInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  alchemy?: InputMaybe<Scalars['String']['input']>;
  apiKey?: InputMaybe<Scalars['String']['input']>;
  apiUrl?: InputMaybe<Scalars['String']['input']>;
  apiUrlProxy?: InputMaybe<Scalars['String']['input']>;
  blockchain_currencies?: InputMaybe<BlockchainCurrenciesArrRelInsertInput>;
  chainId?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  iconUrl?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  nativeCurrency?: InputMaybe<Scalars['jsonb']['input']>;
  rpc?: InputMaybe<Scalars['jsonb']['input']>;
  rpcPublic?: InputMaybe<Scalars['jsonb']['input']>;
  scanUrl?: InputMaybe<Scalars['String']['input']>;
  symbol?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type BlockchainsMaxFields = {
  __typename?: 'BlockchainsMaxFields';
  alchemy?: Maybe<Scalars['String']['output']>;
  apiKey?: Maybe<Scalars['String']['output']>;
  apiUrl?: Maybe<Scalars['String']['output']>;
  apiUrlProxy?: Maybe<Scalars['String']['output']>;
  chainId?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  iconUrl?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  scanUrl?: Maybe<Scalars['String']['output']>;
  symbol?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type BlockchainsMinFields = {
  __typename?: 'BlockchainsMinFields';
  alchemy?: Maybe<Scalars['String']['output']>;
  apiKey?: Maybe<Scalars['String']['output']>;
  apiUrl?: Maybe<Scalars['String']['output']>;
  apiUrlProxy?: Maybe<Scalars['String']['output']>;
  chainId?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  iconUrl?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  scanUrl?: Maybe<Scalars['String']['output']>;
  symbol?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "blockchains" */
export type BlockchainsMutationResponse = {
  __typename?: 'BlockchainsMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Blockchains>;
};

/** input type for inserting object relation for remote table "blockchains" */
export type BlockchainsObjRelInsertInput = {
  data: BlockchainsInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<BlockchainsOnConflict>;
};

/** on_conflict condition type for table "blockchains" */
export type BlockchainsOnConflict = {
  constraint: BlockchainsConstraint;
  update_columns?: Array<BlockchainsUpdateColumn>;
  where?: InputMaybe<BlockchainsBoolExp>;
};

/** Ordering options when selecting data from "blockchains". */
export type BlockchainsOrderBy = {
  active?: InputMaybe<OrderBy>;
  alchemy?: InputMaybe<OrderBy>;
  apiKey?: InputMaybe<OrderBy>;
  apiUrl?: InputMaybe<OrderBy>;
  apiUrlProxy?: InputMaybe<OrderBy>;
  blockchain_currenciesAggregate?: InputMaybe<BlockchainCurrenciesAggregateOrderBy>;
  chainId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  iconUrl?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  nativeCurrency?: InputMaybe<OrderBy>;
  rpc?: InputMaybe<OrderBy>;
  rpcPublic?: InputMaybe<OrderBy>;
  scanUrl?: InputMaybe<OrderBy>;
  symbol?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: blockchains */
export type BlockchainsPkColumnsInput = {
  id: Scalars['Int']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type BlockchainsPrependInput = {
  nativeCurrency?: InputMaybe<Scalars['jsonb']['input']>;
  rpc?: InputMaybe<Scalars['jsonb']['input']>;
  rpcPublic?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "blockchains" */
export enum BlockchainsSelectColumn {
  /** column name */
  Active = 'active',
  /** column name */
  Alchemy = 'alchemy',
  /** column name */
  ApiKey = 'apiKey',
  /** column name */
  ApiUrl = 'apiUrl',
  /** column name */
  ApiUrlProxy = 'apiUrlProxy',
  /** column name */
  ChainId = 'chainId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  IconUrl = 'iconUrl',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  NativeCurrency = 'nativeCurrency',
  /** column name */
  Rpc = 'rpc',
  /** column name */
  RpcPublic = 'rpcPublic',
  /** column name */
  ScanUrl = 'scanUrl',
  /** column name */
  Symbol = 'symbol',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** input type for updating data in table "blockchains" */
export type BlockchainsSetInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  alchemy?: InputMaybe<Scalars['String']['input']>;
  apiKey?: InputMaybe<Scalars['String']['input']>;
  apiUrl?: InputMaybe<Scalars['String']['input']>;
  apiUrlProxy?: InputMaybe<Scalars['String']['input']>;
  chainId?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  iconUrl?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  nativeCurrency?: InputMaybe<Scalars['jsonb']['input']>;
  rpc?: InputMaybe<Scalars['jsonb']['input']>;
  rpcPublic?: InputMaybe<Scalars['jsonb']['input']>;
  scanUrl?: InputMaybe<Scalars['String']['input']>;
  symbol?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type BlockchainsStddevFields = {
  __typename?: 'BlockchainsStddevFields';
  chainId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type BlockchainsStddev_PopFields = {
  __typename?: 'BlockchainsStddev_popFields';
  chainId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type BlockchainsStddev_SampFields = {
  __typename?: 'BlockchainsStddev_sampFields';
  chainId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "blockchains" */
export type BlockchainsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: BlockchainsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type BlockchainsStreamCursorValueInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  alchemy?: InputMaybe<Scalars['String']['input']>;
  apiKey?: InputMaybe<Scalars['String']['input']>;
  apiUrl?: InputMaybe<Scalars['String']['input']>;
  apiUrlProxy?: InputMaybe<Scalars['String']['input']>;
  chainId?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  iconUrl?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  nativeCurrency?: InputMaybe<Scalars['jsonb']['input']>;
  rpc?: InputMaybe<Scalars['jsonb']['input']>;
  rpcPublic?: InputMaybe<Scalars['jsonb']['input']>;
  scanUrl?: InputMaybe<Scalars['String']['input']>;
  symbol?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type BlockchainsSumFields = {
  __typename?: 'BlockchainsSumFields';
  chainId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "blockchains" */
export enum BlockchainsUpdateColumn {
  /** column name */
  Active = 'active',
  /** column name */
  Alchemy = 'alchemy',
  /** column name */
  ApiKey = 'apiKey',
  /** column name */
  ApiUrl = 'apiUrl',
  /** column name */
  ApiUrlProxy = 'apiUrlProxy',
  /** column name */
  ChainId = 'chainId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  IconUrl = 'iconUrl',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  NativeCurrency = 'nativeCurrency',
  /** column name */
  Rpc = 'rpc',
  /** column name */
  RpcPublic = 'rpcPublic',
  /** column name */
  ScanUrl = 'scanUrl',
  /** column name */
  Symbol = 'symbol',
  /** column name */
  UpdatedAt = 'updatedAt',
}

export type BlockchainsUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<BlockchainsAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _deleteAtPath?: InputMaybe<BlockchainsDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _deleteElem?: InputMaybe<BlockchainsDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _deleteKey?: InputMaybe<BlockchainsDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<BlockchainsIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<BlockchainsPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<BlockchainsSetInput>;
  /** filter the rows which have to be updated */
  where: BlockchainsBoolExp;
};

/** aggregate var_pop on columns */
export type BlockchainsVar_PopFields = {
  __typename?: 'BlockchainsVar_popFields';
  chainId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type BlockchainsVar_SampFields = {
  __typename?: 'BlockchainsVar_sampFields';
  chainId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type BlockchainsVarianceFields = {
  __typename?: 'BlockchainsVarianceFields';
  chainId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type BooleanComparisonExp = {
  _eq?: InputMaybe<Scalars['Boolean']['input']>;
  _gt?: InputMaybe<Scalars['Boolean']['input']>;
  _gte?: InputMaybe<Scalars['Boolean']['input']>;
  _in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  _isNull?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['Boolean']['input']>;
  _lte?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Scalars['Boolean']['input']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']['input']>>;
};

/** columns and relationships of "countries" */
export type Countries = {
  __typename?: 'Countries';
  createdAt: Scalars['timestamptz']['output'];
  /** An array relationship */
  currencies: Array<Currencies>;
  /** An aggregate relationship */
  currenciesAggregate: CurrenciesAggregate;
  id: Scalars['Int']['output'];
  language: Scalars['String']['output'];
  name: Scalars['String']['output'];
  /** An aggregate relationship */
  paymentMethodBanksAggregate: PaymentMethodBanksAggregate;
  /** An array relationship */
  payment_method_banks: Array<PaymentMethodBanks>;
  updatedAt: Scalars['timestamptz']['output'];
};

/** columns and relationships of "countries" */
export type CountriesCurrenciesArgs = {
  distinctOn?: InputMaybe<Array<CurrenciesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CurrenciesOrderBy>>;
  where?: InputMaybe<CurrenciesBoolExp>;
};

/** columns and relationships of "countries" */
export type CountriesCurrenciesAggregateArgs = {
  distinctOn?: InputMaybe<Array<CurrenciesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CurrenciesOrderBy>>;
  where?: InputMaybe<CurrenciesBoolExp>;
};

/** columns and relationships of "countries" */
export type CountriesPaymentMethodBanksAggregateArgs = {
  distinctOn?: InputMaybe<Array<PaymentMethodBanksSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PaymentMethodBanksOrderBy>>;
  where?: InputMaybe<PaymentMethodBanksBoolExp>;
};

/** columns and relationships of "countries" */
export type CountriesPayment_Method_BanksArgs = {
  distinctOn?: InputMaybe<Array<PaymentMethodBanksSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PaymentMethodBanksOrderBy>>;
  where?: InputMaybe<PaymentMethodBanksBoolExp>;
};

/** aggregated selection of "countries" */
export type CountriesAggregate = {
  __typename?: 'CountriesAggregate';
  aggregate?: Maybe<CountriesAggregateFields>;
  nodes: Array<Countries>;
};

/** aggregate fields of "countries" */
export type CountriesAggregateFields = {
  __typename?: 'CountriesAggregateFields';
  avg?: Maybe<CountriesAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<CountriesMaxFields>;
  min?: Maybe<CountriesMinFields>;
  stddev?: Maybe<CountriesStddevFields>;
  stddevPop?: Maybe<CountriesStddev_PopFields>;
  stddevSamp?: Maybe<CountriesStddev_SampFields>;
  sum?: Maybe<CountriesSumFields>;
  varPop?: Maybe<CountriesVar_PopFields>;
  varSamp?: Maybe<CountriesVar_SampFields>;
  variance?: Maybe<CountriesVarianceFields>;
};

/** aggregate fields of "countries" */
export type CountriesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CountriesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type CountriesAvgFields = {
  __typename?: 'CountriesAvgFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "countries". All fields are combined with a logical 'AND'. */
export type CountriesBoolExp = {
  _and?: InputMaybe<Array<CountriesBoolExp>>;
  _not?: InputMaybe<CountriesBoolExp>;
  _or?: InputMaybe<Array<CountriesBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  currencies?: InputMaybe<CurrenciesBoolExp>;
  currencies_aggregate?: InputMaybe<Currencies_Aggregate_Bool_Exp>;
  id?: InputMaybe<IntComparisonExp>;
  language?: InputMaybe<StringComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  payment_method_banks?: InputMaybe<PaymentMethodBanksBoolExp>;
  payment_method_banks_aggregate?: InputMaybe<Payment_Method_Banks_Aggregate_Bool_Exp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "countries" */
export enum CountriesConstraint {
  /** unique or primary key constraint on columns "id" */
  CountriesPkey = 'countries_pkey',
}

/** input type for incrementing numeric columns in table "countries" */
export type CountriesIncInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "countries" */
export type CountriesInsertInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  currencies?: InputMaybe<CurrenciesArrRelInsertInput>;
  id?: InputMaybe<Scalars['Int']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  payment_method_banks?: InputMaybe<PaymentMethodBanksArrRelInsertInput>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type CountriesMaxFields = {
  __typename?: 'CountriesMaxFields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  language?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type CountriesMinFields = {
  __typename?: 'CountriesMinFields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  language?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "countries" */
export type CountriesMutationResponse = {
  __typename?: 'CountriesMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Countries>;
};

/** input type for inserting object relation for remote table "countries" */
export type CountriesObjRelInsertInput = {
  data: CountriesInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<CountriesOnConflict>;
};

/** on_conflict condition type for table "countries" */
export type CountriesOnConflict = {
  constraint: CountriesConstraint;
  update_columns?: Array<CountriesUpdateColumn>;
  where?: InputMaybe<CountriesBoolExp>;
};

/** Ordering options when selecting data from "countries". */
export type CountriesOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  currenciesAggregate?: InputMaybe<CurrenciesAggregateOrderBy>;
  id?: InputMaybe<OrderBy>;
  language?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  payment_method_banksAggregate?: InputMaybe<PaymentMethodBanksAggregateOrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: countries */
export type CountriesPkColumnsInput = {
  id: Scalars['Int']['input'];
};

/** select columns of table "countries" */
export enum CountriesSelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Language = 'language',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** input type for updating data in table "countries" */
export type CountriesSetInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type CountriesStddevFields = {
  __typename?: 'CountriesStddevFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type CountriesStddev_PopFields = {
  __typename?: 'CountriesStddev_popFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type CountriesStddev_SampFields = {
  __typename?: 'CountriesStddev_sampFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "countries" */
export type CountriesStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: CountriesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type CountriesStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type CountriesSumFields = {
  __typename?: 'CountriesSumFields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "countries" */
export enum CountriesUpdateColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Language = 'language',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt',
}

export type CountriesUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<CountriesIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CountriesSetInput>;
  /** filter the rows which have to be updated */
  where: CountriesBoolExp;
};

/** aggregate var_pop on columns */
export type CountriesVar_PopFields = {
  __typename?: 'CountriesVar_popFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type CountriesVar_SampFields = {
  __typename?: 'CountriesVar_sampFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type CountriesVarianceFields = {
  __typename?: 'CountriesVarianceFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "currencies" */
export type Currencies = {
  __typename?: 'Currencies';
  active: Scalars['Boolean']['output'];
  /** An aggregate relationship */
  blockchainCurrenciesAggregate: BlockchainCurrenciesAggregate;
  /** An array relationship */
  blockchain_currencies: Array<BlockchainCurrencies>;
  /** An object relationship */
  country: Countries;
  countryId: Scalars['Int']['output'];
  createdAt: Scalars['timestamptz']['output'];
  decimals: Scalars['Int']['output'];
  icon: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  rate: Scalars['numeric']['output'];
  rounding: Scalars['Int']['output'];
  symbol: Scalars['String']['output'];
  updatedAt: Scalars['timestamptz']['output'];
};

/** columns and relationships of "currencies" */
export type CurrenciesBlockchainCurrenciesAggregateArgs = {
  distinctOn?: InputMaybe<Array<BlockchainCurrenciesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<BlockchainCurrenciesOrderBy>>;
  where?: InputMaybe<BlockchainCurrenciesBoolExp>;
};

/** columns and relationships of "currencies" */
export type CurrenciesBlockchain_CurrenciesArgs = {
  distinctOn?: InputMaybe<Array<BlockchainCurrenciesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<BlockchainCurrenciesOrderBy>>;
  where?: InputMaybe<BlockchainCurrenciesBoolExp>;
};

/** aggregated selection of "currencies" */
export type CurrenciesAggregate = {
  __typename?: 'CurrenciesAggregate';
  aggregate?: Maybe<CurrenciesAggregateFields>;
  nodes: Array<Currencies>;
};

/** aggregate fields of "currencies" */
export type CurrenciesAggregateFields = {
  __typename?: 'CurrenciesAggregateFields';
  avg?: Maybe<CurrenciesAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<CurrenciesMaxFields>;
  min?: Maybe<CurrenciesMinFields>;
  stddev?: Maybe<CurrenciesStddevFields>;
  stddevPop?: Maybe<CurrenciesStddev_PopFields>;
  stddevSamp?: Maybe<CurrenciesStddev_SampFields>;
  sum?: Maybe<CurrenciesSumFields>;
  varPop?: Maybe<CurrenciesVar_PopFields>;
  varSamp?: Maybe<CurrenciesVar_SampFields>;
  variance?: Maybe<CurrenciesVarianceFields>;
};

/** aggregate fields of "currencies" */
export type CurrenciesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CurrenciesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "currencies" */
export type CurrenciesAggregateOrderBy = {
  avg?: InputMaybe<Currencies_Avg_Order_By>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<Currencies_Max_Order_By>;
  min?: InputMaybe<Currencies_Min_Order_By>;
  stddev?: InputMaybe<Currencies_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Currencies_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Currencies_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Currencies_Sum_Order_By>;
  var_pop?: InputMaybe<Currencies_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Currencies_Var_Samp_Order_By>;
  variance?: InputMaybe<Currencies_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "currencies" */
export type CurrenciesArrRelInsertInput = {
  data: Array<CurrenciesInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<CurrenciesOnConflict>;
};

/** aggregate avg on columns */
export type CurrenciesAvgFields = {
  __typename?: 'CurrenciesAvgFields';
  countryId?: Maybe<Scalars['Float']['output']>;
  decimals?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  rate?: Maybe<Scalars['Float']['output']>;
  rounding?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "currencies". All fields are combined with a logical 'AND'. */
export type CurrenciesBoolExp = {
  _and?: InputMaybe<Array<CurrenciesBoolExp>>;
  _not?: InputMaybe<CurrenciesBoolExp>;
  _or?: InputMaybe<Array<CurrenciesBoolExp>>;
  active?: InputMaybe<BooleanComparisonExp>;
  blockchain_currencies?: InputMaybe<BlockchainCurrenciesBoolExp>;
  blockchain_currencies_aggregate?: InputMaybe<Blockchain_Currencies_Aggregate_Bool_Exp>;
  country?: InputMaybe<CountriesBoolExp>;
  countryId?: InputMaybe<IntComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  decimals?: InputMaybe<IntComparisonExp>;
  icon?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  rate?: InputMaybe<NumericComparisonExp>;
  rounding?: InputMaybe<IntComparisonExp>;
  symbol?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "currencies" */
export enum CurrenciesConstraint {
  /** unique or primary key constraint on columns "id" */
  CurrenciesPkey = 'currencies_pkey',
}

/** input type for incrementing numeric columns in table "currencies" */
export type CurrenciesIncInput = {
  countryId?: InputMaybe<Scalars['Int']['input']>;
  decimals?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  rate?: InputMaybe<Scalars['numeric']['input']>;
  rounding?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "currencies" */
export type CurrenciesInsertInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  blockchain_currencies?: InputMaybe<BlockchainCurrenciesArrRelInsertInput>;
  country?: InputMaybe<CountriesObjRelInsertInput>;
  countryId?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  decimals?: InputMaybe<Scalars['Int']['input']>;
  icon?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  rate?: InputMaybe<Scalars['numeric']['input']>;
  rounding?: InputMaybe<Scalars['Int']['input']>;
  symbol?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type CurrenciesMaxFields = {
  __typename?: 'CurrenciesMaxFields';
  countryId?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  decimals?: Maybe<Scalars['Int']['output']>;
  icon?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  rate?: Maybe<Scalars['numeric']['output']>;
  rounding?: Maybe<Scalars['Int']['output']>;
  symbol?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type CurrenciesMinFields = {
  __typename?: 'CurrenciesMinFields';
  countryId?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  decimals?: Maybe<Scalars['Int']['output']>;
  icon?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  rate?: Maybe<Scalars['numeric']['output']>;
  rounding?: Maybe<Scalars['Int']['output']>;
  symbol?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "currencies" */
export type CurrenciesMutationResponse = {
  __typename?: 'CurrenciesMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Currencies>;
};

/** input type for inserting object relation for remote table "currencies" */
export type CurrenciesObjRelInsertInput = {
  data: CurrenciesInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<CurrenciesOnConflict>;
};

/** on_conflict condition type for table "currencies" */
export type CurrenciesOnConflict = {
  constraint: CurrenciesConstraint;
  update_columns?: Array<CurrenciesUpdateColumn>;
  where?: InputMaybe<CurrenciesBoolExp>;
};

/** Ordering options when selecting data from "currencies". */
export type CurrenciesOrderBy = {
  active?: InputMaybe<OrderBy>;
  blockchain_currenciesAggregate?: InputMaybe<BlockchainCurrenciesAggregateOrderBy>;
  country?: InputMaybe<CountriesOrderBy>;
  countryId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  decimals?: InputMaybe<OrderBy>;
  icon?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  rate?: InputMaybe<OrderBy>;
  rounding?: InputMaybe<OrderBy>;
  symbol?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: currencies */
export type CurrenciesPkColumnsInput = {
  id: Scalars['Int']['input'];
};

/** select columns of table "currencies" */
export enum CurrenciesSelectColumn {
  /** column name */
  Active = 'active',
  /** column name */
  CountryId = 'countryId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Decimals = 'decimals',
  /** column name */
  Icon = 'icon',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Rate = 'rate',
  /** column name */
  Rounding = 'rounding',
  /** column name */
  Symbol = 'symbol',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** input type for updating data in table "currencies" */
export type CurrenciesSetInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  countryId?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  decimals?: InputMaybe<Scalars['Int']['input']>;
  icon?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  rate?: InputMaybe<Scalars['numeric']['input']>;
  rounding?: InputMaybe<Scalars['Int']['input']>;
  symbol?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type CurrenciesStddevFields = {
  __typename?: 'CurrenciesStddevFields';
  countryId?: Maybe<Scalars['Float']['output']>;
  decimals?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  rate?: Maybe<Scalars['Float']['output']>;
  rounding?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type CurrenciesStddev_PopFields = {
  __typename?: 'CurrenciesStddev_popFields';
  countryId?: Maybe<Scalars['Float']['output']>;
  decimals?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  rate?: Maybe<Scalars['Float']['output']>;
  rounding?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type CurrenciesStddev_SampFields = {
  __typename?: 'CurrenciesStddev_sampFields';
  countryId?: Maybe<Scalars['Float']['output']>;
  decimals?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  rate?: Maybe<Scalars['Float']['output']>;
  rounding?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "currencies" */
export type CurrenciesStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: CurrenciesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type CurrenciesStreamCursorValueInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  countryId?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  decimals?: InputMaybe<Scalars['Int']['input']>;
  icon?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  rate?: InputMaybe<Scalars['numeric']['input']>;
  rounding?: InputMaybe<Scalars['Int']['input']>;
  symbol?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type CurrenciesSumFields = {
  __typename?: 'CurrenciesSumFields';
  countryId?: Maybe<Scalars['Int']['output']>;
  decimals?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  rate?: Maybe<Scalars['numeric']['output']>;
  rounding?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "currencies" */
export enum CurrenciesUpdateColumn {
  /** column name */
  Active = 'active',
  /** column name */
  CountryId = 'countryId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Decimals = 'decimals',
  /** column name */
  Icon = 'icon',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Rate = 'rate',
  /** column name */
  Rounding = 'rounding',
  /** column name */
  Symbol = 'symbol',
  /** column name */
  UpdatedAt = 'updatedAt',
}

export type CurrenciesUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<CurrenciesIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CurrenciesSetInput>;
  /** filter the rows which have to be updated */
  where: CurrenciesBoolExp;
};

/** aggregate var_pop on columns */
export type CurrenciesVar_PopFields = {
  __typename?: 'CurrenciesVar_popFields';
  countryId?: Maybe<Scalars['Float']['output']>;
  decimals?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  rate?: Maybe<Scalars['Float']['output']>;
  rounding?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type CurrenciesVar_SampFields = {
  __typename?: 'CurrenciesVar_sampFields';
  countryId?: Maybe<Scalars['Float']['output']>;
  decimals?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  rate?: Maybe<Scalars['Float']['output']>;
  rounding?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type CurrenciesVarianceFields = {
  __typename?: 'CurrenciesVarianceFields';
  countryId?: Maybe<Scalars['Float']['output']>;
  decimals?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  rate?: Maybe<Scalars['Float']['output']>;
  rounding?: Maybe<Scalars['Float']['output']>;
};

/** ordering argument of a cursor */
export enum CursorOrdering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC',
}

/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export type DateComparisonExp = {
  _eq?: InputMaybe<Scalars['date']['input']>;
  _gt?: InputMaybe<Scalars['date']['input']>;
  _gte?: InputMaybe<Scalars['date']['input']>;
  _in?: InputMaybe<Array<Scalars['date']['input']>>;
  _isNull?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['date']['input']>;
  _lte?: InputMaybe<Scalars['date']['input']>;
  _neq?: InputMaybe<Scalars['date']['input']>;
  _nin?: InputMaybe<Array<Scalars['date']['input']>>;
};

export type DepositOutput = {
  __typename?: 'DepositOutput';
  depositAddress?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  status: TransactionStatus;
};

/** columns and relationships of "events" */
export type Events = {
  __typename?: 'Events';
  createdAt: Scalars['timestamptz']['output'];
  dateFrom: Scalars['timestamptz']['output'];
  dateTill: Scalars['timestamptz']['output'];
  description?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  followers: Array<Followers>;
  /** An aggregate relationship */
  followersAggregate: FollowersAggregate;
  followersCount: Scalars['Int']['output'];
  id: Scalars['uuid']['output'];
  image?: Maybe<Scalars['String']['output']>;
  location: Scalars['String']['output'];
  name: Scalars['String']['output'];
  /** An object relationship */
  organiser?: Maybe<Organisers>;
  organiserId?: Maybe<Scalars['uuid']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  sort: Scalars['Int']['output'];
  status: StatusesEnum;
  /** An array relationship */
  tournaments: Array<Tournaments>;
  /** An aggregate relationship */
  tournamentsAggregate: TournamentsAggregate;
  updatedAt: Scalars['timestamptz']['output'];
  /** An object relationship */
  venue: Venues;
  venueId: Scalars['uuid']['output'];
};

/** columns and relationships of "events" */
export type EventsFollowersArgs = {
  distinctOn?: InputMaybe<Array<FollowersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FollowersOrderBy>>;
  where?: InputMaybe<FollowersBoolExp>;
};

/** columns and relationships of "events" */
export type EventsFollowersAggregateArgs = {
  distinctOn?: InputMaybe<Array<FollowersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FollowersOrderBy>>;
  where?: InputMaybe<FollowersBoolExp>;
};

/** columns and relationships of "events" */
export type EventsTournamentsArgs = {
  distinctOn?: InputMaybe<Array<TournamentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TournamentsOrderBy>>;
  where?: InputMaybe<TournamentsBoolExp>;
};

/** columns and relationships of "events" */
export type EventsTournamentsAggregateArgs = {
  distinctOn?: InputMaybe<Array<TournamentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TournamentsOrderBy>>;
  where?: InputMaybe<TournamentsBoolExp>;
};

/** aggregated selection of "events" */
export type EventsAggregate = {
  __typename?: 'EventsAggregate';
  aggregate?: Maybe<EventsAggregateFields>;
  nodes: Array<Events>;
};

/** aggregate fields of "events" */
export type EventsAggregateFields = {
  __typename?: 'EventsAggregateFields';
  avg?: Maybe<EventsAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<EventsMaxFields>;
  min?: Maybe<EventsMinFields>;
  stddev?: Maybe<EventsStddevFields>;
  stddevPop?: Maybe<EventsStddev_PopFields>;
  stddevSamp?: Maybe<EventsStddev_SampFields>;
  sum?: Maybe<EventsSumFields>;
  varPop?: Maybe<EventsVar_PopFields>;
  varSamp?: Maybe<EventsVar_SampFields>;
  variance?: Maybe<EventsVarianceFields>;
};

/** aggregate fields of "events" */
export type EventsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<EventsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "events" */
export type EventsAggregateOrderBy = {
  avg?: InputMaybe<Events_Avg_Order_By>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<Events_Max_Order_By>;
  min?: InputMaybe<Events_Min_Order_By>;
  stddev?: InputMaybe<Events_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Events_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Events_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Events_Sum_Order_By>;
  var_pop?: InputMaybe<Events_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Events_Var_Samp_Order_By>;
  variance?: InputMaybe<Events_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "events" */
export type EventsArrRelInsertInput = {
  data: Array<EventsInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<EventsOnConflict>;
};

/** aggregate avg on columns */
export type EventsAvgFields = {
  __typename?: 'EventsAvgFields';
  followersCount?: Maybe<Scalars['Float']['output']>;
  sort?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "events". All fields are combined with a logical 'AND'. */
export type EventsBoolExp = {
  _and?: InputMaybe<Array<EventsBoolExp>>;
  _not?: InputMaybe<EventsBoolExp>;
  _or?: InputMaybe<Array<EventsBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  dateFrom?: InputMaybe<TimestamptzComparisonExp>;
  dateTill?: InputMaybe<TimestamptzComparisonExp>;
  description?: InputMaybe<StringComparisonExp>;
  followers?: InputMaybe<FollowersBoolExp>;
  followersCount?: InputMaybe<IntComparisonExp>;
  followers_aggregate?: InputMaybe<Followers_Aggregate_Bool_Exp>;
  id?: InputMaybe<UuidComparisonExp>;
  image?: InputMaybe<StringComparisonExp>;
  location?: InputMaybe<StringComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  organiser?: InputMaybe<OrganisersBoolExp>;
  organiserId?: InputMaybe<UuidComparisonExp>;
  slug?: InputMaybe<StringComparisonExp>;
  sort?: InputMaybe<IntComparisonExp>;
  status?: InputMaybe<StatusesEnumComparisonExp>;
  tournaments?: InputMaybe<TournamentsBoolExp>;
  tournaments_aggregate?: InputMaybe<Tournaments_Aggregate_Bool_Exp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  venue?: InputMaybe<VenuesBoolExp>;
  venueId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "events" */
export enum EventsConstraint {
  /** unique or primary key constraint on columns "id" */
  EventsPkey = 'events_pkey',
}

/** input type for incrementing numeric columns in table "events" */
export type EventsIncInput = {
  followersCount?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "events" */
export type EventsInsertInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  dateFrom?: InputMaybe<Scalars['timestamptz']['input']>;
  dateTill?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  followers?: InputMaybe<FollowersArrRelInsertInput>;
  followersCount?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organiser?: InputMaybe<OrganisersObjRelInsertInput>;
  organiserId?: InputMaybe<Scalars['uuid']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<StatusesEnum>;
  tournaments?: InputMaybe<TournamentsArrRelInsertInput>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  venue?: InputMaybe<VenuesObjRelInsertInput>;
  venueId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type EventsMaxFields = {
  __typename?: 'EventsMaxFields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  dateFrom?: Maybe<Scalars['timestamptz']['output']>;
  dateTill?: Maybe<Scalars['timestamptz']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  followersCount?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  image?: Maybe<Scalars['String']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  organiserId?: Maybe<Scalars['uuid']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  sort?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  venueId?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type EventsMinFields = {
  __typename?: 'EventsMinFields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  dateFrom?: Maybe<Scalars['timestamptz']['output']>;
  dateTill?: Maybe<Scalars['timestamptz']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  followersCount?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  image?: Maybe<Scalars['String']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  organiserId?: Maybe<Scalars['uuid']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  sort?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  venueId?: Maybe<Scalars['uuid']['output']>;
};

/** response of any mutation on the table "events" */
export type EventsMutationResponse = {
  __typename?: 'EventsMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Events>;
};

/** input type for inserting object relation for remote table "events" */
export type EventsObjRelInsertInput = {
  data: EventsInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<EventsOnConflict>;
};

/** on_conflict condition type for table "events" */
export type EventsOnConflict = {
  constraint: EventsConstraint;
  update_columns?: Array<EventsUpdateColumn>;
  where?: InputMaybe<EventsBoolExp>;
};

/** Ordering options when selecting data from "events". */
export type EventsOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  dateFrom?: InputMaybe<OrderBy>;
  dateTill?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  followersAggregate?: InputMaybe<FollowersAggregateOrderBy>;
  followersCount?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  image?: InputMaybe<OrderBy>;
  location?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  organiser?: InputMaybe<OrganisersOrderBy>;
  organiserId?: InputMaybe<OrderBy>;
  slug?: InputMaybe<OrderBy>;
  sort?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  tournamentsAggregate?: InputMaybe<TournamentsAggregateOrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  venue?: InputMaybe<VenuesOrderBy>;
  venueId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: events */
export type EventsPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "events" */
export enum EventsSelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DateFrom = 'dateFrom',
  /** column name */
  DateTill = 'dateTill',
  /** column name */
  Description = 'description',
  /** column name */
  FollowersCount = 'followersCount',
  /** column name */
  Id = 'id',
  /** column name */
  Image = 'image',
  /** column name */
  Location = 'location',
  /** column name */
  Name = 'name',
  /** column name */
  OrganiserId = 'organiserId',
  /** column name */
  Slug = 'slug',
  /** column name */
  Sort = 'sort',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  VenueId = 'venueId',
}

/** input type for updating data in table "events" */
export type EventsSetInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  dateFrom?: InputMaybe<Scalars['timestamptz']['input']>;
  dateTill?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  followersCount?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organiserId?: InputMaybe<Scalars['uuid']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<StatusesEnum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  venueId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate stddev on columns */
export type EventsStddevFields = {
  __typename?: 'EventsStddevFields';
  followersCount?: Maybe<Scalars['Float']['output']>;
  sort?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type EventsStddev_PopFields = {
  __typename?: 'EventsStddev_popFields';
  followersCount?: Maybe<Scalars['Float']['output']>;
  sort?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type EventsStddev_SampFields = {
  __typename?: 'EventsStddev_sampFields';
  followersCount?: Maybe<Scalars['Float']['output']>;
  sort?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "events" */
export type EventsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: EventsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type EventsStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  dateFrom?: InputMaybe<Scalars['timestamptz']['input']>;
  dateTill?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  followersCount?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organiserId?: InputMaybe<Scalars['uuid']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<StatusesEnum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  venueId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate sum on columns */
export type EventsSumFields = {
  __typename?: 'EventsSumFields';
  followersCount?: Maybe<Scalars['Int']['output']>;
  sort?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "events" */
export enum EventsUpdateColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DateFrom = 'dateFrom',
  /** column name */
  DateTill = 'dateTill',
  /** column name */
  Description = 'description',
  /** column name */
  FollowersCount = 'followersCount',
  /** column name */
  Id = 'id',
  /** column name */
  Image = 'image',
  /** column name */
  Location = 'location',
  /** column name */
  Name = 'name',
  /** column name */
  OrganiserId = 'organiserId',
  /** column name */
  Slug = 'slug',
  /** column name */
  Sort = 'sort',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  VenueId = 'venueId',
}

export type EventsUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<EventsIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<EventsSetInput>;
  /** filter the rows which have to be updated */
  where: EventsBoolExp;
};

/** aggregate var_pop on columns */
export type EventsVar_PopFields = {
  __typename?: 'EventsVar_popFields';
  followersCount?: Maybe<Scalars['Float']['output']>;
  sort?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type EventsVar_SampFields = {
  __typename?: 'EventsVar_sampFields';
  followersCount?: Maybe<Scalars['Float']['output']>;
  sort?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type EventsVarianceFields = {
  __typename?: 'EventsVarianceFields';
  followersCount?: Maybe<Scalars['Float']['output']>;
  sort?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "followers" */
export type Followers = {
  __typename?: 'Followers';
  createdAt: Scalars['timestamptz']['output'];
  eventId?: Maybe<Scalars['uuid']['output']>;
  id: Scalars['bigint']['output'];
  organiserId?: Maybe<Scalars['uuid']['output']>;
  playerId?: Maybe<Scalars['uuid']['output']>;
  tournamentId?: Maybe<Scalars['uuid']['output']>;
  updatedAt: Scalars['timestamptz']['output'];
  userId: Scalars['String']['output'];
  venueId?: Maybe<Scalars['uuid']['output']>;
};

/** aggregated selection of "followers" */
export type FollowersAggregate = {
  __typename?: 'FollowersAggregate';
  aggregate?: Maybe<FollowersAggregateFields>;
  nodes: Array<Followers>;
};

/** aggregate fields of "followers" */
export type FollowersAggregateFields = {
  __typename?: 'FollowersAggregateFields';
  avg?: Maybe<FollowersAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<FollowersMaxFields>;
  min?: Maybe<FollowersMinFields>;
  stddev?: Maybe<FollowersStddevFields>;
  stddevPop?: Maybe<FollowersStddev_PopFields>;
  stddevSamp?: Maybe<FollowersStddev_SampFields>;
  sum?: Maybe<FollowersSumFields>;
  varPop?: Maybe<FollowersVar_PopFields>;
  varSamp?: Maybe<FollowersVar_SampFields>;
  variance?: Maybe<FollowersVarianceFields>;
};

/** aggregate fields of "followers" */
export type FollowersAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<FollowersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "followers" */
export type FollowersAggregateOrderBy = {
  avg?: InputMaybe<Followers_Avg_Order_By>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<Followers_Max_Order_By>;
  min?: InputMaybe<Followers_Min_Order_By>;
  stddev?: InputMaybe<Followers_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Followers_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Followers_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Followers_Sum_Order_By>;
  var_pop?: InputMaybe<Followers_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Followers_Var_Samp_Order_By>;
  variance?: InputMaybe<Followers_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "followers" */
export type FollowersArrRelInsertInput = {
  data: Array<FollowersInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<FollowersOnConflict>;
};

/** aggregate avg on columns */
export type FollowersAvgFields = {
  __typename?: 'FollowersAvgFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "followers". All fields are combined with a logical 'AND'. */
export type FollowersBoolExp = {
  _and?: InputMaybe<Array<FollowersBoolExp>>;
  _not?: InputMaybe<FollowersBoolExp>;
  _or?: InputMaybe<Array<FollowersBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  eventId?: InputMaybe<UuidComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  organiserId?: InputMaybe<UuidComparisonExp>;
  playerId?: InputMaybe<UuidComparisonExp>;
  tournamentId?: InputMaybe<UuidComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  userId?: InputMaybe<StringComparisonExp>;
  venueId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "followers" */
export enum FollowersConstraint {
  /** unique or primary key constraint on columns "id" */
  FollowersPkey = 'followers_pkey',
}

/** input type for incrementing numeric columns in table "followers" */
export type FollowersIncInput = {
  id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "followers" */
export type FollowersInsertInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  eventId?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  organiserId?: InputMaybe<Scalars['uuid']['input']>;
  playerId?: InputMaybe<Scalars['uuid']['input']>;
  tournamentId?: InputMaybe<Scalars['uuid']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
  venueId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type FollowersMaxFields = {
  __typename?: 'FollowersMaxFields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  eventId?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  organiserId?: Maybe<Scalars['uuid']['output']>;
  playerId?: Maybe<Scalars['uuid']['output']>;
  tournamentId?: Maybe<Scalars['uuid']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
  venueId?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type FollowersMinFields = {
  __typename?: 'FollowersMinFields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  eventId?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  organiserId?: Maybe<Scalars['uuid']['output']>;
  playerId?: Maybe<Scalars['uuid']['output']>;
  tournamentId?: Maybe<Scalars['uuid']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
  venueId?: Maybe<Scalars['uuid']['output']>;
};

/** response of any mutation on the table "followers" */
export type FollowersMutationResponse = {
  __typename?: 'FollowersMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Followers>;
};

/** on_conflict condition type for table "followers" */
export type FollowersOnConflict = {
  constraint: FollowersConstraint;
  update_columns?: Array<FollowersUpdateColumn>;
  where?: InputMaybe<FollowersBoolExp>;
};

/** Ordering options when selecting data from "followers". */
export type FollowersOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  eventId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  organiserId?: InputMaybe<OrderBy>;
  playerId?: InputMaybe<OrderBy>;
  tournamentId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
  venueId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: followers */
export type FollowersPkColumnsInput = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "followers" */
export enum FollowersSelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  EventId = 'eventId',
  /** column name */
  Id = 'id',
  /** column name */
  OrganiserId = 'organiserId',
  /** column name */
  PlayerId = 'playerId',
  /** column name */
  TournamentId = 'tournamentId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId',
  /** column name */
  VenueId = 'venueId',
}

/** input type for updating data in table "followers" */
export type FollowersSetInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  eventId?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  organiserId?: InputMaybe<Scalars['uuid']['input']>;
  playerId?: InputMaybe<Scalars['uuid']['input']>;
  tournamentId?: InputMaybe<Scalars['uuid']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
  venueId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate stddev on columns */
export type FollowersStddevFields = {
  __typename?: 'FollowersStddevFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type FollowersStddev_PopFields = {
  __typename?: 'FollowersStddev_popFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type FollowersStddev_SampFields = {
  __typename?: 'FollowersStddev_sampFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "followers" */
export type FollowersStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: FollowersStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type FollowersStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  eventId?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  organiserId?: InputMaybe<Scalars['uuid']['input']>;
  playerId?: InputMaybe<Scalars['uuid']['input']>;
  tournamentId?: InputMaybe<Scalars['uuid']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
  venueId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate sum on columns */
export type FollowersSumFields = {
  __typename?: 'FollowersSumFields';
  id?: Maybe<Scalars['bigint']['output']>;
};

/** update columns of table "followers" */
export enum FollowersUpdateColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  EventId = 'eventId',
  /** column name */
  Id = 'id',
  /** column name */
  OrganiserId = 'organiserId',
  /** column name */
  PlayerId = 'playerId',
  /** column name */
  TournamentId = 'tournamentId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId',
  /** column name */
  VenueId = 'venueId',
}

export type FollowersUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<FollowersIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<FollowersSetInput>;
  /** filter the rows which have to be updated */
  where: FollowersBoolExp;
};

/** aggregate var_pop on columns */
export type FollowersVar_PopFields = {
  __typename?: 'FollowersVar_popFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type FollowersVar_SampFields = {
  __typename?: 'FollowersVar_sampFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type FollowersVarianceFields = {
  __typename?: 'FollowersVarianceFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type IntComparisonExp = {
  _eq?: InputMaybe<Scalars['Int']['input']>;
  _gt?: InputMaybe<Scalars['Int']['input']>;
  _gte?: InputMaybe<Scalars['Int']['input']>;
  _in?: InputMaybe<Array<Scalars['Int']['input']>>;
  _isNull?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['Int']['input']>;
  _lte?: InputMaybe<Scalars['Int']['input']>;
  _neq?: InputMaybe<Scalars['Int']['input']>;
  _nin?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type JsonbCastExp = {
  String?: InputMaybe<StringComparisonExp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type JsonbComparisonExp = {
  _cast?: InputMaybe<JsonbCastExp>;
  /** is the column contained in the given json value */
  _containedIn?: InputMaybe<Scalars['jsonb']['input']>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars['jsonb']['input']>;
  _eq?: InputMaybe<Scalars['jsonb']['input']>;
  _gt?: InputMaybe<Scalars['jsonb']['input']>;
  _gte?: InputMaybe<Scalars['jsonb']['input']>;
  /** does the string exist as a top-level key in the column */
  _hasKey?: InputMaybe<Scalars['String']['input']>;
  /** do all of these strings exist as top-level keys in the column */
  _hasKeysAll?: InputMaybe<Array<Scalars['String']['input']>>;
  /** do any of these strings exist as top-level keys in the column */
  _hasKeysAny?: InputMaybe<Array<Scalars['String']['input']>>;
  _in?: InputMaybe<Array<Scalars['jsonb']['input']>>;
  _isNull?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['jsonb']['input']>;
  _lte?: InputMaybe<Scalars['jsonb']['input']>;
  _neq?: InputMaybe<Scalars['jsonb']['input']>;
  _nin?: InputMaybe<Array<Scalars['jsonb']['input']>>;
};

/** columns and relationships of "message_types" */
export type MessageTypes = {
  __typename?: 'MessageTypes';
  contentMail?: Maybe<Scalars['jsonb']['output']>;
  contentNotification?: Maybe<Scalars['jsonb']['output']>;
  createdAt: Scalars['timestamptz']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  sendMail: Scalars['Boolean']['output'];
  sendNotification: Scalars['Boolean']['output'];
  subject: Scalars['jsonb']['output'];
  updatedAt: Scalars['timestamptz']['output'];
};

/** columns and relationships of "message_types" */
export type MessageTypesContentMailArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "message_types" */
export type MessageTypesContentNotificationArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "message_types" */
export type MessageTypesSubjectArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "message_types" */
export type MessageTypesAggregate = {
  __typename?: 'MessageTypesAggregate';
  aggregate?: Maybe<MessageTypesAggregateFields>;
  nodes: Array<MessageTypes>;
};

/** aggregate fields of "message_types" */
export type MessageTypesAggregateFields = {
  __typename?: 'MessageTypesAggregateFields';
  avg?: Maybe<MessageTypesAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<MessageTypesMaxFields>;
  min?: Maybe<MessageTypesMinFields>;
  stddev?: Maybe<MessageTypesStddevFields>;
  stddevPop?: Maybe<MessageTypesStddev_PopFields>;
  stddevSamp?: Maybe<MessageTypesStddev_SampFields>;
  sum?: Maybe<MessageTypesSumFields>;
  varPop?: Maybe<MessageTypesVar_PopFields>;
  varSamp?: Maybe<MessageTypesVar_SampFields>;
  variance?: Maybe<MessageTypesVarianceFields>;
};

/** aggregate fields of "message_types" */
export type MessageTypesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<MessageTypesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type MessageTypesAppendInput = {
  contentMail?: InputMaybe<Scalars['jsonb']['input']>;
  contentNotification?: InputMaybe<Scalars['jsonb']['input']>;
  subject?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate avg on columns */
export type MessageTypesAvgFields = {
  __typename?: 'MessageTypesAvgFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "message_types". All fields are combined with a logical 'AND'. */
export type MessageTypesBoolExp = {
  _and?: InputMaybe<Array<MessageTypesBoolExp>>;
  _not?: InputMaybe<MessageTypesBoolExp>;
  _or?: InputMaybe<Array<MessageTypesBoolExp>>;
  contentMail?: InputMaybe<JsonbComparisonExp>;
  contentNotification?: InputMaybe<JsonbComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  sendMail?: InputMaybe<BooleanComparisonExp>;
  sendNotification?: InputMaybe<BooleanComparisonExp>;
  subject?: InputMaybe<JsonbComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "message_types" */
export enum MessageTypesConstraint {
  /** unique or primary key constraint on columns "id" */
  MessageTypesPkey = 'message_types_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type MessageTypesDeleteAtPathInput = {
  contentMail?: InputMaybe<Array<Scalars['String']['input']>>;
  contentNotification?: InputMaybe<Array<Scalars['String']['input']>>;
  subject?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type MessageTypesDeleteElemInput = {
  contentMail?: InputMaybe<Scalars['Int']['input']>;
  contentNotification?: InputMaybe<Scalars['Int']['input']>;
  subject?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type MessageTypesDeleteKeyInput = {
  contentMail?: InputMaybe<Scalars['String']['input']>;
  contentNotification?: InputMaybe<Scalars['String']['input']>;
  subject?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "message_types" */
export type MessageTypesIncInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "message_types" */
export type MessageTypesInsertInput = {
  contentMail?: InputMaybe<Scalars['jsonb']['input']>;
  contentNotification?: InputMaybe<Scalars['jsonb']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  sendMail?: InputMaybe<Scalars['Boolean']['input']>;
  sendNotification?: InputMaybe<Scalars['Boolean']['input']>;
  subject?: InputMaybe<Scalars['jsonb']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type MessageTypesMaxFields = {
  __typename?: 'MessageTypesMaxFields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type MessageTypesMinFields = {
  __typename?: 'MessageTypesMinFields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "message_types" */
export type MessageTypesMutationResponse = {
  __typename?: 'MessageTypesMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<MessageTypes>;
};

/** on_conflict condition type for table "message_types" */
export type MessageTypesOnConflict = {
  constraint: MessageTypesConstraint;
  update_columns?: Array<MessageTypesUpdateColumn>;
  where?: InputMaybe<MessageTypesBoolExp>;
};

/** Ordering options when selecting data from "message_types". */
export type MessageTypesOrderBy = {
  contentMail?: InputMaybe<OrderBy>;
  contentNotification?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  sendMail?: InputMaybe<OrderBy>;
  sendNotification?: InputMaybe<OrderBy>;
  subject?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: message_types */
export type MessageTypesPkColumnsInput = {
  id: Scalars['Int']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type MessageTypesPrependInput = {
  contentMail?: InputMaybe<Scalars['jsonb']['input']>;
  contentNotification?: InputMaybe<Scalars['jsonb']['input']>;
  subject?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "message_types" */
export enum MessageTypesSelectColumn {
  /** column name */
  ContentMail = 'contentMail',
  /** column name */
  ContentNotification = 'contentNotification',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  SendMail = 'sendMail',
  /** column name */
  SendNotification = 'sendNotification',
  /** column name */
  Subject = 'subject',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** input type for updating data in table "message_types" */
export type MessageTypesSetInput = {
  contentMail?: InputMaybe<Scalars['jsonb']['input']>;
  contentNotification?: InputMaybe<Scalars['jsonb']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  sendMail?: InputMaybe<Scalars['Boolean']['input']>;
  sendNotification?: InputMaybe<Scalars['Boolean']['input']>;
  subject?: InputMaybe<Scalars['jsonb']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type MessageTypesStddevFields = {
  __typename?: 'MessageTypesStddevFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type MessageTypesStddev_PopFields = {
  __typename?: 'MessageTypesStddev_popFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type MessageTypesStddev_SampFields = {
  __typename?: 'MessageTypesStddev_sampFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "message_types" */
export type MessageTypesStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: MessageTypesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type MessageTypesStreamCursorValueInput = {
  contentMail?: InputMaybe<Scalars['jsonb']['input']>;
  contentNotification?: InputMaybe<Scalars['jsonb']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  sendMail?: InputMaybe<Scalars['Boolean']['input']>;
  sendNotification?: InputMaybe<Scalars['Boolean']['input']>;
  subject?: InputMaybe<Scalars['jsonb']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type MessageTypesSumFields = {
  __typename?: 'MessageTypesSumFields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "message_types" */
export enum MessageTypesUpdateColumn {
  /** column name */
  ContentMail = 'contentMail',
  /** column name */
  ContentNotification = 'contentNotification',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  SendMail = 'sendMail',
  /** column name */
  SendNotification = 'sendNotification',
  /** column name */
  Subject = 'subject',
  /** column name */
  UpdatedAt = 'updatedAt',
}

export type MessageTypesUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<MessageTypesAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _deleteAtPath?: InputMaybe<MessageTypesDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _deleteElem?: InputMaybe<MessageTypesDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _deleteKey?: InputMaybe<MessageTypesDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<MessageTypesIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<MessageTypesPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<MessageTypesSetInput>;
  /** filter the rows which have to be updated */
  where: MessageTypesBoolExp;
};

/** aggregate var_pop on columns */
export type MessageTypesVar_PopFields = {
  __typename?: 'MessageTypesVar_popFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type MessageTypesVar_SampFields = {
  __typename?: 'MessageTypesVar_sampFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type MessageTypesVarianceFields = {
  __typename?: 'MessageTypesVarianceFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "messages" */
export type Messages = {
  __typename?: 'Messages';
  content: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id: Scalars['bigint']['output'];
  important: Scalars['Boolean']['output'];
  mailSend?: Maybe<Scalars['timestamptz']['output']>;
  messageTypeId?: Maybe<Scalars['Int']['output']>;
  read: Scalars['Boolean']['output'];
  subject: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  userId: Scalars['String']['output'];
  variables?: Maybe<Scalars['jsonb']['output']>;
};

/** columns and relationships of "messages" */
export type MessagesVariablesArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "messages" */
export type MessagesAggregate = {
  __typename?: 'MessagesAggregate';
  aggregate?: Maybe<MessagesAggregateFields>;
  nodes: Array<Messages>;
};

/** aggregate fields of "messages" */
export type MessagesAggregateFields = {
  __typename?: 'MessagesAggregateFields';
  avg?: Maybe<MessagesAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<MessagesMaxFields>;
  min?: Maybe<MessagesMinFields>;
  stddev?: Maybe<MessagesStddevFields>;
  stddevPop?: Maybe<MessagesStddev_PopFields>;
  stddevSamp?: Maybe<MessagesStddev_SampFields>;
  sum?: Maybe<MessagesSumFields>;
  varPop?: Maybe<MessagesVar_PopFields>;
  varSamp?: Maybe<MessagesVar_SampFields>;
  variance?: Maybe<MessagesVarianceFields>;
};

/** aggregate fields of "messages" */
export type MessagesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<MessagesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type MessagesAppendInput = {
  variables?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate avg on columns */
export type MessagesAvgFields = {
  __typename?: 'MessagesAvgFields';
  id?: Maybe<Scalars['Float']['output']>;
  messageTypeId?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "messages". All fields are combined with a logical 'AND'. */
export type MessagesBoolExp = {
  _and?: InputMaybe<Array<MessagesBoolExp>>;
  _not?: InputMaybe<MessagesBoolExp>;
  _or?: InputMaybe<Array<MessagesBoolExp>>;
  content?: InputMaybe<StringComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  important?: InputMaybe<BooleanComparisonExp>;
  mailSend?: InputMaybe<TimestamptzComparisonExp>;
  messageTypeId?: InputMaybe<IntComparisonExp>;
  read?: InputMaybe<BooleanComparisonExp>;
  subject?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  userId?: InputMaybe<StringComparisonExp>;
  variables?: InputMaybe<JsonbComparisonExp>;
};

/** unique or primary key constraints on table "messages" */
export enum MessagesConstraint {
  /** unique or primary key constraint on columns "id" */
  MessagesPkey = 'messages_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type MessagesDeleteAtPathInput = {
  variables?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type MessagesDeleteElemInput = {
  variables?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type MessagesDeleteKeyInput = {
  variables?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "messages" */
export type MessagesIncInput = {
  id?: InputMaybe<Scalars['bigint']['input']>;
  messageTypeId?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "messages" */
export type MessagesInsertInput = {
  content?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  important?: InputMaybe<Scalars['Boolean']['input']>;
  mailSend?: InputMaybe<Scalars['timestamptz']['input']>;
  messageTypeId?: InputMaybe<Scalars['Int']['input']>;
  read?: InputMaybe<Scalars['Boolean']['input']>;
  subject?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
  variables?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate max on columns */
export type MessagesMaxFields = {
  __typename?: 'MessagesMaxFields';
  content?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  mailSend?: Maybe<Scalars['timestamptz']['output']>;
  messageTypeId?: Maybe<Scalars['Int']['output']>;
  subject?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type MessagesMinFields = {
  __typename?: 'MessagesMinFields';
  content?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  mailSend?: Maybe<Scalars['timestamptz']['output']>;
  messageTypeId?: Maybe<Scalars['Int']['output']>;
  subject?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "messages" */
export type MessagesMutationResponse = {
  __typename?: 'MessagesMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Messages>;
};

/** on_conflict condition type for table "messages" */
export type MessagesOnConflict = {
  constraint: MessagesConstraint;
  update_columns?: Array<MessagesUpdateColumn>;
  where?: InputMaybe<MessagesBoolExp>;
};

/** Ordering options when selecting data from "messages". */
export type MessagesOrderBy = {
  content?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  important?: InputMaybe<OrderBy>;
  mailSend?: InputMaybe<OrderBy>;
  messageTypeId?: InputMaybe<OrderBy>;
  read?: InputMaybe<OrderBy>;
  subject?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
  variables?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: messages */
export type MessagesPkColumnsInput = {
  id: Scalars['bigint']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type MessagesPrependInput = {
  variables?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "messages" */
export enum MessagesSelectColumn {
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Important = 'important',
  /** column name */
  MailSend = 'mailSend',
  /** column name */
  MessageTypeId = 'messageTypeId',
  /** column name */
  Read = 'read',
  /** column name */
  Subject = 'subject',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId',
  /** column name */
  Variables = 'variables',
}

/** input type for updating data in table "messages" */
export type MessagesSetInput = {
  content?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  important?: InputMaybe<Scalars['Boolean']['input']>;
  mailSend?: InputMaybe<Scalars['timestamptz']['input']>;
  messageTypeId?: InputMaybe<Scalars['Int']['input']>;
  read?: InputMaybe<Scalars['Boolean']['input']>;
  subject?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
  variables?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate stddev on columns */
export type MessagesStddevFields = {
  __typename?: 'MessagesStddevFields';
  id?: Maybe<Scalars['Float']['output']>;
  messageTypeId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type MessagesStddev_PopFields = {
  __typename?: 'MessagesStddev_popFields';
  id?: Maybe<Scalars['Float']['output']>;
  messageTypeId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type MessagesStddev_SampFields = {
  __typename?: 'MessagesStddev_sampFields';
  id?: Maybe<Scalars['Float']['output']>;
  messageTypeId?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "messages" */
export type MessagesStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: MessagesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type MessagesStreamCursorValueInput = {
  content?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  important?: InputMaybe<Scalars['Boolean']['input']>;
  mailSend?: InputMaybe<Scalars['timestamptz']['input']>;
  messageTypeId?: InputMaybe<Scalars['Int']['input']>;
  read?: InputMaybe<Scalars['Boolean']['input']>;
  subject?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
  variables?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate sum on columns */
export type MessagesSumFields = {
  __typename?: 'MessagesSumFields';
  id?: Maybe<Scalars['bigint']['output']>;
  messageTypeId?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "messages" */
export enum MessagesUpdateColumn {
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Important = 'important',
  /** column name */
  MailSend = 'mailSend',
  /** column name */
  MessageTypeId = 'messageTypeId',
  /** column name */
  Read = 'read',
  /** column name */
  Subject = 'subject',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId',
  /** column name */
  Variables = 'variables',
}

export type MessagesUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<MessagesAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _deleteAtPath?: InputMaybe<MessagesDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _deleteElem?: InputMaybe<MessagesDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _deleteKey?: InputMaybe<MessagesDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<MessagesIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<MessagesPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<MessagesSetInput>;
  /** filter the rows which have to be updated */
  where: MessagesBoolExp;
};

/** aggregate var_pop on columns */
export type MessagesVar_PopFields = {
  __typename?: 'MessagesVar_popFields';
  id?: Maybe<Scalars['Float']['output']>;
  messageTypeId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type MessagesVar_SampFields = {
  __typename?: 'MessagesVar_sampFields';
  id?: Maybe<Scalars['Float']['output']>;
  messageTypeId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type MessagesVarianceFields = {
  __typename?: 'MessagesVarianceFields';
  id?: Maybe<Scalars['Float']['output']>;
  messageTypeId?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type NumericComparisonExp = {
  _eq?: InputMaybe<Scalars['numeric']['input']>;
  _gt?: InputMaybe<Scalars['numeric']['input']>;
  _gte?: InputMaybe<Scalars['numeric']['input']>;
  _in?: InputMaybe<Array<Scalars['numeric']['input']>>;
  _isNull?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['numeric']['input']>;
  _lte?: InputMaybe<Scalars['numeric']['input']>;
  _neq?: InputMaybe<Scalars['numeric']['input']>;
  _nin?: InputMaybe<Array<Scalars['numeric']['input']>>;
};

/** column ordering options */
export enum OrderBy {
  /** in ascending order, nulls last */
  Asc = 'ASC',
  /** in ascending order, nulls first */
  AscNullsFirst = 'ASC_NULLS_FIRST',
  /** in ascending order, nulls last */
  AscNullsLast = 'ASC_NULLS_LAST',
  /** in descending order, nulls first */
  Desc = 'DESC',
  /** in descending order, nulls first */
  DescNullsFirst = 'DESC_NULLS_FIRST',
  /** in descending order, nulls last */
  DescNullsLast = 'DESC_NULLS_LAST',
}

/** columns and relationships of "organiser_users" */
export type OrganiserUsers = {
  __typename?: 'OrganiserUsers';
  createdAt: Scalars['timestamptz']['output'];
  id: Scalars['bigint']['output'];
  organiserId: Scalars['uuid']['output'];
  updatedAt: Scalars['timestamptz']['output'];
  userId: Scalars['String']['output'];
};

/** aggregated selection of "organiser_users" */
export type OrganiserUsersAggregate = {
  __typename?: 'OrganiserUsersAggregate';
  aggregate?: Maybe<OrganiserUsersAggregateFields>;
  nodes: Array<OrganiserUsers>;
};

/** aggregate fields of "organiser_users" */
export type OrganiserUsersAggregateFields = {
  __typename?: 'OrganiserUsersAggregateFields';
  avg?: Maybe<OrganiserUsersAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<OrganiserUsersMaxFields>;
  min?: Maybe<OrganiserUsersMinFields>;
  stddev?: Maybe<OrganiserUsersStddevFields>;
  stddevPop?: Maybe<OrganiserUsersStddev_PopFields>;
  stddevSamp?: Maybe<OrganiserUsersStddev_SampFields>;
  sum?: Maybe<OrganiserUsersSumFields>;
  varPop?: Maybe<OrganiserUsersVar_PopFields>;
  varSamp?: Maybe<OrganiserUsersVar_SampFields>;
  variance?: Maybe<OrganiserUsersVarianceFields>;
};

/** aggregate fields of "organiser_users" */
export type OrganiserUsersAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<OrganiserUsersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type OrganiserUsersAvgFields = {
  __typename?: 'OrganiserUsersAvgFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "organiser_users". All fields are combined with a logical 'AND'. */
export type OrganiserUsersBoolExp = {
  _and?: InputMaybe<Array<OrganiserUsersBoolExp>>;
  _not?: InputMaybe<OrganiserUsersBoolExp>;
  _or?: InputMaybe<Array<OrganiserUsersBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  organiserId?: InputMaybe<UuidComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  userId?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "organiser_users" */
export enum OrganiserUsersConstraint {
  /** unique or primary key constraint on columns "id" */
  OrganiserUsersPkey = 'organiser_users_pkey',
}

/** input type for incrementing numeric columns in table "organiser_users" */
export type OrganiserUsersIncInput = {
  id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "organiser_users" */
export type OrganiserUsersInsertInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  organiserId?: InputMaybe<Scalars['uuid']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type OrganiserUsersMaxFields = {
  __typename?: 'OrganiserUsersMaxFields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  organiserId?: Maybe<Scalars['uuid']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type OrganiserUsersMinFields = {
  __typename?: 'OrganiserUsersMinFields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  organiserId?: Maybe<Scalars['uuid']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "organiser_users" */
export type OrganiserUsersMutationResponse = {
  __typename?: 'OrganiserUsersMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<OrganiserUsers>;
};

/** on_conflict condition type for table "organiser_users" */
export type OrganiserUsersOnConflict = {
  constraint: OrganiserUsersConstraint;
  update_columns?: Array<OrganiserUsersUpdateColumn>;
  where?: InputMaybe<OrganiserUsersBoolExp>;
};

/** Ordering options when selecting data from "organiser_users". */
export type OrganiserUsersOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  organiserId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: organiser_users */
export type OrganiserUsersPkColumnsInput = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "organiser_users" */
export enum OrganiserUsersSelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  OrganiserId = 'organiserId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId',
}

/** input type for updating data in table "organiser_users" */
export type OrganiserUsersSetInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  organiserId?: InputMaybe<Scalars['uuid']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type OrganiserUsersStddevFields = {
  __typename?: 'OrganiserUsersStddevFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type OrganiserUsersStddev_PopFields = {
  __typename?: 'OrganiserUsersStddev_popFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type OrganiserUsersStddev_SampFields = {
  __typename?: 'OrganiserUsersStddev_sampFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "organiser_users" */
export type OrganiserUsersStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: OrganiserUsersStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type OrganiserUsersStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  organiserId?: InputMaybe<Scalars['uuid']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type OrganiserUsersSumFields = {
  __typename?: 'OrganiserUsersSumFields';
  id?: Maybe<Scalars['bigint']['output']>;
};

/** update columns of table "organiser_users" */
export enum OrganiserUsersUpdateColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  OrganiserId = 'organiserId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId',
}

export type OrganiserUsersUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<OrganiserUsersIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<OrganiserUsersSetInput>;
  /** filter the rows which have to be updated */
  where: OrganiserUsersBoolExp;
};

/** aggregate var_pop on columns */
export type OrganiserUsersVar_PopFields = {
  __typename?: 'OrganiserUsersVar_popFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type OrganiserUsersVar_SampFields = {
  __typename?: 'OrganiserUsersVar_sampFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type OrganiserUsersVarianceFields = {
  __typename?: 'OrganiserUsersVarianceFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "organisers" */
export type Organisers = {
  __typename?: 'Organisers';
  createdAt: Scalars['timestamptz']['output'];
  description?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  events: Array<Events>;
  /** An aggregate relationship */
  eventsAggregate: EventsAggregate;
  facebook?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  followers: Array<Followers>;
  /** An aggregate relationship */
  followersAggregate: FollowersAggregate;
  followersCount: Scalars['Int']['output'];
  id: Scalars['uuid']['output'];
  image?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  status: Scalars['String']['output'];
  twitter?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['timestamptz']['output'];
  website?: Maybe<Scalars['String']['output']>;
};

/** columns and relationships of "organisers" */
export type OrganisersEventsArgs = {
  distinctOn?: InputMaybe<Array<EventsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EventsOrderBy>>;
  where?: InputMaybe<EventsBoolExp>;
};

/** columns and relationships of "organisers" */
export type OrganisersEventsAggregateArgs = {
  distinctOn?: InputMaybe<Array<EventsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EventsOrderBy>>;
  where?: InputMaybe<EventsBoolExp>;
};

/** columns and relationships of "organisers" */
export type OrganisersFollowersArgs = {
  distinctOn?: InputMaybe<Array<FollowersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FollowersOrderBy>>;
  where?: InputMaybe<FollowersBoolExp>;
};

/** columns and relationships of "organisers" */
export type OrganisersFollowersAggregateArgs = {
  distinctOn?: InputMaybe<Array<FollowersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FollowersOrderBy>>;
  where?: InputMaybe<FollowersBoolExp>;
};

/** aggregated selection of "organisers" */
export type OrganisersAggregate = {
  __typename?: 'OrganisersAggregate';
  aggregate?: Maybe<OrganisersAggregateFields>;
  nodes: Array<Organisers>;
};

/** aggregate fields of "organisers" */
export type OrganisersAggregateFields = {
  __typename?: 'OrganisersAggregateFields';
  avg?: Maybe<OrganisersAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<OrganisersMaxFields>;
  min?: Maybe<OrganisersMinFields>;
  stddev?: Maybe<OrganisersStddevFields>;
  stddevPop?: Maybe<OrganisersStddev_PopFields>;
  stddevSamp?: Maybe<OrganisersStddev_SampFields>;
  sum?: Maybe<OrganisersSumFields>;
  varPop?: Maybe<OrganisersVar_PopFields>;
  varSamp?: Maybe<OrganisersVar_SampFields>;
  variance?: Maybe<OrganisersVarianceFields>;
};

/** aggregate fields of "organisers" */
export type OrganisersAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<OrganisersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type OrganisersAvgFields = {
  __typename?: 'OrganisersAvgFields';
  followersCount?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "organisers". All fields are combined with a logical 'AND'. */
export type OrganisersBoolExp = {
  _and?: InputMaybe<Array<OrganisersBoolExp>>;
  _not?: InputMaybe<OrganisersBoolExp>;
  _or?: InputMaybe<Array<OrganisersBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  description?: InputMaybe<StringComparisonExp>;
  events?: InputMaybe<EventsBoolExp>;
  events_aggregate?: InputMaybe<Events_Aggregate_Bool_Exp>;
  facebook?: InputMaybe<StringComparisonExp>;
  followers?: InputMaybe<FollowersBoolExp>;
  followersCount?: InputMaybe<IntComparisonExp>;
  followers_aggregate?: InputMaybe<Followers_Aggregate_Bool_Exp>;
  id?: InputMaybe<UuidComparisonExp>;
  image?: InputMaybe<StringComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  status?: InputMaybe<StringComparisonExp>;
  twitter?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  website?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "organisers" */
export enum OrganisersConstraint {
  /** unique or primary key constraint on columns "id" */
  OrganisersPkey = 'organisers_pkey',
}

/** input type for incrementing numeric columns in table "organisers" */
export type OrganisersIncInput = {
  followersCount?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "organisers" */
export type OrganisersInsertInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  events?: InputMaybe<EventsArrRelInsertInput>;
  facebook?: InputMaybe<Scalars['String']['input']>;
  followers?: InputMaybe<FollowersArrRelInsertInput>;
  followersCount?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  twitter?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type OrganisersMaxFields = {
  __typename?: 'OrganisersMaxFields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  facebook?: Maybe<Scalars['String']['output']>;
  followersCount?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  image?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  twitter?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  website?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type OrganisersMinFields = {
  __typename?: 'OrganisersMinFields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  facebook?: Maybe<Scalars['String']['output']>;
  followersCount?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  image?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  twitter?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  website?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "organisers" */
export type OrganisersMutationResponse = {
  __typename?: 'OrganisersMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Organisers>;
};

/** input type for inserting object relation for remote table "organisers" */
export type OrganisersObjRelInsertInput = {
  data: OrganisersInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<OrganisersOnConflict>;
};

/** on_conflict condition type for table "organisers" */
export type OrganisersOnConflict = {
  constraint: OrganisersConstraint;
  update_columns?: Array<OrganisersUpdateColumn>;
  where?: InputMaybe<OrganisersBoolExp>;
};

/** Ordering options when selecting data from "organisers". */
export type OrganisersOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  eventsAggregate?: InputMaybe<EventsAggregateOrderBy>;
  facebook?: InputMaybe<OrderBy>;
  followersAggregate?: InputMaybe<FollowersAggregateOrderBy>;
  followersCount?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  image?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  twitter?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  website?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: organisers */
export type OrganisersPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "organisers" */
export enum OrganisersSelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Facebook = 'facebook',
  /** column name */
  FollowersCount = 'followersCount',
  /** column name */
  Id = 'id',
  /** column name */
  Image = 'image',
  /** column name */
  Name = 'name',
  /** column name */
  Status = 'status',
  /** column name */
  Twitter = 'twitter',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Website = 'website',
}

/** input type for updating data in table "organisers" */
export type OrganisersSetInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  facebook?: InputMaybe<Scalars['String']['input']>;
  followersCount?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  twitter?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type OrganisersStddevFields = {
  __typename?: 'OrganisersStddevFields';
  followersCount?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type OrganisersStddev_PopFields = {
  __typename?: 'OrganisersStddev_popFields';
  followersCount?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type OrganisersStddev_SampFields = {
  __typename?: 'OrganisersStddev_sampFields';
  followersCount?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "organisers" */
export type OrganisersStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: OrganisersStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type OrganisersStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  facebook?: InputMaybe<Scalars['String']['input']>;
  followersCount?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  twitter?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type OrganisersSumFields = {
  __typename?: 'OrganisersSumFields';
  followersCount?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "organisers" */
export enum OrganisersUpdateColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Facebook = 'facebook',
  /** column name */
  FollowersCount = 'followersCount',
  /** column name */
  Id = 'id',
  /** column name */
  Image = 'image',
  /** column name */
  Name = 'name',
  /** column name */
  Status = 'status',
  /** column name */
  Twitter = 'twitter',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Website = 'website',
}

export type OrganisersUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<OrganisersIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<OrganisersSetInput>;
  /** filter the rows which have to be updated */
  where: OrganisersBoolExp;
};

/** aggregate var_pop on columns */
export type OrganisersVar_PopFields = {
  __typename?: 'OrganisersVar_popFields';
  followersCount?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type OrganisersVar_SampFields = {
  __typename?: 'OrganisersVar_sampFields';
  followersCount?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type OrganisersVarianceFields = {
  __typename?: 'OrganisersVarianceFields';
  followersCount?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "payment_method_banks" */
export type PaymentMethodBanks = {
  __typename?: 'PaymentMethodBanks';
  country: Scalars['String']['output'];
  /** An object relationship */
  countryByCountryId: Countries;
  countryId: Scalars['Int']['output'];
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  currency: Currencies;
  currencyId: Scalars['Int']['output'];
  deposit: Scalars['Boolean']['output'];
  depositCurrency: Scalars['String']['output'];
  depositDetails: Scalars['String']['output'];
  depositExchangeRate: Scalars['numeric']['output'];
  depositFee: Scalars['numeric']['output'];
  depositMax: Scalars['numeric']['output'];
  depositMin: Scalars['numeric']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  paymentMethodId: Scalars['Int']['output'];
  /** An object relationship */
  payment_method: PaymentMethods;
  telegramAdmins?: Maybe<Scalars['String']['output']>;
  telegramGroup?: Maybe<Scalars['bigint']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  withdraw: Scalars['Boolean']['output'];
  withdrawDetails: Scalars['String']['output'];
  withdrawExchangeRate: Scalars['numeric']['output'];
  withdrawFee: Scalars['numeric']['output'];
  withdrawMax: Scalars['numeric']['output'];
  withdrawMin: Scalars['numeric']['output'];
};

/** aggregated selection of "payment_method_banks" */
export type PaymentMethodBanksAggregate = {
  __typename?: 'PaymentMethodBanksAggregate';
  aggregate?: Maybe<PaymentMethodBanksAggregateFields>;
  nodes: Array<PaymentMethodBanks>;
};

/** aggregate fields of "payment_method_banks" */
export type PaymentMethodBanksAggregateFields = {
  __typename?: 'PaymentMethodBanksAggregateFields';
  avg?: Maybe<PaymentMethodBanksAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<PaymentMethodBanksMaxFields>;
  min?: Maybe<PaymentMethodBanksMinFields>;
  stddev?: Maybe<PaymentMethodBanksStddevFields>;
  stddevPop?: Maybe<PaymentMethodBanksStddev_PopFields>;
  stddevSamp?: Maybe<PaymentMethodBanksStddev_SampFields>;
  sum?: Maybe<PaymentMethodBanksSumFields>;
  varPop?: Maybe<PaymentMethodBanksVar_PopFields>;
  varSamp?: Maybe<PaymentMethodBanksVar_SampFields>;
  variance?: Maybe<PaymentMethodBanksVarianceFields>;
};

/** aggregate fields of "payment_method_banks" */
export type PaymentMethodBanksAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<PaymentMethodBanksSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "payment_method_banks" */
export type PaymentMethodBanksAggregateOrderBy = {
  avg?: InputMaybe<Payment_Method_Banks_Avg_Order_By>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<Payment_Method_Banks_Max_Order_By>;
  min?: InputMaybe<Payment_Method_Banks_Min_Order_By>;
  stddev?: InputMaybe<Payment_Method_Banks_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Payment_Method_Banks_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Payment_Method_Banks_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Payment_Method_Banks_Sum_Order_By>;
  var_pop?: InputMaybe<Payment_Method_Banks_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Payment_Method_Banks_Var_Samp_Order_By>;
  variance?: InputMaybe<Payment_Method_Banks_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "payment_method_banks" */
export type PaymentMethodBanksArrRelInsertInput = {
  data: Array<PaymentMethodBanksInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<PaymentMethodBanksOnConflict>;
};

/** aggregate avg on columns */
export type PaymentMethodBanksAvgFields = {
  __typename?: 'PaymentMethodBanksAvgFields';
  countryId?: Maybe<Scalars['Float']['output']>;
  currencyId?: Maybe<Scalars['Float']['output']>;
  depositExchangeRate?: Maybe<Scalars['Float']['output']>;
  depositFee?: Maybe<Scalars['Float']['output']>;
  depositMax?: Maybe<Scalars['Float']['output']>;
  depositMin?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  paymentMethodId?: Maybe<Scalars['Float']['output']>;
  telegramGroup?: Maybe<Scalars['Float']['output']>;
  withdrawExchangeRate?: Maybe<Scalars['Float']['output']>;
  withdrawFee?: Maybe<Scalars['Float']['output']>;
  withdrawMax?: Maybe<Scalars['Float']['output']>;
  withdrawMin?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "payment_method_banks". All fields are combined with a logical 'AND'. */
export type PaymentMethodBanksBoolExp = {
  _and?: InputMaybe<Array<PaymentMethodBanksBoolExp>>;
  _not?: InputMaybe<PaymentMethodBanksBoolExp>;
  _or?: InputMaybe<Array<PaymentMethodBanksBoolExp>>;
  country?: InputMaybe<StringComparisonExp>;
  countryByCountryId?: InputMaybe<CountriesBoolExp>;
  countryId?: InputMaybe<IntComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  currency?: InputMaybe<CurrenciesBoolExp>;
  currencyId?: InputMaybe<IntComparisonExp>;
  deposit?: InputMaybe<BooleanComparisonExp>;
  depositCurrency?: InputMaybe<StringComparisonExp>;
  depositDetails?: InputMaybe<StringComparisonExp>;
  depositExchangeRate?: InputMaybe<NumericComparisonExp>;
  depositFee?: InputMaybe<NumericComparisonExp>;
  depositMax?: InputMaybe<NumericComparisonExp>;
  depositMin?: InputMaybe<NumericComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  paymentMethodId?: InputMaybe<IntComparisonExp>;
  payment_method?: InputMaybe<PaymentMethodsBoolExp>;
  telegramAdmins?: InputMaybe<StringComparisonExp>;
  telegramGroup?: InputMaybe<BigintComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  withdraw?: InputMaybe<BooleanComparisonExp>;
  withdrawDetails?: InputMaybe<StringComparisonExp>;
  withdrawExchangeRate?: InputMaybe<NumericComparisonExp>;
  withdrawFee?: InputMaybe<NumericComparisonExp>;
  withdrawMax?: InputMaybe<NumericComparisonExp>;
  withdrawMin?: InputMaybe<NumericComparisonExp>;
};

/** unique or primary key constraints on table "payment_method_banks" */
export enum PaymentMethodBanksConstraint {
  /** unique or primary key constraint on columns "id" */
  PaymentMethodBankPkey = 'payment_method_bank_pkey',
}

/** input type for incrementing numeric columns in table "payment_method_banks" */
export type PaymentMethodBanksIncInput = {
  countryId?: InputMaybe<Scalars['Int']['input']>;
  currencyId?: InputMaybe<Scalars['Int']['input']>;
  depositExchangeRate?: InputMaybe<Scalars['numeric']['input']>;
  depositFee?: InputMaybe<Scalars['numeric']['input']>;
  depositMax?: InputMaybe<Scalars['numeric']['input']>;
  depositMin?: InputMaybe<Scalars['numeric']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  paymentMethodId?: InputMaybe<Scalars['Int']['input']>;
  telegramGroup?: InputMaybe<Scalars['bigint']['input']>;
  withdrawExchangeRate?: InputMaybe<Scalars['numeric']['input']>;
  withdrawFee?: InputMaybe<Scalars['numeric']['input']>;
  withdrawMax?: InputMaybe<Scalars['numeric']['input']>;
  withdrawMin?: InputMaybe<Scalars['numeric']['input']>;
};

/** input type for inserting data into table "payment_method_banks" */
export type PaymentMethodBanksInsertInput = {
  country?: InputMaybe<Scalars['String']['input']>;
  countryByCountryId?: InputMaybe<CountriesObjRelInsertInput>;
  countryId?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  currency?: InputMaybe<CurrenciesObjRelInsertInput>;
  currencyId?: InputMaybe<Scalars['Int']['input']>;
  deposit?: InputMaybe<Scalars['Boolean']['input']>;
  depositCurrency?: InputMaybe<Scalars['String']['input']>;
  depositDetails?: InputMaybe<Scalars['String']['input']>;
  depositExchangeRate?: InputMaybe<Scalars['numeric']['input']>;
  depositFee?: InputMaybe<Scalars['numeric']['input']>;
  depositMax?: InputMaybe<Scalars['numeric']['input']>;
  depositMin?: InputMaybe<Scalars['numeric']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  paymentMethodId?: InputMaybe<Scalars['Int']['input']>;
  payment_method?: InputMaybe<PaymentMethodsObjRelInsertInput>;
  telegramAdmins?: InputMaybe<Scalars['String']['input']>;
  telegramGroup?: InputMaybe<Scalars['bigint']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  withdraw?: InputMaybe<Scalars['Boolean']['input']>;
  withdrawDetails?: InputMaybe<Scalars['String']['input']>;
  withdrawExchangeRate?: InputMaybe<Scalars['numeric']['input']>;
  withdrawFee?: InputMaybe<Scalars['numeric']['input']>;
  withdrawMax?: InputMaybe<Scalars['numeric']['input']>;
  withdrawMin?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate max on columns */
export type PaymentMethodBanksMaxFields = {
  __typename?: 'PaymentMethodBanksMaxFields';
  country?: Maybe<Scalars['String']['output']>;
  countryId?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  currencyId?: Maybe<Scalars['Int']['output']>;
  depositCurrency?: Maybe<Scalars['String']['output']>;
  depositDetails?: Maybe<Scalars['String']['output']>;
  depositExchangeRate?: Maybe<Scalars['numeric']['output']>;
  depositFee?: Maybe<Scalars['numeric']['output']>;
  depositMax?: Maybe<Scalars['numeric']['output']>;
  depositMin?: Maybe<Scalars['numeric']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  paymentMethodId?: Maybe<Scalars['Int']['output']>;
  telegramAdmins?: Maybe<Scalars['String']['output']>;
  telegramGroup?: Maybe<Scalars['bigint']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  withdrawDetails?: Maybe<Scalars['String']['output']>;
  withdrawExchangeRate?: Maybe<Scalars['numeric']['output']>;
  withdrawFee?: Maybe<Scalars['numeric']['output']>;
  withdrawMax?: Maybe<Scalars['numeric']['output']>;
  withdrawMin?: Maybe<Scalars['numeric']['output']>;
};

/** aggregate min on columns */
export type PaymentMethodBanksMinFields = {
  __typename?: 'PaymentMethodBanksMinFields';
  country?: Maybe<Scalars['String']['output']>;
  countryId?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  currencyId?: Maybe<Scalars['Int']['output']>;
  depositCurrency?: Maybe<Scalars['String']['output']>;
  depositDetails?: Maybe<Scalars['String']['output']>;
  depositExchangeRate?: Maybe<Scalars['numeric']['output']>;
  depositFee?: Maybe<Scalars['numeric']['output']>;
  depositMax?: Maybe<Scalars['numeric']['output']>;
  depositMin?: Maybe<Scalars['numeric']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  paymentMethodId?: Maybe<Scalars['Int']['output']>;
  telegramAdmins?: Maybe<Scalars['String']['output']>;
  telegramGroup?: Maybe<Scalars['bigint']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  withdrawDetails?: Maybe<Scalars['String']['output']>;
  withdrawExchangeRate?: Maybe<Scalars['numeric']['output']>;
  withdrawFee?: Maybe<Scalars['numeric']['output']>;
  withdrawMax?: Maybe<Scalars['numeric']['output']>;
  withdrawMin?: Maybe<Scalars['numeric']['output']>;
};

/** response of any mutation on the table "payment_method_banks" */
export type PaymentMethodBanksMutationResponse = {
  __typename?: 'PaymentMethodBanksMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<PaymentMethodBanks>;
};

/** input type for inserting object relation for remote table "payment_method_banks" */
export type PaymentMethodBanksObjRelInsertInput = {
  data: PaymentMethodBanksInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<PaymentMethodBanksOnConflict>;
};

/** on_conflict condition type for table "payment_method_banks" */
export type PaymentMethodBanksOnConflict = {
  constraint: PaymentMethodBanksConstraint;
  update_columns?: Array<PaymentMethodBanksUpdateColumn>;
  where?: InputMaybe<PaymentMethodBanksBoolExp>;
};

/** Ordering options when selecting data from "payment_method_banks". */
export type PaymentMethodBanksOrderBy = {
  country?: InputMaybe<OrderBy>;
  countryByCountryId?: InputMaybe<CountriesOrderBy>;
  countryId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  currency?: InputMaybe<CurrenciesOrderBy>;
  currencyId?: InputMaybe<OrderBy>;
  deposit?: InputMaybe<OrderBy>;
  depositCurrency?: InputMaybe<OrderBy>;
  depositDetails?: InputMaybe<OrderBy>;
  depositExchangeRate?: InputMaybe<OrderBy>;
  depositFee?: InputMaybe<OrderBy>;
  depositMax?: InputMaybe<OrderBy>;
  depositMin?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  paymentMethodId?: InputMaybe<OrderBy>;
  payment_method?: InputMaybe<PaymentMethodsOrderBy>;
  telegramAdmins?: InputMaybe<OrderBy>;
  telegramGroup?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  withdraw?: InputMaybe<OrderBy>;
  withdrawDetails?: InputMaybe<OrderBy>;
  withdrawExchangeRate?: InputMaybe<OrderBy>;
  withdrawFee?: InputMaybe<OrderBy>;
  withdrawMax?: InputMaybe<OrderBy>;
  withdrawMin?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: payment_method_banks */
export type PaymentMethodBanksPkColumnsInput = {
  id: Scalars['Int']['input'];
};

/** select columns of table "payment_method_banks" */
export enum PaymentMethodBanksSelectColumn {
  /** column name */
  Country = 'country',
  /** column name */
  CountryId = 'countryId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CurrencyId = 'currencyId',
  /** column name */
  Deposit = 'deposit',
  /** column name */
  DepositCurrency = 'depositCurrency',
  /** column name */
  DepositDetails = 'depositDetails',
  /** column name */
  DepositExchangeRate = 'depositExchangeRate',
  /** column name */
  DepositFee = 'depositFee',
  /** column name */
  DepositMax = 'depositMax',
  /** column name */
  DepositMin = 'depositMin',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  PaymentMethodId = 'paymentMethodId',
  /** column name */
  TelegramAdmins = 'telegramAdmins',
  /** column name */
  TelegramGroup = 'telegramGroup',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Withdraw = 'withdraw',
  /** column name */
  WithdrawDetails = 'withdrawDetails',
  /** column name */
  WithdrawExchangeRate = 'withdrawExchangeRate',
  /** column name */
  WithdrawFee = 'withdrawFee',
  /** column name */
  WithdrawMax = 'withdrawMax',
  /** column name */
  WithdrawMin = 'withdrawMin',
}

/** input type for updating data in table "payment_method_banks" */
export type PaymentMethodBanksSetInput = {
  country?: InputMaybe<Scalars['String']['input']>;
  countryId?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  currencyId?: InputMaybe<Scalars['Int']['input']>;
  deposit?: InputMaybe<Scalars['Boolean']['input']>;
  depositCurrency?: InputMaybe<Scalars['String']['input']>;
  depositDetails?: InputMaybe<Scalars['String']['input']>;
  depositExchangeRate?: InputMaybe<Scalars['numeric']['input']>;
  depositFee?: InputMaybe<Scalars['numeric']['input']>;
  depositMax?: InputMaybe<Scalars['numeric']['input']>;
  depositMin?: InputMaybe<Scalars['numeric']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  paymentMethodId?: InputMaybe<Scalars['Int']['input']>;
  telegramAdmins?: InputMaybe<Scalars['String']['input']>;
  telegramGroup?: InputMaybe<Scalars['bigint']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  withdraw?: InputMaybe<Scalars['Boolean']['input']>;
  withdrawDetails?: InputMaybe<Scalars['String']['input']>;
  withdrawExchangeRate?: InputMaybe<Scalars['numeric']['input']>;
  withdrawFee?: InputMaybe<Scalars['numeric']['input']>;
  withdrawMax?: InputMaybe<Scalars['numeric']['input']>;
  withdrawMin?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate stddev on columns */
export type PaymentMethodBanksStddevFields = {
  __typename?: 'PaymentMethodBanksStddevFields';
  countryId?: Maybe<Scalars['Float']['output']>;
  currencyId?: Maybe<Scalars['Float']['output']>;
  depositExchangeRate?: Maybe<Scalars['Float']['output']>;
  depositFee?: Maybe<Scalars['Float']['output']>;
  depositMax?: Maybe<Scalars['Float']['output']>;
  depositMin?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  paymentMethodId?: Maybe<Scalars['Float']['output']>;
  telegramGroup?: Maybe<Scalars['Float']['output']>;
  withdrawExchangeRate?: Maybe<Scalars['Float']['output']>;
  withdrawFee?: Maybe<Scalars['Float']['output']>;
  withdrawMax?: Maybe<Scalars['Float']['output']>;
  withdrawMin?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type PaymentMethodBanksStddev_PopFields = {
  __typename?: 'PaymentMethodBanksStddev_popFields';
  countryId?: Maybe<Scalars['Float']['output']>;
  currencyId?: Maybe<Scalars['Float']['output']>;
  depositExchangeRate?: Maybe<Scalars['Float']['output']>;
  depositFee?: Maybe<Scalars['Float']['output']>;
  depositMax?: Maybe<Scalars['Float']['output']>;
  depositMin?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  paymentMethodId?: Maybe<Scalars['Float']['output']>;
  telegramGroup?: Maybe<Scalars['Float']['output']>;
  withdrawExchangeRate?: Maybe<Scalars['Float']['output']>;
  withdrawFee?: Maybe<Scalars['Float']['output']>;
  withdrawMax?: Maybe<Scalars['Float']['output']>;
  withdrawMin?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type PaymentMethodBanksStddev_SampFields = {
  __typename?: 'PaymentMethodBanksStddev_sampFields';
  countryId?: Maybe<Scalars['Float']['output']>;
  currencyId?: Maybe<Scalars['Float']['output']>;
  depositExchangeRate?: Maybe<Scalars['Float']['output']>;
  depositFee?: Maybe<Scalars['Float']['output']>;
  depositMax?: Maybe<Scalars['Float']['output']>;
  depositMin?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  paymentMethodId?: Maybe<Scalars['Float']['output']>;
  telegramGroup?: Maybe<Scalars['Float']['output']>;
  withdrawExchangeRate?: Maybe<Scalars['Float']['output']>;
  withdrawFee?: Maybe<Scalars['Float']['output']>;
  withdrawMax?: Maybe<Scalars['Float']['output']>;
  withdrawMin?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "payment_method_banks" */
export type PaymentMethodBanksStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: PaymentMethodBanksStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type PaymentMethodBanksStreamCursorValueInput = {
  country?: InputMaybe<Scalars['String']['input']>;
  countryId?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  currencyId?: InputMaybe<Scalars['Int']['input']>;
  deposit?: InputMaybe<Scalars['Boolean']['input']>;
  depositCurrency?: InputMaybe<Scalars['String']['input']>;
  depositDetails?: InputMaybe<Scalars['String']['input']>;
  depositExchangeRate?: InputMaybe<Scalars['numeric']['input']>;
  depositFee?: InputMaybe<Scalars['numeric']['input']>;
  depositMax?: InputMaybe<Scalars['numeric']['input']>;
  depositMin?: InputMaybe<Scalars['numeric']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  paymentMethodId?: InputMaybe<Scalars['Int']['input']>;
  telegramAdmins?: InputMaybe<Scalars['String']['input']>;
  telegramGroup?: InputMaybe<Scalars['bigint']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  withdraw?: InputMaybe<Scalars['Boolean']['input']>;
  withdrawDetails?: InputMaybe<Scalars['String']['input']>;
  withdrawExchangeRate?: InputMaybe<Scalars['numeric']['input']>;
  withdrawFee?: InputMaybe<Scalars['numeric']['input']>;
  withdrawMax?: InputMaybe<Scalars['numeric']['input']>;
  withdrawMin?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate sum on columns */
export type PaymentMethodBanksSumFields = {
  __typename?: 'PaymentMethodBanksSumFields';
  countryId?: Maybe<Scalars['Int']['output']>;
  currencyId?: Maybe<Scalars['Int']['output']>;
  depositExchangeRate?: Maybe<Scalars['numeric']['output']>;
  depositFee?: Maybe<Scalars['numeric']['output']>;
  depositMax?: Maybe<Scalars['numeric']['output']>;
  depositMin?: Maybe<Scalars['numeric']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  paymentMethodId?: Maybe<Scalars['Int']['output']>;
  telegramGroup?: Maybe<Scalars['bigint']['output']>;
  withdrawExchangeRate?: Maybe<Scalars['numeric']['output']>;
  withdrawFee?: Maybe<Scalars['numeric']['output']>;
  withdrawMax?: Maybe<Scalars['numeric']['output']>;
  withdrawMin?: Maybe<Scalars['numeric']['output']>;
};

/** update columns of table "payment_method_banks" */
export enum PaymentMethodBanksUpdateColumn {
  /** column name */
  Country = 'country',
  /** column name */
  CountryId = 'countryId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CurrencyId = 'currencyId',
  /** column name */
  Deposit = 'deposit',
  /** column name */
  DepositCurrency = 'depositCurrency',
  /** column name */
  DepositDetails = 'depositDetails',
  /** column name */
  DepositExchangeRate = 'depositExchangeRate',
  /** column name */
  DepositFee = 'depositFee',
  /** column name */
  DepositMax = 'depositMax',
  /** column name */
  DepositMin = 'depositMin',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  PaymentMethodId = 'paymentMethodId',
  /** column name */
  TelegramAdmins = 'telegramAdmins',
  /** column name */
  TelegramGroup = 'telegramGroup',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Withdraw = 'withdraw',
  /** column name */
  WithdrawDetails = 'withdrawDetails',
  /** column name */
  WithdrawExchangeRate = 'withdrawExchangeRate',
  /** column name */
  WithdrawFee = 'withdrawFee',
  /** column name */
  WithdrawMax = 'withdrawMax',
  /** column name */
  WithdrawMin = 'withdrawMin',
}

export type PaymentMethodBanksUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<PaymentMethodBanksIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PaymentMethodBanksSetInput>;
  /** filter the rows which have to be updated */
  where: PaymentMethodBanksBoolExp;
};

/** aggregate var_pop on columns */
export type PaymentMethodBanksVar_PopFields = {
  __typename?: 'PaymentMethodBanksVar_popFields';
  countryId?: Maybe<Scalars['Float']['output']>;
  currencyId?: Maybe<Scalars['Float']['output']>;
  depositExchangeRate?: Maybe<Scalars['Float']['output']>;
  depositFee?: Maybe<Scalars['Float']['output']>;
  depositMax?: Maybe<Scalars['Float']['output']>;
  depositMin?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  paymentMethodId?: Maybe<Scalars['Float']['output']>;
  telegramGroup?: Maybe<Scalars['Float']['output']>;
  withdrawExchangeRate?: Maybe<Scalars['Float']['output']>;
  withdrawFee?: Maybe<Scalars['Float']['output']>;
  withdrawMax?: Maybe<Scalars['Float']['output']>;
  withdrawMin?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type PaymentMethodBanksVar_SampFields = {
  __typename?: 'PaymentMethodBanksVar_sampFields';
  countryId?: Maybe<Scalars['Float']['output']>;
  currencyId?: Maybe<Scalars['Float']['output']>;
  depositExchangeRate?: Maybe<Scalars['Float']['output']>;
  depositFee?: Maybe<Scalars['Float']['output']>;
  depositMax?: Maybe<Scalars['Float']['output']>;
  depositMin?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  paymentMethodId?: Maybe<Scalars['Float']['output']>;
  telegramGroup?: Maybe<Scalars['Float']['output']>;
  withdrawExchangeRate?: Maybe<Scalars['Float']['output']>;
  withdrawFee?: Maybe<Scalars['Float']['output']>;
  withdrawMax?: Maybe<Scalars['Float']['output']>;
  withdrawMin?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type PaymentMethodBanksVarianceFields = {
  __typename?: 'PaymentMethodBanksVarianceFields';
  countryId?: Maybe<Scalars['Float']['output']>;
  currencyId?: Maybe<Scalars['Float']['output']>;
  depositExchangeRate?: Maybe<Scalars['Float']['output']>;
  depositFee?: Maybe<Scalars['Float']['output']>;
  depositMax?: Maybe<Scalars['Float']['output']>;
  depositMin?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  paymentMethodId?: Maybe<Scalars['Float']['output']>;
  telegramGroup?: Maybe<Scalars['Float']['output']>;
  withdrawExchangeRate?: Maybe<Scalars['Float']['output']>;
  withdrawFee?: Maybe<Scalars['Float']['output']>;
  withdrawMax?: Maybe<Scalars['Float']['output']>;
  withdrawMin?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "payment_methods" */
export type PaymentMethods = {
  __typename?: 'PaymentMethods';
  createdAt: Scalars['timestamptz']['output'];
  deposit: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  /** An aggregate relationship */
  paymentMethodBanksAggregate: PaymentMethodBanksAggregate;
  /** An array relationship */
  payment_method_banks: Array<PaymentMethodBanks>;
  type: Scalars['String']['output'];
  updatedAt: Scalars['timestamptz']['output'];
  withdraw: Scalars['Boolean']['output'];
};

/** columns and relationships of "payment_methods" */
export type PaymentMethodsPaymentMethodBanksAggregateArgs = {
  distinctOn?: InputMaybe<Array<PaymentMethodBanksSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PaymentMethodBanksOrderBy>>;
  where?: InputMaybe<PaymentMethodBanksBoolExp>;
};

/** columns and relationships of "payment_methods" */
export type PaymentMethodsPayment_Method_BanksArgs = {
  distinctOn?: InputMaybe<Array<PaymentMethodBanksSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PaymentMethodBanksOrderBy>>;
  where?: InputMaybe<PaymentMethodBanksBoolExp>;
};

/** aggregated selection of "payment_methods" */
export type PaymentMethodsAggregate = {
  __typename?: 'PaymentMethodsAggregate';
  aggregate?: Maybe<PaymentMethodsAggregateFields>;
  nodes: Array<PaymentMethods>;
};

/** aggregate fields of "payment_methods" */
export type PaymentMethodsAggregateFields = {
  __typename?: 'PaymentMethodsAggregateFields';
  avg?: Maybe<PaymentMethodsAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<PaymentMethodsMaxFields>;
  min?: Maybe<PaymentMethodsMinFields>;
  stddev?: Maybe<PaymentMethodsStddevFields>;
  stddevPop?: Maybe<PaymentMethodsStddev_PopFields>;
  stddevSamp?: Maybe<PaymentMethodsStddev_SampFields>;
  sum?: Maybe<PaymentMethodsSumFields>;
  varPop?: Maybe<PaymentMethodsVar_PopFields>;
  varSamp?: Maybe<PaymentMethodsVar_SampFields>;
  variance?: Maybe<PaymentMethodsVarianceFields>;
};

/** aggregate fields of "payment_methods" */
export type PaymentMethodsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<PaymentMethodsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type PaymentMethodsAvgFields = {
  __typename?: 'PaymentMethodsAvgFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "payment_methods". All fields are combined with a logical 'AND'. */
export type PaymentMethodsBoolExp = {
  _and?: InputMaybe<Array<PaymentMethodsBoolExp>>;
  _not?: InputMaybe<PaymentMethodsBoolExp>;
  _or?: InputMaybe<Array<PaymentMethodsBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  deposit?: InputMaybe<BooleanComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  payment_method_banks?: InputMaybe<PaymentMethodBanksBoolExp>;
  payment_method_banks_aggregate?: InputMaybe<Payment_Method_Banks_Aggregate_Bool_Exp>;
  type?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  withdraw?: InputMaybe<BooleanComparisonExp>;
};

/** unique or primary key constraints on table "payment_methods" */
export enum PaymentMethodsConstraint {
  /** unique or primary key constraint on columns "id" */
  PaymentMethodsPkey = 'payment_methods_pkey',
}

/** input type for incrementing numeric columns in table "payment_methods" */
export type PaymentMethodsIncInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "payment_methods" */
export type PaymentMethodsInsertInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  deposit?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  payment_method_banks?: InputMaybe<PaymentMethodBanksArrRelInsertInput>;
  type?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  withdraw?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate max on columns */
export type PaymentMethodsMaxFields = {
  __typename?: 'PaymentMethodsMaxFields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type PaymentMethodsMinFields = {
  __typename?: 'PaymentMethodsMinFields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "payment_methods" */
export type PaymentMethodsMutationResponse = {
  __typename?: 'PaymentMethodsMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<PaymentMethods>;
};

/** input type for inserting object relation for remote table "payment_methods" */
export type PaymentMethodsObjRelInsertInput = {
  data: PaymentMethodsInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<PaymentMethodsOnConflict>;
};

/** on_conflict condition type for table "payment_methods" */
export type PaymentMethodsOnConflict = {
  constraint: PaymentMethodsConstraint;
  update_columns?: Array<PaymentMethodsUpdateColumn>;
  where?: InputMaybe<PaymentMethodsBoolExp>;
};

/** Ordering options when selecting data from "payment_methods". */
export type PaymentMethodsOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  deposit?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  payment_method_banksAggregate?: InputMaybe<PaymentMethodBanksAggregateOrderBy>;
  type?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  withdraw?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: payment_methods */
export type PaymentMethodsPkColumnsInput = {
  id: Scalars['Int']['input'];
};

/** select columns of table "payment_methods" */
export enum PaymentMethodsSelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Deposit = 'deposit',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Withdraw = 'withdraw',
}

/** input type for updating data in table "payment_methods" */
export type PaymentMethodsSetInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  deposit?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  withdraw?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate stddev on columns */
export type PaymentMethodsStddevFields = {
  __typename?: 'PaymentMethodsStddevFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type PaymentMethodsStddev_PopFields = {
  __typename?: 'PaymentMethodsStddev_popFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type PaymentMethodsStddev_SampFields = {
  __typename?: 'PaymentMethodsStddev_sampFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "payment_methods" */
export type PaymentMethodsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: PaymentMethodsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type PaymentMethodsStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  deposit?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  withdraw?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate sum on columns */
export type PaymentMethodsSumFields = {
  __typename?: 'PaymentMethodsSumFields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "payment_methods" */
export enum PaymentMethodsUpdateColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Deposit = 'deposit',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Withdraw = 'withdraw',
}

export type PaymentMethodsUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<PaymentMethodsIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PaymentMethodsSetInput>;
  /** filter the rows which have to be updated */
  where: PaymentMethodsBoolExp;
};

/** aggregate var_pop on columns */
export type PaymentMethodsVar_PopFields = {
  __typename?: 'PaymentMethodsVar_popFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type PaymentMethodsVar_SampFields = {
  __typename?: 'PaymentMethodsVar_sampFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type PaymentMethodsVarianceFields = {
  __typename?: 'PaymentMethodsVarianceFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "payments" */
export type Payments = {
  __typename?: 'Payments';
  amount: Scalars['numeric']['output'];
  amountOut?: Maybe<Scalars['numeric']['output']>;
  blockchainCurrencyId?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  blockchain_currency?: Maybe<BlockchainCurrencies>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  currency: Currencies;
  currencyId: Scalars['Int']['output'];
  currencySymbol: Scalars['String']['output'];
  depositAddress?: Maybe<Scalars['String']['output']>;
  fee: Scalars['numeric']['output'];
  id: Scalars['uuid']['output'];
  paymentMethodBankId?: Maybe<Scalars['Int']['output']>;
  paymentMethodId: Scalars['Int']['output'];
  /** An object relationship */
  payment_method: PaymentMethods;
  /** An object relationship */
  payment_method_bank?: Maybe<PaymentMethodBanks>;
  status: Scalars['String']['output'];
  statusInternal: Scalars['String']['output'];
  tgMsgId?: Maybe<Scalars['Int']['output']>;
  twofa?: Maybe<Scalars['String']['output']>;
  txid?: Maybe<Scalars['String']['output']>;
  txlog?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  userId: Scalars['String']['output'];
  withdrawAddress?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "payments" */
export type PaymentsAggregate = {
  __typename?: 'PaymentsAggregate';
  aggregate?: Maybe<PaymentsAggregateFields>;
  nodes: Array<Payments>;
};

/** aggregate fields of "payments" */
export type PaymentsAggregateFields = {
  __typename?: 'PaymentsAggregateFields';
  avg?: Maybe<PaymentsAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<PaymentsMaxFields>;
  min?: Maybe<PaymentsMinFields>;
  stddev?: Maybe<PaymentsStddevFields>;
  stddevPop?: Maybe<PaymentsStddev_PopFields>;
  stddevSamp?: Maybe<PaymentsStddev_SampFields>;
  sum?: Maybe<PaymentsSumFields>;
  varPop?: Maybe<PaymentsVar_PopFields>;
  varSamp?: Maybe<PaymentsVar_SampFields>;
  variance?: Maybe<PaymentsVarianceFields>;
};

/** aggregate fields of "payments" */
export type PaymentsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<PaymentsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type PaymentsAvgFields = {
  __typename?: 'PaymentsAvgFields';
  amount?: Maybe<Scalars['Float']['output']>;
  amountOut?: Maybe<Scalars['Float']['output']>;
  blockchainCurrencyId?: Maybe<Scalars['Float']['output']>;
  currencyId?: Maybe<Scalars['Float']['output']>;
  fee?: Maybe<Scalars['Float']['output']>;
  paymentMethodBankId?: Maybe<Scalars['Float']['output']>;
  paymentMethodId?: Maybe<Scalars['Float']['output']>;
  tgMsgId?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "payments". All fields are combined with a logical 'AND'. */
export type PaymentsBoolExp = {
  _and?: InputMaybe<Array<PaymentsBoolExp>>;
  _not?: InputMaybe<PaymentsBoolExp>;
  _or?: InputMaybe<Array<PaymentsBoolExp>>;
  amount?: InputMaybe<NumericComparisonExp>;
  amountOut?: InputMaybe<NumericComparisonExp>;
  blockchainCurrencyId?: InputMaybe<IntComparisonExp>;
  blockchain_currency?: InputMaybe<BlockchainCurrenciesBoolExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  currency?: InputMaybe<CurrenciesBoolExp>;
  currencyId?: InputMaybe<IntComparisonExp>;
  currencySymbol?: InputMaybe<StringComparisonExp>;
  depositAddress?: InputMaybe<StringComparisonExp>;
  fee?: InputMaybe<NumericComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  paymentMethodBankId?: InputMaybe<IntComparisonExp>;
  paymentMethodId?: InputMaybe<IntComparisonExp>;
  payment_method?: InputMaybe<PaymentMethodsBoolExp>;
  payment_method_bank?: InputMaybe<PaymentMethodBanksBoolExp>;
  status?: InputMaybe<StringComparisonExp>;
  statusInternal?: InputMaybe<StringComparisonExp>;
  tgMsgId?: InputMaybe<IntComparisonExp>;
  twofa?: InputMaybe<StringComparisonExp>;
  txid?: InputMaybe<StringComparisonExp>;
  txlog?: InputMaybe<StringComparisonExp>;
  type?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  userId?: InputMaybe<StringComparisonExp>;
  withdrawAddress?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "payments" */
export enum PaymentsConstraint {
  /** unique or primary key constraint on columns "id" */
  PaymentsPkey = 'payments_pkey',
}

/** input type for incrementing numeric columns in table "payments" */
export type PaymentsIncInput = {
  amount?: InputMaybe<Scalars['numeric']['input']>;
  amountOut?: InputMaybe<Scalars['numeric']['input']>;
  blockchainCurrencyId?: InputMaybe<Scalars['Int']['input']>;
  currencyId?: InputMaybe<Scalars['Int']['input']>;
  fee?: InputMaybe<Scalars['numeric']['input']>;
  paymentMethodBankId?: InputMaybe<Scalars['Int']['input']>;
  paymentMethodId?: InputMaybe<Scalars['Int']['input']>;
  tgMsgId?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "payments" */
export type PaymentsInsertInput = {
  amount?: InputMaybe<Scalars['numeric']['input']>;
  amountOut?: InputMaybe<Scalars['numeric']['input']>;
  blockchainCurrencyId?: InputMaybe<Scalars['Int']['input']>;
  blockchain_currency?: InputMaybe<BlockchainCurrenciesObjRelInsertInput>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  currency?: InputMaybe<CurrenciesObjRelInsertInput>;
  currencyId?: InputMaybe<Scalars['Int']['input']>;
  currencySymbol?: InputMaybe<Scalars['String']['input']>;
  depositAddress?: InputMaybe<Scalars['String']['input']>;
  fee?: InputMaybe<Scalars['numeric']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  paymentMethodBankId?: InputMaybe<Scalars['Int']['input']>;
  paymentMethodId?: InputMaybe<Scalars['Int']['input']>;
  payment_method?: InputMaybe<PaymentMethodsObjRelInsertInput>;
  payment_method_bank?: InputMaybe<PaymentMethodBanksObjRelInsertInput>;
  status?: InputMaybe<Scalars['String']['input']>;
  statusInternal?: InputMaybe<Scalars['String']['input']>;
  tgMsgId?: InputMaybe<Scalars['Int']['input']>;
  twofa?: InputMaybe<Scalars['String']['input']>;
  txid?: InputMaybe<Scalars['String']['input']>;
  txlog?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
  withdrawAddress?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type PaymentsMaxFields = {
  __typename?: 'PaymentsMaxFields';
  amount?: Maybe<Scalars['numeric']['output']>;
  amountOut?: Maybe<Scalars['numeric']['output']>;
  blockchainCurrencyId?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  currencyId?: Maybe<Scalars['Int']['output']>;
  currencySymbol?: Maybe<Scalars['String']['output']>;
  depositAddress?: Maybe<Scalars['String']['output']>;
  fee?: Maybe<Scalars['numeric']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  paymentMethodBankId?: Maybe<Scalars['Int']['output']>;
  paymentMethodId?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  statusInternal?: Maybe<Scalars['String']['output']>;
  tgMsgId?: Maybe<Scalars['Int']['output']>;
  twofa?: Maybe<Scalars['String']['output']>;
  txid?: Maybe<Scalars['String']['output']>;
  txlog?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
  withdrawAddress?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type PaymentsMinFields = {
  __typename?: 'PaymentsMinFields';
  amount?: Maybe<Scalars['numeric']['output']>;
  amountOut?: Maybe<Scalars['numeric']['output']>;
  blockchainCurrencyId?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  currencyId?: Maybe<Scalars['Int']['output']>;
  currencySymbol?: Maybe<Scalars['String']['output']>;
  depositAddress?: Maybe<Scalars['String']['output']>;
  fee?: Maybe<Scalars['numeric']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  paymentMethodBankId?: Maybe<Scalars['Int']['output']>;
  paymentMethodId?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  statusInternal?: Maybe<Scalars['String']['output']>;
  tgMsgId?: Maybe<Scalars['Int']['output']>;
  twofa?: Maybe<Scalars['String']['output']>;
  txid?: Maybe<Scalars['String']['output']>;
  txlog?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
  withdrawAddress?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "payments" */
export type PaymentsMutationResponse = {
  __typename?: 'PaymentsMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Payments>;
};

/** input type for inserting object relation for remote table "payments" */
export type PaymentsObjRelInsertInput = {
  data: PaymentsInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<PaymentsOnConflict>;
};

/** on_conflict condition type for table "payments" */
export type PaymentsOnConflict = {
  constraint: PaymentsConstraint;
  update_columns?: Array<PaymentsUpdateColumn>;
  where?: InputMaybe<PaymentsBoolExp>;
};

/** Ordering options when selecting data from "payments". */
export type PaymentsOrderBy = {
  amount?: InputMaybe<OrderBy>;
  amountOut?: InputMaybe<OrderBy>;
  blockchainCurrencyId?: InputMaybe<OrderBy>;
  blockchain_currency?: InputMaybe<BlockchainCurrenciesOrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  currency?: InputMaybe<CurrenciesOrderBy>;
  currencyId?: InputMaybe<OrderBy>;
  currencySymbol?: InputMaybe<OrderBy>;
  depositAddress?: InputMaybe<OrderBy>;
  fee?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  paymentMethodBankId?: InputMaybe<OrderBy>;
  paymentMethodId?: InputMaybe<OrderBy>;
  payment_method?: InputMaybe<PaymentMethodsOrderBy>;
  payment_method_bank?: InputMaybe<PaymentMethodBanksOrderBy>;
  status?: InputMaybe<OrderBy>;
  statusInternal?: InputMaybe<OrderBy>;
  tgMsgId?: InputMaybe<OrderBy>;
  twofa?: InputMaybe<OrderBy>;
  txid?: InputMaybe<OrderBy>;
  txlog?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
  withdrawAddress?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: payments */
export type PaymentsPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "payments" */
export enum PaymentsSelectColumn {
  /** column name */
  Amount = 'amount',
  /** column name */
  AmountOut = 'amountOut',
  /** column name */
  BlockchainCurrencyId = 'blockchainCurrencyId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CurrencyId = 'currencyId',
  /** column name */
  CurrencySymbol = 'currencySymbol',
  /** column name */
  DepositAddress = 'depositAddress',
  /** column name */
  Fee = 'fee',
  /** column name */
  Id = 'id',
  /** column name */
  PaymentMethodBankId = 'paymentMethodBankId',
  /** column name */
  PaymentMethodId = 'paymentMethodId',
  /** column name */
  Status = 'status',
  /** column name */
  StatusInternal = 'statusInternal',
  /** column name */
  TgMsgId = 'tgMsgId',
  /** column name */
  Twofa = 'twofa',
  /** column name */
  Txid = 'txid',
  /** column name */
  Txlog = 'txlog',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId',
  /** column name */
  WithdrawAddress = 'withdrawAddress',
}

/** input type for updating data in table "payments" */
export type PaymentsSetInput = {
  amount?: InputMaybe<Scalars['numeric']['input']>;
  amountOut?: InputMaybe<Scalars['numeric']['input']>;
  blockchainCurrencyId?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  currencyId?: InputMaybe<Scalars['Int']['input']>;
  currencySymbol?: InputMaybe<Scalars['String']['input']>;
  depositAddress?: InputMaybe<Scalars['String']['input']>;
  fee?: InputMaybe<Scalars['numeric']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  paymentMethodBankId?: InputMaybe<Scalars['Int']['input']>;
  paymentMethodId?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  statusInternal?: InputMaybe<Scalars['String']['input']>;
  tgMsgId?: InputMaybe<Scalars['Int']['input']>;
  twofa?: InputMaybe<Scalars['String']['input']>;
  txid?: InputMaybe<Scalars['String']['input']>;
  txlog?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
  withdrawAddress?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type PaymentsStddevFields = {
  __typename?: 'PaymentsStddevFields';
  amount?: Maybe<Scalars['Float']['output']>;
  amountOut?: Maybe<Scalars['Float']['output']>;
  blockchainCurrencyId?: Maybe<Scalars['Float']['output']>;
  currencyId?: Maybe<Scalars['Float']['output']>;
  fee?: Maybe<Scalars['Float']['output']>;
  paymentMethodBankId?: Maybe<Scalars['Float']['output']>;
  paymentMethodId?: Maybe<Scalars['Float']['output']>;
  tgMsgId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type PaymentsStddev_PopFields = {
  __typename?: 'PaymentsStddev_popFields';
  amount?: Maybe<Scalars['Float']['output']>;
  amountOut?: Maybe<Scalars['Float']['output']>;
  blockchainCurrencyId?: Maybe<Scalars['Float']['output']>;
  currencyId?: Maybe<Scalars['Float']['output']>;
  fee?: Maybe<Scalars['Float']['output']>;
  paymentMethodBankId?: Maybe<Scalars['Float']['output']>;
  paymentMethodId?: Maybe<Scalars['Float']['output']>;
  tgMsgId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type PaymentsStddev_SampFields = {
  __typename?: 'PaymentsStddev_sampFields';
  amount?: Maybe<Scalars['Float']['output']>;
  amountOut?: Maybe<Scalars['Float']['output']>;
  blockchainCurrencyId?: Maybe<Scalars['Float']['output']>;
  currencyId?: Maybe<Scalars['Float']['output']>;
  fee?: Maybe<Scalars['Float']['output']>;
  paymentMethodBankId?: Maybe<Scalars['Float']['output']>;
  paymentMethodId?: Maybe<Scalars['Float']['output']>;
  tgMsgId?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "payments" */
export type PaymentsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: PaymentsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type PaymentsStreamCursorValueInput = {
  amount?: InputMaybe<Scalars['numeric']['input']>;
  amountOut?: InputMaybe<Scalars['numeric']['input']>;
  blockchainCurrencyId?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  currencyId?: InputMaybe<Scalars['Int']['input']>;
  currencySymbol?: InputMaybe<Scalars['String']['input']>;
  depositAddress?: InputMaybe<Scalars['String']['input']>;
  fee?: InputMaybe<Scalars['numeric']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  paymentMethodBankId?: InputMaybe<Scalars['Int']['input']>;
  paymentMethodId?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  statusInternal?: InputMaybe<Scalars['String']['input']>;
  tgMsgId?: InputMaybe<Scalars['Int']['input']>;
  twofa?: InputMaybe<Scalars['String']['input']>;
  txid?: InputMaybe<Scalars['String']['input']>;
  txlog?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
  withdrawAddress?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type PaymentsSumFields = {
  __typename?: 'PaymentsSumFields';
  amount?: Maybe<Scalars['numeric']['output']>;
  amountOut?: Maybe<Scalars['numeric']['output']>;
  blockchainCurrencyId?: Maybe<Scalars['Int']['output']>;
  currencyId?: Maybe<Scalars['Int']['output']>;
  fee?: Maybe<Scalars['numeric']['output']>;
  paymentMethodBankId?: Maybe<Scalars['Int']['output']>;
  paymentMethodId?: Maybe<Scalars['Int']['output']>;
  tgMsgId?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "payments" */
export enum PaymentsUpdateColumn {
  /** column name */
  Amount = 'amount',
  /** column name */
  AmountOut = 'amountOut',
  /** column name */
  BlockchainCurrencyId = 'blockchainCurrencyId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CurrencyId = 'currencyId',
  /** column name */
  CurrencySymbol = 'currencySymbol',
  /** column name */
  DepositAddress = 'depositAddress',
  /** column name */
  Fee = 'fee',
  /** column name */
  Id = 'id',
  /** column name */
  PaymentMethodBankId = 'paymentMethodBankId',
  /** column name */
  PaymentMethodId = 'paymentMethodId',
  /** column name */
  Status = 'status',
  /** column name */
  StatusInternal = 'statusInternal',
  /** column name */
  TgMsgId = 'tgMsgId',
  /** column name */
  Twofa = 'twofa',
  /** column name */
  Txid = 'txid',
  /** column name */
  Txlog = 'txlog',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId',
  /** column name */
  WithdrawAddress = 'withdrawAddress',
}

export type PaymentsUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<PaymentsIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PaymentsSetInput>;
  /** filter the rows which have to be updated */
  where: PaymentsBoolExp;
};

/** aggregate var_pop on columns */
export type PaymentsVar_PopFields = {
  __typename?: 'PaymentsVar_popFields';
  amount?: Maybe<Scalars['Float']['output']>;
  amountOut?: Maybe<Scalars['Float']['output']>;
  blockchainCurrencyId?: Maybe<Scalars['Float']['output']>;
  currencyId?: Maybe<Scalars['Float']['output']>;
  fee?: Maybe<Scalars['Float']['output']>;
  paymentMethodBankId?: Maybe<Scalars['Float']['output']>;
  paymentMethodId?: Maybe<Scalars['Float']['output']>;
  tgMsgId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type PaymentsVar_SampFields = {
  __typename?: 'PaymentsVar_sampFields';
  amount?: Maybe<Scalars['Float']['output']>;
  amountOut?: Maybe<Scalars['Float']['output']>;
  blockchainCurrencyId?: Maybe<Scalars['Float']['output']>;
  currencyId?: Maybe<Scalars['Float']['output']>;
  fee?: Maybe<Scalars['Float']['output']>;
  paymentMethodBankId?: Maybe<Scalars['Float']['output']>;
  paymentMethodId?: Maybe<Scalars['Float']['output']>;
  tgMsgId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type PaymentsVarianceFields = {
  __typename?: 'PaymentsVarianceFields';
  amount?: Maybe<Scalars['Float']['output']>;
  amountOut?: Maybe<Scalars['Float']['output']>;
  blockchainCurrencyId?: Maybe<Scalars['Float']['output']>;
  currencyId?: Maybe<Scalars['Float']['output']>;
  fee?: Maybe<Scalars['Float']['output']>;
  paymentMethodBankId?: Maybe<Scalars['Float']['output']>;
  paymentMethodId?: Maybe<Scalars['Float']['output']>;
  tgMsgId?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "pending_txs" */
export type PendingTxs = {
  __typename?: 'PendingTxs';
  amount: Scalars['numeric']['output'];
  blockNumber: Scalars['Int']['output'];
  blockchainCurrencyId?: Maybe<Scalars['Int']['output']>;
  blockchainId: Scalars['Int']['output'];
  contractAddress: Scalars['String']['output'];
  createdAt: Scalars['timestamptz']['output'];
  from: Scalars['String']['output'];
  function: Scalars['String']['output'];
  hash: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  timeStamp: Scalars['Int']['output'];
  to: Scalars['String']['output'];
  tokenSymbol: Scalars['String']['output'];
  updatedAt: Scalars['timestamptz']['output'];
  wallet: Scalars['String']['output'];
};

/** aggregated selection of "pending_txs" */
export type PendingTxsAggregate = {
  __typename?: 'PendingTxsAggregate';
  aggregate?: Maybe<PendingTxsAggregateFields>;
  nodes: Array<PendingTxs>;
};

/** aggregate fields of "pending_txs" */
export type PendingTxsAggregateFields = {
  __typename?: 'PendingTxsAggregateFields';
  avg?: Maybe<PendingTxsAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<PendingTxsMaxFields>;
  min?: Maybe<PendingTxsMinFields>;
  stddev?: Maybe<PendingTxsStddevFields>;
  stddevPop?: Maybe<PendingTxsStddev_PopFields>;
  stddevSamp?: Maybe<PendingTxsStddev_SampFields>;
  sum?: Maybe<PendingTxsSumFields>;
  varPop?: Maybe<PendingTxsVar_PopFields>;
  varSamp?: Maybe<PendingTxsVar_SampFields>;
  variance?: Maybe<PendingTxsVarianceFields>;
};

/** aggregate fields of "pending_txs" */
export type PendingTxsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<PendingTxsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type PendingTxsAvgFields = {
  __typename?: 'PendingTxsAvgFields';
  amount?: Maybe<Scalars['Float']['output']>;
  blockNumber?: Maybe<Scalars['Float']['output']>;
  blockchainCurrencyId?: Maybe<Scalars['Float']['output']>;
  blockchainId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  timeStamp?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "pending_txs". All fields are combined with a logical 'AND'. */
export type PendingTxsBoolExp = {
  _and?: InputMaybe<Array<PendingTxsBoolExp>>;
  _not?: InputMaybe<PendingTxsBoolExp>;
  _or?: InputMaybe<Array<PendingTxsBoolExp>>;
  amount?: InputMaybe<NumericComparisonExp>;
  blockNumber?: InputMaybe<IntComparisonExp>;
  blockchainCurrencyId?: InputMaybe<IntComparisonExp>;
  blockchainId?: InputMaybe<IntComparisonExp>;
  contractAddress?: InputMaybe<StringComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  from?: InputMaybe<StringComparisonExp>;
  function?: InputMaybe<StringComparisonExp>;
  hash?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  timeStamp?: InputMaybe<IntComparisonExp>;
  to?: InputMaybe<StringComparisonExp>;
  tokenSymbol?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  wallet?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "pending_txs" */
export enum PendingTxsConstraint {
  /** unique or primary key constraint on columns "id" */
  PendingTxPkey = 'pending_tx_pkey',
}

/** input type for incrementing numeric columns in table "pending_txs" */
export type PendingTxsIncInput = {
  amount?: InputMaybe<Scalars['numeric']['input']>;
  blockNumber?: InputMaybe<Scalars['Int']['input']>;
  blockchainCurrencyId?: InputMaybe<Scalars['Int']['input']>;
  blockchainId?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  timeStamp?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "pending_txs" */
export type PendingTxsInsertInput = {
  amount?: InputMaybe<Scalars['numeric']['input']>;
  blockNumber?: InputMaybe<Scalars['Int']['input']>;
  blockchainCurrencyId?: InputMaybe<Scalars['Int']['input']>;
  blockchainId?: InputMaybe<Scalars['Int']['input']>;
  contractAddress?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  from?: InputMaybe<Scalars['String']['input']>;
  function?: InputMaybe<Scalars['String']['input']>;
  hash?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  timeStamp?: InputMaybe<Scalars['Int']['input']>;
  to?: InputMaybe<Scalars['String']['input']>;
  tokenSymbol?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  wallet?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type PendingTxsMaxFields = {
  __typename?: 'PendingTxsMaxFields';
  amount?: Maybe<Scalars['numeric']['output']>;
  blockNumber?: Maybe<Scalars['Int']['output']>;
  blockchainCurrencyId?: Maybe<Scalars['Int']['output']>;
  blockchainId?: Maybe<Scalars['Int']['output']>;
  contractAddress?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  from?: Maybe<Scalars['String']['output']>;
  function?: Maybe<Scalars['String']['output']>;
  hash?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  timeStamp?: Maybe<Scalars['Int']['output']>;
  to?: Maybe<Scalars['String']['output']>;
  tokenSymbol?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  wallet?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type PendingTxsMinFields = {
  __typename?: 'PendingTxsMinFields';
  amount?: Maybe<Scalars['numeric']['output']>;
  blockNumber?: Maybe<Scalars['Int']['output']>;
  blockchainCurrencyId?: Maybe<Scalars['Int']['output']>;
  blockchainId?: Maybe<Scalars['Int']['output']>;
  contractAddress?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  from?: Maybe<Scalars['String']['output']>;
  function?: Maybe<Scalars['String']['output']>;
  hash?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  timeStamp?: Maybe<Scalars['Int']['output']>;
  to?: Maybe<Scalars['String']['output']>;
  tokenSymbol?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  wallet?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "pending_txs" */
export type PendingTxsMutationResponse = {
  __typename?: 'PendingTxsMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<PendingTxs>;
};

/** on_conflict condition type for table "pending_txs" */
export type PendingTxsOnConflict = {
  constraint: PendingTxsConstraint;
  update_columns?: Array<PendingTxsUpdateColumn>;
  where?: InputMaybe<PendingTxsBoolExp>;
};

/** Ordering options when selecting data from "pending_txs". */
export type PendingTxsOrderBy = {
  amount?: InputMaybe<OrderBy>;
  blockNumber?: InputMaybe<OrderBy>;
  blockchainCurrencyId?: InputMaybe<OrderBy>;
  blockchainId?: InputMaybe<OrderBy>;
  contractAddress?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  from?: InputMaybe<OrderBy>;
  function?: InputMaybe<OrderBy>;
  hash?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  timeStamp?: InputMaybe<OrderBy>;
  to?: InputMaybe<OrderBy>;
  tokenSymbol?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  wallet?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: pending_txs */
export type PendingTxsPkColumnsInput = {
  id: Scalars['Int']['input'];
};

/** select columns of table "pending_txs" */
export enum PendingTxsSelectColumn {
  /** column name */
  Amount = 'amount',
  /** column name */
  BlockNumber = 'blockNumber',
  /** column name */
  BlockchainCurrencyId = 'blockchainCurrencyId',
  /** column name */
  BlockchainId = 'blockchainId',
  /** column name */
  ContractAddress = 'contractAddress',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  From = 'from',
  /** column name */
  Function = 'function',
  /** column name */
  Hash = 'hash',
  /** column name */
  Id = 'id',
  /** column name */
  TimeStamp = 'timeStamp',
  /** column name */
  To = 'to',
  /** column name */
  TokenSymbol = 'tokenSymbol',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Wallet = 'wallet',
}

/** input type for updating data in table "pending_txs" */
export type PendingTxsSetInput = {
  amount?: InputMaybe<Scalars['numeric']['input']>;
  blockNumber?: InputMaybe<Scalars['Int']['input']>;
  blockchainCurrencyId?: InputMaybe<Scalars['Int']['input']>;
  blockchainId?: InputMaybe<Scalars['Int']['input']>;
  contractAddress?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  from?: InputMaybe<Scalars['String']['input']>;
  function?: InputMaybe<Scalars['String']['input']>;
  hash?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  timeStamp?: InputMaybe<Scalars['Int']['input']>;
  to?: InputMaybe<Scalars['String']['input']>;
  tokenSymbol?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  wallet?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type PendingTxsStddevFields = {
  __typename?: 'PendingTxsStddevFields';
  amount?: Maybe<Scalars['Float']['output']>;
  blockNumber?: Maybe<Scalars['Float']['output']>;
  blockchainCurrencyId?: Maybe<Scalars['Float']['output']>;
  blockchainId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  timeStamp?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type PendingTxsStddev_PopFields = {
  __typename?: 'PendingTxsStddev_popFields';
  amount?: Maybe<Scalars['Float']['output']>;
  blockNumber?: Maybe<Scalars['Float']['output']>;
  blockchainCurrencyId?: Maybe<Scalars['Float']['output']>;
  blockchainId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  timeStamp?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type PendingTxsStddev_SampFields = {
  __typename?: 'PendingTxsStddev_sampFields';
  amount?: Maybe<Scalars['Float']['output']>;
  blockNumber?: Maybe<Scalars['Float']['output']>;
  blockchainCurrencyId?: Maybe<Scalars['Float']['output']>;
  blockchainId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  timeStamp?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "pending_txs" */
export type PendingTxsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: PendingTxsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type PendingTxsStreamCursorValueInput = {
  amount?: InputMaybe<Scalars['numeric']['input']>;
  blockNumber?: InputMaybe<Scalars['Int']['input']>;
  blockchainCurrencyId?: InputMaybe<Scalars['Int']['input']>;
  blockchainId?: InputMaybe<Scalars['Int']['input']>;
  contractAddress?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  from?: InputMaybe<Scalars['String']['input']>;
  function?: InputMaybe<Scalars['String']['input']>;
  hash?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  timeStamp?: InputMaybe<Scalars['Int']['input']>;
  to?: InputMaybe<Scalars['String']['input']>;
  tokenSymbol?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  wallet?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type PendingTxsSumFields = {
  __typename?: 'PendingTxsSumFields';
  amount?: Maybe<Scalars['numeric']['output']>;
  blockNumber?: Maybe<Scalars['Int']['output']>;
  blockchainCurrencyId?: Maybe<Scalars['Int']['output']>;
  blockchainId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  timeStamp?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "pending_txs" */
export enum PendingTxsUpdateColumn {
  /** column name */
  Amount = 'amount',
  /** column name */
  BlockNumber = 'blockNumber',
  /** column name */
  BlockchainCurrencyId = 'blockchainCurrencyId',
  /** column name */
  BlockchainId = 'blockchainId',
  /** column name */
  ContractAddress = 'contractAddress',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  From = 'from',
  /** column name */
  Function = 'function',
  /** column name */
  Hash = 'hash',
  /** column name */
  Id = 'id',
  /** column name */
  TimeStamp = 'timeStamp',
  /** column name */
  To = 'to',
  /** column name */
  TokenSymbol = 'tokenSymbol',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Wallet = 'wallet',
}

export type PendingTxsUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<PendingTxsIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PendingTxsSetInput>;
  /** filter the rows which have to be updated */
  where: PendingTxsBoolExp;
};

/** aggregate var_pop on columns */
export type PendingTxsVar_PopFields = {
  __typename?: 'PendingTxsVar_popFields';
  amount?: Maybe<Scalars['Float']['output']>;
  blockNumber?: Maybe<Scalars['Float']['output']>;
  blockchainCurrencyId?: Maybe<Scalars['Float']['output']>;
  blockchainId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  timeStamp?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type PendingTxsVar_SampFields = {
  __typename?: 'PendingTxsVar_sampFields';
  amount?: Maybe<Scalars['Float']['output']>;
  blockNumber?: Maybe<Scalars['Float']['output']>;
  blockchainCurrencyId?: Maybe<Scalars['Float']['output']>;
  blockchainId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  timeStamp?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type PendingTxsVarianceFields = {
  __typename?: 'PendingTxsVarianceFields';
  amount?: Maybe<Scalars['Float']['output']>;
  blockNumber?: Maybe<Scalars['Float']['output']>;
  blockchainCurrencyId?: Maybe<Scalars['Float']['output']>;
  blockchainId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  timeStamp?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "player_followers" */
export type PlayerFollowers = {
  __typename?: 'PlayerFollowers';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  playerId?: Maybe<Scalars['uuid']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "player_followers" */
export type PlayerFollowersAggregate = {
  __typename?: 'PlayerFollowersAggregate';
  aggregate?: Maybe<PlayerFollowersAggregateFields>;
  nodes: Array<PlayerFollowers>;
};

/** aggregate fields of "player_followers" */
export type PlayerFollowersAggregateFields = {
  __typename?: 'PlayerFollowersAggregateFields';
  avg?: Maybe<PlayerFollowersAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<PlayerFollowersMaxFields>;
  min?: Maybe<PlayerFollowersMinFields>;
  stddev?: Maybe<PlayerFollowersStddevFields>;
  stddevPop?: Maybe<PlayerFollowersStddev_PopFields>;
  stddevSamp?: Maybe<PlayerFollowersStddev_SampFields>;
  sum?: Maybe<PlayerFollowersSumFields>;
  varPop?: Maybe<PlayerFollowersVar_PopFields>;
  varSamp?: Maybe<PlayerFollowersVar_SampFields>;
  variance?: Maybe<PlayerFollowersVarianceFields>;
};

/** aggregate fields of "player_followers" */
export type PlayerFollowersAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<PlayerFollowersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type PlayerFollowersAvgFields = {
  __typename?: 'PlayerFollowersAvgFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "player_followers". All fields are combined with a logical 'AND'. */
export type PlayerFollowersBoolExp = {
  _and?: InputMaybe<Array<PlayerFollowersBoolExp>>;
  _not?: InputMaybe<PlayerFollowersBoolExp>;
  _or?: InputMaybe<Array<PlayerFollowersBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  playerId?: InputMaybe<UuidComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  userId?: InputMaybe<StringComparisonExp>;
};

/** input type for incrementing numeric columns in table "player_followers" */
export type PlayerFollowersIncInput = {
  id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "player_followers" */
export type PlayerFollowersInsertInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  playerId?: InputMaybe<Scalars['uuid']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type PlayerFollowersMaxFields = {
  __typename?: 'PlayerFollowersMaxFields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  playerId?: Maybe<Scalars['uuid']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type PlayerFollowersMinFields = {
  __typename?: 'PlayerFollowersMinFields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  playerId?: Maybe<Scalars['uuid']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "player_followers" */
export type PlayerFollowersMutationResponse = {
  __typename?: 'PlayerFollowersMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<PlayerFollowers>;
};

/** Ordering options when selecting data from "player_followers". */
export type PlayerFollowersOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  playerId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** select columns of table "player_followers" */
export enum PlayerFollowersSelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  PlayerId = 'playerId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId',
}

/** input type for updating data in table "player_followers" */
export type PlayerFollowersSetInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  playerId?: InputMaybe<Scalars['uuid']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type PlayerFollowersStddevFields = {
  __typename?: 'PlayerFollowersStddevFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type PlayerFollowersStddev_PopFields = {
  __typename?: 'PlayerFollowersStddev_popFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type PlayerFollowersStddev_SampFields = {
  __typename?: 'PlayerFollowersStddev_sampFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "player_followers" */
export type PlayerFollowersStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: PlayerFollowersStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type PlayerFollowersStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  playerId?: InputMaybe<Scalars['uuid']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type PlayerFollowersSumFields = {
  __typename?: 'PlayerFollowersSumFields';
  id?: Maybe<Scalars['bigint']['output']>;
};

export type PlayerFollowersUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<PlayerFollowersIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PlayerFollowersSetInput>;
  /** filter the rows which have to be updated */
  where: PlayerFollowersBoolExp;
};

/** aggregate var_pop on columns */
export type PlayerFollowersVar_PopFields = {
  __typename?: 'PlayerFollowersVar_popFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type PlayerFollowersVar_SampFields = {
  __typename?: 'PlayerFollowersVar_sampFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type PlayerFollowersVarianceFields = {
  __typename?: 'PlayerFollowersVarianceFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "player_followers_view" */
export type PlayerFollowersView = {
  __typename?: 'PlayerFollowersView';
  avatar?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  erc20Address?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  /** An object relationship */
  player?: Maybe<Players>;
  playerId?: Maybe<Scalars['uuid']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "player_followers_view" */
export type PlayerFollowersViewAggregate = {
  __typename?: 'PlayerFollowersViewAggregate';
  aggregate?: Maybe<PlayerFollowersViewAggregateFields>;
  nodes: Array<PlayerFollowersView>;
};

/** aggregate fields of "player_followers_view" */
export type PlayerFollowersViewAggregateFields = {
  __typename?: 'PlayerFollowersViewAggregateFields';
  avg?: Maybe<PlayerFollowersViewAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<PlayerFollowersViewMaxFields>;
  min?: Maybe<PlayerFollowersViewMinFields>;
  stddev?: Maybe<PlayerFollowersViewStddevFields>;
  stddevPop?: Maybe<PlayerFollowersViewStddev_PopFields>;
  stddevSamp?: Maybe<PlayerFollowersViewStddev_SampFields>;
  sum?: Maybe<PlayerFollowersViewSumFields>;
  varPop?: Maybe<PlayerFollowersViewVar_PopFields>;
  varSamp?: Maybe<PlayerFollowersViewVar_SampFields>;
  variance?: Maybe<PlayerFollowersViewVarianceFields>;
};

/** aggregate fields of "player_followers_view" */
export type PlayerFollowersViewAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<PlayerFollowersViewSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "player_followers_view" */
export type PlayerFollowersViewAggregateOrderBy = {
  avg?: InputMaybe<Player_Followers_View_Avg_Order_By>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<Player_Followers_View_Max_Order_By>;
  min?: InputMaybe<Player_Followers_View_Min_Order_By>;
  stddev?: InputMaybe<Player_Followers_View_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Player_Followers_View_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Player_Followers_View_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Player_Followers_View_Sum_Order_By>;
  var_pop?: InputMaybe<Player_Followers_View_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Player_Followers_View_Var_Samp_Order_By>;
  variance?: InputMaybe<Player_Followers_View_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "player_followers_view" */
export type PlayerFollowersViewArrRelInsertInput = {
  data: Array<PlayerFollowersViewInsertInput>;
};

/** aggregate avg on columns */
export type PlayerFollowersViewAvgFields = {
  __typename?: 'PlayerFollowersViewAvgFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "player_followers_view". All fields are combined with a logical 'AND'. */
export type PlayerFollowersViewBoolExp = {
  _and?: InputMaybe<Array<PlayerFollowersViewBoolExp>>;
  _not?: InputMaybe<PlayerFollowersViewBoolExp>;
  _or?: InputMaybe<Array<PlayerFollowersViewBoolExp>>;
  avatar?: InputMaybe<StringComparisonExp>;
  email?: InputMaybe<StringComparisonExp>;
  erc20Address?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  player?: InputMaybe<PlayersBoolExp>;
  playerId?: InputMaybe<UuidComparisonExp>;
  username?: InputMaybe<StringComparisonExp>;
};

/** input type for inserting data into table "player_followers_view" */
export type PlayerFollowersViewInsertInput = {
  avatar?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  erc20Address?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  player?: InputMaybe<PlayersObjRelInsertInput>;
  playerId?: InputMaybe<Scalars['uuid']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type PlayerFollowersViewMaxFields = {
  __typename?: 'PlayerFollowersViewMaxFields';
  avatar?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  erc20Address?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  playerId?: Maybe<Scalars['uuid']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type PlayerFollowersViewMinFields = {
  __typename?: 'PlayerFollowersViewMinFields';
  avatar?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  erc20Address?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  playerId?: Maybe<Scalars['uuid']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

/** Ordering options when selecting data from "player_followers_view". */
export type PlayerFollowersViewOrderBy = {
  avatar?: InputMaybe<OrderBy>;
  email?: InputMaybe<OrderBy>;
  erc20Address?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  player?: InputMaybe<PlayersOrderBy>;
  playerId?: InputMaybe<OrderBy>;
  username?: InputMaybe<OrderBy>;
};

/** select columns of table "player_followers_view" */
export enum PlayerFollowersViewSelectColumn {
  /** column name */
  Avatar = 'avatar',
  /** column name */
  Email = 'email',
  /** column name */
  Erc20Address = 'erc20Address',
  /** column name */
  Id = 'id',
  /** column name */
  PlayerId = 'playerId',
  /** column name */
  Username = 'username',
}

/** aggregate stddev on columns */
export type PlayerFollowersViewStddevFields = {
  __typename?: 'PlayerFollowersViewStddevFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type PlayerFollowersViewStddev_PopFields = {
  __typename?: 'PlayerFollowersViewStddev_popFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type PlayerFollowersViewStddev_SampFields = {
  __typename?: 'PlayerFollowersViewStddev_sampFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "player_followers_view" */
export type PlayerFollowersViewStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: PlayerFollowersViewStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type PlayerFollowersViewStreamCursorValueInput = {
  avatar?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  erc20Address?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  playerId?: InputMaybe<Scalars['uuid']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type PlayerFollowersViewSumFields = {
  __typename?: 'PlayerFollowersViewSumFields';
  id?: Maybe<Scalars['bigint']['output']>;
};

/** aggregate var_pop on columns */
export type PlayerFollowersViewVar_PopFields = {
  __typename?: 'PlayerFollowersViewVar_popFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type PlayerFollowersViewVar_SampFields = {
  __typename?: 'PlayerFollowersViewVar_sampFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type PlayerFollowersViewVarianceFields = {
  __typename?: 'PlayerFollowersViewVarianceFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "player_shares" */
export type PlayerShares = {
  __typename?: 'PlayerShares';
  activeDate: Scalars['timestamptz']['output'];
  allowCancel: Scalars['Boolean']['output'];
  bullets: Scalars['Int']['output'];
  createdAt: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  markUp: Scalars['numeric']['output'];
  maxPerUserShare?: Maybe<Scalars['numeric']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  password?: Maybe<Scalars['String']['output']>;
  passwordRequired: Scalars['Boolean']['output'];
  /** An object relationship */
  player: Players;
  playerId: Scalars['uuid']['output'];
  /** An object relationship */
  player_shares_bought?: Maybe<PlayerSharesBought>;
  shareDilution: Scalars['Boolean']['output'];
  sharesToSell: Scalars['Int']['output'];
  slug?: Maybe<Scalars['String']['output']>;
  sort: Scalars['Int']['output'];
  status: StatusGamesEnum;
  termsAcceptedDate: Scalars['timestamptz']['output'];
  /** An array relationship */
  tickets: Array<Tickets>;
  /** An aggregate relationship */
  ticketsAggregate: TicketsAggregate;
  /** An object relationship */
  tournament: Tournaments;
  tournamentId: Scalars['uuid']['output'];
  tournamentResultId?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  tournament_result?: Maybe<TournamentResults>;
  /** An array relationship */
  transactions: Array<Transactions>;
  /** An aggregate relationship */
  transactionsAggregate: TransactionsAggregate;
  updatedAt: Scalars['timestamptz']['output'];
  /** An aggregate relationship */
  userShareBuysAggregate: UserShareBuysAggregate;
  /** An array relationship */
  user_share_buys: Array<UserShareBuys>;
};

/** columns and relationships of "player_shares" */
export type PlayerSharesTicketsArgs = {
  distinctOn?: InputMaybe<Array<TicketsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TicketsOrderBy>>;
  where?: InputMaybe<TicketsBoolExp>;
};

/** columns and relationships of "player_shares" */
export type PlayerSharesTicketsAggregateArgs = {
  distinctOn?: InputMaybe<Array<TicketsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TicketsOrderBy>>;
  where?: InputMaybe<TicketsBoolExp>;
};

/** columns and relationships of "player_shares" */
export type PlayerSharesTransactionsArgs = {
  distinctOn?: InputMaybe<Array<TransactionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TransactionsOrderBy>>;
  where?: InputMaybe<TransactionsBoolExp>;
};

/** columns and relationships of "player_shares" */
export type PlayerSharesTransactionsAggregateArgs = {
  distinctOn?: InputMaybe<Array<TransactionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TransactionsOrderBy>>;
  where?: InputMaybe<TransactionsBoolExp>;
};

/** columns and relationships of "player_shares" */
export type PlayerSharesUserShareBuysAggregateArgs = {
  distinctOn?: InputMaybe<Array<UserShareBuysSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UserShareBuysOrderBy>>;
  where?: InputMaybe<UserShareBuysBoolExp>;
};

/** columns and relationships of "player_shares" */
export type PlayerSharesUser_Share_BuysArgs = {
  distinctOn?: InputMaybe<Array<UserShareBuysSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UserShareBuysOrderBy>>;
  where?: InputMaybe<UserShareBuysBoolExp>;
};

/** aggregated selection of "player_shares" */
export type PlayerSharesAggregate = {
  __typename?: 'PlayerSharesAggregate';
  aggregate?: Maybe<PlayerSharesAggregateFields>;
  nodes: Array<PlayerShares>;
};

/** aggregate fields of "player_shares" */
export type PlayerSharesAggregateFields = {
  __typename?: 'PlayerSharesAggregateFields';
  avg?: Maybe<PlayerSharesAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<PlayerSharesMaxFields>;
  min?: Maybe<PlayerSharesMinFields>;
  stddev?: Maybe<PlayerSharesStddevFields>;
  stddevPop?: Maybe<PlayerSharesStddev_PopFields>;
  stddevSamp?: Maybe<PlayerSharesStddev_SampFields>;
  sum?: Maybe<PlayerSharesSumFields>;
  varPop?: Maybe<PlayerSharesVar_PopFields>;
  varSamp?: Maybe<PlayerSharesVar_SampFields>;
  variance?: Maybe<PlayerSharesVarianceFields>;
};

/** aggregate fields of "player_shares" */
export type PlayerSharesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<PlayerSharesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "player_shares" */
export type PlayerSharesAggregateOrderBy = {
  avg?: InputMaybe<Player_Shares_Avg_Order_By>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<Player_Shares_Max_Order_By>;
  min?: InputMaybe<Player_Shares_Min_Order_By>;
  stddev?: InputMaybe<Player_Shares_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Player_Shares_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Player_Shares_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Player_Shares_Sum_Order_By>;
  var_pop?: InputMaybe<Player_Shares_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Player_Shares_Var_Samp_Order_By>;
  variance?: InputMaybe<Player_Shares_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "player_shares" */
export type PlayerSharesArrRelInsertInput = {
  data: Array<PlayerSharesInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<PlayerSharesOnConflict>;
};

/** aggregate avg on columns */
export type PlayerSharesAvgFields = {
  __typename?: 'PlayerSharesAvgFields';
  bullets?: Maybe<Scalars['Float']['output']>;
  markUp?: Maybe<Scalars['Float']['output']>;
  maxPerUserShare?: Maybe<Scalars['Float']['output']>;
  sharesToSell?: Maybe<Scalars['Float']['output']>;
  sort?: Maybe<Scalars['Float']['output']>;
  tournamentResultId?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "player_shares". All fields are combined with a logical 'AND'. */
export type PlayerSharesBoolExp = {
  _and?: InputMaybe<Array<PlayerSharesBoolExp>>;
  _not?: InputMaybe<PlayerSharesBoolExp>;
  _or?: InputMaybe<Array<PlayerSharesBoolExp>>;
  activeDate?: InputMaybe<TimestamptzComparisonExp>;
  allowCancel?: InputMaybe<BooleanComparisonExp>;
  bullets?: InputMaybe<IntComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  markUp?: InputMaybe<NumericComparisonExp>;
  maxPerUserShare?: InputMaybe<NumericComparisonExp>;
  note?: InputMaybe<StringComparisonExp>;
  password?: InputMaybe<StringComparisonExp>;
  passwordRequired?: InputMaybe<BooleanComparisonExp>;
  player?: InputMaybe<PlayersBoolExp>;
  playerId?: InputMaybe<UuidComparisonExp>;
  player_shares_bought?: InputMaybe<PlayerSharesBoughtBoolExp>;
  shareDilution?: InputMaybe<BooleanComparisonExp>;
  sharesToSell?: InputMaybe<IntComparisonExp>;
  slug?: InputMaybe<StringComparisonExp>;
  sort?: InputMaybe<IntComparisonExp>;
  status?: InputMaybe<StatusGamesEnumComparisonExp>;
  termsAcceptedDate?: InputMaybe<TimestamptzComparisonExp>;
  tickets?: InputMaybe<TicketsBoolExp>;
  tickets_aggregate?: InputMaybe<Tickets_Aggregate_Bool_Exp>;
  tournament?: InputMaybe<TournamentsBoolExp>;
  tournamentId?: InputMaybe<UuidComparisonExp>;
  tournamentResultId?: InputMaybe<IntComparisonExp>;
  tournament_result?: InputMaybe<TournamentResultsBoolExp>;
  transactions?: InputMaybe<TransactionsBoolExp>;
  transactions_aggregate?: InputMaybe<Transactions_Aggregate_Bool_Exp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  user_share_buys?: InputMaybe<UserShareBuysBoolExp>;
  user_share_buys_aggregate?: InputMaybe<User_Share_Buys_Aggregate_Bool_Exp>;
};

/** columns and relationships of "player_shares_bought" */
export type PlayerSharesBought = {
  __typename?: 'PlayerSharesBought';
  playerShareId?: Maybe<Scalars['uuid']['output']>;
  totalMarkupAmount?: Maybe<Scalars['numeric']['output']>;
  totalShareAmount?: Maybe<Scalars['numeric']['output']>;
  totalSharePercentage?: Maybe<Scalars['numeric']['output']>;
  totalTotalAmount?: Maybe<Scalars['numeric']['output']>;
};

/** aggregated selection of "player_shares_bought" */
export type PlayerSharesBoughtAggregate = {
  __typename?: 'PlayerSharesBoughtAggregate';
  aggregate?: Maybe<PlayerSharesBoughtAggregateFields>;
  nodes: Array<PlayerSharesBought>;
};

/** aggregate fields of "player_shares_bought" */
export type PlayerSharesBoughtAggregateFields = {
  __typename?: 'PlayerSharesBoughtAggregateFields';
  avg?: Maybe<PlayerSharesBoughtAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<PlayerSharesBoughtMaxFields>;
  min?: Maybe<PlayerSharesBoughtMinFields>;
  stddev?: Maybe<PlayerSharesBoughtStddevFields>;
  stddevPop?: Maybe<PlayerSharesBoughtStddev_PopFields>;
  stddevSamp?: Maybe<PlayerSharesBoughtStddev_SampFields>;
  sum?: Maybe<PlayerSharesBoughtSumFields>;
  varPop?: Maybe<PlayerSharesBoughtVar_PopFields>;
  varSamp?: Maybe<PlayerSharesBoughtVar_SampFields>;
  variance?: Maybe<PlayerSharesBoughtVarianceFields>;
};

/** aggregate fields of "player_shares_bought" */
export type PlayerSharesBoughtAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<PlayerSharesBoughtSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type PlayerSharesBoughtAvgFields = {
  __typename?: 'PlayerSharesBoughtAvgFields';
  totalMarkupAmount?: Maybe<Scalars['Float']['output']>;
  totalShareAmount?: Maybe<Scalars['Float']['output']>;
  totalSharePercentage?: Maybe<Scalars['Float']['output']>;
  totalTotalAmount?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "player_shares_bought". All fields are combined with a logical 'AND'. */
export type PlayerSharesBoughtBoolExp = {
  _and?: InputMaybe<Array<PlayerSharesBoughtBoolExp>>;
  _not?: InputMaybe<PlayerSharesBoughtBoolExp>;
  _or?: InputMaybe<Array<PlayerSharesBoughtBoolExp>>;
  playerShareId?: InputMaybe<UuidComparisonExp>;
  totalMarkupAmount?: InputMaybe<NumericComparisonExp>;
  totalShareAmount?: InputMaybe<NumericComparisonExp>;
  totalSharePercentage?: InputMaybe<NumericComparisonExp>;
  totalTotalAmount?: InputMaybe<NumericComparisonExp>;
};

/** input type for inserting data into table "player_shares_bought" */
export type PlayerSharesBoughtInsertInput = {
  playerShareId?: InputMaybe<Scalars['uuid']['input']>;
  totalMarkupAmount?: InputMaybe<Scalars['numeric']['input']>;
  totalShareAmount?: InputMaybe<Scalars['numeric']['input']>;
  totalSharePercentage?: InputMaybe<Scalars['numeric']['input']>;
  totalTotalAmount?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate max on columns */
export type PlayerSharesBoughtMaxFields = {
  __typename?: 'PlayerSharesBoughtMaxFields';
  playerShareId?: Maybe<Scalars['uuid']['output']>;
  totalMarkupAmount?: Maybe<Scalars['numeric']['output']>;
  totalShareAmount?: Maybe<Scalars['numeric']['output']>;
  totalSharePercentage?: Maybe<Scalars['numeric']['output']>;
  totalTotalAmount?: Maybe<Scalars['numeric']['output']>;
};

/** aggregate min on columns */
export type PlayerSharesBoughtMinFields = {
  __typename?: 'PlayerSharesBoughtMinFields';
  playerShareId?: Maybe<Scalars['uuid']['output']>;
  totalMarkupAmount?: Maybe<Scalars['numeric']['output']>;
  totalShareAmount?: Maybe<Scalars['numeric']['output']>;
  totalSharePercentage?: Maybe<Scalars['numeric']['output']>;
  totalTotalAmount?: Maybe<Scalars['numeric']['output']>;
};

/** input type for inserting object relation for remote table "player_shares_bought" */
export type PlayerSharesBoughtObjRelInsertInput = {
  data: PlayerSharesBoughtInsertInput;
};

/** Ordering options when selecting data from "player_shares_bought". */
export type PlayerSharesBoughtOrderBy = {
  playerShareId?: InputMaybe<OrderBy>;
  totalMarkupAmount?: InputMaybe<OrderBy>;
  totalShareAmount?: InputMaybe<OrderBy>;
  totalSharePercentage?: InputMaybe<OrderBy>;
  totalTotalAmount?: InputMaybe<OrderBy>;
};

/** select columns of table "player_shares_bought" */
export enum PlayerSharesBoughtSelectColumn {
  /** column name */
  PlayerShareId = 'playerShareId',
  /** column name */
  TotalMarkupAmount = 'totalMarkupAmount',
  /** column name */
  TotalShareAmount = 'totalShareAmount',
  /** column name */
  TotalSharePercentage = 'totalSharePercentage',
  /** column name */
  TotalTotalAmount = 'totalTotalAmount',
}

/** aggregate stddev on columns */
export type PlayerSharesBoughtStddevFields = {
  __typename?: 'PlayerSharesBoughtStddevFields';
  totalMarkupAmount?: Maybe<Scalars['Float']['output']>;
  totalShareAmount?: Maybe<Scalars['Float']['output']>;
  totalSharePercentage?: Maybe<Scalars['Float']['output']>;
  totalTotalAmount?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type PlayerSharesBoughtStddev_PopFields = {
  __typename?: 'PlayerSharesBoughtStddev_popFields';
  totalMarkupAmount?: Maybe<Scalars['Float']['output']>;
  totalShareAmount?: Maybe<Scalars['Float']['output']>;
  totalSharePercentage?: Maybe<Scalars['Float']['output']>;
  totalTotalAmount?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type PlayerSharesBoughtStddev_SampFields = {
  __typename?: 'PlayerSharesBoughtStddev_sampFields';
  totalMarkupAmount?: Maybe<Scalars['Float']['output']>;
  totalShareAmount?: Maybe<Scalars['Float']['output']>;
  totalSharePercentage?: Maybe<Scalars['Float']['output']>;
  totalTotalAmount?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "player_shares_bought" */
export type PlayerSharesBoughtStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: PlayerSharesBoughtStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type PlayerSharesBoughtStreamCursorValueInput = {
  playerShareId?: InputMaybe<Scalars['uuid']['input']>;
  totalMarkupAmount?: InputMaybe<Scalars['numeric']['input']>;
  totalShareAmount?: InputMaybe<Scalars['numeric']['input']>;
  totalSharePercentage?: InputMaybe<Scalars['numeric']['input']>;
  totalTotalAmount?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate sum on columns */
export type PlayerSharesBoughtSumFields = {
  __typename?: 'PlayerSharesBoughtSumFields';
  totalMarkupAmount?: Maybe<Scalars['numeric']['output']>;
  totalShareAmount?: Maybe<Scalars['numeric']['output']>;
  totalSharePercentage?: Maybe<Scalars['numeric']['output']>;
  totalTotalAmount?: Maybe<Scalars['numeric']['output']>;
};

/** aggregate var_pop on columns */
export type PlayerSharesBoughtVar_PopFields = {
  __typename?: 'PlayerSharesBoughtVar_popFields';
  totalMarkupAmount?: Maybe<Scalars['Float']['output']>;
  totalShareAmount?: Maybe<Scalars['Float']['output']>;
  totalSharePercentage?: Maybe<Scalars['Float']['output']>;
  totalTotalAmount?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type PlayerSharesBoughtVar_SampFields = {
  __typename?: 'PlayerSharesBoughtVar_sampFields';
  totalMarkupAmount?: Maybe<Scalars['Float']['output']>;
  totalShareAmount?: Maybe<Scalars['Float']['output']>;
  totalSharePercentage?: Maybe<Scalars['Float']['output']>;
  totalTotalAmount?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type PlayerSharesBoughtVarianceFields = {
  __typename?: 'PlayerSharesBoughtVarianceFields';
  totalMarkupAmount?: Maybe<Scalars['Float']['output']>;
  totalShareAmount?: Maybe<Scalars['Float']['output']>;
  totalSharePercentage?: Maybe<Scalars['Float']['output']>;
  totalTotalAmount?: Maybe<Scalars['Float']['output']>;
};

/** unique or primary key constraints on table "player_shares" */
export enum PlayerSharesConstraint {
  /** unique or primary key constraint on columns "id" */
  PlayerSharesPkey = 'player_shares_pkey',
}

/** input type for incrementing numeric columns in table "player_shares" */
export type PlayerSharesIncInput = {
  bullets?: InputMaybe<Scalars['Int']['input']>;
  markUp?: InputMaybe<Scalars['numeric']['input']>;
  maxPerUserShare?: InputMaybe<Scalars['numeric']['input']>;
  sharesToSell?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Scalars['Int']['input']>;
  tournamentResultId?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "player_shares" */
export type PlayerSharesInsertInput = {
  activeDate?: InputMaybe<Scalars['timestamptz']['input']>;
  allowCancel?: InputMaybe<Scalars['Boolean']['input']>;
  bullets?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  markUp?: InputMaybe<Scalars['numeric']['input']>;
  maxPerUserShare?: InputMaybe<Scalars['numeric']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  passwordRequired?: InputMaybe<Scalars['Boolean']['input']>;
  player?: InputMaybe<PlayersObjRelInsertInput>;
  playerId?: InputMaybe<Scalars['uuid']['input']>;
  player_shares_bought?: InputMaybe<PlayerSharesBoughtObjRelInsertInput>;
  shareDilution?: InputMaybe<Scalars['Boolean']['input']>;
  sharesToSell?: InputMaybe<Scalars['Int']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<StatusGamesEnum>;
  termsAcceptedDate?: InputMaybe<Scalars['timestamptz']['input']>;
  tickets?: InputMaybe<TicketsArrRelInsertInput>;
  tournament?: InputMaybe<TournamentsObjRelInsertInput>;
  tournamentId?: InputMaybe<Scalars['uuid']['input']>;
  tournamentResultId?: InputMaybe<Scalars['Int']['input']>;
  tournament_result?: InputMaybe<TournamentResultsObjRelInsertInput>;
  transactions?: InputMaybe<TransactionsArrRelInsertInput>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  user_share_buys?: InputMaybe<UserShareBuysArrRelInsertInput>;
};

/** aggregate max on columns */
export type PlayerSharesMaxFields = {
  __typename?: 'PlayerSharesMaxFields';
  activeDate?: Maybe<Scalars['timestamptz']['output']>;
  bullets?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  markUp?: Maybe<Scalars['numeric']['output']>;
  maxPerUserShare?: Maybe<Scalars['numeric']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  password?: Maybe<Scalars['String']['output']>;
  playerId?: Maybe<Scalars['uuid']['output']>;
  sharesToSell?: Maybe<Scalars['Int']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  sort?: Maybe<Scalars['Int']['output']>;
  termsAcceptedDate?: Maybe<Scalars['timestamptz']['output']>;
  tournamentId?: Maybe<Scalars['uuid']['output']>;
  tournamentResultId?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type PlayerSharesMinFields = {
  __typename?: 'PlayerSharesMinFields';
  activeDate?: Maybe<Scalars['timestamptz']['output']>;
  bullets?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  markUp?: Maybe<Scalars['numeric']['output']>;
  maxPerUserShare?: Maybe<Scalars['numeric']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  password?: Maybe<Scalars['String']['output']>;
  playerId?: Maybe<Scalars['uuid']['output']>;
  sharesToSell?: Maybe<Scalars['Int']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  sort?: Maybe<Scalars['Int']['output']>;
  termsAcceptedDate?: Maybe<Scalars['timestamptz']['output']>;
  tournamentId?: Maybe<Scalars['uuid']['output']>;
  tournamentResultId?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "player_shares" */
export type PlayerSharesMutationResponse = {
  __typename?: 'PlayerSharesMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<PlayerShares>;
};

/** input type for inserting object relation for remote table "player_shares" */
export type PlayerSharesObjRelInsertInput = {
  data: PlayerSharesInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<PlayerSharesOnConflict>;
};

/** on_conflict condition type for table "player_shares" */
export type PlayerSharesOnConflict = {
  constraint: PlayerSharesConstraint;
  update_columns?: Array<PlayerSharesUpdateColumn>;
  where?: InputMaybe<PlayerSharesBoolExp>;
};

/** Ordering options when selecting data from "player_shares". */
export type PlayerSharesOrderBy = {
  activeDate?: InputMaybe<OrderBy>;
  allowCancel?: InputMaybe<OrderBy>;
  bullets?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  markUp?: InputMaybe<OrderBy>;
  maxPerUserShare?: InputMaybe<OrderBy>;
  note?: InputMaybe<OrderBy>;
  password?: InputMaybe<OrderBy>;
  passwordRequired?: InputMaybe<OrderBy>;
  player?: InputMaybe<PlayersOrderBy>;
  playerId?: InputMaybe<OrderBy>;
  player_shares_bought?: InputMaybe<PlayerSharesBoughtOrderBy>;
  shareDilution?: InputMaybe<OrderBy>;
  sharesToSell?: InputMaybe<OrderBy>;
  slug?: InputMaybe<OrderBy>;
  sort?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  termsAcceptedDate?: InputMaybe<OrderBy>;
  ticketsAggregate?: InputMaybe<TicketsAggregateOrderBy>;
  tournament?: InputMaybe<TournamentsOrderBy>;
  tournamentId?: InputMaybe<OrderBy>;
  tournamentResultId?: InputMaybe<OrderBy>;
  tournament_result?: InputMaybe<TournamentResultsOrderBy>;
  transactionsAggregate?: InputMaybe<TransactionsAggregateOrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  user_share_buysAggregate?: InputMaybe<UserShareBuysAggregateOrderBy>;
};

/** primary key columns input for table: player_shares */
export type PlayerSharesPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "player_shares" */
export enum PlayerSharesSelectColumn {
  /** column name */
  ActiveDate = 'activeDate',
  /** column name */
  AllowCancel = 'allowCancel',
  /** column name */
  Bullets = 'bullets',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  MarkUp = 'markUp',
  /** column name */
  MaxPerUserShare = 'maxPerUserShare',
  /** column name */
  Note = 'note',
  /** column name */
  Password = 'password',
  /** column name */
  PasswordRequired = 'passwordRequired',
  /** column name */
  PlayerId = 'playerId',
  /** column name */
  ShareDilution = 'shareDilution',
  /** column name */
  SharesToSell = 'sharesToSell',
  /** column name */
  Slug = 'slug',
  /** column name */
  Sort = 'sort',
  /** column name */
  Status = 'status',
  /** column name */
  TermsAcceptedDate = 'termsAcceptedDate',
  /** column name */
  TournamentId = 'tournamentId',
  /** column name */
  TournamentResultId = 'tournamentResultId',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** input type for updating data in table "player_shares" */
export type PlayerSharesSetInput = {
  activeDate?: InputMaybe<Scalars['timestamptz']['input']>;
  allowCancel?: InputMaybe<Scalars['Boolean']['input']>;
  bullets?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  markUp?: InputMaybe<Scalars['numeric']['input']>;
  maxPerUserShare?: InputMaybe<Scalars['numeric']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  passwordRequired?: InputMaybe<Scalars['Boolean']['input']>;
  playerId?: InputMaybe<Scalars['uuid']['input']>;
  shareDilution?: InputMaybe<Scalars['Boolean']['input']>;
  sharesToSell?: InputMaybe<Scalars['Int']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<StatusGamesEnum>;
  termsAcceptedDate?: InputMaybe<Scalars['timestamptz']['input']>;
  tournamentId?: InputMaybe<Scalars['uuid']['input']>;
  tournamentResultId?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type PlayerSharesStddevFields = {
  __typename?: 'PlayerSharesStddevFields';
  bullets?: Maybe<Scalars['Float']['output']>;
  markUp?: Maybe<Scalars['Float']['output']>;
  maxPerUserShare?: Maybe<Scalars['Float']['output']>;
  sharesToSell?: Maybe<Scalars['Float']['output']>;
  sort?: Maybe<Scalars['Float']['output']>;
  tournamentResultId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type PlayerSharesStddev_PopFields = {
  __typename?: 'PlayerSharesStddev_popFields';
  bullets?: Maybe<Scalars['Float']['output']>;
  markUp?: Maybe<Scalars['Float']['output']>;
  maxPerUserShare?: Maybe<Scalars['Float']['output']>;
  sharesToSell?: Maybe<Scalars['Float']['output']>;
  sort?: Maybe<Scalars['Float']['output']>;
  tournamentResultId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type PlayerSharesStddev_SampFields = {
  __typename?: 'PlayerSharesStddev_sampFields';
  bullets?: Maybe<Scalars['Float']['output']>;
  markUp?: Maybe<Scalars['Float']['output']>;
  maxPerUserShare?: Maybe<Scalars['Float']['output']>;
  sharesToSell?: Maybe<Scalars['Float']['output']>;
  sort?: Maybe<Scalars['Float']['output']>;
  tournamentResultId?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "player_shares" */
export type PlayerSharesStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: PlayerSharesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type PlayerSharesStreamCursorValueInput = {
  activeDate?: InputMaybe<Scalars['timestamptz']['input']>;
  allowCancel?: InputMaybe<Scalars['Boolean']['input']>;
  bullets?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  markUp?: InputMaybe<Scalars['numeric']['input']>;
  maxPerUserShare?: InputMaybe<Scalars['numeric']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  passwordRequired?: InputMaybe<Scalars['Boolean']['input']>;
  playerId?: InputMaybe<Scalars['uuid']['input']>;
  shareDilution?: InputMaybe<Scalars['Boolean']['input']>;
  sharesToSell?: InputMaybe<Scalars['Int']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<StatusGamesEnum>;
  termsAcceptedDate?: InputMaybe<Scalars['timestamptz']['input']>;
  tournamentId?: InputMaybe<Scalars['uuid']['input']>;
  tournamentResultId?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type PlayerSharesSumFields = {
  __typename?: 'PlayerSharesSumFields';
  bullets?: Maybe<Scalars['Int']['output']>;
  markUp?: Maybe<Scalars['numeric']['output']>;
  maxPerUserShare?: Maybe<Scalars['numeric']['output']>;
  sharesToSell?: Maybe<Scalars['Int']['output']>;
  sort?: Maybe<Scalars['Int']['output']>;
  tournamentResultId?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "player_shares" */
export enum PlayerSharesUpdateColumn {
  /** column name */
  ActiveDate = 'activeDate',
  /** column name */
  AllowCancel = 'allowCancel',
  /** column name */
  Bullets = 'bullets',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  MarkUp = 'markUp',
  /** column name */
  MaxPerUserShare = 'maxPerUserShare',
  /** column name */
  Note = 'note',
  /** column name */
  Password = 'password',
  /** column name */
  PasswordRequired = 'passwordRequired',
  /** column name */
  PlayerId = 'playerId',
  /** column name */
  ShareDilution = 'shareDilution',
  /** column name */
  SharesToSell = 'sharesToSell',
  /** column name */
  Slug = 'slug',
  /** column name */
  Sort = 'sort',
  /** column name */
  Status = 'status',
  /** column name */
  TermsAcceptedDate = 'termsAcceptedDate',
  /** column name */
  TournamentId = 'tournamentId',
  /** column name */
  TournamentResultId = 'tournamentResultId',
  /** column name */
  UpdatedAt = 'updatedAt',
}

export type PlayerSharesUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<PlayerSharesIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PlayerSharesSetInput>;
  /** filter the rows which have to be updated */
  where: PlayerSharesBoolExp;
};

/** aggregate var_pop on columns */
export type PlayerSharesVar_PopFields = {
  __typename?: 'PlayerSharesVar_popFields';
  bullets?: Maybe<Scalars['Float']['output']>;
  markUp?: Maybe<Scalars['Float']['output']>;
  maxPerUserShare?: Maybe<Scalars['Float']['output']>;
  sharesToSell?: Maybe<Scalars['Float']['output']>;
  sort?: Maybe<Scalars['Float']['output']>;
  tournamentResultId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type PlayerSharesVar_SampFields = {
  __typename?: 'PlayerSharesVar_sampFields';
  bullets?: Maybe<Scalars['Float']['output']>;
  markUp?: Maybe<Scalars['Float']['output']>;
  maxPerUserShare?: Maybe<Scalars['Float']['output']>;
  sharesToSell?: Maybe<Scalars['Float']['output']>;
  sort?: Maybe<Scalars['Float']['output']>;
  tournamentResultId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type PlayerSharesVarianceFields = {
  __typename?: 'PlayerSharesVarianceFields';
  bullets?: Maybe<Scalars['Float']['output']>;
  markUp?: Maybe<Scalars['Float']['output']>;
  maxPerUserShare?: Maybe<Scalars['Float']['output']>;
  sharesToSell?: Maybe<Scalars['Float']['output']>;
  sort?: Maybe<Scalars['Float']['output']>;
  tournamentResultId?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "players" */
export type Players = {
  __typename?: 'Players';
  actionsSold: Scalars['Int']['output'];
  allTimeList?: Maybe<Scalars['Int']['output']>;
  allTimeListCountry?: Maybe<Scalars['String']['output']>;
  allTimeListCountryPlace?: Maybe<Scalars['Int']['output']>;
  birthday?: Maybe<Scalars['date']['output']>;
  createdAt: Scalars['timestamptz']['output'];
  description?: Maybe<Scalars['String']['output']>;
  facebook?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  followers: Array<Followers>;
  /** An aggregate relationship */
  followersAggregate: FollowersAggregate;
  followersCount: Scalars['Int']['output'];
  gender?: Maybe<Scalars['String']['output']>;
  globalpokerindexUrl?: Maybe<Scalars['String']['output']>;
  gpiRanking?: Maybe<Scalars['Int']['output']>;
  highestRoi: Scalars['numeric']['output'];
  highestWinning: Scalars['numeric']['output'];
  id: Scalars['uuid']['output'];
  image?: Maybe<Scalars['String']['output']>;
  instagram?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  nationality?: Maybe<Scalars['String']['output']>;
  /** An aggregate relationship */
  playerFollowersViewAggregate: PlayerFollowersViewAggregate;
  /** An aggregate relationship */
  playerSharesAggregate: PlayerSharesAggregate;
  /** An array relationship */
  player_followers_view: Array<PlayerFollowersView>;
  /** An array relationship */
  player_shares: Array<PlayerShares>;
  realName?: Maybe<Scalars['String']['output']>;
  residence?: Maybe<Scalars['String']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  sort: Scalars['Int']['output'];
  status: StatusesEnum;
  thehendonmobUrl?: Maybe<Scalars['String']['output']>;
  totalLiveEarning: Scalars['numeric']['output'];
  totalWinnings: Scalars['numeric']['output'];
  /** An aggregate relationship */
  tournamentResultsAggregate: TournamentResultsAggregate;
  /** An array relationship */
  tournament_results: Array<TournamentResults>;
  twitter?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['timestamptz']['output'];
  /** An object relationship */
  user: Users;
  userId: Scalars['String']['output'];
  verified: Scalars['Boolean']['output'];
};

/** columns and relationships of "players" */
export type PlayersFollowersArgs = {
  distinctOn?: InputMaybe<Array<FollowersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FollowersOrderBy>>;
  where?: InputMaybe<FollowersBoolExp>;
};

/** columns and relationships of "players" */
export type PlayersFollowersAggregateArgs = {
  distinctOn?: InputMaybe<Array<FollowersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FollowersOrderBy>>;
  where?: InputMaybe<FollowersBoolExp>;
};

/** columns and relationships of "players" */
export type PlayersPlayerFollowersViewAggregateArgs = {
  distinctOn?: InputMaybe<Array<PlayerFollowersViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PlayerFollowersViewOrderBy>>;
  where?: InputMaybe<PlayerFollowersViewBoolExp>;
};

/** columns and relationships of "players" */
export type PlayersPlayerSharesAggregateArgs = {
  distinctOn?: InputMaybe<Array<PlayerSharesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PlayerSharesOrderBy>>;
  where?: InputMaybe<PlayerSharesBoolExp>;
};

/** columns and relationships of "players" */
export type PlayersPlayer_Followers_ViewArgs = {
  distinctOn?: InputMaybe<Array<PlayerFollowersViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PlayerFollowersViewOrderBy>>;
  where?: InputMaybe<PlayerFollowersViewBoolExp>;
};

/** columns and relationships of "players" */
export type PlayersPlayer_SharesArgs = {
  distinctOn?: InputMaybe<Array<PlayerSharesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PlayerSharesOrderBy>>;
  where?: InputMaybe<PlayerSharesBoolExp>;
};

/** columns and relationships of "players" */
export type PlayersTournamentResultsAggregateArgs = {
  distinctOn?: InputMaybe<Array<TournamentResultsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TournamentResultsOrderBy>>;
  where?: InputMaybe<TournamentResultsBoolExp>;
};

/** columns and relationships of "players" */
export type PlayersTournament_ResultsArgs = {
  distinctOn?: InputMaybe<Array<TournamentResultsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TournamentResultsOrderBy>>;
  where?: InputMaybe<TournamentResultsBoolExp>;
};

/** aggregated selection of "players" */
export type PlayersAggregate = {
  __typename?: 'PlayersAggregate';
  aggregate?: Maybe<PlayersAggregateFields>;
  nodes: Array<Players>;
};

/** aggregate fields of "players" */
export type PlayersAggregateFields = {
  __typename?: 'PlayersAggregateFields';
  avg?: Maybe<PlayersAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<PlayersMaxFields>;
  min?: Maybe<PlayersMinFields>;
  stddev?: Maybe<PlayersStddevFields>;
  stddevPop?: Maybe<PlayersStddev_PopFields>;
  stddevSamp?: Maybe<PlayersStddev_SampFields>;
  sum?: Maybe<PlayersSumFields>;
  varPop?: Maybe<PlayersVar_PopFields>;
  varSamp?: Maybe<PlayersVar_SampFields>;
  variance?: Maybe<PlayersVarianceFields>;
};

/** aggregate fields of "players" */
export type PlayersAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<PlayersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "players" */
export type PlayersAggregateOrderBy = {
  avg?: InputMaybe<Players_Avg_Order_By>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<Players_Max_Order_By>;
  min?: InputMaybe<Players_Min_Order_By>;
  stddev?: InputMaybe<Players_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Players_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Players_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Players_Sum_Order_By>;
  var_pop?: InputMaybe<Players_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Players_Var_Samp_Order_By>;
  variance?: InputMaybe<Players_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "players" */
export type PlayersArrRelInsertInput = {
  data: Array<PlayersInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<PlayersOnConflict>;
};

/** aggregate avg on columns */
export type PlayersAvgFields = {
  __typename?: 'PlayersAvgFields';
  actionsSold?: Maybe<Scalars['Float']['output']>;
  allTimeList?: Maybe<Scalars['Float']['output']>;
  allTimeListCountryPlace?: Maybe<Scalars['Float']['output']>;
  followersCount?: Maybe<Scalars['Float']['output']>;
  gpiRanking?: Maybe<Scalars['Float']['output']>;
  highestRoi?: Maybe<Scalars['Float']['output']>;
  highestWinning?: Maybe<Scalars['Float']['output']>;
  sort?: Maybe<Scalars['Float']['output']>;
  totalLiveEarning?: Maybe<Scalars['Float']['output']>;
  totalWinnings?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "players". All fields are combined with a logical 'AND'. */
export type PlayersBoolExp = {
  _and?: InputMaybe<Array<PlayersBoolExp>>;
  _not?: InputMaybe<PlayersBoolExp>;
  _or?: InputMaybe<Array<PlayersBoolExp>>;
  actionsSold?: InputMaybe<IntComparisonExp>;
  allTimeList?: InputMaybe<IntComparisonExp>;
  allTimeListCountry?: InputMaybe<StringComparisonExp>;
  allTimeListCountryPlace?: InputMaybe<IntComparisonExp>;
  birthday?: InputMaybe<DateComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  description?: InputMaybe<StringComparisonExp>;
  facebook?: InputMaybe<StringComparisonExp>;
  followers?: InputMaybe<FollowersBoolExp>;
  followersCount?: InputMaybe<IntComparisonExp>;
  followers_aggregate?: InputMaybe<Followers_Aggregate_Bool_Exp>;
  gender?: InputMaybe<StringComparisonExp>;
  globalpokerindexUrl?: InputMaybe<StringComparisonExp>;
  gpiRanking?: InputMaybe<IntComparisonExp>;
  highestRoi?: InputMaybe<NumericComparisonExp>;
  highestWinning?: InputMaybe<NumericComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  image?: InputMaybe<StringComparisonExp>;
  instagram?: InputMaybe<StringComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  nationality?: InputMaybe<StringComparisonExp>;
  player_followers_view?: InputMaybe<PlayerFollowersViewBoolExp>;
  player_followers_view_aggregate?: InputMaybe<Player_Followers_View_Aggregate_Bool_Exp>;
  player_shares?: InputMaybe<PlayerSharesBoolExp>;
  player_shares_aggregate?: InputMaybe<Player_Shares_Aggregate_Bool_Exp>;
  realName?: InputMaybe<StringComparisonExp>;
  residence?: InputMaybe<StringComparisonExp>;
  slug?: InputMaybe<StringComparisonExp>;
  sort?: InputMaybe<IntComparisonExp>;
  status?: InputMaybe<StatusesEnumComparisonExp>;
  thehendonmobUrl?: InputMaybe<StringComparisonExp>;
  totalLiveEarning?: InputMaybe<NumericComparisonExp>;
  totalWinnings?: InputMaybe<NumericComparisonExp>;
  tournament_results?: InputMaybe<TournamentResultsBoolExp>;
  tournament_results_aggregate?: InputMaybe<Tournament_Results_Aggregate_Bool_Exp>;
  twitter?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  user?: InputMaybe<UsersBoolExp>;
  userId?: InputMaybe<StringComparisonExp>;
  verified?: InputMaybe<BooleanComparisonExp>;
};

/** unique or primary key constraints on table "players" */
export enum PlayersConstraint {
  /** unique or primary key constraint on columns "id" */
  PlayersPkey = 'players_pkey',
}

/** input type for incrementing numeric columns in table "players" */
export type PlayersIncInput = {
  actionsSold?: InputMaybe<Scalars['Int']['input']>;
  allTimeList?: InputMaybe<Scalars['Int']['input']>;
  allTimeListCountryPlace?: InputMaybe<Scalars['Int']['input']>;
  followersCount?: InputMaybe<Scalars['Int']['input']>;
  gpiRanking?: InputMaybe<Scalars['Int']['input']>;
  highestRoi?: InputMaybe<Scalars['numeric']['input']>;
  highestWinning?: InputMaybe<Scalars['numeric']['input']>;
  sort?: InputMaybe<Scalars['Int']['input']>;
  totalLiveEarning?: InputMaybe<Scalars['numeric']['input']>;
  totalWinnings?: InputMaybe<Scalars['numeric']['input']>;
};

/** input type for inserting data into table "players" */
export type PlayersInsertInput = {
  actionsSold?: InputMaybe<Scalars['Int']['input']>;
  allTimeList?: InputMaybe<Scalars['Int']['input']>;
  allTimeListCountry?: InputMaybe<Scalars['String']['input']>;
  allTimeListCountryPlace?: InputMaybe<Scalars['Int']['input']>;
  birthday?: InputMaybe<Scalars['date']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  facebook?: InputMaybe<Scalars['String']['input']>;
  followers?: InputMaybe<FollowersArrRelInsertInput>;
  followersCount?: InputMaybe<Scalars['Int']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  globalpokerindexUrl?: InputMaybe<Scalars['String']['input']>;
  gpiRanking?: InputMaybe<Scalars['Int']['input']>;
  highestRoi?: InputMaybe<Scalars['numeric']['input']>;
  highestWinning?: InputMaybe<Scalars['numeric']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  instagram?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  nationality?: InputMaybe<Scalars['String']['input']>;
  player_followers_view?: InputMaybe<PlayerFollowersViewArrRelInsertInput>;
  player_shares?: InputMaybe<PlayerSharesArrRelInsertInput>;
  realName?: InputMaybe<Scalars['String']['input']>;
  residence?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<StatusesEnum>;
  thehendonmobUrl?: InputMaybe<Scalars['String']['input']>;
  totalLiveEarning?: InputMaybe<Scalars['numeric']['input']>;
  totalWinnings?: InputMaybe<Scalars['numeric']['input']>;
  tournament_results?: InputMaybe<TournamentResultsArrRelInsertInput>;
  twitter?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<UsersObjRelInsertInput>;
  userId?: InputMaybe<Scalars['String']['input']>;
  verified?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate max on columns */
export type PlayersMaxFields = {
  __typename?: 'PlayersMaxFields';
  actionsSold?: Maybe<Scalars['Int']['output']>;
  allTimeList?: Maybe<Scalars['Int']['output']>;
  allTimeListCountry?: Maybe<Scalars['String']['output']>;
  allTimeListCountryPlace?: Maybe<Scalars['Int']['output']>;
  birthday?: Maybe<Scalars['date']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  facebook?: Maybe<Scalars['String']['output']>;
  followersCount?: Maybe<Scalars['Int']['output']>;
  gender?: Maybe<Scalars['String']['output']>;
  globalpokerindexUrl?: Maybe<Scalars['String']['output']>;
  gpiRanking?: Maybe<Scalars['Int']['output']>;
  highestRoi?: Maybe<Scalars['numeric']['output']>;
  highestWinning?: Maybe<Scalars['numeric']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  image?: Maybe<Scalars['String']['output']>;
  instagram?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nationality?: Maybe<Scalars['String']['output']>;
  realName?: Maybe<Scalars['String']['output']>;
  residence?: Maybe<Scalars['String']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  sort?: Maybe<Scalars['Int']['output']>;
  thehendonmobUrl?: Maybe<Scalars['String']['output']>;
  totalLiveEarning?: Maybe<Scalars['numeric']['output']>;
  totalWinnings?: Maybe<Scalars['numeric']['output']>;
  twitter?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type PlayersMinFields = {
  __typename?: 'PlayersMinFields';
  actionsSold?: Maybe<Scalars['Int']['output']>;
  allTimeList?: Maybe<Scalars['Int']['output']>;
  allTimeListCountry?: Maybe<Scalars['String']['output']>;
  allTimeListCountryPlace?: Maybe<Scalars['Int']['output']>;
  birthday?: Maybe<Scalars['date']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  facebook?: Maybe<Scalars['String']['output']>;
  followersCount?: Maybe<Scalars['Int']['output']>;
  gender?: Maybe<Scalars['String']['output']>;
  globalpokerindexUrl?: Maybe<Scalars['String']['output']>;
  gpiRanking?: Maybe<Scalars['Int']['output']>;
  highestRoi?: Maybe<Scalars['numeric']['output']>;
  highestWinning?: Maybe<Scalars['numeric']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  image?: Maybe<Scalars['String']['output']>;
  instagram?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nationality?: Maybe<Scalars['String']['output']>;
  realName?: Maybe<Scalars['String']['output']>;
  residence?: Maybe<Scalars['String']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  sort?: Maybe<Scalars['Int']['output']>;
  thehendonmobUrl?: Maybe<Scalars['String']['output']>;
  totalLiveEarning?: Maybe<Scalars['numeric']['output']>;
  totalWinnings?: Maybe<Scalars['numeric']['output']>;
  twitter?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "players" */
export type PlayersMutationResponse = {
  __typename?: 'PlayersMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Players>;
};

/** input type for inserting object relation for remote table "players" */
export type PlayersObjRelInsertInput = {
  data: PlayersInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<PlayersOnConflict>;
};

/** on_conflict condition type for table "players" */
export type PlayersOnConflict = {
  constraint: PlayersConstraint;
  update_columns?: Array<PlayersUpdateColumn>;
  where?: InputMaybe<PlayersBoolExp>;
};

/** Ordering options when selecting data from "players". */
export type PlayersOrderBy = {
  actionsSold?: InputMaybe<OrderBy>;
  allTimeList?: InputMaybe<OrderBy>;
  allTimeListCountry?: InputMaybe<OrderBy>;
  allTimeListCountryPlace?: InputMaybe<OrderBy>;
  birthday?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  facebook?: InputMaybe<OrderBy>;
  followersAggregate?: InputMaybe<FollowersAggregateOrderBy>;
  followersCount?: InputMaybe<OrderBy>;
  gender?: InputMaybe<OrderBy>;
  globalpokerindexUrl?: InputMaybe<OrderBy>;
  gpiRanking?: InputMaybe<OrderBy>;
  highestRoi?: InputMaybe<OrderBy>;
  highestWinning?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  image?: InputMaybe<OrderBy>;
  instagram?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  nationality?: InputMaybe<OrderBy>;
  player_followers_viewAggregate?: InputMaybe<PlayerFollowersViewAggregateOrderBy>;
  player_sharesAggregate?: InputMaybe<PlayerSharesAggregateOrderBy>;
  realName?: InputMaybe<OrderBy>;
  residence?: InputMaybe<OrderBy>;
  slug?: InputMaybe<OrderBy>;
  sort?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  thehendonmobUrl?: InputMaybe<OrderBy>;
  totalLiveEarning?: InputMaybe<OrderBy>;
  totalWinnings?: InputMaybe<OrderBy>;
  tournament_resultsAggregate?: InputMaybe<TournamentResultsAggregateOrderBy>;
  twitter?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  user?: InputMaybe<UsersOrderBy>;
  userId?: InputMaybe<OrderBy>;
  verified?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: players */
export type PlayersPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "players" */
export enum PlayersSelectColumn {
  /** column name */
  ActionsSold = 'actionsSold',
  /** column name */
  AllTimeList = 'allTimeList',
  /** column name */
  AllTimeListCountry = 'allTimeListCountry',
  /** column name */
  AllTimeListCountryPlace = 'allTimeListCountryPlace',
  /** column name */
  Birthday = 'birthday',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Facebook = 'facebook',
  /** column name */
  FollowersCount = 'followersCount',
  /** column name */
  Gender = 'gender',
  /** column name */
  GlobalpokerindexUrl = 'globalpokerindexUrl',
  /** column name */
  GpiRanking = 'gpiRanking',
  /** column name */
  HighestRoi = 'highestRoi',
  /** column name */
  HighestWinning = 'highestWinning',
  /** column name */
  Id = 'id',
  /** column name */
  Image = 'image',
  /** column name */
  Instagram = 'instagram',
  /** column name */
  Name = 'name',
  /** column name */
  Nationality = 'nationality',
  /** column name */
  RealName = 'realName',
  /** column name */
  Residence = 'residence',
  /** column name */
  Slug = 'slug',
  /** column name */
  Sort = 'sort',
  /** column name */
  Status = 'status',
  /** column name */
  ThehendonmobUrl = 'thehendonmobUrl',
  /** column name */
  TotalLiveEarning = 'totalLiveEarning',
  /** column name */
  TotalWinnings = 'totalWinnings',
  /** column name */
  Twitter = 'twitter',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId',
  /** column name */
  Verified = 'verified',
}

/** input type for updating data in table "players" */
export type PlayersSetInput = {
  actionsSold?: InputMaybe<Scalars['Int']['input']>;
  allTimeList?: InputMaybe<Scalars['Int']['input']>;
  allTimeListCountry?: InputMaybe<Scalars['String']['input']>;
  allTimeListCountryPlace?: InputMaybe<Scalars['Int']['input']>;
  birthday?: InputMaybe<Scalars['date']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  facebook?: InputMaybe<Scalars['String']['input']>;
  followersCount?: InputMaybe<Scalars['Int']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  globalpokerindexUrl?: InputMaybe<Scalars['String']['input']>;
  gpiRanking?: InputMaybe<Scalars['Int']['input']>;
  highestRoi?: InputMaybe<Scalars['numeric']['input']>;
  highestWinning?: InputMaybe<Scalars['numeric']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  instagram?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  nationality?: InputMaybe<Scalars['String']['input']>;
  realName?: InputMaybe<Scalars['String']['input']>;
  residence?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<StatusesEnum>;
  thehendonmobUrl?: InputMaybe<Scalars['String']['input']>;
  totalLiveEarning?: InputMaybe<Scalars['numeric']['input']>;
  totalWinnings?: InputMaybe<Scalars['numeric']['input']>;
  twitter?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
  verified?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate stddev on columns */
export type PlayersStddevFields = {
  __typename?: 'PlayersStddevFields';
  actionsSold?: Maybe<Scalars['Float']['output']>;
  allTimeList?: Maybe<Scalars['Float']['output']>;
  allTimeListCountryPlace?: Maybe<Scalars['Float']['output']>;
  followersCount?: Maybe<Scalars['Float']['output']>;
  gpiRanking?: Maybe<Scalars['Float']['output']>;
  highestRoi?: Maybe<Scalars['Float']['output']>;
  highestWinning?: Maybe<Scalars['Float']['output']>;
  sort?: Maybe<Scalars['Float']['output']>;
  totalLiveEarning?: Maybe<Scalars['Float']['output']>;
  totalWinnings?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type PlayersStddev_PopFields = {
  __typename?: 'PlayersStddev_popFields';
  actionsSold?: Maybe<Scalars['Float']['output']>;
  allTimeList?: Maybe<Scalars['Float']['output']>;
  allTimeListCountryPlace?: Maybe<Scalars['Float']['output']>;
  followersCount?: Maybe<Scalars['Float']['output']>;
  gpiRanking?: Maybe<Scalars['Float']['output']>;
  highestRoi?: Maybe<Scalars['Float']['output']>;
  highestWinning?: Maybe<Scalars['Float']['output']>;
  sort?: Maybe<Scalars['Float']['output']>;
  totalLiveEarning?: Maybe<Scalars['Float']['output']>;
  totalWinnings?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type PlayersStddev_SampFields = {
  __typename?: 'PlayersStddev_sampFields';
  actionsSold?: Maybe<Scalars['Float']['output']>;
  allTimeList?: Maybe<Scalars['Float']['output']>;
  allTimeListCountryPlace?: Maybe<Scalars['Float']['output']>;
  followersCount?: Maybe<Scalars['Float']['output']>;
  gpiRanking?: Maybe<Scalars['Float']['output']>;
  highestRoi?: Maybe<Scalars['Float']['output']>;
  highestWinning?: Maybe<Scalars['Float']['output']>;
  sort?: Maybe<Scalars['Float']['output']>;
  totalLiveEarning?: Maybe<Scalars['Float']['output']>;
  totalWinnings?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "players" */
export type PlayersStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: PlayersStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type PlayersStreamCursorValueInput = {
  actionsSold?: InputMaybe<Scalars['Int']['input']>;
  allTimeList?: InputMaybe<Scalars['Int']['input']>;
  allTimeListCountry?: InputMaybe<Scalars['String']['input']>;
  allTimeListCountryPlace?: InputMaybe<Scalars['Int']['input']>;
  birthday?: InputMaybe<Scalars['date']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  facebook?: InputMaybe<Scalars['String']['input']>;
  followersCount?: InputMaybe<Scalars['Int']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  globalpokerindexUrl?: InputMaybe<Scalars['String']['input']>;
  gpiRanking?: InputMaybe<Scalars['Int']['input']>;
  highestRoi?: InputMaybe<Scalars['numeric']['input']>;
  highestWinning?: InputMaybe<Scalars['numeric']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  instagram?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  nationality?: InputMaybe<Scalars['String']['input']>;
  realName?: InputMaybe<Scalars['String']['input']>;
  residence?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<StatusesEnum>;
  thehendonmobUrl?: InputMaybe<Scalars['String']['input']>;
  totalLiveEarning?: InputMaybe<Scalars['numeric']['input']>;
  totalWinnings?: InputMaybe<Scalars['numeric']['input']>;
  twitter?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
  verified?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate sum on columns */
export type PlayersSumFields = {
  __typename?: 'PlayersSumFields';
  actionsSold?: Maybe<Scalars['Int']['output']>;
  allTimeList?: Maybe<Scalars['Int']['output']>;
  allTimeListCountryPlace?: Maybe<Scalars['Int']['output']>;
  followersCount?: Maybe<Scalars['Int']['output']>;
  gpiRanking?: Maybe<Scalars['Int']['output']>;
  highestRoi?: Maybe<Scalars['numeric']['output']>;
  highestWinning?: Maybe<Scalars['numeric']['output']>;
  sort?: Maybe<Scalars['Int']['output']>;
  totalLiveEarning?: Maybe<Scalars['numeric']['output']>;
  totalWinnings?: Maybe<Scalars['numeric']['output']>;
};

/** update columns of table "players" */
export enum PlayersUpdateColumn {
  /** column name */
  ActionsSold = 'actionsSold',
  /** column name */
  AllTimeList = 'allTimeList',
  /** column name */
  AllTimeListCountry = 'allTimeListCountry',
  /** column name */
  AllTimeListCountryPlace = 'allTimeListCountryPlace',
  /** column name */
  Birthday = 'birthday',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Facebook = 'facebook',
  /** column name */
  FollowersCount = 'followersCount',
  /** column name */
  Gender = 'gender',
  /** column name */
  GlobalpokerindexUrl = 'globalpokerindexUrl',
  /** column name */
  GpiRanking = 'gpiRanking',
  /** column name */
  HighestRoi = 'highestRoi',
  /** column name */
  HighestWinning = 'highestWinning',
  /** column name */
  Id = 'id',
  /** column name */
  Image = 'image',
  /** column name */
  Instagram = 'instagram',
  /** column name */
  Name = 'name',
  /** column name */
  Nationality = 'nationality',
  /** column name */
  RealName = 'realName',
  /** column name */
  Residence = 'residence',
  /** column name */
  Slug = 'slug',
  /** column name */
  Sort = 'sort',
  /** column name */
  Status = 'status',
  /** column name */
  ThehendonmobUrl = 'thehendonmobUrl',
  /** column name */
  TotalLiveEarning = 'totalLiveEarning',
  /** column name */
  TotalWinnings = 'totalWinnings',
  /** column name */
  Twitter = 'twitter',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId',
  /** column name */
  Verified = 'verified',
}

export type PlayersUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<PlayersIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PlayersSetInput>;
  /** filter the rows which have to be updated */
  where: PlayersBoolExp;
};

/** aggregate var_pop on columns */
export type PlayersVar_PopFields = {
  __typename?: 'PlayersVar_popFields';
  actionsSold?: Maybe<Scalars['Float']['output']>;
  allTimeList?: Maybe<Scalars['Float']['output']>;
  allTimeListCountryPlace?: Maybe<Scalars['Float']['output']>;
  followersCount?: Maybe<Scalars['Float']['output']>;
  gpiRanking?: Maybe<Scalars['Float']['output']>;
  highestRoi?: Maybe<Scalars['Float']['output']>;
  highestWinning?: Maybe<Scalars['Float']['output']>;
  sort?: Maybe<Scalars['Float']['output']>;
  totalLiveEarning?: Maybe<Scalars['Float']['output']>;
  totalWinnings?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type PlayersVar_SampFields = {
  __typename?: 'PlayersVar_sampFields';
  actionsSold?: Maybe<Scalars['Float']['output']>;
  allTimeList?: Maybe<Scalars['Float']['output']>;
  allTimeListCountryPlace?: Maybe<Scalars['Float']['output']>;
  followersCount?: Maybe<Scalars['Float']['output']>;
  gpiRanking?: Maybe<Scalars['Float']['output']>;
  highestRoi?: Maybe<Scalars['Float']['output']>;
  highestWinning?: Maybe<Scalars['Float']['output']>;
  sort?: Maybe<Scalars['Float']['output']>;
  totalLiveEarning?: Maybe<Scalars['Float']['output']>;
  totalWinnings?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type PlayersVarianceFields = {
  __typename?: 'PlayersVarianceFields';
  actionsSold?: Maybe<Scalars['Float']['output']>;
  allTimeList?: Maybe<Scalars['Float']['output']>;
  allTimeListCountryPlace?: Maybe<Scalars['Float']['output']>;
  followersCount?: Maybe<Scalars['Float']['output']>;
  gpiRanking?: Maybe<Scalars['Float']['output']>;
  highestRoi?: Maybe<Scalars['Float']['output']>;
  highestWinning?: Maybe<Scalars['Float']['output']>;
  sort?: Maybe<Scalars['Float']['output']>;
  totalLiveEarning?: Maybe<Scalars['Float']['output']>;
  totalWinnings?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "stat_events" */
export type StatEvents = {
  __typename?: 'StatEvents';
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  date: Scalars['timestamp']['output'];
  id: Scalars['bigint']['output'];
  location: Scalars['String']['output'];
  name: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
  venueId: Scalars['uuid']['output'];
};

/** aggregated selection of "stat_events" */
export type StatEventsAggregate = {
  __typename?: 'StatEventsAggregate';
  aggregate?: Maybe<StatEventsAggregateFields>;
  nodes: Array<StatEvents>;
};

/** aggregate fields of "stat_events" */
export type StatEventsAggregateFields = {
  __typename?: 'StatEventsAggregateFields';
  avg?: Maybe<StatEventsAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<StatEventsMaxFields>;
  min?: Maybe<StatEventsMinFields>;
  stddev?: Maybe<StatEventsStddevFields>;
  stddevPop?: Maybe<StatEventsStddev_PopFields>;
  stddevSamp?: Maybe<StatEventsStddev_SampFields>;
  sum?: Maybe<StatEventsSumFields>;
  varPop?: Maybe<StatEventsVar_PopFields>;
  varSamp?: Maybe<StatEventsVar_SampFields>;
  variance?: Maybe<StatEventsVarianceFields>;
};

/** aggregate fields of "stat_events" */
export type StatEventsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<StatEventsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type StatEventsAvgFields = {
  __typename?: 'StatEventsAvgFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "stat_events". All fields are combined with a logical 'AND'. */
export type StatEventsBoolExp = {
  _and?: InputMaybe<Array<StatEventsBoolExp>>;
  _not?: InputMaybe<StatEventsBoolExp>;
  _or?: InputMaybe<Array<StatEventsBoolExp>>;
  createdAt?: InputMaybe<TimestampComparisonExp>;
  date?: InputMaybe<TimestampComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  location?: InputMaybe<StringComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestampComparisonExp>;
  venueId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "stat_events" */
export enum StatEventsConstraint {
  /** unique or primary key constraint on columns "id" */
  StatEventsPkey = 'stat_events_pkey',
}

/** input type for incrementing numeric columns in table "stat_events" */
export type StatEventsIncInput = {
  id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "stat_events" */
export type StatEventsInsertInput = {
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  date?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
  venueId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type StatEventsMaxFields = {
  __typename?: 'StatEventsMaxFields';
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  date?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
  venueId?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type StatEventsMinFields = {
  __typename?: 'StatEventsMinFields';
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  date?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
  venueId?: Maybe<Scalars['uuid']['output']>;
};

/** response of any mutation on the table "stat_events" */
export type StatEventsMutationResponse = {
  __typename?: 'StatEventsMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<StatEvents>;
};

/** on_conflict condition type for table "stat_events" */
export type StatEventsOnConflict = {
  constraint: StatEventsConstraint;
  update_columns?: Array<StatEventsUpdateColumn>;
  where?: InputMaybe<StatEventsBoolExp>;
};

/** Ordering options when selecting data from "stat_events". */
export type StatEventsOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  date?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  location?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  venueId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: stat_events */
export type StatEventsPkColumnsInput = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "stat_events" */
export enum StatEventsSelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Date = 'date',
  /** column name */
  Id = 'id',
  /** column name */
  Location = 'location',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  VenueId = 'venueId',
}

/** input type for updating data in table "stat_events" */
export type StatEventsSetInput = {
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  date?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
  venueId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate stddev on columns */
export type StatEventsStddevFields = {
  __typename?: 'StatEventsStddevFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type StatEventsStddev_PopFields = {
  __typename?: 'StatEventsStddev_popFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type StatEventsStddev_SampFields = {
  __typename?: 'StatEventsStddev_sampFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "stat_events" */
export type StatEventsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: StatEventsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type StatEventsStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  date?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
  venueId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate sum on columns */
export type StatEventsSumFields = {
  __typename?: 'StatEventsSumFields';
  id?: Maybe<Scalars['bigint']['output']>;
};

/** update columns of table "stat_events" */
export enum StatEventsUpdateColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Date = 'date',
  /** column name */
  Id = 'id',
  /** column name */
  Location = 'location',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  VenueId = 'venueId',
}

export type StatEventsUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<StatEventsIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<StatEventsSetInput>;
  /** filter the rows which have to be updated */
  where: StatEventsBoolExp;
};

/** aggregate var_pop on columns */
export type StatEventsVar_PopFields = {
  __typename?: 'StatEventsVar_popFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type StatEventsVar_SampFields = {
  __typename?: 'StatEventsVar_sampFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type StatEventsVarianceFields = {
  __typename?: 'StatEventsVarianceFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "stat_players" */
export type StatPlayers = {
  __typename?: 'StatPlayers';
  allTimeMoneyList?: Maybe<Scalars['Int']['output']>;
  asainPokerMoneyList?: Maybe<Scalars['bigint']['output']>;
  asianPokerRanking?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  id: Scalars['bigint']['output'];
  name: Scalars['String']['output'];
  nationality?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  player?: Maybe<Players>;
  playerId?: Maybe<Scalars['uuid']['output']>;
  public: Scalars['Boolean']['output'];
  residence?: Maybe<Scalars['String']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  /** An aggregate relationship */
  statTournamentResultsAggregate: StatTournamentResultsAggregate;
  /** An array relationship */
  stat_tournament_results: Array<StatTournamentResults>;
  thehendonmobId?: Maybe<Scalars['Int']['output']>;
  totalLiveEarning?: Maybe<Scalars['bigint']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
};

/** columns and relationships of "stat_players" */
export type StatPlayersStatTournamentResultsAggregateArgs = {
  distinctOn?: InputMaybe<Array<StatTournamentResultsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<StatTournamentResultsOrderBy>>;
  where?: InputMaybe<StatTournamentResultsBoolExp>;
};

/** columns and relationships of "stat_players" */
export type StatPlayersStat_Tournament_ResultsArgs = {
  distinctOn?: InputMaybe<Array<StatTournamentResultsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<StatTournamentResultsOrderBy>>;
  where?: InputMaybe<StatTournamentResultsBoolExp>;
};

/** aggregated selection of "stat_players" */
export type StatPlayersAggregate = {
  __typename?: 'StatPlayersAggregate';
  aggregate?: Maybe<StatPlayersAggregateFields>;
  nodes: Array<StatPlayers>;
};

/** aggregate fields of "stat_players" */
export type StatPlayersAggregateFields = {
  __typename?: 'StatPlayersAggregateFields';
  avg?: Maybe<StatPlayersAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<StatPlayersMaxFields>;
  min?: Maybe<StatPlayersMinFields>;
  stddev?: Maybe<StatPlayersStddevFields>;
  stddevPop?: Maybe<StatPlayersStddev_PopFields>;
  stddevSamp?: Maybe<StatPlayersStddev_SampFields>;
  sum?: Maybe<StatPlayersSumFields>;
  varPop?: Maybe<StatPlayersVar_PopFields>;
  varSamp?: Maybe<StatPlayersVar_SampFields>;
  variance?: Maybe<StatPlayersVarianceFields>;
};

/** aggregate fields of "stat_players" */
export type StatPlayersAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<StatPlayersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type StatPlayersAvgFields = {
  __typename?: 'StatPlayersAvgFields';
  allTimeMoneyList?: Maybe<Scalars['Float']['output']>;
  asainPokerMoneyList?: Maybe<Scalars['Float']['output']>;
  asianPokerRanking?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  thehendonmobId?: Maybe<Scalars['Float']['output']>;
  totalLiveEarning?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "stat_players". All fields are combined with a logical 'AND'. */
export type StatPlayersBoolExp = {
  _and?: InputMaybe<Array<StatPlayersBoolExp>>;
  _not?: InputMaybe<StatPlayersBoolExp>;
  _or?: InputMaybe<Array<StatPlayersBoolExp>>;
  allTimeMoneyList?: InputMaybe<IntComparisonExp>;
  asainPokerMoneyList?: InputMaybe<BigintComparisonExp>;
  asianPokerRanking?: InputMaybe<IntComparisonExp>;
  createdAt?: InputMaybe<TimestampComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  nationality?: InputMaybe<StringComparisonExp>;
  player?: InputMaybe<PlayersBoolExp>;
  playerId?: InputMaybe<UuidComparisonExp>;
  public?: InputMaybe<BooleanComparisonExp>;
  residence?: InputMaybe<StringComparisonExp>;
  slug?: InputMaybe<StringComparisonExp>;
  stat_tournament_results?: InputMaybe<StatTournamentResultsBoolExp>;
  stat_tournament_results_aggregate?: InputMaybe<Stat_Tournament_Results_Aggregate_Bool_Exp>;
  thehendonmobId?: InputMaybe<IntComparisonExp>;
  totalLiveEarning?: InputMaybe<BigintComparisonExp>;
  updatedAt?: InputMaybe<TimestampComparisonExp>;
};

/** unique or primary key constraints on table "stat_players" */
export enum StatPlayersConstraint {
  /** unique or primary key constraint on columns "id" */
  StatPlayersPkey = 'stat_players_pkey',
  /** unique or primary key constraint on columns "slug" */
  StatPlayersSlugIdx = 'stat_players_slug_idx',
}

/** input type for incrementing numeric columns in table "stat_players" */
export type StatPlayersIncInput = {
  allTimeMoneyList?: InputMaybe<Scalars['Int']['input']>;
  asainPokerMoneyList?: InputMaybe<Scalars['bigint']['input']>;
  asianPokerRanking?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  thehendonmobId?: InputMaybe<Scalars['Int']['input']>;
  totalLiveEarning?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "stat_players" */
export type StatPlayersInsertInput = {
  allTimeMoneyList?: InputMaybe<Scalars['Int']['input']>;
  asainPokerMoneyList?: InputMaybe<Scalars['bigint']['input']>;
  asianPokerRanking?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  nationality?: InputMaybe<Scalars['String']['input']>;
  player?: InputMaybe<PlayersObjRelInsertInput>;
  playerId?: InputMaybe<Scalars['uuid']['input']>;
  public?: InputMaybe<Scalars['Boolean']['input']>;
  residence?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  stat_tournament_results?: InputMaybe<StatTournamentResultsArrRelInsertInput>;
  thehendonmobId?: InputMaybe<Scalars['Int']['input']>;
  totalLiveEarning?: InputMaybe<Scalars['bigint']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate max on columns */
export type StatPlayersMaxFields = {
  __typename?: 'StatPlayersMaxFields';
  allTimeMoneyList?: Maybe<Scalars['Int']['output']>;
  asainPokerMoneyList?: Maybe<Scalars['bigint']['output']>;
  asianPokerRanking?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nationality?: Maybe<Scalars['String']['output']>;
  playerId?: Maybe<Scalars['uuid']['output']>;
  residence?: Maybe<Scalars['String']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  thehendonmobId?: Maybe<Scalars['Int']['output']>;
  totalLiveEarning?: Maybe<Scalars['bigint']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
};

/** aggregate min on columns */
export type StatPlayersMinFields = {
  __typename?: 'StatPlayersMinFields';
  allTimeMoneyList?: Maybe<Scalars['Int']['output']>;
  asainPokerMoneyList?: Maybe<Scalars['bigint']['output']>;
  asianPokerRanking?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nationality?: Maybe<Scalars['String']['output']>;
  playerId?: Maybe<Scalars['uuid']['output']>;
  residence?: Maybe<Scalars['String']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  thehendonmobId?: Maybe<Scalars['Int']['output']>;
  totalLiveEarning?: Maybe<Scalars['bigint']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
};

/** response of any mutation on the table "stat_players" */
export type StatPlayersMutationResponse = {
  __typename?: 'StatPlayersMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<StatPlayers>;
};

/** input type for inserting object relation for remote table "stat_players" */
export type StatPlayersObjRelInsertInput = {
  data: StatPlayersInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<StatPlayersOnConflict>;
};

/** on_conflict condition type for table "stat_players" */
export type StatPlayersOnConflict = {
  constraint: StatPlayersConstraint;
  update_columns?: Array<StatPlayersUpdateColumn>;
  where?: InputMaybe<StatPlayersBoolExp>;
};

/** Ordering options when selecting data from "stat_players". */
export type StatPlayersOrderBy = {
  allTimeMoneyList?: InputMaybe<OrderBy>;
  asainPokerMoneyList?: InputMaybe<OrderBy>;
  asianPokerRanking?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  nationality?: InputMaybe<OrderBy>;
  player?: InputMaybe<PlayersOrderBy>;
  playerId?: InputMaybe<OrderBy>;
  public?: InputMaybe<OrderBy>;
  residence?: InputMaybe<OrderBy>;
  slug?: InputMaybe<OrderBy>;
  stat_tournament_resultsAggregate?: InputMaybe<StatTournamentResultsAggregateOrderBy>;
  thehendonmobId?: InputMaybe<OrderBy>;
  totalLiveEarning?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: stat_players */
export type StatPlayersPkColumnsInput = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "stat_players" */
export enum StatPlayersSelectColumn {
  /** column name */
  AllTimeMoneyList = 'allTimeMoneyList',
  /** column name */
  AsainPokerMoneyList = 'asainPokerMoneyList',
  /** column name */
  AsianPokerRanking = 'asianPokerRanking',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Nationality = 'nationality',
  /** column name */
  PlayerId = 'playerId',
  /** column name */
  Public = 'public',
  /** column name */
  Residence = 'residence',
  /** column name */
  Slug = 'slug',
  /** column name */
  ThehendonmobId = 'thehendonmobId',
  /** column name */
  TotalLiveEarning = 'totalLiveEarning',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** input type for updating data in table "stat_players" */
export type StatPlayersSetInput = {
  allTimeMoneyList?: InputMaybe<Scalars['Int']['input']>;
  asainPokerMoneyList?: InputMaybe<Scalars['bigint']['input']>;
  asianPokerRanking?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  nationality?: InputMaybe<Scalars['String']['input']>;
  playerId?: InputMaybe<Scalars['uuid']['input']>;
  public?: InputMaybe<Scalars['Boolean']['input']>;
  residence?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  thehendonmobId?: InputMaybe<Scalars['Int']['input']>;
  totalLiveEarning?: InputMaybe<Scalars['bigint']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate stddev on columns */
export type StatPlayersStddevFields = {
  __typename?: 'StatPlayersStddevFields';
  allTimeMoneyList?: Maybe<Scalars['Float']['output']>;
  asainPokerMoneyList?: Maybe<Scalars['Float']['output']>;
  asianPokerRanking?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  thehendonmobId?: Maybe<Scalars['Float']['output']>;
  totalLiveEarning?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type StatPlayersStddev_PopFields = {
  __typename?: 'StatPlayersStddev_popFields';
  allTimeMoneyList?: Maybe<Scalars['Float']['output']>;
  asainPokerMoneyList?: Maybe<Scalars['Float']['output']>;
  asianPokerRanking?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  thehendonmobId?: Maybe<Scalars['Float']['output']>;
  totalLiveEarning?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type StatPlayersStddev_SampFields = {
  __typename?: 'StatPlayersStddev_sampFields';
  allTimeMoneyList?: Maybe<Scalars['Float']['output']>;
  asainPokerMoneyList?: Maybe<Scalars['Float']['output']>;
  asianPokerRanking?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  thehendonmobId?: Maybe<Scalars['Float']['output']>;
  totalLiveEarning?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "stat_players" */
export type StatPlayersStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: StatPlayersStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type StatPlayersStreamCursorValueInput = {
  allTimeMoneyList?: InputMaybe<Scalars['Int']['input']>;
  asainPokerMoneyList?: InputMaybe<Scalars['bigint']['input']>;
  asianPokerRanking?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  nationality?: InputMaybe<Scalars['String']['input']>;
  playerId?: InputMaybe<Scalars['uuid']['input']>;
  public?: InputMaybe<Scalars['Boolean']['input']>;
  residence?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  thehendonmobId?: InputMaybe<Scalars['Int']['input']>;
  totalLiveEarning?: InputMaybe<Scalars['bigint']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate sum on columns */
export type StatPlayersSumFields = {
  __typename?: 'StatPlayersSumFields';
  allTimeMoneyList?: Maybe<Scalars['Int']['output']>;
  asainPokerMoneyList?: Maybe<Scalars['bigint']['output']>;
  asianPokerRanking?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  thehendonmobId?: Maybe<Scalars['Int']['output']>;
  totalLiveEarning?: Maybe<Scalars['bigint']['output']>;
};

/** update columns of table "stat_players" */
export enum StatPlayersUpdateColumn {
  /** column name */
  AllTimeMoneyList = 'allTimeMoneyList',
  /** column name */
  AsainPokerMoneyList = 'asainPokerMoneyList',
  /** column name */
  AsianPokerRanking = 'asianPokerRanking',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Nationality = 'nationality',
  /** column name */
  PlayerId = 'playerId',
  /** column name */
  Public = 'public',
  /** column name */
  Residence = 'residence',
  /** column name */
  Slug = 'slug',
  /** column name */
  ThehendonmobId = 'thehendonmobId',
  /** column name */
  TotalLiveEarning = 'totalLiveEarning',
  /** column name */
  UpdatedAt = 'updatedAt',
}

export type StatPlayersUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<StatPlayersIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<StatPlayersSetInput>;
  /** filter the rows which have to be updated */
  where: StatPlayersBoolExp;
};

/** aggregate var_pop on columns */
export type StatPlayersVar_PopFields = {
  __typename?: 'StatPlayersVar_popFields';
  allTimeMoneyList?: Maybe<Scalars['Float']['output']>;
  asainPokerMoneyList?: Maybe<Scalars['Float']['output']>;
  asianPokerRanking?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  thehendonmobId?: Maybe<Scalars['Float']['output']>;
  totalLiveEarning?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type StatPlayersVar_SampFields = {
  __typename?: 'StatPlayersVar_sampFields';
  allTimeMoneyList?: Maybe<Scalars['Float']['output']>;
  asainPokerMoneyList?: Maybe<Scalars['Float']['output']>;
  asianPokerRanking?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  thehendonmobId?: Maybe<Scalars['Float']['output']>;
  totalLiveEarning?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type StatPlayersVarianceFields = {
  __typename?: 'StatPlayersVarianceFields';
  allTimeMoneyList?: Maybe<Scalars['Float']['output']>;
  asainPokerMoneyList?: Maybe<Scalars['Float']['output']>;
  asianPokerRanking?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  thehendonmobId?: Maybe<Scalars['Float']['output']>;
  totalLiveEarning?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "stat_top_players" */
export type StatTopPlayers = {
  __typename?: 'StatTopPlayers';
  id?: Maybe<Scalars['bigint']['output']>;
  money?: Maybe<Scalars['numeric']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  points?: Maybe<Scalars['bigint']['output']>;
  ranking?: Maybe<Scalars['bigint']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  stat_player?: Maybe<StatPlayers>;
};

/** aggregated selection of "stat_top_players" */
export type StatTopPlayersAggregate = {
  __typename?: 'StatTopPlayersAggregate';
  aggregate?: Maybe<StatTopPlayersAggregateFields>;
  nodes: Array<StatTopPlayers>;
};

/** aggregate fields of "stat_top_players" */
export type StatTopPlayersAggregateFields = {
  __typename?: 'StatTopPlayersAggregateFields';
  avg?: Maybe<StatTopPlayersAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<StatTopPlayersMaxFields>;
  min?: Maybe<StatTopPlayersMinFields>;
  stddev?: Maybe<StatTopPlayersStddevFields>;
  stddevPop?: Maybe<StatTopPlayersStddev_PopFields>;
  stddevSamp?: Maybe<StatTopPlayersStddev_SampFields>;
  sum?: Maybe<StatTopPlayersSumFields>;
  varPop?: Maybe<StatTopPlayersVar_PopFields>;
  varSamp?: Maybe<StatTopPlayersVar_SampFields>;
  variance?: Maybe<StatTopPlayersVarianceFields>;
};

/** aggregate fields of "stat_top_players" */
export type StatTopPlayersAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<StatTopPlayersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type StatTopPlayersAvgFields = {
  __typename?: 'StatTopPlayersAvgFields';
  id?: Maybe<Scalars['Float']['output']>;
  money?: Maybe<Scalars['Float']['output']>;
  points?: Maybe<Scalars['Float']['output']>;
  ranking?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "stat_top_players". All fields are combined with a logical 'AND'. */
export type StatTopPlayersBoolExp = {
  _and?: InputMaybe<Array<StatTopPlayersBoolExp>>;
  _not?: InputMaybe<StatTopPlayersBoolExp>;
  _or?: InputMaybe<Array<StatTopPlayersBoolExp>>;
  id?: InputMaybe<BigintComparisonExp>;
  money?: InputMaybe<NumericComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  points?: InputMaybe<BigintComparisonExp>;
  ranking?: InputMaybe<BigintComparisonExp>;
  slug?: InputMaybe<StringComparisonExp>;
  stat_player?: InputMaybe<StatPlayersBoolExp>;
};

/** aggregate max on columns */
export type StatTopPlayersMaxFields = {
  __typename?: 'StatTopPlayersMaxFields';
  id?: Maybe<Scalars['bigint']['output']>;
  money?: Maybe<Scalars['numeric']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  points?: Maybe<Scalars['bigint']['output']>;
  ranking?: Maybe<Scalars['bigint']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type StatTopPlayersMinFields = {
  __typename?: 'StatTopPlayersMinFields';
  id?: Maybe<Scalars['bigint']['output']>;
  money?: Maybe<Scalars['numeric']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  points?: Maybe<Scalars['bigint']['output']>;
  ranking?: Maybe<Scalars['bigint']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
};

/** Ordering options when selecting data from "stat_top_players". */
export type StatTopPlayersOrderBy = {
  id?: InputMaybe<OrderBy>;
  money?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  points?: InputMaybe<OrderBy>;
  ranking?: InputMaybe<OrderBy>;
  slug?: InputMaybe<OrderBy>;
  stat_player?: InputMaybe<StatPlayersOrderBy>;
};

/** select columns of table "stat_top_players" */
export enum StatTopPlayersSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  Money = 'money',
  /** column name */
  Name = 'name',
  /** column name */
  Points = 'points',
  /** column name */
  Ranking = 'ranking',
  /** column name */
  Slug = 'slug',
}

/** aggregate stddev on columns */
export type StatTopPlayersStddevFields = {
  __typename?: 'StatTopPlayersStddevFields';
  id?: Maybe<Scalars['Float']['output']>;
  money?: Maybe<Scalars['Float']['output']>;
  points?: Maybe<Scalars['Float']['output']>;
  ranking?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type StatTopPlayersStddev_PopFields = {
  __typename?: 'StatTopPlayersStddev_popFields';
  id?: Maybe<Scalars['Float']['output']>;
  money?: Maybe<Scalars['Float']['output']>;
  points?: Maybe<Scalars['Float']['output']>;
  ranking?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type StatTopPlayersStddev_SampFields = {
  __typename?: 'StatTopPlayersStddev_sampFields';
  id?: Maybe<Scalars['Float']['output']>;
  money?: Maybe<Scalars['Float']['output']>;
  points?: Maybe<Scalars['Float']['output']>;
  ranking?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "stat_top_players" */
export type StatTopPlayersStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: StatTopPlayersStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type StatTopPlayersStreamCursorValueInput = {
  id?: InputMaybe<Scalars['bigint']['input']>;
  money?: InputMaybe<Scalars['numeric']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  points?: InputMaybe<Scalars['bigint']['input']>;
  ranking?: InputMaybe<Scalars['bigint']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type StatTopPlayersSumFields = {
  __typename?: 'StatTopPlayersSumFields';
  id?: Maybe<Scalars['bigint']['output']>;
  money?: Maybe<Scalars['numeric']['output']>;
  points?: Maybe<Scalars['bigint']['output']>;
  ranking?: Maybe<Scalars['bigint']['output']>;
};

/** aggregate var_pop on columns */
export type StatTopPlayersVar_PopFields = {
  __typename?: 'StatTopPlayersVar_popFields';
  id?: Maybe<Scalars['Float']['output']>;
  money?: Maybe<Scalars['Float']['output']>;
  points?: Maybe<Scalars['Float']['output']>;
  ranking?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type StatTopPlayersVar_SampFields = {
  __typename?: 'StatTopPlayersVar_sampFields';
  id?: Maybe<Scalars['Float']['output']>;
  money?: Maybe<Scalars['Float']['output']>;
  points?: Maybe<Scalars['Float']['output']>;
  ranking?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type StatTopPlayersVarianceFields = {
  __typename?: 'StatTopPlayersVarianceFields';
  id?: Maybe<Scalars['Float']['output']>;
  money?: Maybe<Scalars['Float']['output']>;
  points?: Maybe<Scalars['Float']['output']>;
  ranking?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "stat_top_year_players" */
export type StatTopYearPlayers = {
  __typename?: 'StatTopYearPlayers';
  id?: Maybe<Scalars['bigint']['output']>;
  money?: Maybe<Scalars['numeric']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  points?: Maybe<Scalars['bigint']['output']>;
  ranking?: Maybe<Scalars['bigint']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  stat_player?: Maybe<StatPlayers>;
};

/** aggregated selection of "stat_top_year_players" */
export type StatTopYearPlayersAggregate = {
  __typename?: 'StatTopYearPlayersAggregate';
  aggregate?: Maybe<StatTopYearPlayersAggregateFields>;
  nodes: Array<StatTopYearPlayers>;
};

/** aggregate fields of "stat_top_year_players" */
export type StatTopYearPlayersAggregateFields = {
  __typename?: 'StatTopYearPlayersAggregateFields';
  avg?: Maybe<StatTopYearPlayersAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<StatTopYearPlayersMaxFields>;
  min?: Maybe<StatTopYearPlayersMinFields>;
  stddev?: Maybe<StatTopYearPlayersStddevFields>;
  stddevPop?: Maybe<StatTopYearPlayersStddev_PopFields>;
  stddevSamp?: Maybe<StatTopYearPlayersStddev_SampFields>;
  sum?: Maybe<StatTopYearPlayersSumFields>;
  varPop?: Maybe<StatTopYearPlayersVar_PopFields>;
  varSamp?: Maybe<StatTopYearPlayersVar_SampFields>;
  variance?: Maybe<StatTopYearPlayersVarianceFields>;
};

/** aggregate fields of "stat_top_year_players" */
export type StatTopYearPlayersAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<StatTopYearPlayersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type StatTopYearPlayersAvgFields = {
  __typename?: 'StatTopYearPlayersAvgFields';
  id?: Maybe<Scalars['Float']['output']>;
  money?: Maybe<Scalars['Float']['output']>;
  points?: Maybe<Scalars['Float']['output']>;
  ranking?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "stat_top_year_players". All fields are combined with a logical 'AND'. */
export type StatTopYearPlayersBoolExp = {
  _and?: InputMaybe<Array<StatTopYearPlayersBoolExp>>;
  _not?: InputMaybe<StatTopYearPlayersBoolExp>;
  _or?: InputMaybe<Array<StatTopYearPlayersBoolExp>>;
  id?: InputMaybe<BigintComparisonExp>;
  money?: InputMaybe<NumericComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  points?: InputMaybe<BigintComparisonExp>;
  ranking?: InputMaybe<BigintComparisonExp>;
  slug?: InputMaybe<StringComparisonExp>;
  stat_player?: InputMaybe<StatPlayersBoolExp>;
};

/** aggregate max on columns */
export type StatTopYearPlayersMaxFields = {
  __typename?: 'StatTopYearPlayersMaxFields';
  id?: Maybe<Scalars['bigint']['output']>;
  money?: Maybe<Scalars['numeric']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  points?: Maybe<Scalars['bigint']['output']>;
  ranking?: Maybe<Scalars['bigint']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type StatTopYearPlayersMinFields = {
  __typename?: 'StatTopYearPlayersMinFields';
  id?: Maybe<Scalars['bigint']['output']>;
  money?: Maybe<Scalars['numeric']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  points?: Maybe<Scalars['bigint']['output']>;
  ranking?: Maybe<Scalars['bigint']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
};

/** Ordering options when selecting data from "stat_top_year_players". */
export type StatTopYearPlayersOrderBy = {
  id?: InputMaybe<OrderBy>;
  money?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  points?: InputMaybe<OrderBy>;
  ranking?: InputMaybe<OrderBy>;
  slug?: InputMaybe<OrderBy>;
  stat_player?: InputMaybe<StatPlayersOrderBy>;
};

/** select columns of table "stat_top_year_players" */
export enum StatTopYearPlayersSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  Money = 'money',
  /** column name */
  Name = 'name',
  /** column name */
  Points = 'points',
  /** column name */
  Ranking = 'ranking',
  /** column name */
  Slug = 'slug',
}

/** aggregate stddev on columns */
export type StatTopYearPlayersStddevFields = {
  __typename?: 'StatTopYearPlayersStddevFields';
  id?: Maybe<Scalars['Float']['output']>;
  money?: Maybe<Scalars['Float']['output']>;
  points?: Maybe<Scalars['Float']['output']>;
  ranking?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type StatTopYearPlayersStddev_PopFields = {
  __typename?: 'StatTopYearPlayersStddev_popFields';
  id?: Maybe<Scalars['Float']['output']>;
  money?: Maybe<Scalars['Float']['output']>;
  points?: Maybe<Scalars['Float']['output']>;
  ranking?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type StatTopYearPlayersStddev_SampFields = {
  __typename?: 'StatTopYearPlayersStddev_sampFields';
  id?: Maybe<Scalars['Float']['output']>;
  money?: Maybe<Scalars['Float']['output']>;
  points?: Maybe<Scalars['Float']['output']>;
  ranking?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "stat_top_year_players" */
export type StatTopYearPlayersStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: StatTopYearPlayersStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type StatTopYearPlayersStreamCursorValueInput = {
  id?: InputMaybe<Scalars['bigint']['input']>;
  money?: InputMaybe<Scalars['numeric']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  points?: InputMaybe<Scalars['bigint']['input']>;
  ranking?: InputMaybe<Scalars['bigint']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type StatTopYearPlayersSumFields = {
  __typename?: 'StatTopYearPlayersSumFields';
  id?: Maybe<Scalars['bigint']['output']>;
  money?: Maybe<Scalars['numeric']['output']>;
  points?: Maybe<Scalars['bigint']['output']>;
  ranking?: Maybe<Scalars['bigint']['output']>;
};

/** aggregate var_pop on columns */
export type StatTopYearPlayersVar_PopFields = {
  __typename?: 'StatTopYearPlayersVar_popFields';
  id?: Maybe<Scalars['Float']['output']>;
  money?: Maybe<Scalars['Float']['output']>;
  points?: Maybe<Scalars['Float']['output']>;
  ranking?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type StatTopYearPlayersVar_SampFields = {
  __typename?: 'StatTopYearPlayersVar_sampFields';
  id?: Maybe<Scalars['Float']['output']>;
  money?: Maybe<Scalars['Float']['output']>;
  points?: Maybe<Scalars['Float']['output']>;
  ranking?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type StatTopYearPlayersVarianceFields = {
  __typename?: 'StatTopYearPlayersVarianceFields';
  id?: Maybe<Scalars['Float']['output']>;
  money?: Maybe<Scalars['Float']['output']>;
  points?: Maybe<Scalars['Float']['output']>;
  ranking?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "stat_tournament_results" */
export type StatTournamentResults = {
  __typename?: 'StatTournamentResults';
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  id: Scalars['bigint']['output'];
  place?: Maybe<Scalars['bigint']['output']>;
  playerGeneralResultId?: Maybe<Scalars['bigint']['output']>;
  points?: Maybe<Scalars['Int']['output']>;
  price: Scalars['numeric']['output'];
  priceLocal: Scalars['numeric']['output'];
  statPlayerId: Scalars['bigint']['output'];
  statTournamentId: Scalars['bigint']['output'];
  /** An object relationship */
  stat_player: StatPlayers;
  /** An object relationship */
  stat_tournament: StatTournaments;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
};

/** aggregated selection of "stat_tournament_results" */
export type StatTournamentResultsAggregate = {
  __typename?: 'StatTournamentResultsAggregate';
  aggregate?: Maybe<StatTournamentResultsAggregateFields>;
  nodes: Array<StatTournamentResults>;
};

/** aggregate fields of "stat_tournament_results" */
export type StatTournamentResultsAggregateFields = {
  __typename?: 'StatTournamentResultsAggregateFields';
  avg?: Maybe<StatTournamentResultsAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<StatTournamentResultsMaxFields>;
  min?: Maybe<StatTournamentResultsMinFields>;
  stddev?: Maybe<StatTournamentResultsStddevFields>;
  stddevPop?: Maybe<StatTournamentResultsStddev_PopFields>;
  stddevSamp?: Maybe<StatTournamentResultsStddev_SampFields>;
  sum?: Maybe<StatTournamentResultsSumFields>;
  varPop?: Maybe<StatTournamentResultsVar_PopFields>;
  varSamp?: Maybe<StatTournamentResultsVar_SampFields>;
  variance?: Maybe<StatTournamentResultsVarianceFields>;
};

/** aggregate fields of "stat_tournament_results" */
export type StatTournamentResultsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<StatTournamentResultsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "stat_tournament_results" */
export type StatTournamentResultsAggregateOrderBy = {
  avg?: InputMaybe<Stat_Tournament_Results_Avg_Order_By>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<Stat_Tournament_Results_Max_Order_By>;
  min?: InputMaybe<Stat_Tournament_Results_Min_Order_By>;
  stddev?: InputMaybe<Stat_Tournament_Results_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Stat_Tournament_Results_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Stat_Tournament_Results_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Stat_Tournament_Results_Sum_Order_By>;
  var_pop?: InputMaybe<Stat_Tournament_Results_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Stat_Tournament_Results_Var_Samp_Order_By>;
  variance?: InputMaybe<Stat_Tournament_Results_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "stat_tournament_results" */
export type StatTournamentResultsArrRelInsertInput = {
  data: Array<StatTournamentResultsInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<StatTournamentResultsOnConflict>;
};

/** aggregate avg on columns */
export type StatTournamentResultsAvgFields = {
  __typename?: 'StatTournamentResultsAvgFields';
  id?: Maybe<Scalars['Float']['output']>;
  place?: Maybe<Scalars['Float']['output']>;
  playerGeneralResultId?: Maybe<Scalars['Float']['output']>;
  points?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  priceLocal?: Maybe<Scalars['Float']['output']>;
  statPlayerId?: Maybe<Scalars['Float']['output']>;
  statTournamentId?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "stat_tournament_results". All fields are combined with a logical 'AND'. */
export type StatTournamentResultsBoolExp = {
  _and?: InputMaybe<Array<StatTournamentResultsBoolExp>>;
  _not?: InputMaybe<StatTournamentResultsBoolExp>;
  _or?: InputMaybe<Array<StatTournamentResultsBoolExp>>;
  createdAt?: InputMaybe<TimestampComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  place?: InputMaybe<BigintComparisonExp>;
  playerGeneralResultId?: InputMaybe<BigintComparisonExp>;
  points?: InputMaybe<IntComparisonExp>;
  price?: InputMaybe<NumericComparisonExp>;
  priceLocal?: InputMaybe<NumericComparisonExp>;
  statPlayerId?: InputMaybe<BigintComparisonExp>;
  statTournamentId?: InputMaybe<BigintComparisonExp>;
  stat_player?: InputMaybe<StatPlayersBoolExp>;
  stat_tournament?: InputMaybe<StatTournamentsBoolExp>;
  updatedAt?: InputMaybe<TimestampComparisonExp>;
};

/** unique or primary key constraints on table "stat_tournament_results" */
export enum StatTournamentResultsConstraint {
  /** unique or primary key constraint on columns "id" */
  StatTournamentResultsPkey = 'stat_tournament_results_pkey',
  /** unique or primary key constraint on columns "stat_tournament_id", "stat_player_id" */
  StatTournamentResultsStatPlayerIdStatTournamentIdUniqu = 'stat_tournament_results_stat_player_id_stat_tournament_id_uniqu',
}

/** input type for incrementing numeric columns in table "stat_tournament_results" */
export type StatTournamentResultsIncInput = {
  id?: InputMaybe<Scalars['bigint']['input']>;
  place?: InputMaybe<Scalars['bigint']['input']>;
  playerGeneralResultId?: InputMaybe<Scalars['bigint']['input']>;
  points?: InputMaybe<Scalars['Int']['input']>;
  price?: InputMaybe<Scalars['numeric']['input']>;
  priceLocal?: InputMaybe<Scalars['numeric']['input']>;
  statPlayerId?: InputMaybe<Scalars['bigint']['input']>;
  statTournamentId?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "stat_tournament_results" */
export type StatTournamentResultsInsertInput = {
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  place?: InputMaybe<Scalars['bigint']['input']>;
  playerGeneralResultId?: InputMaybe<Scalars['bigint']['input']>;
  points?: InputMaybe<Scalars['Int']['input']>;
  price?: InputMaybe<Scalars['numeric']['input']>;
  priceLocal?: InputMaybe<Scalars['numeric']['input']>;
  statPlayerId?: InputMaybe<Scalars['bigint']['input']>;
  statTournamentId?: InputMaybe<Scalars['bigint']['input']>;
  stat_player?: InputMaybe<StatPlayersObjRelInsertInput>;
  stat_tournament?: InputMaybe<StatTournamentsObjRelInsertInput>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate max on columns */
export type StatTournamentResultsMaxFields = {
  __typename?: 'StatTournamentResultsMaxFields';
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  place?: Maybe<Scalars['bigint']['output']>;
  playerGeneralResultId?: Maybe<Scalars['bigint']['output']>;
  points?: Maybe<Scalars['Int']['output']>;
  price?: Maybe<Scalars['numeric']['output']>;
  priceLocal?: Maybe<Scalars['numeric']['output']>;
  statPlayerId?: Maybe<Scalars['bigint']['output']>;
  statTournamentId?: Maybe<Scalars['bigint']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
};

/** aggregate min on columns */
export type StatTournamentResultsMinFields = {
  __typename?: 'StatTournamentResultsMinFields';
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  place?: Maybe<Scalars['bigint']['output']>;
  playerGeneralResultId?: Maybe<Scalars['bigint']['output']>;
  points?: Maybe<Scalars['Int']['output']>;
  price?: Maybe<Scalars['numeric']['output']>;
  priceLocal?: Maybe<Scalars['numeric']['output']>;
  statPlayerId?: Maybe<Scalars['bigint']['output']>;
  statTournamentId?: Maybe<Scalars['bigint']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
};

/** response of any mutation on the table "stat_tournament_results" */
export type StatTournamentResultsMutationResponse = {
  __typename?: 'StatTournamentResultsMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<StatTournamentResults>;
};

/** on_conflict condition type for table "stat_tournament_results" */
export type StatTournamentResultsOnConflict = {
  constraint: StatTournamentResultsConstraint;
  update_columns?: Array<StatTournamentResultsUpdateColumn>;
  where?: InputMaybe<StatTournamentResultsBoolExp>;
};

/** Ordering options when selecting data from "stat_tournament_results". */
export type StatTournamentResultsOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  place?: InputMaybe<OrderBy>;
  playerGeneralResultId?: InputMaybe<OrderBy>;
  points?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  priceLocal?: InputMaybe<OrderBy>;
  statPlayerId?: InputMaybe<OrderBy>;
  statTournamentId?: InputMaybe<OrderBy>;
  stat_player?: InputMaybe<StatPlayersOrderBy>;
  stat_tournament?: InputMaybe<StatTournamentsOrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: stat_tournament_results */
export type StatTournamentResultsPkColumnsInput = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "stat_tournament_results" */
export enum StatTournamentResultsSelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Place = 'place',
  /** column name */
  PlayerGeneralResultId = 'playerGeneralResultId',
  /** column name */
  Points = 'points',
  /** column name */
  Price = 'price',
  /** column name */
  PriceLocal = 'priceLocal',
  /** column name */
  StatPlayerId = 'statPlayerId',
  /** column name */
  StatTournamentId = 'statTournamentId',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** input type for updating data in table "stat_tournament_results" */
export type StatTournamentResultsSetInput = {
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  place?: InputMaybe<Scalars['bigint']['input']>;
  playerGeneralResultId?: InputMaybe<Scalars['bigint']['input']>;
  points?: InputMaybe<Scalars['Int']['input']>;
  price?: InputMaybe<Scalars['numeric']['input']>;
  priceLocal?: InputMaybe<Scalars['numeric']['input']>;
  statPlayerId?: InputMaybe<Scalars['bigint']['input']>;
  statTournamentId?: InputMaybe<Scalars['bigint']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate stddev on columns */
export type StatTournamentResultsStddevFields = {
  __typename?: 'StatTournamentResultsStddevFields';
  id?: Maybe<Scalars['Float']['output']>;
  place?: Maybe<Scalars['Float']['output']>;
  playerGeneralResultId?: Maybe<Scalars['Float']['output']>;
  points?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  priceLocal?: Maybe<Scalars['Float']['output']>;
  statPlayerId?: Maybe<Scalars['Float']['output']>;
  statTournamentId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type StatTournamentResultsStddev_PopFields = {
  __typename?: 'StatTournamentResultsStddev_popFields';
  id?: Maybe<Scalars['Float']['output']>;
  place?: Maybe<Scalars['Float']['output']>;
  playerGeneralResultId?: Maybe<Scalars['Float']['output']>;
  points?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  priceLocal?: Maybe<Scalars['Float']['output']>;
  statPlayerId?: Maybe<Scalars['Float']['output']>;
  statTournamentId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type StatTournamentResultsStddev_SampFields = {
  __typename?: 'StatTournamentResultsStddev_sampFields';
  id?: Maybe<Scalars['Float']['output']>;
  place?: Maybe<Scalars['Float']['output']>;
  playerGeneralResultId?: Maybe<Scalars['Float']['output']>;
  points?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  priceLocal?: Maybe<Scalars['Float']['output']>;
  statPlayerId?: Maybe<Scalars['Float']['output']>;
  statTournamentId?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "stat_tournament_results" */
export type StatTournamentResultsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: StatTournamentResultsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type StatTournamentResultsStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  place?: InputMaybe<Scalars['bigint']['input']>;
  playerGeneralResultId?: InputMaybe<Scalars['bigint']['input']>;
  points?: InputMaybe<Scalars['Int']['input']>;
  price?: InputMaybe<Scalars['numeric']['input']>;
  priceLocal?: InputMaybe<Scalars['numeric']['input']>;
  statPlayerId?: InputMaybe<Scalars['bigint']['input']>;
  statTournamentId?: InputMaybe<Scalars['bigint']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate sum on columns */
export type StatTournamentResultsSumFields = {
  __typename?: 'StatTournamentResultsSumFields';
  id?: Maybe<Scalars['bigint']['output']>;
  place?: Maybe<Scalars['bigint']['output']>;
  playerGeneralResultId?: Maybe<Scalars['bigint']['output']>;
  points?: Maybe<Scalars['Int']['output']>;
  price?: Maybe<Scalars['numeric']['output']>;
  priceLocal?: Maybe<Scalars['numeric']['output']>;
  statPlayerId?: Maybe<Scalars['bigint']['output']>;
  statTournamentId?: Maybe<Scalars['bigint']['output']>;
};

/** update columns of table "stat_tournament_results" */
export enum StatTournamentResultsUpdateColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Place = 'place',
  /** column name */
  PlayerGeneralResultId = 'playerGeneralResultId',
  /** column name */
  Points = 'points',
  /** column name */
  Price = 'price',
  /** column name */
  PriceLocal = 'priceLocal',
  /** column name */
  StatPlayerId = 'statPlayerId',
  /** column name */
  StatTournamentId = 'statTournamentId',
  /** column name */
  UpdatedAt = 'updatedAt',
}

export type StatTournamentResultsUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<StatTournamentResultsIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<StatTournamentResultsSetInput>;
  /** filter the rows which have to be updated */
  where: StatTournamentResultsBoolExp;
};

/** aggregate var_pop on columns */
export type StatTournamentResultsVar_PopFields = {
  __typename?: 'StatTournamentResultsVar_popFields';
  id?: Maybe<Scalars['Float']['output']>;
  place?: Maybe<Scalars['Float']['output']>;
  playerGeneralResultId?: Maybe<Scalars['Float']['output']>;
  points?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  priceLocal?: Maybe<Scalars['Float']['output']>;
  statPlayerId?: Maybe<Scalars['Float']['output']>;
  statTournamentId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type StatTournamentResultsVar_SampFields = {
  __typename?: 'StatTournamentResultsVar_sampFields';
  id?: Maybe<Scalars['Float']['output']>;
  place?: Maybe<Scalars['Float']['output']>;
  playerGeneralResultId?: Maybe<Scalars['Float']['output']>;
  points?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  priceLocal?: Maybe<Scalars['Float']['output']>;
  statPlayerId?: Maybe<Scalars['Float']['output']>;
  statTournamentId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type StatTournamentResultsVarianceFields = {
  __typename?: 'StatTournamentResultsVarianceFields';
  id?: Maybe<Scalars['Float']['output']>;
  place?: Maybe<Scalars['Float']['output']>;
  playerGeneralResultId?: Maybe<Scalars['Float']['output']>;
  points?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  priceLocal?: Maybe<Scalars['Float']['output']>;
  statPlayerId?: Maybe<Scalars['Float']['output']>;
  statTournamentId?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "stat_tournaments" */
export type StatTournaments = {
  __typename?: 'StatTournaments';
  buyIn?: Maybe<Scalars['numeric']['output']>;
  buyInLocal?: Maybe<Scalars['numeric']['output']>;
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  /** An object relationship */
  currency: Currencies;
  date: Scalars['timestamp']['output'];
  id: Scalars['bigint']['output'];
  localCurrencyId: Scalars['Int']['output'];
  location?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  prizepool?: Maybe<Scalars['numeric']['output']>;
  prizepoolLocal?: Maybe<Scalars['numeric']['output']>;
  statEventId?: Maybe<Scalars['bigint']['output']>;
  statFileId?: Maybe<Scalars['bigint']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
  /** An object relationship */
  venue: Venues;
  venueId: Scalars['uuid']['output'];
};

/** aggregated selection of "stat_tournaments" */
export type StatTournamentsAggregate = {
  __typename?: 'StatTournamentsAggregate';
  aggregate?: Maybe<StatTournamentsAggregateFields>;
  nodes: Array<StatTournaments>;
};

/** aggregate fields of "stat_tournaments" */
export type StatTournamentsAggregateFields = {
  __typename?: 'StatTournamentsAggregateFields';
  avg?: Maybe<StatTournamentsAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<StatTournamentsMaxFields>;
  min?: Maybe<StatTournamentsMinFields>;
  stddev?: Maybe<StatTournamentsStddevFields>;
  stddevPop?: Maybe<StatTournamentsStddev_PopFields>;
  stddevSamp?: Maybe<StatTournamentsStddev_SampFields>;
  sum?: Maybe<StatTournamentsSumFields>;
  varPop?: Maybe<StatTournamentsVar_PopFields>;
  varSamp?: Maybe<StatTournamentsVar_SampFields>;
  variance?: Maybe<StatTournamentsVarianceFields>;
};

/** aggregate fields of "stat_tournaments" */
export type StatTournamentsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<StatTournamentsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type StatTournamentsAvgFields = {
  __typename?: 'StatTournamentsAvgFields';
  buyIn?: Maybe<Scalars['Float']['output']>;
  buyInLocal?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  localCurrencyId?: Maybe<Scalars['Float']['output']>;
  prizepool?: Maybe<Scalars['Float']['output']>;
  prizepoolLocal?: Maybe<Scalars['Float']['output']>;
  statEventId?: Maybe<Scalars['Float']['output']>;
  statFileId?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "stat_tournaments". All fields are combined with a logical 'AND'. */
export type StatTournamentsBoolExp = {
  _and?: InputMaybe<Array<StatTournamentsBoolExp>>;
  _not?: InputMaybe<StatTournamentsBoolExp>;
  _or?: InputMaybe<Array<StatTournamentsBoolExp>>;
  buyIn?: InputMaybe<NumericComparisonExp>;
  buyInLocal?: InputMaybe<NumericComparisonExp>;
  createdAt?: InputMaybe<TimestampComparisonExp>;
  currency?: InputMaybe<CurrenciesBoolExp>;
  date?: InputMaybe<TimestampComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  localCurrencyId?: InputMaybe<IntComparisonExp>;
  location?: InputMaybe<StringComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  prizepool?: InputMaybe<NumericComparisonExp>;
  prizepoolLocal?: InputMaybe<NumericComparisonExp>;
  statEventId?: InputMaybe<BigintComparisonExp>;
  statFileId?: InputMaybe<BigintComparisonExp>;
  updatedAt?: InputMaybe<TimestampComparisonExp>;
  venue?: InputMaybe<VenuesBoolExp>;
  venueId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "stat_tournaments" */
export enum StatTournamentsConstraint {
  /** unique or primary key constraint on columns "id" */
  StatTournamentsPkey = 'stat_tournaments_pkey',
}

/** input type for incrementing numeric columns in table "stat_tournaments" */
export type StatTournamentsIncInput = {
  buyIn?: InputMaybe<Scalars['numeric']['input']>;
  buyInLocal?: InputMaybe<Scalars['numeric']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  localCurrencyId?: InputMaybe<Scalars['Int']['input']>;
  prizepool?: InputMaybe<Scalars['numeric']['input']>;
  prizepoolLocal?: InputMaybe<Scalars['numeric']['input']>;
  statEventId?: InputMaybe<Scalars['bigint']['input']>;
  statFileId?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "stat_tournaments" */
export type StatTournamentsInsertInput = {
  buyIn?: InputMaybe<Scalars['numeric']['input']>;
  buyInLocal?: InputMaybe<Scalars['numeric']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  currency?: InputMaybe<CurrenciesObjRelInsertInput>;
  date?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  localCurrencyId?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  prizepool?: InputMaybe<Scalars['numeric']['input']>;
  prizepoolLocal?: InputMaybe<Scalars['numeric']['input']>;
  statEventId?: InputMaybe<Scalars['bigint']['input']>;
  statFileId?: InputMaybe<Scalars['bigint']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
  venue?: InputMaybe<VenuesObjRelInsertInput>;
  venueId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type StatTournamentsMaxFields = {
  __typename?: 'StatTournamentsMaxFields';
  buyIn?: Maybe<Scalars['numeric']['output']>;
  buyInLocal?: Maybe<Scalars['numeric']['output']>;
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  date?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  localCurrencyId?: Maybe<Scalars['Int']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  prizepool?: Maybe<Scalars['numeric']['output']>;
  prizepoolLocal?: Maybe<Scalars['numeric']['output']>;
  statEventId?: Maybe<Scalars['bigint']['output']>;
  statFileId?: Maybe<Scalars['bigint']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
  venueId?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type StatTournamentsMinFields = {
  __typename?: 'StatTournamentsMinFields';
  buyIn?: Maybe<Scalars['numeric']['output']>;
  buyInLocal?: Maybe<Scalars['numeric']['output']>;
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  date?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  localCurrencyId?: Maybe<Scalars['Int']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  prizepool?: Maybe<Scalars['numeric']['output']>;
  prizepoolLocal?: Maybe<Scalars['numeric']['output']>;
  statEventId?: Maybe<Scalars['bigint']['output']>;
  statFileId?: Maybe<Scalars['bigint']['output']>;
  updatedAt?: Maybe<Scalars['timestamp']['output']>;
  venueId?: Maybe<Scalars['uuid']['output']>;
};

/** response of any mutation on the table "stat_tournaments" */
export type StatTournamentsMutationResponse = {
  __typename?: 'StatTournamentsMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<StatTournaments>;
};

/** input type for inserting object relation for remote table "stat_tournaments" */
export type StatTournamentsObjRelInsertInput = {
  data: StatTournamentsInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<StatTournamentsOnConflict>;
};

/** on_conflict condition type for table "stat_tournaments" */
export type StatTournamentsOnConflict = {
  constraint: StatTournamentsConstraint;
  update_columns?: Array<StatTournamentsUpdateColumn>;
  where?: InputMaybe<StatTournamentsBoolExp>;
};

/** Ordering options when selecting data from "stat_tournaments". */
export type StatTournamentsOrderBy = {
  buyIn?: InputMaybe<OrderBy>;
  buyInLocal?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  currency?: InputMaybe<CurrenciesOrderBy>;
  date?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  localCurrencyId?: InputMaybe<OrderBy>;
  location?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  prizepool?: InputMaybe<OrderBy>;
  prizepoolLocal?: InputMaybe<OrderBy>;
  statEventId?: InputMaybe<OrderBy>;
  statFileId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  venue?: InputMaybe<VenuesOrderBy>;
  venueId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: stat_tournaments */
export type StatTournamentsPkColumnsInput = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "stat_tournaments" */
export enum StatTournamentsSelectColumn {
  /** column name */
  BuyIn = 'buyIn',
  /** column name */
  BuyInLocal = 'buyInLocal',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Date = 'date',
  /** column name */
  Id = 'id',
  /** column name */
  LocalCurrencyId = 'localCurrencyId',
  /** column name */
  Location = 'location',
  /** column name */
  Name = 'name',
  /** column name */
  Prizepool = 'prizepool',
  /** column name */
  PrizepoolLocal = 'prizepoolLocal',
  /** column name */
  StatEventId = 'statEventId',
  /** column name */
  StatFileId = 'statFileId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  VenueId = 'venueId',
}

/** input type for updating data in table "stat_tournaments" */
export type StatTournamentsSetInput = {
  buyIn?: InputMaybe<Scalars['numeric']['input']>;
  buyInLocal?: InputMaybe<Scalars['numeric']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  date?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  localCurrencyId?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  prizepool?: InputMaybe<Scalars['numeric']['input']>;
  prizepoolLocal?: InputMaybe<Scalars['numeric']['input']>;
  statEventId?: InputMaybe<Scalars['bigint']['input']>;
  statFileId?: InputMaybe<Scalars['bigint']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
  venueId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate stddev on columns */
export type StatTournamentsStddevFields = {
  __typename?: 'StatTournamentsStddevFields';
  buyIn?: Maybe<Scalars['Float']['output']>;
  buyInLocal?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  localCurrencyId?: Maybe<Scalars['Float']['output']>;
  prizepool?: Maybe<Scalars['Float']['output']>;
  prizepoolLocal?: Maybe<Scalars['Float']['output']>;
  statEventId?: Maybe<Scalars['Float']['output']>;
  statFileId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type StatTournamentsStddev_PopFields = {
  __typename?: 'StatTournamentsStddev_popFields';
  buyIn?: Maybe<Scalars['Float']['output']>;
  buyInLocal?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  localCurrencyId?: Maybe<Scalars['Float']['output']>;
  prizepool?: Maybe<Scalars['Float']['output']>;
  prizepoolLocal?: Maybe<Scalars['Float']['output']>;
  statEventId?: Maybe<Scalars['Float']['output']>;
  statFileId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type StatTournamentsStddev_SampFields = {
  __typename?: 'StatTournamentsStddev_sampFields';
  buyIn?: Maybe<Scalars['Float']['output']>;
  buyInLocal?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  localCurrencyId?: Maybe<Scalars['Float']['output']>;
  prizepool?: Maybe<Scalars['Float']['output']>;
  prizepoolLocal?: Maybe<Scalars['Float']['output']>;
  statEventId?: Maybe<Scalars['Float']['output']>;
  statFileId?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "stat_tournaments" */
export type StatTournamentsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: StatTournamentsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type StatTournamentsStreamCursorValueInput = {
  buyIn?: InputMaybe<Scalars['numeric']['input']>;
  buyInLocal?: InputMaybe<Scalars['numeric']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  date?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  localCurrencyId?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  prizepool?: InputMaybe<Scalars['numeric']['input']>;
  prizepoolLocal?: InputMaybe<Scalars['numeric']['input']>;
  statEventId?: InputMaybe<Scalars['bigint']['input']>;
  statFileId?: InputMaybe<Scalars['bigint']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
  venueId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate sum on columns */
export type StatTournamentsSumFields = {
  __typename?: 'StatTournamentsSumFields';
  buyIn?: Maybe<Scalars['numeric']['output']>;
  buyInLocal?: Maybe<Scalars['numeric']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  localCurrencyId?: Maybe<Scalars['Int']['output']>;
  prizepool?: Maybe<Scalars['numeric']['output']>;
  prizepoolLocal?: Maybe<Scalars['numeric']['output']>;
  statEventId?: Maybe<Scalars['bigint']['output']>;
  statFileId?: Maybe<Scalars['bigint']['output']>;
};

/** update columns of table "stat_tournaments" */
export enum StatTournamentsUpdateColumn {
  /** column name */
  BuyIn = 'buyIn',
  /** column name */
  BuyInLocal = 'buyInLocal',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Date = 'date',
  /** column name */
  Id = 'id',
  /** column name */
  LocalCurrencyId = 'localCurrencyId',
  /** column name */
  Location = 'location',
  /** column name */
  Name = 'name',
  /** column name */
  Prizepool = 'prizepool',
  /** column name */
  PrizepoolLocal = 'prizepoolLocal',
  /** column name */
  StatEventId = 'statEventId',
  /** column name */
  StatFileId = 'statFileId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  VenueId = 'venueId',
}

export type StatTournamentsUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<StatTournamentsIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<StatTournamentsSetInput>;
  /** filter the rows which have to be updated */
  where: StatTournamentsBoolExp;
};

/** aggregate var_pop on columns */
export type StatTournamentsVar_PopFields = {
  __typename?: 'StatTournamentsVar_popFields';
  buyIn?: Maybe<Scalars['Float']['output']>;
  buyInLocal?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  localCurrencyId?: Maybe<Scalars['Float']['output']>;
  prizepool?: Maybe<Scalars['Float']['output']>;
  prizepoolLocal?: Maybe<Scalars['Float']['output']>;
  statEventId?: Maybe<Scalars['Float']['output']>;
  statFileId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type StatTournamentsVar_SampFields = {
  __typename?: 'StatTournamentsVar_sampFields';
  buyIn?: Maybe<Scalars['Float']['output']>;
  buyInLocal?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  localCurrencyId?: Maybe<Scalars['Float']['output']>;
  prizepool?: Maybe<Scalars['Float']['output']>;
  prizepoolLocal?: Maybe<Scalars['Float']['output']>;
  statEventId?: Maybe<Scalars['Float']['output']>;
  statFileId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type StatTournamentsVarianceFields = {
  __typename?: 'StatTournamentsVarianceFields';
  buyIn?: Maybe<Scalars['Float']['output']>;
  buyInLocal?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  localCurrencyId?: Maybe<Scalars['Float']['output']>;
  prizepool?: Maybe<Scalars['Float']['output']>;
  prizepoolLocal?: Maybe<Scalars['Float']['output']>;
  statEventId?: Maybe<Scalars['Float']['output']>;
  statFileId?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "status_games" */
export type StatusGames = {
  __typename?: 'StatusGames';
  description?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

/** aggregated selection of "status_games" */
export type StatusGamesAggregate = {
  __typename?: 'StatusGamesAggregate';
  aggregate?: Maybe<StatusGamesAggregateFields>;
  nodes: Array<StatusGames>;
};

/** aggregate fields of "status_games" */
export type StatusGamesAggregateFields = {
  __typename?: 'StatusGamesAggregateFields';
  count: Scalars['Int']['output'];
  max?: Maybe<StatusGamesMaxFields>;
  min?: Maybe<StatusGamesMinFields>;
};

/** aggregate fields of "status_games" */
export type StatusGamesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<StatusGamesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "status_games". All fields are combined with a logical 'AND'. */
export type StatusGamesBoolExp = {
  _and?: InputMaybe<Array<StatusGamesBoolExp>>;
  _not?: InputMaybe<StatusGamesBoolExp>;
  _or?: InputMaybe<Array<StatusGamesBoolExp>>;
  description?: InputMaybe<StringComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "status_games" */
export enum StatusGamesConstraint {
  /** unique or primary key constraint on columns "name" */
  StatusesGamePkey = 'statuses_game_pkey',
}

export enum StatusGamesEnum {
  Active = 'ACTIVE',
  Cancelled = 'CANCELLED',
  Deleted = 'DELETED',
  Finished = 'FINISHED',
  Inactive = 'INACTIVE',
  Paid = 'PAID',
  Started = 'STARTED',
}

/** Boolean expression to compare columns of type "StatusGamesEnum". All fields are combined with logical 'AND'. */
export type StatusGamesEnumComparisonExp = {
  _eq?: InputMaybe<StatusGamesEnum>;
  _in?: InputMaybe<Array<StatusGamesEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<StatusGamesEnum>;
  _nin?: InputMaybe<Array<StatusGamesEnum>>;
};

/** input type for inserting data into table "status_games" */
export type StatusGamesInsertInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type StatusGamesMaxFields = {
  __typename?: 'StatusGamesMaxFields';
  description?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type StatusGamesMinFields = {
  __typename?: 'StatusGamesMinFields';
  description?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "status_games" */
export type StatusGamesMutationResponse = {
  __typename?: 'StatusGamesMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<StatusGames>;
};

/** on_conflict condition type for table "status_games" */
export type StatusGamesOnConflict = {
  constraint: StatusGamesConstraint;
  update_columns?: Array<StatusGamesUpdateColumn>;
  where?: InputMaybe<StatusGamesBoolExp>;
};

/** Ordering options when selecting data from "status_games". */
export type StatusGamesOrderBy = {
  description?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: status_games */
export type StatusGamesPkColumnsInput = {
  name: Scalars['String']['input'];
};

/** select columns of table "status_games" */
export enum StatusGamesSelectColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name',
}

/** input type for updating data in table "status_games" */
export type StatusGamesSetInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "status_games" */
export type StatusGamesStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: StatusGamesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type StatusGamesStreamCursorValueInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "status_games" */
export enum StatusGamesUpdateColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name',
}

export type StatusGamesUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<StatusGamesSetInput>;
  /** filter the rows which have to be updated */
  where: StatusGamesBoolExp;
};

export type StatusOutput = {
  __typename?: 'StatusOutput';
  id?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  status: TransactionStatus;
};

/** columns and relationships of "statuses" */
export type Statuses = {
  __typename?: 'Statuses';
  description?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

/** aggregated selection of "statuses" */
export type StatusesAggregate = {
  __typename?: 'StatusesAggregate';
  aggregate?: Maybe<StatusesAggregateFields>;
  nodes: Array<Statuses>;
};

/** aggregate fields of "statuses" */
export type StatusesAggregateFields = {
  __typename?: 'StatusesAggregateFields';
  count: Scalars['Int']['output'];
  max?: Maybe<StatusesMaxFields>;
  min?: Maybe<StatusesMinFields>;
};

/** aggregate fields of "statuses" */
export type StatusesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<StatusesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "statuses". All fields are combined with a logical 'AND'. */
export type StatusesBoolExp = {
  _and?: InputMaybe<Array<StatusesBoolExp>>;
  _not?: InputMaybe<StatusesBoolExp>;
  _or?: InputMaybe<Array<StatusesBoolExp>>;
  description?: InputMaybe<StringComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "statuses" */
export enum StatusesConstraint {
  /** unique or primary key constraint on columns "name" */
  StatusesPkey = 'statuses_pkey',
}

export enum StatusesEnum {
  Active = 'ACTIVE',
  Deleted = 'DELETED',
  Finished = 'FINISHED',
  Inactive = 'INACTIVE',
  Pending = 'PENDING',
  VerifyMail = 'VERIFY_MAIL',
}

/** Boolean expression to compare columns of type "StatusesEnum". All fields are combined with logical 'AND'. */
export type StatusesEnumComparisonExp = {
  _eq?: InputMaybe<StatusesEnum>;
  _in?: InputMaybe<Array<StatusesEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<StatusesEnum>;
  _nin?: InputMaybe<Array<StatusesEnum>>;
};

/** input type for inserting data into table "statuses" */
export type StatusesInsertInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type StatusesMaxFields = {
  __typename?: 'StatusesMaxFields';
  description?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type StatusesMinFields = {
  __typename?: 'StatusesMinFields';
  description?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "statuses" */
export type StatusesMutationResponse = {
  __typename?: 'StatusesMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Statuses>;
};

/** on_conflict condition type for table "statuses" */
export type StatusesOnConflict = {
  constraint: StatusesConstraint;
  update_columns?: Array<StatusesUpdateColumn>;
  where?: InputMaybe<StatusesBoolExp>;
};

/** Ordering options when selecting data from "statuses". */
export type StatusesOrderBy = {
  description?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: statuses */
export type StatusesPkColumnsInput = {
  name: Scalars['String']['input'];
};

/** select columns of table "statuses" */
export enum StatusesSelectColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name',
}

/** input type for updating data in table "statuses" */
export type StatusesSetInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "statuses" */
export type StatusesStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: StatusesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type StatusesStreamCursorValueInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "statuses" */
export enum StatusesUpdateColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name',
}

export type StatusesUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<StatusesSetInput>;
  /** filter the rows which have to be updated */
  where: StatusesBoolExp;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type StringComparisonExp = {
  _eq?: InputMaybe<Scalars['String']['input']>;
  _gt?: InputMaybe<Scalars['String']['input']>;
  _gte?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']['input']>;
  _in?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']['input']>;
  _isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']['input']>;
  _lt?: InputMaybe<Scalars['String']['input']>;
  _lte?: InputMaybe<Scalars['String']['input']>;
  _neq?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']['input']>;
  _nin?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "tickets" */
export type Tickets = {
  __typename?: 'Tickets';
  amount: Scalars['numeric']['output'];
  bullet: Scalars['Int']['output'];
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id: Scalars['uuid']['output'];
  playerName?: Maybe<Scalars['String']['output']>;
  playerShareId?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  player_share?: Maybe<PlayerShares>;
  status: TicketsStatusEnum;
  /** An object relationship */
  tournament: Tournaments;
  tournamentId: Scalars['uuid']['output'];
  tournamentResultId?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  tournament_result?: Maybe<TournamentResults>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  user: Users;
  userId: Scalars['String']['output'];
  won?: Maybe<Scalars['numeric']['output']>;
  wonLocal?: Maybe<Scalars['numeric']['output']>;
};

/** aggregated selection of "tickets" */
export type TicketsAggregate = {
  __typename?: 'TicketsAggregate';
  aggregate?: Maybe<TicketsAggregateFields>;
  nodes: Array<Tickets>;
};

/** aggregate fields of "tickets" */
export type TicketsAggregateFields = {
  __typename?: 'TicketsAggregateFields';
  avg?: Maybe<TicketsAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<TicketsMaxFields>;
  min?: Maybe<TicketsMinFields>;
  stddev?: Maybe<TicketsStddevFields>;
  stddevPop?: Maybe<TicketsStddev_PopFields>;
  stddevSamp?: Maybe<TicketsStddev_SampFields>;
  sum?: Maybe<TicketsSumFields>;
  varPop?: Maybe<TicketsVar_PopFields>;
  varSamp?: Maybe<TicketsVar_SampFields>;
  variance?: Maybe<TicketsVarianceFields>;
};

/** aggregate fields of "tickets" */
export type TicketsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<TicketsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "tickets" */
export type TicketsAggregateOrderBy = {
  avg?: InputMaybe<Tickets_Avg_Order_By>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<Tickets_Max_Order_By>;
  min?: InputMaybe<Tickets_Min_Order_By>;
  stddev?: InputMaybe<Tickets_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Tickets_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Tickets_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Tickets_Sum_Order_By>;
  var_pop?: InputMaybe<Tickets_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Tickets_Var_Samp_Order_By>;
  variance?: InputMaybe<Tickets_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "tickets" */
export type TicketsArrRelInsertInput = {
  data: Array<TicketsInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<TicketsOnConflict>;
};

/** aggregate avg on columns */
export type TicketsAvgFields = {
  __typename?: 'TicketsAvgFields';
  amount?: Maybe<Scalars['Float']['output']>;
  bullet?: Maybe<Scalars['Float']['output']>;
  tournamentResultId?: Maybe<Scalars['Float']['output']>;
  won?: Maybe<Scalars['Float']['output']>;
  wonLocal?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "tickets". All fields are combined with a logical 'AND'. */
export type TicketsBoolExp = {
  _and?: InputMaybe<Array<TicketsBoolExp>>;
  _not?: InputMaybe<TicketsBoolExp>;
  _or?: InputMaybe<Array<TicketsBoolExp>>;
  amount?: InputMaybe<NumericComparisonExp>;
  bullet?: InputMaybe<IntComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  playerName?: InputMaybe<StringComparisonExp>;
  playerShareId?: InputMaybe<UuidComparisonExp>;
  player_share?: InputMaybe<PlayerSharesBoolExp>;
  status?: InputMaybe<TicketsStatusEnumComparisonExp>;
  tournament?: InputMaybe<TournamentsBoolExp>;
  tournamentId?: InputMaybe<UuidComparisonExp>;
  tournamentResultId?: InputMaybe<IntComparisonExp>;
  tournament_result?: InputMaybe<TournamentResultsBoolExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  user?: InputMaybe<UsersBoolExp>;
  userId?: InputMaybe<StringComparisonExp>;
  won?: InputMaybe<NumericComparisonExp>;
  wonLocal?: InputMaybe<NumericComparisonExp>;
};

/** unique or primary key constraints on table "tickets" */
export enum TicketsConstraint {
  /** unique or primary key constraint on columns "id" */
  TicketsPkey = 'tickets_pkey',
}

/** input type for incrementing numeric columns in table "tickets" */
export type TicketsIncInput = {
  amount?: InputMaybe<Scalars['numeric']['input']>;
  bullet?: InputMaybe<Scalars['Int']['input']>;
  tournamentResultId?: InputMaybe<Scalars['Int']['input']>;
  won?: InputMaybe<Scalars['numeric']['input']>;
  wonLocal?: InputMaybe<Scalars['numeric']['input']>;
};

/** input type for inserting data into table "tickets" */
export type TicketsInsertInput = {
  amount?: InputMaybe<Scalars['numeric']['input']>;
  bullet?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  playerName?: InputMaybe<Scalars['String']['input']>;
  playerShareId?: InputMaybe<Scalars['uuid']['input']>;
  player_share?: InputMaybe<PlayerSharesObjRelInsertInput>;
  status?: InputMaybe<TicketsStatusEnum>;
  tournament?: InputMaybe<TournamentsObjRelInsertInput>;
  tournamentId?: InputMaybe<Scalars['uuid']['input']>;
  tournamentResultId?: InputMaybe<Scalars['Int']['input']>;
  tournament_result?: InputMaybe<TournamentResultsObjRelInsertInput>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<UsersObjRelInsertInput>;
  userId?: InputMaybe<Scalars['String']['input']>;
  won?: InputMaybe<Scalars['numeric']['input']>;
  wonLocal?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate max on columns */
export type TicketsMaxFields = {
  __typename?: 'TicketsMaxFields';
  amount?: Maybe<Scalars['numeric']['output']>;
  bullet?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  playerName?: Maybe<Scalars['String']['output']>;
  playerShareId?: Maybe<Scalars['uuid']['output']>;
  tournamentId?: Maybe<Scalars['uuid']['output']>;
  tournamentResultId?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
  won?: Maybe<Scalars['numeric']['output']>;
  wonLocal?: Maybe<Scalars['numeric']['output']>;
};

/** aggregate min on columns */
export type TicketsMinFields = {
  __typename?: 'TicketsMinFields';
  amount?: Maybe<Scalars['numeric']['output']>;
  bullet?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  playerName?: Maybe<Scalars['String']['output']>;
  playerShareId?: Maybe<Scalars['uuid']['output']>;
  tournamentId?: Maybe<Scalars['uuid']['output']>;
  tournamentResultId?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
  won?: Maybe<Scalars['numeric']['output']>;
  wonLocal?: Maybe<Scalars['numeric']['output']>;
};

/** response of any mutation on the table "tickets" */
export type TicketsMutationResponse = {
  __typename?: 'TicketsMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Tickets>;
};

/** on_conflict condition type for table "tickets" */
export type TicketsOnConflict = {
  constraint: TicketsConstraint;
  update_columns?: Array<TicketsUpdateColumn>;
  where?: InputMaybe<TicketsBoolExp>;
};

/** Ordering options when selecting data from "tickets". */
export type TicketsOrderBy = {
  amount?: InputMaybe<OrderBy>;
  bullet?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  playerName?: InputMaybe<OrderBy>;
  playerShareId?: InputMaybe<OrderBy>;
  player_share?: InputMaybe<PlayerSharesOrderBy>;
  status?: InputMaybe<OrderBy>;
  tournament?: InputMaybe<TournamentsOrderBy>;
  tournamentId?: InputMaybe<OrderBy>;
  tournamentResultId?: InputMaybe<OrderBy>;
  tournament_result?: InputMaybe<TournamentResultsOrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  user?: InputMaybe<UsersOrderBy>;
  userId?: InputMaybe<OrderBy>;
  won?: InputMaybe<OrderBy>;
  wonLocal?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: tickets */
export type TicketsPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "tickets" */
export enum TicketsSelectColumn {
  /** column name */
  Amount = 'amount',
  /** column name */
  Bullet = 'bullet',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  PlayerName = 'playerName',
  /** column name */
  PlayerShareId = 'playerShareId',
  /** column name */
  Status = 'status',
  /** column name */
  TournamentId = 'tournamentId',
  /** column name */
  TournamentResultId = 'tournamentResultId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId',
  /** column name */
  Won = 'won',
  /** column name */
  WonLocal = 'wonLocal',
}

/** input type for updating data in table "tickets" */
export type TicketsSetInput = {
  amount?: InputMaybe<Scalars['numeric']['input']>;
  bullet?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  playerName?: InputMaybe<Scalars['String']['input']>;
  playerShareId?: InputMaybe<Scalars['uuid']['input']>;
  status?: InputMaybe<TicketsStatusEnum>;
  tournamentId?: InputMaybe<Scalars['uuid']['input']>;
  tournamentResultId?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
  won?: InputMaybe<Scalars['numeric']['input']>;
  wonLocal?: InputMaybe<Scalars['numeric']['input']>;
};

/** columns and relationships of "tickets_status" */
export type TicketsStatus = {
  __typename?: 'TicketsStatus';
  description?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

/** aggregated selection of "tickets_status" */
export type TicketsStatusAggregate = {
  __typename?: 'TicketsStatusAggregate';
  aggregate?: Maybe<TicketsStatusAggregateFields>;
  nodes: Array<TicketsStatus>;
};

/** aggregate fields of "tickets_status" */
export type TicketsStatusAggregateFields = {
  __typename?: 'TicketsStatusAggregateFields';
  count: Scalars['Int']['output'];
  max?: Maybe<TicketsStatusMaxFields>;
  min?: Maybe<TicketsStatusMinFields>;
};

/** aggregate fields of "tickets_status" */
export type TicketsStatusAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<TicketsStatusSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "tickets_status". All fields are combined with a logical 'AND'. */
export type TicketsStatusBoolExp = {
  _and?: InputMaybe<Array<TicketsStatusBoolExp>>;
  _not?: InputMaybe<TicketsStatusBoolExp>;
  _or?: InputMaybe<Array<TicketsStatusBoolExp>>;
  description?: InputMaybe<StringComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "tickets_status" */
export enum TicketsStatusConstraint {
  /** unique or primary key constraint on columns "name" */
  TicketsStatusPkey = 'tickets_status_pkey',
}

export enum TicketsStatusEnum {
  Active = 'ACTIVE',
  Cancelled = 'CANCELLED',
  Finished = 'FINISHED',
  PaidLocal = 'PAID_LOCAL',
  Started = 'STARTED',
}

/** Boolean expression to compare columns of type "TicketsStatusEnum". All fields are combined with logical 'AND'. */
export type TicketsStatusEnumComparisonExp = {
  _eq?: InputMaybe<TicketsStatusEnum>;
  _in?: InputMaybe<Array<TicketsStatusEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<TicketsStatusEnum>;
  _nin?: InputMaybe<Array<TicketsStatusEnum>>;
};

/** input type for inserting data into table "tickets_status" */
export type TicketsStatusInsertInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type TicketsStatusMaxFields = {
  __typename?: 'TicketsStatusMaxFields';
  description?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type TicketsStatusMinFields = {
  __typename?: 'TicketsStatusMinFields';
  description?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "tickets_status" */
export type TicketsStatusMutationResponse = {
  __typename?: 'TicketsStatusMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<TicketsStatus>;
};

/** on_conflict condition type for table "tickets_status" */
export type TicketsStatusOnConflict = {
  constraint: TicketsStatusConstraint;
  update_columns?: Array<TicketsStatusUpdateColumn>;
  where?: InputMaybe<TicketsStatusBoolExp>;
};

/** Ordering options when selecting data from "tickets_status". */
export type TicketsStatusOrderBy = {
  description?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: tickets_status */
export type TicketsStatusPkColumnsInput = {
  name: Scalars['String']['input'];
};

/** select columns of table "tickets_status" */
export enum TicketsStatusSelectColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name',
}

/** input type for updating data in table "tickets_status" */
export type TicketsStatusSetInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "tickets_status" */
export type TicketsStatusStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: TicketsStatusStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type TicketsStatusStreamCursorValueInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "tickets_status" */
export enum TicketsStatusUpdateColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name',
}

export type TicketsStatusUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<TicketsStatusSetInput>;
  /** filter the rows which have to be updated */
  where: TicketsStatusBoolExp;
};

/** aggregate stddev on columns */
export type TicketsStddevFields = {
  __typename?: 'TicketsStddevFields';
  amount?: Maybe<Scalars['Float']['output']>;
  bullet?: Maybe<Scalars['Float']['output']>;
  tournamentResultId?: Maybe<Scalars['Float']['output']>;
  won?: Maybe<Scalars['Float']['output']>;
  wonLocal?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type TicketsStddev_PopFields = {
  __typename?: 'TicketsStddev_popFields';
  amount?: Maybe<Scalars['Float']['output']>;
  bullet?: Maybe<Scalars['Float']['output']>;
  tournamentResultId?: Maybe<Scalars['Float']['output']>;
  won?: Maybe<Scalars['Float']['output']>;
  wonLocal?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type TicketsStddev_SampFields = {
  __typename?: 'TicketsStddev_sampFields';
  amount?: Maybe<Scalars['Float']['output']>;
  bullet?: Maybe<Scalars['Float']['output']>;
  tournamentResultId?: Maybe<Scalars['Float']['output']>;
  won?: Maybe<Scalars['Float']['output']>;
  wonLocal?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "tickets" */
export type TicketsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: TicketsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type TicketsStreamCursorValueInput = {
  amount?: InputMaybe<Scalars['numeric']['input']>;
  bullet?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  playerName?: InputMaybe<Scalars['String']['input']>;
  playerShareId?: InputMaybe<Scalars['uuid']['input']>;
  status?: InputMaybe<TicketsStatusEnum>;
  tournamentId?: InputMaybe<Scalars['uuid']['input']>;
  tournamentResultId?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
  won?: InputMaybe<Scalars['numeric']['input']>;
  wonLocal?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate sum on columns */
export type TicketsSumFields = {
  __typename?: 'TicketsSumFields';
  amount?: Maybe<Scalars['numeric']['output']>;
  bullet?: Maybe<Scalars['Int']['output']>;
  tournamentResultId?: Maybe<Scalars['Int']['output']>;
  won?: Maybe<Scalars['numeric']['output']>;
  wonLocal?: Maybe<Scalars['numeric']['output']>;
};

/** update columns of table "tickets" */
export enum TicketsUpdateColumn {
  /** column name */
  Amount = 'amount',
  /** column name */
  Bullet = 'bullet',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  PlayerName = 'playerName',
  /** column name */
  PlayerShareId = 'playerShareId',
  /** column name */
  Status = 'status',
  /** column name */
  TournamentId = 'tournamentId',
  /** column name */
  TournamentResultId = 'tournamentResultId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId',
  /** column name */
  Won = 'won',
  /** column name */
  WonLocal = 'wonLocal',
}

export type TicketsUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<TicketsIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<TicketsSetInput>;
  /** filter the rows which have to be updated */
  where: TicketsBoolExp;
};

/** aggregate var_pop on columns */
export type TicketsVar_PopFields = {
  __typename?: 'TicketsVar_popFields';
  amount?: Maybe<Scalars['Float']['output']>;
  bullet?: Maybe<Scalars['Float']['output']>;
  tournamentResultId?: Maybe<Scalars['Float']['output']>;
  won?: Maybe<Scalars['Float']['output']>;
  wonLocal?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type TicketsVar_SampFields = {
  __typename?: 'TicketsVar_sampFields';
  amount?: Maybe<Scalars['Float']['output']>;
  bullet?: Maybe<Scalars['Float']['output']>;
  tournamentResultId?: Maybe<Scalars['Float']['output']>;
  won?: Maybe<Scalars['Float']['output']>;
  wonLocal?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type TicketsVarianceFields = {
  __typename?: 'TicketsVarianceFields';
  amount?: Maybe<Scalars['Float']['output']>;
  bullet?: Maybe<Scalars['Float']['output']>;
  tournamentResultId?: Maybe<Scalars['Float']['output']>;
  won?: Maybe<Scalars['Float']['output']>;
  wonLocal?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
export type TimestampComparisonExp = {
  _eq?: InputMaybe<Scalars['timestamp']['input']>;
  _gt?: InputMaybe<Scalars['timestamp']['input']>;
  _gte?: InputMaybe<Scalars['timestamp']['input']>;
  _in?: InputMaybe<Array<Scalars['timestamp']['input']>>;
  _isNull?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['timestamp']['input']>;
  _lte?: InputMaybe<Scalars['timestamp']['input']>;
  _neq?: InputMaybe<Scalars['timestamp']['input']>;
  _nin?: InputMaybe<Array<Scalars['timestamp']['input']>>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type TimestamptzComparisonExp = {
  _eq?: InputMaybe<Scalars['timestamptz']['input']>;
  _gt?: InputMaybe<Scalars['timestamptz']['input']>;
  _gte?: InputMaybe<Scalars['timestamptz']['input']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']['input']>>;
  _isNull?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['timestamptz']['input']>;
  _lte?: InputMaybe<Scalars['timestamptz']['input']>;
  _neq?: InputMaybe<Scalars['timestamptz']['input']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']['input']>>;
};

/** columns and relationships of "tournament_balances" */
export type TournamentBalances = {
  __typename?: 'TournamentBalances';
  balance?: Maybe<Scalars['numeric']['output']>;
  /** An object relationship */
  currency?: Maybe<Currencies>;
  currencyId?: Maybe<Scalars['Int']['output']>;
  eventId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  tournament?: Maybe<Tournaments>;
  tournamentId?: Maybe<Scalars['uuid']['output']>;
  venueId?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "tournament_balances" */
export type TournamentBalancesAggregate = {
  __typename?: 'TournamentBalancesAggregate';
  aggregate?: Maybe<TournamentBalancesAggregateFields>;
  nodes: Array<TournamentBalances>;
};

/** aggregate fields of "tournament_balances" */
export type TournamentBalancesAggregateFields = {
  __typename?: 'TournamentBalancesAggregateFields';
  avg?: Maybe<TournamentBalancesAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<TournamentBalancesMaxFields>;
  min?: Maybe<TournamentBalancesMinFields>;
  stddev?: Maybe<TournamentBalancesStddevFields>;
  stddevPop?: Maybe<TournamentBalancesStddev_PopFields>;
  stddevSamp?: Maybe<TournamentBalancesStddev_SampFields>;
  sum?: Maybe<TournamentBalancesSumFields>;
  varPop?: Maybe<TournamentBalancesVar_PopFields>;
  varSamp?: Maybe<TournamentBalancesVar_SampFields>;
  variance?: Maybe<TournamentBalancesVarianceFields>;
};

/** aggregate fields of "tournament_balances" */
export type TournamentBalancesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<TournamentBalancesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "tournament_balances" */
export type TournamentBalancesAggregateOrderBy = {
  avg?: InputMaybe<Tournament_Balances_Avg_Order_By>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<Tournament_Balances_Max_Order_By>;
  min?: InputMaybe<Tournament_Balances_Min_Order_By>;
  stddev?: InputMaybe<Tournament_Balances_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Tournament_Balances_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Tournament_Balances_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Tournament_Balances_Sum_Order_By>;
  var_pop?: InputMaybe<Tournament_Balances_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Tournament_Balances_Var_Samp_Order_By>;
  variance?: InputMaybe<Tournament_Balances_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "tournament_balances" */
export type TournamentBalancesArrRelInsertInput = {
  data: Array<TournamentBalancesInsertInput>;
};

/** aggregate avg on columns */
export type TournamentBalancesAvgFields = {
  __typename?: 'TournamentBalancesAvgFields';
  balance?: Maybe<Scalars['Float']['output']>;
  currencyId?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "tournament_balances". All fields are combined with a logical 'AND'. */
export type TournamentBalancesBoolExp = {
  _and?: InputMaybe<Array<TournamentBalancesBoolExp>>;
  _not?: InputMaybe<TournamentBalancesBoolExp>;
  _or?: InputMaybe<Array<TournamentBalancesBoolExp>>;
  balance?: InputMaybe<NumericComparisonExp>;
  currency?: InputMaybe<CurrenciesBoolExp>;
  currencyId?: InputMaybe<IntComparisonExp>;
  eventId?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  status?: InputMaybe<StringComparisonExp>;
  tournament?: InputMaybe<TournamentsBoolExp>;
  tournamentId?: InputMaybe<UuidComparisonExp>;
  venueId?: InputMaybe<StringComparisonExp>;
};

/** input type for inserting data into table "tournament_balances" */
export type TournamentBalancesInsertInput = {
  balance?: InputMaybe<Scalars['numeric']['input']>;
  currency?: InputMaybe<CurrenciesObjRelInsertInput>;
  currencyId?: InputMaybe<Scalars['Int']['input']>;
  eventId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  tournament?: InputMaybe<TournamentsObjRelInsertInput>;
  tournamentId?: InputMaybe<Scalars['uuid']['input']>;
  venueId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type TournamentBalancesMaxFields = {
  __typename?: 'TournamentBalancesMaxFields';
  balance?: Maybe<Scalars['numeric']['output']>;
  currencyId?: Maybe<Scalars['Int']['output']>;
  eventId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  tournamentId?: Maybe<Scalars['uuid']['output']>;
  venueId?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type TournamentBalancesMinFields = {
  __typename?: 'TournamentBalancesMinFields';
  balance?: Maybe<Scalars['numeric']['output']>;
  currencyId?: Maybe<Scalars['Int']['output']>;
  eventId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  tournamentId?: Maybe<Scalars['uuid']['output']>;
  venueId?: Maybe<Scalars['String']['output']>;
};

/** Ordering options when selecting data from "tournament_balances". */
export type TournamentBalancesOrderBy = {
  balance?: InputMaybe<OrderBy>;
  currency?: InputMaybe<CurrenciesOrderBy>;
  currencyId?: InputMaybe<OrderBy>;
  eventId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  tournament?: InputMaybe<TournamentsOrderBy>;
  tournamentId?: InputMaybe<OrderBy>;
  venueId?: InputMaybe<OrderBy>;
};

/** select columns of table "tournament_balances" */
export enum TournamentBalancesSelectColumn {
  /** column name */
  Balance = 'balance',
  /** column name */
  CurrencyId = 'currencyId',
  /** column name */
  EventId = 'eventId',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  TournamentId = 'tournamentId',
  /** column name */
  VenueId = 'venueId',
}

/** aggregate stddev on columns */
export type TournamentBalancesStddevFields = {
  __typename?: 'TournamentBalancesStddevFields';
  balance?: Maybe<Scalars['Float']['output']>;
  currencyId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type TournamentBalancesStddev_PopFields = {
  __typename?: 'TournamentBalancesStddev_popFields';
  balance?: Maybe<Scalars['Float']['output']>;
  currencyId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type TournamentBalancesStddev_SampFields = {
  __typename?: 'TournamentBalancesStddev_sampFields';
  balance?: Maybe<Scalars['Float']['output']>;
  currencyId?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "tournament_balances" */
export type TournamentBalancesStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: TournamentBalancesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type TournamentBalancesStreamCursorValueInput = {
  balance?: InputMaybe<Scalars['numeric']['input']>;
  currencyId?: InputMaybe<Scalars['Int']['input']>;
  eventId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  tournamentId?: InputMaybe<Scalars['uuid']['input']>;
  venueId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type TournamentBalancesSumFields = {
  __typename?: 'TournamentBalancesSumFields';
  balance?: Maybe<Scalars['numeric']['output']>;
  currencyId?: Maybe<Scalars['Int']['output']>;
};

/** aggregate var_pop on columns */
export type TournamentBalancesVar_PopFields = {
  __typename?: 'TournamentBalancesVar_popFields';
  balance?: Maybe<Scalars['Float']['output']>;
  currencyId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type TournamentBalancesVar_SampFields = {
  __typename?: 'TournamentBalancesVar_sampFields';
  balance?: Maybe<Scalars['Float']['output']>;
  currencyId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type TournamentBalancesVarianceFields = {
  __typename?: 'TournamentBalancesVarianceFields';
  balance?: Maybe<Scalars['Float']['output']>;
  currencyId?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "tournament_result_statuses" */
export type TournamentResultStatuses = {
  __typename?: 'TournamentResultStatuses';
  description?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

/** aggregated selection of "tournament_result_statuses" */
export type TournamentResultStatusesAggregate = {
  __typename?: 'TournamentResultStatusesAggregate';
  aggregate?: Maybe<TournamentResultStatusesAggregateFields>;
  nodes: Array<TournamentResultStatuses>;
};

/** aggregate fields of "tournament_result_statuses" */
export type TournamentResultStatusesAggregateFields = {
  __typename?: 'TournamentResultStatusesAggregateFields';
  count: Scalars['Int']['output'];
  max?: Maybe<TournamentResultStatusesMaxFields>;
  min?: Maybe<TournamentResultStatusesMinFields>;
};

/** aggregate fields of "tournament_result_statuses" */
export type TournamentResultStatusesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<TournamentResultStatusesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "tournament_result_statuses". All fields are combined with a logical 'AND'. */
export type TournamentResultStatusesBoolExp = {
  _and?: InputMaybe<Array<TournamentResultStatusesBoolExp>>;
  _not?: InputMaybe<TournamentResultStatusesBoolExp>;
  _or?: InputMaybe<Array<TournamentResultStatusesBoolExp>>;
  description?: InputMaybe<StringComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "tournament_result_statuses" */
export enum TournamentResultStatusesConstraint {
  /** unique or primary key constraint on columns "name" */
  TournamentResultStatusesPkey = 'tournament_result_statuses_pkey',
}

export enum TournamentResultStatusesEnum {
  /** The user did not come */
  Cancelled = 'CANCELLED',
  /** The user paid for the buyin */
  Paid = 'PAID',
  /** The player is out without a winning */
  PlayerOut = 'PLAYER_OUT',
  /** The player won something */
  Won = 'WON',
}

/** Boolean expression to compare columns of type "TournamentResultStatusesEnum". All fields are combined with logical 'AND'. */
export type TournamentResultStatusesEnumComparisonExp = {
  _eq?: InputMaybe<TournamentResultStatusesEnum>;
  _in?: InputMaybe<Array<TournamentResultStatusesEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<TournamentResultStatusesEnum>;
  _nin?: InputMaybe<Array<TournamentResultStatusesEnum>>;
};

/** input type for inserting data into table "tournament_result_statuses" */
export type TournamentResultStatusesInsertInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type TournamentResultStatusesMaxFields = {
  __typename?: 'TournamentResultStatusesMaxFields';
  description?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type TournamentResultStatusesMinFields = {
  __typename?: 'TournamentResultStatusesMinFields';
  description?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "tournament_result_statuses" */
export type TournamentResultStatusesMutationResponse = {
  __typename?: 'TournamentResultStatusesMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<TournamentResultStatuses>;
};

/** on_conflict condition type for table "tournament_result_statuses" */
export type TournamentResultStatusesOnConflict = {
  constraint: TournamentResultStatusesConstraint;
  update_columns?: Array<TournamentResultStatusesUpdateColumn>;
  where?: InputMaybe<TournamentResultStatusesBoolExp>;
};

/** Ordering options when selecting data from "tournament_result_statuses". */
export type TournamentResultStatusesOrderBy = {
  description?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: tournament_result_statuses */
export type TournamentResultStatusesPkColumnsInput = {
  name: Scalars['String']['input'];
};

/** select columns of table "tournament_result_statuses" */
export enum TournamentResultStatusesSelectColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name',
}

/** input type for updating data in table "tournament_result_statuses" */
export type TournamentResultStatusesSetInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "tournament_result_statuses" */
export type TournamentResultStatusesStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: TournamentResultStatusesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type TournamentResultStatusesStreamCursorValueInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "tournament_result_statuses" */
export enum TournamentResultStatusesUpdateColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name',
}

export type TournamentResultStatusesUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<TournamentResultStatusesSetInput>;
  /** filter the rows which have to be updated */
  where: TournamentResultStatusesBoolExp;
};

/** columns and relationships of "tournament_results" */
export type TournamentResults = {
  __typename?: 'TournamentResults';
  bullets: Scalars['Int']['output'];
  createdAt: Scalars['timestamptz']['output'];
  id: Scalars['bigint']['output'];
  name: Scalars['String']['output'];
  place?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  player?: Maybe<Players>;
  playerId?: Maybe<Scalars['uuid']['output']>;
  playerPayout?: Maybe<Scalars['numeric']['output']>;
  /** An aggregate relationship */
  playerSharesAggregate: PlayerSharesAggregate;
  /** An array relationship */
  player_shares: Array<PlayerShares>;
  roi?: Maybe<Scalars['numeric']['output']>;
  stakedAmount: Scalars['numeric']['output'];
  stakedPercentage: Scalars['numeric']['output'];
  status: TournamentResultStatusesEnum;
  /** An array relationship */
  tickets: Array<Tickets>;
  /** An aggregate relationship */
  ticketsAggregate: TicketsAggregate;
  toBePaid?: Maybe<Scalars['jsonb']['output']>;
  /** An object relationship */
  tournament: Tournaments;
  tournamentId: Scalars['uuid']['output'];
  updatedAt: Scalars['timestamptz']['output'];
  /** An object relationship */
  user?: Maybe<Users>;
  userId?: Maybe<Scalars['String']['output']>;
  winning?: Maybe<Scalars['numeric']['output']>;
};

/** columns and relationships of "tournament_results" */
export type TournamentResultsPlayerSharesAggregateArgs = {
  distinctOn?: InputMaybe<Array<PlayerSharesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PlayerSharesOrderBy>>;
  where?: InputMaybe<PlayerSharesBoolExp>;
};

/** columns and relationships of "tournament_results" */
export type TournamentResultsPlayer_SharesArgs = {
  distinctOn?: InputMaybe<Array<PlayerSharesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PlayerSharesOrderBy>>;
  where?: InputMaybe<PlayerSharesBoolExp>;
};

/** columns and relationships of "tournament_results" */
export type TournamentResultsTicketsArgs = {
  distinctOn?: InputMaybe<Array<TicketsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TicketsOrderBy>>;
  where?: InputMaybe<TicketsBoolExp>;
};

/** columns and relationships of "tournament_results" */
export type TournamentResultsTicketsAggregateArgs = {
  distinctOn?: InputMaybe<Array<TicketsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TicketsOrderBy>>;
  where?: InputMaybe<TicketsBoolExp>;
};

/** columns and relationships of "tournament_results" */
export type TournamentResultsToBePaidArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "tournament_results" */
export type TournamentResultsAggregate = {
  __typename?: 'TournamentResultsAggregate';
  aggregate?: Maybe<TournamentResultsAggregateFields>;
  nodes: Array<TournamentResults>;
};

/** aggregate fields of "tournament_results" */
export type TournamentResultsAggregateFields = {
  __typename?: 'TournamentResultsAggregateFields';
  avg?: Maybe<TournamentResultsAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<TournamentResultsMaxFields>;
  min?: Maybe<TournamentResultsMinFields>;
  stddev?: Maybe<TournamentResultsStddevFields>;
  stddevPop?: Maybe<TournamentResultsStddev_PopFields>;
  stddevSamp?: Maybe<TournamentResultsStddev_SampFields>;
  sum?: Maybe<TournamentResultsSumFields>;
  varPop?: Maybe<TournamentResultsVar_PopFields>;
  varSamp?: Maybe<TournamentResultsVar_SampFields>;
  variance?: Maybe<TournamentResultsVarianceFields>;
};

/** aggregate fields of "tournament_results" */
export type TournamentResultsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<TournamentResultsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "tournament_results" */
export type TournamentResultsAggregateOrderBy = {
  avg?: InputMaybe<Tournament_Results_Avg_Order_By>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<Tournament_Results_Max_Order_By>;
  min?: InputMaybe<Tournament_Results_Min_Order_By>;
  stddev?: InputMaybe<Tournament_Results_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Tournament_Results_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Tournament_Results_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Tournament_Results_Sum_Order_By>;
  var_pop?: InputMaybe<Tournament_Results_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Tournament_Results_Var_Samp_Order_By>;
  variance?: InputMaybe<Tournament_Results_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type TournamentResultsAppendInput = {
  toBePaid?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "tournament_results" */
export type TournamentResultsArrRelInsertInput = {
  data: Array<TournamentResultsInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<TournamentResultsOnConflict>;
};

/** aggregate avg on columns */
export type TournamentResultsAvgFields = {
  __typename?: 'TournamentResultsAvgFields';
  bullets?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  place?: Maybe<Scalars['Float']['output']>;
  playerPayout?: Maybe<Scalars['Float']['output']>;
  roi?: Maybe<Scalars['Float']['output']>;
  stakedAmount?: Maybe<Scalars['Float']['output']>;
  stakedPercentage?: Maybe<Scalars['Float']['output']>;
  winning?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "tournament_results". All fields are combined with a logical 'AND'. */
export type TournamentResultsBoolExp = {
  _and?: InputMaybe<Array<TournamentResultsBoolExp>>;
  _not?: InputMaybe<TournamentResultsBoolExp>;
  _or?: InputMaybe<Array<TournamentResultsBoolExp>>;
  bullets?: InputMaybe<IntComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  place?: InputMaybe<IntComparisonExp>;
  player?: InputMaybe<PlayersBoolExp>;
  playerId?: InputMaybe<UuidComparisonExp>;
  playerPayout?: InputMaybe<NumericComparisonExp>;
  player_shares?: InputMaybe<PlayerSharesBoolExp>;
  player_shares_aggregate?: InputMaybe<Player_Shares_Aggregate_Bool_Exp>;
  roi?: InputMaybe<NumericComparisonExp>;
  stakedAmount?: InputMaybe<NumericComparisonExp>;
  stakedPercentage?: InputMaybe<NumericComparisonExp>;
  status?: InputMaybe<TournamentResultStatusesEnumComparisonExp>;
  tickets?: InputMaybe<TicketsBoolExp>;
  tickets_aggregate?: InputMaybe<Tickets_Aggregate_Bool_Exp>;
  toBePaid?: InputMaybe<JsonbComparisonExp>;
  tournament?: InputMaybe<TournamentsBoolExp>;
  tournamentId?: InputMaybe<UuidComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  user?: InputMaybe<UsersBoolExp>;
  userId?: InputMaybe<StringComparisonExp>;
  winning?: InputMaybe<NumericComparisonExp>;
};

/** unique or primary key constraints on table "tournament_results" */
export enum TournamentResultsConstraint {
  /** unique or primary key constraint on columns "id" */
  TournamentResultPkey = 'tournament_result_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type TournamentResultsDeleteAtPathInput = {
  toBePaid?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type TournamentResultsDeleteElemInput = {
  toBePaid?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type TournamentResultsDeleteKeyInput = {
  toBePaid?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "tournament_results" */
export type TournamentResultsIncInput = {
  bullets?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  place?: InputMaybe<Scalars['Int']['input']>;
  playerPayout?: InputMaybe<Scalars['numeric']['input']>;
  roi?: InputMaybe<Scalars['numeric']['input']>;
  stakedAmount?: InputMaybe<Scalars['numeric']['input']>;
  stakedPercentage?: InputMaybe<Scalars['numeric']['input']>;
  winning?: InputMaybe<Scalars['numeric']['input']>;
};

/** input type for inserting data into table "tournament_results" */
export type TournamentResultsInsertInput = {
  bullets?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  place?: InputMaybe<Scalars['Int']['input']>;
  player?: InputMaybe<PlayersObjRelInsertInput>;
  playerId?: InputMaybe<Scalars['uuid']['input']>;
  playerPayout?: InputMaybe<Scalars['numeric']['input']>;
  player_shares?: InputMaybe<PlayerSharesArrRelInsertInput>;
  roi?: InputMaybe<Scalars['numeric']['input']>;
  stakedAmount?: InputMaybe<Scalars['numeric']['input']>;
  stakedPercentage?: InputMaybe<Scalars['numeric']['input']>;
  status?: InputMaybe<TournamentResultStatusesEnum>;
  tickets?: InputMaybe<TicketsArrRelInsertInput>;
  toBePaid?: InputMaybe<Scalars['jsonb']['input']>;
  tournament?: InputMaybe<TournamentsObjRelInsertInput>;
  tournamentId?: InputMaybe<Scalars['uuid']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<UsersObjRelInsertInput>;
  userId?: InputMaybe<Scalars['String']['input']>;
  winning?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate max on columns */
export type TournamentResultsMaxFields = {
  __typename?: 'TournamentResultsMaxFields';
  bullets?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  place?: Maybe<Scalars['Int']['output']>;
  playerId?: Maybe<Scalars['uuid']['output']>;
  playerPayout?: Maybe<Scalars['numeric']['output']>;
  roi?: Maybe<Scalars['numeric']['output']>;
  stakedAmount?: Maybe<Scalars['numeric']['output']>;
  stakedPercentage?: Maybe<Scalars['numeric']['output']>;
  tournamentId?: Maybe<Scalars['uuid']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
  winning?: Maybe<Scalars['numeric']['output']>;
};

/** aggregate min on columns */
export type TournamentResultsMinFields = {
  __typename?: 'TournamentResultsMinFields';
  bullets?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  place?: Maybe<Scalars['Int']['output']>;
  playerId?: Maybe<Scalars['uuid']['output']>;
  playerPayout?: Maybe<Scalars['numeric']['output']>;
  roi?: Maybe<Scalars['numeric']['output']>;
  stakedAmount?: Maybe<Scalars['numeric']['output']>;
  stakedPercentage?: Maybe<Scalars['numeric']['output']>;
  tournamentId?: Maybe<Scalars['uuid']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
  winning?: Maybe<Scalars['numeric']['output']>;
};

/** response of any mutation on the table "tournament_results" */
export type TournamentResultsMutationResponse = {
  __typename?: 'TournamentResultsMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<TournamentResults>;
};

/** input type for inserting object relation for remote table "tournament_results" */
export type TournamentResultsObjRelInsertInput = {
  data: TournamentResultsInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<TournamentResultsOnConflict>;
};

/** on_conflict condition type for table "tournament_results" */
export type TournamentResultsOnConflict = {
  constraint: TournamentResultsConstraint;
  update_columns?: Array<TournamentResultsUpdateColumn>;
  where?: InputMaybe<TournamentResultsBoolExp>;
};

/** Ordering options when selecting data from "tournament_results". */
export type TournamentResultsOrderBy = {
  bullets?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  place?: InputMaybe<OrderBy>;
  player?: InputMaybe<PlayersOrderBy>;
  playerId?: InputMaybe<OrderBy>;
  playerPayout?: InputMaybe<OrderBy>;
  player_sharesAggregate?: InputMaybe<PlayerSharesAggregateOrderBy>;
  roi?: InputMaybe<OrderBy>;
  stakedAmount?: InputMaybe<OrderBy>;
  stakedPercentage?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  ticketsAggregate?: InputMaybe<TicketsAggregateOrderBy>;
  toBePaid?: InputMaybe<OrderBy>;
  tournament?: InputMaybe<TournamentsOrderBy>;
  tournamentId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  user?: InputMaybe<UsersOrderBy>;
  userId?: InputMaybe<OrderBy>;
  winning?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: tournament_results */
export type TournamentResultsPkColumnsInput = {
  id: Scalars['bigint']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type TournamentResultsPrependInput = {
  toBePaid?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "tournament_results" */
export enum TournamentResultsSelectColumn {
  /** column name */
  Bullets = 'bullets',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Place = 'place',
  /** column name */
  PlayerId = 'playerId',
  /** column name */
  PlayerPayout = 'playerPayout',
  /** column name */
  Roi = 'roi',
  /** column name */
  StakedAmount = 'stakedAmount',
  /** column name */
  StakedPercentage = 'stakedPercentage',
  /** column name */
  Status = 'status',
  /** column name */
  ToBePaid = 'toBePaid',
  /** column name */
  TournamentId = 'tournamentId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId',
  /** column name */
  Winning = 'winning',
}

/** input type for updating data in table "tournament_results" */
export type TournamentResultsSetInput = {
  bullets?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  place?: InputMaybe<Scalars['Int']['input']>;
  playerId?: InputMaybe<Scalars['uuid']['input']>;
  playerPayout?: InputMaybe<Scalars['numeric']['input']>;
  roi?: InputMaybe<Scalars['numeric']['input']>;
  stakedAmount?: InputMaybe<Scalars['numeric']['input']>;
  stakedPercentage?: InputMaybe<Scalars['numeric']['input']>;
  status?: InputMaybe<TournamentResultStatusesEnum>;
  toBePaid?: InputMaybe<Scalars['jsonb']['input']>;
  tournamentId?: InputMaybe<Scalars['uuid']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
  winning?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate stddev on columns */
export type TournamentResultsStddevFields = {
  __typename?: 'TournamentResultsStddevFields';
  bullets?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  place?: Maybe<Scalars['Float']['output']>;
  playerPayout?: Maybe<Scalars['Float']['output']>;
  roi?: Maybe<Scalars['Float']['output']>;
  stakedAmount?: Maybe<Scalars['Float']['output']>;
  stakedPercentage?: Maybe<Scalars['Float']['output']>;
  winning?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type TournamentResultsStddev_PopFields = {
  __typename?: 'TournamentResultsStddev_popFields';
  bullets?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  place?: Maybe<Scalars['Float']['output']>;
  playerPayout?: Maybe<Scalars['Float']['output']>;
  roi?: Maybe<Scalars['Float']['output']>;
  stakedAmount?: Maybe<Scalars['Float']['output']>;
  stakedPercentage?: Maybe<Scalars['Float']['output']>;
  winning?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type TournamentResultsStddev_SampFields = {
  __typename?: 'TournamentResultsStddev_sampFields';
  bullets?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  place?: Maybe<Scalars['Float']['output']>;
  playerPayout?: Maybe<Scalars['Float']['output']>;
  roi?: Maybe<Scalars['Float']['output']>;
  stakedAmount?: Maybe<Scalars['Float']['output']>;
  stakedPercentage?: Maybe<Scalars['Float']['output']>;
  winning?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "tournament_results" */
export type TournamentResultsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: TournamentResultsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type TournamentResultsStreamCursorValueInput = {
  bullets?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  place?: InputMaybe<Scalars['Int']['input']>;
  playerId?: InputMaybe<Scalars['uuid']['input']>;
  playerPayout?: InputMaybe<Scalars['numeric']['input']>;
  roi?: InputMaybe<Scalars['numeric']['input']>;
  stakedAmount?: InputMaybe<Scalars['numeric']['input']>;
  stakedPercentage?: InputMaybe<Scalars['numeric']['input']>;
  status?: InputMaybe<TournamentResultStatusesEnum>;
  toBePaid?: InputMaybe<Scalars['jsonb']['input']>;
  tournamentId?: InputMaybe<Scalars['uuid']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
  winning?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate sum on columns */
export type TournamentResultsSumFields = {
  __typename?: 'TournamentResultsSumFields';
  bullets?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  place?: Maybe<Scalars['Int']['output']>;
  playerPayout?: Maybe<Scalars['numeric']['output']>;
  roi?: Maybe<Scalars['numeric']['output']>;
  stakedAmount?: Maybe<Scalars['numeric']['output']>;
  stakedPercentage?: Maybe<Scalars['numeric']['output']>;
  winning?: Maybe<Scalars['numeric']['output']>;
};

/** update columns of table "tournament_results" */
export enum TournamentResultsUpdateColumn {
  /** column name */
  Bullets = 'bullets',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Place = 'place',
  /** column name */
  PlayerId = 'playerId',
  /** column name */
  PlayerPayout = 'playerPayout',
  /** column name */
  Roi = 'roi',
  /** column name */
  StakedAmount = 'stakedAmount',
  /** column name */
  StakedPercentage = 'stakedPercentage',
  /** column name */
  Status = 'status',
  /** column name */
  ToBePaid = 'toBePaid',
  /** column name */
  TournamentId = 'tournamentId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId',
  /** column name */
  Winning = 'winning',
}

export type TournamentResultsUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<TournamentResultsAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _deleteAtPath?: InputMaybe<TournamentResultsDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _deleteElem?: InputMaybe<TournamentResultsDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _deleteKey?: InputMaybe<TournamentResultsDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<TournamentResultsIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<TournamentResultsPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<TournamentResultsSetInput>;
  /** filter the rows which have to be updated */
  where: TournamentResultsBoolExp;
};

/** aggregate var_pop on columns */
export type TournamentResultsVar_PopFields = {
  __typename?: 'TournamentResultsVar_popFields';
  bullets?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  place?: Maybe<Scalars['Float']['output']>;
  playerPayout?: Maybe<Scalars['Float']['output']>;
  roi?: Maybe<Scalars['Float']['output']>;
  stakedAmount?: Maybe<Scalars['Float']['output']>;
  stakedPercentage?: Maybe<Scalars['Float']['output']>;
  winning?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type TournamentResultsVar_SampFields = {
  __typename?: 'TournamentResultsVar_sampFields';
  bullets?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  place?: Maybe<Scalars['Float']['output']>;
  playerPayout?: Maybe<Scalars['Float']['output']>;
  roi?: Maybe<Scalars['Float']['output']>;
  stakedAmount?: Maybe<Scalars['Float']['output']>;
  stakedPercentage?: Maybe<Scalars['Float']['output']>;
  winning?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type TournamentResultsVarianceFields = {
  __typename?: 'TournamentResultsVarianceFields';
  bullets?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  place?: Maybe<Scalars['Float']['output']>;
  playerPayout?: Maybe<Scalars['Float']['output']>;
  roi?: Maybe<Scalars['Float']['output']>;
  stakedAmount?: Maybe<Scalars['Float']['output']>;
  stakedPercentage?: Maybe<Scalars['Float']['output']>;
  winning?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "tournaments" */
export type Tournaments = {
  __typename?: 'Tournaments';
  /** An array relationship */
  balances: Array<TournamentBalances>;
  /** An aggregate relationship */
  balancesAggregate: TournamentBalancesAggregate;
  bulletsMax: Scalars['Int']['output'];
  buyIn: Scalars['numeric']['output'];
  createdAt: Scalars['timestamptz']['output'];
  /** An object relationship */
  currency: Currencies;
  currencyId: Scalars['Int']['output'];
  currencyRate: Scalars['numeric']['output'];
  date: Scalars['timestamptz']['output'];
  /** An object relationship */
  event?: Maybe<Events>;
  eventId?: Maybe<Scalars['uuid']['output']>;
  /** An array relationship */
  followers: Array<Followers>;
  /** An aggregate relationship */
  followersAggregate: FollowersAggregate;
  guaranteed: Scalars['numeric']['output'];
  id: Scalars['uuid']['output'];
  image?: Maybe<Scalars['String']['output']>;
  localCurrencyId: Scalars['Int']['output'];
  /** An object relationship */
  local_currency: Currencies;
  location?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  /** An aggregate relationship */
  playerSharesAggregate: PlayerSharesAggregate;
  /** An array relationship */
  player_shares: Array<PlayerShares>;
  registrationEnd?: Maybe<Scalars['timestamptz']['output']>;
  sharesMax: Scalars['Int']['output'];
  slug?: Maybe<Scalars['String']['output']>;
  sort: Scalars['Int']['output'];
  status: StatusGamesEnum;
  ticketSalesActive: Scalars['Boolean']['output'];
  /** An array relationship */
  tickets: Array<Tickets>;
  /** An aggregate relationship */
  ticketsAggregate: TicketsAggregate;
  /** An aggregate relationship */
  tournamentResultsAggregate: TournamentResultsAggregate;
  /** An array relationship */
  tournament_results: Array<TournamentResults>;
  /** An array relationship */
  transactions: Array<Transactions>;
  /** An aggregate relationship */
  transactionsAggregate: TransactionsAggregate;
  type: Scalars['String']['output'];
  updatedAt: Scalars['timestamptz']['output'];
  /** An object relationship */
  venue: Venues;
  venueId: Scalars['uuid']['output'];
  watchUrl?: Maybe<Scalars['String']['output']>;
};

/** columns and relationships of "tournaments" */
export type TournamentsBalancesArgs = {
  distinctOn?: InputMaybe<Array<TournamentBalancesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TournamentBalancesOrderBy>>;
  where?: InputMaybe<TournamentBalancesBoolExp>;
};

/** columns and relationships of "tournaments" */
export type TournamentsBalancesAggregateArgs = {
  distinctOn?: InputMaybe<Array<TournamentBalancesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TournamentBalancesOrderBy>>;
  where?: InputMaybe<TournamentBalancesBoolExp>;
};

/** columns and relationships of "tournaments" */
export type TournamentsFollowersArgs = {
  distinctOn?: InputMaybe<Array<FollowersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FollowersOrderBy>>;
  where?: InputMaybe<FollowersBoolExp>;
};

/** columns and relationships of "tournaments" */
export type TournamentsFollowersAggregateArgs = {
  distinctOn?: InputMaybe<Array<FollowersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FollowersOrderBy>>;
  where?: InputMaybe<FollowersBoolExp>;
};

/** columns and relationships of "tournaments" */
export type TournamentsPlayerSharesAggregateArgs = {
  distinctOn?: InputMaybe<Array<PlayerSharesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PlayerSharesOrderBy>>;
  where?: InputMaybe<PlayerSharesBoolExp>;
};

/** columns and relationships of "tournaments" */
export type TournamentsPlayer_SharesArgs = {
  distinctOn?: InputMaybe<Array<PlayerSharesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PlayerSharesOrderBy>>;
  where?: InputMaybe<PlayerSharesBoolExp>;
};

/** columns and relationships of "tournaments" */
export type TournamentsTicketsArgs = {
  distinctOn?: InputMaybe<Array<TicketsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TicketsOrderBy>>;
  where?: InputMaybe<TicketsBoolExp>;
};

/** columns and relationships of "tournaments" */
export type TournamentsTicketsAggregateArgs = {
  distinctOn?: InputMaybe<Array<TicketsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TicketsOrderBy>>;
  where?: InputMaybe<TicketsBoolExp>;
};

/** columns and relationships of "tournaments" */
export type TournamentsTournamentResultsAggregateArgs = {
  distinctOn?: InputMaybe<Array<TournamentResultsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TournamentResultsOrderBy>>;
  where?: InputMaybe<TournamentResultsBoolExp>;
};

/** columns and relationships of "tournaments" */
export type TournamentsTournament_ResultsArgs = {
  distinctOn?: InputMaybe<Array<TournamentResultsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TournamentResultsOrderBy>>;
  where?: InputMaybe<TournamentResultsBoolExp>;
};

/** columns and relationships of "tournaments" */
export type TournamentsTransactionsArgs = {
  distinctOn?: InputMaybe<Array<TransactionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TransactionsOrderBy>>;
  where?: InputMaybe<TransactionsBoolExp>;
};

/** columns and relationships of "tournaments" */
export type TournamentsTransactionsAggregateArgs = {
  distinctOn?: InputMaybe<Array<TransactionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TransactionsOrderBy>>;
  where?: InputMaybe<TransactionsBoolExp>;
};

/** aggregated selection of "tournaments" */
export type TournamentsAggregate = {
  __typename?: 'TournamentsAggregate';
  aggregate?: Maybe<TournamentsAggregateFields>;
  nodes: Array<Tournaments>;
};

/** aggregate fields of "tournaments" */
export type TournamentsAggregateFields = {
  __typename?: 'TournamentsAggregateFields';
  avg?: Maybe<TournamentsAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<TournamentsMaxFields>;
  min?: Maybe<TournamentsMinFields>;
  stddev?: Maybe<TournamentsStddevFields>;
  stddevPop?: Maybe<TournamentsStddev_PopFields>;
  stddevSamp?: Maybe<TournamentsStddev_SampFields>;
  sum?: Maybe<TournamentsSumFields>;
  varPop?: Maybe<TournamentsVar_PopFields>;
  varSamp?: Maybe<TournamentsVar_SampFields>;
  variance?: Maybe<TournamentsVarianceFields>;
};

/** aggregate fields of "tournaments" */
export type TournamentsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<TournamentsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "tournaments" */
export type TournamentsAggregateOrderBy = {
  avg?: InputMaybe<Tournaments_Avg_Order_By>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<Tournaments_Max_Order_By>;
  min?: InputMaybe<Tournaments_Min_Order_By>;
  stddev?: InputMaybe<Tournaments_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Tournaments_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Tournaments_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Tournaments_Sum_Order_By>;
  var_pop?: InputMaybe<Tournaments_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Tournaments_Var_Samp_Order_By>;
  variance?: InputMaybe<Tournaments_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "tournaments" */
export type TournamentsArrRelInsertInput = {
  data: Array<TournamentsInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<TournamentsOnConflict>;
};

/** aggregate avg on columns */
export type TournamentsAvgFields = {
  __typename?: 'TournamentsAvgFields';
  bulletsMax?: Maybe<Scalars['Float']['output']>;
  buyIn?: Maybe<Scalars['Float']['output']>;
  currencyId?: Maybe<Scalars['Float']['output']>;
  currencyRate?: Maybe<Scalars['Float']['output']>;
  guaranteed?: Maybe<Scalars['Float']['output']>;
  localCurrencyId?: Maybe<Scalars['Float']['output']>;
  sharesMax?: Maybe<Scalars['Float']['output']>;
  sort?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "tournaments". All fields are combined with a logical 'AND'. */
export type TournamentsBoolExp = {
  _and?: InputMaybe<Array<TournamentsBoolExp>>;
  _not?: InputMaybe<TournamentsBoolExp>;
  _or?: InputMaybe<Array<TournamentsBoolExp>>;
  balances?: InputMaybe<TournamentBalancesBoolExp>;
  balances_aggregate?: InputMaybe<Tournament_Balances_Aggregate_Bool_Exp>;
  bulletsMax?: InputMaybe<IntComparisonExp>;
  buyIn?: InputMaybe<NumericComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  currency?: InputMaybe<CurrenciesBoolExp>;
  currencyId?: InputMaybe<IntComparisonExp>;
  currencyRate?: InputMaybe<NumericComparisonExp>;
  date?: InputMaybe<TimestamptzComparisonExp>;
  event?: InputMaybe<EventsBoolExp>;
  eventId?: InputMaybe<UuidComparisonExp>;
  followers?: InputMaybe<FollowersBoolExp>;
  followers_aggregate?: InputMaybe<Followers_Aggregate_Bool_Exp>;
  guaranteed?: InputMaybe<NumericComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  image?: InputMaybe<StringComparisonExp>;
  localCurrencyId?: InputMaybe<IntComparisonExp>;
  local_currency?: InputMaybe<CurrenciesBoolExp>;
  location?: InputMaybe<StringComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  player_shares?: InputMaybe<PlayerSharesBoolExp>;
  player_shares_aggregate?: InputMaybe<Player_Shares_Aggregate_Bool_Exp>;
  registrationEnd?: InputMaybe<TimestamptzComparisonExp>;
  sharesMax?: InputMaybe<IntComparisonExp>;
  slug?: InputMaybe<StringComparisonExp>;
  sort?: InputMaybe<IntComparisonExp>;
  status?: InputMaybe<StatusGamesEnumComparisonExp>;
  ticketSalesActive?: InputMaybe<BooleanComparisonExp>;
  tickets?: InputMaybe<TicketsBoolExp>;
  tickets_aggregate?: InputMaybe<Tickets_Aggregate_Bool_Exp>;
  tournament_results?: InputMaybe<TournamentResultsBoolExp>;
  tournament_results_aggregate?: InputMaybe<Tournament_Results_Aggregate_Bool_Exp>;
  transactions?: InputMaybe<TransactionsBoolExp>;
  transactions_aggregate?: InputMaybe<Transactions_Aggregate_Bool_Exp>;
  type?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  venue?: InputMaybe<VenuesBoolExp>;
  venueId?: InputMaybe<UuidComparisonExp>;
  watchUrl?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "tournaments" */
export enum TournamentsConstraint {
  /** unique or primary key constraint on columns "id" */
  TournamentPkey = 'tournament_pkey',
}

/** input type for incrementing numeric columns in table "tournaments" */
export type TournamentsIncInput = {
  bulletsMax?: InputMaybe<Scalars['Int']['input']>;
  buyIn?: InputMaybe<Scalars['numeric']['input']>;
  currencyId?: InputMaybe<Scalars['Int']['input']>;
  currencyRate?: InputMaybe<Scalars['numeric']['input']>;
  guaranteed?: InputMaybe<Scalars['numeric']['input']>;
  localCurrencyId?: InputMaybe<Scalars['Int']['input']>;
  sharesMax?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "tournaments" */
export type TournamentsInsertInput = {
  balances?: InputMaybe<TournamentBalancesArrRelInsertInput>;
  bulletsMax?: InputMaybe<Scalars['Int']['input']>;
  buyIn?: InputMaybe<Scalars['numeric']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  currency?: InputMaybe<CurrenciesObjRelInsertInput>;
  currencyId?: InputMaybe<Scalars['Int']['input']>;
  currencyRate?: InputMaybe<Scalars['numeric']['input']>;
  date?: InputMaybe<Scalars['timestamptz']['input']>;
  event?: InputMaybe<EventsObjRelInsertInput>;
  eventId?: InputMaybe<Scalars['uuid']['input']>;
  followers?: InputMaybe<FollowersArrRelInsertInput>;
  guaranteed?: InputMaybe<Scalars['numeric']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  localCurrencyId?: InputMaybe<Scalars['Int']['input']>;
  local_currency?: InputMaybe<CurrenciesObjRelInsertInput>;
  location?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  player_shares?: InputMaybe<PlayerSharesArrRelInsertInput>;
  registrationEnd?: InputMaybe<Scalars['timestamptz']['input']>;
  sharesMax?: InputMaybe<Scalars['Int']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<StatusGamesEnum>;
  ticketSalesActive?: InputMaybe<Scalars['Boolean']['input']>;
  tickets?: InputMaybe<TicketsArrRelInsertInput>;
  tournament_results?: InputMaybe<TournamentResultsArrRelInsertInput>;
  transactions?: InputMaybe<TransactionsArrRelInsertInput>;
  type?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  venue?: InputMaybe<VenuesObjRelInsertInput>;
  venueId?: InputMaybe<Scalars['uuid']['input']>;
  watchUrl?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type TournamentsMaxFields = {
  __typename?: 'TournamentsMaxFields';
  bulletsMax?: Maybe<Scalars['Int']['output']>;
  buyIn?: Maybe<Scalars['numeric']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  currencyId?: Maybe<Scalars['Int']['output']>;
  currencyRate?: Maybe<Scalars['numeric']['output']>;
  date?: Maybe<Scalars['timestamptz']['output']>;
  eventId?: Maybe<Scalars['uuid']['output']>;
  guaranteed?: Maybe<Scalars['numeric']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  image?: Maybe<Scalars['String']['output']>;
  localCurrencyId?: Maybe<Scalars['Int']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  registrationEnd?: Maybe<Scalars['timestamptz']['output']>;
  sharesMax?: Maybe<Scalars['Int']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  sort?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  venueId?: Maybe<Scalars['uuid']['output']>;
  watchUrl?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type TournamentsMinFields = {
  __typename?: 'TournamentsMinFields';
  bulletsMax?: Maybe<Scalars['Int']['output']>;
  buyIn?: Maybe<Scalars['numeric']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  currencyId?: Maybe<Scalars['Int']['output']>;
  currencyRate?: Maybe<Scalars['numeric']['output']>;
  date?: Maybe<Scalars['timestamptz']['output']>;
  eventId?: Maybe<Scalars['uuid']['output']>;
  guaranteed?: Maybe<Scalars['numeric']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  image?: Maybe<Scalars['String']['output']>;
  localCurrencyId?: Maybe<Scalars['Int']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  registrationEnd?: Maybe<Scalars['timestamptz']['output']>;
  sharesMax?: Maybe<Scalars['Int']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  sort?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  venueId?: Maybe<Scalars['uuid']['output']>;
  watchUrl?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "tournaments" */
export type TournamentsMutationResponse = {
  __typename?: 'TournamentsMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Tournaments>;
};

/** input type for inserting object relation for remote table "tournaments" */
export type TournamentsObjRelInsertInput = {
  data: TournamentsInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<TournamentsOnConflict>;
};

/** on_conflict condition type for table "tournaments" */
export type TournamentsOnConflict = {
  constraint: TournamentsConstraint;
  update_columns?: Array<TournamentsUpdateColumn>;
  where?: InputMaybe<TournamentsBoolExp>;
};

/** Ordering options when selecting data from "tournaments". */
export type TournamentsOrderBy = {
  balancesAggregate?: InputMaybe<TournamentBalancesAggregateOrderBy>;
  bulletsMax?: InputMaybe<OrderBy>;
  buyIn?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  currency?: InputMaybe<CurrenciesOrderBy>;
  currencyId?: InputMaybe<OrderBy>;
  currencyRate?: InputMaybe<OrderBy>;
  date?: InputMaybe<OrderBy>;
  event?: InputMaybe<EventsOrderBy>;
  eventId?: InputMaybe<OrderBy>;
  followersAggregate?: InputMaybe<FollowersAggregateOrderBy>;
  guaranteed?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  image?: InputMaybe<OrderBy>;
  localCurrencyId?: InputMaybe<OrderBy>;
  local_currency?: InputMaybe<CurrenciesOrderBy>;
  location?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  player_sharesAggregate?: InputMaybe<PlayerSharesAggregateOrderBy>;
  registrationEnd?: InputMaybe<OrderBy>;
  sharesMax?: InputMaybe<OrderBy>;
  slug?: InputMaybe<OrderBy>;
  sort?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  ticketSalesActive?: InputMaybe<OrderBy>;
  ticketsAggregate?: InputMaybe<TicketsAggregateOrderBy>;
  tournament_resultsAggregate?: InputMaybe<TournamentResultsAggregateOrderBy>;
  transactionsAggregate?: InputMaybe<TransactionsAggregateOrderBy>;
  type?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  venue?: InputMaybe<VenuesOrderBy>;
  venueId?: InputMaybe<OrderBy>;
  watchUrl?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: tournaments */
export type TournamentsPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "tournaments" */
export enum TournamentsSelectColumn {
  /** column name */
  BulletsMax = 'bulletsMax',
  /** column name */
  BuyIn = 'buyIn',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CurrencyId = 'currencyId',
  /** column name */
  CurrencyRate = 'currencyRate',
  /** column name */
  Date = 'date',
  /** column name */
  EventId = 'eventId',
  /** column name */
  Guaranteed = 'guaranteed',
  /** column name */
  Id = 'id',
  /** column name */
  Image = 'image',
  /** column name */
  LocalCurrencyId = 'localCurrencyId',
  /** column name */
  Location = 'location',
  /** column name */
  Name = 'name',
  /** column name */
  RegistrationEnd = 'registrationEnd',
  /** column name */
  SharesMax = 'sharesMax',
  /** column name */
  Slug = 'slug',
  /** column name */
  Sort = 'sort',
  /** column name */
  Status = 'status',
  /** column name */
  TicketSalesActive = 'ticketSalesActive',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  VenueId = 'venueId',
  /** column name */
  WatchUrl = 'watchUrl',
}

/** input type for updating data in table "tournaments" */
export type TournamentsSetInput = {
  bulletsMax?: InputMaybe<Scalars['Int']['input']>;
  buyIn?: InputMaybe<Scalars['numeric']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  currencyId?: InputMaybe<Scalars['Int']['input']>;
  currencyRate?: InputMaybe<Scalars['numeric']['input']>;
  date?: InputMaybe<Scalars['timestamptz']['input']>;
  eventId?: InputMaybe<Scalars['uuid']['input']>;
  guaranteed?: InputMaybe<Scalars['numeric']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  localCurrencyId?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  registrationEnd?: InputMaybe<Scalars['timestamptz']['input']>;
  sharesMax?: InputMaybe<Scalars['Int']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<StatusGamesEnum>;
  ticketSalesActive?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  venueId?: InputMaybe<Scalars['uuid']['input']>;
  watchUrl?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type TournamentsStddevFields = {
  __typename?: 'TournamentsStddevFields';
  bulletsMax?: Maybe<Scalars['Float']['output']>;
  buyIn?: Maybe<Scalars['Float']['output']>;
  currencyId?: Maybe<Scalars['Float']['output']>;
  currencyRate?: Maybe<Scalars['Float']['output']>;
  guaranteed?: Maybe<Scalars['Float']['output']>;
  localCurrencyId?: Maybe<Scalars['Float']['output']>;
  sharesMax?: Maybe<Scalars['Float']['output']>;
  sort?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type TournamentsStddev_PopFields = {
  __typename?: 'TournamentsStddev_popFields';
  bulletsMax?: Maybe<Scalars['Float']['output']>;
  buyIn?: Maybe<Scalars['Float']['output']>;
  currencyId?: Maybe<Scalars['Float']['output']>;
  currencyRate?: Maybe<Scalars['Float']['output']>;
  guaranteed?: Maybe<Scalars['Float']['output']>;
  localCurrencyId?: Maybe<Scalars['Float']['output']>;
  sharesMax?: Maybe<Scalars['Float']['output']>;
  sort?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type TournamentsStddev_SampFields = {
  __typename?: 'TournamentsStddev_sampFields';
  bulletsMax?: Maybe<Scalars['Float']['output']>;
  buyIn?: Maybe<Scalars['Float']['output']>;
  currencyId?: Maybe<Scalars['Float']['output']>;
  currencyRate?: Maybe<Scalars['Float']['output']>;
  guaranteed?: Maybe<Scalars['Float']['output']>;
  localCurrencyId?: Maybe<Scalars['Float']['output']>;
  sharesMax?: Maybe<Scalars['Float']['output']>;
  sort?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "tournaments" */
export type TournamentsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: TournamentsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type TournamentsStreamCursorValueInput = {
  bulletsMax?: InputMaybe<Scalars['Int']['input']>;
  buyIn?: InputMaybe<Scalars['numeric']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  currencyId?: InputMaybe<Scalars['Int']['input']>;
  currencyRate?: InputMaybe<Scalars['numeric']['input']>;
  date?: InputMaybe<Scalars['timestamptz']['input']>;
  eventId?: InputMaybe<Scalars['uuid']['input']>;
  guaranteed?: InputMaybe<Scalars['numeric']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  localCurrencyId?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  registrationEnd?: InputMaybe<Scalars['timestamptz']['input']>;
  sharesMax?: InputMaybe<Scalars['Int']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<StatusGamesEnum>;
  ticketSalesActive?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  venueId?: InputMaybe<Scalars['uuid']['input']>;
  watchUrl?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type TournamentsSumFields = {
  __typename?: 'TournamentsSumFields';
  bulletsMax?: Maybe<Scalars['Int']['output']>;
  buyIn?: Maybe<Scalars['numeric']['output']>;
  currencyId?: Maybe<Scalars['Int']['output']>;
  currencyRate?: Maybe<Scalars['numeric']['output']>;
  guaranteed?: Maybe<Scalars['numeric']['output']>;
  localCurrencyId?: Maybe<Scalars['Int']['output']>;
  sharesMax?: Maybe<Scalars['Int']['output']>;
  sort?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "tournaments" */
export enum TournamentsUpdateColumn {
  /** column name */
  BulletsMax = 'bulletsMax',
  /** column name */
  BuyIn = 'buyIn',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CurrencyId = 'currencyId',
  /** column name */
  CurrencyRate = 'currencyRate',
  /** column name */
  Date = 'date',
  /** column name */
  EventId = 'eventId',
  /** column name */
  Guaranteed = 'guaranteed',
  /** column name */
  Id = 'id',
  /** column name */
  Image = 'image',
  /** column name */
  LocalCurrencyId = 'localCurrencyId',
  /** column name */
  Location = 'location',
  /** column name */
  Name = 'name',
  /** column name */
  RegistrationEnd = 'registrationEnd',
  /** column name */
  SharesMax = 'sharesMax',
  /** column name */
  Slug = 'slug',
  /** column name */
  Sort = 'sort',
  /** column name */
  Status = 'status',
  /** column name */
  TicketSalesActive = 'ticketSalesActive',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  VenueId = 'venueId',
  /** column name */
  WatchUrl = 'watchUrl',
}

export type TournamentsUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<TournamentsIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<TournamentsSetInput>;
  /** filter the rows which have to be updated */
  where: TournamentsBoolExp;
};

/** aggregate var_pop on columns */
export type TournamentsVar_PopFields = {
  __typename?: 'TournamentsVar_popFields';
  bulletsMax?: Maybe<Scalars['Float']['output']>;
  buyIn?: Maybe<Scalars['Float']['output']>;
  currencyId?: Maybe<Scalars['Float']['output']>;
  currencyRate?: Maybe<Scalars['Float']['output']>;
  guaranteed?: Maybe<Scalars['Float']['output']>;
  localCurrencyId?: Maybe<Scalars['Float']['output']>;
  sharesMax?: Maybe<Scalars['Float']['output']>;
  sort?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type TournamentsVar_SampFields = {
  __typename?: 'TournamentsVar_sampFields';
  bulletsMax?: Maybe<Scalars['Float']['output']>;
  buyIn?: Maybe<Scalars['Float']['output']>;
  currencyId?: Maybe<Scalars['Float']['output']>;
  currencyRate?: Maybe<Scalars['Float']['output']>;
  guaranteed?: Maybe<Scalars['Float']['output']>;
  localCurrencyId?: Maybe<Scalars['Float']['output']>;
  sharesMax?: Maybe<Scalars['Float']['output']>;
  sort?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type TournamentsVarianceFields = {
  __typename?: 'TournamentsVarianceFields';
  bulletsMax?: Maybe<Scalars['Float']['output']>;
  buyIn?: Maybe<Scalars['Float']['output']>;
  currencyId?: Maybe<Scalars['Float']['output']>;
  currencyRate?: Maybe<Scalars['Float']['output']>;
  guaranteed?: Maybe<Scalars['Float']['output']>;
  localCurrencyId?: Maybe<Scalars['Float']['output']>;
  sharesMax?: Maybe<Scalars['Float']['output']>;
  sort?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "transaction_user_types" */
export type TransactionUserTypes = {
  __typename?: 'TransactionUserTypes';
  description?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

/** aggregated selection of "transaction_user_types" */
export type TransactionUserTypesAggregate = {
  __typename?: 'TransactionUserTypesAggregate';
  aggregate?: Maybe<TransactionUserTypesAggregateFields>;
  nodes: Array<TransactionUserTypes>;
};

/** aggregate fields of "transaction_user_types" */
export type TransactionUserTypesAggregateFields = {
  __typename?: 'TransactionUserTypesAggregateFields';
  count: Scalars['Int']['output'];
  max?: Maybe<TransactionUserTypesMaxFields>;
  min?: Maybe<TransactionUserTypesMinFields>;
};

/** aggregate fields of "transaction_user_types" */
export type TransactionUserTypesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<TransactionUserTypesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "transaction_user_types". All fields are combined with a logical 'AND'. */
export type TransactionUserTypesBoolExp = {
  _and?: InputMaybe<Array<TransactionUserTypesBoolExp>>;
  _not?: InputMaybe<TransactionUserTypesBoolExp>;
  _or?: InputMaybe<Array<TransactionUserTypesBoolExp>>;
  description?: InputMaybe<StringComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "transaction_user_types" */
export enum TransactionUserTypesConstraint {
  /** unique or primary key constraint on columns "name" */
  TransactionUserTypesPkey = 'transaction_user_types_pkey',
}

export enum TransactionUserTypesEnum {
  Organiser = 'ORGANISER',
  Player = 'PLAYER',
  Stake3 = 'STAKE3',
  Tournament = 'TOURNAMENT',
  User = 'USER',
}

/** Boolean expression to compare columns of type "TransactionUserTypesEnum". All fields are combined with logical 'AND'. */
export type TransactionUserTypesEnumComparisonExp = {
  _eq?: InputMaybe<TransactionUserTypesEnum>;
  _in?: InputMaybe<Array<TransactionUserTypesEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<TransactionUserTypesEnum>;
  _nin?: InputMaybe<Array<TransactionUserTypesEnum>>;
};

/** input type for inserting data into table "transaction_user_types" */
export type TransactionUserTypesInsertInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type TransactionUserTypesMaxFields = {
  __typename?: 'TransactionUserTypesMaxFields';
  description?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type TransactionUserTypesMinFields = {
  __typename?: 'TransactionUserTypesMinFields';
  description?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "transaction_user_types" */
export type TransactionUserTypesMutationResponse = {
  __typename?: 'TransactionUserTypesMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<TransactionUserTypes>;
};

/** on_conflict condition type for table "transaction_user_types" */
export type TransactionUserTypesOnConflict = {
  constraint: TransactionUserTypesConstraint;
  update_columns?: Array<TransactionUserTypesUpdateColumn>;
  where?: InputMaybe<TransactionUserTypesBoolExp>;
};

/** Ordering options when selecting data from "transaction_user_types". */
export type TransactionUserTypesOrderBy = {
  description?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: transaction_user_types */
export type TransactionUserTypesPkColumnsInput = {
  name: Scalars['String']['input'];
};

/** select columns of table "transaction_user_types" */
export enum TransactionUserTypesSelectColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name',
}

/** input type for updating data in table "transaction_user_types" */
export type TransactionUserTypesSetInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "transaction_user_types" */
export type TransactionUserTypesStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: TransactionUserTypesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type TransactionUserTypesStreamCursorValueInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "transaction_user_types" */
export enum TransactionUserTypesUpdateColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name',
}

export type TransactionUserTypesUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<TransactionUserTypesSetInput>;
  /** filter the rows which have to be updated */
  where: TransactionUserTypesBoolExp;
};

/** columns and relationships of "transactions" */
export type Transactions = {
  __typename?: 'Transactions';
  amount: Scalars['numeric']['output'];
  bullet?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['timestamptz']['output'];
  createdBy?: Maybe<TransactionUserTypesEnum>;
  currencyId: Scalars['Int']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['uuid']['output'];
  log?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  payment?: Maybe<Payments>;
  paymentId?: Maybe<Scalars['uuid']['output']>;
  playerShareId?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  player_share?: Maybe<PlayerShares>;
  status: Scalars['String']['output'];
  ticketId?: Maybe<Scalars['uuid']['output']>;
  tournamentId?: Maybe<Scalars['uuid']['output']>;
  type: Scalars['String']['output'];
  updatedAt: Scalars['timestamptz']['output'];
  updatedBy?: Maybe<TransactionUserTypesEnum>;
  /** An object relationship */
  user?: Maybe<Users>;
  userId?: Maybe<Scalars['String']['output']>;
  userShareBuyId?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  user_share_buy?: Maybe<UserShareBuys>;
};

/** aggregated selection of "transactions" */
export type TransactionsAggregate = {
  __typename?: 'TransactionsAggregate';
  aggregate?: Maybe<TransactionsAggregateFields>;
  nodes: Array<Transactions>;
};

/** aggregate fields of "transactions" */
export type TransactionsAggregateFields = {
  __typename?: 'TransactionsAggregateFields';
  avg?: Maybe<TransactionsAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<TransactionsMaxFields>;
  min?: Maybe<TransactionsMinFields>;
  stddev?: Maybe<TransactionsStddevFields>;
  stddevPop?: Maybe<TransactionsStddev_PopFields>;
  stddevSamp?: Maybe<TransactionsStddev_SampFields>;
  sum?: Maybe<TransactionsSumFields>;
  varPop?: Maybe<TransactionsVar_PopFields>;
  varSamp?: Maybe<TransactionsVar_SampFields>;
  variance?: Maybe<TransactionsVarianceFields>;
};

/** aggregate fields of "transactions" */
export type TransactionsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<TransactionsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "transactions" */
export type TransactionsAggregateOrderBy = {
  avg?: InputMaybe<Transactions_Avg_Order_By>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<Transactions_Max_Order_By>;
  min?: InputMaybe<Transactions_Min_Order_By>;
  stddev?: InputMaybe<Transactions_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Transactions_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Transactions_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Transactions_Sum_Order_By>;
  var_pop?: InputMaybe<Transactions_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Transactions_Var_Samp_Order_By>;
  variance?: InputMaybe<Transactions_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "transactions" */
export type TransactionsArrRelInsertInput = {
  data: Array<TransactionsInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<TransactionsOnConflict>;
};

/** aggregate avg on columns */
export type TransactionsAvgFields = {
  __typename?: 'TransactionsAvgFields';
  amount?: Maybe<Scalars['Float']['output']>;
  bullet?: Maybe<Scalars['Float']['output']>;
  currencyId?: Maybe<Scalars['Float']['output']>;
  userShareBuyId?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "transactions". All fields are combined with a logical 'AND'. */
export type TransactionsBoolExp = {
  _and?: InputMaybe<Array<TransactionsBoolExp>>;
  _not?: InputMaybe<TransactionsBoolExp>;
  _or?: InputMaybe<Array<TransactionsBoolExp>>;
  amount?: InputMaybe<NumericComparisonExp>;
  bullet?: InputMaybe<IntComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  createdBy?: InputMaybe<TransactionUserTypesEnumComparisonExp>;
  currencyId?: InputMaybe<IntComparisonExp>;
  description?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  log?: InputMaybe<StringComparisonExp>;
  payment?: InputMaybe<PaymentsBoolExp>;
  paymentId?: InputMaybe<UuidComparisonExp>;
  playerShareId?: InputMaybe<UuidComparisonExp>;
  player_share?: InputMaybe<PlayerSharesBoolExp>;
  status?: InputMaybe<StringComparisonExp>;
  ticketId?: InputMaybe<UuidComparisonExp>;
  tournamentId?: InputMaybe<UuidComparisonExp>;
  type?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  updatedBy?: InputMaybe<TransactionUserTypesEnumComparisonExp>;
  user?: InputMaybe<UsersBoolExp>;
  userId?: InputMaybe<StringComparisonExp>;
  userShareBuyId?: InputMaybe<IntComparisonExp>;
  user_share_buy?: InputMaybe<UserShareBuysBoolExp>;
};

/** unique or primary key constraints on table "transactions" */
export enum TransactionsConstraint {
  /** unique or primary key constraint on columns "id" */
  TransactionsPkey = 'transactions_pkey',
}

/** input type for incrementing numeric columns in table "transactions" */
export type TransactionsIncInput = {
  amount?: InputMaybe<Scalars['numeric']['input']>;
  bullet?: InputMaybe<Scalars['Int']['input']>;
  currencyId?: InputMaybe<Scalars['Int']['input']>;
  userShareBuyId?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "transactions" */
export type TransactionsInsertInput = {
  amount?: InputMaybe<Scalars['numeric']['input']>;
  bullet?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  createdBy?: InputMaybe<TransactionUserTypesEnum>;
  currencyId?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  log?: InputMaybe<Scalars['String']['input']>;
  payment?: InputMaybe<PaymentsObjRelInsertInput>;
  paymentId?: InputMaybe<Scalars['uuid']['input']>;
  playerShareId?: InputMaybe<Scalars['uuid']['input']>;
  player_share?: InputMaybe<PlayerSharesObjRelInsertInput>;
  status?: InputMaybe<Scalars['String']['input']>;
  ticketId?: InputMaybe<Scalars['uuid']['input']>;
  tournamentId?: InputMaybe<Scalars['uuid']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  updatedBy?: InputMaybe<TransactionUserTypesEnum>;
  user?: InputMaybe<UsersObjRelInsertInput>;
  userId?: InputMaybe<Scalars['String']['input']>;
  userShareBuyId?: InputMaybe<Scalars['Int']['input']>;
  user_share_buy?: InputMaybe<UserShareBuysObjRelInsertInput>;
};

/** aggregate max on columns */
export type TransactionsMaxFields = {
  __typename?: 'TransactionsMaxFields';
  amount?: Maybe<Scalars['numeric']['output']>;
  bullet?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  currencyId?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  log?: Maybe<Scalars['String']['output']>;
  paymentId?: Maybe<Scalars['uuid']['output']>;
  playerShareId?: Maybe<Scalars['uuid']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  ticketId?: Maybe<Scalars['uuid']['output']>;
  tournamentId?: Maybe<Scalars['uuid']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
  userShareBuyId?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type TransactionsMinFields = {
  __typename?: 'TransactionsMinFields';
  amount?: Maybe<Scalars['numeric']['output']>;
  bullet?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  currencyId?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  log?: Maybe<Scalars['String']['output']>;
  paymentId?: Maybe<Scalars['uuid']['output']>;
  playerShareId?: Maybe<Scalars['uuid']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  ticketId?: Maybe<Scalars['uuid']['output']>;
  tournamentId?: Maybe<Scalars['uuid']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
  userShareBuyId?: Maybe<Scalars['Int']['output']>;
};

/** response of any mutation on the table "transactions" */
export type TransactionsMutationResponse = {
  __typename?: 'TransactionsMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Transactions>;
};

/** on_conflict condition type for table "transactions" */
export type TransactionsOnConflict = {
  constraint: TransactionsConstraint;
  update_columns?: Array<TransactionsUpdateColumn>;
  where?: InputMaybe<TransactionsBoolExp>;
};

/** Ordering options when selecting data from "transactions". */
export type TransactionsOrderBy = {
  amount?: InputMaybe<OrderBy>;
  bullet?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<OrderBy>;
  currencyId?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  log?: InputMaybe<OrderBy>;
  payment?: InputMaybe<PaymentsOrderBy>;
  paymentId?: InputMaybe<OrderBy>;
  playerShareId?: InputMaybe<OrderBy>;
  player_share?: InputMaybe<PlayerSharesOrderBy>;
  status?: InputMaybe<OrderBy>;
  ticketId?: InputMaybe<OrderBy>;
  tournamentId?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  updatedBy?: InputMaybe<OrderBy>;
  user?: InputMaybe<UsersOrderBy>;
  userId?: InputMaybe<OrderBy>;
  userShareBuyId?: InputMaybe<OrderBy>;
  user_share_buy?: InputMaybe<UserShareBuysOrderBy>;
};

/** primary key columns input for table: transactions */
export type TransactionsPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "transactions" */
export enum TransactionsSelectColumn {
  /** column name */
  Amount = 'amount',
  /** column name */
  Bullet = 'bullet',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CreatedBy = 'createdBy',
  /** column name */
  CurrencyId = 'currencyId',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Log = 'log',
  /** column name */
  PaymentId = 'paymentId',
  /** column name */
  PlayerShareId = 'playerShareId',
  /** column name */
  Status = 'status',
  /** column name */
  TicketId = 'ticketId',
  /** column name */
  TournamentId = 'tournamentId',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UpdatedBy = 'updatedBy',
  /** column name */
  UserId = 'userId',
  /** column name */
  UserShareBuyId = 'userShareBuyId',
}

/** input type for updating data in table "transactions" */
export type TransactionsSetInput = {
  amount?: InputMaybe<Scalars['numeric']['input']>;
  bullet?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  createdBy?: InputMaybe<TransactionUserTypesEnum>;
  currencyId?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  log?: InputMaybe<Scalars['String']['input']>;
  paymentId?: InputMaybe<Scalars['uuid']['input']>;
  playerShareId?: InputMaybe<Scalars['uuid']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  ticketId?: InputMaybe<Scalars['uuid']['input']>;
  tournamentId?: InputMaybe<Scalars['uuid']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  updatedBy?: InputMaybe<TransactionUserTypesEnum>;
  userId?: InputMaybe<Scalars['String']['input']>;
  userShareBuyId?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type TransactionsStddevFields = {
  __typename?: 'TransactionsStddevFields';
  amount?: Maybe<Scalars['Float']['output']>;
  bullet?: Maybe<Scalars['Float']['output']>;
  currencyId?: Maybe<Scalars['Float']['output']>;
  userShareBuyId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type TransactionsStddev_PopFields = {
  __typename?: 'TransactionsStddev_popFields';
  amount?: Maybe<Scalars['Float']['output']>;
  bullet?: Maybe<Scalars['Float']['output']>;
  currencyId?: Maybe<Scalars['Float']['output']>;
  userShareBuyId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type TransactionsStddev_SampFields = {
  __typename?: 'TransactionsStddev_sampFields';
  amount?: Maybe<Scalars['Float']['output']>;
  bullet?: Maybe<Scalars['Float']['output']>;
  currencyId?: Maybe<Scalars['Float']['output']>;
  userShareBuyId?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "transactions" */
export type TransactionsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: TransactionsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type TransactionsStreamCursorValueInput = {
  amount?: InputMaybe<Scalars['numeric']['input']>;
  bullet?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  createdBy?: InputMaybe<TransactionUserTypesEnum>;
  currencyId?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  log?: InputMaybe<Scalars['String']['input']>;
  paymentId?: InputMaybe<Scalars['uuid']['input']>;
  playerShareId?: InputMaybe<Scalars['uuid']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  ticketId?: InputMaybe<Scalars['uuid']['input']>;
  tournamentId?: InputMaybe<Scalars['uuid']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  updatedBy?: InputMaybe<TransactionUserTypesEnum>;
  userId?: InputMaybe<Scalars['String']['input']>;
  userShareBuyId?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type TransactionsSumFields = {
  __typename?: 'TransactionsSumFields';
  amount?: Maybe<Scalars['numeric']['output']>;
  bullet?: Maybe<Scalars['Int']['output']>;
  currencyId?: Maybe<Scalars['Int']['output']>;
  userShareBuyId?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "transactions" */
export enum TransactionsUpdateColumn {
  /** column name */
  Amount = 'amount',
  /** column name */
  Bullet = 'bullet',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CreatedBy = 'createdBy',
  /** column name */
  CurrencyId = 'currencyId',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Log = 'log',
  /** column name */
  PaymentId = 'paymentId',
  /** column name */
  PlayerShareId = 'playerShareId',
  /** column name */
  Status = 'status',
  /** column name */
  TicketId = 'ticketId',
  /** column name */
  TournamentId = 'tournamentId',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UpdatedBy = 'updatedBy',
  /** column name */
  UserId = 'userId',
  /** column name */
  UserShareBuyId = 'userShareBuyId',
}

export type TransactionsUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<TransactionsIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<TransactionsSetInput>;
  /** filter the rows which have to be updated */
  where: TransactionsBoolExp;
};

/** aggregate var_pop on columns */
export type TransactionsVar_PopFields = {
  __typename?: 'TransactionsVar_popFields';
  amount?: Maybe<Scalars['Float']['output']>;
  bullet?: Maybe<Scalars['Float']['output']>;
  currencyId?: Maybe<Scalars['Float']['output']>;
  userShareBuyId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type TransactionsVar_SampFields = {
  __typename?: 'TransactionsVar_sampFields';
  amount?: Maybe<Scalars['Float']['output']>;
  bullet?: Maybe<Scalars['Float']['output']>;
  currencyId?: Maybe<Scalars['Float']['output']>;
  userShareBuyId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type TransactionsVarianceFields = {
  __typename?: 'TransactionsVarianceFields';
  amount?: Maybe<Scalars['Float']['output']>;
  bullet?: Maybe<Scalars['Float']['output']>;
  currencyId?: Maybe<Scalars['Float']['output']>;
  userShareBuyId?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "user_balances" */
export type UserBalances = {
  __typename?: 'UserBalances';
  balance?: Maybe<Scalars['numeric']['output']>;
  currencyId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "user_balances" */
export type UserBalancesAggregate = {
  __typename?: 'UserBalancesAggregate';
  aggregate?: Maybe<UserBalancesAggregateFields>;
  nodes: Array<UserBalances>;
};

/** aggregate fields of "user_balances" */
export type UserBalancesAggregateFields = {
  __typename?: 'UserBalancesAggregateFields';
  avg?: Maybe<UserBalancesAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<UserBalancesMaxFields>;
  min?: Maybe<UserBalancesMinFields>;
  stddev?: Maybe<UserBalancesStddevFields>;
  stddevPop?: Maybe<UserBalancesStddev_PopFields>;
  stddevSamp?: Maybe<UserBalancesStddev_SampFields>;
  sum?: Maybe<UserBalancesSumFields>;
  varPop?: Maybe<UserBalancesVar_PopFields>;
  varSamp?: Maybe<UserBalancesVar_SampFields>;
  variance?: Maybe<UserBalancesVarianceFields>;
};

/** aggregate fields of "user_balances" */
export type UserBalancesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<UserBalancesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "user_balances" */
export type UserBalancesAggregateOrderBy = {
  avg?: InputMaybe<User_Balances_Avg_Order_By>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<User_Balances_Max_Order_By>;
  min?: InputMaybe<User_Balances_Min_Order_By>;
  stddev?: InputMaybe<User_Balances_Stddev_Order_By>;
  stddev_pop?: InputMaybe<User_Balances_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<User_Balances_Stddev_Samp_Order_By>;
  sum?: InputMaybe<User_Balances_Sum_Order_By>;
  var_pop?: InputMaybe<User_Balances_Var_Pop_Order_By>;
  var_samp?: InputMaybe<User_Balances_Var_Samp_Order_By>;
  variance?: InputMaybe<User_Balances_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "user_balances" */
export type UserBalancesArrRelInsertInput = {
  data: Array<UserBalancesInsertInput>;
};

/** aggregate avg on columns */
export type UserBalancesAvgFields = {
  __typename?: 'UserBalancesAvgFields';
  balance?: Maybe<Scalars['Float']['output']>;
  currencyId?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "user_balances". All fields are combined with a logical 'AND'. */
export type UserBalancesBoolExp = {
  _and?: InputMaybe<Array<UserBalancesBoolExp>>;
  _not?: InputMaybe<UserBalancesBoolExp>;
  _or?: InputMaybe<Array<UserBalancesBoolExp>>;
  balance?: InputMaybe<NumericComparisonExp>;
  currencyId?: InputMaybe<IntComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  status?: InputMaybe<StringComparisonExp>;
  userId?: InputMaybe<StringComparisonExp>;
};

/** input type for inserting data into table "user_balances" */
export type UserBalancesInsertInput = {
  balance?: InputMaybe<Scalars['numeric']['input']>;
  currencyId?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type UserBalancesMaxFields = {
  __typename?: 'UserBalancesMaxFields';
  balance?: Maybe<Scalars['numeric']['output']>;
  currencyId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type UserBalancesMinFields = {
  __typename?: 'UserBalancesMinFields';
  balance?: Maybe<Scalars['numeric']['output']>;
  currencyId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** Ordering options when selecting data from "user_balances". */
export type UserBalancesOrderBy = {
  balance?: InputMaybe<OrderBy>;
  currencyId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** select columns of table "user_balances" */
export enum UserBalancesSelectColumn {
  /** column name */
  Balance = 'balance',
  /** column name */
  CurrencyId = 'currencyId',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  UserId = 'userId',
}

/** aggregate stddev on columns */
export type UserBalancesStddevFields = {
  __typename?: 'UserBalancesStddevFields';
  balance?: Maybe<Scalars['Float']['output']>;
  currencyId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type UserBalancesStddev_PopFields = {
  __typename?: 'UserBalancesStddev_popFields';
  balance?: Maybe<Scalars['Float']['output']>;
  currencyId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type UserBalancesStddev_SampFields = {
  __typename?: 'UserBalancesStddev_sampFields';
  balance?: Maybe<Scalars['Float']['output']>;
  currencyId?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "user_balances" */
export type UserBalancesStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: UserBalancesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type UserBalancesStreamCursorValueInput = {
  balance?: InputMaybe<Scalars['numeric']['input']>;
  currencyId?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type UserBalancesSumFields = {
  __typename?: 'UserBalancesSumFields';
  balance?: Maybe<Scalars['numeric']['output']>;
  currencyId?: Maybe<Scalars['Int']['output']>;
};

/** aggregate var_pop on columns */
export type UserBalancesVar_PopFields = {
  __typename?: 'UserBalancesVar_popFields';
  balance?: Maybe<Scalars['Float']['output']>;
  currencyId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type UserBalancesVar_SampFields = {
  __typename?: 'UserBalancesVar_sampFields';
  balance?: Maybe<Scalars['Float']['output']>;
  currencyId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type UserBalancesVarianceFields = {
  __typename?: 'UserBalancesVarianceFields';
  balance?: Maybe<Scalars['Float']['output']>;
  currencyId?: Maybe<Scalars['Float']['output']>;
};

export type UserOutput = {
  __typename?: 'UserOutput';
  avatar?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  erc20Address?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  language: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  organiserId?: Maybe<Scalars['String']['output']>;
  playerId?: Maybe<Scalars['String']['output']>;
  playerName?: Maybe<Scalars['String']['output']>;
  playerSlug?: Maybe<Scalars['String']['output']>;
  playerStatus?: Maybe<PlayerStatus>;
  provider: ProviderEnum;
  role?: Maybe<Scalars['String']['output']>;
  status: UserStatus;
  twofa?: Maybe<Scalars['Boolean']['output']>;
  username?: Maybe<Scalars['String']['output']>;
  venueId?: Maybe<Scalars['String']['output']>;
};

/** columns and relationships of "user_share_buys" */
export type UserShareBuys = {
  __typename?: 'UserShareBuys';
  bulletsPlayed: Scalars['Int']['output'];
  createdAt: Scalars['timestamptz']['output'];
  id: Scalars['bigint']['output'];
  markupAmount: Scalars['numeric']['output'];
  playerShareId: Scalars['uuid']['output'];
  /** An object relationship */
  player_share: PlayerShares;
  shareAmount: Scalars['numeric']['output'];
  sharePercentage: Scalars['numeric']['output'];
  status: Scalars['String']['output'];
  totalAmount: Scalars['numeric']['output'];
  /** An array relationship */
  transactions: Array<Transactions>;
  /** An aggregate relationship */
  transactionsAggregate: TransactionsAggregate;
  updatedAt: Scalars['timestamptz']['output'];
  /** An object relationship */
  user: Users;
  userId: Scalars['String']['output'];
  won?: Maybe<Scalars['numeric']['output']>;
};

/** columns and relationships of "user_share_buys" */
export type UserShareBuysTransactionsArgs = {
  distinctOn?: InputMaybe<Array<TransactionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TransactionsOrderBy>>;
  where?: InputMaybe<TransactionsBoolExp>;
};

/** columns and relationships of "user_share_buys" */
export type UserShareBuysTransactionsAggregateArgs = {
  distinctOn?: InputMaybe<Array<TransactionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TransactionsOrderBy>>;
  where?: InputMaybe<TransactionsBoolExp>;
};

/** aggregated selection of "user_share_buys" */
export type UserShareBuysAggregate = {
  __typename?: 'UserShareBuysAggregate';
  aggregate?: Maybe<UserShareBuysAggregateFields>;
  nodes: Array<UserShareBuys>;
};

/** aggregate fields of "user_share_buys" */
export type UserShareBuysAggregateFields = {
  __typename?: 'UserShareBuysAggregateFields';
  avg?: Maybe<UserShareBuysAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<UserShareBuysMaxFields>;
  min?: Maybe<UserShareBuysMinFields>;
  stddev?: Maybe<UserShareBuysStddevFields>;
  stddevPop?: Maybe<UserShareBuysStddev_PopFields>;
  stddevSamp?: Maybe<UserShareBuysStddev_SampFields>;
  sum?: Maybe<UserShareBuysSumFields>;
  varPop?: Maybe<UserShareBuysVar_PopFields>;
  varSamp?: Maybe<UserShareBuysVar_SampFields>;
  variance?: Maybe<UserShareBuysVarianceFields>;
};

/** aggregate fields of "user_share_buys" */
export type UserShareBuysAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<UserShareBuysSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "user_share_buys" */
export type UserShareBuysAggregateOrderBy = {
  avg?: InputMaybe<User_Share_Buys_Avg_Order_By>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<User_Share_Buys_Max_Order_By>;
  min?: InputMaybe<User_Share_Buys_Min_Order_By>;
  stddev?: InputMaybe<User_Share_Buys_Stddev_Order_By>;
  stddev_pop?: InputMaybe<User_Share_Buys_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<User_Share_Buys_Stddev_Samp_Order_By>;
  sum?: InputMaybe<User_Share_Buys_Sum_Order_By>;
  var_pop?: InputMaybe<User_Share_Buys_Var_Pop_Order_By>;
  var_samp?: InputMaybe<User_Share_Buys_Var_Samp_Order_By>;
  variance?: InputMaybe<User_Share_Buys_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "user_share_buys" */
export type UserShareBuysArrRelInsertInput = {
  data: Array<UserShareBuysInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<UserShareBuysOnConflict>;
};

/** aggregate avg on columns */
export type UserShareBuysAvgFields = {
  __typename?: 'UserShareBuysAvgFields';
  bulletsPlayed?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  markupAmount?: Maybe<Scalars['Float']['output']>;
  shareAmount?: Maybe<Scalars['Float']['output']>;
  sharePercentage?: Maybe<Scalars['Float']['output']>;
  totalAmount?: Maybe<Scalars['Float']['output']>;
  won?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "user_share_buys". All fields are combined with a logical 'AND'. */
export type UserShareBuysBoolExp = {
  _and?: InputMaybe<Array<UserShareBuysBoolExp>>;
  _not?: InputMaybe<UserShareBuysBoolExp>;
  _or?: InputMaybe<Array<UserShareBuysBoolExp>>;
  bulletsPlayed?: InputMaybe<IntComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  markupAmount?: InputMaybe<NumericComparisonExp>;
  playerShareId?: InputMaybe<UuidComparisonExp>;
  player_share?: InputMaybe<PlayerSharesBoolExp>;
  shareAmount?: InputMaybe<NumericComparisonExp>;
  sharePercentage?: InputMaybe<NumericComparisonExp>;
  status?: InputMaybe<StringComparisonExp>;
  totalAmount?: InputMaybe<NumericComparisonExp>;
  transactions?: InputMaybe<TransactionsBoolExp>;
  transactions_aggregate?: InputMaybe<Transactions_Aggregate_Bool_Exp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  user?: InputMaybe<UsersBoolExp>;
  userId?: InputMaybe<StringComparisonExp>;
  won?: InputMaybe<NumericComparisonExp>;
};

/** unique or primary key constraints on table "user_share_buys" */
export enum UserShareBuysConstraint {
  /** unique or primary key constraint on columns "id" */
  UserShareBuysPkey = 'user_share_buys_pkey',
}

/** input type for incrementing numeric columns in table "user_share_buys" */
export type UserShareBuysIncInput = {
  bulletsPlayed?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  markupAmount?: InputMaybe<Scalars['numeric']['input']>;
  shareAmount?: InputMaybe<Scalars['numeric']['input']>;
  sharePercentage?: InputMaybe<Scalars['numeric']['input']>;
  totalAmount?: InputMaybe<Scalars['numeric']['input']>;
  won?: InputMaybe<Scalars['numeric']['input']>;
};

/** input type for inserting data into table "user_share_buys" */
export type UserShareBuysInsertInput = {
  bulletsPlayed?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  markupAmount?: InputMaybe<Scalars['numeric']['input']>;
  playerShareId?: InputMaybe<Scalars['uuid']['input']>;
  player_share?: InputMaybe<PlayerSharesObjRelInsertInput>;
  shareAmount?: InputMaybe<Scalars['numeric']['input']>;
  sharePercentage?: InputMaybe<Scalars['numeric']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  totalAmount?: InputMaybe<Scalars['numeric']['input']>;
  transactions?: InputMaybe<TransactionsArrRelInsertInput>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<UsersObjRelInsertInput>;
  userId?: InputMaybe<Scalars['String']['input']>;
  won?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate max on columns */
export type UserShareBuysMaxFields = {
  __typename?: 'UserShareBuysMaxFields';
  bulletsPlayed?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  markupAmount?: Maybe<Scalars['numeric']['output']>;
  playerShareId?: Maybe<Scalars['uuid']['output']>;
  shareAmount?: Maybe<Scalars['numeric']['output']>;
  sharePercentage?: Maybe<Scalars['numeric']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  totalAmount?: Maybe<Scalars['numeric']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
  won?: Maybe<Scalars['numeric']['output']>;
};

/** aggregate min on columns */
export type UserShareBuysMinFields = {
  __typename?: 'UserShareBuysMinFields';
  bulletsPlayed?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  markupAmount?: Maybe<Scalars['numeric']['output']>;
  playerShareId?: Maybe<Scalars['uuid']['output']>;
  shareAmount?: Maybe<Scalars['numeric']['output']>;
  sharePercentage?: Maybe<Scalars['numeric']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  totalAmount?: Maybe<Scalars['numeric']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
  won?: Maybe<Scalars['numeric']['output']>;
};

/** response of any mutation on the table "user_share_buys" */
export type UserShareBuysMutationResponse = {
  __typename?: 'UserShareBuysMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<UserShareBuys>;
};

/** input type for inserting object relation for remote table "user_share_buys" */
export type UserShareBuysObjRelInsertInput = {
  data: UserShareBuysInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<UserShareBuysOnConflict>;
};

/** on_conflict condition type for table "user_share_buys" */
export type UserShareBuysOnConflict = {
  constraint: UserShareBuysConstraint;
  update_columns?: Array<UserShareBuysUpdateColumn>;
  where?: InputMaybe<UserShareBuysBoolExp>;
};

/** Ordering options when selecting data from "user_share_buys". */
export type UserShareBuysOrderBy = {
  bulletsPlayed?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  markupAmount?: InputMaybe<OrderBy>;
  playerShareId?: InputMaybe<OrderBy>;
  player_share?: InputMaybe<PlayerSharesOrderBy>;
  shareAmount?: InputMaybe<OrderBy>;
  sharePercentage?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  totalAmount?: InputMaybe<OrderBy>;
  transactionsAggregate?: InputMaybe<TransactionsAggregateOrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  user?: InputMaybe<UsersOrderBy>;
  userId?: InputMaybe<OrderBy>;
  won?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: user_share_buys */
export type UserShareBuysPkColumnsInput = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "user_share_buys" */
export enum UserShareBuysSelectColumn {
  /** column name */
  BulletsPlayed = 'bulletsPlayed',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  MarkupAmount = 'markupAmount',
  /** column name */
  PlayerShareId = 'playerShareId',
  /** column name */
  ShareAmount = 'shareAmount',
  /** column name */
  SharePercentage = 'sharePercentage',
  /** column name */
  Status = 'status',
  /** column name */
  TotalAmount = 'totalAmount',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId',
  /** column name */
  Won = 'won',
}

/** input type for updating data in table "user_share_buys" */
export type UserShareBuysSetInput = {
  bulletsPlayed?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  markupAmount?: InputMaybe<Scalars['numeric']['input']>;
  playerShareId?: InputMaybe<Scalars['uuid']['input']>;
  shareAmount?: InputMaybe<Scalars['numeric']['input']>;
  sharePercentage?: InputMaybe<Scalars['numeric']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  totalAmount?: InputMaybe<Scalars['numeric']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
  won?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate stddev on columns */
export type UserShareBuysStddevFields = {
  __typename?: 'UserShareBuysStddevFields';
  bulletsPlayed?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  markupAmount?: Maybe<Scalars['Float']['output']>;
  shareAmount?: Maybe<Scalars['Float']['output']>;
  sharePercentage?: Maybe<Scalars['Float']['output']>;
  totalAmount?: Maybe<Scalars['Float']['output']>;
  won?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type UserShareBuysStddev_PopFields = {
  __typename?: 'UserShareBuysStddev_popFields';
  bulletsPlayed?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  markupAmount?: Maybe<Scalars['Float']['output']>;
  shareAmount?: Maybe<Scalars['Float']['output']>;
  sharePercentage?: Maybe<Scalars['Float']['output']>;
  totalAmount?: Maybe<Scalars['Float']['output']>;
  won?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type UserShareBuysStddev_SampFields = {
  __typename?: 'UserShareBuysStddev_sampFields';
  bulletsPlayed?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  markupAmount?: Maybe<Scalars['Float']['output']>;
  shareAmount?: Maybe<Scalars['Float']['output']>;
  sharePercentage?: Maybe<Scalars['Float']['output']>;
  totalAmount?: Maybe<Scalars['Float']['output']>;
  won?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "user_share_buys" */
export type UserShareBuysStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: UserShareBuysStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type UserShareBuysStreamCursorValueInput = {
  bulletsPlayed?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  markupAmount?: InputMaybe<Scalars['numeric']['input']>;
  playerShareId?: InputMaybe<Scalars['uuid']['input']>;
  shareAmount?: InputMaybe<Scalars['numeric']['input']>;
  sharePercentage?: InputMaybe<Scalars['numeric']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  totalAmount?: InputMaybe<Scalars['numeric']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
  won?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate sum on columns */
export type UserShareBuysSumFields = {
  __typename?: 'UserShareBuysSumFields';
  bulletsPlayed?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  markupAmount?: Maybe<Scalars['numeric']['output']>;
  shareAmount?: Maybe<Scalars['numeric']['output']>;
  sharePercentage?: Maybe<Scalars['numeric']['output']>;
  totalAmount?: Maybe<Scalars['numeric']['output']>;
  won?: Maybe<Scalars['numeric']['output']>;
};

/** update columns of table "user_share_buys" */
export enum UserShareBuysUpdateColumn {
  /** column name */
  BulletsPlayed = 'bulletsPlayed',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  MarkupAmount = 'markupAmount',
  /** column name */
  PlayerShareId = 'playerShareId',
  /** column name */
  ShareAmount = 'shareAmount',
  /** column name */
  SharePercentage = 'sharePercentage',
  /** column name */
  Status = 'status',
  /** column name */
  TotalAmount = 'totalAmount',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId',
  /** column name */
  Won = 'won',
}

export type UserShareBuysUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<UserShareBuysIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<UserShareBuysSetInput>;
  /** filter the rows which have to be updated */
  where: UserShareBuysBoolExp;
};

/** aggregate var_pop on columns */
export type UserShareBuysVar_PopFields = {
  __typename?: 'UserShareBuysVar_popFields';
  bulletsPlayed?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  markupAmount?: Maybe<Scalars['Float']['output']>;
  shareAmount?: Maybe<Scalars['Float']['output']>;
  sharePercentage?: Maybe<Scalars['Float']['output']>;
  totalAmount?: Maybe<Scalars['Float']['output']>;
  won?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type UserShareBuysVar_SampFields = {
  __typename?: 'UserShareBuysVar_sampFields';
  bulletsPlayed?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  markupAmount?: Maybe<Scalars['Float']['output']>;
  shareAmount?: Maybe<Scalars['Float']['output']>;
  sharePercentage?: Maybe<Scalars['Float']['output']>;
  totalAmount?: Maybe<Scalars['Float']['output']>;
  won?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type UserShareBuysVarianceFields = {
  __typename?: 'UserShareBuysVarianceFields';
  bulletsPlayed?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  markupAmount?: Maybe<Scalars['Float']['output']>;
  shareAmount?: Maybe<Scalars['Float']['output']>;
  sharePercentage?: Maybe<Scalars['Float']['output']>;
  totalAmount?: Maybe<Scalars['Float']['output']>;
  won?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "users" */
export type Users = {
  __typename?: 'Users';
  avatar?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['timestamptz']['output'];
  deletedAt?: Maybe<Scalars['timestamptz']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  erc20Address?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  language: Scalars['String']['output'];
  lastseenAt: Scalars['timestamptz']['output'];
  metadata?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  players: Array<Players>;
  /** An aggregate relationship */
  playersAggregate: PlayersAggregate;
  provider?: Maybe<Scalars['String']['output']>;
  signature?: Maybe<Scalars['String']['output']>;
  status: StatusesEnum;
  telegramId?: Maybe<Scalars['Int']['output']>;
  /** An array relationship */
  transactions: Array<Transactions>;
  /** An aggregate relationship */
  transactionsAggregate: TransactionsAggregate;
  twofa: Scalars['Boolean']['output'];
  twofaBackup?: Maybe<Scalars['String']['output']>;
  twofaSecret?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['timestamptz']['output'];
  /** An aggregate relationship */
  userBalancesAggregate: UserBalancesAggregate;
  /** An aggregate relationship */
  userShareBuysAggregate: UserShareBuysAggregate;
  /** An array relationship */
  user_balances: Array<UserBalances>;
  /** An array relationship */
  user_share_buys: Array<UserShareBuys>;
  username?: Maybe<Scalars['String']['output']>;
  /** An aggregate relationship */
  venueUsersAggregate: VenueUsersAggregate;
  /** An array relationship */
  venue_users: Array<VenueUsers>;
  verificationMail?: Maybe<Scalars['timestamptz']['output']>;
};

/** columns and relationships of "users" */
export type UsersPlayersArgs = {
  distinctOn?: InputMaybe<Array<PlayersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PlayersOrderBy>>;
  where?: InputMaybe<PlayersBoolExp>;
};

/** columns and relationships of "users" */
export type UsersPlayersAggregateArgs = {
  distinctOn?: InputMaybe<Array<PlayersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PlayersOrderBy>>;
  where?: InputMaybe<PlayersBoolExp>;
};

/** columns and relationships of "users" */
export type UsersTransactionsArgs = {
  distinctOn?: InputMaybe<Array<TransactionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TransactionsOrderBy>>;
  where?: InputMaybe<TransactionsBoolExp>;
};

/** columns and relationships of "users" */
export type UsersTransactionsAggregateArgs = {
  distinctOn?: InputMaybe<Array<TransactionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TransactionsOrderBy>>;
  where?: InputMaybe<TransactionsBoolExp>;
};

/** columns and relationships of "users" */
export type UsersUserBalancesAggregateArgs = {
  distinctOn?: InputMaybe<Array<UserBalancesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UserBalancesOrderBy>>;
  where?: InputMaybe<UserBalancesBoolExp>;
};

/** columns and relationships of "users" */
export type UsersUserShareBuysAggregateArgs = {
  distinctOn?: InputMaybe<Array<UserShareBuysSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UserShareBuysOrderBy>>;
  where?: InputMaybe<UserShareBuysBoolExp>;
};

/** columns and relationships of "users" */
export type UsersUser_BalancesArgs = {
  distinctOn?: InputMaybe<Array<UserBalancesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UserBalancesOrderBy>>;
  where?: InputMaybe<UserBalancesBoolExp>;
};

/** columns and relationships of "users" */
export type UsersUser_Share_BuysArgs = {
  distinctOn?: InputMaybe<Array<UserShareBuysSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UserShareBuysOrderBy>>;
  where?: InputMaybe<UserShareBuysBoolExp>;
};

/** columns and relationships of "users" */
export type UsersVenueUsersAggregateArgs = {
  distinctOn?: InputMaybe<Array<VenueUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<VenueUsersOrderBy>>;
  where?: InputMaybe<VenueUsersBoolExp>;
};

/** columns and relationships of "users" */
export type UsersVenue_UsersArgs = {
  distinctOn?: InputMaybe<Array<VenueUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<VenueUsersOrderBy>>;
  where?: InputMaybe<VenueUsersBoolExp>;
};

/** aggregated selection of "users" */
export type UsersAggregate = {
  __typename?: 'UsersAggregate';
  aggregate?: Maybe<UsersAggregateFields>;
  nodes: Array<Users>;
};

/** aggregate fields of "users" */
export type UsersAggregateFields = {
  __typename?: 'UsersAggregateFields';
  avg?: Maybe<UsersAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<UsersMaxFields>;
  min?: Maybe<UsersMinFields>;
  stddev?: Maybe<UsersStddevFields>;
  stddevPop?: Maybe<UsersStddev_PopFields>;
  stddevSamp?: Maybe<UsersStddev_SampFields>;
  sum?: Maybe<UsersSumFields>;
  varPop?: Maybe<UsersVar_PopFields>;
  varSamp?: Maybe<UsersVar_SampFields>;
  variance?: Maybe<UsersVarianceFields>;
};

/** aggregate fields of "users" */
export type UsersAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<UsersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type UsersAvgFields = {
  __typename?: 'UsersAvgFields';
  telegramId?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "users". All fields are combined with a logical 'AND'. */
export type UsersBoolExp = {
  _and?: InputMaybe<Array<UsersBoolExp>>;
  _not?: InputMaybe<UsersBoolExp>;
  _or?: InputMaybe<Array<UsersBoolExp>>;
  avatar?: InputMaybe<StringComparisonExp>;
  country?: InputMaybe<StringComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  deletedAt?: InputMaybe<TimestamptzComparisonExp>;
  email?: InputMaybe<StringComparisonExp>;
  erc20Address?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<StringComparisonExp>;
  language?: InputMaybe<StringComparisonExp>;
  lastseenAt?: InputMaybe<TimestamptzComparisonExp>;
  metadata?: InputMaybe<StringComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  players?: InputMaybe<PlayersBoolExp>;
  players_aggregate?: InputMaybe<Players_Aggregate_Bool_Exp>;
  provider?: InputMaybe<StringComparisonExp>;
  signature?: InputMaybe<StringComparisonExp>;
  status?: InputMaybe<StatusesEnumComparisonExp>;
  telegramId?: InputMaybe<IntComparisonExp>;
  transactions?: InputMaybe<TransactionsBoolExp>;
  transactions_aggregate?: InputMaybe<Transactions_Aggregate_Bool_Exp>;
  twofa?: InputMaybe<BooleanComparisonExp>;
  twofaBackup?: InputMaybe<StringComparisonExp>;
  twofaSecret?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  user_balances?: InputMaybe<UserBalancesBoolExp>;
  user_balances_aggregate?: InputMaybe<User_Balances_Aggregate_Bool_Exp>;
  user_share_buys?: InputMaybe<UserShareBuysBoolExp>;
  user_share_buys_aggregate?: InputMaybe<User_Share_Buys_Aggregate_Bool_Exp>;
  username?: InputMaybe<StringComparisonExp>;
  venue_users?: InputMaybe<VenueUsersBoolExp>;
  venue_users_aggregate?: InputMaybe<Venue_Users_Aggregate_Bool_Exp>;
  verificationMail?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "users" */
export enum UsersConstraint {
  /** unique or primary key constraint on columns "id" */
  UsersPkey = 'users_pkey',
}

/** input type for incrementing numeric columns in table "users" */
export type UsersIncInput = {
  telegramId?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "users" */
export type UsersInsertInput = {
  avatar?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  erc20Address?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  lastseenAt?: InputMaybe<Scalars['timestamptz']['input']>;
  metadata?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  players?: InputMaybe<PlayersArrRelInsertInput>;
  provider?: InputMaybe<Scalars['String']['input']>;
  signature?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<StatusesEnum>;
  telegramId?: InputMaybe<Scalars['Int']['input']>;
  transactions?: InputMaybe<TransactionsArrRelInsertInput>;
  twofa?: InputMaybe<Scalars['Boolean']['input']>;
  twofaBackup?: InputMaybe<Scalars['String']['input']>;
  twofaSecret?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  user_balances?: InputMaybe<UserBalancesArrRelInsertInput>;
  user_share_buys?: InputMaybe<UserShareBuysArrRelInsertInput>;
  username?: InputMaybe<Scalars['String']['input']>;
  venue_users?: InputMaybe<VenueUsersArrRelInsertInput>;
  verificationMail?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type UsersMaxFields = {
  __typename?: 'UsersMaxFields';
  avatar?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  deletedAt?: Maybe<Scalars['timestamptz']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  erc20Address?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  language?: Maybe<Scalars['String']['output']>;
  lastseenAt?: Maybe<Scalars['timestamptz']['output']>;
  metadata?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  provider?: Maybe<Scalars['String']['output']>;
  signature?: Maybe<Scalars['String']['output']>;
  telegramId?: Maybe<Scalars['Int']['output']>;
  twofaBackup?: Maybe<Scalars['String']['output']>;
  twofaSecret?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  username?: Maybe<Scalars['String']['output']>;
  verificationMail?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type UsersMinFields = {
  __typename?: 'UsersMinFields';
  avatar?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  deletedAt?: Maybe<Scalars['timestamptz']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  erc20Address?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  language?: Maybe<Scalars['String']['output']>;
  lastseenAt?: Maybe<Scalars['timestamptz']['output']>;
  metadata?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  provider?: Maybe<Scalars['String']['output']>;
  signature?: Maybe<Scalars['String']['output']>;
  telegramId?: Maybe<Scalars['Int']['output']>;
  twofaBackup?: Maybe<Scalars['String']['output']>;
  twofaSecret?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  username?: Maybe<Scalars['String']['output']>;
  verificationMail?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "users" */
export type UsersMutationResponse = {
  __typename?: 'UsersMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Users>;
};

/** input type for inserting object relation for remote table "users" */
export type UsersObjRelInsertInput = {
  data: UsersInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<UsersOnConflict>;
};

/** on_conflict condition type for table "users" */
export type UsersOnConflict = {
  constraint: UsersConstraint;
  update_columns?: Array<UsersUpdateColumn>;
  where?: InputMaybe<UsersBoolExp>;
};

/** Ordering options when selecting data from "users". */
export type UsersOrderBy = {
  avatar?: InputMaybe<OrderBy>;
  country?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  deletedAt?: InputMaybe<OrderBy>;
  email?: InputMaybe<OrderBy>;
  erc20Address?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  language?: InputMaybe<OrderBy>;
  lastseenAt?: InputMaybe<OrderBy>;
  metadata?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  playersAggregate?: InputMaybe<PlayersAggregateOrderBy>;
  provider?: InputMaybe<OrderBy>;
  signature?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  telegramId?: InputMaybe<OrderBy>;
  transactionsAggregate?: InputMaybe<TransactionsAggregateOrderBy>;
  twofa?: InputMaybe<OrderBy>;
  twofaBackup?: InputMaybe<OrderBy>;
  twofaSecret?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  user_balancesAggregate?: InputMaybe<UserBalancesAggregateOrderBy>;
  user_share_buysAggregate?: InputMaybe<UserShareBuysAggregateOrderBy>;
  username?: InputMaybe<OrderBy>;
  venue_usersAggregate?: InputMaybe<VenueUsersAggregateOrderBy>;
  verificationMail?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: users */
export type UsersPkColumnsInput = {
  id: Scalars['String']['input'];
};

/** select columns of table "users" */
export enum UsersSelectColumn {
  /** column name */
  Avatar = 'avatar',
  /** column name */
  Country = 'country',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  Email = 'email',
  /** column name */
  Erc20Address = 'erc20Address',
  /** column name */
  Id = 'id',
  /** column name */
  Language = 'language',
  /** column name */
  LastseenAt = 'lastseenAt',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Name = 'name',
  /** column name */
  Provider = 'provider',
  /** column name */
  Signature = 'signature',
  /** column name */
  Status = 'status',
  /** column name */
  TelegramId = 'telegramId',
  /** column name */
  Twofa = 'twofa',
  /** column name */
  TwofaBackup = 'twofaBackup',
  /** column name */
  TwofaSecret = 'twofaSecret',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Username = 'username',
  /** column name */
  VerificationMail = 'verificationMail',
}

/** input type for updating data in table "users" */
export type UsersSetInput = {
  avatar?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  erc20Address?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  lastseenAt?: InputMaybe<Scalars['timestamptz']['input']>;
  metadata?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  provider?: InputMaybe<Scalars['String']['input']>;
  signature?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<StatusesEnum>;
  telegramId?: InputMaybe<Scalars['Int']['input']>;
  twofa?: InputMaybe<Scalars['Boolean']['input']>;
  twofaBackup?: InputMaybe<Scalars['String']['input']>;
  twofaSecret?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
  verificationMail?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type UsersStddevFields = {
  __typename?: 'UsersStddevFields';
  telegramId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type UsersStddev_PopFields = {
  __typename?: 'UsersStddev_popFields';
  telegramId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type UsersStddev_SampFields = {
  __typename?: 'UsersStddev_sampFields';
  telegramId?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "users" */
export type UsersStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: UsersStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type UsersStreamCursorValueInput = {
  avatar?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  deletedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  erc20Address?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  lastseenAt?: InputMaybe<Scalars['timestamptz']['input']>;
  metadata?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  provider?: InputMaybe<Scalars['String']['input']>;
  signature?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<StatusesEnum>;
  telegramId?: InputMaybe<Scalars['Int']['input']>;
  twofa?: InputMaybe<Scalars['Boolean']['input']>;
  twofaBackup?: InputMaybe<Scalars['String']['input']>;
  twofaSecret?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
  verificationMail?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type UsersSumFields = {
  __typename?: 'UsersSumFields';
  telegramId?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "users" */
export enum UsersUpdateColumn {
  /** column name */
  Avatar = 'avatar',
  /** column name */
  Country = 'country',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  Email = 'email',
  /** column name */
  Erc20Address = 'erc20Address',
  /** column name */
  Id = 'id',
  /** column name */
  Language = 'language',
  /** column name */
  LastseenAt = 'lastseenAt',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Name = 'name',
  /** column name */
  Provider = 'provider',
  /** column name */
  Signature = 'signature',
  /** column name */
  Status = 'status',
  /** column name */
  TelegramId = 'telegramId',
  /** column name */
  Twofa = 'twofa',
  /** column name */
  TwofaBackup = 'twofaBackup',
  /** column name */
  TwofaSecret = 'twofaSecret',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Username = 'username',
  /** column name */
  VerificationMail = 'verificationMail',
}

export type UsersUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<UsersIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<UsersSetInput>;
  /** filter the rows which have to be updated */
  where: UsersBoolExp;
};

/** aggregate var_pop on columns */
export type UsersVar_PopFields = {
  __typename?: 'UsersVar_popFields';
  telegramId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type UsersVar_SampFields = {
  __typename?: 'UsersVar_sampFields';
  telegramId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type UsersVarianceFields = {
  __typename?: 'UsersVarianceFields';
  telegramId?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type UuidComparisonExp = {
  _eq?: InputMaybe<Scalars['uuid']['input']>;
  _gt?: InputMaybe<Scalars['uuid']['input']>;
  _gte?: InputMaybe<Scalars['uuid']['input']>;
  _in?: InputMaybe<Array<Scalars['uuid']['input']>>;
  _isNull?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['uuid']['input']>;
  _lte?: InputMaybe<Scalars['uuid']['input']>;
  _neq?: InputMaybe<Scalars['uuid']['input']>;
  _nin?: InputMaybe<Array<Scalars['uuid']['input']>>;
};

/** columns and relationships of "venue_users" */
export type VenueUsers = {
  __typename?: 'VenueUsers';
  createdAt: Scalars['timestamptz']['output'];
  id: Scalars['bigint']['output'];
  updatedAt: Scalars['timestamptz']['output'];
  /** An object relationship */
  user: Users;
  userId: Scalars['String']['output'];
  /** An object relationship */
  venue: Venues;
  venueId: Scalars['uuid']['output'];
};

/** aggregated selection of "venue_users" */
export type VenueUsersAggregate = {
  __typename?: 'VenueUsersAggregate';
  aggregate?: Maybe<VenueUsersAggregateFields>;
  nodes: Array<VenueUsers>;
};

/** aggregate fields of "venue_users" */
export type VenueUsersAggregateFields = {
  __typename?: 'VenueUsersAggregateFields';
  avg?: Maybe<VenueUsersAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<VenueUsersMaxFields>;
  min?: Maybe<VenueUsersMinFields>;
  stddev?: Maybe<VenueUsersStddevFields>;
  stddevPop?: Maybe<VenueUsersStddev_PopFields>;
  stddevSamp?: Maybe<VenueUsersStddev_SampFields>;
  sum?: Maybe<VenueUsersSumFields>;
  varPop?: Maybe<VenueUsersVar_PopFields>;
  varSamp?: Maybe<VenueUsersVar_SampFields>;
  variance?: Maybe<VenueUsersVarianceFields>;
};

/** aggregate fields of "venue_users" */
export type VenueUsersAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<VenueUsersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "venue_users" */
export type VenueUsersAggregateOrderBy = {
  avg?: InputMaybe<Venue_Users_Avg_Order_By>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<Venue_Users_Max_Order_By>;
  min?: InputMaybe<Venue_Users_Min_Order_By>;
  stddev?: InputMaybe<Venue_Users_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Venue_Users_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Venue_Users_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Venue_Users_Sum_Order_By>;
  var_pop?: InputMaybe<Venue_Users_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Venue_Users_Var_Samp_Order_By>;
  variance?: InputMaybe<Venue_Users_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "venue_users" */
export type VenueUsersArrRelInsertInput = {
  data: Array<VenueUsersInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<VenueUsersOnConflict>;
};

/** aggregate avg on columns */
export type VenueUsersAvgFields = {
  __typename?: 'VenueUsersAvgFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "venue_users". All fields are combined with a logical 'AND'. */
export type VenueUsersBoolExp = {
  _and?: InputMaybe<Array<VenueUsersBoolExp>>;
  _not?: InputMaybe<VenueUsersBoolExp>;
  _or?: InputMaybe<Array<VenueUsersBoolExp>>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  user?: InputMaybe<UsersBoolExp>;
  userId?: InputMaybe<StringComparisonExp>;
  venue?: InputMaybe<VenuesBoolExp>;
  venueId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "venue_users" */
export enum VenueUsersConstraint {
  /** unique or primary key constraint on columns "id" */
  VenueUsersPkey = 'venue_users_pkey',
}

/** input type for incrementing numeric columns in table "venue_users" */
export type VenueUsersIncInput = {
  id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "venue_users" */
export type VenueUsersInsertInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<UsersObjRelInsertInput>;
  userId?: InputMaybe<Scalars['String']['input']>;
  venue?: InputMaybe<VenuesObjRelInsertInput>;
  venueId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type VenueUsersMaxFields = {
  __typename?: 'VenueUsersMaxFields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
  venueId?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type VenueUsersMinFields = {
  __typename?: 'VenueUsersMinFields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
  venueId?: Maybe<Scalars['uuid']['output']>;
};

/** response of any mutation on the table "venue_users" */
export type VenueUsersMutationResponse = {
  __typename?: 'VenueUsersMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<VenueUsers>;
};

/** on_conflict condition type for table "venue_users" */
export type VenueUsersOnConflict = {
  constraint: VenueUsersConstraint;
  update_columns?: Array<VenueUsersUpdateColumn>;
  where?: InputMaybe<VenueUsersBoolExp>;
};

/** Ordering options when selecting data from "venue_users". */
export type VenueUsersOrderBy = {
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  user?: InputMaybe<UsersOrderBy>;
  userId?: InputMaybe<OrderBy>;
  venue?: InputMaybe<VenuesOrderBy>;
  venueId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: venue_users */
export type VenueUsersPkColumnsInput = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "venue_users" */
export enum VenueUsersSelectColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId',
  /** column name */
  VenueId = 'venueId',
}

/** input type for updating data in table "venue_users" */
export type VenueUsersSetInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
  venueId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate stddev on columns */
export type VenueUsersStddevFields = {
  __typename?: 'VenueUsersStddevFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type VenueUsersStddev_PopFields = {
  __typename?: 'VenueUsersStddev_popFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type VenueUsersStddev_SampFields = {
  __typename?: 'VenueUsersStddev_sampFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "venue_users" */
export type VenueUsersStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: VenueUsersStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type VenueUsersStreamCursorValueInput = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
  venueId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate sum on columns */
export type VenueUsersSumFields = {
  __typename?: 'VenueUsersSumFields';
  id?: Maybe<Scalars['bigint']['output']>;
};

/** update columns of table "venue_users" */
export enum VenueUsersUpdateColumn {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId',
  /** column name */
  VenueId = 'venueId',
}

export type VenueUsersUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<VenueUsersIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<VenueUsersSetInput>;
  /** filter the rows which have to be updated */
  where: VenueUsersBoolExp;
};

/** aggregate var_pop on columns */
export type VenueUsersVar_PopFields = {
  __typename?: 'VenueUsersVar_popFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type VenueUsersVar_SampFields = {
  __typename?: 'VenueUsersVar_sampFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type VenueUsersVarianceFields = {
  __typename?: 'VenueUsersVarianceFields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "venues" */
export type Venues = {
  __typename?: 'Venues';
  address: Scalars['String']['output'];
  city: Scalars['String']['output'];
  country: Scalars['String']['output'];
  createdAt: Scalars['timestamptz']['output'];
  description?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  events: Array<Events>;
  /** An aggregate relationship */
  eventsAggregate: EventsAggregate;
  facebook?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  followers: Array<Followers>;
  /** An aggregate relationship */
  followersAggregate: FollowersAggregate;
  followersCount: Scalars['Int']['output'];
  googleMapsUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['uuid']['output'];
  image?: Maybe<Scalars['String']['output']>;
  instagram?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  slug?: Maybe<Scalars['String']['output']>;
  statsGoogleDrive?: Maybe<Scalars['String']['output']>;
  status: StatusesEnum;
  /** An array relationship */
  tournaments: Array<Tournaments>;
  /** An aggregate relationship */
  tournamentsAggregate: TournamentsAggregate;
  updatedAt: Scalars['timestamptz']['output'];
  /** An aggregate relationship */
  venueUsersAggregate: VenueUsersAggregate;
  /** An array relationship */
  venue_users: Array<VenueUsers>;
  website?: Maybe<Scalars['String']['output']>;
};

/** columns and relationships of "venues" */
export type VenuesEventsArgs = {
  distinctOn?: InputMaybe<Array<EventsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EventsOrderBy>>;
  where?: InputMaybe<EventsBoolExp>;
};

/** columns and relationships of "venues" */
export type VenuesEventsAggregateArgs = {
  distinctOn?: InputMaybe<Array<EventsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EventsOrderBy>>;
  where?: InputMaybe<EventsBoolExp>;
};

/** columns and relationships of "venues" */
export type VenuesFollowersArgs = {
  distinctOn?: InputMaybe<Array<FollowersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FollowersOrderBy>>;
  where?: InputMaybe<FollowersBoolExp>;
};

/** columns and relationships of "venues" */
export type VenuesFollowersAggregateArgs = {
  distinctOn?: InputMaybe<Array<FollowersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FollowersOrderBy>>;
  where?: InputMaybe<FollowersBoolExp>;
};

/** columns and relationships of "venues" */
export type VenuesTournamentsArgs = {
  distinctOn?: InputMaybe<Array<TournamentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TournamentsOrderBy>>;
  where?: InputMaybe<TournamentsBoolExp>;
};

/** columns and relationships of "venues" */
export type VenuesTournamentsAggregateArgs = {
  distinctOn?: InputMaybe<Array<TournamentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TournamentsOrderBy>>;
  where?: InputMaybe<TournamentsBoolExp>;
};

/** columns and relationships of "venues" */
export type VenuesVenueUsersAggregateArgs = {
  distinctOn?: InputMaybe<Array<VenueUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<VenueUsersOrderBy>>;
  where?: InputMaybe<VenueUsersBoolExp>;
};

/** columns and relationships of "venues" */
export type VenuesVenue_UsersArgs = {
  distinctOn?: InputMaybe<Array<VenueUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<VenueUsersOrderBy>>;
  where?: InputMaybe<VenueUsersBoolExp>;
};

/** aggregated selection of "venues" */
export type VenuesAggregate = {
  __typename?: 'VenuesAggregate';
  aggregate?: Maybe<VenuesAggregateFields>;
  nodes: Array<Venues>;
};

/** aggregate fields of "venues" */
export type VenuesAggregateFields = {
  __typename?: 'VenuesAggregateFields';
  avg?: Maybe<VenuesAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<VenuesMaxFields>;
  min?: Maybe<VenuesMinFields>;
  stddev?: Maybe<VenuesStddevFields>;
  stddevPop?: Maybe<VenuesStddev_PopFields>;
  stddevSamp?: Maybe<VenuesStddev_SampFields>;
  sum?: Maybe<VenuesSumFields>;
  varPop?: Maybe<VenuesVar_PopFields>;
  varSamp?: Maybe<VenuesVar_SampFields>;
  variance?: Maybe<VenuesVarianceFields>;
};

/** aggregate fields of "venues" */
export type VenuesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<VenuesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type VenuesAvgFields = {
  __typename?: 'VenuesAvgFields';
  followersCount?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "venues". All fields are combined with a logical 'AND'. */
export type VenuesBoolExp = {
  _and?: InputMaybe<Array<VenuesBoolExp>>;
  _not?: InputMaybe<VenuesBoolExp>;
  _or?: InputMaybe<Array<VenuesBoolExp>>;
  address?: InputMaybe<StringComparisonExp>;
  city?: InputMaybe<StringComparisonExp>;
  country?: InputMaybe<StringComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  description?: InputMaybe<StringComparisonExp>;
  events?: InputMaybe<EventsBoolExp>;
  events_aggregate?: InputMaybe<Events_Aggregate_Bool_Exp>;
  facebook?: InputMaybe<StringComparisonExp>;
  followers?: InputMaybe<FollowersBoolExp>;
  followersCount?: InputMaybe<IntComparisonExp>;
  followers_aggregate?: InputMaybe<Followers_Aggregate_Bool_Exp>;
  googleMapsUrl?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  image?: InputMaybe<StringComparisonExp>;
  instagram?: InputMaybe<StringComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  slug?: InputMaybe<StringComparisonExp>;
  statsGoogleDrive?: InputMaybe<StringComparisonExp>;
  status?: InputMaybe<StatusesEnumComparisonExp>;
  tournaments?: InputMaybe<TournamentsBoolExp>;
  tournaments_aggregate?: InputMaybe<Tournaments_Aggregate_Bool_Exp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
  venue_users?: InputMaybe<VenueUsersBoolExp>;
  venue_users_aggregate?: InputMaybe<Venue_Users_Aggregate_Bool_Exp>;
  website?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "venues" */
export enum VenuesConstraint {
  /** unique or primary key constraint on columns "id" */
  VenuesPkey = 'venues_pkey',
}

/** input type for incrementing numeric columns in table "venues" */
export type VenuesIncInput = {
  followersCount?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "venues" */
export type VenuesInsertInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  events?: InputMaybe<EventsArrRelInsertInput>;
  facebook?: InputMaybe<Scalars['String']['input']>;
  followers?: InputMaybe<FollowersArrRelInsertInput>;
  followersCount?: InputMaybe<Scalars['Int']['input']>;
  googleMapsUrl?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  instagram?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  statsGoogleDrive?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<StatusesEnum>;
  tournaments?: InputMaybe<TournamentsArrRelInsertInput>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  venue_users?: InputMaybe<VenueUsersArrRelInsertInput>;
  website?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type VenuesMaxFields = {
  __typename?: 'VenuesMaxFields';
  address?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  facebook?: Maybe<Scalars['String']['output']>;
  followersCount?: Maybe<Scalars['Int']['output']>;
  googleMapsUrl?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  image?: Maybe<Scalars['String']['output']>;
  instagram?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  statsGoogleDrive?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  website?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type VenuesMinFields = {
  __typename?: 'VenuesMinFields';
  address?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  facebook?: Maybe<Scalars['String']['output']>;
  followersCount?: Maybe<Scalars['Int']['output']>;
  googleMapsUrl?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  image?: Maybe<Scalars['String']['output']>;
  instagram?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  statsGoogleDrive?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  website?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "venues" */
export type VenuesMutationResponse = {
  __typename?: 'VenuesMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Venues>;
};

/** input type for inserting object relation for remote table "venues" */
export type VenuesObjRelInsertInput = {
  data: VenuesInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<VenuesOnConflict>;
};

/** on_conflict condition type for table "venues" */
export type VenuesOnConflict = {
  constraint: VenuesConstraint;
  update_columns?: Array<VenuesUpdateColumn>;
  where?: InputMaybe<VenuesBoolExp>;
};

/** Ordering options when selecting data from "venues". */
export type VenuesOrderBy = {
  address?: InputMaybe<OrderBy>;
  city?: InputMaybe<OrderBy>;
  country?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  eventsAggregate?: InputMaybe<EventsAggregateOrderBy>;
  facebook?: InputMaybe<OrderBy>;
  followersAggregate?: InputMaybe<FollowersAggregateOrderBy>;
  followersCount?: InputMaybe<OrderBy>;
  googleMapsUrl?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  image?: InputMaybe<OrderBy>;
  instagram?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  slug?: InputMaybe<OrderBy>;
  statsGoogleDrive?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  tournamentsAggregate?: InputMaybe<TournamentsAggregateOrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  venue_usersAggregate?: InputMaybe<VenueUsersAggregateOrderBy>;
  website?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: venues */
export type VenuesPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "venues" */
export enum VenuesSelectColumn {
  /** column name */
  Address = 'address',
  /** column name */
  City = 'city',
  /** column name */
  Country = 'country',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Facebook = 'facebook',
  /** column name */
  FollowersCount = 'followersCount',
  /** column name */
  GoogleMapsUrl = 'googleMapsUrl',
  /** column name */
  Id = 'id',
  /** column name */
  Image = 'image',
  /** column name */
  Instagram = 'instagram',
  /** column name */
  Name = 'name',
  /** column name */
  Slug = 'slug',
  /** column name */
  StatsGoogleDrive = 'statsGoogleDrive',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Website = 'website',
}

/** input type for updating data in table "venues" */
export type VenuesSetInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  facebook?: InputMaybe<Scalars['String']['input']>;
  followersCount?: InputMaybe<Scalars['Int']['input']>;
  googleMapsUrl?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  instagram?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  statsGoogleDrive?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<StatusesEnum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type VenuesStddevFields = {
  __typename?: 'VenuesStddevFields';
  followersCount?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type VenuesStddev_PopFields = {
  __typename?: 'VenuesStddev_popFields';
  followersCount?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type VenuesStddev_SampFields = {
  __typename?: 'VenuesStddev_sampFields';
  followersCount?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "venues" */
export type VenuesStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: VenuesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type VenuesStreamCursorValueInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  facebook?: InputMaybe<Scalars['String']['input']>;
  followersCount?: InputMaybe<Scalars['Int']['input']>;
  googleMapsUrl?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  instagram?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  statsGoogleDrive?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<StatusesEnum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type VenuesSumFields = {
  __typename?: 'VenuesSumFields';
  followersCount?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "venues" */
export enum VenuesUpdateColumn {
  /** column name */
  Address = 'address',
  /** column name */
  City = 'city',
  /** column name */
  Country = 'country',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Facebook = 'facebook',
  /** column name */
  FollowersCount = 'followersCount',
  /** column name */
  GoogleMapsUrl = 'googleMapsUrl',
  /** column name */
  Id = 'id',
  /** column name */
  Image = 'image',
  /** column name */
  Instagram = 'instagram',
  /** column name */
  Name = 'name',
  /** column name */
  Slug = 'slug',
  /** column name */
  StatsGoogleDrive = 'statsGoogleDrive',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Website = 'website',
}

export type VenuesUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<VenuesIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<VenuesSetInput>;
  /** filter the rows which have to be updated */
  where: VenuesBoolExp;
};

/** aggregate var_pop on columns */
export type VenuesVar_PopFields = {
  __typename?: 'VenuesVar_popFields';
  followersCount?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type VenuesVar_SampFields = {
  __typename?: 'VenuesVar_sampFields';
  followersCount?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type VenuesVarianceFields = {
  __typename?: 'VenuesVarianceFields';
  followersCount?: Maybe<Scalars['Float']['output']>;
};

export type Activate2FaOutput = {
  __typename?: 'activate2FAOutput';
  backupCode: Scalars['String']['output'];
  code: Scalars['String']['output'];
  codeImage: Scalars['String']['output'];
  codeUrl: Scalars['String']['output'];
  message: Scalars['String']['output'];
  status: GeneralStatus;
};

export type AuthLoginOutput = {
  __typename?: 'authLoginOutput';
  accesstoken?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  expiresIn?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type AuthSignatureOutput = {
  __typename?: 'authSignatureOutput';
  message: Scalars['String']['output'];
  nonce: Scalars['String']['output'];
  status: GeneralStatus;
};

export type Blockchain_Currencies_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Blockchain_Currencies_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Blockchain_Currencies_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Blockchain_Currencies_Aggregate_Bool_Exp_Count>;
};

export type Blockchain_Currencies_Aggregate_Bool_Exp_Bool_And = {
  arguments: Blockchain_Currencies_Select_Column_Blockchain_Currencies_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<BlockchainCurrenciesBoolExp>;
  predicate: BooleanComparisonExp;
};

export type Blockchain_Currencies_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Blockchain_Currencies_Select_Column_Blockchain_Currencies_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<BlockchainCurrenciesBoolExp>;
  predicate: BooleanComparisonExp;
};

export type Blockchain_Currencies_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<BlockchainCurrenciesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<BlockchainCurrenciesBoolExp>;
  predicate: IntComparisonExp;
};

/** order by avg() on columns of table "blockchain_currencies" */
export type Blockchain_Currencies_Avg_Order_By = {
  blockchainId?: InputMaybe<OrderBy>;
  currencyId?: InputMaybe<OrderBy>;
  decimals?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
  withdrawFee?: InputMaybe<OrderBy>;
  withdrawMin?: InputMaybe<OrderBy>;
};

/** order by max() on columns of table "blockchain_currencies" */
export type Blockchain_Currencies_Max_Order_By = {
  blockchainId?: InputMaybe<OrderBy>;
  contract?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  currencyId?: InputMaybe<OrderBy>;
  decimals?: InputMaybe<OrderBy>;
  icon?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  symbol?: InputMaybe<OrderBy>;
  units?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
  withdrawFee?: InputMaybe<OrderBy>;
  withdrawMin?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "blockchain_currencies" */
export type Blockchain_Currencies_Min_Order_By = {
  blockchainId?: InputMaybe<OrderBy>;
  contract?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  currencyId?: InputMaybe<OrderBy>;
  decimals?: InputMaybe<OrderBy>;
  icon?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  symbol?: InputMaybe<OrderBy>;
  units?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
  withdrawFee?: InputMaybe<OrderBy>;
  withdrawMin?: InputMaybe<OrderBy>;
};

/** select "blockchain_currencies_aggregate_bool_exp_bool_and_arguments_columns" columns of table "blockchain_currencies" */
export enum Blockchain_Currencies_Select_Column_Blockchain_Currencies_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Deposit = 'deposit',
  /** column name */
  Withdraw = 'withdraw',
}

/** select "blockchain_currencies_aggregate_bool_exp_bool_or_arguments_columns" columns of table "blockchain_currencies" */
export enum Blockchain_Currencies_Select_Column_Blockchain_Currencies_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Deposit = 'deposit',
  /** column name */
  Withdraw = 'withdraw',
}

/** order by stddev() on columns of table "blockchain_currencies" */
export type Blockchain_Currencies_Stddev_Order_By = {
  blockchainId?: InputMaybe<OrderBy>;
  currencyId?: InputMaybe<OrderBy>;
  decimals?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
  withdrawFee?: InputMaybe<OrderBy>;
  withdrawMin?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "blockchain_currencies" */
export type Blockchain_Currencies_Stddev_Pop_Order_By = {
  blockchainId?: InputMaybe<OrderBy>;
  currencyId?: InputMaybe<OrderBy>;
  decimals?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
  withdrawFee?: InputMaybe<OrderBy>;
  withdrawMin?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "blockchain_currencies" */
export type Blockchain_Currencies_Stddev_Samp_Order_By = {
  blockchainId?: InputMaybe<OrderBy>;
  currencyId?: InputMaybe<OrderBy>;
  decimals?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
  withdrawFee?: InputMaybe<OrderBy>;
  withdrawMin?: InputMaybe<OrderBy>;
};

/** order by sum() on columns of table "blockchain_currencies" */
export type Blockchain_Currencies_Sum_Order_By = {
  blockchainId?: InputMaybe<OrderBy>;
  currencyId?: InputMaybe<OrderBy>;
  decimals?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
  withdrawFee?: InputMaybe<OrderBy>;
  withdrawMin?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "blockchain_currencies" */
export type Blockchain_Currencies_Var_Pop_Order_By = {
  blockchainId?: InputMaybe<OrderBy>;
  currencyId?: InputMaybe<OrderBy>;
  decimals?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
  withdrawFee?: InputMaybe<OrderBy>;
  withdrawMin?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "blockchain_currencies" */
export type Blockchain_Currencies_Var_Samp_Order_By = {
  blockchainId?: InputMaybe<OrderBy>;
  currencyId?: InputMaybe<OrderBy>;
  decimals?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
  withdrawFee?: InputMaybe<OrderBy>;
  withdrawMin?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "blockchain_currencies" */
export type Blockchain_Currencies_Variance_Order_By = {
  blockchainId?: InputMaybe<OrderBy>;
  currencyId?: InputMaybe<OrderBy>;
  decimals?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  value?: InputMaybe<OrderBy>;
  withdrawFee?: InputMaybe<OrderBy>;
  withdrawMin?: InputMaybe<OrderBy>;
};

export type Currencies_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Currencies_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Currencies_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Currencies_Aggregate_Bool_Exp_Count>;
};

export type Currencies_Aggregate_Bool_Exp_Bool_And = {
  arguments: Currencies_Select_Column_Currencies_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<CurrenciesBoolExp>;
  predicate: BooleanComparisonExp;
};

export type Currencies_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Currencies_Select_Column_Currencies_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<CurrenciesBoolExp>;
  predicate: BooleanComparisonExp;
};

export type Currencies_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<CurrenciesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<CurrenciesBoolExp>;
  predicate: IntComparisonExp;
};

/** order by avg() on columns of table "currencies" */
export type Currencies_Avg_Order_By = {
  countryId?: InputMaybe<OrderBy>;
  decimals?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  rate?: InputMaybe<OrderBy>;
  rounding?: InputMaybe<OrderBy>;
};

/** order by max() on columns of table "currencies" */
export type Currencies_Max_Order_By = {
  countryId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  decimals?: InputMaybe<OrderBy>;
  icon?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  rate?: InputMaybe<OrderBy>;
  rounding?: InputMaybe<OrderBy>;
  symbol?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "currencies" */
export type Currencies_Min_Order_By = {
  countryId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  decimals?: InputMaybe<OrderBy>;
  icon?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  rate?: InputMaybe<OrderBy>;
  rounding?: InputMaybe<OrderBy>;
  symbol?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** select "currencies_aggregate_bool_exp_bool_and_arguments_columns" columns of table "currencies" */
export enum Currencies_Select_Column_Currencies_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Active = 'active',
}

/** select "currencies_aggregate_bool_exp_bool_or_arguments_columns" columns of table "currencies" */
export enum Currencies_Select_Column_Currencies_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Active = 'active',
}

/** order by stddev() on columns of table "currencies" */
export type Currencies_Stddev_Order_By = {
  countryId?: InputMaybe<OrderBy>;
  decimals?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  rate?: InputMaybe<OrderBy>;
  rounding?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "currencies" */
export type Currencies_Stddev_Pop_Order_By = {
  countryId?: InputMaybe<OrderBy>;
  decimals?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  rate?: InputMaybe<OrderBy>;
  rounding?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "currencies" */
export type Currencies_Stddev_Samp_Order_By = {
  countryId?: InputMaybe<OrderBy>;
  decimals?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  rate?: InputMaybe<OrderBy>;
  rounding?: InputMaybe<OrderBy>;
};

/** order by sum() on columns of table "currencies" */
export type Currencies_Sum_Order_By = {
  countryId?: InputMaybe<OrderBy>;
  decimals?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  rate?: InputMaybe<OrderBy>;
  rounding?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "currencies" */
export type Currencies_Var_Pop_Order_By = {
  countryId?: InputMaybe<OrderBy>;
  decimals?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  rate?: InputMaybe<OrderBy>;
  rounding?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "currencies" */
export type Currencies_Var_Samp_Order_By = {
  countryId?: InputMaybe<OrderBy>;
  decimals?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  rate?: InputMaybe<OrderBy>;
  rounding?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "currencies" */
export type Currencies_Variance_Order_By = {
  countryId?: InputMaybe<OrderBy>;
  decimals?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  rate?: InputMaybe<OrderBy>;
  rounding?: InputMaybe<OrderBy>;
};

export type DepositAddressOutput = {
  __typename?: 'depositAddressOutput';
  address: Scalars['String']['output'];
  message?: Maybe<Scalars['String']['output']>;
};

export type DepositBankOutput = {
  __typename?: 'depositBankOutput';
  depositDetails: Scalars['String']['output'];
  depositReference: Scalars['String']['output'];
  id?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

export type DownloadFileOutput = {
  __typename?: 'downloadFileOutput';
  base64?: Maybe<Scalars['String']['output']>;
  filename?: Maybe<Scalars['String']['output']>;
  format: Scalars['String']['output'];
  status: GeneralStatus;
};

export type Events_Aggregate_Bool_Exp = {
  count?: InputMaybe<Events_Aggregate_Bool_Exp_Count>;
};

export type Events_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<EventsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<EventsBoolExp>;
  predicate: IntComparisonExp;
};

/** order by avg() on columns of table "events" */
export type Events_Avg_Order_By = {
  followersCount?: InputMaybe<OrderBy>;
  sort?: InputMaybe<OrderBy>;
};

/** order by max() on columns of table "events" */
export type Events_Max_Order_By = {
  createdAt?: InputMaybe<OrderBy>;
  dateFrom?: InputMaybe<OrderBy>;
  dateTill?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  followersCount?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  image?: InputMaybe<OrderBy>;
  location?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  organiserId?: InputMaybe<OrderBy>;
  slug?: InputMaybe<OrderBy>;
  sort?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  venueId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "events" */
export type Events_Min_Order_By = {
  createdAt?: InputMaybe<OrderBy>;
  dateFrom?: InputMaybe<OrderBy>;
  dateTill?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  followersCount?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  image?: InputMaybe<OrderBy>;
  location?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  organiserId?: InputMaybe<OrderBy>;
  slug?: InputMaybe<OrderBy>;
  sort?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  venueId?: InputMaybe<OrderBy>;
};

/** order by stddev() on columns of table "events" */
export type Events_Stddev_Order_By = {
  followersCount?: InputMaybe<OrderBy>;
  sort?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "events" */
export type Events_Stddev_Pop_Order_By = {
  followersCount?: InputMaybe<OrderBy>;
  sort?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "events" */
export type Events_Stddev_Samp_Order_By = {
  followersCount?: InputMaybe<OrderBy>;
  sort?: InputMaybe<OrderBy>;
};

/** order by sum() on columns of table "events" */
export type Events_Sum_Order_By = {
  followersCount?: InputMaybe<OrderBy>;
  sort?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "events" */
export type Events_Var_Pop_Order_By = {
  followersCount?: InputMaybe<OrderBy>;
  sort?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "events" */
export type Events_Var_Samp_Order_By = {
  followersCount?: InputMaybe<OrderBy>;
  sort?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "events" */
export type Events_Variance_Order_By = {
  followersCount?: InputMaybe<OrderBy>;
  sort?: InputMaybe<OrderBy>;
};

export type Followers_Aggregate_Bool_Exp = {
  count?: InputMaybe<Followers_Aggregate_Bool_Exp_Count>;
};

export type Followers_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<FollowersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<FollowersBoolExp>;
  predicate: IntComparisonExp;
};

/** order by avg() on columns of table "followers" */
export type Followers_Avg_Order_By = {
  id?: InputMaybe<OrderBy>;
};

/** order by max() on columns of table "followers" */
export type Followers_Max_Order_By = {
  createdAt?: InputMaybe<OrderBy>;
  eventId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  organiserId?: InputMaybe<OrderBy>;
  playerId?: InputMaybe<OrderBy>;
  tournamentId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
  venueId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "followers" */
export type Followers_Min_Order_By = {
  createdAt?: InputMaybe<OrderBy>;
  eventId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  organiserId?: InputMaybe<OrderBy>;
  playerId?: InputMaybe<OrderBy>;
  tournamentId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
  venueId?: InputMaybe<OrderBy>;
};

/** order by stddev() on columns of table "followers" */
export type Followers_Stddev_Order_By = {
  id?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "followers" */
export type Followers_Stddev_Pop_Order_By = {
  id?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "followers" */
export type Followers_Stddev_Samp_Order_By = {
  id?: InputMaybe<OrderBy>;
};

/** order by sum() on columns of table "followers" */
export type Followers_Sum_Order_By = {
  id?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "followers" */
export type Followers_Var_Pop_Order_By = {
  id?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "followers" */
export type Followers_Var_Samp_Order_By = {
  id?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "followers" */
export type Followers_Variance_Order_By = {
  id?: InputMaybe<OrderBy>;
};

export enum GenderChoice {
  Man = 'man',
  Unknown = 'unknown',
  Woman = 'woman',
}

export type GeneralOutput = {
  __typename?: 'generalOutput';
  id?: Maybe<Scalars['String']['output']>;
  message: Scalars['String']['output'];
  status?: Maybe<GeneralStatus>;
};

export enum GeneralStatus {
  Error = 'error',
  Ok = 'ok',
}

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  activate2FA1?: Maybe<Activate2FaOutput>;
  activate2FA2?: Maybe<GeneralOutput>;
  authLogin?: Maybe<AuthLoginOutput>;
  authRefresh?: Maybe<AuthLoginOutput>;
  authSignature?: Maybe<AuthSignatureOutput>;
  buyTicket?: Maybe<GeneralOutput>;
  cancelTicket?: Maybe<GeneralOutput>;
  cancelTournament?: Maybe<GeneralOutput>;
  deactivate2FA?: Maybe<GeneralOutput>;
  /** delete data from the table: "blockchain_currencies" */
  deleteBlockchainCurrencies?: Maybe<BlockchainCurrenciesMutationResponse>;
  /** delete single row from the table: "blockchain_currencies" */
  deleteBlockchainCurrenciesByPk?: Maybe<BlockchainCurrencies>;
  /** delete data from the table: "blockchains" */
  deleteBlockchains?: Maybe<BlockchainsMutationResponse>;
  /** delete single row from the table: "blockchains" */
  deleteBlockchainsByPk?: Maybe<Blockchains>;
  /** delete data from the table: "countries" */
  deleteCountries?: Maybe<CountriesMutationResponse>;
  /** delete single row from the table: "countries" */
  deleteCountriesByPk?: Maybe<Countries>;
  /** delete data from the table: "currencies" */
  deleteCurrencies?: Maybe<CurrenciesMutationResponse>;
  /** delete single row from the table: "currencies" */
  deleteCurrenciesByPk?: Maybe<Currencies>;
  /** delete data from the table: "events" */
  deleteEvents?: Maybe<EventsMutationResponse>;
  /** delete single row from the table: "events" */
  deleteEventsByPk?: Maybe<Events>;
  /** delete single row from the table: "followers" */
  deleteFollower?: Maybe<Followers>;
  /** delete data from the table: "followers" */
  deleteFollowers?: Maybe<FollowersMutationResponse>;
  /** delete data from the table: "message_types" */
  deleteMessageTypes?: Maybe<MessageTypesMutationResponse>;
  /** delete single row from the table: "message_types" */
  deleteMessageTypesByPk?: Maybe<MessageTypes>;
  /** delete data from the table: "messages" */
  deleteMessages?: Maybe<MessagesMutationResponse>;
  /** delete single row from the table: "messages" */
  deleteMessagesByPk?: Maybe<Messages>;
  /** delete data from the table: "organiser_users" */
  deleteOrganiserUsers?: Maybe<OrganiserUsersMutationResponse>;
  /** delete single row from the table: "organiser_users" */
  deleteOrganiserUsersByPk?: Maybe<OrganiserUsers>;
  /** delete data from the table: "organisers" */
  deleteOrganisers?: Maybe<OrganisersMutationResponse>;
  /** delete single row from the table: "organisers" */
  deleteOrganisersByPk?: Maybe<Organisers>;
  /** delete data from the table: "payment_method_banks" */
  deletePaymentMethodBanks?: Maybe<PaymentMethodBanksMutationResponse>;
  /** delete single row from the table: "payment_method_banks" */
  deletePaymentMethodBanksByPk?: Maybe<PaymentMethodBanks>;
  /** delete data from the table: "payment_methods" */
  deletePaymentMethods?: Maybe<PaymentMethodsMutationResponse>;
  /** delete single row from the table: "payment_methods" */
  deletePaymentMethodsByPk?: Maybe<PaymentMethods>;
  /** delete data from the table: "payments" */
  deletePayments?: Maybe<PaymentsMutationResponse>;
  /** delete single row from the table: "payments" */
  deletePaymentsByPk?: Maybe<Payments>;
  /** delete data from the table: "pending_txs" */
  deletePendingTxs?: Maybe<PendingTxsMutationResponse>;
  /** delete single row from the table: "pending_txs" */
  deletePendingTxsByPk?: Maybe<PendingTxs>;
  /** delete data from the table: "player_followers" */
  deletePlayerFollowers?: Maybe<PlayerFollowersMutationResponse>;
  /** delete single row from the table: "player_shares" */
  deletePlayerShare?: Maybe<PlayerShares>;
  /** delete data from the table: "player_shares" */
  deletePlayerShares?: Maybe<PlayerSharesMutationResponse>;
  /** delete data from the table: "players" */
  deletePlayers?: Maybe<PlayersMutationResponse>;
  /** delete single row from the table: "players" */
  deletePlayersByPk?: Maybe<Players>;
  /** delete data from the table: "stat_events" */
  deleteStatEvents?: Maybe<StatEventsMutationResponse>;
  /** delete single row from the table: "stat_events" */
  deleteStatEventsByPk?: Maybe<StatEvents>;
  /** delete data from the table: "stat_players" */
  deleteStatPlayers?: Maybe<StatPlayersMutationResponse>;
  /** delete single row from the table: "stat_players" */
  deleteStatPlayersByPk?: Maybe<StatPlayers>;
  /** delete data from the table: "stat_tournament_results" */
  deleteStatTournamentResults?: Maybe<StatTournamentResultsMutationResponse>;
  /** delete single row from the table: "stat_tournament_results" */
  deleteStatTournamentResultsByPk?: Maybe<StatTournamentResults>;
  /** delete data from the table: "stat_tournaments" */
  deleteStatTournaments?: Maybe<StatTournamentsMutationResponse>;
  /** delete single row from the table: "stat_tournaments" */
  deleteStatTournamentsByPk?: Maybe<StatTournaments>;
  /** delete data from the table: "status_games" */
  deleteStatusGames?: Maybe<StatusGamesMutationResponse>;
  /** delete single row from the table: "status_games" */
  deleteStatusGamesByPk?: Maybe<StatusGames>;
  /** delete data from the table: "statuses" */
  deleteStatuses?: Maybe<StatusesMutationResponse>;
  /** delete single row from the table: "statuses" */
  deleteStatusesByPk?: Maybe<Statuses>;
  /** delete data from the table: "tickets" */
  deleteTickets?: Maybe<TicketsMutationResponse>;
  /** delete single row from the table: "tickets" */
  deleteTicketsByPk?: Maybe<Tickets>;
  /** delete data from the table: "tickets_status" */
  deleteTicketsStatus?: Maybe<TicketsStatusMutationResponse>;
  /** delete single row from the table: "tickets_status" */
  deleteTicketsStatusByPk?: Maybe<TicketsStatus>;
  /** delete data from the table: "tournament_result_statuses" */
  deleteTournamentResultStatuses?: Maybe<TournamentResultStatusesMutationResponse>;
  /** delete single row from the table: "tournament_result_statuses" */
  deleteTournamentResultStatusesByPk?: Maybe<TournamentResultStatuses>;
  /** delete data from the table: "tournament_results" */
  deleteTournamentResults?: Maybe<TournamentResultsMutationResponse>;
  /** delete single row from the table: "tournament_results" */
  deleteTournamentResultsByPk?: Maybe<TournamentResults>;
  /** delete data from the table: "tournaments" */
  deleteTournaments?: Maybe<TournamentsMutationResponse>;
  /** delete single row from the table: "tournaments" */
  deleteTournamentsByPk?: Maybe<Tournaments>;
  /** delete data from the table: "transaction_user_types" */
  deleteTransactionUserTypes?: Maybe<TransactionUserTypesMutationResponse>;
  /** delete single row from the table: "transaction_user_types" */
  deleteTransactionUserTypesByPk?: Maybe<TransactionUserTypes>;
  /** delete data from the table: "transactions" */
  deleteTransactions?: Maybe<TransactionsMutationResponse>;
  /** delete single row from the table: "transactions" */
  deleteTransactionsByPk?: Maybe<Transactions>;
  /** delete data from the table: "user_share_buys" */
  deleteUserShareBuys?: Maybe<UserShareBuysMutationResponse>;
  /** delete single row from the table: "user_share_buys" */
  deleteUserShareBuysByPk?: Maybe<UserShareBuys>;
  /** delete data from the table: "users" */
  deleteUsers?: Maybe<UsersMutationResponse>;
  /** delete single row from the table: "users" */
  deleteUsersByPk?: Maybe<Users>;
  /** delete single row from the table: "venues" */
  deleteVenue?: Maybe<Venues>;
  /** delete data from the table: "venue_users" */
  deleteVenueUsers?: Maybe<VenueUsersMutationResponse>;
  /** delete single row from the table: "venue_users" */
  deleteVenueUsersByPk?: Maybe<VenueUsers>;
  /** delete data from the table: "venues" */
  deleteVenues?: Maybe<VenuesMutationResponse>;
  finishTournament?: Maybe<GeneralOutput>;
  followPlayer?: Maybe<GeneralOutput>;
  /** insert data into the table: "blockchain_currencies" */
  insertBlockchainCurrencies?: Maybe<BlockchainCurrenciesMutationResponse>;
  /** insert a single row into the table: "blockchain_currencies" */
  insertBlockchainCurrenciesOne?: Maybe<BlockchainCurrencies>;
  /** insert data into the table: "blockchains" */
  insertBlockchains?: Maybe<BlockchainsMutationResponse>;
  /** insert a single row into the table: "blockchains" */
  insertBlockchainsOne?: Maybe<Blockchains>;
  /** insert data into the table: "countries" */
  insertCountries?: Maybe<CountriesMutationResponse>;
  /** insert a single row into the table: "countries" */
  insertCountriesOne?: Maybe<Countries>;
  /** insert data into the table: "currencies" */
  insertCurrencies?: Maybe<CurrenciesMutationResponse>;
  /** insert a single row into the table: "currencies" */
  insertCurrenciesOne?: Maybe<Currencies>;
  /** insert a single row into the table: "events" */
  insertEvent?: Maybe<Events>;
  /** insert data into the table: "events" */
  insertEvents?: Maybe<EventsMutationResponse>;
  /** insert a single row into the table: "followers" */
  insertFollower?: Maybe<Followers>;
  /** insert data into the table: "followers" */
  insertFollowers?: Maybe<FollowersMutationResponse>;
  /** insert data into the table: "message_types" */
  insertMessageTypes?: Maybe<MessageTypesMutationResponse>;
  /** insert a single row into the table: "message_types" */
  insertMessageTypesOne?: Maybe<MessageTypes>;
  /** insert data into the table: "messages" */
  insertMessages?: Maybe<MessagesMutationResponse>;
  /** insert a single row into the table: "messages" */
  insertMessagesOne?: Maybe<Messages>;
  /** insert data into the table: "organiser_users" */
  insertOrganiserUsers?: Maybe<OrganiserUsersMutationResponse>;
  /** insert a single row into the table: "organiser_users" */
  insertOrganiserUsersOne?: Maybe<OrganiserUsers>;
  /** insert data into the table: "organisers" */
  insertOrganisers?: Maybe<OrganisersMutationResponse>;
  /** insert a single row into the table: "organisers" */
  insertOrganisersOne?: Maybe<Organisers>;
  /** insert data into the table: "payment_method_banks" */
  insertPaymentMethodBanks?: Maybe<PaymentMethodBanksMutationResponse>;
  /** insert a single row into the table: "payment_method_banks" */
  insertPaymentMethodBanksOne?: Maybe<PaymentMethodBanks>;
  /** insert data into the table: "payment_methods" */
  insertPaymentMethods?: Maybe<PaymentMethodsMutationResponse>;
  /** insert a single row into the table: "payment_methods" */
  insertPaymentMethodsOne?: Maybe<PaymentMethods>;
  /** insert data into the table: "payments" */
  insertPayments?: Maybe<PaymentsMutationResponse>;
  /** insert a single row into the table: "payments" */
  insertPaymentsOne?: Maybe<Payments>;
  /** insert data into the table: "pending_txs" */
  insertPendingTxs?: Maybe<PendingTxsMutationResponse>;
  /** insert a single row into the table: "pending_txs" */
  insertPendingTxsOne?: Maybe<PendingTxs>;
  /** insert data into the table: "player_followers" */
  insertPlayerFollowers?: Maybe<PlayerFollowersMutationResponse>;
  /** insert a single row into the table: "player_followers" */
  insertPlayerFollowersOne?: Maybe<PlayerFollowers>;
  /** insert a single row into the table: "player_shares" */
  insertPlayerShare?: Maybe<PlayerShares>;
  /** insert data into the table: "player_shares" */
  insertPlayerShares?: Maybe<PlayerSharesMutationResponse>;
  /** insert data into the table: "players" */
  insertPlayers?: Maybe<PlayersMutationResponse>;
  /** insert a single row into the table: "players" */
  insertPlayersOne?: Maybe<Players>;
  /** insert data into the table: "stat_events" */
  insertStatEvents?: Maybe<StatEventsMutationResponse>;
  /** insert a single row into the table: "stat_events" */
  insertStatEventsOne?: Maybe<StatEvents>;
  /** insert data into the table: "stat_players" */
  insertStatPlayers?: Maybe<StatPlayersMutationResponse>;
  /** insert a single row into the table: "stat_players" */
  insertStatPlayersOne?: Maybe<StatPlayers>;
  /** insert data into the table: "stat_tournament_results" */
  insertStatTournamentResults?: Maybe<StatTournamentResultsMutationResponse>;
  /** insert a single row into the table: "stat_tournament_results" */
  insertStatTournamentResultsOne?: Maybe<StatTournamentResults>;
  /** insert data into the table: "stat_tournaments" */
  insertStatTournaments?: Maybe<StatTournamentsMutationResponse>;
  /** insert a single row into the table: "stat_tournaments" */
  insertStatTournamentsOne?: Maybe<StatTournaments>;
  /** insert data into the table: "status_games" */
  insertStatusGames?: Maybe<StatusGamesMutationResponse>;
  /** insert a single row into the table: "status_games" */
  insertStatusGamesOne?: Maybe<StatusGames>;
  /** insert data into the table: "statuses" */
  insertStatuses?: Maybe<StatusesMutationResponse>;
  /** insert a single row into the table: "statuses" */
  insertStatusesOne?: Maybe<Statuses>;
  /** insert data into the table: "tickets" */
  insertTickets?: Maybe<TicketsMutationResponse>;
  /** insert a single row into the table: "tickets" */
  insertTicketsOne?: Maybe<Tickets>;
  /** insert data into the table: "tickets_status" */
  insertTicketsStatus?: Maybe<TicketsStatusMutationResponse>;
  /** insert a single row into the table: "tickets_status" */
  insertTicketsStatusOne?: Maybe<TicketsStatus>;
  /** insert a single row into the table: "tournaments" */
  insertTournament?: Maybe<Tournaments>;
  /** insert data into the table: "tournament_result_statuses" */
  insertTournamentResultStatuses?: Maybe<TournamentResultStatusesMutationResponse>;
  /** insert a single row into the table: "tournament_result_statuses" */
  insertTournamentResultStatusesOne?: Maybe<TournamentResultStatuses>;
  /** insert data into the table: "tournament_results" */
  insertTournamentResults?: Maybe<TournamentResultsMutationResponse>;
  /** insert a single row into the table: "tournament_results" */
  insertTournamentResultsOne?: Maybe<TournamentResults>;
  /** insert data into the table: "tournaments" */
  insertTournaments?: Maybe<TournamentsMutationResponse>;
  /** insert data into the table: "transaction_user_types" */
  insertTransactionUserTypes?: Maybe<TransactionUserTypesMutationResponse>;
  /** insert a single row into the table: "transaction_user_types" */
  insertTransactionUserTypesOne?: Maybe<TransactionUserTypes>;
  /** insert data into the table: "transactions" */
  insertTransactions?: Maybe<TransactionsMutationResponse>;
  /** insert a single row into the table: "transactions" */
  insertTransactionsOne?: Maybe<Transactions>;
  /** insert data into the table: "user_share_buys" */
  insertUserShareBuys?: Maybe<UserShareBuysMutationResponse>;
  /** insert a single row into the table: "user_share_buys" */
  insertUserShareBuysOne?: Maybe<UserShareBuys>;
  /** insert data into the table: "users" */
  insertUsers?: Maybe<UsersMutationResponse>;
  /** insert a single row into the table: "users" */
  insertUsersOne?: Maybe<Users>;
  /** insert a single row into the table: "venues" */
  insertVenue?: Maybe<Venues>;
  /** insert data into the table: "venue_users" */
  insertVenueUsers?: Maybe<VenueUsersMutationResponse>;
  /** insert a single row into the table: "venue_users" */
  insertVenueUsersOne?: Maybe<VenueUsers>;
  /** insert data into the table: "venues" */
  insertVenues?: Maybe<VenuesMutationResponse>;
  linkERC20toFireBaseAccept?: Maybe<GeneralOutput>;
  linkERC20toFireBaseSignature?: Maybe<AuthSignatureOutput>;
  payoutTicket?: Maybe<StatusOutput>;
  playerShareResult?: Maybe<PlayerShareResultOutput>;
  registerPlayer?: Maybe<GeneralOutput>;
  sendVerifyMail?: Maybe<GeneralOutput>;
  setEmailaddress?: Maybe<GeneralOutput>;
  startTournament?: Maybe<GeneralOutput>;
  submitBankDeposit?: Maybe<StatusOutput>;
  submitBankWithdraw?: Maybe<StatusOutput>;
  submitDeposit?: Maybe<DepositOutput>;
  submitPlayerShareCancel?: Maybe<GeneralOutput>;
  submitPlayerShareCreate?: Maybe<GeneralOutput>;
  submitShareBuy?: Maybe<GeneralOutput>;
  submitShareCancel?: Maybe<GeneralOutput>;
  submitShareClaim?: Maybe<GeneralOutput>;
  submitWithdraw?: Maybe<StatusOutput>;
  submittedDeposit?: Maybe<StatusOutput>;
  unfollowPlayer?: Maybe<GeneralOutput>;
  /** update data of the table: "blockchain_currencies" */
  updateBlockchainCurrencies?: Maybe<BlockchainCurrenciesMutationResponse>;
  /** update single row of the table: "blockchain_currencies" */
  updateBlockchainCurrenciesByPk?: Maybe<BlockchainCurrencies>;
  /** update multiples rows of table: "blockchain_currencies" */
  updateBlockchainCurrenciesMany?: Maybe<
    Array<Maybe<BlockchainCurrenciesMutationResponse>>
  >;
  /** update data of the table: "blockchains" */
  updateBlockchains?: Maybe<BlockchainsMutationResponse>;
  /** update single row of the table: "blockchains" */
  updateBlockchainsByPk?: Maybe<Blockchains>;
  /** update multiples rows of table: "blockchains" */
  updateBlockchainsMany?: Maybe<Array<Maybe<BlockchainsMutationResponse>>>;
  /** update data of the table: "countries" */
  updateCountries?: Maybe<CountriesMutationResponse>;
  /** update single row of the table: "countries" */
  updateCountriesByPk?: Maybe<Countries>;
  /** update multiples rows of table: "countries" */
  updateCountriesMany?: Maybe<Array<Maybe<CountriesMutationResponse>>>;
  /** update data of the table: "currencies" */
  updateCurrencies?: Maybe<CurrenciesMutationResponse>;
  /** update single row of the table: "currencies" */
  updateCurrenciesByPk?: Maybe<Currencies>;
  /** update multiples rows of table: "currencies" */
  updateCurrenciesMany?: Maybe<Array<Maybe<CurrenciesMutationResponse>>>;
  /** update single row of the table: "events" */
  updateEvent?: Maybe<Events>;
  /** update data of the table: "events" */
  updateEvents?: Maybe<EventsMutationResponse>;
  /** update multiples rows of table: "events" */
  updateEventsMany?: Maybe<Array<Maybe<EventsMutationResponse>>>;
  /** update single row of the table: "followers" */
  updateFollower?: Maybe<Followers>;
  /** update data of the table: "followers" */
  updateFollowers?: Maybe<FollowersMutationResponse>;
  /** update multiples rows of table: "venues" */
  updateManuVenues?: Maybe<Array<Maybe<VenuesMutationResponse>>>;
  /** update multiples rows of table: "followers" */
  updateManyFollowers?: Maybe<Array<Maybe<FollowersMutationResponse>>>;
  /** update data of the table: "message_types" */
  updateMessageTypes?: Maybe<MessageTypesMutationResponse>;
  /** update single row of the table: "message_types" */
  updateMessageTypesByPk?: Maybe<MessageTypes>;
  /** update multiples rows of table: "message_types" */
  updateMessageTypesMany?: Maybe<Array<Maybe<MessageTypesMutationResponse>>>;
  /** update data of the table: "messages" */
  updateMessages?: Maybe<MessagesMutationResponse>;
  /** update single row of the table: "messages" */
  updateMessagesByPk?: Maybe<Messages>;
  /** update multiples rows of table: "messages" */
  updateMessagesMany?: Maybe<Array<Maybe<MessagesMutationResponse>>>;
  /** update data of the table: "organiser_users" */
  updateOrganiserUsers?: Maybe<OrganiserUsersMutationResponse>;
  /** update single row of the table: "organiser_users" */
  updateOrganiserUsersByPk?: Maybe<OrganiserUsers>;
  /** update multiples rows of table: "organiser_users" */
  updateOrganiserUsersMany?: Maybe<
    Array<Maybe<OrganiserUsersMutationResponse>>
  >;
  /** update data of the table: "organisers" */
  updateOrganisers?: Maybe<OrganisersMutationResponse>;
  /** update single row of the table: "organisers" */
  updateOrganisersByPk?: Maybe<Organisers>;
  /** update multiples rows of table: "organisers" */
  updateOrganisersMany?: Maybe<Array<Maybe<OrganisersMutationResponse>>>;
  /** update data of the table: "payment_method_banks" */
  updatePaymentMethodBanks?: Maybe<PaymentMethodBanksMutationResponse>;
  /** update single row of the table: "payment_method_banks" */
  updatePaymentMethodBanksByPk?: Maybe<PaymentMethodBanks>;
  /** update multiples rows of table: "payment_method_banks" */
  updatePaymentMethodBanksMany?: Maybe<
    Array<Maybe<PaymentMethodBanksMutationResponse>>
  >;
  /** update data of the table: "payment_methods" */
  updatePaymentMethods?: Maybe<PaymentMethodsMutationResponse>;
  /** update single row of the table: "payment_methods" */
  updatePaymentMethodsByPk?: Maybe<PaymentMethods>;
  /** update multiples rows of table: "payment_methods" */
  updatePaymentMethodsMany?: Maybe<
    Array<Maybe<PaymentMethodsMutationResponse>>
  >;
  /** update data of the table: "payments" */
  updatePayments?: Maybe<PaymentsMutationResponse>;
  /** update single row of the table: "payments" */
  updatePaymentsByPk?: Maybe<Payments>;
  /** update multiples rows of table: "payments" */
  updatePaymentsMany?: Maybe<Array<Maybe<PaymentsMutationResponse>>>;
  /** update data of the table: "pending_txs" */
  updatePendingTxs?: Maybe<PendingTxsMutationResponse>;
  /** update single row of the table: "pending_txs" */
  updatePendingTxsByPk?: Maybe<PendingTxs>;
  /** update multiples rows of table: "pending_txs" */
  updatePendingTxsMany?: Maybe<Array<Maybe<PendingTxsMutationResponse>>>;
  /** update single row of the table: "players" */
  updatePlayer?: Maybe<Players>;
  /** update data of the table: "player_followers" */
  updatePlayerFollowers?: Maybe<PlayerFollowersMutationResponse>;
  /** update multiples rows of table: "player_followers" */
  updatePlayerFollowersMany?: Maybe<
    Array<Maybe<PlayerFollowersMutationResponse>>
  >;
  /** update single row of the table: "player_shares" */
  updatePlayerShare?: Maybe<PlayerShares>;
  /** update data of the table: "player_shares" */
  updatePlayerShares?: Maybe<PlayerSharesMutationResponse>;
  /** update multiples rows of table: "player_shares" */
  updatePlayerSharesMany?: Maybe<Array<Maybe<PlayerSharesMutationResponse>>>;
  /** update data of the table: "players" */
  updatePlayers?: Maybe<PlayersMutationResponse>;
  /** update multiples rows of table: "players" */
  updatePlayersMany?: Maybe<Array<Maybe<PlayersMutationResponse>>>;
  /** update data of the table: "stat_events" */
  updateStatEvents?: Maybe<StatEventsMutationResponse>;
  /** update single row of the table: "stat_events" */
  updateStatEventsByPk?: Maybe<StatEvents>;
  /** update multiples rows of table: "stat_events" */
  updateStatEventsMany?: Maybe<Array<Maybe<StatEventsMutationResponse>>>;
  /** update data of the table: "stat_players" */
  updateStatPlayers?: Maybe<StatPlayersMutationResponse>;
  /** update single row of the table: "stat_players" */
  updateStatPlayersByPk?: Maybe<StatPlayers>;
  /** update multiples rows of table: "stat_players" */
  updateStatPlayersMany?: Maybe<Array<Maybe<StatPlayersMutationResponse>>>;
  /** update data of the table: "stat_tournament_results" */
  updateStatTournamentResults?: Maybe<StatTournamentResultsMutationResponse>;
  /** update single row of the table: "stat_tournament_results" */
  updateStatTournamentResultsByPk?: Maybe<StatTournamentResults>;
  /** update multiples rows of table: "stat_tournament_results" */
  updateStatTournamentResultsMany?: Maybe<
    Array<Maybe<StatTournamentResultsMutationResponse>>
  >;
  /** update data of the table: "stat_tournaments" */
  updateStatTournaments?: Maybe<StatTournamentsMutationResponse>;
  /** update single row of the table: "stat_tournaments" */
  updateStatTournamentsByPk?: Maybe<StatTournaments>;
  /** update multiples rows of table: "stat_tournaments" */
  updateStatTournamentsMany?: Maybe<
    Array<Maybe<StatTournamentsMutationResponse>>
  >;
  /** update data of the table: "status_games" */
  updateStatusGames?: Maybe<StatusGamesMutationResponse>;
  /** update single row of the table: "status_games" */
  updateStatusGamesByPk?: Maybe<StatusGames>;
  /** update multiples rows of table: "status_games" */
  updateStatusGamesMany?: Maybe<Array<Maybe<StatusGamesMutationResponse>>>;
  /** update data of the table: "statuses" */
  updateStatuses?: Maybe<StatusesMutationResponse>;
  /** update single row of the table: "statuses" */
  updateStatusesByPk?: Maybe<Statuses>;
  /** update multiples rows of table: "statuses" */
  updateStatusesMany?: Maybe<Array<Maybe<StatusesMutationResponse>>>;
  /** update data of the table: "tickets" */
  updateTickets?: Maybe<TicketsMutationResponse>;
  /** update single row of the table: "tickets" */
  updateTicketsByPk?: Maybe<Tickets>;
  /** update multiples rows of table: "tickets" */
  updateTicketsMany?: Maybe<Array<Maybe<TicketsMutationResponse>>>;
  /** update data of the table: "tickets_status" */
  updateTicketsStatus?: Maybe<TicketsStatusMutationResponse>;
  /** update single row of the table: "tickets_status" */
  updateTicketsStatusByPk?: Maybe<TicketsStatus>;
  /** update multiples rows of table: "tickets_status" */
  updateTicketsStatusMany?: Maybe<Array<Maybe<TicketsStatusMutationResponse>>>;
  /** update single row of the table: "tournaments" */
  updateTournament?: Maybe<Tournaments>;
  /** update data of the table: "tournament_result_statuses" */
  updateTournamentResultStatuses?: Maybe<TournamentResultStatusesMutationResponse>;
  /** update single row of the table: "tournament_result_statuses" */
  updateTournamentResultStatusesByPk?: Maybe<TournamentResultStatuses>;
  /** update multiples rows of table: "tournament_result_statuses" */
  updateTournamentResultStatusesMany?: Maybe<
    Array<Maybe<TournamentResultStatusesMutationResponse>>
  >;
  /** update data of the table: "tournament_results" */
  updateTournamentResults?: Maybe<TournamentResultsMutationResponse>;
  /** update single row of the table: "tournament_results" */
  updateTournamentResultsByPk?: Maybe<TournamentResults>;
  /** update multiples rows of table: "tournament_results" */
  updateTournamentResultsMany?: Maybe<
    Array<Maybe<TournamentResultsMutationResponse>>
  >;
  /** update data of the table: "tournaments" */
  updateTournaments?: Maybe<TournamentsMutationResponse>;
  /** update multiples rows of table: "tournaments" */
  updateTournamentsMany?: Maybe<Array<Maybe<TournamentsMutationResponse>>>;
  /** update data of the table: "transaction_user_types" */
  updateTransactionUserTypes?: Maybe<TransactionUserTypesMutationResponse>;
  /** update single row of the table: "transaction_user_types" */
  updateTransactionUserTypesByPk?: Maybe<TransactionUserTypes>;
  /** update multiples rows of table: "transaction_user_types" */
  updateTransactionUserTypesMany?: Maybe<
    Array<Maybe<TransactionUserTypesMutationResponse>>
  >;
  /** update data of the table: "transactions" */
  updateTransactions?: Maybe<TransactionsMutationResponse>;
  /** update single row of the table: "transactions" */
  updateTransactionsByPk?: Maybe<Transactions>;
  /** update multiples rows of table: "transactions" */
  updateTransactionsMany?: Maybe<Array<Maybe<TransactionsMutationResponse>>>;
  /** update data of the table: "user_share_buys" */
  updateUserShareBuys?: Maybe<UserShareBuysMutationResponse>;
  /** update single row of the table: "user_share_buys" */
  updateUserShareBuysByPk?: Maybe<UserShareBuys>;
  /** update multiples rows of table: "user_share_buys" */
  updateUserShareBuysMany?: Maybe<Array<Maybe<UserShareBuysMutationResponse>>>;
  /** update data of the table: "users" */
  updateUsers?: Maybe<UsersMutationResponse>;
  /** update single row of the table: "users" */
  updateUsersByPk?: Maybe<Users>;
  /** update multiples rows of table: "users" */
  updateUsersMany?: Maybe<Array<Maybe<UsersMutationResponse>>>;
  /** update single row of the table: "venues" */
  updateVenue?: Maybe<Venues>;
  /** update data of the table: "venue_users" */
  updateVenueUsers?: Maybe<VenueUsersMutationResponse>;
  /** update single row of the table: "venue_users" */
  updateVenueUsersByPk?: Maybe<VenueUsers>;
  /** update multiples rows of table: "venue_users" */
  updateVenueUsersMany?: Maybe<Array<Maybe<VenueUsersMutationResponse>>>;
  /** update data of the table: "venues" */
  updateVenues?: Maybe<VenuesMutationResponse>;
};

/** mutation root */
export type Mutation_RootActivate2Fa2Args = {
  code: Scalars['String']['input'];
};

/** mutation root */
export type Mutation_RootAuthLoginArgs = {
  address?: InputMaybe<Scalars['String']['input']>;
  signature?: InputMaybe<Scalars['String']['input']>;
};

/** mutation root */
export type Mutation_RootAuthRefreshArgs = {
  accesstoken?: InputMaybe<Scalars['String']['input']>;
};

/** mutation root */
export type Mutation_RootAuthSignatureArgs = {
  address: Scalars['String']['input'];
};

/** mutation root */
export type Mutation_RootBuyTicketArgs = {
  playerName?: InputMaybe<Scalars['String']['input']>;
  tournamentId: Scalars['String']['input'];
};

/** mutation root */
export type Mutation_RootCancelTicketArgs = {
  ticketId: Scalars['Int']['input'];
};

/** mutation root */
export type Mutation_RootCancelTournamentArgs = {
  tournamentId: Scalars['String']['input'];
};

/** mutation root */
export type Mutation_RootDeactivate2FaArgs = {
  code: Scalars['String']['input'];
};

/** mutation root */
export type Mutation_RootDeleteBlockchainCurrenciesArgs = {
  where: BlockchainCurrenciesBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteBlockchainCurrenciesByPkArgs = {
  id: Scalars['Int']['input'];
};

/** mutation root */
export type Mutation_RootDeleteBlockchainsArgs = {
  where: BlockchainsBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteBlockchainsByPkArgs = {
  id: Scalars['Int']['input'];
};

/** mutation root */
export type Mutation_RootDeleteCountriesArgs = {
  where: CountriesBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteCountriesByPkArgs = {
  id: Scalars['Int']['input'];
};

/** mutation root */
export type Mutation_RootDeleteCurrenciesArgs = {
  where: CurrenciesBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteCurrenciesByPkArgs = {
  id: Scalars['Int']['input'];
};

/** mutation root */
export type Mutation_RootDeleteEventsArgs = {
  where: EventsBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteEventsByPkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDeleteFollowerArgs = {
  id: Scalars['bigint']['input'];
};

/** mutation root */
export type Mutation_RootDeleteFollowersArgs = {
  where: FollowersBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteMessageTypesArgs = {
  where: MessageTypesBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteMessageTypesByPkArgs = {
  id: Scalars['Int']['input'];
};

/** mutation root */
export type Mutation_RootDeleteMessagesArgs = {
  where: MessagesBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteMessagesByPkArgs = {
  id: Scalars['bigint']['input'];
};

/** mutation root */
export type Mutation_RootDeleteOrganiserUsersArgs = {
  where: OrganiserUsersBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteOrganiserUsersByPkArgs = {
  id: Scalars['bigint']['input'];
};

/** mutation root */
export type Mutation_RootDeleteOrganisersArgs = {
  where: OrganisersBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteOrganisersByPkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDeletePaymentMethodBanksArgs = {
  where: PaymentMethodBanksBoolExp;
};

/** mutation root */
export type Mutation_RootDeletePaymentMethodBanksByPkArgs = {
  id: Scalars['Int']['input'];
};

/** mutation root */
export type Mutation_RootDeletePaymentMethodsArgs = {
  where: PaymentMethodsBoolExp;
};

/** mutation root */
export type Mutation_RootDeletePaymentMethodsByPkArgs = {
  id: Scalars['Int']['input'];
};

/** mutation root */
export type Mutation_RootDeletePaymentsArgs = {
  where: PaymentsBoolExp;
};

/** mutation root */
export type Mutation_RootDeletePaymentsByPkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDeletePendingTxsArgs = {
  where: PendingTxsBoolExp;
};

/** mutation root */
export type Mutation_RootDeletePendingTxsByPkArgs = {
  id: Scalars['Int']['input'];
};

/** mutation root */
export type Mutation_RootDeletePlayerFollowersArgs = {
  where: PlayerFollowersBoolExp;
};

/** mutation root */
export type Mutation_RootDeletePlayerShareArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDeletePlayerSharesArgs = {
  where: PlayerSharesBoolExp;
};

/** mutation root */
export type Mutation_RootDeletePlayersArgs = {
  where: PlayersBoolExp;
};

/** mutation root */
export type Mutation_RootDeletePlayersByPkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDeleteStatEventsArgs = {
  where: StatEventsBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteStatEventsByPkArgs = {
  id: Scalars['bigint']['input'];
};

/** mutation root */
export type Mutation_RootDeleteStatPlayersArgs = {
  where: StatPlayersBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteStatPlayersByPkArgs = {
  id: Scalars['bigint']['input'];
};

/** mutation root */
export type Mutation_RootDeleteStatTournamentResultsArgs = {
  where: StatTournamentResultsBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteStatTournamentResultsByPkArgs = {
  id: Scalars['bigint']['input'];
};

/** mutation root */
export type Mutation_RootDeleteStatTournamentsArgs = {
  where: StatTournamentsBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteStatTournamentsByPkArgs = {
  id: Scalars['bigint']['input'];
};

/** mutation root */
export type Mutation_RootDeleteStatusGamesArgs = {
  where: StatusGamesBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteStatusGamesByPkArgs = {
  name: Scalars['String']['input'];
};

/** mutation root */
export type Mutation_RootDeleteStatusesArgs = {
  where: StatusesBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteStatusesByPkArgs = {
  name: Scalars['String']['input'];
};

/** mutation root */
export type Mutation_RootDeleteTicketsArgs = {
  where: TicketsBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteTicketsByPkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDeleteTicketsStatusArgs = {
  where: TicketsStatusBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteTicketsStatusByPkArgs = {
  name: Scalars['String']['input'];
};

/** mutation root */
export type Mutation_RootDeleteTournamentResultStatusesArgs = {
  where: TournamentResultStatusesBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteTournamentResultStatusesByPkArgs = {
  name: Scalars['String']['input'];
};

/** mutation root */
export type Mutation_RootDeleteTournamentResultsArgs = {
  where: TournamentResultsBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteTournamentResultsByPkArgs = {
  id: Scalars['bigint']['input'];
};

/** mutation root */
export type Mutation_RootDeleteTournamentsArgs = {
  where: TournamentsBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteTournamentsByPkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDeleteTransactionUserTypesArgs = {
  where: TransactionUserTypesBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteTransactionUserTypesByPkArgs = {
  name: Scalars['String']['input'];
};

/** mutation root */
export type Mutation_RootDeleteTransactionsArgs = {
  where: TransactionsBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteTransactionsByPkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDeleteUserShareBuysArgs = {
  where: UserShareBuysBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteUserShareBuysByPkArgs = {
  id: Scalars['bigint']['input'];
};

/** mutation root */
export type Mutation_RootDeleteUsersArgs = {
  where: UsersBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteUsersByPkArgs = {
  id: Scalars['String']['input'];
};

/** mutation root */
export type Mutation_RootDeleteVenueArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDeleteVenueUsersArgs = {
  where: VenueUsersBoolExp;
};

/** mutation root */
export type Mutation_RootDeleteVenueUsersByPkArgs = {
  id: Scalars['bigint']['input'];
};

/** mutation root */
export type Mutation_RootDeleteVenuesArgs = {
  where: VenuesBoolExp;
};

/** mutation root */
export type Mutation_RootFinishTournamentArgs = {
  tournamentId: Scalars['String']['input'];
};

/** mutation root */
export type Mutation_RootFollowPlayerArgs = {
  playerId: Scalars['String']['input'];
};

/** mutation root */
export type Mutation_RootInsertBlockchainCurrenciesArgs = {
  objects: Array<BlockchainCurrenciesInsertInput>;
  onConflict?: InputMaybe<BlockchainCurrenciesOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertBlockchainCurrenciesOneArgs = {
  object: BlockchainCurrenciesInsertInput;
  onConflict?: InputMaybe<BlockchainCurrenciesOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertBlockchainsArgs = {
  objects: Array<BlockchainsInsertInput>;
  onConflict?: InputMaybe<BlockchainsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertBlockchainsOneArgs = {
  object: BlockchainsInsertInput;
  onConflict?: InputMaybe<BlockchainsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertCountriesArgs = {
  objects: Array<CountriesInsertInput>;
  onConflict?: InputMaybe<CountriesOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertCountriesOneArgs = {
  object: CountriesInsertInput;
  onConflict?: InputMaybe<CountriesOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertCurrenciesArgs = {
  objects: Array<CurrenciesInsertInput>;
  onConflict?: InputMaybe<CurrenciesOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertCurrenciesOneArgs = {
  object: CurrenciesInsertInput;
  onConflict?: InputMaybe<CurrenciesOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertEventArgs = {
  object: EventsInsertInput;
  onConflict?: InputMaybe<EventsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertEventsArgs = {
  objects: Array<EventsInsertInput>;
  onConflict?: InputMaybe<EventsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertFollowerArgs = {
  object: FollowersInsertInput;
  onConflict?: InputMaybe<FollowersOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertFollowersArgs = {
  objects: Array<FollowersInsertInput>;
  onConflict?: InputMaybe<FollowersOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertMessageTypesArgs = {
  objects: Array<MessageTypesInsertInput>;
  onConflict?: InputMaybe<MessageTypesOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertMessageTypesOneArgs = {
  object: MessageTypesInsertInput;
  onConflict?: InputMaybe<MessageTypesOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertMessagesArgs = {
  objects: Array<MessagesInsertInput>;
  onConflict?: InputMaybe<MessagesOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertMessagesOneArgs = {
  object: MessagesInsertInput;
  onConflict?: InputMaybe<MessagesOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertOrganiserUsersArgs = {
  objects: Array<OrganiserUsersInsertInput>;
  onConflict?: InputMaybe<OrganiserUsersOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertOrganiserUsersOneArgs = {
  object: OrganiserUsersInsertInput;
  onConflict?: InputMaybe<OrganiserUsersOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertOrganisersArgs = {
  objects: Array<OrganisersInsertInput>;
  onConflict?: InputMaybe<OrganisersOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertOrganisersOneArgs = {
  object: OrganisersInsertInput;
  onConflict?: InputMaybe<OrganisersOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertPaymentMethodBanksArgs = {
  objects: Array<PaymentMethodBanksInsertInput>;
  onConflict?: InputMaybe<PaymentMethodBanksOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertPaymentMethodBanksOneArgs = {
  object: PaymentMethodBanksInsertInput;
  onConflict?: InputMaybe<PaymentMethodBanksOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertPaymentMethodsArgs = {
  objects: Array<PaymentMethodsInsertInput>;
  onConflict?: InputMaybe<PaymentMethodsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertPaymentMethodsOneArgs = {
  object: PaymentMethodsInsertInput;
  onConflict?: InputMaybe<PaymentMethodsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertPaymentsArgs = {
  objects: Array<PaymentsInsertInput>;
  onConflict?: InputMaybe<PaymentsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertPaymentsOneArgs = {
  object: PaymentsInsertInput;
  onConflict?: InputMaybe<PaymentsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertPendingTxsArgs = {
  objects: Array<PendingTxsInsertInput>;
  onConflict?: InputMaybe<PendingTxsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertPendingTxsOneArgs = {
  object: PendingTxsInsertInput;
  onConflict?: InputMaybe<PendingTxsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertPlayerFollowersArgs = {
  objects: Array<PlayerFollowersInsertInput>;
};

/** mutation root */
export type Mutation_RootInsertPlayerFollowersOneArgs = {
  object: PlayerFollowersInsertInput;
};

/** mutation root */
export type Mutation_RootInsertPlayerShareArgs = {
  object: PlayerSharesInsertInput;
  onConflict?: InputMaybe<PlayerSharesOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertPlayerSharesArgs = {
  objects: Array<PlayerSharesInsertInput>;
  onConflict?: InputMaybe<PlayerSharesOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertPlayersArgs = {
  objects: Array<PlayersInsertInput>;
  onConflict?: InputMaybe<PlayersOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertPlayersOneArgs = {
  object: PlayersInsertInput;
  onConflict?: InputMaybe<PlayersOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertStatEventsArgs = {
  objects: Array<StatEventsInsertInput>;
  onConflict?: InputMaybe<StatEventsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertStatEventsOneArgs = {
  object: StatEventsInsertInput;
  onConflict?: InputMaybe<StatEventsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertStatPlayersArgs = {
  objects: Array<StatPlayersInsertInput>;
  onConflict?: InputMaybe<StatPlayersOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertStatPlayersOneArgs = {
  object: StatPlayersInsertInput;
  onConflict?: InputMaybe<StatPlayersOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertStatTournamentResultsArgs = {
  objects: Array<StatTournamentResultsInsertInput>;
  onConflict?: InputMaybe<StatTournamentResultsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertStatTournamentResultsOneArgs = {
  object: StatTournamentResultsInsertInput;
  onConflict?: InputMaybe<StatTournamentResultsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertStatTournamentsArgs = {
  objects: Array<StatTournamentsInsertInput>;
  onConflict?: InputMaybe<StatTournamentsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertStatTournamentsOneArgs = {
  object: StatTournamentsInsertInput;
  onConflict?: InputMaybe<StatTournamentsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertStatusGamesArgs = {
  objects: Array<StatusGamesInsertInput>;
  onConflict?: InputMaybe<StatusGamesOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertStatusGamesOneArgs = {
  object: StatusGamesInsertInput;
  onConflict?: InputMaybe<StatusGamesOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertStatusesArgs = {
  objects: Array<StatusesInsertInput>;
  onConflict?: InputMaybe<StatusesOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertStatusesOneArgs = {
  object: StatusesInsertInput;
  onConflict?: InputMaybe<StatusesOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertTicketsArgs = {
  objects: Array<TicketsInsertInput>;
  onConflict?: InputMaybe<TicketsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertTicketsOneArgs = {
  object: TicketsInsertInput;
  onConflict?: InputMaybe<TicketsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertTicketsStatusArgs = {
  objects: Array<TicketsStatusInsertInput>;
  onConflict?: InputMaybe<TicketsStatusOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertTicketsStatusOneArgs = {
  object: TicketsStatusInsertInput;
  onConflict?: InputMaybe<TicketsStatusOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertTournamentArgs = {
  object: TournamentsInsertInput;
  onConflict?: InputMaybe<TournamentsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertTournamentResultStatusesArgs = {
  objects: Array<TournamentResultStatusesInsertInput>;
  onConflict?: InputMaybe<TournamentResultStatusesOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertTournamentResultStatusesOneArgs = {
  object: TournamentResultStatusesInsertInput;
  onConflict?: InputMaybe<TournamentResultStatusesOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertTournamentResultsArgs = {
  objects: Array<TournamentResultsInsertInput>;
  onConflict?: InputMaybe<TournamentResultsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertTournamentResultsOneArgs = {
  object: TournamentResultsInsertInput;
  onConflict?: InputMaybe<TournamentResultsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertTournamentsArgs = {
  objects: Array<TournamentsInsertInput>;
  onConflict?: InputMaybe<TournamentsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertTransactionUserTypesArgs = {
  objects: Array<TransactionUserTypesInsertInput>;
  onConflict?: InputMaybe<TransactionUserTypesOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertTransactionUserTypesOneArgs = {
  object: TransactionUserTypesInsertInput;
  onConflict?: InputMaybe<TransactionUserTypesOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertTransactionsArgs = {
  objects: Array<TransactionsInsertInput>;
  onConflict?: InputMaybe<TransactionsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertTransactionsOneArgs = {
  object: TransactionsInsertInput;
  onConflict?: InputMaybe<TransactionsOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertUserShareBuysArgs = {
  objects: Array<UserShareBuysInsertInput>;
  onConflict?: InputMaybe<UserShareBuysOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertUserShareBuysOneArgs = {
  object: UserShareBuysInsertInput;
  onConflict?: InputMaybe<UserShareBuysOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertUsersArgs = {
  objects: Array<UsersInsertInput>;
  onConflict?: InputMaybe<UsersOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertUsersOneArgs = {
  object: UsersInsertInput;
  onConflict?: InputMaybe<UsersOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertVenueArgs = {
  object: VenuesInsertInput;
  onConflict?: InputMaybe<VenuesOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertVenueUsersArgs = {
  objects: Array<VenueUsersInsertInput>;
  onConflict?: InputMaybe<VenueUsersOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertVenueUsersOneArgs = {
  object: VenueUsersInsertInput;
  onConflict?: InputMaybe<VenueUsersOnConflict>;
};

/** mutation root */
export type Mutation_RootInsertVenuesArgs = {
  objects: Array<VenuesInsertInput>;
  onConflict?: InputMaybe<VenuesOnConflict>;
};

/** mutation root */
export type Mutation_RootLinkErc20toFireBaseAcceptArgs = {
  address?: InputMaybe<Scalars['String']['input']>;
  signature?: InputMaybe<Scalars['String']['input']>;
};

/** mutation root */
export type Mutation_RootLinkErc20toFireBaseSignatureArgs = {
  address: Scalars['String']['input'];
};

/** mutation root */
export type Mutation_RootPayoutTicketArgs = {
  accountHolder: Scalars['String']['input'];
  accountNumber: Scalars['String']['input'];
  bankName?: InputMaybe<Scalars['String']['input']>;
  paymentMethodBankId: Scalars['Int']['input'];
  ticketId: Scalars['String']['input'];
  twoFA?: InputMaybe<Scalars['String']['input']>;
};

/** mutation root */
export type Mutation_RootPlayerShareResultArgs = {
  action: PlayerShareAction;
  place?: InputMaybe<Scalars['Int']['input']>;
  playerShareId?: InputMaybe<Scalars['String']['input']>;
  ticketId?: InputMaybe<Scalars['String']['input']>;
  won?: InputMaybe<Scalars['Float']['input']>;
};

/** mutation root */
export type Mutation_RootRegisterPlayerArgs = {
  data: PlayerRegisterInput;
};

/** mutation root */
export type Mutation_RootSetEmailaddressArgs = {
  email: Scalars['String']['input'];
};

/** mutation root */
export type Mutation_RootStartTournamentArgs = {
  tournamentId: Scalars['String']['input'];
};

/** mutation root */
export type Mutation_RootSubmitBankDepositArgs = {
  id: Scalars['String']['input'];
};

/** mutation root */
export type Mutation_RootSubmitBankWithdrawArgs = {
  accountHolder: Scalars['String']['input'];
  accountNumber: Scalars['String']['input'];
  amount: Scalars['Float']['input'];
  bankName?: InputMaybe<Scalars['String']['input']>;
  paymentMethodBankId: Scalars['Int']['input'];
  twoFA?: InputMaybe<Scalars['String']['input']>;
};

/** mutation root */
export type Mutation_RootSubmitDepositArgs = {
  address: Scalars['String']['input'];
  amount: Scalars['Float']['input'];
  blockchainCurrencyId: Scalars['Int']['input'];
  blockchainId: Scalars['Int']['input'];
};

/** mutation root */
export type Mutation_RootSubmitPlayerShareCancelArgs = {
  playerShareId: Scalars['Int']['input'];
};

/** mutation root */
export type Mutation_RootSubmitPlayerShareCreateArgs = {
  activeDate?: InputMaybe<Scalars['String']['input']>;
  bullets: Scalars['Int']['input'];
  markUp: Scalars['Float']['input'];
  maxPerUserShare?: InputMaybe<Scalars['Float']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  shareDilution: Scalars['Boolean']['input'];
  sharesToSell: Scalars['Int']['input'];
  tournamentId: Scalars['String']['input'];
};

/** mutation root */
export type Mutation_RootSubmitShareBuyArgs = {
  password?: InputMaybe<Scalars['String']['input']>;
  percentage: Scalars['Float']['input'];
  playerShareId: Scalars['String']['input'];
};

/** mutation root */
export type Mutation_RootSubmitShareCancelArgs = {
  userShareBuyId: Scalars['Int']['input'];
};

/** mutation root */
export type Mutation_RootSubmitShareClaimArgs = {
  userShareBuyId: Scalars['Int']['input'];
};

/** mutation root */
export type Mutation_RootSubmitWithdrawArgs = {
  address: Scalars['String']['input'];
  amount: Scalars['Float']['input'];
  blockchainCurrencyId: Scalars['Int']['input'];
  blockchainId: Scalars['Int']['input'];
  twoFA?: InputMaybe<Scalars['String']['input']>;
};

/** mutation root */
export type Mutation_RootSubmittedDepositArgs = {
  id: Scalars['String']['input'];
  tx: Scalars['String']['input'];
};

/** mutation root */
export type Mutation_RootUnfollowPlayerArgs = {
  playerId: Scalars['String']['input'];
};

/** mutation root */
export type Mutation_RootUpdateBlockchainCurrenciesArgs = {
  _inc?: InputMaybe<BlockchainCurrenciesIncInput>;
  _set?: InputMaybe<BlockchainCurrenciesSetInput>;
  where: BlockchainCurrenciesBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateBlockchainCurrenciesByPkArgs = {
  _inc?: InputMaybe<BlockchainCurrenciesIncInput>;
  _set?: InputMaybe<BlockchainCurrenciesSetInput>;
  pk_columns: BlockchainCurrenciesPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateBlockchainCurrenciesManyArgs = {
  updates: Array<BlockchainCurrenciesUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateBlockchainsArgs = {
  _append?: InputMaybe<BlockchainsAppendInput>;
  _deleteAtPath?: InputMaybe<BlockchainsDeleteAtPathInput>;
  _deleteElem?: InputMaybe<BlockchainsDeleteElemInput>;
  _deleteKey?: InputMaybe<BlockchainsDeleteKeyInput>;
  _inc?: InputMaybe<BlockchainsIncInput>;
  _prepend?: InputMaybe<BlockchainsPrependInput>;
  _set?: InputMaybe<BlockchainsSetInput>;
  where: BlockchainsBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateBlockchainsByPkArgs = {
  _append?: InputMaybe<BlockchainsAppendInput>;
  _deleteAtPath?: InputMaybe<BlockchainsDeleteAtPathInput>;
  _deleteElem?: InputMaybe<BlockchainsDeleteElemInput>;
  _deleteKey?: InputMaybe<BlockchainsDeleteKeyInput>;
  _inc?: InputMaybe<BlockchainsIncInput>;
  _prepend?: InputMaybe<BlockchainsPrependInput>;
  _set?: InputMaybe<BlockchainsSetInput>;
  pk_columns: BlockchainsPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateBlockchainsManyArgs = {
  updates: Array<BlockchainsUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateCountriesArgs = {
  _inc?: InputMaybe<CountriesIncInput>;
  _set?: InputMaybe<CountriesSetInput>;
  where: CountriesBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateCountriesByPkArgs = {
  _inc?: InputMaybe<CountriesIncInput>;
  _set?: InputMaybe<CountriesSetInput>;
  pk_columns: CountriesPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateCountriesManyArgs = {
  updates: Array<CountriesUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateCurrenciesArgs = {
  _inc?: InputMaybe<CurrenciesIncInput>;
  _set?: InputMaybe<CurrenciesSetInput>;
  where: CurrenciesBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateCurrenciesByPkArgs = {
  _inc?: InputMaybe<CurrenciesIncInput>;
  _set?: InputMaybe<CurrenciesSetInput>;
  pk_columns: CurrenciesPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateCurrenciesManyArgs = {
  updates: Array<CurrenciesUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateEventArgs = {
  _inc?: InputMaybe<EventsIncInput>;
  _set?: InputMaybe<EventsSetInput>;
  pk_columns: EventsPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateEventsArgs = {
  _inc?: InputMaybe<EventsIncInput>;
  _set?: InputMaybe<EventsSetInput>;
  where: EventsBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateEventsManyArgs = {
  updates: Array<EventsUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateFollowerArgs = {
  _inc?: InputMaybe<FollowersIncInput>;
  _set?: InputMaybe<FollowersSetInput>;
  pk_columns: FollowersPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateFollowersArgs = {
  _inc?: InputMaybe<FollowersIncInput>;
  _set?: InputMaybe<FollowersSetInput>;
  where: FollowersBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateManuVenuesArgs = {
  updates: Array<VenuesUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateManyFollowersArgs = {
  updates: Array<FollowersUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateMessageTypesArgs = {
  _append?: InputMaybe<MessageTypesAppendInput>;
  _deleteAtPath?: InputMaybe<MessageTypesDeleteAtPathInput>;
  _deleteElem?: InputMaybe<MessageTypesDeleteElemInput>;
  _deleteKey?: InputMaybe<MessageTypesDeleteKeyInput>;
  _inc?: InputMaybe<MessageTypesIncInput>;
  _prepend?: InputMaybe<MessageTypesPrependInput>;
  _set?: InputMaybe<MessageTypesSetInput>;
  where: MessageTypesBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateMessageTypesByPkArgs = {
  _append?: InputMaybe<MessageTypesAppendInput>;
  _deleteAtPath?: InputMaybe<MessageTypesDeleteAtPathInput>;
  _deleteElem?: InputMaybe<MessageTypesDeleteElemInput>;
  _deleteKey?: InputMaybe<MessageTypesDeleteKeyInput>;
  _inc?: InputMaybe<MessageTypesIncInput>;
  _prepend?: InputMaybe<MessageTypesPrependInput>;
  _set?: InputMaybe<MessageTypesSetInput>;
  pk_columns: MessageTypesPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateMessageTypesManyArgs = {
  updates: Array<MessageTypesUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateMessagesArgs = {
  _append?: InputMaybe<MessagesAppendInput>;
  _deleteAtPath?: InputMaybe<MessagesDeleteAtPathInput>;
  _deleteElem?: InputMaybe<MessagesDeleteElemInput>;
  _deleteKey?: InputMaybe<MessagesDeleteKeyInput>;
  _inc?: InputMaybe<MessagesIncInput>;
  _prepend?: InputMaybe<MessagesPrependInput>;
  _set?: InputMaybe<MessagesSetInput>;
  where: MessagesBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateMessagesByPkArgs = {
  _append?: InputMaybe<MessagesAppendInput>;
  _deleteAtPath?: InputMaybe<MessagesDeleteAtPathInput>;
  _deleteElem?: InputMaybe<MessagesDeleteElemInput>;
  _deleteKey?: InputMaybe<MessagesDeleteKeyInput>;
  _inc?: InputMaybe<MessagesIncInput>;
  _prepend?: InputMaybe<MessagesPrependInput>;
  _set?: InputMaybe<MessagesSetInput>;
  pk_columns: MessagesPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateMessagesManyArgs = {
  updates: Array<MessagesUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateOrganiserUsersArgs = {
  _inc?: InputMaybe<OrganiserUsersIncInput>;
  _set?: InputMaybe<OrganiserUsersSetInput>;
  where: OrganiserUsersBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateOrganiserUsersByPkArgs = {
  _inc?: InputMaybe<OrganiserUsersIncInput>;
  _set?: InputMaybe<OrganiserUsersSetInput>;
  pk_columns: OrganiserUsersPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateOrganiserUsersManyArgs = {
  updates: Array<OrganiserUsersUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateOrganisersArgs = {
  _inc?: InputMaybe<OrganisersIncInput>;
  _set?: InputMaybe<OrganisersSetInput>;
  where: OrganisersBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateOrganisersByPkArgs = {
  _inc?: InputMaybe<OrganisersIncInput>;
  _set?: InputMaybe<OrganisersSetInput>;
  pk_columns: OrganisersPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateOrganisersManyArgs = {
  updates: Array<OrganisersUpdates>;
};

/** mutation root */
export type Mutation_RootUpdatePaymentMethodBanksArgs = {
  _inc?: InputMaybe<PaymentMethodBanksIncInput>;
  _set?: InputMaybe<PaymentMethodBanksSetInput>;
  where: PaymentMethodBanksBoolExp;
};

/** mutation root */
export type Mutation_RootUpdatePaymentMethodBanksByPkArgs = {
  _inc?: InputMaybe<PaymentMethodBanksIncInput>;
  _set?: InputMaybe<PaymentMethodBanksSetInput>;
  pk_columns: PaymentMethodBanksPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdatePaymentMethodBanksManyArgs = {
  updates: Array<PaymentMethodBanksUpdates>;
};

/** mutation root */
export type Mutation_RootUpdatePaymentMethodsArgs = {
  _inc?: InputMaybe<PaymentMethodsIncInput>;
  _set?: InputMaybe<PaymentMethodsSetInput>;
  where: PaymentMethodsBoolExp;
};

/** mutation root */
export type Mutation_RootUpdatePaymentMethodsByPkArgs = {
  _inc?: InputMaybe<PaymentMethodsIncInput>;
  _set?: InputMaybe<PaymentMethodsSetInput>;
  pk_columns: PaymentMethodsPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdatePaymentMethodsManyArgs = {
  updates: Array<PaymentMethodsUpdates>;
};

/** mutation root */
export type Mutation_RootUpdatePaymentsArgs = {
  _inc?: InputMaybe<PaymentsIncInput>;
  _set?: InputMaybe<PaymentsSetInput>;
  where: PaymentsBoolExp;
};

/** mutation root */
export type Mutation_RootUpdatePaymentsByPkArgs = {
  _inc?: InputMaybe<PaymentsIncInput>;
  _set?: InputMaybe<PaymentsSetInput>;
  pk_columns: PaymentsPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdatePaymentsManyArgs = {
  updates: Array<PaymentsUpdates>;
};

/** mutation root */
export type Mutation_RootUpdatePendingTxsArgs = {
  _inc?: InputMaybe<PendingTxsIncInput>;
  _set?: InputMaybe<PendingTxsSetInput>;
  where: PendingTxsBoolExp;
};

/** mutation root */
export type Mutation_RootUpdatePendingTxsByPkArgs = {
  _inc?: InputMaybe<PendingTxsIncInput>;
  _set?: InputMaybe<PendingTxsSetInput>;
  pk_columns: PendingTxsPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdatePendingTxsManyArgs = {
  updates: Array<PendingTxsUpdates>;
};

/** mutation root */
export type Mutation_RootUpdatePlayerArgs = {
  _inc?: InputMaybe<PlayersIncInput>;
  _set?: InputMaybe<PlayersSetInput>;
  pk_columns: PlayersPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdatePlayerFollowersArgs = {
  _inc?: InputMaybe<PlayerFollowersIncInput>;
  _set?: InputMaybe<PlayerFollowersSetInput>;
  where: PlayerFollowersBoolExp;
};

/** mutation root */
export type Mutation_RootUpdatePlayerFollowersManyArgs = {
  updates: Array<PlayerFollowersUpdates>;
};

/** mutation root */
export type Mutation_RootUpdatePlayerShareArgs = {
  _inc?: InputMaybe<PlayerSharesIncInput>;
  _set?: InputMaybe<PlayerSharesSetInput>;
  pk_columns: PlayerSharesPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdatePlayerSharesArgs = {
  _inc?: InputMaybe<PlayerSharesIncInput>;
  _set?: InputMaybe<PlayerSharesSetInput>;
  where: PlayerSharesBoolExp;
};

/** mutation root */
export type Mutation_RootUpdatePlayerSharesManyArgs = {
  updates: Array<PlayerSharesUpdates>;
};

/** mutation root */
export type Mutation_RootUpdatePlayersArgs = {
  _inc?: InputMaybe<PlayersIncInput>;
  _set?: InputMaybe<PlayersSetInput>;
  where: PlayersBoolExp;
};

/** mutation root */
export type Mutation_RootUpdatePlayersManyArgs = {
  updates: Array<PlayersUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateStatEventsArgs = {
  _inc?: InputMaybe<StatEventsIncInput>;
  _set?: InputMaybe<StatEventsSetInput>;
  where: StatEventsBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateStatEventsByPkArgs = {
  _inc?: InputMaybe<StatEventsIncInput>;
  _set?: InputMaybe<StatEventsSetInput>;
  pk_columns: StatEventsPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateStatEventsManyArgs = {
  updates: Array<StatEventsUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateStatPlayersArgs = {
  _inc?: InputMaybe<StatPlayersIncInput>;
  _set?: InputMaybe<StatPlayersSetInput>;
  where: StatPlayersBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateStatPlayersByPkArgs = {
  _inc?: InputMaybe<StatPlayersIncInput>;
  _set?: InputMaybe<StatPlayersSetInput>;
  pk_columns: StatPlayersPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateStatPlayersManyArgs = {
  updates: Array<StatPlayersUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateStatTournamentResultsArgs = {
  _inc?: InputMaybe<StatTournamentResultsIncInput>;
  _set?: InputMaybe<StatTournamentResultsSetInput>;
  where: StatTournamentResultsBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateStatTournamentResultsByPkArgs = {
  _inc?: InputMaybe<StatTournamentResultsIncInput>;
  _set?: InputMaybe<StatTournamentResultsSetInput>;
  pk_columns: StatTournamentResultsPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateStatTournamentResultsManyArgs = {
  updates: Array<StatTournamentResultsUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateStatTournamentsArgs = {
  _inc?: InputMaybe<StatTournamentsIncInput>;
  _set?: InputMaybe<StatTournamentsSetInput>;
  where: StatTournamentsBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateStatTournamentsByPkArgs = {
  _inc?: InputMaybe<StatTournamentsIncInput>;
  _set?: InputMaybe<StatTournamentsSetInput>;
  pk_columns: StatTournamentsPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateStatTournamentsManyArgs = {
  updates: Array<StatTournamentsUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateStatusGamesArgs = {
  _set?: InputMaybe<StatusGamesSetInput>;
  where: StatusGamesBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateStatusGamesByPkArgs = {
  _set?: InputMaybe<StatusGamesSetInput>;
  pk_columns: StatusGamesPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateStatusGamesManyArgs = {
  updates: Array<StatusGamesUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateStatusesArgs = {
  _set?: InputMaybe<StatusesSetInput>;
  where: StatusesBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateStatusesByPkArgs = {
  _set?: InputMaybe<StatusesSetInput>;
  pk_columns: StatusesPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateStatusesManyArgs = {
  updates: Array<StatusesUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateTicketsArgs = {
  _inc?: InputMaybe<TicketsIncInput>;
  _set?: InputMaybe<TicketsSetInput>;
  where: TicketsBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateTicketsByPkArgs = {
  _inc?: InputMaybe<TicketsIncInput>;
  _set?: InputMaybe<TicketsSetInput>;
  pk_columns: TicketsPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateTicketsManyArgs = {
  updates: Array<TicketsUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateTicketsStatusArgs = {
  _set?: InputMaybe<TicketsStatusSetInput>;
  where: TicketsStatusBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateTicketsStatusByPkArgs = {
  _set?: InputMaybe<TicketsStatusSetInput>;
  pk_columns: TicketsStatusPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateTicketsStatusManyArgs = {
  updates: Array<TicketsStatusUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateTournamentArgs = {
  _inc?: InputMaybe<TournamentsIncInput>;
  _set?: InputMaybe<TournamentsSetInput>;
  pk_columns: TournamentsPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateTournamentResultStatusesArgs = {
  _set?: InputMaybe<TournamentResultStatusesSetInput>;
  where: TournamentResultStatusesBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateTournamentResultStatusesByPkArgs = {
  _set?: InputMaybe<TournamentResultStatusesSetInput>;
  pk_columns: TournamentResultStatusesPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateTournamentResultStatusesManyArgs = {
  updates: Array<TournamentResultStatusesUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateTournamentResultsArgs = {
  _append?: InputMaybe<TournamentResultsAppendInput>;
  _deleteAtPath?: InputMaybe<TournamentResultsDeleteAtPathInput>;
  _deleteElem?: InputMaybe<TournamentResultsDeleteElemInput>;
  _deleteKey?: InputMaybe<TournamentResultsDeleteKeyInput>;
  _inc?: InputMaybe<TournamentResultsIncInput>;
  _prepend?: InputMaybe<TournamentResultsPrependInput>;
  _set?: InputMaybe<TournamentResultsSetInput>;
  where: TournamentResultsBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateTournamentResultsByPkArgs = {
  _append?: InputMaybe<TournamentResultsAppendInput>;
  _deleteAtPath?: InputMaybe<TournamentResultsDeleteAtPathInput>;
  _deleteElem?: InputMaybe<TournamentResultsDeleteElemInput>;
  _deleteKey?: InputMaybe<TournamentResultsDeleteKeyInput>;
  _inc?: InputMaybe<TournamentResultsIncInput>;
  _prepend?: InputMaybe<TournamentResultsPrependInput>;
  _set?: InputMaybe<TournamentResultsSetInput>;
  pk_columns: TournamentResultsPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateTournamentResultsManyArgs = {
  updates: Array<TournamentResultsUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateTournamentsArgs = {
  _inc?: InputMaybe<TournamentsIncInput>;
  _set?: InputMaybe<TournamentsSetInput>;
  where: TournamentsBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateTournamentsManyArgs = {
  updates: Array<TournamentsUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateTransactionUserTypesArgs = {
  _set?: InputMaybe<TransactionUserTypesSetInput>;
  where: TransactionUserTypesBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateTransactionUserTypesByPkArgs = {
  _set?: InputMaybe<TransactionUserTypesSetInput>;
  pk_columns: TransactionUserTypesPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateTransactionUserTypesManyArgs = {
  updates: Array<TransactionUserTypesUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateTransactionsArgs = {
  _inc?: InputMaybe<TransactionsIncInput>;
  _set?: InputMaybe<TransactionsSetInput>;
  where: TransactionsBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateTransactionsByPkArgs = {
  _inc?: InputMaybe<TransactionsIncInput>;
  _set?: InputMaybe<TransactionsSetInput>;
  pk_columns: TransactionsPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateTransactionsManyArgs = {
  updates: Array<TransactionsUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateUserShareBuysArgs = {
  _inc?: InputMaybe<UserShareBuysIncInput>;
  _set?: InputMaybe<UserShareBuysSetInput>;
  where: UserShareBuysBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateUserShareBuysByPkArgs = {
  _inc?: InputMaybe<UserShareBuysIncInput>;
  _set?: InputMaybe<UserShareBuysSetInput>;
  pk_columns: UserShareBuysPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateUserShareBuysManyArgs = {
  updates: Array<UserShareBuysUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateUsersArgs = {
  _inc?: InputMaybe<UsersIncInput>;
  _set?: InputMaybe<UsersSetInput>;
  where: UsersBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateUsersByPkArgs = {
  _inc?: InputMaybe<UsersIncInput>;
  _set?: InputMaybe<UsersSetInput>;
  pk_columns: UsersPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateUsersManyArgs = {
  updates: Array<UsersUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateVenueArgs = {
  _inc?: InputMaybe<VenuesIncInput>;
  _set?: InputMaybe<VenuesSetInput>;
  pk_columns: VenuesPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateVenueUsersArgs = {
  _inc?: InputMaybe<VenueUsersIncInput>;
  _set?: InputMaybe<VenueUsersSetInput>;
  where: VenueUsersBoolExp;
};

/** mutation root */
export type Mutation_RootUpdateVenueUsersByPkArgs = {
  _inc?: InputMaybe<VenueUsersIncInput>;
  _set?: InputMaybe<VenueUsersSetInput>;
  pk_columns: VenueUsersPkColumnsInput;
};

/** mutation root */
export type Mutation_RootUpdateVenueUsersManyArgs = {
  updates: Array<VenueUsersUpdates>;
};

/** mutation root */
export type Mutation_RootUpdateVenuesArgs = {
  _inc?: InputMaybe<VenuesIncInput>;
  _set?: InputMaybe<VenuesSetInput>;
  where: VenuesBoolExp;
};

export type Payment_Method_Banks_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Payment_Method_Banks_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Payment_Method_Banks_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Payment_Method_Banks_Aggregate_Bool_Exp_Count>;
};

export type Payment_Method_Banks_Aggregate_Bool_Exp_Bool_And = {
  arguments: Payment_Method_Banks_Select_Column_Payment_Method_Banks_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<PaymentMethodBanksBoolExp>;
  predicate: BooleanComparisonExp;
};

export type Payment_Method_Banks_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Payment_Method_Banks_Select_Column_Payment_Method_Banks_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<PaymentMethodBanksBoolExp>;
  predicate: BooleanComparisonExp;
};

export type Payment_Method_Banks_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<PaymentMethodBanksSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<PaymentMethodBanksBoolExp>;
  predicate: IntComparisonExp;
};

/** order by avg() on columns of table "payment_method_banks" */
export type Payment_Method_Banks_Avg_Order_By = {
  countryId?: InputMaybe<OrderBy>;
  currencyId?: InputMaybe<OrderBy>;
  depositExchangeRate?: InputMaybe<OrderBy>;
  depositFee?: InputMaybe<OrderBy>;
  depositMax?: InputMaybe<OrderBy>;
  depositMin?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  paymentMethodId?: InputMaybe<OrderBy>;
  telegramGroup?: InputMaybe<OrderBy>;
  withdrawExchangeRate?: InputMaybe<OrderBy>;
  withdrawFee?: InputMaybe<OrderBy>;
  withdrawMax?: InputMaybe<OrderBy>;
  withdrawMin?: InputMaybe<OrderBy>;
};

/** order by max() on columns of table "payment_method_banks" */
export type Payment_Method_Banks_Max_Order_By = {
  country?: InputMaybe<OrderBy>;
  countryId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  currencyId?: InputMaybe<OrderBy>;
  depositCurrency?: InputMaybe<OrderBy>;
  depositDetails?: InputMaybe<OrderBy>;
  depositExchangeRate?: InputMaybe<OrderBy>;
  depositFee?: InputMaybe<OrderBy>;
  depositMax?: InputMaybe<OrderBy>;
  depositMin?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  paymentMethodId?: InputMaybe<OrderBy>;
  telegramAdmins?: InputMaybe<OrderBy>;
  telegramGroup?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  withdrawDetails?: InputMaybe<OrderBy>;
  withdrawExchangeRate?: InputMaybe<OrderBy>;
  withdrawFee?: InputMaybe<OrderBy>;
  withdrawMax?: InputMaybe<OrderBy>;
  withdrawMin?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "payment_method_banks" */
export type Payment_Method_Banks_Min_Order_By = {
  country?: InputMaybe<OrderBy>;
  countryId?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  currencyId?: InputMaybe<OrderBy>;
  depositCurrency?: InputMaybe<OrderBy>;
  depositDetails?: InputMaybe<OrderBy>;
  depositExchangeRate?: InputMaybe<OrderBy>;
  depositFee?: InputMaybe<OrderBy>;
  depositMax?: InputMaybe<OrderBy>;
  depositMin?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  paymentMethodId?: InputMaybe<OrderBy>;
  telegramAdmins?: InputMaybe<OrderBy>;
  telegramGroup?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  withdrawDetails?: InputMaybe<OrderBy>;
  withdrawExchangeRate?: InputMaybe<OrderBy>;
  withdrawFee?: InputMaybe<OrderBy>;
  withdrawMax?: InputMaybe<OrderBy>;
  withdrawMin?: InputMaybe<OrderBy>;
};

/** select "payment_method_banks_aggregate_bool_exp_bool_and_arguments_columns" columns of table "payment_method_banks" */
export enum Payment_Method_Banks_Select_Column_Payment_Method_Banks_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Deposit = 'deposit',
  /** column name */
  Withdraw = 'withdraw',
}

/** select "payment_method_banks_aggregate_bool_exp_bool_or_arguments_columns" columns of table "payment_method_banks" */
export enum Payment_Method_Banks_Select_Column_Payment_Method_Banks_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Deposit = 'deposit',
  /** column name */
  Withdraw = 'withdraw',
}

/** order by stddev() on columns of table "payment_method_banks" */
export type Payment_Method_Banks_Stddev_Order_By = {
  countryId?: InputMaybe<OrderBy>;
  currencyId?: InputMaybe<OrderBy>;
  depositExchangeRate?: InputMaybe<OrderBy>;
  depositFee?: InputMaybe<OrderBy>;
  depositMax?: InputMaybe<OrderBy>;
  depositMin?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  paymentMethodId?: InputMaybe<OrderBy>;
  telegramGroup?: InputMaybe<OrderBy>;
  withdrawExchangeRate?: InputMaybe<OrderBy>;
  withdrawFee?: InputMaybe<OrderBy>;
  withdrawMax?: InputMaybe<OrderBy>;
  withdrawMin?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "payment_method_banks" */
export type Payment_Method_Banks_Stddev_Pop_Order_By = {
  countryId?: InputMaybe<OrderBy>;
  currencyId?: InputMaybe<OrderBy>;
  depositExchangeRate?: InputMaybe<OrderBy>;
  depositFee?: InputMaybe<OrderBy>;
  depositMax?: InputMaybe<OrderBy>;
  depositMin?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  paymentMethodId?: InputMaybe<OrderBy>;
  telegramGroup?: InputMaybe<OrderBy>;
  withdrawExchangeRate?: InputMaybe<OrderBy>;
  withdrawFee?: InputMaybe<OrderBy>;
  withdrawMax?: InputMaybe<OrderBy>;
  withdrawMin?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "payment_method_banks" */
export type Payment_Method_Banks_Stddev_Samp_Order_By = {
  countryId?: InputMaybe<OrderBy>;
  currencyId?: InputMaybe<OrderBy>;
  depositExchangeRate?: InputMaybe<OrderBy>;
  depositFee?: InputMaybe<OrderBy>;
  depositMax?: InputMaybe<OrderBy>;
  depositMin?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  paymentMethodId?: InputMaybe<OrderBy>;
  telegramGroup?: InputMaybe<OrderBy>;
  withdrawExchangeRate?: InputMaybe<OrderBy>;
  withdrawFee?: InputMaybe<OrderBy>;
  withdrawMax?: InputMaybe<OrderBy>;
  withdrawMin?: InputMaybe<OrderBy>;
};

/** order by sum() on columns of table "payment_method_banks" */
export type Payment_Method_Banks_Sum_Order_By = {
  countryId?: InputMaybe<OrderBy>;
  currencyId?: InputMaybe<OrderBy>;
  depositExchangeRate?: InputMaybe<OrderBy>;
  depositFee?: InputMaybe<OrderBy>;
  depositMax?: InputMaybe<OrderBy>;
  depositMin?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  paymentMethodId?: InputMaybe<OrderBy>;
  telegramGroup?: InputMaybe<OrderBy>;
  withdrawExchangeRate?: InputMaybe<OrderBy>;
  withdrawFee?: InputMaybe<OrderBy>;
  withdrawMax?: InputMaybe<OrderBy>;
  withdrawMin?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "payment_method_banks" */
export type Payment_Method_Banks_Var_Pop_Order_By = {
  countryId?: InputMaybe<OrderBy>;
  currencyId?: InputMaybe<OrderBy>;
  depositExchangeRate?: InputMaybe<OrderBy>;
  depositFee?: InputMaybe<OrderBy>;
  depositMax?: InputMaybe<OrderBy>;
  depositMin?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  paymentMethodId?: InputMaybe<OrderBy>;
  telegramGroup?: InputMaybe<OrderBy>;
  withdrawExchangeRate?: InputMaybe<OrderBy>;
  withdrawFee?: InputMaybe<OrderBy>;
  withdrawMax?: InputMaybe<OrderBy>;
  withdrawMin?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "payment_method_banks" */
export type Payment_Method_Banks_Var_Samp_Order_By = {
  countryId?: InputMaybe<OrderBy>;
  currencyId?: InputMaybe<OrderBy>;
  depositExchangeRate?: InputMaybe<OrderBy>;
  depositFee?: InputMaybe<OrderBy>;
  depositMax?: InputMaybe<OrderBy>;
  depositMin?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  paymentMethodId?: InputMaybe<OrderBy>;
  telegramGroup?: InputMaybe<OrderBy>;
  withdrawExchangeRate?: InputMaybe<OrderBy>;
  withdrawFee?: InputMaybe<OrderBy>;
  withdrawMax?: InputMaybe<OrderBy>;
  withdrawMin?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "payment_method_banks" */
export type Payment_Method_Banks_Variance_Order_By = {
  countryId?: InputMaybe<OrderBy>;
  currencyId?: InputMaybe<OrderBy>;
  depositExchangeRate?: InputMaybe<OrderBy>;
  depositFee?: InputMaybe<OrderBy>;
  depositMax?: InputMaybe<OrderBy>;
  depositMin?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  paymentMethodId?: InputMaybe<OrderBy>;
  telegramGroup?: InputMaybe<OrderBy>;
  withdrawExchangeRate?: InputMaybe<OrderBy>;
  withdrawFee?: InputMaybe<OrderBy>;
  withdrawMax?: InputMaybe<OrderBy>;
  withdrawMin?: InputMaybe<OrderBy>;
};

export type PlayerRegisterInput = {
  about?: InputMaybe<Scalars['String']['input']>;
  birthday?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  facebook?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<GenderChoice>;
  globalPokerIndexUrl?: InputMaybe<Scalars['String']['input']>;
  idImg?: InputMaybe<Scalars['String']['input']>;
  nationality?: InputMaybe<Scalars['String']['input']>;
  pokerName?: InputMaybe<Scalars['String']['input']>;
  profileImg?: InputMaybe<Scalars['String']['input']>;
  realName?: InputMaybe<Scalars['String']['input']>;
  residence?: InputMaybe<Scalars['String']['input']>;
  theHendonMobUrl?: InputMaybe<Scalars['String']['input']>;
  trackerUrl?: InputMaybe<Scalars['String']['input']>;
  twitter?: InputMaybe<Scalars['String']['input']>;
};

export enum PlayerShareAction {
  BuyBullet = 'buy_bullet',
  Cancelled = 'cancelled',
  Paid = 'paid',
  PlayerOut = 'player_out',
  Won = 'won',
}

export type PlayerShareResultOutput = {
  __typename?: 'playerShareResultOutput';
  id?: Maybe<Scalars['String']['output']>;
  message: Scalars['String']['output'];
  playerPayout?: Maybe<Scalars['Float']['output']>;
  stake3Payout?: Maybe<Scalars['Float']['output']>;
  stake3PayoutUSD?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<GeneralStatus>;
};

export enum PlayerStatus {
  Active = 'active',
  Deleted = 'deleted',
  Inactive = 'inactive',
  Pending = 'pending',
}

export type Player_Followers_View_Aggregate_Bool_Exp = {
  count?: InputMaybe<Player_Followers_View_Aggregate_Bool_Exp_Count>;
};

export type Player_Followers_View_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<PlayerFollowersViewSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<PlayerFollowersViewBoolExp>;
  predicate: IntComparisonExp;
};

/** order by avg() on columns of table "player_followers_view" */
export type Player_Followers_View_Avg_Order_By = {
  id?: InputMaybe<OrderBy>;
};

/** order by max() on columns of table "player_followers_view" */
export type Player_Followers_View_Max_Order_By = {
  avatar?: InputMaybe<OrderBy>;
  email?: InputMaybe<OrderBy>;
  erc20Address?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  playerId?: InputMaybe<OrderBy>;
  username?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "player_followers_view" */
export type Player_Followers_View_Min_Order_By = {
  avatar?: InputMaybe<OrderBy>;
  email?: InputMaybe<OrderBy>;
  erc20Address?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  playerId?: InputMaybe<OrderBy>;
  username?: InputMaybe<OrderBy>;
};

/** order by stddev() on columns of table "player_followers_view" */
export type Player_Followers_View_Stddev_Order_By = {
  id?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "player_followers_view" */
export type Player_Followers_View_Stddev_Pop_Order_By = {
  id?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "player_followers_view" */
export type Player_Followers_View_Stddev_Samp_Order_By = {
  id?: InputMaybe<OrderBy>;
};

/** order by sum() on columns of table "player_followers_view" */
export type Player_Followers_View_Sum_Order_By = {
  id?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "player_followers_view" */
export type Player_Followers_View_Var_Pop_Order_By = {
  id?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "player_followers_view" */
export type Player_Followers_View_Var_Samp_Order_By = {
  id?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "player_followers_view" */
export type Player_Followers_View_Variance_Order_By = {
  id?: InputMaybe<OrderBy>;
};

export type Player_Shares_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Player_Shares_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Player_Shares_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Player_Shares_Aggregate_Bool_Exp_Count>;
};

export type Player_Shares_Aggregate_Bool_Exp_Bool_And = {
  arguments: Player_Shares_Select_Column_Player_Shares_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<PlayerSharesBoolExp>;
  predicate: BooleanComparisonExp;
};

export type Player_Shares_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Player_Shares_Select_Column_Player_Shares_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<PlayerSharesBoolExp>;
  predicate: BooleanComparisonExp;
};

export type Player_Shares_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<PlayerSharesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<PlayerSharesBoolExp>;
  predicate: IntComparisonExp;
};

/** order by avg() on columns of table "player_shares" */
export type Player_Shares_Avg_Order_By = {
  bullets?: InputMaybe<OrderBy>;
  markUp?: InputMaybe<OrderBy>;
  maxPerUserShare?: InputMaybe<OrderBy>;
  sharesToSell?: InputMaybe<OrderBy>;
  sort?: InputMaybe<OrderBy>;
  tournamentResultId?: InputMaybe<OrderBy>;
};

/** order by max() on columns of table "player_shares" */
export type Player_Shares_Max_Order_By = {
  activeDate?: InputMaybe<OrderBy>;
  bullets?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  markUp?: InputMaybe<OrderBy>;
  maxPerUserShare?: InputMaybe<OrderBy>;
  note?: InputMaybe<OrderBy>;
  password?: InputMaybe<OrderBy>;
  playerId?: InputMaybe<OrderBy>;
  sharesToSell?: InputMaybe<OrderBy>;
  slug?: InputMaybe<OrderBy>;
  sort?: InputMaybe<OrderBy>;
  termsAcceptedDate?: InputMaybe<OrderBy>;
  tournamentId?: InputMaybe<OrderBy>;
  tournamentResultId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "player_shares" */
export type Player_Shares_Min_Order_By = {
  activeDate?: InputMaybe<OrderBy>;
  bullets?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  markUp?: InputMaybe<OrderBy>;
  maxPerUserShare?: InputMaybe<OrderBy>;
  note?: InputMaybe<OrderBy>;
  password?: InputMaybe<OrderBy>;
  playerId?: InputMaybe<OrderBy>;
  sharesToSell?: InputMaybe<OrderBy>;
  slug?: InputMaybe<OrderBy>;
  sort?: InputMaybe<OrderBy>;
  termsAcceptedDate?: InputMaybe<OrderBy>;
  tournamentId?: InputMaybe<OrderBy>;
  tournamentResultId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** select "player_shares_aggregate_bool_exp_bool_and_arguments_columns" columns of table "player_shares" */
export enum Player_Shares_Select_Column_Player_Shares_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  AllowCancel = 'allowCancel',
  /** column name */
  PasswordRequired = 'passwordRequired',
  /** column name */
  ShareDilution = 'shareDilution',
}

/** select "player_shares_aggregate_bool_exp_bool_or_arguments_columns" columns of table "player_shares" */
export enum Player_Shares_Select_Column_Player_Shares_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  AllowCancel = 'allowCancel',
  /** column name */
  PasswordRequired = 'passwordRequired',
  /** column name */
  ShareDilution = 'shareDilution',
}

/** order by stddev() on columns of table "player_shares" */
export type Player_Shares_Stddev_Order_By = {
  bullets?: InputMaybe<OrderBy>;
  markUp?: InputMaybe<OrderBy>;
  maxPerUserShare?: InputMaybe<OrderBy>;
  sharesToSell?: InputMaybe<OrderBy>;
  sort?: InputMaybe<OrderBy>;
  tournamentResultId?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "player_shares" */
export type Player_Shares_Stddev_Pop_Order_By = {
  bullets?: InputMaybe<OrderBy>;
  markUp?: InputMaybe<OrderBy>;
  maxPerUserShare?: InputMaybe<OrderBy>;
  sharesToSell?: InputMaybe<OrderBy>;
  sort?: InputMaybe<OrderBy>;
  tournamentResultId?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "player_shares" */
export type Player_Shares_Stddev_Samp_Order_By = {
  bullets?: InputMaybe<OrderBy>;
  markUp?: InputMaybe<OrderBy>;
  maxPerUserShare?: InputMaybe<OrderBy>;
  sharesToSell?: InputMaybe<OrderBy>;
  sort?: InputMaybe<OrderBy>;
  tournamentResultId?: InputMaybe<OrderBy>;
};

/** order by sum() on columns of table "player_shares" */
export type Player_Shares_Sum_Order_By = {
  bullets?: InputMaybe<OrderBy>;
  markUp?: InputMaybe<OrderBy>;
  maxPerUserShare?: InputMaybe<OrderBy>;
  sharesToSell?: InputMaybe<OrderBy>;
  sort?: InputMaybe<OrderBy>;
  tournamentResultId?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "player_shares" */
export type Player_Shares_Var_Pop_Order_By = {
  bullets?: InputMaybe<OrderBy>;
  markUp?: InputMaybe<OrderBy>;
  maxPerUserShare?: InputMaybe<OrderBy>;
  sharesToSell?: InputMaybe<OrderBy>;
  sort?: InputMaybe<OrderBy>;
  tournamentResultId?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "player_shares" */
export type Player_Shares_Var_Samp_Order_By = {
  bullets?: InputMaybe<OrderBy>;
  markUp?: InputMaybe<OrderBy>;
  maxPerUserShare?: InputMaybe<OrderBy>;
  sharesToSell?: InputMaybe<OrderBy>;
  sort?: InputMaybe<OrderBy>;
  tournamentResultId?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "player_shares" */
export type Player_Shares_Variance_Order_By = {
  bullets?: InputMaybe<OrderBy>;
  markUp?: InputMaybe<OrderBy>;
  maxPerUserShare?: InputMaybe<OrderBy>;
  sharesToSell?: InputMaybe<OrderBy>;
  sort?: InputMaybe<OrderBy>;
  tournamentResultId?: InputMaybe<OrderBy>;
};

export type Players_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Players_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Players_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Players_Aggregate_Bool_Exp_Count>;
};

export type Players_Aggregate_Bool_Exp_Bool_And = {
  arguments: Players_Select_Column_Players_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<PlayersBoolExp>;
  predicate: BooleanComparisonExp;
};

export type Players_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Players_Select_Column_Players_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<PlayersBoolExp>;
  predicate: BooleanComparisonExp;
};

export type Players_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<PlayersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<PlayersBoolExp>;
  predicate: IntComparisonExp;
};

/** order by avg() on columns of table "players" */
export type Players_Avg_Order_By = {
  actionsSold?: InputMaybe<OrderBy>;
  allTimeList?: InputMaybe<OrderBy>;
  allTimeListCountryPlace?: InputMaybe<OrderBy>;
  followersCount?: InputMaybe<OrderBy>;
  gpiRanking?: InputMaybe<OrderBy>;
  highestRoi?: InputMaybe<OrderBy>;
  highestWinning?: InputMaybe<OrderBy>;
  sort?: InputMaybe<OrderBy>;
  totalLiveEarning?: InputMaybe<OrderBy>;
  totalWinnings?: InputMaybe<OrderBy>;
};

/** order by max() on columns of table "players" */
export type Players_Max_Order_By = {
  actionsSold?: InputMaybe<OrderBy>;
  allTimeList?: InputMaybe<OrderBy>;
  allTimeListCountry?: InputMaybe<OrderBy>;
  allTimeListCountryPlace?: InputMaybe<OrderBy>;
  birthday?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  facebook?: InputMaybe<OrderBy>;
  followersCount?: InputMaybe<OrderBy>;
  gender?: InputMaybe<OrderBy>;
  globalpokerindexUrl?: InputMaybe<OrderBy>;
  gpiRanking?: InputMaybe<OrderBy>;
  highestRoi?: InputMaybe<OrderBy>;
  highestWinning?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  image?: InputMaybe<OrderBy>;
  instagram?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  nationality?: InputMaybe<OrderBy>;
  realName?: InputMaybe<OrderBy>;
  residence?: InputMaybe<OrderBy>;
  slug?: InputMaybe<OrderBy>;
  sort?: InputMaybe<OrderBy>;
  thehendonmobUrl?: InputMaybe<OrderBy>;
  totalLiveEarning?: InputMaybe<OrderBy>;
  totalWinnings?: InputMaybe<OrderBy>;
  twitter?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "players" */
export type Players_Min_Order_By = {
  actionsSold?: InputMaybe<OrderBy>;
  allTimeList?: InputMaybe<OrderBy>;
  allTimeListCountry?: InputMaybe<OrderBy>;
  allTimeListCountryPlace?: InputMaybe<OrderBy>;
  birthday?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  facebook?: InputMaybe<OrderBy>;
  followersCount?: InputMaybe<OrderBy>;
  gender?: InputMaybe<OrderBy>;
  globalpokerindexUrl?: InputMaybe<OrderBy>;
  gpiRanking?: InputMaybe<OrderBy>;
  highestRoi?: InputMaybe<OrderBy>;
  highestWinning?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  image?: InputMaybe<OrderBy>;
  instagram?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  nationality?: InputMaybe<OrderBy>;
  realName?: InputMaybe<OrderBy>;
  residence?: InputMaybe<OrderBy>;
  slug?: InputMaybe<OrderBy>;
  sort?: InputMaybe<OrderBy>;
  thehendonmobUrl?: InputMaybe<OrderBy>;
  totalLiveEarning?: InputMaybe<OrderBy>;
  totalWinnings?: InputMaybe<OrderBy>;
  twitter?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** select "players_aggregate_bool_exp_bool_and_arguments_columns" columns of table "players" */
export enum Players_Select_Column_Players_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Verified = 'verified',
}

/** select "players_aggregate_bool_exp_bool_or_arguments_columns" columns of table "players" */
export enum Players_Select_Column_Players_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Verified = 'verified',
}

/** order by stddev() on columns of table "players" */
export type Players_Stddev_Order_By = {
  actionsSold?: InputMaybe<OrderBy>;
  allTimeList?: InputMaybe<OrderBy>;
  allTimeListCountryPlace?: InputMaybe<OrderBy>;
  followersCount?: InputMaybe<OrderBy>;
  gpiRanking?: InputMaybe<OrderBy>;
  highestRoi?: InputMaybe<OrderBy>;
  highestWinning?: InputMaybe<OrderBy>;
  sort?: InputMaybe<OrderBy>;
  totalLiveEarning?: InputMaybe<OrderBy>;
  totalWinnings?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "players" */
export type Players_Stddev_Pop_Order_By = {
  actionsSold?: InputMaybe<OrderBy>;
  allTimeList?: InputMaybe<OrderBy>;
  allTimeListCountryPlace?: InputMaybe<OrderBy>;
  followersCount?: InputMaybe<OrderBy>;
  gpiRanking?: InputMaybe<OrderBy>;
  highestRoi?: InputMaybe<OrderBy>;
  highestWinning?: InputMaybe<OrderBy>;
  sort?: InputMaybe<OrderBy>;
  totalLiveEarning?: InputMaybe<OrderBy>;
  totalWinnings?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "players" */
export type Players_Stddev_Samp_Order_By = {
  actionsSold?: InputMaybe<OrderBy>;
  allTimeList?: InputMaybe<OrderBy>;
  allTimeListCountryPlace?: InputMaybe<OrderBy>;
  followersCount?: InputMaybe<OrderBy>;
  gpiRanking?: InputMaybe<OrderBy>;
  highestRoi?: InputMaybe<OrderBy>;
  highestWinning?: InputMaybe<OrderBy>;
  sort?: InputMaybe<OrderBy>;
  totalLiveEarning?: InputMaybe<OrderBy>;
  totalWinnings?: InputMaybe<OrderBy>;
};

/** order by sum() on columns of table "players" */
export type Players_Sum_Order_By = {
  actionsSold?: InputMaybe<OrderBy>;
  allTimeList?: InputMaybe<OrderBy>;
  allTimeListCountryPlace?: InputMaybe<OrderBy>;
  followersCount?: InputMaybe<OrderBy>;
  gpiRanking?: InputMaybe<OrderBy>;
  highestRoi?: InputMaybe<OrderBy>;
  highestWinning?: InputMaybe<OrderBy>;
  sort?: InputMaybe<OrderBy>;
  totalLiveEarning?: InputMaybe<OrderBy>;
  totalWinnings?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "players" */
export type Players_Var_Pop_Order_By = {
  actionsSold?: InputMaybe<OrderBy>;
  allTimeList?: InputMaybe<OrderBy>;
  allTimeListCountryPlace?: InputMaybe<OrderBy>;
  followersCount?: InputMaybe<OrderBy>;
  gpiRanking?: InputMaybe<OrderBy>;
  highestRoi?: InputMaybe<OrderBy>;
  highestWinning?: InputMaybe<OrderBy>;
  sort?: InputMaybe<OrderBy>;
  totalLiveEarning?: InputMaybe<OrderBy>;
  totalWinnings?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "players" */
export type Players_Var_Samp_Order_By = {
  actionsSold?: InputMaybe<OrderBy>;
  allTimeList?: InputMaybe<OrderBy>;
  allTimeListCountryPlace?: InputMaybe<OrderBy>;
  followersCount?: InputMaybe<OrderBy>;
  gpiRanking?: InputMaybe<OrderBy>;
  highestRoi?: InputMaybe<OrderBy>;
  highestWinning?: InputMaybe<OrderBy>;
  sort?: InputMaybe<OrderBy>;
  totalLiveEarning?: InputMaybe<OrderBy>;
  totalWinnings?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "players" */
export type Players_Variance_Order_By = {
  actionsSold?: InputMaybe<OrderBy>;
  allTimeList?: InputMaybe<OrderBy>;
  allTimeListCountryPlace?: InputMaybe<OrderBy>;
  followersCount?: InputMaybe<OrderBy>;
  gpiRanking?: InputMaybe<OrderBy>;
  highestRoi?: InputMaybe<OrderBy>;
  highestWinning?: InputMaybe<OrderBy>;
  sort?: InputMaybe<OrderBy>;
  totalLiveEarning?: InputMaybe<OrderBy>;
  totalWinnings?: InputMaybe<OrderBy>;
};

export enum ProviderEnum {
  Erc20 = 'erc20',
  Error = 'error',
  FacebookCom = 'facebook_com',
  GoogleCom = 'google_com',
  Password = 'password',
}

export type Query_Root = {
  __typename?: 'query_root';
  /** fetch data from the table: "blockchain_currencies" */
  blockchainCurrencies: Array<BlockchainCurrencies>;
  /** An aggregate relationship */
  blockchainCurrenciesAggregate: BlockchainCurrenciesAggregate;
  /** fetch data from the table: "blockchain_currencies" using primary key columns */
  blockchainCurrenciesByPk?: Maybe<BlockchainCurrencies>;
  /** fetch data from the table: "blockchains" */
  blockchains: Array<Blockchains>;
  /** fetch aggregated fields from the table: "blockchains" */
  blockchainsAggregate: BlockchainsAggregate;
  /** fetch data from the table: "blockchains" using primary key columns */
  blockchainsByPk?: Maybe<Blockchains>;
  /** fetch data from the table: "countries" */
  countries: Array<Countries>;
  /** fetch aggregated fields from the table: "countries" */
  countriesAggregate: CountriesAggregate;
  /** fetch data from the table: "countries" using primary key columns */
  countriesByPk?: Maybe<Countries>;
  /** An array relationship */
  currencies: Array<Currencies>;
  /** An aggregate relationship */
  currenciesAggregate: CurrenciesAggregate;
  /** fetch data from the table: "currencies" using primary key columns */
  currenciesByPk?: Maybe<Currencies>;
  downloadEventTransactions?: Maybe<DownloadFileOutput>;
  /** fetch data from the table: "events" using primary key columns */
  event?: Maybe<Events>;
  /** An array relationship */
  events: Array<Events>;
  /** An aggregate relationship */
  eventsAggregate: EventsAggregate;
  /** fetch data from the table: "followers" using primary key columns */
  follower?: Maybe<Followers>;
  /** An array relationship */
  followers: Array<Followers>;
  /** An aggregate relationship */
  followersAggregate: FollowersAggregate;
  getDepositAddress?: Maybe<DepositAddressOutput>;
  getDepositBankInfo?: Maybe<DepositBankOutput>;
  me?: Maybe<UserOutput>;
  /** fetch data from the table: "message_types" */
  messageTypes: Array<MessageTypes>;
  /** fetch aggregated fields from the table: "message_types" */
  messageTypesAggregate: MessageTypesAggregate;
  /** fetch data from the table: "message_types" using primary key columns */
  messageTypesByPk?: Maybe<MessageTypes>;
  /** fetch data from the table: "messages" */
  messages: Array<Messages>;
  /** fetch aggregated fields from the table: "messages" */
  messagesAggregate: MessagesAggregate;
  /** fetch data from the table: "messages" using primary key columns */
  messagesByPk?: Maybe<Messages>;
  /** fetch data from the table: "organiser_users" */
  organiserUsers: Array<OrganiserUsers>;
  /** fetch aggregated fields from the table: "organiser_users" */
  organiserUsersAggregate: OrganiserUsersAggregate;
  /** fetch data from the table: "organiser_users" using primary key columns */
  organiserUsersByPk?: Maybe<OrganiserUsers>;
  /** fetch data from the table: "organisers" */
  organisers: Array<Organisers>;
  /** fetch aggregated fields from the table: "organisers" */
  organisersAggregate: OrganisersAggregate;
  /** fetch data from the table: "organisers" using primary key columns */
  organisersByPk?: Maybe<Organisers>;
  /** fetch data from the table: "payment_method_banks" */
  paymentMethodBanks: Array<PaymentMethodBanks>;
  /** An aggregate relationship */
  paymentMethodBanksAggregate: PaymentMethodBanksAggregate;
  /** fetch data from the table: "payment_method_banks" using primary key columns */
  paymentMethodBanksByPk?: Maybe<PaymentMethodBanks>;
  /** fetch data from the table: "payment_methods" */
  paymentMethods: Array<PaymentMethods>;
  /** fetch aggregated fields from the table: "payment_methods" */
  paymentMethodsAggregate: PaymentMethodsAggregate;
  /** fetch data from the table: "payment_methods" using primary key columns */
  paymentMethodsByPk?: Maybe<PaymentMethods>;
  /** fetch data from the table: "payments" */
  payments: Array<Payments>;
  /** fetch aggregated fields from the table: "payments" */
  paymentsAggregate: PaymentsAggregate;
  /** fetch data from the table: "payments" using primary key columns */
  paymentsByPk?: Maybe<Payments>;
  /** fetch data from the table: "pending_txs" */
  pendingTxs: Array<PendingTxs>;
  /** fetch aggregated fields from the table: "pending_txs" */
  pendingTxsAggregate: PendingTxsAggregate;
  /** fetch data from the table: "pending_txs" using primary key columns */
  pendingTxsByPk?: Maybe<PendingTxs>;
  /** fetch data from the table: "players" using primary key columns */
  player?: Maybe<Players>;
  /** fetch data from the table: "player_followers" */
  playerFollowers: Array<PlayerFollowers>;
  /** fetch aggregated fields from the table: "player_followers" */
  playerFollowersAggregate: PlayerFollowersAggregate;
  /** fetch data from the table: "player_followers_view" */
  playerFollowersView: Array<PlayerFollowersView>;
  /** An aggregate relationship */
  playerFollowersViewAggregate: PlayerFollowersViewAggregate;
  /** fetch data from the table: "player_shares" using primary key columns */
  playerShare?: Maybe<PlayerShares>;
  /** fetch data from the table: "player_shares" */
  playerShares: Array<PlayerShares>;
  /** An aggregate relationship */
  playerSharesAggregate: PlayerSharesAggregate;
  /** fetch data from the table: "player_shares_bought" */
  playerSharesBought: Array<PlayerSharesBought>;
  /** fetch aggregated fields from the table: "player_shares_bought" */
  playerSharesBoughtAggregate: PlayerSharesBoughtAggregate;
  /** An array relationship */
  players: Array<Players>;
  /** An aggregate relationship */
  playersAggregate: PlayersAggregate;
  /** fetch data from the table: "stat_events" */
  statEvents: Array<StatEvents>;
  /** fetch aggregated fields from the table: "stat_events" */
  statEventsAggregate: StatEventsAggregate;
  /** fetch data from the table: "stat_events" using primary key columns */
  statEventsByPk?: Maybe<StatEvents>;
  /** fetch data from the table: "stat_players" */
  statPlayers: Array<StatPlayers>;
  /** fetch aggregated fields from the table: "stat_players" */
  statPlayersAggregate: StatPlayersAggregate;
  /** fetch data from the table: "stat_players" using primary key columns */
  statPlayersByPk?: Maybe<StatPlayers>;
  /** fetch data from the table: "stat_top_players" */
  statTopPlayers: Array<StatTopPlayers>;
  /** fetch aggregated fields from the table: "stat_top_players" */
  statTopPlayersAggregate: StatTopPlayersAggregate;
  /** fetch data from the table: "stat_top_year_players" */
  statTopYearPlayers: Array<StatTopYearPlayers>;
  /** fetch aggregated fields from the table: "stat_top_year_players" */
  statTopYearPlayersAggregate: StatTopYearPlayersAggregate;
  /** fetch data from the table: "stat_tournament_results" */
  statTournamentResults: Array<StatTournamentResults>;
  /** An aggregate relationship */
  statTournamentResultsAggregate: StatTournamentResultsAggregate;
  /** fetch data from the table: "stat_tournament_results" using primary key columns */
  statTournamentResultsByPk?: Maybe<StatTournamentResults>;
  /** fetch data from the table: "stat_tournaments" */
  statTournaments: Array<StatTournaments>;
  /** fetch aggregated fields from the table: "stat_tournaments" */
  statTournamentsAggregate: StatTournamentsAggregate;
  /** fetch data from the table: "stat_tournaments" using primary key columns */
  statTournamentsByPk?: Maybe<StatTournaments>;
  /** fetch data from the table: "status_games" */
  statusGames: Array<StatusGames>;
  /** fetch aggregated fields from the table: "status_games" */
  statusGamesAggregate: StatusGamesAggregate;
  /** fetch data from the table: "status_games" using primary key columns */
  statusGamesByPk?: Maybe<StatusGames>;
  /** fetch data from the table: "statuses" */
  statuses: Array<Statuses>;
  /** fetch aggregated fields from the table: "statuses" */
  statusesAggregate: StatusesAggregate;
  /** fetch data from the table: "statuses" using primary key columns */
  statusesByPk?: Maybe<Statuses>;
  /** An array relationship */
  tickets: Array<Tickets>;
  /** An aggregate relationship */
  ticketsAggregate: TicketsAggregate;
  /** fetch data from the table: "tickets" using primary key columns */
  ticketsByPk?: Maybe<Tickets>;
  /** fetch data from the table: "tickets_status" */
  ticketsStatus: Array<TicketsStatus>;
  /** fetch aggregated fields from the table: "tickets_status" */
  ticketsStatusAggregate: TicketsStatusAggregate;
  /** fetch data from the table: "tickets_status" using primary key columns */
  ticketsStatusByPk?: Maybe<TicketsStatus>;
  /** fetch data from the table: "tournaments" using primary key columns */
  tournament?: Maybe<Tournaments>;
  /** fetch data from the table: "tournament_balances" */
  tournamentBalances: Array<TournamentBalances>;
  /** fetch aggregated fields from the table: "tournament_balances" */
  tournamentBalancesAggregate: TournamentBalancesAggregate;
  /** fetch data from the table: "tournament_result_statuses" */
  tournamentResultStatuses: Array<TournamentResultStatuses>;
  /** fetch aggregated fields from the table: "tournament_result_statuses" */
  tournamentResultStatusesAggregate: TournamentResultStatusesAggregate;
  /** fetch data from the table: "tournament_result_statuses" using primary key columns */
  tournamentResultStatusesByPk?: Maybe<TournamentResultStatuses>;
  /** fetch data from the table: "tournament_results" */
  tournamentResults: Array<TournamentResults>;
  /** An aggregate relationship */
  tournamentResultsAggregate: TournamentResultsAggregate;
  /** fetch data from the table: "tournament_results" using primary key columns */
  tournamentResultsByPk?: Maybe<TournamentResults>;
  /** An array relationship */
  tournaments: Array<Tournaments>;
  /** An aggregate relationship */
  tournamentsAggregate: TournamentsAggregate;
  /** fetch data from the table: "transaction_user_types" */
  transactionUserTypes: Array<TransactionUserTypes>;
  /** fetch aggregated fields from the table: "transaction_user_types" */
  transactionUserTypesAggregate: TransactionUserTypesAggregate;
  /** fetch data from the table: "transaction_user_types" using primary key columns */
  transactionUserTypesByPk?: Maybe<TransactionUserTypes>;
  /** An array relationship */
  transactions: Array<Transactions>;
  /** An aggregate relationship */
  transactionsAggregate: TransactionsAggregate;
  /** fetch data from the table: "transactions" using primary key columns */
  transactionsByPk?: Maybe<Transactions>;
  /** fetch data from the table: "user_balances" */
  userBalances: Array<UserBalances>;
  /** An aggregate relationship */
  userBalancesAggregate: UserBalancesAggregate;
  /** fetch data from the table: "user_share_buys" */
  userShareBuys: Array<UserShareBuys>;
  /** An aggregate relationship */
  userShareBuysAggregate: UserShareBuysAggregate;
  /** fetch data from the table: "user_share_buys" using primary key columns */
  userShareBuysByPk?: Maybe<UserShareBuys>;
  /** fetch data from the table: "users" */
  users: Array<Users>;
  /** fetch aggregated fields from the table: "users" */
  usersAggregate: UsersAggregate;
  /** fetch data from the table: "users" using primary key columns */
  usersByPk?: Maybe<Users>;
  /** fetch data from the table: "venues" using primary key columns */
  venue?: Maybe<Venues>;
  /** fetch data from the table: "venue_users" */
  venueUsers: Array<VenueUsers>;
  /** An aggregate relationship */
  venueUsersAggregate: VenueUsersAggregate;
  /** fetch data from the table: "venue_users" using primary key columns */
  venueUsersByPk?: Maybe<VenueUsers>;
  /** fetch data from the table: "venues" */
  venues: Array<Venues>;
  /** fetch aggregated fields from the table: "venues" */
  venuesAggregate: VenuesAggregate;
};

export type Query_RootBlockchainCurrenciesArgs = {
  distinctOn?: InputMaybe<Array<BlockchainCurrenciesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<BlockchainCurrenciesOrderBy>>;
  where?: InputMaybe<BlockchainCurrenciesBoolExp>;
};

export type Query_RootBlockchainCurrenciesAggregateArgs = {
  distinctOn?: InputMaybe<Array<BlockchainCurrenciesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<BlockchainCurrenciesOrderBy>>;
  where?: InputMaybe<BlockchainCurrenciesBoolExp>;
};

export type Query_RootBlockchainCurrenciesByPkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootBlockchainsArgs = {
  distinctOn?: InputMaybe<Array<BlockchainsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<BlockchainsOrderBy>>;
  where?: InputMaybe<BlockchainsBoolExp>;
};

export type Query_RootBlockchainsAggregateArgs = {
  distinctOn?: InputMaybe<Array<BlockchainsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<BlockchainsOrderBy>>;
  where?: InputMaybe<BlockchainsBoolExp>;
};

export type Query_RootBlockchainsByPkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootCountriesArgs = {
  distinctOn?: InputMaybe<Array<CountriesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CountriesOrderBy>>;
  where?: InputMaybe<CountriesBoolExp>;
};

export type Query_RootCountriesAggregateArgs = {
  distinctOn?: InputMaybe<Array<CountriesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CountriesOrderBy>>;
  where?: InputMaybe<CountriesBoolExp>;
};

export type Query_RootCountriesByPkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootCurrenciesArgs = {
  distinctOn?: InputMaybe<Array<CurrenciesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CurrenciesOrderBy>>;
  where?: InputMaybe<CurrenciesBoolExp>;
};

export type Query_RootCurrenciesAggregateArgs = {
  distinctOn?: InputMaybe<Array<CurrenciesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CurrenciesOrderBy>>;
  where?: InputMaybe<CurrenciesBoolExp>;
};

export type Query_RootCurrenciesByPkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootDownloadEventTransactionsArgs = {
  eventId: Scalars['String']['input'];
};

export type Query_RootEventArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootEventsArgs = {
  distinctOn?: InputMaybe<Array<EventsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EventsOrderBy>>;
  where?: InputMaybe<EventsBoolExp>;
};

export type Query_RootEventsAggregateArgs = {
  distinctOn?: InputMaybe<Array<EventsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EventsOrderBy>>;
  where?: InputMaybe<EventsBoolExp>;
};

export type Query_RootFollowerArgs = {
  id: Scalars['bigint']['input'];
};

export type Query_RootFollowersArgs = {
  distinctOn?: InputMaybe<Array<FollowersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FollowersOrderBy>>;
  where?: InputMaybe<FollowersBoolExp>;
};

export type Query_RootFollowersAggregateArgs = {
  distinctOn?: InputMaybe<Array<FollowersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FollowersOrderBy>>;
  where?: InputMaybe<FollowersBoolExp>;
};

export type Query_RootGetDepositBankInfoArgs = {
  amount: Scalars['Float']['input'];
  paymentMethodBankId: Scalars['Int']['input'];
};

export type Query_RootMessageTypesArgs = {
  distinctOn?: InputMaybe<Array<MessageTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<MessageTypesOrderBy>>;
  where?: InputMaybe<MessageTypesBoolExp>;
};

export type Query_RootMessageTypesAggregateArgs = {
  distinctOn?: InputMaybe<Array<MessageTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<MessageTypesOrderBy>>;
  where?: InputMaybe<MessageTypesBoolExp>;
};

export type Query_RootMessageTypesByPkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootMessagesArgs = {
  distinctOn?: InputMaybe<Array<MessagesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<MessagesOrderBy>>;
  where?: InputMaybe<MessagesBoolExp>;
};

export type Query_RootMessagesAggregateArgs = {
  distinctOn?: InputMaybe<Array<MessagesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<MessagesOrderBy>>;
  where?: InputMaybe<MessagesBoolExp>;
};

export type Query_RootMessagesByPkArgs = {
  id: Scalars['bigint']['input'];
};

export type Query_RootOrganiserUsersArgs = {
  distinctOn?: InputMaybe<Array<OrganiserUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<OrganiserUsersOrderBy>>;
  where?: InputMaybe<OrganiserUsersBoolExp>;
};

export type Query_RootOrganiserUsersAggregateArgs = {
  distinctOn?: InputMaybe<Array<OrganiserUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<OrganiserUsersOrderBy>>;
  where?: InputMaybe<OrganiserUsersBoolExp>;
};

export type Query_RootOrganiserUsersByPkArgs = {
  id: Scalars['bigint']['input'];
};

export type Query_RootOrganisersArgs = {
  distinctOn?: InputMaybe<Array<OrganisersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<OrganisersOrderBy>>;
  where?: InputMaybe<OrganisersBoolExp>;
};

export type Query_RootOrganisersAggregateArgs = {
  distinctOn?: InputMaybe<Array<OrganisersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<OrganisersOrderBy>>;
  where?: InputMaybe<OrganisersBoolExp>;
};

export type Query_RootOrganisersByPkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootPaymentMethodBanksArgs = {
  distinctOn?: InputMaybe<Array<PaymentMethodBanksSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PaymentMethodBanksOrderBy>>;
  where?: InputMaybe<PaymentMethodBanksBoolExp>;
};

export type Query_RootPaymentMethodBanksAggregateArgs = {
  distinctOn?: InputMaybe<Array<PaymentMethodBanksSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PaymentMethodBanksOrderBy>>;
  where?: InputMaybe<PaymentMethodBanksBoolExp>;
};

export type Query_RootPaymentMethodBanksByPkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootPaymentMethodsArgs = {
  distinctOn?: InputMaybe<Array<PaymentMethodsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PaymentMethodsOrderBy>>;
  where?: InputMaybe<PaymentMethodsBoolExp>;
};

export type Query_RootPaymentMethodsAggregateArgs = {
  distinctOn?: InputMaybe<Array<PaymentMethodsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PaymentMethodsOrderBy>>;
  where?: InputMaybe<PaymentMethodsBoolExp>;
};

export type Query_RootPaymentMethodsByPkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootPaymentsArgs = {
  distinctOn?: InputMaybe<Array<PaymentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PaymentsOrderBy>>;
  where?: InputMaybe<PaymentsBoolExp>;
};

export type Query_RootPaymentsAggregateArgs = {
  distinctOn?: InputMaybe<Array<PaymentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PaymentsOrderBy>>;
  where?: InputMaybe<PaymentsBoolExp>;
};

export type Query_RootPaymentsByPkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootPendingTxsArgs = {
  distinctOn?: InputMaybe<Array<PendingTxsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PendingTxsOrderBy>>;
  where?: InputMaybe<PendingTxsBoolExp>;
};

export type Query_RootPendingTxsAggregateArgs = {
  distinctOn?: InputMaybe<Array<PendingTxsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PendingTxsOrderBy>>;
  where?: InputMaybe<PendingTxsBoolExp>;
};

export type Query_RootPendingTxsByPkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootPlayerArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootPlayerFollowersArgs = {
  distinctOn?: InputMaybe<Array<PlayerFollowersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PlayerFollowersOrderBy>>;
  where?: InputMaybe<PlayerFollowersBoolExp>;
};

export type Query_RootPlayerFollowersAggregateArgs = {
  distinctOn?: InputMaybe<Array<PlayerFollowersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PlayerFollowersOrderBy>>;
  where?: InputMaybe<PlayerFollowersBoolExp>;
};

export type Query_RootPlayerFollowersViewArgs = {
  distinctOn?: InputMaybe<Array<PlayerFollowersViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PlayerFollowersViewOrderBy>>;
  where?: InputMaybe<PlayerFollowersViewBoolExp>;
};

export type Query_RootPlayerFollowersViewAggregateArgs = {
  distinctOn?: InputMaybe<Array<PlayerFollowersViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PlayerFollowersViewOrderBy>>;
  where?: InputMaybe<PlayerFollowersViewBoolExp>;
};

export type Query_RootPlayerShareArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootPlayerSharesArgs = {
  distinctOn?: InputMaybe<Array<PlayerSharesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PlayerSharesOrderBy>>;
  where?: InputMaybe<PlayerSharesBoolExp>;
};

export type Query_RootPlayerSharesAggregateArgs = {
  distinctOn?: InputMaybe<Array<PlayerSharesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PlayerSharesOrderBy>>;
  where?: InputMaybe<PlayerSharesBoolExp>;
};

export type Query_RootPlayerSharesBoughtArgs = {
  distinctOn?: InputMaybe<Array<PlayerSharesBoughtSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PlayerSharesBoughtOrderBy>>;
  where?: InputMaybe<PlayerSharesBoughtBoolExp>;
};

export type Query_RootPlayerSharesBoughtAggregateArgs = {
  distinctOn?: InputMaybe<Array<PlayerSharesBoughtSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PlayerSharesBoughtOrderBy>>;
  where?: InputMaybe<PlayerSharesBoughtBoolExp>;
};

export type Query_RootPlayersArgs = {
  distinctOn?: InputMaybe<Array<PlayersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PlayersOrderBy>>;
  where?: InputMaybe<PlayersBoolExp>;
};

export type Query_RootPlayersAggregateArgs = {
  distinctOn?: InputMaybe<Array<PlayersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PlayersOrderBy>>;
  where?: InputMaybe<PlayersBoolExp>;
};

export type Query_RootStatEventsArgs = {
  distinctOn?: InputMaybe<Array<StatEventsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<StatEventsOrderBy>>;
  where?: InputMaybe<StatEventsBoolExp>;
};

export type Query_RootStatEventsAggregateArgs = {
  distinctOn?: InputMaybe<Array<StatEventsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<StatEventsOrderBy>>;
  where?: InputMaybe<StatEventsBoolExp>;
};

export type Query_RootStatEventsByPkArgs = {
  id: Scalars['bigint']['input'];
};

export type Query_RootStatPlayersArgs = {
  distinctOn?: InputMaybe<Array<StatPlayersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<StatPlayersOrderBy>>;
  where?: InputMaybe<StatPlayersBoolExp>;
};

export type Query_RootStatPlayersAggregateArgs = {
  distinctOn?: InputMaybe<Array<StatPlayersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<StatPlayersOrderBy>>;
  where?: InputMaybe<StatPlayersBoolExp>;
};

export type Query_RootStatPlayersByPkArgs = {
  id: Scalars['bigint']['input'];
};

export type Query_RootStatTopPlayersArgs = {
  distinctOn?: InputMaybe<Array<StatTopPlayersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<StatTopPlayersOrderBy>>;
  where?: InputMaybe<StatTopPlayersBoolExp>;
};

export type Query_RootStatTopPlayersAggregateArgs = {
  distinctOn?: InputMaybe<Array<StatTopPlayersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<StatTopPlayersOrderBy>>;
  where?: InputMaybe<StatTopPlayersBoolExp>;
};

export type Query_RootStatTopYearPlayersArgs = {
  distinctOn?: InputMaybe<Array<StatTopYearPlayersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<StatTopYearPlayersOrderBy>>;
  where?: InputMaybe<StatTopYearPlayersBoolExp>;
};

export type Query_RootStatTopYearPlayersAggregateArgs = {
  distinctOn?: InputMaybe<Array<StatTopYearPlayersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<StatTopYearPlayersOrderBy>>;
  where?: InputMaybe<StatTopYearPlayersBoolExp>;
};

export type Query_RootStatTournamentResultsArgs = {
  distinctOn?: InputMaybe<Array<StatTournamentResultsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<StatTournamentResultsOrderBy>>;
  where?: InputMaybe<StatTournamentResultsBoolExp>;
};

export type Query_RootStatTournamentResultsAggregateArgs = {
  distinctOn?: InputMaybe<Array<StatTournamentResultsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<StatTournamentResultsOrderBy>>;
  where?: InputMaybe<StatTournamentResultsBoolExp>;
};

export type Query_RootStatTournamentResultsByPkArgs = {
  id: Scalars['bigint']['input'];
};

export type Query_RootStatTournamentsArgs = {
  distinctOn?: InputMaybe<Array<StatTournamentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<StatTournamentsOrderBy>>;
  where?: InputMaybe<StatTournamentsBoolExp>;
};

export type Query_RootStatTournamentsAggregateArgs = {
  distinctOn?: InputMaybe<Array<StatTournamentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<StatTournamentsOrderBy>>;
  where?: InputMaybe<StatTournamentsBoolExp>;
};

export type Query_RootStatTournamentsByPkArgs = {
  id: Scalars['bigint']['input'];
};

export type Query_RootStatusGamesArgs = {
  distinctOn?: InputMaybe<Array<StatusGamesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<StatusGamesOrderBy>>;
  where?: InputMaybe<StatusGamesBoolExp>;
};

export type Query_RootStatusGamesAggregateArgs = {
  distinctOn?: InputMaybe<Array<StatusGamesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<StatusGamesOrderBy>>;
  where?: InputMaybe<StatusGamesBoolExp>;
};

export type Query_RootStatusGamesByPkArgs = {
  name: Scalars['String']['input'];
};

export type Query_RootStatusesArgs = {
  distinctOn?: InputMaybe<Array<StatusesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<StatusesOrderBy>>;
  where?: InputMaybe<StatusesBoolExp>;
};

export type Query_RootStatusesAggregateArgs = {
  distinctOn?: InputMaybe<Array<StatusesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<StatusesOrderBy>>;
  where?: InputMaybe<StatusesBoolExp>;
};

export type Query_RootStatusesByPkArgs = {
  name: Scalars['String']['input'];
};

export type Query_RootTicketsArgs = {
  distinctOn?: InputMaybe<Array<TicketsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TicketsOrderBy>>;
  where?: InputMaybe<TicketsBoolExp>;
};

export type Query_RootTicketsAggregateArgs = {
  distinctOn?: InputMaybe<Array<TicketsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TicketsOrderBy>>;
  where?: InputMaybe<TicketsBoolExp>;
};

export type Query_RootTicketsByPkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootTicketsStatusArgs = {
  distinctOn?: InputMaybe<Array<TicketsStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TicketsStatusOrderBy>>;
  where?: InputMaybe<TicketsStatusBoolExp>;
};

export type Query_RootTicketsStatusAggregateArgs = {
  distinctOn?: InputMaybe<Array<TicketsStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TicketsStatusOrderBy>>;
  where?: InputMaybe<TicketsStatusBoolExp>;
};

export type Query_RootTicketsStatusByPkArgs = {
  name: Scalars['String']['input'];
};

export type Query_RootTournamentArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootTournamentBalancesArgs = {
  distinctOn?: InputMaybe<Array<TournamentBalancesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TournamentBalancesOrderBy>>;
  where?: InputMaybe<TournamentBalancesBoolExp>;
};

export type Query_RootTournamentBalancesAggregateArgs = {
  distinctOn?: InputMaybe<Array<TournamentBalancesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TournamentBalancesOrderBy>>;
  where?: InputMaybe<TournamentBalancesBoolExp>;
};

export type Query_RootTournamentResultStatusesArgs = {
  distinctOn?: InputMaybe<Array<TournamentResultStatusesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TournamentResultStatusesOrderBy>>;
  where?: InputMaybe<TournamentResultStatusesBoolExp>;
};

export type Query_RootTournamentResultStatusesAggregateArgs = {
  distinctOn?: InputMaybe<Array<TournamentResultStatusesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TournamentResultStatusesOrderBy>>;
  where?: InputMaybe<TournamentResultStatusesBoolExp>;
};

export type Query_RootTournamentResultStatusesByPkArgs = {
  name: Scalars['String']['input'];
};

export type Query_RootTournamentResultsArgs = {
  distinctOn?: InputMaybe<Array<TournamentResultsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TournamentResultsOrderBy>>;
  where?: InputMaybe<TournamentResultsBoolExp>;
};

export type Query_RootTournamentResultsAggregateArgs = {
  distinctOn?: InputMaybe<Array<TournamentResultsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TournamentResultsOrderBy>>;
  where?: InputMaybe<TournamentResultsBoolExp>;
};

export type Query_RootTournamentResultsByPkArgs = {
  id: Scalars['bigint']['input'];
};

export type Query_RootTournamentsArgs = {
  distinctOn?: InputMaybe<Array<TournamentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TournamentsOrderBy>>;
  where?: InputMaybe<TournamentsBoolExp>;
};

export type Query_RootTournamentsAggregateArgs = {
  distinctOn?: InputMaybe<Array<TournamentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TournamentsOrderBy>>;
  where?: InputMaybe<TournamentsBoolExp>;
};

export type Query_RootTransactionUserTypesArgs = {
  distinctOn?: InputMaybe<Array<TransactionUserTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TransactionUserTypesOrderBy>>;
  where?: InputMaybe<TransactionUserTypesBoolExp>;
};

export type Query_RootTransactionUserTypesAggregateArgs = {
  distinctOn?: InputMaybe<Array<TransactionUserTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TransactionUserTypesOrderBy>>;
  where?: InputMaybe<TransactionUserTypesBoolExp>;
};

export type Query_RootTransactionUserTypesByPkArgs = {
  name: Scalars['String']['input'];
};

export type Query_RootTransactionsArgs = {
  distinctOn?: InputMaybe<Array<TransactionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TransactionsOrderBy>>;
  where?: InputMaybe<TransactionsBoolExp>;
};

export type Query_RootTransactionsAggregateArgs = {
  distinctOn?: InputMaybe<Array<TransactionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TransactionsOrderBy>>;
  where?: InputMaybe<TransactionsBoolExp>;
};

export type Query_RootTransactionsByPkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootUserBalancesArgs = {
  distinctOn?: InputMaybe<Array<UserBalancesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UserBalancesOrderBy>>;
  where?: InputMaybe<UserBalancesBoolExp>;
};

export type Query_RootUserBalancesAggregateArgs = {
  distinctOn?: InputMaybe<Array<UserBalancesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UserBalancesOrderBy>>;
  where?: InputMaybe<UserBalancesBoolExp>;
};

export type Query_RootUserShareBuysArgs = {
  distinctOn?: InputMaybe<Array<UserShareBuysSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UserShareBuysOrderBy>>;
  where?: InputMaybe<UserShareBuysBoolExp>;
};

export type Query_RootUserShareBuysAggregateArgs = {
  distinctOn?: InputMaybe<Array<UserShareBuysSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UserShareBuysOrderBy>>;
  where?: InputMaybe<UserShareBuysBoolExp>;
};

export type Query_RootUserShareBuysByPkArgs = {
  id: Scalars['bigint']['input'];
};

export type Query_RootUsersArgs = {
  distinctOn?: InputMaybe<Array<UsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
  where?: InputMaybe<UsersBoolExp>;
};

export type Query_RootUsersAggregateArgs = {
  distinctOn?: InputMaybe<Array<UsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
  where?: InputMaybe<UsersBoolExp>;
};

export type Query_RootUsersByPkArgs = {
  id: Scalars['String']['input'];
};

export type Query_RootVenueArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootVenueUsersArgs = {
  distinctOn?: InputMaybe<Array<VenueUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<VenueUsersOrderBy>>;
  where?: InputMaybe<VenueUsersBoolExp>;
};

export type Query_RootVenueUsersAggregateArgs = {
  distinctOn?: InputMaybe<Array<VenueUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<VenueUsersOrderBy>>;
  where?: InputMaybe<VenueUsersBoolExp>;
};

export type Query_RootVenueUsersByPkArgs = {
  id: Scalars['bigint']['input'];
};

export type Query_RootVenuesArgs = {
  distinctOn?: InputMaybe<Array<VenuesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<VenuesOrderBy>>;
  where?: InputMaybe<VenuesBoolExp>;
};

export type Query_RootVenuesAggregateArgs = {
  distinctOn?: InputMaybe<Array<VenuesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<VenuesOrderBy>>;
  where?: InputMaybe<VenuesBoolExp>;
};

export type Stat_Tournament_Results_Aggregate_Bool_Exp = {
  count?: InputMaybe<Stat_Tournament_Results_Aggregate_Bool_Exp_Count>;
};

export type Stat_Tournament_Results_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<StatTournamentResultsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<StatTournamentResultsBoolExp>;
  predicate: IntComparisonExp;
};

/** order by avg() on columns of table "stat_tournament_results" */
export type Stat_Tournament_Results_Avg_Order_By = {
  id?: InputMaybe<OrderBy>;
  place?: InputMaybe<OrderBy>;
  playerGeneralResultId?: InputMaybe<OrderBy>;
  points?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  priceLocal?: InputMaybe<OrderBy>;
  statPlayerId?: InputMaybe<OrderBy>;
  statTournamentId?: InputMaybe<OrderBy>;
};

/** order by max() on columns of table "stat_tournament_results" */
export type Stat_Tournament_Results_Max_Order_By = {
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  place?: InputMaybe<OrderBy>;
  playerGeneralResultId?: InputMaybe<OrderBy>;
  points?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  priceLocal?: InputMaybe<OrderBy>;
  statPlayerId?: InputMaybe<OrderBy>;
  statTournamentId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "stat_tournament_results" */
export type Stat_Tournament_Results_Min_Order_By = {
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  place?: InputMaybe<OrderBy>;
  playerGeneralResultId?: InputMaybe<OrderBy>;
  points?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  priceLocal?: InputMaybe<OrderBy>;
  statPlayerId?: InputMaybe<OrderBy>;
  statTournamentId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** order by stddev() on columns of table "stat_tournament_results" */
export type Stat_Tournament_Results_Stddev_Order_By = {
  id?: InputMaybe<OrderBy>;
  place?: InputMaybe<OrderBy>;
  playerGeneralResultId?: InputMaybe<OrderBy>;
  points?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  priceLocal?: InputMaybe<OrderBy>;
  statPlayerId?: InputMaybe<OrderBy>;
  statTournamentId?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "stat_tournament_results" */
export type Stat_Tournament_Results_Stddev_Pop_Order_By = {
  id?: InputMaybe<OrderBy>;
  place?: InputMaybe<OrderBy>;
  playerGeneralResultId?: InputMaybe<OrderBy>;
  points?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  priceLocal?: InputMaybe<OrderBy>;
  statPlayerId?: InputMaybe<OrderBy>;
  statTournamentId?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "stat_tournament_results" */
export type Stat_Tournament_Results_Stddev_Samp_Order_By = {
  id?: InputMaybe<OrderBy>;
  place?: InputMaybe<OrderBy>;
  playerGeneralResultId?: InputMaybe<OrderBy>;
  points?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  priceLocal?: InputMaybe<OrderBy>;
  statPlayerId?: InputMaybe<OrderBy>;
  statTournamentId?: InputMaybe<OrderBy>;
};

/** order by sum() on columns of table "stat_tournament_results" */
export type Stat_Tournament_Results_Sum_Order_By = {
  id?: InputMaybe<OrderBy>;
  place?: InputMaybe<OrderBy>;
  playerGeneralResultId?: InputMaybe<OrderBy>;
  points?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  priceLocal?: InputMaybe<OrderBy>;
  statPlayerId?: InputMaybe<OrderBy>;
  statTournamentId?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "stat_tournament_results" */
export type Stat_Tournament_Results_Var_Pop_Order_By = {
  id?: InputMaybe<OrderBy>;
  place?: InputMaybe<OrderBy>;
  playerGeneralResultId?: InputMaybe<OrderBy>;
  points?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  priceLocal?: InputMaybe<OrderBy>;
  statPlayerId?: InputMaybe<OrderBy>;
  statTournamentId?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "stat_tournament_results" */
export type Stat_Tournament_Results_Var_Samp_Order_By = {
  id?: InputMaybe<OrderBy>;
  place?: InputMaybe<OrderBy>;
  playerGeneralResultId?: InputMaybe<OrderBy>;
  points?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  priceLocal?: InputMaybe<OrderBy>;
  statPlayerId?: InputMaybe<OrderBy>;
  statTournamentId?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "stat_tournament_results" */
export type Stat_Tournament_Results_Variance_Order_By = {
  id?: InputMaybe<OrderBy>;
  place?: InputMaybe<OrderBy>;
  playerGeneralResultId?: InputMaybe<OrderBy>;
  points?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  priceLocal?: InputMaybe<OrderBy>;
  statPlayerId?: InputMaybe<OrderBy>;
  statTournamentId?: InputMaybe<OrderBy>;
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "blockchain_currencies" */
  blockchainCurrencies: Array<BlockchainCurrencies>;
  /** An aggregate relationship */
  blockchainCurrenciesAggregate: BlockchainCurrenciesAggregate;
  /** fetch data from the table: "blockchain_currencies" using primary key columns */
  blockchainCurrenciesByPk?: Maybe<BlockchainCurrencies>;
  /** fetch data from the table in a streaming manner: "blockchain_currencies" */
  blockchainCurrenciesStream: Array<BlockchainCurrencies>;
  /** fetch data from the table: "blockchains" */
  blockchains: Array<Blockchains>;
  /** fetch aggregated fields from the table: "blockchains" */
  blockchainsAggregate: BlockchainsAggregate;
  /** fetch data from the table: "blockchains" using primary key columns */
  blockchainsByPk?: Maybe<Blockchains>;
  /** fetch data from the table in a streaming manner: "blockchains" */
  blockchainsStream: Array<Blockchains>;
  /** fetch data from the table: "countries" */
  countries: Array<Countries>;
  /** fetch aggregated fields from the table: "countries" */
  countriesAggregate: CountriesAggregate;
  /** fetch data from the table: "countries" using primary key columns */
  countriesByPk?: Maybe<Countries>;
  /** fetch data from the table in a streaming manner: "countries" */
  countriesStream: Array<Countries>;
  /** An array relationship */
  currencies: Array<Currencies>;
  /** An aggregate relationship */
  currenciesAggregate: CurrenciesAggregate;
  /** fetch data from the table: "currencies" using primary key columns */
  currenciesByPk?: Maybe<Currencies>;
  /** fetch data from the table in a streaming manner: "currencies" */
  currenciesStream: Array<Currencies>;
  /** fetch data from the table: "events" using primary key columns */
  event?: Maybe<Events>;
  /** An array relationship */
  events: Array<Events>;
  /** An aggregate relationship */
  eventsAggregate: EventsAggregate;
  /** fetch data from the table in a streaming manner: "events" */
  eventsStream: Array<Events>;
  /** fetch data from the table: "followers" using primary key columns */
  follower?: Maybe<Followers>;
  /** An array relationship */
  followers: Array<Followers>;
  /** An aggregate relationship */
  followersAggregate: FollowersAggregate;
  /** fetch data from the table in a streaming manner: "followers" */
  followersStream: Array<Followers>;
  /** fetch data from the table: "message_types" */
  messageTypes: Array<MessageTypes>;
  /** fetch aggregated fields from the table: "message_types" */
  messageTypesAggregate: MessageTypesAggregate;
  /** fetch data from the table: "message_types" using primary key columns */
  messageTypesByPk?: Maybe<MessageTypes>;
  /** fetch data from the table in a streaming manner: "message_types" */
  messageTypesStream: Array<MessageTypes>;
  /** fetch data from the table: "messages" */
  messages: Array<Messages>;
  /** fetch aggregated fields from the table: "messages" */
  messagesAggregate: MessagesAggregate;
  /** fetch data from the table: "messages" using primary key columns */
  messagesByPk?: Maybe<Messages>;
  /** fetch data from the table in a streaming manner: "messages" */
  messagesStream: Array<Messages>;
  /** fetch data from the table: "organiser_users" */
  organiserUsers: Array<OrganiserUsers>;
  /** fetch aggregated fields from the table: "organiser_users" */
  organiserUsersAggregate: OrganiserUsersAggregate;
  /** fetch data from the table: "organiser_users" using primary key columns */
  organiserUsersByPk?: Maybe<OrganiserUsers>;
  /** fetch data from the table in a streaming manner: "organiser_users" */
  organiserUsersStream: Array<OrganiserUsers>;
  /** fetch data from the table: "organisers" */
  organisers: Array<Organisers>;
  /** fetch aggregated fields from the table: "organisers" */
  organisersAggregate: OrganisersAggregate;
  /** fetch data from the table: "organisers" using primary key columns */
  organisersByPk?: Maybe<Organisers>;
  /** fetch data from the table in a streaming manner: "organisers" */
  organisersStream: Array<Organisers>;
  /** fetch data from the table: "payment_method_banks" */
  paymentMethodBanks: Array<PaymentMethodBanks>;
  /** An aggregate relationship */
  paymentMethodBanksAggregate: PaymentMethodBanksAggregate;
  /** fetch data from the table: "payment_method_banks" using primary key columns */
  paymentMethodBanksByPk?: Maybe<PaymentMethodBanks>;
  /** fetch data from the table in a streaming manner: "payment_method_banks" */
  paymentMethodBanksStream: Array<PaymentMethodBanks>;
  /** fetch data from the table: "payment_methods" */
  paymentMethods: Array<PaymentMethods>;
  /** fetch aggregated fields from the table: "payment_methods" */
  paymentMethodsAggregate: PaymentMethodsAggregate;
  /** fetch data from the table: "payment_methods" using primary key columns */
  paymentMethodsByPk?: Maybe<PaymentMethods>;
  /** fetch data from the table in a streaming manner: "payment_methods" */
  paymentMethodsStream: Array<PaymentMethods>;
  /** fetch data from the table: "payments" */
  payments: Array<Payments>;
  /** fetch aggregated fields from the table: "payments" */
  paymentsAggregate: PaymentsAggregate;
  /** fetch data from the table: "payments" using primary key columns */
  paymentsByPk?: Maybe<Payments>;
  /** fetch data from the table in a streaming manner: "payments" */
  paymentsStream: Array<Payments>;
  /** fetch data from the table: "pending_txs" */
  pendingTxs: Array<PendingTxs>;
  /** fetch aggregated fields from the table: "pending_txs" */
  pendingTxsAggregate: PendingTxsAggregate;
  /** fetch data from the table: "pending_txs" using primary key columns */
  pendingTxsByPk?: Maybe<PendingTxs>;
  /** fetch data from the table in a streaming manner: "pending_txs" */
  pendingTxsStream: Array<PendingTxs>;
  /** fetch data from the table: "players" using primary key columns */
  player?: Maybe<Players>;
  /** fetch data from the table: "player_followers" */
  playerFollowers: Array<PlayerFollowers>;
  /** fetch aggregated fields from the table: "player_followers" */
  playerFollowersAggregate: PlayerFollowersAggregate;
  /** fetch data from the table in a streaming manner: "player_followers" */
  playerFollowersStream: Array<PlayerFollowers>;
  /** fetch data from the table: "player_followers_view" */
  playerFollowersView: Array<PlayerFollowersView>;
  /** An aggregate relationship */
  playerFollowersViewAggregate: PlayerFollowersViewAggregate;
  /** fetch data from the table in a streaming manner: "player_followers_view" */
  playerFollowersViewStream: Array<PlayerFollowersView>;
  /** fetch data from the table: "player_shares" using primary key columns */
  playerShare?: Maybe<PlayerShares>;
  /** fetch data from the table: "player_shares" */
  playerShares: Array<PlayerShares>;
  /** An aggregate relationship */
  playerSharesAggregate: PlayerSharesAggregate;
  /** fetch data from the table: "player_shares_bought" */
  playerSharesBought: Array<PlayerSharesBought>;
  /** fetch aggregated fields from the table: "player_shares_bought" */
  playerSharesBoughtAggregate: PlayerSharesBoughtAggregate;
  /** fetch data from the table in a streaming manner: "player_shares_bought" */
  playerSharesBoughtStream: Array<PlayerSharesBought>;
  /** fetch data from the table in a streaming manner: "player_shares" */
  playerSharesStream: Array<PlayerShares>;
  /** An array relationship */
  players: Array<Players>;
  /** An aggregate relationship */
  playersAggregate: PlayersAggregate;
  /** fetch data from the table in a streaming manner: "players" */
  playersStream: Array<Players>;
  /** fetch data from the table: "stat_events" */
  statEvents: Array<StatEvents>;
  /** fetch aggregated fields from the table: "stat_events" */
  statEventsAggregate: StatEventsAggregate;
  /** fetch data from the table: "stat_events" using primary key columns */
  statEventsByPk?: Maybe<StatEvents>;
  /** fetch data from the table in a streaming manner: "stat_events" */
  statEventsStream: Array<StatEvents>;
  /** fetch data from the table: "stat_players" */
  statPlayers: Array<StatPlayers>;
  /** fetch aggregated fields from the table: "stat_players" */
  statPlayersAggregate: StatPlayersAggregate;
  /** fetch data from the table: "stat_players" using primary key columns */
  statPlayersByPk?: Maybe<StatPlayers>;
  /** fetch data from the table in a streaming manner: "stat_players" */
  statPlayersStream: Array<StatPlayers>;
  /** fetch data from the table: "stat_top_players" */
  statTopPlayers: Array<StatTopPlayers>;
  /** fetch aggregated fields from the table: "stat_top_players" */
  statTopPlayersAggregate: StatTopPlayersAggregate;
  /** fetch data from the table in a streaming manner: "stat_top_players" */
  statTopPlayersStream: Array<StatTopPlayers>;
  /** fetch data from the table: "stat_top_year_players" */
  statTopYearPlayers: Array<StatTopYearPlayers>;
  /** fetch aggregated fields from the table: "stat_top_year_players" */
  statTopYearPlayersAggregate: StatTopYearPlayersAggregate;
  /** fetch data from the table in a streaming manner: "stat_top_year_players" */
  statTopYearPlayersStream: Array<StatTopYearPlayers>;
  /** fetch data from the table: "stat_tournament_results" */
  statTournamentResults: Array<StatTournamentResults>;
  /** An aggregate relationship */
  statTournamentResultsAggregate: StatTournamentResultsAggregate;
  /** fetch data from the table: "stat_tournament_results" using primary key columns */
  statTournamentResultsByPk?: Maybe<StatTournamentResults>;
  /** fetch data from the table in a streaming manner: "stat_tournament_results" */
  statTournamentResultsStream: Array<StatTournamentResults>;
  /** fetch data from the table: "stat_tournaments" */
  statTournaments: Array<StatTournaments>;
  /** fetch aggregated fields from the table: "stat_tournaments" */
  statTournamentsAggregate: StatTournamentsAggregate;
  /** fetch data from the table: "stat_tournaments" using primary key columns */
  statTournamentsByPk?: Maybe<StatTournaments>;
  /** fetch data from the table in a streaming manner: "stat_tournaments" */
  statTournamentsStream: Array<StatTournaments>;
  /** fetch data from the table: "status_games" */
  statusGames: Array<StatusGames>;
  /** fetch aggregated fields from the table: "status_games" */
  statusGamesAggregate: StatusGamesAggregate;
  /** fetch data from the table: "status_games" using primary key columns */
  statusGamesByPk?: Maybe<StatusGames>;
  /** fetch data from the table in a streaming manner: "status_games" */
  statusGamesStream: Array<StatusGames>;
  /** fetch data from the table: "statuses" */
  statuses: Array<Statuses>;
  /** fetch aggregated fields from the table: "statuses" */
  statusesAggregate: StatusesAggregate;
  /** fetch data from the table: "statuses" using primary key columns */
  statusesByPk?: Maybe<Statuses>;
  /** fetch data from the table in a streaming manner: "statuses" */
  statusesStream: Array<Statuses>;
  /** An array relationship */
  tickets: Array<Tickets>;
  /** An aggregate relationship */
  ticketsAggregate: TicketsAggregate;
  /** fetch data from the table: "tickets" using primary key columns */
  ticketsByPk?: Maybe<Tickets>;
  /** fetch data from the table: "tickets_status" */
  ticketsStatus: Array<TicketsStatus>;
  /** fetch aggregated fields from the table: "tickets_status" */
  ticketsStatusAggregate: TicketsStatusAggregate;
  /** fetch data from the table: "tickets_status" using primary key columns */
  ticketsStatusByPk?: Maybe<TicketsStatus>;
  /** fetch data from the table in a streaming manner: "tickets_status" */
  ticketsStatusStream: Array<TicketsStatus>;
  /** fetch data from the table in a streaming manner: "tickets" */
  ticketsStream: Array<Tickets>;
  /** fetch data from the table: "tournaments" using primary key columns */
  tournament?: Maybe<Tournaments>;
  /** fetch data from the table: "tournament_balances" */
  tournamentBalances: Array<TournamentBalances>;
  /** fetch aggregated fields from the table: "tournament_balances" */
  tournamentBalancesAggregate: TournamentBalancesAggregate;
  /** fetch data from the table in a streaming manner: "tournament_balances" */
  tournamentBalancesStream: Array<TournamentBalances>;
  /** fetch data from the table: "tournament_result_statuses" */
  tournamentResultStatuses: Array<TournamentResultStatuses>;
  /** fetch aggregated fields from the table: "tournament_result_statuses" */
  tournamentResultStatusesAggregate: TournamentResultStatusesAggregate;
  /** fetch data from the table: "tournament_result_statuses" using primary key columns */
  tournamentResultStatusesByPk?: Maybe<TournamentResultStatuses>;
  /** fetch data from the table in a streaming manner: "tournament_result_statuses" */
  tournamentResultStatusesStream: Array<TournamentResultStatuses>;
  /** fetch data from the table: "tournament_results" */
  tournamentResults: Array<TournamentResults>;
  /** An aggregate relationship */
  tournamentResultsAggregate: TournamentResultsAggregate;
  /** fetch data from the table: "tournament_results" using primary key columns */
  tournamentResultsByPk?: Maybe<TournamentResults>;
  /** fetch data from the table in a streaming manner: "tournament_results" */
  tournamentResultsStream: Array<TournamentResults>;
  /** An array relationship */
  tournaments: Array<Tournaments>;
  /** An aggregate relationship */
  tournamentsAggregate: TournamentsAggregate;
  /** fetch data from the table in a streaming manner: "tournaments" */
  tournamentsStream: Array<Tournaments>;
  /** fetch data from the table: "transaction_user_types" */
  transactionUserTypes: Array<TransactionUserTypes>;
  /** fetch aggregated fields from the table: "transaction_user_types" */
  transactionUserTypesAggregate: TransactionUserTypesAggregate;
  /** fetch data from the table: "transaction_user_types" using primary key columns */
  transactionUserTypesByPk?: Maybe<TransactionUserTypes>;
  /** fetch data from the table in a streaming manner: "transaction_user_types" */
  transactionUserTypesStream: Array<TransactionUserTypes>;
  /** An array relationship */
  transactions: Array<Transactions>;
  /** An aggregate relationship */
  transactionsAggregate: TransactionsAggregate;
  /** fetch data from the table: "transactions" using primary key columns */
  transactionsByPk?: Maybe<Transactions>;
  /** fetch data from the table in a streaming manner: "transactions" */
  transactionsStream: Array<Transactions>;
  /** fetch data from the table: "user_balances" */
  userBalances: Array<UserBalances>;
  /** An aggregate relationship */
  userBalancesAggregate: UserBalancesAggregate;
  /** fetch data from the table in a streaming manner: "user_balances" */
  userBalancesStream: Array<UserBalances>;
  /** fetch data from the table: "user_share_buys" */
  userShareBuys: Array<UserShareBuys>;
  /** An aggregate relationship */
  userShareBuysAggregate: UserShareBuysAggregate;
  /** fetch data from the table: "user_share_buys" using primary key columns */
  userShareBuysByPk?: Maybe<UserShareBuys>;
  /** fetch data from the table in a streaming manner: "user_share_buys" */
  userShareBuysStream: Array<UserShareBuys>;
  /** fetch data from the table: "users" */
  users: Array<Users>;
  /** fetch aggregated fields from the table: "users" */
  usersAggregate: UsersAggregate;
  /** fetch data from the table: "users" using primary key columns */
  usersByPk?: Maybe<Users>;
  /** fetch data from the table in a streaming manner: "users" */
  usersStream: Array<Users>;
  /** fetch data from the table: "venues" using primary key columns */
  venue?: Maybe<Venues>;
  /** fetch data from the table: "venue_users" */
  venueUsers: Array<VenueUsers>;
  /** An aggregate relationship */
  venueUsersAggregate: VenueUsersAggregate;
  /** fetch data from the table: "venue_users" using primary key columns */
  venueUsersByPk?: Maybe<VenueUsers>;
  /** fetch data from the table in a streaming manner: "venue_users" */
  venueUsersStream: Array<VenueUsers>;
  /** fetch data from the table: "venues" */
  venues: Array<Venues>;
  /** fetch aggregated fields from the table: "venues" */
  venuesAggregate: VenuesAggregate;
  /** fetch data from the table in a streaming manner: "venues" */
  venuesStream: Array<Venues>;
};

export type Subscription_RootBlockchainCurrenciesArgs = {
  distinctOn?: InputMaybe<Array<BlockchainCurrenciesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<BlockchainCurrenciesOrderBy>>;
  where?: InputMaybe<BlockchainCurrenciesBoolExp>;
};

export type Subscription_RootBlockchainCurrenciesAggregateArgs = {
  distinctOn?: InputMaybe<Array<BlockchainCurrenciesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<BlockchainCurrenciesOrderBy>>;
  where?: InputMaybe<BlockchainCurrenciesBoolExp>;
};

export type Subscription_RootBlockchainCurrenciesByPkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootBlockchainCurrenciesStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<BlockchainCurrenciesStreamCursorInput>>;
  where?: InputMaybe<BlockchainCurrenciesBoolExp>;
};

export type Subscription_RootBlockchainsArgs = {
  distinctOn?: InputMaybe<Array<BlockchainsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<BlockchainsOrderBy>>;
  where?: InputMaybe<BlockchainsBoolExp>;
};

export type Subscription_RootBlockchainsAggregateArgs = {
  distinctOn?: InputMaybe<Array<BlockchainsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<BlockchainsOrderBy>>;
  where?: InputMaybe<BlockchainsBoolExp>;
};

export type Subscription_RootBlockchainsByPkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootBlockchainsStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<BlockchainsStreamCursorInput>>;
  where?: InputMaybe<BlockchainsBoolExp>;
};

export type Subscription_RootCountriesArgs = {
  distinctOn?: InputMaybe<Array<CountriesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CountriesOrderBy>>;
  where?: InputMaybe<CountriesBoolExp>;
};

export type Subscription_RootCountriesAggregateArgs = {
  distinctOn?: InputMaybe<Array<CountriesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CountriesOrderBy>>;
  where?: InputMaybe<CountriesBoolExp>;
};

export type Subscription_RootCountriesByPkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootCountriesStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<CountriesStreamCursorInput>>;
  where?: InputMaybe<CountriesBoolExp>;
};

export type Subscription_RootCurrenciesArgs = {
  distinctOn?: InputMaybe<Array<CurrenciesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CurrenciesOrderBy>>;
  where?: InputMaybe<CurrenciesBoolExp>;
};

export type Subscription_RootCurrenciesAggregateArgs = {
  distinctOn?: InputMaybe<Array<CurrenciesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CurrenciesOrderBy>>;
  where?: InputMaybe<CurrenciesBoolExp>;
};

export type Subscription_RootCurrenciesByPkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootCurrenciesStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<CurrenciesStreamCursorInput>>;
  where?: InputMaybe<CurrenciesBoolExp>;
};

export type Subscription_RootEventArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootEventsArgs = {
  distinctOn?: InputMaybe<Array<EventsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EventsOrderBy>>;
  where?: InputMaybe<EventsBoolExp>;
};

export type Subscription_RootEventsAggregateArgs = {
  distinctOn?: InputMaybe<Array<EventsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EventsOrderBy>>;
  where?: InputMaybe<EventsBoolExp>;
};

export type Subscription_RootEventsStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<EventsStreamCursorInput>>;
  where?: InputMaybe<EventsBoolExp>;
};

export type Subscription_RootFollowerArgs = {
  id: Scalars['bigint']['input'];
};

export type Subscription_RootFollowersArgs = {
  distinctOn?: InputMaybe<Array<FollowersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FollowersOrderBy>>;
  where?: InputMaybe<FollowersBoolExp>;
};

export type Subscription_RootFollowersAggregateArgs = {
  distinctOn?: InputMaybe<Array<FollowersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FollowersOrderBy>>;
  where?: InputMaybe<FollowersBoolExp>;
};

export type Subscription_RootFollowersStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<FollowersStreamCursorInput>>;
  where?: InputMaybe<FollowersBoolExp>;
};

export type Subscription_RootMessageTypesArgs = {
  distinctOn?: InputMaybe<Array<MessageTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<MessageTypesOrderBy>>;
  where?: InputMaybe<MessageTypesBoolExp>;
};

export type Subscription_RootMessageTypesAggregateArgs = {
  distinctOn?: InputMaybe<Array<MessageTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<MessageTypesOrderBy>>;
  where?: InputMaybe<MessageTypesBoolExp>;
};

export type Subscription_RootMessageTypesByPkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootMessageTypesStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<MessageTypesStreamCursorInput>>;
  where?: InputMaybe<MessageTypesBoolExp>;
};

export type Subscription_RootMessagesArgs = {
  distinctOn?: InputMaybe<Array<MessagesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<MessagesOrderBy>>;
  where?: InputMaybe<MessagesBoolExp>;
};

export type Subscription_RootMessagesAggregateArgs = {
  distinctOn?: InputMaybe<Array<MessagesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<MessagesOrderBy>>;
  where?: InputMaybe<MessagesBoolExp>;
};

export type Subscription_RootMessagesByPkArgs = {
  id: Scalars['bigint']['input'];
};

export type Subscription_RootMessagesStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<MessagesStreamCursorInput>>;
  where?: InputMaybe<MessagesBoolExp>;
};

export type Subscription_RootOrganiserUsersArgs = {
  distinctOn?: InputMaybe<Array<OrganiserUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<OrganiserUsersOrderBy>>;
  where?: InputMaybe<OrganiserUsersBoolExp>;
};

export type Subscription_RootOrganiserUsersAggregateArgs = {
  distinctOn?: InputMaybe<Array<OrganiserUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<OrganiserUsersOrderBy>>;
  where?: InputMaybe<OrganiserUsersBoolExp>;
};

export type Subscription_RootOrganiserUsersByPkArgs = {
  id: Scalars['bigint']['input'];
};

export type Subscription_RootOrganiserUsersStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<OrganiserUsersStreamCursorInput>>;
  where?: InputMaybe<OrganiserUsersBoolExp>;
};

export type Subscription_RootOrganisersArgs = {
  distinctOn?: InputMaybe<Array<OrganisersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<OrganisersOrderBy>>;
  where?: InputMaybe<OrganisersBoolExp>;
};

export type Subscription_RootOrganisersAggregateArgs = {
  distinctOn?: InputMaybe<Array<OrganisersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<OrganisersOrderBy>>;
  where?: InputMaybe<OrganisersBoolExp>;
};

export type Subscription_RootOrganisersByPkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootOrganisersStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<OrganisersStreamCursorInput>>;
  where?: InputMaybe<OrganisersBoolExp>;
};

export type Subscription_RootPaymentMethodBanksArgs = {
  distinctOn?: InputMaybe<Array<PaymentMethodBanksSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PaymentMethodBanksOrderBy>>;
  where?: InputMaybe<PaymentMethodBanksBoolExp>;
};

export type Subscription_RootPaymentMethodBanksAggregateArgs = {
  distinctOn?: InputMaybe<Array<PaymentMethodBanksSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PaymentMethodBanksOrderBy>>;
  where?: InputMaybe<PaymentMethodBanksBoolExp>;
};

export type Subscription_RootPaymentMethodBanksByPkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootPaymentMethodBanksStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<PaymentMethodBanksStreamCursorInput>>;
  where?: InputMaybe<PaymentMethodBanksBoolExp>;
};

export type Subscription_RootPaymentMethodsArgs = {
  distinctOn?: InputMaybe<Array<PaymentMethodsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PaymentMethodsOrderBy>>;
  where?: InputMaybe<PaymentMethodsBoolExp>;
};

export type Subscription_RootPaymentMethodsAggregateArgs = {
  distinctOn?: InputMaybe<Array<PaymentMethodsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PaymentMethodsOrderBy>>;
  where?: InputMaybe<PaymentMethodsBoolExp>;
};

export type Subscription_RootPaymentMethodsByPkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootPaymentMethodsStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<PaymentMethodsStreamCursorInput>>;
  where?: InputMaybe<PaymentMethodsBoolExp>;
};

export type Subscription_RootPaymentsArgs = {
  distinctOn?: InputMaybe<Array<PaymentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PaymentsOrderBy>>;
  where?: InputMaybe<PaymentsBoolExp>;
};

export type Subscription_RootPaymentsAggregateArgs = {
  distinctOn?: InputMaybe<Array<PaymentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PaymentsOrderBy>>;
  where?: InputMaybe<PaymentsBoolExp>;
};

export type Subscription_RootPaymentsByPkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootPaymentsStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<PaymentsStreamCursorInput>>;
  where?: InputMaybe<PaymentsBoolExp>;
};

export type Subscription_RootPendingTxsArgs = {
  distinctOn?: InputMaybe<Array<PendingTxsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PendingTxsOrderBy>>;
  where?: InputMaybe<PendingTxsBoolExp>;
};

export type Subscription_RootPendingTxsAggregateArgs = {
  distinctOn?: InputMaybe<Array<PendingTxsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PendingTxsOrderBy>>;
  where?: InputMaybe<PendingTxsBoolExp>;
};

export type Subscription_RootPendingTxsByPkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootPendingTxsStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<PendingTxsStreamCursorInput>>;
  where?: InputMaybe<PendingTxsBoolExp>;
};

export type Subscription_RootPlayerArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootPlayerFollowersArgs = {
  distinctOn?: InputMaybe<Array<PlayerFollowersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PlayerFollowersOrderBy>>;
  where?: InputMaybe<PlayerFollowersBoolExp>;
};

export type Subscription_RootPlayerFollowersAggregateArgs = {
  distinctOn?: InputMaybe<Array<PlayerFollowersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PlayerFollowersOrderBy>>;
  where?: InputMaybe<PlayerFollowersBoolExp>;
};

export type Subscription_RootPlayerFollowersStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<PlayerFollowersStreamCursorInput>>;
  where?: InputMaybe<PlayerFollowersBoolExp>;
};

export type Subscription_RootPlayerFollowersViewArgs = {
  distinctOn?: InputMaybe<Array<PlayerFollowersViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PlayerFollowersViewOrderBy>>;
  where?: InputMaybe<PlayerFollowersViewBoolExp>;
};

export type Subscription_RootPlayerFollowersViewAggregateArgs = {
  distinctOn?: InputMaybe<Array<PlayerFollowersViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PlayerFollowersViewOrderBy>>;
  where?: InputMaybe<PlayerFollowersViewBoolExp>;
};

export type Subscription_RootPlayerFollowersViewStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<PlayerFollowersViewStreamCursorInput>>;
  where?: InputMaybe<PlayerFollowersViewBoolExp>;
};

export type Subscription_RootPlayerShareArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootPlayerSharesArgs = {
  distinctOn?: InputMaybe<Array<PlayerSharesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PlayerSharesOrderBy>>;
  where?: InputMaybe<PlayerSharesBoolExp>;
};

export type Subscription_RootPlayerSharesAggregateArgs = {
  distinctOn?: InputMaybe<Array<PlayerSharesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PlayerSharesOrderBy>>;
  where?: InputMaybe<PlayerSharesBoolExp>;
};

export type Subscription_RootPlayerSharesBoughtArgs = {
  distinctOn?: InputMaybe<Array<PlayerSharesBoughtSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PlayerSharesBoughtOrderBy>>;
  where?: InputMaybe<PlayerSharesBoughtBoolExp>;
};

export type Subscription_RootPlayerSharesBoughtAggregateArgs = {
  distinctOn?: InputMaybe<Array<PlayerSharesBoughtSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PlayerSharesBoughtOrderBy>>;
  where?: InputMaybe<PlayerSharesBoughtBoolExp>;
};

export type Subscription_RootPlayerSharesBoughtStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<PlayerSharesBoughtStreamCursorInput>>;
  where?: InputMaybe<PlayerSharesBoughtBoolExp>;
};

export type Subscription_RootPlayerSharesStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<PlayerSharesStreamCursorInput>>;
  where?: InputMaybe<PlayerSharesBoolExp>;
};

export type Subscription_RootPlayersArgs = {
  distinctOn?: InputMaybe<Array<PlayersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PlayersOrderBy>>;
  where?: InputMaybe<PlayersBoolExp>;
};

export type Subscription_RootPlayersAggregateArgs = {
  distinctOn?: InputMaybe<Array<PlayersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PlayersOrderBy>>;
  where?: InputMaybe<PlayersBoolExp>;
};

export type Subscription_RootPlayersStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<PlayersStreamCursorInput>>;
  where?: InputMaybe<PlayersBoolExp>;
};

export type Subscription_RootStatEventsArgs = {
  distinctOn?: InputMaybe<Array<StatEventsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<StatEventsOrderBy>>;
  where?: InputMaybe<StatEventsBoolExp>;
};

export type Subscription_RootStatEventsAggregateArgs = {
  distinctOn?: InputMaybe<Array<StatEventsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<StatEventsOrderBy>>;
  where?: InputMaybe<StatEventsBoolExp>;
};

export type Subscription_RootStatEventsByPkArgs = {
  id: Scalars['bigint']['input'];
};

export type Subscription_RootStatEventsStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<StatEventsStreamCursorInput>>;
  where?: InputMaybe<StatEventsBoolExp>;
};

export type Subscription_RootStatPlayersArgs = {
  distinctOn?: InputMaybe<Array<StatPlayersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<StatPlayersOrderBy>>;
  where?: InputMaybe<StatPlayersBoolExp>;
};

export type Subscription_RootStatPlayersAggregateArgs = {
  distinctOn?: InputMaybe<Array<StatPlayersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<StatPlayersOrderBy>>;
  where?: InputMaybe<StatPlayersBoolExp>;
};

export type Subscription_RootStatPlayersByPkArgs = {
  id: Scalars['bigint']['input'];
};

export type Subscription_RootStatPlayersStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<StatPlayersStreamCursorInput>>;
  where?: InputMaybe<StatPlayersBoolExp>;
};

export type Subscription_RootStatTopPlayersArgs = {
  distinctOn?: InputMaybe<Array<StatTopPlayersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<StatTopPlayersOrderBy>>;
  where?: InputMaybe<StatTopPlayersBoolExp>;
};

export type Subscription_RootStatTopPlayersAggregateArgs = {
  distinctOn?: InputMaybe<Array<StatTopPlayersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<StatTopPlayersOrderBy>>;
  where?: InputMaybe<StatTopPlayersBoolExp>;
};

export type Subscription_RootStatTopPlayersStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<StatTopPlayersStreamCursorInput>>;
  where?: InputMaybe<StatTopPlayersBoolExp>;
};

export type Subscription_RootStatTopYearPlayersArgs = {
  distinctOn?: InputMaybe<Array<StatTopYearPlayersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<StatTopYearPlayersOrderBy>>;
  where?: InputMaybe<StatTopYearPlayersBoolExp>;
};

export type Subscription_RootStatTopYearPlayersAggregateArgs = {
  distinctOn?: InputMaybe<Array<StatTopYearPlayersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<StatTopYearPlayersOrderBy>>;
  where?: InputMaybe<StatTopYearPlayersBoolExp>;
};

export type Subscription_RootStatTopYearPlayersStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<StatTopYearPlayersStreamCursorInput>>;
  where?: InputMaybe<StatTopYearPlayersBoolExp>;
};

export type Subscription_RootStatTournamentResultsArgs = {
  distinctOn?: InputMaybe<Array<StatTournamentResultsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<StatTournamentResultsOrderBy>>;
  where?: InputMaybe<StatTournamentResultsBoolExp>;
};

export type Subscription_RootStatTournamentResultsAggregateArgs = {
  distinctOn?: InputMaybe<Array<StatTournamentResultsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<StatTournamentResultsOrderBy>>;
  where?: InputMaybe<StatTournamentResultsBoolExp>;
};

export type Subscription_RootStatTournamentResultsByPkArgs = {
  id: Scalars['bigint']['input'];
};

export type Subscription_RootStatTournamentResultsStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<StatTournamentResultsStreamCursorInput>>;
  where?: InputMaybe<StatTournamentResultsBoolExp>;
};

export type Subscription_RootStatTournamentsArgs = {
  distinctOn?: InputMaybe<Array<StatTournamentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<StatTournamentsOrderBy>>;
  where?: InputMaybe<StatTournamentsBoolExp>;
};

export type Subscription_RootStatTournamentsAggregateArgs = {
  distinctOn?: InputMaybe<Array<StatTournamentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<StatTournamentsOrderBy>>;
  where?: InputMaybe<StatTournamentsBoolExp>;
};

export type Subscription_RootStatTournamentsByPkArgs = {
  id: Scalars['bigint']['input'];
};

export type Subscription_RootStatTournamentsStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<StatTournamentsStreamCursorInput>>;
  where?: InputMaybe<StatTournamentsBoolExp>;
};

export type Subscription_RootStatusGamesArgs = {
  distinctOn?: InputMaybe<Array<StatusGamesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<StatusGamesOrderBy>>;
  where?: InputMaybe<StatusGamesBoolExp>;
};

export type Subscription_RootStatusGamesAggregateArgs = {
  distinctOn?: InputMaybe<Array<StatusGamesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<StatusGamesOrderBy>>;
  where?: InputMaybe<StatusGamesBoolExp>;
};

export type Subscription_RootStatusGamesByPkArgs = {
  name: Scalars['String']['input'];
};

export type Subscription_RootStatusGamesStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<StatusGamesStreamCursorInput>>;
  where?: InputMaybe<StatusGamesBoolExp>;
};

export type Subscription_RootStatusesArgs = {
  distinctOn?: InputMaybe<Array<StatusesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<StatusesOrderBy>>;
  where?: InputMaybe<StatusesBoolExp>;
};

export type Subscription_RootStatusesAggregateArgs = {
  distinctOn?: InputMaybe<Array<StatusesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<StatusesOrderBy>>;
  where?: InputMaybe<StatusesBoolExp>;
};

export type Subscription_RootStatusesByPkArgs = {
  name: Scalars['String']['input'];
};

export type Subscription_RootStatusesStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<StatusesStreamCursorInput>>;
  where?: InputMaybe<StatusesBoolExp>;
};

export type Subscription_RootTicketsArgs = {
  distinctOn?: InputMaybe<Array<TicketsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TicketsOrderBy>>;
  where?: InputMaybe<TicketsBoolExp>;
};

export type Subscription_RootTicketsAggregateArgs = {
  distinctOn?: InputMaybe<Array<TicketsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TicketsOrderBy>>;
  where?: InputMaybe<TicketsBoolExp>;
};

export type Subscription_RootTicketsByPkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootTicketsStatusArgs = {
  distinctOn?: InputMaybe<Array<TicketsStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TicketsStatusOrderBy>>;
  where?: InputMaybe<TicketsStatusBoolExp>;
};

export type Subscription_RootTicketsStatusAggregateArgs = {
  distinctOn?: InputMaybe<Array<TicketsStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TicketsStatusOrderBy>>;
  where?: InputMaybe<TicketsStatusBoolExp>;
};

export type Subscription_RootTicketsStatusByPkArgs = {
  name: Scalars['String']['input'];
};

export type Subscription_RootTicketsStatusStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<TicketsStatusStreamCursorInput>>;
  where?: InputMaybe<TicketsStatusBoolExp>;
};

export type Subscription_RootTicketsStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<TicketsStreamCursorInput>>;
  where?: InputMaybe<TicketsBoolExp>;
};

export type Subscription_RootTournamentArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootTournamentBalancesArgs = {
  distinctOn?: InputMaybe<Array<TournamentBalancesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TournamentBalancesOrderBy>>;
  where?: InputMaybe<TournamentBalancesBoolExp>;
};

export type Subscription_RootTournamentBalancesAggregateArgs = {
  distinctOn?: InputMaybe<Array<TournamentBalancesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TournamentBalancesOrderBy>>;
  where?: InputMaybe<TournamentBalancesBoolExp>;
};

export type Subscription_RootTournamentBalancesStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<TournamentBalancesStreamCursorInput>>;
  where?: InputMaybe<TournamentBalancesBoolExp>;
};

export type Subscription_RootTournamentResultStatusesArgs = {
  distinctOn?: InputMaybe<Array<TournamentResultStatusesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TournamentResultStatusesOrderBy>>;
  where?: InputMaybe<TournamentResultStatusesBoolExp>;
};

export type Subscription_RootTournamentResultStatusesAggregateArgs = {
  distinctOn?: InputMaybe<Array<TournamentResultStatusesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TournamentResultStatusesOrderBy>>;
  where?: InputMaybe<TournamentResultStatusesBoolExp>;
};

export type Subscription_RootTournamentResultStatusesByPkArgs = {
  name: Scalars['String']['input'];
};

export type Subscription_RootTournamentResultStatusesStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<TournamentResultStatusesStreamCursorInput>>;
  where?: InputMaybe<TournamentResultStatusesBoolExp>;
};

export type Subscription_RootTournamentResultsArgs = {
  distinctOn?: InputMaybe<Array<TournamentResultsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TournamentResultsOrderBy>>;
  where?: InputMaybe<TournamentResultsBoolExp>;
};

export type Subscription_RootTournamentResultsAggregateArgs = {
  distinctOn?: InputMaybe<Array<TournamentResultsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TournamentResultsOrderBy>>;
  where?: InputMaybe<TournamentResultsBoolExp>;
};

export type Subscription_RootTournamentResultsByPkArgs = {
  id: Scalars['bigint']['input'];
};

export type Subscription_RootTournamentResultsStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<TournamentResultsStreamCursorInput>>;
  where?: InputMaybe<TournamentResultsBoolExp>;
};

export type Subscription_RootTournamentsArgs = {
  distinctOn?: InputMaybe<Array<TournamentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TournamentsOrderBy>>;
  where?: InputMaybe<TournamentsBoolExp>;
};

export type Subscription_RootTournamentsAggregateArgs = {
  distinctOn?: InputMaybe<Array<TournamentsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TournamentsOrderBy>>;
  where?: InputMaybe<TournamentsBoolExp>;
};

export type Subscription_RootTournamentsStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<TournamentsStreamCursorInput>>;
  where?: InputMaybe<TournamentsBoolExp>;
};

export type Subscription_RootTransactionUserTypesArgs = {
  distinctOn?: InputMaybe<Array<TransactionUserTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TransactionUserTypesOrderBy>>;
  where?: InputMaybe<TransactionUserTypesBoolExp>;
};

export type Subscription_RootTransactionUserTypesAggregateArgs = {
  distinctOn?: InputMaybe<Array<TransactionUserTypesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TransactionUserTypesOrderBy>>;
  where?: InputMaybe<TransactionUserTypesBoolExp>;
};

export type Subscription_RootTransactionUserTypesByPkArgs = {
  name: Scalars['String']['input'];
};

export type Subscription_RootTransactionUserTypesStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<TransactionUserTypesStreamCursorInput>>;
  where?: InputMaybe<TransactionUserTypesBoolExp>;
};

export type Subscription_RootTransactionsArgs = {
  distinctOn?: InputMaybe<Array<TransactionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TransactionsOrderBy>>;
  where?: InputMaybe<TransactionsBoolExp>;
};

export type Subscription_RootTransactionsAggregateArgs = {
  distinctOn?: InputMaybe<Array<TransactionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TransactionsOrderBy>>;
  where?: InputMaybe<TransactionsBoolExp>;
};

export type Subscription_RootTransactionsByPkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootTransactionsStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<TransactionsStreamCursorInput>>;
  where?: InputMaybe<TransactionsBoolExp>;
};

export type Subscription_RootUserBalancesArgs = {
  distinctOn?: InputMaybe<Array<UserBalancesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UserBalancesOrderBy>>;
  where?: InputMaybe<UserBalancesBoolExp>;
};

export type Subscription_RootUserBalancesAggregateArgs = {
  distinctOn?: InputMaybe<Array<UserBalancesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UserBalancesOrderBy>>;
  where?: InputMaybe<UserBalancesBoolExp>;
};

export type Subscription_RootUserBalancesStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<UserBalancesStreamCursorInput>>;
  where?: InputMaybe<UserBalancesBoolExp>;
};

export type Subscription_RootUserShareBuysArgs = {
  distinctOn?: InputMaybe<Array<UserShareBuysSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UserShareBuysOrderBy>>;
  where?: InputMaybe<UserShareBuysBoolExp>;
};

export type Subscription_RootUserShareBuysAggregateArgs = {
  distinctOn?: InputMaybe<Array<UserShareBuysSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UserShareBuysOrderBy>>;
  where?: InputMaybe<UserShareBuysBoolExp>;
};

export type Subscription_RootUserShareBuysByPkArgs = {
  id: Scalars['bigint']['input'];
};

export type Subscription_RootUserShareBuysStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<UserShareBuysStreamCursorInput>>;
  where?: InputMaybe<UserShareBuysBoolExp>;
};

export type Subscription_RootUsersArgs = {
  distinctOn?: InputMaybe<Array<UsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
  where?: InputMaybe<UsersBoolExp>;
};

export type Subscription_RootUsersAggregateArgs = {
  distinctOn?: InputMaybe<Array<UsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
  where?: InputMaybe<UsersBoolExp>;
};

export type Subscription_RootUsersByPkArgs = {
  id: Scalars['String']['input'];
};

export type Subscription_RootUsersStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<UsersStreamCursorInput>>;
  where?: InputMaybe<UsersBoolExp>;
};

export type Subscription_RootVenueArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootVenueUsersArgs = {
  distinctOn?: InputMaybe<Array<VenueUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<VenueUsersOrderBy>>;
  where?: InputMaybe<VenueUsersBoolExp>;
};

export type Subscription_RootVenueUsersAggregateArgs = {
  distinctOn?: InputMaybe<Array<VenueUsersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<VenueUsersOrderBy>>;
  where?: InputMaybe<VenueUsersBoolExp>;
};

export type Subscription_RootVenueUsersByPkArgs = {
  id: Scalars['bigint']['input'];
};

export type Subscription_RootVenueUsersStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<VenueUsersStreamCursorInput>>;
  where?: InputMaybe<VenueUsersBoolExp>;
};

export type Subscription_RootVenuesArgs = {
  distinctOn?: InputMaybe<Array<VenuesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<VenuesOrderBy>>;
  where?: InputMaybe<VenuesBoolExp>;
};

export type Subscription_RootVenuesAggregateArgs = {
  distinctOn?: InputMaybe<Array<VenuesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<VenuesOrderBy>>;
  where?: InputMaybe<VenuesBoolExp>;
};

export type Subscription_RootVenuesStreamArgs = {
  batchSize: Scalars['Int']['input'];
  cursor: Array<InputMaybe<VenuesStreamCursorInput>>;
  where?: InputMaybe<VenuesBoolExp>;
};

export type Tickets_Aggregate_Bool_Exp = {
  count?: InputMaybe<Tickets_Aggregate_Bool_Exp_Count>;
};

export type Tickets_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<TicketsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<TicketsBoolExp>;
  predicate: IntComparisonExp;
};

/** order by avg() on columns of table "tickets" */
export type Tickets_Avg_Order_By = {
  amount?: InputMaybe<OrderBy>;
  bullet?: InputMaybe<OrderBy>;
  tournamentResultId?: InputMaybe<OrderBy>;
  won?: InputMaybe<OrderBy>;
  wonLocal?: InputMaybe<OrderBy>;
};

/** order by max() on columns of table "tickets" */
export type Tickets_Max_Order_By = {
  amount?: InputMaybe<OrderBy>;
  bullet?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  playerName?: InputMaybe<OrderBy>;
  playerShareId?: InputMaybe<OrderBy>;
  tournamentId?: InputMaybe<OrderBy>;
  tournamentResultId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
  won?: InputMaybe<OrderBy>;
  wonLocal?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "tickets" */
export type Tickets_Min_Order_By = {
  amount?: InputMaybe<OrderBy>;
  bullet?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  playerName?: InputMaybe<OrderBy>;
  playerShareId?: InputMaybe<OrderBy>;
  tournamentId?: InputMaybe<OrderBy>;
  tournamentResultId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
  won?: InputMaybe<OrderBy>;
  wonLocal?: InputMaybe<OrderBy>;
};

/** order by stddev() on columns of table "tickets" */
export type Tickets_Stddev_Order_By = {
  amount?: InputMaybe<OrderBy>;
  bullet?: InputMaybe<OrderBy>;
  tournamentResultId?: InputMaybe<OrderBy>;
  won?: InputMaybe<OrderBy>;
  wonLocal?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "tickets" */
export type Tickets_Stddev_Pop_Order_By = {
  amount?: InputMaybe<OrderBy>;
  bullet?: InputMaybe<OrderBy>;
  tournamentResultId?: InputMaybe<OrderBy>;
  won?: InputMaybe<OrderBy>;
  wonLocal?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "tickets" */
export type Tickets_Stddev_Samp_Order_By = {
  amount?: InputMaybe<OrderBy>;
  bullet?: InputMaybe<OrderBy>;
  tournamentResultId?: InputMaybe<OrderBy>;
  won?: InputMaybe<OrderBy>;
  wonLocal?: InputMaybe<OrderBy>;
};

/** order by sum() on columns of table "tickets" */
export type Tickets_Sum_Order_By = {
  amount?: InputMaybe<OrderBy>;
  bullet?: InputMaybe<OrderBy>;
  tournamentResultId?: InputMaybe<OrderBy>;
  won?: InputMaybe<OrderBy>;
  wonLocal?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "tickets" */
export type Tickets_Var_Pop_Order_By = {
  amount?: InputMaybe<OrderBy>;
  bullet?: InputMaybe<OrderBy>;
  tournamentResultId?: InputMaybe<OrderBy>;
  won?: InputMaybe<OrderBy>;
  wonLocal?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "tickets" */
export type Tickets_Var_Samp_Order_By = {
  amount?: InputMaybe<OrderBy>;
  bullet?: InputMaybe<OrderBy>;
  tournamentResultId?: InputMaybe<OrderBy>;
  won?: InputMaybe<OrderBy>;
  wonLocal?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "tickets" */
export type Tickets_Variance_Order_By = {
  amount?: InputMaybe<OrderBy>;
  bullet?: InputMaybe<OrderBy>;
  tournamentResultId?: InputMaybe<OrderBy>;
  won?: InputMaybe<OrderBy>;
  wonLocal?: InputMaybe<OrderBy>;
};

export type Tournament_Balances_Aggregate_Bool_Exp = {
  count?: InputMaybe<Tournament_Balances_Aggregate_Bool_Exp_Count>;
};

export type Tournament_Balances_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<TournamentBalancesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<TournamentBalancesBoolExp>;
  predicate: IntComparisonExp;
};

/** order by avg() on columns of table "tournament_balances" */
export type Tournament_Balances_Avg_Order_By = {
  balance?: InputMaybe<OrderBy>;
  currencyId?: InputMaybe<OrderBy>;
};

/** order by max() on columns of table "tournament_balances" */
export type Tournament_Balances_Max_Order_By = {
  balance?: InputMaybe<OrderBy>;
  currencyId?: InputMaybe<OrderBy>;
  eventId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  tournamentId?: InputMaybe<OrderBy>;
  venueId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "tournament_balances" */
export type Tournament_Balances_Min_Order_By = {
  balance?: InputMaybe<OrderBy>;
  currencyId?: InputMaybe<OrderBy>;
  eventId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  tournamentId?: InputMaybe<OrderBy>;
  venueId?: InputMaybe<OrderBy>;
};

/** order by stddev() on columns of table "tournament_balances" */
export type Tournament_Balances_Stddev_Order_By = {
  balance?: InputMaybe<OrderBy>;
  currencyId?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "tournament_balances" */
export type Tournament_Balances_Stddev_Pop_Order_By = {
  balance?: InputMaybe<OrderBy>;
  currencyId?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "tournament_balances" */
export type Tournament_Balances_Stddev_Samp_Order_By = {
  balance?: InputMaybe<OrderBy>;
  currencyId?: InputMaybe<OrderBy>;
};

/** order by sum() on columns of table "tournament_balances" */
export type Tournament_Balances_Sum_Order_By = {
  balance?: InputMaybe<OrderBy>;
  currencyId?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "tournament_balances" */
export type Tournament_Balances_Var_Pop_Order_By = {
  balance?: InputMaybe<OrderBy>;
  currencyId?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "tournament_balances" */
export type Tournament_Balances_Var_Samp_Order_By = {
  balance?: InputMaybe<OrderBy>;
  currencyId?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "tournament_balances" */
export type Tournament_Balances_Variance_Order_By = {
  balance?: InputMaybe<OrderBy>;
  currencyId?: InputMaybe<OrderBy>;
};

export type Tournament_Results_Aggregate_Bool_Exp = {
  count?: InputMaybe<Tournament_Results_Aggregate_Bool_Exp_Count>;
};

export type Tournament_Results_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<TournamentResultsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<TournamentResultsBoolExp>;
  predicate: IntComparisonExp;
};

/** order by avg() on columns of table "tournament_results" */
export type Tournament_Results_Avg_Order_By = {
  bullets?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  place?: InputMaybe<OrderBy>;
  playerPayout?: InputMaybe<OrderBy>;
  roi?: InputMaybe<OrderBy>;
  stakedAmount?: InputMaybe<OrderBy>;
  stakedPercentage?: InputMaybe<OrderBy>;
  winning?: InputMaybe<OrderBy>;
};

/** order by max() on columns of table "tournament_results" */
export type Tournament_Results_Max_Order_By = {
  bullets?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  place?: InputMaybe<OrderBy>;
  playerId?: InputMaybe<OrderBy>;
  playerPayout?: InputMaybe<OrderBy>;
  roi?: InputMaybe<OrderBy>;
  stakedAmount?: InputMaybe<OrderBy>;
  stakedPercentage?: InputMaybe<OrderBy>;
  tournamentId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
  winning?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "tournament_results" */
export type Tournament_Results_Min_Order_By = {
  bullets?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  place?: InputMaybe<OrderBy>;
  playerId?: InputMaybe<OrderBy>;
  playerPayout?: InputMaybe<OrderBy>;
  roi?: InputMaybe<OrderBy>;
  stakedAmount?: InputMaybe<OrderBy>;
  stakedPercentage?: InputMaybe<OrderBy>;
  tournamentId?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
  winning?: InputMaybe<OrderBy>;
};

/** order by stddev() on columns of table "tournament_results" */
export type Tournament_Results_Stddev_Order_By = {
  bullets?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  place?: InputMaybe<OrderBy>;
  playerPayout?: InputMaybe<OrderBy>;
  roi?: InputMaybe<OrderBy>;
  stakedAmount?: InputMaybe<OrderBy>;
  stakedPercentage?: InputMaybe<OrderBy>;
  winning?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "tournament_results" */
export type Tournament_Results_Stddev_Pop_Order_By = {
  bullets?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  place?: InputMaybe<OrderBy>;
  playerPayout?: InputMaybe<OrderBy>;
  roi?: InputMaybe<OrderBy>;
  stakedAmount?: InputMaybe<OrderBy>;
  stakedPercentage?: InputMaybe<OrderBy>;
  winning?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "tournament_results" */
export type Tournament_Results_Stddev_Samp_Order_By = {
  bullets?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  place?: InputMaybe<OrderBy>;
  playerPayout?: InputMaybe<OrderBy>;
  roi?: InputMaybe<OrderBy>;
  stakedAmount?: InputMaybe<OrderBy>;
  stakedPercentage?: InputMaybe<OrderBy>;
  winning?: InputMaybe<OrderBy>;
};

/** order by sum() on columns of table "tournament_results" */
export type Tournament_Results_Sum_Order_By = {
  bullets?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  place?: InputMaybe<OrderBy>;
  playerPayout?: InputMaybe<OrderBy>;
  roi?: InputMaybe<OrderBy>;
  stakedAmount?: InputMaybe<OrderBy>;
  stakedPercentage?: InputMaybe<OrderBy>;
  winning?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "tournament_results" */
export type Tournament_Results_Var_Pop_Order_By = {
  bullets?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  place?: InputMaybe<OrderBy>;
  playerPayout?: InputMaybe<OrderBy>;
  roi?: InputMaybe<OrderBy>;
  stakedAmount?: InputMaybe<OrderBy>;
  stakedPercentage?: InputMaybe<OrderBy>;
  winning?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "tournament_results" */
export type Tournament_Results_Var_Samp_Order_By = {
  bullets?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  place?: InputMaybe<OrderBy>;
  playerPayout?: InputMaybe<OrderBy>;
  roi?: InputMaybe<OrderBy>;
  stakedAmount?: InputMaybe<OrderBy>;
  stakedPercentage?: InputMaybe<OrderBy>;
  winning?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "tournament_results" */
export type Tournament_Results_Variance_Order_By = {
  bullets?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  place?: InputMaybe<OrderBy>;
  playerPayout?: InputMaybe<OrderBy>;
  roi?: InputMaybe<OrderBy>;
  stakedAmount?: InputMaybe<OrderBy>;
  stakedPercentage?: InputMaybe<OrderBy>;
  winning?: InputMaybe<OrderBy>;
};

export type Tournaments_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Tournaments_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Tournaments_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Tournaments_Aggregate_Bool_Exp_Count>;
};

export type Tournaments_Aggregate_Bool_Exp_Bool_And = {
  arguments: Tournaments_Select_Column_Tournaments_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<TournamentsBoolExp>;
  predicate: BooleanComparisonExp;
};

export type Tournaments_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Tournaments_Select_Column_Tournaments_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<TournamentsBoolExp>;
  predicate: BooleanComparisonExp;
};

export type Tournaments_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<TournamentsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<TournamentsBoolExp>;
  predicate: IntComparisonExp;
};

/** order by avg() on columns of table "tournaments" */
export type Tournaments_Avg_Order_By = {
  bulletsMax?: InputMaybe<OrderBy>;
  buyIn?: InputMaybe<OrderBy>;
  currencyId?: InputMaybe<OrderBy>;
  currencyRate?: InputMaybe<OrderBy>;
  guaranteed?: InputMaybe<OrderBy>;
  localCurrencyId?: InputMaybe<OrderBy>;
  sharesMax?: InputMaybe<OrderBy>;
  sort?: InputMaybe<OrderBy>;
};

/** order by max() on columns of table "tournaments" */
export type Tournaments_Max_Order_By = {
  bulletsMax?: InputMaybe<OrderBy>;
  buyIn?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  currencyId?: InputMaybe<OrderBy>;
  currencyRate?: InputMaybe<OrderBy>;
  date?: InputMaybe<OrderBy>;
  eventId?: InputMaybe<OrderBy>;
  guaranteed?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  image?: InputMaybe<OrderBy>;
  localCurrencyId?: InputMaybe<OrderBy>;
  location?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  registrationEnd?: InputMaybe<OrderBy>;
  sharesMax?: InputMaybe<OrderBy>;
  slug?: InputMaybe<OrderBy>;
  sort?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  venueId?: InputMaybe<OrderBy>;
  watchUrl?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "tournaments" */
export type Tournaments_Min_Order_By = {
  bulletsMax?: InputMaybe<OrderBy>;
  buyIn?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  currencyId?: InputMaybe<OrderBy>;
  currencyRate?: InputMaybe<OrderBy>;
  date?: InputMaybe<OrderBy>;
  eventId?: InputMaybe<OrderBy>;
  guaranteed?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  image?: InputMaybe<OrderBy>;
  localCurrencyId?: InputMaybe<OrderBy>;
  location?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  registrationEnd?: InputMaybe<OrderBy>;
  sharesMax?: InputMaybe<OrderBy>;
  slug?: InputMaybe<OrderBy>;
  sort?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  venueId?: InputMaybe<OrderBy>;
  watchUrl?: InputMaybe<OrderBy>;
};

/** select "tournaments_aggregate_bool_exp_bool_and_arguments_columns" columns of table "tournaments" */
export enum Tournaments_Select_Column_Tournaments_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  TicketSalesActive = 'ticketSalesActive',
}

/** select "tournaments_aggregate_bool_exp_bool_or_arguments_columns" columns of table "tournaments" */
export enum Tournaments_Select_Column_Tournaments_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  TicketSalesActive = 'ticketSalesActive',
}

/** order by stddev() on columns of table "tournaments" */
export type Tournaments_Stddev_Order_By = {
  bulletsMax?: InputMaybe<OrderBy>;
  buyIn?: InputMaybe<OrderBy>;
  currencyId?: InputMaybe<OrderBy>;
  currencyRate?: InputMaybe<OrderBy>;
  guaranteed?: InputMaybe<OrderBy>;
  localCurrencyId?: InputMaybe<OrderBy>;
  sharesMax?: InputMaybe<OrderBy>;
  sort?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "tournaments" */
export type Tournaments_Stddev_Pop_Order_By = {
  bulletsMax?: InputMaybe<OrderBy>;
  buyIn?: InputMaybe<OrderBy>;
  currencyId?: InputMaybe<OrderBy>;
  currencyRate?: InputMaybe<OrderBy>;
  guaranteed?: InputMaybe<OrderBy>;
  localCurrencyId?: InputMaybe<OrderBy>;
  sharesMax?: InputMaybe<OrderBy>;
  sort?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "tournaments" */
export type Tournaments_Stddev_Samp_Order_By = {
  bulletsMax?: InputMaybe<OrderBy>;
  buyIn?: InputMaybe<OrderBy>;
  currencyId?: InputMaybe<OrderBy>;
  currencyRate?: InputMaybe<OrderBy>;
  guaranteed?: InputMaybe<OrderBy>;
  localCurrencyId?: InputMaybe<OrderBy>;
  sharesMax?: InputMaybe<OrderBy>;
  sort?: InputMaybe<OrderBy>;
};

/** order by sum() on columns of table "tournaments" */
export type Tournaments_Sum_Order_By = {
  bulletsMax?: InputMaybe<OrderBy>;
  buyIn?: InputMaybe<OrderBy>;
  currencyId?: InputMaybe<OrderBy>;
  currencyRate?: InputMaybe<OrderBy>;
  guaranteed?: InputMaybe<OrderBy>;
  localCurrencyId?: InputMaybe<OrderBy>;
  sharesMax?: InputMaybe<OrderBy>;
  sort?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "tournaments" */
export type Tournaments_Var_Pop_Order_By = {
  bulletsMax?: InputMaybe<OrderBy>;
  buyIn?: InputMaybe<OrderBy>;
  currencyId?: InputMaybe<OrderBy>;
  currencyRate?: InputMaybe<OrderBy>;
  guaranteed?: InputMaybe<OrderBy>;
  localCurrencyId?: InputMaybe<OrderBy>;
  sharesMax?: InputMaybe<OrderBy>;
  sort?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "tournaments" */
export type Tournaments_Var_Samp_Order_By = {
  bulletsMax?: InputMaybe<OrderBy>;
  buyIn?: InputMaybe<OrderBy>;
  currencyId?: InputMaybe<OrderBy>;
  currencyRate?: InputMaybe<OrderBy>;
  guaranteed?: InputMaybe<OrderBy>;
  localCurrencyId?: InputMaybe<OrderBy>;
  sharesMax?: InputMaybe<OrderBy>;
  sort?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "tournaments" */
export type Tournaments_Variance_Order_By = {
  bulletsMax?: InputMaybe<OrderBy>;
  buyIn?: InputMaybe<OrderBy>;
  currencyId?: InputMaybe<OrderBy>;
  currencyRate?: InputMaybe<OrderBy>;
  guaranteed?: InputMaybe<OrderBy>;
  localCurrencyId?: InputMaybe<OrderBy>;
  sharesMax?: InputMaybe<OrderBy>;
  sort?: InputMaybe<OrderBy>;
};

export enum TransactionStatus {
  Created = 'created',
  Error = 'error',
  WaitForConfirmation = 'wait_for_confirmation',
  Waiting_2fa = 'waiting_2fa',
}

export type Transactions_Aggregate_Bool_Exp = {
  count?: InputMaybe<Transactions_Aggregate_Bool_Exp_Count>;
};

export type Transactions_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<TransactionsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<TransactionsBoolExp>;
  predicate: IntComparisonExp;
};

/** order by avg() on columns of table "transactions" */
export type Transactions_Avg_Order_By = {
  amount?: InputMaybe<OrderBy>;
  bullet?: InputMaybe<OrderBy>;
  currencyId?: InputMaybe<OrderBy>;
  userShareBuyId?: InputMaybe<OrderBy>;
};

/** order by max() on columns of table "transactions" */
export type Transactions_Max_Order_By = {
  amount?: InputMaybe<OrderBy>;
  bullet?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  currencyId?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  log?: InputMaybe<OrderBy>;
  paymentId?: InputMaybe<OrderBy>;
  playerShareId?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  ticketId?: InputMaybe<OrderBy>;
  tournamentId?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
  userShareBuyId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "transactions" */
export type Transactions_Min_Order_By = {
  amount?: InputMaybe<OrderBy>;
  bullet?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  currencyId?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  log?: InputMaybe<OrderBy>;
  paymentId?: InputMaybe<OrderBy>;
  playerShareId?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  ticketId?: InputMaybe<OrderBy>;
  tournamentId?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
  userShareBuyId?: InputMaybe<OrderBy>;
};

/** order by stddev() on columns of table "transactions" */
export type Transactions_Stddev_Order_By = {
  amount?: InputMaybe<OrderBy>;
  bullet?: InputMaybe<OrderBy>;
  currencyId?: InputMaybe<OrderBy>;
  userShareBuyId?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "transactions" */
export type Transactions_Stddev_Pop_Order_By = {
  amount?: InputMaybe<OrderBy>;
  bullet?: InputMaybe<OrderBy>;
  currencyId?: InputMaybe<OrderBy>;
  userShareBuyId?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "transactions" */
export type Transactions_Stddev_Samp_Order_By = {
  amount?: InputMaybe<OrderBy>;
  bullet?: InputMaybe<OrderBy>;
  currencyId?: InputMaybe<OrderBy>;
  userShareBuyId?: InputMaybe<OrderBy>;
};

/** order by sum() on columns of table "transactions" */
export type Transactions_Sum_Order_By = {
  amount?: InputMaybe<OrderBy>;
  bullet?: InputMaybe<OrderBy>;
  currencyId?: InputMaybe<OrderBy>;
  userShareBuyId?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "transactions" */
export type Transactions_Var_Pop_Order_By = {
  amount?: InputMaybe<OrderBy>;
  bullet?: InputMaybe<OrderBy>;
  currencyId?: InputMaybe<OrderBy>;
  userShareBuyId?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "transactions" */
export type Transactions_Var_Samp_Order_By = {
  amount?: InputMaybe<OrderBy>;
  bullet?: InputMaybe<OrderBy>;
  currencyId?: InputMaybe<OrderBy>;
  userShareBuyId?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "transactions" */
export type Transactions_Variance_Order_By = {
  amount?: InputMaybe<OrderBy>;
  bullet?: InputMaybe<OrderBy>;
  currencyId?: InputMaybe<OrderBy>;
  userShareBuyId?: InputMaybe<OrderBy>;
};

export enum UserStatus {
  Active = 'active',
  Deleted = 'deleted',
  Inactive = 'inactive',
  VerifyMail = 'verify_mail',
}

export type User_Balances_Aggregate_Bool_Exp = {
  count?: InputMaybe<User_Balances_Aggregate_Bool_Exp_Count>;
};

export type User_Balances_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<UserBalancesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<UserBalancesBoolExp>;
  predicate: IntComparisonExp;
};

/** order by avg() on columns of table "user_balances" */
export type User_Balances_Avg_Order_By = {
  balance?: InputMaybe<OrderBy>;
  currencyId?: InputMaybe<OrderBy>;
};

/** order by max() on columns of table "user_balances" */
export type User_Balances_Max_Order_By = {
  balance?: InputMaybe<OrderBy>;
  currencyId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "user_balances" */
export type User_Balances_Min_Order_By = {
  balance?: InputMaybe<OrderBy>;
  currencyId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
};

/** order by stddev() on columns of table "user_balances" */
export type User_Balances_Stddev_Order_By = {
  balance?: InputMaybe<OrderBy>;
  currencyId?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "user_balances" */
export type User_Balances_Stddev_Pop_Order_By = {
  balance?: InputMaybe<OrderBy>;
  currencyId?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "user_balances" */
export type User_Balances_Stddev_Samp_Order_By = {
  balance?: InputMaybe<OrderBy>;
  currencyId?: InputMaybe<OrderBy>;
};

/** order by sum() on columns of table "user_balances" */
export type User_Balances_Sum_Order_By = {
  balance?: InputMaybe<OrderBy>;
  currencyId?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "user_balances" */
export type User_Balances_Var_Pop_Order_By = {
  balance?: InputMaybe<OrderBy>;
  currencyId?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "user_balances" */
export type User_Balances_Var_Samp_Order_By = {
  balance?: InputMaybe<OrderBy>;
  currencyId?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "user_balances" */
export type User_Balances_Variance_Order_By = {
  balance?: InputMaybe<OrderBy>;
  currencyId?: InputMaybe<OrderBy>;
};

export type User_Share_Buys_Aggregate_Bool_Exp = {
  count?: InputMaybe<User_Share_Buys_Aggregate_Bool_Exp_Count>;
};

export type User_Share_Buys_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<UserShareBuysSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<UserShareBuysBoolExp>;
  predicate: IntComparisonExp;
};

/** order by avg() on columns of table "user_share_buys" */
export type User_Share_Buys_Avg_Order_By = {
  bulletsPlayed?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  markupAmount?: InputMaybe<OrderBy>;
  shareAmount?: InputMaybe<OrderBy>;
  sharePercentage?: InputMaybe<OrderBy>;
  totalAmount?: InputMaybe<OrderBy>;
  won?: InputMaybe<OrderBy>;
};

/** order by max() on columns of table "user_share_buys" */
export type User_Share_Buys_Max_Order_By = {
  bulletsPlayed?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  markupAmount?: InputMaybe<OrderBy>;
  playerShareId?: InputMaybe<OrderBy>;
  shareAmount?: InputMaybe<OrderBy>;
  sharePercentage?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  totalAmount?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
  won?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "user_share_buys" */
export type User_Share_Buys_Min_Order_By = {
  bulletsPlayed?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  markupAmount?: InputMaybe<OrderBy>;
  playerShareId?: InputMaybe<OrderBy>;
  shareAmount?: InputMaybe<OrderBy>;
  sharePercentage?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  totalAmount?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
  won?: InputMaybe<OrderBy>;
};

/** order by stddev() on columns of table "user_share_buys" */
export type User_Share_Buys_Stddev_Order_By = {
  bulletsPlayed?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  markupAmount?: InputMaybe<OrderBy>;
  shareAmount?: InputMaybe<OrderBy>;
  sharePercentage?: InputMaybe<OrderBy>;
  totalAmount?: InputMaybe<OrderBy>;
  won?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "user_share_buys" */
export type User_Share_Buys_Stddev_Pop_Order_By = {
  bulletsPlayed?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  markupAmount?: InputMaybe<OrderBy>;
  shareAmount?: InputMaybe<OrderBy>;
  sharePercentage?: InputMaybe<OrderBy>;
  totalAmount?: InputMaybe<OrderBy>;
  won?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "user_share_buys" */
export type User_Share_Buys_Stddev_Samp_Order_By = {
  bulletsPlayed?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  markupAmount?: InputMaybe<OrderBy>;
  shareAmount?: InputMaybe<OrderBy>;
  sharePercentage?: InputMaybe<OrderBy>;
  totalAmount?: InputMaybe<OrderBy>;
  won?: InputMaybe<OrderBy>;
};

/** order by sum() on columns of table "user_share_buys" */
export type User_Share_Buys_Sum_Order_By = {
  bulletsPlayed?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  markupAmount?: InputMaybe<OrderBy>;
  shareAmount?: InputMaybe<OrderBy>;
  sharePercentage?: InputMaybe<OrderBy>;
  totalAmount?: InputMaybe<OrderBy>;
  won?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "user_share_buys" */
export type User_Share_Buys_Var_Pop_Order_By = {
  bulletsPlayed?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  markupAmount?: InputMaybe<OrderBy>;
  shareAmount?: InputMaybe<OrderBy>;
  sharePercentage?: InputMaybe<OrderBy>;
  totalAmount?: InputMaybe<OrderBy>;
  won?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "user_share_buys" */
export type User_Share_Buys_Var_Samp_Order_By = {
  bulletsPlayed?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  markupAmount?: InputMaybe<OrderBy>;
  shareAmount?: InputMaybe<OrderBy>;
  sharePercentage?: InputMaybe<OrderBy>;
  totalAmount?: InputMaybe<OrderBy>;
  won?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "user_share_buys" */
export type User_Share_Buys_Variance_Order_By = {
  bulletsPlayed?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  markupAmount?: InputMaybe<OrderBy>;
  shareAmount?: InputMaybe<OrderBy>;
  sharePercentage?: InputMaybe<OrderBy>;
  totalAmount?: InputMaybe<OrderBy>;
  won?: InputMaybe<OrderBy>;
};

export type Venue_Users_Aggregate_Bool_Exp = {
  count?: InputMaybe<Venue_Users_Aggregate_Bool_Exp_Count>;
};

export type Venue_Users_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<VenueUsersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<VenueUsersBoolExp>;
  predicate: IntComparisonExp;
};

/** order by avg() on columns of table "venue_users" */
export type Venue_Users_Avg_Order_By = {
  id?: InputMaybe<OrderBy>;
};

/** order by max() on columns of table "venue_users" */
export type Venue_Users_Max_Order_By = {
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
  venueId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "venue_users" */
export type Venue_Users_Min_Order_By = {
  createdAt?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
  userId?: InputMaybe<OrderBy>;
  venueId?: InputMaybe<OrderBy>;
};

/** order by stddev() on columns of table "venue_users" */
export type Venue_Users_Stddev_Order_By = {
  id?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "venue_users" */
export type Venue_Users_Stddev_Pop_Order_By = {
  id?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "venue_users" */
export type Venue_Users_Stddev_Samp_Order_By = {
  id?: InputMaybe<OrderBy>;
};

/** order by sum() on columns of table "venue_users" */
export type Venue_Users_Sum_Order_By = {
  id?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "venue_users" */
export type Venue_Users_Var_Pop_Order_By = {
  id?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "venue_users" */
export type Venue_Users_Var_Samp_Order_By = {
  id?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "venue_users" */
export type Venue_Users_Variance_Order_By = {
  id?: InputMaybe<OrderBy>;
};

export type NonceMutationVariables = Exact<{
  address: Scalars['String']['input'];
}>;

export type NonceMutation = {
  __typename?: 'mutation_root';
  authSignature?:
    | { __typename?: 'authSignatureOutput'; nonce: string }
    | null
    | undefined;
};

export type AccessTokenMutationVariables = Exact<{
  address?: InputMaybe<Scalars['String']['input']>;
  signature?: InputMaybe<Scalars['String']['input']>;
}>;

export type AccessTokenMutation = {
  __typename?: 'mutation_root';
  authLogin?:
    | {
        __typename?: 'authLoginOutput';
        accesstoken?: string | null | undefined;
        userId?: string | null | undefined;
      }
    | null
    | undefined;
};

export type RefreshAccessTokenMutationVariables = Exact<{
  accessToken?: InputMaybe<Scalars['String']['input']>;
}>;

export type RefreshAccessTokenMutation = {
  __typename?: 'mutation_root';
  authRefresh?:
    | {
        __typename?: 'authLoginOutput';
        userId?: string | null | undefined;
        accesstoken?: string | null | undefined;
      }
    | null
    | undefined;
};

export type DepositCurrenciesDataFragment = {
  __typename?: 'BlockchainCurrencies';
  id: number;
  symbol: string;
  icon: string;
  contract?: string | null | undefined;
  decimals: number;
  deposit: boolean;
  withdraw: boolean;
  withdrawFee: number;
};

export type BlockchainsQueryVariables = Exact<{
  supportedBlockchainIds:
    | Array<Scalars['Int']['input']>
    | Scalars['Int']['input'];
}>;

export type BlockchainsQuery = {
  __typename?: 'query_root';
  blockchains: Array<{
    __typename?: 'Blockchains';
    id: number;
    chainId: number;
    name: string;
    iconUrl?: string | null | undefined;
    blockchain_currencies: Array<{
      __typename?: 'BlockchainCurrencies';
      id: number;
      symbol: string;
      icon: string;
      contract?: string | null | undefined;
      decimals: number;
      deposit: boolean;
      withdraw: boolean;
      withdrawFee: number;
    }>;
  }>;
};

export type PaymentsQueryVariables = Exact<{
  offset: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
}>;

export type PaymentsQuery = {
  __typename?: 'query_root';
  payments: Array<{
    __typename?: 'Payments';
    id: any;
    createdAt?: any | null | undefined;
    type: string;
    status: string;
    txid?: string | null | undefined;
    amount: number;
    blockchain_currency?:
      | {
          __typename?: 'BlockchainCurrencies';
          id: number;
          blockchain: {
            __typename?: 'Blockchains';
            id: number;
            scanUrl?: string | null | undefined;
          };
        }
      | null
      | undefined;
  }>;
  paymentsAggregate: {
    __typename?: 'PaymentsAggregate';
    aggregate?:
      | { __typename?: 'PaymentsAggregateFields'; totalCount: number }
      | null
      | undefined;
  };
};

export type DepositBanksQueryVariables = Exact<{ [key: string]: never }>;

export type DepositBanksQuery = {
  __typename?: 'query_root';
  paymentMethodBanks: Array<{
    __typename?: 'PaymentMethodBanks';
    id: number;
    name: string;
    country: string;
    depositDetails: string;
    depositCurrency: string;
    depositMin: number;
    depositMax: number;
    depositFee: number;
    depositExchangeRate: number;
  }>;
};

export type BankWithdrawalFormDataQueryVariables = Exact<{
  [key: string]: never;
}>;

export type BankWithdrawalFormDataQuery = {
  __typename?: 'query_root';
  me?:
    | {
        __typename?: 'UserOutput';
        id: string;
        email?: string | null | undefined;
        twofa?: boolean | null | undefined;
      }
    | null
    | undefined;
  paymentMethodBanks: Array<{
    __typename?: 'PaymentMethodBanks';
    id: number;
    name: string;
    country: string;
    countryId: number;
    withdrawMin: number;
    withdrawMax: number;
    withdrawFee: number;
    withdrawExchangeRate: number;
    depositCurrency: string;
  }>;
};

export type BlockchainWithdrawalFormDataQueryVariables = Exact<{
  [key: string]: never;
}>;

export type BlockchainWithdrawalFormDataQuery = {
  __typename?: 'query_root';
  me?:
    | {
        __typename?: 'UserOutput';
        id: string;
        email?: string | null | undefined;
        erc20Address?: string | null | undefined;
        twofa?: boolean | null | undefined;
      }
    | null
    | undefined;
};

export type DepositBankInfoQueryVariables = Exact<{
  bankId: Scalars['Int']['input'];
  amount: Scalars['Float']['input'];
}>;

export type DepositBankInfoQuery = {
  __typename?: 'query_root';
  getDepositBankInfo?:
    | {
        __typename?: 'depositBankOutput';
        id?: string | null | undefined;
        depositDetails: string;
        depositReference: string;
      }
    | null
    | undefined;
};

export type BlockchainDepositMutationVariables = Exact<{
  address: Scalars['String']['input'];
  blockchain: Scalars['Int']['input'];
  currency: Scalars['Int']['input'];
  amount: Scalars['Float']['input'];
}>;

export type BlockchainDepositMutation = {
  __typename?: 'mutation_root';
  submitDeposit?:
    | {
        __typename?: 'DepositOutput';
        id?: string | null | undefined;
        status: TransactionStatus;
        depositAddress?: string | null | undefined;
        message?: string | null | undefined;
      }
    | null
    | undefined;
};

export type BlockchainSubmittedDepositMutationVariables = Exact<{
  id: Scalars['String']['input'];
  tx: Scalars['String']['input'];
}>;

export type BlockchainSubmittedDepositMutation = {
  __typename?: 'mutation_root';
  submittedDeposit?:
    | {
        __typename?: 'StatusOutput';
        id?: string | null | undefined;
        status: TransactionStatus;
      }
    | null
    | undefined;
};

export type SubmitBankDepositMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type SubmitBankDepositMutation = {
  __typename?: 'mutation_root';
  submitBankDeposit?:
    | {
        __typename?: 'StatusOutput';
        status: TransactionStatus;
        message?: string | null | undefined;
      }
    | null
    | undefined;
};

export type BlockchainSubmitWithdrawMutationVariables = Exact<{
  address: Scalars['String']['input'];
  blockchain: Scalars['Int']['input'];
  currency: Scalars['Int']['input'];
  amount: Scalars['Float']['input'];
  twoFA?: InputMaybe<Scalars['String']['input']>;
}>;

export type BlockchainSubmitWithdrawMutation = {
  __typename?: 'mutation_root';
  submitWithdraw?:
    | {
        __typename?: 'StatusOutput';
        id?: string | null | undefined;
        status: TransactionStatus;
      }
    | null
    | undefined;
};

export type SubmitBankWithdrawMutationVariables = Exact<{
  bank: Scalars['Int']['input'];
  bankName?: InputMaybe<Scalars['String']['input']>;
  accountHolder: Scalars['String']['input'];
  accountNumber: Scalars['String']['input'];
  amount: Scalars['Float']['input'];
  twoFA?: InputMaybe<Scalars['String']['input']>;
}>;

export type SubmitBankWithdrawMutation = {
  __typename?: 'mutation_root';
  submitBankWithdraw?:
    | {
        __typename?: 'StatusOutput';
        status: TransactionStatus;
        message?: string | null | undefined;
      }
    | null
    | undefined;
};

export type CommonEventDataFragment = {
  __typename?: 'Events';
  id: any;
  name: string;
  slug?: string | null | undefined;
  location: string;
  image?: string | null | undefined;
  dateFrom: any;
  dateTill: any;
};

export type EventListItemDataFragment = {
  __typename?: 'Events';
  status: StatusesEnum;
  slug?: string | null | undefined;
  id: any;
  name: string;
  location: string;
  image?: string | null | undefined;
  dateFrom: any;
  dateTill: any;
};

export type HomePageEventsQueryVariables = Exact<{ [key: string]: never }>;

export type HomePageEventsQuery = {
  __typename?: 'query_root';
  events: Array<{
    __typename?: 'Events';
    id: any;
    name: string;
    slug?: string | null | undefined;
    location: string;
    image?: string | null | undefined;
    dateFrom: any;
    dateTill: any;
    tournaments: Array<{
      __typename?: 'Tournaments';
      id: any;
      slug?: string | null | undefined;
      name: string;
      location?: string | null | undefined;
      date: any;
      buyIn: number;
      venue: {
        __typename?: 'Venues';
        id: any;
        slug?: string | null | undefined;
      };
      player_shares: Array<{
        __typename?: 'PlayerShares';
        id: any;
        status: StatusGamesEnum;
        markUp: number;
        bullets: number;
        shareDilution: boolean;
        sharesToSell: number;
        sort: number;
        player: {
          __typename?: 'Players';
          id: any;
          name: string;
          image?: string | null | undefined;
          slug?: string | null | undefined;
        };
        player_shares_bought?:
          | {
              __typename?: 'PlayerSharesBought';
              totalShareAmount?: number | null | undefined;
              totalSharePercentage?: number | null | undefined;
              totalTotalAmount?: number | null | undefined;
            }
          | null
          | undefined;
      }>;
    }>;
  }>;
};

export type PublicEventPageDataFragment = {
  __typename?: 'Events';
  description?: string | null | undefined;
  followersCount: number;
  id: any;
  name: string;
  slug?: string | null | undefined;
  location: string;
  image?: string | null | undefined;
  dateFrom: any;
  dateTill: any;
  venue: {
    __typename?: 'Venues';
    id: any;
    facebook?: string | null | undefined;
  };
};

export type PublicEventPageQueryVariables = Exact<{
  slug: Scalars['String']['input'];
}>;

export type PublicEventPageQuery = {
  __typename?: 'query_root';
  events: Array<{
    __typename?: 'Events';
    description?: string | null | undefined;
    followersCount: number;
    id: any;
    name: string;
    slug?: string | null | undefined;
    location: string;
    image?: string | null | undefined;
    dateFrom: any;
    dateTill: any;
    tournaments: Array<{
      __typename?: 'Tournaments';
      id: any;
      slug?: string | null | undefined;
      name: string;
      location?: string | null | undefined;
      date: any;
      buyIn: number;
      venue: {
        __typename?: 'Venues';
        id: any;
        slug?: string | null | undefined;
      };
      player_shares: Array<{
        __typename?: 'PlayerShares';
        id: any;
        status: StatusGamesEnum;
        markUp: number;
        bullets: number;
        shareDilution: boolean;
        sharesToSell: number;
        sort: number;
        player: {
          __typename?: 'Players';
          id: any;
          name: string;
          image?: string | null | undefined;
          slug?: string | null | undefined;
        };
        player_shares_bought?:
          | {
              __typename?: 'PlayerSharesBought';
              totalShareAmount?: number | null | undefined;
              totalSharePercentage?: number | null | undefined;
              totalTotalAmount?: number | null | undefined;
            }
          | null
          | undefined;
      }>;
    }>;
    venue: {
      __typename?: 'Venues';
      id: any;
      facebook?: string | null | undefined;
    };
  }>;
};

export type EventsByNameQueryVariables = Exact<{
  name: Scalars['String']['input'];
}>;

export type EventsByNameQuery = {
  __typename?: 'query_root';
  events: Array<{
    __typename?: 'Events';
    id: any;
    name: string;
    image?: string | null | undefined;
    dateFrom: any;
    dateTill: any;
    slug?: string | null | undefined;
  }>;
};

export type SellShareEventsQueryVariables = Exact<{
  playerId: Scalars['uuid']['input'];
}>;

export type SellShareEventsQuery = {
  __typename?: 'query_root';
  events: Array<{
    __typename?: 'Events';
    status: StatusesEnum;
    slug?: string | null | undefined;
    id: any;
    name: string;
    location: string;
    image?: string | null | undefined;
    dateFrom: any;
    dateTill: any;
    tournaments: Array<{
      __typename?: 'Tournaments';
      id: any;
      buyIn: number;
      player_shares: Array<{ __typename?: 'PlayerShares'; id: any }>;
    }>;
  }>;
};

export type EventDetailsQueryVariables = Exact<{
  slug: Scalars['String']['input'];
  includePlayerShares?: InputMaybe<Scalars['Boolean']['input']>;
  playerId?: InputMaybe<Scalars['uuid']['input']>;
  eventsWhere?: InputMaybe<EventsBoolExp>;
  tournamentsWhere?: InputMaybe<TournamentsBoolExp>;
}>;

export type EventDetailsQuery = {
  __typename?: 'query_root';
  events: Array<{
    __typename?: 'Events';
    venueId: any;
    id: any;
    name: string;
    slug?: string | null | undefined;
    location: string;
    image?: string | null | undefined;
    dateFrom: any;
    dateTill: any;
    tournaments: Array<{
      __typename?: 'Tournaments';
      slug?: string | null | undefined;
      status: StatusGamesEnum;
      name: string;
      location?: string | null | undefined;
      type: string;
      guaranteed: number;
      date: any;
      sharesMax: number;
      bulletsMax: number;
      ticketSalesActive: boolean;
      id: any;
      buyIn: number;
      local_currency: {
        __typename?: 'Currencies';
        id: number;
        symbol: string;
        rate: number;
      };
      player_shares?: Array<{ __typename?: 'PlayerShares'; id: any }>;
    }>;
  }>;
};

export type EditEventDataFragmentFragment = {
  __typename?: 'Events';
  id: any;
  name: string;
  status: StatusesEnum;
  dateFrom: any;
  dateTill: any;
  location: string;
  description?: string | null | undefined;
  image?: string | null | undefined;
};

export type EditEventDataQueryVariables = Exact<{
  id: Scalars['uuid']['input'];
}>;

export type EditEventDataQuery = {
  __typename?: 'query_root';
  event?:
    | {
        __typename?: 'Events';
        id: any;
        name: string;
        status: StatusesEnum;
        dateFrom: any;
        dateTill: any;
        location: string;
        description?: string | null | undefined;
        image?: string | null | undefined;
      }
    | null
    | undefined;
};

export type EventFinancialOverviewQueryVariables = Exact<{
  eventId: Scalars['uuid']['input'];
}>;

export type EventFinancialOverviewQuery = {
  __typename?: 'query_root';
  event?:
    | {
        __typename?: 'Events';
        id: any;
        tournaments: Array<{
          __typename?: 'Tournaments';
          id: any;
          name: string;
          status: StatusGamesEnum;
          local_currency: {
            __typename?: 'Currencies';
            id: number;
            symbol: string;
            rate: number;
          };
          balances: Array<{
            __typename?: 'TournamentBalances';
            id?: string | null | undefined;
            status?: string | null | undefined;
            balance?: number | null | undefined;
          }>;
        }>;
      }
    | null
    | undefined;
};

export type DownloadEventTransactionsQueryVariables = Exact<{
  eventId: Scalars['String']['input'];
}>;

export type DownloadEventTransactionsQuery = {
  __typename?: 'query_root';
  downloadEventTransactions?:
    | {
        __typename?: 'downloadFileOutput';
        status: GeneralStatus;
        format: string;
        filename?: string | null | undefined;
        base64?: string | null | undefined;
      }
    | null
    | undefined;
};

export type CreateEventMutationVariables = Exact<{
  data: EventsInsertInput;
}>;

export type CreateEventMutation = {
  __typename?: 'mutation_root';
  insertEvent?:
    | {
        __typename?: 'Events';
        id: any;
        name: string;
        status: StatusesEnum;
        dateFrom: any;
        dateTill: any;
        location: string;
        description?: string | null | undefined;
        image?: string | null | undefined;
      }
    | null
    | undefined;
};

export type EditEventMutationVariables = Exact<{
  id: Scalars['uuid']['input'];
  data: EventsSetInput;
}>;

export type EditEventMutation = {
  __typename?: 'mutation_root';
  updateEvent?:
    | {
        __typename?: 'Events';
        id: any;
        name: string;
        status: StatusesEnum;
        dateFrom: any;
        dateTill: any;
        location: string;
        description?: string | null | undefined;
        image?: string | null | undefined;
      }
    | null
    | undefined;
};

export type ChangeEventStatusMutationVariables = Exact<{
  id: Scalars['uuid']['input'];
  status: StatusesEnum;
}>;

export type ChangeEventStatusMutation = {
  __typename?: 'mutation_root';
  updateEvent?:
    | { __typename?: 'Events'; id: any; status: StatusesEnum }
    | null
    | undefined;
};

export type FollowEventMutationVariables = Exact<{
  eventId: Scalars['uuid']['input'];
}>;

export type FollowEventMutation = {
  __typename?: 'mutation_root';
  insertFollower?: { __typename?: 'Followers'; id: number } | null | undefined;
};

export type UnFollowEventMutationVariables = Exact<{
  eventId: Scalars['uuid']['input'];
}>;

export type UnFollowEventMutation = {
  __typename?: 'mutation_root';
  deleteFollowers?:
    | { __typename?: 'FollowersMutationResponse'; affected_rows: number }
    | null
    | undefined;
};

export type PlayerShareCardDataFragment = {
  __typename?: 'PlayerShares';
  id: any;
  status: StatusGamesEnum;
  markUp: number;
  bullets: number;
  shareDilution: boolean;
  sharesToSell: number;
  sort: number;
  player: {
    __typename?: 'Players';
    id: any;
    name: string;
    image?: string | null | undefined;
    slug?: string | null | undefined;
  };
  player_shares_bought?:
    | {
        __typename?: 'PlayerSharesBought';
        totalShareAmount?: number | null | undefined;
        totalSharePercentage?: number | null | undefined;
        totalTotalAmount?: number | null | undefined;
      }
    | null
    | undefined;
};

export type PlayerPlayerShareDataFragment = {
  __typename?: 'PlayerShares';
  id: any;
  note?: string | null | undefined;
  markUp: number;
  sharesToSell: number;
  bullets: number;
  shareDilution: boolean;
  status: StatusGamesEnum;
  player_shares_bought?:
    | {
        __typename?: 'PlayerSharesBought';
        totalShareAmount?: number | null | undefined;
        totalSharePercentage?: number | null | undefined;
        totalTotalAmount?: number | null | undefined;
      }
    | null
    | undefined;
  tournament: {
    __typename?: 'Tournaments';
    id: any;
    name: string;
    slug?: string | null | undefined;
    location?: string | null | undefined;
    buyIn: number;
    date: any;
    event?:
      | {
          __typename?: 'Events';
          id: any;
          name: string;
          slug?: string | null | undefined;
          location: string;
          image?: string | null | undefined;
          venue: { __typename?: 'Venues'; id: any; name: string };
        }
      | null
      | undefined;
  };
};

export type UserVenueTournamentPlayerShareDataFragment = {
  __typename?: 'PlayerShares';
  id: any;
  bullets: number;
  shareDilution: boolean;
  status: StatusGamesEnum;
  player: { __typename?: 'Players'; id: any; name: string };
  player_shares_bought?:
    | {
        __typename?: 'PlayerSharesBought';
        totalSharePercentage?: number | null | undefined;
        totalShareAmount?: number | null | undefined;
      }
    | null
    | undefined;
  tournament_result?:
    | {
        __typename?: 'TournamentResults';
        bullets: number;
        toBePaid?: any | null | undefined;
        status: TournamentResultStatusesEnum;
        place?: number | null | undefined;
        winning?: number | null | undefined;
        playerPayout?: number | null | undefined;
        stakedAmount: number;
        stakedPercentage: number;
      }
    | null
    | undefined;
  tickets: Array<{
    __typename?: 'Tickets';
    id: any;
    playerName?: string | null | undefined;
    status: TicketsStatusEnum;
    amount: number;
    bullet: number;
    won?: number | null | undefined;
    wonLocal?: number | null | undefined;
    tournament_result?:
      | {
          __typename?: 'TournamentResults';
          bullets: number;
          toBePaid?: any | null | undefined;
          status: TournamentResultStatusesEnum;
          place?: number | null | undefined;
          winning?: number | null | undefined;
          playerPayout?: number | null | undefined;
          stakedAmount: number;
          stakedPercentage: number;
        }
      | null
      | undefined;
  }>;
};

export type UserVenueTournamentTicketDataFragment = {
  __typename?: 'Tickets';
  id: any;
  playerName?: string | null | undefined;
  status: TicketsStatusEnum;
  amount: number;
  bullet: number;
  won?: number | null | undefined;
  wonLocal?: number | null | undefined;
  tournament_result?:
    | {
        __typename?: 'TournamentResults';
        bullets: number;
        toBePaid?: any | null | undefined;
        status: TournamentResultStatusesEnum;
        place?: number | null | undefined;
        winning?: number | null | undefined;
        playerPayout?: number | null | undefined;
        stakedAmount: number;
        stakedPercentage: number;
      }
    | null
    | undefined;
};

export type UserVenueTournamentResultDataFragment = {
  __typename?: 'TournamentResults';
  bullets: number;
  toBePaid?: any | null | undefined;
  status: TournamentResultStatusesEnum;
  place?: number | null | undefined;
  winning?: number | null | undefined;
  playerPayout?: number | null | undefined;
  stakedAmount: number;
  stakedPercentage: number;
};

export type ExtendedPlayerDataFragment = {
  __typename?: 'Players';
  id: any;
  name: string;
  realName?: string | null | undefined;
  image?: string | null | undefined;
  followersCount: number;
  facebook?: string | null | undefined;
  twitter?: string | null | undefined;
  verified: boolean;
  allTimeList?: number | null | undefined;
  totalLiveEarning: number;
  description?: string | null | undefined;
  globalpokerindexUrl?: string | null | undefined;
  thehendonmobUrl?: string | null | undefined;
  actionsSold: number;
  totalWinnings: number;
  highestWinning: number;
  highestRoi: number;
  gpiRanking?: number | null | undefined;
  allTimeListCountry?: string | null | undefined;
  allTimeListCountryPlace?: number | null | undefined;
};

export type PublicPlayerDataFragment = {
  __typename?: 'Players';
  id: any;
  name: string;
  realName?: string | null | undefined;
  image?: string | null | undefined;
  followersCount: number;
  facebook?: string | null | undefined;
  twitter?: string | null | undefined;
  verified: boolean;
  allTimeList?: number | null | undefined;
  totalLiveEarning: number;
  description?: string | null | undefined;
  globalpokerindexUrl?: string | null | undefined;
  thehendonmobUrl?: string | null | undefined;
  actionsSold: number;
  totalWinnings: number;
  highestWinning: number;
  highestRoi: number;
  gpiRanking?: number | null | undefined;
  allTimeListCountry?: string | null | undefined;
  allTimeListCountryPlace?: number | null | undefined;
  followers: Array<{ __typename?: 'Followers'; id: number; userId: string }>;
  player_shares: Array<{
    __typename?: 'PlayerShares';
    id: any;
    note?: string | null | undefined;
    markUp: number;
    sharesToSell: number;
    bullets: number;
    shareDilution: boolean;
    status: StatusGamesEnum;
    player_shares_bought?:
      | {
          __typename?: 'PlayerSharesBought';
          totalShareAmount?: number | null | undefined;
          totalSharePercentage?: number | null | undefined;
          totalTotalAmount?: number | null | undefined;
        }
      | null
      | undefined;
    tournament: {
      __typename?: 'Tournaments';
      id: any;
      name: string;
      slug?: string | null | undefined;
      location?: string | null | undefined;
      buyIn: number;
      date: any;
      event?:
        | {
            __typename?: 'Events';
            id: any;
            name: string;
            slug?: string | null | undefined;
            location: string;
            image?: string | null | undefined;
            venue: { __typename?: 'Venues'; id: any; name: string };
          }
        | null
        | undefined;
    };
  }>;
};

export type SelfPlayerDataFragment = {
  __typename?: 'Players';
  id: any;
  name: string;
  realName?: string | null | undefined;
  image?: string | null | undefined;
  followersCount: number;
  facebook?: string | null | undefined;
  twitter?: string | null | undefined;
  verified: boolean;
  allTimeList?: number | null | undefined;
  totalLiveEarning: number;
  description?: string | null | undefined;
  globalpokerindexUrl?: string | null | undefined;
  thehendonmobUrl?: string | null | undefined;
  actionsSold: number;
  totalWinnings: number;
  highestWinning: number;
  highestRoi: number;
  gpiRanking?: number | null | undefined;
  allTimeListCountry?: string | null | undefined;
  allTimeListCountryPlace?: number | null | undefined;
  player_followers_view: Array<{
    __typename?: 'PlayerFollowersView';
    id?: number | null | undefined;
    erc20Address?: string | null | undefined;
    avatar?: string | null | undefined;
    username?: string | null | undefined;
    email?: string | null | undefined;
  }>;
};

export type PublicPlayerPageDataQueryVariables = Exact<{
  slug: Scalars['String']['input'];
}>;

export type PublicPlayerPageDataQuery = {
  __typename?: 'query_root';
  players: Array<{
    __typename?: 'Players';
    id: any;
    name: string;
    realName?: string | null | undefined;
    image?: string | null | undefined;
    followersCount: number;
    facebook?: string | null | undefined;
    twitter?: string | null | undefined;
    verified: boolean;
    allTimeList?: number | null | undefined;
    totalLiveEarning: number;
    description?: string | null | undefined;
    globalpokerindexUrl?: string | null | undefined;
    thehendonmobUrl?: string | null | undefined;
    actionsSold: number;
    totalWinnings: number;
    highestWinning: number;
    highestRoi: number;
    gpiRanking?: number | null | undefined;
    allTimeListCountry?: string | null | undefined;
    allTimeListCountryPlace?: number | null | undefined;
    followers: Array<{ __typename?: 'Followers'; id: number; userId: string }>;
    player_shares: Array<{
      __typename?: 'PlayerShares';
      id: any;
      note?: string | null | undefined;
      markUp: number;
      sharesToSell: number;
      bullets: number;
      shareDilution: boolean;
      status: StatusGamesEnum;
      player_shares_bought?:
        | {
            __typename?: 'PlayerSharesBought';
            totalShareAmount?: number | null | undefined;
            totalSharePercentage?: number | null | undefined;
            totalTotalAmount?: number | null | undefined;
          }
        | null
        | undefined;
      tournament: {
        __typename?: 'Tournaments';
        id: any;
        name: string;
        slug?: string | null | undefined;
        location?: string | null | undefined;
        buyIn: number;
        date: any;
        event?:
          | {
              __typename?: 'Events';
              id: any;
              name: string;
              slug?: string | null | undefined;
              location: string;
              image?: string | null | undefined;
              venue: { __typename?: 'Venues'; id: any; name: string };
            }
          | null
          | undefined;
      };
    }>;
  }>;
};

export type SelfPlayerQueryVariables = Exact<{
  id: Scalars['uuid']['input'];
}>;

export type SelfPlayerQuery = {
  __typename?: 'query_root';
  player?:
    | {
        __typename?: 'Players';
        id: any;
        name: string;
        realName?: string | null | undefined;
        image?: string | null | undefined;
        followersCount: number;
        facebook?: string | null | undefined;
        twitter?: string | null | undefined;
        verified: boolean;
        allTimeList?: number | null | undefined;
        totalLiveEarning: number;
        description?: string | null | undefined;
        globalpokerindexUrl?: string | null | undefined;
        thehendonmobUrl?: string | null | undefined;
        actionsSold: number;
        totalWinnings: number;
        highestWinning: number;
        highestRoi: number;
        gpiRanking?: number | null | undefined;
        allTimeListCountry?: string | null | undefined;
        allTimeListCountryPlace?: number | null | undefined;
        player_followers_view: Array<{
          __typename?: 'PlayerFollowersView';
          id?: number | null | undefined;
          erc20Address?: string | null | undefined;
          avatar?: string | null | undefined;
          username?: string | null | undefined;
          email?: string | null | undefined;
        }>;
      }
    | null
    | undefined;
};

export type BuyPlayerShareModalDataQueryVariables = Exact<{
  playerId: Scalars['uuid']['input'];
  shareId: Scalars['uuid']['input'];
}>;

export type BuyPlayerShareModalDataQuery = {
  __typename?: 'query_root';
  player?:
    | {
        __typename?: 'Players';
        id: any;
        name: string;
        image?: string | null | undefined;
      }
    | null
    | undefined;
  playerShare?:
    | {
        __typename?: 'PlayerShares';
        shareDilution: boolean;
        slug?: string | null | undefined;
        maxPerUserShare?: number | null | undefined;
        passwordRequired: boolean;
        id: any;
        note?: string | null | undefined;
        markUp: number;
        sharesToSell: number;
        bullets: number;
        status: StatusGamesEnum;
        player_shares_bought?:
          | {
              __typename?: 'PlayerSharesBought';
              totalShareAmount?: number | null | undefined;
              totalSharePercentage?: number | null | undefined;
              totalTotalAmount?: number | null | undefined;
            }
          | null
          | undefined;
        tournament: {
          __typename?: 'Tournaments';
          id: any;
          name: string;
          slug?: string | null | undefined;
          location?: string | null | undefined;
          buyIn: number;
          date: any;
          event?:
            | {
                __typename?: 'Events';
                id: any;
                name: string;
                slug?: string | null | undefined;
                location: string;
                image?: string | null | undefined;
                venue: { __typename?: 'Venues'; id: any; name: string };
              }
            | null
            | undefined;
        };
      }
    | null
    | undefined;
  userShareBuys: Array<{
    __typename?: 'UserShareBuys';
    id: number;
    sharePercentage: number;
  }>;
};

export type PlayerShareUrlParamDataQueryVariables = Exact<{
  slug: Scalars['String']['input'];
}>;

export type PlayerShareUrlParamDataQuery = {
  __typename?: 'query_root';
  playerShares: Array<{
    __typename?: 'PlayerShares';
    id: any;
    player: { __typename?: 'Players'; id: any };
  }>;
};

export type PlayersByNameQueryVariables = Exact<{
  name: Scalars['String']['input'];
}>;

export type PlayersByNameQuery = {
  __typename?: 'query_root';
  players: Array<{
    __typename?: 'Players';
    id: any;
    name: string;
    realName?: string | null | undefined;
    image?: string | null | undefined;
    slug?: string | null | undefined;
  }>;
};

export type PlayerSharesQueryVariables = Exact<{
  playerId: Scalars['uuid']['input'];
}>;

export type PlayerSharesQuery = {
  __typename?: 'query_root';
  playerShares: Array<{
    __typename?: 'PlayerShares';
    id: any;
    status: StatusGamesEnum;
    sharesToSell: number;
    markUp: number;
    bullets: number;
    shareDilution: boolean;
    allowCancel: boolean;
    player_shares_bought?:
      | {
          __typename?: 'PlayerSharesBought';
          totalSharePercentage?: number | null | undefined;
        }
      | null
      | undefined;
    tournament: {
      __typename?: 'Tournaments';
      id: any;
      name: string;
      location?: string | null | undefined;
      image?: string | null | undefined;
      date: any;
      buyIn: number;
      status: StatusGamesEnum;
      watchUrl?: string | null | undefined;
      event?:
        | {
            __typename?: 'Events';
            id: any;
            name: string;
            location: string;
            image?: string | null | undefined;
          }
        | null
        | undefined;
      tournament_results: Array<{
        __typename?: 'TournamentResults';
        id: number;
        place?: number | null | undefined;
        winning?: number | null | undefined;
      }>;
    };
  }>;
};

export type EditUserPlayerProfileFormDataQueryVariables = Exact<{
  id: Scalars['uuid']['input'];
}>;

export type EditUserPlayerProfileFormDataQuery = {
  __typename?: 'query_root';
  player?:
    | {
        __typename?: 'Players';
        id: any;
        facebook?: string | null | undefined;
        twitter?: string | null | undefined;
        description?: string | null | undefined;
        image?: string | null | undefined;
      }
    | null
    | undefined;
};

export type BuyPlayerShareMutationVariables = Exact<{
  playerShareId: Scalars['String']['input'];
  percentage: Scalars['Float']['input'];
  password?: InputMaybe<Scalars['String']['input']>;
}>;

export type BuyPlayerShareMutation = {
  __typename?: 'mutation_root';
  submitShareBuy?:
    | {
        __typename?: 'generalOutput';
        status?: GeneralStatus | null | undefined;
        message: string;
      }
    | null
    | undefined;
};

export type RegisterPlayerMutationVariables = Exact<{
  data: PlayerRegisterInput;
}>;

export type RegisterPlayerMutation = {
  __typename?: 'mutation_root';
  registerPlayer?:
    | {
        __typename?: 'generalOutput';
        status?: GeneralStatus | null | undefined;
        message: string;
      }
    | null
    | undefined;
};

export type CancelPlayerShareMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;

export type CancelPlayerShareMutation = {
  __typename?: 'mutation_root';
  submitPlayerShareCancel?:
    | {
        __typename?: 'generalOutput';
        status?: GeneralStatus | null | undefined;
        message: string;
      }
    | null
    | undefined;
};

export type CreatePlayerShareMutationVariables = Exact<{
  tournamentId: Scalars['String']['input'];
  sharesToSell: Scalars['Int']['input'];
  markUp: Scalars['Float']['input'];
  bullets: Scalars['Int']['input'];
  shareDilution: Scalars['Boolean']['input'];
  maxPerUserShare?: InputMaybe<Scalars['Float']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
}>;

export type CreatePlayerShareMutation = {
  __typename?: 'mutation_root';
  submitPlayerShareCreate?:
    | {
        __typename?: 'generalOutput';
        status?: GeneralStatus | null | undefined;
        message: string;
      }
    | null
    | undefined;
};

export type FollowPlayerMutationVariables = Exact<{
  playerId: Scalars['String']['input'];
}>;

export type FollowPlayerMutation = {
  __typename?: 'mutation_root';
  followPlayer?:
    | {
        __typename?: 'generalOutput';
        status?: GeneralStatus | null | undefined;
        message: string;
      }
    | null
    | undefined;
};

export type UnFollowPlayerMutationVariables = Exact<{
  playerId: Scalars['String']['input'];
}>;

export type UnFollowPlayerMutation = {
  __typename?: 'mutation_root';
  unfollowPlayer?:
    | {
        __typename?: 'generalOutput';
        status?: GeneralStatus | null | undefined;
        message: string;
      }
    | null
    | undefined;
};

export type UpdatePlayerProfileMutationVariables = Exact<{
  playerId: Scalars['uuid']['input'];
  data: PlayersSetInput;
}>;

export type UpdatePlayerProfileMutation = {
  __typename?: 'mutation_root';
  updatePlayer?:
    | {
        __typename?: 'Players';
        id: any;
        facebook?: string | null | undefined;
        twitter?: string | null | undefined;
        description?: string | null | undefined;
        image?: string | null | undefined;
      }
    | null
    | undefined;
};

export type PlayerTicketListItemDataFragment = {
  __typename?: 'Tickets';
  id: any;
  status: TicketsStatusEnum;
  bullet: number;
  won?: number | null | undefined;
  wonLocal?: number | null | undefined;
  playerShareId?: any | null | undefined;
  playerName?: string | null | undefined;
  tournament: {
    __typename?: 'Tournaments';
    id: any;
    name: string;
    date: any;
    buyIn: number;
    location?: string | null | undefined;
    sharesMax: number;
    bulletsMax: number;
    currencyRate: number;
    status: StatusGamesEnum;
    local_currency: {
      __typename?: 'Currencies';
      id: number;
      symbol: string;
      rate: number;
      countryId: number;
    };
    event?:
      | {
          __typename?: 'Events';
          id: any;
          name: string;
          image?: string | null | undefined;
          location: string;
        }
      | null
      | undefined;
  };
};

export type PlayerTicketsListQueryVariables = Exact<{
  userId: Scalars['String']['input'];
}>;

export type PlayerTicketsListQuery = {
  __typename?: 'query_root';
  tickets: Array<{
    __typename?: 'Tickets';
    id: any;
    status: TicketsStatusEnum;
    bullet: number;
    won?: number | null | undefined;
    wonLocal?: number | null | undefined;
    playerShareId?: any | null | undefined;
    playerName?: string | null | undefined;
    tournament: {
      __typename?: 'Tournaments';
      id: any;
      name: string;
      date: any;
      buyIn: number;
      location?: string | null | undefined;
      sharesMax: number;
      bulletsMax: number;
      currencyRate: number;
      status: StatusGamesEnum;
      local_currency: {
        __typename?: 'Currencies';
        id: number;
        symbol: string;
        rate: number;
        countryId: number;
      };
      event?:
        | {
            __typename?: 'Events';
            id: any;
            name: string;
            image?: string | null | undefined;
            location: string;
          }
        | null
        | undefined;
    };
  }>;
};

export type PlayerTicketLocalCurrencyPayoutFormDataQueryVariables = Exact<{
  countryId: Scalars['Int']['input'];
}>;

export type PlayerTicketLocalCurrencyPayoutFormDataQuery = {
  __typename?: 'query_root';
  me?:
    | {
        __typename?: 'UserOutput';
        id: string;
        email?: string | null | undefined;
        twofa?: boolean | null | undefined;
      }
    | null
    | undefined;
  paymentMethodBanks: Array<{
    __typename?: 'PaymentMethodBanks';
    id: number;
    name: string;
  }>;
};

export type BuyPlayerTicketMutationVariables = Exact<{
  tournamentId: Scalars['String']['input'];
  playerName: Scalars['String']['input'];
}>;

export type BuyPlayerTicketMutation = {
  __typename?: 'mutation_root';
  buyTicket?:
    | {
        __typename?: 'generalOutput';
        status?: GeneralStatus | null | undefined;
        message: string;
      }
    | null
    | undefined;
};

export type CancelPlayerTicketMutationVariables = Exact<{
  ticketId: Scalars['Int']['input'];
}>;

export type CancelPlayerTicketMutation = {
  __typename?: 'mutation_root';
  cancelTicket?:
    | {
        __typename?: 'generalOutput';
        status?: GeneralStatus | null | undefined;
        message: string;
      }
    | null
    | undefined;
};

export type PayoutPlayerTicketMutationVariables = Exact<{
  ticketId: Scalars['String']['input'];
  paymentMethodBankId: Scalars['Int']['input'];
  bankName: Scalars['String']['input'];
  accountHolder: Scalars['String']['input'];
  accountNumber: Scalars['String']['input'];
  twoFA?: InputMaybe<Scalars['String']['input']>;
}>;

export type PayoutPlayerTicketMutation = {
  __typename?: 'mutation_root';
  payoutTicket?:
    | {
        __typename?: 'StatusOutput';
        status: TransactionStatus;
        message?: string | null | undefined;
      }
    | null
    | undefined;
};

export type CommonTournamentDataFragment = {
  __typename?: 'Tournaments';
  id: any;
  buyIn: number;
};

export type TournamentPlayerShareCardsDataFragment = {
  __typename?: 'Tournaments';
  id: any;
  slug?: string | null | undefined;
  name: string;
  location?: string | null | undefined;
  date: any;
  buyIn: number;
  venue: { __typename?: 'Venues'; id: any; slug?: string | null | undefined };
  player_shares: Array<{
    __typename?: 'PlayerShares';
    id: any;
    status: StatusGamesEnum;
    markUp: number;
    bullets: number;
    shareDilution: boolean;
    sharesToSell: number;
    sort: number;
    player: {
      __typename?: 'Players';
      id: any;
      name: string;
      image?: string | null | undefined;
      slug?: string | null | undefined;
    };
    player_shares_bought?:
      | {
          __typename?: 'PlayerSharesBought';
          totalShareAmount?: number | null | undefined;
          totalSharePercentage?: number | null | undefined;
          totalTotalAmount?: number | null | undefined;
        }
      | null
      | undefined;
  }>;
};

export type PublicTournamentPageQueryVariables = Exact<{
  slug: Scalars['String']['input'];
}>;

export type PublicTournamentPageQuery = {
  __typename?: 'query_root';
  tournaments: Array<{
    __typename?: 'Tournaments';
    id: any;
    slug?: string | null | undefined;
    name: string;
    location?: string | null | undefined;
    date: any;
    buyIn: number;
    event?:
      | {
          __typename?: 'Events';
          description?: string | null | undefined;
          followersCount: number;
          id: any;
          name: string;
          slug?: string | null | undefined;
          location: string;
          image?: string | null | undefined;
          dateFrom: any;
          dateTill: any;
          venue: {
            __typename?: 'Venues';
            id: any;
            facebook?: string | null | undefined;
          };
        }
      | null
      | undefined;
    venue: { __typename?: 'Venues'; id: any; slug?: string | null | undefined };
    player_shares: Array<{
      __typename?: 'PlayerShares';
      id: any;
      status: StatusGamesEnum;
      markUp: number;
      bullets: number;
      shareDilution: boolean;
      sharesToSell: number;
      sort: number;
      player: {
        __typename?: 'Players';
        id: any;
        name: string;
        image?: string | null | undefined;
        slug?: string | null | undefined;
      };
      player_shares_bought?:
        | {
            __typename?: 'PlayerSharesBought';
            totalShareAmount?: number | null | undefined;
            totalSharePercentage?: number | null | undefined;
            totalTotalAmount?: number | null | undefined;
          }
        | null
        | undefined;
    }>;
  }>;
};

export type SellPlayerShareConfirmModalDataQueryVariables = Exact<{
  tournamentId: Scalars['uuid']['input'];
}>;

export type SellPlayerShareConfirmModalDataQuery = {
  __typename?: 'query_root';
  tournament?:
    | {
        __typename?: 'Tournaments';
        id: any;
        name: string;
        location?: string | null | undefined;
        date: any;
        event?:
          | {
              __typename?: 'Events';
              id: any;
              name: string;
              location: string;
              venue: { __typename?: 'Venues'; id: any; name: string };
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type TournamentsByNameQueryVariables = Exact<{
  name: Scalars['String']['input'];
}>;

export type TournamentsByNameQuery = {
  __typename?: 'query_root';
  tournaments: Array<{
    __typename?: 'Tournaments';
    id: any;
    name: string;
    date: any;
    slug?: string | null | undefined;
    event?:
      | {
          __typename?: 'Events';
          name: string;
          image?: string | null | undefined;
        }
      | null
      | undefined;
  }>;
};

export type UserVenueTournamentQueryVariables = Exact<{
  slug: Scalars['String']['input'];
}>;

export type UserVenueTournamentQuery = {
  __typename?: 'query_root';
  tournaments: Array<{
    __typename?: 'Tournaments';
    slug?: string | null | undefined;
    status: StatusGamesEnum;
    venueId: any;
    name: string;
    date: any;
    currencyRate: number;
    id: any;
    buyIn: number;
    balances: Array<{
      __typename?: 'TournamentBalances';
      id?: string | null | undefined;
      status?: string | null | undefined;
      balance?: number | null | undefined;
    }>;
    tournament_results: Array<{ __typename?: 'TournamentResults'; id: number }>;
    currency: {
      __typename?: 'Currencies';
      id: number;
      name: string;
      symbol: string;
    };
    local_currency: {
      __typename?: 'Currencies';
      name: string;
      symbol: string;
      decimals: number;
      rounding: number;
    };
    player_shares: Array<{
      __typename?: 'PlayerShares';
      id: any;
      bullets: number;
      shareDilution: boolean;
      status: StatusGamesEnum;
      player: { __typename?: 'Players'; id: any; name: string };
      player_shares_bought?:
        | {
            __typename?: 'PlayerSharesBought';
            totalSharePercentage?: number | null | undefined;
            totalShareAmount?: number | null | undefined;
          }
        | null
        | undefined;
      tournament_result?:
        | {
            __typename?: 'TournamentResults';
            bullets: number;
            toBePaid?: any | null | undefined;
            status: TournamentResultStatusesEnum;
            place?: number | null | undefined;
            winning?: number | null | undefined;
            playerPayout?: number | null | undefined;
            stakedAmount: number;
            stakedPercentage: number;
          }
        | null
        | undefined;
      tickets: Array<{
        __typename?: 'Tickets';
        id: any;
        playerName?: string | null | undefined;
        status: TicketsStatusEnum;
        amount: number;
        bullet: number;
        won?: number | null | undefined;
        wonLocal?: number | null | undefined;
        tournament_result?:
          | {
              __typename?: 'TournamentResults';
              bullets: number;
              toBePaid?: any | null | undefined;
              status: TournamentResultStatusesEnum;
              place?: number | null | undefined;
              winning?: number | null | undefined;
              playerPayout?: number | null | undefined;
              stakedAmount: number;
              stakedPercentage: number;
            }
          | null
          | undefined;
      }>;
    }>;
    tickets: Array<{
      __typename?: 'Tickets';
      id: any;
      playerName?: string | null | undefined;
      status: TicketsStatusEnum;
      amount: number;
      bullet: number;
      won?: number | null | undefined;
      wonLocal?: number | null | undefined;
      tournament_result?:
        | {
            __typename?: 'TournamentResults';
            bullets: number;
            toBePaid?: any | null | undefined;
            status: TournamentResultStatusesEnum;
            place?: number | null | undefined;
            winning?: number | null | undefined;
            playerPayout?: number | null | undefined;
            stakedAmount: number;
            stakedPercentage: number;
          }
        | null
        | undefined;
    }>;
  }>;
};

export type UserVenueTournamentPlayerShareQueryVariables = Exact<{
  id: Scalars['uuid']['input'];
}>;

export type UserVenueTournamentPlayerShareQuery = {
  __typename?: 'query_root';
  playerShare?:
    | {
        __typename?: 'PlayerShares';
        id: any;
        bullets: number;
        shareDilution: boolean;
        status: StatusGamesEnum;
        player: { __typename?: 'Players'; id: any; name: string };
        player_shares_bought?:
          | {
              __typename?: 'PlayerSharesBought';
              totalSharePercentage?: number | null | undefined;
              totalShareAmount?: number | null | undefined;
            }
          | null
          | undefined;
        tournament_result?:
          | {
              __typename?: 'TournamentResults';
              bullets: number;
              toBePaid?: any | null | undefined;
              status: TournamentResultStatusesEnum;
              place?: number | null | undefined;
              winning?: number | null | undefined;
              playerPayout?: number | null | undefined;
              stakedAmount: number;
              stakedPercentage: number;
            }
          | null
          | undefined;
        tickets: Array<{
          __typename?: 'Tickets';
          id: any;
          playerName?: string | null | undefined;
          status: TicketsStatusEnum;
          amount: number;
          bullet: number;
          won?: number | null | undefined;
          wonLocal?: number | null | undefined;
          tournament_result?:
            | {
                __typename?: 'TournamentResults';
                bullets: number;
                toBePaid?: any | null | undefined;
                status: TournamentResultStatusesEnum;
                place?: number | null | undefined;
                winning?: number | null | undefined;
                playerPayout?: number | null | undefined;
                stakedAmount: number;
                stakedPercentage: number;
              }
            | null
            | undefined;
        }>;
      }
    | null
    | undefined;
};

export type EditTournamentDataFragmentFragment = {
  __typename?: 'Tournaments';
  id: any;
  name: string;
  status: StatusGamesEnum;
  date: any;
  registrationEnd?: any | null | undefined;
  buyIn: number;
  type: string;
  guaranteed: number;
  watchUrl?: string | null | undefined;
  image?: string | null | undefined;
  bulletsMax: number;
  sharesMax: number;
  localCurrencyId: number;
  currencyRate: number;
};

export type CurrenciesListDataFragment = {
  __typename?: 'Currencies';
  id: number;
  name: string;
  symbol: string;
  rate: number;
};

export type EditTournamentDataQueryVariables = Exact<{
  id: Scalars['uuid']['input'];
}>;

export type EditTournamentDataQuery = {
  __typename?: 'query_root';
  tournament?:
    | {
        __typename?: 'Tournaments';
        id: any;
        name: string;
        status: StatusGamesEnum;
        date: any;
        registrationEnd?: any | null | undefined;
        buyIn: number;
        type: string;
        guaranteed: number;
        watchUrl?: string | null | undefined;
        image?: string | null | undefined;
        bulletsMax: number;
        sharesMax: number;
        localCurrencyId: number;
        currencyRate: number;
      }
    | null
    | undefined;
  currencies: Array<{
    __typename?: 'Currencies';
    id: number;
    name: string;
    symbol: string;
    rate: number;
  }>;
};

export type CurrenciesQueryVariables = Exact<{ [key: string]: never }>;

export type CurrenciesQuery = {
  __typename?: 'query_root';
  currencies: Array<{
    __typename?: 'Currencies';
    id: number;
    name: string;
    symbol: string;
    rate: number;
  }>;
};

export type CreateTournamentMutationVariables = Exact<{
  data: TournamentsInsertInput;
}>;

export type CreateTournamentMutation = {
  __typename?: 'mutation_root';
  insertTournament?:
    | {
        __typename?: 'Tournaments';
        id: any;
        name: string;
        status: StatusGamesEnum;
        date: any;
        registrationEnd?: any | null | undefined;
        buyIn: number;
        type: string;
        guaranteed: number;
        watchUrl?: string | null | undefined;
        image?: string | null | undefined;
        bulletsMax: number;
        sharesMax: number;
        localCurrencyId: number;
        currencyRate: number;
      }
    | null
    | undefined;
};

export type CancelTournamentMutationVariables = Exact<{
  tournamentId: Scalars['String']['input'];
}>;

export type CancelTournamentMutation = {
  __typename?: 'mutation_root';
  cancelTournament?:
    | {
        __typename?: 'generalOutput';
        status?: GeneralStatus | null | undefined;
        message: string;
      }
    | null
    | undefined;
};

export type PublishTournamentMutationVariables = Exact<{
  tournamentId: Scalars['uuid']['input'];
}>;

export type PublishTournamentMutation = {
  __typename?: 'mutation_root';
  updateTournament?:
    | {
        __typename?: 'Tournaments';
        id: any;
        name: string;
        status: StatusGamesEnum;
        date: any;
        registrationEnd?: any | null | undefined;
        buyIn: number;
        type: string;
        guaranteed: number;
        watchUrl?: string | null | undefined;
        image?: string | null | undefined;
        bulletsMax: number;
        sharesMax: number;
        localCurrencyId: number;
        currencyRate: number;
      }
    | null
    | undefined;
};

export type EditTournamentMutationVariables = Exact<{
  id: Scalars['uuid']['input'];
  data: TournamentsSetInput;
}>;

export type EditTournamentMutation = {
  __typename?: 'mutation_root';
  updateTournament?:
    | {
        __typename?: 'Tournaments';
        id: any;
        name: string;
        status: StatusGamesEnum;
        date: any;
        registrationEnd?: any | null | undefined;
        buyIn: number;
        type: string;
        guaranteed: number;
        watchUrl?: string | null | undefined;
        image?: string | null | undefined;
        bulletsMax: number;
        sharesMax: number;
        localCurrencyId: number;
        currencyRate: number;
      }
    | null
    | undefined;
};

export type StartTournamentMutationVariables = Exact<{
  tournamentId: Scalars['String']['input'];
}>;

export type StartTournamentMutation = {
  __typename?: 'mutation_root';
  startTournament?:
    | {
        __typename?: 'generalOutput';
        status?: GeneralStatus | null | undefined;
        message: string;
      }
    | null
    | undefined;
};

export type FinishTournamentMutationVariables = Exact<{
  tournamentId: Scalars['String']['input'];
}>;

export type FinishTournamentMutation = {
  __typename?: 'mutation_root';
  finishTournament?:
    | {
        __typename?: 'generalOutput';
        status?: GeneralStatus | null | undefined;
        message: string;
      }
    | null
    | undefined;
};

export type ModifyPlayerShareMutationVariables = Exact<{
  playerShareId: Scalars['String']['input'];
  action: PlayerShareAction;
}>;

export type ModifyPlayerShareMutation = {
  __typename?: 'mutation_root';
  playerShareResult?:
    | {
        __typename?: 'playerShareResultOutput';
        status?: GeneralStatus | null | undefined;
        message: string;
      }
    | null
    | undefined;
};

export type SetWinningPlayerMutationVariables = Exact<{
  playerShareId: Scalars['String']['input'];
  place: Scalars['Int']['input'];
  winAmount: Scalars['Float']['input'];
}>;

export type SetWinningPlayerMutation = {
  __typename?: 'mutation_root';
  playerShareResult?:
    | {
        __typename?: 'playerShareResultOutput';
        status?: GeneralStatus | null | undefined;
        message: string;
        playerPayout?: number | null | undefined;
        stake3Payout?: number | null | undefined;
        stake3PayoutUSD?: number | null | undefined;
      }
    | null
    | undefined;
};

export type InitialUserDataQueryVariables = Exact<{ [key: string]: never }>;

export type InitialUserDataQuery = {
  __typename?: 'query_root';
  me?:
    | {
        __typename?: 'UserOutput';
        id: string;
        provider: ProviderEnum;
        erc20Address?: string | null | undefined;
        status: UserStatus;
        language: string;
        email?: string | null | undefined;
        name?: string | null | undefined;
        playerId?: string | null | undefined;
        playerStatus?: PlayerStatus | null | undefined;
        playerSlug?: string | null | undefined;
        venueId?: string | null | undefined;
        twofa?: boolean | null | undefined;
      }
    | null
    | undefined;
  userBalances: Array<{
    __typename?: 'UserBalances';
    id?: string | null | undefined;
    balance?: number | null | undefined;
    status?: string | null | undefined;
    currencyId?: number | null | undefined;
  }>;
};

export type UserFollowingsQueryVariables = Exact<{ [key: string]: never }>;

export type UserFollowingsQuery = {
  __typename?: 'query_root';
  followers: Array<{
    __typename?: 'Followers';
    id: number;
    userId: string;
    venueId?: any | null | undefined;
    eventId?: any | null | undefined;
  }>;
};

export type UserIdQueryVariables = Exact<{ [key: string]: never }>;

export type UserIdQuery = {
  __typename?: 'query_root';
  me?: { __typename?: 'UserOutput'; id: string } | null | undefined;
};

export type UserErc20AddressQueryVariables = Exact<{ [key: string]: never }>;

export type UserErc20AddressQuery = {
  __typename?: 'query_root';
  me?:
    | {
        __typename?: 'UserOutput';
        id: string;
        erc20Address?: string | null | undefined;
      }
    | null
    | undefined;
};

export type UserStatusQueryVariables = Exact<{ [key: string]: never }>;

export type UserStatusQuery = {
  __typename?: 'query_root';
  me?:
    | { __typename?: 'UserOutput'; id: string; status: UserStatus }
    | null
    | undefined;
};

export type UserNameQueryVariables = Exact<{ [key: string]: never }>;

export type UserNameQuery = {
  __typename?: 'query_root';
  me?:
    | {
        __typename?: 'UserOutput';
        id: string;
        name?: string | null | undefined;
        playerName?: string | null | undefined;
      }
    | null
    | undefined;
};

export type UserBalanceQueryVariables = Exact<{ [key: string]: never }>;

export type UserBalanceQuery = {
  __typename?: 'query_root';
  userBalances: Array<{
    __typename?: 'UserBalances';
    id?: string | null | undefined;
    balance?: number | null | undefined;
    status?: string | null | undefined;
    currencyId?: number | null | undefined;
  }>;
};

export type UserSettingsModalDataQueryVariables = Exact<{
  [key: string]: never;
}>;

export type UserSettingsModalDataQuery = {
  __typename?: 'query_root';
  me?:
    | {
        __typename?: 'UserOutput';
        id: string;
        provider: ProviderEnum;
        erc20Address?: string | null | undefined;
        email?: string | null | undefined;
        twofa?: boolean | null | undefined;
      }
    | null
    | undefined;
};

export type UserSharesQueryVariables = Exact<{ [key: string]: never }>;

export type UserSharesQuery = {
  __typename?: 'query_root';
  userShareBuys: Array<{
    __typename?: 'UserShareBuys';
    id: number;
    sharePercentage: number;
    totalAmount: number;
    status: string;
    won?: number | null | undefined;
    player_share: {
      __typename?: 'PlayerShares';
      id: any;
      allowCancel: boolean;
      player: {
        __typename?: 'Players';
        id: any;
        name: string;
        image?: string | null | undefined;
      };
      tournament: {
        __typename?: 'Tournaments';
        id: any;
        date: any;
        name: string;
        location?: string | null | undefined;
        watchUrl?: string | null | undefined;
        event?:
          | {
              __typename?: 'Events';
              id: any;
              name: string;
              location: string;
              image?: string | null | undefined;
            }
          | null
          | undefined;
      };
    };
  }>;
};

export type UserLanguageQueryVariables = Exact<{ [key: string]: never }>;

export type UserLanguageQuery = {
  __typename?: 'query_root';
  me?:
    | { __typename?: 'UserOutput'; id: string; language: string }
    | null
    | undefined;
};

export type UserMenuDataQueryVariables = Exact<{ [key: string]: never }>;

export type UserMenuDataQuery = {
  __typename?: 'query_root';
  me?:
    | {
        __typename?: 'UserOutput';
        id: string;
        playerStatus?: PlayerStatus | null | undefined;
        playerSlug?: string | null | undefined;
        venueId?: string | null | undefined;
      }
    | null
    | undefined;
};

export type UserPlayerDataQueryVariables = Exact<{ [key: string]: never }>;

export type UserPlayerDataQuery = {
  __typename?: 'query_root';
  me?:
    | {
        __typename?: 'UserOutput';
        id: string;
        playerId?: string | null | undefined;
        playerStatus?: PlayerStatus | null | undefined;
      }
    | null
    | undefined;
};

export type UserVenueIdQueryVariables = Exact<{ [key: string]: never }>;

export type UserVenueIdQuery = {
  __typename?: 'query_root';
  me?:
    | {
        __typename?: 'UserOutput';
        id: string;
        venueId?: string | null | undefined;
      }
    | null
    | undefined;
};

export type UpdateUserLanguageMutationVariables = Exact<{
  lang: Scalars['String']['input'];
}>;

export type UpdateUserLanguageMutation = {
  __typename?: 'mutation_root';
  updateUsers?:
    | { __typename?: 'UsersMutationResponse'; affected_rows: number }
    | null
    | undefined;
};

export type LinkErc20toFireBaseSignatureMutationVariables = Exact<{
  address: Scalars['String']['input'];
}>;

export type LinkErc20toFireBaseSignatureMutation = {
  __typename?: 'mutation_root';
  linkERC20toFireBaseSignature?:
    | { __typename?: 'authSignatureOutput'; nonce: string }
    | null
    | undefined;
};

export type LinkErc20toFireBaseAcceptMutationVariables = Exact<{
  address: Scalars['String']['input'];
  signature: Scalars['String']['input'];
}>;

export type LinkErc20toFireBaseAcceptMutation = {
  __typename?: 'mutation_root';
  linkERC20toFireBaseAccept?:
    | {
        __typename?: 'generalOutput';
        status?: GeneralStatus | null | undefined;
        message: string;
      }
    | null
    | undefined;
};

export type SetEmailAddressMutationVariables = Exact<{
  email: Scalars['String']['input'];
}>;

export type SetEmailAddressMutation = {
  __typename?: 'mutation_root';
  setEmailaddress?:
    | {
        __typename?: 'generalOutput';
        status?: GeneralStatus | null | undefined;
        message: string;
      }
    | null
    | undefined;
};

export type GetEnable2FaDataMutationVariables = Exact<{ [key: string]: never }>;

export type GetEnable2FaDataMutation = {
  __typename?: 'mutation_root';
  activate2FA1?:
    | {
        __typename?: 'activate2FAOutput';
        status: GeneralStatus;
        message: string;
        codeUrl: string;
        codeImage: string;
        backupCode: string;
      }
    | null
    | undefined;
};

export type Enable2FaMutationVariables = Exact<{
  code: Scalars['String']['input'];
}>;

export type Enable2FaMutation = {
  __typename?: 'mutation_root';
  activate2FA2?:
    | {
        __typename?: 'generalOutput';
        status?: GeneralStatus | null | undefined;
        message: string;
      }
    | null
    | undefined;
};

export type Disable2FaMutationVariables = Exact<{
  code: Scalars['String']['input'];
}>;

export type Disable2FaMutation = {
  __typename?: 'mutation_root';
  deactivate2FA?:
    | {
        __typename?: 'generalOutput';
        status?: GeneralStatus | null | undefined;
        message: string;
      }
    | null
    | undefined;
};

export type CancelUserShareMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;

export type CancelUserShareMutation = {
  __typename?: 'mutation_root';
  submitShareCancel?:
    | {
        __typename?: 'generalOutput';
        status?: GeneralStatus | null | undefined;
        message: string;
      }
    | null
    | undefined;
};

export type VerifyEmailMutationVariables = Exact<{ [key: string]: never }>;

export type VerifyEmailMutation = {
  __typename?: 'mutation_root';
  sendVerifyMail?:
    | {
        __typename?: 'generalOutput';
        message: string;
        status?: GeneralStatus | null | undefined;
      }
    | null
    | undefined;
};

export type PublicVenuePageDataFragment = {
  __typename?: 'Venues';
  id: any;
  name: string;
  image?: string | null | undefined;
  country: string;
  city: string;
  address: string;
  description?: string | null | undefined;
  facebook?: string | null | undefined;
  followersCount: number;
};

export type PublicVenuePageQueryVariables = Exact<{
  slug: Scalars['String']['input'];
}>;

export type PublicVenuePageQuery = {
  __typename?: 'query_root';
  venues: Array<{
    __typename?: 'Venues';
    id: any;
    name: string;
    image?: string | null | undefined;
    country: string;
    city: string;
    address: string;
    description?: string | null | undefined;
    facebook?: string | null | undefined;
    followersCount: number;
    events: Array<{
      __typename?: 'Events';
      id: any;
      name: string;
      slug?: string | null | undefined;
      location: string;
      image?: string | null | undefined;
      dateFrom: any;
      dateTill: any;
      tournaments: Array<{
        __typename?: 'Tournaments';
        id: any;
        slug?: string | null | undefined;
        name: string;
        location?: string | null | undefined;
        date: any;
        buyIn: number;
        venue: {
          __typename?: 'Venues';
          id: any;
          slug?: string | null | undefined;
        };
        player_shares: Array<{
          __typename?: 'PlayerShares';
          id: any;
          status: StatusGamesEnum;
          markUp: number;
          bullets: number;
          shareDilution: boolean;
          sharesToSell: number;
          sort: number;
          player: {
            __typename?: 'Players';
            id: any;
            name: string;
            image?: string | null | undefined;
            slug?: string | null | undefined;
          };
          player_shares_bought?:
            | {
                __typename?: 'PlayerSharesBought';
                totalShareAmount?: number | null | undefined;
                totalSharePercentage?: number | null | undefined;
                totalTotalAmount?: number | null | undefined;
              }
            | null
            | undefined;
        }>;
      }>;
    }>;
  }>;
};

export type VenueQueryVariables = Exact<{
  id: Scalars['uuid']['input'];
}>;

export type VenueQuery = {
  __typename?: 'query_root';
  venue?:
    | {
        __typename?: 'Venues';
        id: any;
        name: string;
        address: string;
        website?: string | null | undefined;
      }
    | null
    | undefined;
  events: Array<{
    __typename?: 'Events';
    status: StatusesEnum;
    slug?: string | null | undefined;
    id: any;
    name: string;
    location: string;
    image?: string | null | undefined;
    dateFrom: any;
    dateTill: any;
    tournaments: Array<{ __typename?: 'Tournaments'; id: any; buyIn: number }>;
  }>;
};

export type FollowVenueMutationVariables = Exact<{
  venueId: Scalars['uuid']['input'];
}>;

export type FollowVenueMutation = {
  __typename?: 'mutation_root';
  insertFollower?: { __typename?: 'Followers'; id: number } | null | undefined;
};

export type UnFollowVenueMutationVariables = Exact<{
  venueId: Scalars['uuid']['input'];
}>;

export type UnFollowVenueMutation = {
  __typename?: 'mutation_root';
  deleteFollowers?:
    | { __typename?: 'FollowersMutationResponse'; affected_rows: number }
    | null
    | undefined;
};

export const DepositCurrenciesDataFragmentDoc = gql`
  fragment depositCurrenciesData on BlockchainCurrencies {
    id
    symbol
    icon
    contract
    decimals
    deposit
    withdraw
    withdrawFee
  }
`;
export const CommonEventDataFragmentDoc = gql`
  fragment commonEventData on Events {
    id
    name
    slug
    location
    image
    dateFrom
    dateTill
  }
`;
export const EventListItemDataFragmentDoc = gql`
  fragment eventListItemData on Events {
    ...commonEventData
    status
    slug
  }
  ${CommonEventDataFragmentDoc}
`;
export const PublicEventPageDataFragmentDoc = gql`
  fragment publicEventPageData on Events {
    ...commonEventData
    description
    followersCount
    venue {
      id
      facebook
    }
  }
  ${CommonEventDataFragmentDoc}
`;
export const EditEventDataFragmentFragmentDoc = gql`
  fragment editEventDataFragment on Events {
    id
    name
    status
    dateFrom
    dateTill
    location
    description
    image
  }
`;
export const UserVenueTournamentResultDataFragmentDoc = gql`
  fragment userVenueTournamentResultData on TournamentResults {
    bullets
    toBePaid
    status
    place
    winning
    playerPayout
    stakedAmount
    stakedPercentage
  }
`;
export const UserVenueTournamentTicketDataFragmentDoc = gql`
  fragment userVenueTournamentTicketData on Tickets {
    id
    playerName
    status
    amount
    bullet
    won
    wonLocal
    tournament_result {
      ...userVenueTournamentResultData
    }
  }
  ${UserVenueTournamentResultDataFragmentDoc}
`;
export const UserVenueTournamentPlayerShareDataFragmentDoc = gql`
  fragment userVenueTournamentPlayerShareData on PlayerShares {
    id
    bullets
    shareDilution
    status
    player {
      id
      name
    }
    player_shares_bought {
      totalSharePercentage
      totalShareAmount
    }
    tournament_result {
      ...userVenueTournamentResultData
    }
    tickets(where: { status: { _neq: CANCELLED } }) {
      ...userVenueTournamentTicketData
    }
  }
  ${UserVenueTournamentResultDataFragmentDoc}
  ${UserVenueTournamentTicketDataFragmentDoc}
`;
export const ExtendedPlayerDataFragmentDoc = gql`
  fragment extendedPlayerData on Players {
    id
    name
    realName
    image
    followersCount
    facebook
    twitter
    verified
    allTimeList
    totalLiveEarning
    description
    globalpokerindexUrl
    thehendonmobUrl
    actionsSold
    totalWinnings
    highestWinning
    highestRoi
    gpiRanking
    allTimeListCountry
    allTimeListCountryPlace
  }
`;
export const PlayerPlayerShareDataFragmentDoc = gql`
  fragment playerPlayerShareData on PlayerShares {
    id
    note
    markUp
    sharesToSell
    bullets
    shareDilution
    status
    player_shares_bought {
      totalShareAmount
      totalSharePercentage
      totalTotalAmount
    }
    tournament {
      id
      name
      slug
      location
      buyIn
      date
      event {
        id
        name
        slug
        location
        image
        venue {
          id
          name
        }
      }
    }
  }
`;
export const PublicPlayerDataFragmentDoc = gql`
  fragment publicPlayerData on Players {
    ...extendedPlayerData
    followers {
      id
      userId
    }
    player_shares(
      where: { status: { _in: [STARTED, ACTIVE] } }
      orderBy: { tournament: { date: ASC } }
    ) {
      ...playerPlayerShareData
    }
  }
  ${ExtendedPlayerDataFragmentDoc}
  ${PlayerPlayerShareDataFragmentDoc}
`;
export const SelfPlayerDataFragmentDoc = gql`
  fragment selfPlayerData on Players {
    ...extendedPlayerData
    player_followers_view {
      id
      erc20Address
      avatar
      username
      email
    }
  }
  ${ExtendedPlayerDataFragmentDoc}
`;
export const PlayerTicketListItemDataFragmentDoc = gql`
  fragment playerTicketListItemData on Tickets {
    id
    status
    bullet
    won
    wonLocal
    playerShareId
    playerName
    tournament {
      id
      name
      date
      buyIn
      location
      sharesMax
      bulletsMax
      currencyRate
      status
      local_currency {
        id
        symbol
        rate
        countryId
      }
      event {
        id
        name
        image
        location
      }
    }
  }
`;
export const CommonTournamentDataFragmentDoc = gql`
  fragment commonTournamentData on Tournaments {
    id
    buyIn
  }
`;
export const PlayerShareCardDataFragmentDoc = gql`
  fragment playerShareCardData on PlayerShares {
    id
    player {
      id
      name
      image
      slug
    }
    status
    markUp
    bullets
    shareDilution
    sharesToSell
    player_shares_bought {
      totalShareAmount
      totalSharePercentage
      totalTotalAmount
    }
    sort
  }
`;
export const TournamentPlayerShareCardsDataFragmentDoc = gql`
  fragment tournamentPlayerShareCardsData on Tournaments {
    id
    slug
    name
    location
    date
    buyIn
    venue {
      id
      slug
    }
    player_shares(
      orderBy: { sort: ASC }
      where: { status: { _in: [STARTED, ACTIVE] } }
    ) {
      ...playerShareCardData
    }
  }
  ${PlayerShareCardDataFragmentDoc}
`;
export const EditTournamentDataFragmentFragmentDoc = gql`
  fragment editTournamentDataFragment on Tournaments {
    id
    name
    status
    date
    registrationEnd
    buyIn
    type
    guaranteed
    watchUrl
    image
    bulletsMax
    sharesMax
    localCurrencyId
    currencyRate
  }
`;
export const CurrenciesListDataFragmentDoc = gql`
  fragment currenciesListData on Currencies {
    id
    name
    symbol
    rate
  }
`;
export const PublicVenuePageDataFragmentDoc = gql`
  fragment publicVenuePageData on Venues {
    id
    name
    image
    country
    city
    address
    description
    facebook
    followersCount
  }
`;
export const NonceDocument = gql`
  mutation nonce($address: String!) {
    authSignature(address: $address) {
      nonce
    }
  }
`;
export type NonceMutationFn = ApolloReactCommon.MutationFunction<
  NonceMutation,
  NonceMutationVariables
>;

/**
 * __useNonceMutation__
 *
 * To run a mutation, you first call `useNonceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNonceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [nonceMutation, { data, loading, error }] = useNonceMutation({
 *   variables: {
 *      address: // value for 'address'
 *   },
 * });
 */
export function useNonceMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    NonceMutation,
    NonceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<NonceMutation, NonceMutationVariables>(
    NonceDocument,
    options
  );
}
export type NonceMutationHookResult = ReturnType<typeof useNonceMutation>;
export type NonceMutationResult =
  ApolloReactCommon.MutationResult<NonceMutation>;
export type NonceMutationOptions = ApolloReactCommon.BaseMutationOptions<
  NonceMutation,
  NonceMutationVariables
>;
export const AccessTokenDocument = gql`
  mutation accessToken($address: String, $signature: String) {
    authLogin(address: $address, signature: $signature) {
      accesstoken
      userId
    }
  }
`;
export type AccessTokenMutationFn = ApolloReactCommon.MutationFunction<
  AccessTokenMutation,
  AccessTokenMutationVariables
>;

/**
 * __useAccessTokenMutation__
 *
 * To run a mutation, you first call `useAccessTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAccessTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [accessTokenMutation, { data, loading, error }] = useAccessTokenMutation({
 *   variables: {
 *      address: // value for 'address'
 *      signature: // value for 'signature'
 *   },
 * });
 */
export function useAccessTokenMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AccessTokenMutation,
    AccessTokenMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    AccessTokenMutation,
    AccessTokenMutationVariables
  >(AccessTokenDocument, options);
}
export type AccessTokenMutationHookResult = ReturnType<
  typeof useAccessTokenMutation
>;
export type AccessTokenMutationResult =
  ApolloReactCommon.MutationResult<AccessTokenMutation>;
export type AccessTokenMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AccessTokenMutation,
  AccessTokenMutationVariables
>;
export const RefreshAccessTokenDocument = gql`
  mutation refreshAccessToken($accessToken: String) {
    authRefresh(accesstoken: $accessToken) {
      userId
      accesstoken
    }
  }
`;
export type RefreshAccessTokenMutationFn = ApolloReactCommon.MutationFunction<
  RefreshAccessTokenMutation,
  RefreshAccessTokenMutationVariables
>;

/**
 * __useRefreshAccessTokenMutation__
 *
 * To run a mutation, you first call `useRefreshAccessTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshAccessTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshAccessTokenMutation, { data, loading, error }] = useRefreshAccessTokenMutation({
 *   variables: {
 *      accessToken: // value for 'accessToken'
 *   },
 * });
 */
export function useRefreshAccessTokenMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RefreshAccessTokenMutation,
    RefreshAccessTokenMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    RefreshAccessTokenMutation,
    RefreshAccessTokenMutationVariables
  >(RefreshAccessTokenDocument, options);
}
export type RefreshAccessTokenMutationHookResult = ReturnType<
  typeof useRefreshAccessTokenMutation
>;
export type RefreshAccessTokenMutationResult =
  ApolloReactCommon.MutationResult<RefreshAccessTokenMutation>;
export type RefreshAccessTokenMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    RefreshAccessTokenMutation,
    RefreshAccessTokenMutationVariables
  >;
export const BlockchainsDocument = gql`
  query blockchains($supportedBlockchainIds: [Int!]!) {
    blockchains(where: { chainId: { _in: $supportedBlockchainIds } }) {
      id
      chainId
      name
      iconUrl
      blockchain_currencies(
        where: {
          _or: [{ deposit: { _eq: true } }, { withdraw: { _eq: true } }]
        }
      ) {
        ...depositCurrenciesData
      }
    }
  }
  ${DepositCurrenciesDataFragmentDoc}
`;

/**
 * __useBlockchainsQuery__
 *
 * To run a query within a React component, call `useBlockchainsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBlockchainsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBlockchainsQuery({
 *   variables: {
 *      supportedBlockchainIds: // value for 'supportedBlockchainIds'
 *   },
 * });
 */
export function useBlockchainsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    BlockchainsQuery,
    BlockchainsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<BlockchainsQuery, BlockchainsQueryVariables>(
    BlockchainsDocument,
    options
  );
}
export function useBlockchainsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    BlockchainsQuery,
    BlockchainsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    BlockchainsQuery,
    BlockchainsQueryVariables
  >(BlockchainsDocument, options);
}
export type BlockchainsQueryHookResult = ReturnType<typeof useBlockchainsQuery>;
export type BlockchainsLazyQueryHookResult = ReturnType<
  typeof useBlockchainsLazyQuery
>;
export type BlockchainsQueryResult = ApolloReactCommon.QueryResult<
  BlockchainsQuery,
  BlockchainsQueryVariables
>;
export function refetchBlockchainsQuery(variables: BlockchainsQueryVariables) {
  return { query: BlockchainsDocument, variables: variables };
}
export const PaymentsDocument = gql`
  query payments($offset: Int!, $limit: Int!) {
    payments(orderBy: { createdAt: DESC }, offset: $offset, limit: $limit) {
      id
      createdAt
      type
      status
      txid
      amount
      blockchain_currency {
        id
        blockchain {
          id
          scanUrl
        }
      }
    }
    paymentsAggregate {
      aggregate {
        totalCount: count
      }
    }
  }
`;

/**
 * __usePaymentsQuery__
 *
 * To run a query within a React component, call `usePaymentsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentsQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function usePaymentsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    PaymentsQuery,
    PaymentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<PaymentsQuery, PaymentsQueryVariables>(
    PaymentsDocument,
    options
  );
}
export function usePaymentsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    PaymentsQuery,
    PaymentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<PaymentsQuery, PaymentsQueryVariables>(
    PaymentsDocument,
    options
  );
}
export type PaymentsQueryHookResult = ReturnType<typeof usePaymentsQuery>;
export type PaymentsLazyQueryHookResult = ReturnType<
  typeof usePaymentsLazyQuery
>;
export type PaymentsQueryResult = ApolloReactCommon.QueryResult<
  PaymentsQuery,
  PaymentsQueryVariables
>;
export function refetchPaymentsQuery(variables: PaymentsQueryVariables) {
  return { query: PaymentsDocument, variables: variables };
}
export const DepositBanksDocument = gql`
  query depositBanks {
    paymentMethodBanks(where: { deposit: { _eq: true } }) {
      id
      name
      country
      depositDetails
      depositCurrency
      depositMin
      depositMax
      depositFee
      depositExchangeRate
    }
  }
`;

/**
 * __useDepositBanksQuery__
 *
 * To run a query within a React component, call `useDepositBanksQuery` and pass it any options that fit your needs.
 * When your component renders, `useDepositBanksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDepositBanksQuery({
 *   variables: {
 *   },
 * });
 */
export function useDepositBanksQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    DepositBanksQuery,
    DepositBanksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    DepositBanksQuery,
    DepositBanksQueryVariables
  >(DepositBanksDocument, options);
}
export function useDepositBanksLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    DepositBanksQuery,
    DepositBanksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    DepositBanksQuery,
    DepositBanksQueryVariables
  >(DepositBanksDocument, options);
}
export type DepositBanksQueryHookResult = ReturnType<
  typeof useDepositBanksQuery
>;
export type DepositBanksLazyQueryHookResult = ReturnType<
  typeof useDepositBanksLazyQuery
>;
export type DepositBanksQueryResult = ApolloReactCommon.QueryResult<
  DepositBanksQuery,
  DepositBanksQueryVariables
>;
export function refetchDepositBanksQuery(
  variables?: DepositBanksQueryVariables
) {
  return { query: DepositBanksDocument, variables: variables };
}
export const BankWithdrawalFormDataDocument = gql`
  query bankWithdrawalFormData {
    me {
      id
      email
      twofa
    }
    paymentMethodBanks(where: { withdraw: { _eq: true } }) {
      id
      name
      country
      countryId
      withdrawMin
      withdrawMax
      withdrawFee
      withdrawExchangeRate
      depositCurrency
    }
  }
`;

/**
 * __useBankWithdrawalFormDataQuery__
 *
 * To run a query within a React component, call `useBankWithdrawalFormDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useBankWithdrawalFormDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBankWithdrawalFormDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useBankWithdrawalFormDataQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    BankWithdrawalFormDataQuery,
    BankWithdrawalFormDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    BankWithdrawalFormDataQuery,
    BankWithdrawalFormDataQueryVariables
  >(BankWithdrawalFormDataDocument, options);
}
export function useBankWithdrawalFormDataLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    BankWithdrawalFormDataQuery,
    BankWithdrawalFormDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    BankWithdrawalFormDataQuery,
    BankWithdrawalFormDataQueryVariables
  >(BankWithdrawalFormDataDocument, options);
}
export type BankWithdrawalFormDataQueryHookResult = ReturnType<
  typeof useBankWithdrawalFormDataQuery
>;
export type BankWithdrawalFormDataLazyQueryHookResult = ReturnType<
  typeof useBankWithdrawalFormDataLazyQuery
>;
export type BankWithdrawalFormDataQueryResult = ApolloReactCommon.QueryResult<
  BankWithdrawalFormDataQuery,
  BankWithdrawalFormDataQueryVariables
>;
export function refetchBankWithdrawalFormDataQuery(
  variables?: BankWithdrawalFormDataQueryVariables
) {
  return { query: BankWithdrawalFormDataDocument, variables: variables };
}
export const BlockchainWithdrawalFormDataDocument = gql`
  query blockchainWithdrawalFormData {
    me {
      id
      email
      erc20Address
      twofa
    }
  }
`;

/**
 * __useBlockchainWithdrawalFormDataQuery__
 *
 * To run a query within a React component, call `useBlockchainWithdrawalFormDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useBlockchainWithdrawalFormDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBlockchainWithdrawalFormDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useBlockchainWithdrawalFormDataQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    BlockchainWithdrawalFormDataQuery,
    BlockchainWithdrawalFormDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    BlockchainWithdrawalFormDataQuery,
    BlockchainWithdrawalFormDataQueryVariables
  >(BlockchainWithdrawalFormDataDocument, options);
}
export function useBlockchainWithdrawalFormDataLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    BlockchainWithdrawalFormDataQuery,
    BlockchainWithdrawalFormDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    BlockchainWithdrawalFormDataQuery,
    BlockchainWithdrawalFormDataQueryVariables
  >(BlockchainWithdrawalFormDataDocument, options);
}
export type BlockchainWithdrawalFormDataQueryHookResult = ReturnType<
  typeof useBlockchainWithdrawalFormDataQuery
>;
export type BlockchainWithdrawalFormDataLazyQueryHookResult = ReturnType<
  typeof useBlockchainWithdrawalFormDataLazyQuery
>;
export type BlockchainWithdrawalFormDataQueryResult =
  ApolloReactCommon.QueryResult<
    BlockchainWithdrawalFormDataQuery,
    BlockchainWithdrawalFormDataQueryVariables
  >;
export function refetchBlockchainWithdrawalFormDataQuery(
  variables?: BlockchainWithdrawalFormDataQueryVariables
) {
  return { query: BlockchainWithdrawalFormDataDocument, variables: variables };
}
export const DepositBankInfoDocument = gql`
  query depositBankInfo($bankId: Int!, $amount: Float!) {
    getDepositBankInfo(paymentMethodBankId: $bankId, amount: $amount) {
      id
      depositDetails
      depositReference
    }
  }
`;

/**
 * __useDepositBankInfoQuery__
 *
 * To run a query within a React component, call `useDepositBankInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useDepositBankInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDepositBankInfoQuery({
 *   variables: {
 *      bankId: // value for 'bankId'
 *      amount: // value for 'amount'
 *   },
 * });
 */
export function useDepositBankInfoQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    DepositBankInfoQuery,
    DepositBankInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    DepositBankInfoQuery,
    DepositBankInfoQueryVariables
  >(DepositBankInfoDocument, options);
}
export function useDepositBankInfoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    DepositBankInfoQuery,
    DepositBankInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    DepositBankInfoQuery,
    DepositBankInfoQueryVariables
  >(DepositBankInfoDocument, options);
}
export type DepositBankInfoQueryHookResult = ReturnType<
  typeof useDepositBankInfoQuery
>;
export type DepositBankInfoLazyQueryHookResult = ReturnType<
  typeof useDepositBankInfoLazyQuery
>;
export type DepositBankInfoQueryResult = ApolloReactCommon.QueryResult<
  DepositBankInfoQuery,
  DepositBankInfoQueryVariables
>;
export function refetchDepositBankInfoQuery(
  variables: DepositBankInfoQueryVariables
) {
  return { query: DepositBankInfoDocument, variables: variables };
}
export const BlockchainDepositDocument = gql`
  mutation blockchainDeposit(
    $address: String!
    $blockchain: Int!
    $currency: Int!
    $amount: Float!
  ) {
    submitDeposit(
      address: $address
      blockchainId: $blockchain
      blockchainCurrencyId: $currency
      amount: $amount
    ) {
      id
      status
      depositAddress
      message
    }
  }
`;
export type BlockchainDepositMutationFn = ApolloReactCommon.MutationFunction<
  BlockchainDepositMutation,
  BlockchainDepositMutationVariables
>;

/**
 * __useBlockchainDepositMutation__
 *
 * To run a mutation, you first call `useBlockchainDepositMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBlockchainDepositMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [blockchainDepositMutation, { data, loading, error }] = useBlockchainDepositMutation({
 *   variables: {
 *      address: // value for 'address'
 *      blockchain: // value for 'blockchain'
 *      currency: // value for 'currency'
 *      amount: // value for 'amount'
 *   },
 * });
 */
export function useBlockchainDepositMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    BlockchainDepositMutation,
    BlockchainDepositMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    BlockchainDepositMutation,
    BlockchainDepositMutationVariables
  >(BlockchainDepositDocument, options);
}
export type BlockchainDepositMutationHookResult = ReturnType<
  typeof useBlockchainDepositMutation
>;
export type BlockchainDepositMutationResult =
  ApolloReactCommon.MutationResult<BlockchainDepositMutation>;
export type BlockchainDepositMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    BlockchainDepositMutation,
    BlockchainDepositMutationVariables
  >;
export const BlockchainSubmittedDepositDocument = gql`
  mutation blockchainSubmittedDeposit($id: String!, $tx: String!) {
    submittedDeposit(id: $id, tx: $tx) {
      id
      status
    }
  }
`;
export type BlockchainSubmittedDepositMutationFn =
  ApolloReactCommon.MutationFunction<
    BlockchainSubmittedDepositMutation,
    BlockchainSubmittedDepositMutationVariables
  >;

/**
 * __useBlockchainSubmittedDepositMutation__
 *
 * To run a mutation, you first call `useBlockchainSubmittedDepositMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBlockchainSubmittedDepositMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [blockchainSubmittedDepositMutation, { data, loading, error }] = useBlockchainSubmittedDepositMutation({
 *   variables: {
 *      id: // value for 'id'
 *      tx: // value for 'tx'
 *   },
 * });
 */
export function useBlockchainSubmittedDepositMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    BlockchainSubmittedDepositMutation,
    BlockchainSubmittedDepositMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    BlockchainSubmittedDepositMutation,
    BlockchainSubmittedDepositMutationVariables
  >(BlockchainSubmittedDepositDocument, options);
}
export type BlockchainSubmittedDepositMutationHookResult = ReturnType<
  typeof useBlockchainSubmittedDepositMutation
>;
export type BlockchainSubmittedDepositMutationResult =
  ApolloReactCommon.MutationResult<BlockchainSubmittedDepositMutation>;
export type BlockchainSubmittedDepositMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    BlockchainSubmittedDepositMutation,
    BlockchainSubmittedDepositMutationVariables
  >;
export const SubmitBankDepositDocument = gql`
  mutation submitBankDeposit($id: String!) {
    submitBankDeposit(id: $id) {
      status
      message
    }
  }
`;
export type SubmitBankDepositMutationFn = ApolloReactCommon.MutationFunction<
  SubmitBankDepositMutation,
  SubmitBankDepositMutationVariables
>;

/**
 * __useSubmitBankDepositMutation__
 *
 * To run a mutation, you first call `useSubmitBankDepositMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitBankDepositMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitBankDepositMutation, { data, loading, error }] = useSubmitBankDepositMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSubmitBankDepositMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SubmitBankDepositMutation,
    SubmitBankDepositMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    SubmitBankDepositMutation,
    SubmitBankDepositMutationVariables
  >(SubmitBankDepositDocument, options);
}
export type SubmitBankDepositMutationHookResult = ReturnType<
  typeof useSubmitBankDepositMutation
>;
export type SubmitBankDepositMutationResult =
  ApolloReactCommon.MutationResult<SubmitBankDepositMutation>;
export type SubmitBankDepositMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    SubmitBankDepositMutation,
    SubmitBankDepositMutationVariables
  >;
export const BlockchainSubmitWithdrawDocument = gql`
  mutation blockchainSubmitWithdraw(
    $address: String!
    $blockchain: Int!
    $currency: Int!
    $amount: Float!
    $twoFA: String
  ) {
    submitWithdraw(
      address: $address
      blockchainId: $blockchain
      blockchainCurrencyId: $currency
      amount: $amount
      twoFA: $twoFA
    ) {
      id
      status
    }
  }
`;
export type BlockchainSubmitWithdrawMutationFn =
  ApolloReactCommon.MutationFunction<
    BlockchainSubmitWithdrawMutation,
    BlockchainSubmitWithdrawMutationVariables
  >;

/**
 * __useBlockchainSubmitWithdrawMutation__
 *
 * To run a mutation, you first call `useBlockchainSubmitWithdrawMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBlockchainSubmitWithdrawMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [blockchainSubmitWithdrawMutation, { data, loading, error }] = useBlockchainSubmitWithdrawMutation({
 *   variables: {
 *      address: // value for 'address'
 *      blockchain: // value for 'blockchain'
 *      currency: // value for 'currency'
 *      amount: // value for 'amount'
 *      twoFA: // value for 'twoFA'
 *   },
 * });
 */
export function useBlockchainSubmitWithdrawMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    BlockchainSubmitWithdrawMutation,
    BlockchainSubmitWithdrawMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    BlockchainSubmitWithdrawMutation,
    BlockchainSubmitWithdrawMutationVariables
  >(BlockchainSubmitWithdrawDocument, options);
}
export type BlockchainSubmitWithdrawMutationHookResult = ReturnType<
  typeof useBlockchainSubmitWithdrawMutation
>;
export type BlockchainSubmitWithdrawMutationResult =
  ApolloReactCommon.MutationResult<BlockchainSubmitWithdrawMutation>;
export type BlockchainSubmitWithdrawMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    BlockchainSubmitWithdrawMutation,
    BlockchainSubmitWithdrawMutationVariables
  >;
export const SubmitBankWithdrawDocument = gql`
  mutation submitBankWithdraw(
    $bank: Int!
    $bankName: String
    $accountHolder: String!
    $accountNumber: String!
    $amount: Float!
    $twoFA: String
  ) {
    submitBankWithdraw(
      amount: $amount
      bankName: $bankName
      accountHolder: $accountHolder
      accountNumber: $accountNumber
      paymentMethodBankId: $bank
      twoFA: $twoFA
    ) {
      status
      message
    }
  }
`;
export type SubmitBankWithdrawMutationFn = ApolloReactCommon.MutationFunction<
  SubmitBankWithdrawMutation,
  SubmitBankWithdrawMutationVariables
>;

/**
 * __useSubmitBankWithdrawMutation__
 *
 * To run a mutation, you first call `useSubmitBankWithdrawMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitBankWithdrawMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitBankWithdrawMutation, { data, loading, error }] = useSubmitBankWithdrawMutation({
 *   variables: {
 *      bank: // value for 'bank'
 *      bankName: // value for 'bankName'
 *      accountHolder: // value for 'accountHolder'
 *      accountNumber: // value for 'accountNumber'
 *      amount: // value for 'amount'
 *      twoFA: // value for 'twoFA'
 *   },
 * });
 */
export function useSubmitBankWithdrawMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SubmitBankWithdrawMutation,
    SubmitBankWithdrawMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    SubmitBankWithdrawMutation,
    SubmitBankWithdrawMutationVariables
  >(SubmitBankWithdrawDocument, options);
}
export type SubmitBankWithdrawMutationHookResult = ReturnType<
  typeof useSubmitBankWithdrawMutation
>;
export type SubmitBankWithdrawMutationResult =
  ApolloReactCommon.MutationResult<SubmitBankWithdrawMutation>;
export type SubmitBankWithdrawMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    SubmitBankWithdrawMutation,
    SubmitBankWithdrawMutationVariables
  >;
export const HomePageEventsDocument = gql`
  query homePageEvents {
    events(
      orderBy: { dateFrom: ASC, sort: ASC }
      where: {
        dateTill: { _gte: "now()" }
        tournaments: { player_shares: { status: { _in: [STARTED, ACTIVE] } } }
      }
    ) {
      ...commonEventData
      tournaments(
        orderBy: { sort: ASC, date: ASC }
        where: { status: { _in: [STARTED, ACTIVE] } }
      ) {
        ...tournamentPlayerShareCardsData
      }
    }
  }
  ${CommonEventDataFragmentDoc}
  ${TournamentPlayerShareCardsDataFragmentDoc}
`;

/**
 * __useHomePageEventsQuery__
 *
 * To run a query within a React component, call `useHomePageEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomePageEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomePageEventsQuery({
 *   variables: {
 *   },
 * });
 */
export function useHomePageEventsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    HomePageEventsQuery,
    HomePageEventsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    HomePageEventsQuery,
    HomePageEventsQueryVariables
  >(HomePageEventsDocument, options);
}
export function useHomePageEventsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    HomePageEventsQuery,
    HomePageEventsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    HomePageEventsQuery,
    HomePageEventsQueryVariables
  >(HomePageEventsDocument, options);
}
export type HomePageEventsQueryHookResult = ReturnType<
  typeof useHomePageEventsQuery
>;
export type HomePageEventsLazyQueryHookResult = ReturnType<
  typeof useHomePageEventsLazyQuery
>;
export type HomePageEventsQueryResult = ApolloReactCommon.QueryResult<
  HomePageEventsQuery,
  HomePageEventsQueryVariables
>;
export function refetchHomePageEventsQuery(
  variables?: HomePageEventsQueryVariables
) {
  return { query: HomePageEventsDocument, variables: variables };
}
export const PublicEventPageDocument = gql`
  query publicEventPage($slug: String!) {
    events(
      orderBy: { dateFrom: ASC, sort: ASC }
      where: {
        slug: { _eq: $slug }
        dateTill: { _gte: "now()" }
        tournaments: { player_shares: { status: { _in: [STARTED, ACTIVE] } } }
      }
    ) {
      ...publicEventPageData
      tournaments(
        orderBy: { sort: ASC, date: ASC }
        where: { status: { _in: [STARTED, ACTIVE] } }
      ) {
        ...tournamentPlayerShareCardsData
      }
    }
  }
  ${PublicEventPageDataFragmentDoc}
  ${TournamentPlayerShareCardsDataFragmentDoc}
`;

/**
 * __usePublicEventPageQuery__
 *
 * To run a query within a React component, call `usePublicEventPageQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicEventPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicEventPageQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function usePublicEventPageQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    PublicEventPageQuery,
    PublicEventPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    PublicEventPageQuery,
    PublicEventPageQueryVariables
  >(PublicEventPageDocument, options);
}
export function usePublicEventPageLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    PublicEventPageQuery,
    PublicEventPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    PublicEventPageQuery,
    PublicEventPageQueryVariables
  >(PublicEventPageDocument, options);
}
export type PublicEventPageQueryHookResult = ReturnType<
  typeof usePublicEventPageQuery
>;
export type PublicEventPageLazyQueryHookResult = ReturnType<
  typeof usePublicEventPageLazyQuery
>;
export type PublicEventPageQueryResult = ApolloReactCommon.QueryResult<
  PublicEventPageQuery,
  PublicEventPageQueryVariables
>;
export function refetchPublicEventPageQuery(
  variables: PublicEventPageQueryVariables
) {
  return { query: PublicEventPageDocument, variables: variables };
}
export const EventsByNameDocument = gql`
  query eventsByName($name: String!) {
    events(
      where: {
        status: { _eq: ACTIVE }
        tournaments: { status: { _eq: ACTIVE } }
        slug: { _ilike: $name }
      }
    ) {
      id
      name
      image
      dateFrom
      dateTill
      slug
    }
  }
`;

/**
 * __useEventsByNameQuery__
 *
 * To run a query within a React component, call `useEventsByNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventsByNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventsByNameQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useEventsByNameQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    EventsByNameQuery,
    EventsByNameQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    EventsByNameQuery,
    EventsByNameQueryVariables
  >(EventsByNameDocument, options);
}
export function useEventsByNameLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    EventsByNameQuery,
    EventsByNameQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    EventsByNameQuery,
    EventsByNameQueryVariables
  >(EventsByNameDocument, options);
}
export type EventsByNameQueryHookResult = ReturnType<
  typeof useEventsByNameQuery
>;
export type EventsByNameLazyQueryHookResult = ReturnType<
  typeof useEventsByNameLazyQuery
>;
export type EventsByNameQueryResult = ApolloReactCommon.QueryResult<
  EventsByNameQuery,
  EventsByNameQueryVariables
>;
export function refetchEventsByNameQuery(
  variables: EventsByNameQueryVariables
) {
  return { query: EventsByNameDocument, variables: variables };
}
export const SellShareEventsDocument = gql`
  query sellShareEvents($playerId: uuid!) {
    events(
      where: {
        status: { _eq: ACTIVE }
        tournaments: { status: { _eq: ACTIVE } }
      }
    ) {
      ...eventListItemData
      tournaments(where: { status: { _eq: ACTIVE } }) {
        ...commonTournamentData
        player_shares(where: { playerId: { _eq: $playerId } }) {
          id
        }
      }
    }
  }
  ${EventListItemDataFragmentDoc}
  ${CommonTournamentDataFragmentDoc}
`;

/**
 * __useSellShareEventsQuery__
 *
 * To run a query within a React component, call `useSellShareEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSellShareEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSellShareEventsQuery({
 *   variables: {
 *      playerId: // value for 'playerId'
 *   },
 * });
 */
export function useSellShareEventsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    SellShareEventsQuery,
    SellShareEventsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    SellShareEventsQuery,
    SellShareEventsQueryVariables
  >(SellShareEventsDocument, options);
}
export function useSellShareEventsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    SellShareEventsQuery,
    SellShareEventsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    SellShareEventsQuery,
    SellShareEventsQueryVariables
  >(SellShareEventsDocument, options);
}
export type SellShareEventsQueryHookResult = ReturnType<
  typeof useSellShareEventsQuery
>;
export type SellShareEventsLazyQueryHookResult = ReturnType<
  typeof useSellShareEventsLazyQuery
>;
export type SellShareEventsQueryResult = ApolloReactCommon.QueryResult<
  SellShareEventsQuery,
  SellShareEventsQueryVariables
>;
export function refetchSellShareEventsQuery(
  variables: SellShareEventsQueryVariables
) {
  return { query: SellShareEventsDocument, variables: variables };
}
export const EventDetailsDocument = gql`
  query eventDetails(
    $slug: String!
    $includePlayerShares: Boolean = false
    $playerId: uuid
    $eventsWhere: EventsBoolExp = {}
    $tournamentsWhere: TournamentsBoolExp = {}
  ) {
    events(where: { slug: { _eq: $slug }, _or: [$eventsWhere] }) {
      ...commonEventData
      venueId
      tournaments(where: { _or: [$tournamentsWhere] }, orderBy: { date: ASC }) {
        ...commonTournamentData
        slug
        status
        name
        location
        type
        guaranteed
        date
        sharesMax
        bulletsMax
        ticketSalesActive
        local_currency {
          id
          symbol
          rate
        }
        player_shares(where: { playerId: { _eq: $playerId } })
          @include(if: $includePlayerShares) {
          id
        }
      }
    }
  }
  ${CommonEventDataFragmentDoc}
  ${CommonTournamentDataFragmentDoc}
`;

/**
 * __useEventDetailsQuery__
 *
 * To run a query within a React component, call `useEventDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventDetailsQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      includePlayerShares: // value for 'includePlayerShares'
 *      playerId: // value for 'playerId'
 *      eventsWhere: // value for 'eventsWhere'
 *      tournamentsWhere: // value for 'tournamentsWhere'
 *   },
 * });
 */
export function useEventDetailsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    EventDetailsQuery,
    EventDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    EventDetailsQuery,
    EventDetailsQueryVariables
  >(EventDetailsDocument, options);
}
export function useEventDetailsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    EventDetailsQuery,
    EventDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    EventDetailsQuery,
    EventDetailsQueryVariables
  >(EventDetailsDocument, options);
}
export type EventDetailsQueryHookResult = ReturnType<
  typeof useEventDetailsQuery
>;
export type EventDetailsLazyQueryHookResult = ReturnType<
  typeof useEventDetailsLazyQuery
>;
export type EventDetailsQueryResult = ApolloReactCommon.QueryResult<
  EventDetailsQuery,
  EventDetailsQueryVariables
>;
export function refetchEventDetailsQuery(
  variables: EventDetailsQueryVariables
) {
  return { query: EventDetailsDocument, variables: variables };
}
export const EditEventDataDocument = gql`
  query editEventData($id: uuid!) {
    event(id: $id) {
      ...editEventDataFragment
    }
  }
  ${EditEventDataFragmentFragmentDoc}
`;

/**
 * __useEditEventDataQuery__
 *
 * To run a query within a React component, call `useEditEventDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditEventDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditEventDataQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEditEventDataQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    EditEventDataQuery,
    EditEventDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    EditEventDataQuery,
    EditEventDataQueryVariables
  >(EditEventDataDocument, options);
}
export function useEditEventDataLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    EditEventDataQuery,
    EditEventDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    EditEventDataQuery,
    EditEventDataQueryVariables
  >(EditEventDataDocument, options);
}
export type EditEventDataQueryHookResult = ReturnType<
  typeof useEditEventDataQuery
>;
export type EditEventDataLazyQueryHookResult = ReturnType<
  typeof useEditEventDataLazyQuery
>;
export type EditEventDataQueryResult = ApolloReactCommon.QueryResult<
  EditEventDataQuery,
  EditEventDataQueryVariables
>;
export function refetchEditEventDataQuery(
  variables: EditEventDataQueryVariables
) {
  return { query: EditEventDataDocument, variables: variables };
}
export const EventFinancialOverviewDocument = gql`
  query eventFinancialOverview($eventId: uuid!) {
    event(id: $eventId) {
      id
      tournaments {
        id
        name
        status
        local_currency {
          id
          symbol
          rate
        }
        balances {
          id
          status
          balance
        }
      }
    }
  }
`;

/**
 * __useEventFinancialOverviewQuery__
 *
 * To run a query within a React component, call `useEventFinancialOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventFinancialOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventFinancialOverviewQuery({
 *   variables: {
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useEventFinancialOverviewQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    EventFinancialOverviewQuery,
    EventFinancialOverviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    EventFinancialOverviewQuery,
    EventFinancialOverviewQueryVariables
  >(EventFinancialOverviewDocument, options);
}
export function useEventFinancialOverviewLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    EventFinancialOverviewQuery,
    EventFinancialOverviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    EventFinancialOverviewQuery,
    EventFinancialOverviewQueryVariables
  >(EventFinancialOverviewDocument, options);
}
export type EventFinancialOverviewQueryHookResult = ReturnType<
  typeof useEventFinancialOverviewQuery
>;
export type EventFinancialOverviewLazyQueryHookResult = ReturnType<
  typeof useEventFinancialOverviewLazyQuery
>;
export type EventFinancialOverviewQueryResult = ApolloReactCommon.QueryResult<
  EventFinancialOverviewQuery,
  EventFinancialOverviewQueryVariables
>;
export function refetchEventFinancialOverviewQuery(
  variables: EventFinancialOverviewQueryVariables
) {
  return { query: EventFinancialOverviewDocument, variables: variables };
}
export const DownloadEventTransactionsDocument = gql`
  query downloadEventTransactions($eventId: String!) {
    downloadEventTransactions(eventId: $eventId) {
      status
      format
      filename
      base64
    }
  }
`;

/**
 * __useDownloadEventTransactionsQuery__
 *
 * To run a query within a React component, call `useDownloadEventTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDownloadEventTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDownloadEventTransactionsQuery({
 *   variables: {
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useDownloadEventTransactionsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    DownloadEventTransactionsQuery,
    DownloadEventTransactionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    DownloadEventTransactionsQuery,
    DownloadEventTransactionsQueryVariables
  >(DownloadEventTransactionsDocument, options);
}
export function useDownloadEventTransactionsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    DownloadEventTransactionsQuery,
    DownloadEventTransactionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    DownloadEventTransactionsQuery,
    DownloadEventTransactionsQueryVariables
  >(DownloadEventTransactionsDocument, options);
}
export type DownloadEventTransactionsQueryHookResult = ReturnType<
  typeof useDownloadEventTransactionsQuery
>;
export type DownloadEventTransactionsLazyQueryHookResult = ReturnType<
  typeof useDownloadEventTransactionsLazyQuery
>;
export type DownloadEventTransactionsQueryResult =
  ApolloReactCommon.QueryResult<
    DownloadEventTransactionsQuery,
    DownloadEventTransactionsQueryVariables
  >;
export function refetchDownloadEventTransactionsQuery(
  variables: DownloadEventTransactionsQueryVariables
) {
  return { query: DownloadEventTransactionsDocument, variables: variables };
}
export const CreateEventDocument = gql`
  mutation createEvent($data: EventsInsertInput!) {
    insertEvent(object: $data) {
      ...editEventDataFragment
    }
  }
  ${EditEventDataFragmentFragmentDoc}
`;
export type CreateEventMutationFn = ApolloReactCommon.MutationFunction<
  CreateEventMutation,
  CreateEventMutationVariables
>;

/**
 * __useCreateEventMutation__
 *
 * To run a mutation, you first call `useCreateEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEventMutation, { data, loading, error }] = useCreateEventMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateEventMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateEventMutation,
    CreateEventMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    CreateEventMutation,
    CreateEventMutationVariables
  >(CreateEventDocument, options);
}
export type CreateEventMutationHookResult = ReturnType<
  typeof useCreateEventMutation
>;
export type CreateEventMutationResult =
  ApolloReactCommon.MutationResult<CreateEventMutation>;
export type CreateEventMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateEventMutation,
  CreateEventMutationVariables
>;
export const EditEventDocument = gql`
  mutation editEvent($id: uuid!, $data: EventsSetInput!) {
    updateEvent(pk_columns: { id: $id }, _set: $data) {
      ...editEventDataFragment
    }
  }
  ${EditEventDataFragmentFragmentDoc}
`;
export type EditEventMutationFn = ApolloReactCommon.MutationFunction<
  EditEventMutation,
  EditEventMutationVariables
>;

/**
 * __useEditEventMutation__
 *
 * To run a mutation, you first call `useEditEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editEventMutation, { data, loading, error }] = useEditEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useEditEventMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    EditEventMutation,
    EditEventMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    EditEventMutation,
    EditEventMutationVariables
  >(EditEventDocument, options);
}
export type EditEventMutationHookResult = ReturnType<
  typeof useEditEventMutation
>;
export type EditEventMutationResult =
  ApolloReactCommon.MutationResult<EditEventMutation>;
export type EditEventMutationOptions = ApolloReactCommon.BaseMutationOptions<
  EditEventMutation,
  EditEventMutationVariables
>;
export const ChangeEventStatusDocument = gql`
  mutation changeEventStatus($id: uuid!, $status: StatusesEnum!) {
    updateEvent(pk_columns: { id: $id }, _set: { status: $status }) {
      id
      status
    }
  }
`;
export type ChangeEventStatusMutationFn = ApolloReactCommon.MutationFunction<
  ChangeEventStatusMutation,
  ChangeEventStatusMutationVariables
>;

/**
 * __useChangeEventStatusMutation__
 *
 * To run a mutation, you first call `useChangeEventStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeEventStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeEventStatusMutation, { data, loading, error }] = useChangeEventStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useChangeEventStatusMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ChangeEventStatusMutation,
    ChangeEventStatusMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    ChangeEventStatusMutation,
    ChangeEventStatusMutationVariables
  >(ChangeEventStatusDocument, options);
}
export type ChangeEventStatusMutationHookResult = ReturnType<
  typeof useChangeEventStatusMutation
>;
export type ChangeEventStatusMutationResult =
  ApolloReactCommon.MutationResult<ChangeEventStatusMutation>;
export type ChangeEventStatusMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    ChangeEventStatusMutation,
    ChangeEventStatusMutationVariables
  >;
export const FollowEventDocument = gql`
  mutation followEvent($eventId: uuid!) {
    insertFollower(object: { eventId: $eventId }) {
      id
    }
  }
`;
export type FollowEventMutationFn = ApolloReactCommon.MutationFunction<
  FollowEventMutation,
  FollowEventMutationVariables
>;

/**
 * __useFollowEventMutation__
 *
 * To run a mutation, you first call `useFollowEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFollowEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [followEventMutation, { data, loading, error }] = useFollowEventMutation({
 *   variables: {
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useFollowEventMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    FollowEventMutation,
    FollowEventMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    FollowEventMutation,
    FollowEventMutationVariables
  >(FollowEventDocument, options);
}
export type FollowEventMutationHookResult = ReturnType<
  typeof useFollowEventMutation
>;
export type FollowEventMutationResult =
  ApolloReactCommon.MutationResult<FollowEventMutation>;
export type FollowEventMutationOptions = ApolloReactCommon.BaseMutationOptions<
  FollowEventMutation,
  FollowEventMutationVariables
>;
export const UnFollowEventDocument = gql`
  mutation unFollowEvent($eventId: uuid!) {
    deleteFollowers(where: { eventId: { _eq: $eventId } }) {
      affected_rows
    }
  }
`;
export type UnFollowEventMutationFn = ApolloReactCommon.MutationFunction<
  UnFollowEventMutation,
  UnFollowEventMutationVariables
>;

/**
 * __useUnFollowEventMutation__
 *
 * To run a mutation, you first call `useUnFollowEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnFollowEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unFollowEventMutation, { data, loading, error }] = useUnFollowEventMutation({
 *   variables: {
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useUnFollowEventMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UnFollowEventMutation,
    UnFollowEventMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UnFollowEventMutation,
    UnFollowEventMutationVariables
  >(UnFollowEventDocument, options);
}
export type UnFollowEventMutationHookResult = ReturnType<
  typeof useUnFollowEventMutation
>;
export type UnFollowEventMutationResult =
  ApolloReactCommon.MutationResult<UnFollowEventMutation>;
export type UnFollowEventMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    UnFollowEventMutation,
    UnFollowEventMutationVariables
  >;
export const PublicPlayerPageDataDocument = gql`
  query publicPlayerPageData($slug: String!) {
    players(where: { status: { _eq: ACTIVE }, slug: { _eq: $slug } }) {
      ...publicPlayerData
    }
  }
  ${PublicPlayerDataFragmentDoc}
`;

/**
 * __usePublicPlayerPageDataQuery__
 *
 * To run a query within a React component, call `usePublicPlayerPageDataQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicPlayerPageDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicPlayerPageDataQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function usePublicPlayerPageDataQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    PublicPlayerPageDataQuery,
    PublicPlayerPageDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    PublicPlayerPageDataQuery,
    PublicPlayerPageDataQueryVariables
  >(PublicPlayerPageDataDocument, options);
}
export function usePublicPlayerPageDataLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    PublicPlayerPageDataQuery,
    PublicPlayerPageDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    PublicPlayerPageDataQuery,
    PublicPlayerPageDataQueryVariables
  >(PublicPlayerPageDataDocument, options);
}
export type PublicPlayerPageDataQueryHookResult = ReturnType<
  typeof usePublicPlayerPageDataQuery
>;
export type PublicPlayerPageDataLazyQueryHookResult = ReturnType<
  typeof usePublicPlayerPageDataLazyQuery
>;
export type PublicPlayerPageDataQueryResult = ApolloReactCommon.QueryResult<
  PublicPlayerPageDataQuery,
  PublicPlayerPageDataQueryVariables
>;
export function refetchPublicPlayerPageDataQuery(
  variables: PublicPlayerPageDataQueryVariables
) {
  return { query: PublicPlayerPageDataDocument, variables: variables };
}
export const SelfPlayerDocument = gql`
  query selfPlayer($id: uuid!) {
    player(id: $id) {
      ...selfPlayerData
    }
  }
  ${SelfPlayerDataFragmentDoc}
`;

/**
 * __useSelfPlayerQuery__
 *
 * To run a query within a React component, call `useSelfPlayerQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelfPlayerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelfPlayerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSelfPlayerQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    SelfPlayerQuery,
    SelfPlayerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<SelfPlayerQuery, SelfPlayerQueryVariables>(
    SelfPlayerDocument,
    options
  );
}
export function useSelfPlayerLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    SelfPlayerQuery,
    SelfPlayerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    SelfPlayerQuery,
    SelfPlayerQueryVariables
  >(SelfPlayerDocument, options);
}
export type SelfPlayerQueryHookResult = ReturnType<typeof useSelfPlayerQuery>;
export type SelfPlayerLazyQueryHookResult = ReturnType<
  typeof useSelfPlayerLazyQuery
>;
export type SelfPlayerQueryResult = ApolloReactCommon.QueryResult<
  SelfPlayerQuery,
  SelfPlayerQueryVariables
>;
export function refetchSelfPlayerQuery(variables: SelfPlayerQueryVariables) {
  return { query: SelfPlayerDocument, variables: variables };
}
export const BuyPlayerShareModalDataDocument = gql`
  query buyPlayerShareModalData($playerId: uuid!, $shareId: uuid!) {
    player(id: $playerId) {
      id
      name
      image
    }
    playerShare(id: $shareId) {
      ...playerPlayerShareData
      shareDilution
      slug
      maxPerUserShare
      passwordRequired
    }
    userShareBuys(where: { playerShareId: { _eq: $shareId } }) {
      id
      sharePercentage
    }
  }
  ${PlayerPlayerShareDataFragmentDoc}
`;

/**
 * __useBuyPlayerShareModalDataQuery__
 *
 * To run a query within a React component, call `useBuyPlayerShareModalDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useBuyPlayerShareModalDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBuyPlayerShareModalDataQuery({
 *   variables: {
 *      playerId: // value for 'playerId'
 *      shareId: // value for 'shareId'
 *   },
 * });
 */
export function useBuyPlayerShareModalDataQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    BuyPlayerShareModalDataQuery,
    BuyPlayerShareModalDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    BuyPlayerShareModalDataQuery,
    BuyPlayerShareModalDataQueryVariables
  >(BuyPlayerShareModalDataDocument, options);
}
export function useBuyPlayerShareModalDataLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    BuyPlayerShareModalDataQuery,
    BuyPlayerShareModalDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    BuyPlayerShareModalDataQuery,
    BuyPlayerShareModalDataQueryVariables
  >(BuyPlayerShareModalDataDocument, options);
}
export type BuyPlayerShareModalDataQueryHookResult = ReturnType<
  typeof useBuyPlayerShareModalDataQuery
>;
export type BuyPlayerShareModalDataLazyQueryHookResult = ReturnType<
  typeof useBuyPlayerShareModalDataLazyQuery
>;
export type BuyPlayerShareModalDataQueryResult = ApolloReactCommon.QueryResult<
  BuyPlayerShareModalDataQuery,
  BuyPlayerShareModalDataQueryVariables
>;
export function refetchBuyPlayerShareModalDataQuery(
  variables: BuyPlayerShareModalDataQueryVariables
) {
  return { query: BuyPlayerShareModalDataDocument, variables: variables };
}
export const PlayerShareUrlParamDataDocument = gql`
  query playerShareUrlParamData($slug: String!) {
    playerShares(where: { slug: { _eq: $slug } }) {
      id
      player {
        id
      }
    }
  }
`;

/**
 * __usePlayerShareUrlParamDataQuery__
 *
 * To run a query within a React component, call `usePlayerShareUrlParamDataQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlayerShareUrlParamDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlayerShareUrlParamDataQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function usePlayerShareUrlParamDataQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    PlayerShareUrlParamDataQuery,
    PlayerShareUrlParamDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    PlayerShareUrlParamDataQuery,
    PlayerShareUrlParamDataQueryVariables
  >(PlayerShareUrlParamDataDocument, options);
}
export function usePlayerShareUrlParamDataLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    PlayerShareUrlParamDataQuery,
    PlayerShareUrlParamDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    PlayerShareUrlParamDataQuery,
    PlayerShareUrlParamDataQueryVariables
  >(PlayerShareUrlParamDataDocument, options);
}
export type PlayerShareUrlParamDataQueryHookResult = ReturnType<
  typeof usePlayerShareUrlParamDataQuery
>;
export type PlayerShareUrlParamDataLazyQueryHookResult = ReturnType<
  typeof usePlayerShareUrlParamDataLazyQuery
>;
export type PlayerShareUrlParamDataQueryResult = ApolloReactCommon.QueryResult<
  PlayerShareUrlParamDataQuery,
  PlayerShareUrlParamDataQueryVariables
>;
export function refetchPlayerShareUrlParamDataQuery(
  variables: PlayerShareUrlParamDataQueryVariables
) {
  return { query: PlayerShareUrlParamDataDocument, variables: variables };
}
export const PlayersByNameDocument = gql`
  query playersByName($name: String!) {
    players(where: { status: { _eq: ACTIVE }, slug: { _ilike: $name } }) {
      id
      name
      realName
      image
      slug
    }
  }
`;

/**
 * __usePlayersByNameQuery__
 *
 * To run a query within a React component, call `usePlayersByNameQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlayersByNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlayersByNameQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function usePlayersByNameQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    PlayersByNameQuery,
    PlayersByNameQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    PlayersByNameQuery,
    PlayersByNameQueryVariables
  >(PlayersByNameDocument, options);
}
export function usePlayersByNameLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    PlayersByNameQuery,
    PlayersByNameQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    PlayersByNameQuery,
    PlayersByNameQueryVariables
  >(PlayersByNameDocument, options);
}
export type PlayersByNameQueryHookResult = ReturnType<
  typeof usePlayersByNameQuery
>;
export type PlayersByNameLazyQueryHookResult = ReturnType<
  typeof usePlayersByNameLazyQuery
>;
export type PlayersByNameQueryResult = ApolloReactCommon.QueryResult<
  PlayersByNameQuery,
  PlayersByNameQueryVariables
>;
export function refetchPlayersByNameQuery(
  variables: PlayersByNameQueryVariables
) {
  return { query: PlayersByNameDocument, variables: variables };
}
export const PlayerSharesDocument = gql`
  query playerShares($playerId: uuid!) {
    playerShares(where: { playerId: { _eq: $playerId } }) {
      id
      status
      sharesToSell
      markUp
      bullets
      shareDilution
      allowCancel
      player_shares_bought {
        totalSharePercentage
      }
      tournament {
        id
        name
        location
        image
        date
        buyIn
        status
        watchUrl
        event {
          id
          name
          location
          image
        }
        tournament_results(where: { playerId: { _eq: $playerId } }) {
          id
          place
          winning
        }
      }
    }
  }
`;

/**
 * __usePlayerSharesQuery__
 *
 * To run a query within a React component, call `usePlayerSharesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlayerSharesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlayerSharesQuery({
 *   variables: {
 *      playerId: // value for 'playerId'
 *   },
 * });
 */
export function usePlayerSharesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    PlayerSharesQuery,
    PlayerSharesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    PlayerSharesQuery,
    PlayerSharesQueryVariables
  >(PlayerSharesDocument, options);
}
export function usePlayerSharesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    PlayerSharesQuery,
    PlayerSharesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    PlayerSharesQuery,
    PlayerSharesQueryVariables
  >(PlayerSharesDocument, options);
}
export type PlayerSharesQueryHookResult = ReturnType<
  typeof usePlayerSharesQuery
>;
export type PlayerSharesLazyQueryHookResult = ReturnType<
  typeof usePlayerSharesLazyQuery
>;
export type PlayerSharesQueryResult = ApolloReactCommon.QueryResult<
  PlayerSharesQuery,
  PlayerSharesQueryVariables
>;
export function refetchPlayerSharesQuery(
  variables: PlayerSharesQueryVariables
) {
  return { query: PlayerSharesDocument, variables: variables };
}
export const EditUserPlayerProfileFormDataDocument = gql`
  query editUserPlayerProfileFormData($id: uuid!) {
    player(id: $id) {
      id
      facebook
      twitter
      description
      image
    }
  }
`;

/**
 * __useEditUserPlayerProfileFormDataQuery__
 *
 * To run a query within a React component, call `useEditUserPlayerProfileFormDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditUserPlayerProfileFormDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditUserPlayerProfileFormDataQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEditUserPlayerProfileFormDataQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    EditUserPlayerProfileFormDataQuery,
    EditUserPlayerProfileFormDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    EditUserPlayerProfileFormDataQuery,
    EditUserPlayerProfileFormDataQueryVariables
  >(EditUserPlayerProfileFormDataDocument, options);
}
export function useEditUserPlayerProfileFormDataLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    EditUserPlayerProfileFormDataQuery,
    EditUserPlayerProfileFormDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    EditUserPlayerProfileFormDataQuery,
    EditUserPlayerProfileFormDataQueryVariables
  >(EditUserPlayerProfileFormDataDocument, options);
}
export type EditUserPlayerProfileFormDataQueryHookResult = ReturnType<
  typeof useEditUserPlayerProfileFormDataQuery
>;
export type EditUserPlayerProfileFormDataLazyQueryHookResult = ReturnType<
  typeof useEditUserPlayerProfileFormDataLazyQuery
>;
export type EditUserPlayerProfileFormDataQueryResult =
  ApolloReactCommon.QueryResult<
    EditUserPlayerProfileFormDataQuery,
    EditUserPlayerProfileFormDataQueryVariables
  >;
export function refetchEditUserPlayerProfileFormDataQuery(
  variables: EditUserPlayerProfileFormDataQueryVariables
) {
  return { query: EditUserPlayerProfileFormDataDocument, variables: variables };
}
export const BuyPlayerShareDocument = gql`
  mutation buyPlayerShare(
    $playerShareId: String!
    $percentage: Float!
    $password: String
  ) {
    submitShareBuy(
      playerShareId: $playerShareId
      percentage: $percentage
      password: $password
    ) {
      status
      message
    }
  }
`;
export type BuyPlayerShareMutationFn = ApolloReactCommon.MutationFunction<
  BuyPlayerShareMutation,
  BuyPlayerShareMutationVariables
>;

/**
 * __useBuyPlayerShareMutation__
 *
 * To run a mutation, you first call `useBuyPlayerShareMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBuyPlayerShareMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [buyPlayerShareMutation, { data, loading, error }] = useBuyPlayerShareMutation({
 *   variables: {
 *      playerShareId: // value for 'playerShareId'
 *      percentage: // value for 'percentage'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useBuyPlayerShareMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    BuyPlayerShareMutation,
    BuyPlayerShareMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    BuyPlayerShareMutation,
    BuyPlayerShareMutationVariables
  >(BuyPlayerShareDocument, options);
}
export type BuyPlayerShareMutationHookResult = ReturnType<
  typeof useBuyPlayerShareMutation
>;
export type BuyPlayerShareMutationResult =
  ApolloReactCommon.MutationResult<BuyPlayerShareMutation>;
export type BuyPlayerShareMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    BuyPlayerShareMutation,
    BuyPlayerShareMutationVariables
  >;
export const RegisterPlayerDocument = gql`
  mutation registerPlayer($data: playerRegisterInput!) {
    registerPlayer(data: $data) {
      status
      message
    }
  }
`;
export type RegisterPlayerMutationFn = ApolloReactCommon.MutationFunction<
  RegisterPlayerMutation,
  RegisterPlayerMutationVariables
>;

/**
 * __useRegisterPlayerMutation__
 *
 * To run a mutation, you first call `useRegisterPlayerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterPlayerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerPlayerMutation, { data, loading, error }] = useRegisterPlayerMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRegisterPlayerMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RegisterPlayerMutation,
    RegisterPlayerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    RegisterPlayerMutation,
    RegisterPlayerMutationVariables
  >(RegisterPlayerDocument, options);
}
export type RegisterPlayerMutationHookResult = ReturnType<
  typeof useRegisterPlayerMutation
>;
export type RegisterPlayerMutationResult =
  ApolloReactCommon.MutationResult<RegisterPlayerMutation>;
export type RegisterPlayerMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    RegisterPlayerMutation,
    RegisterPlayerMutationVariables
  >;
export const CancelPlayerShareDocument = gql`
  mutation cancelPlayerShare($id: Int!) {
    submitPlayerShareCancel(playerShareId: $id) {
      status
      message
    }
  }
`;
export type CancelPlayerShareMutationFn = ApolloReactCommon.MutationFunction<
  CancelPlayerShareMutation,
  CancelPlayerShareMutationVariables
>;

/**
 * __useCancelPlayerShareMutation__
 *
 * To run a mutation, you first call `useCancelPlayerShareMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelPlayerShareMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelPlayerShareMutation, { data, loading, error }] = useCancelPlayerShareMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCancelPlayerShareMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CancelPlayerShareMutation,
    CancelPlayerShareMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    CancelPlayerShareMutation,
    CancelPlayerShareMutationVariables
  >(CancelPlayerShareDocument, options);
}
export type CancelPlayerShareMutationHookResult = ReturnType<
  typeof useCancelPlayerShareMutation
>;
export type CancelPlayerShareMutationResult =
  ApolloReactCommon.MutationResult<CancelPlayerShareMutation>;
export type CancelPlayerShareMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    CancelPlayerShareMutation,
    CancelPlayerShareMutationVariables
  >;
export const CreatePlayerShareDocument = gql`
  mutation createPlayerShare(
    $tournamentId: String!
    $sharesToSell: Int!
    $markUp: Float!
    $bullets: Int!
    $shareDilution: Boolean!
    $maxPerUserShare: Float
    $password: String
    $note: String
  ) {
    submitPlayerShareCreate(
      tournamentId: $tournamentId
      sharesToSell: $sharesToSell
      markUp: $markUp
      bullets: $bullets
      shareDilution: $shareDilution
      maxPerUserShare: $maxPerUserShare
      password: $password
      note: $note
    ) {
      status
      message
    }
  }
`;
export type CreatePlayerShareMutationFn = ApolloReactCommon.MutationFunction<
  CreatePlayerShareMutation,
  CreatePlayerShareMutationVariables
>;

/**
 * __useCreatePlayerShareMutation__
 *
 * To run a mutation, you first call `useCreatePlayerShareMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePlayerShareMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPlayerShareMutation, { data, loading, error }] = useCreatePlayerShareMutation({
 *   variables: {
 *      tournamentId: // value for 'tournamentId'
 *      sharesToSell: // value for 'sharesToSell'
 *      markUp: // value for 'markUp'
 *      bullets: // value for 'bullets'
 *      shareDilution: // value for 'shareDilution'
 *      maxPerUserShare: // value for 'maxPerUserShare'
 *      password: // value for 'password'
 *      note: // value for 'note'
 *   },
 * });
 */
export function useCreatePlayerShareMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreatePlayerShareMutation,
    CreatePlayerShareMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    CreatePlayerShareMutation,
    CreatePlayerShareMutationVariables
  >(CreatePlayerShareDocument, options);
}
export type CreatePlayerShareMutationHookResult = ReturnType<
  typeof useCreatePlayerShareMutation
>;
export type CreatePlayerShareMutationResult =
  ApolloReactCommon.MutationResult<CreatePlayerShareMutation>;
export type CreatePlayerShareMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    CreatePlayerShareMutation,
    CreatePlayerShareMutationVariables
  >;
export const FollowPlayerDocument = gql`
  mutation followPlayer($playerId: String!) {
    followPlayer(playerId: $playerId) {
      status
      message
    }
  }
`;
export type FollowPlayerMutationFn = ApolloReactCommon.MutationFunction<
  FollowPlayerMutation,
  FollowPlayerMutationVariables
>;

/**
 * __useFollowPlayerMutation__
 *
 * To run a mutation, you first call `useFollowPlayerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFollowPlayerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [followPlayerMutation, { data, loading, error }] = useFollowPlayerMutation({
 *   variables: {
 *      playerId: // value for 'playerId'
 *   },
 * });
 */
export function useFollowPlayerMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    FollowPlayerMutation,
    FollowPlayerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    FollowPlayerMutation,
    FollowPlayerMutationVariables
  >(FollowPlayerDocument, options);
}
export type FollowPlayerMutationHookResult = ReturnType<
  typeof useFollowPlayerMutation
>;
export type FollowPlayerMutationResult =
  ApolloReactCommon.MutationResult<FollowPlayerMutation>;
export type FollowPlayerMutationOptions = ApolloReactCommon.BaseMutationOptions<
  FollowPlayerMutation,
  FollowPlayerMutationVariables
>;
export const UnFollowPlayerDocument = gql`
  mutation unFollowPlayer($playerId: String!) {
    unfollowPlayer(playerId: $playerId) {
      status
      message
    }
  }
`;
export type UnFollowPlayerMutationFn = ApolloReactCommon.MutationFunction<
  UnFollowPlayerMutation,
  UnFollowPlayerMutationVariables
>;

/**
 * __useUnFollowPlayerMutation__
 *
 * To run a mutation, you first call `useUnFollowPlayerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnFollowPlayerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unFollowPlayerMutation, { data, loading, error }] = useUnFollowPlayerMutation({
 *   variables: {
 *      playerId: // value for 'playerId'
 *   },
 * });
 */
export function useUnFollowPlayerMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UnFollowPlayerMutation,
    UnFollowPlayerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UnFollowPlayerMutation,
    UnFollowPlayerMutationVariables
  >(UnFollowPlayerDocument, options);
}
export type UnFollowPlayerMutationHookResult = ReturnType<
  typeof useUnFollowPlayerMutation
>;
export type UnFollowPlayerMutationResult =
  ApolloReactCommon.MutationResult<UnFollowPlayerMutation>;
export type UnFollowPlayerMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    UnFollowPlayerMutation,
    UnFollowPlayerMutationVariables
  >;
export const UpdatePlayerProfileDocument = gql`
  mutation updatePlayerProfile($playerId: uuid!, $data: PlayersSetInput!) {
    updatePlayer(pk_columns: { id: $playerId }, _set: $data) {
      id
      facebook
      twitter
      description
      image
    }
  }
`;
export type UpdatePlayerProfileMutationFn = ApolloReactCommon.MutationFunction<
  UpdatePlayerProfileMutation,
  UpdatePlayerProfileMutationVariables
>;

/**
 * __useUpdatePlayerProfileMutation__
 *
 * To run a mutation, you first call `useUpdatePlayerProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePlayerProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePlayerProfileMutation, { data, loading, error }] = useUpdatePlayerProfileMutation({
 *   variables: {
 *      playerId: // value for 'playerId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdatePlayerProfileMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdatePlayerProfileMutation,
    UpdatePlayerProfileMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UpdatePlayerProfileMutation,
    UpdatePlayerProfileMutationVariables
  >(UpdatePlayerProfileDocument, options);
}
export type UpdatePlayerProfileMutationHookResult = ReturnType<
  typeof useUpdatePlayerProfileMutation
>;
export type UpdatePlayerProfileMutationResult =
  ApolloReactCommon.MutationResult<UpdatePlayerProfileMutation>;
export type UpdatePlayerProfileMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    UpdatePlayerProfileMutation,
    UpdatePlayerProfileMutationVariables
  >;
export const PlayerTicketsListDocument = gql`
  query playerTicketsList($userId: String!) {
    tickets(where: { userId: { _eq: $userId } }) {
      ...playerTicketListItemData
    }
  }
  ${PlayerTicketListItemDataFragmentDoc}
`;

/**
 * __usePlayerTicketsListQuery__
 *
 * To run a query within a React component, call `usePlayerTicketsListQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlayerTicketsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlayerTicketsListQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function usePlayerTicketsListQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    PlayerTicketsListQuery,
    PlayerTicketsListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    PlayerTicketsListQuery,
    PlayerTicketsListQueryVariables
  >(PlayerTicketsListDocument, options);
}
export function usePlayerTicketsListLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    PlayerTicketsListQuery,
    PlayerTicketsListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    PlayerTicketsListQuery,
    PlayerTicketsListQueryVariables
  >(PlayerTicketsListDocument, options);
}
export type PlayerTicketsListQueryHookResult = ReturnType<
  typeof usePlayerTicketsListQuery
>;
export type PlayerTicketsListLazyQueryHookResult = ReturnType<
  typeof usePlayerTicketsListLazyQuery
>;
export type PlayerTicketsListQueryResult = ApolloReactCommon.QueryResult<
  PlayerTicketsListQuery,
  PlayerTicketsListQueryVariables
>;
export function refetchPlayerTicketsListQuery(
  variables: PlayerTicketsListQueryVariables
) {
  return { query: PlayerTicketsListDocument, variables: variables };
}
export const PlayerTicketLocalCurrencyPayoutFormDataDocument = gql`
  query playerTicketLocalCurrencyPayoutFormData($countryId: Int!) {
    me {
      id
      email
      twofa
    }
    paymentMethodBanks(
      where: { withdraw: { _eq: true }, countryId: { _eq: $countryId } }
    ) {
      id
      name
    }
  }
`;

/**
 * __usePlayerTicketLocalCurrencyPayoutFormDataQuery__
 *
 * To run a query within a React component, call `usePlayerTicketLocalCurrencyPayoutFormDataQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlayerTicketLocalCurrencyPayoutFormDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlayerTicketLocalCurrencyPayoutFormDataQuery({
 *   variables: {
 *      countryId: // value for 'countryId'
 *   },
 * });
 */
export function usePlayerTicketLocalCurrencyPayoutFormDataQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    PlayerTicketLocalCurrencyPayoutFormDataQuery,
    PlayerTicketLocalCurrencyPayoutFormDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    PlayerTicketLocalCurrencyPayoutFormDataQuery,
    PlayerTicketLocalCurrencyPayoutFormDataQueryVariables
  >(PlayerTicketLocalCurrencyPayoutFormDataDocument, options);
}
export function usePlayerTicketLocalCurrencyPayoutFormDataLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    PlayerTicketLocalCurrencyPayoutFormDataQuery,
    PlayerTicketLocalCurrencyPayoutFormDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    PlayerTicketLocalCurrencyPayoutFormDataQuery,
    PlayerTicketLocalCurrencyPayoutFormDataQueryVariables
  >(PlayerTicketLocalCurrencyPayoutFormDataDocument, options);
}
export type PlayerTicketLocalCurrencyPayoutFormDataQueryHookResult = ReturnType<
  typeof usePlayerTicketLocalCurrencyPayoutFormDataQuery
>;
export type PlayerTicketLocalCurrencyPayoutFormDataLazyQueryHookResult =
  ReturnType<typeof usePlayerTicketLocalCurrencyPayoutFormDataLazyQuery>;
export type PlayerTicketLocalCurrencyPayoutFormDataQueryResult =
  ApolloReactCommon.QueryResult<
    PlayerTicketLocalCurrencyPayoutFormDataQuery,
    PlayerTicketLocalCurrencyPayoutFormDataQueryVariables
  >;
export function refetchPlayerTicketLocalCurrencyPayoutFormDataQuery(
  variables: PlayerTicketLocalCurrencyPayoutFormDataQueryVariables
) {
  return {
    query: PlayerTicketLocalCurrencyPayoutFormDataDocument,
    variables: variables,
  };
}
export const BuyPlayerTicketDocument = gql`
  mutation buyPlayerTicket($tournamentId: String!, $playerName: String!) {
    buyTicket(tournamentId: $tournamentId, playerName: $playerName) {
      status
      message
    }
  }
`;
export type BuyPlayerTicketMutationFn = ApolloReactCommon.MutationFunction<
  BuyPlayerTicketMutation,
  BuyPlayerTicketMutationVariables
>;

/**
 * __useBuyPlayerTicketMutation__
 *
 * To run a mutation, you first call `useBuyPlayerTicketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBuyPlayerTicketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [buyPlayerTicketMutation, { data, loading, error }] = useBuyPlayerTicketMutation({
 *   variables: {
 *      tournamentId: // value for 'tournamentId'
 *      playerName: // value for 'playerName'
 *   },
 * });
 */
export function useBuyPlayerTicketMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    BuyPlayerTicketMutation,
    BuyPlayerTicketMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    BuyPlayerTicketMutation,
    BuyPlayerTicketMutationVariables
  >(BuyPlayerTicketDocument, options);
}
export type BuyPlayerTicketMutationHookResult = ReturnType<
  typeof useBuyPlayerTicketMutation
>;
export type BuyPlayerTicketMutationResult =
  ApolloReactCommon.MutationResult<BuyPlayerTicketMutation>;
export type BuyPlayerTicketMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    BuyPlayerTicketMutation,
    BuyPlayerTicketMutationVariables
  >;
export const CancelPlayerTicketDocument = gql`
  mutation cancelPlayerTicket($ticketId: Int!) {
    cancelTicket(ticketId: $ticketId) {
      status
      message
    }
  }
`;
export type CancelPlayerTicketMutationFn = ApolloReactCommon.MutationFunction<
  CancelPlayerTicketMutation,
  CancelPlayerTicketMutationVariables
>;

/**
 * __useCancelPlayerTicketMutation__
 *
 * To run a mutation, you first call `useCancelPlayerTicketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelPlayerTicketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelPlayerTicketMutation, { data, loading, error }] = useCancelPlayerTicketMutation({
 *   variables: {
 *      ticketId: // value for 'ticketId'
 *   },
 * });
 */
export function useCancelPlayerTicketMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CancelPlayerTicketMutation,
    CancelPlayerTicketMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    CancelPlayerTicketMutation,
    CancelPlayerTicketMutationVariables
  >(CancelPlayerTicketDocument, options);
}
export type CancelPlayerTicketMutationHookResult = ReturnType<
  typeof useCancelPlayerTicketMutation
>;
export type CancelPlayerTicketMutationResult =
  ApolloReactCommon.MutationResult<CancelPlayerTicketMutation>;
export type CancelPlayerTicketMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    CancelPlayerTicketMutation,
    CancelPlayerTicketMutationVariables
  >;
export const PayoutPlayerTicketDocument = gql`
  mutation payoutPlayerTicket(
    $ticketId: String!
    $paymentMethodBankId: Int!
    $bankName: String!
    $accountHolder: String!
    $accountNumber: String!
    $twoFA: String
  ) {
    payoutTicket(
      ticketId: $ticketId
      paymentMethodBankId: $paymentMethodBankId
      bankName: $bankName
      accountHolder: $accountHolder
      accountNumber: $accountNumber
      twoFA: $twoFA
    ) {
      status
      message
    }
  }
`;
export type PayoutPlayerTicketMutationFn = ApolloReactCommon.MutationFunction<
  PayoutPlayerTicketMutation,
  PayoutPlayerTicketMutationVariables
>;

/**
 * __usePayoutPlayerTicketMutation__
 *
 * To run a mutation, you first call `usePayoutPlayerTicketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePayoutPlayerTicketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [payoutPlayerTicketMutation, { data, loading, error }] = usePayoutPlayerTicketMutation({
 *   variables: {
 *      ticketId: // value for 'ticketId'
 *      paymentMethodBankId: // value for 'paymentMethodBankId'
 *      bankName: // value for 'bankName'
 *      accountHolder: // value for 'accountHolder'
 *      accountNumber: // value for 'accountNumber'
 *      twoFA: // value for 'twoFA'
 *   },
 * });
 */
export function usePayoutPlayerTicketMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    PayoutPlayerTicketMutation,
    PayoutPlayerTicketMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    PayoutPlayerTicketMutation,
    PayoutPlayerTicketMutationVariables
  >(PayoutPlayerTicketDocument, options);
}
export type PayoutPlayerTicketMutationHookResult = ReturnType<
  typeof usePayoutPlayerTicketMutation
>;
export type PayoutPlayerTicketMutationResult =
  ApolloReactCommon.MutationResult<PayoutPlayerTicketMutation>;
export type PayoutPlayerTicketMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    PayoutPlayerTicketMutation,
    PayoutPlayerTicketMutationVariables
  >;
export const PublicTournamentPageDocument = gql`
  query publicTournamentPage($slug: String!) {
    tournaments(where: { slug: { _eq: $slug } }) {
      ...tournamentPlayerShareCardsData
      event {
        ...publicEventPageData
      }
    }
  }
  ${TournamentPlayerShareCardsDataFragmentDoc}
  ${PublicEventPageDataFragmentDoc}
`;

/**
 * __usePublicTournamentPageQuery__
 *
 * To run a query within a React component, call `usePublicTournamentPageQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicTournamentPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicTournamentPageQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function usePublicTournamentPageQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    PublicTournamentPageQuery,
    PublicTournamentPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    PublicTournamentPageQuery,
    PublicTournamentPageQueryVariables
  >(PublicTournamentPageDocument, options);
}
export function usePublicTournamentPageLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    PublicTournamentPageQuery,
    PublicTournamentPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    PublicTournamentPageQuery,
    PublicTournamentPageQueryVariables
  >(PublicTournamentPageDocument, options);
}
export type PublicTournamentPageQueryHookResult = ReturnType<
  typeof usePublicTournamentPageQuery
>;
export type PublicTournamentPageLazyQueryHookResult = ReturnType<
  typeof usePublicTournamentPageLazyQuery
>;
export type PublicTournamentPageQueryResult = ApolloReactCommon.QueryResult<
  PublicTournamentPageQuery,
  PublicTournamentPageQueryVariables
>;
export function refetchPublicTournamentPageQuery(
  variables: PublicTournamentPageQueryVariables
) {
  return { query: PublicTournamentPageDocument, variables: variables };
}
export const SellPlayerShareConfirmModalDataDocument = gql`
  query sellPlayerShareConfirmModalData($tournamentId: uuid!) {
    tournament(id: $tournamentId) {
      id
      name
      location
      date
      event {
        id
        name
        location
        venue {
          id
          name
        }
      }
    }
  }
`;

/**
 * __useSellPlayerShareConfirmModalDataQuery__
 *
 * To run a query within a React component, call `useSellPlayerShareConfirmModalDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useSellPlayerShareConfirmModalDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSellPlayerShareConfirmModalDataQuery({
 *   variables: {
 *      tournamentId: // value for 'tournamentId'
 *   },
 * });
 */
export function useSellPlayerShareConfirmModalDataQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    SellPlayerShareConfirmModalDataQuery,
    SellPlayerShareConfirmModalDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    SellPlayerShareConfirmModalDataQuery,
    SellPlayerShareConfirmModalDataQueryVariables
  >(SellPlayerShareConfirmModalDataDocument, options);
}
export function useSellPlayerShareConfirmModalDataLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    SellPlayerShareConfirmModalDataQuery,
    SellPlayerShareConfirmModalDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    SellPlayerShareConfirmModalDataQuery,
    SellPlayerShareConfirmModalDataQueryVariables
  >(SellPlayerShareConfirmModalDataDocument, options);
}
export type SellPlayerShareConfirmModalDataQueryHookResult = ReturnType<
  typeof useSellPlayerShareConfirmModalDataQuery
>;
export type SellPlayerShareConfirmModalDataLazyQueryHookResult = ReturnType<
  typeof useSellPlayerShareConfirmModalDataLazyQuery
>;
export type SellPlayerShareConfirmModalDataQueryResult =
  ApolloReactCommon.QueryResult<
    SellPlayerShareConfirmModalDataQuery,
    SellPlayerShareConfirmModalDataQueryVariables
  >;
export function refetchSellPlayerShareConfirmModalDataQuery(
  variables: SellPlayerShareConfirmModalDataQueryVariables
) {
  return {
    query: SellPlayerShareConfirmModalDataDocument,
    variables: variables,
  };
}
export const TournamentsByNameDocument = gql`
  query tournamentsByName($name: String!) {
    tournaments(
      where: { status: { _in: [STARTED, ACTIVE] }, slug: { _ilike: $name } }
    ) {
      id
      name
      date
      slug
      event {
        name
        image
      }
    }
  }
`;

/**
 * __useTournamentsByNameQuery__
 *
 * To run a query within a React component, call `useTournamentsByNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useTournamentsByNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTournamentsByNameQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useTournamentsByNameQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    TournamentsByNameQuery,
    TournamentsByNameQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    TournamentsByNameQuery,
    TournamentsByNameQueryVariables
  >(TournamentsByNameDocument, options);
}
export function useTournamentsByNameLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    TournamentsByNameQuery,
    TournamentsByNameQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    TournamentsByNameQuery,
    TournamentsByNameQueryVariables
  >(TournamentsByNameDocument, options);
}
export type TournamentsByNameQueryHookResult = ReturnType<
  typeof useTournamentsByNameQuery
>;
export type TournamentsByNameLazyQueryHookResult = ReturnType<
  typeof useTournamentsByNameLazyQuery
>;
export type TournamentsByNameQueryResult = ApolloReactCommon.QueryResult<
  TournamentsByNameQuery,
  TournamentsByNameQueryVariables
>;
export function refetchTournamentsByNameQuery(
  variables: TournamentsByNameQueryVariables
) {
  return { query: TournamentsByNameDocument, variables: variables };
}
export const UserVenueTournamentDocument = gql`
  query userVenueTournament($slug: String!) {
    tournaments(where: { slug: { _eq: $slug } }) {
      ...commonTournamentData
      slug
      status
      venueId
      name
      date
      currencyRate
      balances(where: { currencyId: { _eq: 1 } }, orderBy: { status: ASC }) {
        id
        status
        balance
      }
      tournament_results {
        id
      }
      currency {
        id
        name
        symbol
      }
      local_currency {
        name
        symbol
        decimals
        rounding
      }
      player_shares(
        orderBy: { tournament_result: { status: DESC }, status: ASC }
      ) {
        ...userVenueTournamentPlayerShareData
      }
      tickets(where: { playerShareId: { _isNull: true } }) {
        ...userVenueTournamentTicketData
      }
    }
  }
  ${CommonTournamentDataFragmentDoc}
  ${UserVenueTournamentPlayerShareDataFragmentDoc}
  ${UserVenueTournamentTicketDataFragmentDoc}
`;

/**
 * __useUserVenueTournamentQuery__
 *
 * To run a query within a React component, call `useUserVenueTournamentQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserVenueTournamentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserVenueTournamentQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useUserVenueTournamentQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    UserVenueTournamentQuery,
    UserVenueTournamentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    UserVenueTournamentQuery,
    UserVenueTournamentQueryVariables
  >(UserVenueTournamentDocument, options);
}
export function useUserVenueTournamentLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    UserVenueTournamentQuery,
    UserVenueTournamentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    UserVenueTournamentQuery,
    UserVenueTournamentQueryVariables
  >(UserVenueTournamentDocument, options);
}
export type UserVenueTournamentQueryHookResult = ReturnType<
  typeof useUserVenueTournamentQuery
>;
export type UserVenueTournamentLazyQueryHookResult = ReturnType<
  typeof useUserVenueTournamentLazyQuery
>;
export type UserVenueTournamentQueryResult = ApolloReactCommon.QueryResult<
  UserVenueTournamentQuery,
  UserVenueTournamentQueryVariables
>;
export function refetchUserVenueTournamentQuery(
  variables: UserVenueTournamentQueryVariables
) {
  return { query: UserVenueTournamentDocument, variables: variables };
}
export const UserVenueTournamentPlayerShareDocument = gql`
  query userVenueTournamentPlayerShare($id: uuid!) {
    playerShare(id: $id) {
      ...userVenueTournamentPlayerShareData
    }
  }
  ${UserVenueTournamentPlayerShareDataFragmentDoc}
`;

/**
 * __useUserVenueTournamentPlayerShareQuery__
 *
 * To run a query within a React component, call `useUserVenueTournamentPlayerShareQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserVenueTournamentPlayerShareQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserVenueTournamentPlayerShareQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserVenueTournamentPlayerShareQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    UserVenueTournamentPlayerShareQuery,
    UserVenueTournamentPlayerShareQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    UserVenueTournamentPlayerShareQuery,
    UserVenueTournamentPlayerShareQueryVariables
  >(UserVenueTournamentPlayerShareDocument, options);
}
export function useUserVenueTournamentPlayerShareLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    UserVenueTournamentPlayerShareQuery,
    UserVenueTournamentPlayerShareQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    UserVenueTournamentPlayerShareQuery,
    UserVenueTournamentPlayerShareQueryVariables
  >(UserVenueTournamentPlayerShareDocument, options);
}
export type UserVenueTournamentPlayerShareQueryHookResult = ReturnType<
  typeof useUserVenueTournamentPlayerShareQuery
>;
export type UserVenueTournamentPlayerShareLazyQueryHookResult = ReturnType<
  typeof useUserVenueTournamentPlayerShareLazyQuery
>;
export type UserVenueTournamentPlayerShareQueryResult =
  ApolloReactCommon.QueryResult<
    UserVenueTournamentPlayerShareQuery,
    UserVenueTournamentPlayerShareQueryVariables
  >;
export function refetchUserVenueTournamentPlayerShareQuery(
  variables: UserVenueTournamentPlayerShareQueryVariables
) {
  return {
    query: UserVenueTournamentPlayerShareDocument,
    variables: variables,
  };
}
export const EditTournamentDataDocument = gql`
  query editTournamentData($id: uuid!) {
    tournament(id: $id) {
      ...editTournamentDataFragment
    }
    currencies {
      ...currenciesListData
    }
  }
  ${EditTournamentDataFragmentFragmentDoc}
  ${CurrenciesListDataFragmentDoc}
`;

/**
 * __useEditTournamentDataQuery__
 *
 * To run a query within a React component, call `useEditTournamentDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditTournamentDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditTournamentDataQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEditTournamentDataQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    EditTournamentDataQuery,
    EditTournamentDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    EditTournamentDataQuery,
    EditTournamentDataQueryVariables
  >(EditTournamentDataDocument, options);
}
export function useEditTournamentDataLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    EditTournamentDataQuery,
    EditTournamentDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    EditTournamentDataQuery,
    EditTournamentDataQueryVariables
  >(EditTournamentDataDocument, options);
}
export type EditTournamentDataQueryHookResult = ReturnType<
  typeof useEditTournamentDataQuery
>;
export type EditTournamentDataLazyQueryHookResult = ReturnType<
  typeof useEditTournamentDataLazyQuery
>;
export type EditTournamentDataQueryResult = ApolloReactCommon.QueryResult<
  EditTournamentDataQuery,
  EditTournamentDataQueryVariables
>;
export function refetchEditTournamentDataQuery(
  variables: EditTournamentDataQueryVariables
) {
  return { query: EditTournamentDataDocument, variables: variables };
}
export const CurrenciesDocument = gql`
  query currencies {
    currencies {
      ...currenciesListData
    }
  }
  ${CurrenciesListDataFragmentDoc}
`;

/**
 * __useCurrenciesQuery__
 *
 * To run a query within a React component, call `useCurrenciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrenciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrenciesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrenciesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    CurrenciesQuery,
    CurrenciesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<CurrenciesQuery, CurrenciesQueryVariables>(
    CurrenciesDocument,
    options
  );
}
export function useCurrenciesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CurrenciesQuery,
    CurrenciesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    CurrenciesQuery,
    CurrenciesQueryVariables
  >(CurrenciesDocument, options);
}
export type CurrenciesQueryHookResult = ReturnType<typeof useCurrenciesQuery>;
export type CurrenciesLazyQueryHookResult = ReturnType<
  typeof useCurrenciesLazyQuery
>;
export type CurrenciesQueryResult = ApolloReactCommon.QueryResult<
  CurrenciesQuery,
  CurrenciesQueryVariables
>;
export function refetchCurrenciesQuery(variables?: CurrenciesQueryVariables) {
  return { query: CurrenciesDocument, variables: variables };
}
export const CreateTournamentDocument = gql`
  mutation createTournament($data: TournamentsInsertInput!) {
    insertTournament(object: $data) {
      ...editTournamentDataFragment
    }
  }
  ${EditTournamentDataFragmentFragmentDoc}
`;
export type CreateTournamentMutationFn = ApolloReactCommon.MutationFunction<
  CreateTournamentMutation,
  CreateTournamentMutationVariables
>;

/**
 * __useCreateTournamentMutation__
 *
 * To run a mutation, you first call `useCreateTournamentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTournamentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTournamentMutation, { data, loading, error }] = useCreateTournamentMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateTournamentMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateTournamentMutation,
    CreateTournamentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    CreateTournamentMutation,
    CreateTournamentMutationVariables
  >(CreateTournamentDocument, options);
}
export type CreateTournamentMutationHookResult = ReturnType<
  typeof useCreateTournamentMutation
>;
export type CreateTournamentMutationResult =
  ApolloReactCommon.MutationResult<CreateTournamentMutation>;
export type CreateTournamentMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    CreateTournamentMutation,
    CreateTournamentMutationVariables
  >;
export const CancelTournamentDocument = gql`
  mutation cancelTournament($tournamentId: String!) {
    cancelTournament(tournamentId: $tournamentId) {
      status
      message
    }
  }
`;
export type CancelTournamentMutationFn = ApolloReactCommon.MutationFunction<
  CancelTournamentMutation,
  CancelTournamentMutationVariables
>;

/**
 * __useCancelTournamentMutation__
 *
 * To run a mutation, you first call `useCancelTournamentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelTournamentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelTournamentMutation, { data, loading, error }] = useCancelTournamentMutation({
 *   variables: {
 *      tournamentId: // value for 'tournamentId'
 *   },
 * });
 */
export function useCancelTournamentMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CancelTournamentMutation,
    CancelTournamentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    CancelTournamentMutation,
    CancelTournamentMutationVariables
  >(CancelTournamentDocument, options);
}
export type CancelTournamentMutationHookResult = ReturnType<
  typeof useCancelTournamentMutation
>;
export type CancelTournamentMutationResult =
  ApolloReactCommon.MutationResult<CancelTournamentMutation>;
export type CancelTournamentMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    CancelTournamentMutation,
    CancelTournamentMutationVariables
  >;
export const PublishTournamentDocument = gql`
  mutation publishTournament($tournamentId: uuid!) {
    updateTournament(
      pk_columns: { id: $tournamentId }
      _set: { status: ACTIVE }
    ) {
      ...editTournamentDataFragment
    }
  }
  ${EditTournamentDataFragmentFragmentDoc}
`;
export type PublishTournamentMutationFn = ApolloReactCommon.MutationFunction<
  PublishTournamentMutation,
  PublishTournamentMutationVariables
>;

/**
 * __usePublishTournamentMutation__
 *
 * To run a mutation, you first call `usePublishTournamentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishTournamentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishTournamentMutation, { data, loading, error }] = usePublishTournamentMutation({
 *   variables: {
 *      tournamentId: // value for 'tournamentId'
 *   },
 * });
 */
export function usePublishTournamentMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    PublishTournamentMutation,
    PublishTournamentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    PublishTournamentMutation,
    PublishTournamentMutationVariables
  >(PublishTournamentDocument, options);
}
export type PublishTournamentMutationHookResult = ReturnType<
  typeof usePublishTournamentMutation
>;
export type PublishTournamentMutationResult =
  ApolloReactCommon.MutationResult<PublishTournamentMutation>;
export type PublishTournamentMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    PublishTournamentMutation,
    PublishTournamentMutationVariables
  >;
export const EditTournamentDocument = gql`
  mutation editTournament($id: uuid!, $data: TournamentsSetInput!) {
    updateTournament(pk_columns: { id: $id }, _set: $data) {
      ...editTournamentDataFragment
    }
  }
  ${EditTournamentDataFragmentFragmentDoc}
`;
export type EditTournamentMutationFn = ApolloReactCommon.MutationFunction<
  EditTournamentMutation,
  EditTournamentMutationVariables
>;

/**
 * __useEditTournamentMutation__
 *
 * To run a mutation, you first call `useEditTournamentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditTournamentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editTournamentMutation, { data, loading, error }] = useEditTournamentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useEditTournamentMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    EditTournamentMutation,
    EditTournamentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    EditTournamentMutation,
    EditTournamentMutationVariables
  >(EditTournamentDocument, options);
}
export type EditTournamentMutationHookResult = ReturnType<
  typeof useEditTournamentMutation
>;
export type EditTournamentMutationResult =
  ApolloReactCommon.MutationResult<EditTournamentMutation>;
export type EditTournamentMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    EditTournamentMutation,
    EditTournamentMutationVariables
  >;
export const StartTournamentDocument = gql`
  mutation startTournament($tournamentId: String!) {
    startTournament(tournamentId: $tournamentId) {
      status
      message
    }
  }
`;
export type StartTournamentMutationFn = ApolloReactCommon.MutationFunction<
  StartTournamentMutation,
  StartTournamentMutationVariables
>;

/**
 * __useStartTournamentMutation__
 *
 * To run a mutation, you first call `useStartTournamentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartTournamentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startTournamentMutation, { data, loading, error }] = useStartTournamentMutation({
 *   variables: {
 *      tournamentId: // value for 'tournamentId'
 *   },
 * });
 */
export function useStartTournamentMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    StartTournamentMutation,
    StartTournamentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    StartTournamentMutation,
    StartTournamentMutationVariables
  >(StartTournamentDocument, options);
}
export type StartTournamentMutationHookResult = ReturnType<
  typeof useStartTournamentMutation
>;
export type StartTournamentMutationResult =
  ApolloReactCommon.MutationResult<StartTournamentMutation>;
export type StartTournamentMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    StartTournamentMutation,
    StartTournamentMutationVariables
  >;
export const FinishTournamentDocument = gql`
  mutation finishTournament($tournamentId: String!) {
    finishTournament(tournamentId: $tournamentId) {
      status
      message
    }
  }
`;
export type FinishTournamentMutationFn = ApolloReactCommon.MutationFunction<
  FinishTournamentMutation,
  FinishTournamentMutationVariables
>;

/**
 * __useFinishTournamentMutation__
 *
 * To run a mutation, you first call `useFinishTournamentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinishTournamentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finishTournamentMutation, { data, loading, error }] = useFinishTournamentMutation({
 *   variables: {
 *      tournamentId: // value for 'tournamentId'
 *   },
 * });
 */
export function useFinishTournamentMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    FinishTournamentMutation,
    FinishTournamentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    FinishTournamentMutation,
    FinishTournamentMutationVariables
  >(FinishTournamentDocument, options);
}
export type FinishTournamentMutationHookResult = ReturnType<
  typeof useFinishTournamentMutation
>;
export type FinishTournamentMutationResult =
  ApolloReactCommon.MutationResult<FinishTournamentMutation>;
export type FinishTournamentMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    FinishTournamentMutation,
    FinishTournamentMutationVariables
  >;
export const ModifyPlayerShareDocument = gql`
  mutation modifyPlayerShare(
    $playerShareId: String!
    $action: playerShareAction!
  ) {
    playerShareResult(playerShareId: $playerShareId, action: $action) {
      status
      message
    }
  }
`;
export type ModifyPlayerShareMutationFn = ApolloReactCommon.MutationFunction<
  ModifyPlayerShareMutation,
  ModifyPlayerShareMutationVariables
>;

/**
 * __useModifyPlayerShareMutation__
 *
 * To run a mutation, you first call `useModifyPlayerShareMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModifyPlayerShareMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modifyPlayerShareMutation, { data, loading, error }] = useModifyPlayerShareMutation({
 *   variables: {
 *      playerShareId: // value for 'playerShareId'
 *      action: // value for 'action'
 *   },
 * });
 */
export function useModifyPlayerShareMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ModifyPlayerShareMutation,
    ModifyPlayerShareMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    ModifyPlayerShareMutation,
    ModifyPlayerShareMutationVariables
  >(ModifyPlayerShareDocument, options);
}
export type ModifyPlayerShareMutationHookResult = ReturnType<
  typeof useModifyPlayerShareMutation
>;
export type ModifyPlayerShareMutationResult =
  ApolloReactCommon.MutationResult<ModifyPlayerShareMutation>;
export type ModifyPlayerShareMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    ModifyPlayerShareMutation,
    ModifyPlayerShareMutationVariables
  >;
export const SetWinningPlayerDocument = gql`
  mutation setWinningPlayer(
    $playerShareId: String!
    $place: Int!
    $winAmount: Float!
  ) {
    playerShareResult(
      playerShareId: $playerShareId
      action: won
      place: $place
      won: $winAmount
    ) {
      status
      message
      playerPayout
      stake3Payout
      stake3PayoutUSD
    }
  }
`;
export type SetWinningPlayerMutationFn = ApolloReactCommon.MutationFunction<
  SetWinningPlayerMutation,
  SetWinningPlayerMutationVariables
>;

/**
 * __useSetWinningPlayerMutation__
 *
 * To run a mutation, you first call `useSetWinningPlayerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetWinningPlayerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setWinningPlayerMutation, { data, loading, error }] = useSetWinningPlayerMutation({
 *   variables: {
 *      playerShareId: // value for 'playerShareId'
 *      place: // value for 'place'
 *      winAmount: // value for 'winAmount'
 *   },
 * });
 */
export function useSetWinningPlayerMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SetWinningPlayerMutation,
    SetWinningPlayerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    SetWinningPlayerMutation,
    SetWinningPlayerMutationVariables
  >(SetWinningPlayerDocument, options);
}
export type SetWinningPlayerMutationHookResult = ReturnType<
  typeof useSetWinningPlayerMutation
>;
export type SetWinningPlayerMutationResult =
  ApolloReactCommon.MutationResult<SetWinningPlayerMutation>;
export type SetWinningPlayerMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    SetWinningPlayerMutation,
    SetWinningPlayerMutationVariables
  >;
export const InitialUserDataDocument = gql`
  query initialUserData {
    me {
      id
      provider
      erc20Address
      status
      language
      email
      name
      playerId
      playerStatus
      playerSlug
      venueId
      twofa
    }
    userBalances(where: { currencyId: { _eq: 1 } }, orderBy: { status: ASC }) {
      id
      balance
      status
      currencyId
    }
  }
`;

/**
 * __useInitialUserDataQuery__
 *
 * To run a query within a React component, call `useInitialUserDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useInitialUserDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInitialUserDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useInitialUserDataQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    InitialUserDataQuery,
    InitialUserDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    InitialUserDataQuery,
    InitialUserDataQueryVariables
  >(InitialUserDataDocument, options);
}
export function useInitialUserDataLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    InitialUserDataQuery,
    InitialUserDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    InitialUserDataQuery,
    InitialUserDataQueryVariables
  >(InitialUserDataDocument, options);
}
export type InitialUserDataQueryHookResult = ReturnType<
  typeof useInitialUserDataQuery
>;
export type InitialUserDataLazyQueryHookResult = ReturnType<
  typeof useInitialUserDataLazyQuery
>;
export type InitialUserDataQueryResult = ApolloReactCommon.QueryResult<
  InitialUserDataQuery,
  InitialUserDataQueryVariables
>;
export function refetchInitialUserDataQuery(
  variables?: InitialUserDataQueryVariables
) {
  return { query: InitialUserDataDocument, variables: variables };
}
export const UserFollowingsDocument = gql`
  query userFollowings {
    followers {
      id
      userId
      venueId
      eventId
    }
  }
`;

/**
 * __useUserFollowingsQuery__
 *
 * To run a query within a React component, call `useUserFollowingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserFollowingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserFollowingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserFollowingsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    UserFollowingsQuery,
    UserFollowingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    UserFollowingsQuery,
    UserFollowingsQueryVariables
  >(UserFollowingsDocument, options);
}
export function useUserFollowingsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    UserFollowingsQuery,
    UserFollowingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    UserFollowingsQuery,
    UserFollowingsQueryVariables
  >(UserFollowingsDocument, options);
}
export type UserFollowingsQueryHookResult = ReturnType<
  typeof useUserFollowingsQuery
>;
export type UserFollowingsLazyQueryHookResult = ReturnType<
  typeof useUserFollowingsLazyQuery
>;
export type UserFollowingsQueryResult = ApolloReactCommon.QueryResult<
  UserFollowingsQuery,
  UserFollowingsQueryVariables
>;
export function refetchUserFollowingsQuery(
  variables?: UserFollowingsQueryVariables
) {
  return { query: UserFollowingsDocument, variables: variables };
}
export const UserIdDocument = gql`
  query userId {
    me {
      id
    }
  }
`;

/**
 * __useUserIdQuery__
 *
 * To run a query within a React component, call `useUserIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserIdQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserIdQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    UserIdQuery,
    UserIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<UserIdQuery, UserIdQueryVariables>(
    UserIdDocument,
    options
  );
}
export function useUserIdLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    UserIdQuery,
    UserIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<UserIdQuery, UserIdQueryVariables>(
    UserIdDocument,
    options
  );
}
export type UserIdQueryHookResult = ReturnType<typeof useUserIdQuery>;
export type UserIdLazyQueryHookResult = ReturnType<typeof useUserIdLazyQuery>;
export type UserIdQueryResult = ApolloReactCommon.QueryResult<
  UserIdQuery,
  UserIdQueryVariables
>;
export function refetchUserIdQuery(variables?: UserIdQueryVariables) {
  return { query: UserIdDocument, variables: variables };
}
export const UserErc20AddressDocument = gql`
  query userERC20Address {
    me {
      id
      erc20Address
    }
  }
`;

/**
 * __useUserErc20AddressQuery__
 *
 * To run a query within a React component, call `useUserErc20AddressQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserErc20AddressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserErc20AddressQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserErc20AddressQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    UserErc20AddressQuery,
    UserErc20AddressQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    UserErc20AddressQuery,
    UserErc20AddressQueryVariables
  >(UserErc20AddressDocument, options);
}
export function useUserErc20AddressLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    UserErc20AddressQuery,
    UserErc20AddressQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    UserErc20AddressQuery,
    UserErc20AddressQueryVariables
  >(UserErc20AddressDocument, options);
}
export type UserErc20AddressQueryHookResult = ReturnType<
  typeof useUserErc20AddressQuery
>;
export type UserErc20AddressLazyQueryHookResult = ReturnType<
  typeof useUserErc20AddressLazyQuery
>;
export type UserErc20AddressQueryResult = ApolloReactCommon.QueryResult<
  UserErc20AddressQuery,
  UserErc20AddressQueryVariables
>;
export function refetchUserErc20AddressQuery(
  variables?: UserErc20AddressQueryVariables
) {
  return { query: UserErc20AddressDocument, variables: variables };
}
export const UserStatusDocument = gql`
  query userStatus {
    me {
      id
      status
    }
  }
`;

/**
 * __useUserStatusQuery__
 *
 * To run a query within a React component, call `useUserStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserStatusQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    UserStatusQuery,
    UserStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<UserStatusQuery, UserStatusQueryVariables>(
    UserStatusDocument,
    options
  );
}
export function useUserStatusLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    UserStatusQuery,
    UserStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    UserStatusQuery,
    UserStatusQueryVariables
  >(UserStatusDocument, options);
}
export type UserStatusQueryHookResult = ReturnType<typeof useUserStatusQuery>;
export type UserStatusLazyQueryHookResult = ReturnType<
  typeof useUserStatusLazyQuery
>;
export type UserStatusQueryResult = ApolloReactCommon.QueryResult<
  UserStatusQuery,
  UserStatusQueryVariables
>;
export function refetchUserStatusQuery(variables?: UserStatusQueryVariables) {
  return { query: UserStatusDocument, variables: variables };
}
export const UserNameDocument = gql`
  query userName {
    me {
      id
      name
      playerName
    }
  }
`;

/**
 * __useUserNameQuery__
 *
 * To run a query within a React component, call `useUserNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserNameQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserNameQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    UserNameQuery,
    UserNameQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<UserNameQuery, UserNameQueryVariables>(
    UserNameDocument,
    options
  );
}
export function useUserNameLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    UserNameQuery,
    UserNameQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<UserNameQuery, UserNameQueryVariables>(
    UserNameDocument,
    options
  );
}
export type UserNameQueryHookResult = ReturnType<typeof useUserNameQuery>;
export type UserNameLazyQueryHookResult = ReturnType<
  typeof useUserNameLazyQuery
>;
export type UserNameQueryResult = ApolloReactCommon.QueryResult<
  UserNameQuery,
  UserNameQueryVariables
>;
export function refetchUserNameQuery(variables?: UserNameQueryVariables) {
  return { query: UserNameDocument, variables: variables };
}
export const UserBalanceDocument = gql`
  query userBalance {
    userBalances(where: { currencyId: { _eq: 1 } }, orderBy: { status: ASC }) {
      id
      balance
      status
      currencyId
    }
  }
`;

/**
 * __useUserBalanceQuery__
 *
 * To run a query within a React component, call `useUserBalanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserBalanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserBalanceQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserBalanceQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    UserBalanceQuery,
    UserBalanceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<UserBalanceQuery, UserBalanceQueryVariables>(
    UserBalanceDocument,
    options
  );
}
export function useUserBalanceLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    UserBalanceQuery,
    UserBalanceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    UserBalanceQuery,
    UserBalanceQueryVariables
  >(UserBalanceDocument, options);
}
export type UserBalanceQueryHookResult = ReturnType<typeof useUserBalanceQuery>;
export type UserBalanceLazyQueryHookResult = ReturnType<
  typeof useUserBalanceLazyQuery
>;
export type UserBalanceQueryResult = ApolloReactCommon.QueryResult<
  UserBalanceQuery,
  UserBalanceQueryVariables
>;
export function refetchUserBalanceQuery(variables?: UserBalanceQueryVariables) {
  return { query: UserBalanceDocument, variables: variables };
}
export const UserSettingsModalDataDocument = gql`
  query userSettingsModalData {
    me {
      id
      provider
      erc20Address
      email
      twofa
    }
  }
`;

/**
 * __useUserSettingsModalDataQuery__
 *
 * To run a query within a React component, call `useUserSettingsModalDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserSettingsModalDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserSettingsModalDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserSettingsModalDataQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    UserSettingsModalDataQuery,
    UserSettingsModalDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    UserSettingsModalDataQuery,
    UserSettingsModalDataQueryVariables
  >(UserSettingsModalDataDocument, options);
}
export function useUserSettingsModalDataLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    UserSettingsModalDataQuery,
    UserSettingsModalDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    UserSettingsModalDataQuery,
    UserSettingsModalDataQueryVariables
  >(UserSettingsModalDataDocument, options);
}
export type UserSettingsModalDataQueryHookResult = ReturnType<
  typeof useUserSettingsModalDataQuery
>;
export type UserSettingsModalDataLazyQueryHookResult = ReturnType<
  typeof useUserSettingsModalDataLazyQuery
>;
export type UserSettingsModalDataQueryResult = ApolloReactCommon.QueryResult<
  UserSettingsModalDataQuery,
  UserSettingsModalDataQueryVariables
>;
export function refetchUserSettingsModalDataQuery(
  variables?: UserSettingsModalDataQueryVariables
) {
  return { query: UserSettingsModalDataDocument, variables: variables };
}
export const UserSharesDocument = gql`
  query userShares {
    userShareBuys(orderBy: { id: DESC }) {
      id
      sharePercentage
      totalAmount
      status
      won
      player_share {
        id
        allowCancel
        player {
          id
          name
          image
        }
        tournament {
          id
          date
          name
          location
          watchUrl
          event {
            id
            name
            location
            image
          }
        }
      }
    }
  }
`;

/**
 * __useUserSharesQuery__
 *
 * To run a query within a React component, call `useUserSharesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserSharesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserSharesQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserSharesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    UserSharesQuery,
    UserSharesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<UserSharesQuery, UserSharesQueryVariables>(
    UserSharesDocument,
    options
  );
}
export function useUserSharesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    UserSharesQuery,
    UserSharesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    UserSharesQuery,
    UserSharesQueryVariables
  >(UserSharesDocument, options);
}
export type UserSharesQueryHookResult = ReturnType<typeof useUserSharesQuery>;
export type UserSharesLazyQueryHookResult = ReturnType<
  typeof useUserSharesLazyQuery
>;
export type UserSharesQueryResult = ApolloReactCommon.QueryResult<
  UserSharesQuery,
  UserSharesQueryVariables
>;
export function refetchUserSharesQuery(variables?: UserSharesQueryVariables) {
  return { query: UserSharesDocument, variables: variables };
}
export const UserLanguageDocument = gql`
  query userLanguage {
    me {
      id
      language
    }
  }
`;

/**
 * __useUserLanguageQuery__
 *
 * To run a query within a React component, call `useUserLanguageQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserLanguageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserLanguageQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserLanguageQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    UserLanguageQuery,
    UserLanguageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    UserLanguageQuery,
    UserLanguageQueryVariables
  >(UserLanguageDocument, options);
}
export function useUserLanguageLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    UserLanguageQuery,
    UserLanguageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    UserLanguageQuery,
    UserLanguageQueryVariables
  >(UserLanguageDocument, options);
}
export type UserLanguageQueryHookResult = ReturnType<
  typeof useUserLanguageQuery
>;
export type UserLanguageLazyQueryHookResult = ReturnType<
  typeof useUserLanguageLazyQuery
>;
export type UserLanguageQueryResult = ApolloReactCommon.QueryResult<
  UserLanguageQuery,
  UserLanguageQueryVariables
>;
export function refetchUserLanguageQuery(
  variables?: UserLanguageQueryVariables
) {
  return { query: UserLanguageDocument, variables: variables };
}
export const UserMenuDataDocument = gql`
  query userMenuData {
    me {
      id
      playerStatus
      playerSlug
      venueId
    }
  }
`;

/**
 * __useUserMenuDataQuery__
 *
 * To run a query within a React component, call `useUserMenuDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserMenuDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserMenuDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserMenuDataQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    UserMenuDataQuery,
    UserMenuDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    UserMenuDataQuery,
    UserMenuDataQueryVariables
  >(UserMenuDataDocument, options);
}
export function useUserMenuDataLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    UserMenuDataQuery,
    UserMenuDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    UserMenuDataQuery,
    UserMenuDataQueryVariables
  >(UserMenuDataDocument, options);
}
export type UserMenuDataQueryHookResult = ReturnType<
  typeof useUserMenuDataQuery
>;
export type UserMenuDataLazyQueryHookResult = ReturnType<
  typeof useUserMenuDataLazyQuery
>;
export type UserMenuDataQueryResult = ApolloReactCommon.QueryResult<
  UserMenuDataQuery,
  UserMenuDataQueryVariables
>;
export function refetchUserMenuDataQuery(
  variables?: UserMenuDataQueryVariables
) {
  return { query: UserMenuDataDocument, variables: variables };
}
export const UserPlayerDataDocument = gql`
  query userPlayerData {
    me {
      id
      playerId
      playerStatus
    }
  }
`;

/**
 * __useUserPlayerDataQuery__
 *
 * To run a query within a React component, call `useUserPlayerDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserPlayerDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserPlayerDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserPlayerDataQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    UserPlayerDataQuery,
    UserPlayerDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    UserPlayerDataQuery,
    UserPlayerDataQueryVariables
  >(UserPlayerDataDocument, options);
}
export function useUserPlayerDataLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    UserPlayerDataQuery,
    UserPlayerDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    UserPlayerDataQuery,
    UserPlayerDataQueryVariables
  >(UserPlayerDataDocument, options);
}
export type UserPlayerDataQueryHookResult = ReturnType<
  typeof useUserPlayerDataQuery
>;
export type UserPlayerDataLazyQueryHookResult = ReturnType<
  typeof useUserPlayerDataLazyQuery
>;
export type UserPlayerDataQueryResult = ApolloReactCommon.QueryResult<
  UserPlayerDataQuery,
  UserPlayerDataQueryVariables
>;
export function refetchUserPlayerDataQuery(
  variables?: UserPlayerDataQueryVariables
) {
  return { query: UserPlayerDataDocument, variables: variables };
}
export const UserVenueIdDocument = gql`
  query userVenueId {
    me {
      id
      venueId
    }
  }
`;

/**
 * __useUserVenueIdQuery__
 *
 * To run a query within a React component, call `useUserVenueIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserVenueIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserVenueIdQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserVenueIdQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    UserVenueIdQuery,
    UserVenueIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<UserVenueIdQuery, UserVenueIdQueryVariables>(
    UserVenueIdDocument,
    options
  );
}
export function useUserVenueIdLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    UserVenueIdQuery,
    UserVenueIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    UserVenueIdQuery,
    UserVenueIdQueryVariables
  >(UserVenueIdDocument, options);
}
export type UserVenueIdQueryHookResult = ReturnType<typeof useUserVenueIdQuery>;
export type UserVenueIdLazyQueryHookResult = ReturnType<
  typeof useUserVenueIdLazyQuery
>;
export type UserVenueIdQueryResult = ApolloReactCommon.QueryResult<
  UserVenueIdQuery,
  UserVenueIdQueryVariables
>;
export function refetchUserVenueIdQuery(variables?: UserVenueIdQueryVariables) {
  return { query: UserVenueIdDocument, variables: variables };
}
export const UpdateUserLanguageDocument = gql`
  mutation updateUserLanguage($lang: String!) {
    updateUsers(_set: { language: $lang }, where: {}) {
      affected_rows
    }
  }
`;
export type UpdateUserLanguageMutationFn = ApolloReactCommon.MutationFunction<
  UpdateUserLanguageMutation,
  UpdateUserLanguageMutationVariables
>;

/**
 * __useUpdateUserLanguageMutation__
 *
 * To run a mutation, you first call `useUpdateUserLanguageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserLanguageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserLanguageMutation, { data, loading, error }] = useUpdateUserLanguageMutation({
 *   variables: {
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function useUpdateUserLanguageMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateUserLanguageMutation,
    UpdateUserLanguageMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UpdateUserLanguageMutation,
    UpdateUserLanguageMutationVariables
  >(UpdateUserLanguageDocument, options);
}
export type UpdateUserLanguageMutationHookResult = ReturnType<
  typeof useUpdateUserLanguageMutation
>;
export type UpdateUserLanguageMutationResult =
  ApolloReactCommon.MutationResult<UpdateUserLanguageMutation>;
export type UpdateUserLanguageMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    UpdateUserLanguageMutation,
    UpdateUserLanguageMutationVariables
  >;
export const LinkErc20toFireBaseSignatureDocument = gql`
  mutation linkERC20toFireBaseSignature($address: String!) {
    linkERC20toFireBaseSignature(address: $address) {
      nonce
    }
  }
`;
export type LinkErc20toFireBaseSignatureMutationFn =
  ApolloReactCommon.MutationFunction<
    LinkErc20toFireBaseSignatureMutation,
    LinkErc20toFireBaseSignatureMutationVariables
  >;

/**
 * __useLinkErc20toFireBaseSignatureMutation__
 *
 * To run a mutation, you first call `useLinkErc20toFireBaseSignatureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLinkErc20toFireBaseSignatureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [linkErc20toFireBaseSignatureMutation, { data, loading, error }] = useLinkErc20toFireBaseSignatureMutation({
 *   variables: {
 *      address: // value for 'address'
 *   },
 * });
 */
export function useLinkErc20toFireBaseSignatureMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    LinkErc20toFireBaseSignatureMutation,
    LinkErc20toFireBaseSignatureMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    LinkErc20toFireBaseSignatureMutation,
    LinkErc20toFireBaseSignatureMutationVariables
  >(LinkErc20toFireBaseSignatureDocument, options);
}
export type LinkErc20toFireBaseSignatureMutationHookResult = ReturnType<
  typeof useLinkErc20toFireBaseSignatureMutation
>;
export type LinkErc20toFireBaseSignatureMutationResult =
  ApolloReactCommon.MutationResult<LinkErc20toFireBaseSignatureMutation>;
export type LinkErc20toFireBaseSignatureMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    LinkErc20toFireBaseSignatureMutation,
    LinkErc20toFireBaseSignatureMutationVariables
  >;
export const LinkErc20toFireBaseAcceptDocument = gql`
  mutation linkERC20toFireBaseAccept($address: String!, $signature: String!) {
    linkERC20toFireBaseAccept(address: $address, signature: $signature) {
      status
      message
    }
  }
`;
export type LinkErc20toFireBaseAcceptMutationFn =
  ApolloReactCommon.MutationFunction<
    LinkErc20toFireBaseAcceptMutation,
    LinkErc20toFireBaseAcceptMutationVariables
  >;

/**
 * __useLinkErc20toFireBaseAcceptMutation__
 *
 * To run a mutation, you first call `useLinkErc20toFireBaseAcceptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLinkErc20toFireBaseAcceptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [linkErc20toFireBaseAcceptMutation, { data, loading, error }] = useLinkErc20toFireBaseAcceptMutation({
 *   variables: {
 *      address: // value for 'address'
 *      signature: // value for 'signature'
 *   },
 * });
 */
export function useLinkErc20toFireBaseAcceptMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    LinkErc20toFireBaseAcceptMutation,
    LinkErc20toFireBaseAcceptMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    LinkErc20toFireBaseAcceptMutation,
    LinkErc20toFireBaseAcceptMutationVariables
  >(LinkErc20toFireBaseAcceptDocument, options);
}
export type LinkErc20toFireBaseAcceptMutationHookResult = ReturnType<
  typeof useLinkErc20toFireBaseAcceptMutation
>;
export type LinkErc20toFireBaseAcceptMutationResult =
  ApolloReactCommon.MutationResult<LinkErc20toFireBaseAcceptMutation>;
export type LinkErc20toFireBaseAcceptMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    LinkErc20toFireBaseAcceptMutation,
    LinkErc20toFireBaseAcceptMutationVariables
  >;
export const SetEmailAddressDocument = gql`
  mutation setEmailAddress($email: String!) {
    setEmailaddress(email: $email) {
      status
      message
    }
  }
`;
export type SetEmailAddressMutationFn = ApolloReactCommon.MutationFunction<
  SetEmailAddressMutation,
  SetEmailAddressMutationVariables
>;

/**
 * __useSetEmailAddressMutation__
 *
 * To run a mutation, you first call `useSetEmailAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetEmailAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setEmailAddressMutation, { data, loading, error }] = useSetEmailAddressMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useSetEmailAddressMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SetEmailAddressMutation,
    SetEmailAddressMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    SetEmailAddressMutation,
    SetEmailAddressMutationVariables
  >(SetEmailAddressDocument, options);
}
export type SetEmailAddressMutationHookResult = ReturnType<
  typeof useSetEmailAddressMutation
>;
export type SetEmailAddressMutationResult =
  ApolloReactCommon.MutationResult<SetEmailAddressMutation>;
export type SetEmailAddressMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    SetEmailAddressMutation,
    SetEmailAddressMutationVariables
  >;
export const GetEnable2FaDataDocument = gql`
  mutation getEnable2FAData {
    activate2FA1 {
      status
      message
      codeUrl
      codeImage
      backupCode
    }
  }
`;
export type GetEnable2FaDataMutationFn = ApolloReactCommon.MutationFunction<
  GetEnable2FaDataMutation,
  GetEnable2FaDataMutationVariables
>;

/**
 * __useGetEnable2FaDataMutation__
 *
 * To run a mutation, you first call `useGetEnable2FaDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetEnable2FaDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getEnable2FaDataMutation, { data, loading, error }] = useGetEnable2FaDataMutation({
 *   variables: {
 *   },
 * });
 */
export function useGetEnable2FaDataMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    GetEnable2FaDataMutation,
    GetEnable2FaDataMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    GetEnable2FaDataMutation,
    GetEnable2FaDataMutationVariables
  >(GetEnable2FaDataDocument, options);
}
export type GetEnable2FaDataMutationHookResult = ReturnType<
  typeof useGetEnable2FaDataMutation
>;
export type GetEnable2FaDataMutationResult =
  ApolloReactCommon.MutationResult<GetEnable2FaDataMutation>;
export type GetEnable2FaDataMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    GetEnable2FaDataMutation,
    GetEnable2FaDataMutationVariables
  >;
export const Enable2FaDocument = gql`
  mutation enable2FA($code: String!) {
    activate2FA2(code: $code) {
      status
      message
    }
  }
`;
export type Enable2FaMutationFn = ApolloReactCommon.MutationFunction<
  Enable2FaMutation,
  Enable2FaMutationVariables
>;

/**
 * __useEnable2FaMutation__
 *
 * To run a mutation, you first call `useEnable2FaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnable2FaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enable2FaMutation, { data, loading, error }] = useEnable2FaMutation({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useEnable2FaMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Enable2FaMutation,
    Enable2FaMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    Enable2FaMutation,
    Enable2FaMutationVariables
  >(Enable2FaDocument, options);
}
export type Enable2FaMutationHookResult = ReturnType<
  typeof useEnable2FaMutation
>;
export type Enable2FaMutationResult =
  ApolloReactCommon.MutationResult<Enable2FaMutation>;
export type Enable2FaMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Enable2FaMutation,
  Enable2FaMutationVariables
>;
export const Disable2FaDocument = gql`
  mutation disable2FA($code: String!) {
    deactivate2FA(code: $code) {
      status
      message
    }
  }
`;
export type Disable2FaMutationFn = ApolloReactCommon.MutationFunction<
  Disable2FaMutation,
  Disable2FaMutationVariables
>;

/**
 * __useDisable2FaMutation__
 *
 * To run a mutation, you first call `useDisable2FaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisable2FaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disable2FaMutation, { data, loading, error }] = useDisable2FaMutation({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useDisable2FaMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Disable2FaMutation,
    Disable2FaMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    Disable2FaMutation,
    Disable2FaMutationVariables
  >(Disable2FaDocument, options);
}
export type Disable2FaMutationHookResult = ReturnType<
  typeof useDisable2FaMutation
>;
export type Disable2FaMutationResult =
  ApolloReactCommon.MutationResult<Disable2FaMutation>;
export type Disable2FaMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Disable2FaMutation,
  Disable2FaMutationVariables
>;
export const CancelUserShareDocument = gql`
  mutation cancelUserShare($id: Int!) {
    submitShareCancel(userShareBuyId: $id) {
      status
      message
    }
  }
`;
export type CancelUserShareMutationFn = ApolloReactCommon.MutationFunction<
  CancelUserShareMutation,
  CancelUserShareMutationVariables
>;

/**
 * __useCancelUserShareMutation__
 *
 * To run a mutation, you first call `useCancelUserShareMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelUserShareMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelUserShareMutation, { data, loading, error }] = useCancelUserShareMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCancelUserShareMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CancelUserShareMutation,
    CancelUserShareMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    CancelUserShareMutation,
    CancelUserShareMutationVariables
  >(CancelUserShareDocument, options);
}
export type CancelUserShareMutationHookResult = ReturnType<
  typeof useCancelUserShareMutation
>;
export type CancelUserShareMutationResult =
  ApolloReactCommon.MutationResult<CancelUserShareMutation>;
export type CancelUserShareMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    CancelUserShareMutation,
    CancelUserShareMutationVariables
  >;
export const VerifyEmailDocument = gql`
  mutation verifyEmail {
    sendVerifyMail {
      message
      status
    }
  }
`;
export type VerifyEmailMutationFn = ApolloReactCommon.MutationFunction<
  VerifyEmailMutation,
  VerifyEmailMutationVariables
>;

/**
 * __useVerifyEmailMutation__
 *
 * To run a mutation, you first call `useVerifyEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyEmailMutation, { data, loading, error }] = useVerifyEmailMutation({
 *   variables: {
 *   },
 * });
 */
export function useVerifyEmailMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    VerifyEmailMutation,
    VerifyEmailMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    VerifyEmailMutation,
    VerifyEmailMutationVariables
  >(VerifyEmailDocument, options);
}
export type VerifyEmailMutationHookResult = ReturnType<
  typeof useVerifyEmailMutation
>;
export type VerifyEmailMutationResult =
  ApolloReactCommon.MutationResult<VerifyEmailMutation>;
export type VerifyEmailMutationOptions = ApolloReactCommon.BaseMutationOptions<
  VerifyEmailMutation,
  VerifyEmailMutationVariables
>;
export const PublicVenuePageDocument = gql`
  query publicVenuePage($slug: String!) {
    venues(where: { slug: { _eq: $slug } }) {
      ...publicVenuePageData
      events(
        orderBy: { dateFrom: ASC, sort: ASC }
        where: {
          dateTill: { _gte: "now()" }
          tournaments: { player_shares: { status: { _in: [STARTED, ACTIVE] } } }
        }
      ) {
        ...commonEventData
        tournaments(
          orderBy: { sort: ASC, date: ASC }
          where: { status: { _in: [STARTED, ACTIVE] } }
        ) {
          ...tournamentPlayerShareCardsData
        }
      }
    }
  }
  ${PublicVenuePageDataFragmentDoc}
  ${CommonEventDataFragmentDoc}
  ${TournamentPlayerShareCardsDataFragmentDoc}
`;

/**
 * __usePublicVenuePageQuery__
 *
 * To run a query within a React component, call `usePublicVenuePageQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicVenuePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicVenuePageQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function usePublicVenuePageQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    PublicVenuePageQuery,
    PublicVenuePageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    PublicVenuePageQuery,
    PublicVenuePageQueryVariables
  >(PublicVenuePageDocument, options);
}
export function usePublicVenuePageLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    PublicVenuePageQuery,
    PublicVenuePageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    PublicVenuePageQuery,
    PublicVenuePageQueryVariables
  >(PublicVenuePageDocument, options);
}
export type PublicVenuePageQueryHookResult = ReturnType<
  typeof usePublicVenuePageQuery
>;
export type PublicVenuePageLazyQueryHookResult = ReturnType<
  typeof usePublicVenuePageLazyQuery
>;
export type PublicVenuePageQueryResult = ApolloReactCommon.QueryResult<
  PublicVenuePageQuery,
  PublicVenuePageQueryVariables
>;
export function refetchPublicVenuePageQuery(
  variables: PublicVenuePageQueryVariables
) {
  return { query: PublicVenuePageDocument, variables: variables };
}
export const VenueDocument = gql`
  query venue($id: uuid!) {
    venue(id: $id) {
      id
      name
      address
      website
    }
    events(
      where: {
        _or: [
          { venueId: { _eq: $id } }
          { tournaments: { venueId: { _eq: $id } } }
        ]
      }
    ) {
      ...eventListItemData
      tournaments {
        ...commonTournamentData
      }
    }
  }
  ${EventListItemDataFragmentDoc}
  ${CommonTournamentDataFragmentDoc}
`;

/**
 * __useVenueQuery__
 *
 * To run a query within a React component, call `useVenueQuery` and pass it any options that fit your needs.
 * When your component renders, `useVenueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVenueQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useVenueQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    VenueQuery,
    VenueQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<VenueQuery, VenueQueryVariables>(
    VenueDocument,
    options
  );
}
export function useVenueLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    VenueQuery,
    VenueQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<VenueQuery, VenueQueryVariables>(
    VenueDocument,
    options
  );
}
export type VenueQueryHookResult = ReturnType<typeof useVenueQuery>;
export type VenueLazyQueryHookResult = ReturnType<typeof useVenueLazyQuery>;
export type VenueQueryResult = ApolloReactCommon.QueryResult<
  VenueQuery,
  VenueQueryVariables
>;
export function refetchVenueQuery(variables: VenueQueryVariables) {
  return { query: VenueDocument, variables: variables };
}
export const FollowVenueDocument = gql`
  mutation followVenue($venueId: uuid!) {
    insertFollower(object: { venueId: $venueId }) {
      id
    }
  }
`;
export type FollowVenueMutationFn = ApolloReactCommon.MutationFunction<
  FollowVenueMutation,
  FollowVenueMutationVariables
>;

/**
 * __useFollowVenueMutation__
 *
 * To run a mutation, you first call `useFollowVenueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFollowVenueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [followVenueMutation, { data, loading, error }] = useFollowVenueMutation({
 *   variables: {
 *      venueId: // value for 'venueId'
 *   },
 * });
 */
export function useFollowVenueMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    FollowVenueMutation,
    FollowVenueMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    FollowVenueMutation,
    FollowVenueMutationVariables
  >(FollowVenueDocument, options);
}
export type FollowVenueMutationHookResult = ReturnType<
  typeof useFollowVenueMutation
>;
export type FollowVenueMutationResult =
  ApolloReactCommon.MutationResult<FollowVenueMutation>;
export type FollowVenueMutationOptions = ApolloReactCommon.BaseMutationOptions<
  FollowVenueMutation,
  FollowVenueMutationVariables
>;
export const UnFollowVenueDocument = gql`
  mutation unFollowVenue($venueId: uuid!) {
    deleteFollowers(where: { venueId: { _eq: $venueId } }) {
      affected_rows
    }
  }
`;
export type UnFollowVenueMutationFn = ApolloReactCommon.MutationFunction<
  UnFollowVenueMutation,
  UnFollowVenueMutationVariables
>;

/**
 * __useUnFollowVenueMutation__
 *
 * To run a mutation, you first call `useUnFollowVenueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnFollowVenueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unFollowVenueMutation, { data, loading, error }] = useUnFollowVenueMutation({
 *   variables: {
 *      venueId: // value for 'venueId'
 *   },
 * });
 */
export function useUnFollowVenueMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UnFollowVenueMutation,
    UnFollowVenueMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UnFollowVenueMutation,
    UnFollowVenueMutationVariables
  >(UnFollowVenueDocument, options);
}
export type UnFollowVenueMutationHookResult = ReturnType<
  typeof useUnFollowVenueMutation
>;
export type UnFollowVenueMutationResult =
  ApolloReactCommon.MutationResult<UnFollowVenueMutation>;
export type UnFollowVenueMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    UnFollowVenueMutation,
    UnFollowVenueMutationVariables
  >;
export type BlockchainCurrenciesKeySpecifier = (
  | 'blockchain'
  | 'blockchainId'
  | 'contract'
  | 'createdAt'
  | 'currency'
  | 'currencyId'
  | 'decimals'
  | 'deposit'
  | 'icon'
  | 'id'
  | 'name'
  | 'symbol'
  | 'units'
  | 'updatedAt'
  | 'value'
  | 'withdraw'
  | 'withdrawFee'
  | 'withdrawMin'
  | BlockchainCurrenciesKeySpecifier
)[];
export type BlockchainCurrenciesFieldPolicy = {
  blockchain?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchainId?: FieldPolicy<any> | FieldReadFunction<any>;
  contract?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  currency?: FieldPolicy<any> | FieldReadFunction<any>;
  currencyId?: FieldPolicy<any> | FieldReadFunction<any>;
  decimals?: FieldPolicy<any> | FieldReadFunction<any>;
  deposit?: FieldPolicy<any> | FieldReadFunction<any>;
  icon?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  symbol?: FieldPolicy<any> | FieldReadFunction<any>;
  units?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  value?: FieldPolicy<any> | FieldReadFunction<any>;
  withdraw?: FieldPolicy<any> | FieldReadFunction<any>;
  withdrawFee?: FieldPolicy<any> | FieldReadFunction<any>;
  withdrawMin?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type BlockchainCurrenciesAggregateKeySpecifier = (
  | 'aggregate'
  | 'nodes'
  | BlockchainCurrenciesAggregateKeySpecifier
)[];
export type BlockchainCurrenciesAggregateFieldPolicy = {
  aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type BlockchainCurrenciesAggregateFieldsKeySpecifier = (
  | 'avg'
  | 'count'
  | 'max'
  | 'min'
  | 'stddev'
  | 'stddevPop'
  | 'stddevSamp'
  | 'sum'
  | 'varPop'
  | 'varSamp'
  | 'variance'
  | BlockchainCurrenciesAggregateFieldsKeySpecifier
)[];
export type BlockchainCurrenciesAggregateFieldsFieldPolicy = {
  avg?: FieldPolicy<any> | FieldReadFunction<any>;
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  max?: FieldPolicy<any> | FieldReadFunction<any>;
  min?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev?: FieldPolicy<any> | FieldReadFunction<any>;
  stddevPop?: FieldPolicy<any> | FieldReadFunction<any>;
  stddevSamp?: FieldPolicy<any> | FieldReadFunction<any>;
  sum?: FieldPolicy<any> | FieldReadFunction<any>;
  varPop?: FieldPolicy<any> | FieldReadFunction<any>;
  varSamp?: FieldPolicy<any> | FieldReadFunction<any>;
  variance?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type BlockchainCurrenciesAvgFieldsKeySpecifier = (
  | 'blockchainId'
  | 'currencyId'
  | 'decimals'
  | 'id'
  | 'value'
  | 'withdrawFee'
  | 'withdrawMin'
  | BlockchainCurrenciesAvgFieldsKeySpecifier
)[];
export type BlockchainCurrenciesAvgFieldsFieldPolicy = {
  blockchainId?: FieldPolicy<any> | FieldReadFunction<any>;
  currencyId?: FieldPolicy<any> | FieldReadFunction<any>;
  decimals?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  value?: FieldPolicy<any> | FieldReadFunction<any>;
  withdrawFee?: FieldPolicy<any> | FieldReadFunction<any>;
  withdrawMin?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type BlockchainCurrenciesMaxFieldsKeySpecifier = (
  | 'blockchainId'
  | 'contract'
  | 'createdAt'
  | 'currencyId'
  | 'decimals'
  | 'icon'
  | 'id'
  | 'name'
  | 'symbol'
  | 'units'
  | 'updatedAt'
  | 'value'
  | 'withdrawFee'
  | 'withdrawMin'
  | BlockchainCurrenciesMaxFieldsKeySpecifier
)[];
export type BlockchainCurrenciesMaxFieldsFieldPolicy = {
  blockchainId?: FieldPolicy<any> | FieldReadFunction<any>;
  contract?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  currencyId?: FieldPolicy<any> | FieldReadFunction<any>;
  decimals?: FieldPolicy<any> | FieldReadFunction<any>;
  icon?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  symbol?: FieldPolicy<any> | FieldReadFunction<any>;
  units?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  value?: FieldPolicy<any> | FieldReadFunction<any>;
  withdrawFee?: FieldPolicy<any> | FieldReadFunction<any>;
  withdrawMin?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type BlockchainCurrenciesMinFieldsKeySpecifier = (
  | 'blockchainId'
  | 'contract'
  | 'createdAt'
  | 'currencyId'
  | 'decimals'
  | 'icon'
  | 'id'
  | 'name'
  | 'symbol'
  | 'units'
  | 'updatedAt'
  | 'value'
  | 'withdrawFee'
  | 'withdrawMin'
  | BlockchainCurrenciesMinFieldsKeySpecifier
)[];
export type BlockchainCurrenciesMinFieldsFieldPolicy = {
  blockchainId?: FieldPolicy<any> | FieldReadFunction<any>;
  contract?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  currencyId?: FieldPolicy<any> | FieldReadFunction<any>;
  decimals?: FieldPolicy<any> | FieldReadFunction<any>;
  icon?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  symbol?: FieldPolicy<any> | FieldReadFunction<any>;
  units?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  value?: FieldPolicy<any> | FieldReadFunction<any>;
  withdrawFee?: FieldPolicy<any> | FieldReadFunction<any>;
  withdrawMin?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type BlockchainCurrenciesMutationResponseKeySpecifier = (
  | 'affected_rows'
  | 'returning'
  | BlockchainCurrenciesMutationResponseKeySpecifier
)[];
export type BlockchainCurrenciesMutationResponseFieldPolicy = {
  affected_rows?: FieldPolicy<any> | FieldReadFunction<any>;
  returning?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type BlockchainCurrenciesStddevFieldsKeySpecifier = (
  | 'blockchainId'
  | 'currencyId'
  | 'decimals'
  | 'id'
  | 'value'
  | 'withdrawFee'
  | 'withdrawMin'
  | BlockchainCurrenciesStddevFieldsKeySpecifier
)[];
export type BlockchainCurrenciesStddevFieldsFieldPolicy = {
  blockchainId?: FieldPolicy<any> | FieldReadFunction<any>;
  currencyId?: FieldPolicy<any> | FieldReadFunction<any>;
  decimals?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  value?: FieldPolicy<any> | FieldReadFunction<any>;
  withdrawFee?: FieldPolicy<any> | FieldReadFunction<any>;
  withdrawMin?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type BlockchainCurrenciesStddev_popFieldsKeySpecifier = (
  | 'blockchainId'
  | 'currencyId'
  | 'decimals'
  | 'id'
  | 'value'
  | 'withdrawFee'
  | 'withdrawMin'
  | BlockchainCurrenciesStddev_popFieldsKeySpecifier
)[];
export type BlockchainCurrenciesStddev_popFieldsFieldPolicy = {
  blockchainId?: FieldPolicy<any> | FieldReadFunction<any>;
  currencyId?: FieldPolicy<any> | FieldReadFunction<any>;
  decimals?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  value?: FieldPolicy<any> | FieldReadFunction<any>;
  withdrawFee?: FieldPolicy<any> | FieldReadFunction<any>;
  withdrawMin?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type BlockchainCurrenciesStddev_sampFieldsKeySpecifier = (
  | 'blockchainId'
  | 'currencyId'
  | 'decimals'
  | 'id'
  | 'value'
  | 'withdrawFee'
  | 'withdrawMin'
  | BlockchainCurrenciesStddev_sampFieldsKeySpecifier
)[];
export type BlockchainCurrenciesStddev_sampFieldsFieldPolicy = {
  blockchainId?: FieldPolicy<any> | FieldReadFunction<any>;
  currencyId?: FieldPolicy<any> | FieldReadFunction<any>;
  decimals?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  value?: FieldPolicy<any> | FieldReadFunction<any>;
  withdrawFee?: FieldPolicy<any> | FieldReadFunction<any>;
  withdrawMin?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type BlockchainCurrenciesSumFieldsKeySpecifier = (
  | 'blockchainId'
  | 'currencyId'
  | 'decimals'
  | 'id'
  | 'value'
  | 'withdrawFee'
  | 'withdrawMin'
  | BlockchainCurrenciesSumFieldsKeySpecifier
)[];
export type BlockchainCurrenciesSumFieldsFieldPolicy = {
  blockchainId?: FieldPolicy<any> | FieldReadFunction<any>;
  currencyId?: FieldPolicy<any> | FieldReadFunction<any>;
  decimals?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  value?: FieldPolicy<any> | FieldReadFunction<any>;
  withdrawFee?: FieldPolicy<any> | FieldReadFunction<any>;
  withdrawMin?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type BlockchainCurrenciesVar_popFieldsKeySpecifier = (
  | 'blockchainId'
  | 'currencyId'
  | 'decimals'
  | 'id'
  | 'value'
  | 'withdrawFee'
  | 'withdrawMin'
  | BlockchainCurrenciesVar_popFieldsKeySpecifier
)[];
export type BlockchainCurrenciesVar_popFieldsFieldPolicy = {
  blockchainId?: FieldPolicy<any> | FieldReadFunction<any>;
  currencyId?: FieldPolicy<any> | FieldReadFunction<any>;
  decimals?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  value?: FieldPolicy<any> | FieldReadFunction<any>;
  withdrawFee?: FieldPolicy<any> | FieldReadFunction<any>;
  withdrawMin?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type BlockchainCurrenciesVar_sampFieldsKeySpecifier = (
  | 'blockchainId'
  | 'currencyId'
  | 'decimals'
  | 'id'
  | 'value'
  | 'withdrawFee'
  | 'withdrawMin'
  | BlockchainCurrenciesVar_sampFieldsKeySpecifier
)[];
export type BlockchainCurrenciesVar_sampFieldsFieldPolicy = {
  blockchainId?: FieldPolicy<any> | FieldReadFunction<any>;
  currencyId?: FieldPolicy<any> | FieldReadFunction<any>;
  decimals?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  value?: FieldPolicy<any> | FieldReadFunction<any>;
  withdrawFee?: FieldPolicy<any> | FieldReadFunction<any>;
  withdrawMin?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type BlockchainCurrenciesVarianceFieldsKeySpecifier = (
  | 'blockchainId'
  | 'currencyId'
  | 'decimals'
  | 'id'
  | 'value'
  | 'withdrawFee'
  | 'withdrawMin'
  | BlockchainCurrenciesVarianceFieldsKeySpecifier
)[];
export type BlockchainCurrenciesVarianceFieldsFieldPolicy = {
  blockchainId?: FieldPolicy<any> | FieldReadFunction<any>;
  currencyId?: FieldPolicy<any> | FieldReadFunction<any>;
  decimals?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  value?: FieldPolicy<any> | FieldReadFunction<any>;
  withdrawFee?: FieldPolicy<any> | FieldReadFunction<any>;
  withdrawMin?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type BlockchainsKeySpecifier = (
  | 'active'
  | 'alchemy'
  | 'apiKey'
  | 'apiUrl'
  | 'apiUrlProxy'
  | 'blockchainCurrenciesAggregate'
  | 'blockchain_currencies'
  | 'chainId'
  | 'createdAt'
  | 'iconUrl'
  | 'id'
  | 'name'
  | 'nativeCurrency'
  | 'rpc'
  | 'rpcPublic'
  | 'scanUrl'
  | 'symbol'
  | 'updatedAt'
  | BlockchainsKeySpecifier
)[];
export type BlockchainsFieldPolicy = {
  active?: FieldPolicy<any> | FieldReadFunction<any>;
  alchemy?: FieldPolicy<any> | FieldReadFunction<any>;
  apiKey?: FieldPolicy<any> | FieldReadFunction<any>;
  apiUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  apiUrlProxy?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchainCurrenciesAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchain_currencies?: FieldPolicy<any> | FieldReadFunction<any>;
  chainId?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  iconUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  nativeCurrency?: FieldPolicy<any> | FieldReadFunction<any>;
  rpc?: FieldPolicy<any> | FieldReadFunction<any>;
  rpcPublic?: FieldPolicy<any> | FieldReadFunction<any>;
  scanUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  symbol?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type BlockchainsAggregateKeySpecifier = (
  | 'aggregate'
  | 'nodes'
  | BlockchainsAggregateKeySpecifier
)[];
export type BlockchainsAggregateFieldPolicy = {
  aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type BlockchainsAggregateFieldsKeySpecifier = (
  | 'avg'
  | 'count'
  | 'max'
  | 'min'
  | 'stddev'
  | 'stddevPop'
  | 'stddevSamp'
  | 'sum'
  | 'varPop'
  | 'varSamp'
  | 'variance'
  | BlockchainsAggregateFieldsKeySpecifier
)[];
export type BlockchainsAggregateFieldsFieldPolicy = {
  avg?: FieldPolicy<any> | FieldReadFunction<any>;
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  max?: FieldPolicy<any> | FieldReadFunction<any>;
  min?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev?: FieldPolicy<any> | FieldReadFunction<any>;
  stddevPop?: FieldPolicy<any> | FieldReadFunction<any>;
  stddevSamp?: FieldPolicy<any> | FieldReadFunction<any>;
  sum?: FieldPolicy<any> | FieldReadFunction<any>;
  varPop?: FieldPolicy<any> | FieldReadFunction<any>;
  varSamp?: FieldPolicy<any> | FieldReadFunction<any>;
  variance?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type BlockchainsAvgFieldsKeySpecifier = (
  | 'chainId'
  | 'id'
  | BlockchainsAvgFieldsKeySpecifier
)[];
export type BlockchainsAvgFieldsFieldPolicy = {
  chainId?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type BlockchainsMaxFieldsKeySpecifier = (
  | 'alchemy'
  | 'apiKey'
  | 'apiUrl'
  | 'apiUrlProxy'
  | 'chainId'
  | 'createdAt'
  | 'iconUrl'
  | 'id'
  | 'name'
  | 'scanUrl'
  | 'symbol'
  | 'updatedAt'
  | BlockchainsMaxFieldsKeySpecifier
)[];
export type BlockchainsMaxFieldsFieldPolicy = {
  alchemy?: FieldPolicy<any> | FieldReadFunction<any>;
  apiKey?: FieldPolicy<any> | FieldReadFunction<any>;
  apiUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  apiUrlProxy?: FieldPolicy<any> | FieldReadFunction<any>;
  chainId?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  iconUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  scanUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  symbol?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type BlockchainsMinFieldsKeySpecifier = (
  | 'alchemy'
  | 'apiKey'
  | 'apiUrl'
  | 'apiUrlProxy'
  | 'chainId'
  | 'createdAt'
  | 'iconUrl'
  | 'id'
  | 'name'
  | 'scanUrl'
  | 'symbol'
  | 'updatedAt'
  | BlockchainsMinFieldsKeySpecifier
)[];
export type BlockchainsMinFieldsFieldPolicy = {
  alchemy?: FieldPolicy<any> | FieldReadFunction<any>;
  apiKey?: FieldPolicy<any> | FieldReadFunction<any>;
  apiUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  apiUrlProxy?: FieldPolicy<any> | FieldReadFunction<any>;
  chainId?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  iconUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  scanUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  symbol?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type BlockchainsMutationResponseKeySpecifier = (
  | 'affected_rows'
  | 'returning'
  | BlockchainsMutationResponseKeySpecifier
)[];
export type BlockchainsMutationResponseFieldPolicy = {
  affected_rows?: FieldPolicy<any> | FieldReadFunction<any>;
  returning?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type BlockchainsStddevFieldsKeySpecifier = (
  | 'chainId'
  | 'id'
  | BlockchainsStddevFieldsKeySpecifier
)[];
export type BlockchainsStddevFieldsFieldPolicy = {
  chainId?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type BlockchainsStddev_popFieldsKeySpecifier = (
  | 'chainId'
  | 'id'
  | BlockchainsStddev_popFieldsKeySpecifier
)[];
export type BlockchainsStddev_popFieldsFieldPolicy = {
  chainId?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type BlockchainsStddev_sampFieldsKeySpecifier = (
  | 'chainId'
  | 'id'
  | BlockchainsStddev_sampFieldsKeySpecifier
)[];
export type BlockchainsStddev_sampFieldsFieldPolicy = {
  chainId?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type BlockchainsSumFieldsKeySpecifier = (
  | 'chainId'
  | 'id'
  | BlockchainsSumFieldsKeySpecifier
)[];
export type BlockchainsSumFieldsFieldPolicy = {
  chainId?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type BlockchainsVar_popFieldsKeySpecifier = (
  | 'chainId'
  | 'id'
  | BlockchainsVar_popFieldsKeySpecifier
)[];
export type BlockchainsVar_popFieldsFieldPolicy = {
  chainId?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type BlockchainsVar_sampFieldsKeySpecifier = (
  | 'chainId'
  | 'id'
  | BlockchainsVar_sampFieldsKeySpecifier
)[];
export type BlockchainsVar_sampFieldsFieldPolicy = {
  chainId?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type BlockchainsVarianceFieldsKeySpecifier = (
  | 'chainId'
  | 'id'
  | BlockchainsVarianceFieldsKeySpecifier
)[];
export type BlockchainsVarianceFieldsFieldPolicy = {
  chainId?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CountriesKeySpecifier = (
  | 'createdAt'
  | 'currencies'
  | 'currenciesAggregate'
  | 'id'
  | 'language'
  | 'name'
  | 'paymentMethodBanksAggregate'
  | 'payment_method_banks'
  | 'updatedAt'
  | CountriesKeySpecifier
)[];
export type CountriesFieldPolicy = {
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  currencies?: FieldPolicy<any> | FieldReadFunction<any>;
  currenciesAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  language?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  paymentMethodBanksAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  payment_method_banks?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CountriesAggregateKeySpecifier = (
  | 'aggregate'
  | 'nodes'
  | CountriesAggregateKeySpecifier
)[];
export type CountriesAggregateFieldPolicy = {
  aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CountriesAggregateFieldsKeySpecifier = (
  | 'avg'
  | 'count'
  | 'max'
  | 'min'
  | 'stddev'
  | 'stddevPop'
  | 'stddevSamp'
  | 'sum'
  | 'varPop'
  | 'varSamp'
  | 'variance'
  | CountriesAggregateFieldsKeySpecifier
)[];
export type CountriesAggregateFieldsFieldPolicy = {
  avg?: FieldPolicy<any> | FieldReadFunction<any>;
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  max?: FieldPolicy<any> | FieldReadFunction<any>;
  min?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev?: FieldPolicy<any> | FieldReadFunction<any>;
  stddevPop?: FieldPolicy<any> | FieldReadFunction<any>;
  stddevSamp?: FieldPolicy<any> | FieldReadFunction<any>;
  sum?: FieldPolicy<any> | FieldReadFunction<any>;
  varPop?: FieldPolicy<any> | FieldReadFunction<any>;
  varSamp?: FieldPolicy<any> | FieldReadFunction<any>;
  variance?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CountriesAvgFieldsKeySpecifier = (
  | 'id'
  | CountriesAvgFieldsKeySpecifier
)[];
export type CountriesAvgFieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CountriesMaxFieldsKeySpecifier = (
  | 'createdAt'
  | 'id'
  | 'language'
  | 'name'
  | 'updatedAt'
  | CountriesMaxFieldsKeySpecifier
)[];
export type CountriesMaxFieldsFieldPolicy = {
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  language?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CountriesMinFieldsKeySpecifier = (
  | 'createdAt'
  | 'id'
  | 'language'
  | 'name'
  | 'updatedAt'
  | CountriesMinFieldsKeySpecifier
)[];
export type CountriesMinFieldsFieldPolicy = {
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  language?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CountriesMutationResponseKeySpecifier = (
  | 'affected_rows'
  | 'returning'
  | CountriesMutationResponseKeySpecifier
)[];
export type CountriesMutationResponseFieldPolicy = {
  affected_rows?: FieldPolicy<any> | FieldReadFunction<any>;
  returning?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CountriesStddevFieldsKeySpecifier = (
  | 'id'
  | CountriesStddevFieldsKeySpecifier
)[];
export type CountriesStddevFieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CountriesStddev_popFieldsKeySpecifier = (
  | 'id'
  | CountriesStddev_popFieldsKeySpecifier
)[];
export type CountriesStddev_popFieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CountriesStddev_sampFieldsKeySpecifier = (
  | 'id'
  | CountriesStddev_sampFieldsKeySpecifier
)[];
export type CountriesStddev_sampFieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CountriesSumFieldsKeySpecifier = (
  | 'id'
  | CountriesSumFieldsKeySpecifier
)[];
export type CountriesSumFieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CountriesVar_popFieldsKeySpecifier = (
  | 'id'
  | CountriesVar_popFieldsKeySpecifier
)[];
export type CountriesVar_popFieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CountriesVar_sampFieldsKeySpecifier = (
  | 'id'
  | CountriesVar_sampFieldsKeySpecifier
)[];
export type CountriesVar_sampFieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CountriesVarianceFieldsKeySpecifier = (
  | 'id'
  | CountriesVarianceFieldsKeySpecifier
)[];
export type CountriesVarianceFieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CurrenciesKeySpecifier = (
  | 'active'
  | 'blockchainCurrenciesAggregate'
  | 'blockchain_currencies'
  | 'country'
  | 'countryId'
  | 'createdAt'
  | 'decimals'
  | 'icon'
  | 'id'
  | 'name'
  | 'rate'
  | 'rounding'
  | 'symbol'
  | 'updatedAt'
  | CurrenciesKeySpecifier
)[];
export type CurrenciesFieldPolicy = {
  active?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchainCurrenciesAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchain_currencies?: FieldPolicy<any> | FieldReadFunction<any>;
  country?: FieldPolicy<any> | FieldReadFunction<any>;
  countryId?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  decimals?: FieldPolicy<any> | FieldReadFunction<any>;
  icon?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  rate?: FieldPolicy<any> | FieldReadFunction<any>;
  rounding?: FieldPolicy<any> | FieldReadFunction<any>;
  symbol?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CurrenciesAggregateKeySpecifier = (
  | 'aggregate'
  | 'nodes'
  | CurrenciesAggregateKeySpecifier
)[];
export type CurrenciesAggregateFieldPolicy = {
  aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CurrenciesAggregateFieldsKeySpecifier = (
  | 'avg'
  | 'count'
  | 'max'
  | 'min'
  | 'stddev'
  | 'stddevPop'
  | 'stddevSamp'
  | 'sum'
  | 'varPop'
  | 'varSamp'
  | 'variance'
  | CurrenciesAggregateFieldsKeySpecifier
)[];
export type CurrenciesAggregateFieldsFieldPolicy = {
  avg?: FieldPolicy<any> | FieldReadFunction<any>;
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  max?: FieldPolicy<any> | FieldReadFunction<any>;
  min?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev?: FieldPolicy<any> | FieldReadFunction<any>;
  stddevPop?: FieldPolicy<any> | FieldReadFunction<any>;
  stddevSamp?: FieldPolicy<any> | FieldReadFunction<any>;
  sum?: FieldPolicy<any> | FieldReadFunction<any>;
  varPop?: FieldPolicy<any> | FieldReadFunction<any>;
  varSamp?: FieldPolicy<any> | FieldReadFunction<any>;
  variance?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CurrenciesAvgFieldsKeySpecifier = (
  | 'countryId'
  | 'decimals'
  | 'id'
  | 'rate'
  | 'rounding'
  | CurrenciesAvgFieldsKeySpecifier
)[];
export type CurrenciesAvgFieldsFieldPolicy = {
  countryId?: FieldPolicy<any> | FieldReadFunction<any>;
  decimals?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  rate?: FieldPolicy<any> | FieldReadFunction<any>;
  rounding?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CurrenciesMaxFieldsKeySpecifier = (
  | 'countryId'
  | 'createdAt'
  | 'decimals'
  | 'icon'
  | 'id'
  | 'name'
  | 'rate'
  | 'rounding'
  | 'symbol'
  | 'updatedAt'
  | CurrenciesMaxFieldsKeySpecifier
)[];
export type CurrenciesMaxFieldsFieldPolicy = {
  countryId?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  decimals?: FieldPolicy<any> | FieldReadFunction<any>;
  icon?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  rate?: FieldPolicy<any> | FieldReadFunction<any>;
  rounding?: FieldPolicy<any> | FieldReadFunction<any>;
  symbol?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CurrenciesMinFieldsKeySpecifier = (
  | 'countryId'
  | 'createdAt'
  | 'decimals'
  | 'icon'
  | 'id'
  | 'name'
  | 'rate'
  | 'rounding'
  | 'symbol'
  | 'updatedAt'
  | CurrenciesMinFieldsKeySpecifier
)[];
export type CurrenciesMinFieldsFieldPolicy = {
  countryId?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  decimals?: FieldPolicy<any> | FieldReadFunction<any>;
  icon?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  rate?: FieldPolicy<any> | FieldReadFunction<any>;
  rounding?: FieldPolicy<any> | FieldReadFunction<any>;
  symbol?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CurrenciesMutationResponseKeySpecifier = (
  | 'affected_rows'
  | 'returning'
  | CurrenciesMutationResponseKeySpecifier
)[];
export type CurrenciesMutationResponseFieldPolicy = {
  affected_rows?: FieldPolicy<any> | FieldReadFunction<any>;
  returning?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CurrenciesStddevFieldsKeySpecifier = (
  | 'countryId'
  | 'decimals'
  | 'id'
  | 'rate'
  | 'rounding'
  | CurrenciesStddevFieldsKeySpecifier
)[];
export type CurrenciesStddevFieldsFieldPolicy = {
  countryId?: FieldPolicy<any> | FieldReadFunction<any>;
  decimals?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  rate?: FieldPolicy<any> | FieldReadFunction<any>;
  rounding?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CurrenciesStddev_popFieldsKeySpecifier = (
  | 'countryId'
  | 'decimals'
  | 'id'
  | 'rate'
  | 'rounding'
  | CurrenciesStddev_popFieldsKeySpecifier
)[];
export type CurrenciesStddev_popFieldsFieldPolicy = {
  countryId?: FieldPolicy<any> | FieldReadFunction<any>;
  decimals?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  rate?: FieldPolicy<any> | FieldReadFunction<any>;
  rounding?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CurrenciesStddev_sampFieldsKeySpecifier = (
  | 'countryId'
  | 'decimals'
  | 'id'
  | 'rate'
  | 'rounding'
  | CurrenciesStddev_sampFieldsKeySpecifier
)[];
export type CurrenciesStddev_sampFieldsFieldPolicy = {
  countryId?: FieldPolicy<any> | FieldReadFunction<any>;
  decimals?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  rate?: FieldPolicy<any> | FieldReadFunction<any>;
  rounding?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CurrenciesSumFieldsKeySpecifier = (
  | 'countryId'
  | 'decimals'
  | 'id'
  | 'rate'
  | 'rounding'
  | CurrenciesSumFieldsKeySpecifier
)[];
export type CurrenciesSumFieldsFieldPolicy = {
  countryId?: FieldPolicy<any> | FieldReadFunction<any>;
  decimals?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  rate?: FieldPolicy<any> | FieldReadFunction<any>;
  rounding?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CurrenciesVar_popFieldsKeySpecifier = (
  | 'countryId'
  | 'decimals'
  | 'id'
  | 'rate'
  | 'rounding'
  | CurrenciesVar_popFieldsKeySpecifier
)[];
export type CurrenciesVar_popFieldsFieldPolicy = {
  countryId?: FieldPolicy<any> | FieldReadFunction<any>;
  decimals?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  rate?: FieldPolicy<any> | FieldReadFunction<any>;
  rounding?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CurrenciesVar_sampFieldsKeySpecifier = (
  | 'countryId'
  | 'decimals'
  | 'id'
  | 'rate'
  | 'rounding'
  | CurrenciesVar_sampFieldsKeySpecifier
)[];
export type CurrenciesVar_sampFieldsFieldPolicy = {
  countryId?: FieldPolicy<any> | FieldReadFunction<any>;
  decimals?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  rate?: FieldPolicy<any> | FieldReadFunction<any>;
  rounding?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CurrenciesVarianceFieldsKeySpecifier = (
  | 'countryId'
  | 'decimals'
  | 'id'
  | 'rate'
  | 'rounding'
  | CurrenciesVarianceFieldsKeySpecifier
)[];
export type CurrenciesVarianceFieldsFieldPolicy = {
  countryId?: FieldPolicy<any> | FieldReadFunction<any>;
  decimals?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  rate?: FieldPolicy<any> | FieldReadFunction<any>;
  rounding?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type DepositOutputKeySpecifier = (
  | 'depositAddress'
  | 'id'
  | 'message'
  | 'status'
  | DepositOutputKeySpecifier
)[];
export type DepositOutputFieldPolicy = {
  depositAddress?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  message?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type EventsKeySpecifier = (
  | 'createdAt'
  | 'dateFrom'
  | 'dateTill'
  | 'description'
  | 'followers'
  | 'followersAggregate'
  | 'followersCount'
  | 'id'
  | 'image'
  | 'location'
  | 'name'
  | 'organiser'
  | 'organiserId'
  | 'slug'
  | 'sort'
  | 'status'
  | 'tournaments'
  | 'tournamentsAggregate'
  | 'updatedAt'
  | 'venue'
  | 'venueId'
  | EventsKeySpecifier
)[];
export type EventsFieldPolicy = {
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  dateFrom?: FieldPolicy<any> | FieldReadFunction<any>;
  dateTill?: FieldPolicy<any> | FieldReadFunction<any>;
  description?: FieldPolicy<any> | FieldReadFunction<any>;
  followers?: FieldPolicy<any> | FieldReadFunction<any>;
  followersAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  followersCount?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  image?: FieldPolicy<any> | FieldReadFunction<any>;
  location?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  organiser?: FieldPolicy<any> | FieldReadFunction<any>;
  organiserId?: FieldPolicy<any> | FieldReadFunction<any>;
  slug?: FieldPolicy<any> | FieldReadFunction<any>;
  sort?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  tournaments?: FieldPolicy<any> | FieldReadFunction<any>;
  tournamentsAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  venue?: FieldPolicy<any> | FieldReadFunction<any>;
  venueId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type EventsAggregateKeySpecifier = (
  | 'aggregate'
  | 'nodes'
  | EventsAggregateKeySpecifier
)[];
export type EventsAggregateFieldPolicy = {
  aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type EventsAggregateFieldsKeySpecifier = (
  | 'avg'
  | 'count'
  | 'max'
  | 'min'
  | 'stddev'
  | 'stddevPop'
  | 'stddevSamp'
  | 'sum'
  | 'varPop'
  | 'varSamp'
  | 'variance'
  | EventsAggregateFieldsKeySpecifier
)[];
export type EventsAggregateFieldsFieldPolicy = {
  avg?: FieldPolicy<any> | FieldReadFunction<any>;
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  max?: FieldPolicy<any> | FieldReadFunction<any>;
  min?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev?: FieldPolicy<any> | FieldReadFunction<any>;
  stddevPop?: FieldPolicy<any> | FieldReadFunction<any>;
  stddevSamp?: FieldPolicy<any> | FieldReadFunction<any>;
  sum?: FieldPolicy<any> | FieldReadFunction<any>;
  varPop?: FieldPolicy<any> | FieldReadFunction<any>;
  varSamp?: FieldPolicy<any> | FieldReadFunction<any>;
  variance?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type EventsAvgFieldsKeySpecifier = (
  | 'followersCount'
  | 'sort'
  | EventsAvgFieldsKeySpecifier
)[];
export type EventsAvgFieldsFieldPolicy = {
  followersCount?: FieldPolicy<any> | FieldReadFunction<any>;
  sort?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type EventsMaxFieldsKeySpecifier = (
  | 'createdAt'
  | 'dateFrom'
  | 'dateTill'
  | 'description'
  | 'followersCount'
  | 'id'
  | 'image'
  | 'location'
  | 'name'
  | 'organiserId'
  | 'slug'
  | 'sort'
  | 'updatedAt'
  | 'venueId'
  | EventsMaxFieldsKeySpecifier
)[];
export type EventsMaxFieldsFieldPolicy = {
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  dateFrom?: FieldPolicy<any> | FieldReadFunction<any>;
  dateTill?: FieldPolicy<any> | FieldReadFunction<any>;
  description?: FieldPolicy<any> | FieldReadFunction<any>;
  followersCount?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  image?: FieldPolicy<any> | FieldReadFunction<any>;
  location?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  organiserId?: FieldPolicy<any> | FieldReadFunction<any>;
  slug?: FieldPolicy<any> | FieldReadFunction<any>;
  sort?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  venueId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type EventsMinFieldsKeySpecifier = (
  | 'createdAt'
  | 'dateFrom'
  | 'dateTill'
  | 'description'
  | 'followersCount'
  | 'id'
  | 'image'
  | 'location'
  | 'name'
  | 'organiserId'
  | 'slug'
  | 'sort'
  | 'updatedAt'
  | 'venueId'
  | EventsMinFieldsKeySpecifier
)[];
export type EventsMinFieldsFieldPolicy = {
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  dateFrom?: FieldPolicy<any> | FieldReadFunction<any>;
  dateTill?: FieldPolicy<any> | FieldReadFunction<any>;
  description?: FieldPolicy<any> | FieldReadFunction<any>;
  followersCount?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  image?: FieldPolicy<any> | FieldReadFunction<any>;
  location?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  organiserId?: FieldPolicy<any> | FieldReadFunction<any>;
  slug?: FieldPolicy<any> | FieldReadFunction<any>;
  sort?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  venueId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type EventsMutationResponseKeySpecifier = (
  | 'affected_rows'
  | 'returning'
  | EventsMutationResponseKeySpecifier
)[];
export type EventsMutationResponseFieldPolicy = {
  affected_rows?: FieldPolicy<any> | FieldReadFunction<any>;
  returning?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type EventsStddevFieldsKeySpecifier = (
  | 'followersCount'
  | 'sort'
  | EventsStddevFieldsKeySpecifier
)[];
export type EventsStddevFieldsFieldPolicy = {
  followersCount?: FieldPolicy<any> | FieldReadFunction<any>;
  sort?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type EventsStddev_popFieldsKeySpecifier = (
  | 'followersCount'
  | 'sort'
  | EventsStddev_popFieldsKeySpecifier
)[];
export type EventsStddev_popFieldsFieldPolicy = {
  followersCount?: FieldPolicy<any> | FieldReadFunction<any>;
  sort?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type EventsStddev_sampFieldsKeySpecifier = (
  | 'followersCount'
  | 'sort'
  | EventsStddev_sampFieldsKeySpecifier
)[];
export type EventsStddev_sampFieldsFieldPolicy = {
  followersCount?: FieldPolicy<any> | FieldReadFunction<any>;
  sort?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type EventsSumFieldsKeySpecifier = (
  | 'followersCount'
  | 'sort'
  | EventsSumFieldsKeySpecifier
)[];
export type EventsSumFieldsFieldPolicy = {
  followersCount?: FieldPolicy<any> | FieldReadFunction<any>;
  sort?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type EventsVar_popFieldsKeySpecifier = (
  | 'followersCount'
  | 'sort'
  | EventsVar_popFieldsKeySpecifier
)[];
export type EventsVar_popFieldsFieldPolicy = {
  followersCount?: FieldPolicy<any> | FieldReadFunction<any>;
  sort?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type EventsVar_sampFieldsKeySpecifier = (
  | 'followersCount'
  | 'sort'
  | EventsVar_sampFieldsKeySpecifier
)[];
export type EventsVar_sampFieldsFieldPolicy = {
  followersCount?: FieldPolicy<any> | FieldReadFunction<any>;
  sort?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type EventsVarianceFieldsKeySpecifier = (
  | 'followersCount'
  | 'sort'
  | EventsVarianceFieldsKeySpecifier
)[];
export type EventsVarianceFieldsFieldPolicy = {
  followersCount?: FieldPolicy<any> | FieldReadFunction<any>;
  sort?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type FollowersKeySpecifier = (
  | 'createdAt'
  | 'eventId'
  | 'id'
  | 'organiserId'
  | 'playerId'
  | 'tournamentId'
  | 'updatedAt'
  | 'userId'
  | 'venueId'
  | FollowersKeySpecifier
)[];
export type FollowersFieldPolicy = {
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  eventId?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  organiserId?: FieldPolicy<any> | FieldReadFunction<any>;
  playerId?: FieldPolicy<any> | FieldReadFunction<any>;
  tournamentId?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  userId?: FieldPolicy<any> | FieldReadFunction<any>;
  venueId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type FollowersAggregateKeySpecifier = (
  | 'aggregate'
  | 'nodes'
  | FollowersAggregateKeySpecifier
)[];
export type FollowersAggregateFieldPolicy = {
  aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type FollowersAggregateFieldsKeySpecifier = (
  | 'avg'
  | 'count'
  | 'max'
  | 'min'
  | 'stddev'
  | 'stddevPop'
  | 'stddevSamp'
  | 'sum'
  | 'varPop'
  | 'varSamp'
  | 'variance'
  | FollowersAggregateFieldsKeySpecifier
)[];
export type FollowersAggregateFieldsFieldPolicy = {
  avg?: FieldPolicy<any> | FieldReadFunction<any>;
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  max?: FieldPolicy<any> | FieldReadFunction<any>;
  min?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev?: FieldPolicy<any> | FieldReadFunction<any>;
  stddevPop?: FieldPolicy<any> | FieldReadFunction<any>;
  stddevSamp?: FieldPolicy<any> | FieldReadFunction<any>;
  sum?: FieldPolicy<any> | FieldReadFunction<any>;
  varPop?: FieldPolicy<any> | FieldReadFunction<any>;
  varSamp?: FieldPolicy<any> | FieldReadFunction<any>;
  variance?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type FollowersAvgFieldsKeySpecifier = (
  | 'id'
  | FollowersAvgFieldsKeySpecifier
)[];
export type FollowersAvgFieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type FollowersMaxFieldsKeySpecifier = (
  | 'createdAt'
  | 'eventId'
  | 'id'
  | 'organiserId'
  | 'playerId'
  | 'tournamentId'
  | 'updatedAt'
  | 'userId'
  | 'venueId'
  | FollowersMaxFieldsKeySpecifier
)[];
export type FollowersMaxFieldsFieldPolicy = {
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  eventId?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  organiserId?: FieldPolicy<any> | FieldReadFunction<any>;
  playerId?: FieldPolicy<any> | FieldReadFunction<any>;
  tournamentId?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  userId?: FieldPolicy<any> | FieldReadFunction<any>;
  venueId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type FollowersMinFieldsKeySpecifier = (
  | 'createdAt'
  | 'eventId'
  | 'id'
  | 'organiserId'
  | 'playerId'
  | 'tournamentId'
  | 'updatedAt'
  | 'userId'
  | 'venueId'
  | FollowersMinFieldsKeySpecifier
)[];
export type FollowersMinFieldsFieldPolicy = {
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  eventId?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  organiserId?: FieldPolicy<any> | FieldReadFunction<any>;
  playerId?: FieldPolicy<any> | FieldReadFunction<any>;
  tournamentId?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  userId?: FieldPolicy<any> | FieldReadFunction<any>;
  venueId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type FollowersMutationResponseKeySpecifier = (
  | 'affected_rows'
  | 'returning'
  | FollowersMutationResponseKeySpecifier
)[];
export type FollowersMutationResponseFieldPolicy = {
  affected_rows?: FieldPolicy<any> | FieldReadFunction<any>;
  returning?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type FollowersStddevFieldsKeySpecifier = (
  | 'id'
  | FollowersStddevFieldsKeySpecifier
)[];
export type FollowersStddevFieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type FollowersStddev_popFieldsKeySpecifier = (
  | 'id'
  | FollowersStddev_popFieldsKeySpecifier
)[];
export type FollowersStddev_popFieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type FollowersStddev_sampFieldsKeySpecifier = (
  | 'id'
  | FollowersStddev_sampFieldsKeySpecifier
)[];
export type FollowersStddev_sampFieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type FollowersSumFieldsKeySpecifier = (
  | 'id'
  | FollowersSumFieldsKeySpecifier
)[];
export type FollowersSumFieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type FollowersVar_popFieldsKeySpecifier = (
  | 'id'
  | FollowersVar_popFieldsKeySpecifier
)[];
export type FollowersVar_popFieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type FollowersVar_sampFieldsKeySpecifier = (
  | 'id'
  | FollowersVar_sampFieldsKeySpecifier
)[];
export type FollowersVar_sampFieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type FollowersVarianceFieldsKeySpecifier = (
  | 'id'
  | FollowersVarianceFieldsKeySpecifier
)[];
export type FollowersVarianceFieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MessageTypesKeySpecifier = (
  | 'contentMail'
  | 'contentNotification'
  | 'createdAt'
  | 'id'
  | 'name'
  | 'sendMail'
  | 'sendNotification'
  | 'subject'
  | 'updatedAt'
  | MessageTypesKeySpecifier
)[];
export type MessageTypesFieldPolicy = {
  contentMail?: FieldPolicy<any> | FieldReadFunction<any>;
  contentNotification?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  sendMail?: FieldPolicy<any> | FieldReadFunction<any>;
  sendNotification?: FieldPolicy<any> | FieldReadFunction<any>;
  subject?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MessageTypesAggregateKeySpecifier = (
  | 'aggregate'
  | 'nodes'
  | MessageTypesAggregateKeySpecifier
)[];
export type MessageTypesAggregateFieldPolicy = {
  aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MessageTypesAggregateFieldsKeySpecifier = (
  | 'avg'
  | 'count'
  | 'max'
  | 'min'
  | 'stddev'
  | 'stddevPop'
  | 'stddevSamp'
  | 'sum'
  | 'varPop'
  | 'varSamp'
  | 'variance'
  | MessageTypesAggregateFieldsKeySpecifier
)[];
export type MessageTypesAggregateFieldsFieldPolicy = {
  avg?: FieldPolicy<any> | FieldReadFunction<any>;
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  max?: FieldPolicy<any> | FieldReadFunction<any>;
  min?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev?: FieldPolicy<any> | FieldReadFunction<any>;
  stddevPop?: FieldPolicy<any> | FieldReadFunction<any>;
  stddevSamp?: FieldPolicy<any> | FieldReadFunction<any>;
  sum?: FieldPolicy<any> | FieldReadFunction<any>;
  varPop?: FieldPolicy<any> | FieldReadFunction<any>;
  varSamp?: FieldPolicy<any> | FieldReadFunction<any>;
  variance?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MessageTypesAvgFieldsKeySpecifier = (
  | 'id'
  | MessageTypesAvgFieldsKeySpecifier
)[];
export type MessageTypesAvgFieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MessageTypesMaxFieldsKeySpecifier = (
  | 'createdAt'
  | 'id'
  | 'name'
  | 'updatedAt'
  | MessageTypesMaxFieldsKeySpecifier
)[];
export type MessageTypesMaxFieldsFieldPolicy = {
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MessageTypesMinFieldsKeySpecifier = (
  | 'createdAt'
  | 'id'
  | 'name'
  | 'updatedAt'
  | MessageTypesMinFieldsKeySpecifier
)[];
export type MessageTypesMinFieldsFieldPolicy = {
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MessageTypesMutationResponseKeySpecifier = (
  | 'affected_rows'
  | 'returning'
  | MessageTypesMutationResponseKeySpecifier
)[];
export type MessageTypesMutationResponseFieldPolicy = {
  affected_rows?: FieldPolicy<any> | FieldReadFunction<any>;
  returning?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MessageTypesStddevFieldsKeySpecifier = (
  | 'id'
  | MessageTypesStddevFieldsKeySpecifier
)[];
export type MessageTypesStddevFieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MessageTypesStddev_popFieldsKeySpecifier = (
  | 'id'
  | MessageTypesStddev_popFieldsKeySpecifier
)[];
export type MessageTypesStddev_popFieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MessageTypesStddev_sampFieldsKeySpecifier = (
  | 'id'
  | MessageTypesStddev_sampFieldsKeySpecifier
)[];
export type MessageTypesStddev_sampFieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MessageTypesSumFieldsKeySpecifier = (
  | 'id'
  | MessageTypesSumFieldsKeySpecifier
)[];
export type MessageTypesSumFieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MessageTypesVar_popFieldsKeySpecifier = (
  | 'id'
  | MessageTypesVar_popFieldsKeySpecifier
)[];
export type MessageTypesVar_popFieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MessageTypesVar_sampFieldsKeySpecifier = (
  | 'id'
  | MessageTypesVar_sampFieldsKeySpecifier
)[];
export type MessageTypesVar_sampFieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MessageTypesVarianceFieldsKeySpecifier = (
  | 'id'
  | MessageTypesVarianceFieldsKeySpecifier
)[];
export type MessageTypesVarianceFieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MessagesKeySpecifier = (
  | 'content'
  | 'createdAt'
  | 'id'
  | 'important'
  | 'mailSend'
  | 'messageTypeId'
  | 'read'
  | 'subject'
  | 'updatedAt'
  | 'userId'
  | 'variables'
  | MessagesKeySpecifier
)[];
export type MessagesFieldPolicy = {
  content?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  important?: FieldPolicy<any> | FieldReadFunction<any>;
  mailSend?: FieldPolicy<any> | FieldReadFunction<any>;
  messageTypeId?: FieldPolicy<any> | FieldReadFunction<any>;
  read?: FieldPolicy<any> | FieldReadFunction<any>;
  subject?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  userId?: FieldPolicy<any> | FieldReadFunction<any>;
  variables?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MessagesAggregateKeySpecifier = (
  | 'aggregate'
  | 'nodes'
  | MessagesAggregateKeySpecifier
)[];
export type MessagesAggregateFieldPolicy = {
  aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MessagesAggregateFieldsKeySpecifier = (
  | 'avg'
  | 'count'
  | 'max'
  | 'min'
  | 'stddev'
  | 'stddevPop'
  | 'stddevSamp'
  | 'sum'
  | 'varPop'
  | 'varSamp'
  | 'variance'
  | MessagesAggregateFieldsKeySpecifier
)[];
export type MessagesAggregateFieldsFieldPolicy = {
  avg?: FieldPolicy<any> | FieldReadFunction<any>;
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  max?: FieldPolicy<any> | FieldReadFunction<any>;
  min?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev?: FieldPolicy<any> | FieldReadFunction<any>;
  stddevPop?: FieldPolicy<any> | FieldReadFunction<any>;
  stddevSamp?: FieldPolicy<any> | FieldReadFunction<any>;
  sum?: FieldPolicy<any> | FieldReadFunction<any>;
  varPop?: FieldPolicy<any> | FieldReadFunction<any>;
  varSamp?: FieldPolicy<any> | FieldReadFunction<any>;
  variance?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MessagesAvgFieldsKeySpecifier = (
  | 'id'
  | 'messageTypeId'
  | MessagesAvgFieldsKeySpecifier
)[];
export type MessagesAvgFieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  messageTypeId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MessagesMaxFieldsKeySpecifier = (
  | 'content'
  | 'createdAt'
  | 'id'
  | 'mailSend'
  | 'messageTypeId'
  | 'subject'
  | 'updatedAt'
  | 'userId'
  | MessagesMaxFieldsKeySpecifier
)[];
export type MessagesMaxFieldsFieldPolicy = {
  content?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  mailSend?: FieldPolicy<any> | FieldReadFunction<any>;
  messageTypeId?: FieldPolicy<any> | FieldReadFunction<any>;
  subject?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  userId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MessagesMinFieldsKeySpecifier = (
  | 'content'
  | 'createdAt'
  | 'id'
  | 'mailSend'
  | 'messageTypeId'
  | 'subject'
  | 'updatedAt'
  | 'userId'
  | MessagesMinFieldsKeySpecifier
)[];
export type MessagesMinFieldsFieldPolicy = {
  content?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  mailSend?: FieldPolicy<any> | FieldReadFunction<any>;
  messageTypeId?: FieldPolicy<any> | FieldReadFunction<any>;
  subject?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  userId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MessagesMutationResponseKeySpecifier = (
  | 'affected_rows'
  | 'returning'
  | MessagesMutationResponseKeySpecifier
)[];
export type MessagesMutationResponseFieldPolicy = {
  affected_rows?: FieldPolicy<any> | FieldReadFunction<any>;
  returning?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MessagesStddevFieldsKeySpecifier = (
  | 'id'
  | 'messageTypeId'
  | MessagesStddevFieldsKeySpecifier
)[];
export type MessagesStddevFieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  messageTypeId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MessagesStddev_popFieldsKeySpecifier = (
  | 'id'
  | 'messageTypeId'
  | MessagesStddev_popFieldsKeySpecifier
)[];
export type MessagesStddev_popFieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  messageTypeId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MessagesStddev_sampFieldsKeySpecifier = (
  | 'id'
  | 'messageTypeId'
  | MessagesStddev_sampFieldsKeySpecifier
)[];
export type MessagesStddev_sampFieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  messageTypeId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MessagesSumFieldsKeySpecifier = (
  | 'id'
  | 'messageTypeId'
  | MessagesSumFieldsKeySpecifier
)[];
export type MessagesSumFieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  messageTypeId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MessagesVar_popFieldsKeySpecifier = (
  | 'id'
  | 'messageTypeId'
  | MessagesVar_popFieldsKeySpecifier
)[];
export type MessagesVar_popFieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  messageTypeId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MessagesVar_sampFieldsKeySpecifier = (
  | 'id'
  | 'messageTypeId'
  | MessagesVar_sampFieldsKeySpecifier
)[];
export type MessagesVar_sampFieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  messageTypeId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MessagesVarianceFieldsKeySpecifier = (
  | 'id'
  | 'messageTypeId'
  | MessagesVarianceFieldsKeySpecifier
)[];
export type MessagesVarianceFieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  messageTypeId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type OrganiserUsersKeySpecifier = (
  | 'createdAt'
  | 'id'
  | 'organiserId'
  | 'updatedAt'
  | 'userId'
  | OrganiserUsersKeySpecifier
)[];
export type OrganiserUsersFieldPolicy = {
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  organiserId?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  userId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type OrganiserUsersAggregateKeySpecifier = (
  | 'aggregate'
  | 'nodes'
  | OrganiserUsersAggregateKeySpecifier
)[];
export type OrganiserUsersAggregateFieldPolicy = {
  aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type OrganiserUsersAggregateFieldsKeySpecifier = (
  | 'avg'
  | 'count'
  | 'max'
  | 'min'
  | 'stddev'
  | 'stddevPop'
  | 'stddevSamp'
  | 'sum'
  | 'varPop'
  | 'varSamp'
  | 'variance'
  | OrganiserUsersAggregateFieldsKeySpecifier
)[];
export type OrganiserUsersAggregateFieldsFieldPolicy = {
  avg?: FieldPolicy<any> | FieldReadFunction<any>;
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  max?: FieldPolicy<any> | FieldReadFunction<any>;
  min?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev?: FieldPolicy<any> | FieldReadFunction<any>;
  stddevPop?: FieldPolicy<any> | FieldReadFunction<any>;
  stddevSamp?: FieldPolicy<any> | FieldReadFunction<any>;
  sum?: FieldPolicy<any> | FieldReadFunction<any>;
  varPop?: FieldPolicy<any> | FieldReadFunction<any>;
  varSamp?: FieldPolicy<any> | FieldReadFunction<any>;
  variance?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type OrganiserUsersAvgFieldsKeySpecifier = (
  | 'id'
  | OrganiserUsersAvgFieldsKeySpecifier
)[];
export type OrganiserUsersAvgFieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type OrganiserUsersMaxFieldsKeySpecifier = (
  | 'createdAt'
  | 'id'
  | 'organiserId'
  | 'updatedAt'
  | 'userId'
  | OrganiserUsersMaxFieldsKeySpecifier
)[];
export type OrganiserUsersMaxFieldsFieldPolicy = {
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  organiserId?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  userId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type OrganiserUsersMinFieldsKeySpecifier = (
  | 'createdAt'
  | 'id'
  | 'organiserId'
  | 'updatedAt'
  | 'userId'
  | OrganiserUsersMinFieldsKeySpecifier
)[];
export type OrganiserUsersMinFieldsFieldPolicy = {
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  organiserId?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  userId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type OrganiserUsersMutationResponseKeySpecifier = (
  | 'affected_rows'
  | 'returning'
  | OrganiserUsersMutationResponseKeySpecifier
)[];
export type OrganiserUsersMutationResponseFieldPolicy = {
  affected_rows?: FieldPolicy<any> | FieldReadFunction<any>;
  returning?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type OrganiserUsersStddevFieldsKeySpecifier = (
  | 'id'
  | OrganiserUsersStddevFieldsKeySpecifier
)[];
export type OrganiserUsersStddevFieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type OrganiserUsersStddev_popFieldsKeySpecifier = (
  | 'id'
  | OrganiserUsersStddev_popFieldsKeySpecifier
)[];
export type OrganiserUsersStddev_popFieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type OrganiserUsersStddev_sampFieldsKeySpecifier = (
  | 'id'
  | OrganiserUsersStddev_sampFieldsKeySpecifier
)[];
export type OrganiserUsersStddev_sampFieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type OrganiserUsersSumFieldsKeySpecifier = (
  | 'id'
  | OrganiserUsersSumFieldsKeySpecifier
)[];
export type OrganiserUsersSumFieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type OrganiserUsersVar_popFieldsKeySpecifier = (
  | 'id'
  | OrganiserUsersVar_popFieldsKeySpecifier
)[];
export type OrganiserUsersVar_popFieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type OrganiserUsersVar_sampFieldsKeySpecifier = (
  | 'id'
  | OrganiserUsersVar_sampFieldsKeySpecifier
)[];
export type OrganiserUsersVar_sampFieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type OrganiserUsersVarianceFieldsKeySpecifier = (
  | 'id'
  | OrganiserUsersVarianceFieldsKeySpecifier
)[];
export type OrganiserUsersVarianceFieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type OrganisersKeySpecifier = (
  | 'createdAt'
  | 'description'
  | 'events'
  | 'eventsAggregate'
  | 'facebook'
  | 'followers'
  | 'followersAggregate'
  | 'followersCount'
  | 'id'
  | 'image'
  | 'name'
  | 'status'
  | 'twitter'
  | 'updatedAt'
  | 'website'
  | OrganisersKeySpecifier
)[];
export type OrganisersFieldPolicy = {
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  description?: FieldPolicy<any> | FieldReadFunction<any>;
  events?: FieldPolicy<any> | FieldReadFunction<any>;
  eventsAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  facebook?: FieldPolicy<any> | FieldReadFunction<any>;
  followers?: FieldPolicy<any> | FieldReadFunction<any>;
  followersAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  followersCount?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  image?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  twitter?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  website?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type OrganisersAggregateKeySpecifier = (
  | 'aggregate'
  | 'nodes'
  | OrganisersAggregateKeySpecifier
)[];
export type OrganisersAggregateFieldPolicy = {
  aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type OrganisersAggregateFieldsKeySpecifier = (
  | 'avg'
  | 'count'
  | 'max'
  | 'min'
  | 'stddev'
  | 'stddevPop'
  | 'stddevSamp'
  | 'sum'
  | 'varPop'
  | 'varSamp'
  | 'variance'
  | OrganisersAggregateFieldsKeySpecifier
)[];
export type OrganisersAggregateFieldsFieldPolicy = {
  avg?: FieldPolicy<any> | FieldReadFunction<any>;
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  max?: FieldPolicy<any> | FieldReadFunction<any>;
  min?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev?: FieldPolicy<any> | FieldReadFunction<any>;
  stddevPop?: FieldPolicy<any> | FieldReadFunction<any>;
  stddevSamp?: FieldPolicy<any> | FieldReadFunction<any>;
  sum?: FieldPolicy<any> | FieldReadFunction<any>;
  varPop?: FieldPolicy<any> | FieldReadFunction<any>;
  varSamp?: FieldPolicy<any> | FieldReadFunction<any>;
  variance?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type OrganisersAvgFieldsKeySpecifier = (
  | 'followersCount'
  | OrganisersAvgFieldsKeySpecifier
)[];
export type OrganisersAvgFieldsFieldPolicy = {
  followersCount?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type OrganisersMaxFieldsKeySpecifier = (
  | 'createdAt'
  | 'description'
  | 'facebook'
  | 'followersCount'
  | 'id'
  | 'image'
  | 'name'
  | 'status'
  | 'twitter'
  | 'updatedAt'
  | 'website'
  | OrganisersMaxFieldsKeySpecifier
)[];
export type OrganisersMaxFieldsFieldPolicy = {
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  description?: FieldPolicy<any> | FieldReadFunction<any>;
  facebook?: FieldPolicy<any> | FieldReadFunction<any>;
  followersCount?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  image?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  twitter?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  website?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type OrganisersMinFieldsKeySpecifier = (
  | 'createdAt'
  | 'description'
  | 'facebook'
  | 'followersCount'
  | 'id'
  | 'image'
  | 'name'
  | 'status'
  | 'twitter'
  | 'updatedAt'
  | 'website'
  | OrganisersMinFieldsKeySpecifier
)[];
export type OrganisersMinFieldsFieldPolicy = {
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  description?: FieldPolicy<any> | FieldReadFunction<any>;
  facebook?: FieldPolicy<any> | FieldReadFunction<any>;
  followersCount?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  image?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  twitter?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  website?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type OrganisersMutationResponseKeySpecifier = (
  | 'affected_rows'
  | 'returning'
  | OrganisersMutationResponseKeySpecifier
)[];
export type OrganisersMutationResponseFieldPolicy = {
  affected_rows?: FieldPolicy<any> | FieldReadFunction<any>;
  returning?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type OrganisersStddevFieldsKeySpecifier = (
  | 'followersCount'
  | OrganisersStddevFieldsKeySpecifier
)[];
export type OrganisersStddevFieldsFieldPolicy = {
  followersCount?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type OrganisersStddev_popFieldsKeySpecifier = (
  | 'followersCount'
  | OrganisersStddev_popFieldsKeySpecifier
)[];
export type OrganisersStddev_popFieldsFieldPolicy = {
  followersCount?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type OrganisersStddev_sampFieldsKeySpecifier = (
  | 'followersCount'
  | OrganisersStddev_sampFieldsKeySpecifier
)[];
export type OrganisersStddev_sampFieldsFieldPolicy = {
  followersCount?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type OrganisersSumFieldsKeySpecifier = (
  | 'followersCount'
  | OrganisersSumFieldsKeySpecifier
)[];
export type OrganisersSumFieldsFieldPolicy = {
  followersCount?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type OrganisersVar_popFieldsKeySpecifier = (
  | 'followersCount'
  | OrganisersVar_popFieldsKeySpecifier
)[];
export type OrganisersVar_popFieldsFieldPolicy = {
  followersCount?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type OrganisersVar_sampFieldsKeySpecifier = (
  | 'followersCount'
  | OrganisersVar_sampFieldsKeySpecifier
)[];
export type OrganisersVar_sampFieldsFieldPolicy = {
  followersCount?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type OrganisersVarianceFieldsKeySpecifier = (
  | 'followersCount'
  | OrganisersVarianceFieldsKeySpecifier
)[];
export type OrganisersVarianceFieldsFieldPolicy = {
  followersCount?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PaymentMethodBanksKeySpecifier = (
  | 'country'
  | 'countryByCountryId'
  | 'countryId'
  | 'createdAt'
  | 'currency'
  | 'currencyId'
  | 'deposit'
  | 'depositCurrency'
  | 'depositDetails'
  | 'depositExchangeRate'
  | 'depositFee'
  | 'depositMax'
  | 'depositMin'
  | 'id'
  | 'name'
  | 'paymentMethodId'
  | 'payment_method'
  | 'telegramAdmins'
  | 'telegramGroup'
  | 'updatedAt'
  | 'withdraw'
  | 'withdrawDetails'
  | 'withdrawExchangeRate'
  | 'withdrawFee'
  | 'withdrawMax'
  | 'withdrawMin'
  | PaymentMethodBanksKeySpecifier
)[];
export type PaymentMethodBanksFieldPolicy = {
  country?: FieldPolicy<any> | FieldReadFunction<any>;
  countryByCountryId?: FieldPolicy<any> | FieldReadFunction<any>;
  countryId?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  currency?: FieldPolicy<any> | FieldReadFunction<any>;
  currencyId?: FieldPolicy<any> | FieldReadFunction<any>;
  deposit?: FieldPolicy<any> | FieldReadFunction<any>;
  depositCurrency?: FieldPolicy<any> | FieldReadFunction<any>;
  depositDetails?: FieldPolicy<any> | FieldReadFunction<any>;
  depositExchangeRate?: FieldPolicy<any> | FieldReadFunction<any>;
  depositFee?: FieldPolicy<any> | FieldReadFunction<any>;
  depositMax?: FieldPolicy<any> | FieldReadFunction<any>;
  depositMin?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  paymentMethodId?: FieldPolicy<any> | FieldReadFunction<any>;
  payment_method?: FieldPolicy<any> | FieldReadFunction<any>;
  telegramAdmins?: FieldPolicy<any> | FieldReadFunction<any>;
  telegramGroup?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  withdraw?: FieldPolicy<any> | FieldReadFunction<any>;
  withdrawDetails?: FieldPolicy<any> | FieldReadFunction<any>;
  withdrawExchangeRate?: FieldPolicy<any> | FieldReadFunction<any>;
  withdrawFee?: FieldPolicy<any> | FieldReadFunction<any>;
  withdrawMax?: FieldPolicy<any> | FieldReadFunction<any>;
  withdrawMin?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PaymentMethodBanksAggregateKeySpecifier = (
  | 'aggregate'
  | 'nodes'
  | PaymentMethodBanksAggregateKeySpecifier
)[];
export type PaymentMethodBanksAggregateFieldPolicy = {
  aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PaymentMethodBanksAggregateFieldsKeySpecifier = (
  | 'avg'
  | 'count'
  | 'max'
  | 'min'
  | 'stddev'
  | 'stddevPop'
  | 'stddevSamp'
  | 'sum'
  | 'varPop'
  | 'varSamp'
  | 'variance'
  | PaymentMethodBanksAggregateFieldsKeySpecifier
)[];
export type PaymentMethodBanksAggregateFieldsFieldPolicy = {
  avg?: FieldPolicy<any> | FieldReadFunction<any>;
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  max?: FieldPolicy<any> | FieldReadFunction<any>;
  min?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev?: FieldPolicy<any> | FieldReadFunction<any>;
  stddevPop?: FieldPolicy<any> | FieldReadFunction<any>;
  stddevSamp?: FieldPolicy<any> | FieldReadFunction<any>;
  sum?: FieldPolicy<any> | FieldReadFunction<any>;
  varPop?: FieldPolicy<any> | FieldReadFunction<any>;
  varSamp?: FieldPolicy<any> | FieldReadFunction<any>;
  variance?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PaymentMethodBanksAvgFieldsKeySpecifier = (
  | 'countryId'
  | 'currencyId'
  | 'depositExchangeRate'
  | 'depositFee'
  | 'depositMax'
  | 'depositMin'
  | 'id'
  | 'paymentMethodId'
  | 'telegramGroup'
  | 'withdrawExchangeRate'
  | 'withdrawFee'
  | 'withdrawMax'
  | 'withdrawMin'
  | PaymentMethodBanksAvgFieldsKeySpecifier
)[];
export type PaymentMethodBanksAvgFieldsFieldPolicy = {
  countryId?: FieldPolicy<any> | FieldReadFunction<any>;
  currencyId?: FieldPolicy<any> | FieldReadFunction<any>;
  depositExchangeRate?: FieldPolicy<any> | FieldReadFunction<any>;
  depositFee?: FieldPolicy<any> | FieldReadFunction<any>;
  depositMax?: FieldPolicy<any> | FieldReadFunction<any>;
  depositMin?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  paymentMethodId?: FieldPolicy<any> | FieldReadFunction<any>;
  telegramGroup?: FieldPolicy<any> | FieldReadFunction<any>;
  withdrawExchangeRate?: FieldPolicy<any> | FieldReadFunction<any>;
  withdrawFee?: FieldPolicy<any> | FieldReadFunction<any>;
  withdrawMax?: FieldPolicy<any> | FieldReadFunction<any>;
  withdrawMin?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PaymentMethodBanksMaxFieldsKeySpecifier = (
  | 'country'
  | 'countryId'
  | 'createdAt'
  | 'currencyId'
  | 'depositCurrency'
  | 'depositDetails'
  | 'depositExchangeRate'
  | 'depositFee'
  | 'depositMax'
  | 'depositMin'
  | 'id'
  | 'name'
  | 'paymentMethodId'
  | 'telegramAdmins'
  | 'telegramGroup'
  | 'updatedAt'
  | 'withdrawDetails'
  | 'withdrawExchangeRate'
  | 'withdrawFee'
  | 'withdrawMax'
  | 'withdrawMin'
  | PaymentMethodBanksMaxFieldsKeySpecifier
)[];
export type PaymentMethodBanksMaxFieldsFieldPolicy = {
  country?: FieldPolicy<any> | FieldReadFunction<any>;
  countryId?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  currencyId?: FieldPolicy<any> | FieldReadFunction<any>;
  depositCurrency?: FieldPolicy<any> | FieldReadFunction<any>;
  depositDetails?: FieldPolicy<any> | FieldReadFunction<any>;
  depositExchangeRate?: FieldPolicy<any> | FieldReadFunction<any>;
  depositFee?: FieldPolicy<any> | FieldReadFunction<any>;
  depositMax?: FieldPolicy<any> | FieldReadFunction<any>;
  depositMin?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  paymentMethodId?: FieldPolicy<any> | FieldReadFunction<any>;
  telegramAdmins?: FieldPolicy<any> | FieldReadFunction<any>;
  telegramGroup?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  withdrawDetails?: FieldPolicy<any> | FieldReadFunction<any>;
  withdrawExchangeRate?: FieldPolicy<any> | FieldReadFunction<any>;
  withdrawFee?: FieldPolicy<any> | FieldReadFunction<any>;
  withdrawMax?: FieldPolicy<any> | FieldReadFunction<any>;
  withdrawMin?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PaymentMethodBanksMinFieldsKeySpecifier = (
  | 'country'
  | 'countryId'
  | 'createdAt'
  | 'currencyId'
  | 'depositCurrency'
  | 'depositDetails'
  | 'depositExchangeRate'
  | 'depositFee'
  | 'depositMax'
  | 'depositMin'
  | 'id'
  | 'name'
  | 'paymentMethodId'
  | 'telegramAdmins'
  | 'telegramGroup'
  | 'updatedAt'
  | 'withdrawDetails'
  | 'withdrawExchangeRate'
  | 'withdrawFee'
  | 'withdrawMax'
  | 'withdrawMin'
  | PaymentMethodBanksMinFieldsKeySpecifier
)[];
export type PaymentMethodBanksMinFieldsFieldPolicy = {
  country?: FieldPolicy<any> | FieldReadFunction<any>;
  countryId?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  currencyId?: FieldPolicy<any> | FieldReadFunction<any>;
  depositCurrency?: FieldPolicy<any> | FieldReadFunction<any>;
  depositDetails?: FieldPolicy<any> | FieldReadFunction<any>;
  depositExchangeRate?: FieldPolicy<any> | FieldReadFunction<any>;
  depositFee?: FieldPolicy<any> | FieldReadFunction<any>;
  depositMax?: FieldPolicy<any> | FieldReadFunction<any>;
  depositMin?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  paymentMethodId?: FieldPolicy<any> | FieldReadFunction<any>;
  telegramAdmins?: FieldPolicy<any> | FieldReadFunction<any>;
  telegramGroup?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  withdrawDetails?: FieldPolicy<any> | FieldReadFunction<any>;
  withdrawExchangeRate?: FieldPolicy<any> | FieldReadFunction<any>;
  withdrawFee?: FieldPolicy<any> | FieldReadFunction<any>;
  withdrawMax?: FieldPolicy<any> | FieldReadFunction<any>;
  withdrawMin?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PaymentMethodBanksMutationResponseKeySpecifier = (
  | 'affected_rows'
  | 'returning'
  | PaymentMethodBanksMutationResponseKeySpecifier
)[];
export type PaymentMethodBanksMutationResponseFieldPolicy = {
  affected_rows?: FieldPolicy<any> | FieldReadFunction<any>;
  returning?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PaymentMethodBanksStddevFieldsKeySpecifier = (
  | 'countryId'
  | 'currencyId'
  | 'depositExchangeRate'
  | 'depositFee'
  | 'depositMax'
  | 'depositMin'
  | 'id'
  | 'paymentMethodId'
  | 'telegramGroup'
  | 'withdrawExchangeRate'
  | 'withdrawFee'
  | 'withdrawMax'
  | 'withdrawMin'
  | PaymentMethodBanksStddevFieldsKeySpecifier
)[];
export type PaymentMethodBanksStddevFieldsFieldPolicy = {
  countryId?: FieldPolicy<any> | FieldReadFunction<any>;
  currencyId?: FieldPolicy<any> | FieldReadFunction<any>;
  depositExchangeRate?: FieldPolicy<any> | FieldReadFunction<any>;
  depositFee?: FieldPolicy<any> | FieldReadFunction<any>;
  depositMax?: FieldPolicy<any> | FieldReadFunction<any>;
  depositMin?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  paymentMethodId?: FieldPolicy<any> | FieldReadFunction<any>;
  telegramGroup?: FieldPolicy<any> | FieldReadFunction<any>;
  withdrawExchangeRate?: FieldPolicy<any> | FieldReadFunction<any>;
  withdrawFee?: FieldPolicy<any> | FieldReadFunction<any>;
  withdrawMax?: FieldPolicy<any> | FieldReadFunction<any>;
  withdrawMin?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PaymentMethodBanksStddev_popFieldsKeySpecifier = (
  | 'countryId'
  | 'currencyId'
  | 'depositExchangeRate'
  | 'depositFee'
  | 'depositMax'
  | 'depositMin'
  | 'id'
  | 'paymentMethodId'
  | 'telegramGroup'
  | 'withdrawExchangeRate'
  | 'withdrawFee'
  | 'withdrawMax'
  | 'withdrawMin'
  | PaymentMethodBanksStddev_popFieldsKeySpecifier
)[];
export type PaymentMethodBanksStddev_popFieldsFieldPolicy = {
  countryId?: FieldPolicy<any> | FieldReadFunction<any>;
  currencyId?: FieldPolicy<any> | FieldReadFunction<any>;
  depositExchangeRate?: FieldPolicy<any> | FieldReadFunction<any>;
  depositFee?: FieldPolicy<any> | FieldReadFunction<any>;
  depositMax?: FieldPolicy<any> | FieldReadFunction<any>;
  depositMin?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  paymentMethodId?: FieldPolicy<any> | FieldReadFunction<any>;
  telegramGroup?: FieldPolicy<any> | FieldReadFunction<any>;
  withdrawExchangeRate?: FieldPolicy<any> | FieldReadFunction<any>;
  withdrawFee?: FieldPolicy<any> | FieldReadFunction<any>;
  withdrawMax?: FieldPolicy<any> | FieldReadFunction<any>;
  withdrawMin?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PaymentMethodBanksStddev_sampFieldsKeySpecifier = (
  | 'countryId'
  | 'currencyId'
  | 'depositExchangeRate'
  | 'depositFee'
  | 'depositMax'
  | 'depositMin'
  | 'id'
  | 'paymentMethodId'
  | 'telegramGroup'
  | 'withdrawExchangeRate'
  | 'withdrawFee'
  | 'withdrawMax'
  | 'withdrawMin'
  | PaymentMethodBanksStddev_sampFieldsKeySpecifier
)[];
export type PaymentMethodBanksStddev_sampFieldsFieldPolicy = {
  countryId?: FieldPolicy<any> | FieldReadFunction<any>;
  currencyId?: FieldPolicy<any> | FieldReadFunction<any>;
  depositExchangeRate?: FieldPolicy<any> | FieldReadFunction<any>;
  depositFee?: FieldPolicy<any> | FieldReadFunction<any>;
  depositMax?: FieldPolicy<any> | FieldReadFunction<any>;
  depositMin?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  paymentMethodId?: FieldPolicy<any> | FieldReadFunction<any>;
  telegramGroup?: FieldPolicy<any> | FieldReadFunction<any>;
  withdrawExchangeRate?: FieldPolicy<any> | FieldReadFunction<any>;
  withdrawFee?: FieldPolicy<any> | FieldReadFunction<any>;
  withdrawMax?: FieldPolicy<any> | FieldReadFunction<any>;
  withdrawMin?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PaymentMethodBanksSumFieldsKeySpecifier = (
  | 'countryId'
  | 'currencyId'
  | 'depositExchangeRate'
  | 'depositFee'
  | 'depositMax'
  | 'depositMin'
  | 'id'
  | 'paymentMethodId'
  | 'telegramGroup'
  | 'withdrawExchangeRate'
  | 'withdrawFee'
  | 'withdrawMax'
  | 'withdrawMin'
  | PaymentMethodBanksSumFieldsKeySpecifier
)[];
export type PaymentMethodBanksSumFieldsFieldPolicy = {
  countryId?: FieldPolicy<any> | FieldReadFunction<any>;
  currencyId?: FieldPolicy<any> | FieldReadFunction<any>;
  depositExchangeRate?: FieldPolicy<any> | FieldReadFunction<any>;
  depositFee?: FieldPolicy<any> | FieldReadFunction<any>;
  depositMax?: FieldPolicy<any> | FieldReadFunction<any>;
  depositMin?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  paymentMethodId?: FieldPolicy<any> | FieldReadFunction<any>;
  telegramGroup?: FieldPolicy<any> | FieldReadFunction<any>;
  withdrawExchangeRate?: FieldPolicy<any> | FieldReadFunction<any>;
  withdrawFee?: FieldPolicy<any> | FieldReadFunction<any>;
  withdrawMax?: FieldPolicy<any> | FieldReadFunction<any>;
  withdrawMin?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PaymentMethodBanksVar_popFieldsKeySpecifier = (
  | 'countryId'
  | 'currencyId'
  | 'depositExchangeRate'
  | 'depositFee'
  | 'depositMax'
  | 'depositMin'
  | 'id'
  | 'paymentMethodId'
  | 'telegramGroup'
  | 'withdrawExchangeRate'
  | 'withdrawFee'
  | 'withdrawMax'
  | 'withdrawMin'
  | PaymentMethodBanksVar_popFieldsKeySpecifier
)[];
export type PaymentMethodBanksVar_popFieldsFieldPolicy = {
  countryId?: FieldPolicy<any> | FieldReadFunction<any>;
  currencyId?: FieldPolicy<any> | FieldReadFunction<any>;
  depositExchangeRate?: FieldPolicy<any> | FieldReadFunction<any>;
  depositFee?: FieldPolicy<any> | FieldReadFunction<any>;
  depositMax?: FieldPolicy<any> | FieldReadFunction<any>;
  depositMin?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  paymentMethodId?: FieldPolicy<any> | FieldReadFunction<any>;
  telegramGroup?: FieldPolicy<any> | FieldReadFunction<any>;
  withdrawExchangeRate?: FieldPolicy<any> | FieldReadFunction<any>;
  withdrawFee?: FieldPolicy<any> | FieldReadFunction<any>;
  withdrawMax?: FieldPolicy<any> | FieldReadFunction<any>;
  withdrawMin?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PaymentMethodBanksVar_sampFieldsKeySpecifier = (
  | 'countryId'
  | 'currencyId'
  | 'depositExchangeRate'
  | 'depositFee'
  | 'depositMax'
  | 'depositMin'
  | 'id'
  | 'paymentMethodId'
  | 'telegramGroup'
  | 'withdrawExchangeRate'
  | 'withdrawFee'
  | 'withdrawMax'
  | 'withdrawMin'
  | PaymentMethodBanksVar_sampFieldsKeySpecifier
)[];
export type PaymentMethodBanksVar_sampFieldsFieldPolicy = {
  countryId?: FieldPolicy<any> | FieldReadFunction<any>;
  currencyId?: FieldPolicy<any> | FieldReadFunction<any>;
  depositExchangeRate?: FieldPolicy<any> | FieldReadFunction<any>;
  depositFee?: FieldPolicy<any> | FieldReadFunction<any>;
  depositMax?: FieldPolicy<any> | FieldReadFunction<any>;
  depositMin?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  paymentMethodId?: FieldPolicy<any> | FieldReadFunction<any>;
  telegramGroup?: FieldPolicy<any> | FieldReadFunction<any>;
  withdrawExchangeRate?: FieldPolicy<any> | FieldReadFunction<any>;
  withdrawFee?: FieldPolicy<any> | FieldReadFunction<any>;
  withdrawMax?: FieldPolicy<any> | FieldReadFunction<any>;
  withdrawMin?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PaymentMethodBanksVarianceFieldsKeySpecifier = (
  | 'countryId'
  | 'currencyId'
  | 'depositExchangeRate'
  | 'depositFee'
  | 'depositMax'
  | 'depositMin'
  | 'id'
  | 'paymentMethodId'
  | 'telegramGroup'
  | 'withdrawExchangeRate'
  | 'withdrawFee'
  | 'withdrawMax'
  | 'withdrawMin'
  | PaymentMethodBanksVarianceFieldsKeySpecifier
)[];
export type PaymentMethodBanksVarianceFieldsFieldPolicy = {
  countryId?: FieldPolicy<any> | FieldReadFunction<any>;
  currencyId?: FieldPolicy<any> | FieldReadFunction<any>;
  depositExchangeRate?: FieldPolicy<any> | FieldReadFunction<any>;
  depositFee?: FieldPolicy<any> | FieldReadFunction<any>;
  depositMax?: FieldPolicy<any> | FieldReadFunction<any>;
  depositMin?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  paymentMethodId?: FieldPolicy<any> | FieldReadFunction<any>;
  telegramGroup?: FieldPolicy<any> | FieldReadFunction<any>;
  withdrawExchangeRate?: FieldPolicy<any> | FieldReadFunction<any>;
  withdrawFee?: FieldPolicy<any> | FieldReadFunction<any>;
  withdrawMax?: FieldPolicy<any> | FieldReadFunction<any>;
  withdrawMin?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PaymentMethodsKeySpecifier = (
  | 'createdAt'
  | 'deposit'
  | 'id'
  | 'name'
  | 'paymentMethodBanksAggregate'
  | 'payment_method_banks'
  | 'type'
  | 'updatedAt'
  | 'withdraw'
  | PaymentMethodsKeySpecifier
)[];
export type PaymentMethodsFieldPolicy = {
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  deposit?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  paymentMethodBanksAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  payment_method_banks?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  withdraw?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PaymentMethodsAggregateKeySpecifier = (
  | 'aggregate'
  | 'nodes'
  | PaymentMethodsAggregateKeySpecifier
)[];
export type PaymentMethodsAggregateFieldPolicy = {
  aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PaymentMethodsAggregateFieldsKeySpecifier = (
  | 'avg'
  | 'count'
  | 'max'
  | 'min'
  | 'stddev'
  | 'stddevPop'
  | 'stddevSamp'
  | 'sum'
  | 'varPop'
  | 'varSamp'
  | 'variance'
  | PaymentMethodsAggregateFieldsKeySpecifier
)[];
export type PaymentMethodsAggregateFieldsFieldPolicy = {
  avg?: FieldPolicy<any> | FieldReadFunction<any>;
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  max?: FieldPolicy<any> | FieldReadFunction<any>;
  min?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev?: FieldPolicy<any> | FieldReadFunction<any>;
  stddevPop?: FieldPolicy<any> | FieldReadFunction<any>;
  stddevSamp?: FieldPolicy<any> | FieldReadFunction<any>;
  sum?: FieldPolicy<any> | FieldReadFunction<any>;
  varPop?: FieldPolicy<any> | FieldReadFunction<any>;
  varSamp?: FieldPolicy<any> | FieldReadFunction<any>;
  variance?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PaymentMethodsAvgFieldsKeySpecifier = (
  | 'id'
  | PaymentMethodsAvgFieldsKeySpecifier
)[];
export type PaymentMethodsAvgFieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PaymentMethodsMaxFieldsKeySpecifier = (
  | 'createdAt'
  | 'id'
  | 'name'
  | 'type'
  | 'updatedAt'
  | PaymentMethodsMaxFieldsKeySpecifier
)[];
export type PaymentMethodsMaxFieldsFieldPolicy = {
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PaymentMethodsMinFieldsKeySpecifier = (
  | 'createdAt'
  | 'id'
  | 'name'
  | 'type'
  | 'updatedAt'
  | PaymentMethodsMinFieldsKeySpecifier
)[];
export type PaymentMethodsMinFieldsFieldPolicy = {
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PaymentMethodsMutationResponseKeySpecifier = (
  | 'affected_rows'
  | 'returning'
  | PaymentMethodsMutationResponseKeySpecifier
)[];
export type PaymentMethodsMutationResponseFieldPolicy = {
  affected_rows?: FieldPolicy<any> | FieldReadFunction<any>;
  returning?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PaymentMethodsStddevFieldsKeySpecifier = (
  | 'id'
  | PaymentMethodsStddevFieldsKeySpecifier
)[];
export type PaymentMethodsStddevFieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PaymentMethodsStddev_popFieldsKeySpecifier = (
  | 'id'
  | PaymentMethodsStddev_popFieldsKeySpecifier
)[];
export type PaymentMethodsStddev_popFieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PaymentMethodsStddev_sampFieldsKeySpecifier = (
  | 'id'
  | PaymentMethodsStddev_sampFieldsKeySpecifier
)[];
export type PaymentMethodsStddev_sampFieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PaymentMethodsSumFieldsKeySpecifier = (
  | 'id'
  | PaymentMethodsSumFieldsKeySpecifier
)[];
export type PaymentMethodsSumFieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PaymentMethodsVar_popFieldsKeySpecifier = (
  | 'id'
  | PaymentMethodsVar_popFieldsKeySpecifier
)[];
export type PaymentMethodsVar_popFieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PaymentMethodsVar_sampFieldsKeySpecifier = (
  | 'id'
  | PaymentMethodsVar_sampFieldsKeySpecifier
)[];
export type PaymentMethodsVar_sampFieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PaymentMethodsVarianceFieldsKeySpecifier = (
  | 'id'
  | PaymentMethodsVarianceFieldsKeySpecifier
)[];
export type PaymentMethodsVarianceFieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PaymentsKeySpecifier = (
  | 'amount'
  | 'amountOut'
  | 'blockchainCurrencyId'
  | 'blockchain_currency'
  | 'createdAt'
  | 'currency'
  | 'currencyId'
  | 'currencySymbol'
  | 'depositAddress'
  | 'fee'
  | 'id'
  | 'paymentMethodBankId'
  | 'paymentMethodId'
  | 'payment_method'
  | 'payment_method_bank'
  | 'status'
  | 'statusInternal'
  | 'tgMsgId'
  | 'twofa'
  | 'txid'
  | 'txlog'
  | 'type'
  | 'updatedAt'
  | 'userId'
  | 'withdrawAddress'
  | PaymentsKeySpecifier
)[];
export type PaymentsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  amountOut?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchainCurrencyId?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchain_currency?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  currency?: FieldPolicy<any> | FieldReadFunction<any>;
  currencyId?: FieldPolicy<any> | FieldReadFunction<any>;
  currencySymbol?: FieldPolicy<any> | FieldReadFunction<any>;
  depositAddress?: FieldPolicy<any> | FieldReadFunction<any>;
  fee?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  paymentMethodBankId?: FieldPolicy<any> | FieldReadFunction<any>;
  paymentMethodId?: FieldPolicy<any> | FieldReadFunction<any>;
  payment_method?: FieldPolicy<any> | FieldReadFunction<any>;
  payment_method_bank?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  statusInternal?: FieldPolicy<any> | FieldReadFunction<any>;
  tgMsgId?: FieldPolicy<any> | FieldReadFunction<any>;
  twofa?: FieldPolicy<any> | FieldReadFunction<any>;
  txid?: FieldPolicy<any> | FieldReadFunction<any>;
  txlog?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  userId?: FieldPolicy<any> | FieldReadFunction<any>;
  withdrawAddress?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PaymentsAggregateKeySpecifier = (
  | 'aggregate'
  | 'nodes'
  | PaymentsAggregateKeySpecifier
)[];
export type PaymentsAggregateFieldPolicy = {
  aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PaymentsAggregateFieldsKeySpecifier = (
  | 'avg'
  | 'count'
  | 'max'
  | 'min'
  | 'stddev'
  | 'stddevPop'
  | 'stddevSamp'
  | 'sum'
  | 'varPop'
  | 'varSamp'
  | 'variance'
  | PaymentsAggregateFieldsKeySpecifier
)[];
export type PaymentsAggregateFieldsFieldPolicy = {
  avg?: FieldPolicy<any> | FieldReadFunction<any>;
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  max?: FieldPolicy<any> | FieldReadFunction<any>;
  min?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev?: FieldPolicy<any> | FieldReadFunction<any>;
  stddevPop?: FieldPolicy<any> | FieldReadFunction<any>;
  stddevSamp?: FieldPolicy<any> | FieldReadFunction<any>;
  sum?: FieldPolicy<any> | FieldReadFunction<any>;
  varPop?: FieldPolicy<any> | FieldReadFunction<any>;
  varSamp?: FieldPolicy<any> | FieldReadFunction<any>;
  variance?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PaymentsAvgFieldsKeySpecifier = (
  | 'amount'
  | 'amountOut'
  | 'blockchainCurrencyId'
  | 'currencyId'
  | 'fee'
  | 'paymentMethodBankId'
  | 'paymentMethodId'
  | 'tgMsgId'
  | PaymentsAvgFieldsKeySpecifier
)[];
export type PaymentsAvgFieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  amountOut?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchainCurrencyId?: FieldPolicy<any> | FieldReadFunction<any>;
  currencyId?: FieldPolicy<any> | FieldReadFunction<any>;
  fee?: FieldPolicy<any> | FieldReadFunction<any>;
  paymentMethodBankId?: FieldPolicy<any> | FieldReadFunction<any>;
  paymentMethodId?: FieldPolicy<any> | FieldReadFunction<any>;
  tgMsgId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PaymentsMaxFieldsKeySpecifier = (
  | 'amount'
  | 'amountOut'
  | 'blockchainCurrencyId'
  | 'createdAt'
  | 'currencyId'
  | 'currencySymbol'
  | 'depositAddress'
  | 'fee'
  | 'id'
  | 'paymentMethodBankId'
  | 'paymentMethodId'
  | 'status'
  | 'statusInternal'
  | 'tgMsgId'
  | 'twofa'
  | 'txid'
  | 'txlog'
  | 'type'
  | 'updatedAt'
  | 'userId'
  | 'withdrawAddress'
  | PaymentsMaxFieldsKeySpecifier
)[];
export type PaymentsMaxFieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  amountOut?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchainCurrencyId?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  currencyId?: FieldPolicy<any> | FieldReadFunction<any>;
  currencySymbol?: FieldPolicy<any> | FieldReadFunction<any>;
  depositAddress?: FieldPolicy<any> | FieldReadFunction<any>;
  fee?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  paymentMethodBankId?: FieldPolicy<any> | FieldReadFunction<any>;
  paymentMethodId?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  statusInternal?: FieldPolicy<any> | FieldReadFunction<any>;
  tgMsgId?: FieldPolicy<any> | FieldReadFunction<any>;
  twofa?: FieldPolicy<any> | FieldReadFunction<any>;
  txid?: FieldPolicy<any> | FieldReadFunction<any>;
  txlog?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  userId?: FieldPolicy<any> | FieldReadFunction<any>;
  withdrawAddress?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PaymentsMinFieldsKeySpecifier = (
  | 'amount'
  | 'amountOut'
  | 'blockchainCurrencyId'
  | 'createdAt'
  | 'currencyId'
  | 'currencySymbol'
  | 'depositAddress'
  | 'fee'
  | 'id'
  | 'paymentMethodBankId'
  | 'paymentMethodId'
  | 'status'
  | 'statusInternal'
  | 'tgMsgId'
  | 'twofa'
  | 'txid'
  | 'txlog'
  | 'type'
  | 'updatedAt'
  | 'userId'
  | 'withdrawAddress'
  | PaymentsMinFieldsKeySpecifier
)[];
export type PaymentsMinFieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  amountOut?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchainCurrencyId?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  currencyId?: FieldPolicy<any> | FieldReadFunction<any>;
  currencySymbol?: FieldPolicy<any> | FieldReadFunction<any>;
  depositAddress?: FieldPolicy<any> | FieldReadFunction<any>;
  fee?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  paymentMethodBankId?: FieldPolicy<any> | FieldReadFunction<any>;
  paymentMethodId?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  statusInternal?: FieldPolicy<any> | FieldReadFunction<any>;
  tgMsgId?: FieldPolicy<any> | FieldReadFunction<any>;
  twofa?: FieldPolicy<any> | FieldReadFunction<any>;
  txid?: FieldPolicy<any> | FieldReadFunction<any>;
  txlog?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  userId?: FieldPolicy<any> | FieldReadFunction<any>;
  withdrawAddress?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PaymentsMutationResponseKeySpecifier = (
  | 'affected_rows'
  | 'returning'
  | PaymentsMutationResponseKeySpecifier
)[];
export type PaymentsMutationResponseFieldPolicy = {
  affected_rows?: FieldPolicy<any> | FieldReadFunction<any>;
  returning?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PaymentsStddevFieldsKeySpecifier = (
  | 'amount'
  | 'amountOut'
  | 'blockchainCurrencyId'
  | 'currencyId'
  | 'fee'
  | 'paymentMethodBankId'
  | 'paymentMethodId'
  | 'tgMsgId'
  | PaymentsStddevFieldsKeySpecifier
)[];
export type PaymentsStddevFieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  amountOut?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchainCurrencyId?: FieldPolicy<any> | FieldReadFunction<any>;
  currencyId?: FieldPolicy<any> | FieldReadFunction<any>;
  fee?: FieldPolicy<any> | FieldReadFunction<any>;
  paymentMethodBankId?: FieldPolicy<any> | FieldReadFunction<any>;
  paymentMethodId?: FieldPolicy<any> | FieldReadFunction<any>;
  tgMsgId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PaymentsStddev_popFieldsKeySpecifier = (
  | 'amount'
  | 'amountOut'
  | 'blockchainCurrencyId'
  | 'currencyId'
  | 'fee'
  | 'paymentMethodBankId'
  | 'paymentMethodId'
  | 'tgMsgId'
  | PaymentsStddev_popFieldsKeySpecifier
)[];
export type PaymentsStddev_popFieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  amountOut?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchainCurrencyId?: FieldPolicy<any> | FieldReadFunction<any>;
  currencyId?: FieldPolicy<any> | FieldReadFunction<any>;
  fee?: FieldPolicy<any> | FieldReadFunction<any>;
  paymentMethodBankId?: FieldPolicy<any> | FieldReadFunction<any>;
  paymentMethodId?: FieldPolicy<any> | FieldReadFunction<any>;
  tgMsgId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PaymentsStddev_sampFieldsKeySpecifier = (
  | 'amount'
  | 'amountOut'
  | 'blockchainCurrencyId'
  | 'currencyId'
  | 'fee'
  | 'paymentMethodBankId'
  | 'paymentMethodId'
  | 'tgMsgId'
  | PaymentsStddev_sampFieldsKeySpecifier
)[];
export type PaymentsStddev_sampFieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  amountOut?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchainCurrencyId?: FieldPolicy<any> | FieldReadFunction<any>;
  currencyId?: FieldPolicy<any> | FieldReadFunction<any>;
  fee?: FieldPolicy<any> | FieldReadFunction<any>;
  paymentMethodBankId?: FieldPolicy<any> | FieldReadFunction<any>;
  paymentMethodId?: FieldPolicy<any> | FieldReadFunction<any>;
  tgMsgId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PaymentsSumFieldsKeySpecifier = (
  | 'amount'
  | 'amountOut'
  | 'blockchainCurrencyId'
  | 'currencyId'
  | 'fee'
  | 'paymentMethodBankId'
  | 'paymentMethodId'
  | 'tgMsgId'
  | PaymentsSumFieldsKeySpecifier
)[];
export type PaymentsSumFieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  amountOut?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchainCurrencyId?: FieldPolicy<any> | FieldReadFunction<any>;
  currencyId?: FieldPolicy<any> | FieldReadFunction<any>;
  fee?: FieldPolicy<any> | FieldReadFunction<any>;
  paymentMethodBankId?: FieldPolicy<any> | FieldReadFunction<any>;
  paymentMethodId?: FieldPolicy<any> | FieldReadFunction<any>;
  tgMsgId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PaymentsVar_popFieldsKeySpecifier = (
  | 'amount'
  | 'amountOut'
  | 'blockchainCurrencyId'
  | 'currencyId'
  | 'fee'
  | 'paymentMethodBankId'
  | 'paymentMethodId'
  | 'tgMsgId'
  | PaymentsVar_popFieldsKeySpecifier
)[];
export type PaymentsVar_popFieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  amountOut?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchainCurrencyId?: FieldPolicy<any> | FieldReadFunction<any>;
  currencyId?: FieldPolicy<any> | FieldReadFunction<any>;
  fee?: FieldPolicy<any> | FieldReadFunction<any>;
  paymentMethodBankId?: FieldPolicy<any> | FieldReadFunction<any>;
  paymentMethodId?: FieldPolicy<any> | FieldReadFunction<any>;
  tgMsgId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PaymentsVar_sampFieldsKeySpecifier = (
  | 'amount'
  | 'amountOut'
  | 'blockchainCurrencyId'
  | 'currencyId'
  | 'fee'
  | 'paymentMethodBankId'
  | 'paymentMethodId'
  | 'tgMsgId'
  | PaymentsVar_sampFieldsKeySpecifier
)[];
export type PaymentsVar_sampFieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  amountOut?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchainCurrencyId?: FieldPolicy<any> | FieldReadFunction<any>;
  currencyId?: FieldPolicy<any> | FieldReadFunction<any>;
  fee?: FieldPolicy<any> | FieldReadFunction<any>;
  paymentMethodBankId?: FieldPolicy<any> | FieldReadFunction<any>;
  paymentMethodId?: FieldPolicy<any> | FieldReadFunction<any>;
  tgMsgId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PaymentsVarianceFieldsKeySpecifier = (
  | 'amount'
  | 'amountOut'
  | 'blockchainCurrencyId'
  | 'currencyId'
  | 'fee'
  | 'paymentMethodBankId'
  | 'paymentMethodId'
  | 'tgMsgId'
  | PaymentsVarianceFieldsKeySpecifier
)[];
export type PaymentsVarianceFieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  amountOut?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchainCurrencyId?: FieldPolicy<any> | FieldReadFunction<any>;
  currencyId?: FieldPolicy<any> | FieldReadFunction<any>;
  fee?: FieldPolicy<any> | FieldReadFunction<any>;
  paymentMethodBankId?: FieldPolicy<any> | FieldReadFunction<any>;
  paymentMethodId?: FieldPolicy<any> | FieldReadFunction<any>;
  tgMsgId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PendingTxsKeySpecifier = (
  | 'amount'
  | 'blockNumber'
  | 'blockchainCurrencyId'
  | 'blockchainId'
  | 'contractAddress'
  | 'createdAt'
  | 'from'
  | 'function'
  | 'hash'
  | 'id'
  | 'timeStamp'
  | 'to'
  | 'tokenSymbol'
  | 'updatedAt'
  | 'wallet'
  | PendingTxsKeySpecifier
)[];
export type PendingTxsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  blockNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchainCurrencyId?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchainId?: FieldPolicy<any> | FieldReadFunction<any>;
  contractAddress?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  from?: FieldPolicy<any> | FieldReadFunction<any>;
  function?: FieldPolicy<any> | FieldReadFunction<any>;
  hash?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  timeStamp?: FieldPolicy<any> | FieldReadFunction<any>;
  to?: FieldPolicy<any> | FieldReadFunction<any>;
  tokenSymbol?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  wallet?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PendingTxsAggregateKeySpecifier = (
  | 'aggregate'
  | 'nodes'
  | PendingTxsAggregateKeySpecifier
)[];
export type PendingTxsAggregateFieldPolicy = {
  aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PendingTxsAggregateFieldsKeySpecifier = (
  | 'avg'
  | 'count'
  | 'max'
  | 'min'
  | 'stddev'
  | 'stddevPop'
  | 'stddevSamp'
  | 'sum'
  | 'varPop'
  | 'varSamp'
  | 'variance'
  | PendingTxsAggregateFieldsKeySpecifier
)[];
export type PendingTxsAggregateFieldsFieldPolicy = {
  avg?: FieldPolicy<any> | FieldReadFunction<any>;
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  max?: FieldPolicy<any> | FieldReadFunction<any>;
  min?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev?: FieldPolicy<any> | FieldReadFunction<any>;
  stddevPop?: FieldPolicy<any> | FieldReadFunction<any>;
  stddevSamp?: FieldPolicy<any> | FieldReadFunction<any>;
  sum?: FieldPolicy<any> | FieldReadFunction<any>;
  varPop?: FieldPolicy<any> | FieldReadFunction<any>;
  varSamp?: FieldPolicy<any> | FieldReadFunction<any>;
  variance?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PendingTxsAvgFieldsKeySpecifier = (
  | 'amount'
  | 'blockNumber'
  | 'blockchainCurrencyId'
  | 'blockchainId'
  | 'id'
  | 'timeStamp'
  | PendingTxsAvgFieldsKeySpecifier
)[];
export type PendingTxsAvgFieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  blockNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchainCurrencyId?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchainId?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  timeStamp?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PendingTxsMaxFieldsKeySpecifier = (
  | 'amount'
  | 'blockNumber'
  | 'blockchainCurrencyId'
  | 'blockchainId'
  | 'contractAddress'
  | 'createdAt'
  | 'from'
  | 'function'
  | 'hash'
  | 'id'
  | 'timeStamp'
  | 'to'
  | 'tokenSymbol'
  | 'updatedAt'
  | 'wallet'
  | PendingTxsMaxFieldsKeySpecifier
)[];
export type PendingTxsMaxFieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  blockNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchainCurrencyId?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchainId?: FieldPolicy<any> | FieldReadFunction<any>;
  contractAddress?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  from?: FieldPolicy<any> | FieldReadFunction<any>;
  function?: FieldPolicy<any> | FieldReadFunction<any>;
  hash?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  timeStamp?: FieldPolicy<any> | FieldReadFunction<any>;
  to?: FieldPolicy<any> | FieldReadFunction<any>;
  tokenSymbol?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  wallet?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PendingTxsMinFieldsKeySpecifier = (
  | 'amount'
  | 'blockNumber'
  | 'blockchainCurrencyId'
  | 'blockchainId'
  | 'contractAddress'
  | 'createdAt'
  | 'from'
  | 'function'
  | 'hash'
  | 'id'
  | 'timeStamp'
  | 'to'
  | 'tokenSymbol'
  | 'updatedAt'
  | 'wallet'
  | PendingTxsMinFieldsKeySpecifier
)[];
export type PendingTxsMinFieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  blockNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchainCurrencyId?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchainId?: FieldPolicy<any> | FieldReadFunction<any>;
  contractAddress?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  from?: FieldPolicy<any> | FieldReadFunction<any>;
  function?: FieldPolicy<any> | FieldReadFunction<any>;
  hash?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  timeStamp?: FieldPolicy<any> | FieldReadFunction<any>;
  to?: FieldPolicy<any> | FieldReadFunction<any>;
  tokenSymbol?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  wallet?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PendingTxsMutationResponseKeySpecifier = (
  | 'affected_rows'
  | 'returning'
  | PendingTxsMutationResponseKeySpecifier
)[];
export type PendingTxsMutationResponseFieldPolicy = {
  affected_rows?: FieldPolicy<any> | FieldReadFunction<any>;
  returning?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PendingTxsStddevFieldsKeySpecifier = (
  | 'amount'
  | 'blockNumber'
  | 'blockchainCurrencyId'
  | 'blockchainId'
  | 'id'
  | 'timeStamp'
  | PendingTxsStddevFieldsKeySpecifier
)[];
export type PendingTxsStddevFieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  blockNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchainCurrencyId?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchainId?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  timeStamp?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PendingTxsStddev_popFieldsKeySpecifier = (
  | 'amount'
  | 'blockNumber'
  | 'blockchainCurrencyId'
  | 'blockchainId'
  | 'id'
  | 'timeStamp'
  | PendingTxsStddev_popFieldsKeySpecifier
)[];
export type PendingTxsStddev_popFieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  blockNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchainCurrencyId?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchainId?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  timeStamp?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PendingTxsStddev_sampFieldsKeySpecifier = (
  | 'amount'
  | 'blockNumber'
  | 'blockchainCurrencyId'
  | 'blockchainId'
  | 'id'
  | 'timeStamp'
  | PendingTxsStddev_sampFieldsKeySpecifier
)[];
export type PendingTxsStddev_sampFieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  blockNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchainCurrencyId?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchainId?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  timeStamp?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PendingTxsSumFieldsKeySpecifier = (
  | 'amount'
  | 'blockNumber'
  | 'blockchainCurrencyId'
  | 'blockchainId'
  | 'id'
  | 'timeStamp'
  | PendingTxsSumFieldsKeySpecifier
)[];
export type PendingTxsSumFieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  blockNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchainCurrencyId?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchainId?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  timeStamp?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PendingTxsVar_popFieldsKeySpecifier = (
  | 'amount'
  | 'blockNumber'
  | 'blockchainCurrencyId'
  | 'blockchainId'
  | 'id'
  | 'timeStamp'
  | PendingTxsVar_popFieldsKeySpecifier
)[];
export type PendingTxsVar_popFieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  blockNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchainCurrencyId?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchainId?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  timeStamp?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PendingTxsVar_sampFieldsKeySpecifier = (
  | 'amount'
  | 'blockNumber'
  | 'blockchainCurrencyId'
  | 'blockchainId'
  | 'id'
  | 'timeStamp'
  | PendingTxsVar_sampFieldsKeySpecifier
)[];
export type PendingTxsVar_sampFieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  blockNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchainCurrencyId?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchainId?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  timeStamp?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PendingTxsVarianceFieldsKeySpecifier = (
  | 'amount'
  | 'blockNumber'
  | 'blockchainCurrencyId'
  | 'blockchainId'
  | 'id'
  | 'timeStamp'
  | PendingTxsVarianceFieldsKeySpecifier
)[];
export type PendingTxsVarianceFieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  blockNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchainCurrencyId?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchainId?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  timeStamp?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PlayerFollowersKeySpecifier = (
  | 'createdAt'
  | 'id'
  | 'playerId'
  | 'updatedAt'
  | 'userId'
  | PlayerFollowersKeySpecifier
)[];
export type PlayerFollowersFieldPolicy = {
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  playerId?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  userId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PlayerFollowersAggregateKeySpecifier = (
  | 'aggregate'
  | 'nodes'
  | PlayerFollowersAggregateKeySpecifier
)[];
export type PlayerFollowersAggregateFieldPolicy = {
  aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PlayerFollowersAggregateFieldsKeySpecifier = (
  | 'avg'
  | 'count'
  | 'max'
  | 'min'
  | 'stddev'
  | 'stddevPop'
  | 'stddevSamp'
  | 'sum'
  | 'varPop'
  | 'varSamp'
  | 'variance'
  | PlayerFollowersAggregateFieldsKeySpecifier
)[];
export type PlayerFollowersAggregateFieldsFieldPolicy = {
  avg?: FieldPolicy<any> | FieldReadFunction<any>;
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  max?: FieldPolicy<any> | FieldReadFunction<any>;
  min?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev?: FieldPolicy<any> | FieldReadFunction<any>;
  stddevPop?: FieldPolicy<any> | FieldReadFunction<any>;
  stddevSamp?: FieldPolicy<any> | FieldReadFunction<any>;
  sum?: FieldPolicy<any> | FieldReadFunction<any>;
  varPop?: FieldPolicy<any> | FieldReadFunction<any>;
  varSamp?: FieldPolicy<any> | FieldReadFunction<any>;
  variance?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PlayerFollowersAvgFieldsKeySpecifier = (
  | 'id'
  | PlayerFollowersAvgFieldsKeySpecifier
)[];
export type PlayerFollowersAvgFieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PlayerFollowersMaxFieldsKeySpecifier = (
  | 'createdAt'
  | 'id'
  | 'playerId'
  | 'updatedAt'
  | 'userId'
  | PlayerFollowersMaxFieldsKeySpecifier
)[];
export type PlayerFollowersMaxFieldsFieldPolicy = {
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  playerId?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  userId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PlayerFollowersMinFieldsKeySpecifier = (
  | 'createdAt'
  | 'id'
  | 'playerId'
  | 'updatedAt'
  | 'userId'
  | PlayerFollowersMinFieldsKeySpecifier
)[];
export type PlayerFollowersMinFieldsFieldPolicy = {
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  playerId?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  userId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PlayerFollowersMutationResponseKeySpecifier = (
  | 'affected_rows'
  | 'returning'
  | PlayerFollowersMutationResponseKeySpecifier
)[];
export type PlayerFollowersMutationResponseFieldPolicy = {
  affected_rows?: FieldPolicy<any> | FieldReadFunction<any>;
  returning?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PlayerFollowersStddevFieldsKeySpecifier = (
  | 'id'
  | PlayerFollowersStddevFieldsKeySpecifier
)[];
export type PlayerFollowersStddevFieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PlayerFollowersStddev_popFieldsKeySpecifier = (
  | 'id'
  | PlayerFollowersStddev_popFieldsKeySpecifier
)[];
export type PlayerFollowersStddev_popFieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PlayerFollowersStddev_sampFieldsKeySpecifier = (
  | 'id'
  | PlayerFollowersStddev_sampFieldsKeySpecifier
)[];
export type PlayerFollowersStddev_sampFieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PlayerFollowersSumFieldsKeySpecifier = (
  | 'id'
  | PlayerFollowersSumFieldsKeySpecifier
)[];
export type PlayerFollowersSumFieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PlayerFollowersVar_popFieldsKeySpecifier = (
  | 'id'
  | PlayerFollowersVar_popFieldsKeySpecifier
)[];
export type PlayerFollowersVar_popFieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PlayerFollowersVar_sampFieldsKeySpecifier = (
  | 'id'
  | PlayerFollowersVar_sampFieldsKeySpecifier
)[];
export type PlayerFollowersVar_sampFieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PlayerFollowersVarianceFieldsKeySpecifier = (
  | 'id'
  | PlayerFollowersVarianceFieldsKeySpecifier
)[];
export type PlayerFollowersVarianceFieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PlayerFollowersViewKeySpecifier = (
  | 'avatar'
  | 'email'
  | 'erc20Address'
  | 'id'
  | 'player'
  | 'playerId'
  | 'username'
  | PlayerFollowersViewKeySpecifier
)[];
export type PlayerFollowersViewFieldPolicy = {
  avatar?: FieldPolicy<any> | FieldReadFunction<any>;
  email?: FieldPolicy<any> | FieldReadFunction<any>;
  erc20Address?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  player?: FieldPolicy<any> | FieldReadFunction<any>;
  playerId?: FieldPolicy<any> | FieldReadFunction<any>;
  username?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PlayerFollowersViewAggregateKeySpecifier = (
  | 'aggregate'
  | 'nodes'
  | PlayerFollowersViewAggregateKeySpecifier
)[];
export type PlayerFollowersViewAggregateFieldPolicy = {
  aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PlayerFollowersViewAggregateFieldsKeySpecifier = (
  | 'avg'
  | 'count'
  | 'max'
  | 'min'
  | 'stddev'
  | 'stddevPop'
  | 'stddevSamp'
  | 'sum'
  | 'varPop'
  | 'varSamp'
  | 'variance'
  | PlayerFollowersViewAggregateFieldsKeySpecifier
)[];
export type PlayerFollowersViewAggregateFieldsFieldPolicy = {
  avg?: FieldPolicy<any> | FieldReadFunction<any>;
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  max?: FieldPolicy<any> | FieldReadFunction<any>;
  min?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev?: FieldPolicy<any> | FieldReadFunction<any>;
  stddevPop?: FieldPolicy<any> | FieldReadFunction<any>;
  stddevSamp?: FieldPolicy<any> | FieldReadFunction<any>;
  sum?: FieldPolicy<any> | FieldReadFunction<any>;
  varPop?: FieldPolicy<any> | FieldReadFunction<any>;
  varSamp?: FieldPolicy<any> | FieldReadFunction<any>;
  variance?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PlayerFollowersViewAvgFieldsKeySpecifier = (
  | 'id'
  | PlayerFollowersViewAvgFieldsKeySpecifier
)[];
export type PlayerFollowersViewAvgFieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PlayerFollowersViewMaxFieldsKeySpecifier = (
  | 'avatar'
  | 'email'
  | 'erc20Address'
  | 'id'
  | 'playerId'
  | 'username'
  | PlayerFollowersViewMaxFieldsKeySpecifier
)[];
export type PlayerFollowersViewMaxFieldsFieldPolicy = {
  avatar?: FieldPolicy<any> | FieldReadFunction<any>;
  email?: FieldPolicy<any> | FieldReadFunction<any>;
  erc20Address?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  playerId?: FieldPolicy<any> | FieldReadFunction<any>;
  username?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PlayerFollowersViewMinFieldsKeySpecifier = (
  | 'avatar'
  | 'email'
  | 'erc20Address'
  | 'id'
  | 'playerId'
  | 'username'
  | PlayerFollowersViewMinFieldsKeySpecifier
)[];
export type PlayerFollowersViewMinFieldsFieldPolicy = {
  avatar?: FieldPolicy<any> | FieldReadFunction<any>;
  email?: FieldPolicy<any> | FieldReadFunction<any>;
  erc20Address?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  playerId?: FieldPolicy<any> | FieldReadFunction<any>;
  username?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PlayerFollowersViewStddevFieldsKeySpecifier = (
  | 'id'
  | PlayerFollowersViewStddevFieldsKeySpecifier
)[];
export type PlayerFollowersViewStddevFieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PlayerFollowersViewStddev_popFieldsKeySpecifier = (
  | 'id'
  | PlayerFollowersViewStddev_popFieldsKeySpecifier
)[];
export type PlayerFollowersViewStddev_popFieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PlayerFollowersViewStddev_sampFieldsKeySpecifier = (
  | 'id'
  | PlayerFollowersViewStddev_sampFieldsKeySpecifier
)[];
export type PlayerFollowersViewStddev_sampFieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PlayerFollowersViewSumFieldsKeySpecifier = (
  | 'id'
  | PlayerFollowersViewSumFieldsKeySpecifier
)[];
export type PlayerFollowersViewSumFieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PlayerFollowersViewVar_popFieldsKeySpecifier = (
  | 'id'
  | PlayerFollowersViewVar_popFieldsKeySpecifier
)[];
export type PlayerFollowersViewVar_popFieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PlayerFollowersViewVar_sampFieldsKeySpecifier = (
  | 'id'
  | PlayerFollowersViewVar_sampFieldsKeySpecifier
)[];
export type PlayerFollowersViewVar_sampFieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PlayerFollowersViewVarianceFieldsKeySpecifier = (
  | 'id'
  | PlayerFollowersViewVarianceFieldsKeySpecifier
)[];
export type PlayerFollowersViewVarianceFieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PlayerSharesKeySpecifier = (
  | 'activeDate'
  | 'allowCancel'
  | 'bullets'
  | 'createdAt'
  | 'id'
  | 'markUp'
  | 'maxPerUserShare'
  | 'note'
  | 'password'
  | 'passwordRequired'
  | 'player'
  | 'playerId'
  | 'player_shares_bought'
  | 'shareDilution'
  | 'sharesToSell'
  | 'slug'
  | 'sort'
  | 'status'
  | 'termsAcceptedDate'
  | 'tickets'
  | 'ticketsAggregate'
  | 'tournament'
  | 'tournamentId'
  | 'tournamentResultId'
  | 'tournament_result'
  | 'transactions'
  | 'transactionsAggregate'
  | 'updatedAt'
  | 'userShareBuysAggregate'
  | 'user_share_buys'
  | PlayerSharesKeySpecifier
)[];
export type PlayerSharesFieldPolicy = {
  activeDate?: FieldPolicy<any> | FieldReadFunction<any>;
  allowCancel?: FieldPolicy<any> | FieldReadFunction<any>;
  bullets?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  markUp?: FieldPolicy<any> | FieldReadFunction<any>;
  maxPerUserShare?: FieldPolicy<any> | FieldReadFunction<any>;
  note?: FieldPolicy<any> | FieldReadFunction<any>;
  password?: FieldPolicy<any> | FieldReadFunction<any>;
  passwordRequired?: FieldPolicy<any> | FieldReadFunction<any>;
  player?: FieldPolicy<any> | FieldReadFunction<any>;
  playerId?: FieldPolicy<any> | FieldReadFunction<any>;
  player_shares_bought?: FieldPolicy<any> | FieldReadFunction<any>;
  shareDilution?: FieldPolicy<any> | FieldReadFunction<any>;
  sharesToSell?: FieldPolicy<any> | FieldReadFunction<any>;
  slug?: FieldPolicy<any> | FieldReadFunction<any>;
  sort?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  termsAcceptedDate?: FieldPolicy<any> | FieldReadFunction<any>;
  tickets?: FieldPolicy<any> | FieldReadFunction<any>;
  ticketsAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  tournament?: FieldPolicy<any> | FieldReadFunction<any>;
  tournamentId?: FieldPolicy<any> | FieldReadFunction<any>;
  tournamentResultId?: FieldPolicy<any> | FieldReadFunction<any>;
  tournament_result?: FieldPolicy<any> | FieldReadFunction<any>;
  transactions?: FieldPolicy<any> | FieldReadFunction<any>;
  transactionsAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  userShareBuysAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  user_share_buys?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PlayerSharesAggregateKeySpecifier = (
  | 'aggregate'
  | 'nodes'
  | PlayerSharesAggregateKeySpecifier
)[];
export type PlayerSharesAggregateFieldPolicy = {
  aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PlayerSharesAggregateFieldsKeySpecifier = (
  | 'avg'
  | 'count'
  | 'max'
  | 'min'
  | 'stddev'
  | 'stddevPop'
  | 'stddevSamp'
  | 'sum'
  | 'varPop'
  | 'varSamp'
  | 'variance'
  | PlayerSharesAggregateFieldsKeySpecifier
)[];
export type PlayerSharesAggregateFieldsFieldPolicy = {
  avg?: FieldPolicy<any> | FieldReadFunction<any>;
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  max?: FieldPolicy<any> | FieldReadFunction<any>;
  min?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev?: FieldPolicy<any> | FieldReadFunction<any>;
  stddevPop?: FieldPolicy<any> | FieldReadFunction<any>;
  stddevSamp?: FieldPolicy<any> | FieldReadFunction<any>;
  sum?: FieldPolicy<any> | FieldReadFunction<any>;
  varPop?: FieldPolicy<any> | FieldReadFunction<any>;
  varSamp?: FieldPolicy<any> | FieldReadFunction<any>;
  variance?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PlayerSharesAvgFieldsKeySpecifier = (
  | 'bullets'
  | 'markUp'
  | 'maxPerUserShare'
  | 'sharesToSell'
  | 'sort'
  | 'tournamentResultId'
  | PlayerSharesAvgFieldsKeySpecifier
)[];
export type PlayerSharesAvgFieldsFieldPolicy = {
  bullets?: FieldPolicy<any> | FieldReadFunction<any>;
  markUp?: FieldPolicy<any> | FieldReadFunction<any>;
  maxPerUserShare?: FieldPolicy<any> | FieldReadFunction<any>;
  sharesToSell?: FieldPolicy<any> | FieldReadFunction<any>;
  sort?: FieldPolicy<any> | FieldReadFunction<any>;
  tournamentResultId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PlayerSharesBoughtKeySpecifier = (
  | 'playerShareId'
  | 'totalMarkupAmount'
  | 'totalShareAmount'
  | 'totalSharePercentage'
  | 'totalTotalAmount'
  | PlayerSharesBoughtKeySpecifier
)[];
export type PlayerSharesBoughtFieldPolicy = {
  playerShareId?: FieldPolicy<any> | FieldReadFunction<any>;
  totalMarkupAmount?: FieldPolicy<any> | FieldReadFunction<any>;
  totalShareAmount?: FieldPolicy<any> | FieldReadFunction<any>;
  totalSharePercentage?: FieldPolicy<any> | FieldReadFunction<any>;
  totalTotalAmount?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PlayerSharesBoughtAggregateKeySpecifier = (
  | 'aggregate'
  | 'nodes'
  | PlayerSharesBoughtAggregateKeySpecifier
)[];
export type PlayerSharesBoughtAggregateFieldPolicy = {
  aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PlayerSharesBoughtAggregateFieldsKeySpecifier = (
  | 'avg'
  | 'count'
  | 'max'
  | 'min'
  | 'stddev'
  | 'stddevPop'
  | 'stddevSamp'
  | 'sum'
  | 'varPop'
  | 'varSamp'
  | 'variance'
  | PlayerSharesBoughtAggregateFieldsKeySpecifier
)[];
export type PlayerSharesBoughtAggregateFieldsFieldPolicy = {
  avg?: FieldPolicy<any> | FieldReadFunction<any>;
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  max?: FieldPolicy<any> | FieldReadFunction<any>;
  min?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev?: FieldPolicy<any> | FieldReadFunction<any>;
  stddevPop?: FieldPolicy<any> | FieldReadFunction<any>;
  stddevSamp?: FieldPolicy<any> | FieldReadFunction<any>;
  sum?: FieldPolicy<any> | FieldReadFunction<any>;
  varPop?: FieldPolicy<any> | FieldReadFunction<any>;
  varSamp?: FieldPolicy<any> | FieldReadFunction<any>;
  variance?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PlayerSharesBoughtAvgFieldsKeySpecifier = (
  | 'totalMarkupAmount'
  | 'totalShareAmount'
  | 'totalSharePercentage'
  | 'totalTotalAmount'
  | PlayerSharesBoughtAvgFieldsKeySpecifier
)[];
export type PlayerSharesBoughtAvgFieldsFieldPolicy = {
  totalMarkupAmount?: FieldPolicy<any> | FieldReadFunction<any>;
  totalShareAmount?: FieldPolicy<any> | FieldReadFunction<any>;
  totalSharePercentage?: FieldPolicy<any> | FieldReadFunction<any>;
  totalTotalAmount?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PlayerSharesBoughtMaxFieldsKeySpecifier = (
  | 'playerShareId'
  | 'totalMarkupAmount'
  | 'totalShareAmount'
  | 'totalSharePercentage'
  | 'totalTotalAmount'
  | PlayerSharesBoughtMaxFieldsKeySpecifier
)[];
export type PlayerSharesBoughtMaxFieldsFieldPolicy = {
  playerShareId?: FieldPolicy<any> | FieldReadFunction<any>;
  totalMarkupAmount?: FieldPolicy<any> | FieldReadFunction<any>;
  totalShareAmount?: FieldPolicy<any> | FieldReadFunction<any>;
  totalSharePercentage?: FieldPolicy<any> | FieldReadFunction<any>;
  totalTotalAmount?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PlayerSharesBoughtMinFieldsKeySpecifier = (
  | 'playerShareId'
  | 'totalMarkupAmount'
  | 'totalShareAmount'
  | 'totalSharePercentage'
  | 'totalTotalAmount'
  | PlayerSharesBoughtMinFieldsKeySpecifier
)[];
export type PlayerSharesBoughtMinFieldsFieldPolicy = {
  playerShareId?: FieldPolicy<any> | FieldReadFunction<any>;
  totalMarkupAmount?: FieldPolicy<any> | FieldReadFunction<any>;
  totalShareAmount?: FieldPolicy<any> | FieldReadFunction<any>;
  totalSharePercentage?: FieldPolicy<any> | FieldReadFunction<any>;
  totalTotalAmount?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PlayerSharesBoughtStddevFieldsKeySpecifier = (
  | 'totalMarkupAmount'
  | 'totalShareAmount'
  | 'totalSharePercentage'
  | 'totalTotalAmount'
  | PlayerSharesBoughtStddevFieldsKeySpecifier
)[];
export type PlayerSharesBoughtStddevFieldsFieldPolicy = {
  totalMarkupAmount?: FieldPolicy<any> | FieldReadFunction<any>;
  totalShareAmount?: FieldPolicy<any> | FieldReadFunction<any>;
  totalSharePercentage?: FieldPolicy<any> | FieldReadFunction<any>;
  totalTotalAmount?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PlayerSharesBoughtStddev_popFieldsKeySpecifier = (
  | 'totalMarkupAmount'
  | 'totalShareAmount'
  | 'totalSharePercentage'
  | 'totalTotalAmount'
  | PlayerSharesBoughtStddev_popFieldsKeySpecifier
)[];
export type PlayerSharesBoughtStddev_popFieldsFieldPolicy = {
  totalMarkupAmount?: FieldPolicy<any> | FieldReadFunction<any>;
  totalShareAmount?: FieldPolicy<any> | FieldReadFunction<any>;
  totalSharePercentage?: FieldPolicy<any> | FieldReadFunction<any>;
  totalTotalAmount?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PlayerSharesBoughtStddev_sampFieldsKeySpecifier = (
  | 'totalMarkupAmount'
  | 'totalShareAmount'
  | 'totalSharePercentage'
  | 'totalTotalAmount'
  | PlayerSharesBoughtStddev_sampFieldsKeySpecifier
)[];
export type PlayerSharesBoughtStddev_sampFieldsFieldPolicy = {
  totalMarkupAmount?: FieldPolicy<any> | FieldReadFunction<any>;
  totalShareAmount?: FieldPolicy<any> | FieldReadFunction<any>;
  totalSharePercentage?: FieldPolicy<any> | FieldReadFunction<any>;
  totalTotalAmount?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PlayerSharesBoughtSumFieldsKeySpecifier = (
  | 'totalMarkupAmount'
  | 'totalShareAmount'
  | 'totalSharePercentage'
  | 'totalTotalAmount'
  | PlayerSharesBoughtSumFieldsKeySpecifier
)[];
export type PlayerSharesBoughtSumFieldsFieldPolicy = {
  totalMarkupAmount?: FieldPolicy<any> | FieldReadFunction<any>;
  totalShareAmount?: FieldPolicy<any> | FieldReadFunction<any>;
  totalSharePercentage?: FieldPolicy<any> | FieldReadFunction<any>;
  totalTotalAmount?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PlayerSharesBoughtVar_popFieldsKeySpecifier = (
  | 'totalMarkupAmount'
  | 'totalShareAmount'
  | 'totalSharePercentage'
  | 'totalTotalAmount'
  | PlayerSharesBoughtVar_popFieldsKeySpecifier
)[];
export type PlayerSharesBoughtVar_popFieldsFieldPolicy = {
  totalMarkupAmount?: FieldPolicy<any> | FieldReadFunction<any>;
  totalShareAmount?: FieldPolicy<any> | FieldReadFunction<any>;
  totalSharePercentage?: FieldPolicy<any> | FieldReadFunction<any>;
  totalTotalAmount?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PlayerSharesBoughtVar_sampFieldsKeySpecifier = (
  | 'totalMarkupAmount'
  | 'totalShareAmount'
  | 'totalSharePercentage'
  | 'totalTotalAmount'
  | PlayerSharesBoughtVar_sampFieldsKeySpecifier
)[];
export type PlayerSharesBoughtVar_sampFieldsFieldPolicy = {
  totalMarkupAmount?: FieldPolicy<any> | FieldReadFunction<any>;
  totalShareAmount?: FieldPolicy<any> | FieldReadFunction<any>;
  totalSharePercentage?: FieldPolicy<any> | FieldReadFunction<any>;
  totalTotalAmount?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PlayerSharesBoughtVarianceFieldsKeySpecifier = (
  | 'totalMarkupAmount'
  | 'totalShareAmount'
  | 'totalSharePercentage'
  | 'totalTotalAmount'
  | PlayerSharesBoughtVarianceFieldsKeySpecifier
)[];
export type PlayerSharesBoughtVarianceFieldsFieldPolicy = {
  totalMarkupAmount?: FieldPolicy<any> | FieldReadFunction<any>;
  totalShareAmount?: FieldPolicy<any> | FieldReadFunction<any>;
  totalSharePercentage?: FieldPolicy<any> | FieldReadFunction<any>;
  totalTotalAmount?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PlayerSharesMaxFieldsKeySpecifier = (
  | 'activeDate'
  | 'bullets'
  | 'createdAt'
  | 'id'
  | 'markUp'
  | 'maxPerUserShare'
  | 'note'
  | 'password'
  | 'playerId'
  | 'sharesToSell'
  | 'slug'
  | 'sort'
  | 'termsAcceptedDate'
  | 'tournamentId'
  | 'tournamentResultId'
  | 'updatedAt'
  | PlayerSharesMaxFieldsKeySpecifier
)[];
export type PlayerSharesMaxFieldsFieldPolicy = {
  activeDate?: FieldPolicy<any> | FieldReadFunction<any>;
  bullets?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  markUp?: FieldPolicy<any> | FieldReadFunction<any>;
  maxPerUserShare?: FieldPolicy<any> | FieldReadFunction<any>;
  note?: FieldPolicy<any> | FieldReadFunction<any>;
  password?: FieldPolicy<any> | FieldReadFunction<any>;
  playerId?: FieldPolicy<any> | FieldReadFunction<any>;
  sharesToSell?: FieldPolicy<any> | FieldReadFunction<any>;
  slug?: FieldPolicy<any> | FieldReadFunction<any>;
  sort?: FieldPolicy<any> | FieldReadFunction<any>;
  termsAcceptedDate?: FieldPolicy<any> | FieldReadFunction<any>;
  tournamentId?: FieldPolicy<any> | FieldReadFunction<any>;
  tournamentResultId?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PlayerSharesMinFieldsKeySpecifier = (
  | 'activeDate'
  | 'bullets'
  | 'createdAt'
  | 'id'
  | 'markUp'
  | 'maxPerUserShare'
  | 'note'
  | 'password'
  | 'playerId'
  | 'sharesToSell'
  | 'slug'
  | 'sort'
  | 'termsAcceptedDate'
  | 'tournamentId'
  | 'tournamentResultId'
  | 'updatedAt'
  | PlayerSharesMinFieldsKeySpecifier
)[];
export type PlayerSharesMinFieldsFieldPolicy = {
  activeDate?: FieldPolicy<any> | FieldReadFunction<any>;
  bullets?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  markUp?: FieldPolicy<any> | FieldReadFunction<any>;
  maxPerUserShare?: FieldPolicy<any> | FieldReadFunction<any>;
  note?: FieldPolicy<any> | FieldReadFunction<any>;
  password?: FieldPolicy<any> | FieldReadFunction<any>;
  playerId?: FieldPolicy<any> | FieldReadFunction<any>;
  sharesToSell?: FieldPolicy<any> | FieldReadFunction<any>;
  slug?: FieldPolicy<any> | FieldReadFunction<any>;
  sort?: FieldPolicy<any> | FieldReadFunction<any>;
  termsAcceptedDate?: FieldPolicy<any> | FieldReadFunction<any>;
  tournamentId?: FieldPolicy<any> | FieldReadFunction<any>;
  tournamentResultId?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PlayerSharesMutationResponseKeySpecifier = (
  | 'affected_rows'
  | 'returning'
  | PlayerSharesMutationResponseKeySpecifier
)[];
export type PlayerSharesMutationResponseFieldPolicy = {
  affected_rows?: FieldPolicy<any> | FieldReadFunction<any>;
  returning?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PlayerSharesStddevFieldsKeySpecifier = (
  | 'bullets'
  | 'markUp'
  | 'maxPerUserShare'
  | 'sharesToSell'
  | 'sort'
  | 'tournamentResultId'
  | PlayerSharesStddevFieldsKeySpecifier
)[];
export type PlayerSharesStddevFieldsFieldPolicy = {
  bullets?: FieldPolicy<any> | FieldReadFunction<any>;
  markUp?: FieldPolicy<any> | FieldReadFunction<any>;
  maxPerUserShare?: FieldPolicy<any> | FieldReadFunction<any>;
  sharesToSell?: FieldPolicy<any> | FieldReadFunction<any>;
  sort?: FieldPolicy<any> | FieldReadFunction<any>;
  tournamentResultId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PlayerSharesStddev_popFieldsKeySpecifier = (
  | 'bullets'
  | 'markUp'
  | 'maxPerUserShare'
  | 'sharesToSell'
  | 'sort'
  | 'tournamentResultId'
  | PlayerSharesStddev_popFieldsKeySpecifier
)[];
export type PlayerSharesStddev_popFieldsFieldPolicy = {
  bullets?: FieldPolicy<any> | FieldReadFunction<any>;
  markUp?: FieldPolicy<any> | FieldReadFunction<any>;
  maxPerUserShare?: FieldPolicy<any> | FieldReadFunction<any>;
  sharesToSell?: FieldPolicy<any> | FieldReadFunction<any>;
  sort?: FieldPolicy<any> | FieldReadFunction<any>;
  tournamentResultId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PlayerSharesStddev_sampFieldsKeySpecifier = (
  | 'bullets'
  | 'markUp'
  | 'maxPerUserShare'
  | 'sharesToSell'
  | 'sort'
  | 'tournamentResultId'
  | PlayerSharesStddev_sampFieldsKeySpecifier
)[];
export type PlayerSharesStddev_sampFieldsFieldPolicy = {
  bullets?: FieldPolicy<any> | FieldReadFunction<any>;
  markUp?: FieldPolicy<any> | FieldReadFunction<any>;
  maxPerUserShare?: FieldPolicy<any> | FieldReadFunction<any>;
  sharesToSell?: FieldPolicy<any> | FieldReadFunction<any>;
  sort?: FieldPolicy<any> | FieldReadFunction<any>;
  tournamentResultId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PlayerSharesSumFieldsKeySpecifier = (
  | 'bullets'
  | 'markUp'
  | 'maxPerUserShare'
  | 'sharesToSell'
  | 'sort'
  | 'tournamentResultId'
  | PlayerSharesSumFieldsKeySpecifier
)[];
export type PlayerSharesSumFieldsFieldPolicy = {
  bullets?: FieldPolicy<any> | FieldReadFunction<any>;
  markUp?: FieldPolicy<any> | FieldReadFunction<any>;
  maxPerUserShare?: FieldPolicy<any> | FieldReadFunction<any>;
  sharesToSell?: FieldPolicy<any> | FieldReadFunction<any>;
  sort?: FieldPolicy<any> | FieldReadFunction<any>;
  tournamentResultId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PlayerSharesVar_popFieldsKeySpecifier = (
  | 'bullets'
  | 'markUp'
  | 'maxPerUserShare'
  | 'sharesToSell'
  | 'sort'
  | 'tournamentResultId'
  | PlayerSharesVar_popFieldsKeySpecifier
)[];
export type PlayerSharesVar_popFieldsFieldPolicy = {
  bullets?: FieldPolicy<any> | FieldReadFunction<any>;
  markUp?: FieldPolicy<any> | FieldReadFunction<any>;
  maxPerUserShare?: FieldPolicy<any> | FieldReadFunction<any>;
  sharesToSell?: FieldPolicy<any> | FieldReadFunction<any>;
  sort?: FieldPolicy<any> | FieldReadFunction<any>;
  tournamentResultId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PlayerSharesVar_sampFieldsKeySpecifier = (
  | 'bullets'
  | 'markUp'
  | 'maxPerUserShare'
  | 'sharesToSell'
  | 'sort'
  | 'tournamentResultId'
  | PlayerSharesVar_sampFieldsKeySpecifier
)[];
export type PlayerSharesVar_sampFieldsFieldPolicy = {
  bullets?: FieldPolicy<any> | FieldReadFunction<any>;
  markUp?: FieldPolicy<any> | FieldReadFunction<any>;
  maxPerUserShare?: FieldPolicy<any> | FieldReadFunction<any>;
  sharesToSell?: FieldPolicy<any> | FieldReadFunction<any>;
  sort?: FieldPolicy<any> | FieldReadFunction<any>;
  tournamentResultId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PlayerSharesVarianceFieldsKeySpecifier = (
  | 'bullets'
  | 'markUp'
  | 'maxPerUserShare'
  | 'sharesToSell'
  | 'sort'
  | 'tournamentResultId'
  | PlayerSharesVarianceFieldsKeySpecifier
)[];
export type PlayerSharesVarianceFieldsFieldPolicy = {
  bullets?: FieldPolicy<any> | FieldReadFunction<any>;
  markUp?: FieldPolicy<any> | FieldReadFunction<any>;
  maxPerUserShare?: FieldPolicy<any> | FieldReadFunction<any>;
  sharesToSell?: FieldPolicy<any> | FieldReadFunction<any>;
  sort?: FieldPolicy<any> | FieldReadFunction<any>;
  tournamentResultId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PlayersKeySpecifier = (
  | 'actionsSold'
  | 'allTimeList'
  | 'allTimeListCountry'
  | 'allTimeListCountryPlace'
  | 'birthday'
  | 'createdAt'
  | 'description'
  | 'facebook'
  | 'followers'
  | 'followersAggregate'
  | 'followersCount'
  | 'gender'
  | 'globalpokerindexUrl'
  | 'gpiRanking'
  | 'highestRoi'
  | 'highestWinning'
  | 'id'
  | 'image'
  | 'instagram'
  | 'name'
  | 'nationality'
  | 'playerFollowersViewAggregate'
  | 'playerSharesAggregate'
  | 'player_followers_view'
  | 'player_shares'
  | 'realName'
  | 'residence'
  | 'slug'
  | 'sort'
  | 'status'
  | 'thehendonmobUrl'
  | 'totalLiveEarning'
  | 'totalWinnings'
  | 'tournamentResultsAggregate'
  | 'tournament_results'
  | 'twitter'
  | 'updatedAt'
  | 'user'
  | 'userId'
  | 'verified'
  | PlayersKeySpecifier
)[];
export type PlayersFieldPolicy = {
  actionsSold?: FieldPolicy<any> | FieldReadFunction<any>;
  allTimeList?: FieldPolicy<any> | FieldReadFunction<any>;
  allTimeListCountry?: FieldPolicy<any> | FieldReadFunction<any>;
  allTimeListCountryPlace?: FieldPolicy<any> | FieldReadFunction<any>;
  birthday?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  description?: FieldPolicy<any> | FieldReadFunction<any>;
  facebook?: FieldPolicy<any> | FieldReadFunction<any>;
  followers?: FieldPolicy<any> | FieldReadFunction<any>;
  followersAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  followersCount?: FieldPolicy<any> | FieldReadFunction<any>;
  gender?: FieldPolicy<any> | FieldReadFunction<any>;
  globalpokerindexUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  gpiRanking?: FieldPolicy<any> | FieldReadFunction<any>;
  highestRoi?: FieldPolicy<any> | FieldReadFunction<any>;
  highestWinning?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  image?: FieldPolicy<any> | FieldReadFunction<any>;
  instagram?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  nationality?: FieldPolicy<any> | FieldReadFunction<any>;
  playerFollowersViewAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  playerSharesAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  player_followers_view?: FieldPolicy<any> | FieldReadFunction<any>;
  player_shares?: FieldPolicy<any> | FieldReadFunction<any>;
  realName?: FieldPolicy<any> | FieldReadFunction<any>;
  residence?: FieldPolicy<any> | FieldReadFunction<any>;
  slug?: FieldPolicy<any> | FieldReadFunction<any>;
  sort?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  thehendonmobUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  totalLiveEarning?: FieldPolicy<any> | FieldReadFunction<any>;
  totalWinnings?: FieldPolicy<any> | FieldReadFunction<any>;
  tournamentResultsAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  tournament_results?: FieldPolicy<any> | FieldReadFunction<any>;
  twitter?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  user?: FieldPolicy<any> | FieldReadFunction<any>;
  userId?: FieldPolicy<any> | FieldReadFunction<any>;
  verified?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PlayersAggregateKeySpecifier = (
  | 'aggregate'
  | 'nodes'
  | PlayersAggregateKeySpecifier
)[];
export type PlayersAggregateFieldPolicy = {
  aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PlayersAggregateFieldsKeySpecifier = (
  | 'avg'
  | 'count'
  | 'max'
  | 'min'
  | 'stddev'
  | 'stddevPop'
  | 'stddevSamp'
  | 'sum'
  | 'varPop'
  | 'varSamp'
  | 'variance'
  | PlayersAggregateFieldsKeySpecifier
)[];
export type PlayersAggregateFieldsFieldPolicy = {
  avg?: FieldPolicy<any> | FieldReadFunction<any>;
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  max?: FieldPolicy<any> | FieldReadFunction<any>;
  min?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev?: FieldPolicy<any> | FieldReadFunction<any>;
  stddevPop?: FieldPolicy<any> | FieldReadFunction<any>;
  stddevSamp?: FieldPolicy<any> | FieldReadFunction<any>;
  sum?: FieldPolicy<any> | FieldReadFunction<any>;
  varPop?: FieldPolicy<any> | FieldReadFunction<any>;
  varSamp?: FieldPolicy<any> | FieldReadFunction<any>;
  variance?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PlayersAvgFieldsKeySpecifier = (
  | 'actionsSold'
  | 'allTimeList'
  | 'allTimeListCountryPlace'
  | 'followersCount'
  | 'gpiRanking'
  | 'highestRoi'
  | 'highestWinning'
  | 'sort'
  | 'totalLiveEarning'
  | 'totalWinnings'
  | PlayersAvgFieldsKeySpecifier
)[];
export type PlayersAvgFieldsFieldPolicy = {
  actionsSold?: FieldPolicy<any> | FieldReadFunction<any>;
  allTimeList?: FieldPolicy<any> | FieldReadFunction<any>;
  allTimeListCountryPlace?: FieldPolicy<any> | FieldReadFunction<any>;
  followersCount?: FieldPolicy<any> | FieldReadFunction<any>;
  gpiRanking?: FieldPolicy<any> | FieldReadFunction<any>;
  highestRoi?: FieldPolicy<any> | FieldReadFunction<any>;
  highestWinning?: FieldPolicy<any> | FieldReadFunction<any>;
  sort?: FieldPolicy<any> | FieldReadFunction<any>;
  totalLiveEarning?: FieldPolicy<any> | FieldReadFunction<any>;
  totalWinnings?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PlayersMaxFieldsKeySpecifier = (
  | 'actionsSold'
  | 'allTimeList'
  | 'allTimeListCountry'
  | 'allTimeListCountryPlace'
  | 'birthday'
  | 'createdAt'
  | 'description'
  | 'facebook'
  | 'followersCount'
  | 'gender'
  | 'globalpokerindexUrl'
  | 'gpiRanking'
  | 'highestRoi'
  | 'highestWinning'
  | 'id'
  | 'image'
  | 'instagram'
  | 'name'
  | 'nationality'
  | 'realName'
  | 'residence'
  | 'slug'
  | 'sort'
  | 'thehendonmobUrl'
  | 'totalLiveEarning'
  | 'totalWinnings'
  | 'twitter'
  | 'updatedAt'
  | 'userId'
  | PlayersMaxFieldsKeySpecifier
)[];
export type PlayersMaxFieldsFieldPolicy = {
  actionsSold?: FieldPolicy<any> | FieldReadFunction<any>;
  allTimeList?: FieldPolicy<any> | FieldReadFunction<any>;
  allTimeListCountry?: FieldPolicy<any> | FieldReadFunction<any>;
  allTimeListCountryPlace?: FieldPolicy<any> | FieldReadFunction<any>;
  birthday?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  description?: FieldPolicy<any> | FieldReadFunction<any>;
  facebook?: FieldPolicy<any> | FieldReadFunction<any>;
  followersCount?: FieldPolicy<any> | FieldReadFunction<any>;
  gender?: FieldPolicy<any> | FieldReadFunction<any>;
  globalpokerindexUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  gpiRanking?: FieldPolicy<any> | FieldReadFunction<any>;
  highestRoi?: FieldPolicy<any> | FieldReadFunction<any>;
  highestWinning?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  image?: FieldPolicy<any> | FieldReadFunction<any>;
  instagram?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  nationality?: FieldPolicy<any> | FieldReadFunction<any>;
  realName?: FieldPolicy<any> | FieldReadFunction<any>;
  residence?: FieldPolicy<any> | FieldReadFunction<any>;
  slug?: FieldPolicy<any> | FieldReadFunction<any>;
  sort?: FieldPolicy<any> | FieldReadFunction<any>;
  thehendonmobUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  totalLiveEarning?: FieldPolicy<any> | FieldReadFunction<any>;
  totalWinnings?: FieldPolicy<any> | FieldReadFunction<any>;
  twitter?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  userId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PlayersMinFieldsKeySpecifier = (
  | 'actionsSold'
  | 'allTimeList'
  | 'allTimeListCountry'
  | 'allTimeListCountryPlace'
  | 'birthday'
  | 'createdAt'
  | 'description'
  | 'facebook'
  | 'followersCount'
  | 'gender'
  | 'globalpokerindexUrl'
  | 'gpiRanking'
  | 'highestRoi'
  | 'highestWinning'
  | 'id'
  | 'image'
  | 'instagram'
  | 'name'
  | 'nationality'
  | 'realName'
  | 'residence'
  | 'slug'
  | 'sort'
  | 'thehendonmobUrl'
  | 'totalLiveEarning'
  | 'totalWinnings'
  | 'twitter'
  | 'updatedAt'
  | 'userId'
  | PlayersMinFieldsKeySpecifier
)[];
export type PlayersMinFieldsFieldPolicy = {
  actionsSold?: FieldPolicy<any> | FieldReadFunction<any>;
  allTimeList?: FieldPolicy<any> | FieldReadFunction<any>;
  allTimeListCountry?: FieldPolicy<any> | FieldReadFunction<any>;
  allTimeListCountryPlace?: FieldPolicy<any> | FieldReadFunction<any>;
  birthday?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  description?: FieldPolicy<any> | FieldReadFunction<any>;
  facebook?: FieldPolicy<any> | FieldReadFunction<any>;
  followersCount?: FieldPolicy<any> | FieldReadFunction<any>;
  gender?: FieldPolicy<any> | FieldReadFunction<any>;
  globalpokerindexUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  gpiRanking?: FieldPolicy<any> | FieldReadFunction<any>;
  highestRoi?: FieldPolicy<any> | FieldReadFunction<any>;
  highestWinning?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  image?: FieldPolicy<any> | FieldReadFunction<any>;
  instagram?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  nationality?: FieldPolicy<any> | FieldReadFunction<any>;
  realName?: FieldPolicy<any> | FieldReadFunction<any>;
  residence?: FieldPolicy<any> | FieldReadFunction<any>;
  slug?: FieldPolicy<any> | FieldReadFunction<any>;
  sort?: FieldPolicy<any> | FieldReadFunction<any>;
  thehendonmobUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  totalLiveEarning?: FieldPolicy<any> | FieldReadFunction<any>;
  totalWinnings?: FieldPolicy<any> | FieldReadFunction<any>;
  twitter?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  userId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PlayersMutationResponseKeySpecifier = (
  | 'affected_rows'
  | 'returning'
  | PlayersMutationResponseKeySpecifier
)[];
export type PlayersMutationResponseFieldPolicy = {
  affected_rows?: FieldPolicy<any> | FieldReadFunction<any>;
  returning?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PlayersStddevFieldsKeySpecifier = (
  | 'actionsSold'
  | 'allTimeList'
  | 'allTimeListCountryPlace'
  | 'followersCount'
  | 'gpiRanking'
  | 'highestRoi'
  | 'highestWinning'
  | 'sort'
  | 'totalLiveEarning'
  | 'totalWinnings'
  | PlayersStddevFieldsKeySpecifier
)[];
export type PlayersStddevFieldsFieldPolicy = {
  actionsSold?: FieldPolicy<any> | FieldReadFunction<any>;
  allTimeList?: FieldPolicy<any> | FieldReadFunction<any>;
  allTimeListCountryPlace?: FieldPolicy<any> | FieldReadFunction<any>;
  followersCount?: FieldPolicy<any> | FieldReadFunction<any>;
  gpiRanking?: FieldPolicy<any> | FieldReadFunction<any>;
  highestRoi?: FieldPolicy<any> | FieldReadFunction<any>;
  highestWinning?: FieldPolicy<any> | FieldReadFunction<any>;
  sort?: FieldPolicy<any> | FieldReadFunction<any>;
  totalLiveEarning?: FieldPolicy<any> | FieldReadFunction<any>;
  totalWinnings?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PlayersStddev_popFieldsKeySpecifier = (
  | 'actionsSold'
  | 'allTimeList'
  | 'allTimeListCountryPlace'
  | 'followersCount'
  | 'gpiRanking'
  | 'highestRoi'
  | 'highestWinning'
  | 'sort'
  | 'totalLiveEarning'
  | 'totalWinnings'
  | PlayersStddev_popFieldsKeySpecifier
)[];
export type PlayersStddev_popFieldsFieldPolicy = {
  actionsSold?: FieldPolicy<any> | FieldReadFunction<any>;
  allTimeList?: FieldPolicy<any> | FieldReadFunction<any>;
  allTimeListCountryPlace?: FieldPolicy<any> | FieldReadFunction<any>;
  followersCount?: FieldPolicy<any> | FieldReadFunction<any>;
  gpiRanking?: FieldPolicy<any> | FieldReadFunction<any>;
  highestRoi?: FieldPolicy<any> | FieldReadFunction<any>;
  highestWinning?: FieldPolicy<any> | FieldReadFunction<any>;
  sort?: FieldPolicy<any> | FieldReadFunction<any>;
  totalLiveEarning?: FieldPolicy<any> | FieldReadFunction<any>;
  totalWinnings?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PlayersStddev_sampFieldsKeySpecifier = (
  | 'actionsSold'
  | 'allTimeList'
  | 'allTimeListCountryPlace'
  | 'followersCount'
  | 'gpiRanking'
  | 'highestRoi'
  | 'highestWinning'
  | 'sort'
  | 'totalLiveEarning'
  | 'totalWinnings'
  | PlayersStddev_sampFieldsKeySpecifier
)[];
export type PlayersStddev_sampFieldsFieldPolicy = {
  actionsSold?: FieldPolicy<any> | FieldReadFunction<any>;
  allTimeList?: FieldPolicy<any> | FieldReadFunction<any>;
  allTimeListCountryPlace?: FieldPolicy<any> | FieldReadFunction<any>;
  followersCount?: FieldPolicy<any> | FieldReadFunction<any>;
  gpiRanking?: FieldPolicy<any> | FieldReadFunction<any>;
  highestRoi?: FieldPolicy<any> | FieldReadFunction<any>;
  highestWinning?: FieldPolicy<any> | FieldReadFunction<any>;
  sort?: FieldPolicy<any> | FieldReadFunction<any>;
  totalLiveEarning?: FieldPolicy<any> | FieldReadFunction<any>;
  totalWinnings?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PlayersSumFieldsKeySpecifier = (
  | 'actionsSold'
  | 'allTimeList'
  | 'allTimeListCountryPlace'
  | 'followersCount'
  | 'gpiRanking'
  | 'highestRoi'
  | 'highestWinning'
  | 'sort'
  | 'totalLiveEarning'
  | 'totalWinnings'
  | PlayersSumFieldsKeySpecifier
)[];
export type PlayersSumFieldsFieldPolicy = {
  actionsSold?: FieldPolicy<any> | FieldReadFunction<any>;
  allTimeList?: FieldPolicy<any> | FieldReadFunction<any>;
  allTimeListCountryPlace?: FieldPolicy<any> | FieldReadFunction<any>;
  followersCount?: FieldPolicy<any> | FieldReadFunction<any>;
  gpiRanking?: FieldPolicy<any> | FieldReadFunction<any>;
  highestRoi?: FieldPolicy<any> | FieldReadFunction<any>;
  highestWinning?: FieldPolicy<any> | FieldReadFunction<any>;
  sort?: FieldPolicy<any> | FieldReadFunction<any>;
  totalLiveEarning?: FieldPolicy<any> | FieldReadFunction<any>;
  totalWinnings?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PlayersVar_popFieldsKeySpecifier = (
  | 'actionsSold'
  | 'allTimeList'
  | 'allTimeListCountryPlace'
  | 'followersCount'
  | 'gpiRanking'
  | 'highestRoi'
  | 'highestWinning'
  | 'sort'
  | 'totalLiveEarning'
  | 'totalWinnings'
  | PlayersVar_popFieldsKeySpecifier
)[];
export type PlayersVar_popFieldsFieldPolicy = {
  actionsSold?: FieldPolicy<any> | FieldReadFunction<any>;
  allTimeList?: FieldPolicy<any> | FieldReadFunction<any>;
  allTimeListCountryPlace?: FieldPolicy<any> | FieldReadFunction<any>;
  followersCount?: FieldPolicy<any> | FieldReadFunction<any>;
  gpiRanking?: FieldPolicy<any> | FieldReadFunction<any>;
  highestRoi?: FieldPolicy<any> | FieldReadFunction<any>;
  highestWinning?: FieldPolicy<any> | FieldReadFunction<any>;
  sort?: FieldPolicy<any> | FieldReadFunction<any>;
  totalLiveEarning?: FieldPolicy<any> | FieldReadFunction<any>;
  totalWinnings?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PlayersVar_sampFieldsKeySpecifier = (
  | 'actionsSold'
  | 'allTimeList'
  | 'allTimeListCountryPlace'
  | 'followersCount'
  | 'gpiRanking'
  | 'highestRoi'
  | 'highestWinning'
  | 'sort'
  | 'totalLiveEarning'
  | 'totalWinnings'
  | PlayersVar_sampFieldsKeySpecifier
)[];
export type PlayersVar_sampFieldsFieldPolicy = {
  actionsSold?: FieldPolicy<any> | FieldReadFunction<any>;
  allTimeList?: FieldPolicy<any> | FieldReadFunction<any>;
  allTimeListCountryPlace?: FieldPolicy<any> | FieldReadFunction<any>;
  followersCount?: FieldPolicy<any> | FieldReadFunction<any>;
  gpiRanking?: FieldPolicy<any> | FieldReadFunction<any>;
  highestRoi?: FieldPolicy<any> | FieldReadFunction<any>;
  highestWinning?: FieldPolicy<any> | FieldReadFunction<any>;
  sort?: FieldPolicy<any> | FieldReadFunction<any>;
  totalLiveEarning?: FieldPolicy<any> | FieldReadFunction<any>;
  totalWinnings?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PlayersVarianceFieldsKeySpecifier = (
  | 'actionsSold'
  | 'allTimeList'
  | 'allTimeListCountryPlace'
  | 'followersCount'
  | 'gpiRanking'
  | 'highestRoi'
  | 'highestWinning'
  | 'sort'
  | 'totalLiveEarning'
  | 'totalWinnings'
  | PlayersVarianceFieldsKeySpecifier
)[];
export type PlayersVarianceFieldsFieldPolicy = {
  actionsSold?: FieldPolicy<any> | FieldReadFunction<any>;
  allTimeList?: FieldPolicy<any> | FieldReadFunction<any>;
  allTimeListCountryPlace?: FieldPolicy<any> | FieldReadFunction<any>;
  followersCount?: FieldPolicy<any> | FieldReadFunction<any>;
  gpiRanking?: FieldPolicy<any> | FieldReadFunction<any>;
  highestRoi?: FieldPolicy<any> | FieldReadFunction<any>;
  highestWinning?: FieldPolicy<any> | FieldReadFunction<any>;
  sort?: FieldPolicy<any> | FieldReadFunction<any>;
  totalLiveEarning?: FieldPolicy<any> | FieldReadFunction<any>;
  totalWinnings?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StatEventsKeySpecifier = (
  | 'createdAt'
  | 'date'
  | 'id'
  | 'location'
  | 'name'
  | 'updatedAt'
  | 'venueId'
  | StatEventsKeySpecifier
)[];
export type StatEventsFieldPolicy = {
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  date?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  location?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  venueId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StatEventsAggregateKeySpecifier = (
  | 'aggregate'
  | 'nodes'
  | StatEventsAggregateKeySpecifier
)[];
export type StatEventsAggregateFieldPolicy = {
  aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StatEventsAggregateFieldsKeySpecifier = (
  | 'avg'
  | 'count'
  | 'max'
  | 'min'
  | 'stddev'
  | 'stddevPop'
  | 'stddevSamp'
  | 'sum'
  | 'varPop'
  | 'varSamp'
  | 'variance'
  | StatEventsAggregateFieldsKeySpecifier
)[];
export type StatEventsAggregateFieldsFieldPolicy = {
  avg?: FieldPolicy<any> | FieldReadFunction<any>;
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  max?: FieldPolicy<any> | FieldReadFunction<any>;
  min?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev?: FieldPolicy<any> | FieldReadFunction<any>;
  stddevPop?: FieldPolicy<any> | FieldReadFunction<any>;
  stddevSamp?: FieldPolicy<any> | FieldReadFunction<any>;
  sum?: FieldPolicy<any> | FieldReadFunction<any>;
  varPop?: FieldPolicy<any> | FieldReadFunction<any>;
  varSamp?: FieldPolicy<any> | FieldReadFunction<any>;
  variance?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StatEventsAvgFieldsKeySpecifier = (
  | 'id'
  | StatEventsAvgFieldsKeySpecifier
)[];
export type StatEventsAvgFieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StatEventsMaxFieldsKeySpecifier = (
  | 'createdAt'
  | 'date'
  | 'id'
  | 'location'
  | 'name'
  | 'updatedAt'
  | 'venueId'
  | StatEventsMaxFieldsKeySpecifier
)[];
export type StatEventsMaxFieldsFieldPolicy = {
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  date?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  location?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  venueId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StatEventsMinFieldsKeySpecifier = (
  | 'createdAt'
  | 'date'
  | 'id'
  | 'location'
  | 'name'
  | 'updatedAt'
  | 'venueId'
  | StatEventsMinFieldsKeySpecifier
)[];
export type StatEventsMinFieldsFieldPolicy = {
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  date?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  location?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  venueId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StatEventsMutationResponseKeySpecifier = (
  | 'affected_rows'
  | 'returning'
  | StatEventsMutationResponseKeySpecifier
)[];
export type StatEventsMutationResponseFieldPolicy = {
  affected_rows?: FieldPolicy<any> | FieldReadFunction<any>;
  returning?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StatEventsStddevFieldsKeySpecifier = (
  | 'id'
  | StatEventsStddevFieldsKeySpecifier
)[];
export type StatEventsStddevFieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StatEventsStddev_popFieldsKeySpecifier = (
  | 'id'
  | StatEventsStddev_popFieldsKeySpecifier
)[];
export type StatEventsStddev_popFieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StatEventsStddev_sampFieldsKeySpecifier = (
  | 'id'
  | StatEventsStddev_sampFieldsKeySpecifier
)[];
export type StatEventsStddev_sampFieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StatEventsSumFieldsKeySpecifier = (
  | 'id'
  | StatEventsSumFieldsKeySpecifier
)[];
export type StatEventsSumFieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StatEventsVar_popFieldsKeySpecifier = (
  | 'id'
  | StatEventsVar_popFieldsKeySpecifier
)[];
export type StatEventsVar_popFieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StatEventsVar_sampFieldsKeySpecifier = (
  | 'id'
  | StatEventsVar_sampFieldsKeySpecifier
)[];
export type StatEventsVar_sampFieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StatEventsVarianceFieldsKeySpecifier = (
  | 'id'
  | StatEventsVarianceFieldsKeySpecifier
)[];
export type StatEventsVarianceFieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StatPlayersKeySpecifier = (
  | 'allTimeMoneyList'
  | 'asainPokerMoneyList'
  | 'asianPokerRanking'
  | 'createdAt'
  | 'id'
  | 'name'
  | 'nationality'
  | 'player'
  | 'playerId'
  | 'public'
  | 'residence'
  | 'slug'
  | 'statTournamentResultsAggregate'
  | 'stat_tournament_results'
  | 'thehendonmobId'
  | 'totalLiveEarning'
  | 'updatedAt'
  | StatPlayersKeySpecifier
)[];
export type StatPlayersFieldPolicy = {
  allTimeMoneyList?: FieldPolicy<any> | FieldReadFunction<any>;
  asainPokerMoneyList?: FieldPolicy<any> | FieldReadFunction<any>;
  asianPokerRanking?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  nationality?: FieldPolicy<any> | FieldReadFunction<any>;
  player?: FieldPolicy<any> | FieldReadFunction<any>;
  playerId?: FieldPolicy<any> | FieldReadFunction<any>;
  public?: FieldPolicy<any> | FieldReadFunction<any>;
  residence?: FieldPolicy<any> | FieldReadFunction<any>;
  slug?: FieldPolicy<any> | FieldReadFunction<any>;
  statTournamentResultsAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  stat_tournament_results?: FieldPolicy<any> | FieldReadFunction<any>;
  thehendonmobId?: FieldPolicy<any> | FieldReadFunction<any>;
  totalLiveEarning?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StatPlayersAggregateKeySpecifier = (
  | 'aggregate'
  | 'nodes'
  | StatPlayersAggregateKeySpecifier
)[];
export type StatPlayersAggregateFieldPolicy = {
  aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StatPlayersAggregateFieldsKeySpecifier = (
  | 'avg'
  | 'count'
  | 'max'
  | 'min'
  | 'stddev'
  | 'stddevPop'
  | 'stddevSamp'
  | 'sum'
  | 'varPop'
  | 'varSamp'
  | 'variance'
  | StatPlayersAggregateFieldsKeySpecifier
)[];
export type StatPlayersAggregateFieldsFieldPolicy = {
  avg?: FieldPolicy<any> | FieldReadFunction<any>;
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  max?: FieldPolicy<any> | FieldReadFunction<any>;
  min?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev?: FieldPolicy<any> | FieldReadFunction<any>;
  stddevPop?: FieldPolicy<any> | FieldReadFunction<any>;
  stddevSamp?: FieldPolicy<any> | FieldReadFunction<any>;
  sum?: FieldPolicy<any> | FieldReadFunction<any>;
  varPop?: FieldPolicy<any> | FieldReadFunction<any>;
  varSamp?: FieldPolicy<any> | FieldReadFunction<any>;
  variance?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StatPlayersAvgFieldsKeySpecifier = (
  | 'allTimeMoneyList'
  | 'asainPokerMoneyList'
  | 'asianPokerRanking'
  | 'id'
  | 'thehendonmobId'
  | 'totalLiveEarning'
  | StatPlayersAvgFieldsKeySpecifier
)[];
export type StatPlayersAvgFieldsFieldPolicy = {
  allTimeMoneyList?: FieldPolicy<any> | FieldReadFunction<any>;
  asainPokerMoneyList?: FieldPolicy<any> | FieldReadFunction<any>;
  asianPokerRanking?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  thehendonmobId?: FieldPolicy<any> | FieldReadFunction<any>;
  totalLiveEarning?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StatPlayersMaxFieldsKeySpecifier = (
  | 'allTimeMoneyList'
  | 'asainPokerMoneyList'
  | 'asianPokerRanking'
  | 'createdAt'
  | 'id'
  | 'name'
  | 'nationality'
  | 'playerId'
  | 'residence'
  | 'slug'
  | 'thehendonmobId'
  | 'totalLiveEarning'
  | 'updatedAt'
  | StatPlayersMaxFieldsKeySpecifier
)[];
export type StatPlayersMaxFieldsFieldPolicy = {
  allTimeMoneyList?: FieldPolicy<any> | FieldReadFunction<any>;
  asainPokerMoneyList?: FieldPolicy<any> | FieldReadFunction<any>;
  asianPokerRanking?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  nationality?: FieldPolicy<any> | FieldReadFunction<any>;
  playerId?: FieldPolicy<any> | FieldReadFunction<any>;
  residence?: FieldPolicy<any> | FieldReadFunction<any>;
  slug?: FieldPolicy<any> | FieldReadFunction<any>;
  thehendonmobId?: FieldPolicy<any> | FieldReadFunction<any>;
  totalLiveEarning?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StatPlayersMinFieldsKeySpecifier = (
  | 'allTimeMoneyList'
  | 'asainPokerMoneyList'
  | 'asianPokerRanking'
  | 'createdAt'
  | 'id'
  | 'name'
  | 'nationality'
  | 'playerId'
  | 'residence'
  | 'slug'
  | 'thehendonmobId'
  | 'totalLiveEarning'
  | 'updatedAt'
  | StatPlayersMinFieldsKeySpecifier
)[];
export type StatPlayersMinFieldsFieldPolicy = {
  allTimeMoneyList?: FieldPolicy<any> | FieldReadFunction<any>;
  asainPokerMoneyList?: FieldPolicy<any> | FieldReadFunction<any>;
  asianPokerRanking?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  nationality?: FieldPolicy<any> | FieldReadFunction<any>;
  playerId?: FieldPolicy<any> | FieldReadFunction<any>;
  residence?: FieldPolicy<any> | FieldReadFunction<any>;
  slug?: FieldPolicy<any> | FieldReadFunction<any>;
  thehendonmobId?: FieldPolicy<any> | FieldReadFunction<any>;
  totalLiveEarning?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StatPlayersMutationResponseKeySpecifier = (
  | 'affected_rows'
  | 'returning'
  | StatPlayersMutationResponseKeySpecifier
)[];
export type StatPlayersMutationResponseFieldPolicy = {
  affected_rows?: FieldPolicy<any> | FieldReadFunction<any>;
  returning?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StatPlayersStddevFieldsKeySpecifier = (
  | 'allTimeMoneyList'
  | 'asainPokerMoneyList'
  | 'asianPokerRanking'
  | 'id'
  | 'thehendonmobId'
  | 'totalLiveEarning'
  | StatPlayersStddevFieldsKeySpecifier
)[];
export type StatPlayersStddevFieldsFieldPolicy = {
  allTimeMoneyList?: FieldPolicy<any> | FieldReadFunction<any>;
  asainPokerMoneyList?: FieldPolicy<any> | FieldReadFunction<any>;
  asianPokerRanking?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  thehendonmobId?: FieldPolicy<any> | FieldReadFunction<any>;
  totalLiveEarning?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StatPlayersStddev_popFieldsKeySpecifier = (
  | 'allTimeMoneyList'
  | 'asainPokerMoneyList'
  | 'asianPokerRanking'
  | 'id'
  | 'thehendonmobId'
  | 'totalLiveEarning'
  | StatPlayersStddev_popFieldsKeySpecifier
)[];
export type StatPlayersStddev_popFieldsFieldPolicy = {
  allTimeMoneyList?: FieldPolicy<any> | FieldReadFunction<any>;
  asainPokerMoneyList?: FieldPolicy<any> | FieldReadFunction<any>;
  asianPokerRanking?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  thehendonmobId?: FieldPolicy<any> | FieldReadFunction<any>;
  totalLiveEarning?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StatPlayersStddev_sampFieldsKeySpecifier = (
  | 'allTimeMoneyList'
  | 'asainPokerMoneyList'
  | 'asianPokerRanking'
  | 'id'
  | 'thehendonmobId'
  | 'totalLiveEarning'
  | StatPlayersStddev_sampFieldsKeySpecifier
)[];
export type StatPlayersStddev_sampFieldsFieldPolicy = {
  allTimeMoneyList?: FieldPolicy<any> | FieldReadFunction<any>;
  asainPokerMoneyList?: FieldPolicy<any> | FieldReadFunction<any>;
  asianPokerRanking?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  thehendonmobId?: FieldPolicy<any> | FieldReadFunction<any>;
  totalLiveEarning?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StatPlayersSumFieldsKeySpecifier = (
  | 'allTimeMoneyList'
  | 'asainPokerMoneyList'
  | 'asianPokerRanking'
  | 'id'
  | 'thehendonmobId'
  | 'totalLiveEarning'
  | StatPlayersSumFieldsKeySpecifier
)[];
export type StatPlayersSumFieldsFieldPolicy = {
  allTimeMoneyList?: FieldPolicy<any> | FieldReadFunction<any>;
  asainPokerMoneyList?: FieldPolicy<any> | FieldReadFunction<any>;
  asianPokerRanking?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  thehendonmobId?: FieldPolicy<any> | FieldReadFunction<any>;
  totalLiveEarning?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StatPlayersVar_popFieldsKeySpecifier = (
  | 'allTimeMoneyList'
  | 'asainPokerMoneyList'
  | 'asianPokerRanking'
  | 'id'
  | 'thehendonmobId'
  | 'totalLiveEarning'
  | StatPlayersVar_popFieldsKeySpecifier
)[];
export type StatPlayersVar_popFieldsFieldPolicy = {
  allTimeMoneyList?: FieldPolicy<any> | FieldReadFunction<any>;
  asainPokerMoneyList?: FieldPolicy<any> | FieldReadFunction<any>;
  asianPokerRanking?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  thehendonmobId?: FieldPolicy<any> | FieldReadFunction<any>;
  totalLiveEarning?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StatPlayersVar_sampFieldsKeySpecifier = (
  | 'allTimeMoneyList'
  | 'asainPokerMoneyList'
  | 'asianPokerRanking'
  | 'id'
  | 'thehendonmobId'
  | 'totalLiveEarning'
  | StatPlayersVar_sampFieldsKeySpecifier
)[];
export type StatPlayersVar_sampFieldsFieldPolicy = {
  allTimeMoneyList?: FieldPolicy<any> | FieldReadFunction<any>;
  asainPokerMoneyList?: FieldPolicy<any> | FieldReadFunction<any>;
  asianPokerRanking?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  thehendonmobId?: FieldPolicy<any> | FieldReadFunction<any>;
  totalLiveEarning?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StatPlayersVarianceFieldsKeySpecifier = (
  | 'allTimeMoneyList'
  | 'asainPokerMoneyList'
  | 'asianPokerRanking'
  | 'id'
  | 'thehendonmobId'
  | 'totalLiveEarning'
  | StatPlayersVarianceFieldsKeySpecifier
)[];
export type StatPlayersVarianceFieldsFieldPolicy = {
  allTimeMoneyList?: FieldPolicy<any> | FieldReadFunction<any>;
  asainPokerMoneyList?: FieldPolicy<any> | FieldReadFunction<any>;
  asianPokerRanking?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  thehendonmobId?: FieldPolicy<any> | FieldReadFunction<any>;
  totalLiveEarning?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StatTopPlayersKeySpecifier = (
  | 'id'
  | 'money'
  | 'name'
  | 'points'
  | 'ranking'
  | 'slug'
  | 'stat_player'
  | StatTopPlayersKeySpecifier
)[];
export type StatTopPlayersFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  money?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  points?: FieldPolicy<any> | FieldReadFunction<any>;
  ranking?: FieldPolicy<any> | FieldReadFunction<any>;
  slug?: FieldPolicy<any> | FieldReadFunction<any>;
  stat_player?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StatTopPlayersAggregateKeySpecifier = (
  | 'aggregate'
  | 'nodes'
  | StatTopPlayersAggregateKeySpecifier
)[];
export type StatTopPlayersAggregateFieldPolicy = {
  aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StatTopPlayersAggregateFieldsKeySpecifier = (
  | 'avg'
  | 'count'
  | 'max'
  | 'min'
  | 'stddev'
  | 'stddevPop'
  | 'stddevSamp'
  | 'sum'
  | 'varPop'
  | 'varSamp'
  | 'variance'
  | StatTopPlayersAggregateFieldsKeySpecifier
)[];
export type StatTopPlayersAggregateFieldsFieldPolicy = {
  avg?: FieldPolicy<any> | FieldReadFunction<any>;
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  max?: FieldPolicy<any> | FieldReadFunction<any>;
  min?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev?: FieldPolicy<any> | FieldReadFunction<any>;
  stddevPop?: FieldPolicy<any> | FieldReadFunction<any>;
  stddevSamp?: FieldPolicy<any> | FieldReadFunction<any>;
  sum?: FieldPolicy<any> | FieldReadFunction<any>;
  varPop?: FieldPolicy<any> | FieldReadFunction<any>;
  varSamp?: FieldPolicy<any> | FieldReadFunction<any>;
  variance?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StatTopPlayersAvgFieldsKeySpecifier = (
  | 'id'
  | 'money'
  | 'points'
  | 'ranking'
  | StatTopPlayersAvgFieldsKeySpecifier
)[];
export type StatTopPlayersAvgFieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  money?: FieldPolicy<any> | FieldReadFunction<any>;
  points?: FieldPolicy<any> | FieldReadFunction<any>;
  ranking?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StatTopPlayersMaxFieldsKeySpecifier = (
  | 'id'
  | 'money'
  | 'name'
  | 'points'
  | 'ranking'
  | 'slug'
  | StatTopPlayersMaxFieldsKeySpecifier
)[];
export type StatTopPlayersMaxFieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  money?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  points?: FieldPolicy<any> | FieldReadFunction<any>;
  ranking?: FieldPolicy<any> | FieldReadFunction<any>;
  slug?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StatTopPlayersMinFieldsKeySpecifier = (
  | 'id'
  | 'money'
  | 'name'
  | 'points'
  | 'ranking'
  | 'slug'
  | StatTopPlayersMinFieldsKeySpecifier
)[];
export type StatTopPlayersMinFieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  money?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  points?: FieldPolicy<any> | FieldReadFunction<any>;
  ranking?: FieldPolicy<any> | FieldReadFunction<any>;
  slug?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StatTopPlayersStddevFieldsKeySpecifier = (
  | 'id'
  | 'money'
  | 'points'
  | 'ranking'
  | StatTopPlayersStddevFieldsKeySpecifier
)[];
export type StatTopPlayersStddevFieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  money?: FieldPolicy<any> | FieldReadFunction<any>;
  points?: FieldPolicy<any> | FieldReadFunction<any>;
  ranking?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StatTopPlayersStddev_popFieldsKeySpecifier = (
  | 'id'
  | 'money'
  | 'points'
  | 'ranking'
  | StatTopPlayersStddev_popFieldsKeySpecifier
)[];
export type StatTopPlayersStddev_popFieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  money?: FieldPolicy<any> | FieldReadFunction<any>;
  points?: FieldPolicy<any> | FieldReadFunction<any>;
  ranking?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StatTopPlayersStddev_sampFieldsKeySpecifier = (
  | 'id'
  | 'money'
  | 'points'
  | 'ranking'
  | StatTopPlayersStddev_sampFieldsKeySpecifier
)[];
export type StatTopPlayersStddev_sampFieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  money?: FieldPolicy<any> | FieldReadFunction<any>;
  points?: FieldPolicy<any> | FieldReadFunction<any>;
  ranking?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StatTopPlayersSumFieldsKeySpecifier = (
  | 'id'
  | 'money'
  | 'points'
  | 'ranking'
  | StatTopPlayersSumFieldsKeySpecifier
)[];
export type StatTopPlayersSumFieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  money?: FieldPolicy<any> | FieldReadFunction<any>;
  points?: FieldPolicy<any> | FieldReadFunction<any>;
  ranking?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StatTopPlayersVar_popFieldsKeySpecifier = (
  | 'id'
  | 'money'
  | 'points'
  | 'ranking'
  | StatTopPlayersVar_popFieldsKeySpecifier
)[];
export type StatTopPlayersVar_popFieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  money?: FieldPolicy<any> | FieldReadFunction<any>;
  points?: FieldPolicy<any> | FieldReadFunction<any>;
  ranking?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StatTopPlayersVar_sampFieldsKeySpecifier = (
  | 'id'
  | 'money'
  | 'points'
  | 'ranking'
  | StatTopPlayersVar_sampFieldsKeySpecifier
)[];
export type StatTopPlayersVar_sampFieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  money?: FieldPolicy<any> | FieldReadFunction<any>;
  points?: FieldPolicy<any> | FieldReadFunction<any>;
  ranking?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StatTopPlayersVarianceFieldsKeySpecifier = (
  | 'id'
  | 'money'
  | 'points'
  | 'ranking'
  | StatTopPlayersVarianceFieldsKeySpecifier
)[];
export type StatTopPlayersVarianceFieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  money?: FieldPolicy<any> | FieldReadFunction<any>;
  points?: FieldPolicy<any> | FieldReadFunction<any>;
  ranking?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StatTopYearPlayersKeySpecifier = (
  | 'id'
  | 'money'
  | 'name'
  | 'points'
  | 'ranking'
  | 'slug'
  | 'stat_player'
  | StatTopYearPlayersKeySpecifier
)[];
export type StatTopYearPlayersFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  money?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  points?: FieldPolicy<any> | FieldReadFunction<any>;
  ranking?: FieldPolicy<any> | FieldReadFunction<any>;
  slug?: FieldPolicy<any> | FieldReadFunction<any>;
  stat_player?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StatTopYearPlayersAggregateKeySpecifier = (
  | 'aggregate'
  | 'nodes'
  | StatTopYearPlayersAggregateKeySpecifier
)[];
export type StatTopYearPlayersAggregateFieldPolicy = {
  aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StatTopYearPlayersAggregateFieldsKeySpecifier = (
  | 'avg'
  | 'count'
  | 'max'
  | 'min'
  | 'stddev'
  | 'stddevPop'
  | 'stddevSamp'
  | 'sum'
  | 'varPop'
  | 'varSamp'
  | 'variance'
  | StatTopYearPlayersAggregateFieldsKeySpecifier
)[];
export type StatTopYearPlayersAggregateFieldsFieldPolicy = {
  avg?: FieldPolicy<any> | FieldReadFunction<any>;
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  max?: FieldPolicy<any> | FieldReadFunction<any>;
  min?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev?: FieldPolicy<any> | FieldReadFunction<any>;
  stddevPop?: FieldPolicy<any> | FieldReadFunction<any>;
  stddevSamp?: FieldPolicy<any> | FieldReadFunction<any>;
  sum?: FieldPolicy<any> | FieldReadFunction<any>;
  varPop?: FieldPolicy<any> | FieldReadFunction<any>;
  varSamp?: FieldPolicy<any> | FieldReadFunction<any>;
  variance?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StatTopYearPlayersAvgFieldsKeySpecifier = (
  | 'id'
  | 'money'
  | 'points'
  | 'ranking'
  | StatTopYearPlayersAvgFieldsKeySpecifier
)[];
export type StatTopYearPlayersAvgFieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  money?: FieldPolicy<any> | FieldReadFunction<any>;
  points?: FieldPolicy<any> | FieldReadFunction<any>;
  ranking?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StatTopYearPlayersMaxFieldsKeySpecifier = (
  | 'id'
  | 'money'
  | 'name'
  | 'points'
  | 'ranking'
  | 'slug'
  | StatTopYearPlayersMaxFieldsKeySpecifier
)[];
export type StatTopYearPlayersMaxFieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  money?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  points?: FieldPolicy<any> | FieldReadFunction<any>;
  ranking?: FieldPolicy<any> | FieldReadFunction<any>;
  slug?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StatTopYearPlayersMinFieldsKeySpecifier = (
  | 'id'
  | 'money'
  | 'name'
  | 'points'
  | 'ranking'
  | 'slug'
  | StatTopYearPlayersMinFieldsKeySpecifier
)[];
export type StatTopYearPlayersMinFieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  money?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  points?: FieldPolicy<any> | FieldReadFunction<any>;
  ranking?: FieldPolicy<any> | FieldReadFunction<any>;
  slug?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StatTopYearPlayersStddevFieldsKeySpecifier = (
  | 'id'
  | 'money'
  | 'points'
  | 'ranking'
  | StatTopYearPlayersStddevFieldsKeySpecifier
)[];
export type StatTopYearPlayersStddevFieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  money?: FieldPolicy<any> | FieldReadFunction<any>;
  points?: FieldPolicy<any> | FieldReadFunction<any>;
  ranking?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StatTopYearPlayersStddev_popFieldsKeySpecifier = (
  | 'id'
  | 'money'
  | 'points'
  | 'ranking'
  | StatTopYearPlayersStddev_popFieldsKeySpecifier
)[];
export type StatTopYearPlayersStddev_popFieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  money?: FieldPolicy<any> | FieldReadFunction<any>;
  points?: FieldPolicy<any> | FieldReadFunction<any>;
  ranking?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StatTopYearPlayersStddev_sampFieldsKeySpecifier = (
  | 'id'
  | 'money'
  | 'points'
  | 'ranking'
  | StatTopYearPlayersStddev_sampFieldsKeySpecifier
)[];
export type StatTopYearPlayersStddev_sampFieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  money?: FieldPolicy<any> | FieldReadFunction<any>;
  points?: FieldPolicy<any> | FieldReadFunction<any>;
  ranking?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StatTopYearPlayersSumFieldsKeySpecifier = (
  | 'id'
  | 'money'
  | 'points'
  | 'ranking'
  | StatTopYearPlayersSumFieldsKeySpecifier
)[];
export type StatTopYearPlayersSumFieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  money?: FieldPolicy<any> | FieldReadFunction<any>;
  points?: FieldPolicy<any> | FieldReadFunction<any>;
  ranking?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StatTopYearPlayersVar_popFieldsKeySpecifier = (
  | 'id'
  | 'money'
  | 'points'
  | 'ranking'
  | StatTopYearPlayersVar_popFieldsKeySpecifier
)[];
export type StatTopYearPlayersVar_popFieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  money?: FieldPolicy<any> | FieldReadFunction<any>;
  points?: FieldPolicy<any> | FieldReadFunction<any>;
  ranking?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StatTopYearPlayersVar_sampFieldsKeySpecifier = (
  | 'id'
  | 'money'
  | 'points'
  | 'ranking'
  | StatTopYearPlayersVar_sampFieldsKeySpecifier
)[];
export type StatTopYearPlayersVar_sampFieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  money?: FieldPolicy<any> | FieldReadFunction<any>;
  points?: FieldPolicy<any> | FieldReadFunction<any>;
  ranking?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StatTopYearPlayersVarianceFieldsKeySpecifier = (
  | 'id'
  | 'money'
  | 'points'
  | 'ranking'
  | StatTopYearPlayersVarianceFieldsKeySpecifier
)[];
export type StatTopYearPlayersVarianceFieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  money?: FieldPolicy<any> | FieldReadFunction<any>;
  points?: FieldPolicy<any> | FieldReadFunction<any>;
  ranking?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StatTournamentResultsKeySpecifier = (
  | 'createdAt'
  | 'id'
  | 'place'
  | 'playerGeneralResultId'
  | 'points'
  | 'price'
  | 'priceLocal'
  | 'statPlayerId'
  | 'statTournamentId'
  | 'stat_player'
  | 'stat_tournament'
  | 'updatedAt'
  | StatTournamentResultsKeySpecifier
)[];
export type StatTournamentResultsFieldPolicy = {
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  place?: FieldPolicy<any> | FieldReadFunction<any>;
  playerGeneralResultId?: FieldPolicy<any> | FieldReadFunction<any>;
  points?: FieldPolicy<any> | FieldReadFunction<any>;
  price?: FieldPolicy<any> | FieldReadFunction<any>;
  priceLocal?: FieldPolicy<any> | FieldReadFunction<any>;
  statPlayerId?: FieldPolicy<any> | FieldReadFunction<any>;
  statTournamentId?: FieldPolicy<any> | FieldReadFunction<any>;
  stat_player?: FieldPolicy<any> | FieldReadFunction<any>;
  stat_tournament?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StatTournamentResultsAggregateKeySpecifier = (
  | 'aggregate'
  | 'nodes'
  | StatTournamentResultsAggregateKeySpecifier
)[];
export type StatTournamentResultsAggregateFieldPolicy = {
  aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StatTournamentResultsAggregateFieldsKeySpecifier = (
  | 'avg'
  | 'count'
  | 'max'
  | 'min'
  | 'stddev'
  | 'stddevPop'
  | 'stddevSamp'
  | 'sum'
  | 'varPop'
  | 'varSamp'
  | 'variance'
  | StatTournamentResultsAggregateFieldsKeySpecifier
)[];
export type StatTournamentResultsAggregateFieldsFieldPolicy = {
  avg?: FieldPolicy<any> | FieldReadFunction<any>;
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  max?: FieldPolicy<any> | FieldReadFunction<any>;
  min?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev?: FieldPolicy<any> | FieldReadFunction<any>;
  stddevPop?: FieldPolicy<any> | FieldReadFunction<any>;
  stddevSamp?: FieldPolicy<any> | FieldReadFunction<any>;
  sum?: FieldPolicy<any> | FieldReadFunction<any>;
  varPop?: FieldPolicy<any> | FieldReadFunction<any>;
  varSamp?: FieldPolicy<any> | FieldReadFunction<any>;
  variance?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StatTournamentResultsAvgFieldsKeySpecifier = (
  | 'id'
  | 'place'
  | 'playerGeneralResultId'
  | 'points'
  | 'price'
  | 'priceLocal'
  | 'statPlayerId'
  | 'statTournamentId'
  | StatTournamentResultsAvgFieldsKeySpecifier
)[];
export type StatTournamentResultsAvgFieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  place?: FieldPolicy<any> | FieldReadFunction<any>;
  playerGeneralResultId?: FieldPolicy<any> | FieldReadFunction<any>;
  points?: FieldPolicy<any> | FieldReadFunction<any>;
  price?: FieldPolicy<any> | FieldReadFunction<any>;
  priceLocal?: FieldPolicy<any> | FieldReadFunction<any>;
  statPlayerId?: FieldPolicy<any> | FieldReadFunction<any>;
  statTournamentId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StatTournamentResultsMaxFieldsKeySpecifier = (
  | 'createdAt'
  | 'id'
  | 'place'
  | 'playerGeneralResultId'
  | 'points'
  | 'price'
  | 'priceLocal'
  | 'statPlayerId'
  | 'statTournamentId'
  | 'updatedAt'
  | StatTournamentResultsMaxFieldsKeySpecifier
)[];
export type StatTournamentResultsMaxFieldsFieldPolicy = {
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  place?: FieldPolicy<any> | FieldReadFunction<any>;
  playerGeneralResultId?: FieldPolicy<any> | FieldReadFunction<any>;
  points?: FieldPolicy<any> | FieldReadFunction<any>;
  price?: FieldPolicy<any> | FieldReadFunction<any>;
  priceLocal?: FieldPolicy<any> | FieldReadFunction<any>;
  statPlayerId?: FieldPolicy<any> | FieldReadFunction<any>;
  statTournamentId?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StatTournamentResultsMinFieldsKeySpecifier = (
  | 'createdAt'
  | 'id'
  | 'place'
  | 'playerGeneralResultId'
  | 'points'
  | 'price'
  | 'priceLocal'
  | 'statPlayerId'
  | 'statTournamentId'
  | 'updatedAt'
  | StatTournamentResultsMinFieldsKeySpecifier
)[];
export type StatTournamentResultsMinFieldsFieldPolicy = {
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  place?: FieldPolicy<any> | FieldReadFunction<any>;
  playerGeneralResultId?: FieldPolicy<any> | FieldReadFunction<any>;
  points?: FieldPolicy<any> | FieldReadFunction<any>;
  price?: FieldPolicy<any> | FieldReadFunction<any>;
  priceLocal?: FieldPolicy<any> | FieldReadFunction<any>;
  statPlayerId?: FieldPolicy<any> | FieldReadFunction<any>;
  statTournamentId?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StatTournamentResultsMutationResponseKeySpecifier = (
  | 'affected_rows'
  | 'returning'
  | StatTournamentResultsMutationResponseKeySpecifier
)[];
export type StatTournamentResultsMutationResponseFieldPolicy = {
  affected_rows?: FieldPolicy<any> | FieldReadFunction<any>;
  returning?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StatTournamentResultsStddevFieldsKeySpecifier = (
  | 'id'
  | 'place'
  | 'playerGeneralResultId'
  | 'points'
  | 'price'
  | 'priceLocal'
  | 'statPlayerId'
  | 'statTournamentId'
  | StatTournamentResultsStddevFieldsKeySpecifier
)[];
export type StatTournamentResultsStddevFieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  place?: FieldPolicy<any> | FieldReadFunction<any>;
  playerGeneralResultId?: FieldPolicy<any> | FieldReadFunction<any>;
  points?: FieldPolicy<any> | FieldReadFunction<any>;
  price?: FieldPolicy<any> | FieldReadFunction<any>;
  priceLocal?: FieldPolicy<any> | FieldReadFunction<any>;
  statPlayerId?: FieldPolicy<any> | FieldReadFunction<any>;
  statTournamentId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StatTournamentResultsStddev_popFieldsKeySpecifier = (
  | 'id'
  | 'place'
  | 'playerGeneralResultId'
  | 'points'
  | 'price'
  | 'priceLocal'
  | 'statPlayerId'
  | 'statTournamentId'
  | StatTournamentResultsStddev_popFieldsKeySpecifier
)[];
export type StatTournamentResultsStddev_popFieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  place?: FieldPolicy<any> | FieldReadFunction<any>;
  playerGeneralResultId?: FieldPolicy<any> | FieldReadFunction<any>;
  points?: FieldPolicy<any> | FieldReadFunction<any>;
  price?: FieldPolicy<any> | FieldReadFunction<any>;
  priceLocal?: FieldPolicy<any> | FieldReadFunction<any>;
  statPlayerId?: FieldPolicy<any> | FieldReadFunction<any>;
  statTournamentId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StatTournamentResultsStddev_sampFieldsKeySpecifier = (
  | 'id'
  | 'place'
  | 'playerGeneralResultId'
  | 'points'
  | 'price'
  | 'priceLocal'
  | 'statPlayerId'
  | 'statTournamentId'
  | StatTournamentResultsStddev_sampFieldsKeySpecifier
)[];
export type StatTournamentResultsStddev_sampFieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  place?: FieldPolicy<any> | FieldReadFunction<any>;
  playerGeneralResultId?: FieldPolicy<any> | FieldReadFunction<any>;
  points?: FieldPolicy<any> | FieldReadFunction<any>;
  price?: FieldPolicy<any> | FieldReadFunction<any>;
  priceLocal?: FieldPolicy<any> | FieldReadFunction<any>;
  statPlayerId?: FieldPolicy<any> | FieldReadFunction<any>;
  statTournamentId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StatTournamentResultsSumFieldsKeySpecifier = (
  | 'id'
  | 'place'
  | 'playerGeneralResultId'
  | 'points'
  | 'price'
  | 'priceLocal'
  | 'statPlayerId'
  | 'statTournamentId'
  | StatTournamentResultsSumFieldsKeySpecifier
)[];
export type StatTournamentResultsSumFieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  place?: FieldPolicy<any> | FieldReadFunction<any>;
  playerGeneralResultId?: FieldPolicy<any> | FieldReadFunction<any>;
  points?: FieldPolicy<any> | FieldReadFunction<any>;
  price?: FieldPolicy<any> | FieldReadFunction<any>;
  priceLocal?: FieldPolicy<any> | FieldReadFunction<any>;
  statPlayerId?: FieldPolicy<any> | FieldReadFunction<any>;
  statTournamentId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StatTournamentResultsVar_popFieldsKeySpecifier = (
  | 'id'
  | 'place'
  | 'playerGeneralResultId'
  | 'points'
  | 'price'
  | 'priceLocal'
  | 'statPlayerId'
  | 'statTournamentId'
  | StatTournamentResultsVar_popFieldsKeySpecifier
)[];
export type StatTournamentResultsVar_popFieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  place?: FieldPolicy<any> | FieldReadFunction<any>;
  playerGeneralResultId?: FieldPolicy<any> | FieldReadFunction<any>;
  points?: FieldPolicy<any> | FieldReadFunction<any>;
  price?: FieldPolicy<any> | FieldReadFunction<any>;
  priceLocal?: FieldPolicy<any> | FieldReadFunction<any>;
  statPlayerId?: FieldPolicy<any> | FieldReadFunction<any>;
  statTournamentId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StatTournamentResultsVar_sampFieldsKeySpecifier = (
  | 'id'
  | 'place'
  | 'playerGeneralResultId'
  | 'points'
  | 'price'
  | 'priceLocal'
  | 'statPlayerId'
  | 'statTournamentId'
  | StatTournamentResultsVar_sampFieldsKeySpecifier
)[];
export type StatTournamentResultsVar_sampFieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  place?: FieldPolicy<any> | FieldReadFunction<any>;
  playerGeneralResultId?: FieldPolicy<any> | FieldReadFunction<any>;
  points?: FieldPolicy<any> | FieldReadFunction<any>;
  price?: FieldPolicy<any> | FieldReadFunction<any>;
  priceLocal?: FieldPolicy<any> | FieldReadFunction<any>;
  statPlayerId?: FieldPolicy<any> | FieldReadFunction<any>;
  statTournamentId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StatTournamentResultsVarianceFieldsKeySpecifier = (
  | 'id'
  | 'place'
  | 'playerGeneralResultId'
  | 'points'
  | 'price'
  | 'priceLocal'
  | 'statPlayerId'
  | 'statTournamentId'
  | StatTournamentResultsVarianceFieldsKeySpecifier
)[];
export type StatTournamentResultsVarianceFieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  place?: FieldPolicy<any> | FieldReadFunction<any>;
  playerGeneralResultId?: FieldPolicy<any> | FieldReadFunction<any>;
  points?: FieldPolicy<any> | FieldReadFunction<any>;
  price?: FieldPolicy<any> | FieldReadFunction<any>;
  priceLocal?: FieldPolicy<any> | FieldReadFunction<any>;
  statPlayerId?: FieldPolicy<any> | FieldReadFunction<any>;
  statTournamentId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StatTournamentsKeySpecifier = (
  | 'buyIn'
  | 'buyInLocal'
  | 'createdAt'
  | 'currency'
  | 'date'
  | 'id'
  | 'localCurrencyId'
  | 'location'
  | 'name'
  | 'prizepool'
  | 'prizepoolLocal'
  | 'statEventId'
  | 'statFileId'
  | 'updatedAt'
  | 'venue'
  | 'venueId'
  | StatTournamentsKeySpecifier
)[];
export type StatTournamentsFieldPolicy = {
  buyIn?: FieldPolicy<any> | FieldReadFunction<any>;
  buyInLocal?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  currency?: FieldPolicy<any> | FieldReadFunction<any>;
  date?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  localCurrencyId?: FieldPolicy<any> | FieldReadFunction<any>;
  location?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  prizepool?: FieldPolicy<any> | FieldReadFunction<any>;
  prizepoolLocal?: FieldPolicy<any> | FieldReadFunction<any>;
  statEventId?: FieldPolicy<any> | FieldReadFunction<any>;
  statFileId?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  venue?: FieldPolicy<any> | FieldReadFunction<any>;
  venueId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StatTournamentsAggregateKeySpecifier = (
  | 'aggregate'
  | 'nodes'
  | StatTournamentsAggregateKeySpecifier
)[];
export type StatTournamentsAggregateFieldPolicy = {
  aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StatTournamentsAggregateFieldsKeySpecifier = (
  | 'avg'
  | 'count'
  | 'max'
  | 'min'
  | 'stddev'
  | 'stddevPop'
  | 'stddevSamp'
  | 'sum'
  | 'varPop'
  | 'varSamp'
  | 'variance'
  | StatTournamentsAggregateFieldsKeySpecifier
)[];
export type StatTournamentsAggregateFieldsFieldPolicy = {
  avg?: FieldPolicy<any> | FieldReadFunction<any>;
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  max?: FieldPolicy<any> | FieldReadFunction<any>;
  min?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev?: FieldPolicy<any> | FieldReadFunction<any>;
  stddevPop?: FieldPolicy<any> | FieldReadFunction<any>;
  stddevSamp?: FieldPolicy<any> | FieldReadFunction<any>;
  sum?: FieldPolicy<any> | FieldReadFunction<any>;
  varPop?: FieldPolicy<any> | FieldReadFunction<any>;
  varSamp?: FieldPolicy<any> | FieldReadFunction<any>;
  variance?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StatTournamentsAvgFieldsKeySpecifier = (
  | 'buyIn'
  | 'buyInLocal'
  | 'id'
  | 'localCurrencyId'
  | 'prizepool'
  | 'prizepoolLocal'
  | 'statEventId'
  | 'statFileId'
  | StatTournamentsAvgFieldsKeySpecifier
)[];
export type StatTournamentsAvgFieldsFieldPolicy = {
  buyIn?: FieldPolicy<any> | FieldReadFunction<any>;
  buyInLocal?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  localCurrencyId?: FieldPolicy<any> | FieldReadFunction<any>;
  prizepool?: FieldPolicy<any> | FieldReadFunction<any>;
  prizepoolLocal?: FieldPolicy<any> | FieldReadFunction<any>;
  statEventId?: FieldPolicy<any> | FieldReadFunction<any>;
  statFileId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StatTournamentsMaxFieldsKeySpecifier = (
  | 'buyIn'
  | 'buyInLocal'
  | 'createdAt'
  | 'date'
  | 'id'
  | 'localCurrencyId'
  | 'location'
  | 'name'
  | 'prizepool'
  | 'prizepoolLocal'
  | 'statEventId'
  | 'statFileId'
  | 'updatedAt'
  | 'venueId'
  | StatTournamentsMaxFieldsKeySpecifier
)[];
export type StatTournamentsMaxFieldsFieldPolicy = {
  buyIn?: FieldPolicy<any> | FieldReadFunction<any>;
  buyInLocal?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  date?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  localCurrencyId?: FieldPolicy<any> | FieldReadFunction<any>;
  location?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  prizepool?: FieldPolicy<any> | FieldReadFunction<any>;
  prizepoolLocal?: FieldPolicy<any> | FieldReadFunction<any>;
  statEventId?: FieldPolicy<any> | FieldReadFunction<any>;
  statFileId?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  venueId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StatTournamentsMinFieldsKeySpecifier = (
  | 'buyIn'
  | 'buyInLocal'
  | 'createdAt'
  | 'date'
  | 'id'
  | 'localCurrencyId'
  | 'location'
  | 'name'
  | 'prizepool'
  | 'prizepoolLocal'
  | 'statEventId'
  | 'statFileId'
  | 'updatedAt'
  | 'venueId'
  | StatTournamentsMinFieldsKeySpecifier
)[];
export type StatTournamentsMinFieldsFieldPolicy = {
  buyIn?: FieldPolicy<any> | FieldReadFunction<any>;
  buyInLocal?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  date?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  localCurrencyId?: FieldPolicy<any> | FieldReadFunction<any>;
  location?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  prizepool?: FieldPolicy<any> | FieldReadFunction<any>;
  prizepoolLocal?: FieldPolicy<any> | FieldReadFunction<any>;
  statEventId?: FieldPolicy<any> | FieldReadFunction<any>;
  statFileId?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  venueId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StatTournamentsMutationResponseKeySpecifier = (
  | 'affected_rows'
  | 'returning'
  | StatTournamentsMutationResponseKeySpecifier
)[];
export type StatTournamentsMutationResponseFieldPolicy = {
  affected_rows?: FieldPolicy<any> | FieldReadFunction<any>;
  returning?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StatTournamentsStddevFieldsKeySpecifier = (
  | 'buyIn'
  | 'buyInLocal'
  | 'id'
  | 'localCurrencyId'
  | 'prizepool'
  | 'prizepoolLocal'
  | 'statEventId'
  | 'statFileId'
  | StatTournamentsStddevFieldsKeySpecifier
)[];
export type StatTournamentsStddevFieldsFieldPolicy = {
  buyIn?: FieldPolicy<any> | FieldReadFunction<any>;
  buyInLocal?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  localCurrencyId?: FieldPolicy<any> | FieldReadFunction<any>;
  prizepool?: FieldPolicy<any> | FieldReadFunction<any>;
  prizepoolLocal?: FieldPolicy<any> | FieldReadFunction<any>;
  statEventId?: FieldPolicy<any> | FieldReadFunction<any>;
  statFileId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StatTournamentsStddev_popFieldsKeySpecifier = (
  | 'buyIn'
  | 'buyInLocal'
  | 'id'
  | 'localCurrencyId'
  | 'prizepool'
  | 'prizepoolLocal'
  | 'statEventId'
  | 'statFileId'
  | StatTournamentsStddev_popFieldsKeySpecifier
)[];
export type StatTournamentsStddev_popFieldsFieldPolicy = {
  buyIn?: FieldPolicy<any> | FieldReadFunction<any>;
  buyInLocal?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  localCurrencyId?: FieldPolicy<any> | FieldReadFunction<any>;
  prizepool?: FieldPolicy<any> | FieldReadFunction<any>;
  prizepoolLocal?: FieldPolicy<any> | FieldReadFunction<any>;
  statEventId?: FieldPolicy<any> | FieldReadFunction<any>;
  statFileId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StatTournamentsStddev_sampFieldsKeySpecifier = (
  | 'buyIn'
  | 'buyInLocal'
  | 'id'
  | 'localCurrencyId'
  | 'prizepool'
  | 'prizepoolLocal'
  | 'statEventId'
  | 'statFileId'
  | StatTournamentsStddev_sampFieldsKeySpecifier
)[];
export type StatTournamentsStddev_sampFieldsFieldPolicy = {
  buyIn?: FieldPolicy<any> | FieldReadFunction<any>;
  buyInLocal?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  localCurrencyId?: FieldPolicy<any> | FieldReadFunction<any>;
  prizepool?: FieldPolicy<any> | FieldReadFunction<any>;
  prizepoolLocal?: FieldPolicy<any> | FieldReadFunction<any>;
  statEventId?: FieldPolicy<any> | FieldReadFunction<any>;
  statFileId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StatTournamentsSumFieldsKeySpecifier = (
  | 'buyIn'
  | 'buyInLocal'
  | 'id'
  | 'localCurrencyId'
  | 'prizepool'
  | 'prizepoolLocal'
  | 'statEventId'
  | 'statFileId'
  | StatTournamentsSumFieldsKeySpecifier
)[];
export type StatTournamentsSumFieldsFieldPolicy = {
  buyIn?: FieldPolicy<any> | FieldReadFunction<any>;
  buyInLocal?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  localCurrencyId?: FieldPolicy<any> | FieldReadFunction<any>;
  prizepool?: FieldPolicy<any> | FieldReadFunction<any>;
  prizepoolLocal?: FieldPolicy<any> | FieldReadFunction<any>;
  statEventId?: FieldPolicy<any> | FieldReadFunction<any>;
  statFileId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StatTournamentsVar_popFieldsKeySpecifier = (
  | 'buyIn'
  | 'buyInLocal'
  | 'id'
  | 'localCurrencyId'
  | 'prizepool'
  | 'prizepoolLocal'
  | 'statEventId'
  | 'statFileId'
  | StatTournamentsVar_popFieldsKeySpecifier
)[];
export type StatTournamentsVar_popFieldsFieldPolicy = {
  buyIn?: FieldPolicy<any> | FieldReadFunction<any>;
  buyInLocal?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  localCurrencyId?: FieldPolicy<any> | FieldReadFunction<any>;
  prizepool?: FieldPolicy<any> | FieldReadFunction<any>;
  prizepoolLocal?: FieldPolicy<any> | FieldReadFunction<any>;
  statEventId?: FieldPolicy<any> | FieldReadFunction<any>;
  statFileId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StatTournamentsVar_sampFieldsKeySpecifier = (
  | 'buyIn'
  | 'buyInLocal'
  | 'id'
  | 'localCurrencyId'
  | 'prizepool'
  | 'prizepoolLocal'
  | 'statEventId'
  | 'statFileId'
  | StatTournamentsVar_sampFieldsKeySpecifier
)[];
export type StatTournamentsVar_sampFieldsFieldPolicy = {
  buyIn?: FieldPolicy<any> | FieldReadFunction<any>;
  buyInLocal?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  localCurrencyId?: FieldPolicy<any> | FieldReadFunction<any>;
  prizepool?: FieldPolicy<any> | FieldReadFunction<any>;
  prizepoolLocal?: FieldPolicy<any> | FieldReadFunction<any>;
  statEventId?: FieldPolicy<any> | FieldReadFunction<any>;
  statFileId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StatTournamentsVarianceFieldsKeySpecifier = (
  | 'buyIn'
  | 'buyInLocal'
  | 'id'
  | 'localCurrencyId'
  | 'prizepool'
  | 'prizepoolLocal'
  | 'statEventId'
  | 'statFileId'
  | StatTournamentsVarianceFieldsKeySpecifier
)[];
export type StatTournamentsVarianceFieldsFieldPolicy = {
  buyIn?: FieldPolicy<any> | FieldReadFunction<any>;
  buyInLocal?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  localCurrencyId?: FieldPolicy<any> | FieldReadFunction<any>;
  prizepool?: FieldPolicy<any> | FieldReadFunction<any>;
  prizepoolLocal?: FieldPolicy<any> | FieldReadFunction<any>;
  statEventId?: FieldPolicy<any> | FieldReadFunction<any>;
  statFileId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StatusGamesKeySpecifier = (
  | 'description'
  | 'name'
  | StatusGamesKeySpecifier
)[];
export type StatusGamesFieldPolicy = {
  description?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StatusGamesAggregateKeySpecifier = (
  | 'aggregate'
  | 'nodes'
  | StatusGamesAggregateKeySpecifier
)[];
export type StatusGamesAggregateFieldPolicy = {
  aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StatusGamesAggregateFieldsKeySpecifier = (
  | 'count'
  | 'max'
  | 'min'
  | StatusGamesAggregateFieldsKeySpecifier
)[];
export type StatusGamesAggregateFieldsFieldPolicy = {
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  max?: FieldPolicy<any> | FieldReadFunction<any>;
  min?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StatusGamesMaxFieldsKeySpecifier = (
  | 'description'
  | 'name'
  | StatusGamesMaxFieldsKeySpecifier
)[];
export type StatusGamesMaxFieldsFieldPolicy = {
  description?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StatusGamesMinFieldsKeySpecifier = (
  | 'description'
  | 'name'
  | StatusGamesMinFieldsKeySpecifier
)[];
export type StatusGamesMinFieldsFieldPolicy = {
  description?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StatusGamesMutationResponseKeySpecifier = (
  | 'affected_rows'
  | 'returning'
  | StatusGamesMutationResponseKeySpecifier
)[];
export type StatusGamesMutationResponseFieldPolicy = {
  affected_rows?: FieldPolicy<any> | FieldReadFunction<any>;
  returning?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StatusOutputKeySpecifier = (
  | 'id'
  | 'message'
  | 'status'
  | StatusOutputKeySpecifier
)[];
export type StatusOutputFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  message?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StatusesKeySpecifier = (
  | 'description'
  | 'name'
  | StatusesKeySpecifier
)[];
export type StatusesFieldPolicy = {
  description?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StatusesAggregateKeySpecifier = (
  | 'aggregate'
  | 'nodes'
  | StatusesAggregateKeySpecifier
)[];
export type StatusesAggregateFieldPolicy = {
  aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StatusesAggregateFieldsKeySpecifier = (
  | 'count'
  | 'max'
  | 'min'
  | StatusesAggregateFieldsKeySpecifier
)[];
export type StatusesAggregateFieldsFieldPolicy = {
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  max?: FieldPolicy<any> | FieldReadFunction<any>;
  min?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StatusesMaxFieldsKeySpecifier = (
  | 'description'
  | 'name'
  | StatusesMaxFieldsKeySpecifier
)[];
export type StatusesMaxFieldsFieldPolicy = {
  description?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StatusesMinFieldsKeySpecifier = (
  | 'description'
  | 'name'
  | StatusesMinFieldsKeySpecifier
)[];
export type StatusesMinFieldsFieldPolicy = {
  description?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StatusesMutationResponseKeySpecifier = (
  | 'affected_rows'
  | 'returning'
  | StatusesMutationResponseKeySpecifier
)[];
export type StatusesMutationResponseFieldPolicy = {
  affected_rows?: FieldPolicy<any> | FieldReadFunction<any>;
  returning?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TicketsKeySpecifier = (
  | 'amount'
  | 'bullet'
  | 'createdAt'
  | 'id'
  | 'playerName'
  | 'playerShareId'
  | 'player_share'
  | 'status'
  | 'tournament'
  | 'tournamentId'
  | 'tournamentResultId'
  | 'tournament_result'
  | 'updatedAt'
  | 'user'
  | 'userId'
  | 'won'
  | 'wonLocal'
  | TicketsKeySpecifier
)[];
export type TicketsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  bullet?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  playerName?: FieldPolicy<any> | FieldReadFunction<any>;
  playerShareId?: FieldPolicy<any> | FieldReadFunction<any>;
  player_share?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  tournament?: FieldPolicy<any> | FieldReadFunction<any>;
  tournamentId?: FieldPolicy<any> | FieldReadFunction<any>;
  tournamentResultId?: FieldPolicy<any> | FieldReadFunction<any>;
  tournament_result?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  user?: FieldPolicy<any> | FieldReadFunction<any>;
  userId?: FieldPolicy<any> | FieldReadFunction<any>;
  won?: FieldPolicy<any> | FieldReadFunction<any>;
  wonLocal?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TicketsAggregateKeySpecifier = (
  | 'aggregate'
  | 'nodes'
  | TicketsAggregateKeySpecifier
)[];
export type TicketsAggregateFieldPolicy = {
  aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TicketsAggregateFieldsKeySpecifier = (
  | 'avg'
  | 'count'
  | 'max'
  | 'min'
  | 'stddev'
  | 'stddevPop'
  | 'stddevSamp'
  | 'sum'
  | 'varPop'
  | 'varSamp'
  | 'variance'
  | TicketsAggregateFieldsKeySpecifier
)[];
export type TicketsAggregateFieldsFieldPolicy = {
  avg?: FieldPolicy<any> | FieldReadFunction<any>;
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  max?: FieldPolicy<any> | FieldReadFunction<any>;
  min?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev?: FieldPolicy<any> | FieldReadFunction<any>;
  stddevPop?: FieldPolicy<any> | FieldReadFunction<any>;
  stddevSamp?: FieldPolicy<any> | FieldReadFunction<any>;
  sum?: FieldPolicy<any> | FieldReadFunction<any>;
  varPop?: FieldPolicy<any> | FieldReadFunction<any>;
  varSamp?: FieldPolicy<any> | FieldReadFunction<any>;
  variance?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TicketsAvgFieldsKeySpecifier = (
  | 'amount'
  | 'bullet'
  | 'tournamentResultId'
  | 'won'
  | 'wonLocal'
  | TicketsAvgFieldsKeySpecifier
)[];
export type TicketsAvgFieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  bullet?: FieldPolicy<any> | FieldReadFunction<any>;
  tournamentResultId?: FieldPolicy<any> | FieldReadFunction<any>;
  won?: FieldPolicy<any> | FieldReadFunction<any>;
  wonLocal?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TicketsMaxFieldsKeySpecifier = (
  | 'amount'
  | 'bullet'
  | 'createdAt'
  | 'id'
  | 'playerName'
  | 'playerShareId'
  | 'tournamentId'
  | 'tournamentResultId'
  | 'updatedAt'
  | 'userId'
  | 'won'
  | 'wonLocal'
  | TicketsMaxFieldsKeySpecifier
)[];
export type TicketsMaxFieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  bullet?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  playerName?: FieldPolicy<any> | FieldReadFunction<any>;
  playerShareId?: FieldPolicy<any> | FieldReadFunction<any>;
  tournamentId?: FieldPolicy<any> | FieldReadFunction<any>;
  tournamentResultId?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  userId?: FieldPolicy<any> | FieldReadFunction<any>;
  won?: FieldPolicy<any> | FieldReadFunction<any>;
  wonLocal?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TicketsMinFieldsKeySpecifier = (
  | 'amount'
  | 'bullet'
  | 'createdAt'
  | 'id'
  | 'playerName'
  | 'playerShareId'
  | 'tournamentId'
  | 'tournamentResultId'
  | 'updatedAt'
  | 'userId'
  | 'won'
  | 'wonLocal'
  | TicketsMinFieldsKeySpecifier
)[];
export type TicketsMinFieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  bullet?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  playerName?: FieldPolicy<any> | FieldReadFunction<any>;
  playerShareId?: FieldPolicy<any> | FieldReadFunction<any>;
  tournamentId?: FieldPolicy<any> | FieldReadFunction<any>;
  tournamentResultId?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  userId?: FieldPolicy<any> | FieldReadFunction<any>;
  won?: FieldPolicy<any> | FieldReadFunction<any>;
  wonLocal?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TicketsMutationResponseKeySpecifier = (
  | 'affected_rows'
  | 'returning'
  | TicketsMutationResponseKeySpecifier
)[];
export type TicketsMutationResponseFieldPolicy = {
  affected_rows?: FieldPolicy<any> | FieldReadFunction<any>;
  returning?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TicketsStatusKeySpecifier = (
  | 'description'
  | 'name'
  | TicketsStatusKeySpecifier
)[];
export type TicketsStatusFieldPolicy = {
  description?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TicketsStatusAggregateKeySpecifier = (
  | 'aggregate'
  | 'nodes'
  | TicketsStatusAggregateKeySpecifier
)[];
export type TicketsStatusAggregateFieldPolicy = {
  aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TicketsStatusAggregateFieldsKeySpecifier = (
  | 'count'
  | 'max'
  | 'min'
  | TicketsStatusAggregateFieldsKeySpecifier
)[];
export type TicketsStatusAggregateFieldsFieldPolicy = {
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  max?: FieldPolicy<any> | FieldReadFunction<any>;
  min?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TicketsStatusMaxFieldsKeySpecifier = (
  | 'description'
  | 'name'
  | TicketsStatusMaxFieldsKeySpecifier
)[];
export type TicketsStatusMaxFieldsFieldPolicy = {
  description?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TicketsStatusMinFieldsKeySpecifier = (
  | 'description'
  | 'name'
  | TicketsStatusMinFieldsKeySpecifier
)[];
export type TicketsStatusMinFieldsFieldPolicy = {
  description?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TicketsStatusMutationResponseKeySpecifier = (
  | 'affected_rows'
  | 'returning'
  | TicketsStatusMutationResponseKeySpecifier
)[];
export type TicketsStatusMutationResponseFieldPolicy = {
  affected_rows?: FieldPolicy<any> | FieldReadFunction<any>;
  returning?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TicketsStddevFieldsKeySpecifier = (
  | 'amount'
  | 'bullet'
  | 'tournamentResultId'
  | 'won'
  | 'wonLocal'
  | TicketsStddevFieldsKeySpecifier
)[];
export type TicketsStddevFieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  bullet?: FieldPolicy<any> | FieldReadFunction<any>;
  tournamentResultId?: FieldPolicy<any> | FieldReadFunction<any>;
  won?: FieldPolicy<any> | FieldReadFunction<any>;
  wonLocal?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TicketsStddev_popFieldsKeySpecifier = (
  | 'amount'
  | 'bullet'
  | 'tournamentResultId'
  | 'won'
  | 'wonLocal'
  | TicketsStddev_popFieldsKeySpecifier
)[];
export type TicketsStddev_popFieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  bullet?: FieldPolicy<any> | FieldReadFunction<any>;
  tournamentResultId?: FieldPolicy<any> | FieldReadFunction<any>;
  won?: FieldPolicy<any> | FieldReadFunction<any>;
  wonLocal?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TicketsStddev_sampFieldsKeySpecifier = (
  | 'amount'
  | 'bullet'
  | 'tournamentResultId'
  | 'won'
  | 'wonLocal'
  | TicketsStddev_sampFieldsKeySpecifier
)[];
export type TicketsStddev_sampFieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  bullet?: FieldPolicy<any> | FieldReadFunction<any>;
  tournamentResultId?: FieldPolicy<any> | FieldReadFunction<any>;
  won?: FieldPolicy<any> | FieldReadFunction<any>;
  wonLocal?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TicketsSumFieldsKeySpecifier = (
  | 'amount'
  | 'bullet'
  | 'tournamentResultId'
  | 'won'
  | 'wonLocal'
  | TicketsSumFieldsKeySpecifier
)[];
export type TicketsSumFieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  bullet?: FieldPolicy<any> | FieldReadFunction<any>;
  tournamentResultId?: FieldPolicy<any> | FieldReadFunction<any>;
  won?: FieldPolicy<any> | FieldReadFunction<any>;
  wonLocal?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TicketsVar_popFieldsKeySpecifier = (
  | 'amount'
  | 'bullet'
  | 'tournamentResultId'
  | 'won'
  | 'wonLocal'
  | TicketsVar_popFieldsKeySpecifier
)[];
export type TicketsVar_popFieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  bullet?: FieldPolicy<any> | FieldReadFunction<any>;
  tournamentResultId?: FieldPolicy<any> | FieldReadFunction<any>;
  won?: FieldPolicy<any> | FieldReadFunction<any>;
  wonLocal?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TicketsVar_sampFieldsKeySpecifier = (
  | 'amount'
  | 'bullet'
  | 'tournamentResultId'
  | 'won'
  | 'wonLocal'
  | TicketsVar_sampFieldsKeySpecifier
)[];
export type TicketsVar_sampFieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  bullet?: FieldPolicy<any> | FieldReadFunction<any>;
  tournamentResultId?: FieldPolicy<any> | FieldReadFunction<any>;
  won?: FieldPolicy<any> | FieldReadFunction<any>;
  wonLocal?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TicketsVarianceFieldsKeySpecifier = (
  | 'amount'
  | 'bullet'
  | 'tournamentResultId'
  | 'won'
  | 'wonLocal'
  | TicketsVarianceFieldsKeySpecifier
)[];
export type TicketsVarianceFieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  bullet?: FieldPolicy<any> | FieldReadFunction<any>;
  tournamentResultId?: FieldPolicy<any> | FieldReadFunction<any>;
  won?: FieldPolicy<any> | FieldReadFunction<any>;
  wonLocal?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TournamentBalancesKeySpecifier = (
  | 'balance'
  | 'currency'
  | 'currencyId'
  | 'eventId'
  | 'id'
  | 'status'
  | 'tournament'
  | 'tournamentId'
  | 'venueId'
  | TournamentBalancesKeySpecifier
)[];
export type TournamentBalancesFieldPolicy = {
  balance?: FieldPolicy<any> | FieldReadFunction<any>;
  currency?: FieldPolicy<any> | FieldReadFunction<any>;
  currencyId?: FieldPolicy<any> | FieldReadFunction<any>;
  eventId?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  tournament?: FieldPolicy<any> | FieldReadFunction<any>;
  tournamentId?: FieldPolicy<any> | FieldReadFunction<any>;
  venueId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TournamentBalancesAggregateKeySpecifier = (
  | 'aggregate'
  | 'nodes'
  | TournamentBalancesAggregateKeySpecifier
)[];
export type TournamentBalancesAggregateFieldPolicy = {
  aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TournamentBalancesAggregateFieldsKeySpecifier = (
  | 'avg'
  | 'count'
  | 'max'
  | 'min'
  | 'stddev'
  | 'stddevPop'
  | 'stddevSamp'
  | 'sum'
  | 'varPop'
  | 'varSamp'
  | 'variance'
  | TournamentBalancesAggregateFieldsKeySpecifier
)[];
export type TournamentBalancesAggregateFieldsFieldPolicy = {
  avg?: FieldPolicy<any> | FieldReadFunction<any>;
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  max?: FieldPolicy<any> | FieldReadFunction<any>;
  min?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev?: FieldPolicy<any> | FieldReadFunction<any>;
  stddevPop?: FieldPolicy<any> | FieldReadFunction<any>;
  stddevSamp?: FieldPolicy<any> | FieldReadFunction<any>;
  sum?: FieldPolicy<any> | FieldReadFunction<any>;
  varPop?: FieldPolicy<any> | FieldReadFunction<any>;
  varSamp?: FieldPolicy<any> | FieldReadFunction<any>;
  variance?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TournamentBalancesAvgFieldsKeySpecifier = (
  | 'balance'
  | 'currencyId'
  | TournamentBalancesAvgFieldsKeySpecifier
)[];
export type TournamentBalancesAvgFieldsFieldPolicy = {
  balance?: FieldPolicy<any> | FieldReadFunction<any>;
  currencyId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TournamentBalancesMaxFieldsKeySpecifier = (
  | 'balance'
  | 'currencyId'
  | 'eventId'
  | 'id'
  | 'status'
  | 'tournamentId'
  | 'venueId'
  | TournamentBalancesMaxFieldsKeySpecifier
)[];
export type TournamentBalancesMaxFieldsFieldPolicy = {
  balance?: FieldPolicy<any> | FieldReadFunction<any>;
  currencyId?: FieldPolicy<any> | FieldReadFunction<any>;
  eventId?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  tournamentId?: FieldPolicy<any> | FieldReadFunction<any>;
  venueId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TournamentBalancesMinFieldsKeySpecifier = (
  | 'balance'
  | 'currencyId'
  | 'eventId'
  | 'id'
  | 'status'
  | 'tournamentId'
  | 'venueId'
  | TournamentBalancesMinFieldsKeySpecifier
)[];
export type TournamentBalancesMinFieldsFieldPolicy = {
  balance?: FieldPolicy<any> | FieldReadFunction<any>;
  currencyId?: FieldPolicy<any> | FieldReadFunction<any>;
  eventId?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  tournamentId?: FieldPolicy<any> | FieldReadFunction<any>;
  venueId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TournamentBalancesStddevFieldsKeySpecifier = (
  | 'balance'
  | 'currencyId'
  | TournamentBalancesStddevFieldsKeySpecifier
)[];
export type TournamentBalancesStddevFieldsFieldPolicy = {
  balance?: FieldPolicy<any> | FieldReadFunction<any>;
  currencyId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TournamentBalancesStddev_popFieldsKeySpecifier = (
  | 'balance'
  | 'currencyId'
  | TournamentBalancesStddev_popFieldsKeySpecifier
)[];
export type TournamentBalancesStddev_popFieldsFieldPolicy = {
  balance?: FieldPolicy<any> | FieldReadFunction<any>;
  currencyId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TournamentBalancesStddev_sampFieldsKeySpecifier = (
  | 'balance'
  | 'currencyId'
  | TournamentBalancesStddev_sampFieldsKeySpecifier
)[];
export type TournamentBalancesStddev_sampFieldsFieldPolicy = {
  balance?: FieldPolicy<any> | FieldReadFunction<any>;
  currencyId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TournamentBalancesSumFieldsKeySpecifier = (
  | 'balance'
  | 'currencyId'
  | TournamentBalancesSumFieldsKeySpecifier
)[];
export type TournamentBalancesSumFieldsFieldPolicy = {
  balance?: FieldPolicy<any> | FieldReadFunction<any>;
  currencyId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TournamentBalancesVar_popFieldsKeySpecifier = (
  | 'balance'
  | 'currencyId'
  | TournamentBalancesVar_popFieldsKeySpecifier
)[];
export type TournamentBalancesVar_popFieldsFieldPolicy = {
  balance?: FieldPolicy<any> | FieldReadFunction<any>;
  currencyId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TournamentBalancesVar_sampFieldsKeySpecifier = (
  | 'balance'
  | 'currencyId'
  | TournamentBalancesVar_sampFieldsKeySpecifier
)[];
export type TournamentBalancesVar_sampFieldsFieldPolicy = {
  balance?: FieldPolicy<any> | FieldReadFunction<any>;
  currencyId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TournamentBalancesVarianceFieldsKeySpecifier = (
  | 'balance'
  | 'currencyId'
  | TournamentBalancesVarianceFieldsKeySpecifier
)[];
export type TournamentBalancesVarianceFieldsFieldPolicy = {
  balance?: FieldPolicy<any> | FieldReadFunction<any>;
  currencyId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TournamentResultStatusesKeySpecifier = (
  | 'description'
  | 'name'
  | TournamentResultStatusesKeySpecifier
)[];
export type TournamentResultStatusesFieldPolicy = {
  description?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TournamentResultStatusesAggregateKeySpecifier = (
  | 'aggregate'
  | 'nodes'
  | TournamentResultStatusesAggregateKeySpecifier
)[];
export type TournamentResultStatusesAggregateFieldPolicy = {
  aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TournamentResultStatusesAggregateFieldsKeySpecifier = (
  | 'count'
  | 'max'
  | 'min'
  | TournamentResultStatusesAggregateFieldsKeySpecifier
)[];
export type TournamentResultStatusesAggregateFieldsFieldPolicy = {
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  max?: FieldPolicy<any> | FieldReadFunction<any>;
  min?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TournamentResultStatusesMaxFieldsKeySpecifier = (
  | 'description'
  | 'name'
  | TournamentResultStatusesMaxFieldsKeySpecifier
)[];
export type TournamentResultStatusesMaxFieldsFieldPolicy = {
  description?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TournamentResultStatusesMinFieldsKeySpecifier = (
  | 'description'
  | 'name'
  | TournamentResultStatusesMinFieldsKeySpecifier
)[];
export type TournamentResultStatusesMinFieldsFieldPolicy = {
  description?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TournamentResultStatusesMutationResponseKeySpecifier = (
  | 'affected_rows'
  | 'returning'
  | TournamentResultStatusesMutationResponseKeySpecifier
)[];
export type TournamentResultStatusesMutationResponseFieldPolicy = {
  affected_rows?: FieldPolicy<any> | FieldReadFunction<any>;
  returning?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TournamentResultsKeySpecifier = (
  | 'bullets'
  | 'createdAt'
  | 'id'
  | 'name'
  | 'place'
  | 'player'
  | 'playerId'
  | 'playerPayout'
  | 'playerSharesAggregate'
  | 'player_shares'
  | 'roi'
  | 'stakedAmount'
  | 'stakedPercentage'
  | 'status'
  | 'tickets'
  | 'ticketsAggregate'
  | 'toBePaid'
  | 'tournament'
  | 'tournamentId'
  | 'updatedAt'
  | 'user'
  | 'userId'
  | 'winning'
  | TournamentResultsKeySpecifier
)[];
export type TournamentResultsFieldPolicy = {
  bullets?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  place?: FieldPolicy<any> | FieldReadFunction<any>;
  player?: FieldPolicy<any> | FieldReadFunction<any>;
  playerId?: FieldPolicy<any> | FieldReadFunction<any>;
  playerPayout?: FieldPolicy<any> | FieldReadFunction<any>;
  playerSharesAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  player_shares?: FieldPolicy<any> | FieldReadFunction<any>;
  roi?: FieldPolicy<any> | FieldReadFunction<any>;
  stakedAmount?: FieldPolicy<any> | FieldReadFunction<any>;
  stakedPercentage?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  tickets?: FieldPolicy<any> | FieldReadFunction<any>;
  ticketsAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  toBePaid?: FieldPolicy<any> | FieldReadFunction<any>;
  tournament?: FieldPolicy<any> | FieldReadFunction<any>;
  tournamentId?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  user?: FieldPolicy<any> | FieldReadFunction<any>;
  userId?: FieldPolicy<any> | FieldReadFunction<any>;
  winning?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TournamentResultsAggregateKeySpecifier = (
  | 'aggregate'
  | 'nodes'
  | TournamentResultsAggregateKeySpecifier
)[];
export type TournamentResultsAggregateFieldPolicy = {
  aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TournamentResultsAggregateFieldsKeySpecifier = (
  | 'avg'
  | 'count'
  | 'max'
  | 'min'
  | 'stddev'
  | 'stddevPop'
  | 'stddevSamp'
  | 'sum'
  | 'varPop'
  | 'varSamp'
  | 'variance'
  | TournamentResultsAggregateFieldsKeySpecifier
)[];
export type TournamentResultsAggregateFieldsFieldPolicy = {
  avg?: FieldPolicy<any> | FieldReadFunction<any>;
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  max?: FieldPolicy<any> | FieldReadFunction<any>;
  min?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev?: FieldPolicy<any> | FieldReadFunction<any>;
  stddevPop?: FieldPolicy<any> | FieldReadFunction<any>;
  stddevSamp?: FieldPolicy<any> | FieldReadFunction<any>;
  sum?: FieldPolicy<any> | FieldReadFunction<any>;
  varPop?: FieldPolicy<any> | FieldReadFunction<any>;
  varSamp?: FieldPolicy<any> | FieldReadFunction<any>;
  variance?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TournamentResultsAvgFieldsKeySpecifier = (
  | 'bullets'
  | 'id'
  | 'place'
  | 'playerPayout'
  | 'roi'
  | 'stakedAmount'
  | 'stakedPercentage'
  | 'winning'
  | TournamentResultsAvgFieldsKeySpecifier
)[];
export type TournamentResultsAvgFieldsFieldPolicy = {
  bullets?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  place?: FieldPolicy<any> | FieldReadFunction<any>;
  playerPayout?: FieldPolicy<any> | FieldReadFunction<any>;
  roi?: FieldPolicy<any> | FieldReadFunction<any>;
  stakedAmount?: FieldPolicy<any> | FieldReadFunction<any>;
  stakedPercentage?: FieldPolicy<any> | FieldReadFunction<any>;
  winning?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TournamentResultsMaxFieldsKeySpecifier = (
  | 'bullets'
  | 'createdAt'
  | 'id'
  | 'name'
  | 'place'
  | 'playerId'
  | 'playerPayout'
  | 'roi'
  | 'stakedAmount'
  | 'stakedPercentage'
  | 'tournamentId'
  | 'updatedAt'
  | 'userId'
  | 'winning'
  | TournamentResultsMaxFieldsKeySpecifier
)[];
export type TournamentResultsMaxFieldsFieldPolicy = {
  bullets?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  place?: FieldPolicy<any> | FieldReadFunction<any>;
  playerId?: FieldPolicy<any> | FieldReadFunction<any>;
  playerPayout?: FieldPolicy<any> | FieldReadFunction<any>;
  roi?: FieldPolicy<any> | FieldReadFunction<any>;
  stakedAmount?: FieldPolicy<any> | FieldReadFunction<any>;
  stakedPercentage?: FieldPolicy<any> | FieldReadFunction<any>;
  tournamentId?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  userId?: FieldPolicy<any> | FieldReadFunction<any>;
  winning?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TournamentResultsMinFieldsKeySpecifier = (
  | 'bullets'
  | 'createdAt'
  | 'id'
  | 'name'
  | 'place'
  | 'playerId'
  | 'playerPayout'
  | 'roi'
  | 'stakedAmount'
  | 'stakedPercentage'
  | 'tournamentId'
  | 'updatedAt'
  | 'userId'
  | 'winning'
  | TournamentResultsMinFieldsKeySpecifier
)[];
export type TournamentResultsMinFieldsFieldPolicy = {
  bullets?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  place?: FieldPolicy<any> | FieldReadFunction<any>;
  playerId?: FieldPolicy<any> | FieldReadFunction<any>;
  playerPayout?: FieldPolicy<any> | FieldReadFunction<any>;
  roi?: FieldPolicy<any> | FieldReadFunction<any>;
  stakedAmount?: FieldPolicy<any> | FieldReadFunction<any>;
  stakedPercentage?: FieldPolicy<any> | FieldReadFunction<any>;
  tournamentId?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  userId?: FieldPolicy<any> | FieldReadFunction<any>;
  winning?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TournamentResultsMutationResponseKeySpecifier = (
  | 'affected_rows'
  | 'returning'
  | TournamentResultsMutationResponseKeySpecifier
)[];
export type TournamentResultsMutationResponseFieldPolicy = {
  affected_rows?: FieldPolicy<any> | FieldReadFunction<any>;
  returning?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TournamentResultsStddevFieldsKeySpecifier = (
  | 'bullets'
  | 'id'
  | 'place'
  | 'playerPayout'
  | 'roi'
  | 'stakedAmount'
  | 'stakedPercentage'
  | 'winning'
  | TournamentResultsStddevFieldsKeySpecifier
)[];
export type TournamentResultsStddevFieldsFieldPolicy = {
  bullets?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  place?: FieldPolicy<any> | FieldReadFunction<any>;
  playerPayout?: FieldPolicy<any> | FieldReadFunction<any>;
  roi?: FieldPolicy<any> | FieldReadFunction<any>;
  stakedAmount?: FieldPolicy<any> | FieldReadFunction<any>;
  stakedPercentage?: FieldPolicy<any> | FieldReadFunction<any>;
  winning?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TournamentResultsStddev_popFieldsKeySpecifier = (
  | 'bullets'
  | 'id'
  | 'place'
  | 'playerPayout'
  | 'roi'
  | 'stakedAmount'
  | 'stakedPercentage'
  | 'winning'
  | TournamentResultsStddev_popFieldsKeySpecifier
)[];
export type TournamentResultsStddev_popFieldsFieldPolicy = {
  bullets?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  place?: FieldPolicy<any> | FieldReadFunction<any>;
  playerPayout?: FieldPolicy<any> | FieldReadFunction<any>;
  roi?: FieldPolicy<any> | FieldReadFunction<any>;
  stakedAmount?: FieldPolicy<any> | FieldReadFunction<any>;
  stakedPercentage?: FieldPolicy<any> | FieldReadFunction<any>;
  winning?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TournamentResultsStddev_sampFieldsKeySpecifier = (
  | 'bullets'
  | 'id'
  | 'place'
  | 'playerPayout'
  | 'roi'
  | 'stakedAmount'
  | 'stakedPercentage'
  | 'winning'
  | TournamentResultsStddev_sampFieldsKeySpecifier
)[];
export type TournamentResultsStddev_sampFieldsFieldPolicy = {
  bullets?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  place?: FieldPolicy<any> | FieldReadFunction<any>;
  playerPayout?: FieldPolicy<any> | FieldReadFunction<any>;
  roi?: FieldPolicy<any> | FieldReadFunction<any>;
  stakedAmount?: FieldPolicy<any> | FieldReadFunction<any>;
  stakedPercentage?: FieldPolicy<any> | FieldReadFunction<any>;
  winning?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TournamentResultsSumFieldsKeySpecifier = (
  | 'bullets'
  | 'id'
  | 'place'
  | 'playerPayout'
  | 'roi'
  | 'stakedAmount'
  | 'stakedPercentage'
  | 'winning'
  | TournamentResultsSumFieldsKeySpecifier
)[];
export type TournamentResultsSumFieldsFieldPolicy = {
  bullets?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  place?: FieldPolicy<any> | FieldReadFunction<any>;
  playerPayout?: FieldPolicy<any> | FieldReadFunction<any>;
  roi?: FieldPolicy<any> | FieldReadFunction<any>;
  stakedAmount?: FieldPolicy<any> | FieldReadFunction<any>;
  stakedPercentage?: FieldPolicy<any> | FieldReadFunction<any>;
  winning?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TournamentResultsVar_popFieldsKeySpecifier = (
  | 'bullets'
  | 'id'
  | 'place'
  | 'playerPayout'
  | 'roi'
  | 'stakedAmount'
  | 'stakedPercentage'
  | 'winning'
  | TournamentResultsVar_popFieldsKeySpecifier
)[];
export type TournamentResultsVar_popFieldsFieldPolicy = {
  bullets?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  place?: FieldPolicy<any> | FieldReadFunction<any>;
  playerPayout?: FieldPolicy<any> | FieldReadFunction<any>;
  roi?: FieldPolicy<any> | FieldReadFunction<any>;
  stakedAmount?: FieldPolicy<any> | FieldReadFunction<any>;
  stakedPercentage?: FieldPolicy<any> | FieldReadFunction<any>;
  winning?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TournamentResultsVar_sampFieldsKeySpecifier = (
  | 'bullets'
  | 'id'
  | 'place'
  | 'playerPayout'
  | 'roi'
  | 'stakedAmount'
  | 'stakedPercentage'
  | 'winning'
  | TournamentResultsVar_sampFieldsKeySpecifier
)[];
export type TournamentResultsVar_sampFieldsFieldPolicy = {
  bullets?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  place?: FieldPolicy<any> | FieldReadFunction<any>;
  playerPayout?: FieldPolicy<any> | FieldReadFunction<any>;
  roi?: FieldPolicy<any> | FieldReadFunction<any>;
  stakedAmount?: FieldPolicy<any> | FieldReadFunction<any>;
  stakedPercentage?: FieldPolicy<any> | FieldReadFunction<any>;
  winning?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TournamentResultsVarianceFieldsKeySpecifier = (
  | 'bullets'
  | 'id'
  | 'place'
  | 'playerPayout'
  | 'roi'
  | 'stakedAmount'
  | 'stakedPercentage'
  | 'winning'
  | TournamentResultsVarianceFieldsKeySpecifier
)[];
export type TournamentResultsVarianceFieldsFieldPolicy = {
  bullets?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  place?: FieldPolicy<any> | FieldReadFunction<any>;
  playerPayout?: FieldPolicy<any> | FieldReadFunction<any>;
  roi?: FieldPolicy<any> | FieldReadFunction<any>;
  stakedAmount?: FieldPolicy<any> | FieldReadFunction<any>;
  stakedPercentage?: FieldPolicy<any> | FieldReadFunction<any>;
  winning?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TournamentsKeySpecifier = (
  | 'balances'
  | 'balancesAggregate'
  | 'bulletsMax'
  | 'buyIn'
  | 'createdAt'
  | 'currency'
  | 'currencyId'
  | 'currencyRate'
  | 'date'
  | 'event'
  | 'eventId'
  | 'followers'
  | 'followersAggregate'
  | 'guaranteed'
  | 'id'
  | 'image'
  | 'localCurrencyId'
  | 'local_currency'
  | 'location'
  | 'name'
  | 'playerSharesAggregate'
  | 'player_shares'
  | 'registrationEnd'
  | 'sharesMax'
  | 'slug'
  | 'sort'
  | 'status'
  | 'ticketSalesActive'
  | 'tickets'
  | 'ticketsAggregate'
  | 'tournamentResultsAggregate'
  | 'tournament_results'
  | 'transactions'
  | 'transactionsAggregate'
  | 'type'
  | 'updatedAt'
  | 'venue'
  | 'venueId'
  | 'watchUrl'
  | TournamentsKeySpecifier
)[];
export type TournamentsFieldPolicy = {
  balances?: FieldPolicy<any> | FieldReadFunction<any>;
  balancesAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  bulletsMax?: FieldPolicy<any> | FieldReadFunction<any>;
  buyIn?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  currency?: FieldPolicy<any> | FieldReadFunction<any>;
  currencyId?: FieldPolicy<any> | FieldReadFunction<any>;
  currencyRate?: FieldPolicy<any> | FieldReadFunction<any>;
  date?: FieldPolicy<any> | FieldReadFunction<any>;
  event?: FieldPolicy<any> | FieldReadFunction<any>;
  eventId?: FieldPolicy<any> | FieldReadFunction<any>;
  followers?: FieldPolicy<any> | FieldReadFunction<any>;
  followersAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  guaranteed?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  image?: FieldPolicy<any> | FieldReadFunction<any>;
  localCurrencyId?: FieldPolicy<any> | FieldReadFunction<any>;
  local_currency?: FieldPolicy<any> | FieldReadFunction<any>;
  location?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  playerSharesAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  player_shares?: FieldPolicy<any> | FieldReadFunction<any>;
  registrationEnd?: FieldPolicy<any> | FieldReadFunction<any>;
  sharesMax?: FieldPolicy<any> | FieldReadFunction<any>;
  slug?: FieldPolicy<any> | FieldReadFunction<any>;
  sort?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  ticketSalesActive?: FieldPolicy<any> | FieldReadFunction<any>;
  tickets?: FieldPolicy<any> | FieldReadFunction<any>;
  ticketsAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  tournamentResultsAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  tournament_results?: FieldPolicy<any> | FieldReadFunction<any>;
  transactions?: FieldPolicy<any> | FieldReadFunction<any>;
  transactionsAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  venue?: FieldPolicy<any> | FieldReadFunction<any>;
  venueId?: FieldPolicy<any> | FieldReadFunction<any>;
  watchUrl?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TournamentsAggregateKeySpecifier = (
  | 'aggregate'
  | 'nodes'
  | TournamentsAggregateKeySpecifier
)[];
export type TournamentsAggregateFieldPolicy = {
  aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TournamentsAggregateFieldsKeySpecifier = (
  | 'avg'
  | 'count'
  | 'max'
  | 'min'
  | 'stddev'
  | 'stddevPop'
  | 'stddevSamp'
  | 'sum'
  | 'varPop'
  | 'varSamp'
  | 'variance'
  | TournamentsAggregateFieldsKeySpecifier
)[];
export type TournamentsAggregateFieldsFieldPolicy = {
  avg?: FieldPolicy<any> | FieldReadFunction<any>;
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  max?: FieldPolicy<any> | FieldReadFunction<any>;
  min?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev?: FieldPolicy<any> | FieldReadFunction<any>;
  stddevPop?: FieldPolicy<any> | FieldReadFunction<any>;
  stddevSamp?: FieldPolicy<any> | FieldReadFunction<any>;
  sum?: FieldPolicy<any> | FieldReadFunction<any>;
  varPop?: FieldPolicy<any> | FieldReadFunction<any>;
  varSamp?: FieldPolicy<any> | FieldReadFunction<any>;
  variance?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TournamentsAvgFieldsKeySpecifier = (
  | 'bulletsMax'
  | 'buyIn'
  | 'currencyId'
  | 'currencyRate'
  | 'guaranteed'
  | 'localCurrencyId'
  | 'sharesMax'
  | 'sort'
  | TournamentsAvgFieldsKeySpecifier
)[];
export type TournamentsAvgFieldsFieldPolicy = {
  bulletsMax?: FieldPolicy<any> | FieldReadFunction<any>;
  buyIn?: FieldPolicy<any> | FieldReadFunction<any>;
  currencyId?: FieldPolicy<any> | FieldReadFunction<any>;
  currencyRate?: FieldPolicy<any> | FieldReadFunction<any>;
  guaranteed?: FieldPolicy<any> | FieldReadFunction<any>;
  localCurrencyId?: FieldPolicy<any> | FieldReadFunction<any>;
  sharesMax?: FieldPolicy<any> | FieldReadFunction<any>;
  sort?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TournamentsMaxFieldsKeySpecifier = (
  | 'bulletsMax'
  | 'buyIn'
  | 'createdAt'
  | 'currencyId'
  | 'currencyRate'
  | 'date'
  | 'eventId'
  | 'guaranteed'
  | 'id'
  | 'image'
  | 'localCurrencyId'
  | 'location'
  | 'name'
  | 'registrationEnd'
  | 'sharesMax'
  | 'slug'
  | 'sort'
  | 'type'
  | 'updatedAt'
  | 'venueId'
  | 'watchUrl'
  | TournamentsMaxFieldsKeySpecifier
)[];
export type TournamentsMaxFieldsFieldPolicy = {
  bulletsMax?: FieldPolicy<any> | FieldReadFunction<any>;
  buyIn?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  currencyId?: FieldPolicy<any> | FieldReadFunction<any>;
  currencyRate?: FieldPolicy<any> | FieldReadFunction<any>;
  date?: FieldPolicy<any> | FieldReadFunction<any>;
  eventId?: FieldPolicy<any> | FieldReadFunction<any>;
  guaranteed?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  image?: FieldPolicy<any> | FieldReadFunction<any>;
  localCurrencyId?: FieldPolicy<any> | FieldReadFunction<any>;
  location?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  registrationEnd?: FieldPolicy<any> | FieldReadFunction<any>;
  sharesMax?: FieldPolicy<any> | FieldReadFunction<any>;
  slug?: FieldPolicy<any> | FieldReadFunction<any>;
  sort?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  venueId?: FieldPolicy<any> | FieldReadFunction<any>;
  watchUrl?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TournamentsMinFieldsKeySpecifier = (
  | 'bulletsMax'
  | 'buyIn'
  | 'createdAt'
  | 'currencyId'
  | 'currencyRate'
  | 'date'
  | 'eventId'
  | 'guaranteed'
  | 'id'
  | 'image'
  | 'localCurrencyId'
  | 'location'
  | 'name'
  | 'registrationEnd'
  | 'sharesMax'
  | 'slug'
  | 'sort'
  | 'type'
  | 'updatedAt'
  | 'venueId'
  | 'watchUrl'
  | TournamentsMinFieldsKeySpecifier
)[];
export type TournamentsMinFieldsFieldPolicy = {
  bulletsMax?: FieldPolicy<any> | FieldReadFunction<any>;
  buyIn?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  currencyId?: FieldPolicy<any> | FieldReadFunction<any>;
  currencyRate?: FieldPolicy<any> | FieldReadFunction<any>;
  date?: FieldPolicy<any> | FieldReadFunction<any>;
  eventId?: FieldPolicy<any> | FieldReadFunction<any>;
  guaranteed?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  image?: FieldPolicy<any> | FieldReadFunction<any>;
  localCurrencyId?: FieldPolicy<any> | FieldReadFunction<any>;
  location?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  registrationEnd?: FieldPolicy<any> | FieldReadFunction<any>;
  sharesMax?: FieldPolicy<any> | FieldReadFunction<any>;
  slug?: FieldPolicy<any> | FieldReadFunction<any>;
  sort?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  venueId?: FieldPolicy<any> | FieldReadFunction<any>;
  watchUrl?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TournamentsMutationResponseKeySpecifier = (
  | 'affected_rows'
  | 'returning'
  | TournamentsMutationResponseKeySpecifier
)[];
export type TournamentsMutationResponseFieldPolicy = {
  affected_rows?: FieldPolicy<any> | FieldReadFunction<any>;
  returning?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TournamentsStddevFieldsKeySpecifier = (
  | 'bulletsMax'
  | 'buyIn'
  | 'currencyId'
  | 'currencyRate'
  | 'guaranteed'
  | 'localCurrencyId'
  | 'sharesMax'
  | 'sort'
  | TournamentsStddevFieldsKeySpecifier
)[];
export type TournamentsStddevFieldsFieldPolicy = {
  bulletsMax?: FieldPolicy<any> | FieldReadFunction<any>;
  buyIn?: FieldPolicy<any> | FieldReadFunction<any>;
  currencyId?: FieldPolicy<any> | FieldReadFunction<any>;
  currencyRate?: FieldPolicy<any> | FieldReadFunction<any>;
  guaranteed?: FieldPolicy<any> | FieldReadFunction<any>;
  localCurrencyId?: FieldPolicy<any> | FieldReadFunction<any>;
  sharesMax?: FieldPolicy<any> | FieldReadFunction<any>;
  sort?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TournamentsStddev_popFieldsKeySpecifier = (
  | 'bulletsMax'
  | 'buyIn'
  | 'currencyId'
  | 'currencyRate'
  | 'guaranteed'
  | 'localCurrencyId'
  | 'sharesMax'
  | 'sort'
  | TournamentsStddev_popFieldsKeySpecifier
)[];
export type TournamentsStddev_popFieldsFieldPolicy = {
  bulletsMax?: FieldPolicy<any> | FieldReadFunction<any>;
  buyIn?: FieldPolicy<any> | FieldReadFunction<any>;
  currencyId?: FieldPolicy<any> | FieldReadFunction<any>;
  currencyRate?: FieldPolicy<any> | FieldReadFunction<any>;
  guaranteed?: FieldPolicy<any> | FieldReadFunction<any>;
  localCurrencyId?: FieldPolicy<any> | FieldReadFunction<any>;
  sharesMax?: FieldPolicy<any> | FieldReadFunction<any>;
  sort?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TournamentsStddev_sampFieldsKeySpecifier = (
  | 'bulletsMax'
  | 'buyIn'
  | 'currencyId'
  | 'currencyRate'
  | 'guaranteed'
  | 'localCurrencyId'
  | 'sharesMax'
  | 'sort'
  | TournamentsStddev_sampFieldsKeySpecifier
)[];
export type TournamentsStddev_sampFieldsFieldPolicy = {
  bulletsMax?: FieldPolicy<any> | FieldReadFunction<any>;
  buyIn?: FieldPolicy<any> | FieldReadFunction<any>;
  currencyId?: FieldPolicy<any> | FieldReadFunction<any>;
  currencyRate?: FieldPolicy<any> | FieldReadFunction<any>;
  guaranteed?: FieldPolicy<any> | FieldReadFunction<any>;
  localCurrencyId?: FieldPolicy<any> | FieldReadFunction<any>;
  sharesMax?: FieldPolicy<any> | FieldReadFunction<any>;
  sort?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TournamentsSumFieldsKeySpecifier = (
  | 'bulletsMax'
  | 'buyIn'
  | 'currencyId'
  | 'currencyRate'
  | 'guaranteed'
  | 'localCurrencyId'
  | 'sharesMax'
  | 'sort'
  | TournamentsSumFieldsKeySpecifier
)[];
export type TournamentsSumFieldsFieldPolicy = {
  bulletsMax?: FieldPolicy<any> | FieldReadFunction<any>;
  buyIn?: FieldPolicy<any> | FieldReadFunction<any>;
  currencyId?: FieldPolicy<any> | FieldReadFunction<any>;
  currencyRate?: FieldPolicy<any> | FieldReadFunction<any>;
  guaranteed?: FieldPolicy<any> | FieldReadFunction<any>;
  localCurrencyId?: FieldPolicy<any> | FieldReadFunction<any>;
  sharesMax?: FieldPolicy<any> | FieldReadFunction<any>;
  sort?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TournamentsVar_popFieldsKeySpecifier = (
  | 'bulletsMax'
  | 'buyIn'
  | 'currencyId'
  | 'currencyRate'
  | 'guaranteed'
  | 'localCurrencyId'
  | 'sharesMax'
  | 'sort'
  | TournamentsVar_popFieldsKeySpecifier
)[];
export type TournamentsVar_popFieldsFieldPolicy = {
  bulletsMax?: FieldPolicy<any> | FieldReadFunction<any>;
  buyIn?: FieldPolicy<any> | FieldReadFunction<any>;
  currencyId?: FieldPolicy<any> | FieldReadFunction<any>;
  currencyRate?: FieldPolicy<any> | FieldReadFunction<any>;
  guaranteed?: FieldPolicy<any> | FieldReadFunction<any>;
  localCurrencyId?: FieldPolicy<any> | FieldReadFunction<any>;
  sharesMax?: FieldPolicy<any> | FieldReadFunction<any>;
  sort?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TournamentsVar_sampFieldsKeySpecifier = (
  | 'bulletsMax'
  | 'buyIn'
  | 'currencyId'
  | 'currencyRate'
  | 'guaranteed'
  | 'localCurrencyId'
  | 'sharesMax'
  | 'sort'
  | TournamentsVar_sampFieldsKeySpecifier
)[];
export type TournamentsVar_sampFieldsFieldPolicy = {
  bulletsMax?: FieldPolicy<any> | FieldReadFunction<any>;
  buyIn?: FieldPolicy<any> | FieldReadFunction<any>;
  currencyId?: FieldPolicy<any> | FieldReadFunction<any>;
  currencyRate?: FieldPolicy<any> | FieldReadFunction<any>;
  guaranteed?: FieldPolicy<any> | FieldReadFunction<any>;
  localCurrencyId?: FieldPolicy<any> | FieldReadFunction<any>;
  sharesMax?: FieldPolicy<any> | FieldReadFunction<any>;
  sort?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TournamentsVarianceFieldsKeySpecifier = (
  | 'bulletsMax'
  | 'buyIn'
  | 'currencyId'
  | 'currencyRate'
  | 'guaranteed'
  | 'localCurrencyId'
  | 'sharesMax'
  | 'sort'
  | TournamentsVarianceFieldsKeySpecifier
)[];
export type TournamentsVarianceFieldsFieldPolicy = {
  bulletsMax?: FieldPolicy<any> | FieldReadFunction<any>;
  buyIn?: FieldPolicy<any> | FieldReadFunction<any>;
  currencyId?: FieldPolicy<any> | FieldReadFunction<any>;
  currencyRate?: FieldPolicy<any> | FieldReadFunction<any>;
  guaranteed?: FieldPolicy<any> | FieldReadFunction<any>;
  localCurrencyId?: FieldPolicy<any> | FieldReadFunction<any>;
  sharesMax?: FieldPolicy<any> | FieldReadFunction<any>;
  sort?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TransactionUserTypesKeySpecifier = (
  | 'description'
  | 'name'
  | TransactionUserTypesKeySpecifier
)[];
export type TransactionUserTypesFieldPolicy = {
  description?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TransactionUserTypesAggregateKeySpecifier = (
  | 'aggregate'
  | 'nodes'
  | TransactionUserTypesAggregateKeySpecifier
)[];
export type TransactionUserTypesAggregateFieldPolicy = {
  aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TransactionUserTypesAggregateFieldsKeySpecifier = (
  | 'count'
  | 'max'
  | 'min'
  | TransactionUserTypesAggregateFieldsKeySpecifier
)[];
export type TransactionUserTypesAggregateFieldsFieldPolicy = {
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  max?: FieldPolicy<any> | FieldReadFunction<any>;
  min?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TransactionUserTypesMaxFieldsKeySpecifier = (
  | 'description'
  | 'name'
  | TransactionUserTypesMaxFieldsKeySpecifier
)[];
export type TransactionUserTypesMaxFieldsFieldPolicy = {
  description?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TransactionUserTypesMinFieldsKeySpecifier = (
  | 'description'
  | 'name'
  | TransactionUserTypesMinFieldsKeySpecifier
)[];
export type TransactionUserTypesMinFieldsFieldPolicy = {
  description?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TransactionUserTypesMutationResponseKeySpecifier = (
  | 'affected_rows'
  | 'returning'
  | TransactionUserTypesMutationResponseKeySpecifier
)[];
export type TransactionUserTypesMutationResponseFieldPolicy = {
  affected_rows?: FieldPolicy<any> | FieldReadFunction<any>;
  returning?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TransactionsKeySpecifier = (
  | 'amount'
  | 'bullet'
  | 'createdAt'
  | 'createdBy'
  | 'currencyId'
  | 'description'
  | 'id'
  | 'log'
  | 'payment'
  | 'paymentId'
  | 'playerShareId'
  | 'player_share'
  | 'status'
  | 'ticketId'
  | 'tournamentId'
  | 'type'
  | 'updatedAt'
  | 'updatedBy'
  | 'user'
  | 'userId'
  | 'userShareBuyId'
  | 'user_share_buy'
  | TransactionsKeySpecifier
)[];
export type TransactionsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  bullet?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  createdBy?: FieldPolicy<any> | FieldReadFunction<any>;
  currencyId?: FieldPolicy<any> | FieldReadFunction<any>;
  description?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  log?: FieldPolicy<any> | FieldReadFunction<any>;
  payment?: FieldPolicy<any> | FieldReadFunction<any>;
  paymentId?: FieldPolicy<any> | FieldReadFunction<any>;
  playerShareId?: FieldPolicy<any> | FieldReadFunction<any>;
  player_share?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  ticketId?: FieldPolicy<any> | FieldReadFunction<any>;
  tournamentId?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedBy?: FieldPolicy<any> | FieldReadFunction<any>;
  user?: FieldPolicy<any> | FieldReadFunction<any>;
  userId?: FieldPolicy<any> | FieldReadFunction<any>;
  userShareBuyId?: FieldPolicy<any> | FieldReadFunction<any>;
  user_share_buy?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TransactionsAggregateKeySpecifier = (
  | 'aggregate'
  | 'nodes'
  | TransactionsAggregateKeySpecifier
)[];
export type TransactionsAggregateFieldPolicy = {
  aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TransactionsAggregateFieldsKeySpecifier = (
  | 'avg'
  | 'count'
  | 'max'
  | 'min'
  | 'stddev'
  | 'stddevPop'
  | 'stddevSamp'
  | 'sum'
  | 'varPop'
  | 'varSamp'
  | 'variance'
  | TransactionsAggregateFieldsKeySpecifier
)[];
export type TransactionsAggregateFieldsFieldPolicy = {
  avg?: FieldPolicy<any> | FieldReadFunction<any>;
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  max?: FieldPolicy<any> | FieldReadFunction<any>;
  min?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev?: FieldPolicy<any> | FieldReadFunction<any>;
  stddevPop?: FieldPolicy<any> | FieldReadFunction<any>;
  stddevSamp?: FieldPolicy<any> | FieldReadFunction<any>;
  sum?: FieldPolicy<any> | FieldReadFunction<any>;
  varPop?: FieldPolicy<any> | FieldReadFunction<any>;
  varSamp?: FieldPolicy<any> | FieldReadFunction<any>;
  variance?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TransactionsAvgFieldsKeySpecifier = (
  | 'amount'
  | 'bullet'
  | 'currencyId'
  | 'userShareBuyId'
  | TransactionsAvgFieldsKeySpecifier
)[];
export type TransactionsAvgFieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  bullet?: FieldPolicy<any> | FieldReadFunction<any>;
  currencyId?: FieldPolicy<any> | FieldReadFunction<any>;
  userShareBuyId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TransactionsMaxFieldsKeySpecifier = (
  | 'amount'
  | 'bullet'
  | 'createdAt'
  | 'currencyId'
  | 'description'
  | 'id'
  | 'log'
  | 'paymentId'
  | 'playerShareId'
  | 'status'
  | 'ticketId'
  | 'tournamentId'
  | 'type'
  | 'updatedAt'
  | 'userId'
  | 'userShareBuyId'
  | TransactionsMaxFieldsKeySpecifier
)[];
export type TransactionsMaxFieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  bullet?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  currencyId?: FieldPolicy<any> | FieldReadFunction<any>;
  description?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  log?: FieldPolicy<any> | FieldReadFunction<any>;
  paymentId?: FieldPolicy<any> | FieldReadFunction<any>;
  playerShareId?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  ticketId?: FieldPolicy<any> | FieldReadFunction<any>;
  tournamentId?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  userId?: FieldPolicy<any> | FieldReadFunction<any>;
  userShareBuyId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TransactionsMinFieldsKeySpecifier = (
  | 'amount'
  | 'bullet'
  | 'createdAt'
  | 'currencyId'
  | 'description'
  | 'id'
  | 'log'
  | 'paymentId'
  | 'playerShareId'
  | 'status'
  | 'ticketId'
  | 'tournamentId'
  | 'type'
  | 'updatedAt'
  | 'userId'
  | 'userShareBuyId'
  | TransactionsMinFieldsKeySpecifier
)[];
export type TransactionsMinFieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  bullet?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  currencyId?: FieldPolicy<any> | FieldReadFunction<any>;
  description?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  log?: FieldPolicy<any> | FieldReadFunction<any>;
  paymentId?: FieldPolicy<any> | FieldReadFunction<any>;
  playerShareId?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  ticketId?: FieldPolicy<any> | FieldReadFunction<any>;
  tournamentId?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  userId?: FieldPolicy<any> | FieldReadFunction<any>;
  userShareBuyId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TransactionsMutationResponseKeySpecifier = (
  | 'affected_rows'
  | 'returning'
  | TransactionsMutationResponseKeySpecifier
)[];
export type TransactionsMutationResponseFieldPolicy = {
  affected_rows?: FieldPolicy<any> | FieldReadFunction<any>;
  returning?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TransactionsStddevFieldsKeySpecifier = (
  | 'amount'
  | 'bullet'
  | 'currencyId'
  | 'userShareBuyId'
  | TransactionsStddevFieldsKeySpecifier
)[];
export type TransactionsStddevFieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  bullet?: FieldPolicy<any> | FieldReadFunction<any>;
  currencyId?: FieldPolicy<any> | FieldReadFunction<any>;
  userShareBuyId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TransactionsStddev_popFieldsKeySpecifier = (
  | 'amount'
  | 'bullet'
  | 'currencyId'
  | 'userShareBuyId'
  | TransactionsStddev_popFieldsKeySpecifier
)[];
export type TransactionsStddev_popFieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  bullet?: FieldPolicy<any> | FieldReadFunction<any>;
  currencyId?: FieldPolicy<any> | FieldReadFunction<any>;
  userShareBuyId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TransactionsStddev_sampFieldsKeySpecifier = (
  | 'amount'
  | 'bullet'
  | 'currencyId'
  | 'userShareBuyId'
  | TransactionsStddev_sampFieldsKeySpecifier
)[];
export type TransactionsStddev_sampFieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  bullet?: FieldPolicy<any> | FieldReadFunction<any>;
  currencyId?: FieldPolicy<any> | FieldReadFunction<any>;
  userShareBuyId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TransactionsSumFieldsKeySpecifier = (
  | 'amount'
  | 'bullet'
  | 'currencyId'
  | 'userShareBuyId'
  | TransactionsSumFieldsKeySpecifier
)[];
export type TransactionsSumFieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  bullet?: FieldPolicy<any> | FieldReadFunction<any>;
  currencyId?: FieldPolicy<any> | FieldReadFunction<any>;
  userShareBuyId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TransactionsVar_popFieldsKeySpecifier = (
  | 'amount'
  | 'bullet'
  | 'currencyId'
  | 'userShareBuyId'
  | TransactionsVar_popFieldsKeySpecifier
)[];
export type TransactionsVar_popFieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  bullet?: FieldPolicy<any> | FieldReadFunction<any>;
  currencyId?: FieldPolicy<any> | FieldReadFunction<any>;
  userShareBuyId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TransactionsVar_sampFieldsKeySpecifier = (
  | 'amount'
  | 'bullet'
  | 'currencyId'
  | 'userShareBuyId'
  | TransactionsVar_sampFieldsKeySpecifier
)[];
export type TransactionsVar_sampFieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  bullet?: FieldPolicy<any> | FieldReadFunction<any>;
  currencyId?: FieldPolicy<any> | FieldReadFunction<any>;
  userShareBuyId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TransactionsVarianceFieldsKeySpecifier = (
  | 'amount'
  | 'bullet'
  | 'currencyId'
  | 'userShareBuyId'
  | TransactionsVarianceFieldsKeySpecifier
)[];
export type TransactionsVarianceFieldsFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  bullet?: FieldPolicy<any> | FieldReadFunction<any>;
  currencyId?: FieldPolicy<any> | FieldReadFunction<any>;
  userShareBuyId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type UserBalancesKeySpecifier = (
  | 'balance'
  | 'currencyId'
  | 'id'
  | 'status'
  | 'userId'
  | UserBalancesKeySpecifier
)[];
export type UserBalancesFieldPolicy = {
  balance?: FieldPolicy<any> | FieldReadFunction<any>;
  currencyId?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  userId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type UserBalancesAggregateKeySpecifier = (
  | 'aggregate'
  | 'nodes'
  | UserBalancesAggregateKeySpecifier
)[];
export type UserBalancesAggregateFieldPolicy = {
  aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type UserBalancesAggregateFieldsKeySpecifier = (
  | 'avg'
  | 'count'
  | 'max'
  | 'min'
  | 'stddev'
  | 'stddevPop'
  | 'stddevSamp'
  | 'sum'
  | 'varPop'
  | 'varSamp'
  | 'variance'
  | UserBalancesAggregateFieldsKeySpecifier
)[];
export type UserBalancesAggregateFieldsFieldPolicy = {
  avg?: FieldPolicy<any> | FieldReadFunction<any>;
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  max?: FieldPolicy<any> | FieldReadFunction<any>;
  min?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev?: FieldPolicy<any> | FieldReadFunction<any>;
  stddevPop?: FieldPolicy<any> | FieldReadFunction<any>;
  stddevSamp?: FieldPolicy<any> | FieldReadFunction<any>;
  sum?: FieldPolicy<any> | FieldReadFunction<any>;
  varPop?: FieldPolicy<any> | FieldReadFunction<any>;
  varSamp?: FieldPolicy<any> | FieldReadFunction<any>;
  variance?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type UserBalancesAvgFieldsKeySpecifier = (
  | 'balance'
  | 'currencyId'
  | UserBalancesAvgFieldsKeySpecifier
)[];
export type UserBalancesAvgFieldsFieldPolicy = {
  balance?: FieldPolicy<any> | FieldReadFunction<any>;
  currencyId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type UserBalancesMaxFieldsKeySpecifier = (
  | 'balance'
  | 'currencyId'
  | 'id'
  | 'status'
  | 'userId'
  | UserBalancesMaxFieldsKeySpecifier
)[];
export type UserBalancesMaxFieldsFieldPolicy = {
  balance?: FieldPolicy<any> | FieldReadFunction<any>;
  currencyId?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  userId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type UserBalancesMinFieldsKeySpecifier = (
  | 'balance'
  | 'currencyId'
  | 'id'
  | 'status'
  | 'userId'
  | UserBalancesMinFieldsKeySpecifier
)[];
export type UserBalancesMinFieldsFieldPolicy = {
  balance?: FieldPolicy<any> | FieldReadFunction<any>;
  currencyId?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  userId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type UserBalancesStddevFieldsKeySpecifier = (
  | 'balance'
  | 'currencyId'
  | UserBalancesStddevFieldsKeySpecifier
)[];
export type UserBalancesStddevFieldsFieldPolicy = {
  balance?: FieldPolicy<any> | FieldReadFunction<any>;
  currencyId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type UserBalancesStddev_popFieldsKeySpecifier = (
  | 'balance'
  | 'currencyId'
  | UserBalancesStddev_popFieldsKeySpecifier
)[];
export type UserBalancesStddev_popFieldsFieldPolicy = {
  balance?: FieldPolicy<any> | FieldReadFunction<any>;
  currencyId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type UserBalancesStddev_sampFieldsKeySpecifier = (
  | 'balance'
  | 'currencyId'
  | UserBalancesStddev_sampFieldsKeySpecifier
)[];
export type UserBalancesStddev_sampFieldsFieldPolicy = {
  balance?: FieldPolicy<any> | FieldReadFunction<any>;
  currencyId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type UserBalancesSumFieldsKeySpecifier = (
  | 'balance'
  | 'currencyId'
  | UserBalancesSumFieldsKeySpecifier
)[];
export type UserBalancesSumFieldsFieldPolicy = {
  balance?: FieldPolicy<any> | FieldReadFunction<any>;
  currencyId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type UserBalancesVar_popFieldsKeySpecifier = (
  | 'balance'
  | 'currencyId'
  | UserBalancesVar_popFieldsKeySpecifier
)[];
export type UserBalancesVar_popFieldsFieldPolicy = {
  balance?: FieldPolicy<any> | FieldReadFunction<any>;
  currencyId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type UserBalancesVar_sampFieldsKeySpecifier = (
  | 'balance'
  | 'currencyId'
  | UserBalancesVar_sampFieldsKeySpecifier
)[];
export type UserBalancesVar_sampFieldsFieldPolicy = {
  balance?: FieldPolicy<any> | FieldReadFunction<any>;
  currencyId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type UserBalancesVarianceFieldsKeySpecifier = (
  | 'balance'
  | 'currencyId'
  | UserBalancesVarianceFieldsKeySpecifier
)[];
export type UserBalancesVarianceFieldsFieldPolicy = {
  balance?: FieldPolicy<any> | FieldReadFunction<any>;
  currencyId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type UserOutputKeySpecifier = (
  | 'avatar'
  | 'country'
  | 'email'
  | 'erc20Address'
  | 'id'
  | 'language'
  | 'name'
  | 'organiserId'
  | 'playerId'
  | 'playerName'
  | 'playerSlug'
  | 'playerStatus'
  | 'provider'
  | 'role'
  | 'status'
  | 'twofa'
  | 'username'
  | 'venueId'
  | UserOutputKeySpecifier
)[];
export type UserOutputFieldPolicy = {
  avatar?: FieldPolicy<any> | FieldReadFunction<any>;
  country?: FieldPolicy<any> | FieldReadFunction<any>;
  email?: FieldPolicy<any> | FieldReadFunction<any>;
  erc20Address?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  language?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  organiserId?: FieldPolicy<any> | FieldReadFunction<any>;
  playerId?: FieldPolicy<any> | FieldReadFunction<any>;
  playerName?: FieldPolicy<any> | FieldReadFunction<any>;
  playerSlug?: FieldPolicy<any> | FieldReadFunction<any>;
  playerStatus?: FieldPolicy<any> | FieldReadFunction<any>;
  provider?: FieldPolicy<any> | FieldReadFunction<any>;
  role?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  twofa?: FieldPolicy<any> | FieldReadFunction<any>;
  username?: FieldPolicy<any> | FieldReadFunction<any>;
  venueId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type UserShareBuysKeySpecifier = (
  | 'bulletsPlayed'
  | 'createdAt'
  | 'id'
  | 'markupAmount'
  | 'playerShareId'
  | 'player_share'
  | 'shareAmount'
  | 'sharePercentage'
  | 'status'
  | 'totalAmount'
  | 'transactions'
  | 'transactionsAggregate'
  | 'updatedAt'
  | 'user'
  | 'userId'
  | 'won'
  | UserShareBuysKeySpecifier
)[];
export type UserShareBuysFieldPolicy = {
  bulletsPlayed?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  markupAmount?: FieldPolicy<any> | FieldReadFunction<any>;
  playerShareId?: FieldPolicy<any> | FieldReadFunction<any>;
  player_share?: FieldPolicy<any> | FieldReadFunction<any>;
  shareAmount?: FieldPolicy<any> | FieldReadFunction<any>;
  sharePercentage?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  totalAmount?: FieldPolicy<any> | FieldReadFunction<any>;
  transactions?: FieldPolicy<any> | FieldReadFunction<any>;
  transactionsAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  user?: FieldPolicy<any> | FieldReadFunction<any>;
  userId?: FieldPolicy<any> | FieldReadFunction<any>;
  won?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type UserShareBuysAggregateKeySpecifier = (
  | 'aggregate'
  | 'nodes'
  | UserShareBuysAggregateKeySpecifier
)[];
export type UserShareBuysAggregateFieldPolicy = {
  aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type UserShareBuysAggregateFieldsKeySpecifier = (
  | 'avg'
  | 'count'
  | 'max'
  | 'min'
  | 'stddev'
  | 'stddevPop'
  | 'stddevSamp'
  | 'sum'
  | 'varPop'
  | 'varSamp'
  | 'variance'
  | UserShareBuysAggregateFieldsKeySpecifier
)[];
export type UserShareBuysAggregateFieldsFieldPolicy = {
  avg?: FieldPolicy<any> | FieldReadFunction<any>;
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  max?: FieldPolicy<any> | FieldReadFunction<any>;
  min?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev?: FieldPolicy<any> | FieldReadFunction<any>;
  stddevPop?: FieldPolicy<any> | FieldReadFunction<any>;
  stddevSamp?: FieldPolicy<any> | FieldReadFunction<any>;
  sum?: FieldPolicy<any> | FieldReadFunction<any>;
  varPop?: FieldPolicy<any> | FieldReadFunction<any>;
  varSamp?: FieldPolicy<any> | FieldReadFunction<any>;
  variance?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type UserShareBuysAvgFieldsKeySpecifier = (
  | 'bulletsPlayed'
  | 'id'
  | 'markupAmount'
  | 'shareAmount'
  | 'sharePercentage'
  | 'totalAmount'
  | 'won'
  | UserShareBuysAvgFieldsKeySpecifier
)[];
export type UserShareBuysAvgFieldsFieldPolicy = {
  bulletsPlayed?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  markupAmount?: FieldPolicy<any> | FieldReadFunction<any>;
  shareAmount?: FieldPolicy<any> | FieldReadFunction<any>;
  sharePercentage?: FieldPolicy<any> | FieldReadFunction<any>;
  totalAmount?: FieldPolicy<any> | FieldReadFunction<any>;
  won?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type UserShareBuysMaxFieldsKeySpecifier = (
  | 'bulletsPlayed'
  | 'createdAt'
  | 'id'
  | 'markupAmount'
  | 'playerShareId'
  | 'shareAmount'
  | 'sharePercentage'
  | 'status'
  | 'totalAmount'
  | 'updatedAt'
  | 'userId'
  | 'won'
  | UserShareBuysMaxFieldsKeySpecifier
)[];
export type UserShareBuysMaxFieldsFieldPolicy = {
  bulletsPlayed?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  markupAmount?: FieldPolicy<any> | FieldReadFunction<any>;
  playerShareId?: FieldPolicy<any> | FieldReadFunction<any>;
  shareAmount?: FieldPolicy<any> | FieldReadFunction<any>;
  sharePercentage?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  totalAmount?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  userId?: FieldPolicy<any> | FieldReadFunction<any>;
  won?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type UserShareBuysMinFieldsKeySpecifier = (
  | 'bulletsPlayed'
  | 'createdAt'
  | 'id'
  | 'markupAmount'
  | 'playerShareId'
  | 'shareAmount'
  | 'sharePercentage'
  | 'status'
  | 'totalAmount'
  | 'updatedAt'
  | 'userId'
  | 'won'
  | UserShareBuysMinFieldsKeySpecifier
)[];
export type UserShareBuysMinFieldsFieldPolicy = {
  bulletsPlayed?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  markupAmount?: FieldPolicy<any> | FieldReadFunction<any>;
  playerShareId?: FieldPolicy<any> | FieldReadFunction<any>;
  shareAmount?: FieldPolicy<any> | FieldReadFunction<any>;
  sharePercentage?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  totalAmount?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  userId?: FieldPolicy<any> | FieldReadFunction<any>;
  won?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type UserShareBuysMutationResponseKeySpecifier = (
  | 'affected_rows'
  | 'returning'
  | UserShareBuysMutationResponseKeySpecifier
)[];
export type UserShareBuysMutationResponseFieldPolicy = {
  affected_rows?: FieldPolicy<any> | FieldReadFunction<any>;
  returning?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type UserShareBuysStddevFieldsKeySpecifier = (
  | 'bulletsPlayed'
  | 'id'
  | 'markupAmount'
  | 'shareAmount'
  | 'sharePercentage'
  | 'totalAmount'
  | 'won'
  | UserShareBuysStddevFieldsKeySpecifier
)[];
export type UserShareBuysStddevFieldsFieldPolicy = {
  bulletsPlayed?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  markupAmount?: FieldPolicy<any> | FieldReadFunction<any>;
  shareAmount?: FieldPolicy<any> | FieldReadFunction<any>;
  sharePercentage?: FieldPolicy<any> | FieldReadFunction<any>;
  totalAmount?: FieldPolicy<any> | FieldReadFunction<any>;
  won?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type UserShareBuysStddev_popFieldsKeySpecifier = (
  | 'bulletsPlayed'
  | 'id'
  | 'markupAmount'
  | 'shareAmount'
  | 'sharePercentage'
  | 'totalAmount'
  | 'won'
  | UserShareBuysStddev_popFieldsKeySpecifier
)[];
export type UserShareBuysStddev_popFieldsFieldPolicy = {
  bulletsPlayed?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  markupAmount?: FieldPolicy<any> | FieldReadFunction<any>;
  shareAmount?: FieldPolicy<any> | FieldReadFunction<any>;
  sharePercentage?: FieldPolicy<any> | FieldReadFunction<any>;
  totalAmount?: FieldPolicy<any> | FieldReadFunction<any>;
  won?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type UserShareBuysStddev_sampFieldsKeySpecifier = (
  | 'bulletsPlayed'
  | 'id'
  | 'markupAmount'
  | 'shareAmount'
  | 'sharePercentage'
  | 'totalAmount'
  | 'won'
  | UserShareBuysStddev_sampFieldsKeySpecifier
)[];
export type UserShareBuysStddev_sampFieldsFieldPolicy = {
  bulletsPlayed?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  markupAmount?: FieldPolicy<any> | FieldReadFunction<any>;
  shareAmount?: FieldPolicy<any> | FieldReadFunction<any>;
  sharePercentage?: FieldPolicy<any> | FieldReadFunction<any>;
  totalAmount?: FieldPolicy<any> | FieldReadFunction<any>;
  won?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type UserShareBuysSumFieldsKeySpecifier = (
  | 'bulletsPlayed'
  | 'id'
  | 'markupAmount'
  | 'shareAmount'
  | 'sharePercentage'
  | 'totalAmount'
  | 'won'
  | UserShareBuysSumFieldsKeySpecifier
)[];
export type UserShareBuysSumFieldsFieldPolicy = {
  bulletsPlayed?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  markupAmount?: FieldPolicy<any> | FieldReadFunction<any>;
  shareAmount?: FieldPolicy<any> | FieldReadFunction<any>;
  sharePercentage?: FieldPolicy<any> | FieldReadFunction<any>;
  totalAmount?: FieldPolicy<any> | FieldReadFunction<any>;
  won?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type UserShareBuysVar_popFieldsKeySpecifier = (
  | 'bulletsPlayed'
  | 'id'
  | 'markupAmount'
  | 'shareAmount'
  | 'sharePercentage'
  | 'totalAmount'
  | 'won'
  | UserShareBuysVar_popFieldsKeySpecifier
)[];
export type UserShareBuysVar_popFieldsFieldPolicy = {
  bulletsPlayed?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  markupAmount?: FieldPolicy<any> | FieldReadFunction<any>;
  shareAmount?: FieldPolicy<any> | FieldReadFunction<any>;
  sharePercentage?: FieldPolicy<any> | FieldReadFunction<any>;
  totalAmount?: FieldPolicy<any> | FieldReadFunction<any>;
  won?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type UserShareBuysVar_sampFieldsKeySpecifier = (
  | 'bulletsPlayed'
  | 'id'
  | 'markupAmount'
  | 'shareAmount'
  | 'sharePercentage'
  | 'totalAmount'
  | 'won'
  | UserShareBuysVar_sampFieldsKeySpecifier
)[];
export type UserShareBuysVar_sampFieldsFieldPolicy = {
  bulletsPlayed?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  markupAmount?: FieldPolicy<any> | FieldReadFunction<any>;
  shareAmount?: FieldPolicy<any> | FieldReadFunction<any>;
  sharePercentage?: FieldPolicy<any> | FieldReadFunction<any>;
  totalAmount?: FieldPolicy<any> | FieldReadFunction<any>;
  won?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type UserShareBuysVarianceFieldsKeySpecifier = (
  | 'bulletsPlayed'
  | 'id'
  | 'markupAmount'
  | 'shareAmount'
  | 'sharePercentage'
  | 'totalAmount'
  | 'won'
  | UserShareBuysVarianceFieldsKeySpecifier
)[];
export type UserShareBuysVarianceFieldsFieldPolicy = {
  bulletsPlayed?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  markupAmount?: FieldPolicy<any> | FieldReadFunction<any>;
  shareAmount?: FieldPolicy<any> | FieldReadFunction<any>;
  sharePercentage?: FieldPolicy<any> | FieldReadFunction<any>;
  totalAmount?: FieldPolicy<any> | FieldReadFunction<any>;
  won?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type UsersKeySpecifier = (
  | 'avatar'
  | 'country'
  | 'createdAt'
  | 'deletedAt'
  | 'email'
  | 'erc20Address'
  | 'id'
  | 'language'
  | 'lastseenAt'
  | 'metadata'
  | 'name'
  | 'players'
  | 'playersAggregate'
  | 'provider'
  | 'signature'
  | 'status'
  | 'telegramId'
  | 'transactions'
  | 'transactionsAggregate'
  | 'twofa'
  | 'twofaBackup'
  | 'twofaSecret'
  | 'updatedAt'
  | 'userBalancesAggregate'
  | 'userShareBuysAggregate'
  | 'user_balances'
  | 'user_share_buys'
  | 'username'
  | 'venueUsersAggregate'
  | 'venue_users'
  | 'verificationMail'
  | UsersKeySpecifier
)[];
export type UsersFieldPolicy = {
  avatar?: FieldPolicy<any> | FieldReadFunction<any>;
  country?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  deletedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  email?: FieldPolicy<any> | FieldReadFunction<any>;
  erc20Address?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  language?: FieldPolicy<any> | FieldReadFunction<any>;
  lastseenAt?: FieldPolicy<any> | FieldReadFunction<any>;
  metadata?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  players?: FieldPolicy<any> | FieldReadFunction<any>;
  playersAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  provider?: FieldPolicy<any> | FieldReadFunction<any>;
  signature?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  telegramId?: FieldPolicy<any> | FieldReadFunction<any>;
  transactions?: FieldPolicy<any> | FieldReadFunction<any>;
  transactionsAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  twofa?: FieldPolicy<any> | FieldReadFunction<any>;
  twofaBackup?: FieldPolicy<any> | FieldReadFunction<any>;
  twofaSecret?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  userBalancesAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  userShareBuysAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  user_balances?: FieldPolicy<any> | FieldReadFunction<any>;
  user_share_buys?: FieldPolicy<any> | FieldReadFunction<any>;
  username?: FieldPolicy<any> | FieldReadFunction<any>;
  venueUsersAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  venue_users?: FieldPolicy<any> | FieldReadFunction<any>;
  verificationMail?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type UsersAggregateKeySpecifier = (
  | 'aggregate'
  | 'nodes'
  | UsersAggregateKeySpecifier
)[];
export type UsersAggregateFieldPolicy = {
  aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type UsersAggregateFieldsKeySpecifier = (
  | 'avg'
  | 'count'
  | 'max'
  | 'min'
  | 'stddev'
  | 'stddevPop'
  | 'stddevSamp'
  | 'sum'
  | 'varPop'
  | 'varSamp'
  | 'variance'
  | UsersAggregateFieldsKeySpecifier
)[];
export type UsersAggregateFieldsFieldPolicy = {
  avg?: FieldPolicy<any> | FieldReadFunction<any>;
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  max?: FieldPolicy<any> | FieldReadFunction<any>;
  min?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev?: FieldPolicy<any> | FieldReadFunction<any>;
  stddevPop?: FieldPolicy<any> | FieldReadFunction<any>;
  stddevSamp?: FieldPolicy<any> | FieldReadFunction<any>;
  sum?: FieldPolicy<any> | FieldReadFunction<any>;
  varPop?: FieldPolicy<any> | FieldReadFunction<any>;
  varSamp?: FieldPolicy<any> | FieldReadFunction<any>;
  variance?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type UsersAvgFieldsKeySpecifier = (
  | 'telegramId'
  | UsersAvgFieldsKeySpecifier
)[];
export type UsersAvgFieldsFieldPolicy = {
  telegramId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type UsersMaxFieldsKeySpecifier = (
  | 'avatar'
  | 'country'
  | 'createdAt'
  | 'deletedAt'
  | 'email'
  | 'erc20Address'
  | 'id'
  | 'language'
  | 'lastseenAt'
  | 'metadata'
  | 'name'
  | 'provider'
  | 'signature'
  | 'telegramId'
  | 'twofaBackup'
  | 'twofaSecret'
  | 'updatedAt'
  | 'username'
  | 'verificationMail'
  | UsersMaxFieldsKeySpecifier
)[];
export type UsersMaxFieldsFieldPolicy = {
  avatar?: FieldPolicy<any> | FieldReadFunction<any>;
  country?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  deletedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  email?: FieldPolicy<any> | FieldReadFunction<any>;
  erc20Address?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  language?: FieldPolicy<any> | FieldReadFunction<any>;
  lastseenAt?: FieldPolicy<any> | FieldReadFunction<any>;
  metadata?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  provider?: FieldPolicy<any> | FieldReadFunction<any>;
  signature?: FieldPolicy<any> | FieldReadFunction<any>;
  telegramId?: FieldPolicy<any> | FieldReadFunction<any>;
  twofaBackup?: FieldPolicy<any> | FieldReadFunction<any>;
  twofaSecret?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  username?: FieldPolicy<any> | FieldReadFunction<any>;
  verificationMail?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type UsersMinFieldsKeySpecifier = (
  | 'avatar'
  | 'country'
  | 'createdAt'
  | 'deletedAt'
  | 'email'
  | 'erc20Address'
  | 'id'
  | 'language'
  | 'lastseenAt'
  | 'metadata'
  | 'name'
  | 'provider'
  | 'signature'
  | 'telegramId'
  | 'twofaBackup'
  | 'twofaSecret'
  | 'updatedAt'
  | 'username'
  | 'verificationMail'
  | UsersMinFieldsKeySpecifier
)[];
export type UsersMinFieldsFieldPolicy = {
  avatar?: FieldPolicy<any> | FieldReadFunction<any>;
  country?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  deletedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  email?: FieldPolicy<any> | FieldReadFunction<any>;
  erc20Address?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  language?: FieldPolicy<any> | FieldReadFunction<any>;
  lastseenAt?: FieldPolicy<any> | FieldReadFunction<any>;
  metadata?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  provider?: FieldPolicy<any> | FieldReadFunction<any>;
  signature?: FieldPolicy<any> | FieldReadFunction<any>;
  telegramId?: FieldPolicy<any> | FieldReadFunction<any>;
  twofaBackup?: FieldPolicy<any> | FieldReadFunction<any>;
  twofaSecret?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  username?: FieldPolicy<any> | FieldReadFunction<any>;
  verificationMail?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type UsersMutationResponseKeySpecifier = (
  | 'affected_rows'
  | 'returning'
  | UsersMutationResponseKeySpecifier
)[];
export type UsersMutationResponseFieldPolicy = {
  affected_rows?: FieldPolicy<any> | FieldReadFunction<any>;
  returning?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type UsersStddevFieldsKeySpecifier = (
  | 'telegramId'
  | UsersStddevFieldsKeySpecifier
)[];
export type UsersStddevFieldsFieldPolicy = {
  telegramId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type UsersStddev_popFieldsKeySpecifier = (
  | 'telegramId'
  | UsersStddev_popFieldsKeySpecifier
)[];
export type UsersStddev_popFieldsFieldPolicy = {
  telegramId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type UsersStddev_sampFieldsKeySpecifier = (
  | 'telegramId'
  | UsersStddev_sampFieldsKeySpecifier
)[];
export type UsersStddev_sampFieldsFieldPolicy = {
  telegramId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type UsersSumFieldsKeySpecifier = (
  | 'telegramId'
  | UsersSumFieldsKeySpecifier
)[];
export type UsersSumFieldsFieldPolicy = {
  telegramId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type UsersVar_popFieldsKeySpecifier = (
  | 'telegramId'
  | UsersVar_popFieldsKeySpecifier
)[];
export type UsersVar_popFieldsFieldPolicy = {
  telegramId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type UsersVar_sampFieldsKeySpecifier = (
  | 'telegramId'
  | UsersVar_sampFieldsKeySpecifier
)[];
export type UsersVar_sampFieldsFieldPolicy = {
  telegramId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type UsersVarianceFieldsKeySpecifier = (
  | 'telegramId'
  | UsersVarianceFieldsKeySpecifier
)[];
export type UsersVarianceFieldsFieldPolicy = {
  telegramId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type VenueUsersKeySpecifier = (
  | 'createdAt'
  | 'id'
  | 'updatedAt'
  | 'user'
  | 'userId'
  | 'venue'
  | 'venueId'
  | VenueUsersKeySpecifier
)[];
export type VenueUsersFieldPolicy = {
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  user?: FieldPolicy<any> | FieldReadFunction<any>;
  userId?: FieldPolicy<any> | FieldReadFunction<any>;
  venue?: FieldPolicy<any> | FieldReadFunction<any>;
  venueId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type VenueUsersAggregateKeySpecifier = (
  | 'aggregate'
  | 'nodes'
  | VenueUsersAggregateKeySpecifier
)[];
export type VenueUsersAggregateFieldPolicy = {
  aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type VenueUsersAggregateFieldsKeySpecifier = (
  | 'avg'
  | 'count'
  | 'max'
  | 'min'
  | 'stddev'
  | 'stddevPop'
  | 'stddevSamp'
  | 'sum'
  | 'varPop'
  | 'varSamp'
  | 'variance'
  | VenueUsersAggregateFieldsKeySpecifier
)[];
export type VenueUsersAggregateFieldsFieldPolicy = {
  avg?: FieldPolicy<any> | FieldReadFunction<any>;
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  max?: FieldPolicy<any> | FieldReadFunction<any>;
  min?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev?: FieldPolicy<any> | FieldReadFunction<any>;
  stddevPop?: FieldPolicy<any> | FieldReadFunction<any>;
  stddevSamp?: FieldPolicy<any> | FieldReadFunction<any>;
  sum?: FieldPolicy<any> | FieldReadFunction<any>;
  varPop?: FieldPolicy<any> | FieldReadFunction<any>;
  varSamp?: FieldPolicy<any> | FieldReadFunction<any>;
  variance?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type VenueUsersAvgFieldsKeySpecifier = (
  | 'id'
  | VenueUsersAvgFieldsKeySpecifier
)[];
export type VenueUsersAvgFieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type VenueUsersMaxFieldsKeySpecifier = (
  | 'createdAt'
  | 'id'
  | 'updatedAt'
  | 'userId'
  | 'venueId'
  | VenueUsersMaxFieldsKeySpecifier
)[];
export type VenueUsersMaxFieldsFieldPolicy = {
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  userId?: FieldPolicy<any> | FieldReadFunction<any>;
  venueId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type VenueUsersMinFieldsKeySpecifier = (
  | 'createdAt'
  | 'id'
  | 'updatedAt'
  | 'userId'
  | 'venueId'
  | VenueUsersMinFieldsKeySpecifier
)[];
export type VenueUsersMinFieldsFieldPolicy = {
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  userId?: FieldPolicy<any> | FieldReadFunction<any>;
  venueId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type VenueUsersMutationResponseKeySpecifier = (
  | 'affected_rows'
  | 'returning'
  | VenueUsersMutationResponseKeySpecifier
)[];
export type VenueUsersMutationResponseFieldPolicy = {
  affected_rows?: FieldPolicy<any> | FieldReadFunction<any>;
  returning?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type VenueUsersStddevFieldsKeySpecifier = (
  | 'id'
  | VenueUsersStddevFieldsKeySpecifier
)[];
export type VenueUsersStddevFieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type VenueUsersStddev_popFieldsKeySpecifier = (
  | 'id'
  | VenueUsersStddev_popFieldsKeySpecifier
)[];
export type VenueUsersStddev_popFieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type VenueUsersStddev_sampFieldsKeySpecifier = (
  | 'id'
  | VenueUsersStddev_sampFieldsKeySpecifier
)[];
export type VenueUsersStddev_sampFieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type VenueUsersSumFieldsKeySpecifier = (
  | 'id'
  | VenueUsersSumFieldsKeySpecifier
)[];
export type VenueUsersSumFieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type VenueUsersVar_popFieldsKeySpecifier = (
  | 'id'
  | VenueUsersVar_popFieldsKeySpecifier
)[];
export type VenueUsersVar_popFieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type VenueUsersVar_sampFieldsKeySpecifier = (
  | 'id'
  | VenueUsersVar_sampFieldsKeySpecifier
)[];
export type VenueUsersVar_sampFieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type VenueUsersVarianceFieldsKeySpecifier = (
  | 'id'
  | VenueUsersVarianceFieldsKeySpecifier
)[];
export type VenueUsersVarianceFieldsFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type VenuesKeySpecifier = (
  | 'address'
  | 'city'
  | 'country'
  | 'createdAt'
  | 'description'
  | 'events'
  | 'eventsAggregate'
  | 'facebook'
  | 'followers'
  | 'followersAggregate'
  | 'followersCount'
  | 'googleMapsUrl'
  | 'id'
  | 'image'
  | 'instagram'
  | 'name'
  | 'slug'
  | 'statsGoogleDrive'
  | 'status'
  | 'tournaments'
  | 'tournamentsAggregate'
  | 'updatedAt'
  | 'venueUsersAggregate'
  | 'venue_users'
  | 'website'
  | VenuesKeySpecifier
)[];
export type VenuesFieldPolicy = {
  address?: FieldPolicy<any> | FieldReadFunction<any>;
  city?: FieldPolicy<any> | FieldReadFunction<any>;
  country?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  description?: FieldPolicy<any> | FieldReadFunction<any>;
  events?: FieldPolicy<any> | FieldReadFunction<any>;
  eventsAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  facebook?: FieldPolicy<any> | FieldReadFunction<any>;
  followers?: FieldPolicy<any> | FieldReadFunction<any>;
  followersAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  followersCount?: FieldPolicy<any> | FieldReadFunction<any>;
  googleMapsUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  image?: FieldPolicy<any> | FieldReadFunction<any>;
  instagram?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  slug?: FieldPolicy<any> | FieldReadFunction<any>;
  statsGoogleDrive?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  tournaments?: FieldPolicy<any> | FieldReadFunction<any>;
  tournamentsAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  venueUsersAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  venue_users?: FieldPolicy<any> | FieldReadFunction<any>;
  website?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type VenuesAggregateKeySpecifier = (
  | 'aggregate'
  | 'nodes'
  | VenuesAggregateKeySpecifier
)[];
export type VenuesAggregateFieldPolicy = {
  aggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  nodes?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type VenuesAggregateFieldsKeySpecifier = (
  | 'avg'
  | 'count'
  | 'max'
  | 'min'
  | 'stddev'
  | 'stddevPop'
  | 'stddevSamp'
  | 'sum'
  | 'varPop'
  | 'varSamp'
  | 'variance'
  | VenuesAggregateFieldsKeySpecifier
)[];
export type VenuesAggregateFieldsFieldPolicy = {
  avg?: FieldPolicy<any> | FieldReadFunction<any>;
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  max?: FieldPolicy<any> | FieldReadFunction<any>;
  min?: FieldPolicy<any> | FieldReadFunction<any>;
  stddev?: FieldPolicy<any> | FieldReadFunction<any>;
  stddevPop?: FieldPolicy<any> | FieldReadFunction<any>;
  stddevSamp?: FieldPolicy<any> | FieldReadFunction<any>;
  sum?: FieldPolicy<any> | FieldReadFunction<any>;
  varPop?: FieldPolicy<any> | FieldReadFunction<any>;
  varSamp?: FieldPolicy<any> | FieldReadFunction<any>;
  variance?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type VenuesAvgFieldsKeySpecifier = (
  | 'followersCount'
  | VenuesAvgFieldsKeySpecifier
)[];
export type VenuesAvgFieldsFieldPolicy = {
  followersCount?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type VenuesMaxFieldsKeySpecifier = (
  | 'address'
  | 'city'
  | 'country'
  | 'createdAt'
  | 'description'
  | 'facebook'
  | 'followersCount'
  | 'googleMapsUrl'
  | 'id'
  | 'image'
  | 'instagram'
  | 'name'
  | 'slug'
  | 'statsGoogleDrive'
  | 'updatedAt'
  | 'website'
  | VenuesMaxFieldsKeySpecifier
)[];
export type VenuesMaxFieldsFieldPolicy = {
  address?: FieldPolicy<any> | FieldReadFunction<any>;
  city?: FieldPolicy<any> | FieldReadFunction<any>;
  country?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  description?: FieldPolicy<any> | FieldReadFunction<any>;
  facebook?: FieldPolicy<any> | FieldReadFunction<any>;
  followersCount?: FieldPolicy<any> | FieldReadFunction<any>;
  googleMapsUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  image?: FieldPolicy<any> | FieldReadFunction<any>;
  instagram?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  slug?: FieldPolicy<any> | FieldReadFunction<any>;
  statsGoogleDrive?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  website?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type VenuesMinFieldsKeySpecifier = (
  | 'address'
  | 'city'
  | 'country'
  | 'createdAt'
  | 'description'
  | 'facebook'
  | 'followersCount'
  | 'googleMapsUrl'
  | 'id'
  | 'image'
  | 'instagram'
  | 'name'
  | 'slug'
  | 'statsGoogleDrive'
  | 'updatedAt'
  | 'website'
  | VenuesMinFieldsKeySpecifier
)[];
export type VenuesMinFieldsFieldPolicy = {
  address?: FieldPolicy<any> | FieldReadFunction<any>;
  city?: FieldPolicy<any> | FieldReadFunction<any>;
  country?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  description?: FieldPolicy<any> | FieldReadFunction<any>;
  facebook?: FieldPolicy<any> | FieldReadFunction<any>;
  followersCount?: FieldPolicy<any> | FieldReadFunction<any>;
  googleMapsUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  image?: FieldPolicy<any> | FieldReadFunction<any>;
  instagram?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  slug?: FieldPolicy<any> | FieldReadFunction<any>;
  statsGoogleDrive?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  website?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type VenuesMutationResponseKeySpecifier = (
  | 'affected_rows'
  | 'returning'
  | VenuesMutationResponseKeySpecifier
)[];
export type VenuesMutationResponseFieldPolicy = {
  affected_rows?: FieldPolicy<any> | FieldReadFunction<any>;
  returning?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type VenuesStddevFieldsKeySpecifier = (
  | 'followersCount'
  | VenuesStddevFieldsKeySpecifier
)[];
export type VenuesStddevFieldsFieldPolicy = {
  followersCount?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type VenuesStddev_popFieldsKeySpecifier = (
  | 'followersCount'
  | VenuesStddev_popFieldsKeySpecifier
)[];
export type VenuesStddev_popFieldsFieldPolicy = {
  followersCount?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type VenuesStddev_sampFieldsKeySpecifier = (
  | 'followersCount'
  | VenuesStddev_sampFieldsKeySpecifier
)[];
export type VenuesStddev_sampFieldsFieldPolicy = {
  followersCount?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type VenuesSumFieldsKeySpecifier = (
  | 'followersCount'
  | VenuesSumFieldsKeySpecifier
)[];
export type VenuesSumFieldsFieldPolicy = {
  followersCount?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type VenuesVar_popFieldsKeySpecifier = (
  | 'followersCount'
  | VenuesVar_popFieldsKeySpecifier
)[];
export type VenuesVar_popFieldsFieldPolicy = {
  followersCount?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type VenuesVar_sampFieldsKeySpecifier = (
  | 'followersCount'
  | VenuesVar_sampFieldsKeySpecifier
)[];
export type VenuesVar_sampFieldsFieldPolicy = {
  followersCount?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type VenuesVarianceFieldsKeySpecifier = (
  | 'followersCount'
  | VenuesVarianceFieldsKeySpecifier
)[];
export type VenuesVarianceFieldsFieldPolicy = {
  followersCount?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type activate2FAOutputKeySpecifier = (
  | 'backupCode'
  | 'code'
  | 'codeImage'
  | 'codeUrl'
  | 'message'
  | 'status'
  | activate2FAOutputKeySpecifier
)[];
export type activate2FAOutputFieldPolicy = {
  backupCode?: FieldPolicy<any> | FieldReadFunction<any>;
  code?: FieldPolicy<any> | FieldReadFunction<any>;
  codeImage?: FieldPolicy<any> | FieldReadFunction<any>;
  codeUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  message?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type authLoginOutputKeySpecifier = (
  | 'accesstoken'
  | 'error'
  | 'expiresIn'
  | 'role'
  | 'userId'
  | authLoginOutputKeySpecifier
)[];
export type authLoginOutputFieldPolicy = {
  accesstoken?: FieldPolicy<any> | FieldReadFunction<any>;
  error?: FieldPolicy<any> | FieldReadFunction<any>;
  expiresIn?: FieldPolicy<any> | FieldReadFunction<any>;
  role?: FieldPolicy<any> | FieldReadFunction<any>;
  userId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type authSignatureOutputKeySpecifier = (
  | 'message'
  | 'nonce'
  | 'status'
  | authSignatureOutputKeySpecifier
)[];
export type authSignatureOutputFieldPolicy = {
  message?: FieldPolicy<any> | FieldReadFunction<any>;
  nonce?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type depositAddressOutputKeySpecifier = (
  | 'address'
  | 'message'
  | depositAddressOutputKeySpecifier
)[];
export type depositAddressOutputFieldPolicy = {
  address?: FieldPolicy<any> | FieldReadFunction<any>;
  message?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type depositBankOutputKeySpecifier = (
  | 'depositDetails'
  | 'depositReference'
  | 'id'
  | 'message'
  | depositBankOutputKeySpecifier
)[];
export type depositBankOutputFieldPolicy = {
  depositDetails?: FieldPolicy<any> | FieldReadFunction<any>;
  depositReference?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  message?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type downloadFileOutputKeySpecifier = (
  | 'base64'
  | 'filename'
  | 'format'
  | 'status'
  | downloadFileOutputKeySpecifier
)[];
export type downloadFileOutputFieldPolicy = {
  base64?: FieldPolicy<any> | FieldReadFunction<any>;
  filename?: FieldPolicy<any> | FieldReadFunction<any>;
  format?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type generalOutputKeySpecifier = (
  | 'id'
  | 'message'
  | 'status'
  | generalOutputKeySpecifier
)[];
export type generalOutputFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  message?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type mutation_rootKeySpecifier = (
  | 'activate2FA1'
  | 'activate2FA2'
  | 'authLogin'
  | 'authRefresh'
  | 'authSignature'
  | 'buyTicket'
  | 'cancelTicket'
  | 'cancelTournament'
  | 'deactivate2FA'
  | 'deleteBlockchainCurrencies'
  | 'deleteBlockchainCurrenciesByPk'
  | 'deleteBlockchains'
  | 'deleteBlockchainsByPk'
  | 'deleteCountries'
  | 'deleteCountriesByPk'
  | 'deleteCurrencies'
  | 'deleteCurrenciesByPk'
  | 'deleteEvents'
  | 'deleteEventsByPk'
  | 'deleteFollower'
  | 'deleteFollowers'
  | 'deleteMessageTypes'
  | 'deleteMessageTypesByPk'
  | 'deleteMessages'
  | 'deleteMessagesByPk'
  | 'deleteOrganiserUsers'
  | 'deleteOrganiserUsersByPk'
  | 'deleteOrganisers'
  | 'deleteOrganisersByPk'
  | 'deletePaymentMethodBanks'
  | 'deletePaymentMethodBanksByPk'
  | 'deletePaymentMethods'
  | 'deletePaymentMethodsByPk'
  | 'deletePayments'
  | 'deletePaymentsByPk'
  | 'deletePendingTxs'
  | 'deletePendingTxsByPk'
  | 'deletePlayerFollowers'
  | 'deletePlayerShare'
  | 'deletePlayerShares'
  | 'deletePlayers'
  | 'deletePlayersByPk'
  | 'deleteStatEvents'
  | 'deleteStatEventsByPk'
  | 'deleteStatPlayers'
  | 'deleteStatPlayersByPk'
  | 'deleteStatTournamentResults'
  | 'deleteStatTournamentResultsByPk'
  | 'deleteStatTournaments'
  | 'deleteStatTournamentsByPk'
  | 'deleteStatusGames'
  | 'deleteStatusGamesByPk'
  | 'deleteStatuses'
  | 'deleteStatusesByPk'
  | 'deleteTickets'
  | 'deleteTicketsByPk'
  | 'deleteTicketsStatus'
  | 'deleteTicketsStatusByPk'
  | 'deleteTournamentResultStatuses'
  | 'deleteTournamentResultStatusesByPk'
  | 'deleteTournamentResults'
  | 'deleteTournamentResultsByPk'
  | 'deleteTournaments'
  | 'deleteTournamentsByPk'
  | 'deleteTransactionUserTypes'
  | 'deleteTransactionUserTypesByPk'
  | 'deleteTransactions'
  | 'deleteTransactionsByPk'
  | 'deleteUserShareBuys'
  | 'deleteUserShareBuysByPk'
  | 'deleteUsers'
  | 'deleteUsersByPk'
  | 'deleteVenue'
  | 'deleteVenueUsers'
  | 'deleteVenueUsersByPk'
  | 'deleteVenues'
  | 'finishTournament'
  | 'followPlayer'
  | 'insertBlockchainCurrencies'
  | 'insertBlockchainCurrenciesOne'
  | 'insertBlockchains'
  | 'insertBlockchainsOne'
  | 'insertCountries'
  | 'insertCountriesOne'
  | 'insertCurrencies'
  | 'insertCurrenciesOne'
  | 'insertEvent'
  | 'insertEvents'
  | 'insertFollower'
  | 'insertFollowers'
  | 'insertMessageTypes'
  | 'insertMessageTypesOne'
  | 'insertMessages'
  | 'insertMessagesOne'
  | 'insertOrganiserUsers'
  | 'insertOrganiserUsersOne'
  | 'insertOrganisers'
  | 'insertOrganisersOne'
  | 'insertPaymentMethodBanks'
  | 'insertPaymentMethodBanksOne'
  | 'insertPaymentMethods'
  | 'insertPaymentMethodsOne'
  | 'insertPayments'
  | 'insertPaymentsOne'
  | 'insertPendingTxs'
  | 'insertPendingTxsOne'
  | 'insertPlayerFollowers'
  | 'insertPlayerFollowersOne'
  | 'insertPlayerShare'
  | 'insertPlayerShares'
  | 'insertPlayers'
  | 'insertPlayersOne'
  | 'insertStatEvents'
  | 'insertStatEventsOne'
  | 'insertStatPlayers'
  | 'insertStatPlayersOne'
  | 'insertStatTournamentResults'
  | 'insertStatTournamentResultsOne'
  | 'insertStatTournaments'
  | 'insertStatTournamentsOne'
  | 'insertStatusGames'
  | 'insertStatusGamesOne'
  | 'insertStatuses'
  | 'insertStatusesOne'
  | 'insertTickets'
  | 'insertTicketsOne'
  | 'insertTicketsStatus'
  | 'insertTicketsStatusOne'
  | 'insertTournament'
  | 'insertTournamentResultStatuses'
  | 'insertTournamentResultStatusesOne'
  | 'insertTournamentResults'
  | 'insertTournamentResultsOne'
  | 'insertTournaments'
  | 'insertTransactionUserTypes'
  | 'insertTransactionUserTypesOne'
  | 'insertTransactions'
  | 'insertTransactionsOne'
  | 'insertUserShareBuys'
  | 'insertUserShareBuysOne'
  | 'insertUsers'
  | 'insertUsersOne'
  | 'insertVenue'
  | 'insertVenueUsers'
  | 'insertVenueUsersOne'
  | 'insertVenues'
  | 'linkERC20toFireBaseAccept'
  | 'linkERC20toFireBaseSignature'
  | 'payoutTicket'
  | 'playerShareResult'
  | 'registerPlayer'
  | 'sendVerifyMail'
  | 'setEmailaddress'
  | 'startTournament'
  | 'submitBankDeposit'
  | 'submitBankWithdraw'
  | 'submitDeposit'
  | 'submitPlayerShareCancel'
  | 'submitPlayerShareCreate'
  | 'submitShareBuy'
  | 'submitShareCancel'
  | 'submitShareClaim'
  | 'submitWithdraw'
  | 'submittedDeposit'
  | 'unfollowPlayer'
  | 'updateBlockchainCurrencies'
  | 'updateBlockchainCurrenciesByPk'
  | 'updateBlockchainCurrenciesMany'
  | 'updateBlockchains'
  | 'updateBlockchainsByPk'
  | 'updateBlockchainsMany'
  | 'updateCountries'
  | 'updateCountriesByPk'
  | 'updateCountriesMany'
  | 'updateCurrencies'
  | 'updateCurrenciesByPk'
  | 'updateCurrenciesMany'
  | 'updateEvent'
  | 'updateEvents'
  | 'updateEventsMany'
  | 'updateFollower'
  | 'updateFollowers'
  | 'updateManuVenues'
  | 'updateManyFollowers'
  | 'updateMessageTypes'
  | 'updateMessageTypesByPk'
  | 'updateMessageTypesMany'
  | 'updateMessages'
  | 'updateMessagesByPk'
  | 'updateMessagesMany'
  | 'updateOrganiserUsers'
  | 'updateOrganiserUsersByPk'
  | 'updateOrganiserUsersMany'
  | 'updateOrganisers'
  | 'updateOrganisersByPk'
  | 'updateOrganisersMany'
  | 'updatePaymentMethodBanks'
  | 'updatePaymentMethodBanksByPk'
  | 'updatePaymentMethodBanksMany'
  | 'updatePaymentMethods'
  | 'updatePaymentMethodsByPk'
  | 'updatePaymentMethodsMany'
  | 'updatePayments'
  | 'updatePaymentsByPk'
  | 'updatePaymentsMany'
  | 'updatePendingTxs'
  | 'updatePendingTxsByPk'
  | 'updatePendingTxsMany'
  | 'updatePlayer'
  | 'updatePlayerFollowers'
  | 'updatePlayerFollowersMany'
  | 'updatePlayerShare'
  | 'updatePlayerShares'
  | 'updatePlayerSharesMany'
  | 'updatePlayers'
  | 'updatePlayersMany'
  | 'updateStatEvents'
  | 'updateStatEventsByPk'
  | 'updateStatEventsMany'
  | 'updateStatPlayers'
  | 'updateStatPlayersByPk'
  | 'updateStatPlayersMany'
  | 'updateStatTournamentResults'
  | 'updateStatTournamentResultsByPk'
  | 'updateStatTournamentResultsMany'
  | 'updateStatTournaments'
  | 'updateStatTournamentsByPk'
  | 'updateStatTournamentsMany'
  | 'updateStatusGames'
  | 'updateStatusGamesByPk'
  | 'updateStatusGamesMany'
  | 'updateStatuses'
  | 'updateStatusesByPk'
  | 'updateStatusesMany'
  | 'updateTickets'
  | 'updateTicketsByPk'
  | 'updateTicketsMany'
  | 'updateTicketsStatus'
  | 'updateTicketsStatusByPk'
  | 'updateTicketsStatusMany'
  | 'updateTournament'
  | 'updateTournamentResultStatuses'
  | 'updateTournamentResultStatusesByPk'
  | 'updateTournamentResultStatusesMany'
  | 'updateTournamentResults'
  | 'updateTournamentResultsByPk'
  | 'updateTournamentResultsMany'
  | 'updateTournaments'
  | 'updateTournamentsMany'
  | 'updateTransactionUserTypes'
  | 'updateTransactionUserTypesByPk'
  | 'updateTransactionUserTypesMany'
  | 'updateTransactions'
  | 'updateTransactionsByPk'
  | 'updateTransactionsMany'
  | 'updateUserShareBuys'
  | 'updateUserShareBuysByPk'
  | 'updateUserShareBuysMany'
  | 'updateUsers'
  | 'updateUsersByPk'
  | 'updateUsersMany'
  | 'updateVenue'
  | 'updateVenueUsers'
  | 'updateVenueUsersByPk'
  | 'updateVenueUsersMany'
  | 'updateVenues'
  | mutation_rootKeySpecifier
)[];
export type mutation_rootFieldPolicy = {
  activate2FA1?: FieldPolicy<any> | FieldReadFunction<any>;
  activate2FA2?: FieldPolicy<any> | FieldReadFunction<any>;
  authLogin?: FieldPolicy<any> | FieldReadFunction<any>;
  authRefresh?: FieldPolicy<any> | FieldReadFunction<any>;
  authSignature?: FieldPolicy<any> | FieldReadFunction<any>;
  buyTicket?: FieldPolicy<any> | FieldReadFunction<any>;
  cancelTicket?: FieldPolicy<any> | FieldReadFunction<any>;
  cancelTournament?: FieldPolicy<any> | FieldReadFunction<any>;
  deactivate2FA?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteBlockchainCurrencies?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteBlockchainCurrenciesByPk?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteBlockchains?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteBlockchainsByPk?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteCountries?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteCountriesByPk?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteCurrencies?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteCurrenciesByPk?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteEvents?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteEventsByPk?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteFollower?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteFollowers?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteMessageTypes?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteMessageTypesByPk?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteMessages?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteMessagesByPk?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteOrganiserUsers?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteOrganiserUsersByPk?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteOrganisers?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteOrganisersByPk?: FieldPolicy<any> | FieldReadFunction<any>;
  deletePaymentMethodBanks?: FieldPolicy<any> | FieldReadFunction<any>;
  deletePaymentMethodBanksByPk?: FieldPolicy<any> | FieldReadFunction<any>;
  deletePaymentMethods?: FieldPolicy<any> | FieldReadFunction<any>;
  deletePaymentMethodsByPk?: FieldPolicy<any> | FieldReadFunction<any>;
  deletePayments?: FieldPolicy<any> | FieldReadFunction<any>;
  deletePaymentsByPk?: FieldPolicy<any> | FieldReadFunction<any>;
  deletePendingTxs?: FieldPolicy<any> | FieldReadFunction<any>;
  deletePendingTxsByPk?: FieldPolicy<any> | FieldReadFunction<any>;
  deletePlayerFollowers?: FieldPolicy<any> | FieldReadFunction<any>;
  deletePlayerShare?: FieldPolicy<any> | FieldReadFunction<any>;
  deletePlayerShares?: FieldPolicy<any> | FieldReadFunction<any>;
  deletePlayers?: FieldPolicy<any> | FieldReadFunction<any>;
  deletePlayersByPk?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteStatEvents?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteStatEventsByPk?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteStatPlayers?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteStatPlayersByPk?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteStatTournamentResults?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteStatTournamentResultsByPk?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteStatTournaments?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteStatTournamentsByPk?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteStatusGames?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteStatusGamesByPk?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteStatuses?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteStatusesByPk?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteTickets?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteTicketsByPk?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteTicketsStatus?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteTicketsStatusByPk?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteTournamentResultStatuses?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteTournamentResultStatusesByPk?:
    | FieldPolicy<any>
    | FieldReadFunction<any>;
  deleteTournamentResults?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteTournamentResultsByPk?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteTournaments?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteTournamentsByPk?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteTransactionUserTypes?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteTransactionUserTypesByPk?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteTransactions?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteTransactionsByPk?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteUserShareBuys?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteUserShareBuysByPk?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteUsers?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteUsersByPk?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteVenue?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteVenueUsers?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteVenueUsersByPk?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteVenues?: FieldPolicy<any> | FieldReadFunction<any>;
  finishTournament?: FieldPolicy<any> | FieldReadFunction<any>;
  followPlayer?: FieldPolicy<any> | FieldReadFunction<any>;
  insertBlockchainCurrencies?: FieldPolicy<any> | FieldReadFunction<any>;
  insertBlockchainCurrenciesOne?: FieldPolicy<any> | FieldReadFunction<any>;
  insertBlockchains?: FieldPolicy<any> | FieldReadFunction<any>;
  insertBlockchainsOne?: FieldPolicy<any> | FieldReadFunction<any>;
  insertCountries?: FieldPolicy<any> | FieldReadFunction<any>;
  insertCountriesOne?: FieldPolicy<any> | FieldReadFunction<any>;
  insertCurrencies?: FieldPolicy<any> | FieldReadFunction<any>;
  insertCurrenciesOne?: FieldPolicy<any> | FieldReadFunction<any>;
  insertEvent?: FieldPolicy<any> | FieldReadFunction<any>;
  insertEvents?: FieldPolicy<any> | FieldReadFunction<any>;
  insertFollower?: FieldPolicy<any> | FieldReadFunction<any>;
  insertFollowers?: FieldPolicy<any> | FieldReadFunction<any>;
  insertMessageTypes?: FieldPolicy<any> | FieldReadFunction<any>;
  insertMessageTypesOne?: FieldPolicy<any> | FieldReadFunction<any>;
  insertMessages?: FieldPolicy<any> | FieldReadFunction<any>;
  insertMessagesOne?: FieldPolicy<any> | FieldReadFunction<any>;
  insertOrganiserUsers?: FieldPolicy<any> | FieldReadFunction<any>;
  insertOrganiserUsersOne?: FieldPolicy<any> | FieldReadFunction<any>;
  insertOrganisers?: FieldPolicy<any> | FieldReadFunction<any>;
  insertOrganisersOne?: FieldPolicy<any> | FieldReadFunction<any>;
  insertPaymentMethodBanks?: FieldPolicy<any> | FieldReadFunction<any>;
  insertPaymentMethodBanksOne?: FieldPolicy<any> | FieldReadFunction<any>;
  insertPaymentMethods?: FieldPolicy<any> | FieldReadFunction<any>;
  insertPaymentMethodsOne?: FieldPolicy<any> | FieldReadFunction<any>;
  insertPayments?: FieldPolicy<any> | FieldReadFunction<any>;
  insertPaymentsOne?: FieldPolicy<any> | FieldReadFunction<any>;
  insertPendingTxs?: FieldPolicy<any> | FieldReadFunction<any>;
  insertPendingTxsOne?: FieldPolicy<any> | FieldReadFunction<any>;
  insertPlayerFollowers?: FieldPolicy<any> | FieldReadFunction<any>;
  insertPlayerFollowersOne?: FieldPolicy<any> | FieldReadFunction<any>;
  insertPlayerShare?: FieldPolicy<any> | FieldReadFunction<any>;
  insertPlayerShares?: FieldPolicy<any> | FieldReadFunction<any>;
  insertPlayers?: FieldPolicy<any> | FieldReadFunction<any>;
  insertPlayersOne?: FieldPolicy<any> | FieldReadFunction<any>;
  insertStatEvents?: FieldPolicy<any> | FieldReadFunction<any>;
  insertStatEventsOne?: FieldPolicy<any> | FieldReadFunction<any>;
  insertStatPlayers?: FieldPolicy<any> | FieldReadFunction<any>;
  insertStatPlayersOne?: FieldPolicy<any> | FieldReadFunction<any>;
  insertStatTournamentResults?: FieldPolicy<any> | FieldReadFunction<any>;
  insertStatTournamentResultsOne?: FieldPolicy<any> | FieldReadFunction<any>;
  insertStatTournaments?: FieldPolicy<any> | FieldReadFunction<any>;
  insertStatTournamentsOne?: FieldPolicy<any> | FieldReadFunction<any>;
  insertStatusGames?: FieldPolicy<any> | FieldReadFunction<any>;
  insertStatusGamesOne?: FieldPolicy<any> | FieldReadFunction<any>;
  insertStatuses?: FieldPolicy<any> | FieldReadFunction<any>;
  insertStatusesOne?: FieldPolicy<any> | FieldReadFunction<any>;
  insertTickets?: FieldPolicy<any> | FieldReadFunction<any>;
  insertTicketsOne?: FieldPolicy<any> | FieldReadFunction<any>;
  insertTicketsStatus?: FieldPolicy<any> | FieldReadFunction<any>;
  insertTicketsStatusOne?: FieldPolicy<any> | FieldReadFunction<any>;
  insertTournament?: FieldPolicy<any> | FieldReadFunction<any>;
  insertTournamentResultStatuses?: FieldPolicy<any> | FieldReadFunction<any>;
  insertTournamentResultStatusesOne?: FieldPolicy<any> | FieldReadFunction<any>;
  insertTournamentResults?: FieldPolicy<any> | FieldReadFunction<any>;
  insertTournamentResultsOne?: FieldPolicy<any> | FieldReadFunction<any>;
  insertTournaments?: FieldPolicy<any> | FieldReadFunction<any>;
  insertTransactionUserTypes?: FieldPolicy<any> | FieldReadFunction<any>;
  insertTransactionUserTypesOne?: FieldPolicy<any> | FieldReadFunction<any>;
  insertTransactions?: FieldPolicy<any> | FieldReadFunction<any>;
  insertTransactionsOne?: FieldPolicy<any> | FieldReadFunction<any>;
  insertUserShareBuys?: FieldPolicy<any> | FieldReadFunction<any>;
  insertUserShareBuysOne?: FieldPolicy<any> | FieldReadFunction<any>;
  insertUsers?: FieldPolicy<any> | FieldReadFunction<any>;
  insertUsersOne?: FieldPolicy<any> | FieldReadFunction<any>;
  insertVenue?: FieldPolicy<any> | FieldReadFunction<any>;
  insertVenueUsers?: FieldPolicy<any> | FieldReadFunction<any>;
  insertVenueUsersOne?: FieldPolicy<any> | FieldReadFunction<any>;
  insertVenues?: FieldPolicy<any> | FieldReadFunction<any>;
  linkERC20toFireBaseAccept?: FieldPolicy<any> | FieldReadFunction<any>;
  linkERC20toFireBaseSignature?: FieldPolicy<any> | FieldReadFunction<any>;
  payoutTicket?: FieldPolicy<any> | FieldReadFunction<any>;
  playerShareResult?: FieldPolicy<any> | FieldReadFunction<any>;
  registerPlayer?: FieldPolicy<any> | FieldReadFunction<any>;
  sendVerifyMail?: FieldPolicy<any> | FieldReadFunction<any>;
  setEmailaddress?: FieldPolicy<any> | FieldReadFunction<any>;
  startTournament?: FieldPolicy<any> | FieldReadFunction<any>;
  submitBankDeposit?: FieldPolicy<any> | FieldReadFunction<any>;
  submitBankWithdraw?: FieldPolicy<any> | FieldReadFunction<any>;
  submitDeposit?: FieldPolicy<any> | FieldReadFunction<any>;
  submitPlayerShareCancel?: FieldPolicy<any> | FieldReadFunction<any>;
  submitPlayerShareCreate?: FieldPolicy<any> | FieldReadFunction<any>;
  submitShareBuy?: FieldPolicy<any> | FieldReadFunction<any>;
  submitShareCancel?: FieldPolicy<any> | FieldReadFunction<any>;
  submitShareClaim?: FieldPolicy<any> | FieldReadFunction<any>;
  submitWithdraw?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedDeposit?: FieldPolicy<any> | FieldReadFunction<any>;
  unfollowPlayer?: FieldPolicy<any> | FieldReadFunction<any>;
  updateBlockchainCurrencies?: FieldPolicy<any> | FieldReadFunction<any>;
  updateBlockchainCurrenciesByPk?: FieldPolicy<any> | FieldReadFunction<any>;
  updateBlockchainCurrenciesMany?: FieldPolicy<any> | FieldReadFunction<any>;
  updateBlockchains?: FieldPolicy<any> | FieldReadFunction<any>;
  updateBlockchainsByPk?: FieldPolicy<any> | FieldReadFunction<any>;
  updateBlockchainsMany?: FieldPolicy<any> | FieldReadFunction<any>;
  updateCountries?: FieldPolicy<any> | FieldReadFunction<any>;
  updateCountriesByPk?: FieldPolicy<any> | FieldReadFunction<any>;
  updateCountriesMany?: FieldPolicy<any> | FieldReadFunction<any>;
  updateCurrencies?: FieldPolicy<any> | FieldReadFunction<any>;
  updateCurrenciesByPk?: FieldPolicy<any> | FieldReadFunction<any>;
  updateCurrenciesMany?: FieldPolicy<any> | FieldReadFunction<any>;
  updateEvent?: FieldPolicy<any> | FieldReadFunction<any>;
  updateEvents?: FieldPolicy<any> | FieldReadFunction<any>;
  updateEventsMany?: FieldPolicy<any> | FieldReadFunction<any>;
  updateFollower?: FieldPolicy<any> | FieldReadFunction<any>;
  updateFollowers?: FieldPolicy<any> | FieldReadFunction<any>;
  updateManuVenues?: FieldPolicy<any> | FieldReadFunction<any>;
  updateManyFollowers?: FieldPolicy<any> | FieldReadFunction<any>;
  updateMessageTypes?: FieldPolicy<any> | FieldReadFunction<any>;
  updateMessageTypesByPk?: FieldPolicy<any> | FieldReadFunction<any>;
  updateMessageTypesMany?: FieldPolicy<any> | FieldReadFunction<any>;
  updateMessages?: FieldPolicy<any> | FieldReadFunction<any>;
  updateMessagesByPk?: FieldPolicy<any> | FieldReadFunction<any>;
  updateMessagesMany?: FieldPolicy<any> | FieldReadFunction<any>;
  updateOrganiserUsers?: FieldPolicy<any> | FieldReadFunction<any>;
  updateOrganiserUsersByPk?: FieldPolicy<any> | FieldReadFunction<any>;
  updateOrganiserUsersMany?: FieldPolicy<any> | FieldReadFunction<any>;
  updateOrganisers?: FieldPolicy<any> | FieldReadFunction<any>;
  updateOrganisersByPk?: FieldPolicy<any> | FieldReadFunction<any>;
  updateOrganisersMany?: FieldPolicy<any> | FieldReadFunction<any>;
  updatePaymentMethodBanks?: FieldPolicy<any> | FieldReadFunction<any>;
  updatePaymentMethodBanksByPk?: FieldPolicy<any> | FieldReadFunction<any>;
  updatePaymentMethodBanksMany?: FieldPolicy<any> | FieldReadFunction<any>;
  updatePaymentMethods?: FieldPolicy<any> | FieldReadFunction<any>;
  updatePaymentMethodsByPk?: FieldPolicy<any> | FieldReadFunction<any>;
  updatePaymentMethodsMany?: FieldPolicy<any> | FieldReadFunction<any>;
  updatePayments?: FieldPolicy<any> | FieldReadFunction<any>;
  updatePaymentsByPk?: FieldPolicy<any> | FieldReadFunction<any>;
  updatePaymentsMany?: FieldPolicy<any> | FieldReadFunction<any>;
  updatePendingTxs?: FieldPolicy<any> | FieldReadFunction<any>;
  updatePendingTxsByPk?: FieldPolicy<any> | FieldReadFunction<any>;
  updatePendingTxsMany?: FieldPolicy<any> | FieldReadFunction<any>;
  updatePlayer?: FieldPolicy<any> | FieldReadFunction<any>;
  updatePlayerFollowers?: FieldPolicy<any> | FieldReadFunction<any>;
  updatePlayerFollowersMany?: FieldPolicy<any> | FieldReadFunction<any>;
  updatePlayerShare?: FieldPolicy<any> | FieldReadFunction<any>;
  updatePlayerShares?: FieldPolicy<any> | FieldReadFunction<any>;
  updatePlayerSharesMany?: FieldPolicy<any> | FieldReadFunction<any>;
  updatePlayers?: FieldPolicy<any> | FieldReadFunction<any>;
  updatePlayersMany?: FieldPolicy<any> | FieldReadFunction<any>;
  updateStatEvents?: FieldPolicy<any> | FieldReadFunction<any>;
  updateStatEventsByPk?: FieldPolicy<any> | FieldReadFunction<any>;
  updateStatEventsMany?: FieldPolicy<any> | FieldReadFunction<any>;
  updateStatPlayers?: FieldPolicy<any> | FieldReadFunction<any>;
  updateStatPlayersByPk?: FieldPolicy<any> | FieldReadFunction<any>;
  updateStatPlayersMany?: FieldPolicy<any> | FieldReadFunction<any>;
  updateStatTournamentResults?: FieldPolicy<any> | FieldReadFunction<any>;
  updateStatTournamentResultsByPk?: FieldPolicy<any> | FieldReadFunction<any>;
  updateStatTournamentResultsMany?: FieldPolicy<any> | FieldReadFunction<any>;
  updateStatTournaments?: FieldPolicy<any> | FieldReadFunction<any>;
  updateStatTournamentsByPk?: FieldPolicy<any> | FieldReadFunction<any>;
  updateStatTournamentsMany?: FieldPolicy<any> | FieldReadFunction<any>;
  updateStatusGames?: FieldPolicy<any> | FieldReadFunction<any>;
  updateStatusGamesByPk?: FieldPolicy<any> | FieldReadFunction<any>;
  updateStatusGamesMany?: FieldPolicy<any> | FieldReadFunction<any>;
  updateStatuses?: FieldPolicy<any> | FieldReadFunction<any>;
  updateStatusesByPk?: FieldPolicy<any> | FieldReadFunction<any>;
  updateStatusesMany?: FieldPolicy<any> | FieldReadFunction<any>;
  updateTickets?: FieldPolicy<any> | FieldReadFunction<any>;
  updateTicketsByPk?: FieldPolicy<any> | FieldReadFunction<any>;
  updateTicketsMany?: FieldPolicy<any> | FieldReadFunction<any>;
  updateTicketsStatus?: FieldPolicy<any> | FieldReadFunction<any>;
  updateTicketsStatusByPk?: FieldPolicy<any> | FieldReadFunction<any>;
  updateTicketsStatusMany?: FieldPolicy<any> | FieldReadFunction<any>;
  updateTournament?: FieldPolicy<any> | FieldReadFunction<any>;
  updateTournamentResultStatuses?: FieldPolicy<any> | FieldReadFunction<any>;
  updateTournamentResultStatusesByPk?:
    | FieldPolicy<any>
    | FieldReadFunction<any>;
  updateTournamentResultStatusesMany?:
    | FieldPolicy<any>
    | FieldReadFunction<any>;
  updateTournamentResults?: FieldPolicy<any> | FieldReadFunction<any>;
  updateTournamentResultsByPk?: FieldPolicy<any> | FieldReadFunction<any>;
  updateTournamentResultsMany?: FieldPolicy<any> | FieldReadFunction<any>;
  updateTournaments?: FieldPolicy<any> | FieldReadFunction<any>;
  updateTournamentsMany?: FieldPolicy<any> | FieldReadFunction<any>;
  updateTransactionUserTypes?: FieldPolicy<any> | FieldReadFunction<any>;
  updateTransactionUserTypesByPk?: FieldPolicy<any> | FieldReadFunction<any>;
  updateTransactionUserTypesMany?: FieldPolicy<any> | FieldReadFunction<any>;
  updateTransactions?: FieldPolicy<any> | FieldReadFunction<any>;
  updateTransactionsByPk?: FieldPolicy<any> | FieldReadFunction<any>;
  updateTransactionsMany?: FieldPolicy<any> | FieldReadFunction<any>;
  updateUserShareBuys?: FieldPolicy<any> | FieldReadFunction<any>;
  updateUserShareBuysByPk?: FieldPolicy<any> | FieldReadFunction<any>;
  updateUserShareBuysMany?: FieldPolicy<any> | FieldReadFunction<any>;
  updateUsers?: FieldPolicy<any> | FieldReadFunction<any>;
  updateUsersByPk?: FieldPolicy<any> | FieldReadFunction<any>;
  updateUsersMany?: FieldPolicy<any> | FieldReadFunction<any>;
  updateVenue?: FieldPolicy<any> | FieldReadFunction<any>;
  updateVenueUsers?: FieldPolicy<any> | FieldReadFunction<any>;
  updateVenueUsersByPk?: FieldPolicy<any> | FieldReadFunction<any>;
  updateVenueUsersMany?: FieldPolicy<any> | FieldReadFunction<any>;
  updateVenues?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type playerShareResultOutputKeySpecifier = (
  | 'id'
  | 'message'
  | 'playerPayout'
  | 'stake3Payout'
  | 'stake3PayoutUSD'
  | 'status'
  | playerShareResultOutputKeySpecifier
)[];
export type playerShareResultOutputFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  message?: FieldPolicy<any> | FieldReadFunction<any>;
  playerPayout?: FieldPolicy<any> | FieldReadFunction<any>;
  stake3Payout?: FieldPolicy<any> | FieldReadFunction<any>;
  stake3PayoutUSD?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type query_rootKeySpecifier = (
  | 'blockchainCurrencies'
  | 'blockchainCurrenciesAggregate'
  | 'blockchainCurrenciesByPk'
  | 'blockchains'
  | 'blockchainsAggregate'
  | 'blockchainsByPk'
  | 'countries'
  | 'countriesAggregate'
  | 'countriesByPk'
  | 'currencies'
  | 'currenciesAggregate'
  | 'currenciesByPk'
  | 'downloadEventTransactions'
  | 'event'
  | 'events'
  | 'eventsAggregate'
  | 'follower'
  | 'followers'
  | 'followersAggregate'
  | 'getDepositAddress'
  | 'getDepositBankInfo'
  | 'me'
  | 'messageTypes'
  | 'messageTypesAggregate'
  | 'messageTypesByPk'
  | 'messages'
  | 'messagesAggregate'
  | 'messagesByPk'
  | 'organiserUsers'
  | 'organiserUsersAggregate'
  | 'organiserUsersByPk'
  | 'organisers'
  | 'organisersAggregate'
  | 'organisersByPk'
  | 'paymentMethodBanks'
  | 'paymentMethodBanksAggregate'
  | 'paymentMethodBanksByPk'
  | 'paymentMethods'
  | 'paymentMethodsAggregate'
  | 'paymentMethodsByPk'
  | 'payments'
  | 'paymentsAggregate'
  | 'paymentsByPk'
  | 'pendingTxs'
  | 'pendingTxsAggregate'
  | 'pendingTxsByPk'
  | 'player'
  | 'playerFollowers'
  | 'playerFollowersAggregate'
  | 'playerFollowersView'
  | 'playerFollowersViewAggregate'
  | 'playerShare'
  | 'playerShares'
  | 'playerSharesAggregate'
  | 'playerSharesBought'
  | 'playerSharesBoughtAggregate'
  | 'players'
  | 'playersAggregate'
  | 'statEvents'
  | 'statEventsAggregate'
  | 'statEventsByPk'
  | 'statPlayers'
  | 'statPlayersAggregate'
  | 'statPlayersByPk'
  | 'statTopPlayers'
  | 'statTopPlayersAggregate'
  | 'statTopYearPlayers'
  | 'statTopYearPlayersAggregate'
  | 'statTournamentResults'
  | 'statTournamentResultsAggregate'
  | 'statTournamentResultsByPk'
  | 'statTournaments'
  | 'statTournamentsAggregate'
  | 'statTournamentsByPk'
  | 'statusGames'
  | 'statusGamesAggregate'
  | 'statusGamesByPk'
  | 'statuses'
  | 'statusesAggregate'
  | 'statusesByPk'
  | 'tickets'
  | 'ticketsAggregate'
  | 'ticketsByPk'
  | 'ticketsStatus'
  | 'ticketsStatusAggregate'
  | 'ticketsStatusByPk'
  | 'tournament'
  | 'tournamentBalances'
  | 'tournamentBalancesAggregate'
  | 'tournamentResultStatuses'
  | 'tournamentResultStatusesAggregate'
  | 'tournamentResultStatusesByPk'
  | 'tournamentResults'
  | 'tournamentResultsAggregate'
  | 'tournamentResultsByPk'
  | 'tournaments'
  | 'tournamentsAggregate'
  | 'transactionUserTypes'
  | 'transactionUserTypesAggregate'
  | 'transactionUserTypesByPk'
  | 'transactions'
  | 'transactionsAggregate'
  | 'transactionsByPk'
  | 'userBalances'
  | 'userBalancesAggregate'
  | 'userShareBuys'
  | 'userShareBuysAggregate'
  | 'userShareBuysByPk'
  | 'users'
  | 'usersAggregate'
  | 'usersByPk'
  | 'venue'
  | 'venueUsers'
  | 'venueUsersAggregate'
  | 'venueUsersByPk'
  | 'venues'
  | 'venuesAggregate'
  | query_rootKeySpecifier
)[];
export type query_rootFieldPolicy = {
  blockchainCurrencies?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchainCurrenciesAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchainCurrenciesByPk?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchains?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchainsAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchainsByPk?: FieldPolicy<any> | FieldReadFunction<any>;
  countries?: FieldPolicy<any> | FieldReadFunction<any>;
  countriesAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  countriesByPk?: FieldPolicy<any> | FieldReadFunction<any>;
  currencies?: FieldPolicy<any> | FieldReadFunction<any>;
  currenciesAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  currenciesByPk?: FieldPolicy<any> | FieldReadFunction<any>;
  downloadEventTransactions?: FieldPolicy<any> | FieldReadFunction<any>;
  event?: FieldPolicy<any> | FieldReadFunction<any>;
  events?: FieldPolicy<any> | FieldReadFunction<any>;
  eventsAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  follower?: FieldPolicy<any> | FieldReadFunction<any>;
  followers?: FieldPolicy<any> | FieldReadFunction<any>;
  followersAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  getDepositAddress?: FieldPolicy<any> | FieldReadFunction<any>;
  getDepositBankInfo?: FieldPolicy<any> | FieldReadFunction<any>;
  me?: FieldPolicy<any> | FieldReadFunction<any>;
  messageTypes?: FieldPolicy<any> | FieldReadFunction<any>;
  messageTypesAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  messageTypesByPk?: FieldPolicy<any> | FieldReadFunction<any>;
  messages?: FieldPolicy<any> | FieldReadFunction<any>;
  messagesAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  messagesByPk?: FieldPolicy<any> | FieldReadFunction<any>;
  organiserUsers?: FieldPolicy<any> | FieldReadFunction<any>;
  organiserUsersAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  organiserUsersByPk?: FieldPolicy<any> | FieldReadFunction<any>;
  organisers?: FieldPolicy<any> | FieldReadFunction<any>;
  organisersAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  organisersByPk?: FieldPolicy<any> | FieldReadFunction<any>;
  paymentMethodBanks?: FieldPolicy<any> | FieldReadFunction<any>;
  paymentMethodBanksAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  paymentMethodBanksByPk?: FieldPolicy<any> | FieldReadFunction<any>;
  paymentMethods?: FieldPolicy<any> | FieldReadFunction<any>;
  paymentMethodsAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  paymentMethodsByPk?: FieldPolicy<any> | FieldReadFunction<any>;
  payments?: FieldPolicy<any> | FieldReadFunction<any>;
  paymentsAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  paymentsByPk?: FieldPolicy<any> | FieldReadFunction<any>;
  pendingTxs?: FieldPolicy<any> | FieldReadFunction<any>;
  pendingTxsAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  pendingTxsByPk?: FieldPolicy<any> | FieldReadFunction<any>;
  player?: FieldPolicy<any> | FieldReadFunction<any>;
  playerFollowers?: FieldPolicy<any> | FieldReadFunction<any>;
  playerFollowersAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  playerFollowersView?: FieldPolicy<any> | FieldReadFunction<any>;
  playerFollowersViewAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  playerShare?: FieldPolicy<any> | FieldReadFunction<any>;
  playerShares?: FieldPolicy<any> | FieldReadFunction<any>;
  playerSharesAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  playerSharesBought?: FieldPolicy<any> | FieldReadFunction<any>;
  playerSharesBoughtAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  players?: FieldPolicy<any> | FieldReadFunction<any>;
  playersAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  statEvents?: FieldPolicy<any> | FieldReadFunction<any>;
  statEventsAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  statEventsByPk?: FieldPolicy<any> | FieldReadFunction<any>;
  statPlayers?: FieldPolicy<any> | FieldReadFunction<any>;
  statPlayersAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  statPlayersByPk?: FieldPolicy<any> | FieldReadFunction<any>;
  statTopPlayers?: FieldPolicy<any> | FieldReadFunction<any>;
  statTopPlayersAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  statTopYearPlayers?: FieldPolicy<any> | FieldReadFunction<any>;
  statTopYearPlayersAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  statTournamentResults?: FieldPolicy<any> | FieldReadFunction<any>;
  statTournamentResultsAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  statTournamentResultsByPk?: FieldPolicy<any> | FieldReadFunction<any>;
  statTournaments?: FieldPolicy<any> | FieldReadFunction<any>;
  statTournamentsAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  statTournamentsByPk?: FieldPolicy<any> | FieldReadFunction<any>;
  statusGames?: FieldPolicy<any> | FieldReadFunction<any>;
  statusGamesAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  statusGamesByPk?: FieldPolicy<any> | FieldReadFunction<any>;
  statuses?: FieldPolicy<any> | FieldReadFunction<any>;
  statusesAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  statusesByPk?: FieldPolicy<any> | FieldReadFunction<any>;
  tickets?: FieldPolicy<any> | FieldReadFunction<any>;
  ticketsAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  ticketsByPk?: FieldPolicy<any> | FieldReadFunction<any>;
  ticketsStatus?: FieldPolicy<any> | FieldReadFunction<any>;
  ticketsStatusAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  ticketsStatusByPk?: FieldPolicy<any> | FieldReadFunction<any>;
  tournament?: FieldPolicy<any> | FieldReadFunction<any>;
  tournamentBalances?: FieldPolicy<any> | FieldReadFunction<any>;
  tournamentBalancesAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  tournamentResultStatuses?: FieldPolicy<any> | FieldReadFunction<any>;
  tournamentResultStatusesAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  tournamentResultStatusesByPk?: FieldPolicy<any> | FieldReadFunction<any>;
  tournamentResults?: FieldPolicy<any> | FieldReadFunction<any>;
  tournamentResultsAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  tournamentResultsByPk?: FieldPolicy<any> | FieldReadFunction<any>;
  tournaments?: FieldPolicy<any> | FieldReadFunction<any>;
  tournamentsAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  transactionUserTypes?: FieldPolicy<any> | FieldReadFunction<any>;
  transactionUserTypesAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  transactionUserTypesByPk?: FieldPolicy<any> | FieldReadFunction<any>;
  transactions?: FieldPolicy<any> | FieldReadFunction<any>;
  transactionsAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  transactionsByPk?: FieldPolicy<any> | FieldReadFunction<any>;
  userBalances?: FieldPolicy<any> | FieldReadFunction<any>;
  userBalancesAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  userShareBuys?: FieldPolicy<any> | FieldReadFunction<any>;
  userShareBuysAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  userShareBuysByPk?: FieldPolicy<any> | FieldReadFunction<any>;
  users?: FieldPolicy<any> | FieldReadFunction<any>;
  usersAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  usersByPk?: FieldPolicy<any> | FieldReadFunction<any>;
  venue?: FieldPolicy<any> | FieldReadFunction<any>;
  venueUsers?: FieldPolicy<any> | FieldReadFunction<any>;
  venueUsersAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  venueUsersByPk?: FieldPolicy<any> | FieldReadFunction<any>;
  venues?: FieldPolicy<any> | FieldReadFunction<any>;
  venuesAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type subscription_rootKeySpecifier = (
  | 'blockchainCurrencies'
  | 'blockchainCurrenciesAggregate'
  | 'blockchainCurrenciesByPk'
  | 'blockchainCurrenciesStream'
  | 'blockchains'
  | 'blockchainsAggregate'
  | 'blockchainsByPk'
  | 'blockchainsStream'
  | 'countries'
  | 'countriesAggregate'
  | 'countriesByPk'
  | 'countriesStream'
  | 'currencies'
  | 'currenciesAggregate'
  | 'currenciesByPk'
  | 'currenciesStream'
  | 'event'
  | 'events'
  | 'eventsAggregate'
  | 'eventsStream'
  | 'follower'
  | 'followers'
  | 'followersAggregate'
  | 'followersStream'
  | 'messageTypes'
  | 'messageTypesAggregate'
  | 'messageTypesByPk'
  | 'messageTypesStream'
  | 'messages'
  | 'messagesAggregate'
  | 'messagesByPk'
  | 'messagesStream'
  | 'organiserUsers'
  | 'organiserUsersAggregate'
  | 'organiserUsersByPk'
  | 'organiserUsersStream'
  | 'organisers'
  | 'organisersAggregate'
  | 'organisersByPk'
  | 'organisersStream'
  | 'paymentMethodBanks'
  | 'paymentMethodBanksAggregate'
  | 'paymentMethodBanksByPk'
  | 'paymentMethodBanksStream'
  | 'paymentMethods'
  | 'paymentMethodsAggregate'
  | 'paymentMethodsByPk'
  | 'paymentMethodsStream'
  | 'payments'
  | 'paymentsAggregate'
  | 'paymentsByPk'
  | 'paymentsStream'
  | 'pendingTxs'
  | 'pendingTxsAggregate'
  | 'pendingTxsByPk'
  | 'pendingTxsStream'
  | 'player'
  | 'playerFollowers'
  | 'playerFollowersAggregate'
  | 'playerFollowersStream'
  | 'playerFollowersView'
  | 'playerFollowersViewAggregate'
  | 'playerFollowersViewStream'
  | 'playerShare'
  | 'playerShares'
  | 'playerSharesAggregate'
  | 'playerSharesBought'
  | 'playerSharesBoughtAggregate'
  | 'playerSharesBoughtStream'
  | 'playerSharesStream'
  | 'players'
  | 'playersAggregate'
  | 'playersStream'
  | 'statEvents'
  | 'statEventsAggregate'
  | 'statEventsByPk'
  | 'statEventsStream'
  | 'statPlayers'
  | 'statPlayersAggregate'
  | 'statPlayersByPk'
  | 'statPlayersStream'
  | 'statTopPlayers'
  | 'statTopPlayersAggregate'
  | 'statTopPlayersStream'
  | 'statTopYearPlayers'
  | 'statTopYearPlayersAggregate'
  | 'statTopYearPlayersStream'
  | 'statTournamentResults'
  | 'statTournamentResultsAggregate'
  | 'statTournamentResultsByPk'
  | 'statTournamentResultsStream'
  | 'statTournaments'
  | 'statTournamentsAggregate'
  | 'statTournamentsByPk'
  | 'statTournamentsStream'
  | 'statusGames'
  | 'statusGamesAggregate'
  | 'statusGamesByPk'
  | 'statusGamesStream'
  | 'statuses'
  | 'statusesAggregate'
  | 'statusesByPk'
  | 'statusesStream'
  | 'tickets'
  | 'ticketsAggregate'
  | 'ticketsByPk'
  | 'ticketsStatus'
  | 'ticketsStatusAggregate'
  | 'ticketsStatusByPk'
  | 'ticketsStatusStream'
  | 'ticketsStream'
  | 'tournament'
  | 'tournamentBalances'
  | 'tournamentBalancesAggregate'
  | 'tournamentBalancesStream'
  | 'tournamentResultStatuses'
  | 'tournamentResultStatusesAggregate'
  | 'tournamentResultStatusesByPk'
  | 'tournamentResultStatusesStream'
  | 'tournamentResults'
  | 'tournamentResultsAggregate'
  | 'tournamentResultsByPk'
  | 'tournamentResultsStream'
  | 'tournaments'
  | 'tournamentsAggregate'
  | 'tournamentsStream'
  | 'transactionUserTypes'
  | 'transactionUserTypesAggregate'
  | 'transactionUserTypesByPk'
  | 'transactionUserTypesStream'
  | 'transactions'
  | 'transactionsAggregate'
  | 'transactionsByPk'
  | 'transactionsStream'
  | 'userBalances'
  | 'userBalancesAggregate'
  | 'userBalancesStream'
  | 'userShareBuys'
  | 'userShareBuysAggregate'
  | 'userShareBuysByPk'
  | 'userShareBuysStream'
  | 'users'
  | 'usersAggregate'
  | 'usersByPk'
  | 'usersStream'
  | 'venue'
  | 'venueUsers'
  | 'venueUsersAggregate'
  | 'venueUsersByPk'
  | 'venueUsersStream'
  | 'venues'
  | 'venuesAggregate'
  | 'venuesStream'
  | subscription_rootKeySpecifier
)[];
export type subscription_rootFieldPolicy = {
  blockchainCurrencies?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchainCurrenciesAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchainCurrenciesByPk?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchainCurrenciesStream?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchains?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchainsAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchainsByPk?: FieldPolicy<any> | FieldReadFunction<any>;
  blockchainsStream?: FieldPolicy<any> | FieldReadFunction<any>;
  countries?: FieldPolicy<any> | FieldReadFunction<any>;
  countriesAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  countriesByPk?: FieldPolicy<any> | FieldReadFunction<any>;
  countriesStream?: FieldPolicy<any> | FieldReadFunction<any>;
  currencies?: FieldPolicy<any> | FieldReadFunction<any>;
  currenciesAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  currenciesByPk?: FieldPolicy<any> | FieldReadFunction<any>;
  currenciesStream?: FieldPolicy<any> | FieldReadFunction<any>;
  event?: FieldPolicy<any> | FieldReadFunction<any>;
  events?: FieldPolicy<any> | FieldReadFunction<any>;
  eventsAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  eventsStream?: FieldPolicy<any> | FieldReadFunction<any>;
  follower?: FieldPolicy<any> | FieldReadFunction<any>;
  followers?: FieldPolicy<any> | FieldReadFunction<any>;
  followersAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  followersStream?: FieldPolicy<any> | FieldReadFunction<any>;
  messageTypes?: FieldPolicy<any> | FieldReadFunction<any>;
  messageTypesAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  messageTypesByPk?: FieldPolicy<any> | FieldReadFunction<any>;
  messageTypesStream?: FieldPolicy<any> | FieldReadFunction<any>;
  messages?: FieldPolicy<any> | FieldReadFunction<any>;
  messagesAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  messagesByPk?: FieldPolicy<any> | FieldReadFunction<any>;
  messagesStream?: FieldPolicy<any> | FieldReadFunction<any>;
  organiserUsers?: FieldPolicy<any> | FieldReadFunction<any>;
  organiserUsersAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  organiserUsersByPk?: FieldPolicy<any> | FieldReadFunction<any>;
  organiserUsersStream?: FieldPolicy<any> | FieldReadFunction<any>;
  organisers?: FieldPolicy<any> | FieldReadFunction<any>;
  organisersAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  organisersByPk?: FieldPolicy<any> | FieldReadFunction<any>;
  organisersStream?: FieldPolicy<any> | FieldReadFunction<any>;
  paymentMethodBanks?: FieldPolicy<any> | FieldReadFunction<any>;
  paymentMethodBanksAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  paymentMethodBanksByPk?: FieldPolicy<any> | FieldReadFunction<any>;
  paymentMethodBanksStream?: FieldPolicy<any> | FieldReadFunction<any>;
  paymentMethods?: FieldPolicy<any> | FieldReadFunction<any>;
  paymentMethodsAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  paymentMethodsByPk?: FieldPolicy<any> | FieldReadFunction<any>;
  paymentMethodsStream?: FieldPolicy<any> | FieldReadFunction<any>;
  payments?: FieldPolicy<any> | FieldReadFunction<any>;
  paymentsAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  paymentsByPk?: FieldPolicy<any> | FieldReadFunction<any>;
  paymentsStream?: FieldPolicy<any> | FieldReadFunction<any>;
  pendingTxs?: FieldPolicy<any> | FieldReadFunction<any>;
  pendingTxsAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  pendingTxsByPk?: FieldPolicy<any> | FieldReadFunction<any>;
  pendingTxsStream?: FieldPolicy<any> | FieldReadFunction<any>;
  player?: FieldPolicy<any> | FieldReadFunction<any>;
  playerFollowers?: FieldPolicy<any> | FieldReadFunction<any>;
  playerFollowersAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  playerFollowersStream?: FieldPolicy<any> | FieldReadFunction<any>;
  playerFollowersView?: FieldPolicy<any> | FieldReadFunction<any>;
  playerFollowersViewAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  playerFollowersViewStream?: FieldPolicy<any> | FieldReadFunction<any>;
  playerShare?: FieldPolicy<any> | FieldReadFunction<any>;
  playerShares?: FieldPolicy<any> | FieldReadFunction<any>;
  playerSharesAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  playerSharesBought?: FieldPolicy<any> | FieldReadFunction<any>;
  playerSharesBoughtAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  playerSharesBoughtStream?: FieldPolicy<any> | FieldReadFunction<any>;
  playerSharesStream?: FieldPolicy<any> | FieldReadFunction<any>;
  players?: FieldPolicy<any> | FieldReadFunction<any>;
  playersAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  playersStream?: FieldPolicy<any> | FieldReadFunction<any>;
  statEvents?: FieldPolicy<any> | FieldReadFunction<any>;
  statEventsAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  statEventsByPk?: FieldPolicy<any> | FieldReadFunction<any>;
  statEventsStream?: FieldPolicy<any> | FieldReadFunction<any>;
  statPlayers?: FieldPolicy<any> | FieldReadFunction<any>;
  statPlayersAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  statPlayersByPk?: FieldPolicy<any> | FieldReadFunction<any>;
  statPlayersStream?: FieldPolicy<any> | FieldReadFunction<any>;
  statTopPlayers?: FieldPolicy<any> | FieldReadFunction<any>;
  statTopPlayersAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  statTopPlayersStream?: FieldPolicy<any> | FieldReadFunction<any>;
  statTopYearPlayers?: FieldPolicy<any> | FieldReadFunction<any>;
  statTopYearPlayersAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  statTopYearPlayersStream?: FieldPolicy<any> | FieldReadFunction<any>;
  statTournamentResults?: FieldPolicy<any> | FieldReadFunction<any>;
  statTournamentResultsAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  statTournamentResultsByPk?: FieldPolicy<any> | FieldReadFunction<any>;
  statTournamentResultsStream?: FieldPolicy<any> | FieldReadFunction<any>;
  statTournaments?: FieldPolicy<any> | FieldReadFunction<any>;
  statTournamentsAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  statTournamentsByPk?: FieldPolicy<any> | FieldReadFunction<any>;
  statTournamentsStream?: FieldPolicy<any> | FieldReadFunction<any>;
  statusGames?: FieldPolicy<any> | FieldReadFunction<any>;
  statusGamesAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  statusGamesByPk?: FieldPolicy<any> | FieldReadFunction<any>;
  statusGamesStream?: FieldPolicy<any> | FieldReadFunction<any>;
  statuses?: FieldPolicy<any> | FieldReadFunction<any>;
  statusesAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  statusesByPk?: FieldPolicy<any> | FieldReadFunction<any>;
  statusesStream?: FieldPolicy<any> | FieldReadFunction<any>;
  tickets?: FieldPolicy<any> | FieldReadFunction<any>;
  ticketsAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  ticketsByPk?: FieldPolicy<any> | FieldReadFunction<any>;
  ticketsStatus?: FieldPolicy<any> | FieldReadFunction<any>;
  ticketsStatusAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  ticketsStatusByPk?: FieldPolicy<any> | FieldReadFunction<any>;
  ticketsStatusStream?: FieldPolicy<any> | FieldReadFunction<any>;
  ticketsStream?: FieldPolicy<any> | FieldReadFunction<any>;
  tournament?: FieldPolicy<any> | FieldReadFunction<any>;
  tournamentBalances?: FieldPolicy<any> | FieldReadFunction<any>;
  tournamentBalancesAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  tournamentBalancesStream?: FieldPolicy<any> | FieldReadFunction<any>;
  tournamentResultStatuses?: FieldPolicy<any> | FieldReadFunction<any>;
  tournamentResultStatusesAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  tournamentResultStatusesByPk?: FieldPolicy<any> | FieldReadFunction<any>;
  tournamentResultStatusesStream?: FieldPolicy<any> | FieldReadFunction<any>;
  tournamentResults?: FieldPolicy<any> | FieldReadFunction<any>;
  tournamentResultsAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  tournamentResultsByPk?: FieldPolicy<any> | FieldReadFunction<any>;
  tournamentResultsStream?: FieldPolicy<any> | FieldReadFunction<any>;
  tournaments?: FieldPolicy<any> | FieldReadFunction<any>;
  tournamentsAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  tournamentsStream?: FieldPolicy<any> | FieldReadFunction<any>;
  transactionUserTypes?: FieldPolicy<any> | FieldReadFunction<any>;
  transactionUserTypesAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  transactionUserTypesByPk?: FieldPolicy<any> | FieldReadFunction<any>;
  transactionUserTypesStream?: FieldPolicy<any> | FieldReadFunction<any>;
  transactions?: FieldPolicy<any> | FieldReadFunction<any>;
  transactionsAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  transactionsByPk?: FieldPolicy<any> | FieldReadFunction<any>;
  transactionsStream?: FieldPolicy<any> | FieldReadFunction<any>;
  userBalances?: FieldPolicy<any> | FieldReadFunction<any>;
  userBalancesAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  userBalancesStream?: FieldPolicy<any> | FieldReadFunction<any>;
  userShareBuys?: FieldPolicy<any> | FieldReadFunction<any>;
  userShareBuysAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  userShareBuysByPk?: FieldPolicy<any> | FieldReadFunction<any>;
  userShareBuysStream?: FieldPolicy<any> | FieldReadFunction<any>;
  users?: FieldPolicy<any> | FieldReadFunction<any>;
  usersAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  usersByPk?: FieldPolicy<any> | FieldReadFunction<any>;
  usersStream?: FieldPolicy<any> | FieldReadFunction<any>;
  venue?: FieldPolicy<any> | FieldReadFunction<any>;
  venueUsers?: FieldPolicy<any> | FieldReadFunction<any>;
  venueUsersAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  venueUsersByPk?: FieldPolicy<any> | FieldReadFunction<any>;
  venueUsersStream?: FieldPolicy<any> | FieldReadFunction<any>;
  venues?: FieldPolicy<any> | FieldReadFunction<any>;
  venuesAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  venuesStream?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StrictTypedTypePolicies = {
  BlockchainCurrencies?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | BlockchainCurrenciesKeySpecifier
      | (() => undefined | BlockchainCurrenciesKeySpecifier);
    fields?: BlockchainCurrenciesFieldPolicy;
  };
  BlockchainCurrenciesAggregate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | BlockchainCurrenciesAggregateKeySpecifier
      | (() => undefined | BlockchainCurrenciesAggregateKeySpecifier);
    fields?: BlockchainCurrenciesAggregateFieldPolicy;
  };
  BlockchainCurrenciesAggregateFields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | BlockchainCurrenciesAggregateFieldsKeySpecifier
      | (() => undefined | BlockchainCurrenciesAggregateFieldsKeySpecifier);
    fields?: BlockchainCurrenciesAggregateFieldsFieldPolicy;
  };
  BlockchainCurrenciesAvgFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | BlockchainCurrenciesAvgFieldsKeySpecifier
      | (() => undefined | BlockchainCurrenciesAvgFieldsKeySpecifier);
    fields?: BlockchainCurrenciesAvgFieldsFieldPolicy;
  };
  BlockchainCurrenciesMaxFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | BlockchainCurrenciesMaxFieldsKeySpecifier
      | (() => undefined | BlockchainCurrenciesMaxFieldsKeySpecifier);
    fields?: BlockchainCurrenciesMaxFieldsFieldPolicy;
  };
  BlockchainCurrenciesMinFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | BlockchainCurrenciesMinFieldsKeySpecifier
      | (() => undefined | BlockchainCurrenciesMinFieldsKeySpecifier);
    fields?: BlockchainCurrenciesMinFieldsFieldPolicy;
  };
  BlockchainCurrenciesMutationResponse?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | BlockchainCurrenciesMutationResponseKeySpecifier
      | (() => undefined | BlockchainCurrenciesMutationResponseKeySpecifier);
    fields?: BlockchainCurrenciesMutationResponseFieldPolicy;
  };
  BlockchainCurrenciesStddevFields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | BlockchainCurrenciesStddevFieldsKeySpecifier
      | (() => undefined | BlockchainCurrenciesStddevFieldsKeySpecifier);
    fields?: BlockchainCurrenciesStddevFieldsFieldPolicy;
  };
  BlockchainCurrenciesStddev_popFields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | BlockchainCurrenciesStddev_popFieldsKeySpecifier
      | (() => undefined | BlockchainCurrenciesStddev_popFieldsKeySpecifier);
    fields?: BlockchainCurrenciesStddev_popFieldsFieldPolicy;
  };
  BlockchainCurrenciesStddev_sampFields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | BlockchainCurrenciesStddev_sampFieldsKeySpecifier
      | (() => undefined | BlockchainCurrenciesStddev_sampFieldsKeySpecifier);
    fields?: BlockchainCurrenciesStddev_sampFieldsFieldPolicy;
  };
  BlockchainCurrenciesSumFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | BlockchainCurrenciesSumFieldsKeySpecifier
      | (() => undefined | BlockchainCurrenciesSumFieldsKeySpecifier);
    fields?: BlockchainCurrenciesSumFieldsFieldPolicy;
  };
  BlockchainCurrenciesVar_popFields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | BlockchainCurrenciesVar_popFieldsKeySpecifier
      | (() => undefined | BlockchainCurrenciesVar_popFieldsKeySpecifier);
    fields?: BlockchainCurrenciesVar_popFieldsFieldPolicy;
  };
  BlockchainCurrenciesVar_sampFields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | BlockchainCurrenciesVar_sampFieldsKeySpecifier
      | (() => undefined | BlockchainCurrenciesVar_sampFieldsKeySpecifier);
    fields?: BlockchainCurrenciesVar_sampFieldsFieldPolicy;
  };
  BlockchainCurrenciesVarianceFields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | BlockchainCurrenciesVarianceFieldsKeySpecifier
      | (() => undefined | BlockchainCurrenciesVarianceFieldsKeySpecifier);
    fields?: BlockchainCurrenciesVarianceFieldsFieldPolicy;
  };
  Blockchains?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | BlockchainsKeySpecifier
      | (() => undefined | BlockchainsKeySpecifier);
    fields?: BlockchainsFieldPolicy;
  };
  BlockchainsAggregate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | BlockchainsAggregateKeySpecifier
      | (() => undefined | BlockchainsAggregateKeySpecifier);
    fields?: BlockchainsAggregateFieldPolicy;
  };
  BlockchainsAggregateFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | BlockchainsAggregateFieldsKeySpecifier
      | (() => undefined | BlockchainsAggregateFieldsKeySpecifier);
    fields?: BlockchainsAggregateFieldsFieldPolicy;
  };
  BlockchainsAvgFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | BlockchainsAvgFieldsKeySpecifier
      | (() => undefined | BlockchainsAvgFieldsKeySpecifier);
    fields?: BlockchainsAvgFieldsFieldPolicy;
  };
  BlockchainsMaxFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | BlockchainsMaxFieldsKeySpecifier
      | (() => undefined | BlockchainsMaxFieldsKeySpecifier);
    fields?: BlockchainsMaxFieldsFieldPolicy;
  };
  BlockchainsMinFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | BlockchainsMinFieldsKeySpecifier
      | (() => undefined | BlockchainsMinFieldsKeySpecifier);
    fields?: BlockchainsMinFieldsFieldPolicy;
  };
  BlockchainsMutationResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | BlockchainsMutationResponseKeySpecifier
      | (() => undefined | BlockchainsMutationResponseKeySpecifier);
    fields?: BlockchainsMutationResponseFieldPolicy;
  };
  BlockchainsStddevFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | BlockchainsStddevFieldsKeySpecifier
      | (() => undefined | BlockchainsStddevFieldsKeySpecifier);
    fields?: BlockchainsStddevFieldsFieldPolicy;
  };
  BlockchainsStddev_popFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | BlockchainsStddev_popFieldsKeySpecifier
      | (() => undefined | BlockchainsStddev_popFieldsKeySpecifier);
    fields?: BlockchainsStddev_popFieldsFieldPolicy;
  };
  BlockchainsStddev_sampFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | BlockchainsStddev_sampFieldsKeySpecifier
      | (() => undefined | BlockchainsStddev_sampFieldsKeySpecifier);
    fields?: BlockchainsStddev_sampFieldsFieldPolicy;
  };
  BlockchainsSumFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | BlockchainsSumFieldsKeySpecifier
      | (() => undefined | BlockchainsSumFieldsKeySpecifier);
    fields?: BlockchainsSumFieldsFieldPolicy;
  };
  BlockchainsVar_popFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | BlockchainsVar_popFieldsKeySpecifier
      | (() => undefined | BlockchainsVar_popFieldsKeySpecifier);
    fields?: BlockchainsVar_popFieldsFieldPolicy;
  };
  BlockchainsVar_sampFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | BlockchainsVar_sampFieldsKeySpecifier
      | (() => undefined | BlockchainsVar_sampFieldsKeySpecifier);
    fields?: BlockchainsVar_sampFieldsFieldPolicy;
  };
  BlockchainsVarianceFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | BlockchainsVarianceFieldsKeySpecifier
      | (() => undefined | BlockchainsVarianceFieldsKeySpecifier);
    fields?: BlockchainsVarianceFieldsFieldPolicy;
  };
  Countries?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | CountriesKeySpecifier
      | (() => undefined | CountriesKeySpecifier);
    fields?: CountriesFieldPolicy;
  };
  CountriesAggregate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | CountriesAggregateKeySpecifier
      | (() => undefined | CountriesAggregateKeySpecifier);
    fields?: CountriesAggregateFieldPolicy;
  };
  CountriesAggregateFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | CountriesAggregateFieldsKeySpecifier
      | (() => undefined | CountriesAggregateFieldsKeySpecifier);
    fields?: CountriesAggregateFieldsFieldPolicy;
  };
  CountriesAvgFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | CountriesAvgFieldsKeySpecifier
      | (() => undefined | CountriesAvgFieldsKeySpecifier);
    fields?: CountriesAvgFieldsFieldPolicy;
  };
  CountriesMaxFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | CountriesMaxFieldsKeySpecifier
      | (() => undefined | CountriesMaxFieldsKeySpecifier);
    fields?: CountriesMaxFieldsFieldPolicy;
  };
  CountriesMinFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | CountriesMinFieldsKeySpecifier
      | (() => undefined | CountriesMinFieldsKeySpecifier);
    fields?: CountriesMinFieldsFieldPolicy;
  };
  CountriesMutationResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | CountriesMutationResponseKeySpecifier
      | (() => undefined | CountriesMutationResponseKeySpecifier);
    fields?: CountriesMutationResponseFieldPolicy;
  };
  CountriesStddevFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | CountriesStddevFieldsKeySpecifier
      | (() => undefined | CountriesStddevFieldsKeySpecifier);
    fields?: CountriesStddevFieldsFieldPolicy;
  };
  CountriesStddev_popFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | CountriesStddev_popFieldsKeySpecifier
      | (() => undefined | CountriesStddev_popFieldsKeySpecifier);
    fields?: CountriesStddev_popFieldsFieldPolicy;
  };
  CountriesStddev_sampFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | CountriesStddev_sampFieldsKeySpecifier
      | (() => undefined | CountriesStddev_sampFieldsKeySpecifier);
    fields?: CountriesStddev_sampFieldsFieldPolicy;
  };
  CountriesSumFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | CountriesSumFieldsKeySpecifier
      | (() => undefined | CountriesSumFieldsKeySpecifier);
    fields?: CountriesSumFieldsFieldPolicy;
  };
  CountriesVar_popFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | CountriesVar_popFieldsKeySpecifier
      | (() => undefined | CountriesVar_popFieldsKeySpecifier);
    fields?: CountriesVar_popFieldsFieldPolicy;
  };
  CountriesVar_sampFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | CountriesVar_sampFieldsKeySpecifier
      | (() => undefined | CountriesVar_sampFieldsKeySpecifier);
    fields?: CountriesVar_sampFieldsFieldPolicy;
  };
  CountriesVarianceFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | CountriesVarianceFieldsKeySpecifier
      | (() => undefined | CountriesVarianceFieldsKeySpecifier);
    fields?: CountriesVarianceFieldsFieldPolicy;
  };
  Currencies?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | CurrenciesKeySpecifier
      | (() => undefined | CurrenciesKeySpecifier);
    fields?: CurrenciesFieldPolicy;
  };
  CurrenciesAggregate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | CurrenciesAggregateKeySpecifier
      | (() => undefined | CurrenciesAggregateKeySpecifier);
    fields?: CurrenciesAggregateFieldPolicy;
  };
  CurrenciesAggregateFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | CurrenciesAggregateFieldsKeySpecifier
      | (() => undefined | CurrenciesAggregateFieldsKeySpecifier);
    fields?: CurrenciesAggregateFieldsFieldPolicy;
  };
  CurrenciesAvgFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | CurrenciesAvgFieldsKeySpecifier
      | (() => undefined | CurrenciesAvgFieldsKeySpecifier);
    fields?: CurrenciesAvgFieldsFieldPolicy;
  };
  CurrenciesMaxFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | CurrenciesMaxFieldsKeySpecifier
      | (() => undefined | CurrenciesMaxFieldsKeySpecifier);
    fields?: CurrenciesMaxFieldsFieldPolicy;
  };
  CurrenciesMinFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | CurrenciesMinFieldsKeySpecifier
      | (() => undefined | CurrenciesMinFieldsKeySpecifier);
    fields?: CurrenciesMinFieldsFieldPolicy;
  };
  CurrenciesMutationResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | CurrenciesMutationResponseKeySpecifier
      | (() => undefined | CurrenciesMutationResponseKeySpecifier);
    fields?: CurrenciesMutationResponseFieldPolicy;
  };
  CurrenciesStddevFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | CurrenciesStddevFieldsKeySpecifier
      | (() => undefined | CurrenciesStddevFieldsKeySpecifier);
    fields?: CurrenciesStddevFieldsFieldPolicy;
  };
  CurrenciesStddev_popFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | CurrenciesStddev_popFieldsKeySpecifier
      | (() => undefined | CurrenciesStddev_popFieldsKeySpecifier);
    fields?: CurrenciesStddev_popFieldsFieldPolicy;
  };
  CurrenciesStddev_sampFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | CurrenciesStddev_sampFieldsKeySpecifier
      | (() => undefined | CurrenciesStddev_sampFieldsKeySpecifier);
    fields?: CurrenciesStddev_sampFieldsFieldPolicy;
  };
  CurrenciesSumFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | CurrenciesSumFieldsKeySpecifier
      | (() => undefined | CurrenciesSumFieldsKeySpecifier);
    fields?: CurrenciesSumFieldsFieldPolicy;
  };
  CurrenciesVar_popFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | CurrenciesVar_popFieldsKeySpecifier
      | (() => undefined | CurrenciesVar_popFieldsKeySpecifier);
    fields?: CurrenciesVar_popFieldsFieldPolicy;
  };
  CurrenciesVar_sampFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | CurrenciesVar_sampFieldsKeySpecifier
      | (() => undefined | CurrenciesVar_sampFieldsKeySpecifier);
    fields?: CurrenciesVar_sampFieldsFieldPolicy;
  };
  CurrenciesVarianceFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | CurrenciesVarianceFieldsKeySpecifier
      | (() => undefined | CurrenciesVarianceFieldsKeySpecifier);
    fields?: CurrenciesVarianceFieldsFieldPolicy;
  };
  DepositOutput?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | DepositOutputKeySpecifier
      | (() => undefined | DepositOutputKeySpecifier);
    fields?: DepositOutputFieldPolicy;
  };
  Events?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | EventsKeySpecifier
      | (() => undefined | EventsKeySpecifier);
    fields?: EventsFieldPolicy;
  };
  EventsAggregate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | EventsAggregateKeySpecifier
      | (() => undefined | EventsAggregateKeySpecifier);
    fields?: EventsAggregateFieldPolicy;
  };
  EventsAggregateFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | EventsAggregateFieldsKeySpecifier
      | (() => undefined | EventsAggregateFieldsKeySpecifier);
    fields?: EventsAggregateFieldsFieldPolicy;
  };
  EventsAvgFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | EventsAvgFieldsKeySpecifier
      | (() => undefined | EventsAvgFieldsKeySpecifier);
    fields?: EventsAvgFieldsFieldPolicy;
  };
  EventsMaxFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | EventsMaxFieldsKeySpecifier
      | (() => undefined | EventsMaxFieldsKeySpecifier);
    fields?: EventsMaxFieldsFieldPolicy;
  };
  EventsMinFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | EventsMinFieldsKeySpecifier
      | (() => undefined | EventsMinFieldsKeySpecifier);
    fields?: EventsMinFieldsFieldPolicy;
  };
  EventsMutationResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | EventsMutationResponseKeySpecifier
      | (() => undefined | EventsMutationResponseKeySpecifier);
    fields?: EventsMutationResponseFieldPolicy;
  };
  EventsStddevFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | EventsStddevFieldsKeySpecifier
      | (() => undefined | EventsStddevFieldsKeySpecifier);
    fields?: EventsStddevFieldsFieldPolicy;
  };
  EventsStddev_popFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | EventsStddev_popFieldsKeySpecifier
      | (() => undefined | EventsStddev_popFieldsKeySpecifier);
    fields?: EventsStddev_popFieldsFieldPolicy;
  };
  EventsStddev_sampFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | EventsStddev_sampFieldsKeySpecifier
      | (() => undefined | EventsStddev_sampFieldsKeySpecifier);
    fields?: EventsStddev_sampFieldsFieldPolicy;
  };
  EventsSumFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | EventsSumFieldsKeySpecifier
      | (() => undefined | EventsSumFieldsKeySpecifier);
    fields?: EventsSumFieldsFieldPolicy;
  };
  EventsVar_popFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | EventsVar_popFieldsKeySpecifier
      | (() => undefined | EventsVar_popFieldsKeySpecifier);
    fields?: EventsVar_popFieldsFieldPolicy;
  };
  EventsVar_sampFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | EventsVar_sampFieldsKeySpecifier
      | (() => undefined | EventsVar_sampFieldsKeySpecifier);
    fields?: EventsVar_sampFieldsFieldPolicy;
  };
  EventsVarianceFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | EventsVarianceFieldsKeySpecifier
      | (() => undefined | EventsVarianceFieldsKeySpecifier);
    fields?: EventsVarianceFieldsFieldPolicy;
  };
  Followers?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | FollowersKeySpecifier
      | (() => undefined | FollowersKeySpecifier);
    fields?: FollowersFieldPolicy;
  };
  FollowersAggregate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | FollowersAggregateKeySpecifier
      | (() => undefined | FollowersAggregateKeySpecifier);
    fields?: FollowersAggregateFieldPolicy;
  };
  FollowersAggregateFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | FollowersAggregateFieldsKeySpecifier
      | (() => undefined | FollowersAggregateFieldsKeySpecifier);
    fields?: FollowersAggregateFieldsFieldPolicy;
  };
  FollowersAvgFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | FollowersAvgFieldsKeySpecifier
      | (() => undefined | FollowersAvgFieldsKeySpecifier);
    fields?: FollowersAvgFieldsFieldPolicy;
  };
  FollowersMaxFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | FollowersMaxFieldsKeySpecifier
      | (() => undefined | FollowersMaxFieldsKeySpecifier);
    fields?: FollowersMaxFieldsFieldPolicy;
  };
  FollowersMinFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | FollowersMinFieldsKeySpecifier
      | (() => undefined | FollowersMinFieldsKeySpecifier);
    fields?: FollowersMinFieldsFieldPolicy;
  };
  FollowersMutationResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | FollowersMutationResponseKeySpecifier
      | (() => undefined | FollowersMutationResponseKeySpecifier);
    fields?: FollowersMutationResponseFieldPolicy;
  };
  FollowersStddevFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | FollowersStddevFieldsKeySpecifier
      | (() => undefined | FollowersStddevFieldsKeySpecifier);
    fields?: FollowersStddevFieldsFieldPolicy;
  };
  FollowersStddev_popFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | FollowersStddev_popFieldsKeySpecifier
      | (() => undefined | FollowersStddev_popFieldsKeySpecifier);
    fields?: FollowersStddev_popFieldsFieldPolicy;
  };
  FollowersStddev_sampFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | FollowersStddev_sampFieldsKeySpecifier
      | (() => undefined | FollowersStddev_sampFieldsKeySpecifier);
    fields?: FollowersStddev_sampFieldsFieldPolicy;
  };
  FollowersSumFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | FollowersSumFieldsKeySpecifier
      | (() => undefined | FollowersSumFieldsKeySpecifier);
    fields?: FollowersSumFieldsFieldPolicy;
  };
  FollowersVar_popFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | FollowersVar_popFieldsKeySpecifier
      | (() => undefined | FollowersVar_popFieldsKeySpecifier);
    fields?: FollowersVar_popFieldsFieldPolicy;
  };
  FollowersVar_sampFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | FollowersVar_sampFieldsKeySpecifier
      | (() => undefined | FollowersVar_sampFieldsKeySpecifier);
    fields?: FollowersVar_sampFieldsFieldPolicy;
  };
  FollowersVarianceFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | FollowersVarianceFieldsKeySpecifier
      | (() => undefined | FollowersVarianceFieldsKeySpecifier);
    fields?: FollowersVarianceFieldsFieldPolicy;
  };
  MessageTypes?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | MessageTypesKeySpecifier
      | (() => undefined | MessageTypesKeySpecifier);
    fields?: MessageTypesFieldPolicy;
  };
  MessageTypesAggregate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | MessageTypesAggregateKeySpecifier
      | (() => undefined | MessageTypesAggregateKeySpecifier);
    fields?: MessageTypesAggregateFieldPolicy;
  };
  MessageTypesAggregateFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | MessageTypesAggregateFieldsKeySpecifier
      | (() => undefined | MessageTypesAggregateFieldsKeySpecifier);
    fields?: MessageTypesAggregateFieldsFieldPolicy;
  };
  MessageTypesAvgFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | MessageTypesAvgFieldsKeySpecifier
      | (() => undefined | MessageTypesAvgFieldsKeySpecifier);
    fields?: MessageTypesAvgFieldsFieldPolicy;
  };
  MessageTypesMaxFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | MessageTypesMaxFieldsKeySpecifier
      | (() => undefined | MessageTypesMaxFieldsKeySpecifier);
    fields?: MessageTypesMaxFieldsFieldPolicy;
  };
  MessageTypesMinFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | MessageTypesMinFieldsKeySpecifier
      | (() => undefined | MessageTypesMinFieldsKeySpecifier);
    fields?: MessageTypesMinFieldsFieldPolicy;
  };
  MessageTypesMutationResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | MessageTypesMutationResponseKeySpecifier
      | (() => undefined | MessageTypesMutationResponseKeySpecifier);
    fields?: MessageTypesMutationResponseFieldPolicy;
  };
  MessageTypesStddevFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | MessageTypesStddevFieldsKeySpecifier
      | (() => undefined | MessageTypesStddevFieldsKeySpecifier);
    fields?: MessageTypesStddevFieldsFieldPolicy;
  };
  MessageTypesStddev_popFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | MessageTypesStddev_popFieldsKeySpecifier
      | (() => undefined | MessageTypesStddev_popFieldsKeySpecifier);
    fields?: MessageTypesStddev_popFieldsFieldPolicy;
  };
  MessageTypesStddev_sampFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | MessageTypesStddev_sampFieldsKeySpecifier
      | (() => undefined | MessageTypesStddev_sampFieldsKeySpecifier);
    fields?: MessageTypesStddev_sampFieldsFieldPolicy;
  };
  MessageTypesSumFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | MessageTypesSumFieldsKeySpecifier
      | (() => undefined | MessageTypesSumFieldsKeySpecifier);
    fields?: MessageTypesSumFieldsFieldPolicy;
  };
  MessageTypesVar_popFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | MessageTypesVar_popFieldsKeySpecifier
      | (() => undefined | MessageTypesVar_popFieldsKeySpecifier);
    fields?: MessageTypesVar_popFieldsFieldPolicy;
  };
  MessageTypesVar_sampFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | MessageTypesVar_sampFieldsKeySpecifier
      | (() => undefined | MessageTypesVar_sampFieldsKeySpecifier);
    fields?: MessageTypesVar_sampFieldsFieldPolicy;
  };
  MessageTypesVarianceFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | MessageTypesVarianceFieldsKeySpecifier
      | (() => undefined | MessageTypesVarianceFieldsKeySpecifier);
    fields?: MessageTypesVarianceFieldsFieldPolicy;
  };
  Messages?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | MessagesKeySpecifier
      | (() => undefined | MessagesKeySpecifier);
    fields?: MessagesFieldPolicy;
  };
  MessagesAggregate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | MessagesAggregateKeySpecifier
      | (() => undefined | MessagesAggregateKeySpecifier);
    fields?: MessagesAggregateFieldPolicy;
  };
  MessagesAggregateFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | MessagesAggregateFieldsKeySpecifier
      | (() => undefined | MessagesAggregateFieldsKeySpecifier);
    fields?: MessagesAggregateFieldsFieldPolicy;
  };
  MessagesAvgFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | MessagesAvgFieldsKeySpecifier
      | (() => undefined | MessagesAvgFieldsKeySpecifier);
    fields?: MessagesAvgFieldsFieldPolicy;
  };
  MessagesMaxFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | MessagesMaxFieldsKeySpecifier
      | (() => undefined | MessagesMaxFieldsKeySpecifier);
    fields?: MessagesMaxFieldsFieldPolicy;
  };
  MessagesMinFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | MessagesMinFieldsKeySpecifier
      | (() => undefined | MessagesMinFieldsKeySpecifier);
    fields?: MessagesMinFieldsFieldPolicy;
  };
  MessagesMutationResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | MessagesMutationResponseKeySpecifier
      | (() => undefined | MessagesMutationResponseKeySpecifier);
    fields?: MessagesMutationResponseFieldPolicy;
  };
  MessagesStddevFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | MessagesStddevFieldsKeySpecifier
      | (() => undefined | MessagesStddevFieldsKeySpecifier);
    fields?: MessagesStddevFieldsFieldPolicy;
  };
  MessagesStddev_popFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | MessagesStddev_popFieldsKeySpecifier
      | (() => undefined | MessagesStddev_popFieldsKeySpecifier);
    fields?: MessagesStddev_popFieldsFieldPolicy;
  };
  MessagesStddev_sampFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | MessagesStddev_sampFieldsKeySpecifier
      | (() => undefined | MessagesStddev_sampFieldsKeySpecifier);
    fields?: MessagesStddev_sampFieldsFieldPolicy;
  };
  MessagesSumFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | MessagesSumFieldsKeySpecifier
      | (() => undefined | MessagesSumFieldsKeySpecifier);
    fields?: MessagesSumFieldsFieldPolicy;
  };
  MessagesVar_popFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | MessagesVar_popFieldsKeySpecifier
      | (() => undefined | MessagesVar_popFieldsKeySpecifier);
    fields?: MessagesVar_popFieldsFieldPolicy;
  };
  MessagesVar_sampFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | MessagesVar_sampFieldsKeySpecifier
      | (() => undefined | MessagesVar_sampFieldsKeySpecifier);
    fields?: MessagesVar_sampFieldsFieldPolicy;
  };
  MessagesVarianceFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | MessagesVarianceFieldsKeySpecifier
      | (() => undefined | MessagesVarianceFieldsKeySpecifier);
    fields?: MessagesVarianceFieldsFieldPolicy;
  };
  OrganiserUsers?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | OrganiserUsersKeySpecifier
      | (() => undefined | OrganiserUsersKeySpecifier);
    fields?: OrganiserUsersFieldPolicy;
  };
  OrganiserUsersAggregate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | OrganiserUsersAggregateKeySpecifier
      | (() => undefined | OrganiserUsersAggregateKeySpecifier);
    fields?: OrganiserUsersAggregateFieldPolicy;
  };
  OrganiserUsersAggregateFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | OrganiserUsersAggregateFieldsKeySpecifier
      | (() => undefined | OrganiserUsersAggregateFieldsKeySpecifier);
    fields?: OrganiserUsersAggregateFieldsFieldPolicy;
  };
  OrganiserUsersAvgFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | OrganiserUsersAvgFieldsKeySpecifier
      | (() => undefined | OrganiserUsersAvgFieldsKeySpecifier);
    fields?: OrganiserUsersAvgFieldsFieldPolicy;
  };
  OrganiserUsersMaxFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | OrganiserUsersMaxFieldsKeySpecifier
      | (() => undefined | OrganiserUsersMaxFieldsKeySpecifier);
    fields?: OrganiserUsersMaxFieldsFieldPolicy;
  };
  OrganiserUsersMinFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | OrganiserUsersMinFieldsKeySpecifier
      | (() => undefined | OrganiserUsersMinFieldsKeySpecifier);
    fields?: OrganiserUsersMinFieldsFieldPolicy;
  };
  OrganiserUsersMutationResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | OrganiserUsersMutationResponseKeySpecifier
      | (() => undefined | OrganiserUsersMutationResponseKeySpecifier);
    fields?: OrganiserUsersMutationResponseFieldPolicy;
  };
  OrganiserUsersStddevFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | OrganiserUsersStddevFieldsKeySpecifier
      | (() => undefined | OrganiserUsersStddevFieldsKeySpecifier);
    fields?: OrganiserUsersStddevFieldsFieldPolicy;
  };
  OrganiserUsersStddev_popFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | OrganiserUsersStddev_popFieldsKeySpecifier
      | (() => undefined | OrganiserUsersStddev_popFieldsKeySpecifier);
    fields?: OrganiserUsersStddev_popFieldsFieldPolicy;
  };
  OrganiserUsersStddev_sampFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | OrganiserUsersStddev_sampFieldsKeySpecifier
      | (() => undefined | OrganiserUsersStddev_sampFieldsKeySpecifier);
    fields?: OrganiserUsersStddev_sampFieldsFieldPolicy;
  };
  OrganiserUsersSumFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | OrganiserUsersSumFieldsKeySpecifier
      | (() => undefined | OrganiserUsersSumFieldsKeySpecifier);
    fields?: OrganiserUsersSumFieldsFieldPolicy;
  };
  OrganiserUsersVar_popFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | OrganiserUsersVar_popFieldsKeySpecifier
      | (() => undefined | OrganiserUsersVar_popFieldsKeySpecifier);
    fields?: OrganiserUsersVar_popFieldsFieldPolicy;
  };
  OrganiserUsersVar_sampFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | OrganiserUsersVar_sampFieldsKeySpecifier
      | (() => undefined | OrganiserUsersVar_sampFieldsKeySpecifier);
    fields?: OrganiserUsersVar_sampFieldsFieldPolicy;
  };
  OrganiserUsersVarianceFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | OrganiserUsersVarianceFieldsKeySpecifier
      | (() => undefined | OrganiserUsersVarianceFieldsKeySpecifier);
    fields?: OrganiserUsersVarianceFieldsFieldPolicy;
  };
  Organisers?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | OrganisersKeySpecifier
      | (() => undefined | OrganisersKeySpecifier);
    fields?: OrganisersFieldPolicy;
  };
  OrganisersAggregate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | OrganisersAggregateKeySpecifier
      | (() => undefined | OrganisersAggregateKeySpecifier);
    fields?: OrganisersAggregateFieldPolicy;
  };
  OrganisersAggregateFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | OrganisersAggregateFieldsKeySpecifier
      | (() => undefined | OrganisersAggregateFieldsKeySpecifier);
    fields?: OrganisersAggregateFieldsFieldPolicy;
  };
  OrganisersAvgFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | OrganisersAvgFieldsKeySpecifier
      | (() => undefined | OrganisersAvgFieldsKeySpecifier);
    fields?: OrganisersAvgFieldsFieldPolicy;
  };
  OrganisersMaxFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | OrganisersMaxFieldsKeySpecifier
      | (() => undefined | OrganisersMaxFieldsKeySpecifier);
    fields?: OrganisersMaxFieldsFieldPolicy;
  };
  OrganisersMinFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | OrganisersMinFieldsKeySpecifier
      | (() => undefined | OrganisersMinFieldsKeySpecifier);
    fields?: OrganisersMinFieldsFieldPolicy;
  };
  OrganisersMutationResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | OrganisersMutationResponseKeySpecifier
      | (() => undefined | OrganisersMutationResponseKeySpecifier);
    fields?: OrganisersMutationResponseFieldPolicy;
  };
  OrganisersStddevFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | OrganisersStddevFieldsKeySpecifier
      | (() => undefined | OrganisersStddevFieldsKeySpecifier);
    fields?: OrganisersStddevFieldsFieldPolicy;
  };
  OrganisersStddev_popFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | OrganisersStddev_popFieldsKeySpecifier
      | (() => undefined | OrganisersStddev_popFieldsKeySpecifier);
    fields?: OrganisersStddev_popFieldsFieldPolicy;
  };
  OrganisersStddev_sampFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | OrganisersStddev_sampFieldsKeySpecifier
      | (() => undefined | OrganisersStddev_sampFieldsKeySpecifier);
    fields?: OrganisersStddev_sampFieldsFieldPolicy;
  };
  OrganisersSumFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | OrganisersSumFieldsKeySpecifier
      | (() => undefined | OrganisersSumFieldsKeySpecifier);
    fields?: OrganisersSumFieldsFieldPolicy;
  };
  OrganisersVar_popFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | OrganisersVar_popFieldsKeySpecifier
      | (() => undefined | OrganisersVar_popFieldsKeySpecifier);
    fields?: OrganisersVar_popFieldsFieldPolicy;
  };
  OrganisersVar_sampFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | OrganisersVar_sampFieldsKeySpecifier
      | (() => undefined | OrganisersVar_sampFieldsKeySpecifier);
    fields?: OrganisersVar_sampFieldsFieldPolicy;
  };
  OrganisersVarianceFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | OrganisersVarianceFieldsKeySpecifier
      | (() => undefined | OrganisersVarianceFieldsKeySpecifier);
    fields?: OrganisersVarianceFieldsFieldPolicy;
  };
  PaymentMethodBanks?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PaymentMethodBanksKeySpecifier
      | (() => undefined | PaymentMethodBanksKeySpecifier);
    fields?: PaymentMethodBanksFieldPolicy;
  };
  PaymentMethodBanksAggregate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PaymentMethodBanksAggregateKeySpecifier
      | (() => undefined | PaymentMethodBanksAggregateKeySpecifier);
    fields?: PaymentMethodBanksAggregateFieldPolicy;
  };
  PaymentMethodBanksAggregateFields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | PaymentMethodBanksAggregateFieldsKeySpecifier
      | (() => undefined | PaymentMethodBanksAggregateFieldsKeySpecifier);
    fields?: PaymentMethodBanksAggregateFieldsFieldPolicy;
  };
  PaymentMethodBanksAvgFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PaymentMethodBanksAvgFieldsKeySpecifier
      | (() => undefined | PaymentMethodBanksAvgFieldsKeySpecifier);
    fields?: PaymentMethodBanksAvgFieldsFieldPolicy;
  };
  PaymentMethodBanksMaxFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PaymentMethodBanksMaxFieldsKeySpecifier
      | (() => undefined | PaymentMethodBanksMaxFieldsKeySpecifier);
    fields?: PaymentMethodBanksMaxFieldsFieldPolicy;
  };
  PaymentMethodBanksMinFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PaymentMethodBanksMinFieldsKeySpecifier
      | (() => undefined | PaymentMethodBanksMinFieldsKeySpecifier);
    fields?: PaymentMethodBanksMinFieldsFieldPolicy;
  };
  PaymentMethodBanksMutationResponse?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | PaymentMethodBanksMutationResponseKeySpecifier
      | (() => undefined | PaymentMethodBanksMutationResponseKeySpecifier);
    fields?: PaymentMethodBanksMutationResponseFieldPolicy;
  };
  PaymentMethodBanksStddevFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PaymentMethodBanksStddevFieldsKeySpecifier
      | (() => undefined | PaymentMethodBanksStddevFieldsKeySpecifier);
    fields?: PaymentMethodBanksStddevFieldsFieldPolicy;
  };
  PaymentMethodBanksStddev_popFields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | PaymentMethodBanksStddev_popFieldsKeySpecifier
      | (() => undefined | PaymentMethodBanksStddev_popFieldsKeySpecifier);
    fields?: PaymentMethodBanksStddev_popFieldsFieldPolicy;
  };
  PaymentMethodBanksStddev_sampFields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | PaymentMethodBanksStddev_sampFieldsKeySpecifier
      | (() => undefined | PaymentMethodBanksStddev_sampFieldsKeySpecifier);
    fields?: PaymentMethodBanksStddev_sampFieldsFieldPolicy;
  };
  PaymentMethodBanksSumFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PaymentMethodBanksSumFieldsKeySpecifier
      | (() => undefined | PaymentMethodBanksSumFieldsKeySpecifier);
    fields?: PaymentMethodBanksSumFieldsFieldPolicy;
  };
  PaymentMethodBanksVar_popFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PaymentMethodBanksVar_popFieldsKeySpecifier
      | (() => undefined | PaymentMethodBanksVar_popFieldsKeySpecifier);
    fields?: PaymentMethodBanksVar_popFieldsFieldPolicy;
  };
  PaymentMethodBanksVar_sampFields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | PaymentMethodBanksVar_sampFieldsKeySpecifier
      | (() => undefined | PaymentMethodBanksVar_sampFieldsKeySpecifier);
    fields?: PaymentMethodBanksVar_sampFieldsFieldPolicy;
  };
  PaymentMethodBanksVarianceFields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | PaymentMethodBanksVarianceFieldsKeySpecifier
      | (() => undefined | PaymentMethodBanksVarianceFieldsKeySpecifier);
    fields?: PaymentMethodBanksVarianceFieldsFieldPolicy;
  };
  PaymentMethods?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PaymentMethodsKeySpecifier
      | (() => undefined | PaymentMethodsKeySpecifier);
    fields?: PaymentMethodsFieldPolicy;
  };
  PaymentMethodsAggregate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PaymentMethodsAggregateKeySpecifier
      | (() => undefined | PaymentMethodsAggregateKeySpecifier);
    fields?: PaymentMethodsAggregateFieldPolicy;
  };
  PaymentMethodsAggregateFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PaymentMethodsAggregateFieldsKeySpecifier
      | (() => undefined | PaymentMethodsAggregateFieldsKeySpecifier);
    fields?: PaymentMethodsAggregateFieldsFieldPolicy;
  };
  PaymentMethodsAvgFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PaymentMethodsAvgFieldsKeySpecifier
      | (() => undefined | PaymentMethodsAvgFieldsKeySpecifier);
    fields?: PaymentMethodsAvgFieldsFieldPolicy;
  };
  PaymentMethodsMaxFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PaymentMethodsMaxFieldsKeySpecifier
      | (() => undefined | PaymentMethodsMaxFieldsKeySpecifier);
    fields?: PaymentMethodsMaxFieldsFieldPolicy;
  };
  PaymentMethodsMinFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PaymentMethodsMinFieldsKeySpecifier
      | (() => undefined | PaymentMethodsMinFieldsKeySpecifier);
    fields?: PaymentMethodsMinFieldsFieldPolicy;
  };
  PaymentMethodsMutationResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PaymentMethodsMutationResponseKeySpecifier
      | (() => undefined | PaymentMethodsMutationResponseKeySpecifier);
    fields?: PaymentMethodsMutationResponseFieldPolicy;
  };
  PaymentMethodsStddevFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PaymentMethodsStddevFieldsKeySpecifier
      | (() => undefined | PaymentMethodsStddevFieldsKeySpecifier);
    fields?: PaymentMethodsStddevFieldsFieldPolicy;
  };
  PaymentMethodsStddev_popFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PaymentMethodsStddev_popFieldsKeySpecifier
      | (() => undefined | PaymentMethodsStddev_popFieldsKeySpecifier);
    fields?: PaymentMethodsStddev_popFieldsFieldPolicy;
  };
  PaymentMethodsStddev_sampFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PaymentMethodsStddev_sampFieldsKeySpecifier
      | (() => undefined | PaymentMethodsStddev_sampFieldsKeySpecifier);
    fields?: PaymentMethodsStddev_sampFieldsFieldPolicy;
  };
  PaymentMethodsSumFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PaymentMethodsSumFieldsKeySpecifier
      | (() => undefined | PaymentMethodsSumFieldsKeySpecifier);
    fields?: PaymentMethodsSumFieldsFieldPolicy;
  };
  PaymentMethodsVar_popFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PaymentMethodsVar_popFieldsKeySpecifier
      | (() => undefined | PaymentMethodsVar_popFieldsKeySpecifier);
    fields?: PaymentMethodsVar_popFieldsFieldPolicy;
  };
  PaymentMethodsVar_sampFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PaymentMethodsVar_sampFieldsKeySpecifier
      | (() => undefined | PaymentMethodsVar_sampFieldsKeySpecifier);
    fields?: PaymentMethodsVar_sampFieldsFieldPolicy;
  };
  PaymentMethodsVarianceFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PaymentMethodsVarianceFieldsKeySpecifier
      | (() => undefined | PaymentMethodsVarianceFieldsKeySpecifier);
    fields?: PaymentMethodsVarianceFieldsFieldPolicy;
  };
  Payments?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PaymentsKeySpecifier
      | (() => undefined | PaymentsKeySpecifier);
    fields?: PaymentsFieldPolicy;
  };
  PaymentsAggregate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PaymentsAggregateKeySpecifier
      | (() => undefined | PaymentsAggregateKeySpecifier);
    fields?: PaymentsAggregateFieldPolicy;
  };
  PaymentsAggregateFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PaymentsAggregateFieldsKeySpecifier
      | (() => undefined | PaymentsAggregateFieldsKeySpecifier);
    fields?: PaymentsAggregateFieldsFieldPolicy;
  };
  PaymentsAvgFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PaymentsAvgFieldsKeySpecifier
      | (() => undefined | PaymentsAvgFieldsKeySpecifier);
    fields?: PaymentsAvgFieldsFieldPolicy;
  };
  PaymentsMaxFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PaymentsMaxFieldsKeySpecifier
      | (() => undefined | PaymentsMaxFieldsKeySpecifier);
    fields?: PaymentsMaxFieldsFieldPolicy;
  };
  PaymentsMinFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PaymentsMinFieldsKeySpecifier
      | (() => undefined | PaymentsMinFieldsKeySpecifier);
    fields?: PaymentsMinFieldsFieldPolicy;
  };
  PaymentsMutationResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PaymentsMutationResponseKeySpecifier
      | (() => undefined | PaymentsMutationResponseKeySpecifier);
    fields?: PaymentsMutationResponseFieldPolicy;
  };
  PaymentsStddevFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PaymentsStddevFieldsKeySpecifier
      | (() => undefined | PaymentsStddevFieldsKeySpecifier);
    fields?: PaymentsStddevFieldsFieldPolicy;
  };
  PaymentsStddev_popFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PaymentsStddev_popFieldsKeySpecifier
      | (() => undefined | PaymentsStddev_popFieldsKeySpecifier);
    fields?: PaymentsStddev_popFieldsFieldPolicy;
  };
  PaymentsStddev_sampFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PaymentsStddev_sampFieldsKeySpecifier
      | (() => undefined | PaymentsStddev_sampFieldsKeySpecifier);
    fields?: PaymentsStddev_sampFieldsFieldPolicy;
  };
  PaymentsSumFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PaymentsSumFieldsKeySpecifier
      | (() => undefined | PaymentsSumFieldsKeySpecifier);
    fields?: PaymentsSumFieldsFieldPolicy;
  };
  PaymentsVar_popFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PaymentsVar_popFieldsKeySpecifier
      | (() => undefined | PaymentsVar_popFieldsKeySpecifier);
    fields?: PaymentsVar_popFieldsFieldPolicy;
  };
  PaymentsVar_sampFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PaymentsVar_sampFieldsKeySpecifier
      | (() => undefined | PaymentsVar_sampFieldsKeySpecifier);
    fields?: PaymentsVar_sampFieldsFieldPolicy;
  };
  PaymentsVarianceFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PaymentsVarianceFieldsKeySpecifier
      | (() => undefined | PaymentsVarianceFieldsKeySpecifier);
    fields?: PaymentsVarianceFieldsFieldPolicy;
  };
  PendingTxs?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PendingTxsKeySpecifier
      | (() => undefined | PendingTxsKeySpecifier);
    fields?: PendingTxsFieldPolicy;
  };
  PendingTxsAggregate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PendingTxsAggregateKeySpecifier
      | (() => undefined | PendingTxsAggregateKeySpecifier);
    fields?: PendingTxsAggregateFieldPolicy;
  };
  PendingTxsAggregateFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PendingTxsAggregateFieldsKeySpecifier
      | (() => undefined | PendingTxsAggregateFieldsKeySpecifier);
    fields?: PendingTxsAggregateFieldsFieldPolicy;
  };
  PendingTxsAvgFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PendingTxsAvgFieldsKeySpecifier
      | (() => undefined | PendingTxsAvgFieldsKeySpecifier);
    fields?: PendingTxsAvgFieldsFieldPolicy;
  };
  PendingTxsMaxFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PendingTxsMaxFieldsKeySpecifier
      | (() => undefined | PendingTxsMaxFieldsKeySpecifier);
    fields?: PendingTxsMaxFieldsFieldPolicy;
  };
  PendingTxsMinFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PendingTxsMinFieldsKeySpecifier
      | (() => undefined | PendingTxsMinFieldsKeySpecifier);
    fields?: PendingTxsMinFieldsFieldPolicy;
  };
  PendingTxsMutationResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PendingTxsMutationResponseKeySpecifier
      | (() => undefined | PendingTxsMutationResponseKeySpecifier);
    fields?: PendingTxsMutationResponseFieldPolicy;
  };
  PendingTxsStddevFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PendingTxsStddevFieldsKeySpecifier
      | (() => undefined | PendingTxsStddevFieldsKeySpecifier);
    fields?: PendingTxsStddevFieldsFieldPolicy;
  };
  PendingTxsStddev_popFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PendingTxsStddev_popFieldsKeySpecifier
      | (() => undefined | PendingTxsStddev_popFieldsKeySpecifier);
    fields?: PendingTxsStddev_popFieldsFieldPolicy;
  };
  PendingTxsStddev_sampFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PendingTxsStddev_sampFieldsKeySpecifier
      | (() => undefined | PendingTxsStddev_sampFieldsKeySpecifier);
    fields?: PendingTxsStddev_sampFieldsFieldPolicy;
  };
  PendingTxsSumFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PendingTxsSumFieldsKeySpecifier
      | (() => undefined | PendingTxsSumFieldsKeySpecifier);
    fields?: PendingTxsSumFieldsFieldPolicy;
  };
  PendingTxsVar_popFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PendingTxsVar_popFieldsKeySpecifier
      | (() => undefined | PendingTxsVar_popFieldsKeySpecifier);
    fields?: PendingTxsVar_popFieldsFieldPolicy;
  };
  PendingTxsVar_sampFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PendingTxsVar_sampFieldsKeySpecifier
      | (() => undefined | PendingTxsVar_sampFieldsKeySpecifier);
    fields?: PendingTxsVar_sampFieldsFieldPolicy;
  };
  PendingTxsVarianceFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PendingTxsVarianceFieldsKeySpecifier
      | (() => undefined | PendingTxsVarianceFieldsKeySpecifier);
    fields?: PendingTxsVarianceFieldsFieldPolicy;
  };
  PlayerFollowers?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PlayerFollowersKeySpecifier
      | (() => undefined | PlayerFollowersKeySpecifier);
    fields?: PlayerFollowersFieldPolicy;
  };
  PlayerFollowersAggregate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PlayerFollowersAggregateKeySpecifier
      | (() => undefined | PlayerFollowersAggregateKeySpecifier);
    fields?: PlayerFollowersAggregateFieldPolicy;
  };
  PlayerFollowersAggregateFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PlayerFollowersAggregateFieldsKeySpecifier
      | (() => undefined | PlayerFollowersAggregateFieldsKeySpecifier);
    fields?: PlayerFollowersAggregateFieldsFieldPolicy;
  };
  PlayerFollowersAvgFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PlayerFollowersAvgFieldsKeySpecifier
      | (() => undefined | PlayerFollowersAvgFieldsKeySpecifier);
    fields?: PlayerFollowersAvgFieldsFieldPolicy;
  };
  PlayerFollowersMaxFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PlayerFollowersMaxFieldsKeySpecifier
      | (() => undefined | PlayerFollowersMaxFieldsKeySpecifier);
    fields?: PlayerFollowersMaxFieldsFieldPolicy;
  };
  PlayerFollowersMinFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PlayerFollowersMinFieldsKeySpecifier
      | (() => undefined | PlayerFollowersMinFieldsKeySpecifier);
    fields?: PlayerFollowersMinFieldsFieldPolicy;
  };
  PlayerFollowersMutationResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PlayerFollowersMutationResponseKeySpecifier
      | (() => undefined | PlayerFollowersMutationResponseKeySpecifier);
    fields?: PlayerFollowersMutationResponseFieldPolicy;
  };
  PlayerFollowersStddevFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PlayerFollowersStddevFieldsKeySpecifier
      | (() => undefined | PlayerFollowersStddevFieldsKeySpecifier);
    fields?: PlayerFollowersStddevFieldsFieldPolicy;
  };
  PlayerFollowersStddev_popFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PlayerFollowersStddev_popFieldsKeySpecifier
      | (() => undefined | PlayerFollowersStddev_popFieldsKeySpecifier);
    fields?: PlayerFollowersStddev_popFieldsFieldPolicy;
  };
  PlayerFollowersStddev_sampFields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | PlayerFollowersStddev_sampFieldsKeySpecifier
      | (() => undefined | PlayerFollowersStddev_sampFieldsKeySpecifier);
    fields?: PlayerFollowersStddev_sampFieldsFieldPolicy;
  };
  PlayerFollowersSumFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PlayerFollowersSumFieldsKeySpecifier
      | (() => undefined | PlayerFollowersSumFieldsKeySpecifier);
    fields?: PlayerFollowersSumFieldsFieldPolicy;
  };
  PlayerFollowersVar_popFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PlayerFollowersVar_popFieldsKeySpecifier
      | (() => undefined | PlayerFollowersVar_popFieldsKeySpecifier);
    fields?: PlayerFollowersVar_popFieldsFieldPolicy;
  };
  PlayerFollowersVar_sampFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PlayerFollowersVar_sampFieldsKeySpecifier
      | (() => undefined | PlayerFollowersVar_sampFieldsKeySpecifier);
    fields?: PlayerFollowersVar_sampFieldsFieldPolicy;
  };
  PlayerFollowersVarianceFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PlayerFollowersVarianceFieldsKeySpecifier
      | (() => undefined | PlayerFollowersVarianceFieldsKeySpecifier);
    fields?: PlayerFollowersVarianceFieldsFieldPolicy;
  };
  PlayerFollowersView?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PlayerFollowersViewKeySpecifier
      | (() => undefined | PlayerFollowersViewKeySpecifier);
    fields?: PlayerFollowersViewFieldPolicy;
  };
  PlayerFollowersViewAggregate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PlayerFollowersViewAggregateKeySpecifier
      | (() => undefined | PlayerFollowersViewAggregateKeySpecifier);
    fields?: PlayerFollowersViewAggregateFieldPolicy;
  };
  PlayerFollowersViewAggregateFields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | PlayerFollowersViewAggregateFieldsKeySpecifier
      | (() => undefined | PlayerFollowersViewAggregateFieldsKeySpecifier);
    fields?: PlayerFollowersViewAggregateFieldsFieldPolicy;
  };
  PlayerFollowersViewAvgFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PlayerFollowersViewAvgFieldsKeySpecifier
      | (() => undefined | PlayerFollowersViewAvgFieldsKeySpecifier);
    fields?: PlayerFollowersViewAvgFieldsFieldPolicy;
  };
  PlayerFollowersViewMaxFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PlayerFollowersViewMaxFieldsKeySpecifier
      | (() => undefined | PlayerFollowersViewMaxFieldsKeySpecifier);
    fields?: PlayerFollowersViewMaxFieldsFieldPolicy;
  };
  PlayerFollowersViewMinFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PlayerFollowersViewMinFieldsKeySpecifier
      | (() => undefined | PlayerFollowersViewMinFieldsKeySpecifier);
    fields?: PlayerFollowersViewMinFieldsFieldPolicy;
  };
  PlayerFollowersViewStddevFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PlayerFollowersViewStddevFieldsKeySpecifier
      | (() => undefined | PlayerFollowersViewStddevFieldsKeySpecifier);
    fields?: PlayerFollowersViewStddevFieldsFieldPolicy;
  };
  PlayerFollowersViewStddev_popFields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | PlayerFollowersViewStddev_popFieldsKeySpecifier
      | (() => undefined | PlayerFollowersViewStddev_popFieldsKeySpecifier);
    fields?: PlayerFollowersViewStddev_popFieldsFieldPolicy;
  };
  PlayerFollowersViewStddev_sampFields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | PlayerFollowersViewStddev_sampFieldsKeySpecifier
      | (() => undefined | PlayerFollowersViewStddev_sampFieldsKeySpecifier);
    fields?: PlayerFollowersViewStddev_sampFieldsFieldPolicy;
  };
  PlayerFollowersViewSumFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PlayerFollowersViewSumFieldsKeySpecifier
      | (() => undefined | PlayerFollowersViewSumFieldsKeySpecifier);
    fields?: PlayerFollowersViewSumFieldsFieldPolicy;
  };
  PlayerFollowersViewVar_popFields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | PlayerFollowersViewVar_popFieldsKeySpecifier
      | (() => undefined | PlayerFollowersViewVar_popFieldsKeySpecifier);
    fields?: PlayerFollowersViewVar_popFieldsFieldPolicy;
  };
  PlayerFollowersViewVar_sampFields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | PlayerFollowersViewVar_sampFieldsKeySpecifier
      | (() => undefined | PlayerFollowersViewVar_sampFieldsKeySpecifier);
    fields?: PlayerFollowersViewVar_sampFieldsFieldPolicy;
  };
  PlayerFollowersViewVarianceFields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | PlayerFollowersViewVarianceFieldsKeySpecifier
      | (() => undefined | PlayerFollowersViewVarianceFieldsKeySpecifier);
    fields?: PlayerFollowersViewVarianceFieldsFieldPolicy;
  };
  PlayerShares?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PlayerSharesKeySpecifier
      | (() => undefined | PlayerSharesKeySpecifier);
    fields?: PlayerSharesFieldPolicy;
  };
  PlayerSharesAggregate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PlayerSharesAggregateKeySpecifier
      | (() => undefined | PlayerSharesAggregateKeySpecifier);
    fields?: PlayerSharesAggregateFieldPolicy;
  };
  PlayerSharesAggregateFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PlayerSharesAggregateFieldsKeySpecifier
      | (() => undefined | PlayerSharesAggregateFieldsKeySpecifier);
    fields?: PlayerSharesAggregateFieldsFieldPolicy;
  };
  PlayerSharesAvgFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PlayerSharesAvgFieldsKeySpecifier
      | (() => undefined | PlayerSharesAvgFieldsKeySpecifier);
    fields?: PlayerSharesAvgFieldsFieldPolicy;
  };
  PlayerSharesBought?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PlayerSharesBoughtKeySpecifier
      | (() => undefined | PlayerSharesBoughtKeySpecifier);
    fields?: PlayerSharesBoughtFieldPolicy;
  };
  PlayerSharesBoughtAggregate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PlayerSharesBoughtAggregateKeySpecifier
      | (() => undefined | PlayerSharesBoughtAggregateKeySpecifier);
    fields?: PlayerSharesBoughtAggregateFieldPolicy;
  };
  PlayerSharesBoughtAggregateFields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | PlayerSharesBoughtAggregateFieldsKeySpecifier
      | (() => undefined | PlayerSharesBoughtAggregateFieldsKeySpecifier);
    fields?: PlayerSharesBoughtAggregateFieldsFieldPolicy;
  };
  PlayerSharesBoughtAvgFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PlayerSharesBoughtAvgFieldsKeySpecifier
      | (() => undefined | PlayerSharesBoughtAvgFieldsKeySpecifier);
    fields?: PlayerSharesBoughtAvgFieldsFieldPolicy;
  };
  PlayerSharesBoughtMaxFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PlayerSharesBoughtMaxFieldsKeySpecifier
      | (() => undefined | PlayerSharesBoughtMaxFieldsKeySpecifier);
    fields?: PlayerSharesBoughtMaxFieldsFieldPolicy;
  };
  PlayerSharesBoughtMinFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PlayerSharesBoughtMinFieldsKeySpecifier
      | (() => undefined | PlayerSharesBoughtMinFieldsKeySpecifier);
    fields?: PlayerSharesBoughtMinFieldsFieldPolicy;
  };
  PlayerSharesBoughtStddevFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PlayerSharesBoughtStddevFieldsKeySpecifier
      | (() => undefined | PlayerSharesBoughtStddevFieldsKeySpecifier);
    fields?: PlayerSharesBoughtStddevFieldsFieldPolicy;
  };
  PlayerSharesBoughtStddev_popFields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | PlayerSharesBoughtStddev_popFieldsKeySpecifier
      | (() => undefined | PlayerSharesBoughtStddev_popFieldsKeySpecifier);
    fields?: PlayerSharesBoughtStddev_popFieldsFieldPolicy;
  };
  PlayerSharesBoughtStddev_sampFields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | PlayerSharesBoughtStddev_sampFieldsKeySpecifier
      | (() => undefined | PlayerSharesBoughtStddev_sampFieldsKeySpecifier);
    fields?: PlayerSharesBoughtStddev_sampFieldsFieldPolicy;
  };
  PlayerSharesBoughtSumFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PlayerSharesBoughtSumFieldsKeySpecifier
      | (() => undefined | PlayerSharesBoughtSumFieldsKeySpecifier);
    fields?: PlayerSharesBoughtSumFieldsFieldPolicy;
  };
  PlayerSharesBoughtVar_popFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PlayerSharesBoughtVar_popFieldsKeySpecifier
      | (() => undefined | PlayerSharesBoughtVar_popFieldsKeySpecifier);
    fields?: PlayerSharesBoughtVar_popFieldsFieldPolicy;
  };
  PlayerSharesBoughtVar_sampFields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | PlayerSharesBoughtVar_sampFieldsKeySpecifier
      | (() => undefined | PlayerSharesBoughtVar_sampFieldsKeySpecifier);
    fields?: PlayerSharesBoughtVar_sampFieldsFieldPolicy;
  };
  PlayerSharesBoughtVarianceFields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | PlayerSharesBoughtVarianceFieldsKeySpecifier
      | (() => undefined | PlayerSharesBoughtVarianceFieldsKeySpecifier);
    fields?: PlayerSharesBoughtVarianceFieldsFieldPolicy;
  };
  PlayerSharesMaxFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PlayerSharesMaxFieldsKeySpecifier
      | (() => undefined | PlayerSharesMaxFieldsKeySpecifier);
    fields?: PlayerSharesMaxFieldsFieldPolicy;
  };
  PlayerSharesMinFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PlayerSharesMinFieldsKeySpecifier
      | (() => undefined | PlayerSharesMinFieldsKeySpecifier);
    fields?: PlayerSharesMinFieldsFieldPolicy;
  };
  PlayerSharesMutationResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PlayerSharesMutationResponseKeySpecifier
      | (() => undefined | PlayerSharesMutationResponseKeySpecifier);
    fields?: PlayerSharesMutationResponseFieldPolicy;
  };
  PlayerSharesStddevFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PlayerSharesStddevFieldsKeySpecifier
      | (() => undefined | PlayerSharesStddevFieldsKeySpecifier);
    fields?: PlayerSharesStddevFieldsFieldPolicy;
  };
  PlayerSharesStddev_popFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PlayerSharesStddev_popFieldsKeySpecifier
      | (() => undefined | PlayerSharesStddev_popFieldsKeySpecifier);
    fields?: PlayerSharesStddev_popFieldsFieldPolicy;
  };
  PlayerSharesStddev_sampFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PlayerSharesStddev_sampFieldsKeySpecifier
      | (() => undefined | PlayerSharesStddev_sampFieldsKeySpecifier);
    fields?: PlayerSharesStddev_sampFieldsFieldPolicy;
  };
  PlayerSharesSumFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PlayerSharesSumFieldsKeySpecifier
      | (() => undefined | PlayerSharesSumFieldsKeySpecifier);
    fields?: PlayerSharesSumFieldsFieldPolicy;
  };
  PlayerSharesVar_popFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PlayerSharesVar_popFieldsKeySpecifier
      | (() => undefined | PlayerSharesVar_popFieldsKeySpecifier);
    fields?: PlayerSharesVar_popFieldsFieldPolicy;
  };
  PlayerSharesVar_sampFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PlayerSharesVar_sampFieldsKeySpecifier
      | (() => undefined | PlayerSharesVar_sampFieldsKeySpecifier);
    fields?: PlayerSharesVar_sampFieldsFieldPolicy;
  };
  PlayerSharesVarianceFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PlayerSharesVarianceFieldsKeySpecifier
      | (() => undefined | PlayerSharesVarianceFieldsKeySpecifier);
    fields?: PlayerSharesVarianceFieldsFieldPolicy;
  };
  Players?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PlayersKeySpecifier
      | (() => undefined | PlayersKeySpecifier);
    fields?: PlayersFieldPolicy;
  };
  PlayersAggregate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PlayersAggregateKeySpecifier
      | (() => undefined | PlayersAggregateKeySpecifier);
    fields?: PlayersAggregateFieldPolicy;
  };
  PlayersAggregateFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PlayersAggregateFieldsKeySpecifier
      | (() => undefined | PlayersAggregateFieldsKeySpecifier);
    fields?: PlayersAggregateFieldsFieldPolicy;
  };
  PlayersAvgFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PlayersAvgFieldsKeySpecifier
      | (() => undefined | PlayersAvgFieldsKeySpecifier);
    fields?: PlayersAvgFieldsFieldPolicy;
  };
  PlayersMaxFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PlayersMaxFieldsKeySpecifier
      | (() => undefined | PlayersMaxFieldsKeySpecifier);
    fields?: PlayersMaxFieldsFieldPolicy;
  };
  PlayersMinFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PlayersMinFieldsKeySpecifier
      | (() => undefined | PlayersMinFieldsKeySpecifier);
    fields?: PlayersMinFieldsFieldPolicy;
  };
  PlayersMutationResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PlayersMutationResponseKeySpecifier
      | (() => undefined | PlayersMutationResponseKeySpecifier);
    fields?: PlayersMutationResponseFieldPolicy;
  };
  PlayersStddevFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PlayersStddevFieldsKeySpecifier
      | (() => undefined | PlayersStddevFieldsKeySpecifier);
    fields?: PlayersStddevFieldsFieldPolicy;
  };
  PlayersStddev_popFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PlayersStddev_popFieldsKeySpecifier
      | (() => undefined | PlayersStddev_popFieldsKeySpecifier);
    fields?: PlayersStddev_popFieldsFieldPolicy;
  };
  PlayersStddev_sampFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PlayersStddev_sampFieldsKeySpecifier
      | (() => undefined | PlayersStddev_sampFieldsKeySpecifier);
    fields?: PlayersStddev_sampFieldsFieldPolicy;
  };
  PlayersSumFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PlayersSumFieldsKeySpecifier
      | (() => undefined | PlayersSumFieldsKeySpecifier);
    fields?: PlayersSumFieldsFieldPolicy;
  };
  PlayersVar_popFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PlayersVar_popFieldsKeySpecifier
      | (() => undefined | PlayersVar_popFieldsKeySpecifier);
    fields?: PlayersVar_popFieldsFieldPolicy;
  };
  PlayersVar_sampFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PlayersVar_sampFieldsKeySpecifier
      | (() => undefined | PlayersVar_sampFieldsKeySpecifier);
    fields?: PlayersVar_sampFieldsFieldPolicy;
  };
  PlayersVarianceFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PlayersVarianceFieldsKeySpecifier
      | (() => undefined | PlayersVarianceFieldsKeySpecifier);
    fields?: PlayersVarianceFieldsFieldPolicy;
  };
  StatEvents?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | StatEventsKeySpecifier
      | (() => undefined | StatEventsKeySpecifier);
    fields?: StatEventsFieldPolicy;
  };
  StatEventsAggregate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | StatEventsAggregateKeySpecifier
      | (() => undefined | StatEventsAggregateKeySpecifier);
    fields?: StatEventsAggregateFieldPolicy;
  };
  StatEventsAggregateFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | StatEventsAggregateFieldsKeySpecifier
      | (() => undefined | StatEventsAggregateFieldsKeySpecifier);
    fields?: StatEventsAggregateFieldsFieldPolicy;
  };
  StatEventsAvgFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | StatEventsAvgFieldsKeySpecifier
      | (() => undefined | StatEventsAvgFieldsKeySpecifier);
    fields?: StatEventsAvgFieldsFieldPolicy;
  };
  StatEventsMaxFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | StatEventsMaxFieldsKeySpecifier
      | (() => undefined | StatEventsMaxFieldsKeySpecifier);
    fields?: StatEventsMaxFieldsFieldPolicy;
  };
  StatEventsMinFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | StatEventsMinFieldsKeySpecifier
      | (() => undefined | StatEventsMinFieldsKeySpecifier);
    fields?: StatEventsMinFieldsFieldPolicy;
  };
  StatEventsMutationResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | StatEventsMutationResponseKeySpecifier
      | (() => undefined | StatEventsMutationResponseKeySpecifier);
    fields?: StatEventsMutationResponseFieldPolicy;
  };
  StatEventsStddevFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | StatEventsStddevFieldsKeySpecifier
      | (() => undefined | StatEventsStddevFieldsKeySpecifier);
    fields?: StatEventsStddevFieldsFieldPolicy;
  };
  StatEventsStddev_popFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | StatEventsStddev_popFieldsKeySpecifier
      | (() => undefined | StatEventsStddev_popFieldsKeySpecifier);
    fields?: StatEventsStddev_popFieldsFieldPolicy;
  };
  StatEventsStddev_sampFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | StatEventsStddev_sampFieldsKeySpecifier
      | (() => undefined | StatEventsStddev_sampFieldsKeySpecifier);
    fields?: StatEventsStddev_sampFieldsFieldPolicy;
  };
  StatEventsSumFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | StatEventsSumFieldsKeySpecifier
      | (() => undefined | StatEventsSumFieldsKeySpecifier);
    fields?: StatEventsSumFieldsFieldPolicy;
  };
  StatEventsVar_popFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | StatEventsVar_popFieldsKeySpecifier
      | (() => undefined | StatEventsVar_popFieldsKeySpecifier);
    fields?: StatEventsVar_popFieldsFieldPolicy;
  };
  StatEventsVar_sampFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | StatEventsVar_sampFieldsKeySpecifier
      | (() => undefined | StatEventsVar_sampFieldsKeySpecifier);
    fields?: StatEventsVar_sampFieldsFieldPolicy;
  };
  StatEventsVarianceFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | StatEventsVarianceFieldsKeySpecifier
      | (() => undefined | StatEventsVarianceFieldsKeySpecifier);
    fields?: StatEventsVarianceFieldsFieldPolicy;
  };
  StatPlayers?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | StatPlayersKeySpecifier
      | (() => undefined | StatPlayersKeySpecifier);
    fields?: StatPlayersFieldPolicy;
  };
  StatPlayersAggregate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | StatPlayersAggregateKeySpecifier
      | (() => undefined | StatPlayersAggregateKeySpecifier);
    fields?: StatPlayersAggregateFieldPolicy;
  };
  StatPlayersAggregateFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | StatPlayersAggregateFieldsKeySpecifier
      | (() => undefined | StatPlayersAggregateFieldsKeySpecifier);
    fields?: StatPlayersAggregateFieldsFieldPolicy;
  };
  StatPlayersAvgFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | StatPlayersAvgFieldsKeySpecifier
      | (() => undefined | StatPlayersAvgFieldsKeySpecifier);
    fields?: StatPlayersAvgFieldsFieldPolicy;
  };
  StatPlayersMaxFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | StatPlayersMaxFieldsKeySpecifier
      | (() => undefined | StatPlayersMaxFieldsKeySpecifier);
    fields?: StatPlayersMaxFieldsFieldPolicy;
  };
  StatPlayersMinFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | StatPlayersMinFieldsKeySpecifier
      | (() => undefined | StatPlayersMinFieldsKeySpecifier);
    fields?: StatPlayersMinFieldsFieldPolicy;
  };
  StatPlayersMutationResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | StatPlayersMutationResponseKeySpecifier
      | (() => undefined | StatPlayersMutationResponseKeySpecifier);
    fields?: StatPlayersMutationResponseFieldPolicy;
  };
  StatPlayersStddevFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | StatPlayersStddevFieldsKeySpecifier
      | (() => undefined | StatPlayersStddevFieldsKeySpecifier);
    fields?: StatPlayersStddevFieldsFieldPolicy;
  };
  StatPlayersStddev_popFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | StatPlayersStddev_popFieldsKeySpecifier
      | (() => undefined | StatPlayersStddev_popFieldsKeySpecifier);
    fields?: StatPlayersStddev_popFieldsFieldPolicy;
  };
  StatPlayersStddev_sampFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | StatPlayersStddev_sampFieldsKeySpecifier
      | (() => undefined | StatPlayersStddev_sampFieldsKeySpecifier);
    fields?: StatPlayersStddev_sampFieldsFieldPolicy;
  };
  StatPlayersSumFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | StatPlayersSumFieldsKeySpecifier
      | (() => undefined | StatPlayersSumFieldsKeySpecifier);
    fields?: StatPlayersSumFieldsFieldPolicy;
  };
  StatPlayersVar_popFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | StatPlayersVar_popFieldsKeySpecifier
      | (() => undefined | StatPlayersVar_popFieldsKeySpecifier);
    fields?: StatPlayersVar_popFieldsFieldPolicy;
  };
  StatPlayersVar_sampFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | StatPlayersVar_sampFieldsKeySpecifier
      | (() => undefined | StatPlayersVar_sampFieldsKeySpecifier);
    fields?: StatPlayersVar_sampFieldsFieldPolicy;
  };
  StatPlayersVarianceFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | StatPlayersVarianceFieldsKeySpecifier
      | (() => undefined | StatPlayersVarianceFieldsKeySpecifier);
    fields?: StatPlayersVarianceFieldsFieldPolicy;
  };
  StatTopPlayers?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | StatTopPlayersKeySpecifier
      | (() => undefined | StatTopPlayersKeySpecifier);
    fields?: StatTopPlayersFieldPolicy;
  };
  StatTopPlayersAggregate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | StatTopPlayersAggregateKeySpecifier
      | (() => undefined | StatTopPlayersAggregateKeySpecifier);
    fields?: StatTopPlayersAggregateFieldPolicy;
  };
  StatTopPlayersAggregateFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | StatTopPlayersAggregateFieldsKeySpecifier
      | (() => undefined | StatTopPlayersAggregateFieldsKeySpecifier);
    fields?: StatTopPlayersAggregateFieldsFieldPolicy;
  };
  StatTopPlayersAvgFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | StatTopPlayersAvgFieldsKeySpecifier
      | (() => undefined | StatTopPlayersAvgFieldsKeySpecifier);
    fields?: StatTopPlayersAvgFieldsFieldPolicy;
  };
  StatTopPlayersMaxFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | StatTopPlayersMaxFieldsKeySpecifier
      | (() => undefined | StatTopPlayersMaxFieldsKeySpecifier);
    fields?: StatTopPlayersMaxFieldsFieldPolicy;
  };
  StatTopPlayersMinFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | StatTopPlayersMinFieldsKeySpecifier
      | (() => undefined | StatTopPlayersMinFieldsKeySpecifier);
    fields?: StatTopPlayersMinFieldsFieldPolicy;
  };
  StatTopPlayersStddevFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | StatTopPlayersStddevFieldsKeySpecifier
      | (() => undefined | StatTopPlayersStddevFieldsKeySpecifier);
    fields?: StatTopPlayersStddevFieldsFieldPolicy;
  };
  StatTopPlayersStddev_popFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | StatTopPlayersStddev_popFieldsKeySpecifier
      | (() => undefined | StatTopPlayersStddev_popFieldsKeySpecifier);
    fields?: StatTopPlayersStddev_popFieldsFieldPolicy;
  };
  StatTopPlayersStddev_sampFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | StatTopPlayersStddev_sampFieldsKeySpecifier
      | (() => undefined | StatTopPlayersStddev_sampFieldsKeySpecifier);
    fields?: StatTopPlayersStddev_sampFieldsFieldPolicy;
  };
  StatTopPlayersSumFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | StatTopPlayersSumFieldsKeySpecifier
      | (() => undefined | StatTopPlayersSumFieldsKeySpecifier);
    fields?: StatTopPlayersSumFieldsFieldPolicy;
  };
  StatTopPlayersVar_popFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | StatTopPlayersVar_popFieldsKeySpecifier
      | (() => undefined | StatTopPlayersVar_popFieldsKeySpecifier);
    fields?: StatTopPlayersVar_popFieldsFieldPolicy;
  };
  StatTopPlayersVar_sampFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | StatTopPlayersVar_sampFieldsKeySpecifier
      | (() => undefined | StatTopPlayersVar_sampFieldsKeySpecifier);
    fields?: StatTopPlayersVar_sampFieldsFieldPolicy;
  };
  StatTopPlayersVarianceFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | StatTopPlayersVarianceFieldsKeySpecifier
      | (() => undefined | StatTopPlayersVarianceFieldsKeySpecifier);
    fields?: StatTopPlayersVarianceFieldsFieldPolicy;
  };
  StatTopYearPlayers?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | StatTopYearPlayersKeySpecifier
      | (() => undefined | StatTopYearPlayersKeySpecifier);
    fields?: StatTopYearPlayersFieldPolicy;
  };
  StatTopYearPlayersAggregate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | StatTopYearPlayersAggregateKeySpecifier
      | (() => undefined | StatTopYearPlayersAggregateKeySpecifier);
    fields?: StatTopYearPlayersAggregateFieldPolicy;
  };
  StatTopYearPlayersAggregateFields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | StatTopYearPlayersAggregateFieldsKeySpecifier
      | (() => undefined | StatTopYearPlayersAggregateFieldsKeySpecifier);
    fields?: StatTopYearPlayersAggregateFieldsFieldPolicy;
  };
  StatTopYearPlayersAvgFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | StatTopYearPlayersAvgFieldsKeySpecifier
      | (() => undefined | StatTopYearPlayersAvgFieldsKeySpecifier);
    fields?: StatTopYearPlayersAvgFieldsFieldPolicy;
  };
  StatTopYearPlayersMaxFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | StatTopYearPlayersMaxFieldsKeySpecifier
      | (() => undefined | StatTopYearPlayersMaxFieldsKeySpecifier);
    fields?: StatTopYearPlayersMaxFieldsFieldPolicy;
  };
  StatTopYearPlayersMinFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | StatTopYearPlayersMinFieldsKeySpecifier
      | (() => undefined | StatTopYearPlayersMinFieldsKeySpecifier);
    fields?: StatTopYearPlayersMinFieldsFieldPolicy;
  };
  StatTopYearPlayersStddevFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | StatTopYearPlayersStddevFieldsKeySpecifier
      | (() => undefined | StatTopYearPlayersStddevFieldsKeySpecifier);
    fields?: StatTopYearPlayersStddevFieldsFieldPolicy;
  };
  StatTopYearPlayersStddev_popFields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | StatTopYearPlayersStddev_popFieldsKeySpecifier
      | (() => undefined | StatTopYearPlayersStddev_popFieldsKeySpecifier);
    fields?: StatTopYearPlayersStddev_popFieldsFieldPolicy;
  };
  StatTopYearPlayersStddev_sampFields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | StatTopYearPlayersStddev_sampFieldsKeySpecifier
      | (() => undefined | StatTopYearPlayersStddev_sampFieldsKeySpecifier);
    fields?: StatTopYearPlayersStddev_sampFieldsFieldPolicy;
  };
  StatTopYearPlayersSumFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | StatTopYearPlayersSumFieldsKeySpecifier
      | (() => undefined | StatTopYearPlayersSumFieldsKeySpecifier);
    fields?: StatTopYearPlayersSumFieldsFieldPolicy;
  };
  StatTopYearPlayersVar_popFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | StatTopYearPlayersVar_popFieldsKeySpecifier
      | (() => undefined | StatTopYearPlayersVar_popFieldsKeySpecifier);
    fields?: StatTopYearPlayersVar_popFieldsFieldPolicy;
  };
  StatTopYearPlayersVar_sampFields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | StatTopYearPlayersVar_sampFieldsKeySpecifier
      | (() => undefined | StatTopYearPlayersVar_sampFieldsKeySpecifier);
    fields?: StatTopYearPlayersVar_sampFieldsFieldPolicy;
  };
  StatTopYearPlayersVarianceFields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | StatTopYearPlayersVarianceFieldsKeySpecifier
      | (() => undefined | StatTopYearPlayersVarianceFieldsKeySpecifier);
    fields?: StatTopYearPlayersVarianceFieldsFieldPolicy;
  };
  StatTournamentResults?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | StatTournamentResultsKeySpecifier
      | (() => undefined | StatTournamentResultsKeySpecifier);
    fields?: StatTournamentResultsFieldPolicy;
  };
  StatTournamentResultsAggregate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | StatTournamentResultsAggregateKeySpecifier
      | (() => undefined | StatTournamentResultsAggregateKeySpecifier);
    fields?: StatTournamentResultsAggregateFieldPolicy;
  };
  StatTournamentResultsAggregateFields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | StatTournamentResultsAggregateFieldsKeySpecifier
      | (() => undefined | StatTournamentResultsAggregateFieldsKeySpecifier);
    fields?: StatTournamentResultsAggregateFieldsFieldPolicy;
  };
  StatTournamentResultsAvgFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | StatTournamentResultsAvgFieldsKeySpecifier
      | (() => undefined | StatTournamentResultsAvgFieldsKeySpecifier);
    fields?: StatTournamentResultsAvgFieldsFieldPolicy;
  };
  StatTournamentResultsMaxFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | StatTournamentResultsMaxFieldsKeySpecifier
      | (() => undefined | StatTournamentResultsMaxFieldsKeySpecifier);
    fields?: StatTournamentResultsMaxFieldsFieldPolicy;
  };
  StatTournamentResultsMinFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | StatTournamentResultsMinFieldsKeySpecifier
      | (() => undefined | StatTournamentResultsMinFieldsKeySpecifier);
    fields?: StatTournamentResultsMinFieldsFieldPolicy;
  };
  StatTournamentResultsMutationResponse?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | StatTournamentResultsMutationResponseKeySpecifier
      | (() => undefined | StatTournamentResultsMutationResponseKeySpecifier);
    fields?: StatTournamentResultsMutationResponseFieldPolicy;
  };
  StatTournamentResultsStddevFields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | StatTournamentResultsStddevFieldsKeySpecifier
      | (() => undefined | StatTournamentResultsStddevFieldsKeySpecifier);
    fields?: StatTournamentResultsStddevFieldsFieldPolicy;
  };
  StatTournamentResultsStddev_popFields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | StatTournamentResultsStddev_popFieldsKeySpecifier
      | (() => undefined | StatTournamentResultsStddev_popFieldsKeySpecifier);
    fields?: StatTournamentResultsStddev_popFieldsFieldPolicy;
  };
  StatTournamentResultsStddev_sampFields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | StatTournamentResultsStddev_sampFieldsKeySpecifier
      | (() => undefined | StatTournamentResultsStddev_sampFieldsKeySpecifier);
    fields?: StatTournamentResultsStddev_sampFieldsFieldPolicy;
  };
  StatTournamentResultsSumFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | StatTournamentResultsSumFieldsKeySpecifier
      | (() => undefined | StatTournamentResultsSumFieldsKeySpecifier);
    fields?: StatTournamentResultsSumFieldsFieldPolicy;
  };
  StatTournamentResultsVar_popFields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | StatTournamentResultsVar_popFieldsKeySpecifier
      | (() => undefined | StatTournamentResultsVar_popFieldsKeySpecifier);
    fields?: StatTournamentResultsVar_popFieldsFieldPolicy;
  };
  StatTournamentResultsVar_sampFields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | StatTournamentResultsVar_sampFieldsKeySpecifier
      | (() => undefined | StatTournamentResultsVar_sampFieldsKeySpecifier);
    fields?: StatTournamentResultsVar_sampFieldsFieldPolicy;
  };
  StatTournamentResultsVarianceFields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | StatTournamentResultsVarianceFieldsKeySpecifier
      | (() => undefined | StatTournamentResultsVarianceFieldsKeySpecifier);
    fields?: StatTournamentResultsVarianceFieldsFieldPolicy;
  };
  StatTournaments?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | StatTournamentsKeySpecifier
      | (() => undefined | StatTournamentsKeySpecifier);
    fields?: StatTournamentsFieldPolicy;
  };
  StatTournamentsAggregate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | StatTournamentsAggregateKeySpecifier
      | (() => undefined | StatTournamentsAggregateKeySpecifier);
    fields?: StatTournamentsAggregateFieldPolicy;
  };
  StatTournamentsAggregateFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | StatTournamentsAggregateFieldsKeySpecifier
      | (() => undefined | StatTournamentsAggregateFieldsKeySpecifier);
    fields?: StatTournamentsAggregateFieldsFieldPolicy;
  };
  StatTournamentsAvgFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | StatTournamentsAvgFieldsKeySpecifier
      | (() => undefined | StatTournamentsAvgFieldsKeySpecifier);
    fields?: StatTournamentsAvgFieldsFieldPolicy;
  };
  StatTournamentsMaxFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | StatTournamentsMaxFieldsKeySpecifier
      | (() => undefined | StatTournamentsMaxFieldsKeySpecifier);
    fields?: StatTournamentsMaxFieldsFieldPolicy;
  };
  StatTournamentsMinFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | StatTournamentsMinFieldsKeySpecifier
      | (() => undefined | StatTournamentsMinFieldsKeySpecifier);
    fields?: StatTournamentsMinFieldsFieldPolicy;
  };
  StatTournamentsMutationResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | StatTournamentsMutationResponseKeySpecifier
      | (() => undefined | StatTournamentsMutationResponseKeySpecifier);
    fields?: StatTournamentsMutationResponseFieldPolicy;
  };
  StatTournamentsStddevFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | StatTournamentsStddevFieldsKeySpecifier
      | (() => undefined | StatTournamentsStddevFieldsKeySpecifier);
    fields?: StatTournamentsStddevFieldsFieldPolicy;
  };
  StatTournamentsStddev_popFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | StatTournamentsStddev_popFieldsKeySpecifier
      | (() => undefined | StatTournamentsStddev_popFieldsKeySpecifier);
    fields?: StatTournamentsStddev_popFieldsFieldPolicy;
  };
  StatTournamentsStddev_sampFields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | StatTournamentsStddev_sampFieldsKeySpecifier
      | (() => undefined | StatTournamentsStddev_sampFieldsKeySpecifier);
    fields?: StatTournamentsStddev_sampFieldsFieldPolicy;
  };
  StatTournamentsSumFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | StatTournamentsSumFieldsKeySpecifier
      | (() => undefined | StatTournamentsSumFieldsKeySpecifier);
    fields?: StatTournamentsSumFieldsFieldPolicy;
  };
  StatTournamentsVar_popFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | StatTournamentsVar_popFieldsKeySpecifier
      | (() => undefined | StatTournamentsVar_popFieldsKeySpecifier);
    fields?: StatTournamentsVar_popFieldsFieldPolicy;
  };
  StatTournamentsVar_sampFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | StatTournamentsVar_sampFieldsKeySpecifier
      | (() => undefined | StatTournamentsVar_sampFieldsKeySpecifier);
    fields?: StatTournamentsVar_sampFieldsFieldPolicy;
  };
  StatTournamentsVarianceFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | StatTournamentsVarianceFieldsKeySpecifier
      | (() => undefined | StatTournamentsVarianceFieldsKeySpecifier);
    fields?: StatTournamentsVarianceFieldsFieldPolicy;
  };
  StatusGames?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | StatusGamesKeySpecifier
      | (() => undefined | StatusGamesKeySpecifier);
    fields?: StatusGamesFieldPolicy;
  };
  StatusGamesAggregate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | StatusGamesAggregateKeySpecifier
      | (() => undefined | StatusGamesAggregateKeySpecifier);
    fields?: StatusGamesAggregateFieldPolicy;
  };
  StatusGamesAggregateFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | StatusGamesAggregateFieldsKeySpecifier
      | (() => undefined | StatusGamesAggregateFieldsKeySpecifier);
    fields?: StatusGamesAggregateFieldsFieldPolicy;
  };
  StatusGamesMaxFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | StatusGamesMaxFieldsKeySpecifier
      | (() => undefined | StatusGamesMaxFieldsKeySpecifier);
    fields?: StatusGamesMaxFieldsFieldPolicy;
  };
  StatusGamesMinFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | StatusGamesMinFieldsKeySpecifier
      | (() => undefined | StatusGamesMinFieldsKeySpecifier);
    fields?: StatusGamesMinFieldsFieldPolicy;
  };
  StatusGamesMutationResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | StatusGamesMutationResponseKeySpecifier
      | (() => undefined | StatusGamesMutationResponseKeySpecifier);
    fields?: StatusGamesMutationResponseFieldPolicy;
  };
  StatusOutput?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | StatusOutputKeySpecifier
      | (() => undefined | StatusOutputKeySpecifier);
    fields?: StatusOutputFieldPolicy;
  };
  Statuses?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | StatusesKeySpecifier
      | (() => undefined | StatusesKeySpecifier);
    fields?: StatusesFieldPolicy;
  };
  StatusesAggregate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | StatusesAggregateKeySpecifier
      | (() => undefined | StatusesAggregateKeySpecifier);
    fields?: StatusesAggregateFieldPolicy;
  };
  StatusesAggregateFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | StatusesAggregateFieldsKeySpecifier
      | (() => undefined | StatusesAggregateFieldsKeySpecifier);
    fields?: StatusesAggregateFieldsFieldPolicy;
  };
  StatusesMaxFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | StatusesMaxFieldsKeySpecifier
      | (() => undefined | StatusesMaxFieldsKeySpecifier);
    fields?: StatusesMaxFieldsFieldPolicy;
  };
  StatusesMinFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | StatusesMinFieldsKeySpecifier
      | (() => undefined | StatusesMinFieldsKeySpecifier);
    fields?: StatusesMinFieldsFieldPolicy;
  };
  StatusesMutationResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | StatusesMutationResponseKeySpecifier
      | (() => undefined | StatusesMutationResponseKeySpecifier);
    fields?: StatusesMutationResponseFieldPolicy;
  };
  Tickets?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | TicketsKeySpecifier
      | (() => undefined | TicketsKeySpecifier);
    fields?: TicketsFieldPolicy;
  };
  TicketsAggregate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | TicketsAggregateKeySpecifier
      | (() => undefined | TicketsAggregateKeySpecifier);
    fields?: TicketsAggregateFieldPolicy;
  };
  TicketsAggregateFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | TicketsAggregateFieldsKeySpecifier
      | (() => undefined | TicketsAggregateFieldsKeySpecifier);
    fields?: TicketsAggregateFieldsFieldPolicy;
  };
  TicketsAvgFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | TicketsAvgFieldsKeySpecifier
      | (() => undefined | TicketsAvgFieldsKeySpecifier);
    fields?: TicketsAvgFieldsFieldPolicy;
  };
  TicketsMaxFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | TicketsMaxFieldsKeySpecifier
      | (() => undefined | TicketsMaxFieldsKeySpecifier);
    fields?: TicketsMaxFieldsFieldPolicy;
  };
  TicketsMinFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | TicketsMinFieldsKeySpecifier
      | (() => undefined | TicketsMinFieldsKeySpecifier);
    fields?: TicketsMinFieldsFieldPolicy;
  };
  TicketsMutationResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | TicketsMutationResponseKeySpecifier
      | (() => undefined | TicketsMutationResponseKeySpecifier);
    fields?: TicketsMutationResponseFieldPolicy;
  };
  TicketsStatus?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | TicketsStatusKeySpecifier
      | (() => undefined | TicketsStatusKeySpecifier);
    fields?: TicketsStatusFieldPolicy;
  };
  TicketsStatusAggregate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | TicketsStatusAggregateKeySpecifier
      | (() => undefined | TicketsStatusAggregateKeySpecifier);
    fields?: TicketsStatusAggregateFieldPolicy;
  };
  TicketsStatusAggregateFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | TicketsStatusAggregateFieldsKeySpecifier
      | (() => undefined | TicketsStatusAggregateFieldsKeySpecifier);
    fields?: TicketsStatusAggregateFieldsFieldPolicy;
  };
  TicketsStatusMaxFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | TicketsStatusMaxFieldsKeySpecifier
      | (() => undefined | TicketsStatusMaxFieldsKeySpecifier);
    fields?: TicketsStatusMaxFieldsFieldPolicy;
  };
  TicketsStatusMinFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | TicketsStatusMinFieldsKeySpecifier
      | (() => undefined | TicketsStatusMinFieldsKeySpecifier);
    fields?: TicketsStatusMinFieldsFieldPolicy;
  };
  TicketsStatusMutationResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | TicketsStatusMutationResponseKeySpecifier
      | (() => undefined | TicketsStatusMutationResponseKeySpecifier);
    fields?: TicketsStatusMutationResponseFieldPolicy;
  };
  TicketsStddevFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | TicketsStddevFieldsKeySpecifier
      | (() => undefined | TicketsStddevFieldsKeySpecifier);
    fields?: TicketsStddevFieldsFieldPolicy;
  };
  TicketsStddev_popFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | TicketsStddev_popFieldsKeySpecifier
      | (() => undefined | TicketsStddev_popFieldsKeySpecifier);
    fields?: TicketsStddev_popFieldsFieldPolicy;
  };
  TicketsStddev_sampFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | TicketsStddev_sampFieldsKeySpecifier
      | (() => undefined | TicketsStddev_sampFieldsKeySpecifier);
    fields?: TicketsStddev_sampFieldsFieldPolicy;
  };
  TicketsSumFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | TicketsSumFieldsKeySpecifier
      | (() => undefined | TicketsSumFieldsKeySpecifier);
    fields?: TicketsSumFieldsFieldPolicy;
  };
  TicketsVar_popFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | TicketsVar_popFieldsKeySpecifier
      | (() => undefined | TicketsVar_popFieldsKeySpecifier);
    fields?: TicketsVar_popFieldsFieldPolicy;
  };
  TicketsVar_sampFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | TicketsVar_sampFieldsKeySpecifier
      | (() => undefined | TicketsVar_sampFieldsKeySpecifier);
    fields?: TicketsVar_sampFieldsFieldPolicy;
  };
  TicketsVarianceFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | TicketsVarianceFieldsKeySpecifier
      | (() => undefined | TicketsVarianceFieldsKeySpecifier);
    fields?: TicketsVarianceFieldsFieldPolicy;
  };
  TournamentBalances?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | TournamentBalancesKeySpecifier
      | (() => undefined | TournamentBalancesKeySpecifier);
    fields?: TournamentBalancesFieldPolicy;
  };
  TournamentBalancesAggregate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | TournamentBalancesAggregateKeySpecifier
      | (() => undefined | TournamentBalancesAggregateKeySpecifier);
    fields?: TournamentBalancesAggregateFieldPolicy;
  };
  TournamentBalancesAggregateFields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | TournamentBalancesAggregateFieldsKeySpecifier
      | (() => undefined | TournamentBalancesAggregateFieldsKeySpecifier);
    fields?: TournamentBalancesAggregateFieldsFieldPolicy;
  };
  TournamentBalancesAvgFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | TournamentBalancesAvgFieldsKeySpecifier
      | (() => undefined | TournamentBalancesAvgFieldsKeySpecifier);
    fields?: TournamentBalancesAvgFieldsFieldPolicy;
  };
  TournamentBalancesMaxFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | TournamentBalancesMaxFieldsKeySpecifier
      | (() => undefined | TournamentBalancesMaxFieldsKeySpecifier);
    fields?: TournamentBalancesMaxFieldsFieldPolicy;
  };
  TournamentBalancesMinFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | TournamentBalancesMinFieldsKeySpecifier
      | (() => undefined | TournamentBalancesMinFieldsKeySpecifier);
    fields?: TournamentBalancesMinFieldsFieldPolicy;
  };
  TournamentBalancesStddevFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | TournamentBalancesStddevFieldsKeySpecifier
      | (() => undefined | TournamentBalancesStddevFieldsKeySpecifier);
    fields?: TournamentBalancesStddevFieldsFieldPolicy;
  };
  TournamentBalancesStddev_popFields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | TournamentBalancesStddev_popFieldsKeySpecifier
      | (() => undefined | TournamentBalancesStddev_popFieldsKeySpecifier);
    fields?: TournamentBalancesStddev_popFieldsFieldPolicy;
  };
  TournamentBalancesStddev_sampFields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | TournamentBalancesStddev_sampFieldsKeySpecifier
      | (() => undefined | TournamentBalancesStddev_sampFieldsKeySpecifier);
    fields?: TournamentBalancesStddev_sampFieldsFieldPolicy;
  };
  TournamentBalancesSumFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | TournamentBalancesSumFieldsKeySpecifier
      | (() => undefined | TournamentBalancesSumFieldsKeySpecifier);
    fields?: TournamentBalancesSumFieldsFieldPolicy;
  };
  TournamentBalancesVar_popFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | TournamentBalancesVar_popFieldsKeySpecifier
      | (() => undefined | TournamentBalancesVar_popFieldsKeySpecifier);
    fields?: TournamentBalancesVar_popFieldsFieldPolicy;
  };
  TournamentBalancesVar_sampFields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | TournamentBalancesVar_sampFieldsKeySpecifier
      | (() => undefined | TournamentBalancesVar_sampFieldsKeySpecifier);
    fields?: TournamentBalancesVar_sampFieldsFieldPolicy;
  };
  TournamentBalancesVarianceFields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | TournamentBalancesVarianceFieldsKeySpecifier
      | (() => undefined | TournamentBalancesVarianceFieldsKeySpecifier);
    fields?: TournamentBalancesVarianceFieldsFieldPolicy;
  };
  TournamentResultStatuses?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | TournamentResultStatusesKeySpecifier
      | (() => undefined | TournamentResultStatusesKeySpecifier);
    fields?: TournamentResultStatusesFieldPolicy;
  };
  TournamentResultStatusesAggregate?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | TournamentResultStatusesAggregateKeySpecifier
      | (() => undefined | TournamentResultStatusesAggregateKeySpecifier);
    fields?: TournamentResultStatusesAggregateFieldPolicy;
  };
  TournamentResultStatusesAggregateFields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | TournamentResultStatusesAggregateFieldsKeySpecifier
      | (() => undefined | TournamentResultStatusesAggregateFieldsKeySpecifier);
    fields?: TournamentResultStatusesAggregateFieldsFieldPolicy;
  };
  TournamentResultStatusesMaxFields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | TournamentResultStatusesMaxFieldsKeySpecifier
      | (() => undefined | TournamentResultStatusesMaxFieldsKeySpecifier);
    fields?: TournamentResultStatusesMaxFieldsFieldPolicy;
  };
  TournamentResultStatusesMinFields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | TournamentResultStatusesMinFieldsKeySpecifier
      | (() => undefined | TournamentResultStatusesMinFieldsKeySpecifier);
    fields?: TournamentResultStatusesMinFieldsFieldPolicy;
  };
  TournamentResultStatusesMutationResponse?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | TournamentResultStatusesMutationResponseKeySpecifier
      | (() =>
          | undefined
          | TournamentResultStatusesMutationResponseKeySpecifier);
    fields?: TournamentResultStatusesMutationResponseFieldPolicy;
  };
  TournamentResults?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | TournamentResultsKeySpecifier
      | (() => undefined | TournamentResultsKeySpecifier);
    fields?: TournamentResultsFieldPolicy;
  };
  TournamentResultsAggregate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | TournamentResultsAggregateKeySpecifier
      | (() => undefined | TournamentResultsAggregateKeySpecifier);
    fields?: TournamentResultsAggregateFieldPolicy;
  };
  TournamentResultsAggregateFields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | TournamentResultsAggregateFieldsKeySpecifier
      | (() => undefined | TournamentResultsAggregateFieldsKeySpecifier);
    fields?: TournamentResultsAggregateFieldsFieldPolicy;
  };
  TournamentResultsAvgFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | TournamentResultsAvgFieldsKeySpecifier
      | (() => undefined | TournamentResultsAvgFieldsKeySpecifier);
    fields?: TournamentResultsAvgFieldsFieldPolicy;
  };
  TournamentResultsMaxFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | TournamentResultsMaxFieldsKeySpecifier
      | (() => undefined | TournamentResultsMaxFieldsKeySpecifier);
    fields?: TournamentResultsMaxFieldsFieldPolicy;
  };
  TournamentResultsMinFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | TournamentResultsMinFieldsKeySpecifier
      | (() => undefined | TournamentResultsMinFieldsKeySpecifier);
    fields?: TournamentResultsMinFieldsFieldPolicy;
  };
  TournamentResultsMutationResponse?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | TournamentResultsMutationResponseKeySpecifier
      | (() => undefined | TournamentResultsMutationResponseKeySpecifier);
    fields?: TournamentResultsMutationResponseFieldPolicy;
  };
  TournamentResultsStddevFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | TournamentResultsStddevFieldsKeySpecifier
      | (() => undefined | TournamentResultsStddevFieldsKeySpecifier);
    fields?: TournamentResultsStddevFieldsFieldPolicy;
  };
  TournamentResultsStddev_popFields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | TournamentResultsStddev_popFieldsKeySpecifier
      | (() => undefined | TournamentResultsStddev_popFieldsKeySpecifier);
    fields?: TournamentResultsStddev_popFieldsFieldPolicy;
  };
  TournamentResultsStddev_sampFields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | TournamentResultsStddev_sampFieldsKeySpecifier
      | (() => undefined | TournamentResultsStddev_sampFieldsKeySpecifier);
    fields?: TournamentResultsStddev_sampFieldsFieldPolicy;
  };
  TournamentResultsSumFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | TournamentResultsSumFieldsKeySpecifier
      | (() => undefined | TournamentResultsSumFieldsKeySpecifier);
    fields?: TournamentResultsSumFieldsFieldPolicy;
  };
  TournamentResultsVar_popFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | TournamentResultsVar_popFieldsKeySpecifier
      | (() => undefined | TournamentResultsVar_popFieldsKeySpecifier);
    fields?: TournamentResultsVar_popFieldsFieldPolicy;
  };
  TournamentResultsVar_sampFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | TournamentResultsVar_sampFieldsKeySpecifier
      | (() => undefined | TournamentResultsVar_sampFieldsKeySpecifier);
    fields?: TournamentResultsVar_sampFieldsFieldPolicy;
  };
  TournamentResultsVarianceFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | TournamentResultsVarianceFieldsKeySpecifier
      | (() => undefined | TournamentResultsVarianceFieldsKeySpecifier);
    fields?: TournamentResultsVarianceFieldsFieldPolicy;
  };
  Tournaments?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | TournamentsKeySpecifier
      | (() => undefined | TournamentsKeySpecifier);
    fields?: TournamentsFieldPolicy;
  };
  TournamentsAggregate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | TournamentsAggregateKeySpecifier
      | (() => undefined | TournamentsAggregateKeySpecifier);
    fields?: TournamentsAggregateFieldPolicy;
  };
  TournamentsAggregateFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | TournamentsAggregateFieldsKeySpecifier
      | (() => undefined | TournamentsAggregateFieldsKeySpecifier);
    fields?: TournamentsAggregateFieldsFieldPolicy;
  };
  TournamentsAvgFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | TournamentsAvgFieldsKeySpecifier
      | (() => undefined | TournamentsAvgFieldsKeySpecifier);
    fields?: TournamentsAvgFieldsFieldPolicy;
  };
  TournamentsMaxFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | TournamentsMaxFieldsKeySpecifier
      | (() => undefined | TournamentsMaxFieldsKeySpecifier);
    fields?: TournamentsMaxFieldsFieldPolicy;
  };
  TournamentsMinFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | TournamentsMinFieldsKeySpecifier
      | (() => undefined | TournamentsMinFieldsKeySpecifier);
    fields?: TournamentsMinFieldsFieldPolicy;
  };
  TournamentsMutationResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | TournamentsMutationResponseKeySpecifier
      | (() => undefined | TournamentsMutationResponseKeySpecifier);
    fields?: TournamentsMutationResponseFieldPolicy;
  };
  TournamentsStddevFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | TournamentsStddevFieldsKeySpecifier
      | (() => undefined | TournamentsStddevFieldsKeySpecifier);
    fields?: TournamentsStddevFieldsFieldPolicy;
  };
  TournamentsStddev_popFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | TournamentsStddev_popFieldsKeySpecifier
      | (() => undefined | TournamentsStddev_popFieldsKeySpecifier);
    fields?: TournamentsStddev_popFieldsFieldPolicy;
  };
  TournamentsStddev_sampFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | TournamentsStddev_sampFieldsKeySpecifier
      | (() => undefined | TournamentsStddev_sampFieldsKeySpecifier);
    fields?: TournamentsStddev_sampFieldsFieldPolicy;
  };
  TournamentsSumFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | TournamentsSumFieldsKeySpecifier
      | (() => undefined | TournamentsSumFieldsKeySpecifier);
    fields?: TournamentsSumFieldsFieldPolicy;
  };
  TournamentsVar_popFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | TournamentsVar_popFieldsKeySpecifier
      | (() => undefined | TournamentsVar_popFieldsKeySpecifier);
    fields?: TournamentsVar_popFieldsFieldPolicy;
  };
  TournamentsVar_sampFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | TournamentsVar_sampFieldsKeySpecifier
      | (() => undefined | TournamentsVar_sampFieldsKeySpecifier);
    fields?: TournamentsVar_sampFieldsFieldPolicy;
  };
  TournamentsVarianceFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | TournamentsVarianceFieldsKeySpecifier
      | (() => undefined | TournamentsVarianceFieldsKeySpecifier);
    fields?: TournamentsVarianceFieldsFieldPolicy;
  };
  TransactionUserTypes?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | TransactionUserTypesKeySpecifier
      | (() => undefined | TransactionUserTypesKeySpecifier);
    fields?: TransactionUserTypesFieldPolicy;
  };
  TransactionUserTypesAggregate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | TransactionUserTypesAggregateKeySpecifier
      | (() => undefined | TransactionUserTypesAggregateKeySpecifier);
    fields?: TransactionUserTypesAggregateFieldPolicy;
  };
  TransactionUserTypesAggregateFields?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | TransactionUserTypesAggregateFieldsKeySpecifier
      | (() => undefined | TransactionUserTypesAggregateFieldsKeySpecifier);
    fields?: TransactionUserTypesAggregateFieldsFieldPolicy;
  };
  TransactionUserTypesMaxFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | TransactionUserTypesMaxFieldsKeySpecifier
      | (() => undefined | TransactionUserTypesMaxFieldsKeySpecifier);
    fields?: TransactionUserTypesMaxFieldsFieldPolicy;
  };
  TransactionUserTypesMinFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | TransactionUserTypesMinFieldsKeySpecifier
      | (() => undefined | TransactionUserTypesMinFieldsKeySpecifier);
    fields?: TransactionUserTypesMinFieldsFieldPolicy;
  };
  TransactionUserTypesMutationResponse?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | TransactionUserTypesMutationResponseKeySpecifier
      | (() => undefined | TransactionUserTypesMutationResponseKeySpecifier);
    fields?: TransactionUserTypesMutationResponseFieldPolicy;
  };
  Transactions?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | TransactionsKeySpecifier
      | (() => undefined | TransactionsKeySpecifier);
    fields?: TransactionsFieldPolicy;
  };
  TransactionsAggregate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | TransactionsAggregateKeySpecifier
      | (() => undefined | TransactionsAggregateKeySpecifier);
    fields?: TransactionsAggregateFieldPolicy;
  };
  TransactionsAggregateFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | TransactionsAggregateFieldsKeySpecifier
      | (() => undefined | TransactionsAggregateFieldsKeySpecifier);
    fields?: TransactionsAggregateFieldsFieldPolicy;
  };
  TransactionsAvgFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | TransactionsAvgFieldsKeySpecifier
      | (() => undefined | TransactionsAvgFieldsKeySpecifier);
    fields?: TransactionsAvgFieldsFieldPolicy;
  };
  TransactionsMaxFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | TransactionsMaxFieldsKeySpecifier
      | (() => undefined | TransactionsMaxFieldsKeySpecifier);
    fields?: TransactionsMaxFieldsFieldPolicy;
  };
  TransactionsMinFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | TransactionsMinFieldsKeySpecifier
      | (() => undefined | TransactionsMinFieldsKeySpecifier);
    fields?: TransactionsMinFieldsFieldPolicy;
  };
  TransactionsMutationResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | TransactionsMutationResponseKeySpecifier
      | (() => undefined | TransactionsMutationResponseKeySpecifier);
    fields?: TransactionsMutationResponseFieldPolicy;
  };
  TransactionsStddevFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | TransactionsStddevFieldsKeySpecifier
      | (() => undefined | TransactionsStddevFieldsKeySpecifier);
    fields?: TransactionsStddevFieldsFieldPolicy;
  };
  TransactionsStddev_popFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | TransactionsStddev_popFieldsKeySpecifier
      | (() => undefined | TransactionsStddev_popFieldsKeySpecifier);
    fields?: TransactionsStddev_popFieldsFieldPolicy;
  };
  TransactionsStddev_sampFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | TransactionsStddev_sampFieldsKeySpecifier
      | (() => undefined | TransactionsStddev_sampFieldsKeySpecifier);
    fields?: TransactionsStddev_sampFieldsFieldPolicy;
  };
  TransactionsSumFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | TransactionsSumFieldsKeySpecifier
      | (() => undefined | TransactionsSumFieldsKeySpecifier);
    fields?: TransactionsSumFieldsFieldPolicy;
  };
  TransactionsVar_popFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | TransactionsVar_popFieldsKeySpecifier
      | (() => undefined | TransactionsVar_popFieldsKeySpecifier);
    fields?: TransactionsVar_popFieldsFieldPolicy;
  };
  TransactionsVar_sampFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | TransactionsVar_sampFieldsKeySpecifier
      | (() => undefined | TransactionsVar_sampFieldsKeySpecifier);
    fields?: TransactionsVar_sampFieldsFieldPolicy;
  };
  TransactionsVarianceFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | TransactionsVarianceFieldsKeySpecifier
      | (() => undefined | TransactionsVarianceFieldsKeySpecifier);
    fields?: TransactionsVarianceFieldsFieldPolicy;
  };
  UserBalances?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UserBalancesKeySpecifier
      | (() => undefined | UserBalancesKeySpecifier);
    fields?: UserBalancesFieldPolicy;
  };
  UserBalancesAggregate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UserBalancesAggregateKeySpecifier
      | (() => undefined | UserBalancesAggregateKeySpecifier);
    fields?: UserBalancesAggregateFieldPolicy;
  };
  UserBalancesAggregateFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UserBalancesAggregateFieldsKeySpecifier
      | (() => undefined | UserBalancesAggregateFieldsKeySpecifier);
    fields?: UserBalancesAggregateFieldsFieldPolicy;
  };
  UserBalancesAvgFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UserBalancesAvgFieldsKeySpecifier
      | (() => undefined | UserBalancesAvgFieldsKeySpecifier);
    fields?: UserBalancesAvgFieldsFieldPolicy;
  };
  UserBalancesMaxFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UserBalancesMaxFieldsKeySpecifier
      | (() => undefined | UserBalancesMaxFieldsKeySpecifier);
    fields?: UserBalancesMaxFieldsFieldPolicy;
  };
  UserBalancesMinFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UserBalancesMinFieldsKeySpecifier
      | (() => undefined | UserBalancesMinFieldsKeySpecifier);
    fields?: UserBalancesMinFieldsFieldPolicy;
  };
  UserBalancesStddevFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UserBalancesStddevFieldsKeySpecifier
      | (() => undefined | UserBalancesStddevFieldsKeySpecifier);
    fields?: UserBalancesStddevFieldsFieldPolicy;
  };
  UserBalancesStddev_popFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UserBalancesStddev_popFieldsKeySpecifier
      | (() => undefined | UserBalancesStddev_popFieldsKeySpecifier);
    fields?: UserBalancesStddev_popFieldsFieldPolicy;
  };
  UserBalancesStddev_sampFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UserBalancesStddev_sampFieldsKeySpecifier
      | (() => undefined | UserBalancesStddev_sampFieldsKeySpecifier);
    fields?: UserBalancesStddev_sampFieldsFieldPolicy;
  };
  UserBalancesSumFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UserBalancesSumFieldsKeySpecifier
      | (() => undefined | UserBalancesSumFieldsKeySpecifier);
    fields?: UserBalancesSumFieldsFieldPolicy;
  };
  UserBalancesVar_popFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UserBalancesVar_popFieldsKeySpecifier
      | (() => undefined | UserBalancesVar_popFieldsKeySpecifier);
    fields?: UserBalancesVar_popFieldsFieldPolicy;
  };
  UserBalancesVar_sampFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UserBalancesVar_sampFieldsKeySpecifier
      | (() => undefined | UserBalancesVar_sampFieldsKeySpecifier);
    fields?: UserBalancesVar_sampFieldsFieldPolicy;
  };
  UserBalancesVarianceFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UserBalancesVarianceFieldsKeySpecifier
      | (() => undefined | UserBalancesVarianceFieldsKeySpecifier);
    fields?: UserBalancesVarianceFieldsFieldPolicy;
  };
  UserOutput?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UserOutputKeySpecifier
      | (() => undefined | UserOutputKeySpecifier);
    fields?: UserOutputFieldPolicy;
  };
  UserShareBuys?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UserShareBuysKeySpecifier
      | (() => undefined | UserShareBuysKeySpecifier);
    fields?: UserShareBuysFieldPolicy;
  };
  UserShareBuysAggregate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UserShareBuysAggregateKeySpecifier
      | (() => undefined | UserShareBuysAggregateKeySpecifier);
    fields?: UserShareBuysAggregateFieldPolicy;
  };
  UserShareBuysAggregateFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UserShareBuysAggregateFieldsKeySpecifier
      | (() => undefined | UserShareBuysAggregateFieldsKeySpecifier);
    fields?: UserShareBuysAggregateFieldsFieldPolicy;
  };
  UserShareBuysAvgFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UserShareBuysAvgFieldsKeySpecifier
      | (() => undefined | UserShareBuysAvgFieldsKeySpecifier);
    fields?: UserShareBuysAvgFieldsFieldPolicy;
  };
  UserShareBuysMaxFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UserShareBuysMaxFieldsKeySpecifier
      | (() => undefined | UserShareBuysMaxFieldsKeySpecifier);
    fields?: UserShareBuysMaxFieldsFieldPolicy;
  };
  UserShareBuysMinFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UserShareBuysMinFieldsKeySpecifier
      | (() => undefined | UserShareBuysMinFieldsKeySpecifier);
    fields?: UserShareBuysMinFieldsFieldPolicy;
  };
  UserShareBuysMutationResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UserShareBuysMutationResponseKeySpecifier
      | (() => undefined | UserShareBuysMutationResponseKeySpecifier);
    fields?: UserShareBuysMutationResponseFieldPolicy;
  };
  UserShareBuysStddevFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UserShareBuysStddevFieldsKeySpecifier
      | (() => undefined | UserShareBuysStddevFieldsKeySpecifier);
    fields?: UserShareBuysStddevFieldsFieldPolicy;
  };
  UserShareBuysStddev_popFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UserShareBuysStddev_popFieldsKeySpecifier
      | (() => undefined | UserShareBuysStddev_popFieldsKeySpecifier);
    fields?: UserShareBuysStddev_popFieldsFieldPolicy;
  };
  UserShareBuysStddev_sampFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UserShareBuysStddev_sampFieldsKeySpecifier
      | (() => undefined | UserShareBuysStddev_sampFieldsKeySpecifier);
    fields?: UserShareBuysStddev_sampFieldsFieldPolicy;
  };
  UserShareBuysSumFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UserShareBuysSumFieldsKeySpecifier
      | (() => undefined | UserShareBuysSumFieldsKeySpecifier);
    fields?: UserShareBuysSumFieldsFieldPolicy;
  };
  UserShareBuysVar_popFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UserShareBuysVar_popFieldsKeySpecifier
      | (() => undefined | UserShareBuysVar_popFieldsKeySpecifier);
    fields?: UserShareBuysVar_popFieldsFieldPolicy;
  };
  UserShareBuysVar_sampFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UserShareBuysVar_sampFieldsKeySpecifier
      | (() => undefined | UserShareBuysVar_sampFieldsKeySpecifier);
    fields?: UserShareBuysVar_sampFieldsFieldPolicy;
  };
  UserShareBuysVarianceFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UserShareBuysVarianceFieldsKeySpecifier
      | (() => undefined | UserShareBuysVarianceFieldsKeySpecifier);
    fields?: UserShareBuysVarianceFieldsFieldPolicy;
  };
  Users?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UsersKeySpecifier
      | (() => undefined | UsersKeySpecifier);
    fields?: UsersFieldPolicy;
  };
  UsersAggregate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UsersAggregateKeySpecifier
      | (() => undefined | UsersAggregateKeySpecifier);
    fields?: UsersAggregateFieldPolicy;
  };
  UsersAggregateFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UsersAggregateFieldsKeySpecifier
      | (() => undefined | UsersAggregateFieldsKeySpecifier);
    fields?: UsersAggregateFieldsFieldPolicy;
  };
  UsersAvgFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UsersAvgFieldsKeySpecifier
      | (() => undefined | UsersAvgFieldsKeySpecifier);
    fields?: UsersAvgFieldsFieldPolicy;
  };
  UsersMaxFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UsersMaxFieldsKeySpecifier
      | (() => undefined | UsersMaxFieldsKeySpecifier);
    fields?: UsersMaxFieldsFieldPolicy;
  };
  UsersMinFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UsersMinFieldsKeySpecifier
      | (() => undefined | UsersMinFieldsKeySpecifier);
    fields?: UsersMinFieldsFieldPolicy;
  };
  UsersMutationResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UsersMutationResponseKeySpecifier
      | (() => undefined | UsersMutationResponseKeySpecifier);
    fields?: UsersMutationResponseFieldPolicy;
  };
  UsersStddevFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UsersStddevFieldsKeySpecifier
      | (() => undefined | UsersStddevFieldsKeySpecifier);
    fields?: UsersStddevFieldsFieldPolicy;
  };
  UsersStddev_popFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UsersStddev_popFieldsKeySpecifier
      | (() => undefined | UsersStddev_popFieldsKeySpecifier);
    fields?: UsersStddev_popFieldsFieldPolicy;
  };
  UsersStddev_sampFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UsersStddev_sampFieldsKeySpecifier
      | (() => undefined | UsersStddev_sampFieldsKeySpecifier);
    fields?: UsersStddev_sampFieldsFieldPolicy;
  };
  UsersSumFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UsersSumFieldsKeySpecifier
      | (() => undefined | UsersSumFieldsKeySpecifier);
    fields?: UsersSumFieldsFieldPolicy;
  };
  UsersVar_popFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UsersVar_popFieldsKeySpecifier
      | (() => undefined | UsersVar_popFieldsKeySpecifier);
    fields?: UsersVar_popFieldsFieldPolicy;
  };
  UsersVar_sampFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UsersVar_sampFieldsKeySpecifier
      | (() => undefined | UsersVar_sampFieldsKeySpecifier);
    fields?: UsersVar_sampFieldsFieldPolicy;
  };
  UsersVarianceFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UsersVarianceFieldsKeySpecifier
      | (() => undefined | UsersVarianceFieldsKeySpecifier);
    fields?: UsersVarianceFieldsFieldPolicy;
  };
  VenueUsers?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | VenueUsersKeySpecifier
      | (() => undefined | VenueUsersKeySpecifier);
    fields?: VenueUsersFieldPolicy;
  };
  VenueUsersAggregate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | VenueUsersAggregateKeySpecifier
      | (() => undefined | VenueUsersAggregateKeySpecifier);
    fields?: VenueUsersAggregateFieldPolicy;
  };
  VenueUsersAggregateFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | VenueUsersAggregateFieldsKeySpecifier
      | (() => undefined | VenueUsersAggregateFieldsKeySpecifier);
    fields?: VenueUsersAggregateFieldsFieldPolicy;
  };
  VenueUsersAvgFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | VenueUsersAvgFieldsKeySpecifier
      | (() => undefined | VenueUsersAvgFieldsKeySpecifier);
    fields?: VenueUsersAvgFieldsFieldPolicy;
  };
  VenueUsersMaxFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | VenueUsersMaxFieldsKeySpecifier
      | (() => undefined | VenueUsersMaxFieldsKeySpecifier);
    fields?: VenueUsersMaxFieldsFieldPolicy;
  };
  VenueUsersMinFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | VenueUsersMinFieldsKeySpecifier
      | (() => undefined | VenueUsersMinFieldsKeySpecifier);
    fields?: VenueUsersMinFieldsFieldPolicy;
  };
  VenueUsersMutationResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | VenueUsersMutationResponseKeySpecifier
      | (() => undefined | VenueUsersMutationResponseKeySpecifier);
    fields?: VenueUsersMutationResponseFieldPolicy;
  };
  VenueUsersStddevFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | VenueUsersStddevFieldsKeySpecifier
      | (() => undefined | VenueUsersStddevFieldsKeySpecifier);
    fields?: VenueUsersStddevFieldsFieldPolicy;
  };
  VenueUsersStddev_popFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | VenueUsersStddev_popFieldsKeySpecifier
      | (() => undefined | VenueUsersStddev_popFieldsKeySpecifier);
    fields?: VenueUsersStddev_popFieldsFieldPolicy;
  };
  VenueUsersStddev_sampFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | VenueUsersStddev_sampFieldsKeySpecifier
      | (() => undefined | VenueUsersStddev_sampFieldsKeySpecifier);
    fields?: VenueUsersStddev_sampFieldsFieldPolicy;
  };
  VenueUsersSumFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | VenueUsersSumFieldsKeySpecifier
      | (() => undefined | VenueUsersSumFieldsKeySpecifier);
    fields?: VenueUsersSumFieldsFieldPolicy;
  };
  VenueUsersVar_popFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | VenueUsersVar_popFieldsKeySpecifier
      | (() => undefined | VenueUsersVar_popFieldsKeySpecifier);
    fields?: VenueUsersVar_popFieldsFieldPolicy;
  };
  VenueUsersVar_sampFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | VenueUsersVar_sampFieldsKeySpecifier
      | (() => undefined | VenueUsersVar_sampFieldsKeySpecifier);
    fields?: VenueUsersVar_sampFieldsFieldPolicy;
  };
  VenueUsersVarianceFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | VenueUsersVarianceFieldsKeySpecifier
      | (() => undefined | VenueUsersVarianceFieldsKeySpecifier);
    fields?: VenueUsersVarianceFieldsFieldPolicy;
  };
  Venues?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | VenuesKeySpecifier
      | (() => undefined | VenuesKeySpecifier);
    fields?: VenuesFieldPolicy;
  };
  VenuesAggregate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | VenuesAggregateKeySpecifier
      | (() => undefined | VenuesAggregateKeySpecifier);
    fields?: VenuesAggregateFieldPolicy;
  };
  VenuesAggregateFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | VenuesAggregateFieldsKeySpecifier
      | (() => undefined | VenuesAggregateFieldsKeySpecifier);
    fields?: VenuesAggregateFieldsFieldPolicy;
  };
  VenuesAvgFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | VenuesAvgFieldsKeySpecifier
      | (() => undefined | VenuesAvgFieldsKeySpecifier);
    fields?: VenuesAvgFieldsFieldPolicy;
  };
  VenuesMaxFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | VenuesMaxFieldsKeySpecifier
      | (() => undefined | VenuesMaxFieldsKeySpecifier);
    fields?: VenuesMaxFieldsFieldPolicy;
  };
  VenuesMinFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | VenuesMinFieldsKeySpecifier
      | (() => undefined | VenuesMinFieldsKeySpecifier);
    fields?: VenuesMinFieldsFieldPolicy;
  };
  VenuesMutationResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | VenuesMutationResponseKeySpecifier
      | (() => undefined | VenuesMutationResponseKeySpecifier);
    fields?: VenuesMutationResponseFieldPolicy;
  };
  VenuesStddevFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | VenuesStddevFieldsKeySpecifier
      | (() => undefined | VenuesStddevFieldsKeySpecifier);
    fields?: VenuesStddevFieldsFieldPolicy;
  };
  VenuesStddev_popFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | VenuesStddev_popFieldsKeySpecifier
      | (() => undefined | VenuesStddev_popFieldsKeySpecifier);
    fields?: VenuesStddev_popFieldsFieldPolicy;
  };
  VenuesStddev_sampFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | VenuesStddev_sampFieldsKeySpecifier
      | (() => undefined | VenuesStddev_sampFieldsKeySpecifier);
    fields?: VenuesStddev_sampFieldsFieldPolicy;
  };
  VenuesSumFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | VenuesSumFieldsKeySpecifier
      | (() => undefined | VenuesSumFieldsKeySpecifier);
    fields?: VenuesSumFieldsFieldPolicy;
  };
  VenuesVar_popFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | VenuesVar_popFieldsKeySpecifier
      | (() => undefined | VenuesVar_popFieldsKeySpecifier);
    fields?: VenuesVar_popFieldsFieldPolicy;
  };
  VenuesVar_sampFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | VenuesVar_sampFieldsKeySpecifier
      | (() => undefined | VenuesVar_sampFieldsKeySpecifier);
    fields?: VenuesVar_sampFieldsFieldPolicy;
  };
  VenuesVarianceFields?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | VenuesVarianceFieldsKeySpecifier
      | (() => undefined | VenuesVarianceFieldsKeySpecifier);
    fields?: VenuesVarianceFieldsFieldPolicy;
  };
  activate2FAOutput?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | activate2FAOutputKeySpecifier
      | (() => undefined | activate2FAOutputKeySpecifier);
    fields?: activate2FAOutputFieldPolicy;
  };
  authLoginOutput?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | authLoginOutputKeySpecifier
      | (() => undefined | authLoginOutputKeySpecifier);
    fields?: authLoginOutputFieldPolicy;
  };
  authSignatureOutput?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | authSignatureOutputKeySpecifier
      | (() => undefined | authSignatureOutputKeySpecifier);
    fields?: authSignatureOutputFieldPolicy;
  };
  depositAddressOutput?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | depositAddressOutputKeySpecifier
      | (() => undefined | depositAddressOutputKeySpecifier);
    fields?: depositAddressOutputFieldPolicy;
  };
  depositBankOutput?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | depositBankOutputKeySpecifier
      | (() => undefined | depositBankOutputKeySpecifier);
    fields?: depositBankOutputFieldPolicy;
  };
  downloadFileOutput?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | downloadFileOutputKeySpecifier
      | (() => undefined | downloadFileOutputKeySpecifier);
    fields?: downloadFileOutputFieldPolicy;
  };
  generalOutput?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | generalOutputKeySpecifier
      | (() => undefined | generalOutputKeySpecifier);
    fields?: generalOutputFieldPolicy;
  };
  mutation_root?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | mutation_rootKeySpecifier
      | (() => undefined | mutation_rootKeySpecifier);
    fields?: mutation_rootFieldPolicy;
  };
  playerShareResultOutput?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | playerShareResultOutputKeySpecifier
      | (() => undefined | playerShareResultOutputKeySpecifier);
    fields?: playerShareResultOutputFieldPolicy;
  };
  query_root?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | query_rootKeySpecifier
      | (() => undefined | query_rootKeySpecifier);
    fields?: query_rootFieldPolicy;
  };
  subscription_root?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | subscription_rootKeySpecifier
      | (() => undefined | subscription_rootKeySpecifier);
    fields?: subscription_rootFieldPolicy;
  };
};
export type TypedTypePolicies = StrictTypedTypePolicies & TypePolicies;

export type ResolverTypeWrapper<T> = Promise<T> | T;

export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> =
  | ResolverFn<TResult, TParent, TContext, TArgs>
  | ResolverWithResolve<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<
  TResult,
  TKey extends string,
  TParent,
  TContext,
  TArgs
> {
  subscribe: SubscriptionSubscribeFn<
    { [key in TKey]: TResult },
    TParent,
    TContext,
    TArgs
  >;
  resolve?: SubscriptionResolveFn<
    TResult,
    { [key in TKey]: TResult },
    TContext,
    TArgs
  >;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<
  TResult,
  TKey extends string,
  TParent,
  TContext,
  TArgs
> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<
  TResult,
  TKey extends string,
  TParent = {},
  TContext = {},
  TArgs = {}
> =
  | ((
      ...args: any[]
    ) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (
  obj: T,
  context: TContext,
  info: GraphQLResolveInfo
) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<
  TResult = {},
  TParent = {},
  TContext = {},
  TArgs = {}
> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  BigintComparisonExp: BigintComparisonExp;
  BlockchainCurrencies: ResolverTypeWrapper<BlockchainCurrencies>;
  BlockchainCurrenciesAggregate: ResolverTypeWrapper<BlockchainCurrenciesAggregate>;
  BlockchainCurrenciesAggregateFields: ResolverTypeWrapper<BlockchainCurrenciesAggregateFields>;
  BlockchainCurrenciesAggregateOrderBy: BlockchainCurrenciesAggregateOrderBy;
  BlockchainCurrenciesArrRelInsertInput: BlockchainCurrenciesArrRelInsertInput;
  BlockchainCurrenciesAvgFields: ResolverTypeWrapper<BlockchainCurrenciesAvgFields>;
  BlockchainCurrenciesBoolExp: BlockchainCurrenciesBoolExp;
  BlockchainCurrenciesConstraint: BlockchainCurrenciesConstraint;
  BlockchainCurrenciesIncInput: BlockchainCurrenciesIncInput;
  BlockchainCurrenciesInsertInput: BlockchainCurrenciesInsertInput;
  BlockchainCurrenciesMaxFields: ResolverTypeWrapper<BlockchainCurrenciesMaxFields>;
  BlockchainCurrenciesMinFields: ResolverTypeWrapper<BlockchainCurrenciesMinFields>;
  BlockchainCurrenciesMutationResponse: ResolverTypeWrapper<BlockchainCurrenciesMutationResponse>;
  BlockchainCurrenciesObjRelInsertInput: BlockchainCurrenciesObjRelInsertInput;
  BlockchainCurrenciesOnConflict: BlockchainCurrenciesOnConflict;
  BlockchainCurrenciesOrderBy: BlockchainCurrenciesOrderBy;
  BlockchainCurrenciesPkColumnsInput: BlockchainCurrenciesPkColumnsInput;
  BlockchainCurrenciesSelectColumn: BlockchainCurrenciesSelectColumn;
  BlockchainCurrenciesSetInput: BlockchainCurrenciesSetInput;
  BlockchainCurrenciesStddevFields: ResolverTypeWrapper<BlockchainCurrenciesStddevFields>;
  BlockchainCurrenciesStddev_popFields: ResolverTypeWrapper<BlockchainCurrenciesStddev_PopFields>;
  BlockchainCurrenciesStddev_sampFields: ResolverTypeWrapper<BlockchainCurrenciesStddev_SampFields>;
  BlockchainCurrenciesStreamCursorInput: BlockchainCurrenciesStreamCursorInput;
  BlockchainCurrenciesStreamCursorValueInput: BlockchainCurrenciesStreamCursorValueInput;
  BlockchainCurrenciesSumFields: ResolverTypeWrapper<BlockchainCurrenciesSumFields>;
  BlockchainCurrenciesUpdateColumn: BlockchainCurrenciesUpdateColumn;
  BlockchainCurrenciesUpdates: BlockchainCurrenciesUpdates;
  BlockchainCurrenciesVar_popFields: ResolverTypeWrapper<BlockchainCurrenciesVar_PopFields>;
  BlockchainCurrenciesVar_sampFields: ResolverTypeWrapper<BlockchainCurrenciesVar_SampFields>;
  BlockchainCurrenciesVarianceFields: ResolverTypeWrapper<BlockchainCurrenciesVarianceFields>;
  Blockchains: ResolverTypeWrapper<Blockchains>;
  BlockchainsAggregate: ResolverTypeWrapper<BlockchainsAggregate>;
  BlockchainsAggregateFields: ResolverTypeWrapper<BlockchainsAggregateFields>;
  BlockchainsAppendInput: BlockchainsAppendInput;
  BlockchainsAvgFields: ResolverTypeWrapper<BlockchainsAvgFields>;
  BlockchainsBoolExp: BlockchainsBoolExp;
  BlockchainsConstraint: BlockchainsConstraint;
  BlockchainsDeleteAtPathInput: BlockchainsDeleteAtPathInput;
  BlockchainsDeleteElemInput: BlockchainsDeleteElemInput;
  BlockchainsDeleteKeyInput: BlockchainsDeleteKeyInput;
  BlockchainsIncInput: BlockchainsIncInput;
  BlockchainsInsertInput: BlockchainsInsertInput;
  BlockchainsMaxFields: ResolverTypeWrapper<BlockchainsMaxFields>;
  BlockchainsMinFields: ResolverTypeWrapper<BlockchainsMinFields>;
  BlockchainsMutationResponse: ResolverTypeWrapper<BlockchainsMutationResponse>;
  BlockchainsObjRelInsertInput: BlockchainsObjRelInsertInput;
  BlockchainsOnConflict: BlockchainsOnConflict;
  BlockchainsOrderBy: BlockchainsOrderBy;
  BlockchainsPkColumnsInput: BlockchainsPkColumnsInput;
  BlockchainsPrependInput: BlockchainsPrependInput;
  BlockchainsSelectColumn: BlockchainsSelectColumn;
  BlockchainsSetInput: BlockchainsSetInput;
  BlockchainsStddevFields: ResolverTypeWrapper<BlockchainsStddevFields>;
  BlockchainsStddev_popFields: ResolverTypeWrapper<BlockchainsStddev_PopFields>;
  BlockchainsStddev_sampFields: ResolverTypeWrapper<BlockchainsStddev_SampFields>;
  BlockchainsStreamCursorInput: BlockchainsStreamCursorInput;
  BlockchainsStreamCursorValueInput: BlockchainsStreamCursorValueInput;
  BlockchainsSumFields: ResolverTypeWrapper<BlockchainsSumFields>;
  BlockchainsUpdateColumn: BlockchainsUpdateColumn;
  BlockchainsUpdates: BlockchainsUpdates;
  BlockchainsVar_popFields: ResolverTypeWrapper<BlockchainsVar_PopFields>;
  BlockchainsVar_sampFields: ResolverTypeWrapper<BlockchainsVar_SampFields>;
  BlockchainsVarianceFields: ResolverTypeWrapper<BlockchainsVarianceFields>;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']['output']>;
  BooleanComparisonExp: BooleanComparisonExp;
  Countries: ResolverTypeWrapper<Countries>;
  CountriesAggregate: ResolverTypeWrapper<CountriesAggregate>;
  CountriesAggregateFields: ResolverTypeWrapper<CountriesAggregateFields>;
  CountriesAvgFields: ResolverTypeWrapper<CountriesAvgFields>;
  CountriesBoolExp: CountriesBoolExp;
  CountriesConstraint: CountriesConstraint;
  CountriesIncInput: CountriesIncInput;
  CountriesInsertInput: CountriesInsertInput;
  CountriesMaxFields: ResolverTypeWrapper<CountriesMaxFields>;
  CountriesMinFields: ResolverTypeWrapper<CountriesMinFields>;
  CountriesMutationResponse: ResolverTypeWrapper<CountriesMutationResponse>;
  CountriesObjRelInsertInput: CountriesObjRelInsertInput;
  CountriesOnConflict: CountriesOnConflict;
  CountriesOrderBy: CountriesOrderBy;
  CountriesPkColumnsInput: CountriesPkColumnsInput;
  CountriesSelectColumn: CountriesSelectColumn;
  CountriesSetInput: CountriesSetInput;
  CountriesStddevFields: ResolverTypeWrapper<CountriesStddevFields>;
  CountriesStddev_popFields: ResolverTypeWrapper<CountriesStddev_PopFields>;
  CountriesStddev_sampFields: ResolverTypeWrapper<CountriesStddev_SampFields>;
  CountriesStreamCursorInput: CountriesStreamCursorInput;
  CountriesStreamCursorValueInput: CountriesStreamCursorValueInput;
  CountriesSumFields: ResolverTypeWrapper<CountriesSumFields>;
  CountriesUpdateColumn: CountriesUpdateColumn;
  CountriesUpdates: CountriesUpdates;
  CountriesVar_popFields: ResolverTypeWrapper<CountriesVar_PopFields>;
  CountriesVar_sampFields: ResolverTypeWrapper<CountriesVar_SampFields>;
  CountriesVarianceFields: ResolverTypeWrapper<CountriesVarianceFields>;
  Currencies: ResolverTypeWrapper<Currencies>;
  CurrenciesAggregate: ResolverTypeWrapper<CurrenciesAggregate>;
  CurrenciesAggregateFields: ResolverTypeWrapper<CurrenciesAggregateFields>;
  CurrenciesAggregateOrderBy: CurrenciesAggregateOrderBy;
  CurrenciesArrRelInsertInput: CurrenciesArrRelInsertInput;
  CurrenciesAvgFields: ResolverTypeWrapper<CurrenciesAvgFields>;
  CurrenciesBoolExp: CurrenciesBoolExp;
  CurrenciesConstraint: CurrenciesConstraint;
  CurrenciesIncInput: CurrenciesIncInput;
  CurrenciesInsertInput: CurrenciesInsertInput;
  CurrenciesMaxFields: ResolverTypeWrapper<CurrenciesMaxFields>;
  CurrenciesMinFields: ResolverTypeWrapper<CurrenciesMinFields>;
  CurrenciesMutationResponse: ResolverTypeWrapper<CurrenciesMutationResponse>;
  CurrenciesObjRelInsertInput: CurrenciesObjRelInsertInput;
  CurrenciesOnConflict: CurrenciesOnConflict;
  CurrenciesOrderBy: CurrenciesOrderBy;
  CurrenciesPkColumnsInput: CurrenciesPkColumnsInput;
  CurrenciesSelectColumn: CurrenciesSelectColumn;
  CurrenciesSetInput: CurrenciesSetInput;
  CurrenciesStddevFields: ResolverTypeWrapper<CurrenciesStddevFields>;
  CurrenciesStddev_popFields: ResolverTypeWrapper<CurrenciesStddev_PopFields>;
  CurrenciesStddev_sampFields: ResolverTypeWrapper<CurrenciesStddev_SampFields>;
  CurrenciesStreamCursorInput: CurrenciesStreamCursorInput;
  CurrenciesStreamCursorValueInput: CurrenciesStreamCursorValueInput;
  CurrenciesSumFields: ResolverTypeWrapper<CurrenciesSumFields>;
  CurrenciesUpdateColumn: CurrenciesUpdateColumn;
  CurrenciesUpdates: CurrenciesUpdates;
  CurrenciesVar_popFields: ResolverTypeWrapper<CurrenciesVar_PopFields>;
  CurrenciesVar_sampFields: ResolverTypeWrapper<CurrenciesVar_SampFields>;
  CurrenciesVarianceFields: ResolverTypeWrapper<CurrenciesVarianceFields>;
  CursorOrdering: CursorOrdering;
  DateComparisonExp: DateComparisonExp;
  DepositOutput: ResolverTypeWrapper<DepositOutput>;
  Events: ResolverTypeWrapper<Events>;
  EventsAggregate: ResolverTypeWrapper<EventsAggregate>;
  EventsAggregateFields: ResolverTypeWrapper<EventsAggregateFields>;
  EventsAggregateOrderBy: EventsAggregateOrderBy;
  EventsArrRelInsertInput: EventsArrRelInsertInput;
  EventsAvgFields: ResolverTypeWrapper<EventsAvgFields>;
  EventsBoolExp: EventsBoolExp;
  EventsConstraint: EventsConstraint;
  EventsIncInput: EventsIncInput;
  EventsInsertInput: EventsInsertInput;
  EventsMaxFields: ResolverTypeWrapper<EventsMaxFields>;
  EventsMinFields: ResolverTypeWrapper<EventsMinFields>;
  EventsMutationResponse: ResolverTypeWrapper<EventsMutationResponse>;
  EventsObjRelInsertInput: EventsObjRelInsertInput;
  EventsOnConflict: EventsOnConflict;
  EventsOrderBy: EventsOrderBy;
  EventsPkColumnsInput: EventsPkColumnsInput;
  EventsSelectColumn: EventsSelectColumn;
  EventsSetInput: EventsSetInput;
  EventsStddevFields: ResolverTypeWrapper<EventsStddevFields>;
  EventsStddev_popFields: ResolverTypeWrapper<EventsStddev_PopFields>;
  EventsStddev_sampFields: ResolverTypeWrapper<EventsStddev_SampFields>;
  EventsStreamCursorInput: EventsStreamCursorInput;
  EventsStreamCursorValueInput: EventsStreamCursorValueInput;
  EventsSumFields: ResolverTypeWrapper<EventsSumFields>;
  EventsUpdateColumn: EventsUpdateColumn;
  EventsUpdates: EventsUpdates;
  EventsVar_popFields: ResolverTypeWrapper<EventsVar_PopFields>;
  EventsVar_sampFields: ResolverTypeWrapper<EventsVar_SampFields>;
  EventsVarianceFields: ResolverTypeWrapper<EventsVarianceFields>;
  Float: ResolverTypeWrapper<Scalars['Float']['output']>;
  Followers: ResolverTypeWrapper<Followers>;
  FollowersAggregate: ResolverTypeWrapper<FollowersAggregate>;
  FollowersAggregateFields: ResolverTypeWrapper<FollowersAggregateFields>;
  FollowersAggregateOrderBy: FollowersAggregateOrderBy;
  FollowersArrRelInsertInput: FollowersArrRelInsertInput;
  FollowersAvgFields: ResolverTypeWrapper<FollowersAvgFields>;
  FollowersBoolExp: FollowersBoolExp;
  FollowersConstraint: FollowersConstraint;
  FollowersIncInput: FollowersIncInput;
  FollowersInsertInput: FollowersInsertInput;
  FollowersMaxFields: ResolverTypeWrapper<FollowersMaxFields>;
  FollowersMinFields: ResolverTypeWrapper<FollowersMinFields>;
  FollowersMutationResponse: ResolverTypeWrapper<FollowersMutationResponse>;
  FollowersOnConflict: FollowersOnConflict;
  FollowersOrderBy: FollowersOrderBy;
  FollowersPkColumnsInput: FollowersPkColumnsInput;
  FollowersSelectColumn: FollowersSelectColumn;
  FollowersSetInput: FollowersSetInput;
  FollowersStddevFields: ResolverTypeWrapper<FollowersStddevFields>;
  FollowersStddev_popFields: ResolverTypeWrapper<FollowersStddev_PopFields>;
  FollowersStddev_sampFields: ResolverTypeWrapper<FollowersStddev_SampFields>;
  FollowersStreamCursorInput: FollowersStreamCursorInput;
  FollowersStreamCursorValueInput: FollowersStreamCursorValueInput;
  FollowersSumFields: ResolverTypeWrapper<FollowersSumFields>;
  FollowersUpdateColumn: FollowersUpdateColumn;
  FollowersUpdates: FollowersUpdates;
  FollowersVar_popFields: ResolverTypeWrapper<FollowersVar_PopFields>;
  FollowersVar_sampFields: ResolverTypeWrapper<FollowersVar_SampFields>;
  FollowersVarianceFields: ResolverTypeWrapper<FollowersVarianceFields>;
  Int: ResolverTypeWrapper<Scalars['Int']['output']>;
  IntComparisonExp: IntComparisonExp;
  JsonbCastExp: JsonbCastExp;
  JsonbComparisonExp: JsonbComparisonExp;
  MessageTypes: ResolverTypeWrapper<MessageTypes>;
  MessageTypesAggregate: ResolverTypeWrapper<MessageTypesAggregate>;
  MessageTypesAggregateFields: ResolverTypeWrapper<MessageTypesAggregateFields>;
  MessageTypesAppendInput: MessageTypesAppendInput;
  MessageTypesAvgFields: ResolverTypeWrapper<MessageTypesAvgFields>;
  MessageTypesBoolExp: MessageTypesBoolExp;
  MessageTypesConstraint: MessageTypesConstraint;
  MessageTypesDeleteAtPathInput: MessageTypesDeleteAtPathInput;
  MessageTypesDeleteElemInput: MessageTypesDeleteElemInput;
  MessageTypesDeleteKeyInput: MessageTypesDeleteKeyInput;
  MessageTypesIncInput: MessageTypesIncInput;
  MessageTypesInsertInput: MessageTypesInsertInput;
  MessageTypesMaxFields: ResolverTypeWrapper<MessageTypesMaxFields>;
  MessageTypesMinFields: ResolverTypeWrapper<MessageTypesMinFields>;
  MessageTypesMutationResponse: ResolverTypeWrapper<MessageTypesMutationResponse>;
  MessageTypesOnConflict: MessageTypesOnConflict;
  MessageTypesOrderBy: MessageTypesOrderBy;
  MessageTypesPkColumnsInput: MessageTypesPkColumnsInput;
  MessageTypesPrependInput: MessageTypesPrependInput;
  MessageTypesSelectColumn: MessageTypesSelectColumn;
  MessageTypesSetInput: MessageTypesSetInput;
  MessageTypesStddevFields: ResolverTypeWrapper<MessageTypesStddevFields>;
  MessageTypesStddev_popFields: ResolverTypeWrapper<MessageTypesStddev_PopFields>;
  MessageTypesStddev_sampFields: ResolverTypeWrapper<MessageTypesStddev_SampFields>;
  MessageTypesStreamCursorInput: MessageTypesStreamCursorInput;
  MessageTypesStreamCursorValueInput: MessageTypesStreamCursorValueInput;
  MessageTypesSumFields: ResolverTypeWrapper<MessageTypesSumFields>;
  MessageTypesUpdateColumn: MessageTypesUpdateColumn;
  MessageTypesUpdates: MessageTypesUpdates;
  MessageTypesVar_popFields: ResolverTypeWrapper<MessageTypesVar_PopFields>;
  MessageTypesVar_sampFields: ResolverTypeWrapper<MessageTypesVar_SampFields>;
  MessageTypesVarianceFields: ResolverTypeWrapper<MessageTypesVarianceFields>;
  Messages: ResolverTypeWrapper<Messages>;
  MessagesAggregate: ResolverTypeWrapper<MessagesAggregate>;
  MessagesAggregateFields: ResolverTypeWrapper<MessagesAggregateFields>;
  MessagesAppendInput: MessagesAppendInput;
  MessagesAvgFields: ResolverTypeWrapper<MessagesAvgFields>;
  MessagesBoolExp: MessagesBoolExp;
  MessagesConstraint: MessagesConstraint;
  MessagesDeleteAtPathInput: MessagesDeleteAtPathInput;
  MessagesDeleteElemInput: MessagesDeleteElemInput;
  MessagesDeleteKeyInput: MessagesDeleteKeyInput;
  MessagesIncInput: MessagesIncInput;
  MessagesInsertInput: MessagesInsertInput;
  MessagesMaxFields: ResolverTypeWrapper<MessagesMaxFields>;
  MessagesMinFields: ResolverTypeWrapper<MessagesMinFields>;
  MessagesMutationResponse: ResolverTypeWrapper<MessagesMutationResponse>;
  MessagesOnConflict: MessagesOnConflict;
  MessagesOrderBy: MessagesOrderBy;
  MessagesPkColumnsInput: MessagesPkColumnsInput;
  MessagesPrependInput: MessagesPrependInput;
  MessagesSelectColumn: MessagesSelectColumn;
  MessagesSetInput: MessagesSetInput;
  MessagesStddevFields: ResolverTypeWrapper<MessagesStddevFields>;
  MessagesStddev_popFields: ResolverTypeWrapper<MessagesStddev_PopFields>;
  MessagesStddev_sampFields: ResolverTypeWrapper<MessagesStddev_SampFields>;
  MessagesStreamCursorInput: MessagesStreamCursorInput;
  MessagesStreamCursorValueInput: MessagesStreamCursorValueInput;
  MessagesSumFields: ResolverTypeWrapper<MessagesSumFields>;
  MessagesUpdateColumn: MessagesUpdateColumn;
  MessagesUpdates: MessagesUpdates;
  MessagesVar_popFields: ResolverTypeWrapper<MessagesVar_PopFields>;
  MessagesVar_sampFields: ResolverTypeWrapper<MessagesVar_SampFields>;
  MessagesVarianceFields: ResolverTypeWrapper<MessagesVarianceFields>;
  NumericComparisonExp: NumericComparisonExp;
  OrderBy: OrderBy;
  OrganiserUsers: ResolverTypeWrapper<OrganiserUsers>;
  OrganiserUsersAggregate: ResolverTypeWrapper<OrganiserUsersAggregate>;
  OrganiserUsersAggregateFields: ResolverTypeWrapper<OrganiserUsersAggregateFields>;
  OrganiserUsersAvgFields: ResolverTypeWrapper<OrganiserUsersAvgFields>;
  OrganiserUsersBoolExp: OrganiserUsersBoolExp;
  OrganiserUsersConstraint: OrganiserUsersConstraint;
  OrganiserUsersIncInput: OrganiserUsersIncInput;
  OrganiserUsersInsertInput: OrganiserUsersInsertInput;
  OrganiserUsersMaxFields: ResolverTypeWrapper<OrganiserUsersMaxFields>;
  OrganiserUsersMinFields: ResolverTypeWrapper<OrganiserUsersMinFields>;
  OrganiserUsersMutationResponse: ResolverTypeWrapper<OrganiserUsersMutationResponse>;
  OrganiserUsersOnConflict: OrganiserUsersOnConflict;
  OrganiserUsersOrderBy: OrganiserUsersOrderBy;
  OrganiserUsersPkColumnsInput: OrganiserUsersPkColumnsInput;
  OrganiserUsersSelectColumn: OrganiserUsersSelectColumn;
  OrganiserUsersSetInput: OrganiserUsersSetInput;
  OrganiserUsersStddevFields: ResolverTypeWrapper<OrganiserUsersStddevFields>;
  OrganiserUsersStddev_popFields: ResolverTypeWrapper<OrganiserUsersStddev_PopFields>;
  OrganiserUsersStddev_sampFields: ResolverTypeWrapper<OrganiserUsersStddev_SampFields>;
  OrganiserUsersStreamCursorInput: OrganiserUsersStreamCursorInput;
  OrganiserUsersStreamCursorValueInput: OrganiserUsersStreamCursorValueInput;
  OrganiserUsersSumFields: ResolverTypeWrapper<OrganiserUsersSumFields>;
  OrganiserUsersUpdateColumn: OrganiserUsersUpdateColumn;
  OrganiserUsersUpdates: OrganiserUsersUpdates;
  OrganiserUsersVar_popFields: ResolverTypeWrapper<OrganiserUsersVar_PopFields>;
  OrganiserUsersVar_sampFields: ResolverTypeWrapper<OrganiserUsersVar_SampFields>;
  OrganiserUsersVarianceFields: ResolverTypeWrapper<OrganiserUsersVarianceFields>;
  Organisers: ResolverTypeWrapper<Organisers>;
  OrganisersAggregate: ResolverTypeWrapper<OrganisersAggregate>;
  OrganisersAggregateFields: ResolverTypeWrapper<OrganisersAggregateFields>;
  OrganisersAvgFields: ResolverTypeWrapper<OrganisersAvgFields>;
  OrganisersBoolExp: OrganisersBoolExp;
  OrganisersConstraint: OrganisersConstraint;
  OrganisersIncInput: OrganisersIncInput;
  OrganisersInsertInput: OrganisersInsertInput;
  OrganisersMaxFields: ResolverTypeWrapper<OrganisersMaxFields>;
  OrganisersMinFields: ResolverTypeWrapper<OrganisersMinFields>;
  OrganisersMutationResponse: ResolverTypeWrapper<OrganisersMutationResponse>;
  OrganisersObjRelInsertInput: OrganisersObjRelInsertInput;
  OrganisersOnConflict: OrganisersOnConflict;
  OrganisersOrderBy: OrganisersOrderBy;
  OrganisersPkColumnsInput: OrganisersPkColumnsInput;
  OrganisersSelectColumn: OrganisersSelectColumn;
  OrganisersSetInput: OrganisersSetInput;
  OrganisersStddevFields: ResolverTypeWrapper<OrganisersStddevFields>;
  OrganisersStddev_popFields: ResolverTypeWrapper<OrganisersStddev_PopFields>;
  OrganisersStddev_sampFields: ResolverTypeWrapper<OrganisersStddev_SampFields>;
  OrganisersStreamCursorInput: OrganisersStreamCursorInput;
  OrganisersStreamCursorValueInput: OrganisersStreamCursorValueInput;
  OrganisersSumFields: ResolverTypeWrapper<OrganisersSumFields>;
  OrganisersUpdateColumn: OrganisersUpdateColumn;
  OrganisersUpdates: OrganisersUpdates;
  OrganisersVar_popFields: ResolverTypeWrapper<OrganisersVar_PopFields>;
  OrganisersVar_sampFields: ResolverTypeWrapper<OrganisersVar_SampFields>;
  OrganisersVarianceFields: ResolverTypeWrapper<OrganisersVarianceFields>;
  PaymentMethodBanks: ResolverTypeWrapper<PaymentMethodBanks>;
  PaymentMethodBanksAggregate: ResolverTypeWrapper<PaymentMethodBanksAggregate>;
  PaymentMethodBanksAggregateFields: ResolverTypeWrapper<PaymentMethodBanksAggregateFields>;
  PaymentMethodBanksAggregateOrderBy: PaymentMethodBanksAggregateOrderBy;
  PaymentMethodBanksArrRelInsertInput: PaymentMethodBanksArrRelInsertInput;
  PaymentMethodBanksAvgFields: ResolverTypeWrapper<PaymentMethodBanksAvgFields>;
  PaymentMethodBanksBoolExp: PaymentMethodBanksBoolExp;
  PaymentMethodBanksConstraint: PaymentMethodBanksConstraint;
  PaymentMethodBanksIncInput: PaymentMethodBanksIncInput;
  PaymentMethodBanksInsertInput: PaymentMethodBanksInsertInput;
  PaymentMethodBanksMaxFields: ResolverTypeWrapper<PaymentMethodBanksMaxFields>;
  PaymentMethodBanksMinFields: ResolverTypeWrapper<PaymentMethodBanksMinFields>;
  PaymentMethodBanksMutationResponse: ResolverTypeWrapper<PaymentMethodBanksMutationResponse>;
  PaymentMethodBanksObjRelInsertInput: PaymentMethodBanksObjRelInsertInput;
  PaymentMethodBanksOnConflict: PaymentMethodBanksOnConflict;
  PaymentMethodBanksOrderBy: PaymentMethodBanksOrderBy;
  PaymentMethodBanksPkColumnsInput: PaymentMethodBanksPkColumnsInput;
  PaymentMethodBanksSelectColumn: PaymentMethodBanksSelectColumn;
  PaymentMethodBanksSetInput: PaymentMethodBanksSetInput;
  PaymentMethodBanksStddevFields: ResolverTypeWrapper<PaymentMethodBanksStddevFields>;
  PaymentMethodBanksStddev_popFields: ResolverTypeWrapper<PaymentMethodBanksStddev_PopFields>;
  PaymentMethodBanksStddev_sampFields: ResolverTypeWrapper<PaymentMethodBanksStddev_SampFields>;
  PaymentMethodBanksStreamCursorInput: PaymentMethodBanksStreamCursorInput;
  PaymentMethodBanksStreamCursorValueInput: PaymentMethodBanksStreamCursorValueInput;
  PaymentMethodBanksSumFields: ResolverTypeWrapper<PaymentMethodBanksSumFields>;
  PaymentMethodBanksUpdateColumn: PaymentMethodBanksUpdateColumn;
  PaymentMethodBanksUpdates: PaymentMethodBanksUpdates;
  PaymentMethodBanksVar_popFields: ResolverTypeWrapper<PaymentMethodBanksVar_PopFields>;
  PaymentMethodBanksVar_sampFields: ResolverTypeWrapper<PaymentMethodBanksVar_SampFields>;
  PaymentMethodBanksVarianceFields: ResolverTypeWrapper<PaymentMethodBanksVarianceFields>;
  PaymentMethods: ResolverTypeWrapper<PaymentMethods>;
  PaymentMethodsAggregate: ResolverTypeWrapper<PaymentMethodsAggregate>;
  PaymentMethodsAggregateFields: ResolverTypeWrapper<PaymentMethodsAggregateFields>;
  PaymentMethodsAvgFields: ResolverTypeWrapper<PaymentMethodsAvgFields>;
  PaymentMethodsBoolExp: PaymentMethodsBoolExp;
  PaymentMethodsConstraint: PaymentMethodsConstraint;
  PaymentMethodsIncInput: PaymentMethodsIncInput;
  PaymentMethodsInsertInput: PaymentMethodsInsertInput;
  PaymentMethodsMaxFields: ResolverTypeWrapper<PaymentMethodsMaxFields>;
  PaymentMethodsMinFields: ResolverTypeWrapper<PaymentMethodsMinFields>;
  PaymentMethodsMutationResponse: ResolverTypeWrapper<PaymentMethodsMutationResponse>;
  PaymentMethodsObjRelInsertInput: PaymentMethodsObjRelInsertInput;
  PaymentMethodsOnConflict: PaymentMethodsOnConflict;
  PaymentMethodsOrderBy: PaymentMethodsOrderBy;
  PaymentMethodsPkColumnsInput: PaymentMethodsPkColumnsInput;
  PaymentMethodsSelectColumn: PaymentMethodsSelectColumn;
  PaymentMethodsSetInput: PaymentMethodsSetInput;
  PaymentMethodsStddevFields: ResolverTypeWrapper<PaymentMethodsStddevFields>;
  PaymentMethodsStddev_popFields: ResolverTypeWrapper<PaymentMethodsStddev_PopFields>;
  PaymentMethodsStddev_sampFields: ResolverTypeWrapper<PaymentMethodsStddev_SampFields>;
  PaymentMethodsStreamCursorInput: PaymentMethodsStreamCursorInput;
  PaymentMethodsStreamCursorValueInput: PaymentMethodsStreamCursorValueInput;
  PaymentMethodsSumFields: ResolverTypeWrapper<PaymentMethodsSumFields>;
  PaymentMethodsUpdateColumn: PaymentMethodsUpdateColumn;
  PaymentMethodsUpdates: PaymentMethodsUpdates;
  PaymentMethodsVar_popFields: ResolverTypeWrapper<PaymentMethodsVar_PopFields>;
  PaymentMethodsVar_sampFields: ResolverTypeWrapper<PaymentMethodsVar_SampFields>;
  PaymentMethodsVarianceFields: ResolverTypeWrapper<PaymentMethodsVarianceFields>;
  Payments: ResolverTypeWrapper<Payments>;
  PaymentsAggregate: ResolverTypeWrapper<PaymentsAggregate>;
  PaymentsAggregateFields: ResolverTypeWrapper<PaymentsAggregateFields>;
  PaymentsAvgFields: ResolverTypeWrapper<PaymentsAvgFields>;
  PaymentsBoolExp: PaymentsBoolExp;
  PaymentsConstraint: PaymentsConstraint;
  PaymentsIncInput: PaymentsIncInput;
  PaymentsInsertInput: PaymentsInsertInput;
  PaymentsMaxFields: ResolverTypeWrapper<PaymentsMaxFields>;
  PaymentsMinFields: ResolverTypeWrapper<PaymentsMinFields>;
  PaymentsMutationResponse: ResolverTypeWrapper<PaymentsMutationResponse>;
  PaymentsObjRelInsertInput: PaymentsObjRelInsertInput;
  PaymentsOnConflict: PaymentsOnConflict;
  PaymentsOrderBy: PaymentsOrderBy;
  PaymentsPkColumnsInput: PaymentsPkColumnsInput;
  PaymentsSelectColumn: PaymentsSelectColumn;
  PaymentsSetInput: PaymentsSetInput;
  PaymentsStddevFields: ResolverTypeWrapper<PaymentsStddevFields>;
  PaymentsStddev_popFields: ResolverTypeWrapper<PaymentsStddev_PopFields>;
  PaymentsStddev_sampFields: ResolverTypeWrapper<PaymentsStddev_SampFields>;
  PaymentsStreamCursorInput: PaymentsStreamCursorInput;
  PaymentsStreamCursorValueInput: PaymentsStreamCursorValueInput;
  PaymentsSumFields: ResolverTypeWrapper<PaymentsSumFields>;
  PaymentsUpdateColumn: PaymentsUpdateColumn;
  PaymentsUpdates: PaymentsUpdates;
  PaymentsVar_popFields: ResolverTypeWrapper<PaymentsVar_PopFields>;
  PaymentsVar_sampFields: ResolverTypeWrapper<PaymentsVar_SampFields>;
  PaymentsVarianceFields: ResolverTypeWrapper<PaymentsVarianceFields>;
  PendingTxs: ResolverTypeWrapper<PendingTxs>;
  PendingTxsAggregate: ResolverTypeWrapper<PendingTxsAggregate>;
  PendingTxsAggregateFields: ResolverTypeWrapper<PendingTxsAggregateFields>;
  PendingTxsAvgFields: ResolverTypeWrapper<PendingTxsAvgFields>;
  PendingTxsBoolExp: PendingTxsBoolExp;
  PendingTxsConstraint: PendingTxsConstraint;
  PendingTxsIncInput: PendingTxsIncInput;
  PendingTxsInsertInput: PendingTxsInsertInput;
  PendingTxsMaxFields: ResolverTypeWrapper<PendingTxsMaxFields>;
  PendingTxsMinFields: ResolverTypeWrapper<PendingTxsMinFields>;
  PendingTxsMutationResponse: ResolverTypeWrapper<PendingTxsMutationResponse>;
  PendingTxsOnConflict: PendingTxsOnConflict;
  PendingTxsOrderBy: PendingTxsOrderBy;
  PendingTxsPkColumnsInput: PendingTxsPkColumnsInput;
  PendingTxsSelectColumn: PendingTxsSelectColumn;
  PendingTxsSetInput: PendingTxsSetInput;
  PendingTxsStddevFields: ResolverTypeWrapper<PendingTxsStddevFields>;
  PendingTxsStddev_popFields: ResolverTypeWrapper<PendingTxsStddev_PopFields>;
  PendingTxsStddev_sampFields: ResolverTypeWrapper<PendingTxsStddev_SampFields>;
  PendingTxsStreamCursorInput: PendingTxsStreamCursorInput;
  PendingTxsStreamCursorValueInput: PendingTxsStreamCursorValueInput;
  PendingTxsSumFields: ResolverTypeWrapper<PendingTxsSumFields>;
  PendingTxsUpdateColumn: PendingTxsUpdateColumn;
  PendingTxsUpdates: PendingTxsUpdates;
  PendingTxsVar_popFields: ResolverTypeWrapper<PendingTxsVar_PopFields>;
  PendingTxsVar_sampFields: ResolverTypeWrapper<PendingTxsVar_SampFields>;
  PendingTxsVarianceFields: ResolverTypeWrapper<PendingTxsVarianceFields>;
  PlayerFollowers: ResolverTypeWrapper<PlayerFollowers>;
  PlayerFollowersAggregate: ResolverTypeWrapper<PlayerFollowersAggregate>;
  PlayerFollowersAggregateFields: ResolverTypeWrapper<PlayerFollowersAggregateFields>;
  PlayerFollowersAvgFields: ResolverTypeWrapper<PlayerFollowersAvgFields>;
  PlayerFollowersBoolExp: PlayerFollowersBoolExp;
  PlayerFollowersIncInput: PlayerFollowersIncInput;
  PlayerFollowersInsertInput: PlayerFollowersInsertInput;
  PlayerFollowersMaxFields: ResolverTypeWrapper<PlayerFollowersMaxFields>;
  PlayerFollowersMinFields: ResolverTypeWrapper<PlayerFollowersMinFields>;
  PlayerFollowersMutationResponse: ResolverTypeWrapper<PlayerFollowersMutationResponse>;
  PlayerFollowersOrderBy: PlayerFollowersOrderBy;
  PlayerFollowersSelectColumn: PlayerFollowersSelectColumn;
  PlayerFollowersSetInput: PlayerFollowersSetInput;
  PlayerFollowersStddevFields: ResolverTypeWrapper<PlayerFollowersStddevFields>;
  PlayerFollowersStddev_popFields: ResolverTypeWrapper<PlayerFollowersStddev_PopFields>;
  PlayerFollowersStddev_sampFields: ResolverTypeWrapper<PlayerFollowersStddev_SampFields>;
  PlayerFollowersStreamCursorInput: PlayerFollowersStreamCursorInput;
  PlayerFollowersStreamCursorValueInput: PlayerFollowersStreamCursorValueInput;
  PlayerFollowersSumFields: ResolverTypeWrapper<PlayerFollowersSumFields>;
  PlayerFollowersUpdates: PlayerFollowersUpdates;
  PlayerFollowersVar_popFields: ResolverTypeWrapper<PlayerFollowersVar_PopFields>;
  PlayerFollowersVar_sampFields: ResolverTypeWrapper<PlayerFollowersVar_SampFields>;
  PlayerFollowersVarianceFields: ResolverTypeWrapper<PlayerFollowersVarianceFields>;
  PlayerFollowersView: ResolverTypeWrapper<PlayerFollowersView>;
  PlayerFollowersViewAggregate: ResolverTypeWrapper<PlayerFollowersViewAggregate>;
  PlayerFollowersViewAggregateFields: ResolverTypeWrapper<PlayerFollowersViewAggregateFields>;
  PlayerFollowersViewAggregateOrderBy: PlayerFollowersViewAggregateOrderBy;
  PlayerFollowersViewArrRelInsertInput: PlayerFollowersViewArrRelInsertInput;
  PlayerFollowersViewAvgFields: ResolverTypeWrapper<PlayerFollowersViewAvgFields>;
  PlayerFollowersViewBoolExp: PlayerFollowersViewBoolExp;
  PlayerFollowersViewInsertInput: PlayerFollowersViewInsertInput;
  PlayerFollowersViewMaxFields: ResolverTypeWrapper<PlayerFollowersViewMaxFields>;
  PlayerFollowersViewMinFields: ResolverTypeWrapper<PlayerFollowersViewMinFields>;
  PlayerFollowersViewOrderBy: PlayerFollowersViewOrderBy;
  PlayerFollowersViewSelectColumn: PlayerFollowersViewSelectColumn;
  PlayerFollowersViewStddevFields: ResolverTypeWrapper<PlayerFollowersViewStddevFields>;
  PlayerFollowersViewStddev_popFields: ResolverTypeWrapper<PlayerFollowersViewStddev_PopFields>;
  PlayerFollowersViewStddev_sampFields: ResolverTypeWrapper<PlayerFollowersViewStddev_SampFields>;
  PlayerFollowersViewStreamCursorInput: PlayerFollowersViewStreamCursorInput;
  PlayerFollowersViewStreamCursorValueInput: PlayerFollowersViewStreamCursorValueInput;
  PlayerFollowersViewSumFields: ResolverTypeWrapper<PlayerFollowersViewSumFields>;
  PlayerFollowersViewVar_popFields: ResolverTypeWrapper<PlayerFollowersViewVar_PopFields>;
  PlayerFollowersViewVar_sampFields: ResolverTypeWrapper<PlayerFollowersViewVar_SampFields>;
  PlayerFollowersViewVarianceFields: ResolverTypeWrapper<PlayerFollowersViewVarianceFields>;
  PlayerShares: ResolverTypeWrapper<PlayerShares>;
  PlayerSharesAggregate: ResolverTypeWrapper<PlayerSharesAggregate>;
  PlayerSharesAggregateFields: ResolverTypeWrapper<PlayerSharesAggregateFields>;
  PlayerSharesAggregateOrderBy: PlayerSharesAggregateOrderBy;
  PlayerSharesArrRelInsertInput: PlayerSharesArrRelInsertInput;
  PlayerSharesAvgFields: ResolverTypeWrapper<PlayerSharesAvgFields>;
  PlayerSharesBoolExp: PlayerSharesBoolExp;
  PlayerSharesBought: ResolverTypeWrapper<PlayerSharesBought>;
  PlayerSharesBoughtAggregate: ResolverTypeWrapper<PlayerSharesBoughtAggregate>;
  PlayerSharesBoughtAggregateFields: ResolverTypeWrapper<PlayerSharesBoughtAggregateFields>;
  PlayerSharesBoughtAvgFields: ResolverTypeWrapper<PlayerSharesBoughtAvgFields>;
  PlayerSharesBoughtBoolExp: PlayerSharesBoughtBoolExp;
  PlayerSharesBoughtInsertInput: PlayerSharesBoughtInsertInput;
  PlayerSharesBoughtMaxFields: ResolverTypeWrapper<PlayerSharesBoughtMaxFields>;
  PlayerSharesBoughtMinFields: ResolverTypeWrapper<PlayerSharesBoughtMinFields>;
  PlayerSharesBoughtObjRelInsertInput: PlayerSharesBoughtObjRelInsertInput;
  PlayerSharesBoughtOrderBy: PlayerSharesBoughtOrderBy;
  PlayerSharesBoughtSelectColumn: PlayerSharesBoughtSelectColumn;
  PlayerSharesBoughtStddevFields: ResolverTypeWrapper<PlayerSharesBoughtStddevFields>;
  PlayerSharesBoughtStddev_popFields: ResolverTypeWrapper<PlayerSharesBoughtStddev_PopFields>;
  PlayerSharesBoughtStddev_sampFields: ResolverTypeWrapper<PlayerSharesBoughtStddev_SampFields>;
  PlayerSharesBoughtStreamCursorInput: PlayerSharesBoughtStreamCursorInput;
  PlayerSharesBoughtStreamCursorValueInput: PlayerSharesBoughtStreamCursorValueInput;
  PlayerSharesBoughtSumFields: ResolverTypeWrapper<PlayerSharesBoughtSumFields>;
  PlayerSharesBoughtVar_popFields: ResolverTypeWrapper<PlayerSharesBoughtVar_PopFields>;
  PlayerSharesBoughtVar_sampFields: ResolverTypeWrapper<PlayerSharesBoughtVar_SampFields>;
  PlayerSharesBoughtVarianceFields: ResolverTypeWrapper<PlayerSharesBoughtVarianceFields>;
  PlayerSharesConstraint: PlayerSharesConstraint;
  PlayerSharesIncInput: PlayerSharesIncInput;
  PlayerSharesInsertInput: PlayerSharesInsertInput;
  PlayerSharesMaxFields: ResolverTypeWrapper<PlayerSharesMaxFields>;
  PlayerSharesMinFields: ResolverTypeWrapper<PlayerSharesMinFields>;
  PlayerSharesMutationResponse: ResolverTypeWrapper<PlayerSharesMutationResponse>;
  PlayerSharesObjRelInsertInput: PlayerSharesObjRelInsertInput;
  PlayerSharesOnConflict: PlayerSharesOnConflict;
  PlayerSharesOrderBy: PlayerSharesOrderBy;
  PlayerSharesPkColumnsInput: PlayerSharesPkColumnsInput;
  PlayerSharesSelectColumn: PlayerSharesSelectColumn;
  PlayerSharesSetInput: PlayerSharesSetInput;
  PlayerSharesStddevFields: ResolverTypeWrapper<PlayerSharesStddevFields>;
  PlayerSharesStddev_popFields: ResolverTypeWrapper<PlayerSharesStddev_PopFields>;
  PlayerSharesStddev_sampFields: ResolverTypeWrapper<PlayerSharesStddev_SampFields>;
  PlayerSharesStreamCursorInput: PlayerSharesStreamCursorInput;
  PlayerSharesStreamCursorValueInput: PlayerSharesStreamCursorValueInput;
  PlayerSharesSumFields: ResolverTypeWrapper<PlayerSharesSumFields>;
  PlayerSharesUpdateColumn: PlayerSharesUpdateColumn;
  PlayerSharesUpdates: PlayerSharesUpdates;
  PlayerSharesVar_popFields: ResolverTypeWrapper<PlayerSharesVar_PopFields>;
  PlayerSharesVar_sampFields: ResolverTypeWrapper<PlayerSharesVar_SampFields>;
  PlayerSharesVarianceFields: ResolverTypeWrapper<PlayerSharesVarianceFields>;
  Players: ResolverTypeWrapper<Players>;
  PlayersAggregate: ResolverTypeWrapper<PlayersAggregate>;
  PlayersAggregateFields: ResolverTypeWrapper<PlayersAggregateFields>;
  PlayersAggregateOrderBy: PlayersAggregateOrderBy;
  PlayersArrRelInsertInput: PlayersArrRelInsertInput;
  PlayersAvgFields: ResolverTypeWrapper<PlayersAvgFields>;
  PlayersBoolExp: PlayersBoolExp;
  PlayersConstraint: PlayersConstraint;
  PlayersIncInput: PlayersIncInput;
  PlayersInsertInput: PlayersInsertInput;
  PlayersMaxFields: ResolverTypeWrapper<PlayersMaxFields>;
  PlayersMinFields: ResolverTypeWrapper<PlayersMinFields>;
  PlayersMutationResponse: ResolverTypeWrapper<PlayersMutationResponse>;
  PlayersObjRelInsertInput: PlayersObjRelInsertInput;
  PlayersOnConflict: PlayersOnConflict;
  PlayersOrderBy: PlayersOrderBy;
  PlayersPkColumnsInput: PlayersPkColumnsInput;
  PlayersSelectColumn: PlayersSelectColumn;
  PlayersSetInput: PlayersSetInput;
  PlayersStddevFields: ResolverTypeWrapper<PlayersStddevFields>;
  PlayersStddev_popFields: ResolverTypeWrapper<PlayersStddev_PopFields>;
  PlayersStddev_sampFields: ResolverTypeWrapper<PlayersStddev_SampFields>;
  PlayersStreamCursorInput: PlayersStreamCursorInput;
  PlayersStreamCursorValueInput: PlayersStreamCursorValueInput;
  PlayersSumFields: ResolverTypeWrapper<PlayersSumFields>;
  PlayersUpdateColumn: PlayersUpdateColumn;
  PlayersUpdates: PlayersUpdates;
  PlayersVar_popFields: ResolverTypeWrapper<PlayersVar_PopFields>;
  PlayersVar_sampFields: ResolverTypeWrapper<PlayersVar_SampFields>;
  PlayersVarianceFields: ResolverTypeWrapper<PlayersVarianceFields>;
  StatEvents: ResolverTypeWrapper<StatEvents>;
  StatEventsAggregate: ResolverTypeWrapper<StatEventsAggregate>;
  StatEventsAggregateFields: ResolverTypeWrapper<StatEventsAggregateFields>;
  StatEventsAvgFields: ResolverTypeWrapper<StatEventsAvgFields>;
  StatEventsBoolExp: StatEventsBoolExp;
  StatEventsConstraint: StatEventsConstraint;
  StatEventsIncInput: StatEventsIncInput;
  StatEventsInsertInput: StatEventsInsertInput;
  StatEventsMaxFields: ResolverTypeWrapper<StatEventsMaxFields>;
  StatEventsMinFields: ResolverTypeWrapper<StatEventsMinFields>;
  StatEventsMutationResponse: ResolverTypeWrapper<StatEventsMutationResponse>;
  StatEventsOnConflict: StatEventsOnConflict;
  StatEventsOrderBy: StatEventsOrderBy;
  StatEventsPkColumnsInput: StatEventsPkColumnsInput;
  StatEventsSelectColumn: StatEventsSelectColumn;
  StatEventsSetInput: StatEventsSetInput;
  StatEventsStddevFields: ResolverTypeWrapper<StatEventsStddevFields>;
  StatEventsStddev_popFields: ResolverTypeWrapper<StatEventsStddev_PopFields>;
  StatEventsStddev_sampFields: ResolverTypeWrapper<StatEventsStddev_SampFields>;
  StatEventsStreamCursorInput: StatEventsStreamCursorInput;
  StatEventsStreamCursorValueInput: StatEventsStreamCursorValueInput;
  StatEventsSumFields: ResolverTypeWrapper<StatEventsSumFields>;
  StatEventsUpdateColumn: StatEventsUpdateColumn;
  StatEventsUpdates: StatEventsUpdates;
  StatEventsVar_popFields: ResolverTypeWrapper<StatEventsVar_PopFields>;
  StatEventsVar_sampFields: ResolverTypeWrapper<StatEventsVar_SampFields>;
  StatEventsVarianceFields: ResolverTypeWrapper<StatEventsVarianceFields>;
  StatPlayers: ResolverTypeWrapper<StatPlayers>;
  StatPlayersAggregate: ResolverTypeWrapper<StatPlayersAggregate>;
  StatPlayersAggregateFields: ResolverTypeWrapper<StatPlayersAggregateFields>;
  StatPlayersAvgFields: ResolverTypeWrapper<StatPlayersAvgFields>;
  StatPlayersBoolExp: StatPlayersBoolExp;
  StatPlayersConstraint: StatPlayersConstraint;
  StatPlayersIncInput: StatPlayersIncInput;
  StatPlayersInsertInput: StatPlayersInsertInput;
  StatPlayersMaxFields: ResolverTypeWrapper<StatPlayersMaxFields>;
  StatPlayersMinFields: ResolverTypeWrapper<StatPlayersMinFields>;
  StatPlayersMutationResponse: ResolverTypeWrapper<StatPlayersMutationResponse>;
  StatPlayersObjRelInsertInput: StatPlayersObjRelInsertInput;
  StatPlayersOnConflict: StatPlayersOnConflict;
  StatPlayersOrderBy: StatPlayersOrderBy;
  StatPlayersPkColumnsInput: StatPlayersPkColumnsInput;
  StatPlayersSelectColumn: StatPlayersSelectColumn;
  StatPlayersSetInput: StatPlayersSetInput;
  StatPlayersStddevFields: ResolverTypeWrapper<StatPlayersStddevFields>;
  StatPlayersStddev_popFields: ResolverTypeWrapper<StatPlayersStddev_PopFields>;
  StatPlayersStddev_sampFields: ResolverTypeWrapper<StatPlayersStddev_SampFields>;
  StatPlayersStreamCursorInput: StatPlayersStreamCursorInput;
  StatPlayersStreamCursorValueInput: StatPlayersStreamCursorValueInput;
  StatPlayersSumFields: ResolverTypeWrapper<StatPlayersSumFields>;
  StatPlayersUpdateColumn: StatPlayersUpdateColumn;
  StatPlayersUpdates: StatPlayersUpdates;
  StatPlayersVar_popFields: ResolverTypeWrapper<StatPlayersVar_PopFields>;
  StatPlayersVar_sampFields: ResolverTypeWrapper<StatPlayersVar_SampFields>;
  StatPlayersVarianceFields: ResolverTypeWrapper<StatPlayersVarianceFields>;
  StatTopPlayers: ResolverTypeWrapper<StatTopPlayers>;
  StatTopPlayersAggregate: ResolverTypeWrapper<StatTopPlayersAggregate>;
  StatTopPlayersAggregateFields: ResolverTypeWrapper<StatTopPlayersAggregateFields>;
  StatTopPlayersAvgFields: ResolverTypeWrapper<StatTopPlayersAvgFields>;
  StatTopPlayersBoolExp: StatTopPlayersBoolExp;
  StatTopPlayersMaxFields: ResolverTypeWrapper<StatTopPlayersMaxFields>;
  StatTopPlayersMinFields: ResolverTypeWrapper<StatTopPlayersMinFields>;
  StatTopPlayersOrderBy: StatTopPlayersOrderBy;
  StatTopPlayersSelectColumn: StatTopPlayersSelectColumn;
  StatTopPlayersStddevFields: ResolverTypeWrapper<StatTopPlayersStddevFields>;
  StatTopPlayersStddev_popFields: ResolverTypeWrapper<StatTopPlayersStddev_PopFields>;
  StatTopPlayersStddev_sampFields: ResolverTypeWrapper<StatTopPlayersStddev_SampFields>;
  StatTopPlayersStreamCursorInput: StatTopPlayersStreamCursorInput;
  StatTopPlayersStreamCursorValueInput: StatTopPlayersStreamCursorValueInput;
  StatTopPlayersSumFields: ResolverTypeWrapper<StatTopPlayersSumFields>;
  StatTopPlayersVar_popFields: ResolverTypeWrapper<StatTopPlayersVar_PopFields>;
  StatTopPlayersVar_sampFields: ResolverTypeWrapper<StatTopPlayersVar_SampFields>;
  StatTopPlayersVarianceFields: ResolverTypeWrapper<StatTopPlayersVarianceFields>;
  StatTopYearPlayers: ResolverTypeWrapper<StatTopYearPlayers>;
  StatTopYearPlayersAggregate: ResolverTypeWrapper<StatTopYearPlayersAggregate>;
  StatTopYearPlayersAggregateFields: ResolverTypeWrapper<StatTopYearPlayersAggregateFields>;
  StatTopYearPlayersAvgFields: ResolverTypeWrapper<StatTopYearPlayersAvgFields>;
  StatTopYearPlayersBoolExp: StatTopYearPlayersBoolExp;
  StatTopYearPlayersMaxFields: ResolverTypeWrapper<StatTopYearPlayersMaxFields>;
  StatTopYearPlayersMinFields: ResolverTypeWrapper<StatTopYearPlayersMinFields>;
  StatTopYearPlayersOrderBy: StatTopYearPlayersOrderBy;
  StatTopYearPlayersSelectColumn: StatTopYearPlayersSelectColumn;
  StatTopYearPlayersStddevFields: ResolverTypeWrapper<StatTopYearPlayersStddevFields>;
  StatTopYearPlayersStddev_popFields: ResolverTypeWrapper<StatTopYearPlayersStddev_PopFields>;
  StatTopYearPlayersStddev_sampFields: ResolverTypeWrapper<StatTopYearPlayersStddev_SampFields>;
  StatTopYearPlayersStreamCursorInput: StatTopYearPlayersStreamCursorInput;
  StatTopYearPlayersStreamCursorValueInput: StatTopYearPlayersStreamCursorValueInput;
  StatTopYearPlayersSumFields: ResolverTypeWrapper<StatTopYearPlayersSumFields>;
  StatTopYearPlayersVar_popFields: ResolverTypeWrapper<StatTopYearPlayersVar_PopFields>;
  StatTopYearPlayersVar_sampFields: ResolverTypeWrapper<StatTopYearPlayersVar_SampFields>;
  StatTopYearPlayersVarianceFields: ResolverTypeWrapper<StatTopYearPlayersVarianceFields>;
  StatTournamentResults: ResolverTypeWrapper<StatTournamentResults>;
  StatTournamentResultsAggregate: ResolverTypeWrapper<StatTournamentResultsAggregate>;
  StatTournamentResultsAggregateFields: ResolverTypeWrapper<StatTournamentResultsAggregateFields>;
  StatTournamentResultsAggregateOrderBy: StatTournamentResultsAggregateOrderBy;
  StatTournamentResultsArrRelInsertInput: StatTournamentResultsArrRelInsertInput;
  StatTournamentResultsAvgFields: ResolverTypeWrapper<StatTournamentResultsAvgFields>;
  StatTournamentResultsBoolExp: StatTournamentResultsBoolExp;
  StatTournamentResultsConstraint: StatTournamentResultsConstraint;
  StatTournamentResultsIncInput: StatTournamentResultsIncInput;
  StatTournamentResultsInsertInput: StatTournamentResultsInsertInput;
  StatTournamentResultsMaxFields: ResolverTypeWrapper<StatTournamentResultsMaxFields>;
  StatTournamentResultsMinFields: ResolverTypeWrapper<StatTournamentResultsMinFields>;
  StatTournamentResultsMutationResponse: ResolverTypeWrapper<StatTournamentResultsMutationResponse>;
  StatTournamentResultsOnConflict: StatTournamentResultsOnConflict;
  StatTournamentResultsOrderBy: StatTournamentResultsOrderBy;
  StatTournamentResultsPkColumnsInput: StatTournamentResultsPkColumnsInput;
  StatTournamentResultsSelectColumn: StatTournamentResultsSelectColumn;
  StatTournamentResultsSetInput: StatTournamentResultsSetInput;
  StatTournamentResultsStddevFields: ResolverTypeWrapper<StatTournamentResultsStddevFields>;
  StatTournamentResultsStddev_popFields: ResolverTypeWrapper<StatTournamentResultsStddev_PopFields>;
  StatTournamentResultsStddev_sampFields: ResolverTypeWrapper<StatTournamentResultsStddev_SampFields>;
  StatTournamentResultsStreamCursorInput: StatTournamentResultsStreamCursorInput;
  StatTournamentResultsStreamCursorValueInput: StatTournamentResultsStreamCursorValueInput;
  StatTournamentResultsSumFields: ResolverTypeWrapper<StatTournamentResultsSumFields>;
  StatTournamentResultsUpdateColumn: StatTournamentResultsUpdateColumn;
  StatTournamentResultsUpdates: StatTournamentResultsUpdates;
  StatTournamentResultsVar_popFields: ResolverTypeWrapper<StatTournamentResultsVar_PopFields>;
  StatTournamentResultsVar_sampFields: ResolverTypeWrapper<StatTournamentResultsVar_SampFields>;
  StatTournamentResultsVarianceFields: ResolverTypeWrapper<StatTournamentResultsVarianceFields>;
  StatTournaments: ResolverTypeWrapper<StatTournaments>;
  StatTournamentsAggregate: ResolverTypeWrapper<StatTournamentsAggregate>;
  StatTournamentsAggregateFields: ResolverTypeWrapper<StatTournamentsAggregateFields>;
  StatTournamentsAvgFields: ResolverTypeWrapper<StatTournamentsAvgFields>;
  StatTournamentsBoolExp: StatTournamentsBoolExp;
  StatTournamentsConstraint: StatTournamentsConstraint;
  StatTournamentsIncInput: StatTournamentsIncInput;
  StatTournamentsInsertInput: StatTournamentsInsertInput;
  StatTournamentsMaxFields: ResolverTypeWrapper<StatTournamentsMaxFields>;
  StatTournamentsMinFields: ResolverTypeWrapper<StatTournamentsMinFields>;
  StatTournamentsMutationResponse: ResolverTypeWrapper<StatTournamentsMutationResponse>;
  StatTournamentsObjRelInsertInput: StatTournamentsObjRelInsertInput;
  StatTournamentsOnConflict: StatTournamentsOnConflict;
  StatTournamentsOrderBy: StatTournamentsOrderBy;
  StatTournamentsPkColumnsInput: StatTournamentsPkColumnsInput;
  StatTournamentsSelectColumn: StatTournamentsSelectColumn;
  StatTournamentsSetInput: StatTournamentsSetInput;
  StatTournamentsStddevFields: ResolverTypeWrapper<StatTournamentsStddevFields>;
  StatTournamentsStddev_popFields: ResolverTypeWrapper<StatTournamentsStddev_PopFields>;
  StatTournamentsStddev_sampFields: ResolverTypeWrapper<StatTournamentsStddev_SampFields>;
  StatTournamentsStreamCursorInput: StatTournamentsStreamCursorInput;
  StatTournamentsStreamCursorValueInput: StatTournamentsStreamCursorValueInput;
  StatTournamentsSumFields: ResolverTypeWrapper<StatTournamentsSumFields>;
  StatTournamentsUpdateColumn: StatTournamentsUpdateColumn;
  StatTournamentsUpdates: StatTournamentsUpdates;
  StatTournamentsVar_popFields: ResolverTypeWrapper<StatTournamentsVar_PopFields>;
  StatTournamentsVar_sampFields: ResolverTypeWrapper<StatTournamentsVar_SampFields>;
  StatTournamentsVarianceFields: ResolverTypeWrapper<StatTournamentsVarianceFields>;
  StatusGames: ResolverTypeWrapper<StatusGames>;
  StatusGamesAggregate: ResolverTypeWrapper<StatusGamesAggregate>;
  StatusGamesAggregateFields: ResolverTypeWrapper<StatusGamesAggregateFields>;
  StatusGamesBoolExp: StatusGamesBoolExp;
  StatusGamesConstraint: StatusGamesConstraint;
  StatusGamesEnum: StatusGamesEnum;
  StatusGamesEnumComparisonExp: StatusGamesEnumComparisonExp;
  StatusGamesInsertInput: StatusGamesInsertInput;
  StatusGamesMaxFields: ResolverTypeWrapper<StatusGamesMaxFields>;
  StatusGamesMinFields: ResolverTypeWrapper<StatusGamesMinFields>;
  StatusGamesMutationResponse: ResolverTypeWrapper<StatusGamesMutationResponse>;
  StatusGamesOnConflict: StatusGamesOnConflict;
  StatusGamesOrderBy: StatusGamesOrderBy;
  StatusGamesPkColumnsInput: StatusGamesPkColumnsInput;
  StatusGamesSelectColumn: StatusGamesSelectColumn;
  StatusGamesSetInput: StatusGamesSetInput;
  StatusGamesStreamCursorInput: StatusGamesStreamCursorInput;
  StatusGamesStreamCursorValueInput: StatusGamesStreamCursorValueInput;
  StatusGamesUpdateColumn: StatusGamesUpdateColumn;
  StatusGamesUpdates: StatusGamesUpdates;
  StatusOutput: ResolverTypeWrapper<StatusOutput>;
  Statuses: ResolverTypeWrapper<Statuses>;
  StatusesAggregate: ResolverTypeWrapper<StatusesAggregate>;
  StatusesAggregateFields: ResolverTypeWrapper<StatusesAggregateFields>;
  StatusesBoolExp: StatusesBoolExp;
  StatusesConstraint: StatusesConstraint;
  StatusesEnum: StatusesEnum;
  StatusesEnumComparisonExp: StatusesEnumComparisonExp;
  StatusesInsertInput: StatusesInsertInput;
  StatusesMaxFields: ResolverTypeWrapper<StatusesMaxFields>;
  StatusesMinFields: ResolverTypeWrapper<StatusesMinFields>;
  StatusesMutationResponse: ResolverTypeWrapper<StatusesMutationResponse>;
  StatusesOnConflict: StatusesOnConflict;
  StatusesOrderBy: StatusesOrderBy;
  StatusesPkColumnsInput: StatusesPkColumnsInput;
  StatusesSelectColumn: StatusesSelectColumn;
  StatusesSetInput: StatusesSetInput;
  StatusesStreamCursorInput: StatusesStreamCursorInput;
  StatusesStreamCursorValueInput: StatusesStreamCursorValueInput;
  StatusesUpdateColumn: StatusesUpdateColumn;
  StatusesUpdates: StatusesUpdates;
  String: ResolverTypeWrapper<Scalars['String']['output']>;
  StringComparisonExp: StringComparisonExp;
  Tickets: ResolverTypeWrapper<Tickets>;
  TicketsAggregate: ResolverTypeWrapper<TicketsAggregate>;
  TicketsAggregateFields: ResolverTypeWrapper<TicketsAggregateFields>;
  TicketsAggregateOrderBy: TicketsAggregateOrderBy;
  TicketsArrRelInsertInput: TicketsArrRelInsertInput;
  TicketsAvgFields: ResolverTypeWrapper<TicketsAvgFields>;
  TicketsBoolExp: TicketsBoolExp;
  TicketsConstraint: TicketsConstraint;
  TicketsIncInput: TicketsIncInput;
  TicketsInsertInput: TicketsInsertInput;
  TicketsMaxFields: ResolverTypeWrapper<TicketsMaxFields>;
  TicketsMinFields: ResolverTypeWrapper<TicketsMinFields>;
  TicketsMutationResponse: ResolverTypeWrapper<TicketsMutationResponse>;
  TicketsOnConflict: TicketsOnConflict;
  TicketsOrderBy: TicketsOrderBy;
  TicketsPkColumnsInput: TicketsPkColumnsInput;
  TicketsSelectColumn: TicketsSelectColumn;
  TicketsSetInput: TicketsSetInput;
  TicketsStatus: ResolverTypeWrapper<TicketsStatus>;
  TicketsStatusAggregate: ResolverTypeWrapper<TicketsStatusAggregate>;
  TicketsStatusAggregateFields: ResolverTypeWrapper<TicketsStatusAggregateFields>;
  TicketsStatusBoolExp: TicketsStatusBoolExp;
  TicketsStatusConstraint: TicketsStatusConstraint;
  TicketsStatusEnum: TicketsStatusEnum;
  TicketsStatusEnumComparisonExp: TicketsStatusEnumComparisonExp;
  TicketsStatusInsertInput: TicketsStatusInsertInput;
  TicketsStatusMaxFields: ResolverTypeWrapper<TicketsStatusMaxFields>;
  TicketsStatusMinFields: ResolverTypeWrapper<TicketsStatusMinFields>;
  TicketsStatusMutationResponse: ResolverTypeWrapper<TicketsStatusMutationResponse>;
  TicketsStatusOnConflict: TicketsStatusOnConflict;
  TicketsStatusOrderBy: TicketsStatusOrderBy;
  TicketsStatusPkColumnsInput: TicketsStatusPkColumnsInput;
  TicketsStatusSelectColumn: TicketsStatusSelectColumn;
  TicketsStatusSetInput: TicketsStatusSetInput;
  TicketsStatusStreamCursorInput: TicketsStatusStreamCursorInput;
  TicketsStatusStreamCursorValueInput: TicketsStatusStreamCursorValueInput;
  TicketsStatusUpdateColumn: TicketsStatusUpdateColumn;
  TicketsStatusUpdates: TicketsStatusUpdates;
  TicketsStddevFields: ResolverTypeWrapper<TicketsStddevFields>;
  TicketsStddev_popFields: ResolverTypeWrapper<TicketsStddev_PopFields>;
  TicketsStddev_sampFields: ResolverTypeWrapper<TicketsStddev_SampFields>;
  TicketsStreamCursorInput: TicketsStreamCursorInput;
  TicketsStreamCursorValueInput: TicketsStreamCursorValueInput;
  TicketsSumFields: ResolverTypeWrapper<TicketsSumFields>;
  TicketsUpdateColumn: TicketsUpdateColumn;
  TicketsUpdates: TicketsUpdates;
  TicketsVar_popFields: ResolverTypeWrapper<TicketsVar_PopFields>;
  TicketsVar_sampFields: ResolverTypeWrapper<TicketsVar_SampFields>;
  TicketsVarianceFields: ResolverTypeWrapper<TicketsVarianceFields>;
  TimestampComparisonExp: TimestampComparisonExp;
  TimestamptzComparisonExp: TimestamptzComparisonExp;
  TournamentBalances: ResolverTypeWrapper<TournamentBalances>;
  TournamentBalancesAggregate: ResolverTypeWrapper<TournamentBalancesAggregate>;
  TournamentBalancesAggregateFields: ResolverTypeWrapper<TournamentBalancesAggregateFields>;
  TournamentBalancesAggregateOrderBy: TournamentBalancesAggregateOrderBy;
  TournamentBalancesArrRelInsertInput: TournamentBalancesArrRelInsertInput;
  TournamentBalancesAvgFields: ResolverTypeWrapper<TournamentBalancesAvgFields>;
  TournamentBalancesBoolExp: TournamentBalancesBoolExp;
  TournamentBalancesInsertInput: TournamentBalancesInsertInput;
  TournamentBalancesMaxFields: ResolverTypeWrapper<TournamentBalancesMaxFields>;
  TournamentBalancesMinFields: ResolverTypeWrapper<TournamentBalancesMinFields>;
  TournamentBalancesOrderBy: TournamentBalancesOrderBy;
  TournamentBalancesSelectColumn: TournamentBalancesSelectColumn;
  TournamentBalancesStddevFields: ResolverTypeWrapper<TournamentBalancesStddevFields>;
  TournamentBalancesStddev_popFields: ResolverTypeWrapper<TournamentBalancesStddev_PopFields>;
  TournamentBalancesStddev_sampFields: ResolverTypeWrapper<TournamentBalancesStddev_SampFields>;
  TournamentBalancesStreamCursorInput: TournamentBalancesStreamCursorInput;
  TournamentBalancesStreamCursorValueInput: TournamentBalancesStreamCursorValueInput;
  TournamentBalancesSumFields: ResolverTypeWrapper<TournamentBalancesSumFields>;
  TournamentBalancesVar_popFields: ResolverTypeWrapper<TournamentBalancesVar_PopFields>;
  TournamentBalancesVar_sampFields: ResolverTypeWrapper<TournamentBalancesVar_SampFields>;
  TournamentBalancesVarianceFields: ResolverTypeWrapper<TournamentBalancesVarianceFields>;
  TournamentResultStatuses: ResolverTypeWrapper<TournamentResultStatuses>;
  TournamentResultStatusesAggregate: ResolverTypeWrapper<TournamentResultStatusesAggregate>;
  TournamentResultStatusesAggregateFields: ResolverTypeWrapper<TournamentResultStatusesAggregateFields>;
  TournamentResultStatusesBoolExp: TournamentResultStatusesBoolExp;
  TournamentResultStatusesConstraint: TournamentResultStatusesConstraint;
  TournamentResultStatusesEnum: TournamentResultStatusesEnum;
  TournamentResultStatusesEnumComparisonExp: TournamentResultStatusesEnumComparisonExp;
  TournamentResultStatusesInsertInput: TournamentResultStatusesInsertInput;
  TournamentResultStatusesMaxFields: ResolverTypeWrapper<TournamentResultStatusesMaxFields>;
  TournamentResultStatusesMinFields: ResolverTypeWrapper<TournamentResultStatusesMinFields>;
  TournamentResultStatusesMutationResponse: ResolverTypeWrapper<TournamentResultStatusesMutationResponse>;
  TournamentResultStatusesOnConflict: TournamentResultStatusesOnConflict;
  TournamentResultStatusesOrderBy: TournamentResultStatusesOrderBy;
  TournamentResultStatusesPkColumnsInput: TournamentResultStatusesPkColumnsInput;
  TournamentResultStatusesSelectColumn: TournamentResultStatusesSelectColumn;
  TournamentResultStatusesSetInput: TournamentResultStatusesSetInput;
  TournamentResultStatusesStreamCursorInput: TournamentResultStatusesStreamCursorInput;
  TournamentResultStatusesStreamCursorValueInput: TournamentResultStatusesStreamCursorValueInput;
  TournamentResultStatusesUpdateColumn: TournamentResultStatusesUpdateColumn;
  TournamentResultStatusesUpdates: TournamentResultStatusesUpdates;
  TournamentResults: ResolverTypeWrapper<TournamentResults>;
  TournamentResultsAggregate: ResolverTypeWrapper<TournamentResultsAggregate>;
  TournamentResultsAggregateFields: ResolverTypeWrapper<TournamentResultsAggregateFields>;
  TournamentResultsAggregateOrderBy: TournamentResultsAggregateOrderBy;
  TournamentResultsAppendInput: TournamentResultsAppendInput;
  TournamentResultsArrRelInsertInput: TournamentResultsArrRelInsertInput;
  TournamentResultsAvgFields: ResolverTypeWrapper<TournamentResultsAvgFields>;
  TournamentResultsBoolExp: TournamentResultsBoolExp;
  TournamentResultsConstraint: TournamentResultsConstraint;
  TournamentResultsDeleteAtPathInput: TournamentResultsDeleteAtPathInput;
  TournamentResultsDeleteElemInput: TournamentResultsDeleteElemInput;
  TournamentResultsDeleteKeyInput: TournamentResultsDeleteKeyInput;
  TournamentResultsIncInput: TournamentResultsIncInput;
  TournamentResultsInsertInput: TournamentResultsInsertInput;
  TournamentResultsMaxFields: ResolverTypeWrapper<TournamentResultsMaxFields>;
  TournamentResultsMinFields: ResolverTypeWrapper<TournamentResultsMinFields>;
  TournamentResultsMutationResponse: ResolverTypeWrapper<TournamentResultsMutationResponse>;
  TournamentResultsObjRelInsertInput: TournamentResultsObjRelInsertInput;
  TournamentResultsOnConflict: TournamentResultsOnConflict;
  TournamentResultsOrderBy: TournamentResultsOrderBy;
  TournamentResultsPkColumnsInput: TournamentResultsPkColumnsInput;
  TournamentResultsPrependInput: TournamentResultsPrependInput;
  TournamentResultsSelectColumn: TournamentResultsSelectColumn;
  TournamentResultsSetInput: TournamentResultsSetInput;
  TournamentResultsStddevFields: ResolverTypeWrapper<TournamentResultsStddevFields>;
  TournamentResultsStddev_popFields: ResolverTypeWrapper<TournamentResultsStddev_PopFields>;
  TournamentResultsStddev_sampFields: ResolverTypeWrapper<TournamentResultsStddev_SampFields>;
  TournamentResultsStreamCursorInput: TournamentResultsStreamCursorInput;
  TournamentResultsStreamCursorValueInput: TournamentResultsStreamCursorValueInput;
  TournamentResultsSumFields: ResolverTypeWrapper<TournamentResultsSumFields>;
  TournamentResultsUpdateColumn: TournamentResultsUpdateColumn;
  TournamentResultsUpdates: TournamentResultsUpdates;
  TournamentResultsVar_popFields: ResolverTypeWrapper<TournamentResultsVar_PopFields>;
  TournamentResultsVar_sampFields: ResolverTypeWrapper<TournamentResultsVar_SampFields>;
  TournamentResultsVarianceFields: ResolverTypeWrapper<TournamentResultsVarianceFields>;
  Tournaments: ResolverTypeWrapper<Tournaments>;
  TournamentsAggregate: ResolverTypeWrapper<TournamentsAggregate>;
  TournamentsAggregateFields: ResolverTypeWrapper<TournamentsAggregateFields>;
  TournamentsAggregateOrderBy: TournamentsAggregateOrderBy;
  TournamentsArrRelInsertInput: TournamentsArrRelInsertInput;
  TournamentsAvgFields: ResolverTypeWrapper<TournamentsAvgFields>;
  TournamentsBoolExp: TournamentsBoolExp;
  TournamentsConstraint: TournamentsConstraint;
  TournamentsIncInput: TournamentsIncInput;
  TournamentsInsertInput: TournamentsInsertInput;
  TournamentsMaxFields: ResolverTypeWrapper<TournamentsMaxFields>;
  TournamentsMinFields: ResolverTypeWrapper<TournamentsMinFields>;
  TournamentsMutationResponse: ResolverTypeWrapper<TournamentsMutationResponse>;
  TournamentsObjRelInsertInput: TournamentsObjRelInsertInput;
  TournamentsOnConflict: TournamentsOnConflict;
  TournamentsOrderBy: TournamentsOrderBy;
  TournamentsPkColumnsInput: TournamentsPkColumnsInput;
  TournamentsSelectColumn: TournamentsSelectColumn;
  TournamentsSetInput: TournamentsSetInput;
  TournamentsStddevFields: ResolverTypeWrapper<TournamentsStddevFields>;
  TournamentsStddev_popFields: ResolverTypeWrapper<TournamentsStddev_PopFields>;
  TournamentsStddev_sampFields: ResolverTypeWrapper<TournamentsStddev_SampFields>;
  TournamentsStreamCursorInput: TournamentsStreamCursorInput;
  TournamentsStreamCursorValueInput: TournamentsStreamCursorValueInput;
  TournamentsSumFields: ResolverTypeWrapper<TournamentsSumFields>;
  TournamentsUpdateColumn: TournamentsUpdateColumn;
  TournamentsUpdates: TournamentsUpdates;
  TournamentsVar_popFields: ResolverTypeWrapper<TournamentsVar_PopFields>;
  TournamentsVar_sampFields: ResolverTypeWrapper<TournamentsVar_SampFields>;
  TournamentsVarianceFields: ResolverTypeWrapper<TournamentsVarianceFields>;
  TransactionUserTypes: ResolverTypeWrapper<TransactionUserTypes>;
  TransactionUserTypesAggregate: ResolverTypeWrapper<TransactionUserTypesAggregate>;
  TransactionUserTypesAggregateFields: ResolverTypeWrapper<TransactionUserTypesAggregateFields>;
  TransactionUserTypesBoolExp: TransactionUserTypesBoolExp;
  TransactionUserTypesConstraint: TransactionUserTypesConstraint;
  TransactionUserTypesEnum: TransactionUserTypesEnum;
  TransactionUserTypesEnumComparisonExp: TransactionUserTypesEnumComparisonExp;
  TransactionUserTypesInsertInput: TransactionUserTypesInsertInput;
  TransactionUserTypesMaxFields: ResolverTypeWrapper<TransactionUserTypesMaxFields>;
  TransactionUserTypesMinFields: ResolverTypeWrapper<TransactionUserTypesMinFields>;
  TransactionUserTypesMutationResponse: ResolverTypeWrapper<TransactionUserTypesMutationResponse>;
  TransactionUserTypesOnConflict: TransactionUserTypesOnConflict;
  TransactionUserTypesOrderBy: TransactionUserTypesOrderBy;
  TransactionUserTypesPkColumnsInput: TransactionUserTypesPkColumnsInput;
  TransactionUserTypesSelectColumn: TransactionUserTypesSelectColumn;
  TransactionUserTypesSetInput: TransactionUserTypesSetInput;
  TransactionUserTypesStreamCursorInput: TransactionUserTypesStreamCursorInput;
  TransactionUserTypesStreamCursorValueInput: TransactionUserTypesStreamCursorValueInput;
  TransactionUserTypesUpdateColumn: TransactionUserTypesUpdateColumn;
  TransactionUserTypesUpdates: TransactionUserTypesUpdates;
  Transactions: ResolverTypeWrapper<Transactions>;
  TransactionsAggregate: ResolverTypeWrapper<TransactionsAggregate>;
  TransactionsAggregateFields: ResolverTypeWrapper<TransactionsAggregateFields>;
  TransactionsAggregateOrderBy: TransactionsAggregateOrderBy;
  TransactionsArrRelInsertInput: TransactionsArrRelInsertInput;
  TransactionsAvgFields: ResolverTypeWrapper<TransactionsAvgFields>;
  TransactionsBoolExp: TransactionsBoolExp;
  TransactionsConstraint: TransactionsConstraint;
  TransactionsIncInput: TransactionsIncInput;
  TransactionsInsertInput: TransactionsInsertInput;
  TransactionsMaxFields: ResolverTypeWrapper<TransactionsMaxFields>;
  TransactionsMinFields: ResolverTypeWrapper<TransactionsMinFields>;
  TransactionsMutationResponse: ResolverTypeWrapper<TransactionsMutationResponse>;
  TransactionsOnConflict: TransactionsOnConflict;
  TransactionsOrderBy: TransactionsOrderBy;
  TransactionsPkColumnsInput: TransactionsPkColumnsInput;
  TransactionsSelectColumn: TransactionsSelectColumn;
  TransactionsSetInput: TransactionsSetInput;
  TransactionsStddevFields: ResolverTypeWrapper<TransactionsStddevFields>;
  TransactionsStddev_popFields: ResolverTypeWrapper<TransactionsStddev_PopFields>;
  TransactionsStddev_sampFields: ResolverTypeWrapper<TransactionsStddev_SampFields>;
  TransactionsStreamCursorInput: TransactionsStreamCursorInput;
  TransactionsStreamCursorValueInput: TransactionsStreamCursorValueInput;
  TransactionsSumFields: ResolverTypeWrapper<TransactionsSumFields>;
  TransactionsUpdateColumn: TransactionsUpdateColumn;
  TransactionsUpdates: TransactionsUpdates;
  TransactionsVar_popFields: ResolverTypeWrapper<TransactionsVar_PopFields>;
  TransactionsVar_sampFields: ResolverTypeWrapper<TransactionsVar_SampFields>;
  TransactionsVarianceFields: ResolverTypeWrapper<TransactionsVarianceFields>;
  UserBalances: ResolverTypeWrapper<UserBalances>;
  UserBalancesAggregate: ResolverTypeWrapper<UserBalancesAggregate>;
  UserBalancesAggregateFields: ResolverTypeWrapper<UserBalancesAggregateFields>;
  UserBalancesAggregateOrderBy: UserBalancesAggregateOrderBy;
  UserBalancesArrRelInsertInput: UserBalancesArrRelInsertInput;
  UserBalancesAvgFields: ResolverTypeWrapper<UserBalancesAvgFields>;
  UserBalancesBoolExp: UserBalancesBoolExp;
  UserBalancesInsertInput: UserBalancesInsertInput;
  UserBalancesMaxFields: ResolverTypeWrapper<UserBalancesMaxFields>;
  UserBalancesMinFields: ResolverTypeWrapper<UserBalancesMinFields>;
  UserBalancesOrderBy: UserBalancesOrderBy;
  UserBalancesSelectColumn: UserBalancesSelectColumn;
  UserBalancesStddevFields: ResolverTypeWrapper<UserBalancesStddevFields>;
  UserBalancesStddev_popFields: ResolverTypeWrapper<UserBalancesStddev_PopFields>;
  UserBalancesStddev_sampFields: ResolverTypeWrapper<UserBalancesStddev_SampFields>;
  UserBalancesStreamCursorInput: UserBalancesStreamCursorInput;
  UserBalancesStreamCursorValueInput: UserBalancesStreamCursorValueInput;
  UserBalancesSumFields: ResolverTypeWrapper<UserBalancesSumFields>;
  UserBalancesVar_popFields: ResolverTypeWrapper<UserBalancesVar_PopFields>;
  UserBalancesVar_sampFields: ResolverTypeWrapper<UserBalancesVar_SampFields>;
  UserBalancesVarianceFields: ResolverTypeWrapper<UserBalancesVarianceFields>;
  UserOutput: ResolverTypeWrapper<UserOutput>;
  UserShareBuys: ResolverTypeWrapper<UserShareBuys>;
  UserShareBuysAggregate: ResolverTypeWrapper<UserShareBuysAggregate>;
  UserShareBuysAggregateFields: ResolverTypeWrapper<UserShareBuysAggregateFields>;
  UserShareBuysAggregateOrderBy: UserShareBuysAggregateOrderBy;
  UserShareBuysArrRelInsertInput: UserShareBuysArrRelInsertInput;
  UserShareBuysAvgFields: ResolverTypeWrapper<UserShareBuysAvgFields>;
  UserShareBuysBoolExp: UserShareBuysBoolExp;
  UserShareBuysConstraint: UserShareBuysConstraint;
  UserShareBuysIncInput: UserShareBuysIncInput;
  UserShareBuysInsertInput: UserShareBuysInsertInput;
  UserShareBuysMaxFields: ResolverTypeWrapper<UserShareBuysMaxFields>;
  UserShareBuysMinFields: ResolverTypeWrapper<UserShareBuysMinFields>;
  UserShareBuysMutationResponse: ResolverTypeWrapper<UserShareBuysMutationResponse>;
  UserShareBuysObjRelInsertInput: UserShareBuysObjRelInsertInput;
  UserShareBuysOnConflict: UserShareBuysOnConflict;
  UserShareBuysOrderBy: UserShareBuysOrderBy;
  UserShareBuysPkColumnsInput: UserShareBuysPkColumnsInput;
  UserShareBuysSelectColumn: UserShareBuysSelectColumn;
  UserShareBuysSetInput: UserShareBuysSetInput;
  UserShareBuysStddevFields: ResolverTypeWrapper<UserShareBuysStddevFields>;
  UserShareBuysStddev_popFields: ResolverTypeWrapper<UserShareBuysStddev_PopFields>;
  UserShareBuysStddev_sampFields: ResolverTypeWrapper<UserShareBuysStddev_SampFields>;
  UserShareBuysStreamCursorInput: UserShareBuysStreamCursorInput;
  UserShareBuysStreamCursorValueInput: UserShareBuysStreamCursorValueInput;
  UserShareBuysSumFields: ResolverTypeWrapper<UserShareBuysSumFields>;
  UserShareBuysUpdateColumn: UserShareBuysUpdateColumn;
  UserShareBuysUpdates: UserShareBuysUpdates;
  UserShareBuysVar_popFields: ResolverTypeWrapper<UserShareBuysVar_PopFields>;
  UserShareBuysVar_sampFields: ResolverTypeWrapper<UserShareBuysVar_SampFields>;
  UserShareBuysVarianceFields: ResolverTypeWrapper<UserShareBuysVarianceFields>;
  Users: ResolverTypeWrapper<Users>;
  UsersAggregate: ResolverTypeWrapper<UsersAggregate>;
  UsersAggregateFields: ResolverTypeWrapper<UsersAggregateFields>;
  UsersAvgFields: ResolverTypeWrapper<UsersAvgFields>;
  UsersBoolExp: UsersBoolExp;
  UsersConstraint: UsersConstraint;
  UsersIncInput: UsersIncInput;
  UsersInsertInput: UsersInsertInput;
  UsersMaxFields: ResolverTypeWrapper<UsersMaxFields>;
  UsersMinFields: ResolverTypeWrapper<UsersMinFields>;
  UsersMutationResponse: ResolverTypeWrapper<UsersMutationResponse>;
  UsersObjRelInsertInput: UsersObjRelInsertInput;
  UsersOnConflict: UsersOnConflict;
  UsersOrderBy: UsersOrderBy;
  UsersPkColumnsInput: UsersPkColumnsInput;
  UsersSelectColumn: UsersSelectColumn;
  UsersSetInput: UsersSetInput;
  UsersStddevFields: ResolverTypeWrapper<UsersStddevFields>;
  UsersStddev_popFields: ResolverTypeWrapper<UsersStddev_PopFields>;
  UsersStddev_sampFields: ResolverTypeWrapper<UsersStddev_SampFields>;
  UsersStreamCursorInput: UsersStreamCursorInput;
  UsersStreamCursorValueInput: UsersStreamCursorValueInput;
  UsersSumFields: ResolverTypeWrapper<UsersSumFields>;
  UsersUpdateColumn: UsersUpdateColumn;
  UsersUpdates: UsersUpdates;
  UsersVar_popFields: ResolverTypeWrapper<UsersVar_PopFields>;
  UsersVar_sampFields: ResolverTypeWrapper<UsersVar_SampFields>;
  UsersVarianceFields: ResolverTypeWrapper<UsersVarianceFields>;
  UuidComparisonExp: UuidComparisonExp;
  VenueUsers: ResolverTypeWrapper<VenueUsers>;
  VenueUsersAggregate: ResolverTypeWrapper<VenueUsersAggregate>;
  VenueUsersAggregateFields: ResolverTypeWrapper<VenueUsersAggregateFields>;
  VenueUsersAggregateOrderBy: VenueUsersAggregateOrderBy;
  VenueUsersArrRelInsertInput: VenueUsersArrRelInsertInput;
  VenueUsersAvgFields: ResolverTypeWrapper<VenueUsersAvgFields>;
  VenueUsersBoolExp: VenueUsersBoolExp;
  VenueUsersConstraint: VenueUsersConstraint;
  VenueUsersIncInput: VenueUsersIncInput;
  VenueUsersInsertInput: VenueUsersInsertInput;
  VenueUsersMaxFields: ResolverTypeWrapper<VenueUsersMaxFields>;
  VenueUsersMinFields: ResolverTypeWrapper<VenueUsersMinFields>;
  VenueUsersMutationResponse: ResolverTypeWrapper<VenueUsersMutationResponse>;
  VenueUsersOnConflict: VenueUsersOnConflict;
  VenueUsersOrderBy: VenueUsersOrderBy;
  VenueUsersPkColumnsInput: VenueUsersPkColumnsInput;
  VenueUsersSelectColumn: VenueUsersSelectColumn;
  VenueUsersSetInput: VenueUsersSetInput;
  VenueUsersStddevFields: ResolverTypeWrapper<VenueUsersStddevFields>;
  VenueUsersStddev_popFields: ResolverTypeWrapper<VenueUsersStddev_PopFields>;
  VenueUsersStddev_sampFields: ResolverTypeWrapper<VenueUsersStddev_SampFields>;
  VenueUsersStreamCursorInput: VenueUsersStreamCursorInput;
  VenueUsersStreamCursorValueInput: VenueUsersStreamCursorValueInput;
  VenueUsersSumFields: ResolverTypeWrapper<VenueUsersSumFields>;
  VenueUsersUpdateColumn: VenueUsersUpdateColumn;
  VenueUsersUpdates: VenueUsersUpdates;
  VenueUsersVar_popFields: ResolverTypeWrapper<VenueUsersVar_PopFields>;
  VenueUsersVar_sampFields: ResolverTypeWrapper<VenueUsersVar_SampFields>;
  VenueUsersVarianceFields: ResolverTypeWrapper<VenueUsersVarianceFields>;
  Venues: ResolverTypeWrapper<Venues>;
  VenuesAggregate: ResolverTypeWrapper<VenuesAggregate>;
  VenuesAggregateFields: ResolverTypeWrapper<VenuesAggregateFields>;
  VenuesAvgFields: ResolverTypeWrapper<VenuesAvgFields>;
  VenuesBoolExp: VenuesBoolExp;
  VenuesConstraint: VenuesConstraint;
  VenuesIncInput: VenuesIncInput;
  VenuesInsertInput: VenuesInsertInput;
  VenuesMaxFields: ResolverTypeWrapper<VenuesMaxFields>;
  VenuesMinFields: ResolverTypeWrapper<VenuesMinFields>;
  VenuesMutationResponse: ResolverTypeWrapper<VenuesMutationResponse>;
  VenuesObjRelInsertInput: VenuesObjRelInsertInput;
  VenuesOnConflict: VenuesOnConflict;
  VenuesOrderBy: VenuesOrderBy;
  VenuesPkColumnsInput: VenuesPkColumnsInput;
  VenuesSelectColumn: VenuesSelectColumn;
  VenuesSetInput: VenuesSetInput;
  VenuesStddevFields: ResolverTypeWrapper<VenuesStddevFields>;
  VenuesStddev_popFields: ResolverTypeWrapper<VenuesStddev_PopFields>;
  VenuesStddev_sampFields: ResolverTypeWrapper<VenuesStddev_SampFields>;
  VenuesStreamCursorInput: VenuesStreamCursorInput;
  VenuesStreamCursorValueInput: VenuesStreamCursorValueInput;
  VenuesSumFields: ResolverTypeWrapper<VenuesSumFields>;
  VenuesUpdateColumn: VenuesUpdateColumn;
  VenuesUpdates: VenuesUpdates;
  VenuesVar_popFields: ResolverTypeWrapper<VenuesVar_PopFields>;
  VenuesVar_sampFields: ResolverTypeWrapper<VenuesVar_SampFields>;
  VenuesVarianceFields: ResolverTypeWrapper<VenuesVarianceFields>;
  activate2FAOutput: ResolverTypeWrapper<Activate2FaOutput>;
  authLoginOutput: ResolverTypeWrapper<AuthLoginOutput>;
  authSignatureOutput: ResolverTypeWrapper<AuthSignatureOutput>;
  bigint: ResolverTypeWrapper<Scalars['bigint']['output']>;
  blockchain_currencies_aggregate_bool_exp: Blockchain_Currencies_Aggregate_Bool_Exp;
  blockchain_currencies_aggregate_bool_exp_bool_and: Blockchain_Currencies_Aggregate_Bool_Exp_Bool_And;
  blockchain_currencies_aggregate_bool_exp_bool_or: Blockchain_Currencies_Aggregate_Bool_Exp_Bool_Or;
  blockchain_currencies_aggregate_bool_exp_count: Blockchain_Currencies_Aggregate_Bool_Exp_Count;
  blockchain_currencies_avg_order_by: Blockchain_Currencies_Avg_Order_By;
  blockchain_currencies_max_order_by: Blockchain_Currencies_Max_Order_By;
  blockchain_currencies_min_order_by: Blockchain_Currencies_Min_Order_By;
  blockchain_currencies_select_column_blockchain_currencies_aggregate_bool_exp_bool_and_arguments_columns: Blockchain_Currencies_Select_Column_Blockchain_Currencies_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  blockchain_currencies_select_column_blockchain_currencies_aggregate_bool_exp_bool_or_arguments_columns: Blockchain_Currencies_Select_Column_Blockchain_Currencies_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  blockchain_currencies_stddev_order_by: Blockchain_Currencies_Stddev_Order_By;
  blockchain_currencies_stddev_pop_order_by: Blockchain_Currencies_Stddev_Pop_Order_By;
  blockchain_currencies_stddev_samp_order_by: Blockchain_Currencies_Stddev_Samp_Order_By;
  blockchain_currencies_sum_order_by: Blockchain_Currencies_Sum_Order_By;
  blockchain_currencies_var_pop_order_by: Blockchain_Currencies_Var_Pop_Order_By;
  blockchain_currencies_var_samp_order_by: Blockchain_Currencies_Var_Samp_Order_By;
  blockchain_currencies_variance_order_by: Blockchain_Currencies_Variance_Order_By;
  currencies_aggregate_bool_exp: Currencies_Aggregate_Bool_Exp;
  currencies_aggregate_bool_exp_bool_and: Currencies_Aggregate_Bool_Exp_Bool_And;
  currencies_aggregate_bool_exp_bool_or: Currencies_Aggregate_Bool_Exp_Bool_Or;
  currencies_aggregate_bool_exp_count: Currencies_Aggregate_Bool_Exp_Count;
  currencies_avg_order_by: Currencies_Avg_Order_By;
  currencies_max_order_by: Currencies_Max_Order_By;
  currencies_min_order_by: Currencies_Min_Order_By;
  currencies_select_column_currencies_aggregate_bool_exp_bool_and_arguments_columns: Currencies_Select_Column_Currencies_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  currencies_select_column_currencies_aggregate_bool_exp_bool_or_arguments_columns: Currencies_Select_Column_Currencies_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  currencies_stddev_order_by: Currencies_Stddev_Order_By;
  currencies_stddev_pop_order_by: Currencies_Stddev_Pop_Order_By;
  currencies_stddev_samp_order_by: Currencies_Stddev_Samp_Order_By;
  currencies_sum_order_by: Currencies_Sum_Order_By;
  currencies_var_pop_order_by: Currencies_Var_Pop_Order_By;
  currencies_var_samp_order_by: Currencies_Var_Samp_Order_By;
  currencies_variance_order_by: Currencies_Variance_Order_By;
  date: ResolverTypeWrapper<Scalars['date']['output']>;
  depositAddressOutput: ResolverTypeWrapper<DepositAddressOutput>;
  depositBankOutput: ResolverTypeWrapper<DepositBankOutput>;
  downloadFileOutput: ResolverTypeWrapper<DownloadFileOutput>;
  events_aggregate_bool_exp: Events_Aggregate_Bool_Exp;
  events_aggregate_bool_exp_count: Events_Aggregate_Bool_Exp_Count;
  events_avg_order_by: Events_Avg_Order_By;
  events_max_order_by: Events_Max_Order_By;
  events_min_order_by: Events_Min_Order_By;
  events_stddev_order_by: Events_Stddev_Order_By;
  events_stddev_pop_order_by: Events_Stddev_Pop_Order_By;
  events_stddev_samp_order_by: Events_Stddev_Samp_Order_By;
  events_sum_order_by: Events_Sum_Order_By;
  events_var_pop_order_by: Events_Var_Pop_Order_By;
  events_var_samp_order_by: Events_Var_Samp_Order_By;
  events_variance_order_by: Events_Variance_Order_By;
  followers_aggregate_bool_exp: Followers_Aggregate_Bool_Exp;
  followers_aggregate_bool_exp_count: Followers_Aggregate_Bool_Exp_Count;
  followers_avg_order_by: Followers_Avg_Order_By;
  followers_max_order_by: Followers_Max_Order_By;
  followers_min_order_by: Followers_Min_Order_By;
  followers_stddev_order_by: Followers_Stddev_Order_By;
  followers_stddev_pop_order_by: Followers_Stddev_Pop_Order_By;
  followers_stddev_samp_order_by: Followers_Stddev_Samp_Order_By;
  followers_sum_order_by: Followers_Sum_Order_By;
  followers_var_pop_order_by: Followers_Var_Pop_Order_By;
  followers_var_samp_order_by: Followers_Var_Samp_Order_By;
  followers_variance_order_by: Followers_Variance_Order_By;
  genderChoice: GenderChoice;
  generalOutput: ResolverTypeWrapper<GeneralOutput>;
  generalStatus: GeneralStatus;
  jsonb: ResolverTypeWrapper<Scalars['jsonb']['output']>;
  mutation_root: ResolverTypeWrapper<{}>;
  numeric: ResolverTypeWrapper<Scalars['numeric']['output']>;
  payment_method_banks_aggregate_bool_exp: Payment_Method_Banks_Aggregate_Bool_Exp;
  payment_method_banks_aggregate_bool_exp_bool_and: Payment_Method_Banks_Aggregate_Bool_Exp_Bool_And;
  payment_method_banks_aggregate_bool_exp_bool_or: Payment_Method_Banks_Aggregate_Bool_Exp_Bool_Or;
  payment_method_banks_aggregate_bool_exp_count: Payment_Method_Banks_Aggregate_Bool_Exp_Count;
  payment_method_banks_avg_order_by: Payment_Method_Banks_Avg_Order_By;
  payment_method_banks_max_order_by: Payment_Method_Banks_Max_Order_By;
  payment_method_banks_min_order_by: Payment_Method_Banks_Min_Order_By;
  payment_method_banks_select_column_payment_method_banks_aggregate_bool_exp_bool_and_arguments_columns: Payment_Method_Banks_Select_Column_Payment_Method_Banks_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  payment_method_banks_select_column_payment_method_banks_aggregate_bool_exp_bool_or_arguments_columns: Payment_Method_Banks_Select_Column_Payment_Method_Banks_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  payment_method_banks_stddev_order_by: Payment_Method_Banks_Stddev_Order_By;
  payment_method_banks_stddev_pop_order_by: Payment_Method_Banks_Stddev_Pop_Order_By;
  payment_method_banks_stddev_samp_order_by: Payment_Method_Banks_Stddev_Samp_Order_By;
  payment_method_banks_sum_order_by: Payment_Method_Banks_Sum_Order_By;
  payment_method_banks_var_pop_order_by: Payment_Method_Banks_Var_Pop_Order_By;
  payment_method_banks_var_samp_order_by: Payment_Method_Banks_Var_Samp_Order_By;
  payment_method_banks_variance_order_by: Payment_Method_Banks_Variance_Order_By;
  playerRegisterInput: PlayerRegisterInput;
  playerShareAction: PlayerShareAction;
  playerShareResultOutput: ResolverTypeWrapper<PlayerShareResultOutput>;
  playerStatus: PlayerStatus;
  player_followers_view_aggregate_bool_exp: Player_Followers_View_Aggregate_Bool_Exp;
  player_followers_view_aggregate_bool_exp_count: Player_Followers_View_Aggregate_Bool_Exp_Count;
  player_followers_view_avg_order_by: Player_Followers_View_Avg_Order_By;
  player_followers_view_max_order_by: Player_Followers_View_Max_Order_By;
  player_followers_view_min_order_by: Player_Followers_View_Min_Order_By;
  player_followers_view_stddev_order_by: Player_Followers_View_Stddev_Order_By;
  player_followers_view_stddev_pop_order_by: Player_Followers_View_Stddev_Pop_Order_By;
  player_followers_view_stddev_samp_order_by: Player_Followers_View_Stddev_Samp_Order_By;
  player_followers_view_sum_order_by: Player_Followers_View_Sum_Order_By;
  player_followers_view_var_pop_order_by: Player_Followers_View_Var_Pop_Order_By;
  player_followers_view_var_samp_order_by: Player_Followers_View_Var_Samp_Order_By;
  player_followers_view_variance_order_by: Player_Followers_View_Variance_Order_By;
  player_shares_aggregate_bool_exp: Player_Shares_Aggregate_Bool_Exp;
  player_shares_aggregate_bool_exp_bool_and: Player_Shares_Aggregate_Bool_Exp_Bool_And;
  player_shares_aggregate_bool_exp_bool_or: Player_Shares_Aggregate_Bool_Exp_Bool_Or;
  player_shares_aggregate_bool_exp_count: Player_Shares_Aggregate_Bool_Exp_Count;
  player_shares_avg_order_by: Player_Shares_Avg_Order_By;
  player_shares_max_order_by: Player_Shares_Max_Order_By;
  player_shares_min_order_by: Player_Shares_Min_Order_By;
  player_shares_select_column_player_shares_aggregate_bool_exp_bool_and_arguments_columns: Player_Shares_Select_Column_Player_Shares_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  player_shares_select_column_player_shares_aggregate_bool_exp_bool_or_arguments_columns: Player_Shares_Select_Column_Player_Shares_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  player_shares_stddev_order_by: Player_Shares_Stddev_Order_By;
  player_shares_stddev_pop_order_by: Player_Shares_Stddev_Pop_Order_By;
  player_shares_stddev_samp_order_by: Player_Shares_Stddev_Samp_Order_By;
  player_shares_sum_order_by: Player_Shares_Sum_Order_By;
  player_shares_var_pop_order_by: Player_Shares_Var_Pop_Order_By;
  player_shares_var_samp_order_by: Player_Shares_Var_Samp_Order_By;
  player_shares_variance_order_by: Player_Shares_Variance_Order_By;
  players_aggregate_bool_exp: Players_Aggregate_Bool_Exp;
  players_aggregate_bool_exp_bool_and: Players_Aggregate_Bool_Exp_Bool_And;
  players_aggregate_bool_exp_bool_or: Players_Aggregate_Bool_Exp_Bool_Or;
  players_aggregate_bool_exp_count: Players_Aggregate_Bool_Exp_Count;
  players_avg_order_by: Players_Avg_Order_By;
  players_max_order_by: Players_Max_Order_By;
  players_min_order_by: Players_Min_Order_By;
  players_select_column_players_aggregate_bool_exp_bool_and_arguments_columns: Players_Select_Column_Players_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  players_select_column_players_aggregate_bool_exp_bool_or_arguments_columns: Players_Select_Column_Players_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  players_stddev_order_by: Players_Stddev_Order_By;
  players_stddev_pop_order_by: Players_Stddev_Pop_Order_By;
  players_stddev_samp_order_by: Players_Stddev_Samp_Order_By;
  players_sum_order_by: Players_Sum_Order_By;
  players_var_pop_order_by: Players_Var_Pop_Order_By;
  players_var_samp_order_by: Players_Var_Samp_Order_By;
  players_variance_order_by: Players_Variance_Order_By;
  providerEnum: ProviderEnum;
  query_root: ResolverTypeWrapper<{}>;
  stat_tournament_results_aggregate_bool_exp: Stat_Tournament_Results_Aggregate_Bool_Exp;
  stat_tournament_results_aggregate_bool_exp_count: Stat_Tournament_Results_Aggregate_Bool_Exp_Count;
  stat_tournament_results_avg_order_by: Stat_Tournament_Results_Avg_Order_By;
  stat_tournament_results_max_order_by: Stat_Tournament_Results_Max_Order_By;
  stat_tournament_results_min_order_by: Stat_Tournament_Results_Min_Order_By;
  stat_tournament_results_stddev_order_by: Stat_Tournament_Results_Stddev_Order_By;
  stat_tournament_results_stddev_pop_order_by: Stat_Tournament_Results_Stddev_Pop_Order_By;
  stat_tournament_results_stddev_samp_order_by: Stat_Tournament_Results_Stddev_Samp_Order_By;
  stat_tournament_results_sum_order_by: Stat_Tournament_Results_Sum_Order_By;
  stat_tournament_results_var_pop_order_by: Stat_Tournament_Results_Var_Pop_Order_By;
  stat_tournament_results_var_samp_order_by: Stat_Tournament_Results_Var_Samp_Order_By;
  stat_tournament_results_variance_order_by: Stat_Tournament_Results_Variance_Order_By;
  subscription_root: ResolverTypeWrapper<{}>;
  tickets_aggregate_bool_exp: Tickets_Aggregate_Bool_Exp;
  tickets_aggregate_bool_exp_count: Tickets_Aggregate_Bool_Exp_Count;
  tickets_avg_order_by: Tickets_Avg_Order_By;
  tickets_max_order_by: Tickets_Max_Order_By;
  tickets_min_order_by: Tickets_Min_Order_By;
  tickets_stddev_order_by: Tickets_Stddev_Order_By;
  tickets_stddev_pop_order_by: Tickets_Stddev_Pop_Order_By;
  tickets_stddev_samp_order_by: Tickets_Stddev_Samp_Order_By;
  tickets_sum_order_by: Tickets_Sum_Order_By;
  tickets_var_pop_order_by: Tickets_Var_Pop_Order_By;
  tickets_var_samp_order_by: Tickets_Var_Samp_Order_By;
  tickets_variance_order_by: Tickets_Variance_Order_By;
  timestamp: ResolverTypeWrapper<Scalars['timestamp']['output']>;
  timestamptz: ResolverTypeWrapper<Scalars['timestamptz']['output']>;
  tournament_balances_aggregate_bool_exp: Tournament_Balances_Aggregate_Bool_Exp;
  tournament_balances_aggregate_bool_exp_count: Tournament_Balances_Aggregate_Bool_Exp_Count;
  tournament_balances_avg_order_by: Tournament_Balances_Avg_Order_By;
  tournament_balances_max_order_by: Tournament_Balances_Max_Order_By;
  tournament_balances_min_order_by: Tournament_Balances_Min_Order_By;
  tournament_balances_stddev_order_by: Tournament_Balances_Stddev_Order_By;
  tournament_balances_stddev_pop_order_by: Tournament_Balances_Stddev_Pop_Order_By;
  tournament_balances_stddev_samp_order_by: Tournament_Balances_Stddev_Samp_Order_By;
  tournament_balances_sum_order_by: Tournament_Balances_Sum_Order_By;
  tournament_balances_var_pop_order_by: Tournament_Balances_Var_Pop_Order_By;
  tournament_balances_var_samp_order_by: Tournament_Balances_Var_Samp_Order_By;
  tournament_balances_variance_order_by: Tournament_Balances_Variance_Order_By;
  tournament_results_aggregate_bool_exp: Tournament_Results_Aggregate_Bool_Exp;
  tournament_results_aggregate_bool_exp_count: Tournament_Results_Aggregate_Bool_Exp_Count;
  tournament_results_avg_order_by: Tournament_Results_Avg_Order_By;
  tournament_results_max_order_by: Tournament_Results_Max_Order_By;
  tournament_results_min_order_by: Tournament_Results_Min_Order_By;
  tournament_results_stddev_order_by: Tournament_Results_Stddev_Order_By;
  tournament_results_stddev_pop_order_by: Tournament_Results_Stddev_Pop_Order_By;
  tournament_results_stddev_samp_order_by: Tournament_Results_Stddev_Samp_Order_By;
  tournament_results_sum_order_by: Tournament_Results_Sum_Order_By;
  tournament_results_var_pop_order_by: Tournament_Results_Var_Pop_Order_By;
  tournament_results_var_samp_order_by: Tournament_Results_Var_Samp_Order_By;
  tournament_results_variance_order_by: Tournament_Results_Variance_Order_By;
  tournaments_aggregate_bool_exp: Tournaments_Aggregate_Bool_Exp;
  tournaments_aggregate_bool_exp_bool_and: Tournaments_Aggregate_Bool_Exp_Bool_And;
  tournaments_aggregate_bool_exp_bool_or: Tournaments_Aggregate_Bool_Exp_Bool_Or;
  tournaments_aggregate_bool_exp_count: Tournaments_Aggregate_Bool_Exp_Count;
  tournaments_avg_order_by: Tournaments_Avg_Order_By;
  tournaments_max_order_by: Tournaments_Max_Order_By;
  tournaments_min_order_by: Tournaments_Min_Order_By;
  tournaments_select_column_tournaments_aggregate_bool_exp_bool_and_arguments_columns: Tournaments_Select_Column_Tournaments_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  tournaments_select_column_tournaments_aggregate_bool_exp_bool_or_arguments_columns: Tournaments_Select_Column_Tournaments_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  tournaments_stddev_order_by: Tournaments_Stddev_Order_By;
  tournaments_stddev_pop_order_by: Tournaments_Stddev_Pop_Order_By;
  tournaments_stddev_samp_order_by: Tournaments_Stddev_Samp_Order_By;
  tournaments_sum_order_by: Tournaments_Sum_Order_By;
  tournaments_var_pop_order_by: Tournaments_Var_Pop_Order_By;
  tournaments_var_samp_order_by: Tournaments_Var_Samp_Order_By;
  tournaments_variance_order_by: Tournaments_Variance_Order_By;
  transactionStatus: TransactionStatus;
  transactions_aggregate_bool_exp: Transactions_Aggregate_Bool_Exp;
  transactions_aggregate_bool_exp_count: Transactions_Aggregate_Bool_Exp_Count;
  transactions_avg_order_by: Transactions_Avg_Order_By;
  transactions_max_order_by: Transactions_Max_Order_By;
  transactions_min_order_by: Transactions_Min_Order_By;
  transactions_stddev_order_by: Transactions_Stddev_Order_By;
  transactions_stddev_pop_order_by: Transactions_Stddev_Pop_Order_By;
  transactions_stddev_samp_order_by: Transactions_Stddev_Samp_Order_By;
  transactions_sum_order_by: Transactions_Sum_Order_By;
  transactions_var_pop_order_by: Transactions_Var_Pop_Order_By;
  transactions_var_samp_order_by: Transactions_Var_Samp_Order_By;
  transactions_variance_order_by: Transactions_Variance_Order_By;
  userStatus: UserStatus;
  user_balances_aggregate_bool_exp: User_Balances_Aggregate_Bool_Exp;
  user_balances_aggregate_bool_exp_count: User_Balances_Aggregate_Bool_Exp_Count;
  user_balances_avg_order_by: User_Balances_Avg_Order_By;
  user_balances_max_order_by: User_Balances_Max_Order_By;
  user_balances_min_order_by: User_Balances_Min_Order_By;
  user_balances_stddev_order_by: User_Balances_Stddev_Order_By;
  user_balances_stddev_pop_order_by: User_Balances_Stddev_Pop_Order_By;
  user_balances_stddev_samp_order_by: User_Balances_Stddev_Samp_Order_By;
  user_balances_sum_order_by: User_Balances_Sum_Order_By;
  user_balances_var_pop_order_by: User_Balances_Var_Pop_Order_By;
  user_balances_var_samp_order_by: User_Balances_Var_Samp_Order_By;
  user_balances_variance_order_by: User_Balances_Variance_Order_By;
  user_share_buys_aggregate_bool_exp: User_Share_Buys_Aggregate_Bool_Exp;
  user_share_buys_aggregate_bool_exp_count: User_Share_Buys_Aggregate_Bool_Exp_Count;
  user_share_buys_avg_order_by: User_Share_Buys_Avg_Order_By;
  user_share_buys_max_order_by: User_Share_Buys_Max_Order_By;
  user_share_buys_min_order_by: User_Share_Buys_Min_Order_By;
  user_share_buys_stddev_order_by: User_Share_Buys_Stddev_Order_By;
  user_share_buys_stddev_pop_order_by: User_Share_Buys_Stddev_Pop_Order_By;
  user_share_buys_stddev_samp_order_by: User_Share_Buys_Stddev_Samp_Order_By;
  user_share_buys_sum_order_by: User_Share_Buys_Sum_Order_By;
  user_share_buys_var_pop_order_by: User_Share_Buys_Var_Pop_Order_By;
  user_share_buys_var_samp_order_by: User_Share_Buys_Var_Samp_Order_By;
  user_share_buys_variance_order_by: User_Share_Buys_Variance_Order_By;
  uuid: ResolverTypeWrapper<Scalars['uuid']['output']>;
  venue_users_aggregate_bool_exp: Venue_Users_Aggregate_Bool_Exp;
  venue_users_aggregate_bool_exp_count: Venue_Users_Aggregate_Bool_Exp_Count;
  venue_users_avg_order_by: Venue_Users_Avg_Order_By;
  venue_users_max_order_by: Venue_Users_Max_Order_By;
  venue_users_min_order_by: Venue_Users_Min_Order_By;
  venue_users_stddev_order_by: Venue_Users_Stddev_Order_By;
  venue_users_stddev_pop_order_by: Venue_Users_Stddev_Pop_Order_By;
  venue_users_stddev_samp_order_by: Venue_Users_Stddev_Samp_Order_By;
  venue_users_sum_order_by: Venue_Users_Sum_Order_By;
  venue_users_var_pop_order_by: Venue_Users_Var_Pop_Order_By;
  venue_users_var_samp_order_by: Venue_Users_Var_Samp_Order_By;
  venue_users_variance_order_by: Venue_Users_Variance_Order_By;
};

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  BigintComparisonExp: BigintComparisonExp;
  BlockchainCurrencies: BlockchainCurrencies;
  BlockchainCurrenciesAggregate: BlockchainCurrenciesAggregate;
  BlockchainCurrenciesAggregateFields: BlockchainCurrenciesAggregateFields;
  BlockchainCurrenciesAggregateOrderBy: BlockchainCurrenciesAggregateOrderBy;
  BlockchainCurrenciesArrRelInsertInput: BlockchainCurrenciesArrRelInsertInput;
  BlockchainCurrenciesAvgFields: BlockchainCurrenciesAvgFields;
  BlockchainCurrenciesBoolExp: BlockchainCurrenciesBoolExp;
  BlockchainCurrenciesIncInput: BlockchainCurrenciesIncInput;
  BlockchainCurrenciesInsertInput: BlockchainCurrenciesInsertInput;
  BlockchainCurrenciesMaxFields: BlockchainCurrenciesMaxFields;
  BlockchainCurrenciesMinFields: BlockchainCurrenciesMinFields;
  BlockchainCurrenciesMutationResponse: BlockchainCurrenciesMutationResponse;
  BlockchainCurrenciesObjRelInsertInput: BlockchainCurrenciesObjRelInsertInput;
  BlockchainCurrenciesOnConflict: BlockchainCurrenciesOnConflict;
  BlockchainCurrenciesOrderBy: BlockchainCurrenciesOrderBy;
  BlockchainCurrenciesPkColumnsInput: BlockchainCurrenciesPkColumnsInput;
  BlockchainCurrenciesSetInput: BlockchainCurrenciesSetInput;
  BlockchainCurrenciesStddevFields: BlockchainCurrenciesStddevFields;
  BlockchainCurrenciesStddev_popFields: BlockchainCurrenciesStddev_PopFields;
  BlockchainCurrenciesStddev_sampFields: BlockchainCurrenciesStddev_SampFields;
  BlockchainCurrenciesStreamCursorInput: BlockchainCurrenciesStreamCursorInput;
  BlockchainCurrenciesStreamCursorValueInput: BlockchainCurrenciesStreamCursorValueInput;
  BlockchainCurrenciesSumFields: BlockchainCurrenciesSumFields;
  BlockchainCurrenciesUpdates: BlockchainCurrenciesUpdates;
  BlockchainCurrenciesVar_popFields: BlockchainCurrenciesVar_PopFields;
  BlockchainCurrenciesVar_sampFields: BlockchainCurrenciesVar_SampFields;
  BlockchainCurrenciesVarianceFields: BlockchainCurrenciesVarianceFields;
  Blockchains: Blockchains;
  BlockchainsAggregate: BlockchainsAggregate;
  BlockchainsAggregateFields: BlockchainsAggregateFields;
  BlockchainsAppendInput: BlockchainsAppendInput;
  BlockchainsAvgFields: BlockchainsAvgFields;
  BlockchainsBoolExp: BlockchainsBoolExp;
  BlockchainsDeleteAtPathInput: BlockchainsDeleteAtPathInput;
  BlockchainsDeleteElemInput: BlockchainsDeleteElemInput;
  BlockchainsDeleteKeyInput: BlockchainsDeleteKeyInput;
  BlockchainsIncInput: BlockchainsIncInput;
  BlockchainsInsertInput: BlockchainsInsertInput;
  BlockchainsMaxFields: BlockchainsMaxFields;
  BlockchainsMinFields: BlockchainsMinFields;
  BlockchainsMutationResponse: BlockchainsMutationResponse;
  BlockchainsObjRelInsertInput: BlockchainsObjRelInsertInput;
  BlockchainsOnConflict: BlockchainsOnConflict;
  BlockchainsOrderBy: BlockchainsOrderBy;
  BlockchainsPkColumnsInput: BlockchainsPkColumnsInput;
  BlockchainsPrependInput: BlockchainsPrependInput;
  BlockchainsSetInput: BlockchainsSetInput;
  BlockchainsStddevFields: BlockchainsStddevFields;
  BlockchainsStddev_popFields: BlockchainsStddev_PopFields;
  BlockchainsStddev_sampFields: BlockchainsStddev_SampFields;
  BlockchainsStreamCursorInput: BlockchainsStreamCursorInput;
  BlockchainsStreamCursorValueInput: BlockchainsStreamCursorValueInput;
  BlockchainsSumFields: BlockchainsSumFields;
  BlockchainsUpdates: BlockchainsUpdates;
  BlockchainsVar_popFields: BlockchainsVar_PopFields;
  BlockchainsVar_sampFields: BlockchainsVar_SampFields;
  BlockchainsVarianceFields: BlockchainsVarianceFields;
  Boolean: Scalars['Boolean']['output'];
  BooleanComparisonExp: BooleanComparisonExp;
  Countries: Countries;
  CountriesAggregate: CountriesAggregate;
  CountriesAggregateFields: CountriesAggregateFields;
  CountriesAvgFields: CountriesAvgFields;
  CountriesBoolExp: CountriesBoolExp;
  CountriesIncInput: CountriesIncInput;
  CountriesInsertInput: CountriesInsertInput;
  CountriesMaxFields: CountriesMaxFields;
  CountriesMinFields: CountriesMinFields;
  CountriesMutationResponse: CountriesMutationResponse;
  CountriesObjRelInsertInput: CountriesObjRelInsertInput;
  CountriesOnConflict: CountriesOnConflict;
  CountriesOrderBy: CountriesOrderBy;
  CountriesPkColumnsInput: CountriesPkColumnsInput;
  CountriesSetInput: CountriesSetInput;
  CountriesStddevFields: CountriesStddevFields;
  CountriesStddev_popFields: CountriesStddev_PopFields;
  CountriesStddev_sampFields: CountriesStddev_SampFields;
  CountriesStreamCursorInput: CountriesStreamCursorInput;
  CountriesStreamCursorValueInput: CountriesStreamCursorValueInput;
  CountriesSumFields: CountriesSumFields;
  CountriesUpdates: CountriesUpdates;
  CountriesVar_popFields: CountriesVar_PopFields;
  CountriesVar_sampFields: CountriesVar_SampFields;
  CountriesVarianceFields: CountriesVarianceFields;
  Currencies: Currencies;
  CurrenciesAggregate: CurrenciesAggregate;
  CurrenciesAggregateFields: CurrenciesAggregateFields;
  CurrenciesAggregateOrderBy: CurrenciesAggregateOrderBy;
  CurrenciesArrRelInsertInput: CurrenciesArrRelInsertInput;
  CurrenciesAvgFields: CurrenciesAvgFields;
  CurrenciesBoolExp: CurrenciesBoolExp;
  CurrenciesIncInput: CurrenciesIncInput;
  CurrenciesInsertInput: CurrenciesInsertInput;
  CurrenciesMaxFields: CurrenciesMaxFields;
  CurrenciesMinFields: CurrenciesMinFields;
  CurrenciesMutationResponse: CurrenciesMutationResponse;
  CurrenciesObjRelInsertInput: CurrenciesObjRelInsertInput;
  CurrenciesOnConflict: CurrenciesOnConflict;
  CurrenciesOrderBy: CurrenciesOrderBy;
  CurrenciesPkColumnsInput: CurrenciesPkColumnsInput;
  CurrenciesSetInput: CurrenciesSetInput;
  CurrenciesStddevFields: CurrenciesStddevFields;
  CurrenciesStddev_popFields: CurrenciesStddev_PopFields;
  CurrenciesStddev_sampFields: CurrenciesStddev_SampFields;
  CurrenciesStreamCursorInput: CurrenciesStreamCursorInput;
  CurrenciesStreamCursorValueInput: CurrenciesStreamCursorValueInput;
  CurrenciesSumFields: CurrenciesSumFields;
  CurrenciesUpdates: CurrenciesUpdates;
  CurrenciesVar_popFields: CurrenciesVar_PopFields;
  CurrenciesVar_sampFields: CurrenciesVar_SampFields;
  CurrenciesVarianceFields: CurrenciesVarianceFields;
  DateComparisonExp: DateComparisonExp;
  DepositOutput: DepositOutput;
  Events: Events;
  EventsAggregate: EventsAggregate;
  EventsAggregateFields: EventsAggregateFields;
  EventsAggregateOrderBy: EventsAggregateOrderBy;
  EventsArrRelInsertInput: EventsArrRelInsertInput;
  EventsAvgFields: EventsAvgFields;
  EventsBoolExp: EventsBoolExp;
  EventsIncInput: EventsIncInput;
  EventsInsertInput: EventsInsertInput;
  EventsMaxFields: EventsMaxFields;
  EventsMinFields: EventsMinFields;
  EventsMutationResponse: EventsMutationResponse;
  EventsObjRelInsertInput: EventsObjRelInsertInput;
  EventsOnConflict: EventsOnConflict;
  EventsOrderBy: EventsOrderBy;
  EventsPkColumnsInput: EventsPkColumnsInput;
  EventsSetInput: EventsSetInput;
  EventsStddevFields: EventsStddevFields;
  EventsStddev_popFields: EventsStddev_PopFields;
  EventsStddev_sampFields: EventsStddev_SampFields;
  EventsStreamCursorInput: EventsStreamCursorInput;
  EventsStreamCursorValueInput: EventsStreamCursorValueInput;
  EventsSumFields: EventsSumFields;
  EventsUpdates: EventsUpdates;
  EventsVar_popFields: EventsVar_PopFields;
  EventsVar_sampFields: EventsVar_SampFields;
  EventsVarianceFields: EventsVarianceFields;
  Float: Scalars['Float']['output'];
  Followers: Followers;
  FollowersAggregate: FollowersAggregate;
  FollowersAggregateFields: FollowersAggregateFields;
  FollowersAggregateOrderBy: FollowersAggregateOrderBy;
  FollowersArrRelInsertInput: FollowersArrRelInsertInput;
  FollowersAvgFields: FollowersAvgFields;
  FollowersBoolExp: FollowersBoolExp;
  FollowersIncInput: FollowersIncInput;
  FollowersInsertInput: FollowersInsertInput;
  FollowersMaxFields: FollowersMaxFields;
  FollowersMinFields: FollowersMinFields;
  FollowersMutationResponse: FollowersMutationResponse;
  FollowersOnConflict: FollowersOnConflict;
  FollowersOrderBy: FollowersOrderBy;
  FollowersPkColumnsInput: FollowersPkColumnsInput;
  FollowersSetInput: FollowersSetInput;
  FollowersStddevFields: FollowersStddevFields;
  FollowersStddev_popFields: FollowersStddev_PopFields;
  FollowersStddev_sampFields: FollowersStddev_SampFields;
  FollowersStreamCursorInput: FollowersStreamCursorInput;
  FollowersStreamCursorValueInput: FollowersStreamCursorValueInput;
  FollowersSumFields: FollowersSumFields;
  FollowersUpdates: FollowersUpdates;
  FollowersVar_popFields: FollowersVar_PopFields;
  FollowersVar_sampFields: FollowersVar_SampFields;
  FollowersVarianceFields: FollowersVarianceFields;
  Int: Scalars['Int']['output'];
  IntComparisonExp: IntComparisonExp;
  JsonbCastExp: JsonbCastExp;
  JsonbComparisonExp: JsonbComparisonExp;
  MessageTypes: MessageTypes;
  MessageTypesAggregate: MessageTypesAggregate;
  MessageTypesAggregateFields: MessageTypesAggregateFields;
  MessageTypesAppendInput: MessageTypesAppendInput;
  MessageTypesAvgFields: MessageTypesAvgFields;
  MessageTypesBoolExp: MessageTypesBoolExp;
  MessageTypesDeleteAtPathInput: MessageTypesDeleteAtPathInput;
  MessageTypesDeleteElemInput: MessageTypesDeleteElemInput;
  MessageTypesDeleteKeyInput: MessageTypesDeleteKeyInput;
  MessageTypesIncInput: MessageTypesIncInput;
  MessageTypesInsertInput: MessageTypesInsertInput;
  MessageTypesMaxFields: MessageTypesMaxFields;
  MessageTypesMinFields: MessageTypesMinFields;
  MessageTypesMutationResponse: MessageTypesMutationResponse;
  MessageTypesOnConflict: MessageTypesOnConflict;
  MessageTypesOrderBy: MessageTypesOrderBy;
  MessageTypesPkColumnsInput: MessageTypesPkColumnsInput;
  MessageTypesPrependInput: MessageTypesPrependInput;
  MessageTypesSetInput: MessageTypesSetInput;
  MessageTypesStddevFields: MessageTypesStddevFields;
  MessageTypesStddev_popFields: MessageTypesStddev_PopFields;
  MessageTypesStddev_sampFields: MessageTypesStddev_SampFields;
  MessageTypesStreamCursorInput: MessageTypesStreamCursorInput;
  MessageTypesStreamCursorValueInput: MessageTypesStreamCursorValueInput;
  MessageTypesSumFields: MessageTypesSumFields;
  MessageTypesUpdates: MessageTypesUpdates;
  MessageTypesVar_popFields: MessageTypesVar_PopFields;
  MessageTypesVar_sampFields: MessageTypesVar_SampFields;
  MessageTypesVarianceFields: MessageTypesVarianceFields;
  Messages: Messages;
  MessagesAggregate: MessagesAggregate;
  MessagesAggregateFields: MessagesAggregateFields;
  MessagesAppendInput: MessagesAppendInput;
  MessagesAvgFields: MessagesAvgFields;
  MessagesBoolExp: MessagesBoolExp;
  MessagesDeleteAtPathInput: MessagesDeleteAtPathInput;
  MessagesDeleteElemInput: MessagesDeleteElemInput;
  MessagesDeleteKeyInput: MessagesDeleteKeyInput;
  MessagesIncInput: MessagesIncInput;
  MessagesInsertInput: MessagesInsertInput;
  MessagesMaxFields: MessagesMaxFields;
  MessagesMinFields: MessagesMinFields;
  MessagesMutationResponse: MessagesMutationResponse;
  MessagesOnConflict: MessagesOnConflict;
  MessagesOrderBy: MessagesOrderBy;
  MessagesPkColumnsInput: MessagesPkColumnsInput;
  MessagesPrependInput: MessagesPrependInput;
  MessagesSetInput: MessagesSetInput;
  MessagesStddevFields: MessagesStddevFields;
  MessagesStddev_popFields: MessagesStddev_PopFields;
  MessagesStddev_sampFields: MessagesStddev_SampFields;
  MessagesStreamCursorInput: MessagesStreamCursorInput;
  MessagesStreamCursorValueInput: MessagesStreamCursorValueInput;
  MessagesSumFields: MessagesSumFields;
  MessagesUpdates: MessagesUpdates;
  MessagesVar_popFields: MessagesVar_PopFields;
  MessagesVar_sampFields: MessagesVar_SampFields;
  MessagesVarianceFields: MessagesVarianceFields;
  NumericComparisonExp: NumericComparisonExp;
  OrganiserUsers: OrganiserUsers;
  OrganiserUsersAggregate: OrganiserUsersAggregate;
  OrganiserUsersAggregateFields: OrganiserUsersAggregateFields;
  OrganiserUsersAvgFields: OrganiserUsersAvgFields;
  OrganiserUsersBoolExp: OrganiserUsersBoolExp;
  OrganiserUsersIncInput: OrganiserUsersIncInput;
  OrganiserUsersInsertInput: OrganiserUsersInsertInput;
  OrganiserUsersMaxFields: OrganiserUsersMaxFields;
  OrganiserUsersMinFields: OrganiserUsersMinFields;
  OrganiserUsersMutationResponse: OrganiserUsersMutationResponse;
  OrganiserUsersOnConflict: OrganiserUsersOnConflict;
  OrganiserUsersOrderBy: OrganiserUsersOrderBy;
  OrganiserUsersPkColumnsInput: OrganiserUsersPkColumnsInput;
  OrganiserUsersSetInput: OrganiserUsersSetInput;
  OrganiserUsersStddevFields: OrganiserUsersStddevFields;
  OrganiserUsersStddev_popFields: OrganiserUsersStddev_PopFields;
  OrganiserUsersStddev_sampFields: OrganiserUsersStddev_SampFields;
  OrganiserUsersStreamCursorInput: OrganiserUsersStreamCursorInput;
  OrganiserUsersStreamCursorValueInput: OrganiserUsersStreamCursorValueInput;
  OrganiserUsersSumFields: OrganiserUsersSumFields;
  OrganiserUsersUpdates: OrganiserUsersUpdates;
  OrganiserUsersVar_popFields: OrganiserUsersVar_PopFields;
  OrganiserUsersVar_sampFields: OrganiserUsersVar_SampFields;
  OrganiserUsersVarianceFields: OrganiserUsersVarianceFields;
  Organisers: Organisers;
  OrganisersAggregate: OrganisersAggregate;
  OrganisersAggregateFields: OrganisersAggregateFields;
  OrganisersAvgFields: OrganisersAvgFields;
  OrganisersBoolExp: OrganisersBoolExp;
  OrganisersIncInput: OrganisersIncInput;
  OrganisersInsertInput: OrganisersInsertInput;
  OrganisersMaxFields: OrganisersMaxFields;
  OrganisersMinFields: OrganisersMinFields;
  OrganisersMutationResponse: OrganisersMutationResponse;
  OrganisersObjRelInsertInput: OrganisersObjRelInsertInput;
  OrganisersOnConflict: OrganisersOnConflict;
  OrganisersOrderBy: OrganisersOrderBy;
  OrganisersPkColumnsInput: OrganisersPkColumnsInput;
  OrganisersSetInput: OrganisersSetInput;
  OrganisersStddevFields: OrganisersStddevFields;
  OrganisersStddev_popFields: OrganisersStddev_PopFields;
  OrganisersStddev_sampFields: OrganisersStddev_SampFields;
  OrganisersStreamCursorInput: OrganisersStreamCursorInput;
  OrganisersStreamCursorValueInput: OrganisersStreamCursorValueInput;
  OrganisersSumFields: OrganisersSumFields;
  OrganisersUpdates: OrganisersUpdates;
  OrganisersVar_popFields: OrganisersVar_PopFields;
  OrganisersVar_sampFields: OrganisersVar_SampFields;
  OrganisersVarianceFields: OrganisersVarianceFields;
  PaymentMethodBanks: PaymentMethodBanks;
  PaymentMethodBanksAggregate: PaymentMethodBanksAggregate;
  PaymentMethodBanksAggregateFields: PaymentMethodBanksAggregateFields;
  PaymentMethodBanksAggregateOrderBy: PaymentMethodBanksAggregateOrderBy;
  PaymentMethodBanksArrRelInsertInput: PaymentMethodBanksArrRelInsertInput;
  PaymentMethodBanksAvgFields: PaymentMethodBanksAvgFields;
  PaymentMethodBanksBoolExp: PaymentMethodBanksBoolExp;
  PaymentMethodBanksIncInput: PaymentMethodBanksIncInput;
  PaymentMethodBanksInsertInput: PaymentMethodBanksInsertInput;
  PaymentMethodBanksMaxFields: PaymentMethodBanksMaxFields;
  PaymentMethodBanksMinFields: PaymentMethodBanksMinFields;
  PaymentMethodBanksMutationResponse: PaymentMethodBanksMutationResponse;
  PaymentMethodBanksObjRelInsertInput: PaymentMethodBanksObjRelInsertInput;
  PaymentMethodBanksOnConflict: PaymentMethodBanksOnConflict;
  PaymentMethodBanksOrderBy: PaymentMethodBanksOrderBy;
  PaymentMethodBanksPkColumnsInput: PaymentMethodBanksPkColumnsInput;
  PaymentMethodBanksSetInput: PaymentMethodBanksSetInput;
  PaymentMethodBanksStddevFields: PaymentMethodBanksStddevFields;
  PaymentMethodBanksStddev_popFields: PaymentMethodBanksStddev_PopFields;
  PaymentMethodBanksStddev_sampFields: PaymentMethodBanksStddev_SampFields;
  PaymentMethodBanksStreamCursorInput: PaymentMethodBanksStreamCursorInput;
  PaymentMethodBanksStreamCursorValueInput: PaymentMethodBanksStreamCursorValueInput;
  PaymentMethodBanksSumFields: PaymentMethodBanksSumFields;
  PaymentMethodBanksUpdates: PaymentMethodBanksUpdates;
  PaymentMethodBanksVar_popFields: PaymentMethodBanksVar_PopFields;
  PaymentMethodBanksVar_sampFields: PaymentMethodBanksVar_SampFields;
  PaymentMethodBanksVarianceFields: PaymentMethodBanksVarianceFields;
  PaymentMethods: PaymentMethods;
  PaymentMethodsAggregate: PaymentMethodsAggregate;
  PaymentMethodsAggregateFields: PaymentMethodsAggregateFields;
  PaymentMethodsAvgFields: PaymentMethodsAvgFields;
  PaymentMethodsBoolExp: PaymentMethodsBoolExp;
  PaymentMethodsIncInput: PaymentMethodsIncInput;
  PaymentMethodsInsertInput: PaymentMethodsInsertInput;
  PaymentMethodsMaxFields: PaymentMethodsMaxFields;
  PaymentMethodsMinFields: PaymentMethodsMinFields;
  PaymentMethodsMutationResponse: PaymentMethodsMutationResponse;
  PaymentMethodsObjRelInsertInput: PaymentMethodsObjRelInsertInput;
  PaymentMethodsOnConflict: PaymentMethodsOnConflict;
  PaymentMethodsOrderBy: PaymentMethodsOrderBy;
  PaymentMethodsPkColumnsInput: PaymentMethodsPkColumnsInput;
  PaymentMethodsSetInput: PaymentMethodsSetInput;
  PaymentMethodsStddevFields: PaymentMethodsStddevFields;
  PaymentMethodsStddev_popFields: PaymentMethodsStddev_PopFields;
  PaymentMethodsStddev_sampFields: PaymentMethodsStddev_SampFields;
  PaymentMethodsStreamCursorInput: PaymentMethodsStreamCursorInput;
  PaymentMethodsStreamCursorValueInput: PaymentMethodsStreamCursorValueInput;
  PaymentMethodsSumFields: PaymentMethodsSumFields;
  PaymentMethodsUpdates: PaymentMethodsUpdates;
  PaymentMethodsVar_popFields: PaymentMethodsVar_PopFields;
  PaymentMethodsVar_sampFields: PaymentMethodsVar_SampFields;
  PaymentMethodsVarianceFields: PaymentMethodsVarianceFields;
  Payments: Payments;
  PaymentsAggregate: PaymentsAggregate;
  PaymentsAggregateFields: PaymentsAggregateFields;
  PaymentsAvgFields: PaymentsAvgFields;
  PaymentsBoolExp: PaymentsBoolExp;
  PaymentsIncInput: PaymentsIncInput;
  PaymentsInsertInput: PaymentsInsertInput;
  PaymentsMaxFields: PaymentsMaxFields;
  PaymentsMinFields: PaymentsMinFields;
  PaymentsMutationResponse: PaymentsMutationResponse;
  PaymentsObjRelInsertInput: PaymentsObjRelInsertInput;
  PaymentsOnConflict: PaymentsOnConflict;
  PaymentsOrderBy: PaymentsOrderBy;
  PaymentsPkColumnsInput: PaymentsPkColumnsInput;
  PaymentsSetInput: PaymentsSetInput;
  PaymentsStddevFields: PaymentsStddevFields;
  PaymentsStddev_popFields: PaymentsStddev_PopFields;
  PaymentsStddev_sampFields: PaymentsStddev_SampFields;
  PaymentsStreamCursorInput: PaymentsStreamCursorInput;
  PaymentsStreamCursorValueInput: PaymentsStreamCursorValueInput;
  PaymentsSumFields: PaymentsSumFields;
  PaymentsUpdates: PaymentsUpdates;
  PaymentsVar_popFields: PaymentsVar_PopFields;
  PaymentsVar_sampFields: PaymentsVar_SampFields;
  PaymentsVarianceFields: PaymentsVarianceFields;
  PendingTxs: PendingTxs;
  PendingTxsAggregate: PendingTxsAggregate;
  PendingTxsAggregateFields: PendingTxsAggregateFields;
  PendingTxsAvgFields: PendingTxsAvgFields;
  PendingTxsBoolExp: PendingTxsBoolExp;
  PendingTxsIncInput: PendingTxsIncInput;
  PendingTxsInsertInput: PendingTxsInsertInput;
  PendingTxsMaxFields: PendingTxsMaxFields;
  PendingTxsMinFields: PendingTxsMinFields;
  PendingTxsMutationResponse: PendingTxsMutationResponse;
  PendingTxsOnConflict: PendingTxsOnConflict;
  PendingTxsOrderBy: PendingTxsOrderBy;
  PendingTxsPkColumnsInput: PendingTxsPkColumnsInput;
  PendingTxsSetInput: PendingTxsSetInput;
  PendingTxsStddevFields: PendingTxsStddevFields;
  PendingTxsStddev_popFields: PendingTxsStddev_PopFields;
  PendingTxsStddev_sampFields: PendingTxsStddev_SampFields;
  PendingTxsStreamCursorInput: PendingTxsStreamCursorInput;
  PendingTxsStreamCursorValueInput: PendingTxsStreamCursorValueInput;
  PendingTxsSumFields: PendingTxsSumFields;
  PendingTxsUpdates: PendingTxsUpdates;
  PendingTxsVar_popFields: PendingTxsVar_PopFields;
  PendingTxsVar_sampFields: PendingTxsVar_SampFields;
  PendingTxsVarianceFields: PendingTxsVarianceFields;
  PlayerFollowers: PlayerFollowers;
  PlayerFollowersAggregate: PlayerFollowersAggregate;
  PlayerFollowersAggregateFields: PlayerFollowersAggregateFields;
  PlayerFollowersAvgFields: PlayerFollowersAvgFields;
  PlayerFollowersBoolExp: PlayerFollowersBoolExp;
  PlayerFollowersIncInput: PlayerFollowersIncInput;
  PlayerFollowersInsertInput: PlayerFollowersInsertInput;
  PlayerFollowersMaxFields: PlayerFollowersMaxFields;
  PlayerFollowersMinFields: PlayerFollowersMinFields;
  PlayerFollowersMutationResponse: PlayerFollowersMutationResponse;
  PlayerFollowersOrderBy: PlayerFollowersOrderBy;
  PlayerFollowersSetInput: PlayerFollowersSetInput;
  PlayerFollowersStddevFields: PlayerFollowersStddevFields;
  PlayerFollowersStddev_popFields: PlayerFollowersStddev_PopFields;
  PlayerFollowersStddev_sampFields: PlayerFollowersStddev_SampFields;
  PlayerFollowersStreamCursorInput: PlayerFollowersStreamCursorInput;
  PlayerFollowersStreamCursorValueInput: PlayerFollowersStreamCursorValueInput;
  PlayerFollowersSumFields: PlayerFollowersSumFields;
  PlayerFollowersUpdates: PlayerFollowersUpdates;
  PlayerFollowersVar_popFields: PlayerFollowersVar_PopFields;
  PlayerFollowersVar_sampFields: PlayerFollowersVar_SampFields;
  PlayerFollowersVarianceFields: PlayerFollowersVarianceFields;
  PlayerFollowersView: PlayerFollowersView;
  PlayerFollowersViewAggregate: PlayerFollowersViewAggregate;
  PlayerFollowersViewAggregateFields: PlayerFollowersViewAggregateFields;
  PlayerFollowersViewAggregateOrderBy: PlayerFollowersViewAggregateOrderBy;
  PlayerFollowersViewArrRelInsertInput: PlayerFollowersViewArrRelInsertInput;
  PlayerFollowersViewAvgFields: PlayerFollowersViewAvgFields;
  PlayerFollowersViewBoolExp: PlayerFollowersViewBoolExp;
  PlayerFollowersViewInsertInput: PlayerFollowersViewInsertInput;
  PlayerFollowersViewMaxFields: PlayerFollowersViewMaxFields;
  PlayerFollowersViewMinFields: PlayerFollowersViewMinFields;
  PlayerFollowersViewOrderBy: PlayerFollowersViewOrderBy;
  PlayerFollowersViewStddevFields: PlayerFollowersViewStddevFields;
  PlayerFollowersViewStddev_popFields: PlayerFollowersViewStddev_PopFields;
  PlayerFollowersViewStddev_sampFields: PlayerFollowersViewStddev_SampFields;
  PlayerFollowersViewStreamCursorInput: PlayerFollowersViewStreamCursorInput;
  PlayerFollowersViewStreamCursorValueInput: PlayerFollowersViewStreamCursorValueInput;
  PlayerFollowersViewSumFields: PlayerFollowersViewSumFields;
  PlayerFollowersViewVar_popFields: PlayerFollowersViewVar_PopFields;
  PlayerFollowersViewVar_sampFields: PlayerFollowersViewVar_SampFields;
  PlayerFollowersViewVarianceFields: PlayerFollowersViewVarianceFields;
  PlayerShares: PlayerShares;
  PlayerSharesAggregate: PlayerSharesAggregate;
  PlayerSharesAggregateFields: PlayerSharesAggregateFields;
  PlayerSharesAggregateOrderBy: PlayerSharesAggregateOrderBy;
  PlayerSharesArrRelInsertInput: PlayerSharesArrRelInsertInput;
  PlayerSharesAvgFields: PlayerSharesAvgFields;
  PlayerSharesBoolExp: PlayerSharesBoolExp;
  PlayerSharesBought: PlayerSharesBought;
  PlayerSharesBoughtAggregate: PlayerSharesBoughtAggregate;
  PlayerSharesBoughtAggregateFields: PlayerSharesBoughtAggregateFields;
  PlayerSharesBoughtAvgFields: PlayerSharesBoughtAvgFields;
  PlayerSharesBoughtBoolExp: PlayerSharesBoughtBoolExp;
  PlayerSharesBoughtInsertInput: PlayerSharesBoughtInsertInput;
  PlayerSharesBoughtMaxFields: PlayerSharesBoughtMaxFields;
  PlayerSharesBoughtMinFields: PlayerSharesBoughtMinFields;
  PlayerSharesBoughtObjRelInsertInput: PlayerSharesBoughtObjRelInsertInput;
  PlayerSharesBoughtOrderBy: PlayerSharesBoughtOrderBy;
  PlayerSharesBoughtStddevFields: PlayerSharesBoughtStddevFields;
  PlayerSharesBoughtStddev_popFields: PlayerSharesBoughtStddev_PopFields;
  PlayerSharesBoughtStddev_sampFields: PlayerSharesBoughtStddev_SampFields;
  PlayerSharesBoughtStreamCursorInput: PlayerSharesBoughtStreamCursorInput;
  PlayerSharesBoughtStreamCursorValueInput: PlayerSharesBoughtStreamCursorValueInput;
  PlayerSharesBoughtSumFields: PlayerSharesBoughtSumFields;
  PlayerSharesBoughtVar_popFields: PlayerSharesBoughtVar_PopFields;
  PlayerSharesBoughtVar_sampFields: PlayerSharesBoughtVar_SampFields;
  PlayerSharesBoughtVarianceFields: PlayerSharesBoughtVarianceFields;
  PlayerSharesIncInput: PlayerSharesIncInput;
  PlayerSharesInsertInput: PlayerSharesInsertInput;
  PlayerSharesMaxFields: PlayerSharesMaxFields;
  PlayerSharesMinFields: PlayerSharesMinFields;
  PlayerSharesMutationResponse: PlayerSharesMutationResponse;
  PlayerSharesObjRelInsertInput: PlayerSharesObjRelInsertInput;
  PlayerSharesOnConflict: PlayerSharesOnConflict;
  PlayerSharesOrderBy: PlayerSharesOrderBy;
  PlayerSharesPkColumnsInput: PlayerSharesPkColumnsInput;
  PlayerSharesSetInput: PlayerSharesSetInput;
  PlayerSharesStddevFields: PlayerSharesStddevFields;
  PlayerSharesStddev_popFields: PlayerSharesStddev_PopFields;
  PlayerSharesStddev_sampFields: PlayerSharesStddev_SampFields;
  PlayerSharesStreamCursorInput: PlayerSharesStreamCursorInput;
  PlayerSharesStreamCursorValueInput: PlayerSharesStreamCursorValueInput;
  PlayerSharesSumFields: PlayerSharesSumFields;
  PlayerSharesUpdates: PlayerSharesUpdates;
  PlayerSharesVar_popFields: PlayerSharesVar_PopFields;
  PlayerSharesVar_sampFields: PlayerSharesVar_SampFields;
  PlayerSharesVarianceFields: PlayerSharesVarianceFields;
  Players: Players;
  PlayersAggregate: PlayersAggregate;
  PlayersAggregateFields: PlayersAggregateFields;
  PlayersAggregateOrderBy: PlayersAggregateOrderBy;
  PlayersArrRelInsertInput: PlayersArrRelInsertInput;
  PlayersAvgFields: PlayersAvgFields;
  PlayersBoolExp: PlayersBoolExp;
  PlayersIncInput: PlayersIncInput;
  PlayersInsertInput: PlayersInsertInput;
  PlayersMaxFields: PlayersMaxFields;
  PlayersMinFields: PlayersMinFields;
  PlayersMutationResponse: PlayersMutationResponse;
  PlayersObjRelInsertInput: PlayersObjRelInsertInput;
  PlayersOnConflict: PlayersOnConflict;
  PlayersOrderBy: PlayersOrderBy;
  PlayersPkColumnsInput: PlayersPkColumnsInput;
  PlayersSetInput: PlayersSetInput;
  PlayersStddevFields: PlayersStddevFields;
  PlayersStddev_popFields: PlayersStddev_PopFields;
  PlayersStddev_sampFields: PlayersStddev_SampFields;
  PlayersStreamCursorInput: PlayersStreamCursorInput;
  PlayersStreamCursorValueInput: PlayersStreamCursorValueInput;
  PlayersSumFields: PlayersSumFields;
  PlayersUpdates: PlayersUpdates;
  PlayersVar_popFields: PlayersVar_PopFields;
  PlayersVar_sampFields: PlayersVar_SampFields;
  PlayersVarianceFields: PlayersVarianceFields;
  StatEvents: StatEvents;
  StatEventsAggregate: StatEventsAggregate;
  StatEventsAggregateFields: StatEventsAggregateFields;
  StatEventsAvgFields: StatEventsAvgFields;
  StatEventsBoolExp: StatEventsBoolExp;
  StatEventsIncInput: StatEventsIncInput;
  StatEventsInsertInput: StatEventsInsertInput;
  StatEventsMaxFields: StatEventsMaxFields;
  StatEventsMinFields: StatEventsMinFields;
  StatEventsMutationResponse: StatEventsMutationResponse;
  StatEventsOnConflict: StatEventsOnConflict;
  StatEventsOrderBy: StatEventsOrderBy;
  StatEventsPkColumnsInput: StatEventsPkColumnsInput;
  StatEventsSetInput: StatEventsSetInput;
  StatEventsStddevFields: StatEventsStddevFields;
  StatEventsStddev_popFields: StatEventsStddev_PopFields;
  StatEventsStddev_sampFields: StatEventsStddev_SampFields;
  StatEventsStreamCursorInput: StatEventsStreamCursorInput;
  StatEventsStreamCursorValueInput: StatEventsStreamCursorValueInput;
  StatEventsSumFields: StatEventsSumFields;
  StatEventsUpdates: StatEventsUpdates;
  StatEventsVar_popFields: StatEventsVar_PopFields;
  StatEventsVar_sampFields: StatEventsVar_SampFields;
  StatEventsVarianceFields: StatEventsVarianceFields;
  StatPlayers: StatPlayers;
  StatPlayersAggregate: StatPlayersAggregate;
  StatPlayersAggregateFields: StatPlayersAggregateFields;
  StatPlayersAvgFields: StatPlayersAvgFields;
  StatPlayersBoolExp: StatPlayersBoolExp;
  StatPlayersIncInput: StatPlayersIncInput;
  StatPlayersInsertInput: StatPlayersInsertInput;
  StatPlayersMaxFields: StatPlayersMaxFields;
  StatPlayersMinFields: StatPlayersMinFields;
  StatPlayersMutationResponse: StatPlayersMutationResponse;
  StatPlayersObjRelInsertInput: StatPlayersObjRelInsertInput;
  StatPlayersOnConflict: StatPlayersOnConflict;
  StatPlayersOrderBy: StatPlayersOrderBy;
  StatPlayersPkColumnsInput: StatPlayersPkColumnsInput;
  StatPlayersSetInput: StatPlayersSetInput;
  StatPlayersStddevFields: StatPlayersStddevFields;
  StatPlayersStddev_popFields: StatPlayersStddev_PopFields;
  StatPlayersStddev_sampFields: StatPlayersStddev_SampFields;
  StatPlayersStreamCursorInput: StatPlayersStreamCursorInput;
  StatPlayersStreamCursorValueInput: StatPlayersStreamCursorValueInput;
  StatPlayersSumFields: StatPlayersSumFields;
  StatPlayersUpdates: StatPlayersUpdates;
  StatPlayersVar_popFields: StatPlayersVar_PopFields;
  StatPlayersVar_sampFields: StatPlayersVar_SampFields;
  StatPlayersVarianceFields: StatPlayersVarianceFields;
  StatTopPlayers: StatTopPlayers;
  StatTopPlayersAggregate: StatTopPlayersAggregate;
  StatTopPlayersAggregateFields: StatTopPlayersAggregateFields;
  StatTopPlayersAvgFields: StatTopPlayersAvgFields;
  StatTopPlayersBoolExp: StatTopPlayersBoolExp;
  StatTopPlayersMaxFields: StatTopPlayersMaxFields;
  StatTopPlayersMinFields: StatTopPlayersMinFields;
  StatTopPlayersOrderBy: StatTopPlayersOrderBy;
  StatTopPlayersStddevFields: StatTopPlayersStddevFields;
  StatTopPlayersStddev_popFields: StatTopPlayersStddev_PopFields;
  StatTopPlayersStddev_sampFields: StatTopPlayersStddev_SampFields;
  StatTopPlayersStreamCursorInput: StatTopPlayersStreamCursorInput;
  StatTopPlayersStreamCursorValueInput: StatTopPlayersStreamCursorValueInput;
  StatTopPlayersSumFields: StatTopPlayersSumFields;
  StatTopPlayersVar_popFields: StatTopPlayersVar_PopFields;
  StatTopPlayersVar_sampFields: StatTopPlayersVar_SampFields;
  StatTopPlayersVarianceFields: StatTopPlayersVarianceFields;
  StatTopYearPlayers: StatTopYearPlayers;
  StatTopYearPlayersAggregate: StatTopYearPlayersAggregate;
  StatTopYearPlayersAggregateFields: StatTopYearPlayersAggregateFields;
  StatTopYearPlayersAvgFields: StatTopYearPlayersAvgFields;
  StatTopYearPlayersBoolExp: StatTopYearPlayersBoolExp;
  StatTopYearPlayersMaxFields: StatTopYearPlayersMaxFields;
  StatTopYearPlayersMinFields: StatTopYearPlayersMinFields;
  StatTopYearPlayersOrderBy: StatTopYearPlayersOrderBy;
  StatTopYearPlayersStddevFields: StatTopYearPlayersStddevFields;
  StatTopYearPlayersStddev_popFields: StatTopYearPlayersStddev_PopFields;
  StatTopYearPlayersStddev_sampFields: StatTopYearPlayersStddev_SampFields;
  StatTopYearPlayersStreamCursorInput: StatTopYearPlayersStreamCursorInput;
  StatTopYearPlayersStreamCursorValueInput: StatTopYearPlayersStreamCursorValueInput;
  StatTopYearPlayersSumFields: StatTopYearPlayersSumFields;
  StatTopYearPlayersVar_popFields: StatTopYearPlayersVar_PopFields;
  StatTopYearPlayersVar_sampFields: StatTopYearPlayersVar_SampFields;
  StatTopYearPlayersVarianceFields: StatTopYearPlayersVarianceFields;
  StatTournamentResults: StatTournamentResults;
  StatTournamentResultsAggregate: StatTournamentResultsAggregate;
  StatTournamentResultsAggregateFields: StatTournamentResultsAggregateFields;
  StatTournamentResultsAggregateOrderBy: StatTournamentResultsAggregateOrderBy;
  StatTournamentResultsArrRelInsertInput: StatTournamentResultsArrRelInsertInput;
  StatTournamentResultsAvgFields: StatTournamentResultsAvgFields;
  StatTournamentResultsBoolExp: StatTournamentResultsBoolExp;
  StatTournamentResultsIncInput: StatTournamentResultsIncInput;
  StatTournamentResultsInsertInput: StatTournamentResultsInsertInput;
  StatTournamentResultsMaxFields: StatTournamentResultsMaxFields;
  StatTournamentResultsMinFields: StatTournamentResultsMinFields;
  StatTournamentResultsMutationResponse: StatTournamentResultsMutationResponse;
  StatTournamentResultsOnConflict: StatTournamentResultsOnConflict;
  StatTournamentResultsOrderBy: StatTournamentResultsOrderBy;
  StatTournamentResultsPkColumnsInput: StatTournamentResultsPkColumnsInput;
  StatTournamentResultsSetInput: StatTournamentResultsSetInput;
  StatTournamentResultsStddevFields: StatTournamentResultsStddevFields;
  StatTournamentResultsStddev_popFields: StatTournamentResultsStddev_PopFields;
  StatTournamentResultsStddev_sampFields: StatTournamentResultsStddev_SampFields;
  StatTournamentResultsStreamCursorInput: StatTournamentResultsStreamCursorInput;
  StatTournamentResultsStreamCursorValueInput: StatTournamentResultsStreamCursorValueInput;
  StatTournamentResultsSumFields: StatTournamentResultsSumFields;
  StatTournamentResultsUpdates: StatTournamentResultsUpdates;
  StatTournamentResultsVar_popFields: StatTournamentResultsVar_PopFields;
  StatTournamentResultsVar_sampFields: StatTournamentResultsVar_SampFields;
  StatTournamentResultsVarianceFields: StatTournamentResultsVarianceFields;
  StatTournaments: StatTournaments;
  StatTournamentsAggregate: StatTournamentsAggregate;
  StatTournamentsAggregateFields: StatTournamentsAggregateFields;
  StatTournamentsAvgFields: StatTournamentsAvgFields;
  StatTournamentsBoolExp: StatTournamentsBoolExp;
  StatTournamentsIncInput: StatTournamentsIncInput;
  StatTournamentsInsertInput: StatTournamentsInsertInput;
  StatTournamentsMaxFields: StatTournamentsMaxFields;
  StatTournamentsMinFields: StatTournamentsMinFields;
  StatTournamentsMutationResponse: StatTournamentsMutationResponse;
  StatTournamentsObjRelInsertInput: StatTournamentsObjRelInsertInput;
  StatTournamentsOnConflict: StatTournamentsOnConflict;
  StatTournamentsOrderBy: StatTournamentsOrderBy;
  StatTournamentsPkColumnsInput: StatTournamentsPkColumnsInput;
  StatTournamentsSetInput: StatTournamentsSetInput;
  StatTournamentsStddevFields: StatTournamentsStddevFields;
  StatTournamentsStddev_popFields: StatTournamentsStddev_PopFields;
  StatTournamentsStddev_sampFields: StatTournamentsStddev_SampFields;
  StatTournamentsStreamCursorInput: StatTournamentsStreamCursorInput;
  StatTournamentsStreamCursorValueInput: StatTournamentsStreamCursorValueInput;
  StatTournamentsSumFields: StatTournamentsSumFields;
  StatTournamentsUpdates: StatTournamentsUpdates;
  StatTournamentsVar_popFields: StatTournamentsVar_PopFields;
  StatTournamentsVar_sampFields: StatTournamentsVar_SampFields;
  StatTournamentsVarianceFields: StatTournamentsVarianceFields;
  StatusGames: StatusGames;
  StatusGamesAggregate: StatusGamesAggregate;
  StatusGamesAggregateFields: StatusGamesAggregateFields;
  StatusGamesBoolExp: StatusGamesBoolExp;
  StatusGamesEnumComparisonExp: StatusGamesEnumComparisonExp;
  StatusGamesInsertInput: StatusGamesInsertInput;
  StatusGamesMaxFields: StatusGamesMaxFields;
  StatusGamesMinFields: StatusGamesMinFields;
  StatusGamesMutationResponse: StatusGamesMutationResponse;
  StatusGamesOnConflict: StatusGamesOnConflict;
  StatusGamesOrderBy: StatusGamesOrderBy;
  StatusGamesPkColumnsInput: StatusGamesPkColumnsInput;
  StatusGamesSetInput: StatusGamesSetInput;
  StatusGamesStreamCursorInput: StatusGamesStreamCursorInput;
  StatusGamesStreamCursorValueInput: StatusGamesStreamCursorValueInput;
  StatusGamesUpdates: StatusGamesUpdates;
  StatusOutput: StatusOutput;
  Statuses: Statuses;
  StatusesAggregate: StatusesAggregate;
  StatusesAggregateFields: StatusesAggregateFields;
  StatusesBoolExp: StatusesBoolExp;
  StatusesEnumComparisonExp: StatusesEnumComparisonExp;
  StatusesInsertInput: StatusesInsertInput;
  StatusesMaxFields: StatusesMaxFields;
  StatusesMinFields: StatusesMinFields;
  StatusesMutationResponse: StatusesMutationResponse;
  StatusesOnConflict: StatusesOnConflict;
  StatusesOrderBy: StatusesOrderBy;
  StatusesPkColumnsInput: StatusesPkColumnsInput;
  StatusesSetInput: StatusesSetInput;
  StatusesStreamCursorInput: StatusesStreamCursorInput;
  StatusesStreamCursorValueInput: StatusesStreamCursorValueInput;
  StatusesUpdates: StatusesUpdates;
  String: Scalars['String']['output'];
  StringComparisonExp: StringComparisonExp;
  Tickets: Tickets;
  TicketsAggregate: TicketsAggregate;
  TicketsAggregateFields: TicketsAggregateFields;
  TicketsAggregateOrderBy: TicketsAggregateOrderBy;
  TicketsArrRelInsertInput: TicketsArrRelInsertInput;
  TicketsAvgFields: TicketsAvgFields;
  TicketsBoolExp: TicketsBoolExp;
  TicketsIncInput: TicketsIncInput;
  TicketsInsertInput: TicketsInsertInput;
  TicketsMaxFields: TicketsMaxFields;
  TicketsMinFields: TicketsMinFields;
  TicketsMutationResponse: TicketsMutationResponse;
  TicketsOnConflict: TicketsOnConflict;
  TicketsOrderBy: TicketsOrderBy;
  TicketsPkColumnsInput: TicketsPkColumnsInput;
  TicketsSetInput: TicketsSetInput;
  TicketsStatus: TicketsStatus;
  TicketsStatusAggregate: TicketsStatusAggregate;
  TicketsStatusAggregateFields: TicketsStatusAggregateFields;
  TicketsStatusBoolExp: TicketsStatusBoolExp;
  TicketsStatusEnumComparisonExp: TicketsStatusEnumComparisonExp;
  TicketsStatusInsertInput: TicketsStatusInsertInput;
  TicketsStatusMaxFields: TicketsStatusMaxFields;
  TicketsStatusMinFields: TicketsStatusMinFields;
  TicketsStatusMutationResponse: TicketsStatusMutationResponse;
  TicketsStatusOnConflict: TicketsStatusOnConflict;
  TicketsStatusOrderBy: TicketsStatusOrderBy;
  TicketsStatusPkColumnsInput: TicketsStatusPkColumnsInput;
  TicketsStatusSetInput: TicketsStatusSetInput;
  TicketsStatusStreamCursorInput: TicketsStatusStreamCursorInput;
  TicketsStatusStreamCursorValueInput: TicketsStatusStreamCursorValueInput;
  TicketsStatusUpdates: TicketsStatusUpdates;
  TicketsStddevFields: TicketsStddevFields;
  TicketsStddev_popFields: TicketsStddev_PopFields;
  TicketsStddev_sampFields: TicketsStddev_SampFields;
  TicketsStreamCursorInput: TicketsStreamCursorInput;
  TicketsStreamCursorValueInput: TicketsStreamCursorValueInput;
  TicketsSumFields: TicketsSumFields;
  TicketsUpdates: TicketsUpdates;
  TicketsVar_popFields: TicketsVar_PopFields;
  TicketsVar_sampFields: TicketsVar_SampFields;
  TicketsVarianceFields: TicketsVarianceFields;
  TimestampComparisonExp: TimestampComparisonExp;
  TimestamptzComparisonExp: TimestamptzComparisonExp;
  TournamentBalances: TournamentBalances;
  TournamentBalancesAggregate: TournamentBalancesAggregate;
  TournamentBalancesAggregateFields: TournamentBalancesAggregateFields;
  TournamentBalancesAggregateOrderBy: TournamentBalancesAggregateOrderBy;
  TournamentBalancesArrRelInsertInput: TournamentBalancesArrRelInsertInput;
  TournamentBalancesAvgFields: TournamentBalancesAvgFields;
  TournamentBalancesBoolExp: TournamentBalancesBoolExp;
  TournamentBalancesInsertInput: TournamentBalancesInsertInput;
  TournamentBalancesMaxFields: TournamentBalancesMaxFields;
  TournamentBalancesMinFields: TournamentBalancesMinFields;
  TournamentBalancesOrderBy: TournamentBalancesOrderBy;
  TournamentBalancesStddevFields: TournamentBalancesStddevFields;
  TournamentBalancesStddev_popFields: TournamentBalancesStddev_PopFields;
  TournamentBalancesStddev_sampFields: TournamentBalancesStddev_SampFields;
  TournamentBalancesStreamCursorInput: TournamentBalancesStreamCursorInput;
  TournamentBalancesStreamCursorValueInput: TournamentBalancesStreamCursorValueInput;
  TournamentBalancesSumFields: TournamentBalancesSumFields;
  TournamentBalancesVar_popFields: TournamentBalancesVar_PopFields;
  TournamentBalancesVar_sampFields: TournamentBalancesVar_SampFields;
  TournamentBalancesVarianceFields: TournamentBalancesVarianceFields;
  TournamentResultStatuses: TournamentResultStatuses;
  TournamentResultStatusesAggregate: TournamentResultStatusesAggregate;
  TournamentResultStatusesAggregateFields: TournamentResultStatusesAggregateFields;
  TournamentResultStatusesBoolExp: TournamentResultStatusesBoolExp;
  TournamentResultStatusesEnumComparisonExp: TournamentResultStatusesEnumComparisonExp;
  TournamentResultStatusesInsertInput: TournamentResultStatusesInsertInput;
  TournamentResultStatusesMaxFields: TournamentResultStatusesMaxFields;
  TournamentResultStatusesMinFields: TournamentResultStatusesMinFields;
  TournamentResultStatusesMutationResponse: TournamentResultStatusesMutationResponse;
  TournamentResultStatusesOnConflict: TournamentResultStatusesOnConflict;
  TournamentResultStatusesOrderBy: TournamentResultStatusesOrderBy;
  TournamentResultStatusesPkColumnsInput: TournamentResultStatusesPkColumnsInput;
  TournamentResultStatusesSetInput: TournamentResultStatusesSetInput;
  TournamentResultStatusesStreamCursorInput: TournamentResultStatusesStreamCursorInput;
  TournamentResultStatusesStreamCursorValueInput: TournamentResultStatusesStreamCursorValueInput;
  TournamentResultStatusesUpdates: TournamentResultStatusesUpdates;
  TournamentResults: TournamentResults;
  TournamentResultsAggregate: TournamentResultsAggregate;
  TournamentResultsAggregateFields: TournamentResultsAggregateFields;
  TournamentResultsAggregateOrderBy: TournamentResultsAggregateOrderBy;
  TournamentResultsAppendInput: TournamentResultsAppendInput;
  TournamentResultsArrRelInsertInput: TournamentResultsArrRelInsertInput;
  TournamentResultsAvgFields: TournamentResultsAvgFields;
  TournamentResultsBoolExp: TournamentResultsBoolExp;
  TournamentResultsDeleteAtPathInput: TournamentResultsDeleteAtPathInput;
  TournamentResultsDeleteElemInput: TournamentResultsDeleteElemInput;
  TournamentResultsDeleteKeyInput: TournamentResultsDeleteKeyInput;
  TournamentResultsIncInput: TournamentResultsIncInput;
  TournamentResultsInsertInput: TournamentResultsInsertInput;
  TournamentResultsMaxFields: TournamentResultsMaxFields;
  TournamentResultsMinFields: TournamentResultsMinFields;
  TournamentResultsMutationResponse: TournamentResultsMutationResponse;
  TournamentResultsObjRelInsertInput: TournamentResultsObjRelInsertInput;
  TournamentResultsOnConflict: TournamentResultsOnConflict;
  TournamentResultsOrderBy: TournamentResultsOrderBy;
  TournamentResultsPkColumnsInput: TournamentResultsPkColumnsInput;
  TournamentResultsPrependInput: TournamentResultsPrependInput;
  TournamentResultsSetInput: TournamentResultsSetInput;
  TournamentResultsStddevFields: TournamentResultsStddevFields;
  TournamentResultsStddev_popFields: TournamentResultsStddev_PopFields;
  TournamentResultsStddev_sampFields: TournamentResultsStddev_SampFields;
  TournamentResultsStreamCursorInput: TournamentResultsStreamCursorInput;
  TournamentResultsStreamCursorValueInput: TournamentResultsStreamCursorValueInput;
  TournamentResultsSumFields: TournamentResultsSumFields;
  TournamentResultsUpdates: TournamentResultsUpdates;
  TournamentResultsVar_popFields: TournamentResultsVar_PopFields;
  TournamentResultsVar_sampFields: TournamentResultsVar_SampFields;
  TournamentResultsVarianceFields: TournamentResultsVarianceFields;
  Tournaments: Tournaments;
  TournamentsAggregate: TournamentsAggregate;
  TournamentsAggregateFields: TournamentsAggregateFields;
  TournamentsAggregateOrderBy: TournamentsAggregateOrderBy;
  TournamentsArrRelInsertInput: TournamentsArrRelInsertInput;
  TournamentsAvgFields: TournamentsAvgFields;
  TournamentsBoolExp: TournamentsBoolExp;
  TournamentsIncInput: TournamentsIncInput;
  TournamentsInsertInput: TournamentsInsertInput;
  TournamentsMaxFields: TournamentsMaxFields;
  TournamentsMinFields: TournamentsMinFields;
  TournamentsMutationResponse: TournamentsMutationResponse;
  TournamentsObjRelInsertInput: TournamentsObjRelInsertInput;
  TournamentsOnConflict: TournamentsOnConflict;
  TournamentsOrderBy: TournamentsOrderBy;
  TournamentsPkColumnsInput: TournamentsPkColumnsInput;
  TournamentsSetInput: TournamentsSetInput;
  TournamentsStddevFields: TournamentsStddevFields;
  TournamentsStddev_popFields: TournamentsStddev_PopFields;
  TournamentsStddev_sampFields: TournamentsStddev_SampFields;
  TournamentsStreamCursorInput: TournamentsStreamCursorInput;
  TournamentsStreamCursorValueInput: TournamentsStreamCursorValueInput;
  TournamentsSumFields: TournamentsSumFields;
  TournamentsUpdates: TournamentsUpdates;
  TournamentsVar_popFields: TournamentsVar_PopFields;
  TournamentsVar_sampFields: TournamentsVar_SampFields;
  TournamentsVarianceFields: TournamentsVarianceFields;
  TransactionUserTypes: TransactionUserTypes;
  TransactionUserTypesAggregate: TransactionUserTypesAggregate;
  TransactionUserTypesAggregateFields: TransactionUserTypesAggregateFields;
  TransactionUserTypesBoolExp: TransactionUserTypesBoolExp;
  TransactionUserTypesEnumComparisonExp: TransactionUserTypesEnumComparisonExp;
  TransactionUserTypesInsertInput: TransactionUserTypesInsertInput;
  TransactionUserTypesMaxFields: TransactionUserTypesMaxFields;
  TransactionUserTypesMinFields: TransactionUserTypesMinFields;
  TransactionUserTypesMutationResponse: TransactionUserTypesMutationResponse;
  TransactionUserTypesOnConflict: TransactionUserTypesOnConflict;
  TransactionUserTypesOrderBy: TransactionUserTypesOrderBy;
  TransactionUserTypesPkColumnsInput: TransactionUserTypesPkColumnsInput;
  TransactionUserTypesSetInput: TransactionUserTypesSetInput;
  TransactionUserTypesStreamCursorInput: TransactionUserTypesStreamCursorInput;
  TransactionUserTypesStreamCursorValueInput: TransactionUserTypesStreamCursorValueInput;
  TransactionUserTypesUpdates: TransactionUserTypesUpdates;
  Transactions: Transactions;
  TransactionsAggregate: TransactionsAggregate;
  TransactionsAggregateFields: TransactionsAggregateFields;
  TransactionsAggregateOrderBy: TransactionsAggregateOrderBy;
  TransactionsArrRelInsertInput: TransactionsArrRelInsertInput;
  TransactionsAvgFields: TransactionsAvgFields;
  TransactionsBoolExp: TransactionsBoolExp;
  TransactionsIncInput: TransactionsIncInput;
  TransactionsInsertInput: TransactionsInsertInput;
  TransactionsMaxFields: TransactionsMaxFields;
  TransactionsMinFields: TransactionsMinFields;
  TransactionsMutationResponse: TransactionsMutationResponse;
  TransactionsOnConflict: TransactionsOnConflict;
  TransactionsOrderBy: TransactionsOrderBy;
  TransactionsPkColumnsInput: TransactionsPkColumnsInput;
  TransactionsSetInput: TransactionsSetInput;
  TransactionsStddevFields: TransactionsStddevFields;
  TransactionsStddev_popFields: TransactionsStddev_PopFields;
  TransactionsStddev_sampFields: TransactionsStddev_SampFields;
  TransactionsStreamCursorInput: TransactionsStreamCursorInput;
  TransactionsStreamCursorValueInput: TransactionsStreamCursorValueInput;
  TransactionsSumFields: TransactionsSumFields;
  TransactionsUpdates: TransactionsUpdates;
  TransactionsVar_popFields: TransactionsVar_PopFields;
  TransactionsVar_sampFields: TransactionsVar_SampFields;
  TransactionsVarianceFields: TransactionsVarianceFields;
  UserBalances: UserBalances;
  UserBalancesAggregate: UserBalancesAggregate;
  UserBalancesAggregateFields: UserBalancesAggregateFields;
  UserBalancesAggregateOrderBy: UserBalancesAggregateOrderBy;
  UserBalancesArrRelInsertInput: UserBalancesArrRelInsertInput;
  UserBalancesAvgFields: UserBalancesAvgFields;
  UserBalancesBoolExp: UserBalancesBoolExp;
  UserBalancesInsertInput: UserBalancesInsertInput;
  UserBalancesMaxFields: UserBalancesMaxFields;
  UserBalancesMinFields: UserBalancesMinFields;
  UserBalancesOrderBy: UserBalancesOrderBy;
  UserBalancesStddevFields: UserBalancesStddevFields;
  UserBalancesStddev_popFields: UserBalancesStddev_PopFields;
  UserBalancesStddev_sampFields: UserBalancesStddev_SampFields;
  UserBalancesStreamCursorInput: UserBalancesStreamCursorInput;
  UserBalancesStreamCursorValueInput: UserBalancesStreamCursorValueInput;
  UserBalancesSumFields: UserBalancesSumFields;
  UserBalancesVar_popFields: UserBalancesVar_PopFields;
  UserBalancesVar_sampFields: UserBalancesVar_SampFields;
  UserBalancesVarianceFields: UserBalancesVarianceFields;
  UserOutput: UserOutput;
  UserShareBuys: UserShareBuys;
  UserShareBuysAggregate: UserShareBuysAggregate;
  UserShareBuysAggregateFields: UserShareBuysAggregateFields;
  UserShareBuysAggregateOrderBy: UserShareBuysAggregateOrderBy;
  UserShareBuysArrRelInsertInput: UserShareBuysArrRelInsertInput;
  UserShareBuysAvgFields: UserShareBuysAvgFields;
  UserShareBuysBoolExp: UserShareBuysBoolExp;
  UserShareBuysIncInput: UserShareBuysIncInput;
  UserShareBuysInsertInput: UserShareBuysInsertInput;
  UserShareBuysMaxFields: UserShareBuysMaxFields;
  UserShareBuysMinFields: UserShareBuysMinFields;
  UserShareBuysMutationResponse: UserShareBuysMutationResponse;
  UserShareBuysObjRelInsertInput: UserShareBuysObjRelInsertInput;
  UserShareBuysOnConflict: UserShareBuysOnConflict;
  UserShareBuysOrderBy: UserShareBuysOrderBy;
  UserShareBuysPkColumnsInput: UserShareBuysPkColumnsInput;
  UserShareBuysSetInput: UserShareBuysSetInput;
  UserShareBuysStddevFields: UserShareBuysStddevFields;
  UserShareBuysStddev_popFields: UserShareBuysStddev_PopFields;
  UserShareBuysStddev_sampFields: UserShareBuysStddev_SampFields;
  UserShareBuysStreamCursorInput: UserShareBuysStreamCursorInput;
  UserShareBuysStreamCursorValueInput: UserShareBuysStreamCursorValueInput;
  UserShareBuysSumFields: UserShareBuysSumFields;
  UserShareBuysUpdates: UserShareBuysUpdates;
  UserShareBuysVar_popFields: UserShareBuysVar_PopFields;
  UserShareBuysVar_sampFields: UserShareBuysVar_SampFields;
  UserShareBuysVarianceFields: UserShareBuysVarianceFields;
  Users: Users;
  UsersAggregate: UsersAggregate;
  UsersAggregateFields: UsersAggregateFields;
  UsersAvgFields: UsersAvgFields;
  UsersBoolExp: UsersBoolExp;
  UsersIncInput: UsersIncInput;
  UsersInsertInput: UsersInsertInput;
  UsersMaxFields: UsersMaxFields;
  UsersMinFields: UsersMinFields;
  UsersMutationResponse: UsersMutationResponse;
  UsersObjRelInsertInput: UsersObjRelInsertInput;
  UsersOnConflict: UsersOnConflict;
  UsersOrderBy: UsersOrderBy;
  UsersPkColumnsInput: UsersPkColumnsInput;
  UsersSetInput: UsersSetInput;
  UsersStddevFields: UsersStddevFields;
  UsersStddev_popFields: UsersStddev_PopFields;
  UsersStddev_sampFields: UsersStddev_SampFields;
  UsersStreamCursorInput: UsersStreamCursorInput;
  UsersStreamCursorValueInput: UsersStreamCursorValueInput;
  UsersSumFields: UsersSumFields;
  UsersUpdates: UsersUpdates;
  UsersVar_popFields: UsersVar_PopFields;
  UsersVar_sampFields: UsersVar_SampFields;
  UsersVarianceFields: UsersVarianceFields;
  UuidComparisonExp: UuidComparisonExp;
  VenueUsers: VenueUsers;
  VenueUsersAggregate: VenueUsersAggregate;
  VenueUsersAggregateFields: VenueUsersAggregateFields;
  VenueUsersAggregateOrderBy: VenueUsersAggregateOrderBy;
  VenueUsersArrRelInsertInput: VenueUsersArrRelInsertInput;
  VenueUsersAvgFields: VenueUsersAvgFields;
  VenueUsersBoolExp: VenueUsersBoolExp;
  VenueUsersIncInput: VenueUsersIncInput;
  VenueUsersInsertInput: VenueUsersInsertInput;
  VenueUsersMaxFields: VenueUsersMaxFields;
  VenueUsersMinFields: VenueUsersMinFields;
  VenueUsersMutationResponse: VenueUsersMutationResponse;
  VenueUsersOnConflict: VenueUsersOnConflict;
  VenueUsersOrderBy: VenueUsersOrderBy;
  VenueUsersPkColumnsInput: VenueUsersPkColumnsInput;
  VenueUsersSetInput: VenueUsersSetInput;
  VenueUsersStddevFields: VenueUsersStddevFields;
  VenueUsersStddev_popFields: VenueUsersStddev_PopFields;
  VenueUsersStddev_sampFields: VenueUsersStddev_SampFields;
  VenueUsersStreamCursorInput: VenueUsersStreamCursorInput;
  VenueUsersStreamCursorValueInput: VenueUsersStreamCursorValueInput;
  VenueUsersSumFields: VenueUsersSumFields;
  VenueUsersUpdates: VenueUsersUpdates;
  VenueUsersVar_popFields: VenueUsersVar_PopFields;
  VenueUsersVar_sampFields: VenueUsersVar_SampFields;
  VenueUsersVarianceFields: VenueUsersVarianceFields;
  Venues: Venues;
  VenuesAggregate: VenuesAggregate;
  VenuesAggregateFields: VenuesAggregateFields;
  VenuesAvgFields: VenuesAvgFields;
  VenuesBoolExp: VenuesBoolExp;
  VenuesIncInput: VenuesIncInput;
  VenuesInsertInput: VenuesInsertInput;
  VenuesMaxFields: VenuesMaxFields;
  VenuesMinFields: VenuesMinFields;
  VenuesMutationResponse: VenuesMutationResponse;
  VenuesObjRelInsertInput: VenuesObjRelInsertInput;
  VenuesOnConflict: VenuesOnConflict;
  VenuesOrderBy: VenuesOrderBy;
  VenuesPkColumnsInput: VenuesPkColumnsInput;
  VenuesSetInput: VenuesSetInput;
  VenuesStddevFields: VenuesStddevFields;
  VenuesStddev_popFields: VenuesStddev_PopFields;
  VenuesStddev_sampFields: VenuesStddev_SampFields;
  VenuesStreamCursorInput: VenuesStreamCursorInput;
  VenuesStreamCursorValueInput: VenuesStreamCursorValueInput;
  VenuesSumFields: VenuesSumFields;
  VenuesUpdates: VenuesUpdates;
  VenuesVar_popFields: VenuesVar_PopFields;
  VenuesVar_sampFields: VenuesVar_SampFields;
  VenuesVarianceFields: VenuesVarianceFields;
  activate2FAOutput: Activate2FaOutput;
  authLoginOutput: AuthLoginOutput;
  authSignatureOutput: AuthSignatureOutput;
  bigint: Scalars['bigint']['output'];
  blockchain_currencies_aggregate_bool_exp: Blockchain_Currencies_Aggregate_Bool_Exp;
  blockchain_currencies_aggregate_bool_exp_bool_and: Blockchain_Currencies_Aggregate_Bool_Exp_Bool_And;
  blockchain_currencies_aggregate_bool_exp_bool_or: Blockchain_Currencies_Aggregate_Bool_Exp_Bool_Or;
  blockchain_currencies_aggregate_bool_exp_count: Blockchain_Currencies_Aggregate_Bool_Exp_Count;
  blockchain_currencies_avg_order_by: Blockchain_Currencies_Avg_Order_By;
  blockchain_currencies_max_order_by: Blockchain_Currencies_Max_Order_By;
  blockchain_currencies_min_order_by: Blockchain_Currencies_Min_Order_By;
  blockchain_currencies_stddev_order_by: Blockchain_Currencies_Stddev_Order_By;
  blockchain_currencies_stddev_pop_order_by: Blockchain_Currencies_Stddev_Pop_Order_By;
  blockchain_currencies_stddev_samp_order_by: Blockchain_Currencies_Stddev_Samp_Order_By;
  blockchain_currencies_sum_order_by: Blockchain_Currencies_Sum_Order_By;
  blockchain_currencies_var_pop_order_by: Blockchain_Currencies_Var_Pop_Order_By;
  blockchain_currencies_var_samp_order_by: Blockchain_Currencies_Var_Samp_Order_By;
  blockchain_currencies_variance_order_by: Blockchain_Currencies_Variance_Order_By;
  currencies_aggregate_bool_exp: Currencies_Aggregate_Bool_Exp;
  currencies_aggregate_bool_exp_bool_and: Currencies_Aggregate_Bool_Exp_Bool_And;
  currencies_aggregate_bool_exp_bool_or: Currencies_Aggregate_Bool_Exp_Bool_Or;
  currencies_aggregate_bool_exp_count: Currencies_Aggregate_Bool_Exp_Count;
  currencies_avg_order_by: Currencies_Avg_Order_By;
  currencies_max_order_by: Currencies_Max_Order_By;
  currencies_min_order_by: Currencies_Min_Order_By;
  currencies_stddev_order_by: Currencies_Stddev_Order_By;
  currencies_stddev_pop_order_by: Currencies_Stddev_Pop_Order_By;
  currencies_stddev_samp_order_by: Currencies_Stddev_Samp_Order_By;
  currencies_sum_order_by: Currencies_Sum_Order_By;
  currencies_var_pop_order_by: Currencies_Var_Pop_Order_By;
  currencies_var_samp_order_by: Currencies_Var_Samp_Order_By;
  currencies_variance_order_by: Currencies_Variance_Order_By;
  date: Scalars['date']['output'];
  depositAddressOutput: DepositAddressOutput;
  depositBankOutput: DepositBankOutput;
  downloadFileOutput: DownloadFileOutput;
  events_aggregate_bool_exp: Events_Aggregate_Bool_Exp;
  events_aggregate_bool_exp_count: Events_Aggregate_Bool_Exp_Count;
  events_avg_order_by: Events_Avg_Order_By;
  events_max_order_by: Events_Max_Order_By;
  events_min_order_by: Events_Min_Order_By;
  events_stddev_order_by: Events_Stddev_Order_By;
  events_stddev_pop_order_by: Events_Stddev_Pop_Order_By;
  events_stddev_samp_order_by: Events_Stddev_Samp_Order_By;
  events_sum_order_by: Events_Sum_Order_By;
  events_var_pop_order_by: Events_Var_Pop_Order_By;
  events_var_samp_order_by: Events_Var_Samp_Order_By;
  events_variance_order_by: Events_Variance_Order_By;
  followers_aggregate_bool_exp: Followers_Aggregate_Bool_Exp;
  followers_aggregate_bool_exp_count: Followers_Aggregate_Bool_Exp_Count;
  followers_avg_order_by: Followers_Avg_Order_By;
  followers_max_order_by: Followers_Max_Order_By;
  followers_min_order_by: Followers_Min_Order_By;
  followers_stddev_order_by: Followers_Stddev_Order_By;
  followers_stddev_pop_order_by: Followers_Stddev_Pop_Order_By;
  followers_stddev_samp_order_by: Followers_Stddev_Samp_Order_By;
  followers_sum_order_by: Followers_Sum_Order_By;
  followers_var_pop_order_by: Followers_Var_Pop_Order_By;
  followers_var_samp_order_by: Followers_Var_Samp_Order_By;
  followers_variance_order_by: Followers_Variance_Order_By;
  generalOutput: GeneralOutput;
  jsonb: Scalars['jsonb']['output'];
  mutation_root: {};
  numeric: Scalars['numeric']['output'];
  payment_method_banks_aggregate_bool_exp: Payment_Method_Banks_Aggregate_Bool_Exp;
  payment_method_banks_aggregate_bool_exp_bool_and: Payment_Method_Banks_Aggregate_Bool_Exp_Bool_And;
  payment_method_banks_aggregate_bool_exp_bool_or: Payment_Method_Banks_Aggregate_Bool_Exp_Bool_Or;
  payment_method_banks_aggregate_bool_exp_count: Payment_Method_Banks_Aggregate_Bool_Exp_Count;
  payment_method_banks_avg_order_by: Payment_Method_Banks_Avg_Order_By;
  payment_method_banks_max_order_by: Payment_Method_Banks_Max_Order_By;
  payment_method_banks_min_order_by: Payment_Method_Banks_Min_Order_By;
  payment_method_banks_stddev_order_by: Payment_Method_Banks_Stddev_Order_By;
  payment_method_banks_stddev_pop_order_by: Payment_Method_Banks_Stddev_Pop_Order_By;
  payment_method_banks_stddev_samp_order_by: Payment_Method_Banks_Stddev_Samp_Order_By;
  payment_method_banks_sum_order_by: Payment_Method_Banks_Sum_Order_By;
  payment_method_banks_var_pop_order_by: Payment_Method_Banks_Var_Pop_Order_By;
  payment_method_banks_var_samp_order_by: Payment_Method_Banks_Var_Samp_Order_By;
  payment_method_banks_variance_order_by: Payment_Method_Banks_Variance_Order_By;
  playerRegisterInput: PlayerRegisterInput;
  playerShareResultOutput: PlayerShareResultOutput;
  player_followers_view_aggregate_bool_exp: Player_Followers_View_Aggregate_Bool_Exp;
  player_followers_view_aggregate_bool_exp_count: Player_Followers_View_Aggregate_Bool_Exp_Count;
  player_followers_view_avg_order_by: Player_Followers_View_Avg_Order_By;
  player_followers_view_max_order_by: Player_Followers_View_Max_Order_By;
  player_followers_view_min_order_by: Player_Followers_View_Min_Order_By;
  player_followers_view_stddev_order_by: Player_Followers_View_Stddev_Order_By;
  player_followers_view_stddev_pop_order_by: Player_Followers_View_Stddev_Pop_Order_By;
  player_followers_view_stddev_samp_order_by: Player_Followers_View_Stddev_Samp_Order_By;
  player_followers_view_sum_order_by: Player_Followers_View_Sum_Order_By;
  player_followers_view_var_pop_order_by: Player_Followers_View_Var_Pop_Order_By;
  player_followers_view_var_samp_order_by: Player_Followers_View_Var_Samp_Order_By;
  player_followers_view_variance_order_by: Player_Followers_View_Variance_Order_By;
  player_shares_aggregate_bool_exp: Player_Shares_Aggregate_Bool_Exp;
  player_shares_aggregate_bool_exp_bool_and: Player_Shares_Aggregate_Bool_Exp_Bool_And;
  player_shares_aggregate_bool_exp_bool_or: Player_Shares_Aggregate_Bool_Exp_Bool_Or;
  player_shares_aggregate_bool_exp_count: Player_Shares_Aggregate_Bool_Exp_Count;
  player_shares_avg_order_by: Player_Shares_Avg_Order_By;
  player_shares_max_order_by: Player_Shares_Max_Order_By;
  player_shares_min_order_by: Player_Shares_Min_Order_By;
  player_shares_stddev_order_by: Player_Shares_Stddev_Order_By;
  player_shares_stddev_pop_order_by: Player_Shares_Stddev_Pop_Order_By;
  player_shares_stddev_samp_order_by: Player_Shares_Stddev_Samp_Order_By;
  player_shares_sum_order_by: Player_Shares_Sum_Order_By;
  player_shares_var_pop_order_by: Player_Shares_Var_Pop_Order_By;
  player_shares_var_samp_order_by: Player_Shares_Var_Samp_Order_By;
  player_shares_variance_order_by: Player_Shares_Variance_Order_By;
  players_aggregate_bool_exp: Players_Aggregate_Bool_Exp;
  players_aggregate_bool_exp_bool_and: Players_Aggregate_Bool_Exp_Bool_And;
  players_aggregate_bool_exp_bool_or: Players_Aggregate_Bool_Exp_Bool_Or;
  players_aggregate_bool_exp_count: Players_Aggregate_Bool_Exp_Count;
  players_avg_order_by: Players_Avg_Order_By;
  players_max_order_by: Players_Max_Order_By;
  players_min_order_by: Players_Min_Order_By;
  players_stddev_order_by: Players_Stddev_Order_By;
  players_stddev_pop_order_by: Players_Stddev_Pop_Order_By;
  players_stddev_samp_order_by: Players_Stddev_Samp_Order_By;
  players_sum_order_by: Players_Sum_Order_By;
  players_var_pop_order_by: Players_Var_Pop_Order_By;
  players_var_samp_order_by: Players_Var_Samp_Order_By;
  players_variance_order_by: Players_Variance_Order_By;
  query_root: {};
  stat_tournament_results_aggregate_bool_exp: Stat_Tournament_Results_Aggregate_Bool_Exp;
  stat_tournament_results_aggregate_bool_exp_count: Stat_Tournament_Results_Aggregate_Bool_Exp_Count;
  stat_tournament_results_avg_order_by: Stat_Tournament_Results_Avg_Order_By;
  stat_tournament_results_max_order_by: Stat_Tournament_Results_Max_Order_By;
  stat_tournament_results_min_order_by: Stat_Tournament_Results_Min_Order_By;
  stat_tournament_results_stddev_order_by: Stat_Tournament_Results_Stddev_Order_By;
  stat_tournament_results_stddev_pop_order_by: Stat_Tournament_Results_Stddev_Pop_Order_By;
  stat_tournament_results_stddev_samp_order_by: Stat_Tournament_Results_Stddev_Samp_Order_By;
  stat_tournament_results_sum_order_by: Stat_Tournament_Results_Sum_Order_By;
  stat_tournament_results_var_pop_order_by: Stat_Tournament_Results_Var_Pop_Order_By;
  stat_tournament_results_var_samp_order_by: Stat_Tournament_Results_Var_Samp_Order_By;
  stat_tournament_results_variance_order_by: Stat_Tournament_Results_Variance_Order_By;
  subscription_root: {};
  tickets_aggregate_bool_exp: Tickets_Aggregate_Bool_Exp;
  tickets_aggregate_bool_exp_count: Tickets_Aggregate_Bool_Exp_Count;
  tickets_avg_order_by: Tickets_Avg_Order_By;
  tickets_max_order_by: Tickets_Max_Order_By;
  tickets_min_order_by: Tickets_Min_Order_By;
  tickets_stddev_order_by: Tickets_Stddev_Order_By;
  tickets_stddev_pop_order_by: Tickets_Stddev_Pop_Order_By;
  tickets_stddev_samp_order_by: Tickets_Stddev_Samp_Order_By;
  tickets_sum_order_by: Tickets_Sum_Order_By;
  tickets_var_pop_order_by: Tickets_Var_Pop_Order_By;
  tickets_var_samp_order_by: Tickets_Var_Samp_Order_By;
  tickets_variance_order_by: Tickets_Variance_Order_By;
  timestamp: Scalars['timestamp']['output'];
  timestamptz: Scalars['timestamptz']['output'];
  tournament_balances_aggregate_bool_exp: Tournament_Balances_Aggregate_Bool_Exp;
  tournament_balances_aggregate_bool_exp_count: Tournament_Balances_Aggregate_Bool_Exp_Count;
  tournament_balances_avg_order_by: Tournament_Balances_Avg_Order_By;
  tournament_balances_max_order_by: Tournament_Balances_Max_Order_By;
  tournament_balances_min_order_by: Tournament_Balances_Min_Order_By;
  tournament_balances_stddev_order_by: Tournament_Balances_Stddev_Order_By;
  tournament_balances_stddev_pop_order_by: Tournament_Balances_Stddev_Pop_Order_By;
  tournament_balances_stddev_samp_order_by: Tournament_Balances_Stddev_Samp_Order_By;
  tournament_balances_sum_order_by: Tournament_Balances_Sum_Order_By;
  tournament_balances_var_pop_order_by: Tournament_Balances_Var_Pop_Order_By;
  tournament_balances_var_samp_order_by: Tournament_Balances_Var_Samp_Order_By;
  tournament_balances_variance_order_by: Tournament_Balances_Variance_Order_By;
  tournament_results_aggregate_bool_exp: Tournament_Results_Aggregate_Bool_Exp;
  tournament_results_aggregate_bool_exp_count: Tournament_Results_Aggregate_Bool_Exp_Count;
  tournament_results_avg_order_by: Tournament_Results_Avg_Order_By;
  tournament_results_max_order_by: Tournament_Results_Max_Order_By;
  tournament_results_min_order_by: Tournament_Results_Min_Order_By;
  tournament_results_stddev_order_by: Tournament_Results_Stddev_Order_By;
  tournament_results_stddev_pop_order_by: Tournament_Results_Stddev_Pop_Order_By;
  tournament_results_stddev_samp_order_by: Tournament_Results_Stddev_Samp_Order_By;
  tournament_results_sum_order_by: Tournament_Results_Sum_Order_By;
  tournament_results_var_pop_order_by: Tournament_Results_Var_Pop_Order_By;
  tournament_results_var_samp_order_by: Tournament_Results_Var_Samp_Order_By;
  tournament_results_variance_order_by: Tournament_Results_Variance_Order_By;
  tournaments_aggregate_bool_exp: Tournaments_Aggregate_Bool_Exp;
  tournaments_aggregate_bool_exp_bool_and: Tournaments_Aggregate_Bool_Exp_Bool_And;
  tournaments_aggregate_bool_exp_bool_or: Tournaments_Aggregate_Bool_Exp_Bool_Or;
  tournaments_aggregate_bool_exp_count: Tournaments_Aggregate_Bool_Exp_Count;
  tournaments_avg_order_by: Tournaments_Avg_Order_By;
  tournaments_max_order_by: Tournaments_Max_Order_By;
  tournaments_min_order_by: Tournaments_Min_Order_By;
  tournaments_stddev_order_by: Tournaments_Stddev_Order_By;
  tournaments_stddev_pop_order_by: Tournaments_Stddev_Pop_Order_By;
  tournaments_stddev_samp_order_by: Tournaments_Stddev_Samp_Order_By;
  tournaments_sum_order_by: Tournaments_Sum_Order_By;
  tournaments_var_pop_order_by: Tournaments_Var_Pop_Order_By;
  tournaments_var_samp_order_by: Tournaments_Var_Samp_Order_By;
  tournaments_variance_order_by: Tournaments_Variance_Order_By;
  transactions_aggregate_bool_exp: Transactions_Aggregate_Bool_Exp;
  transactions_aggregate_bool_exp_count: Transactions_Aggregate_Bool_Exp_Count;
  transactions_avg_order_by: Transactions_Avg_Order_By;
  transactions_max_order_by: Transactions_Max_Order_By;
  transactions_min_order_by: Transactions_Min_Order_By;
  transactions_stddev_order_by: Transactions_Stddev_Order_By;
  transactions_stddev_pop_order_by: Transactions_Stddev_Pop_Order_By;
  transactions_stddev_samp_order_by: Transactions_Stddev_Samp_Order_By;
  transactions_sum_order_by: Transactions_Sum_Order_By;
  transactions_var_pop_order_by: Transactions_Var_Pop_Order_By;
  transactions_var_samp_order_by: Transactions_Var_Samp_Order_By;
  transactions_variance_order_by: Transactions_Variance_Order_By;
  user_balances_aggregate_bool_exp: User_Balances_Aggregate_Bool_Exp;
  user_balances_aggregate_bool_exp_count: User_Balances_Aggregate_Bool_Exp_Count;
  user_balances_avg_order_by: User_Balances_Avg_Order_By;
  user_balances_max_order_by: User_Balances_Max_Order_By;
  user_balances_min_order_by: User_Balances_Min_Order_By;
  user_balances_stddev_order_by: User_Balances_Stddev_Order_By;
  user_balances_stddev_pop_order_by: User_Balances_Stddev_Pop_Order_By;
  user_balances_stddev_samp_order_by: User_Balances_Stddev_Samp_Order_By;
  user_balances_sum_order_by: User_Balances_Sum_Order_By;
  user_balances_var_pop_order_by: User_Balances_Var_Pop_Order_By;
  user_balances_var_samp_order_by: User_Balances_Var_Samp_Order_By;
  user_balances_variance_order_by: User_Balances_Variance_Order_By;
  user_share_buys_aggregate_bool_exp: User_Share_Buys_Aggregate_Bool_Exp;
  user_share_buys_aggregate_bool_exp_count: User_Share_Buys_Aggregate_Bool_Exp_Count;
  user_share_buys_avg_order_by: User_Share_Buys_Avg_Order_By;
  user_share_buys_max_order_by: User_Share_Buys_Max_Order_By;
  user_share_buys_min_order_by: User_Share_Buys_Min_Order_By;
  user_share_buys_stddev_order_by: User_Share_Buys_Stddev_Order_By;
  user_share_buys_stddev_pop_order_by: User_Share_Buys_Stddev_Pop_Order_By;
  user_share_buys_stddev_samp_order_by: User_Share_Buys_Stddev_Samp_Order_By;
  user_share_buys_sum_order_by: User_Share_Buys_Sum_Order_By;
  user_share_buys_var_pop_order_by: User_Share_Buys_Var_Pop_Order_By;
  user_share_buys_var_samp_order_by: User_Share_Buys_Var_Samp_Order_By;
  user_share_buys_variance_order_by: User_Share_Buys_Variance_Order_By;
  uuid: Scalars['uuid']['output'];
  venue_users_aggregate_bool_exp: Venue_Users_Aggregate_Bool_Exp;
  venue_users_aggregate_bool_exp_count: Venue_Users_Aggregate_Bool_Exp_Count;
  venue_users_avg_order_by: Venue_Users_Avg_Order_By;
  venue_users_max_order_by: Venue_Users_Max_Order_By;
  venue_users_min_order_by: Venue_Users_Min_Order_By;
  venue_users_stddev_order_by: Venue_Users_Stddev_Order_By;
  venue_users_stddev_pop_order_by: Venue_Users_Stddev_Pop_Order_By;
  venue_users_stddev_samp_order_by: Venue_Users_Stddev_Samp_Order_By;
  venue_users_sum_order_by: Venue_Users_Sum_Order_By;
  venue_users_var_pop_order_by: Venue_Users_Var_Pop_Order_By;
  venue_users_var_samp_order_by: Venue_Users_Var_Samp_Order_By;
  venue_users_variance_order_by: Venue_Users_Variance_Order_By;
};

export type CachedDirectiveArgs = {
  refresh?: Scalars['Boolean']['input'];
  ttl?: Scalars['Int']['input'];
};

export type CachedDirectiveResolver<
  Result,
  Parent,
  ContextType = any,
  Args = CachedDirectiveArgs
> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type ClientDirectiveArgs = {
  always?: Maybe<Scalars['Boolean']['input']>;
};

export type ClientDirectiveResolver<
  Result,
  Parent,
  ContextType = any,
  Args = ClientDirectiveArgs
> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type BlockchainCurrenciesResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BlockchainCurrencies'] = ResolversParentTypes['BlockchainCurrencies']
> = {
  blockchain?: Resolver<ResolversTypes['Blockchains'], ParentType, ContextType>;
  blockchainId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  contract?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  currency?: Resolver<ResolversTypes['Currencies'], ParentType, ContextType>;
  currencyId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  decimals?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  deposit?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  icon?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  symbol?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  units?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  withdraw?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  withdrawFee?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  withdrawMin?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BlockchainCurrenciesAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BlockchainCurrenciesAggregate'] = ResolversParentTypes['BlockchainCurrenciesAggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['BlockchainCurrenciesAggregateFields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes['BlockchainCurrencies']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BlockchainCurrenciesAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BlockchainCurrenciesAggregateFields'] = ResolversParentTypes['BlockchainCurrenciesAggregateFields']
> = {
  avg?: Resolver<
    Maybe<ResolversTypes['BlockchainCurrenciesAvgFields']>,
    ParentType,
    ContextType
  >;
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    Partial<BlockchainCurrenciesAggregateFieldsCountArgs>
  >;
  max?: Resolver<
    Maybe<ResolversTypes['BlockchainCurrenciesMaxFields']>,
    ParentType,
    ContextType
  >;
  min?: Resolver<
    Maybe<ResolversTypes['BlockchainCurrenciesMinFields']>,
    ParentType,
    ContextType
  >;
  stddev?: Resolver<
    Maybe<ResolversTypes['BlockchainCurrenciesStddevFields']>,
    ParentType,
    ContextType
  >;
  stddevPop?: Resolver<
    Maybe<ResolversTypes['BlockchainCurrenciesStddev_popFields']>,
    ParentType,
    ContextType
  >;
  stddevSamp?: Resolver<
    Maybe<ResolversTypes['BlockchainCurrenciesStddev_sampFields']>,
    ParentType,
    ContextType
  >;
  sum?: Resolver<
    Maybe<ResolversTypes['BlockchainCurrenciesSumFields']>,
    ParentType,
    ContextType
  >;
  varPop?: Resolver<
    Maybe<ResolversTypes['BlockchainCurrenciesVar_popFields']>,
    ParentType,
    ContextType
  >;
  varSamp?: Resolver<
    Maybe<ResolversTypes['BlockchainCurrenciesVar_sampFields']>,
    ParentType,
    ContextType
  >;
  variance?: Resolver<
    Maybe<ResolversTypes['BlockchainCurrenciesVarianceFields']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BlockchainCurrenciesAvgFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BlockchainCurrenciesAvgFields'] = ResolversParentTypes['BlockchainCurrenciesAvgFields']
> = {
  blockchainId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  currencyId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  decimals?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  withdrawFee?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  withdrawMin?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BlockchainCurrenciesMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BlockchainCurrenciesMaxFields'] = ResolversParentTypes['BlockchainCurrenciesMaxFields']
> = {
  blockchainId?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  contract?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  currencyId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  decimals?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  icon?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  symbol?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  units?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  value?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  withdrawFee?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  withdrawMin?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BlockchainCurrenciesMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BlockchainCurrenciesMinFields'] = ResolversParentTypes['BlockchainCurrenciesMinFields']
> = {
  blockchainId?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  contract?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  currencyId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  decimals?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  icon?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  symbol?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  units?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  value?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  withdrawFee?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  withdrawMin?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BlockchainCurrenciesMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BlockchainCurrenciesMutationResponse'] = ResolversParentTypes['BlockchainCurrenciesMutationResponse']
> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<
    Array<ResolversTypes['BlockchainCurrencies']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BlockchainCurrenciesStddevFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BlockchainCurrenciesStddevFields'] = ResolversParentTypes['BlockchainCurrenciesStddevFields']
> = {
  blockchainId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  currencyId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  decimals?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  withdrawFee?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  withdrawMin?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BlockchainCurrenciesStddev_PopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BlockchainCurrenciesStddev_popFields'] = ResolversParentTypes['BlockchainCurrenciesStddev_popFields']
> = {
  blockchainId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  currencyId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  decimals?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  withdrawFee?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  withdrawMin?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BlockchainCurrenciesStddev_SampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BlockchainCurrenciesStddev_sampFields'] = ResolversParentTypes['BlockchainCurrenciesStddev_sampFields']
> = {
  blockchainId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  currencyId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  decimals?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  withdrawFee?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  withdrawMin?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BlockchainCurrenciesSumFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BlockchainCurrenciesSumFields'] = ResolversParentTypes['BlockchainCurrenciesSumFields']
> = {
  blockchainId?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  currencyId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  decimals?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  withdrawFee?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  withdrawMin?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BlockchainCurrenciesVar_PopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BlockchainCurrenciesVar_popFields'] = ResolversParentTypes['BlockchainCurrenciesVar_popFields']
> = {
  blockchainId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  currencyId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  decimals?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  withdrawFee?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  withdrawMin?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BlockchainCurrenciesVar_SampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BlockchainCurrenciesVar_sampFields'] = ResolversParentTypes['BlockchainCurrenciesVar_sampFields']
> = {
  blockchainId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  currencyId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  decimals?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  withdrawFee?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  withdrawMin?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BlockchainCurrenciesVarianceFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BlockchainCurrenciesVarianceFields'] = ResolversParentTypes['BlockchainCurrenciesVarianceFields']
> = {
  blockchainId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  currencyId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  decimals?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  withdrawFee?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  withdrawMin?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BlockchainsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Blockchains'] = ResolversParentTypes['Blockchains']
> = {
  active?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  alchemy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  apiKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  apiUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  apiUrlProxy?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  blockchainCurrenciesAggregate?: Resolver<
    ResolversTypes['BlockchainCurrenciesAggregate'],
    ParentType,
    ContextType,
    Partial<BlockchainsBlockchainCurrenciesAggregateArgs>
  >;
  blockchain_currencies?: Resolver<
    Array<ResolversTypes['BlockchainCurrencies']>,
    ParentType,
    ContextType,
    Partial<BlockchainsBlockchain_CurrenciesArgs>
  >;
  chainId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  iconUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  nativeCurrency?: Resolver<
    Maybe<ResolversTypes['jsonb']>,
    ParentType,
    ContextType,
    Partial<BlockchainsNativeCurrencyArgs>
  >;
  rpc?: Resolver<
    ResolversTypes['jsonb'],
    ParentType,
    ContextType,
    Partial<BlockchainsRpcArgs>
  >;
  rpcPublic?: Resolver<
    ResolversTypes['jsonb'],
    ParentType,
    ContextType,
    Partial<BlockchainsRpcPublicArgs>
  >;
  scanUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  symbol?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BlockchainsAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BlockchainsAggregate'] = ResolversParentTypes['BlockchainsAggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['BlockchainsAggregateFields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes['Blockchains']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BlockchainsAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BlockchainsAggregateFields'] = ResolversParentTypes['BlockchainsAggregateFields']
> = {
  avg?: Resolver<
    Maybe<ResolversTypes['BlockchainsAvgFields']>,
    ParentType,
    ContextType
  >;
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    Partial<BlockchainsAggregateFieldsCountArgs>
  >;
  max?: Resolver<
    Maybe<ResolversTypes['BlockchainsMaxFields']>,
    ParentType,
    ContextType
  >;
  min?: Resolver<
    Maybe<ResolversTypes['BlockchainsMinFields']>,
    ParentType,
    ContextType
  >;
  stddev?: Resolver<
    Maybe<ResolversTypes['BlockchainsStddevFields']>,
    ParentType,
    ContextType
  >;
  stddevPop?: Resolver<
    Maybe<ResolversTypes['BlockchainsStddev_popFields']>,
    ParentType,
    ContextType
  >;
  stddevSamp?: Resolver<
    Maybe<ResolversTypes['BlockchainsStddev_sampFields']>,
    ParentType,
    ContextType
  >;
  sum?: Resolver<
    Maybe<ResolversTypes['BlockchainsSumFields']>,
    ParentType,
    ContextType
  >;
  varPop?: Resolver<
    Maybe<ResolversTypes['BlockchainsVar_popFields']>,
    ParentType,
    ContextType
  >;
  varSamp?: Resolver<
    Maybe<ResolversTypes['BlockchainsVar_sampFields']>,
    ParentType,
    ContextType
  >;
  variance?: Resolver<
    Maybe<ResolversTypes['BlockchainsVarianceFields']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BlockchainsAvgFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BlockchainsAvgFields'] = ResolversParentTypes['BlockchainsAvgFields']
> = {
  chainId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BlockchainsMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BlockchainsMaxFields'] = ResolversParentTypes['BlockchainsMaxFields']
> = {
  alchemy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  apiKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  apiUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  apiUrlProxy?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  chainId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  createdAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  iconUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  scanUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  symbol?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BlockchainsMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BlockchainsMinFields'] = ResolversParentTypes['BlockchainsMinFields']
> = {
  alchemy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  apiKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  apiUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  apiUrlProxy?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  chainId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  createdAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  iconUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  scanUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  symbol?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BlockchainsMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BlockchainsMutationResponse'] = ResolversParentTypes['BlockchainsMutationResponse']
> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<
    Array<ResolversTypes['Blockchains']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BlockchainsStddevFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BlockchainsStddevFields'] = ResolversParentTypes['BlockchainsStddevFields']
> = {
  chainId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BlockchainsStddev_PopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BlockchainsStddev_popFields'] = ResolversParentTypes['BlockchainsStddev_popFields']
> = {
  chainId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BlockchainsStddev_SampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BlockchainsStddev_sampFields'] = ResolversParentTypes['BlockchainsStddev_sampFields']
> = {
  chainId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BlockchainsSumFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BlockchainsSumFields'] = ResolversParentTypes['BlockchainsSumFields']
> = {
  chainId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BlockchainsVar_PopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BlockchainsVar_popFields'] = ResolversParentTypes['BlockchainsVar_popFields']
> = {
  chainId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BlockchainsVar_SampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BlockchainsVar_sampFields'] = ResolversParentTypes['BlockchainsVar_sampFields']
> = {
  chainId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BlockchainsVarianceFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BlockchainsVarianceFields'] = ResolversParentTypes['BlockchainsVarianceFields']
> = {
  chainId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CountriesResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Countries'] = ResolversParentTypes['Countries']
> = {
  createdAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  currencies?: Resolver<
    Array<ResolversTypes['Currencies']>,
    ParentType,
    ContextType,
    Partial<CountriesCurrenciesArgs>
  >;
  currenciesAggregate?: Resolver<
    ResolversTypes['CurrenciesAggregate'],
    ParentType,
    ContextType,
    Partial<CountriesCurrenciesAggregateArgs>
  >;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  language?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  paymentMethodBanksAggregate?: Resolver<
    ResolversTypes['PaymentMethodBanksAggregate'],
    ParentType,
    ContextType,
    Partial<CountriesPaymentMethodBanksAggregateArgs>
  >;
  payment_method_banks?: Resolver<
    Array<ResolversTypes['PaymentMethodBanks']>,
    ParentType,
    ContextType,
    Partial<CountriesPayment_Method_BanksArgs>
  >;
  updatedAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CountriesAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CountriesAggregate'] = ResolversParentTypes['CountriesAggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['CountriesAggregateFields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<Array<ResolversTypes['Countries']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CountriesAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CountriesAggregateFields'] = ResolversParentTypes['CountriesAggregateFields']
> = {
  avg?: Resolver<
    Maybe<ResolversTypes['CountriesAvgFields']>,
    ParentType,
    ContextType
  >;
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    Partial<CountriesAggregateFieldsCountArgs>
  >;
  max?: Resolver<
    Maybe<ResolversTypes['CountriesMaxFields']>,
    ParentType,
    ContextType
  >;
  min?: Resolver<
    Maybe<ResolversTypes['CountriesMinFields']>,
    ParentType,
    ContextType
  >;
  stddev?: Resolver<
    Maybe<ResolversTypes['CountriesStddevFields']>,
    ParentType,
    ContextType
  >;
  stddevPop?: Resolver<
    Maybe<ResolversTypes['CountriesStddev_popFields']>,
    ParentType,
    ContextType
  >;
  stddevSamp?: Resolver<
    Maybe<ResolversTypes['CountriesStddev_sampFields']>,
    ParentType,
    ContextType
  >;
  sum?: Resolver<
    Maybe<ResolversTypes['CountriesSumFields']>,
    ParentType,
    ContextType
  >;
  varPop?: Resolver<
    Maybe<ResolversTypes['CountriesVar_popFields']>,
    ParentType,
    ContextType
  >;
  varSamp?: Resolver<
    Maybe<ResolversTypes['CountriesVar_sampFields']>,
    ParentType,
    ContextType
  >;
  variance?: Resolver<
    Maybe<ResolversTypes['CountriesVarianceFields']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CountriesAvgFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CountriesAvgFields'] = ResolversParentTypes['CountriesAvgFields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CountriesMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CountriesMaxFields'] = ResolversParentTypes['CountriesMaxFields']
> = {
  createdAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  language?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CountriesMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CountriesMinFields'] = ResolversParentTypes['CountriesMinFields']
> = {
  createdAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  language?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CountriesMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CountriesMutationResponse'] = ResolversParentTypes['CountriesMutationResponse']
> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<
    Array<ResolversTypes['Countries']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CountriesStddevFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CountriesStddevFields'] = ResolversParentTypes['CountriesStddevFields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CountriesStddev_PopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CountriesStddev_popFields'] = ResolversParentTypes['CountriesStddev_popFields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CountriesStddev_SampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CountriesStddev_sampFields'] = ResolversParentTypes['CountriesStddev_sampFields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CountriesSumFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CountriesSumFields'] = ResolversParentTypes['CountriesSumFields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CountriesVar_PopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CountriesVar_popFields'] = ResolversParentTypes['CountriesVar_popFields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CountriesVar_SampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CountriesVar_sampFields'] = ResolversParentTypes['CountriesVar_sampFields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CountriesVarianceFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CountriesVarianceFields'] = ResolversParentTypes['CountriesVarianceFields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CurrenciesResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Currencies'] = ResolversParentTypes['Currencies']
> = {
  active?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  blockchainCurrenciesAggregate?: Resolver<
    ResolversTypes['BlockchainCurrenciesAggregate'],
    ParentType,
    ContextType,
    Partial<CurrenciesBlockchainCurrenciesAggregateArgs>
  >;
  blockchain_currencies?: Resolver<
    Array<ResolversTypes['BlockchainCurrencies']>,
    ParentType,
    ContextType,
    Partial<CurrenciesBlockchain_CurrenciesArgs>
  >;
  country?: Resolver<ResolversTypes['Countries'], ParentType, ContextType>;
  countryId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  decimals?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  icon?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  rate?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  rounding?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  symbol?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CurrenciesAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CurrenciesAggregate'] = ResolversParentTypes['CurrenciesAggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['CurrenciesAggregateFields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes['Currencies']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CurrenciesAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CurrenciesAggregateFields'] = ResolversParentTypes['CurrenciesAggregateFields']
> = {
  avg?: Resolver<
    Maybe<ResolversTypes['CurrenciesAvgFields']>,
    ParentType,
    ContextType
  >;
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    Partial<CurrenciesAggregateFieldsCountArgs>
  >;
  max?: Resolver<
    Maybe<ResolversTypes['CurrenciesMaxFields']>,
    ParentType,
    ContextType
  >;
  min?: Resolver<
    Maybe<ResolversTypes['CurrenciesMinFields']>,
    ParentType,
    ContextType
  >;
  stddev?: Resolver<
    Maybe<ResolversTypes['CurrenciesStddevFields']>,
    ParentType,
    ContextType
  >;
  stddevPop?: Resolver<
    Maybe<ResolversTypes['CurrenciesStddev_popFields']>,
    ParentType,
    ContextType
  >;
  stddevSamp?: Resolver<
    Maybe<ResolversTypes['CurrenciesStddev_sampFields']>,
    ParentType,
    ContextType
  >;
  sum?: Resolver<
    Maybe<ResolversTypes['CurrenciesSumFields']>,
    ParentType,
    ContextType
  >;
  varPop?: Resolver<
    Maybe<ResolversTypes['CurrenciesVar_popFields']>,
    ParentType,
    ContextType
  >;
  varSamp?: Resolver<
    Maybe<ResolversTypes['CurrenciesVar_sampFields']>,
    ParentType,
    ContextType
  >;
  variance?: Resolver<
    Maybe<ResolversTypes['CurrenciesVarianceFields']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CurrenciesAvgFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CurrenciesAvgFields'] = ResolversParentTypes['CurrenciesAvgFields']
> = {
  countryId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  decimals?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rounding?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CurrenciesMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CurrenciesMaxFields'] = ResolversParentTypes['CurrenciesMaxFields']
> = {
  countryId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  createdAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  decimals?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  icon?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rate?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  rounding?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  symbol?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CurrenciesMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CurrenciesMinFields'] = ResolversParentTypes['CurrenciesMinFields']
> = {
  countryId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  createdAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  decimals?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  icon?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rate?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  rounding?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  symbol?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CurrenciesMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CurrenciesMutationResponse'] = ResolversParentTypes['CurrenciesMutationResponse']
> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<
    Array<ResolversTypes['Currencies']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CurrenciesStddevFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CurrenciesStddevFields'] = ResolversParentTypes['CurrenciesStddevFields']
> = {
  countryId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  decimals?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rounding?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CurrenciesStddev_PopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CurrenciesStddev_popFields'] = ResolversParentTypes['CurrenciesStddev_popFields']
> = {
  countryId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  decimals?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rounding?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CurrenciesStddev_SampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CurrenciesStddev_sampFields'] = ResolversParentTypes['CurrenciesStddev_sampFields']
> = {
  countryId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  decimals?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rounding?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CurrenciesSumFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CurrenciesSumFields'] = ResolversParentTypes['CurrenciesSumFields']
> = {
  countryId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  decimals?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  rate?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  rounding?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CurrenciesVar_PopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CurrenciesVar_popFields'] = ResolversParentTypes['CurrenciesVar_popFields']
> = {
  countryId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  decimals?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rounding?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CurrenciesVar_SampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CurrenciesVar_sampFields'] = ResolversParentTypes['CurrenciesVar_sampFields']
> = {
  countryId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  decimals?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rounding?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CurrenciesVarianceFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CurrenciesVarianceFields'] = ResolversParentTypes['CurrenciesVarianceFields']
> = {
  countryId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  decimals?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rounding?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DepositOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DepositOutput'] = ResolversParentTypes['DepositOutput']
> = {
  depositAddress?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<
    ResolversTypes['transactionStatus'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EventsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Events'] = ResolversParentTypes['Events']
> = {
  createdAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  dateFrom?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  dateTill?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  description?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  followers?: Resolver<
    Array<ResolversTypes['Followers']>,
    ParentType,
    ContextType,
    Partial<EventsFollowersArgs>
  >;
  followersAggregate?: Resolver<
    ResolversTypes['FollowersAggregate'],
    ParentType,
    ContextType,
    Partial<EventsFollowersAggregateArgs>
  >;
  followersCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  image?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  location?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  organiser?: Resolver<
    Maybe<ResolversTypes['Organisers']>,
    ParentType,
    ContextType
  >;
  organiserId?: Resolver<
    Maybe<ResolversTypes['uuid']>,
    ParentType,
    ContextType
  >;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sort?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['StatusesEnum'], ParentType, ContextType>;
  tournaments?: Resolver<
    Array<ResolversTypes['Tournaments']>,
    ParentType,
    ContextType,
    Partial<EventsTournamentsArgs>
  >;
  tournamentsAggregate?: Resolver<
    ResolversTypes['TournamentsAggregate'],
    ParentType,
    ContextType,
    Partial<EventsTournamentsAggregateArgs>
  >;
  updatedAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  venue?: Resolver<ResolversTypes['Venues'], ParentType, ContextType>;
  venueId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EventsAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EventsAggregate'] = ResolversParentTypes['EventsAggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['EventsAggregateFields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<Array<ResolversTypes['Events']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EventsAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EventsAggregateFields'] = ResolversParentTypes['EventsAggregateFields']
> = {
  avg?: Resolver<
    Maybe<ResolversTypes['EventsAvgFields']>,
    ParentType,
    ContextType
  >;
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    Partial<EventsAggregateFieldsCountArgs>
  >;
  max?: Resolver<
    Maybe<ResolversTypes['EventsMaxFields']>,
    ParentType,
    ContextType
  >;
  min?: Resolver<
    Maybe<ResolversTypes['EventsMinFields']>,
    ParentType,
    ContextType
  >;
  stddev?: Resolver<
    Maybe<ResolversTypes['EventsStddevFields']>,
    ParentType,
    ContextType
  >;
  stddevPop?: Resolver<
    Maybe<ResolversTypes['EventsStddev_popFields']>,
    ParentType,
    ContextType
  >;
  stddevSamp?: Resolver<
    Maybe<ResolversTypes['EventsStddev_sampFields']>,
    ParentType,
    ContextType
  >;
  sum?: Resolver<
    Maybe<ResolversTypes['EventsSumFields']>,
    ParentType,
    ContextType
  >;
  varPop?: Resolver<
    Maybe<ResolversTypes['EventsVar_popFields']>,
    ParentType,
    ContextType
  >;
  varSamp?: Resolver<
    Maybe<ResolversTypes['EventsVar_sampFields']>,
    ParentType,
    ContextType
  >;
  variance?: Resolver<
    Maybe<ResolversTypes['EventsVarianceFields']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EventsAvgFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EventsAvgFields'] = ResolversParentTypes['EventsAvgFields']
> = {
  followersCount?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  sort?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EventsMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EventsMaxFields'] = ResolversParentTypes['EventsMaxFields']
> = {
  createdAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  dateFrom?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  dateTill?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  description?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  followersCount?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  image?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  location?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  organiserId?: Resolver<
    Maybe<ResolversTypes['uuid']>,
    ParentType,
    ContextType
  >;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sort?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  updatedAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  venueId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EventsMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EventsMinFields'] = ResolversParentTypes['EventsMinFields']
> = {
  createdAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  dateFrom?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  dateTill?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  description?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  followersCount?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  image?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  location?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  organiserId?: Resolver<
    Maybe<ResolversTypes['uuid']>,
    ParentType,
    ContextType
  >;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sort?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  updatedAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  venueId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EventsMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EventsMutationResponse'] = ResolversParentTypes['EventsMutationResponse']
> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<
    Array<ResolversTypes['Events']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EventsStddevFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EventsStddevFields'] = ResolversParentTypes['EventsStddevFields']
> = {
  followersCount?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  sort?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EventsStddev_PopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EventsStddev_popFields'] = ResolversParentTypes['EventsStddev_popFields']
> = {
  followersCount?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  sort?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EventsStddev_SampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EventsStddev_sampFields'] = ResolversParentTypes['EventsStddev_sampFields']
> = {
  followersCount?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  sort?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EventsSumFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EventsSumFields'] = ResolversParentTypes['EventsSumFields']
> = {
  followersCount?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  sort?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EventsVar_PopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EventsVar_popFields'] = ResolversParentTypes['EventsVar_popFields']
> = {
  followersCount?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  sort?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EventsVar_SampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EventsVar_sampFields'] = ResolversParentTypes['EventsVar_sampFields']
> = {
  followersCount?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  sort?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EventsVarianceFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EventsVarianceFields'] = ResolversParentTypes['EventsVarianceFields']
> = {
  followersCount?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  sort?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FollowersResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Followers'] = ResolversParentTypes['Followers']
> = {
  createdAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  eventId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  organiserId?: Resolver<
    Maybe<ResolversTypes['uuid']>,
    ParentType,
    ContextType
  >;
  playerId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  tournamentId?: Resolver<
    Maybe<ResolversTypes['uuid']>,
    ParentType,
    ContextType
  >;
  updatedAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  venueId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FollowersAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FollowersAggregate'] = ResolversParentTypes['FollowersAggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['FollowersAggregateFields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<Array<ResolversTypes['Followers']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FollowersAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FollowersAggregateFields'] = ResolversParentTypes['FollowersAggregateFields']
> = {
  avg?: Resolver<
    Maybe<ResolversTypes['FollowersAvgFields']>,
    ParentType,
    ContextType
  >;
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    Partial<FollowersAggregateFieldsCountArgs>
  >;
  max?: Resolver<
    Maybe<ResolversTypes['FollowersMaxFields']>,
    ParentType,
    ContextType
  >;
  min?: Resolver<
    Maybe<ResolversTypes['FollowersMinFields']>,
    ParentType,
    ContextType
  >;
  stddev?: Resolver<
    Maybe<ResolversTypes['FollowersStddevFields']>,
    ParentType,
    ContextType
  >;
  stddevPop?: Resolver<
    Maybe<ResolversTypes['FollowersStddev_popFields']>,
    ParentType,
    ContextType
  >;
  stddevSamp?: Resolver<
    Maybe<ResolversTypes['FollowersStddev_sampFields']>,
    ParentType,
    ContextType
  >;
  sum?: Resolver<
    Maybe<ResolversTypes['FollowersSumFields']>,
    ParentType,
    ContextType
  >;
  varPop?: Resolver<
    Maybe<ResolversTypes['FollowersVar_popFields']>,
    ParentType,
    ContextType
  >;
  varSamp?: Resolver<
    Maybe<ResolversTypes['FollowersVar_sampFields']>,
    ParentType,
    ContextType
  >;
  variance?: Resolver<
    Maybe<ResolversTypes['FollowersVarianceFields']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FollowersAvgFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FollowersAvgFields'] = ResolversParentTypes['FollowersAvgFields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FollowersMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FollowersMaxFields'] = ResolversParentTypes['FollowersMaxFields']
> = {
  createdAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  eventId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  organiserId?: Resolver<
    Maybe<ResolversTypes['uuid']>,
    ParentType,
    ContextType
  >;
  playerId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  tournamentId?: Resolver<
    Maybe<ResolversTypes['uuid']>,
    ParentType,
    ContextType
  >;
  updatedAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  venueId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FollowersMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FollowersMinFields'] = ResolversParentTypes['FollowersMinFields']
> = {
  createdAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  eventId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  organiserId?: Resolver<
    Maybe<ResolversTypes['uuid']>,
    ParentType,
    ContextType
  >;
  playerId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  tournamentId?: Resolver<
    Maybe<ResolversTypes['uuid']>,
    ParentType,
    ContextType
  >;
  updatedAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  venueId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FollowersMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FollowersMutationResponse'] = ResolversParentTypes['FollowersMutationResponse']
> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<
    Array<ResolversTypes['Followers']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FollowersStddevFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FollowersStddevFields'] = ResolversParentTypes['FollowersStddevFields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FollowersStddev_PopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FollowersStddev_popFields'] = ResolversParentTypes['FollowersStddev_popFields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FollowersStddev_SampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FollowersStddev_sampFields'] = ResolversParentTypes['FollowersStddev_sampFields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FollowersSumFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FollowersSumFields'] = ResolversParentTypes['FollowersSumFields']
> = {
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FollowersVar_PopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FollowersVar_popFields'] = ResolversParentTypes['FollowersVar_popFields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FollowersVar_SampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FollowersVar_sampFields'] = ResolversParentTypes['FollowersVar_sampFields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FollowersVarianceFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FollowersVarianceFields'] = ResolversParentTypes['FollowersVarianceFields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MessageTypesResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MessageTypes'] = ResolversParentTypes['MessageTypes']
> = {
  contentMail?: Resolver<
    Maybe<ResolversTypes['jsonb']>,
    ParentType,
    ContextType,
    Partial<MessageTypesContentMailArgs>
  >;
  contentNotification?: Resolver<
    Maybe<ResolversTypes['jsonb']>,
    ParentType,
    ContextType,
    Partial<MessageTypesContentNotificationArgs>
  >;
  createdAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  sendMail?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  sendNotification?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >;
  subject?: Resolver<
    ResolversTypes['jsonb'],
    ParentType,
    ContextType,
    Partial<MessageTypesSubjectArgs>
  >;
  updatedAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MessageTypesAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MessageTypesAggregate'] = ResolversParentTypes['MessageTypesAggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['MessageTypesAggregateFields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes['MessageTypes']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MessageTypesAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MessageTypesAggregateFields'] = ResolversParentTypes['MessageTypesAggregateFields']
> = {
  avg?: Resolver<
    Maybe<ResolversTypes['MessageTypesAvgFields']>,
    ParentType,
    ContextType
  >;
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    Partial<MessageTypesAggregateFieldsCountArgs>
  >;
  max?: Resolver<
    Maybe<ResolversTypes['MessageTypesMaxFields']>,
    ParentType,
    ContextType
  >;
  min?: Resolver<
    Maybe<ResolversTypes['MessageTypesMinFields']>,
    ParentType,
    ContextType
  >;
  stddev?: Resolver<
    Maybe<ResolversTypes['MessageTypesStddevFields']>,
    ParentType,
    ContextType
  >;
  stddevPop?: Resolver<
    Maybe<ResolversTypes['MessageTypesStddev_popFields']>,
    ParentType,
    ContextType
  >;
  stddevSamp?: Resolver<
    Maybe<ResolversTypes['MessageTypesStddev_sampFields']>,
    ParentType,
    ContextType
  >;
  sum?: Resolver<
    Maybe<ResolversTypes['MessageTypesSumFields']>,
    ParentType,
    ContextType
  >;
  varPop?: Resolver<
    Maybe<ResolversTypes['MessageTypesVar_popFields']>,
    ParentType,
    ContextType
  >;
  varSamp?: Resolver<
    Maybe<ResolversTypes['MessageTypesVar_sampFields']>,
    ParentType,
    ContextType
  >;
  variance?: Resolver<
    Maybe<ResolversTypes['MessageTypesVarianceFields']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MessageTypesAvgFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MessageTypesAvgFields'] = ResolversParentTypes['MessageTypesAvgFields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MessageTypesMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MessageTypesMaxFields'] = ResolversParentTypes['MessageTypesMaxFields']
> = {
  createdAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MessageTypesMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MessageTypesMinFields'] = ResolversParentTypes['MessageTypesMinFields']
> = {
  createdAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MessageTypesMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MessageTypesMutationResponse'] = ResolversParentTypes['MessageTypesMutationResponse']
> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<
    Array<ResolversTypes['MessageTypes']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MessageTypesStddevFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MessageTypesStddevFields'] = ResolversParentTypes['MessageTypesStddevFields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MessageTypesStddev_PopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MessageTypesStddev_popFields'] = ResolversParentTypes['MessageTypesStddev_popFields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MessageTypesStddev_SampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MessageTypesStddev_sampFields'] = ResolversParentTypes['MessageTypesStddev_sampFields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MessageTypesSumFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MessageTypesSumFields'] = ResolversParentTypes['MessageTypesSumFields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MessageTypesVar_PopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MessageTypesVar_popFields'] = ResolversParentTypes['MessageTypesVar_popFields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MessageTypesVar_SampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MessageTypesVar_sampFields'] = ResolversParentTypes['MessageTypesVar_sampFields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MessageTypesVarianceFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MessageTypesVarianceFields'] = ResolversParentTypes['MessageTypesVarianceFields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MessagesResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Messages'] = ResolversParentTypes['Messages']
> = {
  content?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  important?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  mailSend?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  messageTypeId?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  read?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  subject?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  userId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  variables?: Resolver<
    Maybe<ResolversTypes['jsonb']>,
    ParentType,
    ContextType,
    Partial<MessagesVariablesArgs>
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MessagesAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MessagesAggregate'] = ResolversParentTypes['MessagesAggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['MessagesAggregateFields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<Array<ResolversTypes['Messages']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MessagesAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MessagesAggregateFields'] = ResolversParentTypes['MessagesAggregateFields']
> = {
  avg?: Resolver<
    Maybe<ResolversTypes['MessagesAvgFields']>,
    ParentType,
    ContextType
  >;
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    Partial<MessagesAggregateFieldsCountArgs>
  >;
  max?: Resolver<
    Maybe<ResolversTypes['MessagesMaxFields']>,
    ParentType,
    ContextType
  >;
  min?: Resolver<
    Maybe<ResolversTypes['MessagesMinFields']>,
    ParentType,
    ContextType
  >;
  stddev?: Resolver<
    Maybe<ResolversTypes['MessagesStddevFields']>,
    ParentType,
    ContextType
  >;
  stddevPop?: Resolver<
    Maybe<ResolversTypes['MessagesStddev_popFields']>,
    ParentType,
    ContextType
  >;
  stddevSamp?: Resolver<
    Maybe<ResolversTypes['MessagesStddev_sampFields']>,
    ParentType,
    ContextType
  >;
  sum?: Resolver<
    Maybe<ResolversTypes['MessagesSumFields']>,
    ParentType,
    ContextType
  >;
  varPop?: Resolver<
    Maybe<ResolversTypes['MessagesVar_popFields']>,
    ParentType,
    ContextType
  >;
  varSamp?: Resolver<
    Maybe<ResolversTypes['MessagesVar_sampFields']>,
    ParentType,
    ContextType
  >;
  variance?: Resolver<
    Maybe<ResolversTypes['MessagesVarianceFields']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MessagesAvgFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MessagesAvgFields'] = ResolversParentTypes['MessagesAvgFields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  messageTypeId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MessagesMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MessagesMaxFields'] = ResolversParentTypes['MessagesMaxFields']
> = {
  content?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  mailSend?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  messageTypeId?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  subject?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MessagesMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MessagesMinFields'] = ResolversParentTypes['MessagesMinFields']
> = {
  content?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  mailSend?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  messageTypeId?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  subject?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MessagesMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MessagesMutationResponse'] = ResolversParentTypes['MessagesMutationResponse']
> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<
    Array<ResolversTypes['Messages']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MessagesStddevFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MessagesStddevFields'] = ResolversParentTypes['MessagesStddevFields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  messageTypeId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MessagesStddev_PopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MessagesStddev_popFields'] = ResolversParentTypes['MessagesStddev_popFields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  messageTypeId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MessagesStddev_SampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MessagesStddev_sampFields'] = ResolversParentTypes['MessagesStddev_sampFields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  messageTypeId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MessagesSumFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MessagesSumFields'] = ResolversParentTypes['MessagesSumFields']
> = {
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  messageTypeId?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MessagesVar_PopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MessagesVar_popFields'] = ResolversParentTypes['MessagesVar_popFields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  messageTypeId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MessagesVar_SampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MessagesVar_sampFields'] = ResolversParentTypes['MessagesVar_sampFields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  messageTypeId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MessagesVarianceFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MessagesVarianceFields'] = ResolversParentTypes['MessagesVarianceFields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  messageTypeId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganiserUsersResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrganiserUsers'] = ResolversParentTypes['OrganiserUsers']
> = {
  createdAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  organiserId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganiserUsersAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrganiserUsersAggregate'] = ResolversParentTypes['OrganiserUsersAggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['OrganiserUsersAggregateFields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes['OrganiserUsers']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganiserUsersAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrganiserUsersAggregateFields'] = ResolversParentTypes['OrganiserUsersAggregateFields']
> = {
  avg?: Resolver<
    Maybe<ResolversTypes['OrganiserUsersAvgFields']>,
    ParentType,
    ContextType
  >;
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    Partial<OrganiserUsersAggregateFieldsCountArgs>
  >;
  max?: Resolver<
    Maybe<ResolversTypes['OrganiserUsersMaxFields']>,
    ParentType,
    ContextType
  >;
  min?: Resolver<
    Maybe<ResolversTypes['OrganiserUsersMinFields']>,
    ParentType,
    ContextType
  >;
  stddev?: Resolver<
    Maybe<ResolversTypes['OrganiserUsersStddevFields']>,
    ParentType,
    ContextType
  >;
  stddevPop?: Resolver<
    Maybe<ResolversTypes['OrganiserUsersStddev_popFields']>,
    ParentType,
    ContextType
  >;
  stddevSamp?: Resolver<
    Maybe<ResolversTypes['OrganiserUsersStddev_sampFields']>,
    ParentType,
    ContextType
  >;
  sum?: Resolver<
    Maybe<ResolversTypes['OrganiserUsersSumFields']>,
    ParentType,
    ContextType
  >;
  varPop?: Resolver<
    Maybe<ResolversTypes['OrganiserUsersVar_popFields']>,
    ParentType,
    ContextType
  >;
  varSamp?: Resolver<
    Maybe<ResolversTypes['OrganiserUsersVar_sampFields']>,
    ParentType,
    ContextType
  >;
  variance?: Resolver<
    Maybe<ResolversTypes['OrganiserUsersVarianceFields']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganiserUsersAvgFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrganiserUsersAvgFields'] = ResolversParentTypes['OrganiserUsersAvgFields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganiserUsersMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrganiserUsersMaxFields'] = ResolversParentTypes['OrganiserUsersMaxFields']
> = {
  createdAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  organiserId?: Resolver<
    Maybe<ResolversTypes['uuid']>,
    ParentType,
    ContextType
  >;
  updatedAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganiserUsersMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrganiserUsersMinFields'] = ResolversParentTypes['OrganiserUsersMinFields']
> = {
  createdAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  organiserId?: Resolver<
    Maybe<ResolversTypes['uuid']>,
    ParentType,
    ContextType
  >;
  updatedAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganiserUsersMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrganiserUsersMutationResponse'] = ResolversParentTypes['OrganiserUsersMutationResponse']
> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<
    Array<ResolversTypes['OrganiserUsers']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganiserUsersStddevFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrganiserUsersStddevFields'] = ResolversParentTypes['OrganiserUsersStddevFields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganiserUsersStddev_PopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrganiserUsersStddev_popFields'] = ResolversParentTypes['OrganiserUsersStddev_popFields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganiserUsersStddev_SampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrganiserUsersStddev_sampFields'] = ResolversParentTypes['OrganiserUsersStddev_sampFields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganiserUsersSumFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrganiserUsersSumFields'] = ResolversParentTypes['OrganiserUsersSumFields']
> = {
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganiserUsersVar_PopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrganiserUsersVar_popFields'] = ResolversParentTypes['OrganiserUsersVar_popFields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganiserUsersVar_SampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrganiserUsersVar_sampFields'] = ResolversParentTypes['OrganiserUsersVar_sampFields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganiserUsersVarianceFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrganiserUsersVarianceFields'] = ResolversParentTypes['OrganiserUsersVarianceFields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganisersResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Organisers'] = ResolversParentTypes['Organisers']
> = {
  createdAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  description?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  events?: Resolver<
    Array<ResolversTypes['Events']>,
    ParentType,
    ContextType,
    Partial<OrganisersEventsArgs>
  >;
  eventsAggregate?: Resolver<
    ResolversTypes['EventsAggregate'],
    ParentType,
    ContextType,
    Partial<OrganisersEventsAggregateArgs>
  >;
  facebook?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  followers?: Resolver<
    Array<ResolversTypes['Followers']>,
    ParentType,
    ContextType,
    Partial<OrganisersFollowersArgs>
  >;
  followersAggregate?: Resolver<
    ResolversTypes['FollowersAggregate'],
    ParentType,
    ContextType,
    Partial<OrganisersFollowersAggregateArgs>
  >;
  followersCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  image?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  twitter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  website?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganisersAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrganisersAggregate'] = ResolversParentTypes['OrganisersAggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['OrganisersAggregateFields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes['Organisers']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganisersAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrganisersAggregateFields'] = ResolversParentTypes['OrganisersAggregateFields']
> = {
  avg?: Resolver<
    Maybe<ResolversTypes['OrganisersAvgFields']>,
    ParentType,
    ContextType
  >;
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    Partial<OrganisersAggregateFieldsCountArgs>
  >;
  max?: Resolver<
    Maybe<ResolversTypes['OrganisersMaxFields']>,
    ParentType,
    ContextType
  >;
  min?: Resolver<
    Maybe<ResolversTypes['OrganisersMinFields']>,
    ParentType,
    ContextType
  >;
  stddev?: Resolver<
    Maybe<ResolversTypes['OrganisersStddevFields']>,
    ParentType,
    ContextType
  >;
  stddevPop?: Resolver<
    Maybe<ResolversTypes['OrganisersStddev_popFields']>,
    ParentType,
    ContextType
  >;
  stddevSamp?: Resolver<
    Maybe<ResolversTypes['OrganisersStddev_sampFields']>,
    ParentType,
    ContextType
  >;
  sum?: Resolver<
    Maybe<ResolversTypes['OrganisersSumFields']>,
    ParentType,
    ContextType
  >;
  varPop?: Resolver<
    Maybe<ResolversTypes['OrganisersVar_popFields']>,
    ParentType,
    ContextType
  >;
  varSamp?: Resolver<
    Maybe<ResolversTypes['OrganisersVar_sampFields']>,
    ParentType,
    ContextType
  >;
  variance?: Resolver<
    Maybe<ResolversTypes['OrganisersVarianceFields']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganisersAvgFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrganisersAvgFields'] = ResolversParentTypes['OrganisersAvgFields']
> = {
  followersCount?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganisersMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrganisersMaxFields'] = ResolversParentTypes['OrganisersMaxFields']
> = {
  createdAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  description?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  facebook?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  followersCount?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  image?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  twitter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  website?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganisersMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrganisersMinFields'] = ResolversParentTypes['OrganisersMinFields']
> = {
  createdAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  description?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  facebook?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  followersCount?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  image?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  twitter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  website?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganisersMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrganisersMutationResponse'] = ResolversParentTypes['OrganisersMutationResponse']
> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<
    Array<ResolversTypes['Organisers']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganisersStddevFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrganisersStddevFields'] = ResolversParentTypes['OrganisersStddevFields']
> = {
  followersCount?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganisersStddev_PopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrganisersStddev_popFields'] = ResolversParentTypes['OrganisersStddev_popFields']
> = {
  followersCount?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganisersStddev_SampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrganisersStddev_sampFields'] = ResolversParentTypes['OrganisersStddev_sampFields']
> = {
  followersCount?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganisersSumFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrganisersSumFields'] = ResolversParentTypes['OrganisersSumFields']
> = {
  followersCount?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganisersVar_PopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrganisersVar_popFields'] = ResolversParentTypes['OrganisersVar_popFields']
> = {
  followersCount?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganisersVar_SampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrganisersVar_sampFields'] = ResolversParentTypes['OrganisersVar_sampFields']
> = {
  followersCount?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganisersVarianceFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OrganisersVarianceFields'] = ResolversParentTypes['OrganisersVarianceFields']
> = {
  followersCount?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaymentMethodBanksResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PaymentMethodBanks'] = ResolversParentTypes['PaymentMethodBanks']
> = {
  country?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  countryByCountryId?: Resolver<
    ResolversTypes['Countries'],
    ParentType,
    ContextType
  >;
  countryId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  createdAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  currency?: Resolver<ResolversTypes['Currencies'], ParentType, ContextType>;
  currencyId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  deposit?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  depositCurrency?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  depositDetails?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  depositExchangeRate?: Resolver<
    ResolversTypes['numeric'],
    ParentType,
    ContextType
  >;
  depositFee?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  depositMax?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  depositMin?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  paymentMethodId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  payment_method?: Resolver<
    ResolversTypes['PaymentMethods'],
    ParentType,
    ContextType
  >;
  telegramAdmins?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  telegramGroup?: Resolver<
    Maybe<ResolversTypes['bigint']>,
    ParentType,
    ContextType
  >;
  updatedAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  withdraw?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  withdrawDetails?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  withdrawExchangeRate?: Resolver<
    ResolversTypes['numeric'],
    ParentType,
    ContextType
  >;
  withdrawFee?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  withdrawMax?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  withdrawMin?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaymentMethodBanksAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PaymentMethodBanksAggregate'] = ResolversParentTypes['PaymentMethodBanksAggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['PaymentMethodBanksAggregateFields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes['PaymentMethodBanks']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaymentMethodBanksAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PaymentMethodBanksAggregateFields'] = ResolversParentTypes['PaymentMethodBanksAggregateFields']
> = {
  avg?: Resolver<
    Maybe<ResolversTypes['PaymentMethodBanksAvgFields']>,
    ParentType,
    ContextType
  >;
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    Partial<PaymentMethodBanksAggregateFieldsCountArgs>
  >;
  max?: Resolver<
    Maybe<ResolversTypes['PaymentMethodBanksMaxFields']>,
    ParentType,
    ContextType
  >;
  min?: Resolver<
    Maybe<ResolversTypes['PaymentMethodBanksMinFields']>,
    ParentType,
    ContextType
  >;
  stddev?: Resolver<
    Maybe<ResolversTypes['PaymentMethodBanksStddevFields']>,
    ParentType,
    ContextType
  >;
  stddevPop?: Resolver<
    Maybe<ResolversTypes['PaymentMethodBanksStddev_popFields']>,
    ParentType,
    ContextType
  >;
  stddevSamp?: Resolver<
    Maybe<ResolversTypes['PaymentMethodBanksStddev_sampFields']>,
    ParentType,
    ContextType
  >;
  sum?: Resolver<
    Maybe<ResolversTypes['PaymentMethodBanksSumFields']>,
    ParentType,
    ContextType
  >;
  varPop?: Resolver<
    Maybe<ResolversTypes['PaymentMethodBanksVar_popFields']>,
    ParentType,
    ContextType
  >;
  varSamp?: Resolver<
    Maybe<ResolversTypes['PaymentMethodBanksVar_sampFields']>,
    ParentType,
    ContextType
  >;
  variance?: Resolver<
    Maybe<ResolversTypes['PaymentMethodBanksVarianceFields']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaymentMethodBanksAvgFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PaymentMethodBanksAvgFields'] = ResolversParentTypes['PaymentMethodBanksAvgFields']
> = {
  countryId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  currencyId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  depositExchangeRate?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  depositFee?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  depositMax?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  depositMin?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  paymentMethodId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  telegramGroup?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  withdrawExchangeRate?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  withdrawFee?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  withdrawMax?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  withdrawMin?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaymentMethodBanksMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PaymentMethodBanksMaxFields'] = ResolversParentTypes['PaymentMethodBanksMaxFields']
> = {
  country?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  countryId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  createdAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  currencyId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  depositCurrency?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  depositDetails?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  depositExchangeRate?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  depositFee?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  depositMax?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  depositMin?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  paymentMethodId?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  telegramAdmins?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  telegramGroup?: Resolver<
    Maybe<ResolversTypes['bigint']>,
    ParentType,
    ContextType
  >;
  updatedAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  withdrawDetails?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  withdrawExchangeRate?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  withdrawFee?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  withdrawMax?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  withdrawMin?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaymentMethodBanksMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PaymentMethodBanksMinFields'] = ResolversParentTypes['PaymentMethodBanksMinFields']
> = {
  country?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  countryId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  createdAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  currencyId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  depositCurrency?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  depositDetails?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  depositExchangeRate?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  depositFee?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  depositMax?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  depositMin?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  paymentMethodId?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  telegramAdmins?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  telegramGroup?: Resolver<
    Maybe<ResolversTypes['bigint']>,
    ParentType,
    ContextType
  >;
  updatedAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  withdrawDetails?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  withdrawExchangeRate?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  withdrawFee?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  withdrawMax?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  withdrawMin?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaymentMethodBanksMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PaymentMethodBanksMutationResponse'] = ResolversParentTypes['PaymentMethodBanksMutationResponse']
> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<
    Array<ResolversTypes['PaymentMethodBanks']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaymentMethodBanksStddevFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PaymentMethodBanksStddevFields'] = ResolversParentTypes['PaymentMethodBanksStddevFields']
> = {
  countryId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  currencyId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  depositExchangeRate?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  depositFee?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  depositMax?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  depositMin?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  paymentMethodId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  telegramGroup?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  withdrawExchangeRate?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  withdrawFee?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  withdrawMax?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  withdrawMin?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaymentMethodBanksStddev_PopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PaymentMethodBanksStddev_popFields'] = ResolversParentTypes['PaymentMethodBanksStddev_popFields']
> = {
  countryId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  currencyId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  depositExchangeRate?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  depositFee?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  depositMax?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  depositMin?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  paymentMethodId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  telegramGroup?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  withdrawExchangeRate?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  withdrawFee?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  withdrawMax?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  withdrawMin?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaymentMethodBanksStddev_SampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PaymentMethodBanksStddev_sampFields'] = ResolversParentTypes['PaymentMethodBanksStddev_sampFields']
> = {
  countryId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  currencyId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  depositExchangeRate?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  depositFee?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  depositMax?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  depositMin?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  paymentMethodId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  telegramGroup?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  withdrawExchangeRate?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  withdrawFee?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  withdrawMax?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  withdrawMin?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaymentMethodBanksSumFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PaymentMethodBanksSumFields'] = ResolversParentTypes['PaymentMethodBanksSumFields']
> = {
  countryId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  currencyId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  depositExchangeRate?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  depositFee?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  depositMax?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  depositMin?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  paymentMethodId?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  telegramGroup?: Resolver<
    Maybe<ResolversTypes['bigint']>,
    ParentType,
    ContextType
  >;
  withdrawExchangeRate?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  withdrawFee?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  withdrawMax?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  withdrawMin?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaymentMethodBanksVar_PopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PaymentMethodBanksVar_popFields'] = ResolversParentTypes['PaymentMethodBanksVar_popFields']
> = {
  countryId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  currencyId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  depositExchangeRate?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  depositFee?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  depositMax?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  depositMin?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  paymentMethodId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  telegramGroup?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  withdrawExchangeRate?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  withdrawFee?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  withdrawMax?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  withdrawMin?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaymentMethodBanksVar_SampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PaymentMethodBanksVar_sampFields'] = ResolversParentTypes['PaymentMethodBanksVar_sampFields']
> = {
  countryId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  currencyId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  depositExchangeRate?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  depositFee?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  depositMax?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  depositMin?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  paymentMethodId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  telegramGroup?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  withdrawExchangeRate?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  withdrawFee?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  withdrawMax?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  withdrawMin?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaymentMethodBanksVarianceFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PaymentMethodBanksVarianceFields'] = ResolversParentTypes['PaymentMethodBanksVarianceFields']
> = {
  countryId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  currencyId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  depositExchangeRate?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  depositFee?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  depositMax?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  depositMin?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  paymentMethodId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  telegramGroup?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  withdrawExchangeRate?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  withdrawFee?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  withdrawMax?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  withdrawMin?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaymentMethodsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PaymentMethods'] = ResolversParentTypes['PaymentMethods']
> = {
  createdAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  deposit?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  paymentMethodBanksAggregate?: Resolver<
    ResolversTypes['PaymentMethodBanksAggregate'],
    ParentType,
    ContextType,
    Partial<PaymentMethodsPaymentMethodBanksAggregateArgs>
  >;
  payment_method_banks?: Resolver<
    Array<ResolversTypes['PaymentMethodBanks']>,
    ParentType,
    ContextType,
    Partial<PaymentMethodsPayment_Method_BanksArgs>
  >;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  withdraw?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaymentMethodsAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PaymentMethodsAggregate'] = ResolversParentTypes['PaymentMethodsAggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['PaymentMethodsAggregateFields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes['PaymentMethods']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaymentMethodsAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PaymentMethodsAggregateFields'] = ResolversParentTypes['PaymentMethodsAggregateFields']
> = {
  avg?: Resolver<
    Maybe<ResolversTypes['PaymentMethodsAvgFields']>,
    ParentType,
    ContextType
  >;
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    Partial<PaymentMethodsAggregateFieldsCountArgs>
  >;
  max?: Resolver<
    Maybe<ResolversTypes['PaymentMethodsMaxFields']>,
    ParentType,
    ContextType
  >;
  min?: Resolver<
    Maybe<ResolversTypes['PaymentMethodsMinFields']>,
    ParentType,
    ContextType
  >;
  stddev?: Resolver<
    Maybe<ResolversTypes['PaymentMethodsStddevFields']>,
    ParentType,
    ContextType
  >;
  stddevPop?: Resolver<
    Maybe<ResolversTypes['PaymentMethodsStddev_popFields']>,
    ParentType,
    ContextType
  >;
  stddevSamp?: Resolver<
    Maybe<ResolversTypes['PaymentMethodsStddev_sampFields']>,
    ParentType,
    ContextType
  >;
  sum?: Resolver<
    Maybe<ResolversTypes['PaymentMethodsSumFields']>,
    ParentType,
    ContextType
  >;
  varPop?: Resolver<
    Maybe<ResolversTypes['PaymentMethodsVar_popFields']>,
    ParentType,
    ContextType
  >;
  varSamp?: Resolver<
    Maybe<ResolversTypes['PaymentMethodsVar_sampFields']>,
    ParentType,
    ContextType
  >;
  variance?: Resolver<
    Maybe<ResolversTypes['PaymentMethodsVarianceFields']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaymentMethodsAvgFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PaymentMethodsAvgFields'] = ResolversParentTypes['PaymentMethodsAvgFields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaymentMethodsMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PaymentMethodsMaxFields'] = ResolversParentTypes['PaymentMethodsMaxFields']
> = {
  createdAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaymentMethodsMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PaymentMethodsMinFields'] = ResolversParentTypes['PaymentMethodsMinFields']
> = {
  createdAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaymentMethodsMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PaymentMethodsMutationResponse'] = ResolversParentTypes['PaymentMethodsMutationResponse']
> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<
    Array<ResolversTypes['PaymentMethods']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaymentMethodsStddevFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PaymentMethodsStddevFields'] = ResolversParentTypes['PaymentMethodsStddevFields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaymentMethodsStddev_PopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PaymentMethodsStddev_popFields'] = ResolversParentTypes['PaymentMethodsStddev_popFields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaymentMethodsStddev_SampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PaymentMethodsStddev_sampFields'] = ResolversParentTypes['PaymentMethodsStddev_sampFields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaymentMethodsSumFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PaymentMethodsSumFields'] = ResolversParentTypes['PaymentMethodsSumFields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaymentMethodsVar_PopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PaymentMethodsVar_popFields'] = ResolversParentTypes['PaymentMethodsVar_popFields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaymentMethodsVar_SampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PaymentMethodsVar_sampFields'] = ResolversParentTypes['PaymentMethodsVar_sampFields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaymentMethodsVarianceFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PaymentMethodsVarianceFields'] = ResolversParentTypes['PaymentMethodsVarianceFields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaymentsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Payments'] = ResolversParentTypes['Payments']
> = {
  amount?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  amountOut?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  blockchainCurrencyId?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  blockchain_currency?: Resolver<
    Maybe<ResolversTypes['BlockchainCurrencies']>,
    ParentType,
    ContextType
  >;
  createdAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  currency?: Resolver<ResolversTypes['Currencies'], ParentType, ContextType>;
  currencyId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  currencySymbol?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  depositAddress?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  fee?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  paymentMethodBankId?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  paymentMethodId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  payment_method?: Resolver<
    ResolversTypes['PaymentMethods'],
    ParentType,
    ContextType
  >;
  payment_method_bank?: Resolver<
    Maybe<ResolversTypes['PaymentMethodBanks']>,
    ParentType,
    ContextType
  >;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  statusInternal?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  tgMsgId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  twofa?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  txid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  txlog?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  userId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  withdrawAddress?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaymentsAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PaymentsAggregate'] = ResolversParentTypes['PaymentsAggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['PaymentsAggregateFields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<Array<ResolversTypes['Payments']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaymentsAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PaymentsAggregateFields'] = ResolversParentTypes['PaymentsAggregateFields']
> = {
  avg?: Resolver<
    Maybe<ResolversTypes['PaymentsAvgFields']>,
    ParentType,
    ContextType
  >;
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    Partial<PaymentsAggregateFieldsCountArgs>
  >;
  max?: Resolver<
    Maybe<ResolversTypes['PaymentsMaxFields']>,
    ParentType,
    ContextType
  >;
  min?: Resolver<
    Maybe<ResolversTypes['PaymentsMinFields']>,
    ParentType,
    ContextType
  >;
  stddev?: Resolver<
    Maybe<ResolversTypes['PaymentsStddevFields']>,
    ParentType,
    ContextType
  >;
  stddevPop?: Resolver<
    Maybe<ResolversTypes['PaymentsStddev_popFields']>,
    ParentType,
    ContextType
  >;
  stddevSamp?: Resolver<
    Maybe<ResolversTypes['PaymentsStddev_sampFields']>,
    ParentType,
    ContextType
  >;
  sum?: Resolver<
    Maybe<ResolversTypes['PaymentsSumFields']>,
    ParentType,
    ContextType
  >;
  varPop?: Resolver<
    Maybe<ResolversTypes['PaymentsVar_popFields']>,
    ParentType,
    ContextType
  >;
  varSamp?: Resolver<
    Maybe<ResolversTypes['PaymentsVar_sampFields']>,
    ParentType,
    ContextType
  >;
  variance?: Resolver<
    Maybe<ResolversTypes['PaymentsVarianceFields']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaymentsAvgFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PaymentsAvgFields'] = ResolversParentTypes['PaymentsAvgFields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  amountOut?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  blockchainCurrencyId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  currencyId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  fee?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  paymentMethodBankId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  paymentMethodId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  tgMsgId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaymentsMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PaymentsMaxFields'] = ResolversParentTypes['PaymentsMaxFields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  amountOut?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  blockchainCurrencyId?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  createdAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  currencyId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  currencySymbol?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  depositAddress?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  fee?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  paymentMethodBankId?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  paymentMethodId?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  statusInternal?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  tgMsgId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  twofa?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  txid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  txlog?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  withdrawAddress?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaymentsMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PaymentsMinFields'] = ResolversParentTypes['PaymentsMinFields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  amountOut?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  blockchainCurrencyId?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  createdAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  currencyId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  currencySymbol?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  depositAddress?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  fee?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  paymentMethodBankId?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  paymentMethodId?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  statusInternal?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  tgMsgId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  twofa?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  txid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  txlog?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  withdrawAddress?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaymentsMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PaymentsMutationResponse'] = ResolversParentTypes['PaymentsMutationResponse']
> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<
    Array<ResolversTypes['Payments']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaymentsStddevFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PaymentsStddevFields'] = ResolversParentTypes['PaymentsStddevFields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  amountOut?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  blockchainCurrencyId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  currencyId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  fee?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  paymentMethodBankId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  paymentMethodId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  tgMsgId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaymentsStddev_PopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PaymentsStddev_popFields'] = ResolversParentTypes['PaymentsStddev_popFields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  amountOut?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  blockchainCurrencyId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  currencyId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  fee?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  paymentMethodBankId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  paymentMethodId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  tgMsgId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaymentsStddev_SampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PaymentsStddev_sampFields'] = ResolversParentTypes['PaymentsStddev_sampFields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  amountOut?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  blockchainCurrencyId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  currencyId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  fee?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  paymentMethodBankId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  paymentMethodId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  tgMsgId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaymentsSumFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PaymentsSumFields'] = ResolversParentTypes['PaymentsSumFields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  amountOut?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  blockchainCurrencyId?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  currencyId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  fee?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  paymentMethodBankId?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  paymentMethodId?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  tgMsgId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaymentsVar_PopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PaymentsVar_popFields'] = ResolversParentTypes['PaymentsVar_popFields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  amountOut?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  blockchainCurrencyId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  currencyId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  fee?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  paymentMethodBankId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  paymentMethodId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  tgMsgId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaymentsVar_SampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PaymentsVar_sampFields'] = ResolversParentTypes['PaymentsVar_sampFields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  amountOut?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  blockchainCurrencyId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  currencyId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  fee?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  paymentMethodBankId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  paymentMethodId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  tgMsgId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaymentsVarianceFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PaymentsVarianceFields'] = ResolversParentTypes['PaymentsVarianceFields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  amountOut?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  blockchainCurrencyId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  currencyId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  fee?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  paymentMethodBankId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  paymentMethodId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  tgMsgId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PendingTxsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PendingTxs'] = ResolversParentTypes['PendingTxs']
> = {
  amount?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  blockNumber?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  blockchainCurrencyId?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  blockchainId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  contractAddress?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  from?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  function?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  hash?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  timeStamp?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  to?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  tokenSymbol?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  wallet?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PendingTxsAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PendingTxsAggregate'] = ResolversParentTypes['PendingTxsAggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['PendingTxsAggregateFields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes['PendingTxs']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PendingTxsAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PendingTxsAggregateFields'] = ResolversParentTypes['PendingTxsAggregateFields']
> = {
  avg?: Resolver<
    Maybe<ResolversTypes['PendingTxsAvgFields']>,
    ParentType,
    ContextType
  >;
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    Partial<PendingTxsAggregateFieldsCountArgs>
  >;
  max?: Resolver<
    Maybe<ResolversTypes['PendingTxsMaxFields']>,
    ParentType,
    ContextType
  >;
  min?: Resolver<
    Maybe<ResolversTypes['PendingTxsMinFields']>,
    ParentType,
    ContextType
  >;
  stddev?: Resolver<
    Maybe<ResolversTypes['PendingTxsStddevFields']>,
    ParentType,
    ContextType
  >;
  stddevPop?: Resolver<
    Maybe<ResolversTypes['PendingTxsStddev_popFields']>,
    ParentType,
    ContextType
  >;
  stddevSamp?: Resolver<
    Maybe<ResolversTypes['PendingTxsStddev_sampFields']>,
    ParentType,
    ContextType
  >;
  sum?: Resolver<
    Maybe<ResolversTypes['PendingTxsSumFields']>,
    ParentType,
    ContextType
  >;
  varPop?: Resolver<
    Maybe<ResolversTypes['PendingTxsVar_popFields']>,
    ParentType,
    ContextType
  >;
  varSamp?: Resolver<
    Maybe<ResolversTypes['PendingTxsVar_sampFields']>,
    ParentType,
    ContextType
  >;
  variance?: Resolver<
    Maybe<ResolversTypes['PendingTxsVarianceFields']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PendingTxsAvgFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PendingTxsAvgFields'] = ResolversParentTypes['PendingTxsAvgFields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  blockNumber?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  blockchainCurrencyId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  blockchainId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  timeStamp?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PendingTxsMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PendingTxsMaxFields'] = ResolversParentTypes['PendingTxsMaxFields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  blockNumber?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  blockchainCurrencyId?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  blockchainId?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  contractAddress?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  createdAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  from?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  function?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hash?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  timeStamp?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  to?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tokenSymbol?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  updatedAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  wallet?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PendingTxsMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PendingTxsMinFields'] = ResolversParentTypes['PendingTxsMinFields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  blockNumber?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  blockchainCurrencyId?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  blockchainId?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  contractAddress?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  createdAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  from?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  function?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hash?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  timeStamp?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  to?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tokenSymbol?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  updatedAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  wallet?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PendingTxsMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PendingTxsMutationResponse'] = ResolversParentTypes['PendingTxsMutationResponse']
> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<
    Array<ResolversTypes['PendingTxs']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PendingTxsStddevFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PendingTxsStddevFields'] = ResolversParentTypes['PendingTxsStddevFields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  blockNumber?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  blockchainCurrencyId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  blockchainId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  timeStamp?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PendingTxsStddev_PopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PendingTxsStddev_popFields'] = ResolversParentTypes['PendingTxsStddev_popFields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  blockNumber?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  blockchainCurrencyId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  blockchainId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  timeStamp?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PendingTxsStddev_SampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PendingTxsStddev_sampFields'] = ResolversParentTypes['PendingTxsStddev_sampFields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  blockNumber?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  blockchainCurrencyId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  blockchainId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  timeStamp?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PendingTxsSumFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PendingTxsSumFields'] = ResolversParentTypes['PendingTxsSumFields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  blockNumber?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  blockchainCurrencyId?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  blockchainId?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  timeStamp?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PendingTxsVar_PopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PendingTxsVar_popFields'] = ResolversParentTypes['PendingTxsVar_popFields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  blockNumber?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  blockchainCurrencyId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  blockchainId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  timeStamp?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PendingTxsVar_SampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PendingTxsVar_sampFields'] = ResolversParentTypes['PendingTxsVar_sampFields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  blockNumber?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  blockchainCurrencyId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  blockchainId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  timeStamp?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PendingTxsVarianceFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PendingTxsVarianceFields'] = ResolversParentTypes['PendingTxsVarianceFields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  blockNumber?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  blockchainCurrencyId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  blockchainId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  timeStamp?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlayerFollowersResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PlayerFollowers'] = ResolversParentTypes['PlayerFollowers']
> = {
  createdAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  playerId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updatedAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlayerFollowersAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PlayerFollowersAggregate'] = ResolversParentTypes['PlayerFollowersAggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['PlayerFollowersAggregateFields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes['PlayerFollowers']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlayerFollowersAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PlayerFollowersAggregateFields'] = ResolversParentTypes['PlayerFollowersAggregateFields']
> = {
  avg?: Resolver<
    Maybe<ResolversTypes['PlayerFollowersAvgFields']>,
    ParentType,
    ContextType
  >;
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    Partial<PlayerFollowersAggregateFieldsCountArgs>
  >;
  max?: Resolver<
    Maybe<ResolversTypes['PlayerFollowersMaxFields']>,
    ParentType,
    ContextType
  >;
  min?: Resolver<
    Maybe<ResolversTypes['PlayerFollowersMinFields']>,
    ParentType,
    ContextType
  >;
  stddev?: Resolver<
    Maybe<ResolversTypes['PlayerFollowersStddevFields']>,
    ParentType,
    ContextType
  >;
  stddevPop?: Resolver<
    Maybe<ResolversTypes['PlayerFollowersStddev_popFields']>,
    ParentType,
    ContextType
  >;
  stddevSamp?: Resolver<
    Maybe<ResolversTypes['PlayerFollowersStddev_sampFields']>,
    ParentType,
    ContextType
  >;
  sum?: Resolver<
    Maybe<ResolversTypes['PlayerFollowersSumFields']>,
    ParentType,
    ContextType
  >;
  varPop?: Resolver<
    Maybe<ResolversTypes['PlayerFollowersVar_popFields']>,
    ParentType,
    ContextType
  >;
  varSamp?: Resolver<
    Maybe<ResolversTypes['PlayerFollowersVar_sampFields']>,
    ParentType,
    ContextType
  >;
  variance?: Resolver<
    Maybe<ResolversTypes['PlayerFollowersVarianceFields']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlayerFollowersAvgFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PlayerFollowersAvgFields'] = ResolversParentTypes['PlayerFollowersAvgFields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlayerFollowersMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PlayerFollowersMaxFields'] = ResolversParentTypes['PlayerFollowersMaxFields']
> = {
  createdAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  playerId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updatedAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlayerFollowersMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PlayerFollowersMinFields'] = ResolversParentTypes['PlayerFollowersMinFields']
> = {
  createdAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  playerId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  updatedAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlayerFollowersMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PlayerFollowersMutationResponse'] = ResolversParentTypes['PlayerFollowersMutationResponse']
> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<
    Array<ResolversTypes['PlayerFollowers']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlayerFollowersStddevFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PlayerFollowersStddevFields'] = ResolversParentTypes['PlayerFollowersStddevFields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlayerFollowersStddev_PopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PlayerFollowersStddev_popFields'] = ResolversParentTypes['PlayerFollowersStddev_popFields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlayerFollowersStddev_SampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PlayerFollowersStddev_sampFields'] = ResolversParentTypes['PlayerFollowersStddev_sampFields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlayerFollowersSumFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PlayerFollowersSumFields'] = ResolversParentTypes['PlayerFollowersSumFields']
> = {
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlayerFollowersVar_PopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PlayerFollowersVar_popFields'] = ResolversParentTypes['PlayerFollowersVar_popFields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlayerFollowersVar_SampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PlayerFollowersVar_sampFields'] = ResolversParentTypes['PlayerFollowersVar_sampFields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlayerFollowersVarianceFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PlayerFollowersVarianceFields'] = ResolversParentTypes['PlayerFollowersVarianceFields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlayerFollowersViewResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PlayerFollowersView'] = ResolversParentTypes['PlayerFollowersView']
> = {
  avatar?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  erc20Address?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  player?: Resolver<Maybe<ResolversTypes['Players']>, ParentType, ContextType>;
  playerId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  username?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlayerFollowersViewAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PlayerFollowersViewAggregate'] = ResolversParentTypes['PlayerFollowersViewAggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['PlayerFollowersViewAggregateFields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes['PlayerFollowersView']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlayerFollowersViewAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PlayerFollowersViewAggregateFields'] = ResolversParentTypes['PlayerFollowersViewAggregateFields']
> = {
  avg?: Resolver<
    Maybe<ResolversTypes['PlayerFollowersViewAvgFields']>,
    ParentType,
    ContextType
  >;
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    Partial<PlayerFollowersViewAggregateFieldsCountArgs>
  >;
  max?: Resolver<
    Maybe<ResolversTypes['PlayerFollowersViewMaxFields']>,
    ParentType,
    ContextType
  >;
  min?: Resolver<
    Maybe<ResolversTypes['PlayerFollowersViewMinFields']>,
    ParentType,
    ContextType
  >;
  stddev?: Resolver<
    Maybe<ResolversTypes['PlayerFollowersViewStddevFields']>,
    ParentType,
    ContextType
  >;
  stddevPop?: Resolver<
    Maybe<ResolversTypes['PlayerFollowersViewStddev_popFields']>,
    ParentType,
    ContextType
  >;
  stddevSamp?: Resolver<
    Maybe<ResolversTypes['PlayerFollowersViewStddev_sampFields']>,
    ParentType,
    ContextType
  >;
  sum?: Resolver<
    Maybe<ResolversTypes['PlayerFollowersViewSumFields']>,
    ParentType,
    ContextType
  >;
  varPop?: Resolver<
    Maybe<ResolversTypes['PlayerFollowersViewVar_popFields']>,
    ParentType,
    ContextType
  >;
  varSamp?: Resolver<
    Maybe<ResolversTypes['PlayerFollowersViewVar_sampFields']>,
    ParentType,
    ContextType
  >;
  variance?: Resolver<
    Maybe<ResolversTypes['PlayerFollowersViewVarianceFields']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlayerFollowersViewAvgFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PlayerFollowersViewAvgFields'] = ResolversParentTypes['PlayerFollowersViewAvgFields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlayerFollowersViewMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PlayerFollowersViewMaxFields'] = ResolversParentTypes['PlayerFollowersViewMaxFields']
> = {
  avatar?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  erc20Address?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  playerId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  username?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlayerFollowersViewMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PlayerFollowersViewMinFields'] = ResolversParentTypes['PlayerFollowersViewMinFields']
> = {
  avatar?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  erc20Address?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  playerId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  username?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlayerFollowersViewStddevFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PlayerFollowersViewStddevFields'] = ResolversParentTypes['PlayerFollowersViewStddevFields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlayerFollowersViewStddev_PopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PlayerFollowersViewStddev_popFields'] = ResolversParentTypes['PlayerFollowersViewStddev_popFields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlayerFollowersViewStddev_SampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PlayerFollowersViewStddev_sampFields'] = ResolversParentTypes['PlayerFollowersViewStddev_sampFields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlayerFollowersViewSumFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PlayerFollowersViewSumFields'] = ResolversParentTypes['PlayerFollowersViewSumFields']
> = {
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlayerFollowersViewVar_PopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PlayerFollowersViewVar_popFields'] = ResolversParentTypes['PlayerFollowersViewVar_popFields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlayerFollowersViewVar_SampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PlayerFollowersViewVar_sampFields'] = ResolversParentTypes['PlayerFollowersViewVar_sampFields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlayerFollowersViewVarianceFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PlayerFollowersViewVarianceFields'] = ResolversParentTypes['PlayerFollowersViewVarianceFields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlayerSharesResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PlayerShares'] = ResolversParentTypes['PlayerShares']
> = {
  activeDate?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  allowCancel?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  bullets?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  markUp?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  maxPerUserShare?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  note?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  password?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  passwordRequired?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >;
  player?: Resolver<ResolversTypes['Players'], ParentType, ContextType>;
  playerId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  player_shares_bought?: Resolver<
    Maybe<ResolversTypes['PlayerSharesBought']>,
    ParentType,
    ContextType
  >;
  shareDilution?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  sharesToSell?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sort?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['StatusGamesEnum'], ParentType, ContextType>;
  termsAcceptedDate?: Resolver<
    ResolversTypes['timestamptz'],
    ParentType,
    ContextType
  >;
  tickets?: Resolver<
    Array<ResolversTypes['Tickets']>,
    ParentType,
    ContextType,
    Partial<PlayerSharesTicketsArgs>
  >;
  ticketsAggregate?: Resolver<
    ResolversTypes['TicketsAggregate'],
    ParentType,
    ContextType,
    Partial<PlayerSharesTicketsAggregateArgs>
  >;
  tournament?: Resolver<ResolversTypes['Tournaments'], ParentType, ContextType>;
  tournamentId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  tournamentResultId?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  tournament_result?: Resolver<
    Maybe<ResolversTypes['TournamentResults']>,
    ParentType,
    ContextType
  >;
  transactions?: Resolver<
    Array<ResolversTypes['Transactions']>,
    ParentType,
    ContextType,
    Partial<PlayerSharesTransactionsArgs>
  >;
  transactionsAggregate?: Resolver<
    ResolversTypes['TransactionsAggregate'],
    ParentType,
    ContextType,
    Partial<PlayerSharesTransactionsAggregateArgs>
  >;
  updatedAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  userShareBuysAggregate?: Resolver<
    ResolversTypes['UserShareBuysAggregate'],
    ParentType,
    ContextType,
    Partial<PlayerSharesUserShareBuysAggregateArgs>
  >;
  user_share_buys?: Resolver<
    Array<ResolversTypes['UserShareBuys']>,
    ParentType,
    ContextType,
    Partial<PlayerSharesUser_Share_BuysArgs>
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlayerSharesAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PlayerSharesAggregate'] = ResolversParentTypes['PlayerSharesAggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['PlayerSharesAggregateFields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes['PlayerShares']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlayerSharesAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PlayerSharesAggregateFields'] = ResolversParentTypes['PlayerSharesAggregateFields']
> = {
  avg?: Resolver<
    Maybe<ResolversTypes['PlayerSharesAvgFields']>,
    ParentType,
    ContextType
  >;
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    Partial<PlayerSharesAggregateFieldsCountArgs>
  >;
  max?: Resolver<
    Maybe<ResolversTypes['PlayerSharesMaxFields']>,
    ParentType,
    ContextType
  >;
  min?: Resolver<
    Maybe<ResolversTypes['PlayerSharesMinFields']>,
    ParentType,
    ContextType
  >;
  stddev?: Resolver<
    Maybe<ResolversTypes['PlayerSharesStddevFields']>,
    ParentType,
    ContextType
  >;
  stddevPop?: Resolver<
    Maybe<ResolversTypes['PlayerSharesStddev_popFields']>,
    ParentType,
    ContextType
  >;
  stddevSamp?: Resolver<
    Maybe<ResolversTypes['PlayerSharesStddev_sampFields']>,
    ParentType,
    ContextType
  >;
  sum?: Resolver<
    Maybe<ResolversTypes['PlayerSharesSumFields']>,
    ParentType,
    ContextType
  >;
  varPop?: Resolver<
    Maybe<ResolversTypes['PlayerSharesVar_popFields']>,
    ParentType,
    ContextType
  >;
  varSamp?: Resolver<
    Maybe<ResolversTypes['PlayerSharesVar_sampFields']>,
    ParentType,
    ContextType
  >;
  variance?: Resolver<
    Maybe<ResolversTypes['PlayerSharesVarianceFields']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlayerSharesAvgFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PlayerSharesAvgFields'] = ResolversParentTypes['PlayerSharesAvgFields']
> = {
  bullets?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  markUp?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  maxPerUserShare?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  sharesToSell?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  sort?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  tournamentResultId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlayerSharesBoughtResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PlayerSharesBought'] = ResolversParentTypes['PlayerSharesBought']
> = {
  playerShareId?: Resolver<
    Maybe<ResolversTypes['uuid']>,
    ParentType,
    ContextType
  >;
  totalMarkupAmount?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  totalShareAmount?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  totalSharePercentage?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  totalTotalAmount?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlayerSharesBoughtAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PlayerSharesBoughtAggregate'] = ResolversParentTypes['PlayerSharesBoughtAggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['PlayerSharesBoughtAggregateFields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes['PlayerSharesBought']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlayerSharesBoughtAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PlayerSharesBoughtAggregateFields'] = ResolversParentTypes['PlayerSharesBoughtAggregateFields']
> = {
  avg?: Resolver<
    Maybe<ResolversTypes['PlayerSharesBoughtAvgFields']>,
    ParentType,
    ContextType
  >;
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    Partial<PlayerSharesBoughtAggregateFieldsCountArgs>
  >;
  max?: Resolver<
    Maybe<ResolversTypes['PlayerSharesBoughtMaxFields']>,
    ParentType,
    ContextType
  >;
  min?: Resolver<
    Maybe<ResolversTypes['PlayerSharesBoughtMinFields']>,
    ParentType,
    ContextType
  >;
  stddev?: Resolver<
    Maybe<ResolversTypes['PlayerSharesBoughtStddevFields']>,
    ParentType,
    ContextType
  >;
  stddevPop?: Resolver<
    Maybe<ResolversTypes['PlayerSharesBoughtStddev_popFields']>,
    ParentType,
    ContextType
  >;
  stddevSamp?: Resolver<
    Maybe<ResolversTypes['PlayerSharesBoughtStddev_sampFields']>,
    ParentType,
    ContextType
  >;
  sum?: Resolver<
    Maybe<ResolversTypes['PlayerSharesBoughtSumFields']>,
    ParentType,
    ContextType
  >;
  varPop?: Resolver<
    Maybe<ResolversTypes['PlayerSharesBoughtVar_popFields']>,
    ParentType,
    ContextType
  >;
  varSamp?: Resolver<
    Maybe<ResolversTypes['PlayerSharesBoughtVar_sampFields']>,
    ParentType,
    ContextType
  >;
  variance?: Resolver<
    Maybe<ResolversTypes['PlayerSharesBoughtVarianceFields']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlayerSharesBoughtAvgFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PlayerSharesBoughtAvgFields'] = ResolversParentTypes['PlayerSharesBoughtAvgFields']
> = {
  totalMarkupAmount?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  totalShareAmount?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  totalSharePercentage?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  totalTotalAmount?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlayerSharesBoughtMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PlayerSharesBoughtMaxFields'] = ResolversParentTypes['PlayerSharesBoughtMaxFields']
> = {
  playerShareId?: Resolver<
    Maybe<ResolversTypes['uuid']>,
    ParentType,
    ContextType
  >;
  totalMarkupAmount?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  totalShareAmount?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  totalSharePercentage?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  totalTotalAmount?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlayerSharesBoughtMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PlayerSharesBoughtMinFields'] = ResolversParentTypes['PlayerSharesBoughtMinFields']
> = {
  playerShareId?: Resolver<
    Maybe<ResolversTypes['uuid']>,
    ParentType,
    ContextType
  >;
  totalMarkupAmount?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  totalShareAmount?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  totalSharePercentage?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  totalTotalAmount?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlayerSharesBoughtStddevFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PlayerSharesBoughtStddevFields'] = ResolversParentTypes['PlayerSharesBoughtStddevFields']
> = {
  totalMarkupAmount?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  totalShareAmount?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  totalSharePercentage?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  totalTotalAmount?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlayerSharesBoughtStddev_PopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PlayerSharesBoughtStddev_popFields'] = ResolversParentTypes['PlayerSharesBoughtStddev_popFields']
> = {
  totalMarkupAmount?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  totalShareAmount?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  totalSharePercentage?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  totalTotalAmount?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlayerSharesBoughtStddev_SampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PlayerSharesBoughtStddev_sampFields'] = ResolversParentTypes['PlayerSharesBoughtStddev_sampFields']
> = {
  totalMarkupAmount?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  totalShareAmount?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  totalSharePercentage?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  totalTotalAmount?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlayerSharesBoughtSumFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PlayerSharesBoughtSumFields'] = ResolversParentTypes['PlayerSharesBoughtSumFields']
> = {
  totalMarkupAmount?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  totalShareAmount?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  totalSharePercentage?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  totalTotalAmount?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlayerSharesBoughtVar_PopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PlayerSharesBoughtVar_popFields'] = ResolversParentTypes['PlayerSharesBoughtVar_popFields']
> = {
  totalMarkupAmount?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  totalShareAmount?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  totalSharePercentage?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  totalTotalAmount?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlayerSharesBoughtVar_SampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PlayerSharesBoughtVar_sampFields'] = ResolversParentTypes['PlayerSharesBoughtVar_sampFields']
> = {
  totalMarkupAmount?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  totalShareAmount?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  totalSharePercentage?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  totalTotalAmount?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlayerSharesBoughtVarianceFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PlayerSharesBoughtVarianceFields'] = ResolversParentTypes['PlayerSharesBoughtVarianceFields']
> = {
  totalMarkupAmount?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  totalShareAmount?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  totalSharePercentage?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  totalTotalAmount?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlayerSharesMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PlayerSharesMaxFields'] = ResolversParentTypes['PlayerSharesMaxFields']
> = {
  activeDate?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  bullets?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  createdAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  markUp?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  maxPerUserShare?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  note?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  password?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  playerId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  sharesToSell?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sort?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  termsAcceptedDate?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  tournamentId?: Resolver<
    Maybe<ResolversTypes['uuid']>,
    ParentType,
    ContextType
  >;
  tournamentResultId?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  updatedAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlayerSharesMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PlayerSharesMinFields'] = ResolversParentTypes['PlayerSharesMinFields']
> = {
  activeDate?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  bullets?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  createdAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  markUp?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  maxPerUserShare?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  note?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  password?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  playerId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  sharesToSell?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sort?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  termsAcceptedDate?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  tournamentId?: Resolver<
    Maybe<ResolversTypes['uuid']>,
    ParentType,
    ContextType
  >;
  tournamentResultId?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  updatedAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlayerSharesMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PlayerSharesMutationResponse'] = ResolversParentTypes['PlayerSharesMutationResponse']
> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<
    Array<ResolversTypes['PlayerShares']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlayerSharesStddevFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PlayerSharesStddevFields'] = ResolversParentTypes['PlayerSharesStddevFields']
> = {
  bullets?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  markUp?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  maxPerUserShare?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  sharesToSell?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  sort?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  tournamentResultId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlayerSharesStddev_PopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PlayerSharesStddev_popFields'] = ResolversParentTypes['PlayerSharesStddev_popFields']
> = {
  bullets?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  markUp?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  maxPerUserShare?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  sharesToSell?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  sort?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  tournamentResultId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlayerSharesStddev_SampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PlayerSharesStddev_sampFields'] = ResolversParentTypes['PlayerSharesStddev_sampFields']
> = {
  bullets?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  markUp?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  maxPerUserShare?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  sharesToSell?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  sort?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  tournamentResultId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlayerSharesSumFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PlayerSharesSumFields'] = ResolversParentTypes['PlayerSharesSumFields']
> = {
  bullets?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  markUp?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  maxPerUserShare?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  sharesToSell?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  sort?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  tournamentResultId?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlayerSharesVar_PopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PlayerSharesVar_popFields'] = ResolversParentTypes['PlayerSharesVar_popFields']
> = {
  bullets?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  markUp?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  maxPerUserShare?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  sharesToSell?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  sort?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  tournamentResultId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlayerSharesVar_SampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PlayerSharesVar_sampFields'] = ResolversParentTypes['PlayerSharesVar_sampFields']
> = {
  bullets?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  markUp?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  maxPerUserShare?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  sharesToSell?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  sort?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  tournamentResultId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlayerSharesVarianceFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PlayerSharesVarianceFields'] = ResolversParentTypes['PlayerSharesVarianceFields']
> = {
  bullets?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  markUp?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  maxPerUserShare?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  sharesToSell?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  sort?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  tournamentResultId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlayersResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Players'] = ResolversParentTypes['Players']
> = {
  actionsSold?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  allTimeList?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  allTimeListCountry?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  allTimeListCountryPlace?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  birthday?: Resolver<Maybe<ResolversTypes['date']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  description?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  facebook?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  followers?: Resolver<
    Array<ResolversTypes['Followers']>,
    ParentType,
    ContextType,
    Partial<PlayersFollowersArgs>
  >;
  followersAggregate?: Resolver<
    ResolversTypes['FollowersAggregate'],
    ParentType,
    ContextType,
    Partial<PlayersFollowersAggregateArgs>
  >;
  followersCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  gender?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  globalpokerindexUrl?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  gpiRanking?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  highestRoi?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  highestWinning?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  image?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  instagram?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  nationality?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  playerFollowersViewAggregate?: Resolver<
    ResolversTypes['PlayerFollowersViewAggregate'],
    ParentType,
    ContextType,
    Partial<PlayersPlayerFollowersViewAggregateArgs>
  >;
  playerSharesAggregate?: Resolver<
    ResolversTypes['PlayerSharesAggregate'],
    ParentType,
    ContextType,
    Partial<PlayersPlayerSharesAggregateArgs>
  >;
  player_followers_view?: Resolver<
    Array<ResolversTypes['PlayerFollowersView']>,
    ParentType,
    ContextType,
    Partial<PlayersPlayer_Followers_ViewArgs>
  >;
  player_shares?: Resolver<
    Array<ResolversTypes['PlayerShares']>,
    ParentType,
    ContextType,
    Partial<PlayersPlayer_SharesArgs>
  >;
  realName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  residence?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sort?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['StatusesEnum'], ParentType, ContextType>;
  thehendonmobUrl?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  totalLiveEarning?: Resolver<
    ResolversTypes['numeric'],
    ParentType,
    ContextType
  >;
  totalWinnings?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  tournamentResultsAggregate?: Resolver<
    ResolversTypes['TournamentResultsAggregate'],
    ParentType,
    ContextType,
    Partial<PlayersTournamentResultsAggregateArgs>
  >;
  tournament_results?: Resolver<
    Array<ResolversTypes['TournamentResults']>,
    ParentType,
    ContextType,
    Partial<PlayersTournament_ResultsArgs>
  >;
  twitter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['Users'], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  verified?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlayersAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PlayersAggregate'] = ResolversParentTypes['PlayersAggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['PlayersAggregateFields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<Array<ResolversTypes['Players']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlayersAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PlayersAggregateFields'] = ResolversParentTypes['PlayersAggregateFields']
> = {
  avg?: Resolver<
    Maybe<ResolversTypes['PlayersAvgFields']>,
    ParentType,
    ContextType
  >;
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    Partial<PlayersAggregateFieldsCountArgs>
  >;
  max?: Resolver<
    Maybe<ResolversTypes['PlayersMaxFields']>,
    ParentType,
    ContextType
  >;
  min?: Resolver<
    Maybe<ResolversTypes['PlayersMinFields']>,
    ParentType,
    ContextType
  >;
  stddev?: Resolver<
    Maybe<ResolversTypes['PlayersStddevFields']>,
    ParentType,
    ContextType
  >;
  stddevPop?: Resolver<
    Maybe<ResolversTypes['PlayersStddev_popFields']>,
    ParentType,
    ContextType
  >;
  stddevSamp?: Resolver<
    Maybe<ResolversTypes['PlayersStddev_sampFields']>,
    ParentType,
    ContextType
  >;
  sum?: Resolver<
    Maybe<ResolversTypes['PlayersSumFields']>,
    ParentType,
    ContextType
  >;
  varPop?: Resolver<
    Maybe<ResolversTypes['PlayersVar_popFields']>,
    ParentType,
    ContextType
  >;
  varSamp?: Resolver<
    Maybe<ResolversTypes['PlayersVar_sampFields']>,
    ParentType,
    ContextType
  >;
  variance?: Resolver<
    Maybe<ResolversTypes['PlayersVarianceFields']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlayersAvgFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PlayersAvgFields'] = ResolversParentTypes['PlayersAvgFields']
> = {
  actionsSold?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  allTimeList?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  allTimeListCountryPlace?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  followersCount?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  gpiRanking?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  highestRoi?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  highestWinning?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  sort?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  totalLiveEarning?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  totalWinnings?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlayersMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PlayersMaxFields'] = ResolversParentTypes['PlayersMaxFields']
> = {
  actionsSold?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  allTimeList?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  allTimeListCountry?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  allTimeListCountryPlace?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  birthday?: Resolver<Maybe<ResolversTypes['date']>, ParentType, ContextType>;
  createdAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  description?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  facebook?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  followersCount?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  gender?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  globalpokerindexUrl?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  gpiRanking?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  highestRoi?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  highestWinning?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  image?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  instagram?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  nationality?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  realName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  residence?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sort?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  thehendonmobUrl?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  totalLiveEarning?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  totalWinnings?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  twitter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlayersMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PlayersMinFields'] = ResolversParentTypes['PlayersMinFields']
> = {
  actionsSold?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  allTimeList?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  allTimeListCountry?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  allTimeListCountryPlace?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  birthday?: Resolver<Maybe<ResolversTypes['date']>, ParentType, ContextType>;
  createdAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  description?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  facebook?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  followersCount?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  gender?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  globalpokerindexUrl?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  gpiRanking?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  highestRoi?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  highestWinning?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  image?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  instagram?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  nationality?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  realName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  residence?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sort?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  thehendonmobUrl?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  totalLiveEarning?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  totalWinnings?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  twitter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlayersMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PlayersMutationResponse'] = ResolversParentTypes['PlayersMutationResponse']
> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<
    Array<ResolversTypes['Players']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlayersStddevFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PlayersStddevFields'] = ResolversParentTypes['PlayersStddevFields']
> = {
  actionsSold?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  allTimeList?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  allTimeListCountryPlace?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  followersCount?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  gpiRanking?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  highestRoi?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  highestWinning?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  sort?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  totalLiveEarning?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  totalWinnings?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlayersStddev_PopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PlayersStddev_popFields'] = ResolversParentTypes['PlayersStddev_popFields']
> = {
  actionsSold?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  allTimeList?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  allTimeListCountryPlace?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  followersCount?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  gpiRanking?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  highestRoi?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  highestWinning?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  sort?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  totalLiveEarning?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  totalWinnings?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlayersStddev_SampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PlayersStddev_sampFields'] = ResolversParentTypes['PlayersStddev_sampFields']
> = {
  actionsSold?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  allTimeList?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  allTimeListCountryPlace?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  followersCount?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  gpiRanking?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  highestRoi?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  highestWinning?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  sort?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  totalLiveEarning?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  totalWinnings?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlayersSumFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PlayersSumFields'] = ResolversParentTypes['PlayersSumFields']
> = {
  actionsSold?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  allTimeList?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  allTimeListCountryPlace?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  followersCount?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  gpiRanking?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  highestRoi?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  highestWinning?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  sort?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  totalLiveEarning?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  totalWinnings?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlayersVar_PopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PlayersVar_popFields'] = ResolversParentTypes['PlayersVar_popFields']
> = {
  actionsSold?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  allTimeList?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  allTimeListCountryPlace?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  followersCount?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  gpiRanking?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  highestRoi?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  highestWinning?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  sort?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  totalLiveEarning?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  totalWinnings?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlayersVar_SampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PlayersVar_sampFields'] = ResolversParentTypes['PlayersVar_sampFields']
> = {
  actionsSold?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  allTimeList?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  allTimeListCountryPlace?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  followersCount?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  gpiRanking?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  highestRoi?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  highestWinning?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  sort?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  totalLiveEarning?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  totalWinnings?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlayersVarianceFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PlayersVarianceFields'] = ResolversParentTypes['PlayersVarianceFields']
> = {
  actionsSold?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  allTimeList?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  allTimeListCountryPlace?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  followersCount?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  gpiRanking?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  highestRoi?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  highestWinning?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  sort?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  totalLiveEarning?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  totalWinnings?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StatEventsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StatEvents'] = ResolversParentTypes['StatEvents']
> = {
  createdAt?: Resolver<
    Maybe<ResolversTypes['timestamp']>,
    ParentType,
    ContextType
  >;
  date?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  location?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<
    Maybe<ResolversTypes['timestamp']>,
    ParentType,
    ContextType
  >;
  venueId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StatEventsAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StatEventsAggregate'] = ResolversParentTypes['StatEventsAggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['StatEventsAggregateFields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes['StatEvents']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StatEventsAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StatEventsAggregateFields'] = ResolversParentTypes['StatEventsAggregateFields']
> = {
  avg?: Resolver<
    Maybe<ResolversTypes['StatEventsAvgFields']>,
    ParentType,
    ContextType
  >;
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    Partial<StatEventsAggregateFieldsCountArgs>
  >;
  max?: Resolver<
    Maybe<ResolversTypes['StatEventsMaxFields']>,
    ParentType,
    ContextType
  >;
  min?: Resolver<
    Maybe<ResolversTypes['StatEventsMinFields']>,
    ParentType,
    ContextType
  >;
  stddev?: Resolver<
    Maybe<ResolversTypes['StatEventsStddevFields']>,
    ParentType,
    ContextType
  >;
  stddevPop?: Resolver<
    Maybe<ResolversTypes['StatEventsStddev_popFields']>,
    ParentType,
    ContextType
  >;
  stddevSamp?: Resolver<
    Maybe<ResolversTypes['StatEventsStddev_sampFields']>,
    ParentType,
    ContextType
  >;
  sum?: Resolver<
    Maybe<ResolversTypes['StatEventsSumFields']>,
    ParentType,
    ContextType
  >;
  varPop?: Resolver<
    Maybe<ResolversTypes['StatEventsVar_popFields']>,
    ParentType,
    ContextType
  >;
  varSamp?: Resolver<
    Maybe<ResolversTypes['StatEventsVar_sampFields']>,
    ParentType,
    ContextType
  >;
  variance?: Resolver<
    Maybe<ResolversTypes['StatEventsVarianceFields']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StatEventsAvgFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StatEventsAvgFields'] = ResolversParentTypes['StatEventsAvgFields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StatEventsMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StatEventsMaxFields'] = ResolversParentTypes['StatEventsMaxFields']
> = {
  createdAt?: Resolver<
    Maybe<ResolversTypes['timestamp']>,
    ParentType,
    ContextType
  >;
  date?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  location?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<
    Maybe<ResolversTypes['timestamp']>,
    ParentType,
    ContextType
  >;
  venueId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StatEventsMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StatEventsMinFields'] = ResolversParentTypes['StatEventsMinFields']
> = {
  createdAt?: Resolver<
    Maybe<ResolversTypes['timestamp']>,
    ParentType,
    ContextType
  >;
  date?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  location?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<
    Maybe<ResolversTypes['timestamp']>,
    ParentType,
    ContextType
  >;
  venueId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StatEventsMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StatEventsMutationResponse'] = ResolversParentTypes['StatEventsMutationResponse']
> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<
    Array<ResolversTypes['StatEvents']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StatEventsStddevFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StatEventsStddevFields'] = ResolversParentTypes['StatEventsStddevFields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StatEventsStddev_PopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StatEventsStddev_popFields'] = ResolversParentTypes['StatEventsStddev_popFields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StatEventsStddev_SampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StatEventsStddev_sampFields'] = ResolversParentTypes['StatEventsStddev_sampFields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StatEventsSumFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StatEventsSumFields'] = ResolversParentTypes['StatEventsSumFields']
> = {
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StatEventsVar_PopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StatEventsVar_popFields'] = ResolversParentTypes['StatEventsVar_popFields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StatEventsVar_SampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StatEventsVar_sampFields'] = ResolversParentTypes['StatEventsVar_sampFields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StatEventsVarianceFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StatEventsVarianceFields'] = ResolversParentTypes['StatEventsVarianceFields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StatPlayersResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StatPlayers'] = ResolversParentTypes['StatPlayers']
> = {
  allTimeMoneyList?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  asainPokerMoneyList?: Resolver<
    Maybe<ResolversTypes['bigint']>,
    ParentType,
    ContextType
  >;
  asianPokerRanking?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  createdAt?: Resolver<
    Maybe<ResolversTypes['timestamp']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  nationality?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  player?: Resolver<Maybe<ResolversTypes['Players']>, ParentType, ContextType>;
  playerId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  public?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  residence?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  statTournamentResultsAggregate?: Resolver<
    ResolversTypes['StatTournamentResultsAggregate'],
    ParentType,
    ContextType,
    Partial<StatPlayersStatTournamentResultsAggregateArgs>
  >;
  stat_tournament_results?: Resolver<
    Array<ResolversTypes['StatTournamentResults']>,
    ParentType,
    ContextType,
    Partial<StatPlayersStat_Tournament_ResultsArgs>
  >;
  thehendonmobId?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  totalLiveEarning?: Resolver<
    Maybe<ResolversTypes['bigint']>,
    ParentType,
    ContextType
  >;
  updatedAt?: Resolver<
    Maybe<ResolversTypes['timestamp']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StatPlayersAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StatPlayersAggregate'] = ResolversParentTypes['StatPlayersAggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['StatPlayersAggregateFields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes['StatPlayers']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StatPlayersAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StatPlayersAggregateFields'] = ResolversParentTypes['StatPlayersAggregateFields']
> = {
  avg?: Resolver<
    Maybe<ResolversTypes['StatPlayersAvgFields']>,
    ParentType,
    ContextType
  >;
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    Partial<StatPlayersAggregateFieldsCountArgs>
  >;
  max?: Resolver<
    Maybe<ResolversTypes['StatPlayersMaxFields']>,
    ParentType,
    ContextType
  >;
  min?: Resolver<
    Maybe<ResolversTypes['StatPlayersMinFields']>,
    ParentType,
    ContextType
  >;
  stddev?: Resolver<
    Maybe<ResolversTypes['StatPlayersStddevFields']>,
    ParentType,
    ContextType
  >;
  stddevPop?: Resolver<
    Maybe<ResolversTypes['StatPlayersStddev_popFields']>,
    ParentType,
    ContextType
  >;
  stddevSamp?: Resolver<
    Maybe<ResolversTypes['StatPlayersStddev_sampFields']>,
    ParentType,
    ContextType
  >;
  sum?: Resolver<
    Maybe<ResolversTypes['StatPlayersSumFields']>,
    ParentType,
    ContextType
  >;
  varPop?: Resolver<
    Maybe<ResolversTypes['StatPlayersVar_popFields']>,
    ParentType,
    ContextType
  >;
  varSamp?: Resolver<
    Maybe<ResolversTypes['StatPlayersVar_sampFields']>,
    ParentType,
    ContextType
  >;
  variance?: Resolver<
    Maybe<ResolversTypes['StatPlayersVarianceFields']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StatPlayersAvgFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StatPlayersAvgFields'] = ResolversParentTypes['StatPlayersAvgFields']
> = {
  allTimeMoneyList?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  asainPokerMoneyList?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  asianPokerRanking?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  thehendonmobId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  totalLiveEarning?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StatPlayersMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StatPlayersMaxFields'] = ResolversParentTypes['StatPlayersMaxFields']
> = {
  allTimeMoneyList?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  asainPokerMoneyList?: Resolver<
    Maybe<ResolversTypes['bigint']>,
    ParentType,
    ContextType
  >;
  asianPokerRanking?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  createdAt?: Resolver<
    Maybe<ResolversTypes['timestamp']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  nationality?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  playerId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  residence?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  thehendonmobId?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  totalLiveEarning?: Resolver<
    Maybe<ResolversTypes['bigint']>,
    ParentType,
    ContextType
  >;
  updatedAt?: Resolver<
    Maybe<ResolversTypes['timestamp']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StatPlayersMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StatPlayersMinFields'] = ResolversParentTypes['StatPlayersMinFields']
> = {
  allTimeMoneyList?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  asainPokerMoneyList?: Resolver<
    Maybe<ResolversTypes['bigint']>,
    ParentType,
    ContextType
  >;
  asianPokerRanking?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  createdAt?: Resolver<
    Maybe<ResolversTypes['timestamp']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  nationality?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  playerId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  residence?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  thehendonmobId?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  totalLiveEarning?: Resolver<
    Maybe<ResolversTypes['bigint']>,
    ParentType,
    ContextType
  >;
  updatedAt?: Resolver<
    Maybe<ResolversTypes['timestamp']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StatPlayersMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StatPlayersMutationResponse'] = ResolversParentTypes['StatPlayersMutationResponse']
> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<
    Array<ResolversTypes['StatPlayers']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StatPlayersStddevFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StatPlayersStddevFields'] = ResolversParentTypes['StatPlayersStddevFields']
> = {
  allTimeMoneyList?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  asainPokerMoneyList?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  asianPokerRanking?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  thehendonmobId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  totalLiveEarning?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StatPlayersStddev_PopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StatPlayersStddev_popFields'] = ResolversParentTypes['StatPlayersStddev_popFields']
> = {
  allTimeMoneyList?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  asainPokerMoneyList?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  asianPokerRanking?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  thehendonmobId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  totalLiveEarning?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StatPlayersStddev_SampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StatPlayersStddev_sampFields'] = ResolversParentTypes['StatPlayersStddev_sampFields']
> = {
  allTimeMoneyList?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  asainPokerMoneyList?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  asianPokerRanking?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  thehendonmobId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  totalLiveEarning?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StatPlayersSumFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StatPlayersSumFields'] = ResolversParentTypes['StatPlayersSumFields']
> = {
  allTimeMoneyList?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  asainPokerMoneyList?: Resolver<
    Maybe<ResolversTypes['bigint']>,
    ParentType,
    ContextType
  >;
  asianPokerRanking?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  thehendonmobId?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  totalLiveEarning?: Resolver<
    Maybe<ResolversTypes['bigint']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StatPlayersVar_PopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StatPlayersVar_popFields'] = ResolversParentTypes['StatPlayersVar_popFields']
> = {
  allTimeMoneyList?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  asainPokerMoneyList?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  asianPokerRanking?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  thehendonmobId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  totalLiveEarning?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StatPlayersVar_SampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StatPlayersVar_sampFields'] = ResolversParentTypes['StatPlayersVar_sampFields']
> = {
  allTimeMoneyList?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  asainPokerMoneyList?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  asianPokerRanking?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  thehendonmobId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  totalLiveEarning?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StatPlayersVarianceFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StatPlayersVarianceFields'] = ResolversParentTypes['StatPlayersVarianceFields']
> = {
  allTimeMoneyList?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  asainPokerMoneyList?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  asianPokerRanking?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  thehendonmobId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  totalLiveEarning?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StatTopPlayersResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StatTopPlayers'] = ResolversParentTypes['StatTopPlayers']
> = {
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  money?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  points?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  ranking?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  stat_player?: Resolver<
    Maybe<ResolversTypes['StatPlayers']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StatTopPlayersAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StatTopPlayersAggregate'] = ResolversParentTypes['StatTopPlayersAggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['StatTopPlayersAggregateFields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes['StatTopPlayers']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StatTopPlayersAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StatTopPlayersAggregateFields'] = ResolversParentTypes['StatTopPlayersAggregateFields']
> = {
  avg?: Resolver<
    Maybe<ResolversTypes['StatTopPlayersAvgFields']>,
    ParentType,
    ContextType
  >;
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    Partial<StatTopPlayersAggregateFieldsCountArgs>
  >;
  max?: Resolver<
    Maybe<ResolversTypes['StatTopPlayersMaxFields']>,
    ParentType,
    ContextType
  >;
  min?: Resolver<
    Maybe<ResolversTypes['StatTopPlayersMinFields']>,
    ParentType,
    ContextType
  >;
  stddev?: Resolver<
    Maybe<ResolversTypes['StatTopPlayersStddevFields']>,
    ParentType,
    ContextType
  >;
  stddevPop?: Resolver<
    Maybe<ResolversTypes['StatTopPlayersStddev_popFields']>,
    ParentType,
    ContextType
  >;
  stddevSamp?: Resolver<
    Maybe<ResolversTypes['StatTopPlayersStddev_sampFields']>,
    ParentType,
    ContextType
  >;
  sum?: Resolver<
    Maybe<ResolversTypes['StatTopPlayersSumFields']>,
    ParentType,
    ContextType
  >;
  varPop?: Resolver<
    Maybe<ResolversTypes['StatTopPlayersVar_popFields']>,
    ParentType,
    ContextType
  >;
  varSamp?: Resolver<
    Maybe<ResolversTypes['StatTopPlayersVar_sampFields']>,
    ParentType,
    ContextType
  >;
  variance?: Resolver<
    Maybe<ResolversTypes['StatTopPlayersVarianceFields']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StatTopPlayersAvgFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StatTopPlayersAvgFields'] = ResolversParentTypes['StatTopPlayersAvgFields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  money?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  points?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ranking?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StatTopPlayersMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StatTopPlayersMaxFields'] = ResolversParentTypes['StatTopPlayersMaxFields']
> = {
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  money?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  points?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  ranking?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StatTopPlayersMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StatTopPlayersMinFields'] = ResolversParentTypes['StatTopPlayersMinFields']
> = {
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  money?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  points?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  ranking?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StatTopPlayersStddevFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StatTopPlayersStddevFields'] = ResolversParentTypes['StatTopPlayersStddevFields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  money?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  points?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ranking?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StatTopPlayersStddev_PopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StatTopPlayersStddev_popFields'] = ResolversParentTypes['StatTopPlayersStddev_popFields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  money?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  points?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ranking?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StatTopPlayersStddev_SampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StatTopPlayersStddev_sampFields'] = ResolversParentTypes['StatTopPlayersStddev_sampFields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  money?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  points?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ranking?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StatTopPlayersSumFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StatTopPlayersSumFields'] = ResolversParentTypes['StatTopPlayersSumFields']
> = {
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  money?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  points?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  ranking?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StatTopPlayersVar_PopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StatTopPlayersVar_popFields'] = ResolversParentTypes['StatTopPlayersVar_popFields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  money?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  points?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ranking?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StatTopPlayersVar_SampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StatTopPlayersVar_sampFields'] = ResolversParentTypes['StatTopPlayersVar_sampFields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  money?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  points?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ranking?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StatTopPlayersVarianceFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StatTopPlayersVarianceFields'] = ResolversParentTypes['StatTopPlayersVarianceFields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  money?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  points?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ranking?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StatTopYearPlayersResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StatTopYearPlayers'] = ResolversParentTypes['StatTopYearPlayers']
> = {
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  money?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  points?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  ranking?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  stat_player?: Resolver<
    Maybe<ResolversTypes['StatPlayers']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StatTopYearPlayersAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StatTopYearPlayersAggregate'] = ResolversParentTypes['StatTopYearPlayersAggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['StatTopYearPlayersAggregateFields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes['StatTopYearPlayers']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StatTopYearPlayersAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StatTopYearPlayersAggregateFields'] = ResolversParentTypes['StatTopYearPlayersAggregateFields']
> = {
  avg?: Resolver<
    Maybe<ResolversTypes['StatTopYearPlayersAvgFields']>,
    ParentType,
    ContextType
  >;
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    Partial<StatTopYearPlayersAggregateFieldsCountArgs>
  >;
  max?: Resolver<
    Maybe<ResolversTypes['StatTopYearPlayersMaxFields']>,
    ParentType,
    ContextType
  >;
  min?: Resolver<
    Maybe<ResolversTypes['StatTopYearPlayersMinFields']>,
    ParentType,
    ContextType
  >;
  stddev?: Resolver<
    Maybe<ResolversTypes['StatTopYearPlayersStddevFields']>,
    ParentType,
    ContextType
  >;
  stddevPop?: Resolver<
    Maybe<ResolversTypes['StatTopYearPlayersStddev_popFields']>,
    ParentType,
    ContextType
  >;
  stddevSamp?: Resolver<
    Maybe<ResolversTypes['StatTopYearPlayersStddev_sampFields']>,
    ParentType,
    ContextType
  >;
  sum?: Resolver<
    Maybe<ResolversTypes['StatTopYearPlayersSumFields']>,
    ParentType,
    ContextType
  >;
  varPop?: Resolver<
    Maybe<ResolversTypes['StatTopYearPlayersVar_popFields']>,
    ParentType,
    ContextType
  >;
  varSamp?: Resolver<
    Maybe<ResolversTypes['StatTopYearPlayersVar_sampFields']>,
    ParentType,
    ContextType
  >;
  variance?: Resolver<
    Maybe<ResolversTypes['StatTopYearPlayersVarianceFields']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StatTopYearPlayersAvgFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StatTopYearPlayersAvgFields'] = ResolversParentTypes['StatTopYearPlayersAvgFields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  money?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  points?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ranking?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StatTopYearPlayersMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StatTopYearPlayersMaxFields'] = ResolversParentTypes['StatTopYearPlayersMaxFields']
> = {
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  money?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  points?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  ranking?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StatTopYearPlayersMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StatTopYearPlayersMinFields'] = ResolversParentTypes['StatTopYearPlayersMinFields']
> = {
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  money?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  points?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  ranking?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StatTopYearPlayersStddevFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StatTopYearPlayersStddevFields'] = ResolversParentTypes['StatTopYearPlayersStddevFields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  money?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  points?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ranking?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StatTopYearPlayersStddev_PopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StatTopYearPlayersStddev_popFields'] = ResolversParentTypes['StatTopYearPlayersStddev_popFields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  money?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  points?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ranking?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StatTopYearPlayersStddev_SampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StatTopYearPlayersStddev_sampFields'] = ResolversParentTypes['StatTopYearPlayersStddev_sampFields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  money?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  points?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ranking?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StatTopYearPlayersSumFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StatTopYearPlayersSumFields'] = ResolversParentTypes['StatTopYearPlayersSumFields']
> = {
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  money?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  points?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  ranking?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StatTopYearPlayersVar_PopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StatTopYearPlayersVar_popFields'] = ResolversParentTypes['StatTopYearPlayersVar_popFields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  money?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  points?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ranking?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StatTopYearPlayersVar_SampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StatTopYearPlayersVar_sampFields'] = ResolversParentTypes['StatTopYearPlayersVar_sampFields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  money?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  points?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ranking?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StatTopYearPlayersVarianceFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StatTopYearPlayersVarianceFields'] = ResolversParentTypes['StatTopYearPlayersVarianceFields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  money?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  points?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ranking?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StatTournamentResultsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StatTournamentResults'] = ResolversParentTypes['StatTournamentResults']
> = {
  createdAt?: Resolver<
    Maybe<ResolversTypes['timestamp']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  place?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  playerGeneralResultId?: Resolver<
    Maybe<ResolversTypes['bigint']>,
    ParentType,
    ContextType
  >;
  points?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  price?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  priceLocal?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  statPlayerId?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  statTournamentId?: Resolver<
    ResolversTypes['bigint'],
    ParentType,
    ContextType
  >;
  stat_player?: Resolver<
    ResolversTypes['StatPlayers'],
    ParentType,
    ContextType
  >;
  stat_tournament?: Resolver<
    ResolversTypes['StatTournaments'],
    ParentType,
    ContextType
  >;
  updatedAt?: Resolver<
    Maybe<ResolversTypes['timestamp']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StatTournamentResultsAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StatTournamentResultsAggregate'] = ResolversParentTypes['StatTournamentResultsAggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['StatTournamentResultsAggregateFields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes['StatTournamentResults']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StatTournamentResultsAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StatTournamentResultsAggregateFields'] = ResolversParentTypes['StatTournamentResultsAggregateFields']
> = {
  avg?: Resolver<
    Maybe<ResolversTypes['StatTournamentResultsAvgFields']>,
    ParentType,
    ContextType
  >;
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    Partial<StatTournamentResultsAggregateFieldsCountArgs>
  >;
  max?: Resolver<
    Maybe<ResolversTypes['StatTournamentResultsMaxFields']>,
    ParentType,
    ContextType
  >;
  min?: Resolver<
    Maybe<ResolversTypes['StatTournamentResultsMinFields']>,
    ParentType,
    ContextType
  >;
  stddev?: Resolver<
    Maybe<ResolversTypes['StatTournamentResultsStddevFields']>,
    ParentType,
    ContextType
  >;
  stddevPop?: Resolver<
    Maybe<ResolversTypes['StatTournamentResultsStddev_popFields']>,
    ParentType,
    ContextType
  >;
  stddevSamp?: Resolver<
    Maybe<ResolversTypes['StatTournamentResultsStddev_sampFields']>,
    ParentType,
    ContextType
  >;
  sum?: Resolver<
    Maybe<ResolversTypes['StatTournamentResultsSumFields']>,
    ParentType,
    ContextType
  >;
  varPop?: Resolver<
    Maybe<ResolversTypes['StatTournamentResultsVar_popFields']>,
    ParentType,
    ContextType
  >;
  varSamp?: Resolver<
    Maybe<ResolversTypes['StatTournamentResultsVar_sampFields']>,
    ParentType,
    ContextType
  >;
  variance?: Resolver<
    Maybe<ResolversTypes['StatTournamentResultsVarianceFields']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StatTournamentResultsAvgFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StatTournamentResultsAvgFields'] = ResolversParentTypes['StatTournamentResultsAvgFields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  place?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  playerGeneralResultId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  points?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  price?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  priceLocal?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  statPlayerId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  statTournamentId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StatTournamentResultsMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StatTournamentResultsMaxFields'] = ResolversParentTypes['StatTournamentResultsMaxFields']
> = {
  createdAt?: Resolver<
    Maybe<ResolversTypes['timestamp']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  place?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  playerGeneralResultId?: Resolver<
    Maybe<ResolversTypes['bigint']>,
    ParentType,
    ContextType
  >;
  points?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  price?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  priceLocal?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  statPlayerId?: Resolver<
    Maybe<ResolversTypes['bigint']>,
    ParentType,
    ContextType
  >;
  statTournamentId?: Resolver<
    Maybe<ResolversTypes['bigint']>,
    ParentType,
    ContextType
  >;
  updatedAt?: Resolver<
    Maybe<ResolversTypes['timestamp']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StatTournamentResultsMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StatTournamentResultsMinFields'] = ResolversParentTypes['StatTournamentResultsMinFields']
> = {
  createdAt?: Resolver<
    Maybe<ResolversTypes['timestamp']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  place?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  playerGeneralResultId?: Resolver<
    Maybe<ResolversTypes['bigint']>,
    ParentType,
    ContextType
  >;
  points?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  price?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  priceLocal?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  statPlayerId?: Resolver<
    Maybe<ResolversTypes['bigint']>,
    ParentType,
    ContextType
  >;
  statTournamentId?: Resolver<
    Maybe<ResolversTypes['bigint']>,
    ParentType,
    ContextType
  >;
  updatedAt?: Resolver<
    Maybe<ResolversTypes['timestamp']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StatTournamentResultsMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StatTournamentResultsMutationResponse'] = ResolversParentTypes['StatTournamentResultsMutationResponse']
> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<
    Array<ResolversTypes['StatTournamentResults']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StatTournamentResultsStddevFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StatTournamentResultsStddevFields'] = ResolversParentTypes['StatTournamentResultsStddevFields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  place?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  playerGeneralResultId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  points?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  price?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  priceLocal?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  statPlayerId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  statTournamentId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StatTournamentResultsStddev_PopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StatTournamentResultsStddev_popFields'] = ResolversParentTypes['StatTournamentResultsStddev_popFields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  place?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  playerGeneralResultId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  points?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  price?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  priceLocal?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  statPlayerId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  statTournamentId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StatTournamentResultsStddev_SampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StatTournamentResultsStddev_sampFields'] = ResolversParentTypes['StatTournamentResultsStddev_sampFields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  place?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  playerGeneralResultId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  points?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  price?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  priceLocal?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  statPlayerId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  statTournamentId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StatTournamentResultsSumFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StatTournamentResultsSumFields'] = ResolversParentTypes['StatTournamentResultsSumFields']
> = {
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  place?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  playerGeneralResultId?: Resolver<
    Maybe<ResolversTypes['bigint']>,
    ParentType,
    ContextType
  >;
  points?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  price?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  priceLocal?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  statPlayerId?: Resolver<
    Maybe<ResolversTypes['bigint']>,
    ParentType,
    ContextType
  >;
  statTournamentId?: Resolver<
    Maybe<ResolversTypes['bigint']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StatTournamentResultsVar_PopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StatTournamentResultsVar_popFields'] = ResolversParentTypes['StatTournamentResultsVar_popFields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  place?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  playerGeneralResultId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  points?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  price?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  priceLocal?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  statPlayerId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  statTournamentId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StatTournamentResultsVar_SampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StatTournamentResultsVar_sampFields'] = ResolversParentTypes['StatTournamentResultsVar_sampFields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  place?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  playerGeneralResultId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  points?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  price?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  priceLocal?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  statPlayerId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  statTournamentId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StatTournamentResultsVarianceFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StatTournamentResultsVarianceFields'] = ResolversParentTypes['StatTournamentResultsVarianceFields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  place?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  playerGeneralResultId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  points?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  price?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  priceLocal?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  statPlayerId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  statTournamentId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StatTournamentsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StatTournaments'] = ResolversParentTypes['StatTournaments']
> = {
  buyIn?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  buyInLocal?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  createdAt?: Resolver<
    Maybe<ResolversTypes['timestamp']>,
    ParentType,
    ContextType
  >;
  currency?: Resolver<ResolversTypes['Currencies'], ParentType, ContextType>;
  date?: Resolver<ResolversTypes['timestamp'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  localCurrencyId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  location?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  prizepool?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  prizepoolLocal?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  statEventId?: Resolver<
    Maybe<ResolversTypes['bigint']>,
    ParentType,
    ContextType
  >;
  statFileId?: Resolver<
    Maybe<ResolversTypes['bigint']>,
    ParentType,
    ContextType
  >;
  updatedAt?: Resolver<
    Maybe<ResolversTypes['timestamp']>,
    ParentType,
    ContextType
  >;
  venue?: Resolver<ResolversTypes['Venues'], ParentType, ContextType>;
  venueId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StatTournamentsAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StatTournamentsAggregate'] = ResolversParentTypes['StatTournamentsAggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['StatTournamentsAggregateFields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes['StatTournaments']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StatTournamentsAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StatTournamentsAggregateFields'] = ResolversParentTypes['StatTournamentsAggregateFields']
> = {
  avg?: Resolver<
    Maybe<ResolversTypes['StatTournamentsAvgFields']>,
    ParentType,
    ContextType
  >;
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    Partial<StatTournamentsAggregateFieldsCountArgs>
  >;
  max?: Resolver<
    Maybe<ResolversTypes['StatTournamentsMaxFields']>,
    ParentType,
    ContextType
  >;
  min?: Resolver<
    Maybe<ResolversTypes['StatTournamentsMinFields']>,
    ParentType,
    ContextType
  >;
  stddev?: Resolver<
    Maybe<ResolversTypes['StatTournamentsStddevFields']>,
    ParentType,
    ContextType
  >;
  stddevPop?: Resolver<
    Maybe<ResolversTypes['StatTournamentsStddev_popFields']>,
    ParentType,
    ContextType
  >;
  stddevSamp?: Resolver<
    Maybe<ResolversTypes['StatTournamentsStddev_sampFields']>,
    ParentType,
    ContextType
  >;
  sum?: Resolver<
    Maybe<ResolversTypes['StatTournamentsSumFields']>,
    ParentType,
    ContextType
  >;
  varPop?: Resolver<
    Maybe<ResolversTypes['StatTournamentsVar_popFields']>,
    ParentType,
    ContextType
  >;
  varSamp?: Resolver<
    Maybe<ResolversTypes['StatTournamentsVar_sampFields']>,
    ParentType,
    ContextType
  >;
  variance?: Resolver<
    Maybe<ResolversTypes['StatTournamentsVarianceFields']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StatTournamentsAvgFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StatTournamentsAvgFields'] = ResolversParentTypes['StatTournamentsAvgFields']
> = {
  buyIn?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  buyInLocal?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  localCurrencyId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  prizepool?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  prizepoolLocal?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  statEventId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  statFileId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StatTournamentsMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StatTournamentsMaxFields'] = ResolversParentTypes['StatTournamentsMaxFields']
> = {
  buyIn?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  buyInLocal?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  createdAt?: Resolver<
    Maybe<ResolversTypes['timestamp']>,
    ParentType,
    ContextType
  >;
  date?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  localCurrencyId?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  location?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  prizepool?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  prizepoolLocal?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  statEventId?: Resolver<
    Maybe<ResolversTypes['bigint']>,
    ParentType,
    ContextType
  >;
  statFileId?: Resolver<
    Maybe<ResolversTypes['bigint']>,
    ParentType,
    ContextType
  >;
  updatedAt?: Resolver<
    Maybe<ResolversTypes['timestamp']>,
    ParentType,
    ContextType
  >;
  venueId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StatTournamentsMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StatTournamentsMinFields'] = ResolversParentTypes['StatTournamentsMinFields']
> = {
  buyIn?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  buyInLocal?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  createdAt?: Resolver<
    Maybe<ResolversTypes['timestamp']>,
    ParentType,
    ContextType
  >;
  date?: Resolver<Maybe<ResolversTypes['timestamp']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  localCurrencyId?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  location?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  prizepool?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  prizepoolLocal?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  statEventId?: Resolver<
    Maybe<ResolversTypes['bigint']>,
    ParentType,
    ContextType
  >;
  statFileId?: Resolver<
    Maybe<ResolversTypes['bigint']>,
    ParentType,
    ContextType
  >;
  updatedAt?: Resolver<
    Maybe<ResolversTypes['timestamp']>,
    ParentType,
    ContextType
  >;
  venueId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StatTournamentsMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StatTournamentsMutationResponse'] = ResolversParentTypes['StatTournamentsMutationResponse']
> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<
    Array<ResolversTypes['StatTournaments']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StatTournamentsStddevFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StatTournamentsStddevFields'] = ResolversParentTypes['StatTournamentsStddevFields']
> = {
  buyIn?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  buyInLocal?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  localCurrencyId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  prizepool?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  prizepoolLocal?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  statEventId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  statFileId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StatTournamentsStddev_PopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StatTournamentsStddev_popFields'] = ResolversParentTypes['StatTournamentsStddev_popFields']
> = {
  buyIn?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  buyInLocal?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  localCurrencyId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  prizepool?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  prizepoolLocal?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  statEventId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  statFileId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StatTournamentsStddev_SampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StatTournamentsStddev_sampFields'] = ResolversParentTypes['StatTournamentsStddev_sampFields']
> = {
  buyIn?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  buyInLocal?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  localCurrencyId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  prizepool?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  prizepoolLocal?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  statEventId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  statFileId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StatTournamentsSumFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StatTournamentsSumFields'] = ResolversParentTypes['StatTournamentsSumFields']
> = {
  buyIn?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  buyInLocal?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  localCurrencyId?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  prizepool?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  prizepoolLocal?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  statEventId?: Resolver<
    Maybe<ResolversTypes['bigint']>,
    ParentType,
    ContextType
  >;
  statFileId?: Resolver<
    Maybe<ResolversTypes['bigint']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StatTournamentsVar_PopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StatTournamentsVar_popFields'] = ResolversParentTypes['StatTournamentsVar_popFields']
> = {
  buyIn?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  buyInLocal?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  localCurrencyId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  prizepool?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  prizepoolLocal?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  statEventId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  statFileId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StatTournamentsVar_SampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StatTournamentsVar_sampFields'] = ResolversParentTypes['StatTournamentsVar_sampFields']
> = {
  buyIn?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  buyInLocal?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  localCurrencyId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  prizepool?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  prizepoolLocal?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  statEventId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  statFileId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StatTournamentsVarianceFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StatTournamentsVarianceFields'] = ResolversParentTypes['StatTournamentsVarianceFields']
> = {
  buyIn?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  buyInLocal?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  localCurrencyId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  prizepool?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  prizepoolLocal?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  statEventId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  statFileId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StatusGamesResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StatusGames'] = ResolversParentTypes['StatusGames']
> = {
  description?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StatusGamesAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StatusGamesAggregate'] = ResolversParentTypes['StatusGamesAggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['StatusGamesAggregateFields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes['StatusGames']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StatusGamesAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StatusGamesAggregateFields'] = ResolversParentTypes['StatusGamesAggregateFields']
> = {
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    Partial<StatusGamesAggregateFieldsCountArgs>
  >;
  max?: Resolver<
    Maybe<ResolversTypes['StatusGamesMaxFields']>,
    ParentType,
    ContextType
  >;
  min?: Resolver<
    Maybe<ResolversTypes['StatusGamesMinFields']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StatusGamesMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StatusGamesMaxFields'] = ResolversParentTypes['StatusGamesMaxFields']
> = {
  description?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StatusGamesMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StatusGamesMinFields'] = ResolversParentTypes['StatusGamesMinFields']
> = {
  description?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StatusGamesMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StatusGamesMutationResponse'] = ResolversParentTypes['StatusGamesMutationResponse']
> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<
    Array<ResolversTypes['StatusGames']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StatusOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StatusOutput'] = ResolversParentTypes['StatusOutput']
> = {
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<
    ResolversTypes['transactionStatus'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StatusesResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Statuses'] = ResolversParentTypes['Statuses']
> = {
  description?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StatusesAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StatusesAggregate'] = ResolversParentTypes['StatusesAggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['StatusesAggregateFields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<Array<ResolversTypes['Statuses']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StatusesAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StatusesAggregateFields'] = ResolversParentTypes['StatusesAggregateFields']
> = {
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    Partial<StatusesAggregateFieldsCountArgs>
  >;
  max?: Resolver<
    Maybe<ResolversTypes['StatusesMaxFields']>,
    ParentType,
    ContextType
  >;
  min?: Resolver<
    Maybe<ResolversTypes['StatusesMinFields']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StatusesMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StatusesMaxFields'] = ResolversParentTypes['StatusesMaxFields']
> = {
  description?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StatusesMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StatusesMinFields'] = ResolversParentTypes['StatusesMinFields']
> = {
  description?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StatusesMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StatusesMutationResponse'] = ResolversParentTypes['StatusesMutationResponse']
> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<
    Array<ResolversTypes['Statuses']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TicketsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Tickets'] = ResolversParentTypes['Tickets']
> = {
  amount?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  bullet?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  createdAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  playerName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  playerShareId?: Resolver<
    Maybe<ResolversTypes['uuid']>,
    ParentType,
    ContextType
  >;
  player_share?: Resolver<
    Maybe<ResolversTypes['PlayerShares']>,
    ParentType,
    ContextType
  >;
  status?: Resolver<
    ResolversTypes['TicketsStatusEnum'],
    ParentType,
    ContextType
  >;
  tournament?: Resolver<ResolversTypes['Tournaments'], ParentType, ContextType>;
  tournamentId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  tournamentResultId?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  tournament_result?: Resolver<
    Maybe<ResolversTypes['TournamentResults']>,
    ParentType,
    ContextType
  >;
  updatedAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  user?: Resolver<ResolversTypes['Users'], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  won?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  wonLocal?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TicketsAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TicketsAggregate'] = ResolversParentTypes['TicketsAggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['TicketsAggregateFields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<Array<ResolversTypes['Tickets']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TicketsAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TicketsAggregateFields'] = ResolversParentTypes['TicketsAggregateFields']
> = {
  avg?: Resolver<
    Maybe<ResolversTypes['TicketsAvgFields']>,
    ParentType,
    ContextType
  >;
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    Partial<TicketsAggregateFieldsCountArgs>
  >;
  max?: Resolver<
    Maybe<ResolversTypes['TicketsMaxFields']>,
    ParentType,
    ContextType
  >;
  min?: Resolver<
    Maybe<ResolversTypes['TicketsMinFields']>,
    ParentType,
    ContextType
  >;
  stddev?: Resolver<
    Maybe<ResolversTypes['TicketsStddevFields']>,
    ParentType,
    ContextType
  >;
  stddevPop?: Resolver<
    Maybe<ResolversTypes['TicketsStddev_popFields']>,
    ParentType,
    ContextType
  >;
  stddevSamp?: Resolver<
    Maybe<ResolversTypes['TicketsStddev_sampFields']>,
    ParentType,
    ContextType
  >;
  sum?: Resolver<
    Maybe<ResolversTypes['TicketsSumFields']>,
    ParentType,
    ContextType
  >;
  varPop?: Resolver<
    Maybe<ResolversTypes['TicketsVar_popFields']>,
    ParentType,
    ContextType
  >;
  varSamp?: Resolver<
    Maybe<ResolversTypes['TicketsVar_sampFields']>,
    ParentType,
    ContextType
  >;
  variance?: Resolver<
    Maybe<ResolversTypes['TicketsVarianceFields']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TicketsAvgFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TicketsAvgFields'] = ResolversParentTypes['TicketsAvgFields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  bullet?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  tournamentResultId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  won?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  wonLocal?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TicketsMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TicketsMaxFields'] = ResolversParentTypes['TicketsMaxFields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  bullet?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  createdAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  playerName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  playerShareId?: Resolver<
    Maybe<ResolversTypes['uuid']>,
    ParentType,
    ContextType
  >;
  tournamentId?: Resolver<
    Maybe<ResolversTypes['uuid']>,
    ParentType,
    ContextType
  >;
  tournamentResultId?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  updatedAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  won?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  wonLocal?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TicketsMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TicketsMinFields'] = ResolversParentTypes['TicketsMinFields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  bullet?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  createdAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  playerName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  playerShareId?: Resolver<
    Maybe<ResolversTypes['uuid']>,
    ParentType,
    ContextType
  >;
  tournamentId?: Resolver<
    Maybe<ResolversTypes['uuid']>,
    ParentType,
    ContextType
  >;
  tournamentResultId?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  updatedAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  won?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  wonLocal?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TicketsMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TicketsMutationResponse'] = ResolversParentTypes['TicketsMutationResponse']
> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<
    Array<ResolversTypes['Tickets']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TicketsStatusResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TicketsStatus'] = ResolversParentTypes['TicketsStatus']
> = {
  description?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TicketsStatusAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TicketsStatusAggregate'] = ResolversParentTypes['TicketsStatusAggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['TicketsStatusAggregateFields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes['TicketsStatus']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TicketsStatusAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TicketsStatusAggregateFields'] = ResolversParentTypes['TicketsStatusAggregateFields']
> = {
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    Partial<TicketsStatusAggregateFieldsCountArgs>
  >;
  max?: Resolver<
    Maybe<ResolversTypes['TicketsStatusMaxFields']>,
    ParentType,
    ContextType
  >;
  min?: Resolver<
    Maybe<ResolversTypes['TicketsStatusMinFields']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TicketsStatusMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TicketsStatusMaxFields'] = ResolversParentTypes['TicketsStatusMaxFields']
> = {
  description?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TicketsStatusMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TicketsStatusMinFields'] = ResolversParentTypes['TicketsStatusMinFields']
> = {
  description?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TicketsStatusMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TicketsStatusMutationResponse'] = ResolversParentTypes['TicketsStatusMutationResponse']
> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<
    Array<ResolversTypes['TicketsStatus']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TicketsStddevFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TicketsStddevFields'] = ResolversParentTypes['TicketsStddevFields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  bullet?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  tournamentResultId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  won?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  wonLocal?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TicketsStddev_PopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TicketsStddev_popFields'] = ResolversParentTypes['TicketsStddev_popFields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  bullet?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  tournamentResultId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  won?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  wonLocal?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TicketsStddev_SampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TicketsStddev_sampFields'] = ResolversParentTypes['TicketsStddev_sampFields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  bullet?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  tournamentResultId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  won?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  wonLocal?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TicketsSumFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TicketsSumFields'] = ResolversParentTypes['TicketsSumFields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  bullet?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  tournamentResultId?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  won?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  wonLocal?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TicketsVar_PopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TicketsVar_popFields'] = ResolversParentTypes['TicketsVar_popFields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  bullet?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  tournamentResultId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  won?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  wonLocal?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TicketsVar_SampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TicketsVar_sampFields'] = ResolversParentTypes['TicketsVar_sampFields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  bullet?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  tournamentResultId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  won?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  wonLocal?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TicketsVarianceFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TicketsVarianceFields'] = ResolversParentTypes['TicketsVarianceFields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  bullet?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  tournamentResultId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  won?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  wonLocal?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TournamentBalancesResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TournamentBalances'] = ResolversParentTypes['TournamentBalances']
> = {
  balance?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  currency?: Resolver<
    Maybe<ResolversTypes['Currencies']>,
    ParentType,
    ContextType
  >;
  currencyId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  eventId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tournament?: Resolver<
    Maybe<ResolversTypes['Tournaments']>,
    ParentType,
    ContextType
  >;
  tournamentId?: Resolver<
    Maybe<ResolversTypes['uuid']>,
    ParentType,
    ContextType
  >;
  venueId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TournamentBalancesAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TournamentBalancesAggregate'] = ResolversParentTypes['TournamentBalancesAggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['TournamentBalancesAggregateFields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes['TournamentBalances']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TournamentBalancesAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TournamentBalancesAggregateFields'] = ResolversParentTypes['TournamentBalancesAggregateFields']
> = {
  avg?: Resolver<
    Maybe<ResolversTypes['TournamentBalancesAvgFields']>,
    ParentType,
    ContextType
  >;
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    Partial<TournamentBalancesAggregateFieldsCountArgs>
  >;
  max?: Resolver<
    Maybe<ResolversTypes['TournamentBalancesMaxFields']>,
    ParentType,
    ContextType
  >;
  min?: Resolver<
    Maybe<ResolversTypes['TournamentBalancesMinFields']>,
    ParentType,
    ContextType
  >;
  stddev?: Resolver<
    Maybe<ResolversTypes['TournamentBalancesStddevFields']>,
    ParentType,
    ContextType
  >;
  stddevPop?: Resolver<
    Maybe<ResolversTypes['TournamentBalancesStddev_popFields']>,
    ParentType,
    ContextType
  >;
  stddevSamp?: Resolver<
    Maybe<ResolversTypes['TournamentBalancesStddev_sampFields']>,
    ParentType,
    ContextType
  >;
  sum?: Resolver<
    Maybe<ResolversTypes['TournamentBalancesSumFields']>,
    ParentType,
    ContextType
  >;
  varPop?: Resolver<
    Maybe<ResolversTypes['TournamentBalancesVar_popFields']>,
    ParentType,
    ContextType
  >;
  varSamp?: Resolver<
    Maybe<ResolversTypes['TournamentBalancesVar_sampFields']>,
    ParentType,
    ContextType
  >;
  variance?: Resolver<
    Maybe<ResolversTypes['TournamentBalancesVarianceFields']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TournamentBalancesAvgFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TournamentBalancesAvgFields'] = ResolversParentTypes['TournamentBalancesAvgFields']
> = {
  balance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  currencyId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TournamentBalancesMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TournamentBalancesMaxFields'] = ResolversParentTypes['TournamentBalancesMaxFields']
> = {
  balance?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  currencyId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  eventId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tournamentId?: Resolver<
    Maybe<ResolversTypes['uuid']>,
    ParentType,
    ContextType
  >;
  venueId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TournamentBalancesMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TournamentBalancesMinFields'] = ResolversParentTypes['TournamentBalancesMinFields']
> = {
  balance?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  currencyId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  eventId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tournamentId?: Resolver<
    Maybe<ResolversTypes['uuid']>,
    ParentType,
    ContextType
  >;
  venueId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TournamentBalancesStddevFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TournamentBalancesStddevFields'] = ResolversParentTypes['TournamentBalancesStddevFields']
> = {
  balance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  currencyId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TournamentBalancesStddev_PopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TournamentBalancesStddev_popFields'] = ResolversParentTypes['TournamentBalancesStddev_popFields']
> = {
  balance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  currencyId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TournamentBalancesStddev_SampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TournamentBalancesStddev_sampFields'] = ResolversParentTypes['TournamentBalancesStddev_sampFields']
> = {
  balance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  currencyId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TournamentBalancesSumFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TournamentBalancesSumFields'] = ResolversParentTypes['TournamentBalancesSumFields']
> = {
  balance?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  currencyId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TournamentBalancesVar_PopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TournamentBalancesVar_popFields'] = ResolversParentTypes['TournamentBalancesVar_popFields']
> = {
  balance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  currencyId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TournamentBalancesVar_SampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TournamentBalancesVar_sampFields'] = ResolversParentTypes['TournamentBalancesVar_sampFields']
> = {
  balance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  currencyId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TournamentBalancesVarianceFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TournamentBalancesVarianceFields'] = ResolversParentTypes['TournamentBalancesVarianceFields']
> = {
  balance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  currencyId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TournamentResultStatusesResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TournamentResultStatuses'] = ResolversParentTypes['TournamentResultStatuses']
> = {
  description?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TournamentResultStatusesAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TournamentResultStatusesAggregate'] = ResolversParentTypes['TournamentResultStatusesAggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['TournamentResultStatusesAggregateFields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes['TournamentResultStatuses']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TournamentResultStatusesAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TournamentResultStatusesAggregateFields'] = ResolversParentTypes['TournamentResultStatusesAggregateFields']
> = {
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    Partial<TournamentResultStatusesAggregateFieldsCountArgs>
  >;
  max?: Resolver<
    Maybe<ResolversTypes['TournamentResultStatusesMaxFields']>,
    ParentType,
    ContextType
  >;
  min?: Resolver<
    Maybe<ResolversTypes['TournamentResultStatusesMinFields']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TournamentResultStatusesMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TournamentResultStatusesMaxFields'] = ResolversParentTypes['TournamentResultStatusesMaxFields']
> = {
  description?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TournamentResultStatusesMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TournamentResultStatusesMinFields'] = ResolversParentTypes['TournamentResultStatusesMinFields']
> = {
  description?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TournamentResultStatusesMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TournamentResultStatusesMutationResponse'] = ResolversParentTypes['TournamentResultStatusesMutationResponse']
> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<
    Array<ResolversTypes['TournamentResultStatuses']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TournamentResultsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TournamentResults'] = ResolversParentTypes['TournamentResults']
> = {
  bullets?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  place?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  player?: Resolver<Maybe<ResolversTypes['Players']>, ParentType, ContextType>;
  playerId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  playerPayout?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  playerSharesAggregate?: Resolver<
    ResolversTypes['PlayerSharesAggregate'],
    ParentType,
    ContextType,
    Partial<TournamentResultsPlayerSharesAggregateArgs>
  >;
  player_shares?: Resolver<
    Array<ResolversTypes['PlayerShares']>,
    ParentType,
    ContextType,
    Partial<TournamentResultsPlayer_SharesArgs>
  >;
  roi?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  stakedAmount?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  stakedPercentage?: Resolver<
    ResolversTypes['numeric'],
    ParentType,
    ContextType
  >;
  status?: Resolver<
    ResolversTypes['TournamentResultStatusesEnum'],
    ParentType,
    ContextType
  >;
  tickets?: Resolver<
    Array<ResolversTypes['Tickets']>,
    ParentType,
    ContextType,
    Partial<TournamentResultsTicketsArgs>
  >;
  ticketsAggregate?: Resolver<
    ResolversTypes['TicketsAggregate'],
    ParentType,
    ContextType,
    Partial<TournamentResultsTicketsAggregateArgs>
  >;
  toBePaid?: Resolver<
    Maybe<ResolversTypes['jsonb']>,
    ParentType,
    ContextType,
    Partial<TournamentResultsToBePaidArgs>
  >;
  tournament?: Resolver<ResolversTypes['Tournaments'], ParentType, ContextType>;
  tournamentId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['Users']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  winning?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TournamentResultsAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TournamentResultsAggregate'] = ResolversParentTypes['TournamentResultsAggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['TournamentResultsAggregateFields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes['TournamentResults']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TournamentResultsAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TournamentResultsAggregateFields'] = ResolversParentTypes['TournamentResultsAggregateFields']
> = {
  avg?: Resolver<
    Maybe<ResolversTypes['TournamentResultsAvgFields']>,
    ParentType,
    ContextType
  >;
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    Partial<TournamentResultsAggregateFieldsCountArgs>
  >;
  max?: Resolver<
    Maybe<ResolversTypes['TournamentResultsMaxFields']>,
    ParentType,
    ContextType
  >;
  min?: Resolver<
    Maybe<ResolversTypes['TournamentResultsMinFields']>,
    ParentType,
    ContextType
  >;
  stddev?: Resolver<
    Maybe<ResolversTypes['TournamentResultsStddevFields']>,
    ParentType,
    ContextType
  >;
  stddevPop?: Resolver<
    Maybe<ResolversTypes['TournamentResultsStddev_popFields']>,
    ParentType,
    ContextType
  >;
  stddevSamp?: Resolver<
    Maybe<ResolversTypes['TournamentResultsStddev_sampFields']>,
    ParentType,
    ContextType
  >;
  sum?: Resolver<
    Maybe<ResolversTypes['TournamentResultsSumFields']>,
    ParentType,
    ContextType
  >;
  varPop?: Resolver<
    Maybe<ResolversTypes['TournamentResultsVar_popFields']>,
    ParentType,
    ContextType
  >;
  varSamp?: Resolver<
    Maybe<ResolversTypes['TournamentResultsVar_sampFields']>,
    ParentType,
    ContextType
  >;
  variance?: Resolver<
    Maybe<ResolversTypes['TournamentResultsVarianceFields']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TournamentResultsAvgFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TournamentResultsAvgFields'] = ResolversParentTypes['TournamentResultsAvgFields']
> = {
  bullets?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  place?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  playerPayout?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  roi?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  stakedAmount?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  stakedPercentage?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  winning?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TournamentResultsMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TournamentResultsMaxFields'] = ResolversParentTypes['TournamentResultsMaxFields']
> = {
  bullets?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  createdAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  place?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  playerId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  playerPayout?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  roi?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  stakedAmount?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  stakedPercentage?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  tournamentId?: Resolver<
    Maybe<ResolversTypes['uuid']>,
    ParentType,
    ContextType
  >;
  updatedAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  winning?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TournamentResultsMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TournamentResultsMinFields'] = ResolversParentTypes['TournamentResultsMinFields']
> = {
  bullets?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  createdAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  place?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  playerId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  playerPayout?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  roi?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  stakedAmount?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  stakedPercentage?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  tournamentId?: Resolver<
    Maybe<ResolversTypes['uuid']>,
    ParentType,
    ContextType
  >;
  updatedAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  winning?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TournamentResultsMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TournamentResultsMutationResponse'] = ResolversParentTypes['TournamentResultsMutationResponse']
> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<
    Array<ResolversTypes['TournamentResults']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TournamentResultsStddevFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TournamentResultsStddevFields'] = ResolversParentTypes['TournamentResultsStddevFields']
> = {
  bullets?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  place?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  playerPayout?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  roi?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  stakedAmount?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  stakedPercentage?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  winning?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TournamentResultsStddev_PopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TournamentResultsStddev_popFields'] = ResolversParentTypes['TournamentResultsStddev_popFields']
> = {
  bullets?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  place?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  playerPayout?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  roi?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  stakedAmount?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  stakedPercentage?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  winning?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TournamentResultsStddev_SampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TournamentResultsStddev_sampFields'] = ResolversParentTypes['TournamentResultsStddev_sampFields']
> = {
  bullets?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  place?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  playerPayout?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  roi?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  stakedAmount?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  stakedPercentage?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  winning?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TournamentResultsSumFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TournamentResultsSumFields'] = ResolversParentTypes['TournamentResultsSumFields']
> = {
  bullets?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  place?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  playerPayout?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  roi?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  stakedAmount?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  stakedPercentage?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  winning?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TournamentResultsVar_PopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TournamentResultsVar_popFields'] = ResolversParentTypes['TournamentResultsVar_popFields']
> = {
  bullets?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  place?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  playerPayout?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  roi?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  stakedAmount?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  stakedPercentage?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  winning?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TournamentResultsVar_SampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TournamentResultsVar_sampFields'] = ResolversParentTypes['TournamentResultsVar_sampFields']
> = {
  bullets?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  place?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  playerPayout?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  roi?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  stakedAmount?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  stakedPercentage?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  winning?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TournamentResultsVarianceFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TournamentResultsVarianceFields'] = ResolversParentTypes['TournamentResultsVarianceFields']
> = {
  bullets?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  place?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  playerPayout?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  roi?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  stakedAmount?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  stakedPercentage?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  winning?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TournamentsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Tournaments'] = ResolversParentTypes['Tournaments']
> = {
  balances?: Resolver<
    Array<ResolversTypes['TournamentBalances']>,
    ParentType,
    ContextType,
    Partial<TournamentsBalancesArgs>
  >;
  balancesAggregate?: Resolver<
    ResolversTypes['TournamentBalancesAggregate'],
    ParentType,
    ContextType,
    Partial<TournamentsBalancesAggregateArgs>
  >;
  bulletsMax?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  buyIn?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  currency?: Resolver<ResolversTypes['Currencies'], ParentType, ContextType>;
  currencyId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  currencyRate?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  date?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  event?: Resolver<Maybe<ResolversTypes['Events']>, ParentType, ContextType>;
  eventId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  followers?: Resolver<
    Array<ResolversTypes['Followers']>,
    ParentType,
    ContextType,
    Partial<TournamentsFollowersArgs>
  >;
  followersAggregate?: Resolver<
    ResolversTypes['FollowersAggregate'],
    ParentType,
    ContextType,
    Partial<TournamentsFollowersAggregateArgs>
  >;
  guaranteed?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  image?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  localCurrencyId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  local_currency?: Resolver<
    ResolversTypes['Currencies'],
    ParentType,
    ContextType
  >;
  location?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  playerSharesAggregate?: Resolver<
    ResolversTypes['PlayerSharesAggregate'],
    ParentType,
    ContextType,
    Partial<TournamentsPlayerSharesAggregateArgs>
  >;
  player_shares?: Resolver<
    Array<ResolversTypes['PlayerShares']>,
    ParentType,
    ContextType,
    Partial<TournamentsPlayer_SharesArgs>
  >;
  registrationEnd?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  sharesMax?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sort?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['StatusGamesEnum'], ParentType, ContextType>;
  ticketSalesActive?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >;
  tickets?: Resolver<
    Array<ResolversTypes['Tickets']>,
    ParentType,
    ContextType,
    Partial<TournamentsTicketsArgs>
  >;
  ticketsAggregate?: Resolver<
    ResolversTypes['TicketsAggregate'],
    ParentType,
    ContextType,
    Partial<TournamentsTicketsAggregateArgs>
  >;
  tournamentResultsAggregate?: Resolver<
    ResolversTypes['TournamentResultsAggregate'],
    ParentType,
    ContextType,
    Partial<TournamentsTournamentResultsAggregateArgs>
  >;
  tournament_results?: Resolver<
    Array<ResolversTypes['TournamentResults']>,
    ParentType,
    ContextType,
    Partial<TournamentsTournament_ResultsArgs>
  >;
  transactions?: Resolver<
    Array<ResolversTypes['Transactions']>,
    ParentType,
    ContextType,
    Partial<TournamentsTransactionsArgs>
  >;
  transactionsAggregate?: Resolver<
    ResolversTypes['TransactionsAggregate'],
    ParentType,
    ContextType,
    Partial<TournamentsTransactionsAggregateArgs>
  >;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  venue?: Resolver<ResolversTypes['Venues'], ParentType, ContextType>;
  venueId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  watchUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TournamentsAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TournamentsAggregate'] = ResolversParentTypes['TournamentsAggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['TournamentsAggregateFields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes['Tournaments']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TournamentsAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TournamentsAggregateFields'] = ResolversParentTypes['TournamentsAggregateFields']
> = {
  avg?: Resolver<
    Maybe<ResolversTypes['TournamentsAvgFields']>,
    ParentType,
    ContextType
  >;
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    Partial<TournamentsAggregateFieldsCountArgs>
  >;
  max?: Resolver<
    Maybe<ResolversTypes['TournamentsMaxFields']>,
    ParentType,
    ContextType
  >;
  min?: Resolver<
    Maybe<ResolversTypes['TournamentsMinFields']>,
    ParentType,
    ContextType
  >;
  stddev?: Resolver<
    Maybe<ResolversTypes['TournamentsStddevFields']>,
    ParentType,
    ContextType
  >;
  stddevPop?: Resolver<
    Maybe<ResolversTypes['TournamentsStddev_popFields']>,
    ParentType,
    ContextType
  >;
  stddevSamp?: Resolver<
    Maybe<ResolversTypes['TournamentsStddev_sampFields']>,
    ParentType,
    ContextType
  >;
  sum?: Resolver<
    Maybe<ResolversTypes['TournamentsSumFields']>,
    ParentType,
    ContextType
  >;
  varPop?: Resolver<
    Maybe<ResolversTypes['TournamentsVar_popFields']>,
    ParentType,
    ContextType
  >;
  varSamp?: Resolver<
    Maybe<ResolversTypes['TournamentsVar_sampFields']>,
    ParentType,
    ContextType
  >;
  variance?: Resolver<
    Maybe<ResolversTypes['TournamentsVarianceFields']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TournamentsAvgFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TournamentsAvgFields'] = ResolversParentTypes['TournamentsAvgFields']
> = {
  bulletsMax?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  buyIn?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  currencyId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  currencyRate?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  guaranteed?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  localCurrencyId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  sharesMax?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sort?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TournamentsMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TournamentsMaxFields'] = ResolversParentTypes['TournamentsMaxFields']
> = {
  bulletsMax?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  buyIn?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  createdAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  currencyId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  currencyRate?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  date?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  eventId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  guaranteed?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  image?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  localCurrencyId?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  location?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  registrationEnd?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  sharesMax?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sort?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  venueId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  watchUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TournamentsMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TournamentsMinFields'] = ResolversParentTypes['TournamentsMinFields']
> = {
  bulletsMax?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  buyIn?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  createdAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  currencyId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  currencyRate?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  date?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  eventId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  guaranteed?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  image?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  localCurrencyId?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  location?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  registrationEnd?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  sharesMax?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sort?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  venueId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  watchUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TournamentsMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TournamentsMutationResponse'] = ResolversParentTypes['TournamentsMutationResponse']
> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<
    Array<ResolversTypes['Tournaments']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TournamentsStddevFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TournamentsStddevFields'] = ResolversParentTypes['TournamentsStddevFields']
> = {
  bulletsMax?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  buyIn?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  currencyId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  currencyRate?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  guaranteed?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  localCurrencyId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  sharesMax?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sort?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TournamentsStddev_PopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TournamentsStddev_popFields'] = ResolversParentTypes['TournamentsStddev_popFields']
> = {
  bulletsMax?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  buyIn?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  currencyId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  currencyRate?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  guaranteed?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  localCurrencyId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  sharesMax?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sort?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TournamentsStddev_SampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TournamentsStddev_sampFields'] = ResolversParentTypes['TournamentsStddev_sampFields']
> = {
  bulletsMax?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  buyIn?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  currencyId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  currencyRate?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  guaranteed?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  localCurrencyId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  sharesMax?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sort?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TournamentsSumFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TournamentsSumFields'] = ResolversParentTypes['TournamentsSumFields']
> = {
  bulletsMax?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  buyIn?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  currencyId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  currencyRate?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  guaranteed?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  localCurrencyId?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  sharesMax?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  sort?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TournamentsVar_PopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TournamentsVar_popFields'] = ResolversParentTypes['TournamentsVar_popFields']
> = {
  bulletsMax?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  buyIn?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  currencyId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  currencyRate?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  guaranteed?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  localCurrencyId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  sharesMax?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sort?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TournamentsVar_SampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TournamentsVar_sampFields'] = ResolversParentTypes['TournamentsVar_sampFields']
> = {
  bulletsMax?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  buyIn?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  currencyId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  currencyRate?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  guaranteed?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  localCurrencyId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  sharesMax?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sort?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TournamentsVarianceFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TournamentsVarianceFields'] = ResolversParentTypes['TournamentsVarianceFields']
> = {
  bulletsMax?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  buyIn?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  currencyId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  currencyRate?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  guaranteed?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  localCurrencyId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  sharesMax?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sort?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TransactionUserTypesResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TransactionUserTypes'] = ResolversParentTypes['TransactionUserTypes']
> = {
  description?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TransactionUserTypesAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TransactionUserTypesAggregate'] = ResolversParentTypes['TransactionUserTypesAggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['TransactionUserTypesAggregateFields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes['TransactionUserTypes']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TransactionUserTypesAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TransactionUserTypesAggregateFields'] = ResolversParentTypes['TransactionUserTypesAggregateFields']
> = {
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    Partial<TransactionUserTypesAggregateFieldsCountArgs>
  >;
  max?: Resolver<
    Maybe<ResolversTypes['TransactionUserTypesMaxFields']>,
    ParentType,
    ContextType
  >;
  min?: Resolver<
    Maybe<ResolversTypes['TransactionUserTypesMinFields']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TransactionUserTypesMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TransactionUserTypesMaxFields'] = ResolversParentTypes['TransactionUserTypesMaxFields']
> = {
  description?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TransactionUserTypesMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TransactionUserTypesMinFields'] = ResolversParentTypes['TransactionUserTypesMinFields']
> = {
  description?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TransactionUserTypesMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TransactionUserTypesMutationResponse'] = ResolversParentTypes['TransactionUserTypesMutationResponse']
> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<
    Array<ResolversTypes['TransactionUserTypes']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TransactionsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Transactions'] = ResolversParentTypes['Transactions']
> = {
  amount?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  bullet?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  createdBy?: Resolver<
    Maybe<ResolversTypes['TransactionUserTypesEnum']>,
    ParentType,
    ContextType
  >;
  currencyId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  description?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  log?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  payment?: Resolver<
    Maybe<ResolversTypes['Payments']>,
    ParentType,
    ContextType
  >;
  paymentId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  playerShareId?: Resolver<
    Maybe<ResolversTypes['uuid']>,
    ParentType,
    ContextType
  >;
  player_share?: Resolver<
    Maybe<ResolversTypes['PlayerShares']>,
    ParentType,
    ContextType
  >;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ticketId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  tournamentId?: Resolver<
    Maybe<ResolversTypes['uuid']>,
    ParentType,
    ContextType
  >;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  updatedBy?: Resolver<
    Maybe<ResolversTypes['TransactionUserTypesEnum']>,
    ParentType,
    ContextType
  >;
  user?: Resolver<Maybe<ResolversTypes['Users']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  userShareBuyId?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  user_share_buy?: Resolver<
    Maybe<ResolversTypes['UserShareBuys']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TransactionsAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TransactionsAggregate'] = ResolversParentTypes['TransactionsAggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['TransactionsAggregateFields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes['Transactions']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TransactionsAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TransactionsAggregateFields'] = ResolversParentTypes['TransactionsAggregateFields']
> = {
  avg?: Resolver<
    Maybe<ResolversTypes['TransactionsAvgFields']>,
    ParentType,
    ContextType
  >;
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    Partial<TransactionsAggregateFieldsCountArgs>
  >;
  max?: Resolver<
    Maybe<ResolversTypes['TransactionsMaxFields']>,
    ParentType,
    ContextType
  >;
  min?: Resolver<
    Maybe<ResolversTypes['TransactionsMinFields']>,
    ParentType,
    ContextType
  >;
  stddev?: Resolver<
    Maybe<ResolversTypes['TransactionsStddevFields']>,
    ParentType,
    ContextType
  >;
  stddevPop?: Resolver<
    Maybe<ResolversTypes['TransactionsStddev_popFields']>,
    ParentType,
    ContextType
  >;
  stddevSamp?: Resolver<
    Maybe<ResolversTypes['TransactionsStddev_sampFields']>,
    ParentType,
    ContextType
  >;
  sum?: Resolver<
    Maybe<ResolversTypes['TransactionsSumFields']>,
    ParentType,
    ContextType
  >;
  varPop?: Resolver<
    Maybe<ResolversTypes['TransactionsVar_popFields']>,
    ParentType,
    ContextType
  >;
  varSamp?: Resolver<
    Maybe<ResolversTypes['TransactionsVar_sampFields']>,
    ParentType,
    ContextType
  >;
  variance?: Resolver<
    Maybe<ResolversTypes['TransactionsVarianceFields']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TransactionsAvgFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TransactionsAvgFields'] = ResolversParentTypes['TransactionsAvgFields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  bullet?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  currencyId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  userShareBuyId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TransactionsMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TransactionsMaxFields'] = ResolversParentTypes['TransactionsMaxFields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  bullet?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  createdAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  currencyId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  description?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  log?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  paymentId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  playerShareId?: Resolver<
    Maybe<ResolversTypes['uuid']>,
    ParentType,
    ContextType
  >;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ticketId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  tournamentId?: Resolver<
    Maybe<ResolversTypes['uuid']>,
    ParentType,
    ContextType
  >;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  userShareBuyId?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TransactionsMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TransactionsMinFields'] = ResolversParentTypes['TransactionsMinFields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  bullet?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  createdAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  currencyId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  description?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  log?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  paymentId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  playerShareId?: Resolver<
    Maybe<ResolversTypes['uuid']>,
    ParentType,
    ContextType
  >;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ticketId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  tournamentId?: Resolver<
    Maybe<ResolversTypes['uuid']>,
    ParentType,
    ContextType
  >;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  userShareBuyId?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TransactionsMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TransactionsMutationResponse'] = ResolversParentTypes['TransactionsMutationResponse']
> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<
    Array<ResolversTypes['Transactions']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TransactionsStddevFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TransactionsStddevFields'] = ResolversParentTypes['TransactionsStddevFields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  bullet?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  currencyId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  userShareBuyId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TransactionsStddev_PopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TransactionsStddev_popFields'] = ResolversParentTypes['TransactionsStddev_popFields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  bullet?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  currencyId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  userShareBuyId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TransactionsStddev_SampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TransactionsStddev_sampFields'] = ResolversParentTypes['TransactionsStddev_sampFields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  bullet?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  currencyId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  userShareBuyId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TransactionsSumFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TransactionsSumFields'] = ResolversParentTypes['TransactionsSumFields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  bullet?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  currencyId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  userShareBuyId?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TransactionsVar_PopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TransactionsVar_popFields'] = ResolversParentTypes['TransactionsVar_popFields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  bullet?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  currencyId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  userShareBuyId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TransactionsVar_SampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TransactionsVar_sampFields'] = ResolversParentTypes['TransactionsVar_sampFields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  bullet?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  currencyId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  userShareBuyId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TransactionsVarianceFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TransactionsVarianceFields'] = ResolversParentTypes['TransactionsVarianceFields']
> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  bullet?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  currencyId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  userShareBuyId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserBalancesResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserBalances'] = ResolversParentTypes['UserBalances']
> = {
  balance?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  currencyId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserBalancesAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserBalancesAggregate'] = ResolversParentTypes['UserBalancesAggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['UserBalancesAggregateFields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes['UserBalances']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserBalancesAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserBalancesAggregateFields'] = ResolversParentTypes['UserBalancesAggregateFields']
> = {
  avg?: Resolver<
    Maybe<ResolversTypes['UserBalancesAvgFields']>,
    ParentType,
    ContextType
  >;
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    Partial<UserBalancesAggregateFieldsCountArgs>
  >;
  max?: Resolver<
    Maybe<ResolversTypes['UserBalancesMaxFields']>,
    ParentType,
    ContextType
  >;
  min?: Resolver<
    Maybe<ResolversTypes['UserBalancesMinFields']>,
    ParentType,
    ContextType
  >;
  stddev?: Resolver<
    Maybe<ResolversTypes['UserBalancesStddevFields']>,
    ParentType,
    ContextType
  >;
  stddevPop?: Resolver<
    Maybe<ResolversTypes['UserBalancesStddev_popFields']>,
    ParentType,
    ContextType
  >;
  stddevSamp?: Resolver<
    Maybe<ResolversTypes['UserBalancesStddev_sampFields']>,
    ParentType,
    ContextType
  >;
  sum?: Resolver<
    Maybe<ResolversTypes['UserBalancesSumFields']>,
    ParentType,
    ContextType
  >;
  varPop?: Resolver<
    Maybe<ResolversTypes['UserBalancesVar_popFields']>,
    ParentType,
    ContextType
  >;
  varSamp?: Resolver<
    Maybe<ResolversTypes['UserBalancesVar_sampFields']>,
    ParentType,
    ContextType
  >;
  variance?: Resolver<
    Maybe<ResolversTypes['UserBalancesVarianceFields']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserBalancesAvgFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserBalancesAvgFields'] = ResolversParentTypes['UserBalancesAvgFields']
> = {
  balance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  currencyId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserBalancesMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserBalancesMaxFields'] = ResolversParentTypes['UserBalancesMaxFields']
> = {
  balance?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  currencyId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserBalancesMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserBalancesMinFields'] = ResolversParentTypes['UserBalancesMinFields']
> = {
  balance?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  currencyId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserBalancesStddevFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserBalancesStddevFields'] = ResolversParentTypes['UserBalancesStddevFields']
> = {
  balance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  currencyId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserBalancesStddev_PopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserBalancesStddev_popFields'] = ResolversParentTypes['UserBalancesStddev_popFields']
> = {
  balance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  currencyId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserBalancesStddev_SampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserBalancesStddev_sampFields'] = ResolversParentTypes['UserBalancesStddev_sampFields']
> = {
  balance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  currencyId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserBalancesSumFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserBalancesSumFields'] = ResolversParentTypes['UserBalancesSumFields']
> = {
  balance?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  currencyId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserBalancesVar_PopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserBalancesVar_popFields'] = ResolversParentTypes['UserBalancesVar_popFields']
> = {
  balance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  currencyId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserBalancesVar_SampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserBalancesVar_sampFields'] = ResolversParentTypes['UserBalancesVar_sampFields']
> = {
  balance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  currencyId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserBalancesVarianceFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserBalancesVarianceFields'] = ResolversParentTypes['UserBalancesVarianceFields']
> = {
  balance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  currencyId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserOutput'] = ResolversParentTypes['UserOutput']
> = {
  avatar?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  country?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  erc20Address?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  language?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  organiserId?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  playerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  playerName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  playerSlug?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  playerStatus?: Resolver<
    Maybe<ResolversTypes['playerStatus']>,
    ParentType,
    ContextType
  >;
  provider?: Resolver<ResolversTypes['providerEnum'], ParentType, ContextType>;
  role?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['userStatus'], ParentType, ContextType>;
  twofa?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  username?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  venueId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserShareBuysResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserShareBuys'] = ResolversParentTypes['UserShareBuys']
> = {
  bulletsPlayed?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  markupAmount?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  playerShareId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  player_share?: Resolver<
    ResolversTypes['PlayerShares'],
    ParentType,
    ContextType
  >;
  shareAmount?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  sharePercentage?: Resolver<
    ResolversTypes['numeric'],
    ParentType,
    ContextType
  >;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  totalAmount?: Resolver<ResolversTypes['numeric'], ParentType, ContextType>;
  transactions?: Resolver<
    Array<ResolversTypes['Transactions']>,
    ParentType,
    ContextType,
    Partial<UserShareBuysTransactionsArgs>
  >;
  transactionsAggregate?: Resolver<
    ResolversTypes['TransactionsAggregate'],
    ParentType,
    ContextType,
    Partial<UserShareBuysTransactionsAggregateArgs>
  >;
  updatedAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['Users'], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  won?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserShareBuysAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserShareBuysAggregate'] = ResolversParentTypes['UserShareBuysAggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['UserShareBuysAggregateFields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes['UserShareBuys']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserShareBuysAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserShareBuysAggregateFields'] = ResolversParentTypes['UserShareBuysAggregateFields']
> = {
  avg?: Resolver<
    Maybe<ResolversTypes['UserShareBuysAvgFields']>,
    ParentType,
    ContextType
  >;
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    Partial<UserShareBuysAggregateFieldsCountArgs>
  >;
  max?: Resolver<
    Maybe<ResolversTypes['UserShareBuysMaxFields']>,
    ParentType,
    ContextType
  >;
  min?: Resolver<
    Maybe<ResolversTypes['UserShareBuysMinFields']>,
    ParentType,
    ContextType
  >;
  stddev?: Resolver<
    Maybe<ResolversTypes['UserShareBuysStddevFields']>,
    ParentType,
    ContextType
  >;
  stddevPop?: Resolver<
    Maybe<ResolversTypes['UserShareBuysStddev_popFields']>,
    ParentType,
    ContextType
  >;
  stddevSamp?: Resolver<
    Maybe<ResolversTypes['UserShareBuysStddev_sampFields']>,
    ParentType,
    ContextType
  >;
  sum?: Resolver<
    Maybe<ResolversTypes['UserShareBuysSumFields']>,
    ParentType,
    ContextType
  >;
  varPop?: Resolver<
    Maybe<ResolversTypes['UserShareBuysVar_popFields']>,
    ParentType,
    ContextType
  >;
  varSamp?: Resolver<
    Maybe<ResolversTypes['UserShareBuysVar_sampFields']>,
    ParentType,
    ContextType
  >;
  variance?: Resolver<
    Maybe<ResolversTypes['UserShareBuysVarianceFields']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserShareBuysAvgFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserShareBuysAvgFields'] = ResolversParentTypes['UserShareBuysAvgFields']
> = {
  bulletsPlayed?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  markupAmount?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  shareAmount?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  sharePercentage?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  totalAmount?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  won?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserShareBuysMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserShareBuysMaxFields'] = ResolversParentTypes['UserShareBuysMaxFields']
> = {
  bulletsPlayed?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  createdAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  markupAmount?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  playerShareId?: Resolver<
    Maybe<ResolversTypes['uuid']>,
    ParentType,
    ContextType
  >;
  shareAmount?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  sharePercentage?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  totalAmount?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  updatedAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  won?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserShareBuysMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserShareBuysMinFields'] = ResolversParentTypes['UserShareBuysMinFields']
> = {
  bulletsPlayed?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  createdAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  markupAmount?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  playerShareId?: Resolver<
    Maybe<ResolversTypes['uuid']>,
    ParentType,
    ContextType
  >;
  shareAmount?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  sharePercentage?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  totalAmount?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  updatedAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  won?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserShareBuysMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserShareBuysMutationResponse'] = ResolversParentTypes['UserShareBuysMutationResponse']
> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<
    Array<ResolversTypes['UserShareBuys']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserShareBuysStddevFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserShareBuysStddevFields'] = ResolversParentTypes['UserShareBuysStddevFields']
> = {
  bulletsPlayed?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  markupAmount?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  shareAmount?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  sharePercentage?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  totalAmount?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  won?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserShareBuysStddev_PopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserShareBuysStddev_popFields'] = ResolversParentTypes['UserShareBuysStddev_popFields']
> = {
  bulletsPlayed?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  markupAmount?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  shareAmount?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  sharePercentage?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  totalAmount?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  won?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserShareBuysStddev_SampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserShareBuysStddev_sampFields'] = ResolversParentTypes['UserShareBuysStddev_sampFields']
> = {
  bulletsPlayed?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  markupAmount?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  shareAmount?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  sharePercentage?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  totalAmount?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  won?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserShareBuysSumFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserShareBuysSumFields'] = ResolversParentTypes['UserShareBuysSumFields']
> = {
  bulletsPlayed?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  markupAmount?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  shareAmount?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  sharePercentage?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  totalAmount?: Resolver<
    Maybe<ResolversTypes['numeric']>,
    ParentType,
    ContextType
  >;
  won?: Resolver<Maybe<ResolversTypes['numeric']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserShareBuysVar_PopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserShareBuysVar_popFields'] = ResolversParentTypes['UserShareBuysVar_popFields']
> = {
  bulletsPlayed?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  markupAmount?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  shareAmount?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  sharePercentage?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  totalAmount?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  won?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserShareBuysVar_SampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserShareBuysVar_sampFields'] = ResolversParentTypes['UserShareBuysVar_sampFields']
> = {
  bulletsPlayed?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  markupAmount?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  shareAmount?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  sharePercentage?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  totalAmount?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  won?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserShareBuysVarianceFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserShareBuysVarianceFields'] = ResolversParentTypes['UserShareBuysVarianceFields']
> = {
  bulletsPlayed?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  markupAmount?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  shareAmount?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  sharePercentage?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  totalAmount?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  won?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Users'] = ResolversParentTypes['Users']
> = {
  avatar?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  country?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  deletedAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  erc20Address?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  language?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lastseenAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  metadata?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  players?: Resolver<
    Array<ResolversTypes['Players']>,
    ParentType,
    ContextType,
    Partial<UsersPlayersArgs>
  >;
  playersAggregate?: Resolver<
    ResolversTypes['PlayersAggregate'],
    ParentType,
    ContextType,
    Partial<UsersPlayersAggregateArgs>
  >;
  provider?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  signature?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  status?: Resolver<ResolversTypes['StatusesEnum'], ParentType, ContextType>;
  telegramId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  transactions?: Resolver<
    Array<ResolversTypes['Transactions']>,
    ParentType,
    ContextType,
    Partial<UsersTransactionsArgs>
  >;
  transactionsAggregate?: Resolver<
    ResolversTypes['TransactionsAggregate'],
    ParentType,
    ContextType,
    Partial<UsersTransactionsAggregateArgs>
  >;
  twofa?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  twofaBackup?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  twofaSecret?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  updatedAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  userBalancesAggregate?: Resolver<
    ResolversTypes['UserBalancesAggregate'],
    ParentType,
    ContextType,
    Partial<UsersUserBalancesAggregateArgs>
  >;
  userShareBuysAggregate?: Resolver<
    ResolversTypes['UserShareBuysAggregate'],
    ParentType,
    ContextType,
    Partial<UsersUserShareBuysAggregateArgs>
  >;
  user_balances?: Resolver<
    Array<ResolversTypes['UserBalances']>,
    ParentType,
    ContextType,
    Partial<UsersUser_BalancesArgs>
  >;
  user_share_buys?: Resolver<
    Array<ResolversTypes['UserShareBuys']>,
    ParentType,
    ContextType,
    Partial<UsersUser_Share_BuysArgs>
  >;
  username?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  venueUsersAggregate?: Resolver<
    ResolversTypes['VenueUsersAggregate'],
    ParentType,
    ContextType,
    Partial<UsersVenueUsersAggregateArgs>
  >;
  venue_users?: Resolver<
    Array<ResolversTypes['VenueUsers']>,
    ParentType,
    ContextType,
    Partial<UsersVenue_UsersArgs>
  >;
  verificationMail?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UsersAggregate'] = ResolversParentTypes['UsersAggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['UsersAggregateFields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<Array<ResolversTypes['Users']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UsersAggregateFields'] = ResolversParentTypes['UsersAggregateFields']
> = {
  avg?: Resolver<
    Maybe<ResolversTypes['UsersAvgFields']>,
    ParentType,
    ContextType
  >;
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    Partial<UsersAggregateFieldsCountArgs>
  >;
  max?: Resolver<
    Maybe<ResolversTypes['UsersMaxFields']>,
    ParentType,
    ContextType
  >;
  min?: Resolver<
    Maybe<ResolversTypes['UsersMinFields']>,
    ParentType,
    ContextType
  >;
  stddev?: Resolver<
    Maybe<ResolversTypes['UsersStddevFields']>,
    ParentType,
    ContextType
  >;
  stddevPop?: Resolver<
    Maybe<ResolversTypes['UsersStddev_popFields']>,
    ParentType,
    ContextType
  >;
  stddevSamp?: Resolver<
    Maybe<ResolversTypes['UsersStddev_sampFields']>,
    ParentType,
    ContextType
  >;
  sum?: Resolver<
    Maybe<ResolversTypes['UsersSumFields']>,
    ParentType,
    ContextType
  >;
  varPop?: Resolver<
    Maybe<ResolversTypes['UsersVar_popFields']>,
    ParentType,
    ContextType
  >;
  varSamp?: Resolver<
    Maybe<ResolversTypes['UsersVar_sampFields']>,
    ParentType,
    ContextType
  >;
  variance?: Resolver<
    Maybe<ResolversTypes['UsersVarianceFields']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersAvgFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UsersAvgFields'] = ResolversParentTypes['UsersAvgFields']
> = {
  telegramId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UsersMaxFields'] = ResolversParentTypes['UsersMaxFields']
> = {
  avatar?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  country?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  deletedAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  erc20Address?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  language?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastseenAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  metadata?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  provider?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  signature?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  telegramId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  twofaBackup?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  twofaSecret?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  updatedAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  username?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  verificationMail?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UsersMinFields'] = ResolversParentTypes['UsersMinFields']
> = {
  avatar?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  country?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  deletedAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  erc20Address?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  language?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastseenAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  metadata?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  provider?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  signature?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  telegramId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  twofaBackup?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  twofaSecret?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  updatedAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  username?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  verificationMail?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UsersMutationResponse'] = ResolversParentTypes['UsersMutationResponse']
> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<ResolversTypes['Users']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersStddevFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UsersStddevFields'] = ResolversParentTypes['UsersStddevFields']
> = {
  telegramId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersStddev_PopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UsersStddev_popFields'] = ResolversParentTypes['UsersStddev_popFields']
> = {
  telegramId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersStddev_SampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UsersStddev_sampFields'] = ResolversParentTypes['UsersStddev_sampFields']
> = {
  telegramId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersSumFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UsersSumFields'] = ResolversParentTypes['UsersSumFields']
> = {
  telegramId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersVar_PopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UsersVar_popFields'] = ResolversParentTypes['UsersVar_popFields']
> = {
  telegramId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersVar_SampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UsersVar_sampFields'] = ResolversParentTypes['UsersVar_sampFields']
> = {
  telegramId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersVarianceFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UsersVarianceFields'] = ResolversParentTypes['UsersVarianceFields']
> = {
  telegramId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VenueUsersResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['VenueUsers'] = ResolversParentTypes['VenueUsers']
> = {
  createdAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['bigint'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['Users'], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  venue?: Resolver<ResolversTypes['Venues'], ParentType, ContextType>;
  venueId?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VenueUsersAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['VenueUsersAggregate'] = ResolversParentTypes['VenueUsersAggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['VenueUsersAggregateFields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<
    Array<ResolversTypes['VenueUsers']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VenueUsersAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['VenueUsersAggregateFields'] = ResolversParentTypes['VenueUsersAggregateFields']
> = {
  avg?: Resolver<
    Maybe<ResolversTypes['VenueUsersAvgFields']>,
    ParentType,
    ContextType
  >;
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    Partial<VenueUsersAggregateFieldsCountArgs>
  >;
  max?: Resolver<
    Maybe<ResolversTypes['VenueUsersMaxFields']>,
    ParentType,
    ContextType
  >;
  min?: Resolver<
    Maybe<ResolversTypes['VenueUsersMinFields']>,
    ParentType,
    ContextType
  >;
  stddev?: Resolver<
    Maybe<ResolversTypes['VenueUsersStddevFields']>,
    ParentType,
    ContextType
  >;
  stddevPop?: Resolver<
    Maybe<ResolversTypes['VenueUsersStddev_popFields']>,
    ParentType,
    ContextType
  >;
  stddevSamp?: Resolver<
    Maybe<ResolversTypes['VenueUsersStddev_sampFields']>,
    ParentType,
    ContextType
  >;
  sum?: Resolver<
    Maybe<ResolversTypes['VenueUsersSumFields']>,
    ParentType,
    ContextType
  >;
  varPop?: Resolver<
    Maybe<ResolversTypes['VenueUsersVar_popFields']>,
    ParentType,
    ContextType
  >;
  varSamp?: Resolver<
    Maybe<ResolversTypes['VenueUsersVar_sampFields']>,
    ParentType,
    ContextType
  >;
  variance?: Resolver<
    Maybe<ResolversTypes['VenueUsersVarianceFields']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VenueUsersAvgFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['VenueUsersAvgFields'] = ResolversParentTypes['VenueUsersAvgFields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VenueUsersMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['VenueUsersMaxFields'] = ResolversParentTypes['VenueUsersMaxFields']
> = {
  createdAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  updatedAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  venueId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VenueUsersMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['VenueUsersMinFields'] = ResolversParentTypes['VenueUsersMinFields']
> = {
  createdAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  updatedAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  venueId?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VenueUsersMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['VenueUsersMutationResponse'] = ResolversParentTypes['VenueUsersMutationResponse']
> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<
    Array<ResolversTypes['VenueUsers']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VenueUsersStddevFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['VenueUsersStddevFields'] = ResolversParentTypes['VenueUsersStddevFields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VenueUsersStddev_PopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['VenueUsersStddev_popFields'] = ResolversParentTypes['VenueUsersStddev_popFields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VenueUsersStddev_SampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['VenueUsersStddev_sampFields'] = ResolversParentTypes['VenueUsersStddev_sampFields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VenueUsersSumFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['VenueUsersSumFields'] = ResolversParentTypes['VenueUsersSumFields']
> = {
  id?: Resolver<Maybe<ResolversTypes['bigint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VenueUsersVar_PopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['VenueUsersVar_popFields'] = ResolversParentTypes['VenueUsersVar_popFields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VenueUsersVar_SampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['VenueUsersVar_sampFields'] = ResolversParentTypes['VenueUsersVar_sampFields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VenueUsersVarianceFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['VenueUsersVarianceFields'] = ResolversParentTypes['VenueUsersVarianceFields']
> = {
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VenuesResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Venues'] = ResolversParentTypes['Venues']
> = {
  address?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  city?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  country?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  description?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  events?: Resolver<
    Array<ResolversTypes['Events']>,
    ParentType,
    ContextType,
    Partial<VenuesEventsArgs>
  >;
  eventsAggregate?: Resolver<
    ResolversTypes['EventsAggregate'],
    ParentType,
    ContextType,
    Partial<VenuesEventsAggregateArgs>
  >;
  facebook?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  followers?: Resolver<
    Array<ResolversTypes['Followers']>,
    ParentType,
    ContextType,
    Partial<VenuesFollowersArgs>
  >;
  followersAggregate?: Resolver<
    ResolversTypes['FollowersAggregate'],
    ParentType,
    ContextType,
    Partial<VenuesFollowersAggregateArgs>
  >;
  followersCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  googleMapsUrl?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<ResolversTypes['uuid'], ParentType, ContextType>;
  image?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  instagram?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  statsGoogleDrive?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  status?: Resolver<ResolversTypes['StatusesEnum'], ParentType, ContextType>;
  tournaments?: Resolver<
    Array<ResolversTypes['Tournaments']>,
    ParentType,
    ContextType,
    Partial<VenuesTournamentsArgs>
  >;
  tournamentsAggregate?: Resolver<
    ResolversTypes['TournamentsAggregate'],
    ParentType,
    ContextType,
    Partial<VenuesTournamentsAggregateArgs>
  >;
  updatedAt?: Resolver<ResolversTypes['timestamptz'], ParentType, ContextType>;
  venueUsersAggregate?: Resolver<
    ResolversTypes['VenueUsersAggregate'],
    ParentType,
    ContextType,
    Partial<VenuesVenueUsersAggregateArgs>
  >;
  venue_users?: Resolver<
    Array<ResolversTypes['VenueUsers']>,
    ParentType,
    ContextType,
    Partial<VenuesVenue_UsersArgs>
  >;
  website?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VenuesAggregateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['VenuesAggregate'] = ResolversParentTypes['VenuesAggregate']
> = {
  aggregate?: Resolver<
    Maybe<ResolversTypes['VenuesAggregateFields']>,
    ParentType,
    ContextType
  >;
  nodes?: Resolver<Array<ResolversTypes['Venues']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VenuesAggregateFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['VenuesAggregateFields'] = ResolversParentTypes['VenuesAggregateFields']
> = {
  avg?: Resolver<
    Maybe<ResolversTypes['VenuesAvgFields']>,
    ParentType,
    ContextType
  >;
  count?: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType,
    Partial<VenuesAggregateFieldsCountArgs>
  >;
  max?: Resolver<
    Maybe<ResolversTypes['VenuesMaxFields']>,
    ParentType,
    ContextType
  >;
  min?: Resolver<
    Maybe<ResolversTypes['VenuesMinFields']>,
    ParentType,
    ContextType
  >;
  stddev?: Resolver<
    Maybe<ResolversTypes['VenuesStddevFields']>,
    ParentType,
    ContextType
  >;
  stddevPop?: Resolver<
    Maybe<ResolversTypes['VenuesStddev_popFields']>,
    ParentType,
    ContextType
  >;
  stddevSamp?: Resolver<
    Maybe<ResolversTypes['VenuesStddev_sampFields']>,
    ParentType,
    ContextType
  >;
  sum?: Resolver<
    Maybe<ResolversTypes['VenuesSumFields']>,
    ParentType,
    ContextType
  >;
  varPop?: Resolver<
    Maybe<ResolversTypes['VenuesVar_popFields']>,
    ParentType,
    ContextType
  >;
  varSamp?: Resolver<
    Maybe<ResolversTypes['VenuesVar_sampFields']>,
    ParentType,
    ContextType
  >;
  variance?: Resolver<
    Maybe<ResolversTypes['VenuesVarianceFields']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VenuesAvgFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['VenuesAvgFields'] = ResolversParentTypes['VenuesAvgFields']
> = {
  followersCount?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VenuesMaxFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['VenuesMaxFields'] = ResolversParentTypes['VenuesMaxFields']
> = {
  address?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  city?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  country?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  description?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  facebook?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  followersCount?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  googleMapsUrl?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  image?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  instagram?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  statsGoogleDrive?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  updatedAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  website?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VenuesMinFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['VenuesMinFields'] = ResolversParentTypes['VenuesMinFields']
> = {
  address?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  city?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  country?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  description?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  facebook?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  followersCount?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  googleMapsUrl?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['uuid']>, ParentType, ContextType>;
  image?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  instagram?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  statsGoogleDrive?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  updatedAt?: Resolver<
    Maybe<ResolversTypes['timestamptz']>,
    ParentType,
    ContextType
  >;
  website?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VenuesMutationResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['VenuesMutationResponse'] = ResolversParentTypes['VenuesMutationResponse']
> = {
  affected_rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<
    Array<ResolversTypes['Venues']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VenuesStddevFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['VenuesStddevFields'] = ResolversParentTypes['VenuesStddevFields']
> = {
  followersCount?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VenuesStddev_PopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['VenuesStddev_popFields'] = ResolversParentTypes['VenuesStddev_popFields']
> = {
  followersCount?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VenuesStddev_SampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['VenuesStddev_sampFields'] = ResolversParentTypes['VenuesStddev_sampFields']
> = {
  followersCount?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VenuesSumFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['VenuesSumFields'] = ResolversParentTypes['VenuesSumFields']
> = {
  followersCount?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VenuesVar_PopFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['VenuesVar_popFields'] = ResolversParentTypes['VenuesVar_popFields']
> = {
  followersCount?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VenuesVar_SampFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['VenuesVar_sampFields'] = ResolversParentTypes['VenuesVar_sampFields']
> = {
  followersCount?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VenuesVarianceFieldsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['VenuesVarianceFields'] = ResolversParentTypes['VenuesVarianceFields']
> = {
  followersCount?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Activate2FaOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['activate2FAOutput'] = ResolversParentTypes['activate2FAOutput']
> = {
  backupCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  codeImage?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  codeUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['generalStatus'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AuthLoginOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['authLoginOutput'] = ResolversParentTypes['authLoginOutput']
> = {
  accesstoken?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  expiresIn?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  role?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AuthSignatureOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['authSignatureOutput'] = ResolversParentTypes['authSignatureOutput']
> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  nonce?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['generalStatus'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface BigintScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['bigint'], any> {
  name: 'bigint';
}

export interface DateScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['date'], any> {
  name: 'date';
}

export type DepositAddressOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['depositAddressOutput'] = ResolversParentTypes['depositAddressOutput']
> = {
  address?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DepositBankOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['depositBankOutput'] = ResolversParentTypes['depositBankOutput']
> = {
  depositDetails?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  depositReference?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DownloadFileOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['downloadFileOutput'] = ResolversParentTypes['downloadFileOutput']
> = {
  base64?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  filename?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  format?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['generalStatus'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GeneralOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['generalOutput'] = ResolversParentTypes['generalOutput']
> = {
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<
    Maybe<ResolversTypes['generalStatus']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface JsonbScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['jsonb'], any> {
  name: 'jsonb';
}

export type Mutation_RootResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['mutation_root'] = ResolversParentTypes['mutation_root']
> = {
  activate2FA1?: Resolver<
    Maybe<ResolversTypes['activate2FAOutput']>,
    ParentType,
    ContextType
  >;
  activate2FA2?: Resolver<
    Maybe<ResolversTypes['generalOutput']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootActivate2Fa2Args, 'code'>
  >;
  authLogin?: Resolver<
    Maybe<ResolversTypes['authLoginOutput']>,
    ParentType,
    ContextType,
    Partial<Mutation_RootAuthLoginArgs>
  >;
  authRefresh?: Resolver<
    Maybe<ResolversTypes['authLoginOutput']>,
    ParentType,
    ContextType,
    Partial<Mutation_RootAuthRefreshArgs>
  >;
  authSignature?: Resolver<
    Maybe<ResolversTypes['authSignatureOutput']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootAuthSignatureArgs, 'address'>
  >;
  buyTicket?: Resolver<
    Maybe<ResolversTypes['generalOutput']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootBuyTicketArgs, 'tournamentId'>
  >;
  cancelTicket?: Resolver<
    Maybe<ResolversTypes['generalOutput']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootCancelTicketArgs, 'ticketId'>
  >;
  cancelTournament?: Resolver<
    Maybe<ResolversTypes['generalOutput']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootCancelTournamentArgs, 'tournamentId'>
  >;
  deactivate2FA?: Resolver<
    Maybe<ResolversTypes['generalOutput']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeactivate2FaArgs, 'code'>
  >;
  deleteBlockchainCurrencies?: Resolver<
    Maybe<ResolversTypes['BlockchainCurrenciesMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteBlockchainCurrenciesArgs, 'where'>
  >;
  deleteBlockchainCurrenciesByPk?: Resolver<
    Maybe<ResolversTypes['BlockchainCurrencies']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteBlockchainCurrenciesByPkArgs, 'id'>
  >;
  deleteBlockchains?: Resolver<
    Maybe<ResolversTypes['BlockchainsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteBlockchainsArgs, 'where'>
  >;
  deleteBlockchainsByPk?: Resolver<
    Maybe<ResolversTypes['Blockchains']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteBlockchainsByPkArgs, 'id'>
  >;
  deleteCountries?: Resolver<
    Maybe<ResolversTypes['CountriesMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteCountriesArgs, 'where'>
  >;
  deleteCountriesByPk?: Resolver<
    Maybe<ResolversTypes['Countries']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteCountriesByPkArgs, 'id'>
  >;
  deleteCurrencies?: Resolver<
    Maybe<ResolversTypes['CurrenciesMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteCurrenciesArgs, 'where'>
  >;
  deleteCurrenciesByPk?: Resolver<
    Maybe<ResolversTypes['Currencies']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteCurrenciesByPkArgs, 'id'>
  >;
  deleteEvents?: Resolver<
    Maybe<ResolversTypes['EventsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteEventsArgs, 'where'>
  >;
  deleteEventsByPk?: Resolver<
    Maybe<ResolversTypes['Events']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteEventsByPkArgs, 'id'>
  >;
  deleteFollower?: Resolver<
    Maybe<ResolversTypes['Followers']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteFollowerArgs, 'id'>
  >;
  deleteFollowers?: Resolver<
    Maybe<ResolversTypes['FollowersMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteFollowersArgs, 'where'>
  >;
  deleteMessageTypes?: Resolver<
    Maybe<ResolversTypes['MessageTypesMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteMessageTypesArgs, 'where'>
  >;
  deleteMessageTypesByPk?: Resolver<
    Maybe<ResolversTypes['MessageTypes']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteMessageTypesByPkArgs, 'id'>
  >;
  deleteMessages?: Resolver<
    Maybe<ResolversTypes['MessagesMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteMessagesArgs, 'where'>
  >;
  deleteMessagesByPk?: Resolver<
    Maybe<ResolversTypes['Messages']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteMessagesByPkArgs, 'id'>
  >;
  deleteOrganiserUsers?: Resolver<
    Maybe<ResolversTypes['OrganiserUsersMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteOrganiserUsersArgs, 'where'>
  >;
  deleteOrganiserUsersByPk?: Resolver<
    Maybe<ResolversTypes['OrganiserUsers']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteOrganiserUsersByPkArgs, 'id'>
  >;
  deleteOrganisers?: Resolver<
    Maybe<ResolversTypes['OrganisersMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteOrganisersArgs, 'where'>
  >;
  deleteOrganisersByPk?: Resolver<
    Maybe<ResolversTypes['Organisers']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteOrganisersByPkArgs, 'id'>
  >;
  deletePaymentMethodBanks?: Resolver<
    Maybe<ResolversTypes['PaymentMethodBanksMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeletePaymentMethodBanksArgs, 'where'>
  >;
  deletePaymentMethodBanksByPk?: Resolver<
    Maybe<ResolversTypes['PaymentMethodBanks']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeletePaymentMethodBanksByPkArgs, 'id'>
  >;
  deletePaymentMethods?: Resolver<
    Maybe<ResolversTypes['PaymentMethodsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeletePaymentMethodsArgs, 'where'>
  >;
  deletePaymentMethodsByPk?: Resolver<
    Maybe<ResolversTypes['PaymentMethods']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeletePaymentMethodsByPkArgs, 'id'>
  >;
  deletePayments?: Resolver<
    Maybe<ResolversTypes['PaymentsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeletePaymentsArgs, 'where'>
  >;
  deletePaymentsByPk?: Resolver<
    Maybe<ResolversTypes['Payments']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeletePaymentsByPkArgs, 'id'>
  >;
  deletePendingTxs?: Resolver<
    Maybe<ResolversTypes['PendingTxsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeletePendingTxsArgs, 'where'>
  >;
  deletePendingTxsByPk?: Resolver<
    Maybe<ResolversTypes['PendingTxs']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeletePendingTxsByPkArgs, 'id'>
  >;
  deletePlayerFollowers?: Resolver<
    Maybe<ResolversTypes['PlayerFollowersMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeletePlayerFollowersArgs, 'where'>
  >;
  deletePlayerShare?: Resolver<
    Maybe<ResolversTypes['PlayerShares']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeletePlayerShareArgs, 'id'>
  >;
  deletePlayerShares?: Resolver<
    Maybe<ResolversTypes['PlayerSharesMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeletePlayerSharesArgs, 'where'>
  >;
  deletePlayers?: Resolver<
    Maybe<ResolversTypes['PlayersMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeletePlayersArgs, 'where'>
  >;
  deletePlayersByPk?: Resolver<
    Maybe<ResolversTypes['Players']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeletePlayersByPkArgs, 'id'>
  >;
  deleteStatEvents?: Resolver<
    Maybe<ResolversTypes['StatEventsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteStatEventsArgs, 'where'>
  >;
  deleteStatEventsByPk?: Resolver<
    Maybe<ResolversTypes['StatEvents']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteStatEventsByPkArgs, 'id'>
  >;
  deleteStatPlayers?: Resolver<
    Maybe<ResolversTypes['StatPlayersMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteStatPlayersArgs, 'where'>
  >;
  deleteStatPlayersByPk?: Resolver<
    Maybe<ResolversTypes['StatPlayers']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteStatPlayersByPkArgs, 'id'>
  >;
  deleteStatTournamentResults?: Resolver<
    Maybe<ResolversTypes['StatTournamentResultsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteStatTournamentResultsArgs, 'where'>
  >;
  deleteStatTournamentResultsByPk?: Resolver<
    Maybe<ResolversTypes['StatTournamentResults']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteStatTournamentResultsByPkArgs, 'id'>
  >;
  deleteStatTournaments?: Resolver<
    Maybe<ResolversTypes['StatTournamentsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteStatTournamentsArgs, 'where'>
  >;
  deleteStatTournamentsByPk?: Resolver<
    Maybe<ResolversTypes['StatTournaments']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteStatTournamentsByPkArgs, 'id'>
  >;
  deleteStatusGames?: Resolver<
    Maybe<ResolversTypes['StatusGamesMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteStatusGamesArgs, 'where'>
  >;
  deleteStatusGamesByPk?: Resolver<
    Maybe<ResolversTypes['StatusGames']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteStatusGamesByPkArgs, 'name'>
  >;
  deleteStatuses?: Resolver<
    Maybe<ResolversTypes['StatusesMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteStatusesArgs, 'where'>
  >;
  deleteStatusesByPk?: Resolver<
    Maybe<ResolversTypes['Statuses']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteStatusesByPkArgs, 'name'>
  >;
  deleteTickets?: Resolver<
    Maybe<ResolversTypes['TicketsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteTicketsArgs, 'where'>
  >;
  deleteTicketsByPk?: Resolver<
    Maybe<ResolversTypes['Tickets']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteTicketsByPkArgs, 'id'>
  >;
  deleteTicketsStatus?: Resolver<
    Maybe<ResolversTypes['TicketsStatusMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteTicketsStatusArgs, 'where'>
  >;
  deleteTicketsStatusByPk?: Resolver<
    Maybe<ResolversTypes['TicketsStatus']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteTicketsStatusByPkArgs, 'name'>
  >;
  deleteTournamentResultStatuses?: Resolver<
    Maybe<ResolversTypes['TournamentResultStatusesMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteTournamentResultStatusesArgs, 'where'>
  >;
  deleteTournamentResultStatusesByPk?: Resolver<
    Maybe<ResolversTypes['TournamentResultStatuses']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteTournamentResultStatusesByPkArgs, 'name'>
  >;
  deleteTournamentResults?: Resolver<
    Maybe<ResolversTypes['TournamentResultsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteTournamentResultsArgs, 'where'>
  >;
  deleteTournamentResultsByPk?: Resolver<
    Maybe<ResolversTypes['TournamentResults']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteTournamentResultsByPkArgs, 'id'>
  >;
  deleteTournaments?: Resolver<
    Maybe<ResolversTypes['TournamentsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteTournamentsArgs, 'where'>
  >;
  deleteTournamentsByPk?: Resolver<
    Maybe<ResolversTypes['Tournaments']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteTournamentsByPkArgs, 'id'>
  >;
  deleteTransactionUserTypes?: Resolver<
    Maybe<ResolversTypes['TransactionUserTypesMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteTransactionUserTypesArgs, 'where'>
  >;
  deleteTransactionUserTypesByPk?: Resolver<
    Maybe<ResolversTypes['TransactionUserTypes']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteTransactionUserTypesByPkArgs, 'name'>
  >;
  deleteTransactions?: Resolver<
    Maybe<ResolversTypes['TransactionsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteTransactionsArgs, 'where'>
  >;
  deleteTransactionsByPk?: Resolver<
    Maybe<ResolversTypes['Transactions']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteTransactionsByPkArgs, 'id'>
  >;
  deleteUserShareBuys?: Resolver<
    Maybe<ResolversTypes['UserShareBuysMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteUserShareBuysArgs, 'where'>
  >;
  deleteUserShareBuysByPk?: Resolver<
    Maybe<ResolversTypes['UserShareBuys']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteUserShareBuysByPkArgs, 'id'>
  >;
  deleteUsers?: Resolver<
    Maybe<ResolversTypes['UsersMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteUsersArgs, 'where'>
  >;
  deleteUsersByPk?: Resolver<
    Maybe<ResolversTypes['Users']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteUsersByPkArgs, 'id'>
  >;
  deleteVenue?: Resolver<
    Maybe<ResolversTypes['Venues']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteVenueArgs, 'id'>
  >;
  deleteVenueUsers?: Resolver<
    Maybe<ResolversTypes['VenueUsersMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteVenueUsersArgs, 'where'>
  >;
  deleteVenueUsersByPk?: Resolver<
    Maybe<ResolversTypes['VenueUsers']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteVenueUsersByPkArgs, 'id'>
  >;
  deleteVenues?: Resolver<
    Maybe<ResolversTypes['VenuesMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootDeleteVenuesArgs, 'where'>
  >;
  finishTournament?: Resolver<
    Maybe<ResolversTypes['generalOutput']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootFinishTournamentArgs, 'tournamentId'>
  >;
  followPlayer?: Resolver<
    Maybe<ResolversTypes['generalOutput']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootFollowPlayerArgs, 'playerId'>
  >;
  insertBlockchainCurrencies?: Resolver<
    Maybe<ResolversTypes['BlockchainCurrenciesMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertBlockchainCurrenciesArgs, 'objects'>
  >;
  insertBlockchainCurrenciesOne?: Resolver<
    Maybe<ResolversTypes['BlockchainCurrencies']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertBlockchainCurrenciesOneArgs, 'object'>
  >;
  insertBlockchains?: Resolver<
    Maybe<ResolversTypes['BlockchainsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertBlockchainsArgs, 'objects'>
  >;
  insertBlockchainsOne?: Resolver<
    Maybe<ResolversTypes['Blockchains']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertBlockchainsOneArgs, 'object'>
  >;
  insertCountries?: Resolver<
    Maybe<ResolversTypes['CountriesMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertCountriesArgs, 'objects'>
  >;
  insertCountriesOne?: Resolver<
    Maybe<ResolversTypes['Countries']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertCountriesOneArgs, 'object'>
  >;
  insertCurrencies?: Resolver<
    Maybe<ResolversTypes['CurrenciesMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertCurrenciesArgs, 'objects'>
  >;
  insertCurrenciesOne?: Resolver<
    Maybe<ResolversTypes['Currencies']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertCurrenciesOneArgs, 'object'>
  >;
  insertEvent?: Resolver<
    Maybe<ResolversTypes['Events']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertEventArgs, 'object'>
  >;
  insertEvents?: Resolver<
    Maybe<ResolversTypes['EventsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertEventsArgs, 'objects'>
  >;
  insertFollower?: Resolver<
    Maybe<ResolversTypes['Followers']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertFollowerArgs, 'object'>
  >;
  insertFollowers?: Resolver<
    Maybe<ResolversTypes['FollowersMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertFollowersArgs, 'objects'>
  >;
  insertMessageTypes?: Resolver<
    Maybe<ResolversTypes['MessageTypesMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertMessageTypesArgs, 'objects'>
  >;
  insertMessageTypesOne?: Resolver<
    Maybe<ResolversTypes['MessageTypes']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertMessageTypesOneArgs, 'object'>
  >;
  insertMessages?: Resolver<
    Maybe<ResolversTypes['MessagesMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertMessagesArgs, 'objects'>
  >;
  insertMessagesOne?: Resolver<
    Maybe<ResolversTypes['Messages']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertMessagesOneArgs, 'object'>
  >;
  insertOrganiserUsers?: Resolver<
    Maybe<ResolversTypes['OrganiserUsersMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertOrganiserUsersArgs, 'objects'>
  >;
  insertOrganiserUsersOne?: Resolver<
    Maybe<ResolversTypes['OrganiserUsers']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertOrganiserUsersOneArgs, 'object'>
  >;
  insertOrganisers?: Resolver<
    Maybe<ResolversTypes['OrganisersMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertOrganisersArgs, 'objects'>
  >;
  insertOrganisersOne?: Resolver<
    Maybe<ResolversTypes['Organisers']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertOrganisersOneArgs, 'object'>
  >;
  insertPaymentMethodBanks?: Resolver<
    Maybe<ResolversTypes['PaymentMethodBanksMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertPaymentMethodBanksArgs, 'objects'>
  >;
  insertPaymentMethodBanksOne?: Resolver<
    Maybe<ResolversTypes['PaymentMethodBanks']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertPaymentMethodBanksOneArgs, 'object'>
  >;
  insertPaymentMethods?: Resolver<
    Maybe<ResolversTypes['PaymentMethodsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertPaymentMethodsArgs, 'objects'>
  >;
  insertPaymentMethodsOne?: Resolver<
    Maybe<ResolversTypes['PaymentMethods']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertPaymentMethodsOneArgs, 'object'>
  >;
  insertPayments?: Resolver<
    Maybe<ResolversTypes['PaymentsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertPaymentsArgs, 'objects'>
  >;
  insertPaymentsOne?: Resolver<
    Maybe<ResolversTypes['Payments']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertPaymentsOneArgs, 'object'>
  >;
  insertPendingTxs?: Resolver<
    Maybe<ResolversTypes['PendingTxsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertPendingTxsArgs, 'objects'>
  >;
  insertPendingTxsOne?: Resolver<
    Maybe<ResolversTypes['PendingTxs']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertPendingTxsOneArgs, 'object'>
  >;
  insertPlayerFollowers?: Resolver<
    Maybe<ResolversTypes['PlayerFollowersMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertPlayerFollowersArgs, 'objects'>
  >;
  insertPlayerFollowersOne?: Resolver<
    Maybe<ResolversTypes['PlayerFollowers']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertPlayerFollowersOneArgs, 'object'>
  >;
  insertPlayerShare?: Resolver<
    Maybe<ResolversTypes['PlayerShares']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertPlayerShareArgs, 'object'>
  >;
  insertPlayerShares?: Resolver<
    Maybe<ResolversTypes['PlayerSharesMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertPlayerSharesArgs, 'objects'>
  >;
  insertPlayers?: Resolver<
    Maybe<ResolversTypes['PlayersMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertPlayersArgs, 'objects'>
  >;
  insertPlayersOne?: Resolver<
    Maybe<ResolversTypes['Players']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertPlayersOneArgs, 'object'>
  >;
  insertStatEvents?: Resolver<
    Maybe<ResolversTypes['StatEventsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertStatEventsArgs, 'objects'>
  >;
  insertStatEventsOne?: Resolver<
    Maybe<ResolversTypes['StatEvents']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertStatEventsOneArgs, 'object'>
  >;
  insertStatPlayers?: Resolver<
    Maybe<ResolversTypes['StatPlayersMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertStatPlayersArgs, 'objects'>
  >;
  insertStatPlayersOne?: Resolver<
    Maybe<ResolversTypes['StatPlayers']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertStatPlayersOneArgs, 'object'>
  >;
  insertStatTournamentResults?: Resolver<
    Maybe<ResolversTypes['StatTournamentResultsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertStatTournamentResultsArgs, 'objects'>
  >;
  insertStatTournamentResultsOne?: Resolver<
    Maybe<ResolversTypes['StatTournamentResults']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertStatTournamentResultsOneArgs, 'object'>
  >;
  insertStatTournaments?: Resolver<
    Maybe<ResolversTypes['StatTournamentsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertStatTournamentsArgs, 'objects'>
  >;
  insertStatTournamentsOne?: Resolver<
    Maybe<ResolversTypes['StatTournaments']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertStatTournamentsOneArgs, 'object'>
  >;
  insertStatusGames?: Resolver<
    Maybe<ResolversTypes['StatusGamesMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertStatusGamesArgs, 'objects'>
  >;
  insertStatusGamesOne?: Resolver<
    Maybe<ResolversTypes['StatusGames']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertStatusGamesOneArgs, 'object'>
  >;
  insertStatuses?: Resolver<
    Maybe<ResolversTypes['StatusesMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertStatusesArgs, 'objects'>
  >;
  insertStatusesOne?: Resolver<
    Maybe<ResolversTypes['Statuses']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertStatusesOneArgs, 'object'>
  >;
  insertTickets?: Resolver<
    Maybe<ResolversTypes['TicketsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertTicketsArgs, 'objects'>
  >;
  insertTicketsOne?: Resolver<
    Maybe<ResolversTypes['Tickets']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertTicketsOneArgs, 'object'>
  >;
  insertTicketsStatus?: Resolver<
    Maybe<ResolversTypes['TicketsStatusMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertTicketsStatusArgs, 'objects'>
  >;
  insertTicketsStatusOne?: Resolver<
    Maybe<ResolversTypes['TicketsStatus']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertTicketsStatusOneArgs, 'object'>
  >;
  insertTournament?: Resolver<
    Maybe<ResolversTypes['Tournaments']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertTournamentArgs, 'object'>
  >;
  insertTournamentResultStatuses?: Resolver<
    Maybe<ResolversTypes['TournamentResultStatusesMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertTournamentResultStatusesArgs, 'objects'>
  >;
  insertTournamentResultStatusesOne?: Resolver<
    Maybe<ResolversTypes['TournamentResultStatuses']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertTournamentResultStatusesOneArgs, 'object'>
  >;
  insertTournamentResults?: Resolver<
    Maybe<ResolversTypes['TournamentResultsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertTournamentResultsArgs, 'objects'>
  >;
  insertTournamentResultsOne?: Resolver<
    Maybe<ResolversTypes['TournamentResults']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertTournamentResultsOneArgs, 'object'>
  >;
  insertTournaments?: Resolver<
    Maybe<ResolversTypes['TournamentsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertTournamentsArgs, 'objects'>
  >;
  insertTransactionUserTypes?: Resolver<
    Maybe<ResolversTypes['TransactionUserTypesMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertTransactionUserTypesArgs, 'objects'>
  >;
  insertTransactionUserTypesOne?: Resolver<
    Maybe<ResolversTypes['TransactionUserTypes']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertTransactionUserTypesOneArgs, 'object'>
  >;
  insertTransactions?: Resolver<
    Maybe<ResolversTypes['TransactionsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertTransactionsArgs, 'objects'>
  >;
  insertTransactionsOne?: Resolver<
    Maybe<ResolversTypes['Transactions']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertTransactionsOneArgs, 'object'>
  >;
  insertUserShareBuys?: Resolver<
    Maybe<ResolversTypes['UserShareBuysMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertUserShareBuysArgs, 'objects'>
  >;
  insertUserShareBuysOne?: Resolver<
    Maybe<ResolversTypes['UserShareBuys']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertUserShareBuysOneArgs, 'object'>
  >;
  insertUsers?: Resolver<
    Maybe<ResolversTypes['UsersMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertUsersArgs, 'objects'>
  >;
  insertUsersOne?: Resolver<
    Maybe<ResolversTypes['Users']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertUsersOneArgs, 'object'>
  >;
  insertVenue?: Resolver<
    Maybe<ResolversTypes['Venues']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertVenueArgs, 'object'>
  >;
  insertVenueUsers?: Resolver<
    Maybe<ResolversTypes['VenueUsersMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertVenueUsersArgs, 'objects'>
  >;
  insertVenueUsersOne?: Resolver<
    Maybe<ResolversTypes['VenueUsers']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertVenueUsersOneArgs, 'object'>
  >;
  insertVenues?: Resolver<
    Maybe<ResolversTypes['VenuesMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootInsertVenuesArgs, 'objects'>
  >;
  linkERC20toFireBaseAccept?: Resolver<
    Maybe<ResolversTypes['generalOutput']>,
    ParentType,
    ContextType,
    Partial<Mutation_RootLinkErc20toFireBaseAcceptArgs>
  >;
  linkERC20toFireBaseSignature?: Resolver<
    Maybe<ResolversTypes['authSignatureOutput']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootLinkErc20toFireBaseSignatureArgs, 'address'>
  >;
  payoutTicket?: Resolver<
    Maybe<ResolversTypes['StatusOutput']>,
    ParentType,
    ContextType,
    RequireFields<
      Mutation_RootPayoutTicketArgs,
      'accountHolder' | 'accountNumber' | 'paymentMethodBankId' | 'ticketId'
    >
  >;
  playerShareResult?: Resolver<
    Maybe<ResolversTypes['playerShareResultOutput']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootPlayerShareResultArgs, 'action'>
  >;
  registerPlayer?: Resolver<
    Maybe<ResolversTypes['generalOutput']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootRegisterPlayerArgs, 'data'>
  >;
  sendVerifyMail?: Resolver<
    Maybe<ResolversTypes['generalOutput']>,
    ParentType,
    ContextType
  >;
  setEmailaddress?: Resolver<
    Maybe<ResolversTypes['generalOutput']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootSetEmailaddressArgs, 'email'>
  >;
  startTournament?: Resolver<
    Maybe<ResolversTypes['generalOutput']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootStartTournamentArgs, 'tournamentId'>
  >;
  submitBankDeposit?: Resolver<
    Maybe<ResolversTypes['StatusOutput']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootSubmitBankDepositArgs, 'id'>
  >;
  submitBankWithdraw?: Resolver<
    Maybe<ResolversTypes['StatusOutput']>,
    ParentType,
    ContextType,
    RequireFields<
      Mutation_RootSubmitBankWithdrawArgs,
      'accountHolder' | 'accountNumber' | 'amount' | 'paymentMethodBankId'
    >
  >;
  submitDeposit?: Resolver<
    Maybe<ResolversTypes['DepositOutput']>,
    ParentType,
    ContextType,
    RequireFields<
      Mutation_RootSubmitDepositArgs,
      'address' | 'amount' | 'blockchainCurrencyId' | 'blockchainId'
    >
  >;
  submitPlayerShareCancel?: Resolver<
    Maybe<ResolversTypes['generalOutput']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootSubmitPlayerShareCancelArgs, 'playerShareId'>
  >;
  submitPlayerShareCreate?: Resolver<
    Maybe<ResolversTypes['generalOutput']>,
    ParentType,
    ContextType,
    RequireFields<
      Mutation_RootSubmitPlayerShareCreateArgs,
      'bullets' | 'markUp' | 'shareDilution' | 'sharesToSell' | 'tournamentId'
    >
  >;
  submitShareBuy?: Resolver<
    Maybe<ResolversTypes['generalOutput']>,
    ParentType,
    ContextType,
    RequireFields<
      Mutation_RootSubmitShareBuyArgs,
      'percentage' | 'playerShareId'
    >
  >;
  submitShareCancel?: Resolver<
    Maybe<ResolversTypes['generalOutput']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootSubmitShareCancelArgs, 'userShareBuyId'>
  >;
  submitShareClaim?: Resolver<
    Maybe<ResolversTypes['generalOutput']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootSubmitShareClaimArgs, 'userShareBuyId'>
  >;
  submitWithdraw?: Resolver<
    Maybe<ResolversTypes['StatusOutput']>,
    ParentType,
    ContextType,
    RequireFields<
      Mutation_RootSubmitWithdrawArgs,
      'address' | 'amount' | 'blockchainCurrencyId' | 'blockchainId'
    >
  >;
  submittedDeposit?: Resolver<
    Maybe<ResolversTypes['StatusOutput']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootSubmittedDepositArgs, 'id' | 'tx'>
  >;
  unfollowPlayer?: Resolver<
    Maybe<ResolversTypes['generalOutput']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUnfollowPlayerArgs, 'playerId'>
  >;
  updateBlockchainCurrencies?: Resolver<
    Maybe<ResolversTypes['BlockchainCurrenciesMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateBlockchainCurrenciesArgs, 'where'>
  >;
  updateBlockchainCurrenciesByPk?: Resolver<
    Maybe<ResolversTypes['BlockchainCurrencies']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateBlockchainCurrenciesByPkArgs, 'pk_columns'>
  >;
  updateBlockchainCurrenciesMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['BlockchainCurrenciesMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateBlockchainCurrenciesManyArgs, 'updates'>
  >;
  updateBlockchains?: Resolver<
    Maybe<ResolversTypes['BlockchainsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateBlockchainsArgs, 'where'>
  >;
  updateBlockchainsByPk?: Resolver<
    Maybe<ResolversTypes['Blockchains']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateBlockchainsByPkArgs, 'pk_columns'>
  >;
  updateBlockchainsMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['BlockchainsMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateBlockchainsManyArgs, 'updates'>
  >;
  updateCountries?: Resolver<
    Maybe<ResolversTypes['CountriesMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateCountriesArgs, 'where'>
  >;
  updateCountriesByPk?: Resolver<
    Maybe<ResolversTypes['Countries']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateCountriesByPkArgs, 'pk_columns'>
  >;
  updateCountriesMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['CountriesMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateCountriesManyArgs, 'updates'>
  >;
  updateCurrencies?: Resolver<
    Maybe<ResolversTypes['CurrenciesMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateCurrenciesArgs, 'where'>
  >;
  updateCurrenciesByPk?: Resolver<
    Maybe<ResolversTypes['Currencies']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateCurrenciesByPkArgs, 'pk_columns'>
  >;
  updateCurrenciesMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['CurrenciesMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateCurrenciesManyArgs, 'updates'>
  >;
  updateEvent?: Resolver<
    Maybe<ResolversTypes['Events']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateEventArgs, 'pk_columns'>
  >;
  updateEvents?: Resolver<
    Maybe<ResolversTypes['EventsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateEventsArgs, 'where'>
  >;
  updateEventsMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['EventsMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateEventsManyArgs, 'updates'>
  >;
  updateFollower?: Resolver<
    Maybe<ResolversTypes['Followers']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateFollowerArgs, 'pk_columns'>
  >;
  updateFollowers?: Resolver<
    Maybe<ResolversTypes['FollowersMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateFollowersArgs, 'where'>
  >;
  updateManuVenues?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['VenuesMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateManuVenuesArgs, 'updates'>
  >;
  updateManyFollowers?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['FollowersMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateManyFollowersArgs, 'updates'>
  >;
  updateMessageTypes?: Resolver<
    Maybe<ResolversTypes['MessageTypesMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateMessageTypesArgs, 'where'>
  >;
  updateMessageTypesByPk?: Resolver<
    Maybe<ResolversTypes['MessageTypes']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateMessageTypesByPkArgs, 'pk_columns'>
  >;
  updateMessageTypesMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['MessageTypesMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateMessageTypesManyArgs, 'updates'>
  >;
  updateMessages?: Resolver<
    Maybe<ResolversTypes['MessagesMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateMessagesArgs, 'where'>
  >;
  updateMessagesByPk?: Resolver<
    Maybe<ResolversTypes['Messages']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateMessagesByPkArgs, 'pk_columns'>
  >;
  updateMessagesMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['MessagesMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateMessagesManyArgs, 'updates'>
  >;
  updateOrganiserUsers?: Resolver<
    Maybe<ResolversTypes['OrganiserUsersMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateOrganiserUsersArgs, 'where'>
  >;
  updateOrganiserUsersByPk?: Resolver<
    Maybe<ResolversTypes['OrganiserUsers']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateOrganiserUsersByPkArgs, 'pk_columns'>
  >;
  updateOrganiserUsersMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['OrganiserUsersMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateOrganiserUsersManyArgs, 'updates'>
  >;
  updateOrganisers?: Resolver<
    Maybe<ResolversTypes['OrganisersMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateOrganisersArgs, 'where'>
  >;
  updateOrganisersByPk?: Resolver<
    Maybe<ResolversTypes['Organisers']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateOrganisersByPkArgs, 'pk_columns'>
  >;
  updateOrganisersMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['OrganisersMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateOrganisersManyArgs, 'updates'>
  >;
  updatePaymentMethodBanks?: Resolver<
    Maybe<ResolversTypes['PaymentMethodBanksMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdatePaymentMethodBanksArgs, 'where'>
  >;
  updatePaymentMethodBanksByPk?: Resolver<
    Maybe<ResolversTypes['PaymentMethodBanks']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdatePaymentMethodBanksByPkArgs, 'pk_columns'>
  >;
  updatePaymentMethodBanksMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['PaymentMethodBanksMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdatePaymentMethodBanksManyArgs, 'updates'>
  >;
  updatePaymentMethods?: Resolver<
    Maybe<ResolversTypes['PaymentMethodsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdatePaymentMethodsArgs, 'where'>
  >;
  updatePaymentMethodsByPk?: Resolver<
    Maybe<ResolversTypes['PaymentMethods']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdatePaymentMethodsByPkArgs, 'pk_columns'>
  >;
  updatePaymentMethodsMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['PaymentMethodsMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdatePaymentMethodsManyArgs, 'updates'>
  >;
  updatePayments?: Resolver<
    Maybe<ResolversTypes['PaymentsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdatePaymentsArgs, 'where'>
  >;
  updatePaymentsByPk?: Resolver<
    Maybe<ResolversTypes['Payments']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdatePaymentsByPkArgs, 'pk_columns'>
  >;
  updatePaymentsMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['PaymentsMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdatePaymentsManyArgs, 'updates'>
  >;
  updatePendingTxs?: Resolver<
    Maybe<ResolversTypes['PendingTxsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdatePendingTxsArgs, 'where'>
  >;
  updatePendingTxsByPk?: Resolver<
    Maybe<ResolversTypes['PendingTxs']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdatePendingTxsByPkArgs, 'pk_columns'>
  >;
  updatePendingTxsMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['PendingTxsMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdatePendingTxsManyArgs, 'updates'>
  >;
  updatePlayer?: Resolver<
    Maybe<ResolversTypes['Players']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdatePlayerArgs, 'pk_columns'>
  >;
  updatePlayerFollowers?: Resolver<
    Maybe<ResolversTypes['PlayerFollowersMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdatePlayerFollowersArgs, 'where'>
  >;
  updatePlayerFollowersMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['PlayerFollowersMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdatePlayerFollowersManyArgs, 'updates'>
  >;
  updatePlayerShare?: Resolver<
    Maybe<ResolversTypes['PlayerShares']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdatePlayerShareArgs, 'pk_columns'>
  >;
  updatePlayerShares?: Resolver<
    Maybe<ResolversTypes['PlayerSharesMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdatePlayerSharesArgs, 'where'>
  >;
  updatePlayerSharesMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['PlayerSharesMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdatePlayerSharesManyArgs, 'updates'>
  >;
  updatePlayers?: Resolver<
    Maybe<ResolversTypes['PlayersMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdatePlayersArgs, 'where'>
  >;
  updatePlayersMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['PlayersMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdatePlayersManyArgs, 'updates'>
  >;
  updateStatEvents?: Resolver<
    Maybe<ResolversTypes['StatEventsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateStatEventsArgs, 'where'>
  >;
  updateStatEventsByPk?: Resolver<
    Maybe<ResolversTypes['StatEvents']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateStatEventsByPkArgs, 'pk_columns'>
  >;
  updateStatEventsMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['StatEventsMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateStatEventsManyArgs, 'updates'>
  >;
  updateStatPlayers?: Resolver<
    Maybe<ResolversTypes['StatPlayersMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateStatPlayersArgs, 'where'>
  >;
  updateStatPlayersByPk?: Resolver<
    Maybe<ResolversTypes['StatPlayers']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateStatPlayersByPkArgs, 'pk_columns'>
  >;
  updateStatPlayersMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['StatPlayersMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateStatPlayersManyArgs, 'updates'>
  >;
  updateStatTournamentResults?: Resolver<
    Maybe<ResolversTypes['StatTournamentResultsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateStatTournamentResultsArgs, 'where'>
  >;
  updateStatTournamentResultsByPk?: Resolver<
    Maybe<ResolversTypes['StatTournamentResults']>,
    ParentType,
    ContextType,
    RequireFields<
      Mutation_RootUpdateStatTournamentResultsByPkArgs,
      'pk_columns'
    >
  >;
  updateStatTournamentResultsMany?: Resolver<
    Maybe<
      Array<Maybe<ResolversTypes['StatTournamentResultsMutationResponse']>>
    >,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateStatTournamentResultsManyArgs, 'updates'>
  >;
  updateStatTournaments?: Resolver<
    Maybe<ResolversTypes['StatTournamentsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateStatTournamentsArgs, 'where'>
  >;
  updateStatTournamentsByPk?: Resolver<
    Maybe<ResolversTypes['StatTournaments']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateStatTournamentsByPkArgs, 'pk_columns'>
  >;
  updateStatTournamentsMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['StatTournamentsMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateStatTournamentsManyArgs, 'updates'>
  >;
  updateStatusGames?: Resolver<
    Maybe<ResolversTypes['StatusGamesMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateStatusGamesArgs, 'where'>
  >;
  updateStatusGamesByPk?: Resolver<
    Maybe<ResolversTypes['StatusGames']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateStatusGamesByPkArgs, 'pk_columns'>
  >;
  updateStatusGamesMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['StatusGamesMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateStatusGamesManyArgs, 'updates'>
  >;
  updateStatuses?: Resolver<
    Maybe<ResolversTypes['StatusesMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateStatusesArgs, 'where'>
  >;
  updateStatusesByPk?: Resolver<
    Maybe<ResolversTypes['Statuses']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateStatusesByPkArgs, 'pk_columns'>
  >;
  updateStatusesMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['StatusesMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateStatusesManyArgs, 'updates'>
  >;
  updateTickets?: Resolver<
    Maybe<ResolversTypes['TicketsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateTicketsArgs, 'where'>
  >;
  updateTicketsByPk?: Resolver<
    Maybe<ResolversTypes['Tickets']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateTicketsByPkArgs, 'pk_columns'>
  >;
  updateTicketsMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['TicketsMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateTicketsManyArgs, 'updates'>
  >;
  updateTicketsStatus?: Resolver<
    Maybe<ResolversTypes['TicketsStatusMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateTicketsStatusArgs, 'where'>
  >;
  updateTicketsStatusByPk?: Resolver<
    Maybe<ResolversTypes['TicketsStatus']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateTicketsStatusByPkArgs, 'pk_columns'>
  >;
  updateTicketsStatusMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['TicketsStatusMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateTicketsStatusManyArgs, 'updates'>
  >;
  updateTournament?: Resolver<
    Maybe<ResolversTypes['Tournaments']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateTournamentArgs, 'pk_columns'>
  >;
  updateTournamentResultStatuses?: Resolver<
    Maybe<ResolversTypes['TournamentResultStatusesMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateTournamentResultStatusesArgs, 'where'>
  >;
  updateTournamentResultStatusesByPk?: Resolver<
    Maybe<ResolversTypes['TournamentResultStatuses']>,
    ParentType,
    ContextType,
    RequireFields<
      Mutation_RootUpdateTournamentResultStatusesByPkArgs,
      'pk_columns'
    >
  >;
  updateTournamentResultStatusesMany?: Resolver<
    Maybe<
      Array<Maybe<ResolversTypes['TournamentResultStatusesMutationResponse']>>
    >,
    ParentType,
    ContextType,
    RequireFields<
      Mutation_RootUpdateTournamentResultStatusesManyArgs,
      'updates'
    >
  >;
  updateTournamentResults?: Resolver<
    Maybe<ResolversTypes['TournamentResultsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateTournamentResultsArgs, 'where'>
  >;
  updateTournamentResultsByPk?: Resolver<
    Maybe<ResolversTypes['TournamentResults']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateTournamentResultsByPkArgs, 'pk_columns'>
  >;
  updateTournamentResultsMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['TournamentResultsMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateTournamentResultsManyArgs, 'updates'>
  >;
  updateTournaments?: Resolver<
    Maybe<ResolversTypes['TournamentsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateTournamentsArgs, 'where'>
  >;
  updateTournamentsMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['TournamentsMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateTournamentsManyArgs, 'updates'>
  >;
  updateTransactionUserTypes?: Resolver<
    Maybe<ResolversTypes['TransactionUserTypesMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateTransactionUserTypesArgs, 'where'>
  >;
  updateTransactionUserTypesByPk?: Resolver<
    Maybe<ResolversTypes['TransactionUserTypes']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateTransactionUserTypesByPkArgs, 'pk_columns'>
  >;
  updateTransactionUserTypesMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['TransactionUserTypesMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateTransactionUserTypesManyArgs, 'updates'>
  >;
  updateTransactions?: Resolver<
    Maybe<ResolversTypes['TransactionsMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateTransactionsArgs, 'where'>
  >;
  updateTransactionsByPk?: Resolver<
    Maybe<ResolversTypes['Transactions']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateTransactionsByPkArgs, 'pk_columns'>
  >;
  updateTransactionsMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['TransactionsMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateTransactionsManyArgs, 'updates'>
  >;
  updateUserShareBuys?: Resolver<
    Maybe<ResolversTypes['UserShareBuysMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateUserShareBuysArgs, 'where'>
  >;
  updateUserShareBuysByPk?: Resolver<
    Maybe<ResolversTypes['UserShareBuys']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateUserShareBuysByPkArgs, 'pk_columns'>
  >;
  updateUserShareBuysMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['UserShareBuysMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateUserShareBuysManyArgs, 'updates'>
  >;
  updateUsers?: Resolver<
    Maybe<ResolversTypes['UsersMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateUsersArgs, 'where'>
  >;
  updateUsersByPk?: Resolver<
    Maybe<ResolversTypes['Users']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateUsersByPkArgs, 'pk_columns'>
  >;
  updateUsersMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['UsersMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateUsersManyArgs, 'updates'>
  >;
  updateVenue?: Resolver<
    Maybe<ResolversTypes['Venues']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateVenueArgs, 'pk_columns'>
  >;
  updateVenueUsers?: Resolver<
    Maybe<ResolversTypes['VenueUsersMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateVenueUsersArgs, 'where'>
  >;
  updateVenueUsersByPk?: Resolver<
    Maybe<ResolversTypes['VenueUsers']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateVenueUsersByPkArgs, 'pk_columns'>
  >;
  updateVenueUsersMany?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['VenueUsersMutationResponse']>>>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateVenueUsersManyArgs, 'updates'>
  >;
  updateVenues?: Resolver<
    Maybe<ResolversTypes['VenuesMutationResponse']>,
    ParentType,
    ContextType,
    RequireFields<Mutation_RootUpdateVenuesArgs, 'where'>
  >;
};

export interface NumericScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['numeric'], any> {
  name: 'numeric';
}

export type PlayerShareResultOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['playerShareResultOutput'] = ResolversParentTypes['playerShareResultOutput']
> = {
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  playerPayout?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  stake3Payout?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  stake3PayoutUSD?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  status?: Resolver<
    Maybe<ResolversTypes['generalStatus']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Query_RootResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['query_root'] = ResolversParentTypes['query_root']
> = {
  blockchainCurrencies?: Resolver<
    Array<ResolversTypes['BlockchainCurrencies']>,
    ParentType,
    ContextType,
    Partial<Query_RootBlockchainCurrenciesArgs>
  >;
  blockchainCurrenciesAggregate?: Resolver<
    ResolversTypes['BlockchainCurrenciesAggregate'],
    ParentType,
    ContextType,
    Partial<Query_RootBlockchainCurrenciesAggregateArgs>
  >;
  blockchainCurrenciesByPk?: Resolver<
    Maybe<ResolversTypes['BlockchainCurrencies']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootBlockchainCurrenciesByPkArgs, 'id'>
  >;
  blockchains?: Resolver<
    Array<ResolversTypes['Blockchains']>,
    ParentType,
    ContextType,
    Partial<Query_RootBlockchainsArgs>
  >;
  blockchainsAggregate?: Resolver<
    ResolversTypes['BlockchainsAggregate'],
    ParentType,
    ContextType,
    Partial<Query_RootBlockchainsAggregateArgs>
  >;
  blockchainsByPk?: Resolver<
    Maybe<ResolversTypes['Blockchains']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootBlockchainsByPkArgs, 'id'>
  >;
  countries?: Resolver<
    Array<ResolversTypes['Countries']>,
    ParentType,
    ContextType,
    Partial<Query_RootCountriesArgs>
  >;
  countriesAggregate?: Resolver<
    ResolversTypes['CountriesAggregate'],
    ParentType,
    ContextType,
    Partial<Query_RootCountriesAggregateArgs>
  >;
  countriesByPk?: Resolver<
    Maybe<ResolversTypes['Countries']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootCountriesByPkArgs, 'id'>
  >;
  currencies?: Resolver<
    Array<ResolversTypes['Currencies']>,
    ParentType,
    ContextType,
    Partial<Query_RootCurrenciesArgs>
  >;
  currenciesAggregate?: Resolver<
    ResolversTypes['CurrenciesAggregate'],
    ParentType,
    ContextType,
    Partial<Query_RootCurrenciesAggregateArgs>
  >;
  currenciesByPk?: Resolver<
    Maybe<ResolversTypes['Currencies']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootCurrenciesByPkArgs, 'id'>
  >;
  downloadEventTransactions?: Resolver<
    Maybe<ResolversTypes['downloadFileOutput']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootDownloadEventTransactionsArgs, 'eventId'>
  >;
  event?: Resolver<
    Maybe<ResolversTypes['Events']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootEventArgs, 'id'>
  >;
  events?: Resolver<
    Array<ResolversTypes['Events']>,
    ParentType,
    ContextType,
    Partial<Query_RootEventsArgs>
  >;
  eventsAggregate?: Resolver<
    ResolversTypes['EventsAggregate'],
    ParentType,
    ContextType,
    Partial<Query_RootEventsAggregateArgs>
  >;
  follower?: Resolver<
    Maybe<ResolversTypes['Followers']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootFollowerArgs, 'id'>
  >;
  followers?: Resolver<
    Array<ResolversTypes['Followers']>,
    ParentType,
    ContextType,
    Partial<Query_RootFollowersArgs>
  >;
  followersAggregate?: Resolver<
    ResolversTypes['FollowersAggregate'],
    ParentType,
    ContextType,
    Partial<Query_RootFollowersAggregateArgs>
  >;
  getDepositAddress?: Resolver<
    Maybe<ResolversTypes['depositAddressOutput']>,
    ParentType,
    ContextType
  >;
  getDepositBankInfo?: Resolver<
    Maybe<ResolversTypes['depositBankOutput']>,
    ParentType,
    ContextType,
    RequireFields<
      Query_RootGetDepositBankInfoArgs,
      'amount' | 'paymentMethodBankId'
    >
  >;
  me?: Resolver<Maybe<ResolversTypes['UserOutput']>, ParentType, ContextType>;
  messageTypes?: Resolver<
    Array<ResolversTypes['MessageTypes']>,
    ParentType,
    ContextType,
    Partial<Query_RootMessageTypesArgs>
  >;
  messageTypesAggregate?: Resolver<
    ResolversTypes['MessageTypesAggregate'],
    ParentType,
    ContextType,
    Partial<Query_RootMessageTypesAggregateArgs>
  >;
  messageTypesByPk?: Resolver<
    Maybe<ResolversTypes['MessageTypes']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootMessageTypesByPkArgs, 'id'>
  >;
  messages?: Resolver<
    Array<ResolversTypes['Messages']>,
    ParentType,
    ContextType,
    Partial<Query_RootMessagesArgs>
  >;
  messagesAggregate?: Resolver<
    ResolversTypes['MessagesAggregate'],
    ParentType,
    ContextType,
    Partial<Query_RootMessagesAggregateArgs>
  >;
  messagesByPk?: Resolver<
    Maybe<ResolversTypes['Messages']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootMessagesByPkArgs, 'id'>
  >;
  organiserUsers?: Resolver<
    Array<ResolversTypes['OrganiserUsers']>,
    ParentType,
    ContextType,
    Partial<Query_RootOrganiserUsersArgs>
  >;
  organiserUsersAggregate?: Resolver<
    ResolversTypes['OrganiserUsersAggregate'],
    ParentType,
    ContextType,
    Partial<Query_RootOrganiserUsersAggregateArgs>
  >;
  organiserUsersByPk?: Resolver<
    Maybe<ResolversTypes['OrganiserUsers']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootOrganiserUsersByPkArgs, 'id'>
  >;
  organisers?: Resolver<
    Array<ResolversTypes['Organisers']>,
    ParentType,
    ContextType,
    Partial<Query_RootOrganisersArgs>
  >;
  organisersAggregate?: Resolver<
    ResolversTypes['OrganisersAggregate'],
    ParentType,
    ContextType,
    Partial<Query_RootOrganisersAggregateArgs>
  >;
  organisersByPk?: Resolver<
    Maybe<ResolversTypes['Organisers']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootOrganisersByPkArgs, 'id'>
  >;
  paymentMethodBanks?: Resolver<
    Array<ResolversTypes['PaymentMethodBanks']>,
    ParentType,
    ContextType,
    Partial<Query_RootPaymentMethodBanksArgs>
  >;
  paymentMethodBanksAggregate?: Resolver<
    ResolversTypes['PaymentMethodBanksAggregate'],
    ParentType,
    ContextType,
    Partial<Query_RootPaymentMethodBanksAggregateArgs>
  >;
  paymentMethodBanksByPk?: Resolver<
    Maybe<ResolversTypes['PaymentMethodBanks']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootPaymentMethodBanksByPkArgs, 'id'>
  >;
  paymentMethods?: Resolver<
    Array<ResolversTypes['PaymentMethods']>,
    ParentType,
    ContextType,
    Partial<Query_RootPaymentMethodsArgs>
  >;
  paymentMethodsAggregate?: Resolver<
    ResolversTypes['PaymentMethodsAggregate'],
    ParentType,
    ContextType,
    Partial<Query_RootPaymentMethodsAggregateArgs>
  >;
  paymentMethodsByPk?: Resolver<
    Maybe<ResolversTypes['PaymentMethods']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootPaymentMethodsByPkArgs, 'id'>
  >;
  payments?: Resolver<
    Array<ResolversTypes['Payments']>,
    ParentType,
    ContextType,
    Partial<Query_RootPaymentsArgs>
  >;
  paymentsAggregate?: Resolver<
    ResolversTypes['PaymentsAggregate'],
    ParentType,
    ContextType,
    Partial<Query_RootPaymentsAggregateArgs>
  >;
  paymentsByPk?: Resolver<
    Maybe<ResolversTypes['Payments']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootPaymentsByPkArgs, 'id'>
  >;
  pendingTxs?: Resolver<
    Array<ResolversTypes['PendingTxs']>,
    ParentType,
    ContextType,
    Partial<Query_RootPendingTxsArgs>
  >;
  pendingTxsAggregate?: Resolver<
    ResolversTypes['PendingTxsAggregate'],
    ParentType,
    ContextType,
    Partial<Query_RootPendingTxsAggregateArgs>
  >;
  pendingTxsByPk?: Resolver<
    Maybe<ResolversTypes['PendingTxs']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootPendingTxsByPkArgs, 'id'>
  >;
  player?: Resolver<
    Maybe<ResolversTypes['Players']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootPlayerArgs, 'id'>
  >;
  playerFollowers?: Resolver<
    Array<ResolversTypes['PlayerFollowers']>,
    ParentType,
    ContextType,
    Partial<Query_RootPlayerFollowersArgs>
  >;
  playerFollowersAggregate?: Resolver<
    ResolversTypes['PlayerFollowersAggregate'],
    ParentType,
    ContextType,
    Partial<Query_RootPlayerFollowersAggregateArgs>
  >;
  playerFollowersView?: Resolver<
    Array<ResolversTypes['PlayerFollowersView']>,
    ParentType,
    ContextType,
    Partial<Query_RootPlayerFollowersViewArgs>
  >;
  playerFollowersViewAggregate?: Resolver<
    ResolversTypes['PlayerFollowersViewAggregate'],
    ParentType,
    ContextType,
    Partial<Query_RootPlayerFollowersViewAggregateArgs>
  >;
  playerShare?: Resolver<
    Maybe<ResolversTypes['PlayerShares']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootPlayerShareArgs, 'id'>
  >;
  playerShares?: Resolver<
    Array<ResolversTypes['PlayerShares']>,
    ParentType,
    ContextType,
    Partial<Query_RootPlayerSharesArgs>
  >;
  playerSharesAggregate?: Resolver<
    ResolversTypes['PlayerSharesAggregate'],
    ParentType,
    ContextType,
    Partial<Query_RootPlayerSharesAggregateArgs>
  >;
  playerSharesBought?: Resolver<
    Array<ResolversTypes['PlayerSharesBought']>,
    ParentType,
    ContextType,
    Partial<Query_RootPlayerSharesBoughtArgs>
  >;
  playerSharesBoughtAggregate?: Resolver<
    ResolversTypes['PlayerSharesBoughtAggregate'],
    ParentType,
    ContextType,
    Partial<Query_RootPlayerSharesBoughtAggregateArgs>
  >;
  players?: Resolver<
    Array<ResolversTypes['Players']>,
    ParentType,
    ContextType,
    Partial<Query_RootPlayersArgs>
  >;
  playersAggregate?: Resolver<
    ResolversTypes['PlayersAggregate'],
    ParentType,
    ContextType,
    Partial<Query_RootPlayersAggregateArgs>
  >;
  statEvents?: Resolver<
    Array<ResolversTypes['StatEvents']>,
    ParentType,
    ContextType,
    Partial<Query_RootStatEventsArgs>
  >;
  statEventsAggregate?: Resolver<
    ResolversTypes['StatEventsAggregate'],
    ParentType,
    ContextType,
    Partial<Query_RootStatEventsAggregateArgs>
  >;
  statEventsByPk?: Resolver<
    Maybe<ResolversTypes['StatEvents']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootStatEventsByPkArgs, 'id'>
  >;
  statPlayers?: Resolver<
    Array<ResolversTypes['StatPlayers']>,
    ParentType,
    ContextType,
    Partial<Query_RootStatPlayersArgs>
  >;
  statPlayersAggregate?: Resolver<
    ResolversTypes['StatPlayersAggregate'],
    ParentType,
    ContextType,
    Partial<Query_RootStatPlayersAggregateArgs>
  >;
  statPlayersByPk?: Resolver<
    Maybe<ResolversTypes['StatPlayers']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootStatPlayersByPkArgs, 'id'>
  >;
  statTopPlayers?: Resolver<
    Array<ResolversTypes['StatTopPlayers']>,
    ParentType,
    ContextType,
    Partial<Query_RootStatTopPlayersArgs>
  >;
  statTopPlayersAggregate?: Resolver<
    ResolversTypes['StatTopPlayersAggregate'],
    ParentType,
    ContextType,
    Partial<Query_RootStatTopPlayersAggregateArgs>
  >;
  statTopYearPlayers?: Resolver<
    Array<ResolversTypes['StatTopYearPlayers']>,
    ParentType,
    ContextType,
    Partial<Query_RootStatTopYearPlayersArgs>
  >;
  statTopYearPlayersAggregate?: Resolver<
    ResolversTypes['StatTopYearPlayersAggregate'],
    ParentType,
    ContextType,
    Partial<Query_RootStatTopYearPlayersAggregateArgs>
  >;
  statTournamentResults?: Resolver<
    Array<ResolversTypes['StatTournamentResults']>,
    ParentType,
    ContextType,
    Partial<Query_RootStatTournamentResultsArgs>
  >;
  statTournamentResultsAggregate?: Resolver<
    ResolversTypes['StatTournamentResultsAggregate'],
    ParentType,
    ContextType,
    Partial<Query_RootStatTournamentResultsAggregateArgs>
  >;
  statTournamentResultsByPk?: Resolver<
    Maybe<ResolversTypes['StatTournamentResults']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootStatTournamentResultsByPkArgs, 'id'>
  >;
  statTournaments?: Resolver<
    Array<ResolversTypes['StatTournaments']>,
    ParentType,
    ContextType,
    Partial<Query_RootStatTournamentsArgs>
  >;
  statTournamentsAggregate?: Resolver<
    ResolversTypes['StatTournamentsAggregate'],
    ParentType,
    ContextType,
    Partial<Query_RootStatTournamentsAggregateArgs>
  >;
  statTournamentsByPk?: Resolver<
    Maybe<ResolversTypes['StatTournaments']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootStatTournamentsByPkArgs, 'id'>
  >;
  statusGames?: Resolver<
    Array<ResolversTypes['StatusGames']>,
    ParentType,
    ContextType,
    Partial<Query_RootStatusGamesArgs>
  >;
  statusGamesAggregate?: Resolver<
    ResolversTypes['StatusGamesAggregate'],
    ParentType,
    ContextType,
    Partial<Query_RootStatusGamesAggregateArgs>
  >;
  statusGamesByPk?: Resolver<
    Maybe<ResolversTypes['StatusGames']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootStatusGamesByPkArgs, 'name'>
  >;
  statuses?: Resolver<
    Array<ResolversTypes['Statuses']>,
    ParentType,
    ContextType,
    Partial<Query_RootStatusesArgs>
  >;
  statusesAggregate?: Resolver<
    ResolversTypes['StatusesAggregate'],
    ParentType,
    ContextType,
    Partial<Query_RootStatusesAggregateArgs>
  >;
  statusesByPk?: Resolver<
    Maybe<ResolversTypes['Statuses']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootStatusesByPkArgs, 'name'>
  >;
  tickets?: Resolver<
    Array<ResolversTypes['Tickets']>,
    ParentType,
    ContextType,
    Partial<Query_RootTicketsArgs>
  >;
  ticketsAggregate?: Resolver<
    ResolversTypes['TicketsAggregate'],
    ParentType,
    ContextType,
    Partial<Query_RootTicketsAggregateArgs>
  >;
  ticketsByPk?: Resolver<
    Maybe<ResolversTypes['Tickets']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootTicketsByPkArgs, 'id'>
  >;
  ticketsStatus?: Resolver<
    Array<ResolversTypes['TicketsStatus']>,
    ParentType,
    ContextType,
    Partial<Query_RootTicketsStatusArgs>
  >;
  ticketsStatusAggregate?: Resolver<
    ResolversTypes['TicketsStatusAggregate'],
    ParentType,
    ContextType,
    Partial<Query_RootTicketsStatusAggregateArgs>
  >;
  ticketsStatusByPk?: Resolver<
    Maybe<ResolversTypes['TicketsStatus']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootTicketsStatusByPkArgs, 'name'>
  >;
  tournament?: Resolver<
    Maybe<ResolversTypes['Tournaments']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootTournamentArgs, 'id'>
  >;
  tournamentBalances?: Resolver<
    Array<ResolversTypes['TournamentBalances']>,
    ParentType,
    ContextType,
    Partial<Query_RootTournamentBalancesArgs>
  >;
  tournamentBalancesAggregate?: Resolver<
    ResolversTypes['TournamentBalancesAggregate'],
    ParentType,
    ContextType,
    Partial<Query_RootTournamentBalancesAggregateArgs>
  >;
  tournamentResultStatuses?: Resolver<
    Array<ResolversTypes['TournamentResultStatuses']>,
    ParentType,
    ContextType,
    Partial<Query_RootTournamentResultStatusesArgs>
  >;
  tournamentResultStatusesAggregate?: Resolver<
    ResolversTypes['TournamentResultStatusesAggregate'],
    ParentType,
    ContextType,
    Partial<Query_RootTournamentResultStatusesAggregateArgs>
  >;
  tournamentResultStatusesByPk?: Resolver<
    Maybe<ResolversTypes['TournamentResultStatuses']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootTournamentResultStatusesByPkArgs, 'name'>
  >;
  tournamentResults?: Resolver<
    Array<ResolversTypes['TournamentResults']>,
    ParentType,
    ContextType,
    Partial<Query_RootTournamentResultsArgs>
  >;
  tournamentResultsAggregate?: Resolver<
    ResolversTypes['TournamentResultsAggregate'],
    ParentType,
    ContextType,
    Partial<Query_RootTournamentResultsAggregateArgs>
  >;
  tournamentResultsByPk?: Resolver<
    Maybe<ResolversTypes['TournamentResults']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootTournamentResultsByPkArgs, 'id'>
  >;
  tournaments?: Resolver<
    Array<ResolversTypes['Tournaments']>,
    ParentType,
    ContextType,
    Partial<Query_RootTournamentsArgs>
  >;
  tournamentsAggregate?: Resolver<
    ResolversTypes['TournamentsAggregate'],
    ParentType,
    ContextType,
    Partial<Query_RootTournamentsAggregateArgs>
  >;
  transactionUserTypes?: Resolver<
    Array<ResolversTypes['TransactionUserTypes']>,
    ParentType,
    ContextType,
    Partial<Query_RootTransactionUserTypesArgs>
  >;
  transactionUserTypesAggregate?: Resolver<
    ResolversTypes['TransactionUserTypesAggregate'],
    ParentType,
    ContextType,
    Partial<Query_RootTransactionUserTypesAggregateArgs>
  >;
  transactionUserTypesByPk?: Resolver<
    Maybe<ResolversTypes['TransactionUserTypes']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootTransactionUserTypesByPkArgs, 'name'>
  >;
  transactions?: Resolver<
    Array<ResolversTypes['Transactions']>,
    ParentType,
    ContextType,
    Partial<Query_RootTransactionsArgs>
  >;
  transactionsAggregate?: Resolver<
    ResolversTypes['TransactionsAggregate'],
    ParentType,
    ContextType,
    Partial<Query_RootTransactionsAggregateArgs>
  >;
  transactionsByPk?: Resolver<
    Maybe<ResolversTypes['Transactions']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootTransactionsByPkArgs, 'id'>
  >;
  userBalances?: Resolver<
    Array<ResolversTypes['UserBalances']>,
    ParentType,
    ContextType,
    Partial<Query_RootUserBalancesArgs>
  >;
  userBalancesAggregate?: Resolver<
    ResolversTypes['UserBalancesAggregate'],
    ParentType,
    ContextType,
    Partial<Query_RootUserBalancesAggregateArgs>
  >;
  userShareBuys?: Resolver<
    Array<ResolversTypes['UserShareBuys']>,
    ParentType,
    ContextType,
    Partial<Query_RootUserShareBuysArgs>
  >;
  userShareBuysAggregate?: Resolver<
    ResolversTypes['UserShareBuysAggregate'],
    ParentType,
    ContextType,
    Partial<Query_RootUserShareBuysAggregateArgs>
  >;
  userShareBuysByPk?: Resolver<
    Maybe<ResolversTypes['UserShareBuys']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootUserShareBuysByPkArgs, 'id'>
  >;
  users?: Resolver<
    Array<ResolversTypes['Users']>,
    ParentType,
    ContextType,
    Partial<Query_RootUsersArgs>
  >;
  usersAggregate?: Resolver<
    ResolversTypes['UsersAggregate'],
    ParentType,
    ContextType,
    Partial<Query_RootUsersAggregateArgs>
  >;
  usersByPk?: Resolver<
    Maybe<ResolversTypes['Users']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootUsersByPkArgs, 'id'>
  >;
  venue?: Resolver<
    Maybe<ResolversTypes['Venues']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootVenueArgs, 'id'>
  >;
  venueUsers?: Resolver<
    Array<ResolversTypes['VenueUsers']>,
    ParentType,
    ContextType,
    Partial<Query_RootVenueUsersArgs>
  >;
  venueUsersAggregate?: Resolver<
    ResolversTypes['VenueUsersAggregate'],
    ParentType,
    ContextType,
    Partial<Query_RootVenueUsersAggregateArgs>
  >;
  venueUsersByPk?: Resolver<
    Maybe<ResolversTypes['VenueUsers']>,
    ParentType,
    ContextType,
    RequireFields<Query_RootVenueUsersByPkArgs, 'id'>
  >;
  venues?: Resolver<
    Array<ResolversTypes['Venues']>,
    ParentType,
    ContextType,
    Partial<Query_RootVenuesArgs>
  >;
  venuesAggregate?: Resolver<
    ResolversTypes['VenuesAggregate'],
    ParentType,
    ContextType,
    Partial<Query_RootVenuesAggregateArgs>
  >;
};

export type Subscription_RootResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['subscription_root'] = ResolversParentTypes['subscription_root']
> = {
  blockchainCurrencies?: SubscriptionResolver<
    Array<ResolversTypes['BlockchainCurrencies']>,
    'blockchainCurrencies',
    ParentType,
    ContextType,
    Partial<Subscription_RootBlockchainCurrenciesArgs>
  >;
  blockchainCurrenciesAggregate?: SubscriptionResolver<
    ResolversTypes['BlockchainCurrenciesAggregate'],
    'blockchainCurrenciesAggregate',
    ParentType,
    ContextType,
    Partial<Subscription_RootBlockchainCurrenciesAggregateArgs>
  >;
  blockchainCurrenciesByPk?: SubscriptionResolver<
    Maybe<ResolversTypes['BlockchainCurrencies']>,
    'blockchainCurrenciesByPk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootBlockchainCurrenciesByPkArgs, 'id'>
  >;
  blockchainCurrenciesStream?: SubscriptionResolver<
    Array<ResolversTypes['BlockchainCurrencies']>,
    'blockchainCurrenciesStream',
    ParentType,
    ContextType,
    RequireFields<
      Subscription_RootBlockchainCurrenciesStreamArgs,
      'batchSize' | 'cursor'
    >
  >;
  blockchains?: SubscriptionResolver<
    Array<ResolversTypes['Blockchains']>,
    'blockchains',
    ParentType,
    ContextType,
    Partial<Subscription_RootBlockchainsArgs>
  >;
  blockchainsAggregate?: SubscriptionResolver<
    ResolversTypes['BlockchainsAggregate'],
    'blockchainsAggregate',
    ParentType,
    ContextType,
    Partial<Subscription_RootBlockchainsAggregateArgs>
  >;
  blockchainsByPk?: SubscriptionResolver<
    Maybe<ResolversTypes['Blockchains']>,
    'blockchainsByPk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootBlockchainsByPkArgs, 'id'>
  >;
  blockchainsStream?: SubscriptionResolver<
    Array<ResolversTypes['Blockchains']>,
    'blockchainsStream',
    ParentType,
    ContextType,
    RequireFields<
      Subscription_RootBlockchainsStreamArgs,
      'batchSize' | 'cursor'
    >
  >;
  countries?: SubscriptionResolver<
    Array<ResolversTypes['Countries']>,
    'countries',
    ParentType,
    ContextType,
    Partial<Subscription_RootCountriesArgs>
  >;
  countriesAggregate?: SubscriptionResolver<
    ResolversTypes['CountriesAggregate'],
    'countriesAggregate',
    ParentType,
    ContextType,
    Partial<Subscription_RootCountriesAggregateArgs>
  >;
  countriesByPk?: SubscriptionResolver<
    Maybe<ResolversTypes['Countries']>,
    'countriesByPk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootCountriesByPkArgs, 'id'>
  >;
  countriesStream?: SubscriptionResolver<
    Array<ResolversTypes['Countries']>,
    'countriesStream',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootCountriesStreamArgs, 'batchSize' | 'cursor'>
  >;
  currencies?: SubscriptionResolver<
    Array<ResolversTypes['Currencies']>,
    'currencies',
    ParentType,
    ContextType,
    Partial<Subscription_RootCurrenciesArgs>
  >;
  currenciesAggregate?: SubscriptionResolver<
    ResolversTypes['CurrenciesAggregate'],
    'currenciesAggregate',
    ParentType,
    ContextType,
    Partial<Subscription_RootCurrenciesAggregateArgs>
  >;
  currenciesByPk?: SubscriptionResolver<
    Maybe<ResolversTypes['Currencies']>,
    'currenciesByPk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootCurrenciesByPkArgs, 'id'>
  >;
  currenciesStream?: SubscriptionResolver<
    Array<ResolversTypes['Currencies']>,
    'currenciesStream',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootCurrenciesStreamArgs, 'batchSize' | 'cursor'>
  >;
  event?: SubscriptionResolver<
    Maybe<ResolversTypes['Events']>,
    'event',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootEventArgs, 'id'>
  >;
  events?: SubscriptionResolver<
    Array<ResolversTypes['Events']>,
    'events',
    ParentType,
    ContextType,
    Partial<Subscription_RootEventsArgs>
  >;
  eventsAggregate?: SubscriptionResolver<
    ResolversTypes['EventsAggregate'],
    'eventsAggregate',
    ParentType,
    ContextType,
    Partial<Subscription_RootEventsAggregateArgs>
  >;
  eventsStream?: SubscriptionResolver<
    Array<ResolversTypes['Events']>,
    'eventsStream',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootEventsStreamArgs, 'batchSize' | 'cursor'>
  >;
  follower?: SubscriptionResolver<
    Maybe<ResolversTypes['Followers']>,
    'follower',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootFollowerArgs, 'id'>
  >;
  followers?: SubscriptionResolver<
    Array<ResolversTypes['Followers']>,
    'followers',
    ParentType,
    ContextType,
    Partial<Subscription_RootFollowersArgs>
  >;
  followersAggregate?: SubscriptionResolver<
    ResolversTypes['FollowersAggregate'],
    'followersAggregate',
    ParentType,
    ContextType,
    Partial<Subscription_RootFollowersAggregateArgs>
  >;
  followersStream?: SubscriptionResolver<
    Array<ResolversTypes['Followers']>,
    'followersStream',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootFollowersStreamArgs, 'batchSize' | 'cursor'>
  >;
  messageTypes?: SubscriptionResolver<
    Array<ResolversTypes['MessageTypes']>,
    'messageTypes',
    ParentType,
    ContextType,
    Partial<Subscription_RootMessageTypesArgs>
  >;
  messageTypesAggregate?: SubscriptionResolver<
    ResolversTypes['MessageTypesAggregate'],
    'messageTypesAggregate',
    ParentType,
    ContextType,
    Partial<Subscription_RootMessageTypesAggregateArgs>
  >;
  messageTypesByPk?: SubscriptionResolver<
    Maybe<ResolversTypes['MessageTypes']>,
    'messageTypesByPk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootMessageTypesByPkArgs, 'id'>
  >;
  messageTypesStream?: SubscriptionResolver<
    Array<ResolversTypes['MessageTypes']>,
    'messageTypesStream',
    ParentType,
    ContextType,
    RequireFields<
      Subscription_RootMessageTypesStreamArgs,
      'batchSize' | 'cursor'
    >
  >;
  messages?: SubscriptionResolver<
    Array<ResolversTypes['Messages']>,
    'messages',
    ParentType,
    ContextType,
    Partial<Subscription_RootMessagesArgs>
  >;
  messagesAggregate?: SubscriptionResolver<
    ResolversTypes['MessagesAggregate'],
    'messagesAggregate',
    ParentType,
    ContextType,
    Partial<Subscription_RootMessagesAggregateArgs>
  >;
  messagesByPk?: SubscriptionResolver<
    Maybe<ResolversTypes['Messages']>,
    'messagesByPk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootMessagesByPkArgs, 'id'>
  >;
  messagesStream?: SubscriptionResolver<
    Array<ResolversTypes['Messages']>,
    'messagesStream',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootMessagesStreamArgs, 'batchSize' | 'cursor'>
  >;
  organiserUsers?: SubscriptionResolver<
    Array<ResolversTypes['OrganiserUsers']>,
    'organiserUsers',
    ParentType,
    ContextType,
    Partial<Subscription_RootOrganiserUsersArgs>
  >;
  organiserUsersAggregate?: SubscriptionResolver<
    ResolversTypes['OrganiserUsersAggregate'],
    'organiserUsersAggregate',
    ParentType,
    ContextType,
    Partial<Subscription_RootOrganiserUsersAggregateArgs>
  >;
  organiserUsersByPk?: SubscriptionResolver<
    Maybe<ResolversTypes['OrganiserUsers']>,
    'organiserUsersByPk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootOrganiserUsersByPkArgs, 'id'>
  >;
  organiserUsersStream?: SubscriptionResolver<
    Array<ResolversTypes['OrganiserUsers']>,
    'organiserUsersStream',
    ParentType,
    ContextType,
    RequireFields<
      Subscription_RootOrganiserUsersStreamArgs,
      'batchSize' | 'cursor'
    >
  >;
  organisers?: SubscriptionResolver<
    Array<ResolversTypes['Organisers']>,
    'organisers',
    ParentType,
    ContextType,
    Partial<Subscription_RootOrganisersArgs>
  >;
  organisersAggregate?: SubscriptionResolver<
    ResolversTypes['OrganisersAggregate'],
    'organisersAggregate',
    ParentType,
    ContextType,
    Partial<Subscription_RootOrganisersAggregateArgs>
  >;
  organisersByPk?: SubscriptionResolver<
    Maybe<ResolversTypes['Organisers']>,
    'organisersByPk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootOrganisersByPkArgs, 'id'>
  >;
  organisersStream?: SubscriptionResolver<
    Array<ResolversTypes['Organisers']>,
    'organisersStream',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootOrganisersStreamArgs, 'batchSize' | 'cursor'>
  >;
  paymentMethodBanks?: SubscriptionResolver<
    Array<ResolversTypes['PaymentMethodBanks']>,
    'paymentMethodBanks',
    ParentType,
    ContextType,
    Partial<Subscription_RootPaymentMethodBanksArgs>
  >;
  paymentMethodBanksAggregate?: SubscriptionResolver<
    ResolversTypes['PaymentMethodBanksAggregate'],
    'paymentMethodBanksAggregate',
    ParentType,
    ContextType,
    Partial<Subscription_RootPaymentMethodBanksAggregateArgs>
  >;
  paymentMethodBanksByPk?: SubscriptionResolver<
    Maybe<ResolversTypes['PaymentMethodBanks']>,
    'paymentMethodBanksByPk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootPaymentMethodBanksByPkArgs, 'id'>
  >;
  paymentMethodBanksStream?: SubscriptionResolver<
    Array<ResolversTypes['PaymentMethodBanks']>,
    'paymentMethodBanksStream',
    ParentType,
    ContextType,
    RequireFields<
      Subscription_RootPaymentMethodBanksStreamArgs,
      'batchSize' | 'cursor'
    >
  >;
  paymentMethods?: SubscriptionResolver<
    Array<ResolversTypes['PaymentMethods']>,
    'paymentMethods',
    ParentType,
    ContextType,
    Partial<Subscription_RootPaymentMethodsArgs>
  >;
  paymentMethodsAggregate?: SubscriptionResolver<
    ResolversTypes['PaymentMethodsAggregate'],
    'paymentMethodsAggregate',
    ParentType,
    ContextType,
    Partial<Subscription_RootPaymentMethodsAggregateArgs>
  >;
  paymentMethodsByPk?: SubscriptionResolver<
    Maybe<ResolversTypes['PaymentMethods']>,
    'paymentMethodsByPk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootPaymentMethodsByPkArgs, 'id'>
  >;
  paymentMethodsStream?: SubscriptionResolver<
    Array<ResolversTypes['PaymentMethods']>,
    'paymentMethodsStream',
    ParentType,
    ContextType,
    RequireFields<
      Subscription_RootPaymentMethodsStreamArgs,
      'batchSize' | 'cursor'
    >
  >;
  payments?: SubscriptionResolver<
    Array<ResolversTypes['Payments']>,
    'payments',
    ParentType,
    ContextType,
    Partial<Subscription_RootPaymentsArgs>
  >;
  paymentsAggregate?: SubscriptionResolver<
    ResolversTypes['PaymentsAggregate'],
    'paymentsAggregate',
    ParentType,
    ContextType,
    Partial<Subscription_RootPaymentsAggregateArgs>
  >;
  paymentsByPk?: SubscriptionResolver<
    Maybe<ResolversTypes['Payments']>,
    'paymentsByPk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootPaymentsByPkArgs, 'id'>
  >;
  paymentsStream?: SubscriptionResolver<
    Array<ResolversTypes['Payments']>,
    'paymentsStream',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootPaymentsStreamArgs, 'batchSize' | 'cursor'>
  >;
  pendingTxs?: SubscriptionResolver<
    Array<ResolversTypes['PendingTxs']>,
    'pendingTxs',
    ParentType,
    ContextType,
    Partial<Subscription_RootPendingTxsArgs>
  >;
  pendingTxsAggregate?: SubscriptionResolver<
    ResolversTypes['PendingTxsAggregate'],
    'pendingTxsAggregate',
    ParentType,
    ContextType,
    Partial<Subscription_RootPendingTxsAggregateArgs>
  >;
  pendingTxsByPk?: SubscriptionResolver<
    Maybe<ResolversTypes['PendingTxs']>,
    'pendingTxsByPk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootPendingTxsByPkArgs, 'id'>
  >;
  pendingTxsStream?: SubscriptionResolver<
    Array<ResolversTypes['PendingTxs']>,
    'pendingTxsStream',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootPendingTxsStreamArgs, 'batchSize' | 'cursor'>
  >;
  player?: SubscriptionResolver<
    Maybe<ResolversTypes['Players']>,
    'player',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootPlayerArgs, 'id'>
  >;
  playerFollowers?: SubscriptionResolver<
    Array<ResolversTypes['PlayerFollowers']>,
    'playerFollowers',
    ParentType,
    ContextType,
    Partial<Subscription_RootPlayerFollowersArgs>
  >;
  playerFollowersAggregate?: SubscriptionResolver<
    ResolversTypes['PlayerFollowersAggregate'],
    'playerFollowersAggregate',
    ParentType,
    ContextType,
    Partial<Subscription_RootPlayerFollowersAggregateArgs>
  >;
  playerFollowersStream?: SubscriptionResolver<
    Array<ResolversTypes['PlayerFollowers']>,
    'playerFollowersStream',
    ParentType,
    ContextType,
    RequireFields<
      Subscription_RootPlayerFollowersStreamArgs,
      'batchSize' | 'cursor'
    >
  >;
  playerFollowersView?: SubscriptionResolver<
    Array<ResolversTypes['PlayerFollowersView']>,
    'playerFollowersView',
    ParentType,
    ContextType,
    Partial<Subscription_RootPlayerFollowersViewArgs>
  >;
  playerFollowersViewAggregate?: SubscriptionResolver<
    ResolversTypes['PlayerFollowersViewAggregate'],
    'playerFollowersViewAggregate',
    ParentType,
    ContextType,
    Partial<Subscription_RootPlayerFollowersViewAggregateArgs>
  >;
  playerFollowersViewStream?: SubscriptionResolver<
    Array<ResolversTypes['PlayerFollowersView']>,
    'playerFollowersViewStream',
    ParentType,
    ContextType,
    RequireFields<
      Subscription_RootPlayerFollowersViewStreamArgs,
      'batchSize' | 'cursor'
    >
  >;
  playerShare?: SubscriptionResolver<
    Maybe<ResolversTypes['PlayerShares']>,
    'playerShare',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootPlayerShareArgs, 'id'>
  >;
  playerShares?: SubscriptionResolver<
    Array<ResolversTypes['PlayerShares']>,
    'playerShares',
    ParentType,
    ContextType,
    Partial<Subscription_RootPlayerSharesArgs>
  >;
  playerSharesAggregate?: SubscriptionResolver<
    ResolversTypes['PlayerSharesAggregate'],
    'playerSharesAggregate',
    ParentType,
    ContextType,
    Partial<Subscription_RootPlayerSharesAggregateArgs>
  >;
  playerSharesBought?: SubscriptionResolver<
    Array<ResolversTypes['PlayerSharesBought']>,
    'playerSharesBought',
    ParentType,
    ContextType,
    Partial<Subscription_RootPlayerSharesBoughtArgs>
  >;
  playerSharesBoughtAggregate?: SubscriptionResolver<
    ResolversTypes['PlayerSharesBoughtAggregate'],
    'playerSharesBoughtAggregate',
    ParentType,
    ContextType,
    Partial<Subscription_RootPlayerSharesBoughtAggregateArgs>
  >;
  playerSharesBoughtStream?: SubscriptionResolver<
    Array<ResolversTypes['PlayerSharesBought']>,
    'playerSharesBoughtStream',
    ParentType,
    ContextType,
    RequireFields<
      Subscription_RootPlayerSharesBoughtStreamArgs,
      'batchSize' | 'cursor'
    >
  >;
  playerSharesStream?: SubscriptionResolver<
    Array<ResolversTypes['PlayerShares']>,
    'playerSharesStream',
    ParentType,
    ContextType,
    RequireFields<
      Subscription_RootPlayerSharesStreamArgs,
      'batchSize' | 'cursor'
    >
  >;
  players?: SubscriptionResolver<
    Array<ResolversTypes['Players']>,
    'players',
    ParentType,
    ContextType,
    Partial<Subscription_RootPlayersArgs>
  >;
  playersAggregate?: SubscriptionResolver<
    ResolversTypes['PlayersAggregate'],
    'playersAggregate',
    ParentType,
    ContextType,
    Partial<Subscription_RootPlayersAggregateArgs>
  >;
  playersStream?: SubscriptionResolver<
    Array<ResolversTypes['Players']>,
    'playersStream',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootPlayersStreamArgs, 'batchSize' | 'cursor'>
  >;
  statEvents?: SubscriptionResolver<
    Array<ResolversTypes['StatEvents']>,
    'statEvents',
    ParentType,
    ContextType,
    Partial<Subscription_RootStatEventsArgs>
  >;
  statEventsAggregate?: SubscriptionResolver<
    ResolversTypes['StatEventsAggregate'],
    'statEventsAggregate',
    ParentType,
    ContextType,
    Partial<Subscription_RootStatEventsAggregateArgs>
  >;
  statEventsByPk?: SubscriptionResolver<
    Maybe<ResolversTypes['StatEvents']>,
    'statEventsByPk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootStatEventsByPkArgs, 'id'>
  >;
  statEventsStream?: SubscriptionResolver<
    Array<ResolversTypes['StatEvents']>,
    'statEventsStream',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootStatEventsStreamArgs, 'batchSize' | 'cursor'>
  >;
  statPlayers?: SubscriptionResolver<
    Array<ResolversTypes['StatPlayers']>,
    'statPlayers',
    ParentType,
    ContextType,
    Partial<Subscription_RootStatPlayersArgs>
  >;
  statPlayersAggregate?: SubscriptionResolver<
    ResolversTypes['StatPlayersAggregate'],
    'statPlayersAggregate',
    ParentType,
    ContextType,
    Partial<Subscription_RootStatPlayersAggregateArgs>
  >;
  statPlayersByPk?: SubscriptionResolver<
    Maybe<ResolversTypes['StatPlayers']>,
    'statPlayersByPk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootStatPlayersByPkArgs, 'id'>
  >;
  statPlayersStream?: SubscriptionResolver<
    Array<ResolversTypes['StatPlayers']>,
    'statPlayersStream',
    ParentType,
    ContextType,
    RequireFields<
      Subscription_RootStatPlayersStreamArgs,
      'batchSize' | 'cursor'
    >
  >;
  statTopPlayers?: SubscriptionResolver<
    Array<ResolversTypes['StatTopPlayers']>,
    'statTopPlayers',
    ParentType,
    ContextType,
    Partial<Subscription_RootStatTopPlayersArgs>
  >;
  statTopPlayersAggregate?: SubscriptionResolver<
    ResolversTypes['StatTopPlayersAggregate'],
    'statTopPlayersAggregate',
    ParentType,
    ContextType,
    Partial<Subscription_RootStatTopPlayersAggregateArgs>
  >;
  statTopPlayersStream?: SubscriptionResolver<
    Array<ResolversTypes['StatTopPlayers']>,
    'statTopPlayersStream',
    ParentType,
    ContextType,
    RequireFields<
      Subscription_RootStatTopPlayersStreamArgs,
      'batchSize' | 'cursor'
    >
  >;
  statTopYearPlayers?: SubscriptionResolver<
    Array<ResolversTypes['StatTopYearPlayers']>,
    'statTopYearPlayers',
    ParentType,
    ContextType,
    Partial<Subscription_RootStatTopYearPlayersArgs>
  >;
  statTopYearPlayersAggregate?: SubscriptionResolver<
    ResolversTypes['StatTopYearPlayersAggregate'],
    'statTopYearPlayersAggregate',
    ParentType,
    ContextType,
    Partial<Subscription_RootStatTopYearPlayersAggregateArgs>
  >;
  statTopYearPlayersStream?: SubscriptionResolver<
    Array<ResolversTypes['StatTopYearPlayers']>,
    'statTopYearPlayersStream',
    ParentType,
    ContextType,
    RequireFields<
      Subscription_RootStatTopYearPlayersStreamArgs,
      'batchSize' | 'cursor'
    >
  >;
  statTournamentResults?: SubscriptionResolver<
    Array<ResolversTypes['StatTournamentResults']>,
    'statTournamentResults',
    ParentType,
    ContextType,
    Partial<Subscription_RootStatTournamentResultsArgs>
  >;
  statTournamentResultsAggregate?: SubscriptionResolver<
    ResolversTypes['StatTournamentResultsAggregate'],
    'statTournamentResultsAggregate',
    ParentType,
    ContextType,
    Partial<Subscription_RootStatTournamentResultsAggregateArgs>
  >;
  statTournamentResultsByPk?: SubscriptionResolver<
    Maybe<ResolversTypes['StatTournamentResults']>,
    'statTournamentResultsByPk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootStatTournamentResultsByPkArgs, 'id'>
  >;
  statTournamentResultsStream?: SubscriptionResolver<
    Array<ResolversTypes['StatTournamentResults']>,
    'statTournamentResultsStream',
    ParentType,
    ContextType,
    RequireFields<
      Subscription_RootStatTournamentResultsStreamArgs,
      'batchSize' | 'cursor'
    >
  >;
  statTournaments?: SubscriptionResolver<
    Array<ResolversTypes['StatTournaments']>,
    'statTournaments',
    ParentType,
    ContextType,
    Partial<Subscription_RootStatTournamentsArgs>
  >;
  statTournamentsAggregate?: SubscriptionResolver<
    ResolversTypes['StatTournamentsAggregate'],
    'statTournamentsAggregate',
    ParentType,
    ContextType,
    Partial<Subscription_RootStatTournamentsAggregateArgs>
  >;
  statTournamentsByPk?: SubscriptionResolver<
    Maybe<ResolversTypes['StatTournaments']>,
    'statTournamentsByPk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootStatTournamentsByPkArgs, 'id'>
  >;
  statTournamentsStream?: SubscriptionResolver<
    Array<ResolversTypes['StatTournaments']>,
    'statTournamentsStream',
    ParentType,
    ContextType,
    RequireFields<
      Subscription_RootStatTournamentsStreamArgs,
      'batchSize' | 'cursor'
    >
  >;
  statusGames?: SubscriptionResolver<
    Array<ResolversTypes['StatusGames']>,
    'statusGames',
    ParentType,
    ContextType,
    Partial<Subscription_RootStatusGamesArgs>
  >;
  statusGamesAggregate?: SubscriptionResolver<
    ResolversTypes['StatusGamesAggregate'],
    'statusGamesAggregate',
    ParentType,
    ContextType,
    Partial<Subscription_RootStatusGamesAggregateArgs>
  >;
  statusGamesByPk?: SubscriptionResolver<
    Maybe<ResolversTypes['StatusGames']>,
    'statusGamesByPk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootStatusGamesByPkArgs, 'name'>
  >;
  statusGamesStream?: SubscriptionResolver<
    Array<ResolversTypes['StatusGames']>,
    'statusGamesStream',
    ParentType,
    ContextType,
    RequireFields<
      Subscription_RootStatusGamesStreamArgs,
      'batchSize' | 'cursor'
    >
  >;
  statuses?: SubscriptionResolver<
    Array<ResolversTypes['Statuses']>,
    'statuses',
    ParentType,
    ContextType,
    Partial<Subscription_RootStatusesArgs>
  >;
  statusesAggregate?: SubscriptionResolver<
    ResolversTypes['StatusesAggregate'],
    'statusesAggregate',
    ParentType,
    ContextType,
    Partial<Subscription_RootStatusesAggregateArgs>
  >;
  statusesByPk?: SubscriptionResolver<
    Maybe<ResolversTypes['Statuses']>,
    'statusesByPk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootStatusesByPkArgs, 'name'>
  >;
  statusesStream?: SubscriptionResolver<
    Array<ResolversTypes['Statuses']>,
    'statusesStream',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootStatusesStreamArgs, 'batchSize' | 'cursor'>
  >;
  tickets?: SubscriptionResolver<
    Array<ResolversTypes['Tickets']>,
    'tickets',
    ParentType,
    ContextType,
    Partial<Subscription_RootTicketsArgs>
  >;
  ticketsAggregate?: SubscriptionResolver<
    ResolversTypes['TicketsAggregate'],
    'ticketsAggregate',
    ParentType,
    ContextType,
    Partial<Subscription_RootTicketsAggregateArgs>
  >;
  ticketsByPk?: SubscriptionResolver<
    Maybe<ResolversTypes['Tickets']>,
    'ticketsByPk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootTicketsByPkArgs, 'id'>
  >;
  ticketsStatus?: SubscriptionResolver<
    Array<ResolversTypes['TicketsStatus']>,
    'ticketsStatus',
    ParentType,
    ContextType,
    Partial<Subscription_RootTicketsStatusArgs>
  >;
  ticketsStatusAggregate?: SubscriptionResolver<
    ResolversTypes['TicketsStatusAggregate'],
    'ticketsStatusAggregate',
    ParentType,
    ContextType,
    Partial<Subscription_RootTicketsStatusAggregateArgs>
  >;
  ticketsStatusByPk?: SubscriptionResolver<
    Maybe<ResolversTypes['TicketsStatus']>,
    'ticketsStatusByPk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootTicketsStatusByPkArgs, 'name'>
  >;
  ticketsStatusStream?: SubscriptionResolver<
    Array<ResolversTypes['TicketsStatus']>,
    'ticketsStatusStream',
    ParentType,
    ContextType,
    RequireFields<
      Subscription_RootTicketsStatusStreamArgs,
      'batchSize' | 'cursor'
    >
  >;
  ticketsStream?: SubscriptionResolver<
    Array<ResolversTypes['Tickets']>,
    'ticketsStream',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootTicketsStreamArgs, 'batchSize' | 'cursor'>
  >;
  tournament?: SubscriptionResolver<
    Maybe<ResolversTypes['Tournaments']>,
    'tournament',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootTournamentArgs, 'id'>
  >;
  tournamentBalances?: SubscriptionResolver<
    Array<ResolversTypes['TournamentBalances']>,
    'tournamentBalances',
    ParentType,
    ContextType,
    Partial<Subscription_RootTournamentBalancesArgs>
  >;
  tournamentBalancesAggregate?: SubscriptionResolver<
    ResolversTypes['TournamentBalancesAggregate'],
    'tournamentBalancesAggregate',
    ParentType,
    ContextType,
    Partial<Subscription_RootTournamentBalancesAggregateArgs>
  >;
  tournamentBalancesStream?: SubscriptionResolver<
    Array<ResolversTypes['TournamentBalances']>,
    'tournamentBalancesStream',
    ParentType,
    ContextType,
    RequireFields<
      Subscription_RootTournamentBalancesStreamArgs,
      'batchSize' | 'cursor'
    >
  >;
  tournamentResultStatuses?: SubscriptionResolver<
    Array<ResolversTypes['TournamentResultStatuses']>,
    'tournamentResultStatuses',
    ParentType,
    ContextType,
    Partial<Subscription_RootTournamentResultStatusesArgs>
  >;
  tournamentResultStatusesAggregate?: SubscriptionResolver<
    ResolversTypes['TournamentResultStatusesAggregate'],
    'tournamentResultStatusesAggregate',
    ParentType,
    ContextType,
    Partial<Subscription_RootTournamentResultStatusesAggregateArgs>
  >;
  tournamentResultStatusesByPk?: SubscriptionResolver<
    Maybe<ResolversTypes['TournamentResultStatuses']>,
    'tournamentResultStatusesByPk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootTournamentResultStatusesByPkArgs, 'name'>
  >;
  tournamentResultStatusesStream?: SubscriptionResolver<
    Array<ResolversTypes['TournamentResultStatuses']>,
    'tournamentResultStatusesStream',
    ParentType,
    ContextType,
    RequireFields<
      Subscription_RootTournamentResultStatusesStreamArgs,
      'batchSize' | 'cursor'
    >
  >;
  tournamentResults?: SubscriptionResolver<
    Array<ResolversTypes['TournamentResults']>,
    'tournamentResults',
    ParentType,
    ContextType,
    Partial<Subscription_RootTournamentResultsArgs>
  >;
  tournamentResultsAggregate?: SubscriptionResolver<
    ResolversTypes['TournamentResultsAggregate'],
    'tournamentResultsAggregate',
    ParentType,
    ContextType,
    Partial<Subscription_RootTournamentResultsAggregateArgs>
  >;
  tournamentResultsByPk?: SubscriptionResolver<
    Maybe<ResolversTypes['TournamentResults']>,
    'tournamentResultsByPk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootTournamentResultsByPkArgs, 'id'>
  >;
  tournamentResultsStream?: SubscriptionResolver<
    Array<ResolversTypes['TournamentResults']>,
    'tournamentResultsStream',
    ParentType,
    ContextType,
    RequireFields<
      Subscription_RootTournamentResultsStreamArgs,
      'batchSize' | 'cursor'
    >
  >;
  tournaments?: SubscriptionResolver<
    Array<ResolversTypes['Tournaments']>,
    'tournaments',
    ParentType,
    ContextType,
    Partial<Subscription_RootTournamentsArgs>
  >;
  tournamentsAggregate?: SubscriptionResolver<
    ResolversTypes['TournamentsAggregate'],
    'tournamentsAggregate',
    ParentType,
    ContextType,
    Partial<Subscription_RootTournamentsAggregateArgs>
  >;
  tournamentsStream?: SubscriptionResolver<
    Array<ResolversTypes['Tournaments']>,
    'tournamentsStream',
    ParentType,
    ContextType,
    RequireFields<
      Subscription_RootTournamentsStreamArgs,
      'batchSize' | 'cursor'
    >
  >;
  transactionUserTypes?: SubscriptionResolver<
    Array<ResolversTypes['TransactionUserTypes']>,
    'transactionUserTypes',
    ParentType,
    ContextType,
    Partial<Subscription_RootTransactionUserTypesArgs>
  >;
  transactionUserTypesAggregate?: SubscriptionResolver<
    ResolversTypes['TransactionUserTypesAggregate'],
    'transactionUserTypesAggregate',
    ParentType,
    ContextType,
    Partial<Subscription_RootTransactionUserTypesAggregateArgs>
  >;
  transactionUserTypesByPk?: SubscriptionResolver<
    Maybe<ResolversTypes['TransactionUserTypes']>,
    'transactionUserTypesByPk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootTransactionUserTypesByPkArgs, 'name'>
  >;
  transactionUserTypesStream?: SubscriptionResolver<
    Array<ResolversTypes['TransactionUserTypes']>,
    'transactionUserTypesStream',
    ParentType,
    ContextType,
    RequireFields<
      Subscription_RootTransactionUserTypesStreamArgs,
      'batchSize' | 'cursor'
    >
  >;
  transactions?: SubscriptionResolver<
    Array<ResolversTypes['Transactions']>,
    'transactions',
    ParentType,
    ContextType,
    Partial<Subscription_RootTransactionsArgs>
  >;
  transactionsAggregate?: SubscriptionResolver<
    ResolversTypes['TransactionsAggregate'],
    'transactionsAggregate',
    ParentType,
    ContextType,
    Partial<Subscription_RootTransactionsAggregateArgs>
  >;
  transactionsByPk?: SubscriptionResolver<
    Maybe<ResolversTypes['Transactions']>,
    'transactionsByPk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootTransactionsByPkArgs, 'id'>
  >;
  transactionsStream?: SubscriptionResolver<
    Array<ResolversTypes['Transactions']>,
    'transactionsStream',
    ParentType,
    ContextType,
    RequireFields<
      Subscription_RootTransactionsStreamArgs,
      'batchSize' | 'cursor'
    >
  >;
  userBalances?: SubscriptionResolver<
    Array<ResolversTypes['UserBalances']>,
    'userBalances',
    ParentType,
    ContextType,
    Partial<Subscription_RootUserBalancesArgs>
  >;
  userBalancesAggregate?: SubscriptionResolver<
    ResolversTypes['UserBalancesAggregate'],
    'userBalancesAggregate',
    ParentType,
    ContextType,
    Partial<Subscription_RootUserBalancesAggregateArgs>
  >;
  userBalancesStream?: SubscriptionResolver<
    Array<ResolversTypes['UserBalances']>,
    'userBalancesStream',
    ParentType,
    ContextType,
    RequireFields<
      Subscription_RootUserBalancesStreamArgs,
      'batchSize' | 'cursor'
    >
  >;
  userShareBuys?: SubscriptionResolver<
    Array<ResolversTypes['UserShareBuys']>,
    'userShareBuys',
    ParentType,
    ContextType,
    Partial<Subscription_RootUserShareBuysArgs>
  >;
  userShareBuysAggregate?: SubscriptionResolver<
    ResolversTypes['UserShareBuysAggregate'],
    'userShareBuysAggregate',
    ParentType,
    ContextType,
    Partial<Subscription_RootUserShareBuysAggregateArgs>
  >;
  userShareBuysByPk?: SubscriptionResolver<
    Maybe<ResolversTypes['UserShareBuys']>,
    'userShareBuysByPk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootUserShareBuysByPkArgs, 'id'>
  >;
  userShareBuysStream?: SubscriptionResolver<
    Array<ResolversTypes['UserShareBuys']>,
    'userShareBuysStream',
    ParentType,
    ContextType,
    RequireFields<
      Subscription_RootUserShareBuysStreamArgs,
      'batchSize' | 'cursor'
    >
  >;
  users?: SubscriptionResolver<
    Array<ResolversTypes['Users']>,
    'users',
    ParentType,
    ContextType,
    Partial<Subscription_RootUsersArgs>
  >;
  usersAggregate?: SubscriptionResolver<
    ResolversTypes['UsersAggregate'],
    'usersAggregate',
    ParentType,
    ContextType,
    Partial<Subscription_RootUsersAggregateArgs>
  >;
  usersByPk?: SubscriptionResolver<
    Maybe<ResolversTypes['Users']>,
    'usersByPk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootUsersByPkArgs, 'id'>
  >;
  usersStream?: SubscriptionResolver<
    Array<ResolversTypes['Users']>,
    'usersStream',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootUsersStreamArgs, 'batchSize' | 'cursor'>
  >;
  venue?: SubscriptionResolver<
    Maybe<ResolversTypes['Venues']>,
    'venue',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootVenueArgs, 'id'>
  >;
  venueUsers?: SubscriptionResolver<
    Array<ResolversTypes['VenueUsers']>,
    'venueUsers',
    ParentType,
    ContextType,
    Partial<Subscription_RootVenueUsersArgs>
  >;
  venueUsersAggregate?: SubscriptionResolver<
    ResolversTypes['VenueUsersAggregate'],
    'venueUsersAggregate',
    ParentType,
    ContextType,
    Partial<Subscription_RootVenueUsersAggregateArgs>
  >;
  venueUsersByPk?: SubscriptionResolver<
    Maybe<ResolversTypes['VenueUsers']>,
    'venueUsersByPk',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootVenueUsersByPkArgs, 'id'>
  >;
  venueUsersStream?: SubscriptionResolver<
    Array<ResolversTypes['VenueUsers']>,
    'venueUsersStream',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootVenueUsersStreamArgs, 'batchSize' | 'cursor'>
  >;
  venues?: SubscriptionResolver<
    Array<ResolversTypes['Venues']>,
    'venues',
    ParentType,
    ContextType,
    Partial<Subscription_RootVenuesArgs>
  >;
  venuesAggregate?: SubscriptionResolver<
    ResolversTypes['VenuesAggregate'],
    'venuesAggregate',
    ParentType,
    ContextType,
    Partial<Subscription_RootVenuesAggregateArgs>
  >;
  venuesStream?: SubscriptionResolver<
    Array<ResolversTypes['Venues']>,
    'venuesStream',
    ParentType,
    ContextType,
    RequireFields<Subscription_RootVenuesStreamArgs, 'batchSize' | 'cursor'>
  >;
};

export interface TimestampScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['timestamp'], any> {
  name: 'timestamp';
}

export interface TimestamptzScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['timestamptz'], any> {
  name: 'timestamptz';
}

export interface UuidScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['uuid'], any> {
  name: 'uuid';
}

export type Resolvers<ContextType = any> = {
  BlockchainCurrencies?: BlockchainCurrenciesResolvers<ContextType>;
  BlockchainCurrenciesAggregate?: BlockchainCurrenciesAggregateResolvers<ContextType>;
  BlockchainCurrenciesAggregateFields?: BlockchainCurrenciesAggregateFieldsResolvers<ContextType>;
  BlockchainCurrenciesAvgFields?: BlockchainCurrenciesAvgFieldsResolvers<ContextType>;
  BlockchainCurrenciesMaxFields?: BlockchainCurrenciesMaxFieldsResolvers<ContextType>;
  BlockchainCurrenciesMinFields?: BlockchainCurrenciesMinFieldsResolvers<ContextType>;
  BlockchainCurrenciesMutationResponse?: BlockchainCurrenciesMutationResponseResolvers<ContextType>;
  BlockchainCurrenciesStddevFields?: BlockchainCurrenciesStddevFieldsResolvers<ContextType>;
  BlockchainCurrenciesStddev_popFields?: BlockchainCurrenciesStddev_PopFieldsResolvers<ContextType>;
  BlockchainCurrenciesStddev_sampFields?: BlockchainCurrenciesStddev_SampFieldsResolvers<ContextType>;
  BlockchainCurrenciesSumFields?: BlockchainCurrenciesSumFieldsResolvers<ContextType>;
  BlockchainCurrenciesVar_popFields?: BlockchainCurrenciesVar_PopFieldsResolvers<ContextType>;
  BlockchainCurrenciesVar_sampFields?: BlockchainCurrenciesVar_SampFieldsResolvers<ContextType>;
  BlockchainCurrenciesVarianceFields?: BlockchainCurrenciesVarianceFieldsResolvers<ContextType>;
  Blockchains?: BlockchainsResolvers<ContextType>;
  BlockchainsAggregate?: BlockchainsAggregateResolvers<ContextType>;
  BlockchainsAggregateFields?: BlockchainsAggregateFieldsResolvers<ContextType>;
  BlockchainsAvgFields?: BlockchainsAvgFieldsResolvers<ContextType>;
  BlockchainsMaxFields?: BlockchainsMaxFieldsResolvers<ContextType>;
  BlockchainsMinFields?: BlockchainsMinFieldsResolvers<ContextType>;
  BlockchainsMutationResponse?: BlockchainsMutationResponseResolvers<ContextType>;
  BlockchainsStddevFields?: BlockchainsStddevFieldsResolvers<ContextType>;
  BlockchainsStddev_popFields?: BlockchainsStddev_PopFieldsResolvers<ContextType>;
  BlockchainsStddev_sampFields?: BlockchainsStddev_SampFieldsResolvers<ContextType>;
  BlockchainsSumFields?: BlockchainsSumFieldsResolvers<ContextType>;
  BlockchainsVar_popFields?: BlockchainsVar_PopFieldsResolvers<ContextType>;
  BlockchainsVar_sampFields?: BlockchainsVar_SampFieldsResolvers<ContextType>;
  BlockchainsVarianceFields?: BlockchainsVarianceFieldsResolvers<ContextType>;
  Countries?: CountriesResolvers<ContextType>;
  CountriesAggregate?: CountriesAggregateResolvers<ContextType>;
  CountriesAggregateFields?: CountriesAggregateFieldsResolvers<ContextType>;
  CountriesAvgFields?: CountriesAvgFieldsResolvers<ContextType>;
  CountriesMaxFields?: CountriesMaxFieldsResolvers<ContextType>;
  CountriesMinFields?: CountriesMinFieldsResolvers<ContextType>;
  CountriesMutationResponse?: CountriesMutationResponseResolvers<ContextType>;
  CountriesStddevFields?: CountriesStddevFieldsResolvers<ContextType>;
  CountriesStddev_popFields?: CountriesStddev_PopFieldsResolvers<ContextType>;
  CountriesStddev_sampFields?: CountriesStddev_SampFieldsResolvers<ContextType>;
  CountriesSumFields?: CountriesSumFieldsResolvers<ContextType>;
  CountriesVar_popFields?: CountriesVar_PopFieldsResolvers<ContextType>;
  CountriesVar_sampFields?: CountriesVar_SampFieldsResolvers<ContextType>;
  CountriesVarianceFields?: CountriesVarianceFieldsResolvers<ContextType>;
  Currencies?: CurrenciesResolvers<ContextType>;
  CurrenciesAggregate?: CurrenciesAggregateResolvers<ContextType>;
  CurrenciesAggregateFields?: CurrenciesAggregateFieldsResolvers<ContextType>;
  CurrenciesAvgFields?: CurrenciesAvgFieldsResolvers<ContextType>;
  CurrenciesMaxFields?: CurrenciesMaxFieldsResolvers<ContextType>;
  CurrenciesMinFields?: CurrenciesMinFieldsResolvers<ContextType>;
  CurrenciesMutationResponse?: CurrenciesMutationResponseResolvers<ContextType>;
  CurrenciesStddevFields?: CurrenciesStddevFieldsResolvers<ContextType>;
  CurrenciesStddev_popFields?: CurrenciesStddev_PopFieldsResolvers<ContextType>;
  CurrenciesStddev_sampFields?: CurrenciesStddev_SampFieldsResolvers<ContextType>;
  CurrenciesSumFields?: CurrenciesSumFieldsResolvers<ContextType>;
  CurrenciesVar_popFields?: CurrenciesVar_PopFieldsResolvers<ContextType>;
  CurrenciesVar_sampFields?: CurrenciesVar_SampFieldsResolvers<ContextType>;
  CurrenciesVarianceFields?: CurrenciesVarianceFieldsResolvers<ContextType>;
  DepositOutput?: DepositOutputResolvers<ContextType>;
  Events?: EventsResolvers<ContextType>;
  EventsAggregate?: EventsAggregateResolvers<ContextType>;
  EventsAggregateFields?: EventsAggregateFieldsResolvers<ContextType>;
  EventsAvgFields?: EventsAvgFieldsResolvers<ContextType>;
  EventsMaxFields?: EventsMaxFieldsResolvers<ContextType>;
  EventsMinFields?: EventsMinFieldsResolvers<ContextType>;
  EventsMutationResponse?: EventsMutationResponseResolvers<ContextType>;
  EventsStddevFields?: EventsStddevFieldsResolvers<ContextType>;
  EventsStddev_popFields?: EventsStddev_PopFieldsResolvers<ContextType>;
  EventsStddev_sampFields?: EventsStddev_SampFieldsResolvers<ContextType>;
  EventsSumFields?: EventsSumFieldsResolvers<ContextType>;
  EventsVar_popFields?: EventsVar_PopFieldsResolvers<ContextType>;
  EventsVar_sampFields?: EventsVar_SampFieldsResolvers<ContextType>;
  EventsVarianceFields?: EventsVarianceFieldsResolvers<ContextType>;
  Followers?: FollowersResolvers<ContextType>;
  FollowersAggregate?: FollowersAggregateResolvers<ContextType>;
  FollowersAggregateFields?: FollowersAggregateFieldsResolvers<ContextType>;
  FollowersAvgFields?: FollowersAvgFieldsResolvers<ContextType>;
  FollowersMaxFields?: FollowersMaxFieldsResolvers<ContextType>;
  FollowersMinFields?: FollowersMinFieldsResolvers<ContextType>;
  FollowersMutationResponse?: FollowersMutationResponseResolvers<ContextType>;
  FollowersStddevFields?: FollowersStddevFieldsResolvers<ContextType>;
  FollowersStddev_popFields?: FollowersStddev_PopFieldsResolvers<ContextType>;
  FollowersStddev_sampFields?: FollowersStddev_SampFieldsResolvers<ContextType>;
  FollowersSumFields?: FollowersSumFieldsResolvers<ContextType>;
  FollowersVar_popFields?: FollowersVar_PopFieldsResolvers<ContextType>;
  FollowersVar_sampFields?: FollowersVar_SampFieldsResolvers<ContextType>;
  FollowersVarianceFields?: FollowersVarianceFieldsResolvers<ContextType>;
  MessageTypes?: MessageTypesResolvers<ContextType>;
  MessageTypesAggregate?: MessageTypesAggregateResolvers<ContextType>;
  MessageTypesAggregateFields?: MessageTypesAggregateFieldsResolvers<ContextType>;
  MessageTypesAvgFields?: MessageTypesAvgFieldsResolvers<ContextType>;
  MessageTypesMaxFields?: MessageTypesMaxFieldsResolvers<ContextType>;
  MessageTypesMinFields?: MessageTypesMinFieldsResolvers<ContextType>;
  MessageTypesMutationResponse?: MessageTypesMutationResponseResolvers<ContextType>;
  MessageTypesStddevFields?: MessageTypesStddevFieldsResolvers<ContextType>;
  MessageTypesStddev_popFields?: MessageTypesStddev_PopFieldsResolvers<ContextType>;
  MessageTypesStddev_sampFields?: MessageTypesStddev_SampFieldsResolvers<ContextType>;
  MessageTypesSumFields?: MessageTypesSumFieldsResolvers<ContextType>;
  MessageTypesVar_popFields?: MessageTypesVar_PopFieldsResolvers<ContextType>;
  MessageTypesVar_sampFields?: MessageTypesVar_SampFieldsResolvers<ContextType>;
  MessageTypesVarianceFields?: MessageTypesVarianceFieldsResolvers<ContextType>;
  Messages?: MessagesResolvers<ContextType>;
  MessagesAggregate?: MessagesAggregateResolvers<ContextType>;
  MessagesAggregateFields?: MessagesAggregateFieldsResolvers<ContextType>;
  MessagesAvgFields?: MessagesAvgFieldsResolvers<ContextType>;
  MessagesMaxFields?: MessagesMaxFieldsResolvers<ContextType>;
  MessagesMinFields?: MessagesMinFieldsResolvers<ContextType>;
  MessagesMutationResponse?: MessagesMutationResponseResolvers<ContextType>;
  MessagesStddevFields?: MessagesStddevFieldsResolvers<ContextType>;
  MessagesStddev_popFields?: MessagesStddev_PopFieldsResolvers<ContextType>;
  MessagesStddev_sampFields?: MessagesStddev_SampFieldsResolvers<ContextType>;
  MessagesSumFields?: MessagesSumFieldsResolvers<ContextType>;
  MessagesVar_popFields?: MessagesVar_PopFieldsResolvers<ContextType>;
  MessagesVar_sampFields?: MessagesVar_SampFieldsResolvers<ContextType>;
  MessagesVarianceFields?: MessagesVarianceFieldsResolvers<ContextType>;
  OrganiserUsers?: OrganiserUsersResolvers<ContextType>;
  OrganiserUsersAggregate?: OrganiserUsersAggregateResolvers<ContextType>;
  OrganiserUsersAggregateFields?: OrganiserUsersAggregateFieldsResolvers<ContextType>;
  OrganiserUsersAvgFields?: OrganiserUsersAvgFieldsResolvers<ContextType>;
  OrganiserUsersMaxFields?: OrganiserUsersMaxFieldsResolvers<ContextType>;
  OrganiserUsersMinFields?: OrganiserUsersMinFieldsResolvers<ContextType>;
  OrganiserUsersMutationResponse?: OrganiserUsersMutationResponseResolvers<ContextType>;
  OrganiserUsersStddevFields?: OrganiserUsersStddevFieldsResolvers<ContextType>;
  OrganiserUsersStddev_popFields?: OrganiserUsersStddev_PopFieldsResolvers<ContextType>;
  OrganiserUsersStddev_sampFields?: OrganiserUsersStddev_SampFieldsResolvers<ContextType>;
  OrganiserUsersSumFields?: OrganiserUsersSumFieldsResolvers<ContextType>;
  OrganiserUsersVar_popFields?: OrganiserUsersVar_PopFieldsResolvers<ContextType>;
  OrganiserUsersVar_sampFields?: OrganiserUsersVar_SampFieldsResolvers<ContextType>;
  OrganiserUsersVarianceFields?: OrganiserUsersVarianceFieldsResolvers<ContextType>;
  Organisers?: OrganisersResolvers<ContextType>;
  OrganisersAggregate?: OrganisersAggregateResolvers<ContextType>;
  OrganisersAggregateFields?: OrganisersAggregateFieldsResolvers<ContextType>;
  OrganisersAvgFields?: OrganisersAvgFieldsResolvers<ContextType>;
  OrganisersMaxFields?: OrganisersMaxFieldsResolvers<ContextType>;
  OrganisersMinFields?: OrganisersMinFieldsResolvers<ContextType>;
  OrganisersMutationResponse?: OrganisersMutationResponseResolvers<ContextType>;
  OrganisersStddevFields?: OrganisersStddevFieldsResolvers<ContextType>;
  OrganisersStddev_popFields?: OrganisersStddev_PopFieldsResolvers<ContextType>;
  OrganisersStddev_sampFields?: OrganisersStddev_SampFieldsResolvers<ContextType>;
  OrganisersSumFields?: OrganisersSumFieldsResolvers<ContextType>;
  OrganisersVar_popFields?: OrganisersVar_PopFieldsResolvers<ContextType>;
  OrganisersVar_sampFields?: OrganisersVar_SampFieldsResolvers<ContextType>;
  OrganisersVarianceFields?: OrganisersVarianceFieldsResolvers<ContextType>;
  PaymentMethodBanks?: PaymentMethodBanksResolvers<ContextType>;
  PaymentMethodBanksAggregate?: PaymentMethodBanksAggregateResolvers<ContextType>;
  PaymentMethodBanksAggregateFields?: PaymentMethodBanksAggregateFieldsResolvers<ContextType>;
  PaymentMethodBanksAvgFields?: PaymentMethodBanksAvgFieldsResolvers<ContextType>;
  PaymentMethodBanksMaxFields?: PaymentMethodBanksMaxFieldsResolvers<ContextType>;
  PaymentMethodBanksMinFields?: PaymentMethodBanksMinFieldsResolvers<ContextType>;
  PaymentMethodBanksMutationResponse?: PaymentMethodBanksMutationResponseResolvers<ContextType>;
  PaymentMethodBanksStddevFields?: PaymentMethodBanksStddevFieldsResolvers<ContextType>;
  PaymentMethodBanksStddev_popFields?: PaymentMethodBanksStddev_PopFieldsResolvers<ContextType>;
  PaymentMethodBanksStddev_sampFields?: PaymentMethodBanksStddev_SampFieldsResolvers<ContextType>;
  PaymentMethodBanksSumFields?: PaymentMethodBanksSumFieldsResolvers<ContextType>;
  PaymentMethodBanksVar_popFields?: PaymentMethodBanksVar_PopFieldsResolvers<ContextType>;
  PaymentMethodBanksVar_sampFields?: PaymentMethodBanksVar_SampFieldsResolvers<ContextType>;
  PaymentMethodBanksVarianceFields?: PaymentMethodBanksVarianceFieldsResolvers<ContextType>;
  PaymentMethods?: PaymentMethodsResolvers<ContextType>;
  PaymentMethodsAggregate?: PaymentMethodsAggregateResolvers<ContextType>;
  PaymentMethodsAggregateFields?: PaymentMethodsAggregateFieldsResolvers<ContextType>;
  PaymentMethodsAvgFields?: PaymentMethodsAvgFieldsResolvers<ContextType>;
  PaymentMethodsMaxFields?: PaymentMethodsMaxFieldsResolvers<ContextType>;
  PaymentMethodsMinFields?: PaymentMethodsMinFieldsResolvers<ContextType>;
  PaymentMethodsMutationResponse?: PaymentMethodsMutationResponseResolvers<ContextType>;
  PaymentMethodsStddevFields?: PaymentMethodsStddevFieldsResolvers<ContextType>;
  PaymentMethodsStddev_popFields?: PaymentMethodsStddev_PopFieldsResolvers<ContextType>;
  PaymentMethodsStddev_sampFields?: PaymentMethodsStddev_SampFieldsResolvers<ContextType>;
  PaymentMethodsSumFields?: PaymentMethodsSumFieldsResolvers<ContextType>;
  PaymentMethodsVar_popFields?: PaymentMethodsVar_PopFieldsResolvers<ContextType>;
  PaymentMethodsVar_sampFields?: PaymentMethodsVar_SampFieldsResolvers<ContextType>;
  PaymentMethodsVarianceFields?: PaymentMethodsVarianceFieldsResolvers<ContextType>;
  Payments?: PaymentsResolvers<ContextType>;
  PaymentsAggregate?: PaymentsAggregateResolvers<ContextType>;
  PaymentsAggregateFields?: PaymentsAggregateFieldsResolvers<ContextType>;
  PaymentsAvgFields?: PaymentsAvgFieldsResolvers<ContextType>;
  PaymentsMaxFields?: PaymentsMaxFieldsResolvers<ContextType>;
  PaymentsMinFields?: PaymentsMinFieldsResolvers<ContextType>;
  PaymentsMutationResponse?: PaymentsMutationResponseResolvers<ContextType>;
  PaymentsStddevFields?: PaymentsStddevFieldsResolvers<ContextType>;
  PaymentsStddev_popFields?: PaymentsStddev_PopFieldsResolvers<ContextType>;
  PaymentsStddev_sampFields?: PaymentsStddev_SampFieldsResolvers<ContextType>;
  PaymentsSumFields?: PaymentsSumFieldsResolvers<ContextType>;
  PaymentsVar_popFields?: PaymentsVar_PopFieldsResolvers<ContextType>;
  PaymentsVar_sampFields?: PaymentsVar_SampFieldsResolvers<ContextType>;
  PaymentsVarianceFields?: PaymentsVarianceFieldsResolvers<ContextType>;
  PendingTxs?: PendingTxsResolvers<ContextType>;
  PendingTxsAggregate?: PendingTxsAggregateResolvers<ContextType>;
  PendingTxsAggregateFields?: PendingTxsAggregateFieldsResolvers<ContextType>;
  PendingTxsAvgFields?: PendingTxsAvgFieldsResolvers<ContextType>;
  PendingTxsMaxFields?: PendingTxsMaxFieldsResolvers<ContextType>;
  PendingTxsMinFields?: PendingTxsMinFieldsResolvers<ContextType>;
  PendingTxsMutationResponse?: PendingTxsMutationResponseResolvers<ContextType>;
  PendingTxsStddevFields?: PendingTxsStddevFieldsResolvers<ContextType>;
  PendingTxsStddev_popFields?: PendingTxsStddev_PopFieldsResolvers<ContextType>;
  PendingTxsStddev_sampFields?: PendingTxsStddev_SampFieldsResolvers<ContextType>;
  PendingTxsSumFields?: PendingTxsSumFieldsResolvers<ContextType>;
  PendingTxsVar_popFields?: PendingTxsVar_PopFieldsResolvers<ContextType>;
  PendingTxsVar_sampFields?: PendingTxsVar_SampFieldsResolvers<ContextType>;
  PendingTxsVarianceFields?: PendingTxsVarianceFieldsResolvers<ContextType>;
  PlayerFollowers?: PlayerFollowersResolvers<ContextType>;
  PlayerFollowersAggregate?: PlayerFollowersAggregateResolvers<ContextType>;
  PlayerFollowersAggregateFields?: PlayerFollowersAggregateFieldsResolvers<ContextType>;
  PlayerFollowersAvgFields?: PlayerFollowersAvgFieldsResolvers<ContextType>;
  PlayerFollowersMaxFields?: PlayerFollowersMaxFieldsResolvers<ContextType>;
  PlayerFollowersMinFields?: PlayerFollowersMinFieldsResolvers<ContextType>;
  PlayerFollowersMutationResponse?: PlayerFollowersMutationResponseResolvers<ContextType>;
  PlayerFollowersStddevFields?: PlayerFollowersStddevFieldsResolvers<ContextType>;
  PlayerFollowersStddev_popFields?: PlayerFollowersStddev_PopFieldsResolvers<ContextType>;
  PlayerFollowersStddev_sampFields?: PlayerFollowersStddev_SampFieldsResolvers<ContextType>;
  PlayerFollowersSumFields?: PlayerFollowersSumFieldsResolvers<ContextType>;
  PlayerFollowersVar_popFields?: PlayerFollowersVar_PopFieldsResolvers<ContextType>;
  PlayerFollowersVar_sampFields?: PlayerFollowersVar_SampFieldsResolvers<ContextType>;
  PlayerFollowersVarianceFields?: PlayerFollowersVarianceFieldsResolvers<ContextType>;
  PlayerFollowersView?: PlayerFollowersViewResolvers<ContextType>;
  PlayerFollowersViewAggregate?: PlayerFollowersViewAggregateResolvers<ContextType>;
  PlayerFollowersViewAggregateFields?: PlayerFollowersViewAggregateFieldsResolvers<ContextType>;
  PlayerFollowersViewAvgFields?: PlayerFollowersViewAvgFieldsResolvers<ContextType>;
  PlayerFollowersViewMaxFields?: PlayerFollowersViewMaxFieldsResolvers<ContextType>;
  PlayerFollowersViewMinFields?: PlayerFollowersViewMinFieldsResolvers<ContextType>;
  PlayerFollowersViewStddevFields?: PlayerFollowersViewStddevFieldsResolvers<ContextType>;
  PlayerFollowersViewStddev_popFields?: PlayerFollowersViewStddev_PopFieldsResolvers<ContextType>;
  PlayerFollowersViewStddev_sampFields?: PlayerFollowersViewStddev_SampFieldsResolvers<ContextType>;
  PlayerFollowersViewSumFields?: PlayerFollowersViewSumFieldsResolvers<ContextType>;
  PlayerFollowersViewVar_popFields?: PlayerFollowersViewVar_PopFieldsResolvers<ContextType>;
  PlayerFollowersViewVar_sampFields?: PlayerFollowersViewVar_SampFieldsResolvers<ContextType>;
  PlayerFollowersViewVarianceFields?: PlayerFollowersViewVarianceFieldsResolvers<ContextType>;
  PlayerShares?: PlayerSharesResolvers<ContextType>;
  PlayerSharesAggregate?: PlayerSharesAggregateResolvers<ContextType>;
  PlayerSharesAggregateFields?: PlayerSharesAggregateFieldsResolvers<ContextType>;
  PlayerSharesAvgFields?: PlayerSharesAvgFieldsResolvers<ContextType>;
  PlayerSharesBought?: PlayerSharesBoughtResolvers<ContextType>;
  PlayerSharesBoughtAggregate?: PlayerSharesBoughtAggregateResolvers<ContextType>;
  PlayerSharesBoughtAggregateFields?: PlayerSharesBoughtAggregateFieldsResolvers<ContextType>;
  PlayerSharesBoughtAvgFields?: PlayerSharesBoughtAvgFieldsResolvers<ContextType>;
  PlayerSharesBoughtMaxFields?: PlayerSharesBoughtMaxFieldsResolvers<ContextType>;
  PlayerSharesBoughtMinFields?: PlayerSharesBoughtMinFieldsResolvers<ContextType>;
  PlayerSharesBoughtStddevFields?: PlayerSharesBoughtStddevFieldsResolvers<ContextType>;
  PlayerSharesBoughtStddev_popFields?: PlayerSharesBoughtStddev_PopFieldsResolvers<ContextType>;
  PlayerSharesBoughtStddev_sampFields?: PlayerSharesBoughtStddev_SampFieldsResolvers<ContextType>;
  PlayerSharesBoughtSumFields?: PlayerSharesBoughtSumFieldsResolvers<ContextType>;
  PlayerSharesBoughtVar_popFields?: PlayerSharesBoughtVar_PopFieldsResolvers<ContextType>;
  PlayerSharesBoughtVar_sampFields?: PlayerSharesBoughtVar_SampFieldsResolvers<ContextType>;
  PlayerSharesBoughtVarianceFields?: PlayerSharesBoughtVarianceFieldsResolvers<ContextType>;
  PlayerSharesMaxFields?: PlayerSharesMaxFieldsResolvers<ContextType>;
  PlayerSharesMinFields?: PlayerSharesMinFieldsResolvers<ContextType>;
  PlayerSharesMutationResponse?: PlayerSharesMutationResponseResolvers<ContextType>;
  PlayerSharesStddevFields?: PlayerSharesStddevFieldsResolvers<ContextType>;
  PlayerSharesStddev_popFields?: PlayerSharesStddev_PopFieldsResolvers<ContextType>;
  PlayerSharesStddev_sampFields?: PlayerSharesStddev_SampFieldsResolvers<ContextType>;
  PlayerSharesSumFields?: PlayerSharesSumFieldsResolvers<ContextType>;
  PlayerSharesVar_popFields?: PlayerSharesVar_PopFieldsResolvers<ContextType>;
  PlayerSharesVar_sampFields?: PlayerSharesVar_SampFieldsResolvers<ContextType>;
  PlayerSharesVarianceFields?: PlayerSharesVarianceFieldsResolvers<ContextType>;
  Players?: PlayersResolvers<ContextType>;
  PlayersAggregate?: PlayersAggregateResolvers<ContextType>;
  PlayersAggregateFields?: PlayersAggregateFieldsResolvers<ContextType>;
  PlayersAvgFields?: PlayersAvgFieldsResolvers<ContextType>;
  PlayersMaxFields?: PlayersMaxFieldsResolvers<ContextType>;
  PlayersMinFields?: PlayersMinFieldsResolvers<ContextType>;
  PlayersMutationResponse?: PlayersMutationResponseResolvers<ContextType>;
  PlayersStddevFields?: PlayersStddevFieldsResolvers<ContextType>;
  PlayersStddev_popFields?: PlayersStddev_PopFieldsResolvers<ContextType>;
  PlayersStddev_sampFields?: PlayersStddev_SampFieldsResolvers<ContextType>;
  PlayersSumFields?: PlayersSumFieldsResolvers<ContextType>;
  PlayersVar_popFields?: PlayersVar_PopFieldsResolvers<ContextType>;
  PlayersVar_sampFields?: PlayersVar_SampFieldsResolvers<ContextType>;
  PlayersVarianceFields?: PlayersVarianceFieldsResolvers<ContextType>;
  StatEvents?: StatEventsResolvers<ContextType>;
  StatEventsAggregate?: StatEventsAggregateResolvers<ContextType>;
  StatEventsAggregateFields?: StatEventsAggregateFieldsResolvers<ContextType>;
  StatEventsAvgFields?: StatEventsAvgFieldsResolvers<ContextType>;
  StatEventsMaxFields?: StatEventsMaxFieldsResolvers<ContextType>;
  StatEventsMinFields?: StatEventsMinFieldsResolvers<ContextType>;
  StatEventsMutationResponse?: StatEventsMutationResponseResolvers<ContextType>;
  StatEventsStddevFields?: StatEventsStddevFieldsResolvers<ContextType>;
  StatEventsStddev_popFields?: StatEventsStddev_PopFieldsResolvers<ContextType>;
  StatEventsStddev_sampFields?: StatEventsStddev_SampFieldsResolvers<ContextType>;
  StatEventsSumFields?: StatEventsSumFieldsResolvers<ContextType>;
  StatEventsVar_popFields?: StatEventsVar_PopFieldsResolvers<ContextType>;
  StatEventsVar_sampFields?: StatEventsVar_SampFieldsResolvers<ContextType>;
  StatEventsVarianceFields?: StatEventsVarianceFieldsResolvers<ContextType>;
  StatPlayers?: StatPlayersResolvers<ContextType>;
  StatPlayersAggregate?: StatPlayersAggregateResolvers<ContextType>;
  StatPlayersAggregateFields?: StatPlayersAggregateFieldsResolvers<ContextType>;
  StatPlayersAvgFields?: StatPlayersAvgFieldsResolvers<ContextType>;
  StatPlayersMaxFields?: StatPlayersMaxFieldsResolvers<ContextType>;
  StatPlayersMinFields?: StatPlayersMinFieldsResolvers<ContextType>;
  StatPlayersMutationResponse?: StatPlayersMutationResponseResolvers<ContextType>;
  StatPlayersStddevFields?: StatPlayersStddevFieldsResolvers<ContextType>;
  StatPlayersStddev_popFields?: StatPlayersStddev_PopFieldsResolvers<ContextType>;
  StatPlayersStddev_sampFields?: StatPlayersStddev_SampFieldsResolvers<ContextType>;
  StatPlayersSumFields?: StatPlayersSumFieldsResolvers<ContextType>;
  StatPlayersVar_popFields?: StatPlayersVar_PopFieldsResolvers<ContextType>;
  StatPlayersVar_sampFields?: StatPlayersVar_SampFieldsResolvers<ContextType>;
  StatPlayersVarianceFields?: StatPlayersVarianceFieldsResolvers<ContextType>;
  StatTopPlayers?: StatTopPlayersResolvers<ContextType>;
  StatTopPlayersAggregate?: StatTopPlayersAggregateResolvers<ContextType>;
  StatTopPlayersAggregateFields?: StatTopPlayersAggregateFieldsResolvers<ContextType>;
  StatTopPlayersAvgFields?: StatTopPlayersAvgFieldsResolvers<ContextType>;
  StatTopPlayersMaxFields?: StatTopPlayersMaxFieldsResolvers<ContextType>;
  StatTopPlayersMinFields?: StatTopPlayersMinFieldsResolvers<ContextType>;
  StatTopPlayersStddevFields?: StatTopPlayersStddevFieldsResolvers<ContextType>;
  StatTopPlayersStddev_popFields?: StatTopPlayersStddev_PopFieldsResolvers<ContextType>;
  StatTopPlayersStddev_sampFields?: StatTopPlayersStddev_SampFieldsResolvers<ContextType>;
  StatTopPlayersSumFields?: StatTopPlayersSumFieldsResolvers<ContextType>;
  StatTopPlayersVar_popFields?: StatTopPlayersVar_PopFieldsResolvers<ContextType>;
  StatTopPlayersVar_sampFields?: StatTopPlayersVar_SampFieldsResolvers<ContextType>;
  StatTopPlayersVarianceFields?: StatTopPlayersVarianceFieldsResolvers<ContextType>;
  StatTopYearPlayers?: StatTopYearPlayersResolvers<ContextType>;
  StatTopYearPlayersAggregate?: StatTopYearPlayersAggregateResolvers<ContextType>;
  StatTopYearPlayersAggregateFields?: StatTopYearPlayersAggregateFieldsResolvers<ContextType>;
  StatTopYearPlayersAvgFields?: StatTopYearPlayersAvgFieldsResolvers<ContextType>;
  StatTopYearPlayersMaxFields?: StatTopYearPlayersMaxFieldsResolvers<ContextType>;
  StatTopYearPlayersMinFields?: StatTopYearPlayersMinFieldsResolvers<ContextType>;
  StatTopYearPlayersStddevFields?: StatTopYearPlayersStddevFieldsResolvers<ContextType>;
  StatTopYearPlayersStddev_popFields?: StatTopYearPlayersStddev_PopFieldsResolvers<ContextType>;
  StatTopYearPlayersStddev_sampFields?: StatTopYearPlayersStddev_SampFieldsResolvers<ContextType>;
  StatTopYearPlayersSumFields?: StatTopYearPlayersSumFieldsResolvers<ContextType>;
  StatTopYearPlayersVar_popFields?: StatTopYearPlayersVar_PopFieldsResolvers<ContextType>;
  StatTopYearPlayersVar_sampFields?: StatTopYearPlayersVar_SampFieldsResolvers<ContextType>;
  StatTopYearPlayersVarianceFields?: StatTopYearPlayersVarianceFieldsResolvers<ContextType>;
  StatTournamentResults?: StatTournamentResultsResolvers<ContextType>;
  StatTournamentResultsAggregate?: StatTournamentResultsAggregateResolvers<ContextType>;
  StatTournamentResultsAggregateFields?: StatTournamentResultsAggregateFieldsResolvers<ContextType>;
  StatTournamentResultsAvgFields?: StatTournamentResultsAvgFieldsResolvers<ContextType>;
  StatTournamentResultsMaxFields?: StatTournamentResultsMaxFieldsResolvers<ContextType>;
  StatTournamentResultsMinFields?: StatTournamentResultsMinFieldsResolvers<ContextType>;
  StatTournamentResultsMutationResponse?: StatTournamentResultsMutationResponseResolvers<ContextType>;
  StatTournamentResultsStddevFields?: StatTournamentResultsStddevFieldsResolvers<ContextType>;
  StatTournamentResultsStddev_popFields?: StatTournamentResultsStddev_PopFieldsResolvers<ContextType>;
  StatTournamentResultsStddev_sampFields?: StatTournamentResultsStddev_SampFieldsResolvers<ContextType>;
  StatTournamentResultsSumFields?: StatTournamentResultsSumFieldsResolvers<ContextType>;
  StatTournamentResultsVar_popFields?: StatTournamentResultsVar_PopFieldsResolvers<ContextType>;
  StatTournamentResultsVar_sampFields?: StatTournamentResultsVar_SampFieldsResolvers<ContextType>;
  StatTournamentResultsVarianceFields?: StatTournamentResultsVarianceFieldsResolvers<ContextType>;
  StatTournaments?: StatTournamentsResolvers<ContextType>;
  StatTournamentsAggregate?: StatTournamentsAggregateResolvers<ContextType>;
  StatTournamentsAggregateFields?: StatTournamentsAggregateFieldsResolvers<ContextType>;
  StatTournamentsAvgFields?: StatTournamentsAvgFieldsResolvers<ContextType>;
  StatTournamentsMaxFields?: StatTournamentsMaxFieldsResolvers<ContextType>;
  StatTournamentsMinFields?: StatTournamentsMinFieldsResolvers<ContextType>;
  StatTournamentsMutationResponse?: StatTournamentsMutationResponseResolvers<ContextType>;
  StatTournamentsStddevFields?: StatTournamentsStddevFieldsResolvers<ContextType>;
  StatTournamentsStddev_popFields?: StatTournamentsStddev_PopFieldsResolvers<ContextType>;
  StatTournamentsStddev_sampFields?: StatTournamentsStddev_SampFieldsResolvers<ContextType>;
  StatTournamentsSumFields?: StatTournamentsSumFieldsResolvers<ContextType>;
  StatTournamentsVar_popFields?: StatTournamentsVar_PopFieldsResolvers<ContextType>;
  StatTournamentsVar_sampFields?: StatTournamentsVar_SampFieldsResolvers<ContextType>;
  StatTournamentsVarianceFields?: StatTournamentsVarianceFieldsResolvers<ContextType>;
  StatusGames?: StatusGamesResolvers<ContextType>;
  StatusGamesAggregate?: StatusGamesAggregateResolvers<ContextType>;
  StatusGamesAggregateFields?: StatusGamesAggregateFieldsResolvers<ContextType>;
  StatusGamesMaxFields?: StatusGamesMaxFieldsResolvers<ContextType>;
  StatusGamesMinFields?: StatusGamesMinFieldsResolvers<ContextType>;
  StatusGamesMutationResponse?: StatusGamesMutationResponseResolvers<ContextType>;
  StatusOutput?: StatusOutputResolvers<ContextType>;
  Statuses?: StatusesResolvers<ContextType>;
  StatusesAggregate?: StatusesAggregateResolvers<ContextType>;
  StatusesAggregateFields?: StatusesAggregateFieldsResolvers<ContextType>;
  StatusesMaxFields?: StatusesMaxFieldsResolvers<ContextType>;
  StatusesMinFields?: StatusesMinFieldsResolvers<ContextType>;
  StatusesMutationResponse?: StatusesMutationResponseResolvers<ContextType>;
  Tickets?: TicketsResolvers<ContextType>;
  TicketsAggregate?: TicketsAggregateResolvers<ContextType>;
  TicketsAggregateFields?: TicketsAggregateFieldsResolvers<ContextType>;
  TicketsAvgFields?: TicketsAvgFieldsResolvers<ContextType>;
  TicketsMaxFields?: TicketsMaxFieldsResolvers<ContextType>;
  TicketsMinFields?: TicketsMinFieldsResolvers<ContextType>;
  TicketsMutationResponse?: TicketsMutationResponseResolvers<ContextType>;
  TicketsStatus?: TicketsStatusResolvers<ContextType>;
  TicketsStatusAggregate?: TicketsStatusAggregateResolvers<ContextType>;
  TicketsStatusAggregateFields?: TicketsStatusAggregateFieldsResolvers<ContextType>;
  TicketsStatusMaxFields?: TicketsStatusMaxFieldsResolvers<ContextType>;
  TicketsStatusMinFields?: TicketsStatusMinFieldsResolvers<ContextType>;
  TicketsStatusMutationResponse?: TicketsStatusMutationResponseResolvers<ContextType>;
  TicketsStddevFields?: TicketsStddevFieldsResolvers<ContextType>;
  TicketsStddev_popFields?: TicketsStddev_PopFieldsResolvers<ContextType>;
  TicketsStddev_sampFields?: TicketsStddev_SampFieldsResolvers<ContextType>;
  TicketsSumFields?: TicketsSumFieldsResolvers<ContextType>;
  TicketsVar_popFields?: TicketsVar_PopFieldsResolvers<ContextType>;
  TicketsVar_sampFields?: TicketsVar_SampFieldsResolvers<ContextType>;
  TicketsVarianceFields?: TicketsVarianceFieldsResolvers<ContextType>;
  TournamentBalances?: TournamentBalancesResolvers<ContextType>;
  TournamentBalancesAggregate?: TournamentBalancesAggregateResolvers<ContextType>;
  TournamentBalancesAggregateFields?: TournamentBalancesAggregateFieldsResolvers<ContextType>;
  TournamentBalancesAvgFields?: TournamentBalancesAvgFieldsResolvers<ContextType>;
  TournamentBalancesMaxFields?: TournamentBalancesMaxFieldsResolvers<ContextType>;
  TournamentBalancesMinFields?: TournamentBalancesMinFieldsResolvers<ContextType>;
  TournamentBalancesStddevFields?: TournamentBalancesStddevFieldsResolvers<ContextType>;
  TournamentBalancesStddev_popFields?: TournamentBalancesStddev_PopFieldsResolvers<ContextType>;
  TournamentBalancesStddev_sampFields?: TournamentBalancesStddev_SampFieldsResolvers<ContextType>;
  TournamentBalancesSumFields?: TournamentBalancesSumFieldsResolvers<ContextType>;
  TournamentBalancesVar_popFields?: TournamentBalancesVar_PopFieldsResolvers<ContextType>;
  TournamentBalancesVar_sampFields?: TournamentBalancesVar_SampFieldsResolvers<ContextType>;
  TournamentBalancesVarianceFields?: TournamentBalancesVarianceFieldsResolvers<ContextType>;
  TournamentResultStatuses?: TournamentResultStatusesResolvers<ContextType>;
  TournamentResultStatusesAggregate?: TournamentResultStatusesAggregateResolvers<ContextType>;
  TournamentResultStatusesAggregateFields?: TournamentResultStatusesAggregateFieldsResolvers<ContextType>;
  TournamentResultStatusesMaxFields?: TournamentResultStatusesMaxFieldsResolvers<ContextType>;
  TournamentResultStatusesMinFields?: TournamentResultStatusesMinFieldsResolvers<ContextType>;
  TournamentResultStatusesMutationResponse?: TournamentResultStatusesMutationResponseResolvers<ContextType>;
  TournamentResults?: TournamentResultsResolvers<ContextType>;
  TournamentResultsAggregate?: TournamentResultsAggregateResolvers<ContextType>;
  TournamentResultsAggregateFields?: TournamentResultsAggregateFieldsResolvers<ContextType>;
  TournamentResultsAvgFields?: TournamentResultsAvgFieldsResolvers<ContextType>;
  TournamentResultsMaxFields?: TournamentResultsMaxFieldsResolvers<ContextType>;
  TournamentResultsMinFields?: TournamentResultsMinFieldsResolvers<ContextType>;
  TournamentResultsMutationResponse?: TournamentResultsMutationResponseResolvers<ContextType>;
  TournamentResultsStddevFields?: TournamentResultsStddevFieldsResolvers<ContextType>;
  TournamentResultsStddev_popFields?: TournamentResultsStddev_PopFieldsResolvers<ContextType>;
  TournamentResultsStddev_sampFields?: TournamentResultsStddev_SampFieldsResolvers<ContextType>;
  TournamentResultsSumFields?: TournamentResultsSumFieldsResolvers<ContextType>;
  TournamentResultsVar_popFields?: TournamentResultsVar_PopFieldsResolvers<ContextType>;
  TournamentResultsVar_sampFields?: TournamentResultsVar_SampFieldsResolvers<ContextType>;
  TournamentResultsVarianceFields?: TournamentResultsVarianceFieldsResolvers<ContextType>;
  Tournaments?: TournamentsResolvers<ContextType>;
  TournamentsAggregate?: TournamentsAggregateResolvers<ContextType>;
  TournamentsAggregateFields?: TournamentsAggregateFieldsResolvers<ContextType>;
  TournamentsAvgFields?: TournamentsAvgFieldsResolvers<ContextType>;
  TournamentsMaxFields?: TournamentsMaxFieldsResolvers<ContextType>;
  TournamentsMinFields?: TournamentsMinFieldsResolvers<ContextType>;
  TournamentsMutationResponse?: TournamentsMutationResponseResolvers<ContextType>;
  TournamentsStddevFields?: TournamentsStddevFieldsResolvers<ContextType>;
  TournamentsStddev_popFields?: TournamentsStddev_PopFieldsResolvers<ContextType>;
  TournamentsStddev_sampFields?: TournamentsStddev_SampFieldsResolvers<ContextType>;
  TournamentsSumFields?: TournamentsSumFieldsResolvers<ContextType>;
  TournamentsVar_popFields?: TournamentsVar_PopFieldsResolvers<ContextType>;
  TournamentsVar_sampFields?: TournamentsVar_SampFieldsResolvers<ContextType>;
  TournamentsVarianceFields?: TournamentsVarianceFieldsResolvers<ContextType>;
  TransactionUserTypes?: TransactionUserTypesResolvers<ContextType>;
  TransactionUserTypesAggregate?: TransactionUserTypesAggregateResolvers<ContextType>;
  TransactionUserTypesAggregateFields?: TransactionUserTypesAggregateFieldsResolvers<ContextType>;
  TransactionUserTypesMaxFields?: TransactionUserTypesMaxFieldsResolvers<ContextType>;
  TransactionUserTypesMinFields?: TransactionUserTypesMinFieldsResolvers<ContextType>;
  TransactionUserTypesMutationResponse?: TransactionUserTypesMutationResponseResolvers<ContextType>;
  Transactions?: TransactionsResolvers<ContextType>;
  TransactionsAggregate?: TransactionsAggregateResolvers<ContextType>;
  TransactionsAggregateFields?: TransactionsAggregateFieldsResolvers<ContextType>;
  TransactionsAvgFields?: TransactionsAvgFieldsResolvers<ContextType>;
  TransactionsMaxFields?: TransactionsMaxFieldsResolvers<ContextType>;
  TransactionsMinFields?: TransactionsMinFieldsResolvers<ContextType>;
  TransactionsMutationResponse?: TransactionsMutationResponseResolvers<ContextType>;
  TransactionsStddevFields?: TransactionsStddevFieldsResolvers<ContextType>;
  TransactionsStddev_popFields?: TransactionsStddev_PopFieldsResolvers<ContextType>;
  TransactionsStddev_sampFields?: TransactionsStddev_SampFieldsResolvers<ContextType>;
  TransactionsSumFields?: TransactionsSumFieldsResolvers<ContextType>;
  TransactionsVar_popFields?: TransactionsVar_PopFieldsResolvers<ContextType>;
  TransactionsVar_sampFields?: TransactionsVar_SampFieldsResolvers<ContextType>;
  TransactionsVarianceFields?: TransactionsVarianceFieldsResolvers<ContextType>;
  UserBalances?: UserBalancesResolvers<ContextType>;
  UserBalancesAggregate?: UserBalancesAggregateResolvers<ContextType>;
  UserBalancesAggregateFields?: UserBalancesAggregateFieldsResolvers<ContextType>;
  UserBalancesAvgFields?: UserBalancesAvgFieldsResolvers<ContextType>;
  UserBalancesMaxFields?: UserBalancesMaxFieldsResolvers<ContextType>;
  UserBalancesMinFields?: UserBalancesMinFieldsResolvers<ContextType>;
  UserBalancesStddevFields?: UserBalancesStddevFieldsResolvers<ContextType>;
  UserBalancesStddev_popFields?: UserBalancesStddev_PopFieldsResolvers<ContextType>;
  UserBalancesStddev_sampFields?: UserBalancesStddev_SampFieldsResolvers<ContextType>;
  UserBalancesSumFields?: UserBalancesSumFieldsResolvers<ContextType>;
  UserBalancesVar_popFields?: UserBalancesVar_PopFieldsResolvers<ContextType>;
  UserBalancesVar_sampFields?: UserBalancesVar_SampFieldsResolvers<ContextType>;
  UserBalancesVarianceFields?: UserBalancesVarianceFieldsResolvers<ContextType>;
  UserOutput?: UserOutputResolvers<ContextType>;
  UserShareBuys?: UserShareBuysResolvers<ContextType>;
  UserShareBuysAggregate?: UserShareBuysAggregateResolvers<ContextType>;
  UserShareBuysAggregateFields?: UserShareBuysAggregateFieldsResolvers<ContextType>;
  UserShareBuysAvgFields?: UserShareBuysAvgFieldsResolvers<ContextType>;
  UserShareBuysMaxFields?: UserShareBuysMaxFieldsResolvers<ContextType>;
  UserShareBuysMinFields?: UserShareBuysMinFieldsResolvers<ContextType>;
  UserShareBuysMutationResponse?: UserShareBuysMutationResponseResolvers<ContextType>;
  UserShareBuysStddevFields?: UserShareBuysStddevFieldsResolvers<ContextType>;
  UserShareBuysStddev_popFields?: UserShareBuysStddev_PopFieldsResolvers<ContextType>;
  UserShareBuysStddev_sampFields?: UserShareBuysStddev_SampFieldsResolvers<ContextType>;
  UserShareBuysSumFields?: UserShareBuysSumFieldsResolvers<ContextType>;
  UserShareBuysVar_popFields?: UserShareBuysVar_PopFieldsResolvers<ContextType>;
  UserShareBuysVar_sampFields?: UserShareBuysVar_SampFieldsResolvers<ContextType>;
  UserShareBuysVarianceFields?: UserShareBuysVarianceFieldsResolvers<ContextType>;
  Users?: UsersResolvers<ContextType>;
  UsersAggregate?: UsersAggregateResolvers<ContextType>;
  UsersAggregateFields?: UsersAggregateFieldsResolvers<ContextType>;
  UsersAvgFields?: UsersAvgFieldsResolvers<ContextType>;
  UsersMaxFields?: UsersMaxFieldsResolvers<ContextType>;
  UsersMinFields?: UsersMinFieldsResolvers<ContextType>;
  UsersMutationResponse?: UsersMutationResponseResolvers<ContextType>;
  UsersStddevFields?: UsersStddevFieldsResolvers<ContextType>;
  UsersStddev_popFields?: UsersStddev_PopFieldsResolvers<ContextType>;
  UsersStddev_sampFields?: UsersStddev_SampFieldsResolvers<ContextType>;
  UsersSumFields?: UsersSumFieldsResolvers<ContextType>;
  UsersVar_popFields?: UsersVar_PopFieldsResolvers<ContextType>;
  UsersVar_sampFields?: UsersVar_SampFieldsResolvers<ContextType>;
  UsersVarianceFields?: UsersVarianceFieldsResolvers<ContextType>;
  VenueUsers?: VenueUsersResolvers<ContextType>;
  VenueUsersAggregate?: VenueUsersAggregateResolvers<ContextType>;
  VenueUsersAggregateFields?: VenueUsersAggregateFieldsResolvers<ContextType>;
  VenueUsersAvgFields?: VenueUsersAvgFieldsResolvers<ContextType>;
  VenueUsersMaxFields?: VenueUsersMaxFieldsResolvers<ContextType>;
  VenueUsersMinFields?: VenueUsersMinFieldsResolvers<ContextType>;
  VenueUsersMutationResponse?: VenueUsersMutationResponseResolvers<ContextType>;
  VenueUsersStddevFields?: VenueUsersStddevFieldsResolvers<ContextType>;
  VenueUsersStddev_popFields?: VenueUsersStddev_PopFieldsResolvers<ContextType>;
  VenueUsersStddev_sampFields?: VenueUsersStddev_SampFieldsResolvers<ContextType>;
  VenueUsersSumFields?: VenueUsersSumFieldsResolvers<ContextType>;
  VenueUsersVar_popFields?: VenueUsersVar_PopFieldsResolvers<ContextType>;
  VenueUsersVar_sampFields?: VenueUsersVar_SampFieldsResolvers<ContextType>;
  VenueUsersVarianceFields?: VenueUsersVarianceFieldsResolvers<ContextType>;
  Venues?: VenuesResolvers<ContextType>;
  VenuesAggregate?: VenuesAggregateResolvers<ContextType>;
  VenuesAggregateFields?: VenuesAggregateFieldsResolvers<ContextType>;
  VenuesAvgFields?: VenuesAvgFieldsResolvers<ContextType>;
  VenuesMaxFields?: VenuesMaxFieldsResolvers<ContextType>;
  VenuesMinFields?: VenuesMinFieldsResolvers<ContextType>;
  VenuesMutationResponse?: VenuesMutationResponseResolvers<ContextType>;
  VenuesStddevFields?: VenuesStddevFieldsResolvers<ContextType>;
  VenuesStddev_popFields?: VenuesStddev_PopFieldsResolvers<ContextType>;
  VenuesStddev_sampFields?: VenuesStddev_SampFieldsResolvers<ContextType>;
  VenuesSumFields?: VenuesSumFieldsResolvers<ContextType>;
  VenuesVar_popFields?: VenuesVar_PopFieldsResolvers<ContextType>;
  VenuesVar_sampFields?: VenuesVar_SampFieldsResolvers<ContextType>;
  VenuesVarianceFields?: VenuesVarianceFieldsResolvers<ContextType>;
  activate2FAOutput?: Activate2FaOutputResolvers<ContextType>;
  authLoginOutput?: AuthLoginOutputResolvers<ContextType>;
  authSignatureOutput?: AuthSignatureOutputResolvers<ContextType>;
  bigint?: GraphQLScalarType;
  date?: GraphQLScalarType;
  depositAddressOutput?: DepositAddressOutputResolvers<ContextType>;
  depositBankOutput?: DepositBankOutputResolvers<ContextType>;
  downloadFileOutput?: DownloadFileOutputResolvers<ContextType>;
  generalOutput?: GeneralOutputResolvers<ContextType>;
  jsonb?: GraphQLScalarType;
  mutation_root?: Mutation_RootResolvers<ContextType>;
  numeric?: GraphQLScalarType;
  playerShareResultOutput?: PlayerShareResultOutputResolvers<ContextType>;
  query_root?: Query_RootResolvers<ContextType>;
  subscription_root?: Subscription_RootResolvers<ContextType>;
  timestamp?: GraphQLScalarType;
  timestamptz?: GraphQLScalarType;
  uuid?: GraphQLScalarType;
};

export type DirectiveResolvers<ContextType = any> = {
  cached?: CachedDirectiveResolver<any, any, ContextType>;
  client?: ClientDirectiveResolver<any, any, ContextType>;
};
