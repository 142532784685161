import { Global, css } from '@emotion/react';
import {
  desktopDefaultFontSize,
  mobileDefaultFontSize,
  fallbackFonts,
} from './consts';

const GlobalStyles = () => (
  <Global
    styles={(theme) => css`
      html,
      body {
        max-width: 100%;
        min-height: 100vh;
        background: ${theme.getColor('bgColor')};
        font-size: ${desktopDefaultFontSize}px;
        font-weight: 400;
        font-family: Roboto, ${fallbackFonts};
        line-height: ${20 / desktopDefaultFontSize};
        color: ${theme.getColor('white')};
        letter-spacing: 0.25px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;

        ${theme.breakpoints.down('md')} {
          font-size: ${mobileDefaultFontSize}px;
          line-height: ${16 / mobileDefaultFontSize};
        }
      }

      *,
      *:before,
      *:after {
        box-sizing: border-box;
      }

      a {
        color: inherit;
        text-decoration: none;
        transition: ${theme.transitions.create(['color'], {
          duration: theme.transitions.duration.standard,
        })};
        &:hover {
          color: ${theme.getColor('primary')};
        }
      }

      img {
        display: block;
        max-width: 100%;
      }

      code {
        font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
          monospace;
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        margin: 0;
      }

      p {
        margin: 0;
      }

      ul {
        list-style-type: disc;
      }

      // TODO: remove once mui's ready
      .ReactModal__Content {
        max-width: 100%;
        display: flex;
        justify-content: center;
        outline: none !important;
      }

      .center-modal {
        position: absolute;
        top: 50%;
        left: 50%;
        right: auto;
        bottom: auto;
        margin-right: -50%;
        transform: translate(-50%, -50%);
        padding: 20px;
      }

      .center-modal-overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1000;
        background-color: rgba(70, 70, 70, 0.5);
      }
    `}
  />
);

export { GlobalStyles };
