import { FormLabel as DefFormLabel } from '@mui/material';
import { styled } from '@mui/material/styles';

export const HorizontalFormControlLabel = styled(DefFormLabel)`
  flex: 0 0 124px;
  display: flex;
  align-items: center;
  height: 52px;
  margin-right: 16px;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: 100%;
    height: auto;
    flex: 0 0 auto;
    margin-bottom: 8px;
  }
`;
