import { SvgIcon, SvgIconProps } from '@mui/material';

export type CalendarIconProps = SvgIconProps;

const CalendarIcon = (props: CalendarIconProps) => (
  <SvgIcon {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M21.333 2.4H20V0h-2.667v2.4H6.667V0H4v2.4H2.667C1.187 2.4 0 3.48 0 4.8v16.8C0 22.92 1.187 24 2.667 24h18.666C22.8 24 24 22.92 24 21.6V4.8c0-1.32-1.2-2.4-2.667-2.4zm0 19.2H2.667V8.4h18.666zM4.667 13.2c0-1.656 1.493-3 3.333-3s3.333 1.344 3.333 3-1.493 3-3.333 3-3.333-1.344-3.333-3z"
    />
  </SvgIcon>
);

export { CalendarIcon };
