import { SvgIcon, SvgIconProps } from '@mui/material';

export type UserLoginIconProps = SvgIconProps;

const UserLoginIcon = (props: UserLoginIconProps) => (
  <SvgIcon {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12 0C8.685 0 6 2.685 6 6s2.685 6 6 6 6-2.685 6-6-2.685-6-6-6zm3 6c0-1.65-1.35-3-3-3S9 4.35 9 6s1.35 3 3 3 3-1.35 3-3zm6 13.5c-.3-1.065-4.95-3-9-3s-8.7 1.935-9 3.015V21h18zm-21 0c0-3.99 7.995-6 12-6s12 2.01 12 6V24H0z"
    />
  </SvgIcon>
);

export { UserLoginIcon };
