import { useLayoutEffect, useMemo, useState, useCallback } from 'react';
import { omitBy } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import Link from 'next/link';
import { useSnackbar } from 'notistack';
import {
  useCreatePlayerShareMutation,
  useSellPlayerShareConfirmModalDataQuery,
  CreatePlayerShareMutationVariables,
} from '@/apollo/operations';
import { GeneralStatus } from '@/apollo/operations';
import { Button } from '@/components/buttons';
import { PageSpinner } from '@/components/common-elements';
import { useModals, ModalsData } from '@/providers';
import { resetListStyles } from '@/styles';
import { SiteLinks, externalLinkProps } from '@/utils/consts';
import dayjs from '@/utils/dayjs';
import { getPercentages } from '@/utils/helpers';
import { TranslationKeys } from '@/utils/i18n';
import { sendSentryError } from '@/utils/sentry';
import { FormControlLabel, Checkbox } from '@mui/material';
import { styled, css } from '@mui/material/styles';
import { Text as DefText } from '../texts';

const SellPlayerSharesConfirmModal = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { dispatch, data } = useModals<'sellPlayerSharesConfirm'>();
  const [userCheckedEverything, setUserCheckedEverything] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);

  const {
    loading,
    error,
    data: modalQueryData,
  } = useSellPlayerShareConfirmModalDataQuery({
    skip: !data?.tournamentId,
    variables: {
      tournamentId: data?.tournamentId,
    },
  });

  const infoItems = useMemo(() => {
    if (!data) return null;

    if (!modalQueryData?.tournament) {
      return {
        eventName: '',
        tournamentName: '',
        startTime: '',
        location: '',
        ...data,
      };
    }
    return {
      eventName: modalQueryData.tournament.event?.name ?? 'Event name',
      tournamentName: modalQueryData.tournament.name,
      startTime: modalQueryData.tournament.date,
      location:
        modalQueryData.tournament.location ??
        modalQueryData.tournament.event?.location,
      ...data,
    };
  }, [data, modalQueryData]);

  const showError = useCallback(
    (e: Error) => {
      sendSentryError(e);
      enqueueSnackbar(t('SELL_PLAYER_SHARE_CONFIRM_MODAL__mutationError'), {
        variant: 'error',
      });
    },
    [t, enqueueSnackbar]
  );

  const [createPlayerShare, { loading: creatingPlayerShare }] =
    useCreatePlayerShareMutation({
      fetchPolicy: 'no-cache',
      onCompleted: ({ submitPlayerShareCreate }) => {
        if (submitPlayerShareCreate?.status === GeneralStatus.Ok) {
          enqueueSnackbar(
            t('SELL_PLAYER_SHARE_CONFIRM_MODAL__mutationSuccess'),
            {
              variant: 'success',
            }
          );
          dispatch({
            type: 'closeModal',
          });
        } else {
          showError(
            new Error('createPlayerShare mutation response status not ok')
          );
        }
      },
      onError: (e) => {
        showError(e);
      },
    });

  const onConfirmPress = useCallback(() => {
    if (!data?.tournamentId) {
      showError(new Error('No tournament id in sell share confirm modal'));
      return;
    }

    const variables = omitBy(data, (value, key) => {
      if (key === 'shareDilution') return false;
      return !value;
    }) as CreatePlayerShareMutationVariables;

    return createPlayerShare({
      variables,
    });
  }, [data, showError, createPlayerShare]);

  useLayoutEffect(() => {
    dispatch({
      type: 'setWrapperProps',
      payload: {
        title: t('SELL_PLAYER_SHARE_CONFIRM_MODAL__title'),
      },
    });
  }, [dispatch, t]);

  if (loading && !modalQueryData) {
    return <PageSpinner />;
  }

  if (error || !infoItems) {
    return (
      <DefText align={'center'}>
        {t('SELL_PLAYER_SHARE_CONFIRM_MODAL__modalDataLoadError')}
      </DefText>
    );
  }

  const buttonDisabled = !(userCheckedEverything && termsAccepted);

  return (
    <Wrapper>
      <List>
        {recordsLabels.map(([key, label]) => {
          const value = infoItems[key];
          let renderValue;

          switch (key) {
            case 'startTime':
              renderValue = dayjs(value).format('D MMMM HH:mm');
              break;
            case 'sharesToSell':
              renderValue = getPercentages(value);
              break;
            case 'markUp':
              renderValue = value.toFixed(2);
              break;
            case 'shareDilution':
              renderValue = t(
                value
                  ? 'SELL_PLAYER_SHARE_CONFIRM_MODAL__shareDilutionYes'
                  : 'SELL_PLAYER_SHARE_CONFIRM_MODAL__shareDilutionNo'
              );
              break;
            case 'maxPerUserShare':
              renderValue = value
                ? getPercentages(value)
                : t('SELL_PLAYER_SHARE_CONFIRM_MODAL__limitSharesPerUserNo');
              break;
            case 'password':
              renderValue =
                value ||
                t('SELL_PLAYER_SHARE_CONFIRM_MODAL__passwordProtectNo');
              break;
            case 'note':
              renderValue =
                value || t('SELL_PLAYER_SHARE_CONFIRM_MODAL__emptyNote');
              break;
            default:
              renderValue = value;
          }
          return (
            <ListItem key={key}>
              <ListItemLabel component={'h3'} variant={'body-large'}>
                {t(label)}:
              </ListItemLabel>
              <ListItemValue variant={'title-medium'} weight={'semi-bold'}>
                {renderValue}
              </ListItemValue>
            </ListItem>
          );
        })}
      </List>
      <FormControlLabel
        css={css`
          margin-top: 16px;
        `}
        control={
          <Checkbox
            checked={userCheckedEverything}
            onChange={(event, checked) => setUserCheckedEverything(checked)}
          />
        }
        label={
          <CheckboxLabel>
            {t('SELL_PLAYER_SHARE_CONFIRM_MODAL__checkText')}
          </CheckboxLabel>
        }
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={termsAccepted}
            onChange={(event, checked) => setTermsAccepted(checked)}
          />
        }
        label={
          <CheckboxLabel>
            {t('SELL_PLAYER_SHARE_CONFIRM_MODAL__termsText')}{' '}
            <CheckboxLabelLink href={SiteLinks.TERMS} {...externalLinkProps}>
              {t('SELL_PLAYER_SHARE_CONFIRM_MODAL__termsLink')}
            </CheckboxLabelLink>
          </CheckboxLabel>
        }
      />
      <Button
        css={css`
          margin-top: 30px;
          align-self: center;
        `}
        loading={creatingPlayerShare}
        disabled={buttonDisabled}
        onClick={() => {
          !buttonDisabled && onConfirmPress();
        }}
      >
        {t('SELL_PLAYER_SHARE_CONFIRM_MODAL__submitButton', {
          percent: getPercentages(data?.sharesToSell),
        })}
      </Button>
    </Wrapper>
  );
};

type InfoKeys =
  | 'eventName'
  | 'tournamentName'
  | 'startTime'
  | 'location'
  | keyof Omit<ModalsData['sellPlayerSharesConfirm'], 'tournamentId'>;

const recordsLabelsMap: ReadonlyMap<InfoKeys, TranslationKeys> = new Map([
  ['eventName', 'SELL_PLAYER_SHARE_CONFIRM_MODAL__eventNameLabel'],
  ['tournamentName', 'SELL_PLAYER_SHARE_CONFIRM_MODAL__tournamentNameLabel'],
  ['location', 'SELL_PLAYER_SHARE_CONFIRM_MODAL__locationLabel'],
  ['startTime', 'SELL_PLAYER_SHARE_CONFIRM_MODAL__startTimeLabel'],
  ['sharesToSell', 'SELL_PLAYER_SHARE_CONFIRM_MODAL__sharesToSellLabel'],
  ['markUp', 'SELL_PLAYER_SHARE_CONFIRM_MODAL__markupLabel'],
  ['bullets', 'SELL_PLAYER_SHARE_CONFIRM_MODAL__bulletsLabel'],
  ['shareDilution', 'SELL_PLAYER_SHARE_CONFIRM_MODAL__shareDilutionLabel'],
  [
    'maxPerUserShare',
    'SELL_PLAYER_SHARE_CONFIRM_MODAL__limitSharesPerUserLabel',
  ],
  ['password', 'SELL_PLAYER_SHARE_CONFIRM_MODAL__passwordLabel'],
  ['note', 'SELL_PLAYER_SHARE_CONFIRM_MODAL__noteLabel'],
]);

const recordsLabels = Array.from(recordsLabelsMap);

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
`;

const List = styled('ul')`
  ${resetListStyles};
`;

const ListItem = styled('li')`
  display: flex;
`;

const ListItemLabel = styled(DefText)`
  width: 180px;
  margin-right: 16px;
  flex-shrink: 0;
`;

const ListItemValue = styled(DefText)``;

const CheckboxLabel = styled(DefText)``;

const CheckboxLabelLink = styled(Link)`
  color: #fff;
  font-weight: 700;
  @supports (font-variation-settings: normal) {
    font-variation-settings: 'wght' 700;
  }
`;

export { SellPlayerSharesConfirmModal };
