import { useCallback } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { ConnectorNotFoundError, useConnect, useDisconnect } from 'wagmi';
import { useServerAuth } from '@/providers/ServerAuth';
import { ConnectorNames } from '@/utils/connectors';
import {
  LocalStorageKeys,
  setLocalStorageValue,
  removeLocalStorageValue,
} from '@/utils/local-storage';
import { useChainId } from './useChainId';

export const useWalletConnect = () => {
  const { t } = useTranslation();
  const { connectors, connectAsync } = useConnect();
  const chainId = useChainId();
  const { disconnectAsync } = useDisconnect();
  const { authOnServerWithWallet } = useServerAuth();

  const connectWallet = useCallback(
    async (connectorID: ConnectorNames, withServerAuth = false) => {
      const findConnector = connectors.find((c) => c.id === connectorID);

      try {
        const { account } = await connectAsync({
          connector: findConnector,
          chainId,
        });
        setLocalStorageValue(LocalStorageKeys.CONNECTOR, connectorID);
        if (withServerAuth && account) {
          // noinspection ES6MissingAwait
          authOnServerWithWallet(account);
        }
      } catch (error) {
        console.error(error);
        removeLocalStorageValue(LocalStorageKeys.CONNECTOR);
        if (error instanceof ConnectorNotFoundError) {
          toast.error(t('WALLET_AUTH__unsupportedNetworkError'));
        }
        if (error instanceof Error) {
          toast.error(t('WALLET_AUTH__authError'));
        }
      }
    },
    [t, connectors, chainId, connectAsync, authOnServerWithWallet]
  );

  const disconnectWallet = useCallback(() => {
    disconnectAsync();
    removeLocalStorageValue(LocalStorageKeys.CONNECTOR);
  }, [disconnectAsync]);

  return { connectWallet, disconnectWallet };
};
