import { useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import {
  GeneralStatus,
  UserIdDocument,
  useDisable2FaMutation,
  UserIdQuery,
  UserIdQueryVariables,
} from '@/apollo/operations';
import { Button } from '@/components/buttons';
import { useModals } from '@/providers';
import { sendSentryError } from '@/utils/sentry';
import {
  FormControl as DefFormControl,
  FormLabel as DefFormLabel,
  TextField,
} from '@mui/material';
import { styled, css } from '@mui/material/styles';
import { Text } from '../texts';

const Disable2FAModal = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { dispatch } = useModals();
  const [codeValue, setCodeValue] = useState('');

  const showError = (e: Error) => {
    sendSentryError(e);
    enqueueSnackbar(t('DISABLE_2FA_MODAL__mutationError'), {
      variant: 'error',
    });
  };

  const [enable2FA, { loading: enabling2FA }] = useDisable2FaMutation({
    fetchPolicy: 'no-cache',
    update: (cache, result) => {
      if (result.data?.deactivate2FA?.status === GeneralStatus.Ok) {
        const userIdResponse = cache.readQuery<
          UserIdQuery,
          UserIdQueryVariables
        >({
          query: UserIdDocument,
        });
        if (!userIdResponse?.me) return;
        cache.modify({
          id: cache.identify(userIdResponse.me),
          fields: {
            twofa: () => false,
          },
        });
      }
    },
    onCompleted: (data) => {
      if (data.deactivate2FA?.status === GeneralStatus.Ok) {
        enqueueSnackbar(t('DISABLE_2FA_MODAL__mutationSuccess'), {
          variant: 'success',
        });
        dispatch({
          type: 'closeModal',
        });
      } else {
        showError(
          new Error(
            `Disable2FaMutation status not ok; msg: ${data.deactivate2FA?.message}`
          )
        );
      }
    },
    onError: (e) => {
      showError(e);
    },
  });

  useLayoutEffect(() => {
    dispatch({
      type: 'setWrapperProps',
      payload: {
        title: t('DISABLE_2FA_MODAL__title'),
        dialogContentStyles: css`
          padding-top: 0;
        `,
      },
    });
  }, [t, dispatch]);

  return (
    <>
      <Text
        style={{
          marginTop: 20,
        }}
        component={'h2'}
        variant={'body-medium'}
        align={'center'}
        color={'spunPearl'}
      >
        {t('DISABLE_2FA_MODAL__header')}
      </Text>
      <FormControl>
        <FormLabel>{t('DISABLE_2FA_MODAL__inputLabel')}</FormLabel>
        <Input
          value={codeValue}
          onChange={(event) => setCodeValue(event.target.value)}
        />
      </FormControl>
      <SubmitButton
        disabled={!codeValue}
        loading={enabling2FA}
        onClick={() => {
          enable2FA({
            variables: {
              code: codeValue,
            },
          });
        }}
      >
        {t('DISABLE_2FA_MODAL__submitButton')}
      </SubmitButton>
    </>
  );
};

const FormControl = styled(DefFormControl)`
  align-self: center;
  flex-direction: row;
  margin-top: 16px;
`;

const FormLabel = styled(DefFormLabel)`
  margin-right: 16px;
  margin-top: 14px;
`;

const Input = styled(TextField)`
  width: 165px;
`;

const SubmitButton = styled(Button)`
  min-width: 310px;
  margin-top: 20px;
  align-self: center;
`;

export { Disable2FAModal };
