import { SvgIcon, SvgIconProps } from '@mui/material';

export type VerifiedIconProps = SvgIconProps;

const VerifiedIcon = (props: VerifiedIconProps) => (
  <SvgIcon {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        fill="#000d20"
        fillRule="nonzero"
        d="M9.523 1.098A4.586 4.586 0 0 1 6.91 2.182a4.587 4.587 0 0 0-2.88 1.333A4.603 4.603 0 0 0 2.7 6.4a4.604 4.604 0 0 1-1.083 2.618 4.604 4.604 0 0 0 0 5.964A4.604 4.604 0 0 1 2.7 17.6a4.603 4.603 0 0 0 1.33 2.885 4.586 4.586 0 0 0 2.88 1.333 4.586 4.586 0 0 1 2.613 1.084 4.586 4.586 0 0 0 5.952 0 4.586 4.586 0 0 1 2.613-1.084 4.586 4.586 0 0 0 2.88-1.333 4.602 4.602 0 0 0 1.33-2.885 4.604 4.604 0 0 1 1.083-2.618 4.604 4.604 0 0 0 0-5.964A4.597 4.597 0 0 1 22.298 6.4a4.602 4.602 0 0 0-1.33-2.885 4.586 4.586 0 0 0-2.88-1.333 4.586 4.586 0 0 1-2.613-1.084 4.585 4.585 0 0 0-5.952 0z"
      />
      <path
        fill="#01ecc8"
        d="M17.522 10.107c.273-.275.425-.644.421-1.026a1.436 1.436 0 0 0-.44-1.018 1.525 1.525 0 0 0-1.048-.427 1.527 1.527 0 0 0-1.057.41l-4.945 4.8L8.51 10.96a1.526 1.526 0 0 0-1.056-.41c-.394.004-.77.157-1.05.427-.278.27-.436.636-.439 1.018-.003.383.148.75.422 1.026l3.003 2.915c.282.273.664.427 1.062.427s.78-.154 1.062-.427z"
      />
    </g>
  </SvgIcon>
);

export { VerifiedIcon };
