import { SvgIcon, SvgIconProps } from '@mui/material';

export type TwitterColorIconProps = SvgIconProps;

const TwitterColorIcon = (props: TwitterColorIconProps) => (
  <SvgIcon {...props}>
    <g fill="none">
      <rect width={24} height={24} fill="#47acdf" rx={12} />
      <path
        fill="#fff"
        d="M9.32 18a8.273 8.273 0 0 1-4.52-1.338c1.108.073 3.064-.101 4.28-1.279-1.83-.085-2.655-1.509-2.762-2.117.155.06.897.134 1.315-.037-2.105-.535-2.428-2.41-2.356-2.982.395.28 1.064.378 1.328.353-1.962-1.424-1.256-3.566-.91-4.028 1.408 1.979 3.518 3.09 6.128 3.152a3.104 3.104 0 0 1-.075-.681c0-1.68 1.339-3.043 2.99-3.043.863 0 1.64.372 2.187.967.576-.137 1.444-.458 1.868-.736-.214.78-.88 1.429-1.282 1.67.003.008-.003-.009 0 0 .354-.055 1.31-.241 1.689-.501-.187.437-.893 1.165-1.472 1.572C17.836 13.794 14.199 18 9.32 18z"
      />
    </g>
  </SvgIcon>
);

export { TwitterColorIcon };
