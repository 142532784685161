import { useCallback } from 'react';
import { AccessTokenMutationVariables } from '@/apollo/operations';
import { useGetWalletAccessAuthData } from './useGetWalletAccessAuthData';
import { useWalletSignature } from './useWalletSignature';

export const useGetWalletServerAuthData = () => {
  const getSignature = useWalletSignature();
  const getAccessAuthData = useGetWalletAccessAuthData();

  return useCallback(
    async (address: CryptoAddress) => {
      try {
        const signature = await getSignature(address);

        const variables: AccessTokenMutationVariables = {
          address,
          signature,
        };

        return await getAccessAuthData(variables);
      } catch (e) {
        console.log(`Couldn't get auth data in server auth hook: `, e);
        throw e;
      }
    },
    [getAccessAuthData, getSignature]
  );
};
