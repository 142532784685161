import { useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
import { useOnMenuItemClick } from '@/hooks/useOnMenuItemClick';
import { removeQueryParamsFromRouter } from '@/utils/helpers';
import { isProperMenuItem, MenuItemTypes } from '@/utils/user-menu';

export const useMobileMenuUrlParam = () => {
  const router = useRouter();
  const calledRef = useRef(false);
  const menuModalName = router.query[menuModalQueryParamName];
  const onMenuItemClick = useOnMenuItemClick();

  useEffect(() => {
    if (!(isProperMenuItem(menuModalName) && !calledRef.current)) return;

    // @ts-ignore
    onMenuItemClick(MenuItemTypes[menuModalName.toUpperCase()]);

    removeQueryParamsFromRouter(router, [menuModalQueryParamName]);

    calledRef.current = true;
  }, [router, menuModalName, onMenuItemClick]);
};

const menuModalQueryParamName = 'menu-modal';
