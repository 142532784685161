import { ComponentProps, ChangeEvent, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Link from 'next/link';
import { usePaymentsQuery, PaymentsQuery } from '@/apollo/operations';
import { PageSpinner } from '@/components/common-elements';
import { useGetShortenedString } from '@/hooks/useGetShortenedString';
import { externalLinkProps } from '@/utils/consts';
import dayjs from '@/utils/dayjs';
import { getPrice } from '@/utils/helpers';
import { TranslationKeys } from '@/utils/i18n';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TablePagination,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Text } from '../texts';

export type PaymentsTableProps = ComponentProps<typeof Wrapper>;

const PaymentsTable = (props: PaymentsTableProps) => {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const getShortenedString = useGetShortenedString({
    desktop: {
      start: 4,
      end: 3,
    },
    mobile: {
      start: 3,
      end: 2,
    },
  });

  const { loading, error, data } = usePaymentsQuery({
    fetchPolicy: 'no-cache',
    variables: {
      offset: page * rowsPerPage,
      limit: rowsPerPage,
    },
  });

  const { payments, paymentsAmount } = useMemo(
    () => ({
      payments: data?.payments ?? [],
      paymentsAmount: data?.paymentsAggregate.aggregate?.totalCount ?? 0,
    }),
    [data]
  );

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Wrapper {...props}>
      {(() => {
        if (loading) return <PageSpinner />;
        if (error) {
          return <Text align={'center'}>{t('PAYMENTS_TABLE__loadError')}</Text>;
        }
        return (
          <>
            <TableContainer sx={{ maxHeight: 440 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map(([column, label]) => (
                      <TableCell key={column}>{t(label)}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {payments.map((payment) => (
                    <TableRow
                      key={payment.id}
                      hover
                      role="checkbox"
                      tabIndex={-1}
                    >
                      {columns.map(([column]) => {
                        const recordValue = payment[column];
                        let value;
                        switch (column) {
                          case 'id':
                            value = (
                              <Text component={'span'}>#{recordValue}</Text>
                            );
                            break;
                          case 'createdAt':
                            value = (
                              <Text component={'span'}>
                                {dayjs(recordValue).format('DD/MM/YYYY')}
                              </Text>
                            );
                            break;
                          case 'status':
                            value = (
                              <Text
                                component={'span'}
                                {...(recordValue === 'Paid'
                                  ? { color: 'primary' }
                                  : {})}
                              >
                                {recordValue}
                              </Text>
                            );
                            break;
                          case 'txid': {
                            const scanUrl =
                              payment.blockchain_currency?.blockchain.scanUrl;
                            const content = (
                              <Text component={'span'}>
                                {getShortenedString(recordValue)}
                              </Text>
                            );
                            value =
                              scanUrl && recordValue ? (
                                <StyledLink
                                  href={`${scanUrl}tx/${recordValue}`}
                                  {...externalLinkProps}
                                >
                                  {content}
                                </StyledLink>
                              ) : (
                                content
                              );
                            break;
                          }
                          case 'amount':
                            value = (
                              <Text component={'span'}>
                                {getPrice(recordValue)}
                              </Text>
                            );
                            break;
                          default:
                            value = (
                              <Text component={'span'}>{recordValue}</Text>
                            );
                        }
                        return <TableCell key={column}>{value}</TableCell>;
                      })}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={paymentsAmount}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </>
        );
      })()}
    </Wrapper>
  );
};

type Payment = Omit<PaymentsQuery['payments'][number], '__typename'>;

const columnsMap: ReadonlyMap<keyof Payment, TranslationKeys> = new Map([
  ['id', 'PAYMENTS_TABLE__idColumn'],
  ['createdAt', 'PAYMENTS_TABLE__dateColumn'],
  ['type', 'PAYMENTS_TABLE__typeColumn'],
  ['status', 'PAYMENTS_TABLE__statusColumn'],
  ['txid', 'PAYMENTS_TABLE__txColumn'],
  ['amount', 'PAYMENTS_TABLE__amountColumn'],
]);

const columns = Array.from(columnsMap);

const Wrapper = styled('div')``;

const StyledLink = styled(Link)`
  text-decoration-color: ${({ theme }) => theme.getColor('primary')};
`;

export { PaymentsTable };
