import { useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useWalletConnect } from '@/hooks';
import { useModals } from '@/providers';
import { resetListStyles, resetButtonStyles } from '@/styles';
import { wallets } from '@/utils/connectors';
import { styled } from '@mui/material/styles';
import { Text as DefText } from '../texts';

const WalletsModal = () => {
  const { t } = useTranslation();
  const { data, dispatch } = useModals<'wallets', 'wallets'>();
  const { connectWallet } = useWalletConnect();

  useLayoutEffect(() => {
    dispatch({
      type: 'setWrapperProps',
      payload: {
        title: t('WALLETS_MODAL__title'),
      },
    });
  }, [dispatch, t]);

  return (
    <>
      <WalletsList>
        {wallets.map(({ title, connectorId, icon }, index) => (
          <WalletsListItem key={index}>
            <WalletButton
              onClick={async () => {
                await connectWallet(connectorId, !!data?.withServerAuthRequest);
                dispatch(
                  // @ts-ignore
                  data?.openModalAfterwards
                    ? {
                        type: 'setModalContent',
                        payload: {
                          name: data.openModalAfterwards,
                        },
                      }
                    : {
                        type: 'closeModal',
                      }
                );
              }}
            >
              <WalletIcon src={icon} alt={title} />
              <WalletTitle>{title}</WalletTitle>
            </WalletButton>
          </WalletsListItem>
        ))}
      </WalletsList>
    </>
  );
};

const WalletsList = styled('ul')`
  ${resetListStyles};
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 10px;
  justify-content: space-between;
  margin: 0 -10px;
`;

const WalletsListItem = styled('li')``;

const WalletButton = styled('button')`
  ${resetButtonStyles};
  display: flex;
  align-items: center;
  padding: 6px 10px;
`;

const WalletIcon = styled('img')`
  margin-right: 10px;
  width: 40px;
  height: 40px;
`;

const WalletTitle = styled(DefText)``;

export { WalletsModal };
