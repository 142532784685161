import {
  useCallback,
  useRef,
  ComponentProps,
  ReactNode,
  MouseEvent,
  ComponentType,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useMethods } from 'react-use';
import { InputButton } from '@/components/buttons';
import {
  LoopIcon,
  FilterIcon,
  CloseIcon as DefCloseIcon,
} from '@/components/icons';
import { EventsSearch } from '@/components/search/EventsSearch';
import { PlayersSearch } from '@/components/search/PlayersSearch';
import { TournamentsSearch } from '@/components/search/TournamentsSearch';
import { searchInputsIconStyles } from '@/styles/searchInputs';
import { TranslationKeys } from '@/utils/i18n';
import { Paper, ButtonBaseProps, ClickAwayListener } from '@mui/material';
import { styled, css } from '@mui/material/styles';

export type MobileSearchProps = Omit<ComponentProps<typeof Wrapper>, '$opened'>;

const MobileSearch = (props: MobileSearchProps) => {
  const { t } = useTranslation();
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const [state, methods] = useMethods(createMethods, initialState);

  const onControlButtonPress = useCallback(
    (e: MouseEvent<HTMLButtonElement>, type: InputTypes) => {
      methods.setContent(type);
    },
    [methods]
  );

  const opened = state.controlsActive || !!state.content;

  return (
    <ClickAwayListener
      onClickAway={() => {
        methods.close();
      }}
    >
      <Wrapper ref={wrapperRef} $opened={opened} {...props}>
        {(state.controlsActive || state.content) && (
          <Content>
            {state.controlsActive && (
              <Buttons>
                {buttons.map(([key, { label, icon, ...props }]) => (
                  <InputButton
                    key={key}
                    onClick={(e) => {
                      onControlButtonPress(e, key);
                    }}
                    {...props}
                  >
                    {t(label)} {icon}
                  </InputButton>
                ))}
              </Buttons>
            )}
            {state.content}
          </Content>
        )}
        <InputButton
          onClick={() => {
            methods.onMainButtonClick();
          }}
        >
          {t('MOBILE_SEARCH__controlsButton')}
          {opened ? (
            <DefCloseIcon css={searchInputsIconStyles} />
          ) : (
            <FilterIcon css={searchInputsIconStyles} />
          )}
        </InputButton>
      </Wrapper>
    </ClickAwayListener>
  );
};

enum InputTypes {
  PLAYER_NAME = 'playerName',
  EVENT_NAME = 'eventName',
  TOURNAMENT_NAME = 'tournamentName',
}

type State = {
  controlsActive: boolean;
  content: ReactNode | null;
};

const initialState: State = {
  controlsActive: false,
  content: null,
};

const contents: {
  [key in InputTypes]: ComponentType;
} = {
  [InputTypes.PLAYER_NAME]: PlayersSearch,
  [InputTypes.EVENT_NAME]: EventsSearch,
  [InputTypes.TOURNAMENT_NAME]: TournamentsSearch,
};

const createMethods = (state: State) => ({
  close: () => initialState,
  onMainButtonClick: () => {
    if (state.content) {
      return {
        controlsActive: true,
        content: null,
      };
    }

    if (state.controlsActive) {
      return {
        controlsActive: false,
        content: null,
      };
    }

    return {
      controlsActive: true,
      content: null,
    };
  },
  toggleControls: () => ({
    ...state,
    controlsActive: !state.controlsActive,
  }),
  setContent: (type: InputTypes) => {
    const Component = contents[type];
    return {
      controlsActive: false,
      content: <Component />,
    };
  },
});

const buttonsDataMap: ReadonlyMap<
  InputTypes,
  ButtonBaseProps & { label: TranslationKeys; icon: ReactNode }
> = new Map([
  [
    InputTypes.PLAYER_NAME,
    {
      label: 'SEARCH_BAR__playerNameInputLabel',
      icon: <LoopIcon css={searchInputsIconStyles} />,
    },
  ],
  [
    InputTypes.EVENT_NAME,
    {
      label: 'SEARCH_BAR__eventNameInputLabel',
      icon: <LoopIcon css={searchInputsIconStyles} />,
    },
  ],
  [
    InputTypes.TOURNAMENT_NAME,
    {
      label: 'SEARCH_BAR__tournamentNameInputLabel',
      icon: <LoopIcon css={searchInputsIconStyles} />,
    },
  ],
]);

const buttons = Array.from(buttonsDataMap);

const Wrapper = styled(Paper, {
  shouldForwardProp: (prop) => prop !== '$opened',
})<{
  $opened: boolean;
}>`
  padding: 12px;
  position: relative;
  ${({ $opened }) =>
    $opened &&
    css`
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    `}
`;

const Content = styled(Paper)`
  width: 100%;
  max-height: calc(100vh - 170px);
  padding: 12px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  position: absolute;
  left: 0;
  bottom: 100%;
  overflow-y: scroll;
`;

const Buttons = styled(Paper)``;

export { MobileSearch };
