import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { getLangData } from '@/utils/i18n';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const Dates = ({ children }: PropsWithChildren) => {
  const { i18n } = useTranslation();
  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      adapterLocale={getLangData(i18n.language).dayjsName}
    >
      {children}
    </LocalizationProvider>
  );
};

export { Dates };
