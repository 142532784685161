import { useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
import { usePlayerShareUrlParamDataQuery } from '@/apollo/operations';
import { useModals } from '@/providers';

export const usePlayerShareUrlParam = () => {
  const { query } = useRouter();
  const calledRef = useRef(false);
  const slug = query['player-share'];
  const { dispatch } = useModals();

  const { data } = usePlayerShareUrlParamDataQuery({
    skip: !slug,
    fetchPolicy: 'network-only',
    variables: {
      slug: slug as string,
    },
  });

  useEffect(() => {
    const playerShare = data?.playerShares[0];
    if (playerShare && !calledRef.current) {
      dispatch({
        type: 'setModalContent',
        payload: {
          name: 'buyPlayerShares',
          data: {
            shareId: playerShare.id,
            playerId: playerShare.player.id,
          },
        },
      });
      calledRef.current = true;
    }
  }, [data, dispatch]);
};
