import { Roboto } from 'next/font/google';
import { desktopDefaultFontSize, fallbackFonts } from '@/styles/consts';
import { createTheme } from '@mui/material/styles';
import { ThemeOptions } from '@mui/system';
import { colors } from './colors';

export const roboto = Roboto({
  weight: ['400', '500'],
  subsets: ['latin'],
  display: 'swap',
  fallback: ['Helvetica', 'Arial', 'sans-serif'],
});

const defBreakpoints: ThemeOptions['breakpoints'] = {
  values: {
    xs: 0,
    sm: 648,
    md: 953,
    lg: 1328,
    xl: 1488,
  },
};

const defTypography: ThemeOptions['typography'] = {
  htmlFontSize: desktopDefaultFontSize,
};

const {
  breakpoints,
  // spacing,
  // typography: { pxToRem },
  // @ts-ignore
} = createTheme({ breakpoints: defBreakpoints, typography: defTypography });

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 648,
      md: 953,
      lg: 1328,
      xl: 1488,
    },
  },
  typography: {
    htmlFontSize: desktopDefaultFontSize,
    fontFamily: roboto.style.fontFamily,
  },
  palette: {
    primary: {
      main: colors.primary,
    },
    // TODO: replace with proper
    secondary: {
      main: '#19857b',
    },
    white: {
      main: colors.white,
    },
    info: {
      main: colors.white,
    },
    error: {
      main: colors.alizarinCrimson,
    },
  },
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiContainer: {
      defaultProps: {
        fixed: true,
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: '#fff',
        },
      },
    },
    MuiLink: {
      defaultProps: {
        underline: 'none',
      },
      styleOverrides: {
        root: {
          color: '#fff',
          '&:hover': {
            color: colors.primary,
          },
        },
      },
    },
    MuiButton: {
      defaultProps: {
        variant: 'contained',
      },
      styleOverrides: {
        root: {
          padding: '10px 24px',
          borderRadius: 8,
          fontFamily: `'SF Pro Display', ${fallbackFonts}`,
          textTransform: 'initial',
          '&.Mui-disabled': {
            backgroundColor: colors.spunPearl,
          },
          [breakpoints.down('sm')]: {
            padding: '10px 10px',
            lineHeight: '20px',
          },
        },
        startIcon: {
          marginLeft: 0,
        },
        sizeSmall: {
          padding: '6px 12px',
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: 10,
          borderRadius: 8,
          backgroundColor: colors.deepCove,
          color: '#fff',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          width: '100%',
          backgroundColor: colors.deepCove,
          color: '#fff',
          [breakpoints.down('sm')]: {
            margin: 6,
          },
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: {
          backgroundColor: colors.deepCove,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: colors.blueZodiac,
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          height: 13,
        },
        rail: {
          backgroundColor: 'transparent',
          border: '1px solid #fff',
          borderRadius: 10,
        },
        thumb: {
          width: 40,
          height: 40,
          border: '10px solid #fff',
        },
        marked: {
          padding: '40px 0',
          '@media (pointer: coarse)': {
            padding: '40px 0',
          },
        },
        mark: {
          width: 24,
          height: 24,
          borderRadius: 12,
          transform: 'translate(-50%, -50%)',
        },
        markActive: {
          backgroundColor: colors.primary,
          opacity: 1,
        },
        markLabel: {
          top: 0,
          '@media (pointer: coarse)': {
            top: 0,
          },
          fontSize: 16,
          color: '#fff',
          fontWeight: 600,
        },
      },
    },
    MuiTabs: {
      defaultProps: {
        variant: 'fullWidth',
      },
      styleOverrides: {
        flexContainer: {
          gap: 8,
        },
        indicator: {
          display: 'none',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          backgroundColor: colors.blueZodiac,
          fontSize: '0.8571428571rem',
          lineHeight: 16 / 12,
          color: colors.spunPearl,
          '&.Mui-selected': {
            backgroundColor: colors.white,
            color: colors.completelyBlack,
          },
        },
      },
    },
    MuiPopover: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        paper: {
          backgroundColor: colors.blueZodiac,
          overflow: 'visible',
          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          marginTop: 8 * 1.5,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: colors.spunPearl,
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
          color: colors.spunPearl,
          '&.Mui-focused': {
            color: colors.spunPearl,
          },
          '&.Mui-disabled': {
            color: colors.spunPearl,
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: colors.spunPearl,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
          color: colors.spunPearl,
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: colors.spunPearl,
          },
          '&.Mui-disabled': {
            borderColor: colors.spunPearl,
            color: colors.spunPearl,
          },
          '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
            borderColor: colors.spunPearl,
            color: colors.spunPearl,
          },
        },
        notchedOutline: {
          borderColor: colors.spunPearl,
          color: colors.spunPearl,
          '&:hover': {
            borderColor: colors.spunPearl,
          },
        },
        input: {
          color: colors.spunPearl,
          '&.Mui-disabled': {
            WebkitTextFillColor: colors.spunPearl,
          },
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          borderBottomLeftRadius: 8,
          borderBottomRightRadius: 8,
          backgroundColor: colors.blueCharcoal,
          color: colors.spunPearl,
          '&.Mui-focused': {
            backgroundColor: colors.blueCharcoal,
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: '#fff',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        iconOutlined: {
          color: colors.spunPearl,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        outlined: {
          color: colors.spunPearl,
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          color: colors.spunPearl,
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: colors.spunPearl,
          '&.Mui-disabled': {
            color: colors.spunPearl,
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          color: '#fff',
          backgroundColor: colors.blueZodiac,
          borderBottom: 'none',
        },
        root: {
          color: '#fff',
        },
        footer: {
          fontSize: '1rem',
          borderBottom: 'none',
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          color: '#fff',
        },
        selectIcon: {
          color: '#fff',
        },
      },
    },
  },
  colors,
  getColor: (colorName) => colors[colorName],
});
