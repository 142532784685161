import { useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useEditEventDataQuery,
  useUserVenueIdQuery,
} from '@/apollo/operations';
import { PageSpinner } from '@/components/common-elements';
import { UserVenueAddEditEventForm } from '@/components/forms';
import { useModals } from '@/providers';
import { Text } from '../texts';

const UserVenueAddEditEventModal = () => {
  const { t } = useTranslation();
  const { dispatch, data: modalData } = useModals<'userVenueAddEditEvent'>();

  useLayoutEffect(() => {
    dispatch({
      type: 'setWrapperProps',
      payload: {
        title: t('USER_VENUE_ADD_EDIT_EVENT_MODAL__title'),
      },
    });
  }, [t, dispatch]);

  const { data: userVenueIdData } = useUserVenueIdQuery();

  const { loading, error, data } = useEditEventDataQuery({
    fetchPolicy: 'cache-and-network',
    skip: !modalData?.eventId,
    variables: {
      id: modalData?.eventId,
    },
  });

  if (loading && !data?.event) {
    return <PageSpinner />;
  }

  if (error) {
    return (
      <Text align={'center'}>
        {t('USER_VENUE_ADD_EDIT_EVENT_MODAL__eventLoadError')}
      </Text>
    );
  }

  return (
    <UserVenueAddEditEventForm
      venueId={userVenueIdData?.me?.venueId}
      event={data?.event}
      onSuccessSubmit={() => {
        dispatch({
          type: 'closeModal',
        });
      }}
    />
  );
};

export { UserVenueAddEditEventModal };
