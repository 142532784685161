import { SvgIcon, SvgIconProps } from '@mui/material';

export type FilterIconProps = SvgIconProps;

const FilterIcon = (props: FilterIconProps) => (
  <SvgIcon {...props}>
    <path
      fill="currentColor"
      d="M.375 2.49c.15.195 8.61 10.994 8.61 10.994V22.5c0 .825.674 1.5 1.514 1.5h3.015c.825 0 1.515-.675 1.515-1.5v-9.03s8.235-10.53 8.625-11.01c.39-.48.344-.96.344-.96C23.998.676 23.323 0 22.484 0H1.514C.6 0 0 .72 0 1.5c0 .3.09.66.375.99z"
    />
  </SvgIcon>
);

export { FilterIcon };
