import { useCustomActionUrlParam } from '@/hooks/useCustomActionUrlParam';
import { useInitialUserDataLoad } from '@/hooks/useInitialUserDataLoad';
import { useLanguage } from '@/hooks/useLanguage';
import { useMobileMenuUrlParam } from '@/hooks/useMobileMenuUrlParam';
import { usePlayerShareUrlParam } from '@/hooks/usePlayerShareUrlParam';
import { useWalletEventListeners } from '@/hooks/useWalletEventListeners';

export const GlobalHooks = () => {
  useWalletEventListeners();
  useInitialUserDataLoad();
  usePlayerShareUrlParam();
  useMobileMenuUrlParam();
  useCustomActionUrlParam();
  useLanguage();
  return null;
};
