import {
  useReducer,
  useMemo,
  createContext,
  useContext,
  useCallback,
  PropsWithChildren,
  Dispatch,
  ComponentType,
  ReactNode,
} from 'react';
import { once } from 'lodash-es';
import { CreatePlayerShareMutationVariables } from '@/apollo/operations';
import { ModalWrapper, ModalWrapperProps } from '@/components/modal-elements';
import {
  LoginModal,
  WalletsModal,
  EmailLoginModal,
  UserSettingsModal,
  BuyPlayerSharesModal,
  BuyPlayerSharesConfirmModal,
  WithdrawDepositModal,
  MobileMenuModal,
  EditPlayerProfileModal,
  BecomePlayerModal,
  SellPlayerShareModal,
  SellPlayerSharesConfirmModal,
  ResetPasswordModal,
  Enable2FAModal,
  Disable2FAModal,
  VerifyEmailModal,
  SetWinningPlayerModal,
  UserVenueEventFinancialOverviewModal,
  UserVenueAddEditEventModal,
  UserVenueAddEditTournamentModal,
  ConfirmModal,
  BuyTournamentTicketConfirmModal,
  PlayerTicketLocalCurrencyPayoutModal,
} from '@/components/modals';
import { TranslationKeys } from '@/utils/i18n';

const Modals = ({ children }: PropsWithChildren) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { modalOpened, wrapperProps, Content } = state;
  const contextValue = useMemo(() => ({ ...state, dispatch }), [state]);
  const close = useCallback(() => dispatch({ type: 'closeModal' }), []);
  const ModalsContext = useMemo(() => createModalsContext(), []);

  return (
    <>
      <ModalsContext.Provider value={contextValue}>
        {children}
        <ModalWrapper
          open={modalOpened}
          close={close}
          onClose={close}
          {...wrapperProps}
        >
          {!!Content && <Content />}
        </ModalWrapper>
      </ModalsContext.Provider>
    </>
  );
};

export type ModalsData = {
  login: undefined;
  wallets: {
    withServerAuthRequest: boolean;
    openModalAfterwards?: ModalName;
  };
  emailLogin: undefined;
  resetPassword: undefined;
  userSettings: undefined;
  enable2FA: undefined;
  disable2FA: undefined;
  verifyEmail: undefined;
  buyPlayerShares: {
    playerId: string;
    shareId: string;
  };
  buyPlayerSharesConfirm: {
    playerShareId: string;
    eventName: string;
    tournamentName: string;
    startTime: string;
    venueName: string;
    buyIn: number;
    markup: number;
    bullets: number;
    shareDilution: boolean;
    percent: number;
    price: number;
    password?: string;
  };
  withdrawDeposit: undefined;
  mobileMenu: undefined;
  editPlayerProfile: {
    playerId: string;
  };
  becomePlayer: undefined;
  sellPlayerShare: {
    tournamentId: string;
    sharesMax: number;
    bulletsMax: number;
    buyIn: number;
  };
  sellPlayerSharesConfirm: CreatePlayerShareMutationVariables;
  setWinningPlayer: {
    playerShareId: string;
  };
  userVenueEventFinancialOverview: {
    eventId: string;
  };
  userVenueAddEditEvent?: {
    eventId: string;
  };
  userVenueAddEditTournament: {
    venueId: string;
  } & (
    | {
        eventId: string;
      }
    | {
        tournamentId: string;
      }
  );
  buyTournamentTicketConfirm: {
    tournamentId: string;
    eventName: string;
    tournamentName: string;
    startTime: string;
    buyIn: number;
    bullets: number;
    localCurrencySymbol: string;
    localCurrencyRate: number;
  };
  playerTicketLocalCurrencyPayout: {
    ticketId: string;
    localCurrencySymbol: string;
    localCurrencyCountryId: number;
  };
  confirm: {
    title: TranslationKeys;
    desc?: ReactNode;
    withClosingOnConfirmPress?: boolean;
    onConfirmPress: () => Promise<unknown>;
  };
};

export type ModalName = keyof ModalsData;

type State<T extends ModalName> = {
  modalOpened: boolean;
  wrapperProps: Omit<ModalWrapperProps, 'open' | 'close' | 'onClose'>;
  Content: (typeof modals)[T] | null;
  data: ModalsData[T] | undefined;
};

const initialState = {
  modalOpened: false,
  wrapperProps: {},
  Content: null,
  data: undefined,
};

const modals: {
  [key in ModalName]: ComponentType;
} = {
  login: LoginModal,
  wallets: WalletsModal,
  resetPassword: ResetPasswordModal,
  emailLogin: EmailLoginModal,
  userSettings: UserSettingsModal,
  enable2FA: Enable2FAModal,
  disable2FA: Disable2FAModal,
  verifyEmail: VerifyEmailModal,
  buyPlayerShares: BuyPlayerSharesModal,
  buyPlayerSharesConfirm: BuyPlayerSharesConfirmModal,
  withdrawDeposit: WithdrawDepositModal,
  mobileMenu: MobileMenuModal,
  editPlayerProfile: EditPlayerProfileModal,
  becomePlayer: BecomePlayerModal,
  sellPlayerShare: SellPlayerShareModal,
  sellPlayerSharesConfirm: SellPlayerSharesConfirmModal,
  setWinningPlayer: SetWinningPlayerModal,
  userVenueAddEditEvent: UserVenueAddEditEventModal,
  userVenueAddEditTournament: UserVenueAddEditTournamentModal,
  userVenueEventFinancialOverview: UserVenueEventFinancialOverviewModal,
  buyTournamentTicketConfirm: BuyTournamentTicketConfirmModal,
  playerTicketLocalCurrencyPayout: PlayerTicketLocalCurrencyPayoutModal,
  confirm: ConfirmModal,
};

type CloseModalAction = {
  type: 'closeModal';
};

type SetModalContentAction<T extends ModalName> = {
  type: 'setModalContent';
  payload: ModalsData[T] extends undefined
    ? {
        name: T;
      }
    : {
        name: T;
        data: ModalsData[T];
      };
};

type SetWrapperPropsAction<T extends ModalName> = {
  type: 'setWrapperProps';
  payload: State<T>['wrapperProps'];
};

type Action<T extends ModalName> =
  | CloseModalAction
  | SetModalContentAction<T>
  | SetWrapperPropsAction<T>;

const reducer = <T extends ModalName>(
  prevState: State<T>,
  action: Action<T>
): State<T> => {
  switch (action.type) {
    case 'closeModal':
      return {
        ...prevState,
        modalOpened: false,
      };
    case 'setWrapperProps':
      return {
        ...prevState,
        wrapperProps: action.payload,
      };
    case 'setModalContent':
      const Content = modals[action.payload.name];

      if (!Content) {
        throw new Error(`No such modal name: ${action.payload.name}`);
      }

      return {
        wrapperProps: {},
        modalOpened: true,
        Content,
        data: 'data' in action.payload ? action.payload.data : undefined,
      };
    default:
      return prevState;
  }
};

const createModalsContext = once(<
  T extends ModalName,
  C extends ModalName | undefined = undefined
>() =>
  createContext<
    State<C extends undefined ? T : C> & {
      dispatch: Dispatch<Action<T>>;
    }
  >({
    ...initialState,
    dispatch: () => undefined,
  })
);

const useModals = <
  T extends ModalName,
  C extends ModalName | undefined = undefined
>() => useContext(createModalsContext<T, C>());

export { Modals, useModals };
