// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
// import { getAnalytics } from 'firebase/analytics';
import { getAuth } from 'firebase/auth';

// Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyCLgf4ndqNK-R-mAvxxJD0_yR_WxukNSDY',
  authDomain: 'stake3-com.firebaseapp.com',
  databaseURL:
    'https://stake3-com-default-rtdb.asia-southeast1.firebasedatabase.app',
  projectId: 'stake3-com',
  storageBucket: 'stake3-com.appspot.com',
  messagingSenderId: '986143312929',
  appId: '1:986143312929:web:89a94b8d5d32cf72a66760',
  measurementId: 'G-4PXF2CJ43M',
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
export const firebaseAuth = getAuth(firebaseApp);
