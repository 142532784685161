import { useMemo, useEffect, ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Form as DefForm } from 'formik';
import { TFunction } from 'i18next';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';
import { Button } from '@/components/buttons';
import { FormikTextField } from '@/components/formik-elements';
import { useFirebaseResetPasswordConfirm } from '@/hooks';
import { sendSentryError } from '@/utils/sentry';
import { styled } from '@mui/material/styles';
import { Text } from '../texts';

export type ResetPasswordConfirmFormProps = {
  code: string;
  onSuccessSubmit?: () => void;
} & ComponentProps<typeof Form>;

const ResetPasswordConfirmForm = ({
  code,
  onSuccessSubmit,
  ...props
}: ResetPasswordConfirmFormProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const schema = useMemo(() => getSchema({ t }), [t]);
  const [resetPasswordConfirm, _, resetConfirmError] =
    useFirebaseResetPasswordConfirm();

  useEffect(() => {
    if (resetConfirmError) {
      sendSentryError(resetConfirmError);
    }
  }, [resetConfirmError]);

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={async ({ password }) => {
        const success = await resetPasswordConfirm(code, password);
        if (success) {
          enqueueSnackbar(t('RESET_PASSWORD_CONFIRM_FORM__changeSuccess'), {
            variant: 'success',
          });
          onSuccessSubmit?.();
        } else {
          enqueueSnackbar(t('RESET_PASSWORD_CONFIRM_FORM__changeError'), {
            variant: 'error',
          });
        }
      }}
    >
      {({ isSubmitting }) => (
        <Form {...props}>
          <Text
            component={'h1'}
            variant={'headline-large'}
            style={{
              marginBottom: 32,
            }}
          >
            {t('RESET_PASSWORD_CONFIRM_FORM__title')}
          </Text>
          <Input
            type={'password'}
            name={'password'}
            label={t('RESET_PASSWORD_CONFIRM_FORM__passwordInputLabel')}
          />
          <Input
            type={'password'}
            name={'confirmPassword'}
            label={t('RESET_PASSWORD_CONFIRM_FORM__passwordConfirmInputLabel')}
          />
          <SubmitButton type={'submit'} loading={isSubmitting}>
            {t('RESET_PASSWORD_CONFIRM_FORM__submitButton')}
          </SubmitButton>
        </Form>
      )}
    </Formik>
  );
};

type FormValues = {
  password: string;
  confirmPassword: string;
};

const initialValues: FormValues = {
  password: '',
  confirmPassword: '',
};

const getSchema = ({ t }: { t: TFunction }): yup.ObjectSchema<FormValues> =>
  yup
    .object({
      password: yup
        .string()
        .min(8, t('FORMS__minCharsError', { amount: 8 }))
        .required(t('FORMS__requiredError')),
      confirmPassword: yup
        .string()
        .oneOf([yup.ref('password')], t('FORMS__passwordsMatchError'))
        .required(t('FORMS__requiredError')),
    })
    .defined();

const Form = styled(DefForm)`
  display: flex;
  flex-direction: column;
`;

const Input = styled(FormikTextField)`
  & + & {
    margin-top: 32px;
  }
`;

const SubmitButton = styled(Button)`
  margin-top: 32px;
`;

export { ResetPasswordConfirmForm };
