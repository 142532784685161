import { useState, useCallback, ComponentProps } from 'react';
import { isNumber } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import { useAccount } from 'wagmi';
import { SignWalletButton, Button } from '@/components/buttons';
import { UserBalance as DefUserBalance } from '@/components/common-elements';
import { UserIcon, GlobeIcon, WalletIcon } from '@/components/icons';
import { LanguagesMenu, DesktopUserMenu } from '@/components/menus';
import {
  useShortenedAddress,
  useIsTablet,
  useFirebaseAuthState,
} from '@/hooks';
import { useModals, useUserBalance } from '@/providers';
import { useServerAuth } from '@/providers/ServerAuth';
import { getLangData } from '@/utils/i18n';
import { Paper, ButtonBase } from '@mui/material';
import { styled, css } from '@mui/material/styles';
import { Text } from '../texts';

export type HeaderUserBlockProps = ComponentProps<typeof Wrapper>;

const HeaderUserBlock = (props: HeaderUserBlockProps) => {
  const { t, i18n } = useTranslation();
  const router = useRouter();
  const [user] = useFirebaseAuthState();
  const { isConnected, address } = useAccount();
  const { authorized, authOnServerWithWallet } = useServerAuth();
  const shortenedAddress = useShortenedAddress(address);
  const tablet = useIsTablet();
  const { dispatch } = useModals<'withdrawDeposit' | 'mobileMenu' | 'login'>();
  const { balance, balancePending } = useUserBalance();

  const [languagesMenuAnchorEl, setLanguagesMenuAnchorEl] =
    useState<null | HTMLElement>(null);

  const handleLanguagesMenuClose = useCallback(() => {
    setLanguagesMenuAnchorEl(null);
  }, []);

  const [desktopUserMenuAnchorEl, setDesktopUserMenuAnchorEl] =
    useState<null | HTMLElement>(null);

  const handleDesktopUserMenuClose = useCallback(() => {
    setDesktopUserMenuAnchorEl(null);
  }, []);

  /* Cases:
   * 1. Not logged in
   * 2. Wallet connected, not authorized
   * 3. Wallet connected, authorized with wallet
   * 4. Authorized with firebase, wallet not connected
   * 5. Authorized with firebase, wallet connected, shouldn't be option to
   *  sign wallet*/

  return (
    <Wrapper {...props}>
      {(() => {
        if (authorized && tablet) {
          return (
            <ButtonBase
              onClick={() => {
                dispatch({
                  type: 'setModalContent',
                  payload: {
                    name: 'mobileMenu',
                  },
                });
              }}
            >
              {userIcon}
            </ButtonBase>
          );
        }

        if (!authorized && !isConnected) {
          return (
            <>
              <Button
                startIcon={<Icon />}
                onClick={() => {
                  dispatch({
                    type: 'setModalContent',
                    payload: {
                      name: 'login',
                    },
                  });
                }}
              >
                {t('HEADER__loginButton')}
              </Button>
              <Button
                css={css`
                  margin-left: 12px;
                  text-transform: uppercase;
                `}
                startIcon={<GlobeIcon />}
                onClick={(event) => {
                  setLanguagesMenuAnchorEl(event.currentTarget);
                }}
              >
                {getLangData(i18n.language).shortName}
              </Button>
              <LanguagesMenu
                anchorEl={languagesMenuAnchorEl}
                handleClose={handleLanguagesMenuClose}
              />
            </>
          );
        }

        if (!authorized && isConnected) {
          return (
            <>
              <SignWalletButton
                onClick={() => {
                  authOnServerWithWallet();
                }}
              />
              <UserBlock LinkComponent={Paper} disabled>
                <UserBlockAddress variant={'label-large'}>
                  {shortenedAddress}
                </UserBlockAddress>
                {userIcon}
              </UserBlock>
            </>
          );
        }

        return (
          <>
            {isNumber(balance) && <UserBalance balance={balance} balancePending={balancePending} />}
            <Button
              css={css`
                margin-right: 12px;
              `}
              variant={'outlined'}
              onClick={() => {
                dispatch({
                  type: 'setModalContent',
                  payload: {
                    name: 'withdrawDeposit',
                  },
                });
              }}
            >
              {t('HEADER__depositWithdrawButton')}
            </Button>
            <Button
              variant={'outlined'}
              onClick={() => {
                router.push('/stakings');
              }}
            >
              {t('HEADER__stakingButton')}
            </Button>
            <UserBlock
              LinkComponent={Paper}
              onClick={(event) => {
                setDesktopUserMenuAnchorEl(event.currentTarget);
              }}
            >
              <UserBlockAddress variant={'label-large'}>
                {user?.email || shortenedAddress}
              </UserBlockAddress>
              {userIcon}
            </UserBlock>
            <DesktopUserMenu
              anchorEl={desktopUserMenuAnchorEl}
              handleClose={handleDesktopUserMenuClose}
            />
          </>
        );
      })()}
    </Wrapper>
  );
};

const Wrapper = styled('div')`
  display: flex;
  align-items: center;
`;

const Icon = styled(WalletIcon)`
  width: 15px;
  height: 15px;
`;

const UserBalance = styled(DefUserBalance)`
  margin-right: 32px;
`;

const UserBlock = styled(ButtonBase)`
  margin-left: 12px;
  display: flex;
  align-items: center;
  padding: 6px 6px 6px 10px;
  background-color: ${({ theme }) => theme.getColor('deepCove')};
  border-radius: 8px;
  ${({ theme }) => theme.breakpoints.down('md')} {
    padding: 0;
  }
`;

const UserBlockAddress = styled(Text)`
  margin-right: 16px;
`;

const UserBlockUserIcon = styled('div')`
  width: 32px;
  height: 32px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  background-color: ${({ theme }) => theme.getColor('blueZodiac')};
  color: ${({ theme }) => theme.getColor('primary')};
`;

const userIcon = (
  <UserBlockUserIcon>
    <UserIcon
      css={css`
        width: 30px;
        height: 28px;
      `}
    />
  </UserBlockUserIcon>
);

export { HeaderUserBlock };
