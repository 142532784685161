// noinspection SpellCheckingInspection
export const simpleColors = {
  white: '#FFF',
  completelyBlack: '#000',
  blueCharcoal: '#000D20',
  brightTurquoise: '#01ECC8',
  alizarinCrimson: '#DB2636',
  bitterSweet: '#FF7467',
  deepCove: '#041630',
  blueZodiac: '#0E274D',
  bunting: '#112040',
  cyprus: '#003A42',
  spunPearl: '#AEA9B1',
} as const;

// NOT USED, for future
export const gradientColors = {
  gradient1: `linear-gradient(94.1deg, ${simpleColors.white} 0%, ${simpleColors.completelyBlack} 99.58%)`,
} as const;

// NOT USED, for future
export const rgbaColors = {
  rgbaColor1: 'rgba(255, 255, 255, 0.1)',
} as const;

// NOT USED, for future
export const typedColors = {
  // warn: simpleColors.dandelion,
  // success: simpleColors.turquoise,
  error: simpleColors.alizarinCrimson,
  bgColor: simpleColors.blueCharcoal,
  primary: simpleColors.brightTurquoise,
  secondary: rgbaColors.rgbaColor1,
} as const;

export const colors = {
  ...simpleColors,
  ...gradientColors,
  ...rgbaColors,
  ...typedColors,
} as const;

export type ColorName = keyof typeof colors;
export type ColorValue = (typeof colors)[ColorName];
