import { useMemo, useCallback, useEffect, ComponentProps } from 'react';
import { isEmpty, toNumber, toString } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { useFormik, FormikConfig } from 'formik';
import { TFunction } from 'i18next';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';
import {
  TransactionStatus,
  usePlayerTicketLocalCurrencyPayoutFormDataQuery,
  usePayoutPlayerTicketMutation,
  PayoutPlayerTicketMutationVariables,
} from '@/apollo/operations';
import { Button } from '@/components/buttons';
import { PageSpinner } from '@/components/common-elements';
import { useFirebaseAuthState } from '@/hooks';
import { useModals } from '@/providers';
import { sendSentryError } from '@/utils/sentry';
import {
  TextField,
  FormControl,
  FormLabel as DefFormLabel,
  MenuItem,
} from '@mui/material';
import { styled, css, Theme } from '@mui/material/styles';
import { Text } from '../texts';

export type PlayerTicketLocalCurrencyPayoutFormProps = {
  ticketId: string;
  countryId: number;
  onSuccessSubmit?: () => void;
} & ComponentProps<typeof Form>;

const PlayerTicketLocalCurrencyPayoutForm = ({
  ticketId,
  countryId,
  onSuccessSubmit,
  ...props
}: PlayerTicketLocalCurrencyPayoutFormProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [firebaseUser] = useFirebaseAuthState();
  const { dispatch } = useModals<'userSettings' | 'verifyEmail'>();

  const schema = useMemo(() => getSchema({ t }), [t]);

  const { loading: loadingFormData, data: formDataResponse } =
    usePlayerTicketLocalCurrencyPayoutFormDataQuery({
      fetchPolicy: 'cache-and-network',
      variables: {
        countryId,
      },
    });

  const userEmail = formDataResponse?.me?.email;
  const userEmailVerificationRequired =
    !!firebaseUser && !firebaseUser?.emailVerified;
  const user2FAEnabled = !!formDataResponse?.me?.twofa;

  const withdrawBanks = useMemo(
    () => formDataResponse?.paymentMethodBanks ?? [],
    [formDataResponse]
  );

  const showError = (e: Error, msg?: Maybe<string>) => {
    sendSentryError(e);
    enqueueSnackbar(
      msg ||
        t('PLAYER_TICKET_LOCAL_CURRENCY_PAYOUT_FORM__submitBankWithdrawError'),
      {
        variant: 'error',
      }
    );
  };

  const [payoutPlayerTicket] = usePayoutPlayerTicketMutation({
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      if (data.payoutTicket?.status === TransactionStatus.Created) {
        enqueueSnackbar(
          t(
            'PLAYER_TICKET_LOCAL_CURRENCY_PAYOUT_FORM__submitBankWithdrawSuccess'
          ),
          {
            variant: 'success',
          }
        );
      } else {
        showError(
          new Error(
            `payoutPlayerTicket mutation status not wait for confirmation; msg: ${data.payoutTicket?.message}`
          ),
          data.payoutTicket?.message
        );
      }
    },
    onError: (e) => {
      showError(e);
    },
  });

  const onSubmit = useCallback<FormikConfig<FormValues>['onSubmit']>(
    (values) =>
      payoutPlayerTicket({
        variables: {
          ticketId,
          ...values,
        },
      }),
    [ticketId, payoutPlayerTicket]
  );

  const { values, touched, errors, isSubmitting, setFieldValue, submitForm } =
    useFormik({
      initialValues,
      validationSchema: schema,
      onSubmit,
    });

  const banksSelectOptions = useMemo(
    () =>
      withdrawBanks.map(({ id, name }) => ({
        id: toString(id),
        name,
      })),
    [withdrawBanks]
  );

  useEffect(() => {
    if (banksSelectOptions.length === 1) {
      setFieldValue('paymentMethodBankId', parseInt(banksSelectOptions[0].id));
    }
  }, [banksSelectOptions, setFieldValue]);

  return (
    <Form {...props}>
      {(() => {
        if (loadingFormData && isEmpty(formDataResponse)) {
          return <PageSpinner />;
        }

        if (!userEmail) {
          return (
            <>
              <ServiceText>
                {t('PLAYER_TICKET_LOCAL_CURRENCY_PAYOUT_FORM__noEmail')}
              </ServiceText>
              <ServiceButton
                onClick={() => {
                  dispatch({
                    type: 'setModalContent',
                    payload: {
                      name: 'userSettings',
                    },
                  });
                }}
              >
                {t(
                  'PLAYER_TICKET_LOCAL_CURRENCY_PAYOUT_FORM__openSettingsModalButton'
                )}
              </ServiceButton>
            </>
          );
        }

        if (userEmailVerificationRequired) {
          return (
            <>
              <ServiceText>
                {t(
                  'PLAYER_TICKET_LOCAL_CURRENCY_PAYOUT_FORM__noEmailVerification'
                )}
              </ServiceText>
              <ServiceButton
                onClick={() => {
                  dispatch({
                    type: 'setModalContent',
                    payload: {
                      name: 'verifyEmail',
                    },
                  });
                }}
              >
                {t(
                  'PLAYER_TICKET_LOCAL_CURRENCY_PAYOUT_FORM__openVerifyEmailModalButton'
                )}
              </ServiceButton>
            </>
          );
        }

        /*
        if (!user2FAEnabled) {
          return (
            <>
              <ServiceText>{t('PLAYER_TICKET_LOCAL_CURRENCY_PAYOUT_FORM__no2FA')}</ServiceText>
              <ServiceButton
                onClick={() => {
                  dispatch({
                    type: 'setModalContent',
                    payload: {
                      name: 'userSettings',
                    },
                  });
                }}
              >
                {t('PLAYER_TICKET_LOCAL_CURRENCY_PAYOUT_FORM__openSettingsModalButton')}
              </ServiceButton>
            </>
          );
        }
        */

        if (isEmpty(banksSelectOptions)) {
          return (
            <ServiceText>
              {t('PLAYER_TICKET_LOCAL_CURRENCY_PAYOUT_FORM__noBanksOptions')}
            </ServiceText>
          );
        }

        return (
          <>
            <TextField
              css={css`
                margin-bottom: 32px;
              `}
              select
              fullWidth
              label={t(
                'PLAYER_TICKET_LOCAL_CURRENCY_PAYOUT_FORM__bankSelectLabel'
              )}
              value={values.paymentMethodBankId || ''}
              onChange={(data) => {
                const bankId = data.target.value;
                setFieldValue('paymentMethodBankId', toNumber(bankId));
              }}
            >
              {banksSelectOptions.map(({ id, name }) => (
                <MenuItem key={id} value={id}>
                  {name}
                </MenuItem>
              ))}
            </TextField>
            {!!values.paymentMethodBankId && (
              <>
                <BottomFormControl fullWidth>
                  <FormLabel>
                    {t(
                      'PLAYER_TICKET_LOCAL_CURRENCY_PAYOUT_FORM__bankNameLabel'
                    )}
                    :
                  </FormLabel>
                  <Input
                    disabled={isSubmitting}
                    value={values.bankName}
                    error={touched.bankName && !!errors.bankName}
                    helperText={touched.bankName && errors.bankName}
                    onChange={(event) =>
                      setFieldValue('bankName', event.target.value)
                    }
                  />
                </BottomFormControl>
                <BottomFormControl fullWidth>
                  <FormLabel>
                    {t(
                      'PLAYER_TICKET_LOCAL_CURRENCY_PAYOUT_FORM__accountHolderLabel'
                    )}
                    :
                  </FormLabel>
                  <Input
                    disabled={isSubmitting}
                    value={values.accountHolder}
                    error={touched.accountHolder && !!errors.accountHolder}
                    helperText={touched.accountHolder && errors.accountHolder}
                    onChange={(event) =>
                      setFieldValue('accountHolder', event.target.value)
                    }
                  />
                </BottomFormControl>
                <BottomFormControl fullWidth>
                  <FormLabel>
                    {t(
                      'PLAYER_TICKET_LOCAL_CURRENCY_PAYOUT_FORM__accountNumberLabel'
                    )}
                    :
                  </FormLabel>
                  <Input
                    disabled={isSubmitting}
                    value={values.accountNumber}
                    error={touched.accountNumber && !!errors.accountNumber}
                    helperText={touched.accountNumber && errors.accountNumber}
                    onChange={(event) =>
                      setFieldValue('accountNumber', event.target.value)
                    }
                  />
                  {!user2FAEnabled && (
                    <SubmitButton loading={isSubmitting} onClick={submitForm}>
                      {t(
                        'PLAYER_TICKET_LOCAL_CURRENCY_PAYOUT_FORM__submitButton'
                      )}
                    </SubmitButton>
                  )}
                </BottomFormControl>
                {user2FAEnabled && (
                  <BottomFormControl fullWidth>
                    <FormLabel>
                      {t('PLAYER_TICKET_LOCAL_CURRENCY_PAYOUT_FORM__2faLabel')}:
                    </FormLabel>
                    <Input
                      disabled={isSubmitting}
                      value={values.twoFA}
                      error={touched.twoFA && !!errors.twoFA}
                      helperText={touched.twoFA && errors.twoFA}
                      onChange={(event) =>
                        setFieldValue('twoFA', event.target.value)
                      }
                    />
                    <SubmitButton loading={isSubmitting} onClick={submitForm}>
                      {t(
                        'PLAYER_TICKET_LOCAL_CURRENCY_PAYOUT_FORM__submitButton'
                      )}
                    </SubmitButton>
                  </BottomFormControl>
                )}
              </>
            )}
          </>
        );
      })()}
    </Form>
  );
};

type FormValues = Omit<PayoutPlayerTicketMutationVariables, 'ticketId'>;

const initialValues: FormValues = {
  paymentMethodBankId: 0,
  bankName: '',
  accountNumber: '',
  accountHolder: '',
  twoFA: '',
};

const getSchema = ({ t }: { t: TFunction }): yup.ObjectSchema<FormValues> =>
  yup
    .object({
      paymentMethodBankId: yup
        .number()
        .positive(t('FORMS__requiredError'))
        .required(t('FORMS__requiredError')),
      bankName: yup.string().required(t('FORMS__requiredError')),
      accountHolder: yup.string().required(t('FORMS__requiredError')),
      accountNumber: yup.string().required(t('FORMS__requiredError')),
      twoFA: yup.string(),
    })
    .defined();

const Form = styled('form')``;

const ServiceText = styled(Text)`
  margin-top: 22px;
  text-align: center;
`;

const ServiceButton = styled(Button)`
  margin: 20px auto 0;
  display: flex;
`;

const labelStyles = (theme: Theme) => css`
  width: 140px;
  margin-right: 10px;
  ${theme.breakpoints.down('sm')} {
    width: 100%;
  }
`;

const horizontalBlockStyles = (theme: Theme) => css`
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  ${theme.breakpoints.down('sm')} {
    flex-wrap: wrap;
  }
`;

const BottomFormControl = styled(FormControl)`
  ${({ theme }) => horizontalBlockStyles(theme)};
`;

const FormLabel = styled(DefFormLabel)`
  ${({ theme }) => labelStyles(theme)}
  margin-top: 13px;
`;

const Input = styled(TextField)`
  display: flex;
  flex-grow: 1;
`;

const SubmitButton = styled(Button)`
  height: 53px;
  margin-left: 16px;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin-left: 0;
    margin-top: 10px;
    width: 100%;
  }
`;

export { PlayerTicketLocalCurrencyPayoutForm };
