import { HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import Link from 'next/link';
import { resetListStyles } from '@/styles';
import { SiteLinks } from '@/utils/consts';
import { TranslationKeys } from '@/utils/i18n';
import { styled } from '@mui/material/styles';
import { Text } from '../texts';

export type FooterLinksProps = HTMLAttributes<HTMLDivElement>;

const FooterLinks = (props: FooterLinksProps) => {
  const { t } = useTranslation();
  return (
    <Wrapper {...props}>
      <List>
        {links.map(({ url, label }) => (
          <ListItem key={label}>
            <Link
              href={url}
              style={{
                textDecoration: 'none',
              }}
            >
              <Text
                component={'span'}
                variant={'title-medium'}
                weight={'semi-bold'}
              >
                {t(label)}
              </Text>
            </Link>
          </ListItem>
        ))}
      </List>
    </Wrapper>
  );
};

const Wrapper = styled('menu')`
  ${({ theme }) => theme.breakpoints.down('sm')} {
      padding-left: 0;
  }`;

const List = styled('ul')`
  ${resetListStyles};
  display: flex;
`;
const ListItem = styled('li')`
  margin-left: 32px;
  ${({theme}) => theme.breakpoints.down('md')} {
      margin-left: 12px;
  }
  ${({theme}) => theme.breakpoints.down('sm')} {
      margin: 0 7px 0 7px;
  }
`;

const links: ReadonlyArray<{
  url: SiteLinks;
  label: TranslationKeys;
}> = [
  {
    url: SiteLinks.TRACKER,
    label: 'FOOTER__trackerLink',
  },
  {
    url: SiteLinks.ABOUT_US,
    label: 'FOOTER__aboutLink',
  },
  {
    url: SiteLinks.CONTACT_US,
    label: 'FOOTER__contactLink',
  },
  // {
  //   url: SiteLinks.DMCA_NOTICE,
  //   label: 'FOOTER__dmcaLink',
  // },
  {
    url: SiteLinks.TERMS,
    label: 'FOOTER__termsLink',
  },
];

export { FooterLinks };
