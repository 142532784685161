import { HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { LinearProgressBar } from '@/components/styled';
import { Text } from '@/components/texts';
import { getPercentages, getPrice } from '@/utils/helpers';
import { styled } from '@mui/material/styles';

export type ShareProgressProps = {
  currentAmount: number;
  totalAmount: number;
  currentPercent: number;
  totalPercent: number;
} & HTMLAttributes<HTMLDivElement>;

const ShareProgress = ({
  currentAmount,
  totalAmount,
  currentPercent,
  totalPercent,
  ...props
}: ShareProgressProps) => {
  const { t } = useTranslation();
  return (
    <Wrapper {...props}>
      <Progress variant="determinate" value={(currentPercent/totalPercent*100)} />
      <ContentProgressAmount>
        <ValueText component={'span'} variant={'title-small'}>
          {getPrice(currentAmount)}
        </ValueText>
        <AllText component={'span'} variant={'body-small'} color={'spunPearl'}>
          /{getPrice(totalAmount)}
        </AllText>
      </ContentProgressAmount>
      <ContentProgressPercent>
        <ValueText component={'span'} variant={'title-small'}>
          {t('SHARE_PROGRESS__sold')}{' '}
          {getPercentages(currentPercent, { round: true })}
        </ValueText>
        <AllText component={'span'} variant={'body-small'} color={'spunPearl'}>
          /{getPercentages(totalPercent, { round: true })}
        </AllText>
      </ContentProgressPercent>
    </Wrapper>
  );
};

const Wrapper = styled('div')`
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
`;

const Progress = styled(LinearProgressBar)`
  grid-column: 1 / -1;
  margin-bottom: 4px;
`;

const ContentProgressAmount = styled('p')`
${({ theme }) => theme.breakpoints.down('md')} {
    > span {
    display: none;
    }
  }
`;
const ContentProgressPercent = styled('p')``;

const ValueText = styled(Text)``;
const AllText = styled(Text)``;

export { ShareProgress };
