import { useLayoutEffect, useMemo, useState } from 'react';
import { pick, omitBy } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import Link from 'next/link';
import { useSnackbar } from 'notistack';
import { useBuyPlayerShareMutation } from '@/apollo/operations';
import { GeneralStatus } from '@/apollo/operations';
import { Button } from '@/components/buttons';
import { useModals, ModalsData } from '@/providers';
import { resetListStyles } from '@/styles';
import { SiteLinks, externalLinkProps } from '@/utils/consts';
import dayjs from '@/utils/dayjs';
import { getPrice, getPercentages } from '@/utils/helpers';
import { TranslationKeys } from '@/utils/i18n';
import { sendSentryError } from '@/utils/sentry';
import { FormControlLabel, Checkbox } from '@mui/material';
import { styled, css } from '@mui/material/styles';
import { Text as DefText } from '../texts';

const BuyPlayerSharesConfirmModal = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { dispatch, data } = useModals<'buyPlayerSharesConfirm'>();
  const [termsAccepted, setTermsAccepted] = useState(false);

  const infoItems = useMemo(
    () =>
      omitBy(pick(data, pickedFields), (value) => !value) as Pick<
        ModalsData['buyPlayerSharesConfirm'],
        InfoKeys
      >,
    [data]
  );

  const [buyPlayerShare, { loading: buyingPlayerShare }] =
    useBuyPlayerShareMutation({
      fetchPolicy: 'no-cache',
      refetchQueries: 'active',
      awaitRefetchQueries: true,
      onCompleted: (data) => {
        if (data.submitShareBuy?.status === GeneralStatus.Ok) {
          enqueueSnackbar(
            t('BUY_PLAYER_SHARES_CONFIRM_MODAL__buyMutationSuccess'),
            {
              variant: 'success',
            }
          );
          dispatch({
            type: 'closeModal',
          });
        } else {
          enqueueSnackbar(
            t('BUY_PLAYER_SHARES_CONFIRM_MODAL__buyMutationError'),
            {
              variant: 'error',
            }
          );
        }
      },
      onError: (e) => {
        sendSentryError(e);
        enqueueSnackbar(
          t('BUY_PLAYER_SHARES_CONFIRM_MODAL__buyMutationError'),
          {
            variant: 'error',
          }
        );
      },
    });

  useLayoutEffect(() => {
    dispatch({
      type: 'setWrapperProps',
      payload: {
        title: t('BUY_PLAYER_SHARES_CONFIRM_MODAL__title'),
      },
    });
  }, [dispatch, t]);

  const buttonDisabled = !(
    termsAccepted &&
    data?.playerShareId &&
    data.percent
  );

  return (
    <Wrapper>
      <List>
        {(Object.entries(infoItems) as ObjectEntries<typeof infoItems>).map(
          ([key, value]) => {
            let renderValue;
            switch (key) {
              case 'startTime':
                renderValue = dayjs.utc(value).format('D MMMM HH:mm');
                break;
              case 'buyIn':
                renderValue = getPrice(value);
                break;
              case 'shareDilution':
                renderValue = t(
                  value
                    ? 'BUY_PLAYER_SHARES_CONFIRM_MODAL__shareDilutionYes'
                    : 'BUY_PLAYER_SHARES_CONFIRM_MODAL__shareDilutionNo'
                );
                break;
              default:
                renderValue = value;
            }
            return (
              <ListItem key={key}>
                <ListItemLabel component={'h3'} variant={'body-large'}>
                  {t(labels[key])}:
                </ListItemLabel>
                <ListItemValue variant={'title-medium'} weight={'semi-bold'}>
                  {renderValue}
                </ListItemValue>
              </ListItem>
            );
          }
        )}
      </List>
      <FormControlLabel
        css={css`
          margin-top: 16px;
        `}
        control={
          <Checkbox
            checked={termsAccepted}
            onChange={(event, checked) => setTermsAccepted(checked)}
          />
        }
        label={
          <CheckboxLabel>
            {t('BUY_PLAYER_SHARES_CONFIRM_MODAL__termsText')}{' '}
            <CheckboxLabelLink href={SiteLinks.TERMS} {...externalLinkProps}>
              {t('BUY_PLAYER_SHARES_CONFIRM_MODAL__termsLink')}
            </CheckboxLabelLink>
          </CheckboxLabel>
        }
      />
      <Button
        css={css`
          margin-top: 30px;
          align-self: center;
        `}
        loading={buyingPlayerShare}
        disabled={buttonDisabled}
        onClick={() => {
          if (buttonDisabled) return;
          buyPlayerShare({
            variables: {
              playerShareId: data.playerShareId,
              percentage: data.percent,
              password: data.password,
            },
          });
        }}
      >
        {t('BUY_PLAYER_SHARES_CONFIRM_MODAL__submitButton', {
          percent: getPercentages(data?.percent),
          price: getPrice(data?.price),
        })}
      </Button>
    </Wrapper>
  );
};

type InfoKeys = keyof Pick<
  ModalsData['buyPlayerSharesConfirm'],
  | 'eventName'
  | 'tournamentName'
  | 'startTime'
  | 'venueName'
  | 'buyIn'
  | 'markup'
  | 'bullets'
  | 'shareDilution'
>;

const pickedFields: InfoKeys[] = [
  'eventName',
  'tournamentName',
  'startTime',
  'venueName',
  'buyIn',
  'markup',
  'bullets',
  'shareDilution',
];

const labels: {
  [key in InfoKeys]: TranslationKeys;
} = {
  eventName: 'BUY_PLAYER_SHARES_CONFIRM_MODAL__eventNameLabel',
  tournamentName: 'BUY_PLAYER_SHARES_CONFIRM_MODAL__tournamentNameLabel',
  startTime: 'BUY_PLAYER_SHARES_CONFIRM_MODAL__startTimeLabel',
  venueName: 'BUY_PLAYER_SHARES_CONFIRM_MODAL__venueLabel',
  buyIn: 'BUY_PLAYER_SHARES_CONFIRM_MODAL__buyInLabel',
  markup: 'BUY_PLAYER_SHARES_CONFIRM_MODAL__markupLabel',
  bullets: 'BUY_PLAYER_SHARES_CONFIRM_MODAL__bulletsLabel',
  shareDilution: 'BUY_PLAYER_SHARES_CONFIRM_MODAL__shareDilutionLabel',
};

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
`;

const List = styled('ul')`
  ${resetListStyles};
`;

const ListItem = styled('li')`
  display: flex;
`;

const ListItemLabel = styled(DefText)`
  width: 120px;
  margin-right: 16px;
`;

const ListItemValue = styled(DefText)``;

const CheckboxLabel = styled(DefText)``;
const CheckboxLabelLink = styled(Link)`
  color: #fff;
  font-weight: 700;
  @supports (font-variation-settings: normal) {
    font-variation-settings: 'wght' 700;
  }
`;

export { BuyPlayerSharesConfirmModal };
