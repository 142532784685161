export const devEnvironment = process && process.env.NODE_ENV === 'development';

export const server = typeof window === 'undefined';

export const externalLinkProps = {
  rel: 'no-referrer',
  target: '_blank',
};

export enum SiteLinks {
  TRACKER = 'https://app.stake3.com/tracker/',
  TWITTER = 'https://twitter.com/stake3com',
  ABOUT_US = 'https://stake3.com/aboutus',
  CONTACT_US = 'https://stake3.com/contactus',
  DMCA_NOTICE = '/',
  TERMS = 'https://stake3.com/termsofservice',
  BECOME_A_PLAYER = 'https://linktr.ee/stake3',
}
