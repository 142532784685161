import { isString, isNumber } from 'lodash-es';
import { ConnectorNames } from './connectors';
import { server } from './consts';
import { isArrayOrObject } from './helpers';

export enum LocalStorageKeys {
  LANGUAGE = 'stake3/language',
  CONNECTOR = 'stake3/wallet/connector',
  GRAPHQL_AUTH = 'stake3/auth/graphql',
}

export type LocalStorageObserverFn = (value: any) => void;

const changeObservers = Object.values(LocalStorageKeys).reduce(
  (acc, key) => ({
    ...acc,
    [key]: [],
  }),
  {} as Record<LocalStorageKeys, LocalStorageObserverFn[]>
);

type ManagedData<R, P = undefined> = {
  params?: P extends undefined ? R : P;
  response: Nullish<R>;
};

// Type is optional, since initially didn't exist
type GraphqlAuthShape = {
  type?: 'firebase' | 'wallet';
  userId: string;
  accessToken: string;
};

type KeysManageData = {
  [LocalStorageKeys.LANGUAGE]: ManagedData<string>;
  [LocalStorageKeys.GRAPHQL_AUTH]: ManagedData<
    GraphqlAuthShape,
    Required<GraphqlAuthShape>
  >;
  [LocalStorageKeys.CONNECTOR]: ManagedData<ConnectorNames>;
};

export const setLocalStorageValue = <T extends keyof KeysManageData>(
  key: T,
  value: NonNullable<KeysManageData[T]['params']>
) => {
  if (server) return;

  let dataToSave;

  switch (true) {
    case isArrayOrObject(value):
      dataToSave = JSON.stringify(value);
      break;
    case isNumber(value):
      // @ts-ignore
      dataToSave = parseFloat(value);
      break;
    case isString(value):
      dataToSave = value;
      break;
    default:
      dataToSave = null;
  }

  if (!dataToSave) {
    removeLocalStorageValue(key);
    return;
  }

  localStorage.setItem(key, dataToSave as string);
  changeObservers[key].forEach((fn) => fn(value));
};

export const removeLocalStorageValue = (key: LocalStorageKeys) => {
  if (server) return;
  localStorage.removeItem(key);
  changeObservers[key].forEach((fn) => fn(null));
};

export const getLocalStorageValue = <T extends keyof KeysManageData>(
  key: T
): KeysManageData[T]['response'] => {
  if (server) return null;

  const savedData = localStorage.getItem(key);

  if (!savedData) return null;

  try {
    return JSON.parse(savedData);
  } catch (e) {
    return savedData;
  }
};

export const addLocalStorageObserver = (
  key: LocalStorageKeys,
  fn: LocalStorageObserverFn
) => {
  if (changeObservers[key].includes(fn)) return false;
  changeObservers[key].push(fn);
  return true;
};

export const removeLocalStorageObserver = (
  key: LocalStorageKeys,
  fn: LocalStorageObserverFn
) => {
  if (!changeObservers[key].includes(fn)) return false;
  changeObservers[key] = changeObservers[key].filter(
    (storedFn) => storedFn !== fn
  );
  return true;
};
