import { useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ResetPasswordForm } from '@/components/forms';
import { useModals } from '@/providers';

const ResetPasswordModal = () => {
  const { t } = useTranslation();
  const { dispatch } = useModals<'emailLogin'>();

  useLayoutEffect(() => {
    dispatch({
      type: 'setWrapperProps',
      payload: {
        title: t('RESET_PASSWORD_MODAL__title'),
      },
    });
  }, [t, dispatch]);

  return (
    <ResetPasswordForm
      style={{
        marginTop: 16,
      }}
      onSuccessSubmit={() => {
        dispatch({
          type: 'closeModal',
        });
      }}
    />
  );
};

export { ResetPasswordModal };
