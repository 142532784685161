import { LinearProgress, linearProgressClasses } from '@mui/material';
import { styled } from '@mui/material/styles';

export const LinearProgressBar = styled(LinearProgress)(({ theme }) => ({
  height: 16,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: 'rgba(255,255,255,0.2)',
  },
  [`& .${linearProgressClasses.bar}`]: {
    backgroundColor: theme.colors.primary,
  },
}));
