import { isArray, isObject, isString, isNumber, toNumber } from 'lodash-es';
import { NextRouter } from 'next/router';
import dayjs from '@/utils/dayjs';
import { getEnvVariable } from '@/utils/env-helpers';

// import { v4 as uuidv4 } from 'uuid';

const isStringOrNumber = (value: unknown): value is StringOrNumber =>
  [isString, isNumber].some((fn) => fn(value));

export const isArrayOrObject = (
  data: unknown
): data is Array<unknown> | Record<string, unknown> =>
  [isArray, isObject].some((fn) => fn(data));

// export const getUniqueId = () => uuidv4();

type OptionalReadOnlyObject =
  | Record<string, unknown>
  | Readonly<Record<string, unknown>>;
type OptionalReadOnlyArray = Array<unknown> | ReadonlyArray<unknown>;

export const prettifyResponse = (
  data: OptionalReadOnlyObject | OptionalReadOnlyArray
) => JSON.stringify(data, undefined, 2);

export const wait = (delay = 1000) =>
  new Promise((resolve) => {
    setTimeout(resolve, delay);
  });

export const getFormattedNumber = (
  rawAmount: unknown,
  round?: number,
  rounding?: number,
  config?: Intl.NumberFormatOptions
) => {
  if (!isStringOrNumber(rawAmount)) return '';
  if (round === undefined) round = 2;
  let amount = !isNumber(rawAmount) ? toNumber(rawAmount) : rawAmount;
  if (rounding !== undefined && isNumber(rounding))
    amount = getRoundedNumber(amount, rounding);
  return amount.toLocaleString(undefined, {
    maximumFractionDigits: round,
    ...config,
  });
};
export const getRoundedNumber = (rawAmount: unknown, rounding?: number) => {
  if (!isStringOrNumber(rawAmount)) return 0;
  const amount = !isNumber(rawAmount) ? toNumber(rawAmount) : rawAmount;
  if (rounding !== undefined && isNumber(rounding))
    return (
      Math.round(amount / Math.pow(10, -rounding)) * Math.pow(10, -rounding)
    );
  return amount;
};

export const getPrice = (
  rawAmount: unknown,
  round?: number,
  config?: Intl.NumberFormatOptions
) => {
  if (!isStringOrNumber(rawAmount)) return '';
  if (round === undefined) round = 2;
  const amount = !isNumber(rawAmount) ? toNumber(rawAmount) : rawAmount;
  const localeAmount = amount.toLocaleString(undefined, {
    minimumFractionDigits: round,
    maximumFractionDigits: round,
    ...config,
  });

  return '$' + localeAmount;
};

type Options = {
  round?: boolean;
  withSign?: boolean;
};

const defaultOptions: Options = {
  round: false,
  withSign: false,
};

export const getPercentages = (
  rawPercent: unknown,
  options: Options = defaultOptions
) => {
  if (!isStringOrNumber(rawPercent)) return '0%';

  const mergedOptions = {
    ...defaultOptions,
    ...options,
  };

  const percent = isString(rawPercent) ? parseFloat(rawPercent) : rawPercent;

  if (isNaN(percent)) return '0%';

  const localeOptions = {
    style: 'percent',
    minimumFractionDigits: mergedOptions.round ? 0 : 2,
    maximumFractionDigits: mergedOptions.round ? 0 : 2,
  };

  if (!mergedOptions.withSign) {
    return (percent / 100).toLocaleString(undefined, localeOptions);
  }

  const positivePercent = Math.abs(percent);

  let result = (positivePercent / 100).toLocaleString(undefined, localeOptions);

  if (percent !== 0) {
    const positive = percent === 0 || percent > 0;
    result = `${positive ? '+' : '-'} ${result}`;
  }

  return result;
};

export const getNTHNumber = (value: unknown) => {
  if (!isNumber(value)) return '';
  if (value > 3 && value < 21) return `${value}th`;
  switch (value % 10) {
    case 1:
      return `${value}st`;
    case 2:
      return `${value}nd`;
    case 3:
      return `${value}rd`;
    default:
      return `${value}th`;
  }
};

export const isDateAfterCurrentMoment = (utcStr: string) =>
  dayjs.utc().isAfter(dayjs.utc(utcStr));

export const getFrontendUrl = (value: unknown) => {
  if (!isString(value)) return null;
  return `https://${getEnvVariable('FRONTEND_DOMAIN')}/${value}`;
};

/**
 * If removeList is empty, the function removes all params from url.
 * @param {NextRouter} router
 * @param {string[]} removeList
 */
export const removeQueryParamsFromRouter = (
  router: NextRouter,
  removeList: string[] = []
) => {
  if (removeList.length > 0) {
    removeList.forEach((param) => delete router.query[param]);
  } else {
    // Remove all
    Object.keys(router.query).forEach((param) => delete router.query[param]);
  }
  router.replace(
    {
      pathname: router.pathname,
      query: router.query,
    },
    undefined,
    /**
     * Do not refresh the page
     */
    { shallow: true }
  );
};
