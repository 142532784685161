import { CachePersistor, LocalStorageWrapper } from 'apollo3-cache-persist';
import { server } from '@/utils/consts';
import {
  InMemoryCache,
  NormalizedCacheObject,
  InMemoryCacheConfig,
} from '@apollo/client';

const cacheConfig: InMemoryCacheConfig = {
  typePolicies: {
    query_root: {
      // With this settings bugs on go back to sell-share-events page
      /*fields: {
        events: {
          keyArgs: false,
          merge: (_, incoming) => incoming,
        },
      },*/
      queryType: true,
    },
    mutation_root: {
      mutationType: true,
    },
    subscription_root: {
      subscriptionType: true,
    },
    UserBalances: {
      keyFields: false,
    },
    TournamentBalances: {
      keyFields: false,
    },
  },
};

const cache = new InMemoryCache(cacheConfig);

let persistor: CachePersistor<NormalizedCacheObject>;

let persisted = false;

const removeCacheFromLocalStorage = async () => {
  if (!server && persistor) {
    return persistor.purge();
  }
};

const getCache = async () => {
  if (server) return new InMemoryCache(cacheConfig);
  if (persisted) return cache;

  persistor = new CachePersistor({
    cache,
    storage: new LocalStorageWrapper(window.localStorage),
    debug: false,
  });

  await persistor.restore();

  persisted = true;

  return cache;
};

export { getCache, removeCacheFromLocalStorage };
