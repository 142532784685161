import { useTranslation } from 'react-i18next';
import { Button, ButtonProps } from '@/components/buttons/Button';
import { WalletIcon } from '@/components/icons';

export type ConnectWalletButtonProps = ButtonProps;

const ConnectWalletButton = (props: ConnectWalletButtonProps) => {
  const { t } = useTranslation();
  return (
    <Button startIcon={<WalletIcon />} {...props}>
      {t('LOGIN_MODAL__walletButton')}
    </Button>
  );
};

export { ConnectWalletButton };
