import { useMemo } from 'react';
import { EventFinancialOverviewQuery } from '@/apollo/operations';
import { getFormattedNumber, getPrice } from '@/utils/helpers';
import {
  TableRow,
  TableCell as DefTableCell,
  TableRowProps,
} from '@mui/material';
import { css, styled } from '@mui/material/styles';

export type UserVenueFinancialOverviewTableRowProps = {
  tournament: Tournament;
} & TableRowProps;

const UserVenueFinancialOverviewTableRow = ({
  tournament,
  ...props
}: UserVenueFinancialOverviewTableRowProps) => {
  const renderValues = useMemo(() => {
    const pendingBalance = tournament.balances
      .filter(({ status }) => status === 'pending')
      .reduce((acc, { balance }) => (balance ? acc + balance : acc), 0);
    const balance = tournament.balances
      .filter(({ status }) => status === 'approved')
      .reduce((acc, { balance }) => (balance ? acc + balance : acc), 0);
    const totalBalance = pendingBalance + balance;

    const getLocalBalance = (balance: number) =>
      `${getFormattedNumber(balance * tournament.local_currency.rate)} ${
        tournament.local_currency.symbol
      }`;

    const getBalanceString = (balance: number) =>
      `${getPrice(balance)}\n${getLocalBalance(balance)}`;

    return Object.entries({
      name: tournament.name,
      status: tournament.status,
      pendingBalance: getBalanceString(pendingBalance),
      balance: getBalanceString(balance),
      totalExpected: getBalanceString(totalBalance),
    }) as ObjectEntries<UserVenueFinancialOverviewTableRowData>;
  }, [tournament]);

  return (
    <TableRow hover role="checkbox" tabIndex={-1} {...props}>
      {renderValues.map(([key, value], index, arr) => (
        <TableCell
          key={key}
          $centered={isUserVenueFinancialOverviewTableRowCenteredCell(key)}
          $bold={index + 1 === arr.length}
        >
          {value}
        </TableCell>
      ))}
    </TableRow>
  );
};

type Tournament = NonNullable<
  EventFinancialOverviewQuery['event']
>['tournaments'][number];

export type UserVenueFinancialOverviewTableRowData = Pick<
  Tournament,
  'name' | 'status'
> & {
  pendingBalance: string;
  balance: string;
  totalExpected: string;
};

const userVenueFinancialOverviewTableRowCenteredColumns: ReadonlyArray<
  keyof UserVenueFinancialOverviewTableRowData
> = ['pendingBalance', 'balance', 'totalExpected'];

const isUserVenueFinancialOverviewTableRowCenteredCell = (
  column: keyof UserVenueFinancialOverviewTableRowData
) => userVenueFinancialOverviewTableRowCenteredColumns.includes(column);

const TableCell = styled(DefTableCell)<{
  $bold: boolean;
  $centered: boolean;
}>`
  white-space: pre-line;
  ${({ $bold }) =>
    $bold &&
    css`
      font-weight: 500;
    `}
  ${({ $centered }) =>
    $centered &&
    css`
      text-align: center;
    `}
`;

export { UserVenueFinancialOverviewTableRow };
