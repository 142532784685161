import { useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SetWinningPlayerMutationResult } from '@/apollo/operations';
import {
  HorizontalFormControl,
  HorizontalFormControlLabel,
} from '@/components/form-elements';
import { SetWinningPlayerForm } from '@/components/forms';
import { useModals } from '@/providers';
import { getFormattedNumber } from '@/utils/helpers';
import { TranslationKeys } from '@/utils/i18n';
import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Button } from '../buttons';

const SetWinningPlayerModal = () => {
  const { t } = useTranslation();
  const { dispatch, data } = useModals<'setWinningPlayer'>();
  const [mutationResult, setMutationResult] = useState<MutationResult>();

  useLayoutEffect(() => {
    dispatch({
      type: 'setWrapperProps',
      payload: {
        title: t('SET_WINNING_PLAYER_MODAL__title'),
      },
    });
  }, [t, dispatch]);

  if (!data?.playerShareId) {
    return <>Error</>;
  }

  return (
    <>
      <SetWinningPlayerForm
        playerShareId={data.playerShareId}
        disabled={!!mutationResult}
        onSuccessSubmit={(result) => setMutationResult(result)}
      />
      {!!mutationResult && (
        <Result>
          {labels.map(([key, label]) => (
            <HorizontalFormControl key={key} fullWidth>
              <HorizontalFormControlLabel>
                {t(label)}
              </HorizontalFormControlLabel>
              <Input
                name={key}
                disabled
                value={getFormattedNumber(mutationResult[key]) ?? 0}
              />
            </HorizontalFormControl>
          ))}
          <SubmitButton
            onClick={() => {
              dispatch({
                type: 'closeModal',
              });
            }}
          >
            {t('SET_WINNING_PLAYER_MODAL__closeButton')}
          </SubmitButton>
        </Result>
      )}
    </>
  );
};

type MutationResult = NonNullable<
  NonNullable<SetWinningPlayerMutationResult['data']>['playerShareResult']
>;

const labelsMap: ReadonlyMap<
  keyof Pick<
    MutationResult,
    'playerPayout' | 'stake3Payout' | 'stake3PayoutUSD'
  >,
  TranslationKeys
> = new Map([
  ['playerPayout', 'SET_WINNING_PLAYER_MODAL__playerPayoutLabel'],
  ['stake3Payout', 'SET_WINNING_PLAYER_MODAL__stake3PayoutLabel'],
  ['stake3PayoutUSD', 'SET_WINNING_PLAYER_MODAL__stake3USDPayoutLabel'],
]);

const labels = Array.from(labelsMap);

const Input = styled(TextField)`
  flex: 1 1 auto;
`;

const SubmitButton = styled(Button)`
  display: block;
  width: 100%;
  margin-top: 32px;
`;

const Result = styled('div')`
  margin-top: 32px;
`;

export { SetWinningPlayerModal };
