import { PropsWithChildren } from 'react';
import { AppProps } from 'next/app';
import { Apollo } from './Apollo';
import { Blockchains } from './Blockchains';
import { Dates } from './Dates';
import { Modals } from './Modals';
import { ServerAuth } from './ServerAuth';
import { Snackbar } from './Snackbar';
import { UserBalance } from './UserBalance';
import { Wagmi } from './Wagmi';

export type ProvidersProps = PropsWithChildren<{
  pageProps: AppProps['pageProps'];
}>;

const Providers = ({ pageProps, children }: ProvidersProps) => (
  <Snackbar>
    <Apollo pageProps={pageProps}>
      <Wagmi>
        <Blockchains>
          <ServerAuth>
            <UserBalance>
              <Dates>
                <Modals>{children}</Modals>
              </Dates>
            </UserBalance>
          </ServerAuth>
        </Blockchains>
      </Wagmi>
    </Apollo>
  </Snackbar>
);

export { Providers };
