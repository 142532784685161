import {
  useLayoutEffect,
  useMemo,
  useEffect,
  useState,
  useCallback,
} from 'react';
import { isString, isNumber, toString } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { useMethods } from 'react-use';
import Image from 'next/image';
import { useRouter } from 'next/router';
import * as yup from 'yup';
import { useBuyPlayerShareModalDataQuery } from '@/apollo/operations';
import { Button } from '@/components/buttons/Button';
import {
  PageSpinner,
  ShareProgress,
  InfoBlock,
} from '@/components/common-elements';
import {
  HorizontalFormControl,
  HorizontalFormControlLabel,
} from '@/components/form-elements';
import { usePlayerShareRenderData } from '@/hooks';
import { useModals } from '@/providers';
import { useServerAuth } from '@/providers/ServerAuth';
import { resetListStyles } from '@/styles';
import dayjs from '@/utils/dayjs';
import { getPercentages, getPrice } from '@/utils/helpers';
import { Slider, TextField } from '@mui/material';
import { styled, css } from '@mui/material/styles';
import { Text as DefText } from '../texts';

const BuyPlayerSharesModal = () => {
  const { t } = useTranslation();
  const { authorized } = useServerAuth();
  const router = useRouter();
  const { dispatch, data: { playerId, shareId } = {} } = useModals<
    'buyPlayerSharesConfirm',
    'buyPlayerShares'
  >();
  const [tillTime, setTillTime] = useState(initialTillTime);
  const [{ sliderPercentageValue, inputValue }, methods] = useMethods(
    createMethods,
    initialState
  );
  const [amountValue, setAmountValue] = useState(0);

  const passwordValidator = useMemo(
    () => yup.string().required(t('FORMS__requiredError')),
    [t]
  );
  const [passwordInputValue, setPasswordInputValue] = useState('');
  const [passwordInputError, setPasswordInputError] = useState('');

  const isPasswordValid = useCallback(() => {
    try {
      passwordValidator.validateSync(passwordInputValue);
      return true;
    } catch (e) {
      console.log('e', e);
      if (e instanceof Error) {
        setPasswordInputError(e.message);
      }
      return false;
    }
  }, [passwordValidator, passwordInputValue]);

  useEffect(() => {
    setPasswordInputError('');
  }, [passwordInputValue]);

  const { loading, error, data } = useBuyPlayerShareModalDataQuery({
    fetchPolicy: 'cache-and-network',
    pollInterval: 30 * 1000,
    variables: {
      playerId,
      shareId,
    },
  });

  const { player, playerShare } = useMemo(
    () => ({
      player: data?.player,
      playerShare: data?.playerShare,
    }),
    [data]
  );

  const {
    infoBlocks,
    tournamentName,
    // eventName,
    location,
    ended,
    currentAmount,
    totalAmount,
    progressPercent,
    totalPercent,
  } = usePlayerShareRenderData(playerShare);

  const step = useMemo(
    () =>
      isNumber(playerShare?.tournament.buyIn) &&
      playerShare?.tournament.buyIn! < 1000
        ? 1
        : playerShare?.tournament.buyIn! < 10000
        ? 0.1
        : 0.01,
    [playerShare]
  );

  const alreadyBoughtSharePercentByUser = useMemo(
    () =>
      data?.userShareBuys?.reduce(
        (acc, { sharePercentage }) => acc + sharePercentage,
        0
      ) ?? 0,
    [data]
  );

  const availablePercent = useMemo(() => {
    const totalLeftAvailableSharePercent = totalPercent - progressPercent;
    return playerShare?.maxPerUserShare
      ? Math.min(totalLeftAvailableSharePercent, playerShare.maxPerUserShare) -
          alreadyBoughtSharePercentByUser
      : totalLeftAvailableSharePercent;
  }, [
    playerShare?.maxPerUserShare,
    progressPercent,
    totalPercent,
    alreadyBoughtSharePercentByUser,
  ]);

  useLayoutEffect(() => {
    dispatch({
      type: 'setWrapperProps',
      payload: {
        title: t('BUY_PLAYER_SHARES_MODAL__title'),
      },
    });
  }, [dispatch, t]);

  useEffect(() => {
    methods.setSliderPercentageValue(step);
  }, [step, methods]);

  // Close modal if user has been logged out
  useEffect(() => {
    if (!authorized || ended) {
      dispatch({
        type: 'closeModal',
      });
    }
  }, [authorized, ended, dispatch]);

  useEffect(() => {
    const total = (totalAmount / totalPercent) * 100;
    setAmountValue((total * sliderPercentageValue) / 100);
  }, [totalAmount, sliderPercentageValue, totalPercent]);

  useEffect(() => {
    if (!playerShare?.tournament.date) return;
    setTillTime(getTillTime(playerShare.tournament.date));

    const i = setInterval(() => {
      setTillTime(getTillTime(playerShare.tournament.date));
    }, 1000);

    return () => {
      clearInterval(i);
    };
  }, [playerShare?.tournament.date]);

  useEffect(() => {
    if (
      playerShare?.slug &&
      router.query['player-share'] !== playerShare.slug
    ) {
      window.history.replaceState(
        null,
        // @ts-ignore
        null,
        `?player-share=${playerShare.slug}`
      );
    }
  }, [router, playerShare?.slug]);

  return (
    <Wrapper>
      {(() => {
        if (loading && !data) {
          return <PageSpinner />;
        }

        if (error || !(player && playerShare)) {
          return (
            <DefText align={'center'}>
              {t('BUY_PLAYER_SHARES_MODAL__dataLoadError')}
            </DefText>
          );
        }

        return (
          <>
            <Header>
              <HeaderAvatar>
                <Image src={player.image ?? ''} alt={'Avatar'} fill />
              </HeaderAvatar>
              <HeaderName
                component={'h2'}
                variant={'title-medium'}
                weight={'semi-bold'}
              >
                {player.name}
              </HeaderName>
            </Header>
            <Content>
              <ContentEventName
                component={'h1'}
                variant={'title-large'}
                weight={'semi-bold'}
              >
                {tournamentName}
              </ContentEventName>
              <ContentEventLocation variant={'title-small'} color={'primary'}>
                {location}
              </ContentEventLocation>
              <ContentEventTime variant={'body-small'}>
                {!!tillTime.months && (
                  <>
                    <DefText
                      component={'span'}
                      variant={'title-medium'}
                      weight={'semi-bold'}
                    >
                      {tillTime.months}
                    </DefText>{' '}
                    {t('BUY_PLAYER_SHARES_MODAL__months')}
                  </>
                )}
                <DefText
                  component={'span'}
                  variant={'title-medium'}
                  weight={'semi-bold'}
                >
                  {tillTime.days}
                </DefText>{' '}
                {t('BUY_PLAYER_SHARES_MODAL__days')}{' '}
                <DefText
                  component={'span'}
                  variant={'title-medium'}
                  weight={'semi-bold'}
                >
                  {tillTime.hours}
                </DefText>{' '}
                {t('BUY_PLAYER_SHARES_MODAL__hours')}{' '}
                <DefText
                  component={'span'}
                  variant={'title-medium'}
                  weight={'semi-bold'}
                >
                  {tillTime.minutes}
                </DefText>{' '}
                {t('BUY_PLAYER_SHARES_MODAL__minutes')}{' '}
                <DefText
                  component={'span'}
                  variant={'title-medium'}
                  weight={'semi-bold'}
                >
                  {tillTime.seconds}
                </DefText>{' '}
                {t('BUY_PLAYER_SHARES_MODAL__seconds')}
              </ContentEventTime>
              <ContentEventProgress
                currentAmount={currentAmount}
                totalAmount={totalAmount}
                currentPercent={progressPercent}
                totalPercent={totalPercent}
              />
              <ContentInfoBlocksList>
                {infoBlocks.map((props) => (
                  <InfoBlock key={props.title} {...props} />
                ))}
              </ContentInfoBlocksList>
              {!!playerShare.note && (
                <ContentNote>
                  <ContentNoteTitle component={'h3'} variant={'title-medium'}>
                    {t('BUY_PLAYER_SHARES_MODAL__noteLabel')}
                  </ContentNoteTitle>
                  <ContentNoteValue>{playerShare.note}</ContentNoteValue>
                </ContentNote>
              )}
              {availablePercent > 0 ? (
                <ContentBottomForm>
                  <ContentBottomFormSlider>
                    <ContentBottomFormSliderMinValue
                      variant={'title-medium'}
                      weight={'semi-bold'}
                    >
                      {getPercentages(step)}
                    </ContentBottomFormSliderMinValue>
                    <ContentBottomFormSliderMaxValue
                      variant={'title-medium'}
                      weight={'semi-bold'}
                    >
                      {getPercentages(availablePercent)}
                    </ContentBottomFormSliderMaxValue>
                    <Slider
                      css={css`
                        grid-column: 1 / -1;
                      `}
                      aria-label="Percentage"
                      defaultValue={step}
                      step={step}
                      min={step}
                      max={availablePercent}
                      value={sliderPercentageValue}
                      onChange={(event, value) => {
                        if (isNumber(value)) {
                          methods.setSliderPercentageValue(value);
                        }
                      }}
                    />
                  </ContentBottomFormSlider>
                  <ContentBottomFormSliderResult
                    type={'number'}
                    InputProps={{
                      endAdornment: <>%</>,
                    }}
                    inputProps={{
                      step,
                    }}
                    value={inputValue}
                    onChange={(event) => {
                      methods.setInputValue(event.target.value);
                    }}
                  />
                  {!!playerShare.maxPerUserShare && (
                    <MaxPlayerSharePercentText
                      align={'center'}
                      color={'spunPearl'}
                    >
                      {t(
                        'BUY_PLAYER_SHARES_MODAL__playerSharePercentLimitNote',
                        {
                          amount: playerShare.maxPerUserShare,
                        }
                      )}
                    </MaxPlayerSharePercentText>
                  )}
                  {playerShare.passwordRequired && (
                    <HorizontalFormControl
                      css={css`
                        grid-column: 1 / -1;
                        margin-bottom: 8px;
                      `}
                    >
                      <HorizontalFormControlLabel
                        css={css`
                          flex-basis: 160px;
                        `}
                      >
                        {t('BUY_PLAYER_SHARES_MODAL__passwordInputLabel')}
                      </HorizontalFormControlLabel>
                      <TextField
                        fullWidth
                        value={passwordInputValue}
                        error={!!passwordInputError}
                        helperText={passwordInputError}
                        onChange={(e) => {
                          setPasswordInputValue(e.target.value);
                        }}
                      />
                    </HorizontalFormControl>
                  )}
                  <ContentBottomFormButton
                    disabled={
                      !playerShare?.id ||
                      sliderPercentageValue < step ||
                      sliderPercentageValue > availablePercent
                    }
                    onClick={() => {
                      if (!playerShare?.id) return;
                      if (playerShare.passwordRequired && !isPasswordValid()) {
                        return;
                      }
                      dispatch({
                        type: 'setModalContent',
                        payload: {
                          name: 'buyPlayerSharesConfirm',
                          data: {
                            playerShareId: playerShare?.id,
                            eventName:
                              playerShare.tournament.event?.name ??
                              'Event name',
                            tournamentName: playerShare.tournament.name,
                            startTime: playerShare.tournament.date,
                            venueName:
                              playerShare.tournament.event?.venue.name ??
                              'Venue name',
                            buyIn: playerShare.tournament.buyIn,
                            markup: playerShare.markUp,
                            bullets: playerShare.bullets,
                            shareDilution: playerShare.shareDilution,
                            percent: sliderPercentageValue,
                            price: amountValue,
                            password: passwordInputValue || undefined,
                          },
                        },
                      });
                    }}
                  >
                    {t('BUY_PLAYER_SHARES_MODAL__buyButton')}{' '}
                    {getPrice(amountValue)}
                  </ContentBottomFormButton>
                </ContentBottomForm>
              ) : (
                <DefText
                  style={{
                    marginTop: 20,
                  }}
                  align={'center'}
                >
                  {t('BUY_PLAYER_SHARES_MODAL__allBought')}
                </DefText>
              )}
            </Content>
          </>
        );
      })()}
    </Wrapper>
  );
};

type State = {
  sliderPercentageValue: number;
  inputValue: string;
};

const initialState: State = {
  sliderPercentageValue: 0,
  inputValue: '',
};

// noinspection JSUnusedGlobalSymbols
const createMethods = (state: State) => ({
  setSliderPercentageValue: (sliderPercentageValue: number) => {
    return {
      sliderPercentageValue,
      inputValue: toString(sliderPercentageValue),
    };
  },
  setInputValue: (inputValue: string) => {
    return {
      sliderPercentageValue: !isNaN(parseFloat(inputValue))
        ? parseFloat(inputValue)
        : state.sliderPercentageValue,
      inputValue,
    };
  },
});

const initialTillTime = {
  months: 0,
  days: 0,
  hours: 0,
  minutes: 0,
  seconds: 0,
};

const getTillTime = (till: unknown) => {
  if (!(isString(till) && !!till)) {
    return initialTillTime;
  }
  const start = dayjs.utc();
  const end = dayjs.utc(till).subtract(1, 'hour');

  const diff = dayjs.duration(end.diff(start));

  const months = diff.months() > 0 ? diff.months() : 0;
  const days = diff.days() > 0 ? diff.days() : 0;
  const hours = diff.hours() > 0 ? diff.hours() : 0;
  const minutes = diff.minutes() > 0 ? diff.minutes() : 0;
  const seconds = diff.seconds() > 0 ? diff.seconds() : 0;

  return {
    months,
    days,
    hours,
    minutes,
    seconds,
  };
};

const Wrapper = styled('div')``;

const Header = styled('header')`
  display: flex;
  gap: 16px;
`;
const HeaderAvatar = styled('div')`
  width: 64px;
  height: 64px;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
`;
const HeaderName = styled(DefText)``;
const Content = styled('div')`
  padding-top: 14px;
`;
const ContentEventName = styled(DefText)``;
const ContentEventLocation = styled(DefText)``;
const ContentEventTime = styled(DefText)`
  margin-top: 16px;
`;
const ContentEventProgress = styled(ShareProgress)`
  margin-top: 12px;
`;
const ContentInfoBlocksList = styled('ul')`
  ${resetListStyles};
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 4px;
  margin-top: 18px;
`;
const ContentNote = styled('div')`
  margin-top: 20px;
`;
const ContentNoteTitle = styled(DefText)`
  margin-bottom: 4px;
`;
const ContentNoteValue = styled(DefText)``;
const ContentBottomForm = styled('div')`
  margin-top: 38px;
  display: grid;
  grid-template-columns: 1fr 88px;
  grid-gap: 8px 24px;
`;
const ContentBottomFormSlider = styled('div')`
  flex: 1 1 auto;
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
`;
const ContentBottomFormSliderMinValue = styled(DefText)``;
const ContentBottomFormSliderMaxValue = styled(DefText)``;
const ContentBottomFormSliderResult = styled(TextField)`
  margin-top: 23px;
`;
/*const ContentBottomFormSliderResult = styled(DefText)`
  //width: 88px;
  padding: 12px 10px;
  align-self: end;
  border: 1px solid ${({ theme }) => theme.getColor('spunPearl')};
  border-radius: 8px;
  margin-bottom: 2px;
  text-align: center;
  color: ${({ theme }) => theme.getColor('spunPearl')};
`;*/
const MaxPlayerSharePercentText = styled(DefText)`
  grid-column: 1 / -1;
  margin-bottom: 8px;
`;
const ContentBottomFormButton = styled(Button)`
  flex-basis: 100%;
  min-width: 150px;
  grid-column: 1 / -1;
  justify-self: center;
`;

export { BuyPlayerSharesModal };
