import { isString } from 'lodash-es';
// import { getEnvVariable } from '@/utils/env-helpers';
import * as Sentry from '@sentry/nextjs';

/*export const initSentry = () => {
  Sentry.init({
    dsn: getEnvVariable('SENTRY_DSN'),
    maxBreadcrumbs: 50,
    // debug: true,
  });
};*/

export const setSentryUser = (id: string) => {
  Sentry.setUser({ id });
};

export const removeSentryUser = () => {
  Sentry.setUser(null);
};

export const sendSentryError = (e: unknown) => {
  const error =
    e instanceof Error
      ? e
      : new Error(isString(e) ? e : 'sendSentryError received strange e param');
  Sentry.captureException(error);
  console.log('SENTRY:', error);
};
