import { useMemo, createContext, useContext, PropsWithChildren } from 'react';
import { useDeepCompareMemo } from 'use-deep-compare';
import { useNetwork } from 'wagmi';
import { useBlockchainsQuery, BlockchainsQuery } from '@/apollo/operations';

const Blockchains = (props: PropsWithChildren) => {
  const { chain, chains } = useNetwork();

  const supportedBlockchainIds = useMemo(
    () => chains.map(({ id }) => id),
    [chains]
  );

  const { loading, data: { blockchains } = {} } = useBlockchainsQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      supportedBlockchainIds,
    },
  });

  const contextValue = useDeepCompareMemo(
    () => ({
      loading,
      blockchains: blockchains || [],
      activeBlockchain:
        blockchains?.find(({ chainId }) => chainId === chain?.id) || null,
    }),
    [loading, blockchains, chain]
  );

  return <BlockchainsContext.Provider value={contextValue} {...props} />;
};

type Blockchains = BlockchainsQuery['blockchains'][number];

const BlockchainsContext = createContext<{
  loading: boolean;
  blockchains: Blockchains[];
  activeBlockchain: Blockchains | null;
}>({
  loading: false,
  blockchains: [],
  activeBlockchain: null,
});

export const useBlockchains = () => useContext(BlockchainsContext);

export { Blockchains };
