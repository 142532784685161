import { PropsWithChildren, useEffect } from 'react';
import { AppProps } from 'next/app';
import { useApollo } from '@/utils/apollo-client';
import { sendSentryError } from '@/utils/sentry';
import { ApolloProvider } from '@apollo/client';

export type ApolloProps = PropsWithChildren<{
  pageProps: AppProps['pageProps'];
}>;
const Apollo = ({ pageProps, children }: ApolloProps) => {
  const { loading, error, value: client } = useApollo(pageProps);

  useEffect(() => {
    if (error || (!loading && !client)) {
      sendSentryError(error || new Error('There is no apollo client'));
    }
  }, [loading, error, client]);

  if (loading || !client) return null;

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export { Apollo };
