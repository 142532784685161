import { ComponentProps } from 'react';
import { styled } from '@mui/material/styles';
import { Text } from '../texts';
import { ShareDilutionIcon as DefShareDilutionIcon } from "../icons";
import { Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';

export type InfoBlockProps = {
  title: string;
  value: string | number;
  share?: boolean;
} & ComponentProps<typeof Wrapper>;

const InfoBlock = ({ title, value, share, ...props }: InfoBlockProps) => {
  const { t } = useTranslation();
  return (
  <Wrapper {...props}>
    <Title variant={'overline'} color={'spunPearl'} align={'center'}>
      {title}
    </Title>
    <Value variant={'label-large'} align={'center'}>
      {value}{' '}
      {
        share &&
        (<Tooltip title={t('USER_VENUE_TOURNAMENT_PLAYER_SHARES_TABLE__shareDilutionLabel')}>
          <ShareDilutionWrapper>
            <ShareDilutionIcon/>
          </ShareDilutionWrapper>
        </Tooltip>)
      }
    </Value>
  </Wrapper>
)};

const Wrapper = styled('li')`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4px 8px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.getColor('cyprus')};
`;

const Title = styled(Text)``;
const Value = styled(Text)``;

const ShareDilutionWrapper = styled('div')`
  display: inline-block;
  position: absolute;
  padding-left: 2px;
  margin-top: -2px;
`;
const ShareDilutionIcon = styled(DefShareDilutionIcon)`
  width: 20px;
  height: 20px;
`;

export { InfoBlock };
