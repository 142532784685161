import { useEffect, useRef } from 'react';
import { isString } from 'lodash-es';
import { useRouter } from 'next/router';
import { useModals } from '@/providers';
import { useServerAuth } from '@/providers/ServerAuth';
import { removeQueryParamsFromRouter } from '@/utils/helpers';

export const useCustomActionUrlParam = () => {
  const router = useRouter();
  const calledRef = useRef(false);
  const { authorized } = useServerAuth();
  const action = router.query[customActionQueryParamName];
  const { dispatch } = useModals<'withdrawDeposit'>();

  useEffect(() => {
    if (!(isValidCustomActionParamValue(action) && !calledRef.current)) return;

    switch (action) {
      case 'withdraw_modal':
        if (authorized) {
          dispatch({
            type: 'setModalContent',
            payload: {
              name: 'withdrawDeposit',
            },
          });
        }
        break;
      default:
    }

    removeQueryParamsFromRouter(router, [customActionQueryParamName]);

    calledRef.current = true;
  }, [router, authorized, action, dispatch]);
};

const paramValues = ['withdraw_modal'] as const;

const isValidCustomActionParamValue = (
  value: unknown
): value is (typeof paramValues)[number] => {
  // @ts-ignore
  return isString(value) && paramValues.includes(value);
};

const customActionQueryParamName = 'custom-action';
