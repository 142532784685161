import { useCallback } from 'react';
import { useRouter } from 'next/router';
import { useUserMenuDataQuery } from '@/apollo/operations';
import { useWalletConnect } from '@/hooks/useWalletConnect';
import { useModals } from '@/providers';
import { useServerAuth } from '@/providers/ServerAuth';
import {
  removeLocalStorageValue,
  LocalStorageKeys,
} from '@/utils/local-storage';
import {
  MenuItemTypes,
  isUserAPlayer,
  isPlayerProfileAvailable,
  isVenueUser,
} from '@/utils/user-menu';

export const useOnMenuItemClick = () => {
  const router = useRouter();
  const { disconnectWallet } = useWalletConnect();
  const { authorized } = useServerAuth();
  const { dispatch } = useModals<'becomePlayer' | 'userSettings'>();
  const { data } = useUserMenuDataQuery();
  const user = data?.me;

  return useCallback(
    (type: MenuItemTypes) => {
      if (!authorized) return;
      dispatch({
        type: 'closeModal',
      });
      switch (type) {
        case MenuItemTypes.BECOMING_PLAYER:
          /*
           * Open if came outside and user is not yet player
           * In menu inside an app this menu item is hidden if conditions met
           * */
          if (!isUserAPlayer(user)) {
            dispatch({
              type: 'setModalContent',
              payload: {
                name: 'becomePlayer',
              },
            });
          }
          break;
        case MenuItemTypes.PLAYER_PROFILE:
          if (isPlayerProfileAvailable(user)) {
            router.push(`/player-profile`);
          }
          break;
        case MenuItemTypes.PLAYER_SHARES:
          if (isUserAPlayer(user)) {
            router.push(`/player-shares`);
          }
          break;
        case MenuItemTypes.PLAYER_TICKETS:
          if (isUserAPlayer(user)) {
            router.push(`/player-tickets`);
          }
          break;
        case MenuItemTypes.SETTINGS:
          dispatch({
            type: 'setModalContent',
            payload: {
              name: 'userSettings',
            },
          });
          break;
        case MenuItemTypes.VENUE:
          if (isVenueUser(user)) {
            router.push(`/venue-management`);
          }
          break;
        case MenuItemTypes.LANGUAGE:
          break;
        case MenuItemTypes.LOGOUT:
          disconnectWallet();
          removeLocalStorageValue(LocalStorageKeys.GRAPHQL_AUTH);
          break;
        default:
      }
    },
    [router, authorized, user, dispatch, disconnectWallet]
  );
};
