import {
  useEffect,
  useCallback,
  useState,
  useRef,
  useMemo,
  ComponentProps,
  ReactNode,
  MouseEvent,
} from 'react';
import { useTranslation } from 'react-i18next';
import { InputButton } from '@/components/buttons';
import { LoopIcon } from '@/components/icons';
import { PlayerSearchPopover, EventSearchPopover } from '@/components/menus';
import { TournamentSearchPopover } from '@/components/menus/TournamentSearchPopover';
import { resetListStyles } from '@/styles';
import { searchInputsIconStyles } from '@/styles/searchInputs';
import { TranslationKeys } from '@/utils/i18n';
import { Paper, ButtonBaseProps } from '@mui/material';
import { styled } from '@mui/material/styles';

export type DesktopSearchProps = ComponentProps<typeof Wrapper>;

const DesktopSearch = (props: DesktopSearchProps) => {
  const { t } = useTranslation();
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [openedSearch, setOpenedSearch] = useState<InputTypes | null>(null);

  const commonPopoversProps = useMemo(
    () => ({
      anchorEl,
      handleClose: () => setOpenedSearch(null),
    }),
    [anchorEl]
  );

  const onButtonPress = useCallback(
    (e: MouseEvent<HTMLButtonElement>, type: InputTypes) => {
      setOpenedSearch(type);
    },
    []
  );

  useEffect(() => {
    setAnchorEl(wrapperRef.current);
  }, []);

  return (
    <Wrapper ref={wrapperRef} {...props}>
      <ButtonsList>
        {buttons.map(([key, { label, icon, ...props }]) => (
          <li key={key}>
            <InputButton
              onClick={(e) => {
                onButtonPress(e, key);
              }}
              {...props}
            >
              {t(label)} {icon}
            </InputButton>
          </li>
        ))}
      </ButtonsList>
      <PlayerSearchPopover
        {...commonPopoversProps}
        open={openedSearch === InputTypes.PLAYER_NAME}
      />
      <EventSearchPopover
        {...commonPopoversProps}
        open={openedSearch === InputTypes.EVENT_NAME}
      />
      <TournamentSearchPopover
        {...commonPopoversProps}
        open={openedSearch === InputTypes.TOURNAMENT_NAME}
      />
    </Wrapper>
  );
};

enum InputTypes {
  PLAYER_NAME = 'playerName',
  EVENT_NAME = 'eventName',
  TOURNAMENT_NAME = 'tournamentName',
}

const buttonsDataMap: ReadonlyMap<
  InputTypes,
  ButtonBaseProps & { label: TranslationKeys; icon: ReactNode }
> = new Map([
  [
    InputTypes.PLAYER_NAME,
    {
      label: 'SEARCH_BAR__playerNameInputLabel',
      icon: <LoopIcon css={searchInputsIconStyles} />,
    },
  ],
  [
    InputTypes.EVENT_NAME,
    {
      label: 'SEARCH_BAR__eventNameInputLabel',
      icon: <LoopIcon css={searchInputsIconStyles} />,
    },
  ],
  [
    InputTypes.TOURNAMENT_NAME,
    {
      label: 'SEARCH_BAR__tournamentNameInputLabel',
      icon: <LoopIcon css={searchInputsIconStyles} />,
    },
  ],
]);

const buttons = Array.from(buttonsDataMap);

const Wrapper = styled(Paper)`
  padding: 12px;
  position: relative;
`;

const ButtonsList = styled('ul')`
  ${resetListStyles};
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 12px;
`;

export { DesktopSearch };
