import { useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useEditUserPlayerProfileFormDataQuery } from '@/apollo/operations';
import { PageSpinner } from '@/components/common-elements';
import { EditPlayerProfileForm } from '@/components/forms';
import { useModals } from '@/providers';
import { css } from '@mui/material/styles';
import { Text } from '../texts';

const EditPlayerProfileModal = () => {
  const { t } = useTranslation();
  const { dispatch, data } = useModals<'editPlayerProfile'>();

  const {
    loading,
    error,
    data: playerData,
  } = useEditUserPlayerProfileFormDataQuery({
    fetchPolicy: 'cache-and-network',
    skip: !data?.playerId,
    variables: {
      id: data?.playerId,
    },
  });

  const player = playerData?.player;

  useLayoutEffect(() => {
    dispatch({
      type: 'setWrapperProps',
      payload: {
        title: t('EDIT_PLAYER_PROFILE_MODAL__title'),
        dialogContentStyles: css`
          padding-top: 0;
        `,
      },
    });
  }, [dispatch, t]);

  if (loading && !player) {
    return <PageSpinner />;
  }

  if (error || !player) {
    return (
      <Text align={'center'}>
        {t('EDIT_PLAYER_PROFILE_MODAL__loadPlayerDataError')}
      </Text>
    );
  }

  return (
    <EditPlayerProfileForm
      player={player}
      onSuccessSubmit={() => dispatch({ type: 'closeModal' })}
    />
  );
};

export { EditPlayerProfileModal };
