import { useMemo, useState, useEffect } from 'react';
import { isString } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { PlayerPlayerShareDataFragment } from '@/apollo/operations';
import dayjs from '@/utils/dayjs';
import { getPrice, isDateAfterCurrentMoment } from '@/utils/helpers';

export const usePlayerShareRenderData = (
  playerShare: Maybe<PlayerPlayerShareDataFragment>,
  withEndTimer = true
) => {
  const { t } = useTranslation();
  const [ended, setEnded] = useState(
    () =>
      isString(playerShare?.tournament.date) &&
      isDateAfterCurrentMoment(playerShare!.tournament.date)
  );

  useEffect(() => {
    if (withEndTimer && isString(playerShare?.tournament.date)) {
      const i = setInterval(
        () => setEnded(isDateAfterCurrentMoment(playerShare!.tournament.date)),
        1000
      );
      return () => {
        clearInterval(i);
      };
    }
  }, [withEndTimer, playerShare]);

  return useMemo(() => {
    if (!playerShare) {
      return {
        infoBlocks: [],
        tournamentName: '',
        eventName: '',
        eventImage: '',
        location: '',
        date: '',
        ended,
        currentAmount: 0,
        totalAmount: 0,
        progressPercent: 0,
        totalPercent: 0,
      };
    }
    const totalAmount =
      playerShare.sharesToSell *
      (playerShare.tournament.buyIn / 100) *
      playerShare.markUp *
      playerShare.bullets;
    const currentAmount =
      playerShare.player_shares_bought?.totalTotalAmount ?? 0;
    return {
      infoBlocks: [
        {
          title: t('EVENTS__playerBuyIn'),
          value: getPrice(playerShare.tournament.buyIn),
        },
        {
          title: t('EVENTS__playerMarkup'),
          value: playerShare.markUp,
        },
        {
          title: t('EVENTS__playerAvailable'),
          value: getPrice(totalAmount - currentAmount),
        },
        {
          title: t('EVENTS__playerBullets'),
          value: playerShare.bullets,
          share: playerShare.shareDilution ?? false,
        },
      ],
      tournamentName: playerShare.tournament.name ?? 'Tournament name',
      eventName: playerShare.tournament.event?.name ?? 'Event name',
      eventImage: playerShare.tournament.event?.image ?? '',
      location:
        playerShare.tournament.location ??
        playerShare.tournament.event?.location ??
        'Event location',
      date: dayjs(playerShare.tournament.date).format('MMMM D, YYYY - h:mmA'),
      ended,
      currentAmount,
      totalAmount,
      progressPercent:
        playerShare.player_shares_bought?.totalSharePercentage ?? 0,
      totalPercent: playerShare.sharesToSell,
    };
  }, [t, playerShare, ended]);
};
