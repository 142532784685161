import { SvgIcon, SvgIconProps } from '@mui/material';

export type WalletIconProps = SvgIconProps;

const WalletIcon = (props: WalletIconProps) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path
      fillRule="nonzero"
      d="M22.737 20v1.333C22.737 22.8 21.6 24 20.21 24H2.526C1.124 24 0 22.8 0 21.333V2.667C0 1.2 1.124 0 2.526 0h17.685c1.389 0 2.526 1.2 2.526 2.667V4H11.368C9.966 4 8.842 5.2 8.842 6.667v10.666C8.842 18.8 9.966 20 11.368 20h11.369Zm-11.369-2.667H24V6.667H11.368v10.666ZM16.421 14c-1.048 0-1.895-.893-1.895-2s.847-2 1.895-2c1.049 0 1.895.893 1.895 2s-.846 2-1.895 2Z"
    />
  </SvgIcon>
);

export { WalletIcon };
