import { useMemo, useCallback } from 'react';
import type {
  CommonEventDataFragment,
  TournamentPlayerShareCardsDataFragment,
} from '@/apollo/operations';
import type { PlayerShareCardProps } from '@/components/cards';

export const useTournamentsPlayerShareCards = (
  eventLocation: CommonEventDataFragment['location'],
  tournaments: TournamentPlayerShareCardsDataFragment[] = []
) => {
  const getTournamentPlayerShares = useCallback(
    ({
      name,
      slug,
      date,
      location,
      buyIn,
      venue,
      player_shares,
    }: TournamentPlayerShareCardsDataFragment) =>
      player_shares.map((shareData) => ({
        ...shareData,
        tournamentSlug: slug,
        venueSlug: venue.slug,
        name,
        date,
        location: location ?? eventLocation,
        buyIn,
      })) as PlayerShareCardProps['data'][],
    [eventLocation]
  );

  const shares = useMemo(
    () =>
      tournaments.reduce(
        (acc, tournament) => [...acc, ...getTournamentPlayerShares(tournament)],
        [] as PlayerShareCardProps['data'][]
      ).sort((p1, p2) => (p1.sort < p2.sort) ? -1 : (p1.sort > p2.sort) ? 1 : 0),
    [tournaments, getTournamentPlayerShares]
  );

  return useMemo(
    () => ({
      shares,
      getTournamentPlayerShares,
    }),
    [shares, getTournamentPlayerShares]
  );
};
