import { HTMLAttributes } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { HeaderUserBlock } from '@/components/header/HeaderUserBlock';
import { Container as DefContainer } from '@mui/material';
import { styled } from '@mui/material/styles';
import logo from '../../assets/svgs/logo.svg';

export type HeaderProps = HTMLAttributes<HTMLDivElement>;

const Header = (props: HeaderProps) => (
  <Wrapper {...props}>
    <Container>
      <Link href={'/'}>
        <Logo loading={'eager'} src={logo} width={176} height={24} alt="Logo" />
      </Link>
      <HeaderUserBlock />
    </Container>
  </Wrapper>
);

const Wrapper = styled('header')`
  padding-top: 26px;
  padding-bottom: 26px;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding-top: 18px;
    padding-bottom: 18px;
  }
`;

const Container = styled(DefContainer)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Logo = styled(Image)`
  width: 176px;
  height: auto;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: 120px;
  }
`;

export { Header };
