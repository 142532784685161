import { useCallback } from 'react';
import {
  UserStatus,
  UserStatusDocument,
  UserStatusQuery,
  UserStatusQueryVariables,
} from '@/apollo/operations';
import { useModals } from '@/providers';
import { useApolloClient } from '@apollo/client';

export const useGetFirebaseNotifyEmailVerify = () => {
  const client = useApolloClient();
  const { dispatch } = useModals<'verifyEmail'>();
  return useCallback(async () => {
    try {
      const userResponse = await client.query<
        UserStatusQuery,
        UserStatusQueryVariables
      >({
        query: UserStatusDocument,
        fetchPolicy: 'network-only',
      });
      const status = userResponse.data.me?.status;
      if (!status) {
        console.log('NO STATUS AFTER FIREBASE LOGIN');
      }
      if (status === UserStatus.VerifyMail) {
        dispatch({
          type: 'setModalContent',
          // @ts-ignore // remove on modals refactor
          payload: {
            name: 'verifyEmail',
          },
        });
      }
    } catch (e) {}
  }, [client, dispatch]);
};
