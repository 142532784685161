import { CircularProgress, CircularProgressProps } from '@mui/material';

export type PageSpinnerProps = CircularProgressProps;

const PageSpinner = (props: PageSpinnerProps) => (
  <CircularProgress
    style={{
      display: 'block',
      alignSelf: 'center',
      margin: '40px auto',
    }}
    {...props}
  />
);

export { PageSpinner };
