import {
  SearchPopover,
  EventsSearch,
  SearchPopoverProps,
} from '@/components/search';

export type EventSearchPopoverProps = SearchPopoverProps;

const EventSearchPopover = (props: EventSearchPopoverProps) => (
  <SearchPopover id="event-search-menu" {...props}>
    <EventsSearch />
  </SearchPopover>
);

export { EventSearchPopover };
