import { useCallback } from 'react';
import {
  AccessTokenDocument,
  AccessTokenMutation,
  AccessTokenMutationVariables,
} from '@/apollo/operations';
import { useApolloClient } from '@apollo/client';

export const useGetWalletAccessAuthData = () => {
  const client = useApolloClient();

  return useCallback(
    async (variables: AccessTokenMutationVariables) => {
      const result = await client.mutate<
        AccessTokenMutation,
        AccessTokenMutationVariables
      >({
        mutation: AccessTokenDocument,
        fetchPolicy: 'no-cache',
        variables,
      });

      const userId = result.data?.authLogin?.userId;
      const accessToken = result.data?.authLogin?.accesstoken;

      if (!(userId && accessToken)) {
        throw new Error(`No authLogin data in response`);
      }

      return { userId, accessToken };
    },
    [client]
  );
};
