import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useAccount, ConnectorData } from 'wagmi';
import { useServerAuth } from '@/providers/ServerAuth';
import {
  LocalStorageKeys,
  removeLocalStorageValue,
} from '@/utils/local-storage';

export const useWalletEventListeners = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { type } = useServerAuth();
  const { connector, address } = useAccount();

  useEffect(() => {
    if (connector) {
      const handleUpdateEvent = (event: ConnectorData) => {
        if (event.chain?.unsupported) {
          enqueueSnackbar(t('unsupported blockchain'), {
            variant: 'warning',
          });
        }
        // Address was changed
        if (event.account && address !== event.account) {
          if (type === 'wallet') {
            removeLocalStorageValue(LocalStorageKeys.GRAPHQL_AUTH);
          }
        }
      };

      const handleDeactivateEvent = () => {
        removeLocalStorageValue(LocalStorageKeys.CONNECTOR);
        if (type === 'wallet') {
          removeLocalStorageValue(LocalStorageKeys.GRAPHQL_AUTH);
        }
      };

      connector.addListener('change', handleUpdateEvent);
      connector.addListener('disconnect', handleDeactivateEvent);

      return () => {
        connector.removeListener('change', handleUpdateEvent);
        connector.removeListener('disconnect', handleDeactivateEvent);
      };
    }
  }, [t, enqueueSnackbar, connector, address, type]);
};
