import { useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import dynamic from 'next/dynamic';
import { PageSpinner } from '@/components/common-elements';
import { useModals } from '@/providers';
import { css } from '@mui/material/styles';

const BecomePlayerModal = () => {
  const { t } = useTranslation();
  const { dispatch } = useModals();

  useLayoutEffect(() => {
    dispatch({
      type: 'setWrapperProps',
      payload: {
        title: t('BECOME_PLAYER_MODAL__title'),
        dialogContentStyles: css`
          padding-top: 0;
        `,
      },
    });
  }, [dispatch, t]);

  return (
    <BecomePlayerForm
      onSuccessSubmit={() => dispatch({ type: 'closeModal' })}
    />
  );
};

const BecomePlayerForm = dynamic(
  () =>
    import('@/components/forms/BecomePlayerForm').then(
      (mod) => mod.BecomePlayerForm
    ),
  {
    loading: () => <PageSpinner />,
    ssr: false,
  }
);

export { BecomePlayerModal };
