import { useEffect, useRef } from 'react';
import { usePrevious } from 'react-use';
import {
  InitialUserDataDocument,
  InitialUserDataQuery,
  InitialUserDataQueryVariables,
} from '@/apollo/operations';
import { useServerAuth } from '@/providers/ServerAuth';
import { sendSentryError } from '@/utils/sentry';
import { useApolloClient } from '@apollo/client';

export const useInitialUserDataLoad = () => {
  const initialRenderRef = useRef(true);
  const client = useApolloClient();
  const { authorized, userId } = useServerAuth();
  const prevUserId = usePrevious(userId);

  useEffect(() => {
    if (!authorized) return;
    if (initialRenderRef.current || prevUserId !== userId) {
      initialRenderRef.current = false;
      try {
        client.query<InitialUserDataQuery, InitialUserDataQueryVariables>({
          query: InitialUserDataDocument,
          fetchPolicy: 'network-only',
          errorPolicy: 'all',
        });
      } catch (e) {
        const error = e instanceof Error ? e.message : undefined;
        sendSentryError(
          new Error(
            `Debug message to check if that catch actually works: ${error}`
          )
        );
        console.log('Error in initial user data load', e);
      }
    }
  }, [client, authorized, prevUserId, userId]);
};
