import { useLayoutEffect, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { PlayerTicketLocalCurrencyPayoutForm } from '@/components/forms';
import { useModals } from '@/providers';
import { sendSentryError } from '@/utils/sentry';
import { css } from '@mui/material/styles';
import { Text } from '../texts';

const PlayerTicketLocalCurrencyPayoutModal = () => {
  const { t } = useTranslation();
  const { dispatch, data } = useModals<'playerTicketLocalCurrencyPayout'>();

  useLayoutEffect(() => {
    dispatch({
      type: 'setWrapperProps',
      payload: {
        title: t('PLAYER_TICKET_LOCAL_CURRENCY_PAYOUT_MODAL__title', {
          currencySymbol: data?.localCurrencySymbol,
        }),
        dialogContentStyles: css`
          padding-top: 8px;
        `,
      },
    });
  }, [t, dispatch, data]);

  const wrongParams = !(data?.ticketId && data?.localCurrencyCountryId);

  useEffect(() => {
    if (wrongParams) {
      sendSentryError(
        new Error(
          'PlayerTicketLocalCurrencyPayoutModal has wrong params;' +
            ` ticketId: ${data?.ticketId};` +
            `localCurrencyCountryId: ${data?.localCurrencyCountryId}`
        )
      );
    }
  }, [wrongParams, data?.ticketId, data?.localCurrencyCountryId]);

  if (wrongParams) {
    return (
      <Text>{t('PLAYER_TICKET_LOCAL_CURRENCY_PAYOUT_MODAL__paramsError')}</Text>
    );
  }

  return (
    <PlayerTicketLocalCurrencyPayoutForm
      ticketId={data.ticketId}
      countryId={data.localCurrencyCountryId}
      onSuccessSubmit={() => {
        dispatch({
          type: 'closeModal',
        });
      }}
    />
  );
};

export { PlayerTicketLocalCurrencyPayoutModal };
