import { useMemo, ComponentProps } from 'react';
import { isEmpty } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { Formik, Form as DefForm } from 'formik';
import { TFunction } from 'i18next';
import { useSnackbar } from 'notistack';
import { object, string, ObjectSchema } from 'yup';
import {
  useUpdatePlayerProfileMutation,
  EditUserPlayerProfileFormDataQuery,
  UpdatePlayerProfileMutationVariables,
} from '@/apollo/operations';
import { Button, GetImageButton } from '@/components/buttons';
import {
  HorizontalFormControl,
  HorizontalFormControlLabel,
} from '@/components/form-elements';
import {
  FormikTextField,
  FormikTextFieldProps,
} from '@/components/formik-elements';
import { TranslationKeys } from '@/utils/i18n';
import { sendSentryError } from '@/utils/sentry';
import { styled } from '@mui/material/styles';

export type EditPlayerProfileFormProps = {
  player: NonNullable<EditUserPlayerProfileFormDataQuery['player']>;
  onSuccessSubmit?: () => void;
} & ComponentProps<typeof Form>;

const EditPlayerProfileForm = ({
  player,
  onSuccessSubmit,
  ...props
}: EditPlayerProfileFormProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const initialValues = useMemo<FormValues>(
    () =>
      Object.entries(defInitialValues).reduce(
        (acc, [key, defValue]) => ({
          ...acc,
          [key]: player[key as keyof FormValues] ?? defValue,
        }),
        {}
      ),
    [player]
  );

  const schema = useMemo(() => getSchema({ t }), [t]);

  const [updatePlayerProfile] = useUpdatePlayerProfileMutation({
    onCompleted: () => {
      enqueueSnackbar(t('EDIT_PLAYER_PROFILE_FORM__updateSuccess'), {
        variant: 'success',
      });
      onSuccessSubmit?.();
    },
    onError: (e) => {
      sendSentryError(e);
      enqueueSnackbar(t('EDIT_PLAYER_PROFILE_FORM__updateSuccess'), {
        variant: 'success',
      });
    },
  });

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={(values) => {
        const newValues = Object.entries(values).reduce(
          (acc, [key, value]) =>
            initialValues[key as keyof FormValues] === value
              ? acc
              : {
                  ...acc,
                  [key]: value,
                },
          {}
        );

        if (isEmpty(newValues)) {
          enqueueSnackbar(t('EDIT_PLAYER_PROFILE_FORM__noChangesInfo'), {
            variant: 'info',
          });
          return Promise.resolve();
        }

        return updatePlayerProfile({
          variables: {
            playerId: player.id,
            data: newValues,
          },
        });
      }}
    >
      {({ touched, errors, isSubmitting, setFieldValue }) => (
        <Form {...props}>
          {inputs.map(([name, { label, ...props }]) => (
            <HorizontalFormControl key={name}>
              <HorizontalFormControlLabel>
                {t(label)}
              </HorizontalFormControlLabel>
              {name === 'image' ? (
                <AddButton
                  error={touched.image && !!errors.image}
                  onImagePick={(img) => {
                    setFieldValue('image', img);
                  }}
                >
                  {t(label)}
                </AddButton>
              ) : (
                <Input name={name} {...props} />
              )}
            </HorizontalFormControl>
          ))}
          <SubmitButton type={'submit'} loading={isSubmitting}>
            {t('EDIT_PLAYER_PROFILE_FORM__submitButton')}
          </SubmitButton>
        </Form>
      )}
    </Formik>
  );
};

type FormValues = Pick<
  UpdatePlayerProfileMutationVariables['data'],
  'facebook' | 'twitter' | 'description' | 'image'
>;

const defInitialValues: FormValues = {
  facebook: '',
  twitter: '',
  description: '',
  image: '',
};

const getSchema = ({ t }: { t: TFunction }): ObjectSchema<FormValues> =>
  object({
    facebook: string().url(),
    twitter: string().url(),
    description: string().min(8, t('FORMS__minCharsError', { amount: 8 })),
    image: string(),
  }).defined();

const inputsDataMap: ReadonlyMap<
  keyof FormValues,
  {
    label: TranslationKeys;
  } & Omit<FormikTextFieldProps, 'name'>
> = new Map([
  [
    'facebook',
    {
      label: 'EDIT_PLAYER_PROFILE_FORM__facebookInputLabel',
    },
  ],
  [
    'twitter',
    {
      label: 'EDIT_PLAYER_PROFILE_FORM__twitterInputLabel',
    },
  ],
  [
    'description',
    {
      label: 'EDIT_PLAYER_PROFILE_FORM__descriptionInputLabel',
      multiline: true,
      rows: 4,
    },
  ],
  [
    'image',
    {
      label: 'EDIT_PLAYER_PROFILE_FORM__imageInputLabel',
    },
  ],
]);

const inputs = Array.from(inputsDataMap);

const Form = styled(DefForm)`
  display: flex;
  flex-direction: column;
`;

const Input = styled(FormikTextField)`
  flex: 1 1 auto;
`;

const AddButton = styled(GetImageButton)``;

const SubmitButton = styled(Button)`
  margin-top: 32px;
  align-self: center;
  width: 100%;
  max-width: 310px;
`;

export { EditPlayerProfileForm };
