import { Connector } from 'wagmi';
import metamaskIcon from '../assets/svgs/metamask.svg';
import safePalIcon from '../assets/svgs/safepal-icon.svg';
import trustIcon from '../assets/svgs/trust.svg';
import walletConnectIcon from '../assets/svgs/wallet-connect.svg';

export enum ConnectorNames {
  INJECTED = 'injected',
  METAMASK = 'metaMask',
  WALLET_CONNECT = 'walletConnect',
}

export enum WalletTitles {
  METAMASK = 'Metamask',
  TRUST_WALLET = 'Trust Wallet',
  WALLET_CONNECT = 'WalletConnect',
  SAFEPAL = 'SafePal',
}

type WalletConfig = {
  title: WalletTitles;
  icon: string;
  connectorId: ConnectorNames;
  priority: number | (() => number);
  href?: string;
  installed?: boolean;
  downloadLink?: {
    desktop?: string;
    mobile?: string;
  };
};

export const wallets: ReadonlyArray<WalletConfig> = [
  {
    title: WalletTitles.METAMASK,
    icon: metamaskIcon.src,
    /*installed:
      !!window.ethereum &&
      'isMetaMask' in window.ethereum &&
      Boolean(window.ethereum.isMetaMask),*/
    connectorId: ConnectorNames.METAMASK,
    priority: 1,
    href: 'https://metamask.app.link/dapp/inpoker/',
  },
  {
    title: WalletTitles.TRUST_WALLET,
    icon: trustIcon.src,
    connectorId: ConnectorNames.INJECTED,
    /*installed:
      window.ethereum &&
      ['isTrust', 'isTrustWallet'].some(
        // @ts-ignore
        (prop) => prop in window.ethereum && Boolean(window.ethereum[prop])
      ),*/
    priority: 4,
    href: 'https://link.trustwallet.com/open_url?coin_id=20000714&url=https://dashboard.inpoker.io/',
    downloadLink: {
      desktop:
        'https://chrome.google.com/webstore/detail/trust-wallet/egjidjbpglichdcondbcbdnbeeppgdph/related',
    },
  },
  {
    title: WalletTitles.WALLET_CONNECT,
    icon: walletConnectIcon.src,
    connectorId: ConnectorNames.WALLET_CONNECT,
    priority: 5,
  },
  {
    title: WalletTitles.SAFEPAL,
    icon: safePalIcon.src,
    connectorId: ConnectorNames.INJECTED,
    /*installed:
      !!window.ethereum &&
      'isSafePal' in window.ethereum &&
      Boolean(window.ethereum['isSafePal']),*/
    priority: 6,
    /*priority: () =>
      !!window.ethereum &&
      'isSafePal' in window.ethereum &&
      Boolean(window.ethereum['isSafePal'])
        ? 0
        : 999,*/
  },
];

export const isWalletConnectConnector = (connector: Maybe<Connector>) =>
  connector?.id === ConnectorNames.WALLET_CONNECT;

export const isWallet = (title: WalletTitles) =>
  !!wallets.find(({ title: walletTitle }) => walletTitle === title)?.installed;
