import { useState, useEffect, useCallback } from 'react';
import { server } from '@/utils/consts';
import {
  LocalStorageKeys,
  getLocalStorageValue,
  setLocalStorageValue,
  removeLocalStorageValue,
  addLocalStorageObserver,
  removeLocalStorageObserver,
} from '@/utils/local-storage';
import { sendSentryError } from '@/utils/sentry';

export const useLocalStorageValue = <T extends LocalStorageKeys>(key: T) => {
  const [value, setValue] = useState(getLocalStorageValue(key));
  const changeHandler = useCallback((newValue: $TSFixMe) => {
    setValue(newValue);
  }, []);

  useEffect(() => {
    addLocalStorageObserver(key, changeHandler);
    return () => {
      removeLocalStorageObserver(key, changeHandler);
    };
  }, [key, changeHandler]);

  useEffect(() => {
    if (server) return;

    const onStorage = (e: StorageEvent) => {
      if (e.storageArea === localStorage && e.key === key) {
        try {
          if (!e.newValue) {
            removeLocalStorageValue(key);
          } else {
            setLocalStorageValue(key, JSON.parse(e.newValue));
          }
        } catch (e) {
          sendSentryError(
            new Error(`Couldn't update local storage from StorageEvent`)
          );
        }
      }
    };

    window.addEventListener('storage', onStorage);

    return () => {
      window.removeEventListener('storage', onStorage);
    };
  }, [key]);

  return value;
};
