import { SnackbarProvider, SnackbarProviderProps } from 'notistack';

const Snackbar = (props: SnackbarProviderProps) => (
  <SnackbarProvider
    anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
    autoHideDuration={3000}
    {...props}
  />
);

export { Snackbar };
