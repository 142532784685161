import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { UserMenuItems } from '@/components/menus/UserMenuItems';
import { useIsTablet, useOnMenuItemClick } from '@/hooks';
import { MenuItemTypes } from '@/utils/user-menu';
import { Menu, MenuProps } from './Menu';

export type DesktopUserMenuProps = {
  handleClose: () => void;
} & Omit<MenuProps, 'open'>;

const DesktopUserMenu = ({
  anchorEl,
  handleClose,
  ...props
}: DesktopUserMenuProps) => {
  const { i18n } = useTranslation();
  const tablet = useIsTablet();
  const handleMenuItemClick = useOnMenuItemClick();

  useEffect(() => {
    if (tablet) {
      handleClose();
    }
  }, [tablet, handleClose]);

  const onMenuItemClick = useCallback(
    (type: MenuItemTypes) => {
      handleMenuItemClick(type);
      handleClose();
    },
    [handleMenuItemClick, handleClose]
  );

  const onLanguageClick = useCallback(
    (language: string) => {
      i18n.changeLanguage(language);
      handleClose();
    },
    [i18n, handleClose]
  );

  return (
    <Menu
      id="desktop-user-menu"
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleClose}
      onClick={handleClose}
      {...props}
    >
      <UserMenuItems
        onMenuItemClick={onMenuItemClick}
        onLanguageClick={onLanguageClick}
      />
    </Menu>
  );
};

export { DesktopUserMenu };
