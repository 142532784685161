import { SvgIcon, SvgIconProps } from '@mui/material';

export type CloseIconProps = SvgIconProps;

const CloseIcon = (props: CloseIconProps) => (
  <SvgIcon {...props}>
    <path
      fill="currentColor"
      fillRule="nonzero"
      d="M24 2.417 21.583 0 12 9.583 2.417 0 0 2.417 9.583 12 0 21.583 2.417 24 12 14.417 21.583 24 24 21.583 14.417 12z"
    />
  </SvgIcon>
);

export { CloseIcon };
