import { useMemo } from 'react';
import { isFunction } from 'lodash-es';
import { useAccount, useSwitchNetwork } from 'wagmi';
import {
  WalletTitles,
  isWallet,
  isWalletConnectConnector,
} from '@/utils/connectors';

export const useChangeNetwork = () => {
  const { isConnected, connector } = useAccount();
  const switchNetworkArgs = useSwitchNetwork();

  const canSwitch = useMemo(() => {
    if (!isConnected) return false;

    return (
      isFunction(switchNetworkArgs.switchNetworkAsync) &&
      !isWalletConnectConnector(connector) &&
      !isWallet(WalletTitles.SAFEPAL)
    );
  }, [switchNetworkArgs, isConnected, connector]);

  return {
    canSwitch,
    ...switchNetworkArgs,
  };
};
