import {
  SearchPopover,
  PlayersSearch,
  SearchPopoverProps,
} from '@/components/search';

export type PlayerSearchPopoverProps = SearchPopoverProps;

const PlayerSearchPopover = (props: PlayerSearchPopoverProps) => (
  <SearchPopover id="player-search-menu" {...props}>
    <PlayersSearch />
  </SearchPopover>
);

export { PlayerSearchPopover };
