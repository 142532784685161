import dayjs from 'dayjs';
import 'dayjs/locale/en-gb';
import 'dayjs/locale/ko';
import 'dayjs/locale/vi';
import duration from 'dayjs/plugin/duration';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(duration);

export default dayjs;
