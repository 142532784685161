import { useEffect } from 'react';
import { useIsTablet } from '@/hooks';
import { Popover, PopoverProps } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export type SearchPopoverProps = {
  handleClose: () => void;
} & Omit<PopoverProps, 'open'> & {
    open?: boolean;
  };

const SearchPopover = ({
  anchorEl,
  handleClose,
  children,
  ...props
}: SearchPopoverProps) => {
  const tablet = useIsTablet();
  const theme = useTheme();

  useEffect(() => {
    if (tablet) {
      handleClose();
    }
  }, [tablet, handleClose]);

  return (
    <Popover
      id="player-search-menu"
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleClose}
      PaperProps={{
        sx: {
          width: '100%',
          maxWidth: 900,
          '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            left: 14,
            width: 10,
            height: 10,
            bgcolor: theme.colors.blueZodiac,
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: 'left', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
      {...props}
    >
      {children}
    </Popover>
  );
};

export { SearchPopover };
