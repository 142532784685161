import { useState, useLayoutEffect, ComponentProps } from 'react';
import { resetListStyles } from '@/styles';
import { styled, css } from '@mui/material/styles';

export type SearchResultListProps = {
  itemsAmount: number;
  columns: number;
} & ComponentProps<typeof ResultList>;

const SearchResultList = ({ itemsAmount, columns, ...props }: SearchResultListProps) => {
  const [resultRowsStyles, setResultRowsStyles] = useState<
    ReturnType<typeof css> | undefined
  >(undefined);

  useLayoutEffect(() => {
    if (itemsAmount < 1) {
      setResultRowsStyles(undefined);
      return;
    }

    const rowCount = itemsAmount < 5 ? itemsAmount : Math.ceil(itemsAmount / columns);

    setResultRowsStyles(css`
      grid-template-rows: repeat(${rowCount}, auto);
    `);
  }, [itemsAmount, columns]);

  return <ResultList css={resultRowsStyles} {...props} />;
};

const ResultList = styled('ul')`
  ${resetListStyles};
  margin-top: 20px;
  max-height: 300px;
  display: grid;
  grid-auto-flow: column;
  gap: 8px;
  overflow: auto;

  ${({ theme }) => theme.breakpoints.down('md')} {
    display: flex;
    flex-direction: column;
  }
`;

export { SearchResultList };
