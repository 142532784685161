import { useState, useMemo, ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';
import { BlockchainDepositForm, BankDepositForm } from '@/components/forms';
import { TranslationKeys } from '@/utils/i18n';
import { TextField, MenuItem } from '@mui/material';
import { styled, css } from '@mui/material/styles';

export type DepositBlockProps = ComponentProps<typeof Wrapper>;

const DepositBlock = (props: DepositBlockProps) => {
  const { t } = useTranslation();
  const [paymentMethod, setPaymentMethod] = useState<ContentTypes | null>(null);

  const content = useMemo(() => {
    switch (paymentMethod) {
      case ContentTypes.BANK:
        return <BankDepositForm />;
      case ContentTypes.BLOCKCHAIN:
        return <BlockchainDepositForm />;
      default:
        return null;
    }
  }, [paymentMethod]);

  return (
    <Wrapper {...props}>
      <TextField
        css={css`
          margin-bottom: 32px;
        `}
        select
        fullWidth
        label={t('DEPOSIT_BLOCK__selectPlaceholder')}
        // disabled={changingChain || disabled}
        value={paymentMethod ?? ''}
        onChange={(data) => {
          const newPaymentMethod = data.target.value;
          setPaymentMethod(newPaymentMethod as ContentTypes);
        }}
      >
        {options.map(([key, label]) => (
          <MenuItem key={key} value={key}>
            {t(label)}
          </MenuItem>
        ))}
      </TextField>
      {content}
    </Wrapper>
  );
};

enum ContentTypes {
  BANK = 'bank',
  BLOCKCHAIN = 'blockchain',
}

const optionsMap: ReadonlyMap<ContentTypes, TranslationKeys> = new Map([
  [ContentTypes.BANK, 'DEPOSIT_BLOCK__bankSelectMenuItem'],
  [ContentTypes.BLOCKCHAIN, 'DEPOSIT_BLOCK__blockchainSelectMenuItem'],
]);

const options = Array.from(optionsMap);

const Wrapper = styled('section')``;

export { DepositBlock };
