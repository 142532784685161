import { useTranslation } from 'react-i18next';
import { SellPlayerShareForm } from '@/components/forms';
import { useModals } from '@/providers';
import { sendSentryError } from '@/utils/sentry';
import { Text } from '../texts';

const SellPlayerShareModal = () => {
  const { t } = useTranslation();
  const { data: { tournamentId, sharesMax, bulletsMax, buyIn } = {} } =
    useModals<'sellPlayerShare', 'sellPlayerShare'>();

  if (!(tournamentId && sharesMax && bulletsMax && buyIn)) {
    sendSentryError(
      new Error('There is no enough initial data to create SellPlayerShareForm')
    );
    return (
      <Text align={'center'}>{t('SELL_PLAYER_SHARE_MODAL__dataError')}</Text>
    );
  }

  return (
    <SellPlayerShareForm
      tournamentId={tournamentId}
      sharesMax={sharesMax}
      bulletsMax={bulletsMax}
      buyIn={buyIn}
    />
  );
};

export { SellPlayerShareModal };
