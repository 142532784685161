import { useCallback } from 'react';
import { useSignMessage } from 'wagmi';
import {
  NonceDocument,
  NonceMutation,
  NonceMutationVariables,
} from '@/apollo/operations';
import { useApolloClient } from '@apollo/client';

export const useWalletSignature = () => {
  const { signMessageAsync } = useSignMessage();
  const client = useApolloClient();

  const getNonce = useCallback(
    async (address: CryptoAddress) => {
      try {
        const response = await client.mutate<
          NonceMutation,
          NonceMutationVariables
        >({
          mutation: NonceDocument,
          fetchPolicy: 'no-cache',
          variables: {
            address,
          },
        });

        const nonce = response.data?.authSignature?.nonce;

        if (!nonce) {
          throw new Error('No nonce in query response');
        }

        return nonce;
      } catch (e) {
        console.log(`Couldn't get nonce:`, e);
        throw e;
      }
    },
    [client]
  );

  return useCallback(
    async (address: CryptoAddress) => {
      try {
        const nonce = await getNonce(address);
        return await signMessageAsync({
          message: nonce,
        });
      } catch (e) {
        console.log(`Couldn't sign wallet:`, e);
        throw e;
      }
    },
    [getNonce, signMessageAsync]
  );
};
