import { useMemo, ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Form as DefForm } from 'formik';
import { TFunction } from 'i18next';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';
import {
  UserIdDocument,
  GeneralStatus,
  useSetEmailAddressMutation,
  UserIdQuery,
  UserIdQueryVariables,
} from '@/apollo/operations';
import { Button } from '@/components/buttons';
import {
  HorizontalFormControl,
  HorizontalFormControlLabel,
} from '@/components/form-elements';
import { FormikTextField } from '@/components/formik-elements';
import { TranslationKeys } from '@/utils/i18n';
import { sendSentryError } from '@/utils/sentry';
import { styled } from '@mui/material/styles';

export type SetEmailFormProps = ComponentProps<typeof Form>;

const SetEmailForm = (props: SetEmailFormProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const schema = useMemo(() => getSchema({ t }), [t]);

  const showError = (e: Error, msg?: string) => {
    sendSentryError(e);
    enqueueSnackbar(msg || t('SET_EMAIL_FORM__setError'), {
      variant: 'error',
    });
  };

  const [setEmailAddress] = useSetEmailAddressMutation({
    fetchPolicy: 'no-cache',
    update: (cache, result, { variables }) => {
      if (
        result.data?.setEmailaddress?.status === GeneralStatus.Ok &&
        variables?.email
      ) {
        const userIdResponse = cache.readQuery<
          UserIdQuery,
          UserIdQueryVariables
        >({
          query: UserIdDocument,
        });
        if (!userIdResponse?.me) return;
        cache.modify({
          id: cache.identify(userIdResponse.me),
          fields: {
            email: () => variables.email,
          },
        });
      }
    },
    onCompleted: ({ setEmailaddress }) => {
      if (setEmailaddress?.status === GeneralStatus.Ok) {
        enqueueSnackbar(t('SET_EMAIL_FORM__setSuccess'), {
          variant: 'success',
        });
      } else {
        showError(
          new Error(
            `setEmailAddress?.status not ok; response message: ${setEmailaddress?.message}`
          ),
          setEmailaddress?.message
        );
      }
    },
    onError: (e) => {
      showError(e);
    },
  });

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={({ email }) =>
        setEmailAddress({
          variables: {
            email,
          },
        })
      }
    >
      {({ isSubmitting }) => (
        <Form {...props}>
          {inputs.map(([key, label]) => (
            <HorizontalFormControl key={key}>
              <HorizontalFormControlLabel>
                {t(label)}
              </HorizontalFormControlLabel>
              <Input name={key} />
            </HorizontalFormControl>
          ))}
          <SubmitButton type={'submit'} loading={isSubmitting}>
            {t('SET_EMAIL_FORM__submitButton')}
          </SubmitButton>
        </Form>
      )}
    </Formik>
  );
};

const inputsMap: ReadonlyMap<keyof FormValues, TranslationKeys> = new Map([
  ['email', 'SET_EMAIL_FORM__emailInputLabel'],
]);

const inputs = Array.from(inputsMap);

type FormValues = {
  email: string;
};

const initialValues: FormValues = {
  email: '',
};

const getSchema = ({ t }: { t: TFunction }): yup.ObjectSchema<FormValues> =>
  yup
    .object({
      email: yup
        .string()
        .email(t('FORMS__emailError'))
        .required(t('FORMS__requiredError')),
    })
    .defined();

const Form = styled(DefForm)`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Input = styled(FormikTextField)`
  flex: 1 1 auto;
`;

const SubmitButton = styled(Button)`
  margin-top: 32px;
`;

export { SetEmailForm };
