import { useTranslation } from 'react-i18next';
import { WalletIcon } from '@/components/icons';
import { styled } from '@mui/material/styles';
import { Button, ButtonProps } from './Button';

export type SignWalletButtonProps = ButtonProps;

const SignWalletButton = (props: SignWalletButtonProps) => {
  const { t } = useTranslation();
  return (
    <Button startIcon={<Icon />} {...props}>
      {t('WALLET_SIGN_BUTTON')}
    </Button>
  );
};

const Icon = styled(WalletIcon)`
  width: 15px;
  height: 15px;
`;

export { SignWalletButton };
