import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { LanguagesMenuItems } from '@/components/menus/LanguagesMenuItems';
import { Menu, MenuProps } from './Menu';

export type LanguagesMenuProps = {
  handleClose: () => void;
} & Omit<MenuProps, 'open'>;

const LanguagesMenu = ({
  anchorEl,
  handleClose,
  ...props
}: LanguagesMenuProps) => {
  const { i18n } = useTranslation();

  const onItemClick = useCallback(
    (language: string) => {
      i18n.changeLanguage(language);
      handleClose();
    },
    [i18n, handleClose]
  );

  return (
    <Menu
      id="languages-menu"
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleClose}
      onClick={handleClose}
      {...props}
    >
      <LanguagesMenuItems onLanguageClick={onItemClick} />
    </Menu>
  );
};

export { LanguagesMenu };
