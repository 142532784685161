import { useEffect, ComponentProps } from 'react';
import { isEmpty } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useFilePicker } from 'use-file-picker';
import { prettifyResponse } from '@/utils/helpers';
import { resizeImage } from '@/utils/img-helpers';
import { styled } from '@mui/material/styles';
import { Text } from '../texts';
import { AddButton, AddButtonProps } from './AddButton';

export type GetImageButtonProps = {
  error?: boolean;
  withReducing?: boolean;
  onImagePick: (data: string) => void;
} & AddButtonProps &
  Pick<ComponentProps<typeof Wrapper>, 'className' | 'style'>;

const GetImageButton = ({
  className,
  style,
  error = false,
  withReducing = true,
  onImagePick,
  children,
  ...props
}: GetImageButtonProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [openFileSelector, { loading, errors, filesContent }] = useFilePicker({
    readAs: 'DataURL',
    accept: 'image/*',
    multiple: false,
    // limitFilesConfig: { max: 1 },
    // minFileSize: 0.1, // in megabytes
    maxFileSize: 50,
    // imageSizeRestrictions: {
    //   // maxHeight: 3200, // in pixels
    //   // maxWidth: 3200,
    //   // minHeight: 600,
    //   // minWidth: 600,
    // },
  });

  useEffect(() => {
    if (!isEmpty(errors)) {
      let message = t('GET_IMAGE_BUTTON__error');
      if (errors[0]['fileSizeToolarge']) {
        message = t('GET_IMAGE_BUTTON__size_error');
      } else {
        if (errors[0]['imageHeightTooBig']) {
          message = t('GET_IMAGE_BUTTON__big_error');
        } else if (errors[0]['imageHeightTooSmall']) {
          message = t('GET_IMAGE_BUTTON__small_error');
        }
      }
      enqueueSnackbar(message, {
        variant: 'error',
      });
      console.log('pick media errors:', prettifyResponse(errors));
    }
  }, [errors, enqueueSnackbar, t]);

  useEffect(() => {
    if (!filesContent[0]) return;
    if (withReducing) {
      resizeImage(filesContent[0].content).then((img) => onImagePick(img));
    } else {
      onImagePick(filesContent[0].content);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filesContent]);

  return (
    <Wrapper className={className} style={style}>
      <StyledButton
        loading={loading}
        {...(error
          ? {
              color: 'error',
            }
          : {})}
        onClick={openFileSelector}
        {...props}
      >
        {children}
      </StyledButton>
      {!!filesContent[0] && <FileName>{filesContent[0].name}</FileName>}
    </Wrapper>
  );
};

const Wrapper = styled('div')`
  width: 100%;
`;

const StyledButton = styled(AddButton)`
  width: 100%;
  height: 53px;
  padding-left: 8px;
  padding-right: 8px;
  justify-content: flex-start;
  font-size: 0.8rem;
`;

const FileName = styled(Text)`
  margin-top: 4px;
`;

export { GetImageButton };
