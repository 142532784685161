import { useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAccount } from 'wagmi';
import {
  useUserSettingsModalDataQuery,
  ProviderEnum,
} from '@/apollo/operations';
import { Button } from '@/components/buttons';
import { ChangePasswordForm, SetEmailForm } from '@/components/forms';
import { useConnectWalletToFirebaseUser, useFirebaseAuthState } from '@/hooks';
import { useModals } from '@/providers';
import { useServerAuth } from '@/providers/ServerAuth';
import { styled, css } from '@mui/material/styles';
import { WalletIcon } from '../icons';
import { Text } from '../texts';

const UserSettingsModal = () => {
  const { t } = useTranslation();
  const { dispatch } = useModals<
    'wallets' | 'enable2FA' | 'disable2FA' | 'verifyEmail'
  >();
  const { isConnected, isConnecting } = useAccount();
  const { authorized } = useServerAuth();
  const [firebaseUser] = useFirebaseAuthState();

  const { data } = useUserSettingsModalDataQuery({
    skip: !authorized,
  });

  const provider = data?.me?.provider;
  const address = data?.me?.erc20Address;
  const email = data?.me?.email;
  const twoFAEnabled = !!data?.me?.twofa;

  const [
    { loading: connectingWalletToFirebaseUser },
    connectWalletToFirebaseUser,
  ] = useConnectWalletToFirebaseUser();

  useLayoutEffect(() => {
    dispatch({
      type: 'setWrapperProps',
      payload: {
        title: t('USER_SETTINGS_MODAL__title'),
        dialogContentStyles: css`
          padding-top: 0;
        `,
      },
    });
  }, [t, dispatch]);

  if (!authorized) {
    return <Text>Some error appear. Please re-login to see settings</Text>;
  }

  return (
    <>
      <Section>
        <SectionTitle>
          {t('USER_SETTINGS_MODAL__walletSectionTitle')}
        </SectionTitle>
        <SectionContent>
          {(() => {
            if (address) {
              return (
                <Text>
                  {t('USER_SETTINGS_MODAL__walletSectionCurrentWalletAddress')}:{' '}
                  {address}
                </Text>
              );
            }

            if (!isConnected) {
              return (
                <>
                  <WalletDescText>
                    {t('USER_SETTINGS_MODAL__walletSectionDesc1')}
                  </WalletDescText>
                  <Button
                    startIcon={<Icon />}
                    loading={isConnecting}
                    onClick={() => {
                      dispatch({
                        type: 'setModalContent',
                        payload: {
                          name: 'wallets',
                          data: {
                            withServerAuthRequest: false,
                            openModalAfterwards: 'userSettings',
                          },
                        },
                      });
                    }}
                  >
                    {t('USER_SETTINGS_MODAL__walletSectionButton')}
                  </Button>
                </>
              );
            }

            return (
              <>
                <WalletDescText>
                  {t('USER_SETTINGS_MODAL__walletSectionDesc2')}
                </WalletDescText>
                <Button
                  startIcon={<Icon />}
                  loading={connectingWalletToFirebaseUser}
                  onClick={connectWalletToFirebaseUser}
                >
                  {t('USER_SETTINGS_MODAL__walletSectionSignButton')}
                </Button>
              </>
            );
          })()}
        </SectionContent>
      </Section>
      {email ? (
        <Section>
          <SectionTitle>
            {t('USER_SETTINGS_MODAL__2faSectionTitle')}
          </SectionTitle>
          <SectionContent>
            <Button
              onClick={() => {
                if (
                  !twoFAEnabled &&
                  firebaseUser &&
                  !firebaseUser?.emailVerified
                ) {
                  dispatch({
                    type: 'setModalContent',
                    // @ts-ignore // remove on modals refactor
                    payload: {
                      name: 'verifyEmail',
                    },
                  });
                  return;
                }
                dispatch({
                  type: 'setModalContent',
                  // @ts-ignore // remove on modals refactor
                  payload: {
                    name: twoFAEnabled ? 'disable2FA' : 'enable2FA',
                  },
                });
              }}
            >
              {t(
                twoFAEnabled
                  ? 'USER_SETTINGS_MODAL__2faSectionDisableButton'
                  : 'USER_SETTINGS_MODAL__2faSectionEnableButton'
              )}
            </Button>
          </SectionContent>
        </Section>
      ) : (
        <Section>
          <SectionTitle>
            {t('USER_SETTINGS_MODAL__emailSectionTitle')}
          </SectionTitle>
          <SectionContent>
            <SetEmailForm />
          </SectionContent>
        </Section>
      )}
      {provider === ProviderEnum.Password && (
        <Section>
          <SectionTitle>
            {t('USER_SETTINGS_MODAL__changePasswordSectionTitle')}
          </SectionTitle>
          <SectionContent>
            <ChangePasswordForm />
          </SectionContent>
        </Section>
      )}
    </>
  );
};

const Section = styled('section')`
  & + & {
    margin-top: 32px;
  }

  .MuiButton-root {
    align-self: center;
    width: 310px;
    max-width: 100%;
  }
`;

const SectionTitle = styled(Text)`
  margin-bottom: 32px;
`;

SectionTitle.defaultProps = {
  variant: 'title-medium',
  color: 'spunPearl',
};

const SectionContent = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const WalletDescText = styled(Text)`
  margin-bottom: 8px;
`;

const Icon = styled(WalletIcon)`
  width: 15px;
  height: 15px;
`;

export { UserSettingsModal };
