import { SvgIcon, SvgIconProps } from '@mui/material';

export type GoogleIconProps = SvgIconProps;

const GoogleIcon = (props: GoogleIconProps) => (
  <SvgIcon {...props}>
    <path
      fill="currentColor"
      d="M24 12.28C24 19.128 19.234 24 12.197 24 5.449 24 0 18.639 0 12S5.45 0 12.197 0c3.285 0 6.049 1.185 8.178 3.14l-3.32 3.14C12.714 2.159 4.639 5.256 4.639 12c0 4.186 3.398 7.577 7.559 7.577 4.83 0 6.64-3.406 6.924-5.172h-6.924v-4.128h11.611c.113.615.192 1.205.192 2.004z"
    />
  </SvgIcon>
);

export { GoogleIcon };
