import { HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import Image from 'next/image';
import Link from 'next/link';
import logo from '@/assets/svgs/white-logo.svg';
import { FooterLinks as DefFooterLinks } from '@/components/footer/FooterLinks';
import { Container as DefContainer } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Text } from '../texts';

export type FooterProps = HTMLAttributes<HTMLDivElement>;

const Footer = (props: FooterProps) => {
  const { t } = useTranslation();
  return (
    <Wrapper {...props}>
      <Container>
        <Link href={'/'}>
          <Image
            src={logo}
            width={147}
            height={20}
            alt="Logo"
            loading={'eager'}
          />
        </Link>

        <CopyrightText variant={'body-small'} color={'spunPearl'}>
          {t('FOOTER__copyright')}
        </CopyrightText>
        <FooterLinks />
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled('footer')`
  padding-top: 28px;
  padding-bottom: 26px;
  background-color: ${({ theme }) => theme.getColor('completelyBlack')};
`;

const Container = styled(DefContainer)`
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    grid-template-columns: auto;
    justify-content: center;
    justify-items: center;
  }
`;

const CopyrightText = styled(Text)`
  margin-top: 4px;
  grid-column: 1;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    order: 1;
  }
`;

const FooterLinks = styled(DefFooterLinks)`
  grid-column: 2;
  grid-row: 1 / 3;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    grid-column: 1;
    grid-row: unset;
  }
`;

export { Footer };
