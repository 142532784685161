import {
  useState,
  useEffect,
  useMemo,
  useCallback,
  ComponentProps,
} from 'react';
import { isEmpty, toNumber } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { useFormik, FormikConfig } from 'formik';
import { TFunction } from 'i18next';
import { useSnackbar } from 'notistack';
import { parseUnits } from 'viem';
import { useContractWrite, useAccount } from 'wagmi';
import { object, number, ObjectSchema } from 'yup';
import {
  TransactionStatus,
  useBlockchainDepositMutation,
  useBlockchainSubmittedDepositMutation,
  useUserErc20AddressQuery,
} from '@/apollo/operations';
import { Button, ConnectWalletButton } from '@/components/buttons';
import { PageSpinner } from '@/components/common-elements';
import { BlockchainsSelect } from '@/components/form-elements';
import { CurrenciesOptions as DefCurrenciesOptions } from '@/components/form-elements/CurrenciesOptions';
import {
  useBlockchainCurrenciesData,
  useConnectWalletToFirebaseUser,
} from '@/hooks';
import { useBlockchains, useModals } from '@/providers';
import { useServerAuth } from '@/providers/ServerAuth';
import { usdtEthAbi } from '@/utils/ABIs';
import { prettifyResponse } from '@/utils/helpers';
import { sendSentryError } from '@/utils/sentry';
import { ApolloError } from '@apollo/client';
import {
  TextField,
  FormControl,
  FormLabel as DefFormLabel,
} from '@mui/material';
import { styled, css } from '@mui/material/styles';
import { erc20ABI } from '@wagmi/core';
import { Text } from '../texts';

export type BlockchainDepositFormProps = ComponentProps<typeof Form>;

const BlockchainDepositForm = (props: BlockchainDepositFormProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { isConnected, isConnecting, address } = useAccount();
  const { authorized, type } = useServerAuth();
  const { dispatch } = useModals<'wallets'>();
  const { activeBlockchain } = useBlockchains();

  const [schema, setSchema] = useState(getSchema({ t }));

  const [
    { loading: connectingWalletToFirebaseUser },
    connectWalletToFirebaseUser,
  ] = useConnectWalletToFirebaseUser();

  const { data } = useUserErc20AddressQuery({
    skip: !authorized,
  });

  const savedUserAddress = data?.me?.erc20Address;

  const [userPaymentDeposit] = useBlockchainDepositMutation({
    fetchPolicy: 'no-cache',
  });

  const [userPaymentDeposited] = useBlockchainSubmittedDepositMutation({
    fetchPolicy: 'no-cache',
  });

  const { writeAsync } = useContractWrite({
    abi: erc20ABI,
    functionName: 'transfer',
  });

  const { loading: loadingBlockchainCurrencies, data: blockchainCurrencies } =
    useBlockchainCurrenciesData('deposit');

  const onSubmit = useCallback<FormikConfig<FormValues>['onSubmit']>(
    async ({ blockchain, currency, amount }) => {
      try {
        const currencyData = activeBlockchain?.blockchain_currencies.find(
          ({ id }) => id === currency
        );

        if (
          !(
            address &&
            blockchain &&
            currency &&
            amount &&
            currencyData &&
            currencyData.contract
          )
        ) {
          throw new Error(`Some of required params are missed`);
        }

        const depositResult = await (async () => {
          const depositResult = await userPaymentDeposit({
            variables: {
              address,
              blockchain,
              currency,
              amount,
            },
          });

          return depositResult.data?.submitDeposit;
        })();

        const { id, status, depositAddress } = depositResult ?? {};

        if (!(id && status === TransactionStatus.Created && depositAddress)) {
          throw new Error(`No data in payment deposit mutation`);
        }

        const bitIntAmount = parseUnits(`${amount}`, currencyData.decimals);

        let standardAbi = true;
        if (
          currencyData.contract == '0xdac17f958d2ee523a2206206994597c13d831ec7'
        ) {
          // USDT on ETH works a bit different
          standardAbi = false;
        }

        const transactionResult = await writeAsync({
          // @ts-ignore
          abi: standardAbi ? erc20ABI : usdtEthAbi,
          address: currencyData.contract as CryptoAddress,
          args: [depositAddress as CryptoAddress, bitIntAmount],
        });

        if (!transactionResult?.hash) {
          throw new Error('Transaction unsuccessful');
        }

        const { errors, data } = await (() => {
          const mutationConfig = {
            variables: {
              id,
              tx: transactionResult.hash,
            },
          };

          return userPaymentDeposited(mutationConfig);
        })();

        if (
          !isEmpty(errors) ||
          data?.submittedDeposit?.status !==
            TransactionStatus.WaitForConfirmation
        ) {
          throw new Error(
            `Errors on paymentDeposited: ${
              !isEmpty(errors) ? prettifyResponse(errors!) : 'wrong status'
            }`
          );
        }
        enqueueSnackbar(t('BLOCKCHAIN_DEPOSIT_FORM__depositSuccess'), {
          variant: 'success',
        });
      } catch (e) {
        if (e instanceof Error || e instanceof ApolloError) {
          sendSentryError(e);
        }
        console.log(`Error in deposit function:`, e);
        enqueueSnackbar(t('BLOCKCHAIN_DEPOSIT_FORM__depositError'), {
          variant: 'error',
        });
      }
    },
    [
      t,
      enqueueSnackbar,
      address,
      activeBlockchain?.blockchain_currencies,
      writeAsync,
      userPaymentDeposit,
      userPaymentDeposited,
    ]
  );

  const { values, errors, isSubmitting, setFieldValue, submitForm } = useFormik(
    {
      initialValues: {
        ...initialValues,
        blockchain: activeBlockchain?.id ?? null,
      },
      enableReinitialize: true,
      validationSchema: schema,
      onSubmit,
    }
  );

  const chosenCurrencyMaxAmount = useMemo(
    () =>
      toNumber(
        blockchainCurrencies.find(({ id }) => id === values.currency)?.amount ??
          '0'
      ),
    [blockchainCurrencies, values.currency]
  );

  useEffect(() => {
    const min = 1;
    const max = chosenCurrencyMaxAmount;
    setSchema(
      getSchema({
        t,
        min,
        max,
      })
    );
  }, [t, chosenCurrencyMaxAmount]);

  /* User is connected either with firebase or with wallet. Otherwise, he
   * shouldn't be able to see withdraw/deposit button.
   * Interface cases:
   * 1. Not firebase user
   *    a. Wallet connected - normal flow
   *    b. Wallet not connected - connect and auth with wallet
   * 2. Firebase user
   *    a. Saved wallet address exists, wallet is connected, addresses match -
   *       normal flow
   *    b. Wallet not connected blocked with linking wallet to firebase use
   *       button
   * */

  // TODO: add same for wallet auth
  if (type === 'firebase') {
    if (!(savedUserAddress && address)) {
      return !isConnected ? (
        <>
          <Text align={'center'}>
            {t('BLOCKCHAIN_DEPOSIT_FORM__connectWalletDesc1')}
          </Text>
          <ConnectWalletButton
            css={connectWalletButtonStyles}
            loading={isConnecting}
            onClick={() => {
              dispatch({
                type: 'setModalContent',
                payload: {
                  name: 'wallets',
                  data: {
                    withServerAuthRequest: false,
                  },
                },
              });
            }}
          />
        </>
      ) : (
        <>
          <Text align={'center'}>
            {t('BLOCKCHAIN_DEPOSIT_FORM__connectWalletDesc2')}
          </Text>
          <ConnectWalletButton
            css={connectWalletButtonStyles}
            loading={connectingWalletToFirebaseUser}
            onClick={connectWalletToFirebaseUser}
          />
        </>
      );
    } else if (address.toLowerCase() !== savedUserAddress.toLowerCase()) {
      return (
        <Text align={'center'}>
          {t('BLOCKCHAIN_DEPOSIT_FORM__addressesMismatch', {
            address: savedUserAddress,
          })}
        </Text>
      );
    }
  }

  return (
    <Form {...props}>
      <BlockchainsSelect />
      {(() => {
        if (!values.blockchain) return null;

        if (loadingBlockchainCurrencies) {
          return <PageSpinner />;
        }

        if (isEmpty(blockchainCurrencies)) {
          return (
            <Text
              align={'center'}
              css={css`
                margin-top: 22px;
              `}
            >
              {t('BLOCKCHAIN_DEPOSIT_FORM__noOptions')}
            </Text>
          );
        }

        return (
          <>
            <CurrenciesOptions
              disabled={isSubmitting}
              label={t('BLOCKCHAIN_DEPOSIT_FORM__availableOptionsLabel')}
              labelStyles={labelStyles}
              currencies={blockchainCurrencies}
              selectedCurrencyId={values.currency ?? 0}
              error={!!errors.currency}
              helperText={errors.currency}
              onChange={(value) => {
                setFieldValue('currency', value);
              }}
            />
            <BottomFormControl fullWidth>
              <FormLabel css={labelStyles}>
                {t('BLOCKCHAIN_DEPOSIT_FORM__amountToDepositLabel')}:
              </FormLabel>
              <Input
                disabled={!values.currency || isSubmitting}
                value={values.amount}
                placeholder={t(
                  'BLOCKCHAIN_DEPOSIT_FORM__amountToDepositInputPlaceholder'
                )}
                error={!!errors.amount}
                helperText={errors.amount}
                InputProps={{
                  endAdornment: (
                    <MaxButton
                      variant={'outlined'}
                      onClick={() => {
                        if (values.blockchain && values.currency) {
                          setFieldValue('amount', chosenCurrencyMaxAmount);
                        }
                      }}
                    >
                      {t(
                        'BLOCKCHAIN_DEPOSIT_FORM__amountToDepositInputMaxButton'
                      )}
                    </MaxButton>
                  ),
                }}
                onChange={(event) =>
                  setFieldValue('amount', toNumber(event.target.value))
                }
              />
              <SubmitButton loading={isSubmitting} onClick={submitForm}>
                {t('BLOCKCHAIN_DEPOSIT_FORM__button')}
              </SubmitButton>
            </BottomFormControl>
          </>
        );
      })()}
    </Form>
  );
};

type FormValues = {
  blockchain: Nullish<number>;
  currency: Nullish<number>;
  amount: number;
};

const initialValues: FormValues = {
  blockchain: null,
  currency: null,
  amount: 0,
};

const getSchema = ({
  t,
  min = 0,
  max = 0,
}: {
  t: TFunction;
  min?: number;
  max?: number;
}): ObjectSchema<FormValues> =>
  object({
    blockchain: number().required(t('FORMS__requiredError')),
    currency: number().required(t('FORMS__requiredError')),
    amount: number()
      .transform((value) =>
        !isNaN(toNumber(value)) ? toNumber(value) : undefined
      )
      .positive(t('FORMS__positiveError'))
      .min(min, t('FORMS__minError'))
      .max(max, t('FORMS__maxError'))
      .required('Field is required'),
  }).defined();

const connectWalletButtonStyles = css`
  display: flex;
  margin: 10px auto 0;
`;

const Form = styled('form')``;

const labelStyles = css`
  width: 140px;
  margin-right: 10px;
`;

const CurrenciesOptions = styled(DefCurrenciesOptions)`
  margin-top: 22px;
`;

const BottomFormControl = styled(FormControl)`
  flex-direction: row;
  margin-top: 20px;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    flex-wrap: wrap;
  }
`;

const FormLabel = styled(DefFormLabel)`
  margin-top: 13px;
`;

const Input = styled(TextField)`
  display: flex;
  flex-grow: 1;
`;

const MaxButton = styled(Button)`
  position: relative;
  right: -10px;
`;

const SubmitButton = styled(Button)`
  height: 53px;
  margin-left: 16px;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin-left: 0;
    margin-top: 10px;
    width: 100%;
  }
`;

export { BlockchainDepositForm };
