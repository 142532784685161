import { SvgIcon, SvgIconProps } from '@mui/material';

export type PlusIconProps = SvgIconProps;

const PlusIcon = (props: PlusIconProps) => (
  <SvgIcon {...props}>
    <path
      fill="currentColor"
      d="M24 13.5H13.5V24h-3V13.5H0v-3h10.5V0h3v10.5H24z"
    />
  </SvgIcon>
);

export { PlusIcon };
