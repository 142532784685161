import {
  useMemo,
  useCallback,
  useState,
  useEffect,
  ComponentProps,
} from 'react';
import {isEmpty, toNumber, toString, omit, uniqBy} from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { useFormik, FormikConfig } from 'formik';
import { TFunction } from 'i18next';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';
import {
  TransactionStatus,
  useBankWithdrawalFormDataQuery,
  useSubmitBankWithdrawMutation,
  SubmitBankWithdrawMutationVariables,
} from '@/apollo/operations';
import { Button } from '@/components/buttons';
import { PageSpinner } from '@/components/common-elements';
import { useFirebaseAuthState } from '@/hooks';
import { useUserBalance, useModals } from '@/providers';
import { getPercentages } from '@/utils/helpers';
import { getFormattedNumber, getPrice } from '@/utils/helpers';
import { sendSentryError } from '@/utils/sentry';
import {
  TextField,
  FormControl,
  FormLabel as DefFormLabel,
  MenuItem,
} from '@mui/material';
import { styled, css, Theme } from '@mui/material/styles';
import { Text } from '../texts';

export type BankWithdrawFormProps = {
  preSelectedCountry?: number;
} & ComponentProps<typeof Form>;

const BankWithdrawForm = ({
  preSelectedCountry,
  ...props
}: BankWithdrawFormProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [firebaseUser] = useFirebaseAuthState();
  const { dispatch } = useModals<'userSettings' | 'verifyEmail'>();

  const { balance } = useUserBalance();

  const [schema, setSchema] = useState(() => getSchema({ t }));

  const { loading: loadingFormData, data: formDataResponse } =
    useBankWithdrawalFormDataQuery({
      fetchPolicy: 'cache-and-network',
    });

  const userEmail = formDataResponse?.me?.email;
  const userEmailVerificationRequired =
    !!firebaseUser && !firebaseUser?.emailVerified;
  const user2FAEnabled = !!formDataResponse?.me?.twofa;

  const withdrawBanks = useMemo(
    () => formDataResponse?.paymentMethodBanks ?? [],
    [formDataResponse]
  );

  const withdrawBanksCountriesOptions = useMemo(
    () =>
      uniqBy(
        withdrawBanks.map(({ countryId, country }) => ({
          id: toString(countryId),
          name: country,
        })), 'id'
      ),
    [withdrawBanks]
  );

  const showError = (e: Error, msg?: Maybe<string>) => {
    sendSentryError(e);
    enqueueSnackbar(msg || t('BANK_WITHDRAWAL_FORM__submitBankWithdrawError'), {
      variant: 'error',
    });
  };

  const [submitBankWithdraw] = useSubmitBankWithdrawMutation({
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      if (data.submitBankWithdraw?.status === TransactionStatus.Created) {
        enqueueSnackbar(t('BANK_WITHDRAWAL_FORM__submitBankWithdrawSuccess'), {
          variant: 'success',
        });
      } else {
        showError(
          new Error(
            `submitBankWithdraw mutation status not wait for confirmation; msg: ${data.submitBankWithdraw?.message}`
          ),
          data.submitBankWithdraw?.message
        );
      }
    },
    onError: (e) => {
      showError(e);
    },
  });

  const onSubmit = useCallback<FormikConfig<FormValues>['onSubmit']>(
    (values) =>
      submitBankWithdraw({
        variables: omit(values, 'country'),
      }),
    [submitBankWithdraw]
  );

  const { values, touched, errors, isSubmitting, setFieldValue, submitForm } =
    useFormik({
      initialValues: {
        ...initialValues,
        country: preSelectedCountry ?? null,
      },
      enableReinitialize: true,
      validationSchema: schema,
      onSubmit,
    });

  const banksSelectOptions = useMemo(
    () =>
      withdrawBanks
        .filter(({ countryId }) => countryId === values.country)
        .map(({ id, name }) => ({
          id: toString(id),
          name,
        })),
    [withdrawBanks, values.country]
  );

  const selectedBank = useMemo(
    () => withdrawBanks.find(({ id }) => id === values.bank),
    [withdrawBanks, values.bank]
  );

  const maxWithdrawAmount = Math.min(
    balance ?? 0,
    selectedBank?.withdrawMax ?? 0
  );

  useEffect(() => {
    setSchema(
      getSchema({
        t,
        min: selectedBank?.withdrawMin ?? 0,
        max: maxWithdrawAmount,
      })
    );
  }, [t, selectedBank, maxWithdrawAmount]);

  useEffect(() => {
    if (!values.country) return;
    if (banksSelectOptions.length === 1) {
      setFieldValue('bank', parseInt(banksSelectOptions[0].id));
    }
  }, [values.country, banksSelectOptions, setFieldValue]);

  return (
    <Form {...props}>
      {(() => {
        if (loadingFormData && isEmpty(formDataResponse)) {
          return <PageSpinner />;
        }

        if (!userEmail) {
          return (
            <>
              <ServiceText>{t('BANK_WITHDRAWAL_FORM__noEmail')}</ServiceText>
              <ServiceButton
                onClick={() => {
                  dispatch({
                    type: 'setModalContent',
                    payload: {
                      name: 'userSettings',
                    },
                  });
                }}
              >
                {t('BANK_WITHDRAWAL_FORM__openSettingsModalButton')}
              </ServiceButton>
            </>
          );
        }

        if (userEmailVerificationRequired) {
          return (
            <>
              <ServiceText>
                {t('BANK_WITHDRAWAL_FORM__noEmailVerification')}
              </ServiceText>
              <ServiceButton
                onClick={() => {
                  dispatch({
                    type: 'setModalContent',
                    payload: {
                      name: 'verifyEmail',
                    },
                  });
                }}
              >
                {t('BANK_WITHDRAWAL_FORM__openVerifyEmailModalButton')}
              </ServiceButton>
            </>
          );
        }

        // if (!user2FAEnabled) {
        //   return (
        //     <>
        //       <ServiceText>{t('BANK_WITHDRAWAL_FORM__no2FA')}</ServiceText>
        //       <ServiceButton
        //         onClick={() => {
        //           dispatch({
        //             type: 'setModalContent',
        //             payload: {
        //               name: 'userSettings',
        //             },
        //           });
        //         }}
        //       >
        //         {t('BANK_WITHDRAWAL_FORM__openSettingsModalButton')}
        //       </ServiceButton>
        //     </>
        //   );
        // }

        if (!preSelectedCountry && isEmpty(withdrawBanksCountriesOptions)) {
          return (
            <ServiceText>
              {t('BANK_WITHDRAWAL_FORM__noCountriesOptions')}
            </ServiceText>
          );
        }

        return (
          <>
            {!preSelectedCountry && (
              <TextField
                css={css`
                  margin-bottom: 32px;
                `}
                select
                fullWidth
                label={t('BANK_WITHDRAWAL_FORM__countriesSelectLabel')}
                value={values.country ? toString(values.country) : ''}
                onChange={(data) => {
                  const newCountry = data.target.value;
                  setFieldValue('country', toNumber(newCountry));
                }}
              >
                {withdrawBanksCountriesOptions.map(({ id, name }) => (
                  <MenuItem key={id} value={id}>
                    {name}
                  </MenuItem>
                ))}
              </TextField>
            )}
            {!!values.country && banksSelectOptions.length > 1 && (
              <TextField
                css={css`
                  margin-bottom: 32px;
                `}
                select
                fullWidth
                label={t('BANK_WITHDRAWAL_FORM__bankSelectLabel')}
                value={values.bank}
                onChange={(data) => {
                  const bankId = data.target.value;
                  setFieldValue('bank', toNumber(bankId));
                }}
              >
                {banksSelectOptions.map(({ id, name }) => (
                  <MenuItem key={id} value={id}>
                    {name}
                  </MenuItem>
                ))}
              </TextField>
            )}
            {!!values.country && !!values.bank && (
              <>
                <BottomFormControl fullWidth>
                  <FormLabel>
                    {t('BANK_WITHDRAWAL_FORM__bankNameLabel')}:
                  </FormLabel>
                  <Input
                    disabled={isSubmitting}
                    value={values.bankName}
                    error={touched.bankName && !!errors.bankName}
                    helperText={touched.bankName && errors.bankName}
                    onChange={(event) =>
                      setFieldValue('bankName', event.target.value)
                    }
                  />
                </BottomFormControl>
                <BottomFormControl fullWidth>
                  <FormLabel>
                    {t('BANK_WITHDRAWAL_FORM__accountHolderLabel')}:
                  </FormLabel>
                  <Input
                    disabled={isSubmitting}
                    value={values.accountHolder}
                    error={touched.accountHolder && !!errors.accountHolder}
                    helperText={touched.accountHolder && errors.accountHolder}
                    onChange={(event) =>
                      setFieldValue('accountHolder', event.target.value)
                    }
                  />
                </BottomFormControl>
                <BottomFormControl fullWidth>
                  <FormLabel>
                    {t('BANK_WITHDRAWAL_FORM__accountNumberLabel')}:
                  </FormLabel>
                  <Input
                    disabled={isSubmitting}
                    value={values.accountNumber}
                    error={touched.accountNumber && !!errors.accountNumber}
                    helperText={touched.accountNumber && errors.accountNumber}
                    onChange={(event) =>
                      setFieldValue('accountNumber', event.target.value)
                    }
                  />
                </BottomFormControl>
                <BottomFormControl fullWidth>
                  <FormLabel>
                    {t('BANK_WITHDRAWAL_FORM__amountLabel')}:
                  </FormLabel>
                  <Input
                    disabled={isSubmitting}
                    value={values.amount}
                    placeholder={t(
                      'BANK_WITHDRAWAL_FORM__amountInputPlaceholder'
                    )}
                    error={touched.amount && !!errors.amount}
                    helperText={
                      (touched.amount && errors.amount) ||
                      (selectedBank
                        ? `Fee: ${getPercentages(
                            selectedBank.withdrawFee
                          )}  | ` +
                          `${t('BANK_DEPOSIT_FORM__amountMin')}: ${getPrice(
                            selectedBank.withdrawMin
                          )}, ${t('BANK_DEPOSIT_FORM__amountMax')}: ${getPrice(
                            selectedBank.withdrawMax
                          )} | ` +
                          `${t(
                            'BANK_DEPOSIT_FORM__amountExchangeRate'
                          )}: $1,00 ≈ ${getFormattedNumber(
                            selectedBank.withdrawExchangeRate
                          )} ${selectedBank.depositCurrency}`
                        : undefined)
                    }
                    InputProps={{
                      endAdornment: (
                        <MaxButton
                          variant={'outlined'}
                          onClick={() => {
                            setFieldValue('amount', maxWithdrawAmount);
                          }}
                        >
                          {t(
                            'BANK_WITHDRAWAL_FORM__amountToDepositInputMaxButton'
                          )}
                        </MaxButton>
                      ),
                    }}
                    onChange={(event) =>
                      setFieldValue('amount', toNumber(event.target.value))
                    }
                  />
                  {!user2FAEnabled && (
                    <SubmitButton loading={isSubmitting} onClick={submitForm}>
                      {t('BANK_WITHDRAWAL_FORM__submitButton')}
                    </SubmitButton>
                  )}
                </BottomFormControl>
                {user2FAEnabled && (
                  <BottomFormControl fullWidth>
                    <FormLabel>
                      {t('BANK_WITHDRAWAL_FORM__2faLabel')}:
                    </FormLabel>
                    <Input
                      disabled={isSubmitting}
                      value={values.twoFA}
                      error={touched.twoFA && !!errors.twoFA}
                      helperText={touched.twoFA && errors.twoFA}
                      onChange={(event) =>
                        setFieldValue('twoFA', event.target.value)
                      }
                    />
                    <SubmitButton loading={isSubmitting} onClick={submitForm}>
                      {t('BANK_WITHDRAWAL_FORM__submitButton')}
                    </SubmitButton>
                  </BottomFormControl>
                )}
              </>
            )}
          </>
        );
      })()}
    </Form>
  );
};

// TODO2: country should be extracted from form
type FormValues = SubmitBankWithdrawMutationVariables & {
  country: Nullish<number>;
};

const initialValues: FormValues = {
  country: null,
  bank: 0,
  bankName: '',
  accountHolder: '',
  accountNumber: '',
  amount: 0,
  twoFA: '',
};

const getSchema = ({
  t,
  min = 0,
  max = 0,
}: {
  t: TFunction;
  min?: number;
  max?: number;
}): yup.ObjectSchema<FormValues> =>
  yup
    .object({
      country: yup.number().required(t('FORMS__requiredError')),
      bank: yup.number().required(t('FORMS__requiredError')),
      bankName: yup.string().required(t('FORMS__requiredError')),
      accountHolder: yup.string().required(t('FORMS__requiredError')),
      accountNumber: yup.string().required(t('FORMS__requiredError')),
      amount: yup
        .number()
        .positive(t('FORMS__positiveError'))
        .min(min, t('FORMS__minAmountError', { amount: min }))
        .max(max, t('FORMS__maxAmountError', { amount: max }))
        .required(t('FORMS__requiredError')),
      twoFA: yup.string(),
    })
    .defined();

const Form = styled('form')``;

const ServiceText = styled(Text)`
  margin-top: 22px;
  text-align: center;
`;

const ServiceButton = styled(Button)`
  margin: 20px auto 0;
  display: flex;
`;

const labelStyles = (theme: Theme) => css`
  width: 140px;
  margin-right: 10px;
  ${theme.breakpoints.down('sm')} {
    width: 100%;
  }
`;

const horizontalBlockStyles = (theme: Theme) => css`
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  ${theme.breakpoints.down('sm')} {
    flex-wrap: wrap;
  }
`;

const BottomFormControl = styled(FormControl)`
  ${({ theme }) => horizontalBlockStyles(theme)};
`;

const FormLabel = styled(DefFormLabel)`
  ${({ theme }) => labelStyles(theme)}
  margin-top: 13px;
`;

const Input = styled(TextField)`
  display: flex;
  flex-grow: 1;
`;

const MaxButton = styled(Button)`
  position: relative;
  right: -10px;
`;

const SubmitButton = styled(Button)`
  height: 53px;
  margin-left: 16px;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin-left: 0;
    margin-top: 10px;
    width: 100%;
  }
`;

export { BankWithdrawForm };
