import { SvgIcon, SvgIconProps } from '@mui/material';

export type UserIconProps = SvgIconProps;

const UserIcon = (props: UserIconProps) => (
  <SvgIcon {...props}>
    <path
      fill="currentColor"
      fillRule="nonzero"
      d="M12 18.938c2.738 0 9.244 1.106 11.925 4.368a1.79 1.79 0 0 1 .075-.093V24H0v-.786c.025.028.05.06.075.092C2.756 20.044 9.263 18.937 12 18.937ZM12 3a6.546 6.546 0 0 1 6.563 6.563A6.546 6.546 0 0 1 12 16.125a6.546 6.546 0 0 1-6.563-6.563A6.546 6.546 0 0 1 12 3Z"
    />
  </SvgIcon>
);

export { UserIcon };
