import { Fragment, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Image from 'next/image';
import { useUserMenuDataQuery, PlayerStatus } from '@/apollo/operations';
import { LanguagesMenuItems } from '@/components/menus/LanguagesMenuItems';
import { getLangData } from '@/utils/i18n';
import {
  MenuItemTypes,
  playerMenuItems,
  userMenuItems,
  isUserAPlayer,
  isVenueUser,
} from '@/utils/user-menu';
import { Collapse, List, MenuItem as DefMenuItem } from '@mui/material';
import { styled, css } from '@mui/material/styles';

export type UserMenuItemsProps = {
  onMenuItemClick: (type: MenuItemTypes) => void;
  onLanguageClick: (language: string) => void;
};

const UserMenuItems = ({
  onMenuItemClick,
  onLanguageClick,
}: UserMenuItemsProps) => {
  const { t, i18n } = useTranslation();
  const { shortName } = getLangData(i18n.language);
  const [languagesCollapsed, setLanguagesCollapsed] = useState(true);
  const { data } = useUserMenuDataQuery();

  const { player, pendingPlayer } = useMemo(
    () => ({
      player: isUserAPlayer(data?.me),
      pendingPlayer: data?.me?.playerStatus === PlayerStatus.Pending,
    }),
    [data]
  );

  const menuItems = useMemo(() => {
    const venueUser = isVenueUser(data?.me);
    const items = player ? playerMenuItems : userMenuItems;
    return venueUser
      ? items
      : items.filter(([type]) => type !== MenuItemTypes.VENUE);
  }, [data?.me, player]);

  return menuItems.map(([key, { icon, label: defLabel }]) => {
    const label =
      [MenuItemTypes.PLAYER_PROFILE, MenuItemTypes.PLAYER_SHARES].some(
        (i) => i === key
      ) && pendingPlayer
        ? `${t(defLabel)} ${t('USER_MENU__pendingDesc')}`
        : t(defLabel);

    return (
      <Fragment key={label}>
        <MenuItem
          onClick={(e) => {
            if (key === MenuItemTypes.LANGUAGE) {
              e.stopPropagation();
              setLanguagesCollapsed((prevState) => !prevState);
            } else {
              onMenuItemClick(key);
            }
          }}
        >
          <Icon width={32} height={24} src={icon} alt={label} /> {label}
          {key === MenuItemTypes.LANGUAGE ? `(${shortName.toUpperCase()})` : ''}
          {key === MenuItemTypes.LANGUAGE && (
            <SubmenuIcon $closed={languagesCollapsed} />
          )}
        </MenuItem>
        {key === MenuItemTypes.LANGUAGE && (
          <Collapse in={!languagesCollapsed} timeout="auto" unmountOnExit>
            <List
              component={'ul'}
              disablePadding
              css={css`
                padding-left: 36px;
              `}
            >
              <LanguagesMenuItems onLanguageClick={onLanguageClick} />
            </List>
          </Collapse>
        )}
      </Fragment>
    );
  });
};

const MenuItem = styled(DefMenuItem)`
  display: flex;
  & + & {
    margin-top: 10px;
  }
`;

const SubmenuIcon = styled('div')<{
  $closed: boolean;
}>`
  margin-left: auto;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 5px 5px 5px;
  border-color: transparent transparent #fff transparent;
  position: relative;
  bottom: 1px;
  ${({ $closed }) =>
    $closed &&
    css`
      transform: rotate(180deg);
    `}
`;

const Icon = styled(Image)`
  margin-right: 5px;
`;

export { UserMenuItems };
