import { useMemo } from 'react';
import { useCreateUserWithEmailAndPassword } from 'react-firebase-hooks/auth';
import { useGetFirebaseNotifyEmailVerify } from '@/hooks/useGetFirebaseNotifyEmailVerify';
import { firebaseAuth } from '@/utils/firebase';
import { setLocalStorageValue, LocalStorageKeys } from '@/utils/local-storage';
import { sendSentryError } from '@/utils/sentry';

export const useFirebaseRegisterWithEmail = () => {
  const notifyEmailVerify = useGetFirebaseNotifyEmailVerify();
  const [register, ...rest] = useCreateUserWithEmailAndPassword(firebaseAuth);
  return useMemo(() => {
    const customRegister = async (
      ...params: Parameters<
        ReturnType<typeof useCreateUserWithEmailAndPassword>[0]
      >
    ) => {
      const userCredential = await register(...params);
      if (userCredential) {
        try {
          const accessToken = await userCredential.user.getIdToken();
          setLocalStorageValue(LocalStorageKeys.GRAPHQL_AUTH, {
            type: 'firebase',
            userId: userCredential.user.uid,
            accessToken,
          });
          notifyEmailVerify();
        } catch (e) {
          if (e instanceof Error) {
            sendSentryError(e);
          }
        }
      }
      return userCredential;
    };
    return [customRegister, ...rest] as const;
  }, [register, notifyEmailVerify, rest]);
};
