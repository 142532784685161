import { useState, useMemo, useRef, useEffect, ComponentProps } from 'react';
import { toNumber } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { Formik, Form as DefForm, FormikProps } from 'formik';
import { TFunction } from 'i18next';
import { ObjectSchema, object, string, number, boolean } from 'yup';
import { CreatePlayerShareMutationVariables } from '@/apollo/operations';
import { Button } from '@/components/buttons';
import { Slider } from '@/components/form-elements';
import { FormikTextField } from '@/components/formik-elements';
import { useModals } from '@/providers';
import { getPercentages } from '@/utils/helpers';
import { Checkbox, FormControlLabel } from '@mui/material';
import { styled, css } from '@mui/material/styles';
import { Text } from '../texts';

export type SellPlayerShareFormProps = {
  tournamentId: string;
  sharesMax: number;
  bulletsMax: number;
  buyIn: number;
} & ComponentProps<typeof Form>;

const SellPlayerShareForm = ({
  tournamentId,
  sharesMax,
  bulletsMax,
  buyIn,
  ...props
}: SellPlayerShareFormProps) => {
  const { t } = useTranslation();
  const { dispatch } = useModals<'sellPlayerSharesConfirm'>();
  const formikRef = useRef<FormikProps<FormValues> | null>(null);
  const [userShareRequired, setUserShareRequired] = useState(false);
  const [passwordRequired, setPasswordRequired] = useState(false);

  const schema = useMemo(
    () =>
      getSchema({
        t,
        userShareRequired,
        passwordRequired,
        sharesMax,
        bulletsMax,
      }),
    [t, sharesMax, bulletsMax, userShareRequired, passwordRequired]
  );

  const sharesToSellMarks = useMemo(() => {
    const step = sharesMax / 4;
    return Array.from(Array(5)).map((_, index) => ({
      label: getPercentages(index * step, { round: true }),
      value: index * step,
    }));
  }, [sharesMax]);

  const bulletsMarks = useMemo(
    () =>
      Array.from(Array(bulletsMax)).map((_, index) => ({
        label: index + 1,
        value: index + 1,
      })),
    [bulletsMax]
  );

  useEffect(() => {
    if (!userShareRequired) {
      formikRef.current?.setFieldValue('maxPerUserShare', 0);
      formikRef.current?.setFieldTouched('maxPerUserShare', false);
      formikRef.current?.setFieldError('maxPerUserShare', undefined);
    }

    if (!passwordRequired) {
      formikRef.current?.setFieldValue('password', '');
      formikRef.current?.setFieldTouched('password', false);
      formikRef.current?.setFieldError('password', undefined);
    }
  }, [userShareRequired, passwordRequired]);

  // noinspection RequiredAttributes
  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={(values) => {
        // Required if values were added with input and therefore strings
        const checkedValues = Object.entries(values).reduce(
          (acc, [key, value]) => ({
            ...acc,
            [key]: numFormKeys.some((numKey) => numKey === key)
              ? toNumber(value)
              : value,
          }),
          {}
        ) as FormValues;
        dispatch({
          type: 'setModalContent',
          payload: {
            name: 'sellPlayerSharesConfirm',
            data: {
              tournamentId,
              ...checkedValues,
            },
          },
        });
      }}
    >
      {({ values, touched, errors, setFieldValue }) => (
        <Form {...props}>
          <Slider
            label={t('SELL_PLAYER_SHARE_FORM__shareSellLabel')}
            value={values.sharesToSell}
            min={0}
            max={sharesMax}
            step={1}
            sliderProps={{
              marks: sharesToSellMarks,
            }}
            errorText={
              touched.sharesToSell && errors.sharesToSell
                ? errors.sharesToSell
                : undefined
            }
            onChange={(value) => setFieldValue('sharesToSell', value)}
          />
          <Slider
            label={t('SELL_PLAYER_SHARE_FORM__markUpLabel')}
            value={values.markUp}
            min={1}
            max={2}
            step={0.05}
            sliderProps={{
              marks: [
                {
                  label: '1',
                  value: 1,
                },
                {
                  label: '1.5',
                  value: 1.5,
                },
                {
                  label: '2.0',
                  value: 2,
                },
              ],
            }}
            errorText={
              touched.markUp && errors.markUp ? errors.markUp : undefined
            }
            onChange={(value) => {
              setFieldValue('markUp', value);
            }}
          />
          <Slider
            label={t('SELL_PLAYER_SHARE_FORM__bulletsLabel')}
            value={values.bullets}
            min={1}
            max={bulletsMax}
            step={1}
            sliderProps={{
              marks: bulletsMarks,
            }}
            errorText={
              touched.bullets && errors.bullets ? errors.bullets : undefined
            }
            onChange={(value) => setFieldValue('bullets', value)}
          />
          <FormControlLabel
            css={topOffsetStyles}
            control={
              <Checkbox
                checked={values.shareDilution}
                onChange={(event, checked) =>
                  setFieldValue('shareDilution', checked)
                }
              />
            }
            label={
              <Text>{t('SELL_PLAYER_SHARE_FORM__shareDilutionLabel')} </Text>
            }
          />
          <CheckboxWithInput>
            <FormControlLabel
              control={
                <Checkbox
                  checked={userShareRequired}
                  onChange={(event, checked) => setUserShareRequired(checked)}
                />
              }
              label={
                <Text>
                  {t('SELL_PLAYER_SHARE_FORM__limitSharePerUserCheckboxLabel')}{' '}
                </Text>
              }
            />
            <FormikTextField
              name={'maxPerUserShare'}
              type={'number'}
              placeholder={'5%'}
              disabled={!userShareRequired}
            />
          </CheckboxWithInput>
          {/*<CheckboxWithInput>*/}
          {/*  <FormControlLabel*/}
          {/*    control={*/}
          {/*      <Checkbox*/}
          {/*        checked={passwordRequired}*/}
          {/*        onChange={(event, checked) => setPasswordRequired(checked)}*/}
          {/*      />*/}
          {/*    }*/}
          {/*    label={*/}
          {/*      <Text>*/}
          {/*        {t('SELL_PLAYER_SHARE_FORM__passwordCheckboxLabel')}{' '}*/}
          {/*      </Text>*/}
          {/*    }*/}
          {/*  />*/}
          {/*  <FormikTextField name={'password'} disabled={!passwordRequired} />*/}
          {/*</CheckboxWithInput>*/}
          <FormikTextField
            css={topOffsetStyles}
            fullWidth
            name={'note'}
            multiline
            rows={4}
            placeholder={t('SELL_PLAYER_SHARE_FORM__notePlaceholder')}
          />
          <SubmitButton type={'submit'}>
            {t('SELL_PLAYER_SHARE_FORM__submitButton')}
          </SubmitButton>
        </Form>
      )}
    </Formik>
  );
};

type FormValues = Omit<CreatePlayerShareMutationVariables, 'tournamentId'>;

const initialValues: FormValues = {
  sharesToSell: 0,
  markUp: 1,
  bullets: 1,
  shareDilution: false,
  maxPerUserShare: 0,
  password: '',
  note: '',
};

const numFormKeys: ReadonlyArray<keyof FormValues> = [
  'sharesToSell',
  'markUp',
  'bullets',
  'maxPerUserShare',
];

type SchemaCreationData = {
  t: TFunction;
  userShareRequired: boolean;
  passwordRequired: boolean;
  sharesMax: number;
  bulletsMax: number;
};

const getSchema = ({
  t,
  userShareRequired,
  passwordRequired,
  sharesMax,
  bulletsMax,
}: SchemaCreationData): ObjectSchema<FormValues> => {
  const maxPerUserShare = userShareRequired
    ? number()
        .positive(t('FORMS__positiveError'))
        .max(sharesMax, t('FORMS__maxAmountError', { amount: sharesMax }))
        .required(t('FORMS__requiredError'))
    : number();

  const password = passwordRequired
    ? string().required(t('FORMS__requiredError'))
    : string();

  return object({
    sharesToSell: number()
      .positive(t('FORMS__positiveError'))
      .max(sharesMax, t('FORMS__maxAmountError', { amount: sharesMax }))
      .required(t('FORMS__requiredError')),
    bullets: number()
      .positive(t('FORMS__positiveError'))
      .max(bulletsMax, t('FORMS__maxAmountError', { amount: bulletsMax }))
      .required(t('FORMS__requiredError')),
    markUp: number()
      .positive(t('FORMS__positiveError'))
      .max(3, t('FORMS__maxAmountError', { amount: 3 }))
      .when('sharesToSell', ([sharesToSell], schema) => {
        return schema.max(100 / sharesToSell, t('FORMS__maxCombinedError'));
      })
      .required(t('FORMS__requiredError')),
    shareDilution: boolean().required(t('FORMS__requiredError')),
    maxPerUserShare,
    password,
    note: string(),
  }).defined();
};

const Form = styled(DefForm)``;

const topOffsetStyles = css`
  margin-top: 16px;
`;

const CheckboxWithInput = styled('div')`
  ${topOffsetStyles};
  .MuiFormControlLabel-root {
    position: relative;
    top: 2px;
  }
`;

const SubmitButton = styled(Button)`
  display: block;
  margin: 30px auto 0;
`;

export { SellPlayerShareForm };
