import { isArray, isEmpty, compact, uniq, isNull } from 'lodash-es';
import { useAsync } from 'react-use';
import axios from 'axios';
import merge from 'deepmerge';
import { AppProps } from 'next/app';
import { firebaseAuth } from '@/utils/firebase';
import { sendSentryError } from '@/utils/sentry';
import {
  ApolloClient,
  ApolloLink,
  split,
  NormalizedCacheObject,
  WatchQueryFetchPolicy,
} from '@apollo/client';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition, Observable } from '@apollo/client/utilities';
import { getCache, removeCacheFromLocalStorage } from './apollo-cache';
import { createRestartableClient } from './backend-ws';
import { server } from './consts';
import { getEnvVariable } from './env-helpers';
import {
  LocalStorageKeys,
  getLocalStorageValue,
  setLocalStorageValue,
  removeLocalStorageValue,
} from './local-storage';
import { resolvers } from './resolvers';
import { typeDefs } from './type-defs';

export type ApolloClientType = ApolloClient<NormalizedCacheObject>;

let apolloClient: ApolloClientType;

const getAuthHeader = () => {
  if (server) return {};
  const auth = getLocalStorageValue(LocalStorageKeys.GRAPHQL_AUTH);
  return auth?.accessToken
    ? {
        Authorization: `Bearer ${auth.accessToken}`,
      }
    : {};
};

const errorLink = onError(({ networkError, graphQLErrors }) => {
  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
  }

  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
          locations
        )}, Path: ${path}`
      );
    });
  }
});

const authLink = setContext((_, { headers: oldHeaders }) => {
  const authHeader = getAuthHeader();
  return {
    headers: {
      ...oldHeaders,
      ...authHeader,
    },
  };
});

/*const getCurrentFirebaseUser = () =>
  new Promise<User | null>((resolve, reject) => {
    if (firebaseAuth.currentUser) {
      resolve(firebaseAuth.currentUser);
      return;
    }
    const unsubscribe = firebaseAuth.onAuthStateChanged((user) => {
      unsubscribe();
      resolve(user);
    }, reject);
  });*/

let firebaseRefreshingTokenPromise: Nullish<
  Promise<{
    type: 'firebase';
    userId: string;
    accessToken: string;
  } | null>
> = null;

const refreshFirebaseToken = () => {
  if (firebaseRefreshingTokenPromise) {
    return firebaseRefreshingTokenPromise;
  }

  firebaseRefreshingTokenPromise = firebaseAuth
    .authStateReady()
    .then(async () => {
      const firebaseUser = firebaseAuth.currentUser;
      if (!firebaseUser) {
        sendSentryError(
          new Error(
            'Debug error about firebase user is null, but firebase auth token is saved'
          )
        );
        throw new Error('No firebase user to refresh token');
      }

      const accessToken = await firebaseUser.getIdToken(true);
      return {
        userId: firebaseUser.uid,
        accessToken,
      };
    })
    .then(
      (result) =>
        ({
          type: 'firebase',
          ...result,
        } as const)
    )
    .catch(() => null)
    .finally(() => {
      firebaseRefreshingTokenPromise = null;
    });

  return firebaseRefreshingTokenPromise;
};

const refreshWalletToken = async () => {
  try {
    const serverAuth = getLocalStorageValue(LocalStorageKeys.GRAPHQL_AUTH);

    if (!serverAuth) {
      throw new Error(
        'No server auth date to call wallet access token refresh'
      );
    }

    const response = await axios.post<{
      errors?: ReadonlyArray<{
        extensions: {
          code: string;
          path: string;
        };
        message: string;
      }>;
      data?: {
        authRefresh: {
          userId: string;
          accesstoken: string;
        };
      };
    }>(
      getEnvVariable('HTTP_API_URL'),
      {
        operationName: 'refreshAccessToken',
        query: `
        mutation refreshAccessToken($accessToken: String) {
          authRefresh(accesstoken: $accessToken) {
            userId
            accesstoken
          }
        }
      `,
        variables: {
          accessToken: serverAuth.accessToken,
        },
      },
      {
        headers: {
          'content-type': 'application/json',
          ...getAuthHeader(),
        },
      }
    );

    const userId = response?.data.data?.authRefresh.userId;
    const accessToken = response?.data.data?.authRefresh.accesstoken;

    if (!isEmpty(response?.data.errors) || !(userId && accessToken)) {
      throw new Error('There is no token on wallet access token refresh');
    }

    return {
      type: 'wallet',
      userId,
      accessToken,
    } as const;
  } catch (e) {
    return null;
  }
};

const refreshToken = () => {
  const serverAuth = getLocalStorageValue(LocalStorageKeys.GRAPHQL_AUTH);

  if (!serverAuth) {
    throw new Error('There is no serverAuth to refresh access token');
  }

  if (serverAuth.type === 'firebase') {
    return refreshFirebaseToken();
  }

  return refreshWalletToken();
};

const refreshTokenLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    const authData = getLocalStorageValue(LocalStorageKeys.GRAPHQL_AUTH);

    if (!authData) return;

    const graphqlErrorCodes = (graphQLErrors?.map(
      ({ extensions }) => extensions?.code
    ) ?? []) as string[];

    const networkErrorCodes =
      networkError && 'result' in networkError && isArray(networkError.result)
        ? networkError.result.reduce((acc, { errors }) => {
            const codes = compact(
              errors.map((error: any) => error?.extensions?.code)
            );
            return [...acc, ...codes];
          }, [])
        : [];

    const networkStatusCode =
      networkError && 'statusCode' in networkError
        ? networkError.statusCode
        : null;

    if (
      ['invalid-jwt', 'validation-failed'].some((authErrorCode) =>
        uniq([...graphqlErrorCodes, ...networkErrorCodes]).some(
          (code) => authErrorCode === code
        )
      ) ||
      networkStatusCode === 401
    ) {
      return new Observable((observer) => {
        refreshToken()
          .then((authData) => {
            if (isNull(authData)) {
              throw new Error('There is no token on refresh');
            }

            setLocalStorageValue(LocalStorageKeys.GRAPHQL_AUTH, authData);

            const subscriber = {
              next: observer.next.bind(observer),
              error: observer.error.bind(observer),
              complete: observer.complete.bind(observer),
            };

            // Retry last failed request
            forward(operation).subscribe(subscriber);
          })
          .catch((error: Error) => {
            console.log('clearing');
            removeLocalStorageValue(LocalStorageKeys.GRAPHQL_AUTH);
            observer.error(error);
          });
      });
    }
  }
);

export const wsClient =
  !server &&
  createRestartableClient({
    url: getEnvVariable('WS_API_URL'),
    connectionParams: () => ({
      headers: getAuthHeader(),
    }),
  });

const wsLink = wsClient && new GraphQLWsLink(wsClient);

const httpLink = new BatchHttpLink({ uri: getEnvVariable('HTTP_API_URL') });

const splitLink = wsLink
  ? split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return (
          definition.kind === 'OperationDefinition' &&
          definition.operation === 'subscription'
        );
      },
      wsLink,
      httpLink
    )
  : httpLink;

// noinspection JSUnusedGlobalSymbols
const apolloConfig = {
  link: ApolloLink.from([errorLink, refreshTokenLink, authLink, splitLink]),
  typeDefs,
  resolvers,
  ssrMode: server,
  defaultOptions: {
    watchQuery: {
      nextFetchPolicy(lastFetchPolicy: WatchQueryFetchPolicy) {
        if (
          lastFetchPolicy === 'cache-and-network' ||
          lastFetchPolicy === 'network-only'
        ) {
          return 'cache-first';
        }

        return lastFetchPolicy;
      },
    },
  },
};

const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__';

let creatingPromise: Maybe<Promise<ApolloClientType>>;

const createClient = () => {
  if (creatingPromise) return creatingPromise;

  creatingPromise = getCache()
    .then((cache) => {
      // @ts-ignore
      const client = new ApolloClient({
        cache,
        ...apolloConfig,
      });
      client.onClearStore(removeCacheFromLocalStorage);
      client.onResetStore(removeCacheFromLocalStorage);
      return client;
    })
    .finally(() => {
      creatingPromise = undefined;
    });

  return creatingPromise;
};

export const initializeApollo = async (initialState = null) => {
  const _apolloClient = apolloClient ?? (await createClient());

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();

    // Merge the initialState from getStaticProps/getServerSideProps in the existing cache
    const data = merge(existingCache, initialState, {
      // Replace completely by new array - probably should be customised by
      // key field to correspond apollo client's cache field policy
      arrayMerge: (_, sourceArray) => sourceArray,
      // combine arrays using object equality (like in sets)
      /*arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter((d) =>
          sourceArray.every((s) => !isEqual(d, s))
        ),
      ],*/
    });

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data);
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient;
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient;
};

export const addApolloState = (
  client: ApolloClientType,
  pageProps: AppProps['pageProps']
) => {
  if (pageProps?.props) {
    pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract();
  }

  return pageProps;
};

export const useApollo = (pageProps: AppProps['pageProps']) => {
  const state = pageProps[APOLLO_STATE_PROP_NAME];
  return useAsync(() => initializeApollo(state), [state]);
};
