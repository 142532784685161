import { useMemo } from 'react';
import { useGetFirebaseNotifyEmailVerify } from '@/hooks/useGetFirebaseNotifyEmailVerify';
import { setLocalStorageValue, LocalStorageKeys } from '@/utils/local-storage';
import { sendSentryError } from '@/utils/sentry';
import { useFirebaseSignInWithEmail } from './useFirebaseSignInWithEmail';
import { useFirebaseSignInWithFacebook } from './useFirebaseSignInWithFacebook';
import { useFirebaseSignInWithGoogle } from './useFirebaseSignInWithGoogle';

type ProvidersHookParams = Parameters<
  ReturnType<typeof useFirebaseSignInWithGoogle>[0]
>;
type EmailHookParams = Parameters<
  ReturnType<typeof useFirebaseSignInWithEmail>[0]
>;

type LoginFunctionConfig = Readonly<{
  google: ProvidersHookParams;
  facebook: ProvidersHookParams;
  email: EmailHookParams;
}>;

type FirebaseLoginType = keyof LoginFunctionConfig;

export const useFirebaseLogin = <T extends FirebaseLoginType>(type: T) => {
  const google = useFirebaseSignInWithGoogle();
  const facebook = useFirebaseSignInWithFacebook();
  const email = useFirebaseSignInWithEmail();

  const notifyEmailVerify = useGetFirebaseNotifyEmailVerify();

  const hookDataByType = useMemo(
    () =>
      ({
        google,
        facebook,
        email,
      } as const),
    [google, facebook, email]
  );

  return useMemo(() => {
    const [loginFn, ...rest] = hookDataByType[type];
    const customLoginFn = async (...data: LoginFunctionConfig[T]) => {
      // @ts-ignore
      const userCredential = await loginFn(...data);
      if (userCredential) {
        try {
          const accessToken = await userCredential.user.getIdToken();
          setLocalStorageValue(LocalStorageKeys.GRAPHQL_AUTH, {
            type: 'firebase',
            userId: userCredential.user.uid,
            accessToken,
          });
          notifyEmailVerify();
        } catch (e) {
          if (e instanceof Error) {
            sendSentryError(e);
          }
        }
      }
      return userCredential;
    };
    return [customLoginFn, ...rest] as const;
  }, [type, hookDataByType, notifyEmailVerify]);
};
