import { useMemo } from 'react';
import {
  useGetShortenedString,
  GetShortenedStringConfig,
} from './useGetShortenedString';

export const useShortenedAddress = (
  token: Maybe<string>,
  config?: GetShortenedStringConfig
) => {
  const getShortenedString = useGetShortenedString(config);
  return useMemo(() => getShortenedString(token), [token, getShortenedString]);
};
