import { useLayoutEffect, useMemo, useState, useEffect } from 'react';
import { pick, omitBy } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useSnackbar } from 'notistack';
import {
  useUserNameQuery,
  useBuyPlayerTicketMutation,
} from '@/apollo/operations';
import { GeneralStatus } from '@/apollo/operations';
import { Button } from '@/components/buttons';
import {
  HorizontalFormControl,
  HorizontalFormControlLabel,
} from '@/components/form-elements';
import { useModals, ModalsData } from '@/providers';
import { resetListStyles } from '@/styles';
import { SiteLinks, externalLinkProps } from '@/utils/consts';
import dayjs from '@/utils/dayjs';
import { getPrice, getFormattedNumber } from '@/utils/helpers';
import { TranslationKeys } from '@/utils/i18n';
import { sendSentryError } from '@/utils/sentry';
import { FormControlLabel, Checkbox, TextField } from '@mui/material';
import { styled, css } from '@mui/material/styles';
import { Text as DefText } from '../texts';

const BuyTournamentTicketConfirmModal = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const router = useRouter();
  const { dispatch, data } = useModals<'buyTournamentTicketConfirm'>();
  const [playerName, setPlayerName] = useState('');
  const [userCheckedBuyingParams, setUserCheckedBuyingParams] = useState(false);
  const [userCheckedNameIsIdenticalToID, setUserCheckedNameIsIdenticalToID] =
    useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);

  const { data: userNameData } = useUserNameQuery();

  useEffect(() => {
    const name = userNameData?.me?.name || userNameData?.me?.playerName;
    if (name) {
      setPlayerName(name);
    }
  }, [userNameData?.me?.name, userNameData?.me?.playerName]);

  const infoItems = useMemo(() => {
    const preparedItems = omitBy(
      pick(data, pickedFields),
      (value) => !value
    ) as Pick<BuyTournamentTicketConfirmModalData, InfoKeys>;

    return Object.entries(preparedItems) as ObjectEntries<typeof preparedItems>;
  }, [data]);

  const [buyPlayerTicket, { loading: buyingPlayerTicket }] =
    useBuyPlayerTicketMutation({
      fetchPolicy: 'no-cache',
      refetchQueries: 'active',
      awaitRefetchQueries: true,
      onCompleted: (data) => {
        if (data.buyTicket?.status === GeneralStatus.Ok) {
          enqueueSnackbar(
            t('BUY_TOURNAMENT_TICKET_CONFIRM_MODAL__buyMutationSuccess'),
            {
              variant: 'success',
            }
          );
          dispatch({
            type: 'closeModal',
          });
          router.push('/player-tickets');
        } else {
          enqueueSnackbar(
            t('BUY_TOURNAMENT_TICKET_CONFIRM_MODAL__buyMutationError') + '. ' + data.buyTicket?.message,
            {
              variant: 'error',
            }
          );
        }
      },
      onError: (e) => {
        sendSentryError(e);
        enqueueSnackbar(
          t('BUY_TOURNAMENT_TICKET_CONFIRM_MODAL__buyMutationError'),
          {
            variant: 'error',
          }
        );
      },
    });

  useLayoutEffect(() => {
    dispatch({
      type: 'setWrapperProps',
      payload: {
        title: t('BUY_TOURNAMENT_TICKET_CONFIRM_MODAL__title'),
      },
    });
  }, [dispatch, t]);

  const buttonDisabled = !(
    data!.tournamentId &&
    userCheckedBuyingParams &&
    userCheckedNameIsIdenticalToID &&
    termsAccepted &&
    playerName
  );

  return (
    <Wrapper>
      <List>
        {infoItems.map(([key, value]) => {
          let renderValue;
          switch (key) {
            case 'startTime':
              renderValue = dayjs.utc(value).format('D MMMM HH:mm');
              break;
            case 'buyIn':
              renderValue = `${getPrice(value)} / ${getFormattedNumber(
                (data?.localCurrencyRate || 0) * value
              )} ${data?.localCurrencySymbol || ''}`;
              break;
            default:
              renderValue = value;
          }
          return (
            <ListItem key={key}>
              <ListItemLabel component={'h3'} variant={'body-large'}>
                {t(labels[key])}:
              </ListItemLabel>
              <ListItemValue variant={'title-medium'} weight={'semi-bold'}>
                {renderValue}
              </ListItemValue>
            </ListItem>
          );
        })}
      </List>
      <HorizontalFormControl
        css={css`
          margin-top: 16px;
        `}
      >
        <HorizontalFormControlLabel
          css={css`
            color: #fff;
          `}
        >
          {t('BUY_TOURNAMENT_TICKET_CONFIRM_MODAL__playerNameInputLabel')}
        </HorizontalFormControlLabel>
        <TextField
          fullWidth
          value={playerName}
          onChange={(e) => {
            setPlayerName(e.target.value);
          }}
        />
      </HorizontalFormControl>
      <FormControlLabel
        css={css`
          margin-top: 16px;
        `}
        control={
          <Checkbox
            checked={userCheckedBuyingParams}
            onChange={(event, checked) => setUserCheckedBuyingParams(checked)}
          />
        }
        label={
          <CheckboxLabel>
            {t('BUY_TOURNAMENT_TICKET_CONFIRM_MODAL__paramsText')}
          </CheckboxLabel>
        }
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={userCheckedNameIsIdenticalToID}
            onChange={(event, checked) =>
              setUserCheckedNameIsIdenticalToID(checked)
            }
          />
        }
        label={
          <CheckboxLabel>
            {t('BUY_TOURNAMENT_TICKET_CONFIRM_MODAL__sameNameAsInID')}
          </CheckboxLabel>
        }
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={termsAccepted}
            onChange={(event, checked) => setTermsAccepted(checked)}
          />
        }
        label={
          <CheckboxLabel>
            {t('BUY_TOURNAMENT_TICKET_CONFIRM_MODAL__termsText')}{' '}
            <CheckboxLabelLink href={SiteLinks.TERMS} {...externalLinkProps}>
              {t('BUY_TOURNAMENT_TICKET_CONFIRM_MODAL__termsLink')}
            </CheckboxLabelLink>
          </CheckboxLabel>
        }
      />
      <Button
        css={css`
          margin-top: 30px;
          align-self: center;
          min-width: 120px;
        `}
        loading={buyingPlayerTicket}
        disabled={buttonDisabled}
        onClick={() => {
          if (buttonDisabled) return;
          buyPlayerTicket({
            variables: {
              tournamentId: data!.tournamentId,
              playerName,
            },
          });
        }}
      >
        {t('BUY_TOURNAMENT_TICKET_CONFIRM_MODAL__submitButton')}
      </Button>
    </Wrapper>
  );
};

type BuyTournamentTicketConfirmModalData =
  ModalsData['buyTournamentTicketConfirm'];

type InfoKeys = Exclude<
  keyof BuyTournamentTicketConfirmModalData,
  | 'tournamentId'
  | 'localCurrencySymbol'
  | 'localCurrencyRate'
  | 'playerNameIsRequired'
>;

const pickedFields: InfoKeys[] = [
  'eventName',
  'tournamentName',
  'startTime',
  'buyIn',
  'bullets',
];

const labels: {
  [key in InfoKeys]: TranslationKeys;
} = {
  eventName: 'BUY_TOURNAMENT_TICKET_CONFIRM_MODAL__eventNameLabel',
  tournamentName: 'BUY_TOURNAMENT_TICKET_CONFIRM_MODAL__tournamentNameLabel',
  startTime: 'BUY_TOURNAMENT_TICKET_CONFIRM_MODAL__startTimeLabel',
  buyIn: 'BUY_TOURNAMENT_TICKET_CONFIRM_MODAL__buyInLabel',
  bullets: 'BUY_TOURNAMENT_TICKET_CONFIRM_MODAL__bulletsLabel',
};

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
`;

const List = styled('ul')`
  ${resetListStyles};
`;

const ListItem = styled('li')`
  display: flex;
`;

const ListItemLabel = styled(DefText)`
  width: 120px;
  margin-right: 16px;
`;

const ListItemValue = styled(DefText)``;

const CheckboxLabel = styled(DefText)``;
const CheckboxLabelLink = styled(Link)`
  color: ${({ theme }) => theme.getColor('primary')};
  font-weight: 700;
  @supports (font-variation-settings: normal) {
    font-variation-settings: 'wght' 700;
  }
`;

export { BuyTournamentTicketConfirmModal };
