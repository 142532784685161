import { useLayoutEffect, useState, ReactNode, ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import { DepositBlock, WithdrawBlock } from '@/components/common-elements';
import { PaymentsTable } from '@/components/tables';
import { useModals } from '@/providers';
import { TranslationKeys } from '@/utils/i18n';
import { Tabs, Tab } from '@mui/material';
import { styled, css } from '@mui/material/styles';

const WithdrawDepositModal = () => {
  const { t } = useTranslation();
  const { dispatch } = useModals();
  const [contentType, setContentType] = useState(ContentTypes.DEPOSIT);

  useLayoutEffect(() => {
    dispatch({
      type: 'setWrapperProps',
      payload: {
        title: t(
          contentType === ContentTypes.HISTORY
            ? 'WITHDRAW_DEPOSIT_MODAL__paymentsTitle'
            : 'WITHDRAW_DEPOSIT_MODAL__paymentTitle'
        ),
        dialogContentStyles,
      },
    });
  }, [dispatch, t, contentType]);

  return (
    <>
      <Tabs
        value={contentType}
        onChange={(event, newValue) => setContentType(newValue)}
        aria-label="withdraw/deposit tabs"
      >
        {labelsByTypeArr.map(([key, { label }]) => (
          <Tab key={key} label={t(label)} {...a11yProps(key)} />
        ))}
      </Tabs>
      {labelsByTypeArr.map(([key, { Component }]) => (
        <TabPanel key={key} value={contentType} index={key}>
          <Component />
        </TabPanel>
      ))}
    </>
  );
};

enum ContentTypes {
  DEPOSIT,
  WITHDRAW,
  HISTORY,
}

type TabPanelProps = {
  children?: ReactNode;
  index: number;
  value: number;
};

function TabPanel({ children, value, index, ...other }: TabPanelProps) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`withdraw-deposit-tabpanel-${index}`}
      aria-labelledby={`withdraw-deposit-tab-${index}`}
      {...other}
    >
      {value === index && <TabPanelContent>{children}</TabPanelContent>}
    </div>
  );
}

function a11yProps(value: ContentTypes) {
  return {
    id: `withdraw-deposit-tab-${value}`,
    'aria-controls': `withdraw-deposit-tabpanel-${value}`,
  };
}

const labelsByTypeMap: ReadonlyMap<
  ContentTypes,
  {
    label: TranslationKeys;
    Component: ComponentType;
  }
> = new Map([
  [
    ContentTypes.DEPOSIT,
    {
      label: 'WITHDRAW_DEPOSIT_MODAL__depositTabLabel',
      Component: DepositBlock,
    },
  ],
  [
    ContentTypes.WITHDRAW,
    {
      label: 'WITHDRAW_DEPOSIT_MODAL__withdrawTabLabel',
      Component: WithdrawBlock,
    },
  ],
  [
    ContentTypes.HISTORY,
    {
      label: 'WITHDRAW_DEPOSIT_MODAL__historyTabLabel',
      Component: PaymentsTable,
    },
  ],
]);

const labelsByTypeArr = Array.from(labelsByTypeMap);

const dialogContentStyles = css`
  padding-top: 0;
`;

const TabPanelContent = styled('div')`
  padding-top: 32px;
`;

export { WithdrawDepositModal };
