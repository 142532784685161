import { SvgIcon, SvgIconProps } from '@mui/material';

export type FacebookColorIconProps = SvgIconProps;

const FacebookColorIcon = (props: FacebookColorIconProps) => (
  <SvgIcon {...props}>
    <linearGradient id="a" x1="50%" x2="50%" y1="0%" y2="99.703%">
      <stop offset={0} stopColor="#18acfe" />
      <stop offset={1} stopColor="#0163e0" />
    </linearGradient>
    <g fill="none">
      <circle cx={12} cy={12} r={12} fill="url(#a)" />
      <path
        fill="#fff"
        d="m15.91 15.211.467-2.963h-2.918v-1.923c0-.81.407-1.602 1.714-1.602H16.5V6.2S15.296 6 14.145 6c-2.404 0-3.974 1.42-3.974 3.989v2.259H7.5v2.963h2.671v7.165a10.85 10.85 0 0 0 3.288 0V15.21z"
      />
    </g>
  </SvgIcon>
);

export { FacebookColorIcon };
