import { useMemo, ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Form as DefForm } from 'formik';
import { TFunction } from 'i18next';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';
import {
  GeneralStatus,
  useSetWinningPlayerMutation,
  SetWinningPlayerMutationResult,
  UserVenueTournamentPlayerShareDocument,
} from '@/apollo/operations';
import { Button } from '@/components/buttons';
import {
  HorizontalFormControl,
  HorizontalFormControlLabel,
} from '@/components/form-elements';
import { FormikTextField } from '@/components/formik-elements';
import { TranslationKeys } from '@/utils/i18n';
import { sendSentryError } from '@/utils/sentry';
import { styled } from '@mui/material/styles';

export type SetWinningPlayerFormProps = {
  playerShareId: string;
  disabled?: boolean;
  onSuccessSubmit: (
    result: NonNullable<
      NonNullable<SetWinningPlayerMutationResult['data']>['playerShareResult']
    >
  ) => void;
} & ComponentProps<typeof Form>;

const SetWinningPlayerForm = ({
  playerShareId,
  disabled,
  onSuccessSubmit,
  ...props
}: SetWinningPlayerFormProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const schema = useMemo(() => getSchema({ t }), [t]);

  const showError = (e: Error, msg?: string) => {
    sendSentryError(e);
    enqueueSnackbar(msg || t('SET_WINNING_PLAYER_FORM__mutationError'), {
      variant: 'error',
    });
  };

  const [setWinningPlayer] = useSetWinningPlayerMutation({
    fetchPolicy: 'no-cache',
    refetchQueries: [
      {
        query: UserVenueTournamentPlayerShareDocument,
        variables: {
          id: playerShareId,
        },
      },
    ],
    awaitRefetchQueries: true,
    onCompleted: (data) => {
      if (data.playerShareResult?.status === GeneralStatus.Ok) {
        enqueueSnackbar(t('SET_WINNING_PLAYER_FORM__mutationSuccess'), {
          variant: 'success',
        });
        onSuccessSubmit(data.playerShareResult);
      } else {
        showError(
          new Error(
            `SetWinningPlayer mutation status not okn; msg: ${data.playerShareResult?.message}`
          ),
          data.playerShareResult?.message
        );
      }
    },
    onError: (e) => {
      showError(e);
    },
  });

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={({ place, winAmount }) =>
        setWinningPlayer({
          variables: {
            playerShareId,
            place,
            winAmount,
          },
        })
      }
    >
      {({ isSubmitting }) => (
        <Form {...props}>
          {inputs.map(([key, label]) => (
            <HorizontalFormControl key={key}>
              <HorizontalFormControlLabel>
                {t(label)}
              </HorizontalFormControlLabel>
              <Input name={key} type={'number'} disabled={disabled} />
            </HorizontalFormControl>
          ))}
          <SubmitButton
            type={'submit'}
            loading={isSubmitting}
            disabled={disabled}
          >
            {t('SET_WINNING_PLAYER_MODAL__submitButton')}
          </SubmitButton>
        </Form>
      )}
    </Formik>
  );
};

const inputsMap: ReadonlyMap<keyof FormValues, TranslationKeys> = new Map([
  ['place', 'SET_WINNING_PLAYER_FORM__placeInputLabel'],
  ['winAmount', 'SET_WINNING_PLAYER_FORM__winAmountInputLabel'],
]);

const inputs = Array.from(inputsMap);

type FormValues = {
  place: number;
  winAmount: number;
};

const initialValues: FormValues = {
  place: 0,
  winAmount: 0,
};

const getSchema = ({ t }: { t: TFunction }): yup.ObjectSchema<FormValues> =>
  yup
    .object({
      place: yup.number().required(t('FORMS__requiredError')),
      winAmount: yup
        .number()
        .positive(t('FORMS__positiveError'))
        .required(t('FORMS__requiredError')),
    })
    .defined();

const Form = styled(DefForm)`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Input = styled(FormikTextField)`
  flex: 1 1 auto;
`;

const SubmitButton = styled(Button)`
  margin-top: 32px;
`;

export { SetWinningPlayerForm };
