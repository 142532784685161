import { isNil } from 'lodash-es';

const envVariables = {
  TEST_BLOCKCHAINS_INCLUDED: process.env.NEXT_PUBLIC_TEST_BLOCKCHAINS_INCLUDED,
  WALLET_CONNECT_PROJECT_ID: process.env.NEXT_PUBLIC_WALLET_CONNECT_PROJECT_ID,
  API_DOMAIN: process.env.NEXT_PUBLIC_API_DOMAIN,
  FRONTEND_DOMAIN: process.env.NEXT_PUBLIC_FRONTEND_DOMAIN,
  HTTP_API_URL: process.env.NEXT_PUBLIC_HTTP_API_URL,
  WS_API_URL: process.env.NEXT_PUBLIC_WS_API_URL,
  BSCSCAN_URL: process.env.NEXT_PUBLIC_BSCSCAN_URL,
  SENTRY_DSN: process.env.NEXT_PUBLIC_SENTRY_DSN,
  GOOGLE_TAG_MANAGER_ID: process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID,
};

type EnvVariableName = keyof typeof envVariables;

export const getEnvVariable = (name: EnvVariableName, required = true) => {
  const value = envVariables[name];
  if (isNil(value) && required) {
    throw new Error(`${name} variable should be set in .env file`);
  }
  return value!;
};
