import { useLayoutEffect, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAsyncFn } from 'react-use';
import download from 'downloadjs';
import { useSnackbar } from 'notistack';
import {
  GeneralStatus,
  DownloadEventTransactionsDocument,
  DownloadEventTransactionsQuery,
  DownloadEventTransactionsQueryVariables,
} from '@/apollo/operations';
import { Button } from '@/components/buttons';
import { UserVenueFinancialOverviewTable } from '@/components/tables';
import { useModals } from '@/providers';
import { sendSentryError } from '@/utils/sentry';
import { useApolloClient } from '@apollo/client';
import { css } from '@mui/material/styles';
import { Text } from '../texts';

const UserVenueEventFinancialOverviewModal = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const client = useApolloClient();
  const { dispatch, data } = useModals<'userVenueEventFinancialOverview'>();

  useLayoutEffect(() => {
    dispatch({
      type: 'setWrapperProps',
      payload: {
        title: t('USER_VENUE_EVENT_FINANCIAL_OVERVIEW_MODAL__title'),
        fullWidth: true,
        maxWidth: 'lg',
        dialogContentStyles: css`
          padding-top: 0;
        `,
      },
    });
  }, [dispatch, t]);

  useEffect(() => {
    if (!data?.eventId) {
      sendSentryError(
        new Error(`UserVenueEventFinancialOverviewModal doesn't have event id`)
      );
    }
  }, [data?.eventId]);

  const [{ loading, error }, downloadTransactions] = useAsyncFn(
    async (eventId: string) => {
      const response = await client.query<
        DownloadEventTransactionsQuery,
        DownloadEventTransactionsQueryVariables
      >({
        query: DownloadEventTransactionsDocument,
        fetchPolicy: 'no-cache',
        variables: {
          eventId,
        },
      });

      if (response.error) {
        throw response.error;
      }

      const status = response.data.downloadEventTransactions?.status;

      if (status !== GeneralStatus.Ok) {
        throw new Error(`DownloadEventTransactionsQuery response not ok`);
      }

      const data = response.data.downloadEventTransactions?.base64;
      const filename = response.data.downloadEventTransactions?.filename;

      if (!data || !filename) {
        throw new Error(
          'Base64 string is empty in DownloadEventTransactionsQuery'
        );
      }
      download(data, filename, xslxMimeType);
    },
    [client]
  );

  useEffect(() => {
    if (error) {
      sendSentryError(error);
      enqueueSnackbar(
        t('USER_VENUE_EVENT_FINANCIAL_OVERVIEW_MODAL__downloadError'),
        {
          variant: 'error',
        }
      );
    }
  }, [t, enqueueSnackbar, error]);

  if (!data?.eventId) {
    return <Text>Error</Text>;
  }

  return (
    <>
      <UserVenueFinancialOverviewTable eventId={data.eventId} />
      <Button
        css={css`
          align-self: flex-end;
          margin-top: 8px;
        `}
        loading={loading}
        onClick={() => downloadTransactions(data.eventId)}
      >
        {t(
          'USER_VENUE_EVENT_FINANCIAL_OVERVIEW_MODAL__downloadTransactionsButton'
        )}
      </Button>
    </>
  );
};

const xslxMimeType =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

export { UserVenueEventFinancialOverviewModal };
