import { useMemo, ComponentProps } from 'react';
import { isEmpty, toString, toNumber } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { Formik, Form as DefForm } from 'formik';
import { TFunction } from 'i18next';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';
import {
  StatusGamesEnum,
  useCurrenciesQuery,
  useCreateTournamentMutation,
  useEditTournamentMutation,
  EditTournamentDataQuery,
  CreateTournamentMutationVariables,
} from '@/apollo/operations';
import { Button, GetImageButton } from '@/components/buttons';
import {
  HorizontalFormControl,
  HorizontalFormControlLabel,
} from '@/components/form-elements';
import {
  FormikTextField,
  FormikDateTimeField,
} from '@/components/formik-elements';
import dayjs from '@/utils/dayjs';
import { TranslationKeys } from '@/utils/i18n';
import { sendSentryError } from '@/utils/sentry';
import { MenuItem } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Text } from '../texts';

export type UserVenueAddEditTournamentFormProps = {
  venueId: Maybe<string>;
  eventId: Maybe<string>;
  tournament?: EditTournamentDataQuery['tournament'];
  onSuccessSubmit?: () => void;
} & ComponentProps<typeof Form>;

const UserVenueAddEditTournamentForm = ({
  venueId,
  eventId,
  tournament,
  onSuccessSubmit,
  ...props
}: UserVenueAddEditTournamentFormProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const disabledFields = useMemo<ReadonlyArray<FormValuesKeys>>(() => {
    if (!tournament) return [];
    switch (tournament?.status) {
      case StatusGamesEnum.Inactive:
        return [];
      case StatusGamesEnum.Active:
        return ['buyIn', 'localCurrencyId', 'currencyRate'] as const;
      case StatusGamesEnum.Started:
        return Object.keys(defInitialValues).filter(
          (val) => val !== 'watchUrl'
        ) as ReadonlyArray<FormValuesKeys>;
      default:
        return Object.keys(defInitialValues) as ReadonlyArray<FormValuesKeys>;
    }
  }, [tournament]);

  const { data: currenciesResponse } = useCurrenciesQuery({
    fetchPolicy: 'cache-and-network',
  });

  const currenciesOptions = useMemo(
    () =>
      currenciesResponse?.currencies.map(({ id, name, rate }) => ({
        label: name,
        value: toString(id),
        rate: rate
      })) ?? [],
    [currenciesResponse]
  );

  const schema = useMemo(
    () => getSchema({ t, creation: !tournament?.id }),
    [t, tournament]
  );

  const initialValues = useMemo(
    () => {
      return Object.entries(defInitialValues).reduce(
        (acc, [key, value]) => {
          return ({
            ...
              acc,
            [key]:
              (key == 'buyIn' || key == 'guaranteed'?
                Math.round((tournament?.[key as keyof FormValues] ?? value) * (tournament?.currencyRate ?? 1)) :
                tournament?.[key as keyof FormValues] ?? value),
          })
        },
        {}
      ) as FormValues
    },
    [tournament]
  );
  console.log('initialValues',initialValues);

  const [createTournament] = useCreateTournamentMutation({
    refetchQueries: 'active',
    awaitRefetchQueries: true,
    onCompleted: () => {
      enqueueSnackbar(
        t('USER_VENUE_ADD_EDIT_EVENT_FORM__createMutationSuccess'),
        {
          variant: 'success',
        }
      );
      onSuccessSubmit?.();
    },
    onError: (e) => {
      sendSentryError(e);
      enqueueSnackbar(
        t('USER_VENUE_ADD_EDIT_EVENT_FORM__createMutationError'),
        {
          variant: 'error',
        }
      );
    },
  });

  const [editTournament] = useEditTournamentMutation({
    refetchQueries: 'active',
    awaitRefetchQueries: true,
    onCompleted: () => {
      enqueueSnackbar(
        t('USER_VENUE_ADD_EDIT_EVENT_FORM__editMutationSuccess'),
        {
          variant: 'success',
        }
      );
      onSuccessSubmit?.();
    },
    onError: (e) => {
      sendSentryError(e);
      enqueueSnackbar(t('USER_VENUE_ADD_EDIT_EVENT_FORM__editMutationError'), {
        variant: 'error',
      });
    },
  });

  if (!venueId) {
    return <Text>Error</Text>;
  }

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={(values) => {
        if ((!tournament?.id) || tournament?.status == StatusGamesEnum.Inactive) {
          if(values.buyIn) {
            const localCurrencyId = (!tournament?.id) ? toNumber(values.localCurrencyId) : tournament.localCurrencyId;
            const currency = currenciesOptions.find((option) => (toNumber(option.value) == localCurrencyId));
            const rate = currency?.rate ?? 1;
            const buyIn = Math.round(values.buyIn / rate);
            values.currencyRate = values.buyIn / buyIn;
            values.guaranteed = Math.round((values.guaranteed ?? 0) / values.currencyRate);
            values.buyIn = buyIn;
          } else {
            return;
          }
        } else {
          values.currencyRate = tournament.currencyRate;
          values.buyIn = tournament.buyIn;
          values.guaranteed = Math.round((values.guaranteed ?? 0) / values.currencyRate);
        }

        console.log('values',values);

        if (!tournament?.id) {
          const localCurrencyId = toNumber(values.localCurrencyId);

          return createTournament({
            variables: {
              data: {
                venueId,
                eventId,
                ...values,
                localCurrencyId,
              },
            },
          });
        }

        const newValues = Object.entries(values)
          .map(([key, value]) =>
            key === 'localCurrencyId' ? [key, toNumber(value)] : [key, value]
          )
          .reduce(
            (acc, [key, value]) =>
              initialValues[key as keyof FormValues] === value
                ? acc
                : {
                    ...acc,
                    [key]: value,
                  },
            {}
          );

        if (isEmpty(newValues)) {
          enqueueSnackbar(
            t('USER_VENUE_ADD_EDIT_TOURNAMENT_MODAL__noChangesMessage'),
            {
              variant: 'info',
            }
          );
          return Promise.resolve();
        }

        if (tournament?.id) {
          return editTournament({
            variables: {
              id: tournament.id,
              data: newValues,
            },
          });
        }
      }}
    >
      {({ touched, errors, isSubmitting, setFieldValue, values }) => (
        <Form {...props}>
          {inputs.map(([key, { label, Component = Input, ...props }]) => (
            <HorizontalFormControl key={key}>
              <HorizontalFormControlLabel>
                {t(label)}
              </HorizontalFormControlLabel>
              {(() => {
                if (key === 'image') {
                  return (
                    <AddButton
                      error={touched.image && !!errors.image}
                      disabled={
                        disabledFields.includes('image') || isSubmitting
                      }
                      onImagePick={(img) => {
                        setFieldValue('image', img);
                      }}
                    >
                      {t(label)}
                    </AddButton>
                  );
                }

                if (key === 'localCurrencyId') {
                  return (
                    <Input
                      name={key}
                      select
                      disabled={
                        disabledFields.includes('localCurrencyId') ||
                        isSubmitting
                      }
                    >
                      {currenciesOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Input>
                  );
                }

                return (
                  <Component
                    name={key}
                    disabled={disabledFields.includes(key) || isSubmitting}
                    {...props}
                  />
                );
              })()}
            </HorizontalFormControl>
          ))}
          <SubmitButton type={'submit'} loading={isSubmitting}>
            {t(
              tournament?.id
                ? 'USER_VENUE_ADD_EDIT_EVENT_FORM__editButton'
                : 'USER_VENUE_ADD_EDIT_EVENT_FORM__createButton'
            )}
          </SubmitButton>
        </Form>
      )}
    </Formik>
  );
};

const Form = styled(DefForm)`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const AddButton = styled(GetImageButton)`
  flex: 1 1 auto;
`;

const Input = styled(FormikTextField)`
  flex: 1 1 auto;
`;

const DateInput = styled(FormikDateTimeField)`
  flex: 1 1 auto;
`;

const SubmitButton = styled(Button)`
  margin-top: 32px;
  align-self: center;
  width: 100%;
  max-width: 310px;
`;

type FormValues = Pick<
  CreateTournamentMutationVariables['data'],
  | 'name'
  | 'date'
  | 'registrationEnd'
  | 'buyIn'
  | 'type'
  | 'guaranteed'
  | 'watchUrl'
  | 'image'
  | 'bulletsMax'
  | 'sharesMax'
  | 'localCurrencyId'
  | 'currencyRate'
>;

type FormValuesKeys = keyof FormValues;

const defInitialValues: FormValues = {
  name: '',
  date: '',
  registrationEnd: '',
  buyIn: null,
  type: 'No-Limit Hold\'em',
  guaranteed: 0,
  watchUrl: '',
  image: '',
  bulletsMax: 3,
  sharesMax: 90,
  localCurrencyId: 2,
  currencyRate: 1,
};

const getSchema = ({
  t,
  creation,
}: {
  t: TFunction;
  creation: boolean;
}): yup.ObjectSchema<FormValues> => {
  let name = yup.string();
  let date = yup
    .string()
    .test('date-validation', t('FORMS__invalidDate'), (value) =>
      dayjs(value).isValid()
    );
  let registrationEnd = yup
    .string()
    .test('date-validation', t('FORMS__invalidDate'), (value) =>
      dayjs(value).isValid()
    )
    .test(
      'after-date',
      t(
        'USER_VENUE_ADD_EDIT_TOURNAMENT_MODAL__registrationEndShouldBeBeforeDateError'
      ),
      (value, context) => {
        return (
          dayjs(context.parent.date).isValid() &&
          dayjs(value).isAfter(dayjs(context.parent.date))
        );
      }
    );
  let buyIn = yup.number().positive(t('FORMS__positiveError'));
  let type = yup.string();
  let guaranteed = yup.number();
  const watchUrl = yup.string();
  const image = yup.string();
  const bulletsMax = yup.number();
  const sharesMax = yup.number();
  const currencyRate = yup.number();
  // let bulletsMax = yup
  //   .number()
  //   .integer(t('FORMS__integerError'))
  //   .positive(t('FORMS__positiveError'));
  // let sharesMax = yup
  //   .number()
  //   .integer(t('FORMS__integerError'))
  //   .positive(t('FORMS__positiveError'));
  let localCurrencyId = yup
    .number()
    .integer(t('FORMS__integerError'))
    .positive(t('FORMS__positiveError'));
  // let currencyRate = yup.number().positive(t('FORMS__positiveError'));

  if (creation) {
    name = name.required(t('FORMS__requiredError'));
    date = date.required(t('FORMS__requiredError'));
    registrationEnd = registrationEnd.required(t('FORMS__requiredError'));
    buyIn = buyIn.required(t('FORMS__requiredError'));
    type = type.required(t('FORMS__requiredError'));
    // guaranteed = guaranteed.required(t('FORMS__requiredError'));
    // image = image.required(t('FORMS__requiredError'));
    // bulletsMax = bulletsMax.required(t('FORMS__requiredError'));
    // sharesMax = sharesMax.required(t('FORMS__requiredError'));
    localCurrencyId = localCurrencyId.required(t('FORMS__requiredError'));
    // currencyRate = currencyRate.required(t('FORMS__requiredError'));
  }

  return yup
    .object({
      name,
      date,
      registrationEnd,
      buyIn,
      type,
      guaranteed,
      watchUrl,
      image,
      bulletsMax,
      sharesMax,
      localCurrencyId,
      currencyRate,
    })
    .defined();
};

const inputsMap: ReadonlyMap<
  keyof FormValues,
  {
    label: TranslationKeys;
  } & (
    | {
        Component?: typeof Input;
        props?: Omit<ComponentProps<typeof Input>, 'name'>;
      }
    | {
        Component: typeof DateInput;
        props?: Omit<ComponentProps<typeof DateInput>, 'name'>;
      }
  )
> = new Map([
  [
    'name',
    {
      label: 'USER_VENUE_ADD_EDIT_TOURNAMENT_MODAL__nameInputLabel',
    },
  ],
  [
    'date',
    {
      label: 'USER_VENUE_ADD_EDIT_TOURNAMENT_MODAL__dateInputLabel',
      Component: DateInput,
      disablePast: true,
    },
  ],
  [
    'registrationEnd',
    {
      label: 'USER_VENUE_ADD_EDIT_TOURNAMENT_MODAL__registrationEndInputLabel',
      Component: DateInput,
      disablePast: true,
    },
  ],
  [
    'type',
    {
      label: 'USER_VENUE_ADD_EDIT_TOURNAMENT_MODAL__typeInputLabel',
    },
  ],
  [
    'localCurrencyId',
    {
      label: 'USER_VENUE_ADD_EDIT_TOURNAMENT_MODAL__localCurrencyIdInputLabel',
      type: 'number',
    },
  ],
  [
    'buyIn',
    {
      label: 'USER_VENUE_ADD_EDIT_TOURNAMENT_MODAL__buyInInputLabel',
      type: 'number',
      inputProps: {
        min: 0,
        step: '0.01',
      },
    },
  ],
  [
    'guaranteed',
    {
      label: 'USER_VENUE_ADD_EDIT_TOURNAMENT_MODAL__guaranteedInputLabel',
      type: 'number',
      inputProps: {
        min: 0,
        step: '0.01',
      },
    },
  ],
  // [
  //   'currencyRate',
  //   {
  //     label: 'USER_VENUE_ADD_EDIT_TOURNAMENT_MODAL__currencyRateInputLabel',
  //     type: 'number',
  //     inputProps: {
  //       min: 0,
  //       step: '0.01',
  //     },
  //   },
  // ],
  [
    'watchUrl',
    {
      label: 'USER_VENUE_ADD_EDIT_TOURNAMENT_MODAL__watchUrlInputLabel',
    },
  ],
  // [
  //   'image',
  //   {
  //     label: 'USER_VENUE_ADD_EDIT_TOURNAMENT_MODAL__imageInputLabel',
  //   },
  // ],
  // [
  //   'bulletsMax',
  //   {
  //     label: 'USER_VENUE_ADD_EDIT_TOURNAMENT_MODAL__bulletsMaxInputLabel',
  //     type: 'number',
  //     inputProps: {
  //       min: 0,
  //     },
  //   },
  // ],
  // [
  //   'sharesMax',
  //   {
  //     label: 'USER_VENUE_ADD_EDIT_TOURNAMENT_MODAL__sharesMaxInputLabel',
  //     type: 'number',
  //     inputProps: {
  //       min: 0,
  //     },
  //   },
  // ],
]);

const inputs = Array.from(inputsMap);

export { UserVenueAddEditTournamentForm };
