import { ComponentProps, ReactNode } from 'react';
import { isNumber, toNumber } from 'lodash-es';
import { Text as DefText } from '@/components/texts';
import {
  Slider as DefSlider,
  TextField,
  SliderProps as DefSliderProps,
  TextFieldProps as DefTextFieldProps,
} from '@mui/material';
import { styled } from '@mui/material/styles';

export type SliderProps = {
  value: number;
  min?: number;
  max?: number;
  step?: number;
  sliderProps: Omit<
    DefSliderProps,
    'value' | 'min' | 'max' | 'step' | 'onChange'
  >;
  textFieldProps?: Omit<DefTextFieldProps, 'value' | 'onChange'>;
  label?: ReactNode;
  errorText?: ReactNode;
  onChange: (val: number | string) => void;
} & ComponentProps<typeof Wrapper>;

const Slider = ({
  label,
  value,
  min,
  max,
  step,
  sliderProps,
  textFieldProps,
  errorText,
  onChange,
  ...props
}: SliderProps) => (
  <Wrapper {...props}>
    {!!label && (
      <Label component={'h3'} variant={'headline-small'} weight={'semi-bold'}>
        {label}
      </Label>
    )}
    <StyledSlider
      value={
        !isNumber(value) && !isNaN(toNumber(value)) ? toNumber(value) : value
      }
      min={min}
      max={max}
      step={step}
      onChange={(event, value) => {
        isNumber(value) && onChange(value);
      }}
      {...sliderProps}
    />
    <Input
      type={'number'}
      value={value}
      onChange={(event) => {
        onChange(event.target.value);
      }}
      {...textFieldProps}
      inputProps={{
        min,
        max,
        step,
        ...textFieldProps?.inputProps,
      }}
    >
      {value}
    </Input>
    {!!errorText && <ErrorText>{errorText}</ErrorText>}
  </Wrapper>
);

const Wrapper = styled('div')`
  display: flex;
  flex-wrap: wrap;
  padding-left: 20px;
`;

const Label = styled(DefText)`
  flex: 0 0 100%;
  margin-bottom: 16px;
  text-align: center;
`;

const StyledSlider = styled(DefSlider)`
  flex: 1 1 50%;
  margin-bottom: 0;
`;

const Input = styled(TextField)`
  width: 88px;
  margin-top: 23px;
  margin-left: 40px;
`;

const ErrorText = styled(DefText)`
  flex: 0 0 100%;
  text-align: center;
  color: ${({ theme }) => theme.getColor('error')};
`;

export { Slider };
