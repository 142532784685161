import { useCallback, useState } from 'react';
import { confirmPasswordReset, AuthError } from 'firebase/auth';
import { firebaseAuth } from '@/utils/firebase';

export type SendPasswordResetEmailHook = [
  (code: string, newPassword: string) => Promise<boolean>,
  boolean,
  AuthError | Error | undefined
];

export const useFirebaseResetPasswordConfirm =
  (): SendPasswordResetEmailHook => {
    const [error, setError] = useState<AuthError>();
    const [loading, setLoading] = useState<boolean>(false);

    const sendPasswordResetEmail = useCallback(
      async (code: string, newPassword: string) => {
        setLoading(true);
        setError(undefined);
        try {
          await confirmPasswordReset(firebaseAuth, code, newPassword);
          return true;
        } catch (err) {
          setError(err as AuthError);
          return false;
        } finally {
          setLoading(false);
        }
      },
      []
    );

    return [sendPasswordResetEmail, loading, error];
  };
