import {
  SearchPopover,
  TournamentsSearch,
  SearchPopoverProps,
} from '@/components/search';

export type TournamentSearchPopoverProps = SearchPopoverProps;

const TournamentSearchPopover = (props: TournamentSearchPopoverProps) => (
  <SearchPopover id="tournament-search-menu" {...props}>
    <TournamentsSearch />
  </SearchPopover>
);

export { TournamentSearchPopover };
