import { useLayoutEffect, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCopyToClipboard } from 'react-use';
import Image from 'next/image';
import { useSnackbar } from 'notistack';
import {
  useGetEnable2FaDataMutation,
  useEnable2FaMutation,
  GeneralStatus,
  UserIdQuery,
  UserIdQueryVariables,
  UserIdDocument,
} from '@/apollo/operations';
import { Button } from '@/components/buttons';
import { PageSpinner } from '@/components/common-elements';
import { CopyIcon as DefCopyIcon } from '@/components/icons';
import { useModals } from '@/providers';
import { externalLinkProps } from '@/utils/consts';
import { sendSentryError } from '@/utils/sentry';
import {
  Link as DefLink,
  FormControl as DefFormControl,
  FormLabel as DefFormLabel,
  TextField,
  IconButton as DefIconButton,
} from '@mui/material';
import { styled, css } from '@mui/material/styles';
import { Text } from '../texts';

const Enable2FAModal = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { dispatch } = useModals();
  const [backupCodesVisible, setBackupCodesVisible] = useState(false);
  const [codeValue, setCodeValue] = useState('');

  const [_, copyToClipBoard] = useCopyToClipboard();

  const [
    getEnable2FAData,
    {
      loading: gettingEnable2FAData,
      error: gettingEnable2FADataError,
      data: enable2FAData,
    },
  ] = useGetEnable2FaDataMutation({
    fetchPolicy: 'no-cache',
  });

  const showError = (e: Error) => {
    sendSentryError(e);
    enqueueSnackbar(t('ENABLE_2FA_MODAL__mutationError'), {
      variant: 'error',
    });
  };

  const [enable2FA, { loading: enabling2FA }] = useEnable2FaMutation({
    fetchPolicy: 'no-cache',
    update: (cache, result) => {
      if (result.data?.activate2FA2?.status === GeneralStatus.Ok) {
        const userIdResponse = cache.readQuery<
          UserIdQuery,
          UserIdQueryVariables
        >({
          query: UserIdDocument,
        });
        if (!userIdResponse?.me) return;
        cache.modify({
          id: cache.identify(userIdResponse.me),
          fields: {
            twofa: () => true,
          },
        });
      }
    },
    onCompleted: (data) => {
      if (data.activate2FA2?.status === GeneralStatus.Ok) {
        enqueueSnackbar(t('ENABLE_2FA_MODAL__mutationSuccess'), {
          variant: 'success',
        });
        setBackupCodesVisible(true);
      } else {
        showError(
          new Error(
            `Enable2FaMutation status not ok; msg: ${data.activate2FA2?.message}`
          )
        );
      }
    },
    onError: (e) => {
      showError(e);
    },
  });

  useLayoutEffect(() => {
    dispatch({
      type: 'setWrapperProps',
      payload: {
        title: t('ENABLE_2FA_MODAL__title'),
        dialogContentStyles: css`
          padding-top: 0;
        `,
      },
    });
  }, [t, dispatch]);

  // Timer's to prevent double mutation call
  useEffect(() => {
    const t = setTimeout(() => getEnable2FAData(), 200);
    return () => {
      clearTimeout(t);
    };
  }, [getEnable2FAData]);

  if (gettingEnable2FAData) {
    return <PageSpinner />;
  }

  const info = enable2FAData?.activate2FA1;

  if (gettingEnable2FADataError || !info) {
    return <Text>{t('ENABLE_2FA_MODAL__loadDataError')}</Text>;
  }

  return backupCodesVisible && enable2FAData?.activate2FA1?.backupCode ? (
    <>
      <Text
        style={{
          marginTop: 20,
          marginBottom: 20,
        }}
        align={'center'}
        color={'spunPearl'}
      >
        {t('ENABLE_2FA_MODAL__codesTitle')}
      </Text>
      <Text
        style={{
          whiteSpace: 'pre',
        }}
        align={'center'}
      >
        {enable2FAData.activate2FA1.backupCode}
      </Text>
    </>
  ) : (
    <>
      <Text
        style={{
          marginTop: 20,
        }}
        align={'center'}
        color={'spunPearl'}
      >
        {t('ENABLE_2FA_MODAL__imageTitle')}
      </Text>
      <CodeImage
        width={147}
        height={143}
        src={info.codeImage}
        alt={'QR Code'}
      />
      <Text color={'spunPearl'} align={'center'}>
        <Link href={info.codeUrl} {...externalLinkProps}>
          {t('ENABLE_2FA_MODAL__readCodeLink')}
        </Link>{' '}
        {t('ENABLE_2FA_MODAL__copyPasteCode')}
        <IconButton
          onClick={() => {
            copyToClipBoard(info.codeUrl);
            enqueueSnackbar(t('ENABLE_2FA_MODAL__copiedToClipboard'), {
              variant: 'info',
            });
          }}
        >
          <CopyIcon />
        </IconButton>
      </Text>
      <FormControl>
        <FormLabel>{t('ENABLE_2FA_MODAL__inputLabel')}</FormLabel>
        <Input
          value={codeValue}
          onChange={(event) => setCodeValue(event.target.value)}
        />
      </FormControl>
      <SubmitButton
        disabled={!codeValue}
        loading={enabling2FA}
        onClick={() => {
          enable2FA({
            variables: {
              code: codeValue,
            },
          });
        }}
      >
        {t('ENABLE_2FA_MODAL__submitButton')}
      </SubmitButton>
    </>
  );
};

const CodeImage = styled(Image)`
  width: 147px;
  height: 143px;
  align-self: center;
  margin: 20px 0 30px;
`;

const FormControl = styled(DefFormControl)`
  align-self: center;
  flex-direction: row;
  margin-top: 16px;
`;

const FormLabel = styled(DefFormLabel)`
  margin-right: 16px;
  margin-top: 14px;
`;

const Input = styled(TextField)`
  width: 165px;
`;

const Link = styled(DefLink)`
  align-self: center;
  color: ${({ theme }) => theme.getColor('spunPearl')};
  text-decoration-color: ${({ theme }) => theme.getColor('spunPearl')};
`;

const IconButton = styled(DefIconButton)``;

const CopyIcon = styled(DefCopyIcon)`
  color: ${({ theme }) => theme.getColor('spunPearl')};
`;

const SubmitButton = styled(Button)`
  min-width: 310px;
  margin-top: 20px;
  align-self: center;
`;

export { Enable2FAModal };
