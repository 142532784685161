import Image from 'next/image';
import { supportedLanguages, getLangData } from '@/utils/i18n';
import { MenuItem as DefMenuItem } from '@mui/material';
import { styled } from '@mui/material/styles';

export type LanguagesMenuItemsProps = {
  onLanguageClick: (language: string) => void;
};

const LanguagesMenuItems = ({ onLanguageClick }: LanguagesMenuItemsProps) =>
  supportedLanguages.map((language) => {
    const { icon, switcherFullName } = getLangData(language);
    return (
      <MenuItem key={language} onClick={() => onLanguageClick(language)}>
        <Icon width={32} height={24} src={icon} alt={`${language}`} />{' '}
        {switcherFullName}
      </MenuItem>
    );
  });

const MenuItem = styled(DefMenuItem)`
  display: flex;
  & + & {
    margin-top: 10px;
  }
`;

const Icon = styled(Image)`
  margin-right: 5px;
`;

export { LanguagesMenuItems };
