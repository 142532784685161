import { ComponentProps, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Form as DefForm } from 'formik';
import { TFunction } from 'i18next';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';
import { Button } from '@/components/buttons';
import { FormikTextField } from '@/components/formik-elements';
import { UserLoginIcon } from '@/components/icons';
import { useFirebaseRegisterWithEmail } from '@/hooks';
import { sendSentryError } from '@/utils/sentry';
import { styled } from '@mui/material/styles';

export type EmailRegisterFormProps = {
  onSuccessSubmit?: () => void;
} & ComponentProps<typeof Form>;

const EmailRegisterForm = ({
  onSuccessSubmit,
  ...props
}: EmailRegisterFormProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const schema = useMemo(() => getSchema({ t }), [t]);
  const [registerWithEmailAndPassword, _, __, authError] =
    useFirebaseRegisterWithEmail();

  useEffect(() => {
    if (authError) {
      let message = t('EMAIL_REGISTER_FORM__registeringError');
      if(authError.code == "auth/email-already-in-use"){
        message =  t('EMAIL_REGISTER_FORM__registeringErrorMailExists');
      } else
      if(authError.code == "auth/account-exists-with-different-credential"){
        message =  t('EMAIL_REGISTER_FORM__registeringErrorDifferent');
      } else {
        sendSentryError(authError);
      }

      enqueueSnackbar(message, {
        variant: 'error',
      });
    }
  }, [t, enqueueSnackbar, authError]);

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={async ({ email, password }) => {
        const userCredentials = await registerWithEmailAndPassword(
          email,
          password
        );
        !!userCredentials && onSuccessSubmit?.();
      }}
    >
      {() => (
        <Form {...props}>
          <Input
            type={'email'}
            name={'email'}
            label={t('EMAIL_REGISTER_FORM__emailInputLabel')}
          />
          <Input
            type={'password'}
            name={'password'}
            label={t('EMAIL_REGISTER_FORM__passwordInputLabel')}
          />
          <Input
            type={'password'}
            name={'confirmPassword'}
            label={t('EMAIL_REGISTER_FORM__passwordConfirmInputLabel')}
          />
          <SubmitButton type={'submit'}>
            <SubmitButtonIcon />
            {t('EMAIL_REGISTER_FORM__submitButton')}
          </SubmitButton>
        </Form>
      )}
    </Formik>
  );
};

type FormValues = {
  email: string;
  password: string;
  confirmPassword: string;
};

const initialValues: FormValues = {
  email: '',
  password: '',
  confirmPassword: '',
};

const getSchema = ({ t }: { t: TFunction }): yup.ObjectSchema<FormValues> =>
  yup
    .object({
      email: yup
        .string()
        .email(t('FORMS__emailError'))
        .required(t('FORMS__requiredError')),
      password: yup
        .string()
        .min(8, t('FORMS__minCharsError', { amount: 8 }))
        .required(t('FORMS__requiredError')),
      confirmPassword: yup
        .string()
        .oneOf([yup.ref('password')], t('FORMS__passwordsMatchError'))
        .required(t('FORMS__requiredError')),
    })
    .defined();

const Form = styled(DefForm)`
  display: flex;
  flex-direction: column;
`;

const Input = styled(FormikTextField)`
  & + & {
    margin-top: 32px;
  }
`;

const SubmitButton = styled(Button)`
  margin-top: 32px;
`;

const SubmitButtonIcon = styled(UserLoginIcon)`
  width: 14px;
  height: 14px;
  margin-right: 12px;
`;

export { EmailRegisterForm };
