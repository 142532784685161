import { useMemo, useCallback, useEffect } from 'react';
import { isString } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { useAsync } from 'react-use';
import { useRouter } from 'next/router';
import {
  UserLanguageDocument,
  UpdateUserLanguageDocument,
  UserLanguageQuery,
  UserLanguageQueryVariables,
  UpdateUserLanguageMutation,
  UpdateUserLanguageMutationVariables,
} from '@/apollo/operations';
import { useServerAuth } from '@/providers/ServerAuth';
import { getLangData, getLangNameByShortName } from '@/utils/i18n';
import { useApolloClient } from '@apollo/client';

export const useLanguage = () => {
  const { i18n } = useTranslation();
  const client = useApolloClient();
  const { authorized } = useServerAuth();
  const { query } = useRouter();

  const languageData = useMemo(
    () => getLangData(i18n.language),
    [i18n.language]
  );

  useEffect(() => {
    const languageToSet =
      isString(query.locale) && getLangNameByShortName(query.locale);

    if (languageToSet) {
      i18n.changeLanguage(languageToSet);
    }
  }, [query, i18n]);

  const updateLanguage = useCallback(
    async (lang: string) => {
      try {
        await client.mutate<
          UpdateUserLanguageMutation,
          UpdateUserLanguageMutationVariables
        >({
          mutation: UpdateUserLanguageDocument,
          variables: {
            lang,
          },
        });
      } catch (error) {
        console.log(error);
      }
    },
    [client]
  );

  useAsync(async () => {
    if (!authorized) return;

    try {
      const { data } = await client.query<
        UserLanguageQuery,
        UserLanguageQueryVariables
      >({
        query: UserLanguageDocument,
        fetchPolicy: 'no-cache',
      });
      const savedServerLanguage = data.me?.language;
      if (savedServerLanguage !== languageData.shortName) {
        await updateLanguage(languageData.shortName);
      }
    } catch (error) {
      console.log(error);
    }
  }, [authorized, languageData]);
};
