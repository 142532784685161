import { ReactNode } from 'react';
import { toNumber, isString } from 'lodash-es';
import Image from 'next/image';
import { useBlockchains } from '@/providers';
import { getFrontendUrl } from '@/utils/helpers';
import {
  FormControl as DefFormControl,
  FormLabel as DefFormLabel,
  RadioGroup as DefRadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
  FormControlProps,
} from '@mui/material';
import { styled, css } from '@mui/material/styles';
import { Text } from '../texts';

export type CurrenciesOptionsProps = {
  label: ReactNode;
  labelStyles: ReturnType<typeof css>;
  currencies: Currencies;
  selectedCurrencyId: number;
  helperText: ReactNode;
  onChange: (id: number) => void;
} & FormControlProps;

const CurrenciesOptions = ({
  label,
  labelStyles,
  currencies,
  selectedCurrencyId,
  onChange,
  error,
  helperText,
  ...props
}: CurrenciesOptionsProps) => (
  <FormControl {...props}>
    <FormLabel id="radio-buttons-group-label" css={labelStyles}>
      {label}:
    </FormLabel>
    <RadioGroup
      name="radio-buttons-group"
      aria-labelledby="radio-buttons-group-label"
      value={selectedCurrencyId}
      onChange={(event, value) => {
        onChange(toNumber(value));
      }}
    >
      {currencies.map(({ id, symbol, amount, icon }) => {
        const iconUrl = getFrontendUrl(icon);
        return (
          <FormControlLabel
            key={id}
            label={
              <Label>
                {!!iconUrl && (
                  <Icon width={20} height={20} src={iconUrl} alt={symbol} />
                )}
                {isString(amount) && !isNaN(toNumber(amount))
                  ? `${toNumber(amount).toFixed(2)} `
                  : ''}
                {symbol}
              </Label>
            }
            value={id}
            control={<Radio />}
          />
        );
      })}
      {error && !!helperText && (
        <FormHelperText error>{helperText}</FormHelperText>
      )}
    </RadioGroup>
  </FormControl>
);

type Currencies = Array<
  ReturnType<
    typeof useBlockchains
  >['blockchains'][number]['blockchain_currencies'][number] & {
    amount?: string;
  }
>;

const FormControl = styled(DefFormControl)`
  flex-direction: row;
`;

const FormLabel = styled(DefFormLabel)`
  margin-top: 10px;
`;

const RadioGroup = styled(DefRadioGroup)`
  flex-direction: row;
  align-items: center;
`;

const Icon = styled(Image)`
  display: inline-flex;
  margin-right: 4px;
`;

const Label = styled(Text)`
  display: flex;
  align-items: center;
`;

export { CurrenciesOptions };
