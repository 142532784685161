import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useModals } from '@/providers';
import { useServerAuth } from '@/providers/ServerAuth';

export const useOpenBuyPlayerShareModal = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { authorized } = useServerAuth();
  const { dispatch } = useModals<'buyPlayerShares'>();
  return useCallback(
    (playerId: string, shareId: string) => {
      if (authorized) {
        dispatch({
          type: 'setModalContent',
          payload: {
            name: 'buyPlayerShares',
            data: {
              playerId,
              shareId,
            },
          },
        });
      } else {
        enqueueSnackbar(t('BUY_PLAYER_SHARES_MODAL__authError'), {
          variant: 'warning',
        });
      }
    },
    [t, enqueueSnackbar, authorized, dispatch]
  );
};
