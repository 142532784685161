import { JSX } from 'react';
import {
  fallbackFonts,
  mobileDefaultFontSize,
  desktopDefaultFontSize,
  ColorName,
} from '@/styles';
import { styled, css, Theme } from '@mui/material/styles';

export type TextProps<T extends TextTagName = 'p'> = {
  component?: T;
  variant?: Variant;
  weight?: Weight;
  font?: Font;
  letterSpacing?: LetterSpacing;
  color?: ColorName;
  align?: Align;
} & JSX.IntrinsicElements[T];

const Text = <T extends TextTagName>({
  component,
  variant,
  weight,
  letterSpacing,
  color,
  align,
  ...props
}: TextProps<T>) => {
  return (
    // @ts-ignore
    <StyledText
      {...(component ? { as: component } : {})}
      $variant={variant || tagVariantMap[component || 'p']}
      $weight={weight}
      $letterSpacing={letterSpacing}
      $color={color}
      $align={align}
      {...props}
    />
  );
};

type TextTagName = keyof Pick<
  HTMLElementTagNameMap,
  'a' | 'legend' | 'label' | 'strong' | 'span' | 'p' | 'h1' | 'h2' | 'h3'
>;
type LetterSpacing = 'normal' | 't1' | 't2' | 't3' | 't4' | 't5' | 't6';
type Variant =
  | 'overline'
  | 'body-small'
  | 'body-medium'
  | 'body-large'
  | 'label-small'
  | 'label-medium'
  | 'label-large'
  | 'title-small'
  | 'title-medium'
  | 'title-large'
  | 'headline-small'
  | 'headline-medium'
  | 'headline-large'
  | 'display-small'
  | 'display-medium'
  | 'display-large';
type Weight = 'normal' | 'medium' | 'semi-bold' | 'bold';
type Font =
  | 'roboto'
  | 'sf-pro-display'
  | 'sf-pro-display-medium'
  | 'sf-pro-display-semibold'
  | 'sf-pro-display-bold';
type Align = 'left' | 'center' | 'right';

const tagVariantMap: {
  [key in TextTagName]: Variant;
} = {
  a: 'body-medium',
  legend: 'body-medium',
  label: 'body-medium',
  span: 'body-medium',
  p: 'body-medium',
  strong: 'body-medium',
  h3: 'title-medium',
  h2: 'title-medium',
  h1: 'title-medium',
};

const letterSpacingStyles: {
  [key in LetterSpacing]: ReturnType<typeof css>;
} = {
  normal: css`
    letter-spacing: normal;
  `,
  t1: css`
    letter-spacing: -0.25px;
  `,
  t2: css`
    letter-spacing: 0.1px;
  `,
  t3: css`
    letter-spacing: 0.15px;
  `,
  t4: css`
    letter-spacing: 0.25px;
  `,
  t5: css`
    letter-spacing: 0.4px;
  `,
  t6: css`
    letter-spacing: 0.5px;
  `,
};

const weightStyles: {
  [key in Weight]: ReturnType<typeof css>;
} = {
  normal: css`
    font-weight: 400;
    @supports (font-variation-settings: normal) {
      font-variation-settings: 'wght' 400;
    }
  `,
  medium: css`
    font-weight: 500;
    @supports (font-variation-settings: normal) {
      font-variation-settings: 'wght' 500;
    }
  `,
  'semi-bold': css`
    font-weight: 600;
    @supports (font-variation-settings: normal) {
      font-variation-settings: 'wght' 600;
    }
  `,
  bold: css`
    font-weight: 700;
    @supports (font-variation-settings: normal) {
      font-variation-settings: 'wght' 700;
    }
  `,
};

const fontStyles: {
  [key in Font]: ReturnType<typeof css>;
} = {
  roboto: css`
    font-family: Roboto, ${fallbackFonts};
    font-weight: 400;
    @supports (font-variation-settings: normal) {
      font-variation-settings: 'wght' 400;
    }
  `,
  'sf-pro-display': css`
    font-family: 'SF Pro Display', ${fallbackFonts};
    font-weight: 400;
  `,
  'sf-pro-display-medium': css`
    font-family: 'SF Pro Display', ${fallbackFonts};
    font-weight: 500;
  `,
  'sf-pro-display-semibold': css`
    font-family: 'SF Pro Display', ${fallbackFonts};
    font-weight: 600;
  `,
  'sf-pro-display-bold': css`
    font-family: 'SF Pro Display', ${fallbackFonts};
    font-weight: 700;
  `,
};

const getVariantStyles: (theme: Theme) => {
  [key in Variant]: ReturnType<typeof css>;
} = (theme) => ({
  overline: css`
    ${letterSpacingStyles['t6']};
    ${fontStyles['roboto']};
    font-size: ${theme.typography.pxToRem(10)};
    line-height: ${16 / 10};
    text-transform: uppercase;
  `,
  'body-small': css`
    ${letterSpacingStyles['t5']};
    ${fontStyles['roboto']};
    font-size: ${theme.typography.pxToRem(mobileDefaultFontSize)};
    line-height: ${16 / mobileDefaultFontSize};
  `,
  'body-medium': css`
    ${letterSpacingStyles['t4']};
    ${fontStyles['roboto']};
    font-size: ${theme.typography.pxToRem(desktopDefaultFontSize)};
    line-height: ${20 / desktopDefaultFontSize};
  `,
  'body-large': css`
    ${letterSpacingStyles['t6']};
    ${fontStyles['roboto']};
    font-size: ${theme.typography.pxToRem(16)};
    line-height: ${24 / 16};
  `,
  'label-small': css`
    ${weightStyles['medium']};
    ${letterSpacingStyles['t6']};
    ${fontStyles['roboto']};
    font-size: ${theme.typography.pxToRem(10)};
    line-height: ${16 / 10};
  `,
  'label-medium': css`
    ${weightStyles['medium']};
    ${letterSpacingStyles['t6']};
    ${fontStyles['roboto']};
    font-size: ${theme.typography.pxToRem(12)};
    line-height: ${16 / 12};
  `,
  'label-large': css`
    ${weightStyles['medium']};
    ${letterSpacingStyles['t2']};
    ${fontStyles['roboto']};
    font-size: ${theme.typography.pxToRem(14)};
    line-height: ${20 / 14};
  `,
  'title-small': css`
    ${weightStyles['medium']};
    ${letterSpacingStyles['t2']};
    ${fontStyles['roboto']};
    font-size: ${theme.typography.pxToRem(14)};
    line-height: ${20 / 14};
    ${theme.breakpoints.down('sm')} {
      // Label-small
      ${letterSpacingStyles['t6']};
      font-size: ${theme.typography.pxToRem(10)};
      line-height: ${16 / 10};
    }
  `,
  'title-medium': css`
    ${letterSpacingStyles['t3']};
    ${fontStyles['sf-pro-display-medium']};
    font-size: ${theme.typography.pxToRem(16)};
    line-height: ${24 / 16};
    ${theme.breakpoints.down('sm')} {
      // title-small
      ${weightStyles['medium']};
      ${letterSpacingStyles['t2']};
      ${fontStyles['roboto']};
      font-size: ${theme.typography.pxToRem(14)};
      line-height: ${20 / 14};
    }
  `,
  'title-large': css`
    ${letterSpacingStyles['normal']};
    ${fontStyles['sf-pro-display-semibold']};
    font-size: ${theme.typography.pxToRem(22)};
    line-height: ${28 / 22};
  `,
  'headline-small': css`
    ${letterSpacingStyles['normal']};
    ${fontStyles['sf-pro-display']};
    font-size: ${theme.typography.pxToRem(24)};
    line-height: ${32 / 24};
  `,
  'headline-medium': css`
    ${letterSpacingStyles['normal']};
    ${fontStyles['sf-pro-display']};
    font-size: ${theme.typography.pxToRem(28)};
    line-height: ${36 / 28};
  `,
  'headline-large': css`
    ${letterSpacingStyles['normal']};
    ${fontStyles['sf-pro-display']};
    font-size: ${theme.typography.pxToRem(32)};
    line-height: ${40 / 32};
  `,
  'display-small': css`
    ${letterSpacingStyles['normal']};
    ${fontStyles['sf-pro-display-semibold']};
    font-size: ${theme.typography.pxToRem(36)};
    line-height: ${48 / 36};
    ${theme.breakpoints.down('sm')} {
      // headline-small
      font-size: ${theme.typography.pxToRem(24)};
      line-height: ${32 / 24};
    }
  `,
  'display-medium': css`
    ${letterSpacingStyles['normal']};
    ${fontStyles['sf-pro-display-semibold']};
    font-size: ${theme.typography.pxToRem(44)};
    line-height: ${56 / 44};
  `,
  'display-large': css`
    ${letterSpacingStyles['t1']};
    ${fontStyles['sf-pro-display-semibold']};
    font-size: ${theme.typography.pxToRem(56)};
    line-height: ${64 / 56};
  `,
});

const primaryColorLinkStyles = (theme: Theme) => css`
  &:hover {
    color: ${theme.getColor('white')};
  }
`;

const StyledText = styled('p')<{
  $variant?: Variant;
  $weight?: Weight;
  $font?: Font;
  $letterSpacing?: LetterSpacing;
  $color?: ColorName;
  $align?: Align;
}>`
  ${({ theme, $variant = 'body-medium' }) => getVariantStyles(theme)[$variant]};
  ${({ $font }) => $font && fontStyles[$font]};
  ${({ $weight }) => $weight && weightStyles[$weight]};
  ${({ $letterSpacing }) =>
    $letterSpacing && letterSpacingStyles[$letterSpacing]};
  color: ${({ theme, $color }) => theme.getColor($color || 'white')};
  ${({ $align }) =>
    !!$align &&
    css`
      text-align: ${$align};
    `};
  ${({ theme, as, $color }) => {
    const primaryColor = $color === 'primary' || $color === 'brightTurquoise';
    if (!primaryColor) return;
    if (as === 'a') {
      return primaryColorLinkStyles(theme);
    } else {
      return css`
        a {
          color: inherit;
          ${primaryColorLinkStyles(theme)}
        }
      `;
    }
  }}
`;

export { Text };
