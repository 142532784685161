import { useMemo, ComponentProps, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Form as DefForm } from 'formik';
import { TFunction } from 'i18next';
import { useSnackbar } from 'notistack';
import { object, string, ObjectSchema } from 'yup';
import { Button } from '@/components/buttons';
import { FormikTextField } from '@/components/formik-elements';
import { UserLoginIcon } from '@/components/icons';
import { useFirebaseLogin } from '@/hooks/useFirebaseLogin';
import { useModals } from '@/providers';
import { sendSentryError } from '@/utils/sentry';
import { Link } from '@mui/material';
import { styled, css } from '@mui/material/styles';

export type EmailLoginFormProps = {
  onSuccessSubmit?: () => void;
} & ComponentProps<typeof Form>;

const EmailLoginForm = ({ onSuccessSubmit, ...props }: EmailLoginFormProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { dispatch } = useModals<'resetPassword'>();
  const schema = useMemo(() => getSchema({ t }), [t]);

  const [signInWithEmailAndPassword, _, __, authError] =
    useFirebaseLogin('email');

  useEffect(() => {
    if (authError) {
      let message = t('EMAIL_REGISTER_FORM__loginError');
      if(authError.code == "auth/wrong-password"){
        message =  t('EMAIL_REGISTER_FORM__loginErrorWrongPassword');
      } else
      if(authError.code == "auth/user-not-found"){
        message =  t('EMAIL_REGISTER_FORM__loginErrorNotFound');
      } else
      if(authError.code == "auth/account-exists-with-different-credential"){
        message =  t('EMAIL_REGISTER_FORM__registeringErrorDifferent');
      } else
      if(authError.code == "auth/user-disabled"){
        message =  t('EMAIL_REGISTER_FORM__loginErrorDisabled');
      } else {
        sendSentryError(authError);
      }

      enqueueSnackbar(message, {
        variant: 'error',
      });
    }
  }, [t, enqueueSnackbar, authError]);

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={async ({ email, password }) => {
        const userCredentials = await signInWithEmailAndPassword(
          email,
          password
        );
        !!userCredentials && onSuccessSubmit?.();
      }}
    >
      {({ isSubmitting }) => (
        <Form {...props}>
          <Input
            type={'email'}
            name={'email'}
            label={t('EMAIL_LOGIN_FORM__emailInputLabel')}
          />
          <Input
            type={'password'}
            name={'password'}
            label={t('EMAIL_LOGIN_FORM__passwordInputLabel')}
          />
          <Link
            component={'button'}
            css={css`
              align-self: flex-end;
            `}
            tabIndex={-1}
            onClick={() => {
              dispatch({
                type: 'setModalContent',
                payload: {
                  name: 'resetPassword',
                },
              });
            }}
          >
            {t('EMAIL_LOGIN_FORM__forgotPassword')}
          </Link>
          <SubmitButton type={'submit'} loading={isSubmitting}>
            <SubmitButtonIcon />
            {t('EMAIL_LOGIN_FORM__submitButton')}
          </SubmitButton>
        </Form>
      )}
    </Formik>
  );
};

type FormValues = {
  email: string;
  password: string;
};

const initialValues: FormValues = {
  email: '',
  password: '',
};

const getSchema = ({ t }: { t: TFunction }): ObjectSchema<FormValues> =>
  object({
    email: string()
      .email(t('FORMS__emailError'))
      .required(t('FORMS__requiredError')),
    password: string()
      .min(8, t('FORMS__minCharsError', { amount: 8 }))
      .required(t('FORMS__requiredError')),
  }).defined();

const Form = styled(DefForm)`
  display: flex;
  flex-direction: column;
`;

const Input = styled(FormikTextField)`
  & + & {
    margin-top: 32px;
  }
`;

const SubmitButton = styled(Button)`
  margin-top: 8px;
`;

const SubmitButtonIcon = styled(UserLoginIcon)`
  width: 14px;
  height: 14px;
  margin-right: 12px;
`;

export { EmailLoginForm };
