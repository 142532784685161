import {
  useMemo,
  useCallback,
  useState,
  useEffect,
  ComponentProps,
} from 'react';
import { isEmpty, toNumber, toString, uniq } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { useCopyToClipboard } from 'react-use';
import { useFormik, FormikConfig } from 'formik';
import { TFunction } from 'i18next';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';
import {
  DepositBankInfoDocument,
  useDepositBanksQuery,
  DepositBankInfoQuery,
  DepositBankInfoQueryVariables,
  useSubmitBankDepositMutation,
  TransactionStatus,
} from '@/apollo/operations';
import { Button } from '@/components/buttons';
import { PageSpinner } from '@/components/common-elements';
import { getFormattedNumber } from '@/utils/helpers';
import { sendSentryError } from '@/utils/sentry';
import { useApolloClient } from '@apollo/client';
import {
  TextField,
  FormControl,
  FormLabel as DefFormLabel,
  MenuItem,
  IconButton,
} from '@mui/material';
import { styled, css, Theme } from '@mui/material/styles';
import { CopyIcon as DefCopyIcon } from '../icons';
import { Text } from '../texts';
import {Simulate} from "react-dom/test-utils";
import copy = Simulate.copy;

export type BankDepositFormProps = ComponentProps<typeof Form>;

const BankDepositForm = (props: BankDepositFormProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const client = useApolloClient();

  const [_, copyToClipboard] = useCopyToClipboard();

  const [estimatedAmountToReceive, setEstimatedAmountToReceive] = useState('');

  const [depositDetails, setDepositDetails] =
    useState<DepositBankInfoQuery['getDepositBankInfo']>(null);

  const [schema, setSchema] = useState(() => getSchema({ t }));

  const { loading: loadingDepositBanks, data: depositBanksResponse } =
    useDepositBanksQuery({
      fetchPolicy: 'cache-and-network',
    });

  const depositBanks = useMemo(
    () => depositBanksResponse?.paymentMethodBanks ?? [],
    [depositBanksResponse]
  );

  const depositBanksCountries = useMemo(
    () => uniq(depositBanks.map(({ country }) => country)),
    [depositBanks]
  );

  const [submitBankDeposit, { loading: submittingBankDeposit }] =
    useSubmitBankDepositMutation({
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (
          data.submitBankDeposit?.status ===
          TransactionStatus.WaitForConfirmation
        ) {
          enqueueSnackbar(t('BANK_DEPOSIT_FORM__submitBankDepositSuccess'), {
            variant: 'success',
          });
        } else {
          enqueueSnackbar(t('BANK_DEPOSIT_FORM__submitBankDepositError'), {
            variant: 'error',
          });
        }
      },
      onError: (e) => {
        enqueueSnackbar(t('BANK_DEPOSIT_FORM__submitBankDepositError'), {
          variant: 'error',
        });
        sendSentryError(e);
      },
    });

  const onSubmit = useCallback<FormikConfig<FormValues>['onSubmit']>(
    async ({ bank, amount }) => {
      try {
        const response = await client.query<
          DepositBankInfoQuery,
          DepositBankInfoQueryVariables
        >({
          query: DepositBankInfoDocument,
          fetchPolicy: 'no-cache',
          variables: {
            bankId: toNumber(bank),
            amount: toNumber(amount),
          },
        });
        const details = response.data?.getDepositBankInfo;
        if (!details?.id) {
          throw new Error('No details in DepositBankInfoMutation response');
        }
        setDepositDetails(details);
      } catch (e) {
        if (e instanceof Error) {
          sendSentryError(e);
        }
        setDepositDetails(null);
        console.log(`Error in DepositBankInfoMutation:`, e);
        enqueueSnackbar(t('BANK_DEPOSIT_FORM__depositBankInfoError'), {
          variant: 'error',
        });
      }
    },
    [t, client, enqueueSnackbar]
  );

  const { values, errors, isSubmitting, setFieldValue, submitForm } = useFormik(
    {
      initialValues: initialValues,
      // enableReinitialize: true,
      validationSchema: schema,
      onSubmit,
    }
  );

  const banksSelectOptions = useMemo(
    () =>
      depositBanks
        .filter(({ country }) => country === values.country)
        .map(({ id, name }) => ({
          id: toString(id),
          name,
        })),
    [depositBanks, values.country]
  );

  const selectedBank = useMemo(
    () => depositBanks.find(({ id }) => toString(id) === values.bank),
    [depositBanks, values.bank]
  );

  useEffect(() => {
    if (!selectedBank) return;
    setSchema(
      getSchema({
        t,
        min: selectedBank.depositMin,
        max: selectedBank.depositMax,
      })
    );
  }, [t, selectedBank]);

  // On any form values change clear deposit details
  useEffect(() => {
    setDepositDetails(null);
  }, [values]);

  // Set estimate field on amount field change
  useEffect(() => {
    if (!isNaN(toNumber(values.amount)) && selectedBank) {
      setEstimatedAmountToReceive(
        toString(
          Math.round(toNumber(values.amount) / selectedBank.depositExchangeRate)
        )
      );
    }
  }, [values.amount, selectedBank]);

  return (
    <Form {...props}>
      {(() => {
        if (loadingDepositBanks && isEmpty(depositBanks)) {
          return <PageSpinner />;
        }

        if (isEmpty(depositBanksCountries)) {
          return (
            <Text
              align={'center'}
              css={css`
                margin-top: 22px;
              `}
            >
              {t('BANK_DEPOSIT_FORM__noCountriesOptions')}
            </Text>
          );
        }

        if (values.country) {
          if (banksSelectOptions.length === 1) {
            values.bank = banksSelectOptions[0].id;
          }
        }

        return (
          <>
            <TextField
              css={css`
                margin-bottom: 32px;
              `}
              select
              fullWidth
              label={t('BANK_DEPOSIT_FORM__countriesSelectLabel')}
              value={values.country}
              onChange={(data) => {
                const newCountry = data.target.value;
                setFieldValue('country', newCountry);
              }}
            >
              {depositBanksCountries.map((country) => (
                <MenuItem key={country} value={country}>
                  {country}
                </MenuItem>
              ))}
            </TextField>
            {!!values.country && banksSelectOptions.length > 1 && (
              <TextField
                css={css`
                  margin-bottom: 32px;
                `}
                select
                fullWidth
                label={t('BANK_DEPOSIT_FORM__bankSelectLabel')}
                value={values.bank}
                onChange={(data) => {
                  const bankId = data.target.value;
                  setFieldValue('bank', bankId);
                }}
              >
                {banksSelectOptions.map(({ id, name }) => (
                  <MenuItem key={id} value={id}>
                    {name}
                  </MenuItem>
                ))}
              </TextField>
            )}
            {values.country && values.bank && (
              <>
                <BottomFormControl fullWidth>
                  <FormLabel>
                    {t('BANK_DEPOSIT_FORM__amountToDepositLabel')}:
                  </FormLabel>
                  <Input
                    type={'number'}
                    disabled={isSubmitting}
                    value={values.amount}
                    InputProps={{
                      endAdornment: selectedBank ? (
                        <Text>{selectedBank.depositCurrency}</Text>
                      ) : undefined,
                    }}
                    placeholder={t(
                      'BANK_DEPOSIT_FORM__amountToDepositInputPlaceholder'
                    )}
                    error={!!errors.amount}
                    helperText={
                      errors.amount ||
                      (selectedBank
                        ? `${t(
                            'BANK_DEPOSIT_FORM__amountMin'
                          )}: ${getFormattedNumber(selectedBank.depositMin)} ${
                            selectedBank.depositCurrency
                          },\n${t(
                            'BANK_DEPOSIT_FORM__amountMax'
                          )}: ${getFormattedNumber(selectedBank.depositMax)} ${
                            selectedBank.depositCurrency
                          }`
                        : undefined)
                    }
                    onChange={(event) => {
                      console.log(
                        'event.target.value',
                        typeof event.target.value
                      );
                      return setFieldValue('amount', event.target.value);
                    }}
                  />
                </BottomFormControl>
                <BottomFormControl fullWidth>
                  <FormLabel>
                    {t('BANK_DEPOSIT_FORM__amountToReceiveLabel')}:
                  </FormLabel>
                  <Input
                    type={'number'}
                    disabled={isSubmitting}
                    value={estimatedAmountToReceive}
                    InputProps={{
                      endAdornment: selectedBank ? <Text>$</Text> : undefined,
                    }}
                    helperText={
                      selectedBank
                        ? `${t(
                            'BANK_DEPOSIT_FORM__amountExchangeRate'
                          )}: $1,00 ≈ ${getFormattedNumber(
                            selectedBank.depositExchangeRate
                          )} ${selectedBank.depositCurrency}`
                        : undefined
                    }
                    onChange={(event) => {
                      setEstimatedAmountToReceive(event.target.value);
                      if (
                        selectedBank &&
                        !isNaN(parseFloat(event.target.value))
                      ) {
                        setFieldValue(
                          'amount',
                          parseFloat(event.target.value) *
                            selectedBank.depositExchangeRate
                        );
                      } else {
                        setFieldValue('amount', 0);
                      }
                    }}
                  />
                  <SubmitButton loading={isSubmitting} onClick={submitForm}>
                    {t('BLOCKCHAIN_DEPOSIT_FORM__button')}
                  </SubmitButton>
                </BottomFormControl>
              </>
            )}
            {!!depositDetails && (
              <>
                <DetailsBlock>
                  <DetailsBlockLabel>
                    {t('BANK_DEPOSIT_FORM__paymentInstructionsLabel')}:
                  </DetailsBlockLabel>
                  <DetailsBlockValue>
                    {depositDetails.depositDetails.split('\\n').join('\n').split(/\[CopyPast value=.*\]/).at(0)}
                    <CopyButton
                      aria-label={'copy'}
                      onClick={() => {
                        const copyValue = depositDetails.depositDetails.substring(
                          depositDetails.depositDetails.indexOf('[CopyPast value=') + 16, depositDetails.depositDetails.indexOf(']')
                        );
                        if (!copyValue) return;
                        copyToClipboard(copyValue);
                      }}
                    >
                      <CopyIcon />
                    </CopyButton>
                    {depositDetails.depositDetails.split('\\n').join('\n').split(/\[CopyPast value=.*\]/).at(1)}
                  </DetailsBlockValue>
                </DetailsBlock>
                <DetailsBlock
                  css={css`
                    align-items: center;
                  `}
                >
                  <DetailsBlockLabel>
                    {t('BANK_DEPOSIT_FORM__refLabel')}:
                  </DetailsBlockLabel>
                  <DetailsBlockValue>
                    {depositDetails.depositReference}
                    <CopyButton
                      aria-label={'copy'}
                      onClick={() =>
                        copyToClipboard(depositDetails.depositReference)
                      }
                    >
                      <CopyIcon />
                    </CopyButton>
                  </DetailsBlockValue>
                  <PaidButton
                    loading={submittingBankDeposit}
                    onClick={() => {
                      // TODO: remove if
                      if (depositDetails?.id) {
                        submitBankDeposit({
                          variables: {
                            id: depositDetails.id,
                          },
                        });
                      }
                    }}
                  >
                    {t('BANK_DEPOSIT_FORM__paidButton')}
                  </PaidButton>
                </DetailsBlock>
              </>
            )}
          </>
        );
      })()}
    </Form>
  );
};

type FormValues = {
  country: string;
  bank: string;
  amount: number;
};

const initialValues: FormValues = {
  country: '',
  bank: '',
  amount: 0,
};

const getSchema = ({
  t,
  min = 0,
  max = 0,
}: {
  t: TFunction;
  min?: number;
  max?: number;
}): yup.ObjectSchema<FormValues> =>
  yup
    .object({
      country: yup.string().required(t('FORMS__requiredError')),
      bank: yup.string().required(t('FORMS__requiredError')),
      amount: yup
        .number()
        .positive(t('FORMS__positiveError'))
        .min(min, t('FORMS__minAmountError', { amount: min }))
        .max(max, t('FORMS__maxAmountError', { amount: max }))
        .required(t('FORMS__requiredError')),
    })
    .defined();

const Form = styled('form')``;

const labelStyles = (theme: Theme) => css`
  width: 140px;
  margin-right: 10px;
  ${theme.breakpoints.down('sm')} {
    width: 100%;
  }
`;

const horizontalBlockStyles = (theme: Theme) => css`
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  ${theme.breakpoints.down('sm')} {
    flex-wrap: wrap;
  }
`;

const BottomFormControl = styled(FormControl)`
  ${({ theme }) => horizontalBlockStyles(theme)};
`;

/*const BottomFormControlWrapper = styled(FormControl)`
  ${({ theme }) => horizontalBlockStyles(theme)};
`;*/

const FormLabel = styled(DefFormLabel)`
  ${({ theme }) => labelStyles(theme)}
  margin-top: 13px;
`;

const Input = styled(TextField)`
  display: flex;
  flex-grow: 1;
`;

const CopyButton = styled(IconButton)`
  padding: 2px;
  margin-left: 8px;
  cursor: copy;
`;

const CopyIcon = styled(DefCopyIcon)`
  width: 20px;
  height: 20px;
`;

const SubmitButton = styled(Button)`
  height: 53px;
  margin-left: 16px;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin-left: 0;
    margin-top: 10px;
    width: 100%;
  }
`;

const PaidButton = styled(Button)`
  height: 53px;
  margin-left: auto;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin-left: 0;
    margin-top: 10px;
    width: 100%;
  }
`;

const DetailsBlock = styled('div')`
  ${({ theme }) => horizontalBlockStyles(theme)};
`;

const DetailsBlockLabel = styled(Text)`
  ${({ theme }) => labelStyles(theme)}
  color: ${({ theme }) => theme.getColor('spunPearl')};
`;

const DetailsBlockValue = styled(Text)`
  white-space: pre;
`;

export { BankDepositForm };
