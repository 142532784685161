import { useMemo } from 'react';
import { isUndefined, isString, isNil } from 'lodash-es';
import { useFormikContext, useField } from 'formik';
import { TextFieldProps, TextField } from '@mui/material';

export type FormikTextFieldProps = Omit<TextFieldProps, 'onChange'> & {
  name: string;
};

const FormikTextField = ({ name, ...props }: FormikTextFieldProps) => {
  const { setFieldValue } = useFormikContext();
  const [{ value, onChange, ...field }, { touched, error }] = useField(name);

  /*const Component = useMemo(
    () => (props.type === 'password' ? PasswordField : Input),
    [props.type]
  );*/

  const customValue = useMemo(() => {
    if (props.select && (value === 0 || isUndefined(value))) {
      return '';
    }
    if (props.type === 'number' && isNil(value)) {
      return '';
    }
    return value;
  }, [props, value]);

  return (
    <TextField
      {...field}
      value={customValue}
      onChange={(newValue) => {
        isString(newValue) ? setFieldValue(name, newValue) : onChange(newValue);
      }}
      {...props}
      {...(touched && !!error
        ? {
            error: true,
            helperText: error,
          }
        : {})}
    />
  );
};

export { FormikTextField };
