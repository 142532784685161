import { SvgIcon, SvgIconProps } from '@mui/material';

export type GlobeIconProps = SvgIconProps;

const GlobeIcon = (props: GlobeIconProps) => (
  <SvgIcon {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M0 12C0 5.376 5.364 0 11.988 0 18.624 0 24 5.376 24 12s-5.376 12-12.012 12C5.364 24 0 18.624 0 12zm16.764-4.8h3.54a9.636 9.636 0 0 0-5.196-4.272A18.781 18.781 0 0 1 16.764 7.2zM12 2.448A16.904 16.904 0 0 1 14.292 7.2H9.708A16.904 16.904 0 0 1 12 2.448zM2.4 12c0 .828.12 1.632.312 2.4h4.056A19.817 19.817 0 0 1 6.6 12c0-.816.072-1.608.168-2.4H2.712A9.89 9.89 0 0 0 2.4 12zm1.296 4.8h3.54c.384 1.5.936 2.94 1.656 4.272A9.584 9.584 0 0 1 3.696 16.8zm0-9.6h3.54c.384-1.5.936-2.94 1.656-4.272A9.584 9.584 0 0 0 3.696 7.2zM12 21.552A16.904 16.904 0 0 1 9.708 16.8h4.584A16.903 16.903 0 0 1 12 21.552zM9 12c0 .816.084 1.608.192 2.4h5.616c.108-.792.192-1.584.192-2.4 0-.816-.084-1.62-.192-2.4H9.192C9.084 10.38 9 11.184 9 12zm6.108 9.072a18.78 18.78 0 0 0 1.656-4.272h3.54a9.636 9.636 0 0 1-5.196 4.272zM17.4 12c0 .816-.072 1.608-.168 2.4h4.056A9.892 9.892 0 0 0 21.6 12c0-.828-.12-1.632-.312-2.4h-4.056c.096.792.168 1.584.168 2.4z"
    />
  </SvgIcon>
);

export { GlobeIcon };
