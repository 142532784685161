import { sample } from 'lodash-es';
import { configureChains, createConfig, Connector } from 'wagmi';
import { InjectedConnector } from 'wagmi/connectors/injected';
import { MetaMaskConnector } from 'wagmi/connectors/metaMask';
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect';
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc';
import { publicProvider } from 'wagmi/providers/public';
import { supportedBlockchains } from '@/utils/blockchains';
import { getEnvVariable } from './env-helpers';

const getProvidersAndConnectors = () => {
  const { chains, publicClient, webSocketPublicClient } = configureChains(
    supportedBlockchains,
    [
      jsonRpcProvider({
        rpc: (chain) => ({
          http: sample(chain.rpcUrls.default.http)!,
        }),
      }),
      publicProvider(),
    ]
  );

  const injectedConnector = new InjectedConnector({
    chains,
  });

  const metaMaskConnector = new MetaMaskConnector({
    chains,
  });

  const walletConnectConnector = new WalletConnectConnector({
    chains,
    options: {
      projectId: getEnvVariable('WALLET_CONNECT_PROJECT_ID'),
    },
  });

  return [
    publicClient,
    webSocketPublicClient,
    [
      injectedConnector,
      metaMaskConnector,
      walletConnectConnector,
    ] as Connector[],
  ] as const;
};

export const getConfig = () => {
  const [publicClient, webSocketPublicClient, connectors] =
    getProvidersAndConnectors();

  return createConfig({
    publicClient,
    webSocketPublicClient,
    connectors,
    autoConnect: true,
  });
};
