import { useMemo, ComponentProps } from 'react';
import { isEmpty } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { Formik, Form as DefForm } from 'formik';
import { TFunction } from 'i18next';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';
import {
  StatusesEnum,
  VenueDocument,
  useCreateEventMutation,
  useEditEventMutation,
  useChangeEventStatusMutation,
  EditEventDataQuery,
  CreateEventMutationVariables,
} from '@/apollo/operations';
import { Button, GetImageButton } from '@/components/buttons';
import {
  HorizontalFormControl,
  HorizontalFormControlLabel,
} from '@/components/form-elements';
import { FormikTextField, FormikDateField } from '@/components/formik-elements';
import dayjs from '@/utils/dayjs';
import { TranslationKeys } from '@/utils/i18n';
import { sendSentryError } from '@/utils/sentry';
import { styled } from '@mui/material/styles';
import { Text } from '../texts';

export type UserVenueAddEditEventFormProps = {
  venueId: Maybe<string>;
  event?: EditEventDataQuery['event'];
  onSuccessSubmit?: () => void;
} & ComponentProps<typeof Form>;

const UserVenueAddEditEventForm = ({
  venueId,
  event,
  onSuccessSubmit,
  ...props
}: UserVenueAddEditEventFormProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const schema = useMemo(
    () => getSchema({ t, creation: !event?.id }),
    [t, event]
  );

  const initialValues = useMemo(
    () =>
      Object.entries(defInitialValues).reduce(
        (acc, [key, value]) => ({
          ...acc,
          [key]: event?.[key as keyof FormValues] ?? value,
        }),
        {}
      ) as FormValues,
    [event]
  );

  const commonMutationParams = useMemo(
    () => ({
      refetchQueries: [
        {
          query: VenueDocument,
          variables: {
            id: venueId,
          },
        },
      ],
      awaitRefetchQueries: true,
    }),
    [venueId]
  );

  const [createEvent] = useCreateEventMutation({
    ...commonMutationParams,
    onCompleted: () => {
      enqueueSnackbar(
        t('USER_VENUE_ADD_EDIT_EVENT_FORM__createMutationSuccess'),
        {
          variant: 'success',
        }
      );
      onSuccessSubmit?.();
    },
    onError: (e) => {
      sendSentryError(e);
      enqueueSnackbar(
        t('USER_VENUE_ADD_EDIT_EVENT_FORM__createMutationError'),
        {
          variant: 'error',
        }
      );
    },
  });

  const [editEvent] = useEditEventMutation({
    ...commonMutationParams,
    onCompleted: () => {
      enqueueSnackbar(
        t('USER_VENUE_ADD_EDIT_EVENT_FORM__editMutationSuccess'),
        {
          variant: 'success',
        }
      );
      onSuccessSubmit?.();
    },
    onError: (e) => {
      sendSentryError(e);
      enqueueSnackbar(t('USER_VENUE_ADD_EDIT_EVENT_FORM__editMutationError'), {
        variant: 'error',
      });
    },
  });

  const [changeEventStatus, { loading: changingEventStatus }] =
    useChangeEventStatusMutation({
      ...commonMutationParams,
      onCompleted: () => {
        enqueueSnackbar(
          t('USER_VENUE_ADD_EDIT_EVENT_FORM__publishMutationSuccess'),
          {
            variant: 'success',
          }
        );
        onSuccessSubmit?.();
      },
      onError: (e) => {
        sendSentryError(e);
        enqueueSnackbar(
          t('USER_VENUE_ADD_EDIT_EVENT_FORM__publishMutationError'),
          {
            variant: 'error',
          }
        );
      },
    });

  if (!venueId) {
    return <Text>Error</Text>;
  }

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={(values) => {
        if (!event?.id) {
          return createEvent({
            variables: {
              data: {
                venueId,
                ...values,
              },
            },
          });
        }

        const newValues = Object.entries(values).reduce(
          (acc, [key, value]) =>
            initialValues[key as keyof FormValues] === value
              ? acc
              : {
                  ...acc,
                  [key]: value,
                },
          {}
        );

        if (isEmpty(newValues)) {
          enqueueSnackbar(
            t('USER_VENUE_ADD_EDIT_EVENT_FORM__noChangesMessage'),
            {
              variant: 'info',
            }
          );
          return Promise.resolve();
        }

        return editEvent({
          variables: {
            id: event.id,
            data: newValues,
          },
        });
      }}
    >
      {({ touched, errors, isSubmitting, setFieldValue }) => (
        <Form {...props}>
          {inputs.map(([key, { label, Component = () => null, ...props }]) => (
            <HorizontalFormControl key={key}>
              <HorizontalFormControlLabel>
                {t(label)}
              </HorizontalFormControlLabel>
              {key === 'image' ? (
                <AddButton
                  error={touched.image && !!errors.image}
                  disabled={isSubmitting}
                  onImagePick={(img) => {
                    setFieldValue('image', img);
                  }}
                >
                  {t(label)}
                </AddButton>
              ) : (
                <Component name={key} disabled={isSubmitting} {...props} />
              )}
            </HorizontalFormControl>
          ))}
          <Buttons>
            <SubmitButton type={'submit'} loading={isSubmitting}>
              {t(
                event?.id
                  ? 'USER_VENUE_ADD_EDIT_EVENT_FORM__editButton'
                  : 'USER_VENUE_ADD_EDIT_EVENT_FORM__createButton'
              )}
            </SubmitButton>
            {!!event && event.status === StatusesEnum.Inactive && (
              <PublishButton
                loading={changingEventStatus}
                onClick={() => {
                  changeEventStatus({
                    variables: {
                      id: event.id,
                      status: StatusesEnum.Active,
                    },
                  });
                }}
              >
                {t('EDIT_PLAYER_PROFILE_FORM__publishButton')}
              </PublishButton>
            )}
          </Buttons>
        </Form>
      )}
    </Formik>
  );
};

const Form = styled(DefForm)`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const AddButton = styled(GetImageButton)`
  flex: 1 1 auto;
`;

const Input = styled(FormikTextField)`
  flex: 1 1 auto;
`;

const DateInput = styled(FormikDateField)`
  flex: 1 1 auto;
`;

const Buttons = styled('div')`
  margin-top: 32px;
  display: flex;
  justify-content: center;
  gap: 16px;
`;

const SubmitButton = styled(Button)`
  width: 200px;
`;

const PublishButton = styled(SubmitButton)``;

type InputData = {
  label: TranslationKeys;
} & (
  | {
      Component: typeof Input;
      props?: Omit<ComponentProps<typeof Input>, 'name'>;
    }
  | {
      Component: typeof DateInput;
      props?: Omit<ComponentProps<typeof DateInput>, 'name'>;
    }
  | {
      Component?: undefined;
    }
);

const inputsMap: ReadonlyMap<keyof FormValues, InputData> = new Map([
  [
    'name',
    {
      label: 'USER_VENUE_ADD_EDIT_EVENT_FORM__nameInputLabel',
      Component: Input,
    },
  ],
  [
    'dateFrom',
    {
      label: 'USER_VENUE_ADD_EDIT_EVENT_FORM__dateFromInputLabel',
      Component: DateInput,
      disablePast: true,
    },
  ],
  [
    'dateTill',
    {
      label: 'USER_VENUE_ADD_EDIT_EVENT_FORM__dateTillInputLabel',
      Component: DateInput,
      disablePast: true,
    },
  ],
  [
    'location',
    {
      label: 'USER_VENUE_ADD_EDIT_EVENT_FORM__locationInputLabel',
      Component: Input,
    },
  ],
  [
    'description',
    {
      label: 'USER_VENUE_ADD_EDIT_EVENT_FORM__descriptionInputLabel',
      Component: Input,
      multiline: true,
      rows: 4,
    },
  ],
  [
    'image',
    {
      label: 'USER_VENUE_ADD_EDIT_EVENT_FORM__imageInputLabel',
    },
  ],
]);

const inputs = Array.from(inputsMap);

type FormValues = Pick<
  CreateEventMutationVariables['data'],
  'name' | 'dateFrom' | 'dateTill' | 'location' | 'description' | 'image'
>;

const defInitialValues: FormValues = {
  name: '',
  dateFrom: '',
  dateTill: '',
  location: '',
  description: '',
  image: '',
};

const getSchema = <T extends boolean>({
  t,
  creation,
}: {
  t: TFunction;
  creation: T;
}): yup.ObjectSchema<T extends true ? Required<FormValues> : FormValues> =>
  (creation
    ? yup
        .object({
          name: yup.string().required(t('FORMS__requiredError')),
          dateFrom: yup
            .string()
            .test('date-validation', t('FORMS__invalidDate'), (value) =>
              dayjs(value).isValid()
            )
            .required(t('FORMS__requiredError')),
          dateTill: yup
            .string()
            .test('date-validation', t('FORMS__invalidDate'), (value) =>
              dayjs(value).isValid()
            )
            .test(
              'after-from',
              t(
                'USER_VENUE_ADD_EDIT_EVENT_FORM__tillDateShouldBeAfterFromError'
              ),
              (value, context) => {
                return (
                  dayjs(context.parent.dateFrom).isValid() &&
                  dayjs(value).isAfter(dayjs(context.parent.dateFrom))
                );
              }
            )
            .required(t('FORMS__requiredError')),
          location: yup.string().required(t('FORMS__requiredError')),
          description: yup.string().required(t('FORMS__requiredError')),
          image: yup.string().required(t('FORMS__requiredError')),
        })
        .defined()
    : yup
        .object({
          name: yup.string(),
          dateFrom: yup.string(),
          dateTill: yup.string(),
          location: yup.string(),
          description: yup.string(),
          image: yup.string(),
        })
        .defined()) as $TSFixMe;

export { UserVenueAddEditEventForm };
