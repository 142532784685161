import { PlusIcon } from '@/components/icons';
import { styled, css } from '@mui/material/styles';
import { Button, ButtonProps } from './Button';

export type AddButtonProps = ButtonProps;

const AddButton = ({ children, ...props }: AddButtonProps) => (
  <Button
    css={css`
      align-items: center;
    `}
    {...props}
  >
    <AddIcon />
    {children}
  </Button>
);

const AddIcon = styled(PlusIcon)`
  margin-right: 10px;
  width: 12px;
  height: 12px;
`;

export { AddButton };
