import { useCallback } from 'react';
import { isString } from 'lodash-es';
import { useMediaQuery } from '@mui/material';
import { Breakpoint, useTheme } from '@mui/material/styles';

export type GetShortenedStringConfig = { breakPoint?: Breakpoint | number } & {
  [key in 'mobile' | 'desktop']?: {
    start?: number;
    end?: number;
  };
};

export const useGetShortenedString = (config?: GetShortenedStringConfig) => {
  const theme = useTheme();
  const breakPoint = config?.breakPoint || 'sm';
  const mobileStartLength = config?.mobile?.start || 4;
  const mobileEndLength = config?.mobile?.end || 6;
  const desktopStartLength = config?.desktop?.start || 6;
  const desktopEndLength = config?.desktop?.end || 8;
  const mobile = useMediaQuery(theme.breakpoints.down(breakPoint));
  const startLength = mobile ? mobileStartLength : desktopStartLength;
  const endLength = mobile ? mobileEndLength : desktopEndLength;

  return useCallback(
    (str: unknown) => {
      if (!isString(str)) return '';

      return `${str.substring(0, startLength)}..${str.substring(
        str.length - endLength
      )}`;
    },
    [startLength, endLength]
  );
};
