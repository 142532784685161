import {
  useCallback,
  createContext,
  useContext,
  useEffect,
  useMemo,
  PropsWithChildren,
} from 'react';
import toast from 'react-hot-toast';
import { usePrevious, useUpdateEffect } from 'react-use';
import { useAccount } from 'wagmi';
import {
  useLocalStorageValue,
  useGetWalletServerAuthData,
  useFirebaseAuthState,
  useFirebaseLogout,
} from '@/hooks';
import { wsClient } from '@/utils/apollo-client';
import {
  LocalStorageKeys,
  setLocalStorageValue,
  removeLocalStorageValue,
} from '@/utils/local-storage';
import { setSentryUser, removeSentryUser } from '@/utils/sentry';
import { useApolloClient } from '@apollo/client';

const ServerAuth = (props: PropsWithChildren) => {
  const client = useApolloClient();
  const [firebaseUser, loadingFirebaseUser] = useFirebaseAuthState();
  const [logoutFirebase] = useFirebaseLogout();
  const { address } = useAccount();
  const authData = useLocalStorageValue(LocalStorageKeys.GRAPHQL_AUTH);
  const userId = authData?.userId;
  const prevUserId = usePrevious(userId);
  const getWalletServerAuthData = useGetWalletServerAuthData();

  const authOnServerWithWallet = useCallback(
    async (customAddress?: CryptoAddress) => {
      try {
        const userCryptoAddress = address || customAddress;
        if (!userCryptoAddress) {
          throw new Error(`Can't login without connected wallet`);
        }
        const serverAuth = await getWalletServerAuthData(userCryptoAddress);
        setLocalStorageValue(LocalStorageKeys.GRAPHQL_AUTH, {
          type: 'wallet',
          ...serverAuth,
        });
        if (wsClient) {
          wsClient.restart();
        }
        return true;
      } catch (e) {
        console.log(e);
        toast.error(`Couldn't auth on server`);
        removeLocalStorageValue(LocalStorageKeys.GRAPHQL_AUTH);
        return false;
      }
    },
    [address, getWalletServerAuthData]
  );

  useUpdateEffect(() => {
    if (loadingFirebaseUser) return;
    const t = setTimeout(() => {
      if (authData && !(firebaseUser || address)) {
        removeLocalStorageValue(LocalStorageKeys.GRAPHQL_AUTH);
      }
    }, 2000);
    return () => {
      clearTimeout(t);
    };
  }, [authData, loadingFirebaseUser, firebaseUser, address]);

  useEffect(() => {
    if (userId) {
      setSentryUser(userId);
    } else {
      removeSentryUser();
    }
  }, [userId]);

  // Logout firebase if auth data was cleared
  useEffect(() => {
    if (!authData && firebaseUser) {
      logoutFirebase();
    }
  }, [authData, firebaseUser, logoutFirebase]);

  // Clear apollo cache if auth data was cleared
  useEffect(() => {
    if (!authData) {
      client.cache.evict({ fieldName: 'me' });
      client.cache.evict({ fieldName: 'userBalances' });
      client.cache.gc();
    }
  }, [authData, prevUserId, userId, client]);

  const context = useMemo<Context>(
    () => ({
      ...(authData
        ? {
            authorized: true,
            type: authData.type || 'wallet',
            userId: authData.userId,
          }
        : {
            authorized: false,
            type: null,
            userId: null,
          }),
      authOnServerWithWallet,
    }),
    [authData, authOnServerWithWallet]
  );

  return <ServerAuthContext.Provider value={context} {...props} />;
};

type Context = (
  | {
      authorized: false;
      type: null;
      userId: null;
    }
  | {
      authorized: true;
      type: 'firebase' | 'wallet';
      userId: string;
    }
) & {
  authOnServerWithWallet: (address?: CryptoAddress) => Promise<boolean>;
};

const ServerAuthContext = createContext<Context>({
  authorized: false,
  type: null,
  userId: null,
  authOnServerWithWallet: () => Promise.resolve(false),
});

const useServerAuth = () => useContext(ServerAuthContext);

export { ServerAuth, useServerAuth };
