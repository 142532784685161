import { useRef } from 'react';
import { useMount } from 'react-use';
import { LoopIcon } from '@/components/icons';
import { searchInputsIconStyles } from '@/styles/searchInputs';
import { TextField, CircularProgress, TextFieldProps } from '@mui/material';
import { styled } from '@mui/material/styles';

export type SearchInputProps = {
  loading?: boolean;
} & TextFieldProps;

const SearchInput = ({ loading, ...props }: SearchInputProps) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  useMount(() => {
    inputRef.current?.focus();
  });

  return (
    <Input
      inputRef={inputRef}
      fullWidth
      variant={'filled'}
      InputProps={{
        endAdornment: loading ? (
          <CircularProgress size={20} />
        ) : (
          <LoopIcon css={searchInputsIconStyles} />
        ),
        disableUnderline: true,
      }}
      {...props}
    />
  );
};

const Input = styled(TextField)``;

export { SearchInput };
