import { isString } from 'lodash-es';
import { initReactI18next } from 'react-i18next';
import dayjs from 'dayjs';
import i18n from 'i18next';
import en from '../assets/i18n/en.json';
import kr from '../assets/i18n/kr.json';
import vn from '../assets/i18n/vn.json';
import enImg from '../assets/svgs/en.svg';
import krImg from '../assets/svgs/kr.svg';
import vnImg from '../assets/svgs/vn.svg';
import {
  LocalStorageKeys,
  getLocalStorageValue,
  setLocalStorageValue,
} from './local-storage';

export const supportedLanguages = ['English', 'Korean', 'Vietnamese'] as const;

export const resources = {
  English: {
    translation: en,
  },
  Korean: {
    translation: kr,
  },
  Vietnamese: {
    translation: vn,
  },
} as const;

export type TranslationKeys =
  keyof (typeof resources)['English']['translation'];

/*export const shortLangNames = {
  // noinspection JSUnusedGlobalSymbols
  en: 'English',
  ko: 'Korean',
  sp: 'Spanish',
  vi: 'Vietnamese',
  ja: 'Japanese',
  ru: 'Russian',
};*/

i18n.use(initReactI18next).init({
  resources,
  lng: getLocalStorageValue(LocalStorageKeys.LANGUAGE) ?? undefined,
  fallbackLng: 'English',
  interpolation: {
    escapeValue: false,
  },
});

const langData: ReadonlyMap<
  (typeof supportedLanguages)[number],
  {
    shortName: string;
    dayjsName: string;
    switcherFullName: string;
    icon: string;
  }
> = new Map([
  [
    'English',
    {
      shortName: 'en',
      dayjsName: 'en-gb',
      switcherFullName: 'English',
      icon: enImg,
    },
  ],
  [
    'Korean',
    {
      shortName: 'kr',
      dayjsName: 'ko',
      switcherFullName: 'Korean / 한국인',
      icon: krImg,
    },
  ],
  [
    'Vietnamese',
    {
      shortName: 'vn',
      dayjsName: 'vi',
      switcherFullName: 'Vietnamese / Tiếng Việt',
      icon: vnImg,
    },
  ],
]);

const isLangName = (key: unknown): key is keyof typeof resources =>
  isString(key) && Object.keys(resources).includes(key);

export const getLangData = (key: unknown) => {
  if (!(isLangName(key) && langData.has(key))) {
    throw new Error('invalid language key');
  }
  return langData.get(key)!;
};

/*export const isValidShortName = (shortName: string) => {
  Array.from(langData)
    .map(([_, { shortName }]) => shortName)
    .some((sn) => sn === shortName);
};*/

export const getLangNameByShortName = (shortName: string) => {
  return Array.from(langData)
    .map(([key, { shortName }]) => ({ key, shortName }))
    .find(({ shortName: sn }) => sn === shortName)?.key;
};

i18n.on('languageChanged', function (language) {
  dayjs.locale(getLangData(language).dayjsName);
  setLocalStorageValue(LocalStorageKeys.LANGUAGE, language);
});

// noinspection JSUnusedGlobalSymbols
export default i18n;
