import { PropsWithChildren } from 'react';
import { Menu as DefMenu, MenuProps as DefMenuProps } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export type MenuProps = PropsWithChildren<DefMenuProps>;

const Menu = (props: MenuProps) => {
  const theme = useTheme();
  return (
    <DefMenu
      PaperProps={{
        sx: {
          width: 300,
          '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: theme.colors.blueZodiac,
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      {...props}
    />
  );
};

export { Menu };
