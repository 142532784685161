import { useMemo, useCallback, ComponentProps } from 'react';
import { isEmpty, toNumber, toString } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { useUpdateEffect } from 'react-use';
import { useFormik, FormikConfig } from 'formik';
import { TFunction } from 'i18next';
import { useSnackbar } from 'notistack';
import { useAccount } from 'wagmi';
import * as yup from 'yup';
import { object, number, ObjectSchema } from 'yup';
import {
  TransactionStatus,
  useBlockchainSubmitWithdrawMutation,
  useBlockchainWithdrawalFormDataQuery,
} from '@/apollo/operations';
import { Button, ConnectWalletButton } from '@/components/buttons';
import { PageSpinner } from '@/components/common-elements';
import { CurrenciesOptions as DefCurrenciesOptions } from '@/components/form-elements/CurrenciesOptions';
import { useConnectWalletToFirebaseUser } from '@/hooks';
import { useBlockchains, useModals, useUserBalance } from '@/providers';
import { useServerAuth } from '@/providers/ServerAuth';
import { prettifyResponse, getPrice } from '@/utils/helpers';
import { sendSentryError } from '@/utils/sentry';
import { ApolloError } from '@apollo/client';
import {
  TextField,
  FormControl,
  FormLabel as DefFormLabel,
  MenuItem,
} from '@mui/material';
import { styled, css } from '@mui/material/styles';
import { Text } from '../texts';

export type BlockchainWithdrawFormProps = ComponentProps<typeof Form>;

const BlockchainWithdrawForm = (props: BlockchainWithdrawFormProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { dispatch } = useModals<'wallets' | 'userSettings'>();
  const { isConnected, isConnecting, address } = useAccount();
  const { loading, blockchains } = useBlockchains();

  const { authorized, type } = useServerAuth();

  const { balance: rawBalance } = useUserBalance();

  const balance = rawBalance || 0;

  const { data: formData } = useBlockchainWithdrawalFormDataQuery({
    skip: !authorized,
  });

  const savedUserAddress = formData?.me?.erc20Address;
  const user2FAEnabled = !!formData?.me?.twofa;

  const schema = useMemo(
    () =>
      getSchema({
        t,
        ...(balance >= 2
          ? {
              min: 1,
              max: balance,
            }
          : {}),
      }),
    [t, balance]
  );

  const [
    { loading: connectingWalletToFirebaseUser },
    connectWalletToFirebaseUser,
  ] = useConnectWalletToFirebaseUser();

  const [userPaymentSubmitWithdraw] = useBlockchainSubmitWithdrawMutation({
    fetchPolicy: 'no-cache',
  });

  const onSubmit = useCallback<FormikConfig<FormValues>['onSubmit']>(
    async ({ blockchain, currency, amount, twoFA }) => {
      try {
        if (!(address && blockchain && currency)) {
          throw new Error(
            `Something's wrong with withdraw mutation params: ${prettifyResponse(
              { address, currency, amount }
            )}`
          );
        }

        const { data: withdrawResult } = await userPaymentSubmitWithdraw({
          variables: {
            address,
            blockchain,
            currency,
            amount,
            twoFA,
          },
        });

        if (
          withdrawResult?.submitWithdraw?.status === TransactionStatus.Created
        ) {
          enqueueSnackbar(t('BLOCKCHAIN_WITHDRAW_FORM__withdrawSuccess'), {
            variant: 'success',
          });
        } else if (
          withdrawResult?.submitWithdraw?.status ===
          TransactionStatus.Waiting_2fa
        ) {
          enqueueSnackbar(t('BLOCKCHAIN_WITHDRAW_FORM__withdraw2FA'), {
            variant: 'success',
          });
        } else {
          throw new Error(`Wrong status in payment withdraw mutation`);
        }
      } catch (e) {
        if (e instanceof Error || e instanceof ApolloError) {
          sendSentryError(e);
        }
        console.log(`Error in deposit function:`, e);
        enqueueSnackbar(t('BLOCKCHAIN_WITHDRAW_FORM__depositError'), {
          variant: 'error',
        });
      }
    },
    [t, enqueueSnackbar, address, userPaymentSubmitWithdraw]
  );

  const { values, touched, errors, isSubmitting, setFieldValue, submitForm } =
    useFormik({
      initialValues,
      enableReinitialize: true,
      validationSchema: schema,
      onSubmit,
    });

  const blockchainsOptions = useMemo(
    () =>
      blockchains.map(({ id, name, iconUrl }) => ({
        label: name,
        value: toString(id),
        icon: iconUrl ?? undefined,
      })),
    [blockchains]
  );

  const blockchainCurrencies = useMemo(() => {
    const blockchain = blockchains.find(({ id }) => id === values.blockchain);
    return (
      blockchain?.blockchain_currencies.filter(({ withdraw }) => withdraw) ?? []
    );
  }, [blockchains, values.blockchain]);

  const withdrawFee = useMemo(
    () =>
      blockchainCurrencies.find(({ id }) => id === values.currency)
        ?.withdrawFee ?? null,
    [blockchainCurrencies, values.currency]
  );

  // Reset values on blockchain change
  useUpdateEffect(() => {
    setFieldValue('currency', null);
    setFieldValue('amount', 0);
  }, [values.blockchain, setFieldValue]);

  /* User is connected either with firebase or with wallet. Otherwise, he
   * shouldn't be able to see withdraw/deposit button.
   * Interface cases:
   * 1. Not firebase user
   *    a. Wallet connected - normal flow
   *    b. Wallet not connected - connect and auth with wallet
   * 2. Firebase user
   *    a. Saved wallet address exists, wallet is connected, addresses match -
   *       normal flow
   *    b. Wallet not connected blocked with linking wallet to firebase use
   *       button
   * */

  // TODO: add same for wallet auth
  if (type === 'firebase' && !savedUserAddress) {
    return !isConnected ? (
      <>
        <Text align={'center'}>
          {t('BLOCKCHAIN_WITHDRAW__connectWalletDesc1')}
        </Text>
        <ConnectWalletButton
          css={connectWalletButtonStyles}
          loading={isConnecting}
          onClick={() => {
            dispatch({
              type: 'setModalContent',
              payload: {
                name: 'wallets',
                data: {
                  withServerAuthRequest: false,
                },
              },
            });
          }}
        />
      </>
    ) : (
      <>
        <Text align={'center'}>
          {t('BLOCKCHAIN_WITHDRAW__connectWalletDesc2')}
        </Text>
        <ConnectWalletButton
          css={connectWalletButtonStyles}
          loading={connectingWalletToFirebaseUser}
          onClick={connectWalletToFirebaseUser}
        />
      </>
    );
  }

  // if (!user2FAEnabled) {
  //   return (
  //     <>
  //       <Text align={'center'}>{t('BLOCKCHAIN_WITHDRAW_FORM__no2FA')}</Text>
  //       <Button
  //         css={css`
  //           display: block;
  //           margin: 16px auto 0;
  //         `}
  //         onClick={() => {
  //           dispatch({
  //             type: 'setModalContent',
  //             payload: {
  //               name: 'userSettings',
  //               data: undefined,
  //             },
  //           });
  //         }}
  //       >
  //         {t('BLOCKCHAIN_WITHDRAW_FORM__openSettingsModalButton')}
  //       </Button>
  //     </>
  //   );
  // }

  return (
    <Form {...props}>
      {loading ? (
        <PageSpinner />
      ) : (
        <>
          <TextField
            fullWidth
            select
            label={t('BLOCKCHAINS_SELECT__placeholder')}
            value={toString(values.blockchain)}
            disabled={isSubmitting}
            onChange={(data) => {
              const newBlockchainId = toNumber(data.target.value);
              if (newBlockchainId !== values.blockchain) {
                setFieldValue('blockchain', toNumber(newBlockchainId));
              }
            }}
          >
            {blockchainsOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          {(() => {
            if (isEmpty(blockchainCurrencies)) {
              return (
                <Text
                  align={'center'}
                  css={css`
                    margin-top: 22px;
                  `}
                >
                  {t('BLOCKCHAIN_WITHDRAW_FORM__noOptions')}
                </Text>
              );
            }

            return (
              <>
                <CurrenciesOptions
                  disabled={isSubmitting}
                  label={t('BLOCKCHAIN_WITHDRAW_FORM__availableOptionsLabel')}
                  labelStyles={labelStyles}
                  currencies={blockchainCurrencies}
                  selectedCurrencyId={values.currency ?? 0}
                  error={!!errors.currency}
                  helperText={errors.currency}
                  onChange={(value) => {
                    setFieldValue('currency', value);
                  }}
                />
                <BottomFormControl fullWidth>
                  <FormLabel css={labelStyles}>
                    {t('BLOCKCHAIN_WITHDRAW_FORM__amountToDepositLabel')}:
                  </FormLabel>
                  <Input
                    disabled={!values.currency || isSubmitting}
                    value={values.amount}
                    placeholder={t(
                      'BLOCKCHAIN_WITHDRAW_FORM__amountToDepositInputPlaceholder'
                    )}
                    error={!!errors.amount}
                    helperText={
                      errors.amount ||
                      (!!withdrawFee && (
                        <Text component={'span'} variant={'body-small'}>
                          {t('BLOCKCHAIN_WITHDRAW_FORM__withdrawFeeHelperText')}{' '}
                          {getPrice(withdrawFee)}
                        </Text>
                      ))
                    }
                    InputProps={{
                      endAdornment: (
                        <MaxButton
                          disabled={!(values.blockchain && values.currency)}
                          variant={'outlined'}
                          onClick={() => {
                            if (values.blockchain && values.currency) {
                              setFieldValue('amount', balance);
                            }
                          }}
                        >
                          {t(
                            'BLOCKCHAIN_WITHDRAW_FORM__amountToDepositInputMaxButton'
                          )}
                        </MaxButton>
                      ),
                    }}
                    onChange={(event) =>
                      setFieldValue('amount', toNumber(event.target.value))
                    }
                  />
                  { (!user2FAEnabled) && (
                    <SubmitButton loading={isSubmitting} onClick={submitForm}>
                      {t('BANK_WITHDRAWAL_FORM__submitButton')}
                    </SubmitButton>
                  )}
                </BottomFormControl>
                { (user2FAEnabled) && ( <BottomFormControl fullWidth>
                  <FormLabel css={labelStyles}>
                    {t('BLOCKCHAIN_WITHDRAW_FORM__2faLabel')}:
                  </FormLabel>
                  <Input
                    disabled={isSubmitting}
                    value={values.twoFA}
                    error={touched.twoFA && !!errors.twoFA}
                    helperText={touched.twoFA && errors.twoFA}
                    onChange={(event) =>
                      setFieldValue('twoFA', event.target.value)
                    }
                  />
                  <SubmitButton loading={isSubmitting} onClick={submitForm}>
                    {t('BLOCKCHAIN_WITHDRAW_FORM__button')}
                  </SubmitButton>
                </BottomFormControl>
                )}
              </>
            );
          })()}
        </>
      )}
    </Form>
  );
};

type FormValues = {
  blockchain: Nullish<number>;
  currency: Nullish<number>;
  amount: number;
  twoFA: string | undefined;
};

const initialValues: FormValues = {
  blockchain: null,
  currency: null,
  amount: 0,
  twoFA: '',
};

const getSchema = ({
  t,
  min = 0,
  max = 0,
}: {
  t: TFunction;
  min?: number;
  max?: number;
}): ObjectSchema<FormValues> =>
  object({
    blockchain: number().required(t('FORMS__requiredError')),
    currency: number().required(t('FORMS__requiredError')),
    amount: number()
      .transform((value) =>
        !isNaN(toNumber(value)) ? toNumber(value) : undefined
      )
      .positive(t('FORMS__positiveError'))
      .min(min, t('FORMS__minError'))
      .max(max, t('FORMS__maxError'))
      .required('Field is required'),
    twoFA: yup.string(),
  }).defined();

const connectWalletButtonStyles = css`
  display: flex;
  margin: 10px auto 0;
`;

const Form = styled('form')``;

const labelStyles = css`
  width: 140px;
  margin-right: 10px;
`;

const CurrenciesOptions = styled(DefCurrenciesOptions)`
  margin-top: 22px;
`;

const BottomFormControl = styled(FormControl)`
  flex-direction: row;
  margin-top: 20px;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    flex-wrap: wrap;
  }
`;

const FormLabel = styled(DefFormLabel)`
  margin-top: 13px;
`;

const Input = styled(TextField)`
  display: flex;
  flex-grow: 1;
`;

const MaxButton = styled(Button)`
  position: relative;
  right: -10px;
`;

const SubmitButton = styled(Button)`
  height: 53px;
  margin-left: 16px;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin-left: 0;
    margin-top: 10px;
    width: 100%;
  }
`;

export { BlockchainWithdrawForm };
