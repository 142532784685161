import {
  Button as DefButton,
  CircularProgress,
  ButtonProps as DefButtonProps,
} from '@mui/material';
import { styled } from '@mui/material/styles';

export type ButtonProps = {
  loading?: boolean;
} & DefButtonProps;

const Button = ({ loading, disabled, children, ...props }: ButtonProps) => {
  return (
    <StyledButton disabled={loading || disabled} {...props}>
      {children}
      {loading && <Spinner size={20} />}
    </StyledButton>
  );
};

const StyledButton = styled(DefButton)`
  position: relative;
`;

const Spinner = styled(CircularProgress)`
  margin: auto;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

export { Button };
