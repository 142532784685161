import { useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useModals } from '@/providers';
import { resetListStyles } from '@/styles';
import { sendSentryError } from '@/utils/sentry';
import { styled } from '@mui/material/styles';
import { Button as DefButton } from '../buttons';
import { Text } from '../texts';

const ConfirmModal = () => {
  const { t } = useTranslation();
  const { dispatch, data } = useModals<'confirm'>();

  const [loading, setLoading] = useState(false);

  useLayoutEffect(() => {
    dispatch({
      type: 'setWrapperProps',
      payload: {
        title: data ? t(data.title) : null,
      },
    });
  }, [dispatch, t, data]);

  if (!data) {
    return <Text>Error</Text>;
  }

  const { withClosingOnConfirmPress = true } = data;

  const closeModal = () => {
    dispatch({
      type: 'closeModal',
    });
  };

  return (
    <>
      {!!data.desc && <Text align={'center'}>{data.desc}</Text>}
      <ButtonsList>
        <ButtonsListItem>
          <Button loading={loading} onClick={closeModal}>
            {t('CONFIRM_MODAL__cancelButton')}
          </Button>
        </ButtonsListItem>
        <ButtonsListItem>
          <Button
            loading={loading}
            onClick={async () => {
              try {
                setLoading(true);
                await data.onConfirmPress();
                if (withClosingOnConfirmPress) {
                  closeModal();
                } else {
                  setLoading(false);
                }
              } catch (e) {
                sendSentryError(e);
                setLoading(false);
              }
            }}
          >
            {t('CONFIRM_MODAL__confirmButton')}
          </Button>
        </ButtonsListItem>
      </ButtonsList>
    </>
  );
};

const ButtonsList = styled('ul')`
  ${resetListStyles};
  max-width: 310px;
  align-self: center;
  display: flex;
  justify-content: space-between;
  padding-bottom: 16px;
  gap: 16px;
`;

const ButtonsListItem = styled('li')`
  flex: 0 0 48%;
`;

const Button = styled(DefButton)`
  width: 100%;
`;

export { ConfirmModal };
