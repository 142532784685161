import { Text } from '@/components/texts';
import { Paper } from '@mui/material';
import { styled } from '@mui/material/styles';

const ItemStatus = styled(Paper)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 46px;
  padding: 4px;
  background-color: ${({ theme }) => theme.getColor('blueZodiac')};
  color: #fff;
  text-transform: capitalize;
`;

ItemStatus.defaultProps = {
  // @ts-ignore
  component: Text,
};

export { ItemStatus };
