import { ComponentProps } from 'react';
import { ButtonBase } from '@mui/material';
import { styled } from '@mui/material/styles';

export type InputButtonProps = ComponentProps<typeof StyledButton>;

const InputButton = (props: InputButtonProps) => <StyledButton {...props} />;

const StyledButton = styled(ButtonBase)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 18px 16px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.getColor('blueCharcoal')};
  color: ${({ theme }) => theme.getColor('spunPearl')};

  ${({ theme }) => theme.breakpoints.between('md', 'lg')} {
    font-size: 11px;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    & + & {
      margin-top: 16px;
    }
  }
`;

export { InputButton };
