import { SvgIcon, SvgIconProps } from '@mui/material';

export type LoopIconProps = SvgIconProps;

const LoopIcon = (props: LoopIconProps) => (
  <SvgIcon {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M16.137 14.093 24 21.955 21.955 24l-7.862-7.863c-1.469 1.057-3.239 1.702-5.174 1.702A8.92 8.92 0 0 1 0 8.919 8.92 8.92 0 0 1 8.92 0a8.92 8.92 0 0 1 8.919 8.92c0 1.934-.645 3.704-1.702 5.173zM8.92 2.744A6.167 6.167 0 0 0 2.744 8.92a6.167 6.167 0 0 0 6.175 6.175 6.167 6.167 0 0 0 6.175-6.175A6.167 6.167 0 0 0 8.92 2.744z"
    />
  </SvgIcon>
);

export { LoopIcon };
