import { ComponentProps, PropsWithChildren } from 'react';
import { styled } from '@mui/material/styles';

export type SearchWrapperProps = PropsWithChildren<
  ComponentProps<typeof Wrapper>
>;

const SearchWrapper = (props: SearchWrapperProps) => <Wrapper {...props} />;

const Wrapper = styled('div')`
  padding: 24px;
  ${({ theme }) => theme.breakpoints.down('md')} {
    padding: 0;
  }
`;

export { SearchWrapper };
