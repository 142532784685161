import { useMemo } from 'react';
import { isEmpty } from 'lodash-es';
import { formatUnits } from 'viem';
import { useContractReads, useAccount } from 'wagmi';
import { useBlockchains } from '@/providers';
import { isCryptoAddress } from '@/utils/blockchains';
import { erc20ABI } from '@wagmi/core';

export const useBlockchainCurrenciesData = (type: 'deposit' | 'withdraw') => {
  const { address } = useAccount();
  const { activeBlockchain } = useBlockchains();

  const currencies = useMemo(
    () =>
      activeBlockchain?.blockchain_currencies.filter(
        ({ contract, deposit, withdraw }) =>
          isCryptoAddress(contract) && (type === 'deposit' ? deposit : withdraw)
      ) || [],
    [type, activeBlockchain]
  );

  const { isLoading, data: blockchainData } = useContractReads({
    contracts: currencies.map(({ contract }) => ({
      address: contract as CryptoAddress,
      abi: erc20ABI,
      functionName: 'balanceOf',
      args: [address!],
    })),
    enabled: !!address && !isEmpty(currencies),
    watch: true,
  });

  return useMemo(
    () => ({
      loading: isLoading,
      data: currencies.map(({ decimals, ...currencyData }, index) => ({
        ...currencyData,
        decimals,
        amount:
          typeof blockchainData?.[index].result === 'bigint'
            ? formatUnits(blockchainData?.[index].result as bigint, decimals)
            : '0',
      })),
    }),
    [isLoading, currencies, blockchainData]
  );
};

export type BlockchainCurrencies = ReturnType<
  typeof useBlockchainCurrenciesData
>['data'];
