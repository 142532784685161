import { isString } from 'lodash-es';
import { mainnet, bsc, polygon, bscTestnet, polygonMumbai } from 'wagmi/chains';
import { getEnvVariable } from '@/utils/env-helpers';

const testChains = !!getEnvVariable('TEST_BLOCKCHAINS_INCLUDED')
  ? [bscTestnet, polygonMumbai]
  : [];

export const supportedBlockchains = [mainnet, bsc, polygon, ...testChains];

export const isCryptoAddress = (value: unknown): value is CryptoAddress =>
  isString(value) && value.startsWith('0x');
