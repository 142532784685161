import { ReactNode } from 'react';
import { CloseIcon } from '@/components/icons';
import {
  Dialog,
  DialogContent as DefDialogContent,
  IconButton,
  DialogProps,
} from '@mui/material';
import { styled, css, Theme } from '@mui/material/styles';
import { Text } from '../texts';

export type ModalWrapperProps = {
  title?: ReactNode;
  withCloseButton?: boolean;
  dialogContentStyles?:
    | ReturnType<typeof css>
    | ((theme: Theme) => ReturnType<typeof css>);
  close: () => void;
} & Omit<DialogProps, 'title'>;

const ModalWrapper = ({
  title,
  withCloseButton = true,
  children,
  close,
  dialogContentStyles,
  ...modalProps
}: ModalWrapperProps) => (
  <Dialog {...modalProps}>
    {(!!title || withCloseButton) && (
      <Header>
        {!!title && (
          <Title variant={'headline-medium'} weight={'medium'}>
            {title}
          </Title>
        )}
        {withCloseButton && (
          <IconButton
            css={css`
              margin-left: auto;
              background-color: transparent;
            `}
            aria-label={'Close'}
            onClick={() => {
              close();
            }}
          >
            <CloseIcon
              css={css`
                width: 14px;
                height: 14px;
              `}
            />
          </IconButton>
        )}
      </Header>
    )}
    <DialogContent css={dialogContentStyles}>{children}</DialogContent>
  </Dialog>
);

const Header = styled('header')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
`;

const Title = styled(Text)``;

const DialogContent = styled(DefDialogContent)`
  display: flex;
  flex-direction: column;
`;

export { ModalWrapper };
