import { useMemo, ComponentProps } from 'react';
import { isEmpty } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { useEventFinancialOverviewQuery } from '@/apollo/operations';
import { PageSpinner } from '@/components/common-elements';
import {
  UserVenueFinancialOverviewTableRow,
  UserVenueFinancialOverviewTableRowData,
} from '@/components/tables/UserVenueFinancialOverviewTableRow';
import { getFormattedNumber, getPrice } from '@/utils/helpers';
import { TranslationKeys } from '@/utils/i18n';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell as DefTableCell,
  TableFooter,
} from '@mui/material';
import { styled, css } from '@mui/material/styles';
import { Text } from '../texts';

export type UserVenueFinancialOverviewTableProps = {
  eventId: string;
} & ComponentProps<typeof Wrapper>;

const UserVenueFinancialOverviewTable = ({
  eventId,
  ...props
}: UserVenueFinancialOverviewTableProps) => {
  const { t } = useTranslation();

  const { loading, error, data } = useEventFinancialOverviewQuery({
    fetchPolicy: 'no-cache',
    variables: {
      eventId,
    },
  });

  const tournaments = useMemo(() => data?.event?.tournaments ?? [], [data]);

  const { footerPendingBalance, footerBalance, footerTotalBalance } =
    useMemo(() => {
      const { localCurrencies, pendingBalance, balance } = tournaments.reduce(
        (acc, { local_currency, balances }) => {
          if (!acc.localCurrencies.has(local_currency.symbol)) {
            acc.localCurrencies.set(local_currency.symbol, local_currency.rate);
          }
          const pendingBalance = balances
            .filter(({ status }) => status === 'pending')
            .reduce((acc, { balance }) => (balance ? acc + balance : acc), 0);
          const balance = balances
            .filter(({ status }) => status === 'approved')
            .reduce((acc, { balance }) => (balance ? acc + balance : acc), 0);
          return {
            localCurrencies: acc.localCurrencies,
            pendingBalance: acc.pendingBalance + pendingBalance,
            balance: acc.balance + balance,
          };
        },
        {
          localCurrencies: new Map<string, number>(),
          pendingBalance: 0,
          balance: 0,
        }
      );

      const getBalanceString = (balance: number) => {
        const localValues = Array.from(localCurrencies).map(
          ([symbol, rate]) => `${getFormattedNumber(balance * rate)} ${symbol}`
        );
        return [getPrice(balance), ...localValues].join('\n');
      };

      return {
        footerPendingBalance: getBalanceString(pendingBalance),
        footerBalance: getBalanceString(balance),
        footerTotalBalance: getBalanceString(pendingBalance + balance),
      };
    }, [tournaments]);

  return (
    <Wrapper {...props}>
      {(() => {
        if (loading) return <PageSpinner />;
        if (error) {
          return (
            <Text align={'center'}>
              {t('USER_VENUE_FINANCIAL_OVERVIEW_TABLE__loadError')}
            </Text>
          );
        }
        if (isEmpty(tournaments)) {
          return (
            <Text align={'center'}>
              {t('USER_VENUE_FINANCIAL_OVERVIEW_TABLE__emptyTournaments')}
            </Text>
          );
        }
        return (
          <>
            <TableContainer sx={{ maxHeight: 440 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map(([column, label]) => (
                      <TableCell key={column}>{t(label)}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tournaments.map((tournament) => (
                    <UserVenueFinancialOverviewTableRow
                      key={tournament.id}
                      tournament={tournament}
                    />
                  ))}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TableCell $centered>
                      {t('USER_VENUE_FINANCIAL_OVERVIEW_TABLE__totalFooter')}
                    </TableCell>
                    <TableCell />
                    <TableCell $centered>{footerPendingBalance}</TableCell>
                    <TableCell $centered>{footerBalance}</TableCell>
                    <TableCell $centered $bold>
                      {footerTotalBalance}
                    </TableCell>
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </>
        );
      })()}
    </Wrapper>
  );
};

const columnsMap: ReadonlyMap<
  keyof UserVenueFinancialOverviewTableRowData,
  TranslationKeys
> = new Map([
  ['name', 'USER_VENUE_FINANCIAL_OVERVIEW_TABLE__tournamentNameColumn'],
  ['status', 'USER_VENUE_FINANCIAL_OVERVIEW_TABLE__tournamentStatusColumn'],
  [
    'pendingBalance',
    'USER_VENUE_FINANCIAL_OVERVIEW_TABLE__pendingBalanceColumn',
  ],
  ['balance', 'USER_VENUE_FINANCIAL_OVERVIEW_TABLE__balanceColumn'],
  ['totalExpected', 'USER_VENUE_FINANCIAL_OVERVIEW_TABLE__totalExpectedColumn'],
]);

const columns = Array.from(columnsMap);

const Wrapper = styled('div')``;

const TableCell = styled(DefTableCell)<{
  $bold?: boolean;
  $centered?: boolean;
}>`
  white-space: pre-line;
  ${({ $bold = false }) =>
    $bold &&
    css`
      font-weight: 500;
    `}
  ${({ $centered = false }) =>
    $centered &&
    css`
      text-align: center;
    `}
`;

export { UserVenueFinancialOverviewTable };
