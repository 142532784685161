import { forwardRef } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export type QuestionMarkInCircleIconProps = SvgIconProps;

// eslint-disable-next-line react/display-name
const QuestionMarkInCircleIcon = forwardRef<
  SVGSVGElement,
  QuestionMarkInCircleIconProps
>((props, ref) => (
  <SvgIcon ref={ref} {...props}>
    <g fill="currentColor">
      <path d="M10.714 19.295a1.286 1.286 0 1 0 2.572 0 1.286 1.286 0 0 0-2.572 0z" />
      <path d="M12 24C5.383 24 0 18.617 0 12S5.383 0 12 0s12 5.383 12 12-5.383 12-12 12zm0-22.278C6.333 1.722 1.722 6.332 1.722 12c0 5.667 4.61 10.278 10.278 10.278 5.667 0 10.278-4.611 10.278-10.278 0-5.667-4.611-10.278-10.278-10.278z" />
      <path d="M12 16.33a.857.857 0 0 1-.857-.857v-1.449c0-1.402 1.071-2.474 2.018-3.42.693-.693 1.41-1.41 1.41-1.982 0-1.428-1.153-2.59-2.571-2.59-1.442 0-2.571 1.112-2.571 2.532a.857.857 0 0 1-1.715 0c0-2.342 1.923-4.247 4.286-4.247s4.286 1.931 4.286 4.305c0 1.283-.973 2.255-1.913 3.196-.746.744-1.516 1.514-1.516 2.205v1.45a.858.858 0 0 1-.857.858z" />
    </g>
  </SvgIcon>
));

export { QuestionMarkInCircleIcon };
