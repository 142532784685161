import type { AppProps } from 'next/app';
import Head from 'next/head';
import Script from 'next/script';
import 'swiper/css';
import 'swiper/css/autoplay';
// import '@/assets/fonts/general-sans.css';
import '@/assets/fonts/sf-pro-display.css';
import { GlobalHooks } from '@/hooks';
import { PageWrapper } from '@/layouts';
import { Providers } from '@/providers';
import { createEmotionCache, theme, GlobalStyles } from '@/styles';
import { devEnvironment } from '@/utils/consts';
import { getEnvVariable } from '@/utils/env-helpers';
import { CacheProvider, EmotionCache } from '@emotion/react';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import '../utils/i18n';

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

// sendSentryError(new Error('Test sentry error'));

export interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache;
}

const MyApp = (props: MyAppProps) => {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;
  return (
    <>
      <Head>
        <link rel="icon" href="/favicon.ico" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#000000" />
      </Head>
      {!devEnvironment && (
        <Script
          id="gtag-base"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${getEnvVariable(
              'GOOGLE_TAG_MANAGER_ID'
            )}');
          `,
          }}
        />
      )}
      <CacheProvider value={emotionCache}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <GlobalStyles />
          <Providers pageProps={pageProps}>
            <GlobalHooks />
            <PageWrapper>
              <Component {...pageProps} />
            </PageWrapper>
          </Providers>
        </ThemeProvider>
      </CacheProvider>
    </>
  );
};

export default MyApp;
