import { useMemo, ComponentProps, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Form as DefForm } from 'formik';
import { TFunction } from 'i18next';
import { useSnackbar } from 'notistack';
import { object, string, ObjectSchema } from 'yup';
import { Button } from '@/components/buttons';
import { FormikTextField } from '@/components/formik-elements';
import { useFirebaseResetPassword } from '@/hooks';
import { sendSentryError } from '@/utils/sentry';
import { styled } from '@mui/material/styles';

export type ResetPasswordFormProps = {
  onSuccessSubmit?: () => void;
} & ComponentProps<typeof Form>;

const ResetPasswordForm = ({
  onSuccessSubmit,
  ...props
}: ResetPasswordFormProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const schema = useMemo(() => getSchema({ t }), [t]);
  const [resetPassword, _, resetError] = useFirebaseResetPassword();

  useEffect(() => {
    if (resetError) {
      sendSentryError(resetError);
    }
  }, [resetError]);

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={async ({ email }) => {
        const success = await resetPassword(email);
        if (success) {
          enqueueSnackbar(t('RESET_PASSWORD_FORM__sendSuccess'), {
            variant: 'success',
          });
          onSuccessSubmit?.();
        } else {
          enqueueSnackbar(t('RESET_PASSWORD_FORM__sendError'), {
            variant: 'error',
          });
        }
      }}
    >
      {({ isSubmitting }) => (
        <Form {...props}>
          <Input
            type={'email'}
            name={'email'}
            label={t('RESET_PASSWORD_FORM__emailInputLabel')}
          />
          <SubmitButton type={'submit'} loading={isSubmitting}>
            {t('RESET_PASSWORD_FORM__submitButton')}
          </SubmitButton>
        </Form>
      )}
    </Formik>
  );
};

type FormValues = {
  email: string;
};

const initialValues: FormValues = {
  email: '',
};

const getSchema = ({ t }: { t: TFunction }): ObjectSchema<FormValues> =>
  object({
    email: string()
      .email(t('FORMS__emailError'))
      .required(t('FORMS__requiredError')),
  }).defined();

const Form = styled(DefForm)`
  display: flex;
  flex-direction: column;
`;

const Input = styled(FormikTextField)`
  & + & {
    margin-top: 32px;
  }
`;

const SubmitButton = styled(Button)`
  margin-top: 32px;
`;

export { ResetPasswordForm };
