import { PropsWithChildren } from 'react';
import { Footer } from '@/components/footer';
import { Header } from '@/components/header';
import { Container } from '@mui/material';
import { styled } from '@mui/material/styles';

const PageWrapper = ({ children }: PropsWithChildren<any>) => (
  <Wrapper>
    <Header />
    <Container component={'main'}>{children}</Container>
    <Footer />
  </Wrapper>
);

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  main {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding-bottom: 50px;
  }
`;

export { PageWrapper };
