import { ComponentProps } from 'react';
import { useFormikContext, useField } from 'formik';
import { CalendarIcon } from '@/components/icons';
import dayjs from '@/utils/dayjs';
import { TextField } from '@mui/material';
import { css } from '@mui/material/styles';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

export type FormikDateTimeFieldProps = Omit<
  ComponentProps<typeof DateTimePicker>,
  'value' | 'renderInput' | 'onChange'
> & {
  name: string;
};

// TODO: consider to merge with FormikDateField
const FormikDateTimeField = ({ name, ...props }: FormikDateTimeFieldProps) => {
  const { setFieldValue } = useFormikContext();
  const [{ value, onChange, ...field }, { touched, error }] = useField(name);

  return (
    <DateTimePicker
      {...field}
      disableHighlightToday
      value={value}
      PopperProps={{ sx: commonSXProps }}
      DialogProps={{ sx: commonSXProps }}
      renderInput={(params) => (
        <TextField
          InputProps={{
            endAdornment: (
              <CalendarIcon
                css={css`
                  width: 20px;
                  height: 20px;
                `}
              />
            ),
          }}
          {...params}
          {...(touched && !!error
            ? {
                error: true,
                helperText: error,
              }
            : { error: false, helperText: undefined })}
        />
      )}
      onChange={(newValue) => {
        if (dayjs.isDayjs(newValue) && newValue.isValid()) {
          setFieldValue(name, newValue.utc().format());
        }
      }}
      {...props}
    />
  );
};

const commonSXProps = {
  '& .MuiPickersPopper-paper': {
    color: '#fff',
  },
  '& .MuiDatePickerToolbar-root': {
    color: '#fff',
    '& .MuiTypography-root': {
      color: '#fff',
    },
  },
  '& .MuiPickersCalendarHeader-label': {
    color: '#fff',
  },
  '& .MuiDayPicker-weekDayLabel': {
    color: '#fff',
  },
  '& .PrivatePickersYear-button': {
    color: '#fff',
  },
  '& .MuiClockNumber-root': {
    color: '#fff',
  },
  '& .MuiDateTimePickerToolbar-root .MuiTypography-root': {
    color: '#fff',
  },
};

export { FormikDateTimeField };
