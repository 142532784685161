import { useState, useLayoutEffect, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { EmailLoginForm, EmailRegisterForm } from '@/components/forms';
import { useModals } from '@/providers';
import { TranslationKeys } from '@/utils/i18n';
import { Tabs, Tab } from '@mui/material';
import { styled, css } from '@mui/material/styles';

const EmailLoginModal = () => {
  const { t } = useTranslation();
  const { dispatch } = useModals();
  const [contentType, setContentType] = useState(ContentTypes.LOGIN);

  useLayoutEffect(() => {
    dispatch({
      type: 'setWrapperProps',
      payload: {
        title: t(
          contentType === ContentTypes.LOGIN
            ? 'EMAIL_LOGIN_MODAL__loginTitle'
            : 'EMAIL_LOGIN_MODAL__registerTitle'
        ),
        dialogContentStyles,
      },
    });
  }, [dispatch, t, contentType]);

  return (
    <>
      <Tabs
        value={contentType}
        onChange={(event, newValue) => setContentType(newValue)}
        aria-label="login/register tabs"
      >
        {labelsByTypeArr.map(([key, { label }]) => (
          <Tab key={key} label={t(label)} {...a11yProps(key)} />
        ))}
      </Tabs>
      {labelsByTypeArr.map(([key, { Component }]) => (
        <TabPanel key={key} value={contentType} index={key}>
          <Component
            onSuccessSubmit={() => {
              dispatch({
                type: 'closeModal',
              });
            }}
          />
        </TabPanel>
      ))}
    </>
  );
};

enum ContentTypes {
  LOGIN,
  REGISTER,
}

type TabPanelProps = {
  children?: ReactNode;
  index: number;
  value: number;
};

function TabPanel({ children, value, index, ...other }: TabPanelProps) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`login-register-tabpanel-${index}`}
      aria-labelledby={`login-register-tab-${index}`}
      {...other}
    >
      {value === index && <TabPanelContent>{children}</TabPanelContent>}
    </div>
  );
}

function a11yProps(value: ContentTypes) {
  return {
    id: `login-register-tab-${value}`,
    'aria-controls': `login-register-tabpanel-${value}`,
  };
}

const labelsByTypeMap: ReadonlyMap<
  ContentTypes,
  {
    label: TranslationKeys;
    Component: typeof EmailLoginForm | typeof EmailRegisterForm;
  }
> = new Map([
  [
    ContentTypes.LOGIN,
    {
      label: 'EMAIL_LOGIN_MODAL__loginTabLabel',
      Component: EmailLoginForm,
    },
  ],
  [
    ContentTypes.REGISTER,
    {
      label: 'EMAIL_LOGIN_MODAL__registerTabLabel',
      Component: EmailRegisterForm,
    },
  ],
]);

const labelsByTypeArr = Array.from(labelsByTypeMap);

const dialogContentStyles = css`
  padding-top: 0;
`;

const TabPanelContent = styled('div')`
  padding-top: 32px;
`;

export { EmailLoginModal };
