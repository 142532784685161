import { useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useVerifyEmailMutation, GeneralStatus } from '@/apollo/operations';
import { useModals } from '@/providers';
import { sendSentryError } from '@/utils/sentry';
import { Link as DefLink } from '@mui/material';
import { css, styled } from '@mui/material/styles';
import { Text as DefText } from '../texts';

const VerifyEmailModal = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { dispatch } = useModals();

  const showError = (e: Error, msg?: string) => {
    sendSentryError(e);
    enqueueSnackbar(msg || t('VERIFY_EMAIL_MODAL__sendError'), {
      variant: 'error',
    });
  };

  const [verifyEmail] = useVerifyEmailMutation({
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      if (data.sendVerifyMail?.status === GeneralStatus.Ok) {
        enqueueSnackbar(t('VERIFY_EMAIL_MODAL__sendSuccess'), {
          variant: 'success',
        });
      } else {
        showError(
          new Error(
            `sendVerifyMail status not ok; msg:${data.sendVerifyMail?.message}`
          ),
          data.sendVerifyMail?.message
        );
      }
    },
    onError: (e) => {
      showError(e);
      sendSentryError(e);
    },
  });

  useLayoutEffect(() => {
    dispatch({
      type: 'setWrapperProps',
      payload: {
        title: t('VERIFY_EMAIL_MODAL__title'),
        dialogContentStyles: css`
          padding-top: 0;
          padding-bottom: 50px;
        `,
      },
    });
  }, [t, dispatch]);

  return (
    <>
      <Text
        style={{
          marginTop: 20,
        }}
        component={'h2'}
        variant={'body-medium'}
        align={'center'}
        color={'spunPearl'}
      >
        {t('VERIFY_EMAIL_MODAL__header')}
      </Text>
      <Text
        style={{
          marginTop: 20,
        }}
        component={'h2'}
        variant={'body-medium'}
        align={'center'}
        color={'spunPearl'}
      >
        {t('VERIFY_EMAIL_MODAL__text')}{' '}
        {/*
        // @ts-ignore*/}
        <Link component={'button'} onClick={() => verifyEmail()}>
          {t('VERIFY_EMAIL_MODAL__link')}.
        </Link>
      </Text>
    </>
  );
};

const Text = styled(DefText)`
  white-space: pre;
`;
const Link = styled(DefLink)`
  color: ${({ theme }) => theme.getColor('spunPearl')};
  text-decoration-color: ${({ theme }) => theme.getColor('spunPearl')};
`;

export { VerifyEmailModal };
