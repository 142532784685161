import { MouseEvent, useCallback } from 'react';
import { useRouter } from 'next/router';
import { useUserPlayerDataQuery } from '@/apollo/operations';
import { useServerAuth } from '@/providers/ServerAuth';

export const useOnPlayerPress = () => {
  const router = useRouter();
  const { authorized } = useServerAuth();
  const { data } = useUserPlayerDataQuery({
    skip: !authorized,
  });
  const userPlayerId = data?.me?.playerId;
  return useCallback(
    (
      playerId: string,
      e: MouseEvent<HTMLAnchorElement> | MouseEvent<HTMLButtonElement>
    ) => {
      if (!authorized) return;
      if (playerId === userPlayerId) {
        e.preventDefault();
        router.push('/player-profile');
      }
    },
    [router, authorized, userPlayerId]
  );
};
