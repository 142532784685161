import { useMemo, HTMLAttributes } from 'react';
import { toString, toNumber } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { useChangeNetwork } from '@/hooks';
import { useBlockchains } from '@/providers';
import { TextField, MenuItem } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Text } from '../texts';

export type BlockchainsSelectProps = {
  disabled?: boolean;
} & HTMLAttributes<HTMLDivElement>;

const BlockchainsSelect = ({ disabled, ...props }: BlockchainsSelectProps) => {
  const { t } = useTranslation();
  const { activeBlockchain, blockchains } = useBlockchains();

  const {
    isLoading: changingChain,
    canSwitch,
    switchNetworkAsync,
  } = useChangeNetwork();

  const options = useMemo(
    () =>
      blockchains.map(({ chainId, name, iconUrl }) => ({
        label: name,
        value: toString(chainId),
        icon: iconUrl ?? undefined,
      })),
    [blockchains]
  );

  const value = useMemo(
    () =>
      activeBlockchain
        ? options.find(
            ({ value }) => value === toString(activeBlockchain.chainId)
          )?.value
        : undefined,
    [activeBlockchain, options]
  );

  return (
    <Wrapper {...props}>
      {!(canSwitch && switchNetworkAsync) ? (
        <ErrorBlock>
          <Text variant={'title-medium'}>
            {t('BLOCKCHAINS_SELECT__errorTitle')}
          </Text>
          <Text>
            {t('BLOCKCHAINS_SELECT__errorBlockchains')}:{' '}
            {blockchains.map(({ name }) => name).join(', ')}
          </Text>
        </ErrorBlock>
      ) : (
        <TextField
          fullWidth
          select
          label={t('BLOCKCHAINS_SELECT__placeholder')}
          value={value}
          disabled={changingChain || disabled}
          onChange={(data) => {
            const newBlockchainId = data.target.value;
            if (newBlockchainId !== toString(activeBlockchain?.chainId)) {
              switchNetworkAsync(toNumber(newBlockchainId)).catch((e) => {
                console.warn('switchNetworkAsync error', e);
              });
            }
          }}
        >
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      )}
    </Wrapper>
  );
};

const Wrapper = styled('div')``;

const ErrorBlock = styled('div')`
  margin-top: 10px;
`;

export { BlockchainsSelect };
