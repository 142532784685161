import { SvgIcon, SvgIconProps } from '@mui/material';

export type CopyIconProps = SvgIconProps;

const CopyIcon = (props: CopyIconProps) => (
  <SvgIcon {...props}>
    <path
      fill="currentColor"
      d="M17.273 0H4.182C2.982 0 2 .982 2 2.182v15.273h2.182V2.182h13.09zm3.272 4.364h-12c-1.2 0-2.181.981-2.181 2.181v15.273c0 1.2.981 2.182 2.181 2.182h12c1.2 0 2.182-.982 2.182-2.182V6.545c0-1.2-.982-2.181-2.182-2.181zm0 17.454h-12V6.545h12z"
    />
  </SvgIcon>
);

export { CopyIcon };
